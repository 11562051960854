/**
 *
 * AdvancedDataTable types
 *
 */

/* ----------------------------------------------------------------
                  Settings types
---------------------------------------------------------------- */
export const settings = Object.freeze({
  COLUMN_VISIBILITY: 'columnVisibility',
  CURRENT_PAGE: 'currentPage',
  FILTERING: 'filtering',
  CUSTOM_FILTERING: 'customFiltering',
  GROUPING: 'grouping',
  LAZY_LOADING_BLOCK_SIZE: 'lazyLoadingBlockSize',
  MULTILINE_CELL: 'multilineCell',
  PAGE_SIZE: 'pageSize',
  PAGING_TABLE: 'pagingTable',
  REORDERING: 'reordering',
  RESIZING: 'resizing',
  SELECTION_ALL: 'selectionAll',
  TABLE_ACTION_MENU: 'tableActionMenu',
  TABLE_DRAWER: 'tableDrawer',
  TABLE_ROW_DETAIL: 'tableRowDetail',
  VIRTUAL_TABLE: 'virtualTable',
  SIGNALR_UPDATE: 'signalrUpdate',
  SETTING_BUTTON: 'settingButton',
  UPDATE_BUTTON: 'updateButton',
  FILTER_BUTTON: 'filterButton',
  CLEAR_FILTER_BUTTON: 'clearFilterButton',
  SAVED_FILTERS: 'savedFilters',
  TABLE_TYPE: 'tableType',
  TERMINAL_ADVANCED_INPUT: 'terminalAdvancedInput',
});

/* ----------------------------------------------------------------
                  Filter Operation types
---------------------------------------------------------------- */
export const filterOperation = Object.freeze({
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_OR_EQUAL: 'greaterThanOrEqual',
  LESS_THAN: 'lessThan',
  LESS_THAN_OR_EQUAL: 'lessThanOrEqual',
  DATE_RANGE: 'dateRange',
  MONTH: 'month',
  ONE_DAY: 'oneDay',
});
