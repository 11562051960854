/**
 *
 * MultiSelectField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { isArray } from 'utils/typeUtils';

const styles = (theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 9) + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MultiSelect extends React.PureComponent {
  optionsInMap = {};

  isInitialized = false;

  constructor(props) {
    super(props);
    const { options } = props;

    if (options.length > 0) {
      this.handleInitializationInEditMode();
      this.optionsInMap = this.transformOptionsInMap(options);
      this.isInitialized = true;
    }
  }

  componentDidMount() {
    const { options } = this.props;

    if (options.length > 0) {
      this.handleInitializationInEditMode();
      this.optionsInMap = this.transformOptionsInMap(options);
      this.isInitialized = true;
    }
  }

  componentDidUpdate() {
    const { options } = this.props;

    if (options.length && !this.isInitialized) {
      this.handleInitializationInEditMode();
      this.optionsInMap = this.transformOptionsInMap(options);
      this.isInitialized = true;
    }
  }

  transformOptionsInMap = (options) => (
    options.reduce((acc, option) => Object.assign({}, acc, { [option.value]: option.text }), {})
  );

  handleInitializationInEditMode = () => {
    const {
      input: { onChange },
      mode,
      options,
      textValue,
    } = this.props;

    if (mode === 'edit' && textValue != null) {
      const arrayOfNames = new Set(textValue.split(/[,;]/).map((item) => item.trim()));
      const newValue = options.reduce((acc, item) => {
        if (arrayOfNames.has(item.text)) acc.push(item.value);
        return acc;
      }, []);

      onChange(newValue);
    }
  };

  handleRenderViewMode = (itemLabel) => {
    const { classes } = this.props;
    return (
      <Chip
        key={itemLabel}
        label={itemLabel}
        className={classes.chip}
        variant="outlined"
        color="primary"
      />
    );
  };

  handleRenderValueFn = (acc, id) => {
    acc.push(this.optionsInMap[id]);
    return acc;
  };

  getValue = () => {
    const { mode, textValue, input: { value }, options } = this.props;
    if ((mode === 'add' || mode === 'edit') && options.length > 0) { return isArray(value) ? value : []; }
    if (mode === 'view') { if (textValue != null && textValue !== '') { return textValue.split(/[,;]/).map((item) => item.trim()); } }
    return [];
  };

  render() {
    const {
      classes,
      helperText,
      label,
      meta: { touched, error, submitFailed },
      input: { onChange },
      options,
      readOnly,
      required,
      mode,
    } = this.props;
    const isError = (touched || submitFailed) && !!error;

    return (
      <FormControl
        className={classes.formControl}
        error={isError}
        required={required}
      >
        <InputLabel htmlFor="select-multiple-field">{label}</InputLabel>
        <Select
          multiple
          value={this.getValue()}
          onChange={onChange}
          input={<Input id="select-multiple-field" />}
          renderValue={(selected) => mode === 'view'
            ? (
              <div className={classes.chips}>
                {
                  selected.filter(i => i.length > 0)
                    .map(this.handleRenderViewMode)
                }
              </div>
            ) : selected.reduce(this.handleRenderValueFn, []).join(', ')}
          MenuProps={MenuProps}
          inputProps={{
            readOnly,
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              <Checkbox
                checked={this.getValue().indexOf(option.value) > -1}
                color="primary"
              />
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
}

MultiSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  mode: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  textValue: PropTypes.string,
  touched: PropTypes.bool,
};

MultiSelect.defaultProps = {
  textValue: '',
  options: [],
};

export default withStyles(styles, { withTheme: true })(MultiSelect);
