/**
 *
 * ReceptionView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReceptionView from './ReceptionView';
import * as actions from './actions';

class ReceptionViewContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentWillMount() {
    const { params: { requestId }, handleParams } = this.props;
    if (requestId) {
      handleParams(requestId);
    }
  }

  render() {
    return (
      <ReceptionView {...this.props} />
    );
  }
}

ReceptionViewContainer.propTypes = {
  params: PropTypes.object,
  handleParams: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    handleParams: (requestId) => dispatch(actions.handleParams(requestId)),
  };
}
export default connect(null, mapDispatchToProps)(ReceptionViewContainer);
