/**
 *
 * TenantForm14140
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import { CREATED } from 'domain/Data/RequestStatus/constants';
import {
  ON_CAR_ENTRY, ON_PASS_MAKING,
  ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { formField } from 'domain/typeConstants/form';
import * as entity from 'domain/Data/constants';
import { validateCarNumber } from 'utils/validators';
import {
  berlinskyVisitTimeLabel,
  carModelTextVisible,
  carNumberHelperText, carNumberVisible, carNumberRequired,
  departmentCalculatedValue, departmentHelperText, departmentVisibleOptions,
  fileVisible, fileLabel,
  issueReasonVisibleOptions, issueReasonVisible, issueReasonRequired,
  longtermVisible,
  materialValuesDataLabel,
  nonStandardCarNumberVisible,
  parkingPlaceVisible, parkingPlaceCalculatedValue, parkingPlaceRequired,
  parkingVisible, parkingHelperText,
  typeHelperText,
  visitDateRowBreakBefore,
  visitorFullNameLabel,
} from '../common/fields';
import { helperTextClassName } from '../common/formClassNames';
import {
  MAKING_PASS_ON_THE_CAR,
  ON_CHECK_CARD,
  ON_LOSS_OR_BREAK_OF_PASS,
} from '../../../domain/Data/RequestType/constants';
import { GROUND, UNDERGROUND } from '../../../domain/Data/ParkingType/constants';
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class TenantForm14140 extends TenantForm {
  getClassNames() {
    return {
      helperText: helperTextClassName,
    };
  }

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'customer',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        cached: false,
        type: formField.SELECT,
        entityName: entity.COMPANIES,
        name: 'company',
        label: 'Арендатор',
        visible: false,
        initialValue: (values, mode, user) => user.companyId,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'arrivalDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: entity.REQUEST_STATUSES,
        visible: (currentValues, initialValues, mode) => mode === 'view',
        initialValue: CREATED,
        orderBy: 'name',
      },
      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        required: true,
        entityName: entity.REQUEST_TYPES,
        orderBy: 'order',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_VISIT;
          }
          return values.type;
        },
        clearErrorsOnChange: true,
        helperText: typeHelperText,
      },
      {
        type: formField.SELECT,
        entityName: entity.ACTION_CARDS,
        name: 'berlinskyActionCard',
        label: 'Действие с картой',
        visible: (values) => values.type === ON_CHECK_CARD,
      },
      {
        name: 'longterm',
        type: formField.CHECKBOX,
        label: 'Долгосрочная',
        visible: longtermVisible,
      },
      {
        type: formField.CHECKBOX,
        name: 'isGiveRemoteController',
        label: 'Выдать пульт',
        visible: (values) => values.type === MAKING_PASS_ON_THE_CAR,
      },
      {
        name: 'visitDate',
        type: formField.DATE,
        label: 'Дата',
        required: true,
        visible: true,
        initialValue: currentDate,
        rowBreakBefore: visitDateRowBreakBefore,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
      },
      {
        type: formField.SELECT,
        entityName: entity.ISSUE_REASONS,
        name: 'issueReason',
        label: 'Причина',
        visible: issueReasonVisible,
        visibleOptions: issueReasonVisibleOptions,
        required: issueReasonRequired,
      },
      {
        name: 'expirationDate',
        type: formField.DATE,
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: (values) => values.longterm && values.type !== SERVICE,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm ? values.expirationDate : null,
        min: (values) => new Date(values.visitDate) < currentDate ?
          new Date(values.visitDate) : currentDate,
      },
      {
        name: 'isGroupRequest',
        type: formField.CHECKBOX,
        label: 'Групповая заявка',
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
        rowBreakBefore: true,
      },
      {
        name: 'nonstandardCarNumber',
        type: formField.CHECKBOX,
        label: 'Нестандартный № авто',
        rowBreakBefore: false,
        rowBreakAfter: false,
        visible: nonStandardCarNumberVisible,
      },
      {
        type: formField.CHECKBOX,
        name: 'informed',
        label: 'Ознакомлен',
        visible: (values) => values.type === MAKING_PASS_ON_THE_CAR || values.type === ON_PASS_MAKING || values.type === ON_VISIT,
        required: true,
        helperText: "В случаи утери карты или её поломки, Арендатор должен заплатить штраф",
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: formField.DADATA,
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        visible: (values) => !values.isGroupRequest && values.type !== SERVICE,
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'berlinskyMaterialCommodityValueTypes',
        label: 'Карта/Пульт',
        type: formField.SELECT,
        multiple: true,
        entityName: entity.MATERIAL_COMMODITY_VALUE_TYPES,
        visible: (values) => values.type === ON_LOSS_OR_BREAK_OF_PASS,
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: true
      },
      {
        type: formField.SELECT,
        name: 'berlinskyVisitTime',
        entityName: entity.VISIT_TIMES,
        label: berlinskyVisitTimeLabel,
        visible: (values) => new Set([ON_VISIT, ON_CAR_ENTRY]).has(values.type),
      },
      {
        type: formField.SELECT,
        entityName: entity.TIMES,
        name: 'berlinskyTime',
        label: 'Время',
        visible: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
        rowBreakBefore: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
      },
      {
        type: formField.SELECT,
        multiple: true,
        entityName: entity.ACCESS_AREAS,
        name: 'berlinskyAccessAreas',
        label: 'Зона доступа',
        visible: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
      },
      {
        name: 'berlinksyAnotherTime',
        label: 'Другое',
        visible: (values) => values.type === ON_PASS_MAKING || values.type === ON_CHECK_CARD,
        grid: { sm: 12 },
      },
      {
        name: 'remoteControllerNumber',
        label: 'Пульт, ключ №',
        visible: false,
      },
      {
        name: 'passNumber',
        visible: false,
        label: '№ карты',
        initialValue: () => null,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        required: carNumberRequired,
        visible: carNumberVisible,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
        helperText: carNumberHelperText,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: carModelTextVisible,
      },
      {
        name: 'parking',
        type: formField.SELECT,
        label: 'Парковка',
        entityName: entity.PARKINGS,
        visible: parkingVisible,
        helperText: parkingHelperText,
        calculatedValue: (currentValues) => {
          if (currentValues) {
            if (currentValues.type === MAKING_PASS_ON_THE_CAR) {
              return UNDERGROUND;
            }

            return currentValues.parking;
          }

          return null;
        },
        disabled: (values) => values.type === MAKING_PASS_ON_THE_CAR,
      },
      {
        name: 'parkingPlace',
        label: 'Место парковки',
        visible: parkingPlaceVisible,
        required: parkingPlaceRequired,
        calculatedValue: parkingPlaceCalculatedValue,
        initialValue: () => null,
      },
      {
        name: 'materialValuesData',
        label: materialValuesDataLabel,
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        type: formField.SELECT,
        entityName: entity.DEPARTMENTS,
        name: 'department',
        label: 'Здание',
        visible: true,
        required: true,
        calculatedValue: departmentCalculatedValue,
        helperText: departmentHelperText,
        rowBreakBefore: true,
        visibleOptions: departmentVisibleOptions,
      },
      {
        name: 'isDiscretionOfSecurity',
        label: (values) => values.type === ON_CAR_ENTRY ? 'Выбор машиноместа на усмотрение СБ' : 'На усмотрение СБ',
        type: formField.CHECKBOX,
        visible: (values) => (values.type === ON_CAR_ENTRY || values.type === MAKING_PASS_ON_THE_CAR) && values.parking !== GROUND,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        disabled: true,
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
      },
      {
        name: 'service',
        type: formField.SELECT,
        label: 'Категория работ',
        entityName: entity.SERVICES,
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'assignee',
        type: formField.SELECT,
        label: 'Исполнитель',
        entityName: entity.EMPLOYEES,
        orderBy: 'name',
        cached: false,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => { // eslint-disable-line
            if (item.company === user.companyId && item.isPerformer) assignee.push(item.id);
          });

          return assignee;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        required: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'executionTerm',
        type: formField.DATE,
        label: 'Срок исполнения',
        required: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
        disabled: true,
      },
      {
        type: formField.FILE,
        name: 'file',
        label: fileLabel,
        visible: fileVisible,
        rowBreakBefore: true,
        required: (values) => new Set([ON_PROPERTY_REMOVAL]).has(values.type),
      },
      {
        name: 'photo',
        label: 'Фото',
        type: formField.FILE,
        visible: (values) => values.type === ON_PASS_MAKING,
      },
    ];
  }
}
