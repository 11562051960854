/*
 *
 * DadataField actions
 *
 */

import {
  CLEAR_SUGGESTIONS,
  LOAD_SUGGESTIONS,
  LOAD_SUGGESTIONS_SUCCESS,
  LOAD_SUGGESTIONS_ERROR,
} from './constants';

export function clearSuggestions(advancedForm, field) {
  return {
    type: CLEAR_SUGGESTIONS,
    meta: { advancedForm, field },
  };
}

export function loadSuggestions(advancedForm, field, searchQuery) {
  return {
    type: LOAD_SUGGESTIONS,
    meta: { advancedForm, field },
    payload: { searchQuery },
  };
}

export function suggestionsLoaded(advancedForm, field, suggestions) {
  return {
    type: LOAD_SUGGESTIONS_SUCCESS,
    meta: { advancedForm, field },
    payload: { suggestions },
  };
}

export function suggestionsLoadingError(advancedForm, field, error) {
  return {
    type: LOAD_SUGGESTIONS_ERROR,
    meta: { advancedForm, field },
    payload: { error },
  };
}
