/**
 *
 * DocumentsPage15767
 *
 */

import React from 'react';
import DocumentsPage from 'pages/Documents/DocumentsPage';
import {
    SupplyRequestDxGrid15767,
    SupplyRequestForm15767,
    SupplyRequestPositionFormContainer15767,
    SupplyRequestMobileGrid15767,
    SupplyRequestFormDialogContainer15767,
    SupplyRequestPositionFormDialog15767,
} from './SupplyRequestView';


function DocumentsPage15767(props) {

    return (
        <DocumentsPage
            supplyRequestDxGrid={<SupplyRequestDxGrid15767/>}
            supplyRequestForm={<SupplyRequestForm15767/>}
            supplyRequestFormDialog={<SupplyRequestFormDialogContainer15767
                form={<SupplyRequestForm15767/>}
            />}

            supplyRequestPositionForm={<SupplyRequestPositionFormContainer15767/>}
            supplyRequestPositionFormDialog={<SupplyRequestPositionFormDialog15767
                form={<SupplyRequestPositionFormContainer15767/>}
            />}

            supplyRequestMobileGrid={<SupplyRequestMobileGrid15767/>}
            {...props}
        />
    );
}

export default DocumentsPage15767;

