/*
 *
 * ReceptionDxGrid14927
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';
import { requestColumnSizes } from 'domain/typeConstants/table';

export class ReceptionDxGrid14927 extends ReceptionDxGrid {

  getColumns() {
    const columns = super.getColumns();

    const passNumberIndex = columns.findIndex((column) => column.name === 'passNumber');
    columns[passNumberIndex] = {
      path: 'accessCardKey',
      name: 'accessCardKey',
      title: 'Ключ карты доступа',
      width: requestColumnSizes.passNumber,
    };

    columns.push({
      path: ['accessCard', 'name'],
      name: 'accessCardName',
      title: 'Номер карты доступа',
      getCellValue: (row) => (row.accessCard ? row.accessCard.name : undefined),
      width: requestColumnSizes.passNumber,
    })

    return columns;
  }

  getGridFilters() {
    const gridFilters = super.getGridFilters();
    gridFilters.push({ columnName: 'accessCardKey', value: '' });
    gridFilters.push({ columnName: 'accessCardName', value: '' });
    return gridFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const passNumberIndex = columnOrder.findIndex((column) => column === 'passNumber')
    columnOrder.splice(passNumberIndex, 1, 'accessCardKey')
    columnOrder.splice(passNumberIndex, 0, 'accessCardName')
    return columnOrder;
  }
}
