export const statusAnnotation = (accountId) => {
    let annotation = {
        "4285215000": { label: 'Создана', color: '#DC2218' },
        "4285216000": { label: 'Закрыта', color: '#E0E0E0' },
        "12884953000": { label: 'Принята', color: '#16DB23' },
        "421575453000": { label: 'Выполнена', color: '#1D88DB' },
        "421575459000": { label: 'Отклонена', color: '#8822DB' },
        "421575460000": { label: 'На территории', color: '#DB7D1B' },
        "1812442996000": { label: 'У Исполнителя', color: '#D6B216' },
        "2237210852000": { label: 'Выполняется', color: '#DB6865' },
        "3736492009000": { label: 'На согласовании', color: '#CC1CD6' },
        "4417117407000": { label: 'Ожидает S3', color: '#204CDB' },
        "4092397887000": { label: 'У Арендатора', color: '#DDB78A' },
        "2804833187000": { label: 'Выдан пропуск', color: '#DD7D1B' },
    }

    switch (accountId) {
        case '14326':
            annotation["12884953000"] = { label: 'Согласована', color: '#16DB23' }
            return annotation

        default:
            return annotation
    }
}



export const typeAnnotation = (accountId) => {
    let annotation = {
        "393629542000": { label: 'Гость', color: '#DC2218' },
        "393629546000": { label: 'Авто', color: '#18CFDB' },
        "393629549000": { label: 'Внос', color: '#DB7418' },
        "421534163000": { label: 'Вынос', color: '#3E20DB' },
        "437149164000": { label: 'Пост.пропуск', color: '#DBC115' },
        "421534160000": { label: 'Работы', color: '#32DB11' },
        "3724900074000": { label: 'Сервис', color: '#D7224D' },
    }

    switch (accountId) {
        case '14326':
            annotation["393629546000"] = { label: 'На гостя и парковку', color: '#18CFDB' }
            annotation["3724900074000"] = { label: 'В инженерную службу Арендодателя', color: '#D7224D' }
            annotation["437149164000"] = { label: 'Получение/перевыпуск карты', color: '#3E20DB' }
            annotation["393629549000"] = { label: 'Ввоз/вывоз/перемещение', color: '#DB7418' }
            annotation["421534160000"] = { label: 'На проведение работ', color: '#32DB11' }
            annotation["4022408710000"] = { label: 'Блокировка/доп.уровень карты', color: '#DBC115' }
            return annotation

        default:
            return annotation
    }
}

export const LOAD_DATA = 'claris-vnext/Dashboard/DashboardView/LOAD_DATA';
export const SAVE_DATA = 'claris-vnext/Dashboard/DashboardView/SAVE_DATA';
export const SAVE_RANGE = 'claris-vnext/Dashboard/DashboardView/SAVE_RANGE';
export const SET_FILTER = 'claris-vnext/Dashboard/DashboardView/SET_FILTER';