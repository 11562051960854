/**
 *
 * ParkingOperatorFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { PARKING_OPERATOR_REQUESTS_FORM, PARKING_OPERATOR_REQUESTS_FORM_DIALOG } from './constants';

function ParkingOperatorFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={PARKING_OPERATOR_REQUESTS_FORM_DIALOG}
      formName={PARKING_OPERATOR_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
    />
  );
}

ParkingOperatorFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ParkingOperatorFormDialog;
