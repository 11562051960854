/**
 *
 * VisitorArrivalForm14366
 *
 */

import { VisitorArrivalForm } from 'pages/Requests/ReceptionView';
import { ON_VISIT } from 'domain/Data/RequestType/constants';
import { ON_TERRITORY } from 'domain/Data/RequestStatus/constants';
import { kyrilicKeyboardLayoutToLatin } from 'utils/common';

export class VisitorArrivalForm14366 extends VisitorArrivalForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const currentDate = new Date();
    const fields = super.getFields();

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].calculatedValue = (values) => values.type === ON_VISIT ? values.status : ON_TERRITORY;

    const arrivalDateIndex = fields.findIndex((field) => field.name === 'arrivalDate');
    fields[arrivalDateIndex].required = (values) => values.type !== ON_VISIT;
    fields[arrivalDateIndex].calculatedValue = (values) => values.type === ON_VISIT ? values.arrivalDate : currentDate;

    const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
    fields[passNumberIndex].rowBreakBefore = true;

    fields.splice(passNumberIndex + 1, 0, {
      name: 'accessCardKey',
      label: 'Ключ карты доступа',
      initialValue: () => null,
      normalize: (value) => value ? kyrilicKeyboardLayoutToLatin(value) : value,
    });

    return fields;
  }
}
