/**
*
* SelectGridFieldContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SelectGridField from './SelectGridField';
import * as advancedFormActionCreators from '../actions';
import * as actionCreators from './actions';
import { makeSelectRecord, makeSelectMode, } from '../selectors';
import { makeSelectEntities } from 'domain/Data/selectors';


function createSelectGridFieldContainer(formName, fieldName) {

  class SelectGridFieldContainer extends React.PureComponent {
    render() {
      const { clearErrorsOnChange, ...restProps } = this.props;
      return (
        <Field
          {...restProps}
          component={SelectGridField}
        />
      );
    }
  }

  SelectGridFieldContainer.propTypes = {
    normalize: PropTypes.func,
    clearErrorsOnChange: PropTypes.bool,
  };

  const mapStateToProps = createStructuredSelector({
    record: makeSelectRecord(formName),
    mode: makeSelectMode(formName),
    entities: makeSelectEntities(),
  });

  const mapDispatchToProps = (dispatch) => ({
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
    executeEditAction: (recordId) => dispatch(actionCreators.executeEditAction(fieldName, recordId)),
  });

  const areStatesEqual = () => true

  return connect(mapStateToProps, mapDispatchToProps, null, { areStatesEqual })(SelectGridFieldContainer);
}

export default createSelectGridFieldContainer;
