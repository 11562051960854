/**
 *
 * AdvancedMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Loader from 'components/CircularLoader';

import {
    ActionMenu,
    HeaderRow,
    ListContainer,
    RowDetail,
} from './components';

const styles = () => ({
    root: {
        position: 'relative',
        fontSize: 12,
        height: '100%',
    },
    overlay: {
        background: 'rgba(33,33,33, .15)',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    hide: {
        display: 'none',
    },
});

class AdvancedMobileGrid extends React.PureComponent {
    static propTypes = {
        actionButtons: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            svgIcon: PropTypes.node,
            disabled: PropTypes.bool,
        })),
        actionMenuItems: PropTypes.array,
        changeIsLoading: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        isListOver: PropTypes.bool,
        isLoading: PropTypes.bool,
        loadData: PropTypes.func.isRequired,
        getRowHeight: PropTypes.func,
        onActionMenuItemClick: PropTypes.func.isRequired,
        onActionButtonClick: PropTypes.func.isRequired,
        onChangeSearchQuery: PropTypes.func,
        onMenuItemChange: PropTypes.func.isRequired,
        rows: PropTypes.array,
        rowComponent: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func,
        ]),
        searchQuery: PropTypes.string,
        detailRowFields: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    getValue: PropTypes.func,
                    name: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired,
                    type: PropTypes.string,
                }),
            ),
            PropTypes.array,
            PropTypes.func,
        ]),
    };

    constructor(props) {
        super(props);

        this.listContainerRef = React.createRef();
        this.listRef = React.createRef();

        this.state = {
            actionMenuRecord: null,
            actionMenuX: null,
            actionMenuY: null,
            containerHeight: null,
            containerWidth: null,
            detailRecord: null,
            detailRowX: null,
            detailRowY: null,
            isOpenActionMenu: false,
            isOpenDetailRow: false,
            isOpenFilterDialog: false,
        };

        this.onSignalRMessage = this.onSignalRMessage.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleChangeResize);
        const listContainer = this.listContainerRef.current.getBoundingClientRect();
        const {width, height} = listContainer;

        this.setState({
            containerHeight: height - 40,
            containerWidth: width,
        });

        window.addEventListener('signalRMessage', this.onSignalRMessage);
        ;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleChangeResize);
        window.removeEventListener('signalRMessage', this.onSignalRMessage);
    }

    onSignalRMessage(e) {
        if (this.props.entityName == e.detail)
            this.props.loadData();
    };

    handleClickActionMenu = (row) => (event) => {
        const {onMenuItemChange, changeCurrentRecord} = this.props;
        if (changeCurrentRecord) changeCurrentRecord(row)
        if (onMenuItemChange) onMenuItemChange([row.id]);
        this.setState({
            isOpenActionMenu: true,
            actionMenuX: event.clientX,
            actionMenuY: event.clientY,
            actionMenuRecord: row,
        });
    };

    handleChangeResize = () => {
        const listContainer = this.listContainerRef.current;
        const {height, width} = listContainer.getBoundingClientRect();

        this.setState({
            containerHeight: height - 40,
            containerWidth: width,
        });
    };

    handleClickActionMenuItem = (actionName, record) => {
        const {onActionMenuItemClick} = this.props;
        if (onActionMenuItemClick) onActionMenuItemClick(actionName, record);
        this.setState(() => ({isOpenActionMenu: false}));
    };

    handleCloseActionMenu = () => {
        this.setState({isOpenActionMenu: false});
    };

    handleClickDetailRow = (row) => (event) => {
        this.setState({
            isOpenDetailRow: true,
            detailRowX: event.clientX,
            detailRowY: event.clientY,
            detailRecord: row,
        });
    };

    handleCloseDetailRow = () => {
        this.setState({isOpenDetailRow: false});
    };

    handleClickFilterOnButton = () => {
        this.setState({isOpenFilterDialog: true});
    };

    handleClickFilterOffButton = () => {
        const {onClearFilters, gridFilters} = this.props;
        const clearGridFilters = gridFilters.map(f => f.value !== '' ? {...f, value: ''} : f)
        if (onClearFilters) {
            onClearFilters(clearGridFilters)
        }
    };

    render() {
        const {
            actionButtons,
            actionMenuItems,
            changeIsLoading,
            classes,
            detailRowFields,
            getRowHeight,
            isListOver,
            isLoading,
            loadData,
            onActionButtonClick,
            onChangeSearchQuery,
            rowComponent,
            rows,
            searchQuery,
            features,
            accountId,
        } = this.props;

        const {
            actionMenuRecord,
            actionMenuX,
            actionMenuY,
            detailRowX,
            detailRowY,
            isOpenDetailRow,
            containerHeight,
            containerWidth,
            isOpenActionMenu,
            detailRecord,
        } = this.state;

        return (
            <div
                ref={this.listContainerRef}
                className={classes.root}
            >
                <HeaderRow
                    actionButtons={actionButtons}
                    listRef={this.listRef}
                    loadData={loadData}
                    onActionButtonClick={onActionButtonClick}
                    onChangeSearchQuery={onChangeSearchQuery}
                    searchQuery={searchQuery}
                    onClickFilterOn={this.handleClickFilterOnButton}
                    onClickFilterOff={this.handleClickFilterOffButton}
                    features={features}
                />
                <ListContainer
                    changeIsLoading={changeIsLoading}
                    getRowHeight={getRowHeight}
                    height={containerHeight}
                    isListOver={isListOver}
                    isLoading={isLoading}
                    listRef={this.listRef}
                    onActionMenuClick={this.handleClickActionMenu}
                    onChangeExpandedRowIds={this.handleChangeExpandedRowIds}
                    onDetailRowClick={this.handleClickDetailRow}
                    onLoadData={loadData}
                    rowComponent={rowComponent}
                    rows={rows}
                    width={containerWidth}
                    accountId={accountId}
                />
                <Loader
                    size={50}
                    state={isLoading}
                    thickness={3}
                />
                <ActionMenu
                    anchorElLeftPosition={actionMenuX}
                    anchorElTopPosition={actionMenuY}
                    dense
                    items={actionMenuItems}
                    onClickActionMenuItem={this.handleClickActionMenuItem}
                    onClose={this.handleCloseActionMenu}
                    open={isOpenActionMenu}
                    row={actionMenuRecord}
                />
                <RowDetail
                    anchorElLeftPosition={detailRowX}
                    anchorElTopPosition={detailRowY}
                    detailRowFields={detailRowFields}
                    onClose={this.handleCloseDetailRow}
                    open={isOpenDetailRow}
                    row={detailRecord}
                />
                <div
                    className={classNames(classes.overlay, {
                        [classes.hide]: !isLoading,
                    })}
                />
            </div>
        );
    }
}

export default withStyles(styles)(AdvancedMobileGrid);
