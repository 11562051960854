/*
 *
 *  Agreed estimation cost form 14720
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';

import { AGREED_ESTIMATION_COST } from "domain/Data/RequestStatus/constants";
import { formField } from "domain/typeConstants/form";
import { TENANT_AGREED_ESTIMATION_COST_FORM } from "pages/Requests/TenantView/constants";


export class TenantAgreedEstimationCostForm14720 extends React.PureComponent {
    getFields() {
        return [
            {
                name: 'status',
                type: 'select',
                label: 'Статус',
                entityName: 'requestStatuses',
                visible: () => false,
                calculatedValue: () => AGREED_ESTIMATION_COST,
            },
            {
                name: 'number',
                label: '№',
                visible: true,
                disabled: true,
            },
            {
                name: 'visitDate',
                type: 'date',
                label: 'Дата',
                visible: true,
                disabled: true,
            },
            {
                name: 'whatHappened',
                label: 'Что нужно сделать',
                visible: true,
                disabled: true,
            },
            {
                name: 'estimationCostScan',
                label: 'Скан сметы',
                type: formField.FILE,
                required: true,
                visible: true,
            }
        ];
    }

    render() {
        return (
            <AdvancedForm
                name={TENANT_AGREED_ESTIMATION_COST_FORM}
                entityName="requests"
                fields={this.getFields()}
                {...this.props}
            />
        );
    }
}
