/**
 *
 * DocumentMatchingFormHeader sagas
 *
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { change } from 'redux-form/immutable';

import { makeSelectEntityId } from 'containers/AdvancedForm/selectors';
import * as api from 'utils/api';

import { documentDetailsFound, documentDetailsError } from './actions';
import { COPY_DOCUMENT_DETAILS, FIND_DOCUMENT_DETAILS } from './constants';

export default function* rootSaga() {
  yield takeLatest(COPY_DOCUMENT_DETAILS, copyDocumentDetailsSaga);
  yield takeLatest(FIND_DOCUMENT_DETAILS, findDocumentDetailsSaga);
}

function* copyDocumentDetailsSaga({ meta, payload }) {
  yield put(change(meta.advancedForm, 'documentDetails', payload.documentDetails));
}

function* findDocumentDetailsSaga({ meta }) {
  try {
    const entityId = yield select(makeSelectEntityId(meta.advancedForm));
    const response = yield call(api.getDocumentDetails, entityId);

    const visitorFullName = response.data && response.data.visitorFullName;
    const documentDetails = response.data && response.data.documentDetails;
    const requestNumber = response.data && response.data.requestNumber;
    const visitDate = response.data && response.data.visitDate;

    yield put(documentDetailsFound(visitorFullName, documentDetails, requestNumber, visitDate));
  } catch (err) {
    yield put(documentDetailsError(err));
  }
}
