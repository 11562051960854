/*
 *
 * RequestsPageContainer14691
 *
 */

import React from 'react';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import RequestsPage14691 from './RequestsPage14691';
import saga from './sagas';

function RequestsPageContainer14691(props) {
  return (
    <RequestsPage14691 {...props} />
  );
}

const withSaga = injectSaga({ key: 'receptionPage14691', saga });

export default compose(
  withSaga,
)(RequestsPageContainer14691);
