/**
*
* DataTableBody
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import Attachment from '@material-ui/icons/Attachment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import ToolsTableBodyCell from './ToolsTableBodyCell';
import DateTimeTableCell from './DateTimeTableCell';
import LabeledTableCell from './LabeledTableCell';
import DetailContainer from 'containers/AdvancedDataTable/DetailContainer';


const styleSheet = {
  bodyTable: {
    width: "100%",
    display: "table",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    borderSpacing: 0,
    borderCollapse: "collapse",
    overflow: "hidden",
    tableLayout: "fixed"
  },
  cell: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: 4,
    paddingRight: 8,
  },
  row: {
    cursor: "pointer",
  },
  rowSelected: {
    backgroundColor: "#f5f5f5",
  },
  currentRecord: {
    backgroundColor: '#cfd8dc',
  },
  expandedRecord: {
    borderLeft: '4px solid grey'
  },
  iconButton: {
    width: 32,
    height: 32,
  },
  chip: {
    marginRight: 5,
    marginTop: 5,
  }
};

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


class DataTableBody extends React.PureComponent {

  getValueFromRecord(path, record) {
    if (Array.isArray(path)) {
      return path.reduce(
        (acc, property) => acc != null ? acc[property] : null,
        record
      );
    }

    return record[path];
  }

  isSelected = (selection, record) => selection.findIndex(selectionRecord => selectionRecord.id === record.id) !== -1

  isShowActionMenu = () => this.props.actionMenuItems.length > 0;

  cellClickHandler = (record) => {
    const { onRowClick } = this.props;
    if (onRowClick) onRowClick(record);
  }

  checkboxClickHandler = (record) => {
    const { onSelectRowClick } = this.props;
    if (onSelectRowClick) onSelectRowClick(record);
  }

  // для нескольких раскрытий
  // хранилище не подходит, пока  убрано
  // expandedRowClickHandler = (record) => {
  //   const { expandedRowIds, onExpandedRowIdsChange } = this.props
  //   const newExpandedRowIds = [...expandedRowIds]
  //   if (expandedRowIds.includes(record.id)) {
  //     const recordIdx = newExpandedRowIds.indexOf(record.id);
  //     newExpandedRowIds.splice(recordIdx, 1)
  //   } else newExpandedRowIds.push(record.id)
  //   if (onExpandedRowIdsChange) onExpandedRowIdsChange(newExpandedRowIds)
  // }

  // раскрывает/закрывает одну запись
  expandedRowClickHandler = (record) => {
    const { expandedRowIds, onExpandedRowIdsChange } = this.props
    let newExpandedRowIds;
    if (expandedRowIds.includes(record.id)) {
      newExpandedRowIds = []
    } else newExpandedRowIds = [record.id]
    if (onExpandedRowIdsChange) onExpandedRowIdsChange(newExpandedRowIds)
  }

  openDrawerClickHandler = (record) => {
    const { onOpenDrawer } = this.props
    if (onOpenDrawer) onOpenDrawer(record)
  }

  rowDoubleClickHandler = (record) => {
    const { onRowDoubleClick } = this.props;
    if (onRowDoubleClick) onRowDoubleClick(record);
  }

  createActionMenuItemClickHandler = (record) => (actionName) => {
    const { onActionMenuItemClick } = this.props;
    if (onActionMenuItemClick) onActionMenuItemClick(actionName, record);
  }

  handleClickContextMenu = (event, record) => {
    const { onRowContextMenuClick } = this.props
    if (onRowContextMenuClick) onRowContextMenuClick(event, record);
  };

  computeStyles = (styles, record) => {
    if (!styles) return null;

    return styles.reduce((result, item) => {
      const isActive = item.condition ? item.condition(record) : {};
      return isActive ? { ...result, ...item.style } : result;
    }, {});
  }

  renderCell(column, record) {
    const { classes, downloadFile } = this.props
    const style = this.computeStyles(column.styles, record);
    const value = column.getCellValue ? column.getCellValue(record) :
      this.getValueFromRecord(column.path, record)

    if (column.labels) {
      return (
        <LabeledTableCell
          key={column.id}
          views={column.labels}
          onClick={() => this.cellClickHandler(record)}
          onContextMenu={(event) => this.handleClickContextMenu(event, record)}
        >
          {value}
        </LabeledTableCell>
      );
    }

    switch (column.type) {
      case 'datetime':
      case 'date':
      case 'time':
        return (
          <DateTimeTableCell
            key={column.id}
            className={classes.cell}
            style={style}
            type={column.type}
            onClick={() => this.cellClickHandler(record)}
            onContextMenu={(event) => this.handleClickContextMenu(event, record)}
          >
            {value}
          </DateTimeTableCell>
        );

      case 'boolean':
        return (
          <TableCell
            key={column.id}
            className={classes.cell}
            style={style}
            padding="dense"
            onClick={() => this.cellClickHandler(record)}
            onContextMenu={(event) => this.handleClickContextMenu(event, record)}
          >
            {value ? 'Да' : 'Нет'}
          </TableCell>
        );

      case 'format_text':
        return (
          <TableCell
            key={column.id}
            className={classes.cell}
            dangerouslySetInnerHTML={{ __html: value }}
            onClick={() => this.cellClickHandler(record)}
            onContextMenu={(event) => this.handleClickContextMenu(event, record)}
          />
        );

      case 'file':
        return (<TableCell
          key={column.id}
          className={classes.cell}
          onClick={() => this.cellClickHandler(record)}
          onContextMenu={(event) => this.handleClickContextMenu(event, record)}
        >
          {value &&
            <LightTooltip title={record[column.path[0]].name}>
              <IconButton
                className={classes.iconButton}
                onClick={() => downloadFile(value)}
              >
                <Attachment />
              </IconButton>
            </LightTooltip>
          }
        </TableCell>
        )

      case 'chip':
        return (<TableCell
          key={column.id}
          className={classes.cell}
          onClick={() => this.cellClickHandler(record)}
          onContextMenu={(event) => this.handleClickContextMenu(event, record)}
        >
          {
            value && value.trim().split(";").map((item, idx) => {
              if (item.length > 0) {
                return (
                  <Chip
                    key={idx}
                    label={item}
                    color="primary"
                    variant="outlined"
                    className={classes.chip}
                  />
                )
              }
            })
          }
        </TableCell>
        )


      default:
        return (
          <TableCell
            key={column.id}
            className={classes.cell}
            style={style}
            padding="dense"
            numeric={column.type === 'numeric'}
            onClick={() => this.cellClickHandler(record)}
            onContextMenu={(event) => this.handleClickContextMenu(event, record)}
          >
            {value}
          </TableCell>
        );
    }
  }

  prepareData = () => {
    const { data, tableType, pageSize, pageNumber } = this.props
    return data && tableType == 'journal'
      ? data.slice((pageNumber - 1) * pageSize, (pageNumber - 1) * pageSize + pageSize)
      : data.some(r => r === undefined) ? [] : data
  }


  render() {
    const {
      dataTableName,
      selectable,
      classes,
      actionMenuItems,
      columns,
      columnOrder,
      columnWidths,
      selection,
      width,
      toolWidth,
      expandedRowIds,
      detailContainer,
      loaderState,
      currentRecordId,
      onMenuItemChange,
      isActionMenu,
      isTableRowDetail,
      isFilePreview,
      columnVisibilityNames,
      tab,
      changeTab,
      containerWidth,
      lastColumnWidth,
      filePreviewFieldName,
    } = this.props;

    const rows = this.prepareData()

    return (
      { columns } ?
        <Table className={classes.bodyTable} style={{ width: width + lastColumnWidth }}>
          <colgroup>
            <col key={"tools"} style={{ width: toolWidth }}></col>
            {columnOrder.map(o => {
              const column = columnWidths.find(col => col.columnName === o && !columnVisibilityNames.includes(col.columnName))
              return column && <col key={column.columnName} style={{ width: column.width }}></col>
            })}
            {lastColumnWidth > 0 && <col key={"last_col"} style={{ width: `${lastColumnWidth}px` }}></col>}
          </colgroup>

          {rows?.length > 0 ? <TableBody>
            {
              rows.map((record) => {
                const isSelected = this.isSelected(selection, record);
                return (
                  <React.Fragment key={record.id}>
                    <TableRow
                      hover
                      onDoubleClick={() => this.rowDoubleClickHandler(record)}
                      className={classNames(classes.row,
                        {
                          [classes.rowSelected]: isSelected,
                          [classes.currentRecord]: record.id === currentRecordId,
                          [classes.expandedRecord]: expandedRowIds && expandedRowIds.includes(record.id),
                        })}
                      tabIndex="-1"
                      key={record.id}
                    >
                      <ToolsTableBodyCell
                        selectable={selectable}
                        checked={isSelected}
                        actionMenuItems={actionMenuItems}
                        onActionMenuItemClick={this.createActionMenuItemClickHandler(record)}
                        onCheckboxClick={() => this.checkboxClickHandler(record)}
                        onExpandedRowClick={() => this.expandedRowClickHandler(record)}
                        onOpenDrawer={() => this.openDrawerClickHandler(record)}
                        onMenuItemChange={onMenuItemChange}
                        expandedRowIds={expandedRowIds}
                        record={record}
                        isActionMenu={isActionMenu}
                        isTableRowDetail={isTableRowDetail}
                        isFilePreview={isFilePreview}
                        filePreviewFieldName={filePreviewFieldName}
                      />
                      {
                        columnOrder.map(o => {
                          const column = columns.find(col => col.name === o && !columnVisibilityNames.includes(col.name))
                          if (column) { return this.renderCell(column, record) }
                        })
                      }
                      {lastColumnWidth > 0 && <TableCell></TableCell>}
                    </TableRow>

                    {expandedRowIds && expandedRowIds.includes(record.id) &&
                      <DetailContainer
                        dataTableName={dataTableName}
                        record={record}
                        detailContainer={detailContainer}
                        colspan={columnOrder.length}
                        tab={tab}
                        changeTab={changeTab}
                        containerWidth={containerWidth}
                      />}
                  </React.Fragment>
                );
              })
            }
          </TableBody >
            :
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={columnOrder.length + 2}
                  style={{ textAlign: "left", position: "relative", height: "80px" }}>
                  {!loaderState && <big>Нет данных</big>}
                </TableCell>
              </TableRow>
            </TableBody>
          }
        </Table >
        : <></>
    );
  }
}

DataTableBody.propTypes = {
  selectable: PropTypes.bool,
  actionMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ),
  classes: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string,
      labels: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.arrayOf(PropTypes.object),
  onMenuItemChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onSelectRowClick: PropTypes.func,
  onActionMenuItemClick: PropTypes.func,
};

export default withStyles(styleSheet)(DataTableBody);
