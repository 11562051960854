/**
*
* ChangePasswordPage
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import withPublicRoot from 'containers/App/withPublicRoot';
import ChangePasswordFormSheet from './ChangePasswordFormSheet';

const styleSheet = () => ({
  changePasswordFormSheetContainer: {
    minHeight: 520,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  changePasswordFormSheet: {
    width: 400,
  },
});

function ChangePasswordPage({ classes, nextPathAfterLogin }) {
  return (
    <div className={classes.changePasswordFormSheetContainer}>
      <ChangePasswordFormSheet
        className={classes.changePasswordFormSheet}
        nextPathAfterLogin={nextPathAfterLogin}
      />
    </div>
  );
}

ChangePasswordPage.propTypes = {
  classes: PropTypes.object,
  nextPathAfterLogin: PropTypes.string,
};

export default withPublicRoot(
  withStyles(styleSheet)(ChangePasswordPage)
);
