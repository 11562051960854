/**
 *
 * DocumentPositions constants
 *
 */

export const EXECUTE_ADD_ACTION = 'claris-vnext/Positions/PositionsView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/Positions/PositionsView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/Positions/PositionsView/EXECUTE_VIEW_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/Positions/PositionsView/EXECUTE_COPY_ACTION';

export const DOCUMENT_POSITION_GRID = 'DocumentPositionGrid';
export const DOCUMENT_POSITION_FORM_DIALOG = 'DocumentPositionFormDialog';
export const DOCUMENT_POSITION_FORM = 'DocumentPositionForm';
