/*
 *
 * ApplicantFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { APPLICANT_REQUESTS_FORM, APPLICANT_REQUESTS_FORM_DIALOG } from './constants';

function ApplicantFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={APPLICANT_REQUESTS_FORM_DIALOG}
      formName={APPLICANT_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
    />
  );
}

ApplicantFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ApplicantFormDialog;
