/**
 *
 * SightingView constants
 *
 */

export const SIGHTING_AGREEMENT_FORM_DIALOG = 'SightingAgreementFormDialog';
export const SIGHTING_DISAGREEMENT_FORM_DIALOG = 'SightingDisagreementFormDialog';
export const SIGHTING_DOCUMENTS_DATA_TABLE = 'SightingDocumentsDataTable';
export const SIGHTING_DOCUMENTS_DX_GRID = 'SightingDocumentsDxGrid';
export const SIGHTING_DOCUMENTS_FORM = 'SightingDocumentsForm';
export const SIGHTING_DOCUMENTS_FORM_DIALOG = 'SightingDocumentsFormDialog';

export const EXECUTE_AGREE_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_AGREE_ACTION';
// export const EXECUTE_PRINT_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_PRINT_ACTION';
export const EXECUTE_DISAGREE_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_DISAGREE_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ADD_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_EDIT_ACTION';
//export const EXECUTE_REPORT_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_REPORT_ACTION';

export const EXECUTE_AGREE_DIALOG_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_AGREE_DIALOG_ACTION';
export const EXECUTE_DISAGREE_DIALOG_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_DISAGREE_DIALOG_ACTION';
export const EXECUTE_RUN_DIALOG_ACTION = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_RUN_DIALOG_ACTION';

// export const EXECUTE_TASK_ADD = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_TASK_ADD';
// export const EXECUTE_TASK_EDIT = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_TASK_EDIT';
// export const EXECUTE_TASK_COPY = 'claris-vnext/DocumentsPage/SightingView/EXECUTE_TASK_COPY';
