/*
 *
 * createFileFieldContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field } from 'redux-form/immutable';
import * as fileActionCreators from 'domain/Data/File/actions';
import { formField } from 'domain/typeConstants/form';

import FileField from './FileField';
import MultipleFileField from './MultipleFileField';

import * as actionCreators from './actions';
import makeSelectField, {
  makeSelectFile,
  makeSelectMultipleFile,
} from './selectors';

function createFileFieldContainer(formName, fieldName) {
  class FileFieldContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    static propTypes = {
      field: PropTypes.object.isRequired,
      files: PropTypes.array,
      file: PropTypes.object,
    };

    render() {
      const { clearErrorsOnChange, file, files, ...restProps } = this.props; // eslint-disable-line no-unused-vars

      if (this.props.field.type === formField.MULTIPLE_FILE) {
        return (
          <Field
            {...restProps}
            files={files}
            component={MultipleFileField}
          />
        );
      }

      return (
        <Field
          {...restProps}
          file={file}
          component={FileField}
        />
      );
    }
  }

  FileFieldContainer.propTypes = {
    clearErrorsOnChange: PropTypes.bool,
  };

  const mapStateToProps = createStructuredSelector({
    field: makeSelectField(formName, fieldName),
    file: makeSelectFile(formName, fieldName),
    files: makeSelectMultipleFile(formName, fieldName),
  });

  const mapDispatchToProps = (dispatch) => ({
    downloadFile: (fileId) => dispatch(fileActionCreators.downloadFile(fileId)),
    saveFile: (file, fieldType) => dispatch(fileActionCreators.saveFile(file, { advancedForm: formName, field: fieldName, fieldType })),
    clear: (fieldType, fileId) => dispatch(actionCreators.clear(formName, fieldName, fieldType, fileId)),
    showErrorMessageFile: (alertTitle, alertContent) => dispatch(actionCreators.showErrorMessageFile(alertTitle, alertContent)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(FileFieldContainer);
}

export default createFileFieldContainer;
