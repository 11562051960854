/*
 *
 * ServiceRequestsManagerDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { requestAction } from 'domain/typeConstants/action';
import { makeSelectRoles } from 'domain/User/selectors';
import * as actionCreators from './actions';

class ServiceRequestsManagerDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => this.props.executeEditAction(record.id);

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case requestAction.ADD:
        this.props.executeAddAction();
        break;

      case requestAction.VIEW:
        this.props.executeViewAction(ids[0]);
        break;

      case requestAction.EDIT:
        this.props.executeEditAction(ids[0]);
        break;

      case requestAction.COPY:
        this.props.executeCopyAction(ids[0]);
        break;

      case requestAction.ACCEPT:
        this.props.executeAcceptAction(ids);
        break;

      case requestAction.REJECT:
        this.props.executeRejectAction(ids);
        break;

      case requestAction.COMPLETE:
        this.props.executeCompleteAction(ids[0]);
        break;

      case requestAction.SEND_TO_AGREEMENT_BY_TENANT:
        this.props.executeSendToAgreementByTenantAction(ids);
        break;

      case requestAction.SEND_EMAIL:
        this.props.executeSendEmailAction(ids);
        break;

      case requestAction.SEND_TO_AGREEMENT:
        this.props.executeSendToAgreementAction(ids[0]);
        break;

      case requestAction.ARRIVED:
        this.props.executeArrivedAction(ids);
        break;

      case requestAction.DEPARTED:
        this.props.executeDepartedAction(ids);
        break;

      case requestAction.ADD_TO_BLACK_LIST:
        this.props.executeAddBlackListItem(ids);
        break;

      case requestAction.CLOSE:
        this.props.executeCloseAction(ids);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName, roles } = this.props;
    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
      roles,
    });
  }
}

ServiceRequestsManagerDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  executeAddAction: PropTypes.func.isRequired,
  executeEditAction: PropTypes.func.isRequired,
  executeViewAction: PropTypes.func.isRequired,
  executeCopyAction: PropTypes.func.isRequired,
  executeAcceptAction: PropTypes.func.isRequired,
  executeRejectAction: PropTypes.func.isRequired,
  executeCompleteAction: PropTypes.func.isRequired,
  executeSendToAgreementByTenantAction: PropTypes.func.isRequired,
  executeSendEmailAction: PropTypes.func.isRequired,
  executeSendToAgreementAction: PropTypes.func.isRequired,
  executeArrivedAction: PropTypes.func.isRequired,
  executeDepartedAction: PropTypes.func.isRequired,
  executeAddBlackListItem: PropTypes.func.isRequired,
  executeCloseAction: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
});


function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestsManagerDxGridContainer);
