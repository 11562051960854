/**
 *
 * AppBarContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as userActionCreators from 'domain/User/actions';
import {
  makeSelectAccountName,
  makeSelectIsAdmin,
  makeSelectUsername,
} from 'domain/User/selectors';
import { makeSelectBreakpoint } from 'domain/Screen/selectors';
import * as navigationDrawerActionCreators from 'containers/NavigationDrawer/actions';
import { makeSelectContainerWidth, makeSelectIsOpen } from 'containers/NavigationDrawer/selectors';
import * as changeRoleDialogActionCreators from 'containers/ChangeRoleDialog/actions';
import AppBar from './AppBar';

function AppBarContainer({ accountName, breakpoint, isAdmin, navigationDrawerIsOpen,
  logout, openChangeRoleDialog, openNavigationDrawer, username, containerWidth, children }) {
  return (
    <AppBar
      accountName={accountName}
      breakpoint={breakpoint}
      isAdmin={isAdmin}
      navigationDrawerIsOpen={navigationDrawerIsOpen}
      onLogout={logout}
      onOpenChangeRoleDialog={openChangeRoleDialog}
      onOpenNavigationDrawer={openNavigationDrawer}
      username={username}
      containerWidth={containerWidth}
    >
      {children}
    </AppBar>
  );
}

AppBarContainer.propTypes = {
  accountName: PropTypes.string,
  breakpoint: PropTypes.object.isRequired,
  children: PropTypes.node,
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func,
  navigationDrawerIsOpen: PropTypes.bool.isRequired,
  openChangeRoleDialog: PropTypes.func.isRequired,
  openNavigationDrawer: PropTypes.func.isRequired,
  username: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  accountName: makeSelectAccountName(),
  breakpoint: makeSelectBreakpoint(),
  isAdmin: makeSelectIsAdmin(),
  navigationDrawerIsOpen: makeSelectIsOpen(),
  username: makeSelectUsername(),
  containerWidth: makeSelectContainerWidth(),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(userActionCreators, dispatch),
  ...bindActionCreators(navigationDrawerActionCreators, dispatch),
  ...bindActionCreators(changeRoleDialogActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBarContainer);
