/**
 *
 * createDateFieldContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form/immutable';
import DateTimeField from './DateTimeField';
import * as advancedFormActionCreators from '../actions';

function createDateTimeFieldContainer(formName) {
  class DateTimeFieldContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    static contextTypes = {
      advancedForm: PropTypes.string.isRequired,
    };
    static propTypes = {
      calculateFieldsValues: PropTypes.func.isRequired,
    };

    handleChange = () => { setTimeout(() => this.props.calculateFieldsValues(), 0); };

    normalize = (value) => (value !== null ? value : null);

    render() {
      const {
        calculateFieldsValues,
        ...restProps
      } = this.props;

      return (
        <Field
          {...restProps}
          normalize={this.normalize}
          onChange={this.handleChange}
          component={DateTimeField}
        />
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
  });

  return connect(null, mapDispatchToProps)(DateTimeFieldContainer);
}

export default createDateTimeFieldContainer;
