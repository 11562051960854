import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

const styles = () => ({
  toolbar: {
    alignItems: 'center',
    background: '#E0E0E0',
    width: '100%',
  },
  content: {
    alignItems: 'center',
    width: '100%',
    overflow: "auto",
  },
  img: {
    margin: "auto",
    display: "block",
  }
});

class ImageViewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
    };
  }

  componentWillUnmount() {
    const { clearDownloadLink } = this.props.data;
    if (clearDownloadLink) clearDownloadLink();
  }

  handleZoomIn = () => {
    const { scale } = this.state;
    if (scale >= 5) return;
    this.setState({ scale: Number((scale + 0.2).toFixed(1)) });
  };

  handleZoomOut = () => {
    const { scale } = this.state;
    if (scale <= 0.2) return;
    this.setState({ scale: Number((scale - 0.2).toFixed(1)) });
  };

  render() {
    const { classes, styles, data } = this.props;
    const { scale } = this.state;

    let computedScale = null;
    if (((scale * 100) % 10) === 0) {
      computedScale = `${(scale * 100)}%`;
    } else if (((scale * 100) % 10) < 1) {
      computedScale = `${Math.round(scale * 100)}%`;
    } else {
      computedScale = `${Math.ceil(scale * 100)}%`;
    }

    return (
      <div style={styles}>
        <Grid container className={classes.toolbar}>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={3} className={classes.center}>
            <IconButton
              aria-label="ZoomOut"
              onClick={this.handleZoomOut}
            >
              <RemoveCircleOutline />
            </IconButton>
            <span className={classes.number}>
              {computedScale}
            </span>
            <IconButton
              aria-label="ZoomIn"
              onClick={this.handleZoomIn}
            >
              <AddCircleOutline />
            </IconButton>
          </Grid>
        </Grid>

        <Grid className={classes.content}>
          {data.value &&
            <img src={data.value}
              className={classes.img}
              style={{ width: computedScale }}
            />}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ImageViewer);