/*
 *
 * Scanner actions
 *
 */

import {
  CONNECT_TO_SERVICE,
  DISCONNECT_FROM_SERVICE,
  SCAN_PASSPORT,
} from './constants';

export function connectToService(user) {
  return {
    type: CONNECT_TO_SERVICE,
    payload: {
      user,
    },
  };
}

export function disconnectFromService() {
  return {
    type: DISCONNECT_FROM_SERVICE,
  };
}

export function scanPassport(data) {
  return {
    type: SCAN_PASSPORT,
    payload: { data },
  };
}
