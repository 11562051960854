/**
 *
 * ApplicantForm Requests Form 15776
 *
 */


import { ApplicantForm } from "pages/Requests/ApplicantView";
import { ASSIGNEE_USER } from "../common/constants";
import { SERVICE } from "domain/Data/RequestType/constants";


export class ApplicantForm15776 extends ApplicantForm {

    getFields() {

        const fields = super.getFields();

        const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');

        fields[assigneeIndex].initialValue = (values, mode) => mode === 'add' && values.type === SERVICE ? ASSIGNEE_USER : null
        fields[assigneeIndex].visible = true
        fields[assigneeIndex].calculatedValue = (values, mode) => {
            if (mode === 'copy') return null;
            if (mode === 'add' && values.type === SERVICE && values.assignee === null) return ASSIGNEE_USER
            return values.assignee;
        }
        fields[assigneeIndex].visibleOptions = (currentValues, initialValue, mode, user, entities) => {
            const assignee = [];
            entities.get('employees').map((item) => {
                if (item.isPerformer) assignee.push(item.id);
            });
            return assignee;
        }

        return fields;
    }
}
