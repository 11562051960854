/**
 *
 * ReceptionView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function ReceptionView({
  agreementDialog,
  blackListFormDialog,
  dataTable,
  formDialog,
  rejectFormDialog,
  viewName,
  viewStyles,
  visitorArrivalFormDialog,
  workformDialog,
}) {
  return (
    <div style={viewStyles.root}>
      {
        <Paper style={viewStyles.dataTable}>
          {
            React.cloneElement(dataTable, { viewName })
          }
        </Paper>
      }
      { formDialog}
      { workformDialog}
      { rejectFormDialog}
      { visitorArrivalFormDialog}
      { blackListFormDialog}
      { agreementDialog}
    </div>
  );
}

ReceptionView.propTypes = {
  agreementDialog: PropTypes.node,
  blackListFormDialog: PropTypes.node.isRequired,
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  rejectFormDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
  visitorArrivalFormDialog: PropTypes.node.isRequired,
  workformDialog: PropTypes.node,
};

ReceptionView.defaultProps = {
  viewStyles: {},
};

export default ReceptionView;
