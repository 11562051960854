/*
 *
 * FieldProgress
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

function FieldProgress({ mode, value }) {
  return (
    <LinearProgress variant={mode} value={value} />
  );
}

FieldProgress.propTypes = {
  mode: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default FieldProgress;
