/*
 *
 * sagasConfig requestsPage14140
 *
 */

import receptionViewSaga from './ReceptionView/sagas';

const requestsPageSagasConfig14140 = {
  receptionViewSaga,
};

export default requestsPageSagasConfig14140;
