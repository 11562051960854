/**
 *
 * ServiceRequestsManagerForm12970
 *
 */
import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { SERVICE } from 'domain/Data/RequestType/constants';
import { CREATED, CLOSED, ACCEPTED, COMPLETED, REJECTED } from 'domain/Data/RequestStatus/constants';
import { formField } from "domain/typeConstants/form";

class ServiceRequestsManagerForm12970 extends ServiceRequestsManagerForm { // eslint-disable-line react/prefer-stateless-function

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        disabled: true,
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        visibleOptions: () => [COMPLETED, CLOSED, REJECTED, ACCEPTED, CREATED],
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return CREATED; }

          return values.status;
        },
        disabled: (currentValues, initialValues, mode) => !!((mode === 'view')),
        orderBy: 'name',
        required: true,
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        initialValue: () => SERVICE,
        visibleOptions: () => [SERVICE],
        orderBy: 'order',
        clearErrorsOnChange: true,
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        rowBreakBefore: true,
        required: true,
        visible: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'service',
        type: 'select',
        label: 'Сервис',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
        disabled: (currentValues, initialValues, mode) => !!((mode === 'view')),
      },
      {
        name: 'assignee',
        type: 'select',
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => {
            if (item.company === user.companyId && item.isPerformer) assignee.push(item.id);
          });

          return assignee;
        },
        disabled: (currentValues, initialValues, mode) => !!((mode === 'view')),
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        visible: true,
        rowBreakBefore: true,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
        disabled: (currentValues, initialValues, mode) => !!((mode === 'view')),
      },
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        visible: true,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
        disabled: (currentValues, initialValues, mode) => !!((mode === 'view')),
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: true,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
        disabled: (currentValues, initialValues, mode) => ((mode === 'view')),
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
        initialValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.note;
        },
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: true,
        rowBreakBefore: true,
      },
      {
        name: 'requestPriority',
        type: formField.SELECT,
        label: 'Приоритет',
        entityName: 'requestPriorities',
        visible: (values, initialValues, mode) => values.type === SERVICE && (mode === 'edit' || mode === 'add'),
        rowBreakBefore: true,
      },
    ];
  }
}

export default ServiceRequestsManagerForm12970;
