/*
 *
 * ServiceRequestsManagerView actions 14326
 *
 */

import {
  EXECUTE_REJECT_ACTION,
  REJECT_REQUESTS_SUCCESS,
  REJECT_REQUESTS_ERROR,
  REJECT_REQUESTS,
  EXECUTE_ACCEPT_ACTION,
  ACCEPT_REQUESTS_SUCCESS,
  ACCEPT_REQUESTS_ERROR,
  ACCEPT_REQUESTS,

  SEND_EMAIL,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
  KHIMKI_SERVICE_UPDATE_FORM_FIELD,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_SEND_TO_AGREMENT_ACTION,
  EXECUTE_SEND_TO_PERFORMER,
} from './constants';

export function executeSendToAgreementByTenantAction(dialogName) {
  return {
    type: EXECUTE_SEND_TO_AGREMENT_ACTION,
    meta: { dialogName },
  };
}

export function executeSendToPerformer(dialogName) {
  return {
    type: EXECUTE_SEND_TO_PERFORMER,
    meta: { dialogName },
  };
}

export function updateFormField(formName, field) {
  return {
    type: KHIMKI_SERVICE_UPDATE_FORM_FIELD,
    meta: { formName },
    payload: { field },
  };
}

export function executeAcceptAction(dialogName) {
  return {
    type: EXECUTE_ACCEPT_ACTION,
    meta: { dialogName },
  };
}

export function acceptServiceRequests() {
  return {
    type: ACCEPT_REQUESTS,
  };
}

export function serviceRequestsAccepted() {
  return {
    type: ACCEPT_REQUESTS_SUCCESS,
  };
}

export function serviceRequestsAcceptingError(error) {
  return {
    type: ACCEPT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function executeRejectAction(dialogName) {
  return {
    type: EXECUTE_REJECT_ACTION,
    meta: { dialogName },
  };
}

export function rejectServiceRequests() {
  return {
    type: REJECT_REQUESTS,
  };
}

export function serviceRequestsRejected() {
  return {
    type: REJECT_REQUESTS_SUCCESS,
  };
}

export function serviceRequestsRejectingError(error) {
  return {
    type: REJECT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function sendEmail(ids) {
  return {
    type: SEND_EMAIL,
    payload: { ids },
  };
}

export function sendedEmail() {
  return {
    type: SEND_EMAIL_SUCCESS,
  };
}

export function sendingEmailError(error) {
  return {
    type: SEND_EMAIL_ERROR,
    payload: { error },
  };
}

export function executeCloseAction(dialogName) {
  return {
    type: EXECUTE_CLOSE_ACTION,
    meta: { dialogName },
  };
}