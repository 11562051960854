/**
 *
 * DocumentMatchingFormHeader constants
 *
 */

export const COPY_DOCUMENT_DETAILS = 'claris-vnext/RequestsPage/DocumentMatchingFormHeader/COPY_DOCUMENT_DETAILS';

export const FIND_DOCUMENT_DETAILS = 'claris-vnext/RequestsPage/DocumentMatchingFormHeader/FIND_DOCUMENT_DETAILS';
export const FIND_DOCUMENT_DETAILS_SUCCESS = 'claris-vnext/RequestsPage/DocumentMatchingFormHeader/FIND_DOCUMENT_DETAILS_SUCCESS';
export const FIND_DOCUMENT_DETAILS_ERROR = 'claris-vnext/RequestsPage/DocumentMatchingFormHeader/FIND_DOCUMENT_DETAILS_ERROR';
