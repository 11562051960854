/*
 *
 * Dashboard reducer
 *
 */

import { Map } from 'immutable';
import { SAVE_DATA, SET_FILTER, SAVE_RANGE } from './constants';

const initialState = new Map({});

function dashboardReducer(state = initialState, action) {

    switch (action.type) {

        case SAVE_DATA:
            return state.setIn([action.payload.entityName, 'data'], action.payload.data);

        case SAVE_RANGE:
            return state.setIn([action.payload.entityName, 'range'], action.payload.data);

        case SET_FILTER:
            return state.setIn(['filter', action.payload.accessKey], action.payload.data);

        default:
            return state;
    }
}

export default dashboardReducer;
