/*
 *
 * ReceptionRejectForm14326
 *
 */

import ReceptionRejectForm from 'pages/Requests/ReceptionView/ReceptionRejectForm';

export class ReceptionRejectForm14326 extends ReceptionRejectForm {
  getFields() {
    const fields = super.getFields();

    const managementCompanyCommentIndex = fields.findIndex(({ name }) => name === 'managementCompanyComment');
    fields[managementCompanyCommentIndex].required = true;
    fields[managementCompanyCommentIndex].label = 'Комментарий Администрации ХБП';

    return fields;
  }
}

export default ReceptionRejectForm14326;
