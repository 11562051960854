/**
 *
 * AgreementDialog constants
 *
 */

export const OPEN_AGREEMENT_DIALOG = 'claris-vnext/DocumentsPage/AgreementDialog/OPEN_AGREEMENT_DIALOG';
export const SUBMIT_AGREEMENT_DIALOG = 'claris-vnext/DocumentsPage/AgreementDialog/ACCEPT_AGREEMENT_DIALOG';
export const CLOSE_AGREEMENT_DIALOG = 'claris-vnext/DocumentsPage/AgreementDialog/CLOSE_AGREEMENT_DIALOG';

export const DIALOG_NAME = 'documentsAgreementDialog';
