/**
 *
 * SupplierInvoiceFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { SUPPLIER_INVOICE_FORM, SUPPLIER_INVOICE_FORM_DIALOG } from './constants';

function SupplierInvoiceFormDialog({ form, detailContainer }) {
  return (
    <AdvancedFormDialog
      name={SUPPLIER_INVOICE_FORM_DIALOG}
      formName={SUPPLIER_INVOICE_FORM}
      advancedForm={form}
      detailContainer={detailContainer}
      viewModeTitle="Просмотр счета"
      maxWidth="lg"
    />
  );
}

SupplierInvoiceFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default SupplierInvoiceFormDialog;
