/**
 *
 * AdvancedMobileGrid state selectors
 *
 */

import {denormalize} from 'normalizr';
import {createSelector} from 'reselect';

import {mapEntityNameToSchema} from 'domain/Data/utils';
import * as dataSelectors from 'domain/Data/selectors';
import {makeSelectCurrentRecord} from "../AdvancedDataTable/selectors";

const makeSelectDomain = () => (state) => state.get('advancedMobileGrid');

const makeSelectAdvancedMobileGrid = (mobileGridName) => createSelector(
    makeSelectDomain(),
    (domainState) => domainState.get(mobileGridName),
);

const makeSelectEntityName = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.getIn(['data', 'entityName'])
);

const makeSelectEntitiesIds = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.getIn(['data', 'ids']).toJS(),
);

const makeSelectData = (mobileGridName) => createSelector(
    makeSelectEntityName(mobileGridName),
    makeSelectEntitiesIds(mobileGridName),
    dataSelectors.makeSelectEntities(),
    (entityName, ids, entities) => selectData(entityName, ids, entities),
);

function selectData(entityName, ids, entities) {
    const denormalizedData = denormalize(
        {
            [entityName]: ids,
        },
        {
            [entityName]: [mapEntityNameToSchema(entityName)],
        },
        entities,
    );

    return Object.values(denormalizedData[entityName]);
}

const makeSelectLazyLoadingBlockSize = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('lazyLoadingBlockSize'),
);

const makeSelectSelection = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState ?
        mobileGridState.get('selection').toJS() : null,
);

const makeSelectMobileCurrentRecord = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState ? mobileGridState.get('currentRecord') : null,
);

const makeSelectSorting = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('sorting').toJS(),
);

const makeSelectMenuItem = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState ?
        mobileGridState.get('itemMenuRecord').toJS() : null,
);

const makeSelectFilters = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('filters').toJS(),
);

const makeSelectSearchQuery = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('searchQuery'),
);

const makeSelectGridFilters = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('gridFilters'),
);

const makeSelectLimit = (mobileGridName) => createSelector( // eslint-disable-line
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('limit'),
);

const makeSelectIsLoading = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('isLoading'),
);

const makeSelectIsListOver = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    (mobileGridState) => mobileGridState.get('isListOver'),
);

const makeSelectActionButtons = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    makeSelectEntityName(mobileGridName),
    makeSelectSelection(mobileGridName),
    dataSelectors.makeSelectEntities(),
    (mobileGridState, entityName, selection, entities) => mobileGridState.get('actionButtons')
        .map((action) => calculateActionState(action, entityName, selection, entities)).toJS(),
);

const makeSelectActionMenuItems = (mobileGridName) => createSelector(
    makeSelectAdvancedMobileGrid(mobileGridName),
    makeSelectEntityName(mobileGridName),
    makeSelectMenuItem(mobileGridName),
    dataSelectors.makeSelectEntities(),
    (mobileGridState, entityName, menuItem, entities) => mobileGridState.get('actionMenuItems')
        .map((action) => calculateActionStateForMenuItems(action, entityName, menuItem, entities)).toJS(),
);

function calculateActionState(action, entityName, selection, entities) {
    const {disabled, ...restProps} = action;
    const selectedEntities = selection && selection.length > 0 ?
        selectData(entityName, selection, entities) : [];

    const isDisabled = disabled === true ||
        (typeof disabled === 'function' && disabled(selection, selectedEntities));

    return {
        ...restProps,
        disabled: isDisabled,
    };
}

function calculateActionStateForMenuItems(action, entityName, itemMenuRecord, entities) {
    const {hidden, ...restProps} = action;
    const selectedEntities = itemMenuRecord && itemMenuRecord.length > 0 ?
        selectData(entityName, itemMenuRecord, entities) : [];

    const isHidden = hidden === true ||
        (typeof hidden === 'function' && hidden(itemMenuRecord, selectedEntities));

    return {
        ...restProps,
        hidden: isHidden,
    };
}

const makeSelectTotalCount = (mobileGridName) => createSelector(
    makeSelectEntityName(mobileGridName),
    dataSelectors.makeSelectData(),
    (entityName, dataState) => dataState.getIn(['meta', entityName, 'totalCount'])
);

export default makeSelectDomain;
export {
    makeSelectActionButtons,
    makeSelectActionMenuItems,
    makeSelectData,
    makeSelectDomain,
    makeSelectEntitiesIds,
    makeSelectEntityName,
    makeSelectFilters,
    makeSelectGridFilters,
    makeSelectIsListOver,
    makeSelectIsLoading,
    makeSelectLazyLoadingBlockSize,
    makeSelectLimit,
    makeSelectMenuItem,
    makeSelectSearchQuery,
    makeSelectSelection,
    makeSelectSorting,
    makeSelectTotalCount,
    selectData,
    makeSelectMobileCurrentRecord,
};
