/**
 *
 * VisitorLogs PageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';

import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';

import VisitorLogsPage12970 from './VisitorLogsPage12970';

class VisitorLogsPageContainer12970 extends React.PureComponent {
  render() {
    const { accountId, userRoles, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, ...restProps };
    return (
      <VisitorLogsPage12970 {...props} />
    );
  }
}

VisitorLogsPageContainer12970.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
});

export default connect(mapStateToProps)(VisitorLogsPageContainer12970);
