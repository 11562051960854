/**
 *
 * ReceptionView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

function ReceptionView({
  formDialog,
  snackbar,
  viewName, // eslint-disable-line
}) {
  return (
    <div>
      { formDialog }
      { snackbar }
    </div>
  );
}

ReceptionView.propTypes = {
  snackbar: PropTypes.node,
  formDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
};

export default ReceptionView;
