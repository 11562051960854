/**
 *
 * Common fields for BC Lesnaya(14366)
 *
 */

import { UK_TSVETNOY, MELIORA_PLACE } from 'custom/14366/domain/Data/Departments/constants';
import * as Employee from 'custom/14366/domain/Data/Employees/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import {
  ON_CAR_ENTRY, ON_PASS_MAKING, ON_VISIT, SERVICE
} from 'domain/Data/RequestType/constants';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';


const departmentId = getValueFromLocalStorage(GLOBAL_USER_KEY)?.departmentId

export const department = {
  type: 'select',
  entityName: 'departments',
  name: 'department',
  label: 'Здание',
  visible: true,
  required: true,
  calculatedValue: (values, mode, entities) => {
    if (entities && !values.department) {
      const company = entities.get('companies').get(values.company);
      return company && company.department ? company.department : values.department;
    }
    return values.department;
  },
  visibleOptions: () => {
    return ['3959751378000', '4006045944000','4443829413000']
  },
  orderBy: 'name',
};

export const company = {
  cached: false,
  type: 'select',
  entityName: 'companies',
  name: 'company',
  label: 'Арендатор',
  visible: true,
  rowBreakBefore: true,
  initialValue: (values, mode, user) => user.companyId,
  numberInQueueFromEnd: 1,
};

export const companyByStatus =
{
  name: 'company',
  type: 'autocomplete',
  label: 'Арендатор',
  entityName: 'companies',
  orderBy: 'name',
  numberInQueueFromEnd: 1,
  initialValue: null,
  rowBreakAfter: (values) => values.type === ON_VISIT ||
    values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING ||
    values.type === SERVICE,
  required: true,
  filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
};

export const assignee = {
  name: 'assignee',
  type: 'select',
  label: 'Исполнитель',
  entityName: 'employees',
  orderBy: 'name',
  cached: false,
  visible: false,
  rowBreakAfter: (values) => values.type === SERVICE,
  calculatedValue: (currentValues) => {
    if (currentValues.type === SERVICE) {
      if (departmentId === UK_TSVETNOY) return Employee.UK_TSVETNOY_ASSIGNED
      if (departmentId === MELIORA_PLACE) return Employee.MELIORA_PLACE_ASSIGNED
      return Employee.ALPHA_COM_ASSIGNED
    }
    return null;
  },
}