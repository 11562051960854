/*
 *
 * AdvancedDataTable utils
 *
 */

import { getAllKeysAsArray } from 'utils/objectUtils';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';


export const sortTableData = (records, columns, sorting) =>
  records.sort((recordA, recordB) =>
    compareRecords(recordA, recordB, columns, sorting)
  );

const compareRecords = (recordA, recordB, columns, sorting) => {
  let curSortingRuleIndex = 0;
  let curSortingRule = sorting[curSortingRuleIndex];
  let comparingResult = 0;

  while (curSortingRule && comparingResult === 0) {
    const curColumn = findColumnById(columns, curSortingRule.columnId);
    const { direction } = curSortingRule;
    const { path, type } = curColumn;

    const valueA = getValueFromRecord(path, recordA);
    const valueB = getValueFromRecord(path, recordB);

    switch (type) {
      case 'numeric':
        comparingResult = compareValuesAsNumeric(valueA, valueB);
        break;

      case 'datetime':
        comparingResult = compareValuesAsDatetime(valueA, valueB);
        break;

      case 'date':
        comparingResult = compareValuesAsDatetime(valueA, valueB);
        break;

      case 'time':
        comparingResult = compareValuesAsDatetime(valueA, valueB);
        break;

      default:
        comparingResult = compareValuesAsString(valueA, valueB);
    }

    if (direction === 'desc' && comparingResult !== 0) comparingResult *= -1;

    curSortingRuleIndex += 1;
    curSortingRule = sorting[curSortingRuleIndex];
  }

  return comparingResult;
};

const findColumnById = (columns, id) => columns.find((column) => column.id === id);

const compareValuesAsNumeric = (valueA, valueB) => {
  const parsedValueA = parseFloat(valueA);
  const parsedValueB = parseFloat(valueB);

  if (isNaN(parsedValueA) || isNaN(parsedValueB)) {
    return compareValuesAsString(valueA, valueB);
  }

  if (parsedValueA < parsedValueB) return -1;
  if (parsedValueA > parsedValueB) return 1;
  return 0;
};

const compareValuesAsDatetime = (valueA, valueB) => {
  const parsedValueA = Date.parse(valueA);
  const parsedValueB = Date.parse(valueB);

  if (isNaN(parsedValueA) || isNaN(parsedValueB)) {
    return compareValuesAsString(valueA, valueB);
  }

  if (parsedValueA < parsedValueB) return -1;
  if (parsedValueA > parsedValueB) return 1;
  return 0;
};

const compareValuesAsString = (valueA, valueB) => {
  if ((valueA === null || valueA === undefined) &&
    (valueB !== null || valueB !== undefined)) return -1;

  if ((valueA !== null || valueA !== undefined) &&
    (valueB === null || valueB === undefined)) return 1;

  if ((valueA === null || valueA === undefined) &&
    (valueB === null || valueB === undefined)) return 0;

  if (valueA.toLowerCase() < valueB.toLowerCase()) return -1;
  if (valueA.toLowerCase() > valueB.toLowerCase()) return 1;
  return 0;
};

const getValueFromRecord = (path, record) => {
  if (Array.isArray(path)) {
    return path.reduce(
      (acc, property) => acc != null ? acc[property] : null,
      record,
    );
  }

  return record[path];
};

export const createPaginationData = (totalCount, pageSize, pageNumber) => {

  const pageCount = Math.ceil(totalCount / pageSize)

  let panel = {
    previous: null,
    next: null,
    list: [],
  }

  pageNumber === 1 ? panel.previous = true : panel.previous = false
  pageNumber === pageCount ? panel.next = true : panel.next = false

  let list = []

  if (pageCount <= 4) {
    for (let i = 1; i <= pageCount; i++) {
      list.push(i)
    }
    panel.list = list
  }

  else if (([1, 2].includes(pageNumber)) && pageCount > 4) {
    for (let i = 1; i <= 3; i++) {
      list.push(i)
    }
    list.push(-1)
    list.push(pageCount)
    panel.list = list
  }

  else if (pageNumber === 3 && pageCount === 5) {
    for (let i = 1; i <= pageCount; i++) {
      list.push(i)
    }
    panel.list = list
  }

  else if (pageNumber === 3 && pageCount > 5) {
    for (let i = 1; i <= pageNumber + 1; i++) {
      list.push(i)
    }
    list.push(-1)
    list.push(pageCount)
    panel.list = list
  }

  else if (pageNumber === pageCount - 1 && pageCount >= 5) {
    list.push(1)
    list.push(-1)
    for (let i = pageNumber - 1; i <= pageCount; i++) {
      list.push(i)
    }
    panel.list = list
  }

  else if (pageNumber === pageCount && pageCount >= 5) {
    list.push(1)
    list.push(-1)
    for (let i = pageNumber - 2; i <= pageCount; i++) {
      list.push(i)
    }
    panel.list = list
  }

  else if (pageNumber === pageCount - 2 && pageCount >= 5) {
    list.push(1)
    list.push(-1)
    for (let i = pageNumber - 1; i <= pageCount; i++) {
      list.push(i)
    }
    panel.list = list
  }

  else if ((pageNumber <= pageCount - 3 && pageNumber > 3) && pageCount >= 5) {
    list.push(1)
    list.push(-1)
    for (let i = pageNumber - 1; i <= pageNumber + 1; i++) {
      list.push(i)
    }
    list.push(-1)
    list.push(pageCount)
    panel.list = list
  }
  return panel
}


export const checkTableFeature = (featureName, featuresFromConfig) => {
  if (featuresFromConfig == null) return false;
  return featuresFromConfig.find((item) => item.name === featureName);
};

export const needToUpdateSettingsConfig = ({
  accessKey, currentConfig, localStorageConfig,
}) => {
  if (!localStorageConfig || !localStorageConfig[accessKey]) return true;

  const localStorageColumnWidth = localStorageConfig[accessKey].resizing.value.slice();
  const localStorageColumnFilter = localStorageConfig[accessKey].filtering.value.slice();
  const localStorageColumnOrder = localStorageConfig[accessKey].reordering.value.slice();
  const localStorageUpdateButton = localStorageConfig[accessKey].updateButton.enabled;

  const currentColumnWidth = currentConfig[settings.RESIZING].value.slice();
  const currentColumnOrder = currentConfig[settings.REORDERING].value.slice();
  const currentColumnFilter = currentConfig[settings.FILTERING].value.slice();
  const currentUpdateButton = currentConfig[settings.UPDATE_BUTTON].enabled;

  const isColumnWidthEquals = compareTableFeature(localStorageColumnWidth, currentColumnWidth);
  const isColumnFilterEquals = compareTableFeature(localStorageColumnFilter, currentColumnFilter);
  const isColumnOrderEquals = compareColumnOrdering(localStorageColumnOrder, currentColumnOrder);
  const isUpdateButtonEquals = localStorageUpdateButton === currentUpdateButton;

  if (!isColumnWidthEquals || !isColumnOrderEquals || !isColumnFilterEquals || !isUpdateButtonEquals) return true;

  // config key amount
  const configKeyAmount = getAllKeysAsArray(currentConfig).length;
  const localStorageConfigKeyAmount = getAllKeysAsArray(localStorageConfig[accessKey]).length;

  return configKeyAmount > localStorageConfigKeyAmount;
};

export function compareTableFeature(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;

  for (let i = 0, length = a.length; i < length; i += 1) {
    if (b[i] == null) return null;
    if (a.columnName !== b.columnName) return false;
  }

  return true;
}

export function compareColumnOrdering(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;

  const arrayA = a.slice().sort();
  const arrayB = b.slice().sort();

  for (let i = 0, length = arrayA.length; i < length; i += 1) {
    if (arrayA[i] !== arrayB[i]) return false;
  }

  return true;
}
