import {
    LOAD_DATA,
    SAVE_DATA,
    SAVE_RANGE,
    SET_FILTER,
} from './constants';

export function loadDataAction(entityName, options) {
    return {
        type: LOAD_DATA,
        payload: { entityName, options }
    };
}

export function saveDataAction(entityName, data) {
    return {
        type: SAVE_DATA,
        payload: { entityName, data }
    };
}

export function saveRangeAction(entityName, data) {
    return {
        type: SAVE_RANGE,
        payload: { entityName, data }
    };
}

export function setFilterAction(accessKey, data) {
    return {
        type: SET_FILTER,
        payload: { accessKey, data }
    };
}
