/**
 *
 * ChangeRoleDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { role } from 'domain/typeConstants/roles';

const styleSheet = (theme) => ({
  paper: {
    [theme.breakpoints.only('xs')]: {
      margin: 15,
    },
  },
  textField: {
    [theme.breakpoints.only('xs')]: {
      width: 280,
    },
    width: 300,
  },
  actions: {
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'space-around',
    },
  },
});

class ChangeRoleDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      role: props.currentRole,
    };
  }

  handleAcceptButtonClick = () => this.props.onAccept(this.state.role);

  handleCancelButtonClick = () => this.props.onCancel();

  handleChange = (event) => this.setState({ role: event.target.value });

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          classes={{
            paper: classes.paper,
          }}
        >
          <DialogTitle>
            <span>Выбор роли</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Выберите роль под которой вы хотите перезайти в приложение и подтвердите выбор.</DialogContentText>
            <form>
              <TextField
                select
                value={this.state.role}
                label="Ваша роль"
                className={this.props.classes.textField}
                onChange={this.handleChange}
                margin="normal"
              >
                <MenuItem value={role.ADMINISTRATOR_BC}>Администратор БЦ</MenuItem>
                <MenuItem value={role.TENANT}>Арендатор</MenuItem>
                <MenuItem value={role.SIGHTING}>Визирующий</MenuItem>
                <MenuItem value={role.APPLICANT}>Заявитель</MenuItem>
                <MenuItem value={role.ASSIGNED}>Исполнитель SD</MenuItem>
                <MenuItem value={role.CHECKPOINT}>КПП</MenuItem>
                <MenuItem value={role.SERVICE_REQUESTS_MANAGER}>Менеджер сервисных заявок</MenuItem>
                <MenuItem value={role.USER}>Пользователи</MenuItem>
                <MenuItem value={role.RECEPTION}>Ресепшен</MenuItem>
                <MenuItem value={role.SUPPLY_APPLICANT}>Заявитель Снабжения</MenuItem>
              </TextField>
            </form>

          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button onClick={this.handleAcceptButtonClick} color="primary" autoFocus>
              Принять
            </Button>
            <Button onClick={this.handleCancelButtonClick} color="primary">
              Отмена
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ChangeRoleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  currentRole: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(ChangeRoleDialog);
