/**
 *
 * AdministratorBC Requests DxGrid 14278
 *
 */

import {
    CURRENT_DAY_REQUESTS, ON_CREATE_REQUESTS, REQUESTS_FOR_USER_DEPARTMENT, REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT, REQUESTS_ON_WORK_PRODUCTION
} from 'domain/Data/Filters/constants';
import { ReceptionDxGrid14278 } from '../ReceptionView/ReceptionDxGrid14278';

export class AdministratorBCRequestsDxGrid14278 extends ReceptionDxGrid14278 {

    getFilters() {
        const filters = [];
        const { viewName } = this.props;
        if (viewName === 'today') {
            filters.push(CURRENT_DAY_REQUESTS)
            filters.push(REQUESTS_FOR_USER_DEPARTMENT);
        };
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        return filters;
    }
}
