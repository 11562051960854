/*
 *
 * AdvancedFormDialogCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createAdvancedFormDialog from './createAdvancedFormDialog';
import { register } from './actions';

class AdvancedFormDialogCreator extends React.PureComponent {
  componentWillMount = () => {
    const { name, formName, navigation } = this.props
    this.context.store.dispatch(register(name, formName, navigation))
  }

  render() {
    const { name, formName, entityName, dataTableName, numberField, ...restProps } = this.props;
    const AdvancedFormDialogContainer = createAdvancedFormDialog({ dialogName: name, formName, entityName, dataTableName, numberField });

    return (
      <AdvancedFormDialogContainer {...restProps} />
    );
  }
}

AdvancedFormDialogCreator.contextTypes = {
  store: PropTypes.object.isRequired,
};

AdvancedFormDialogCreator.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
};

export default AdvancedFormDialogCreator;
