/**
 *
 * RulesPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import queryString from 'query-string';

import { makeSelectBreakpoint } from 'domain/Screen/selectors';
import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';

import { getRulesPage } from './getRulesPage';
import saga from './sagas';

class RulesPageContainer extends React.PureComponent {
  render() {
    const { accountId, userRoles, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, ...restProps };

    return (getRulesPage(accountId, props));
  }
}

RulesPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
  breakpoint: makeSelectBreakpoint(),
});

const reducer = (state = {}) => state;
const withReducer = injectReducer({ key: 'rulePage', reducer });
const withSaga = injectSaga({ key: 'rulePage', saga });
const withConnect = connect(mapStateToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(RulesPageContainer);
