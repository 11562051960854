/**
 *
 * VisitorArrivalForm14927
 *
 */

import { VisitorArrivalForm } from 'pages/Requests/ReceptionView';
import { kyrilicKeyboardLayoutToLatin } from 'utils/common';

export class VisitorArrivalForm14927 extends VisitorArrivalForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
    fields[passNumberIndex].rowBreakBefore = true;

    fields.splice(passNumberIndex, 1, {
      name: 'accessCardKey',
      label: 'Ключ карты доступа',
      initialValue: () => null,
      normalize: (value) => value ? kyrilicKeyboardLayoutToLatin(value) : value,
    });

    return fields;
  }
}
