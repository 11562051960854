/**
 *
 * WorkFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { USER_WORK_FORM, USER_WORK_FORM_DIALOG } from '../../Works/WorkView/constants';

function WorkFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={USER_WORK_FORM_DIALOG}
      formName={USER_WORK_FORM}
      advancedForm={form}
      addModeTitle="Добавление действия"
      editModeTitle="Изменение действия"
      viewModeTitle="Просмотр действия"
      copyModeTitle="Копирование действия"
      maxWidth = "sm"
    />
  );
}

WorkFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default WorkFormDialog;
