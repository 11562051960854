/*
 *
 * NavigationDrawer reducer
 *
 */

import { SET_CONTAINER_WIDTH } from 'containers/AdvancedDataTable/constants';
import { fromJS } from 'immutable';

import {
  OPEN_NAVIGATION_DRAWER,
  CLOSE_NAVIGATION_DRAWER,
} from './constants';

const initialState = fromJS({
  isOpen: false,
  width: null,
});

function navigationDrawerReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_NAVIGATION_DRAWER:
      return state
        .set('containerWidth', payload.width)
        .set('isOpen', true);

    case CLOSE_NAVIGATION_DRAWER:
      return state
        .set('containerWidth', payload.width)
        .set('isOpen', false);

    case SET_CONTAINER_WIDTH:
      return state.set('containerWidth', payload.width)

    default:
      return state;
  }
}

export default navigationDrawerReducer;
