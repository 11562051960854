/**
 *
 * TaskDxGridContainer
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction } from 'domain/typeConstants/action';
import * as actionCreators from '../../actions';
import DocumentDxGrid from './DocumentDxGrid';

class DocumentDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => { this.props.onSelect(record.id) };

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case requestAction.SELECT:
        this.props.onSelect(ids[0])
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { viewName } = this.props;

    return <DocumentDxGrid
      viewName={viewName}
      onActionExecuting={this.handleActionExecuting}
      onRowDoubleClick={this.handleRowDoubleClick}
    />
  }
}

DocumentDxGridContainer.propTypes = {
  viewName: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(DocumentDxGridContainer);
