/**
 *
 * getRulesPage Function
 *
 */

import React from 'react';

import { account } from 'domain/typeConstants/accounts';

import { RulesPage12970 } from 'custom/12970/pages/Rules';

import { RulesPage14326 } from 'custom/14326/pages/Rules';

import RulesPage from './RulesPage';

export function getRulesPage(accountId, props) {
  switch (accountId) {
    case account.COMCITY:
      return (
        <RulesPage12970 {...props} />
      );

    case account.BC_KHIMKI:
      return (
        <RulesPage14326 {...props} />
      );

    default:
      return (
        <RulesPage {...props} />
      );
  }
}
