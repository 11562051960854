/*
 *
 * DadataFieldCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createDadataFieldContainer from './createDadataFieldContainer';

class DadataFieldCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const DadataFieldContainer = createDadataFieldContainer(this.context.advancedForm, this.props.name);
    return <DadataFieldContainer {...this.props} />;
  }
}

DadataFieldCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

DadataFieldCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DadataFieldCreator;
