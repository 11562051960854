import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { columnStatusLabels } from 'pages/Requests/common/columnStatusLabel';

/** ---------- actualCreationDate Column ---------- */
export const ACTUAL_CREATION_DATE = 'actualCreationDate';

export const actualCreationDateColumn = {
  path: ACTUAL_CREATION_DATE,
  name: ACTUAL_CREATION_DATE,
  title: 'Создано',
  type: columnType.DATETIME,
  width: columnSize.DATETIME,
};

export const actualCreationDateColumnFilter = {
  columnName: ACTUAL_CREATION_DATE,
  value: '',
};

/** ---------- whoMeets Column ---------- */

export const WHO_MEETS = 'whoMeets';

export const whoMeetsColumn = {
  path: WHO_MEETS,
  name: WHO_MEETS,
  title: 'Кто встречает',
  width: 100,
};

export const whoMeetsColumnFilter = {
  columnName: WHO_MEETS,
  value: '',
};

/** ---------- agreedEmployee Column ---------- */
export const AGREED_EMPLOYEE = 'agreedEmployee';

export const agreedEmployeeColumn = {
  path: ['agreedEmployee', 'name'],
  name: 'agreedEmployee',
  title: 'Согласовал',
  getCellValue: (row) => row.agreedEmployee ? row.agreedEmployee.name : undefined,
  width: 100,
};

export const agreedEmployeeColumnFilter = {
  columnName: AGREED_EMPLOYEE,
  value: '',
};

/* AGREE TAB 14691 */
export const getAgreeTabColumns = () => ([
  {
    path: 'number',
    name: 'number',
    title: '№',
    type: columnType.NUMERIC,
    width: columnSize.NUMERIC,
  },
  {
    path: 'visitorFullName',
    name: 'visitorFullName',
    title: 'ФИО посетителя',
    width: requestColumnSizes.visitorFullName,
  },
  {
    path: 'visitDate',
    name: 'visitDate',
    title: 'Дата',
    type: columnType.DATE,
    width: columnSize.DATE,
  },
  {
    path: 'expirationDate',
    name: 'expirationDate',
    title: 'До',
    type: columnType.DATE,
    width: columnSize.DATE,
  },
  {
    path: ['status', 'name'],
    name: 'statusName',
    getCellValue: (row) => (row.status ? row.status.name : undefined),
    title: 'Статус',
    type: columnType.LABEL,
    labels: columnStatusLabels,
    width: requestColumnSizes.statusName,
  },
  {
    path: ['type', 'shortName'],
    name: 'typeShortName',
    getCellValue: (row) => (row.type ? row.type.shortName : undefined),
    title: 'Вид',
    width: requestColumnSizes.typeShortName,
  },
  {
    path: ['company', 'name'],
    name: 'company_name',
    getCellValue: (row) => (row.company ? row.company.name : undefined),
    title: 'Арендатор',
    width: requestColumnSizes.company_name,
  },
  {
    path: 'note',
    name: 'note',
    title: 'Примечание',
    width: requestColumnSizes.note,
  },
  {
    path: 'vozdvizhenkaTime',
    name: 'vozdvizhenkaTime',
    getCellValue: (row) => (row.vozdvizhenkaTime ? row.vozdvizhenkaTime.name : undefined),
    title: 'Время',
    width: 100,
  },
  {
    path: 'issueReason',
    name: 'issueReason',
    getCellValue: (row) => row.issueReason ? row.issueReason.name : undefined,
    title: 'Причина',
    width: 100,
  },
  {
    path: 'passType',
    name: 'passType',
    getCellValue: (row) => (row.passType ? row.passType.name : undefined),
    title: 'Вид пропуска',
    width: 100,
  },
  {
    path: 'vozdvizhenkaAccessLevel',
    name: 'vozdvizhenkaAccessLevel',
    getCellValue: (row) => (row.vozdvizhenkaAccessLevel ? row.vozdvizhenkaAccessLevel.name : undefined),
    title: 'Уровень',
    width: 100,
  },
  {
    path: 'vodvizhenkaAccessRight',
    name: 'vodvizhenkaAccessRight',
    getCellValue: (row) => row.vodvizhenkaAccessRight,
    title: 'Право',
    width: 100,
  },
  {
    path: ['file', 'name'],
    name: 'fileName',
    getCellValue: (row) => (row.file ? row.file.id : undefined),
    title: 'Файл',
    type: columnType.FILE,
    width: 100,
  },
  {
    path: ['author', 'name'],
    name: 'authorName',
    getCellValue: (row) => (row.author ? row.author.name : undefined),
    title: 'Автор',
    width: requestColumnSizes.authorName,
  },
  actualCreationDateColumn,
]);

export const agreeTabColumnFilters = [
  { columnName: 'number', value: '' },
  { columnName: 'visitorFullName', value: '' },
  { columnName: 'visitDate', value: '' },
  { columnName: 'expirationDate', value: '' },
  { columnName: 'statusName', value: '' },
  { columnName: 'typeShortName', value: '' },
  { columnName: 'company_name', value: '' },
  { columnName: 'note', value: '' },
  { columnName: 'vozdvizhenkaTime', value: '' },
  { columnName: 'issueReason', value: '' },
  { columnName: 'passType', value: '' },
  { columnName: 'vozdvizhenkaAccessLevel', value: '' },
  { columnName: 'vodvizhenkaAccessRight', value: '' },
  { columnName: 'fileName', value: '' },
  { columnName: 'authorName', value: '' },
  actualCreationDateColumnFilter,
];

export const agreeTabColumnOrder = [
  'number', 'visitorFullName', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
  'company_name', 'note', 'blackListNote', 'vozdvizhenkaTime', 'issueReason', 'passType', 'vozdvizhenkaAccessLevel',
  'vodvizhenkaAccessRight', 'fileName', 'authorName', ACTUAL_CREATION_DATE,
];

/* ReceptionAllAndTodayTab */

export const getReceptionAllAndTodayTab = (styles) => {
  return [
    {
      path: 'number',
      name: 'number',
      title: '№',
      type: columnType.NUMERIC,
      width: columnSize.NUMERIC,
      styles,
    },
    {
      path: 'visitorFullName',
      name: 'visitorFullName',
      title: 'ФИО посетителя',
      width: requestColumnSizes.visitorFullName,
      styles,
    },
    {
      path: 'carNumber',
      name: 'carNumber',
      title: '№ авто',
      width: requestColumnSizes.carNumber,
      styles,
    },
    {
      path: 'carModelText',
      name: 'carModelText',
      title: 'Марка',
      width: requestColumnSizes.carModelText,
      styles,
    },
    {
      path: 'visitDate',
      name: 'visitDate',
      title: 'Дата',
      type: columnType.DATE,
      width: columnSize.DATE,
      styles,
    },
    {
      path: 'expirationDate',
      name: 'expirationDate',
      title: 'До',
      type: columnType.DATE,
      width: columnSize.DATE,
      styles,
    },
    {
      path: ['status', 'name'],
      name: 'statusName',
      getCellValue: (row) => (row.status ? row.status.name : undefined),
      title: 'Статус',
      type: columnType.LABEL,
      labels: columnStatusLabels,
      entityName: 'requestStatuses',
      width: requestColumnSizes.statusName,
    },
    {
      path: ['type', 'shortName'],
      name: 'typeShortName',
      getCellValue: (row) => (row.type ? row.type.shortName : undefined),
      title: 'Вид',
      styles,
      entityName: 'requestTypes',
      width: requestColumnSizes.typeShortName,
    },
    {
      path: ['company', 'name'],
      name: 'company_name',
      getCellValue: (row) => (row.company ? row.company.name : undefined),
      title: 'Арендатор',
      width: requestColumnSizes.company_name,
      styles,
    },
    {
      path: 'documentDetails',
      name: 'documentDetails',
      title: 'Серия и №',
      width: requestColumnSizes.documentDetails,
      styles,
    },
    {
      path: 'passNumber',
      name: 'passNumber',
      title: '№ пропуска',
      width: requestColumnSizes.passNumber,
      styles,
    },
    {
      path: ['parking', 'name'],
      name: 'parkingName',
      getCellValue: (row) => (row.parking ? row.parking.name : undefined),
      title: 'Парковка',
      width: requestColumnSizes.parkingName,
      styles,
    },
    {
      path: 'parkingPlace',
      name: 'parkingPlace',
      title: 'Место',
      width: requestColumnSizes.parkingPlace,
      styles,
    },
    {
      path: 'materialValuesData',
      name: 'materialValuesData',
      title: 'Мат. ценности',
      width: requestColumnSizes.materialValuesData,
      styles,
    },
    {
      path: 'companyName',
      name: 'companyName',
      title: 'Подрядчик',
      width: requestColumnSizes.companyName,
      styles,
    },
    {
      path: ['service', 'name'],
      name: 'serviceName',
      getCellValue: (row) => (row.service ? row.service.name : undefined),
      title: 'Сервис',
      width: requestColumnSizes.serviceName,
      styles,
    },
    whoMeetsColumn,
    agreedEmployeeColumn,
    {
      path: 'whatHappened',
      name: 'whatHappened',
      title: 'Наименование работ',
      width: requestColumnSizes.whatHappened,
      styles,
    },
    {
      path: ['vozdvizhenkaTime', 'name'],
      name: 'vozdvizhenkaTime',
      getCellValue: (row) => (row.vozdvizhenkaTime ? row.vozdvizhenkaTime.name : undefined),
      title: 'Время',
      width: 100,
      styles,
    },
    {
      path: 'responsibleFromContractor',
      name: 'responsibleFromContractor',
      title: 'Отв. от порядчика',
      width: 70,
      styles,
    },
    {
      path: 'responsibleFromContractorPhone',
      name: 'responsibleFromContractorPhone',
      title: 'Тел. отв.',
      width: 50,
      styles,
    },
    {
      path: 'arrivalDate',
      name: 'arrivalDate',
      title: 'Прибыл',
      type: columnType.TIME,
      width: columnSize.DATE,
    },
    {
      path: 'departureDate',
      name: 'departureDate',
      title: 'Убыл',
      type: columnType.TIME,
      width: columnSize.DATE,
    },

    {
      path: 'note',
      name: 'note',
      title: 'Примечание',
      width: requestColumnSizes.note,
      styles,
    },
    {
      path: 'blackListNote',
      name: 'blackListNote',
      title: 'Причина ЧС',
      width: requestColumnSizes.blackListNote,
      styles,
    },
    {
      path: ['author', 'name'],
      name: 'authorName',
      getCellValue: (row) => (row.author ? row.author.name : undefined),
      title: 'Автор',
      width: requestColumnSizes.authorName,
      styles,
    },
    {
      path: ['file', 'name'],
      name: 'file',
      getCellValue: (row) => (row.file ? row.file.id : undefined),
      title: 'Файл',
      type: columnType.FILE,
      width: 50,
      styles,
    },
    {
      path: 'actualCreationDate',
      name: 'actualCreationDate',
      title: 'Создана',
      type: columnType.DATETIME,
      width: columnSize.DATE,
      styles,
    },
  ];
};

export const receptionAllAndTodayTabFilters = [
  { columnName: 'number', value: '' },
  { columnName: 'visitorFullName', value: '' },
  { columnName: 'carNumber', value: '' },
  { columnName: 'carModelText', value: '' },
  { columnName: 'visitDate', value: '' },
  { columnName: 'expirationDate', value: '' },
  { columnName: 'statusName', value: '' },
  { columnName: 'typeShortName', value: '' },
  { columnName: 'company_name', value: '' },
  { columnName: 'documentDetails', value: '' },
  { columnName: 'passNumber', value: '' },
  { columnName: 'parkingName', value: '' },
  { columnName: 'parkingPlace', value: '' },
  { columnName: 'materialValuesData', value: '' },
  { columnName: 'companyName', value: '' },
  { columnName: 'whatHappened', value: '' },
  { columnName: 'serviceName', value: '' },
  { columnName: 'vozdvizhenkaTime', value: '' },
  { columnName: 'responsibleFromContractor', value: '' },
  { columnName: 'responsibleFromContractorPhone', value: '' },
  { columnName: 'arrivalDate', value: '' },
  { columnName: 'departureDate', value: '' },
  { columnName: 'note', value: '' },
  { columnName: 'blackListNote', value: '' },
  { columnName: 'authorName', value: '' },
  { columnName: 'file', value: '' },
  whoMeetsColumnFilter,
  agreedEmployeeColumnFilter
];

export const receptionAllAndTodayTabOrders = [
  'number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
  'company_name', 'passNumber', 'parkingName', 'parkingPlace', 'materialValuesData', 'companyName',
  'whatHappened', 'vozdvizhenkaTime',
  'responsibleFromContractor', 'responsibleFromContractorPhone', 'file', 'whoMeets', 'arrivalDate', 'departureDate',
  'note', 'blackListNote', 'authorName', 'agreedEmployee', 'actualCreationDate',
];
