import React from 'react';
import PropTypes from 'prop-types';

import CheckpointOperatorView, {
  CheckpointOperatorDxGrid,
  CheckpointOperatorDxGridContainer,
  CheckpointOperatorForm,
  CheckpointOperatorFormDialog,
  CheckpointOperatorMobileGrid,
} from '../CheckpointOperator';

const getDxGrid = (DxGrid) => (
  <CheckpointOperatorDxGridContainer component={DxGrid || <CheckpointOperatorDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <CheckpointOperatorDxGridContainer component={MobileGrid || <CheckpointOperatorMobileGrid />} />
);

const getDataTableView = ({ breakpoint, assignedDxGrid, assignedMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(assignedMobileGrid);
  }

  return getDxGrid(assignedDxGrid);
};

const getFormDialog = (Form) => (
  <CheckpointOperatorFormDialog form={Form || <CheckpointOperatorForm />} />
);

export const getCheckpointOperatorView = (props) => {
  const {
    breakpoint,
    checkpointOperatorDxGrid,
    checkpointOperatorForm,
    checkpointOperatorFormDialog,
    checkpointOperatorMobileGrid,
    checkpointOperatorView,
    viewName,
    viewStyles,
  } = props;

  if (checkpointOperatorView) return checkpointOperatorView;

  return (
    <CheckpointOperatorView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        checkpointOperatorDxGrid,
        checkpointOperatorMobileGrid,
      })}
      formDialog={checkpointOperatorFormDialog || getFormDialog(checkpointOperatorForm)}
    />
  );
};

getCheckpointOperatorView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  checkpointOperatorDxGrid: PropTypes.node,
  checkpointOperatorForm: PropTypes.node,
  checkpointOperatorFormDialog: PropTypes.node,
  checkpointOperatorMobileGrid: PropTypes.node,
  checkpointOperatorView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
