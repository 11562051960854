/**
 *
 * VisitorArrivalForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
    ON_VISIT,
    ON_CAR_ENTRY,
    ON_PASS_MAKING,
    SERVICE,
} from 'domain/Data/RequestType/constants';
import {ON_TERRITORY, PASS_ISSUED} from 'domain/Data/RequestStatus/constants';
import {VISITOR_ARRIVAL_FORM} from './constants';
import {DocumentMatchingFormHeader} from '../common/DocumentMatchingFormHeader';
import {COMPANY_ARCHIVE_STATUS} from 'pages/Companies/ReceptionView/constants';
import {visitorFullNameLabel} from '../common/fields';
import {account} from 'domain/typeConstants/accounts';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants';


class VisitorArrivalForm extends React.PureComponent {

    getFields() {
        const currentDate = new Date();
        const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
        const isKrylatckiHolmy = (accountId === account.KRYLATSKI_HOLMY)
        const isBelyeSady = (accountId === account.BC_BELIYE_SADY)

        return [
            {
                name: 'status',
                type: 'select',
                label: 'Статус',
                entityName: 'requestStatuses',
                visible: () => false,
                calculatedValue: (values) => (isBelyeSady || isKrylatckiHolmy) && values.type === ON_VISIT ? PASS_ISSUED : ON_TERRITORY,
            },
            {
                name: 'number',
                label: '№',
                disabled: true,
            },
            {
                name: 'arrivalDate',
                type: 'date',
                label: 'Дата прибытия',
                required: true,
                calculatedValue: () => currentDate,
            },
            {
                name: 'departureDate',
                type: 'date',
                visible: () => false,
                calculatedValue: (values) => values.longterm ? null : values.departureDate,
            },
            {
                name: 'visitorFullName',
                label: visitorFullNameLabel,
                visible: (values) => values.type !== SERVICE,
            },
            {
                name: 'passNumber',
                label: '№ пропуска',
                visible: (values) => values.type === ON_VISIT ||
                    values.type === ON_PASS_MAKING,
            },
            {
                name: 'company',
                type: 'autocomplete',
                label: 'Арендатор',
                entityName: 'companies',
                filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
                orderBy: 'name',
                rowBreakBefore: true,
                disabled: true,
                cached: false,
                numberInQueueFromEnd: 1,
            },
            {
                name: 'documentType',
                type: 'select',
                label: 'Вид документа',
                entityName: 'documentTypes',
                visible: (values) => values.type !== ON_CAR_ENTRY &&
                    values.type !== SERVICE,
                rowBreakBefore: true,
            },
            {
                name: 'documentDetails',
                label: 'Серия, № документа',
                visible: (values) => values.type !== ON_CAR_ENTRY &&
                    values.type !== SERVICE,
            },
        ];
    }

    render() {
        return (
            <AdvancedForm
                header={<DocumentMatchingFormHeader/>}
                name={VISITOR_ARRIVAL_FORM}
                entityName="requests"
                fields={this.getFields()}
                {...this.props}
            />
        );
    }
}

export default VisitorArrivalForm;
