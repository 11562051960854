/**
 *
 * ReceptionDxGrid 14419
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';
import { REQUESTS_FOR_AGREE, REQUESTS_ON_WORK_PRODUCTION, ON_CREATE_REQUESTS, } from 'domain/Data/Filters/constants';
import {
  carryingOurWorkingTabFilters, carryingOurWorkingTabOrder, getCarryingOurWorkingTab,
} from '../common/dxGridComponents/columns';

export class ReceptionDxGrid14419 extends ReceptionDxGrid {
  unnecessaryColumns = new Set(['whatHappened', 'serviceName', 'documentDetails', 'materialValuesData']);

  getFilters() {
    const { viewName } = this.props;
    const filters = super.getFilters();

    if (viewName === 'agree') filters.push(REQUESTS_FOR_AGREE);
    if (viewName === 'carryingOutWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);

    return filters;
  }

  getColumns() {
    const { viewName } = this.props;
    const columns = super.getColumns();

    columns.push({
      path: 'employee.phone',
      name: 'employee.phone',
      title: 'Телефон',
      getCellValue: (row) => row.author ? row.author.phone : undefined,
    });
    columns.push({
      path: 'position',
      name: 'position',
      title: 'Должность',
      getCellValue: (row) => {
        if (row.author && row.author.position) {
          return row.author.position.name;
        }

        return undefined;
      },
    });

    columns.push({
      path: ['groupRequestVisitorsData'],
      name: 'groupRequestVisitorsData',
      getCellValue: (row) => row.groupRequestVisitorsData ? row.groupRequestVisitorsData : undefined,
      title: 'Список посетителей',
    });

    if (viewName === 'all') {
      const companyNameIndex = columns.findIndex((column) => column.name === 'companyName');
      columns[companyNameIndex].title = 'Подрядчик';
    }

    if (viewName === 'carryingOutWorks') {
      return getCarryingOurWorkingTab();
    }

    return columns.filter((column) => !this.unnecessaryColumns.has(column.name));
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();
    const { viewName } = this.props;

    if (viewName === 'carryingOutWorks') {
      return carryingOurWorkingTabFilters;
    }
    columnFilters.push({ columnName: 'employee.phone', value: '' });
    columnFilters.push({ columnName: 'position', value: '' });
    columnFilters.push({ columnName: 'groupRequestVisitorsData', value: '' });

    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder().filter((column) => !this.unnecessaryColumns.has(column));
    const { viewName } = this.props;
    if (viewName === 'carryingOutWorks') {
      return carryingOurWorkingTabOrder;
    }
    columnOrder.push('employee.phone');
    columnOrder.push('position');
    columnOrder.push('groupRequestVisitorsData');
    return columnOrder;
  }
}
