/**
 *
 * AdminSkudForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_PASS_MAKING,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { ADMIN_SKUD_REQUESTS_FORM } from './constants';
import { visitorFullNameLabel } from '../common/fields';
import { formField } from 'domain/typeConstants/form';
import * as entity from 'domain/Data/constants';
import { CREATED, REJECTED } from 'domain/Data/RequestStatus/constants';
import {
  checkpointPostValue, departmentCalculatedValue,
  departmentVisibleOptions, issueReasonVisibleOptions,
  statusVisibleOptions
} from 'custom/15755/pages/Requests/common/fields';
import { validatePassMakingLanguage, visitoFullNameHelperText } from 'custom/15755/pages/Requests/utils';



class AdminSkudForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        disabled: true,
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: entity.REQUEST_STATUSES,
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') {
            return CREATED
          }
          return values.status;
        },
        visibleOptions: statusVisibleOptions,
        orderBy: 'name',
      },
      {
        name: 'type',
        initialValue: ON_PASS_MAKING,
        visible: false,
      },
      {
        name: 'visitDate',
        label: 'Дата',
        type: formField.DATE,
        required: true,
        initialValue: currentDate,
        min: () => currentDate,
        rowBreakBefore: true,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: (values) => values.type === ON_PASS_MAKING ? formField.TEXT : formField.DADATA,
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        visible: (values) => (!values.isGroupRequest && values.type !== SERVICE) && values.type !== ON_CAR_ENTRY,
        required: (values) => !values.isGroupRequest && [ON_PASS_MAKING, ON_VISIT, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type),
        rowBreakBefore: (values) => !values.isGroupRequest && values.type !== SERVICE,
        validate: validatePassMakingLanguage,
        helperText: visitoFullNameHelperText,
      },
      {
        name: 'companyName',
        label: (values) => 'Подразделение',
      },
      {
        name: 'company',
        type: formField.AUTOCOMPLETE,
        label: 'Арендатор',
        entityName: entity.COMPANIES,
        orderBy: 'name',
        numberInQueueFromEnd: 1,
        initialValue: (values, mode, user) => user.companyId,
        rowBreakBefore: true,
        required: true,
      },
      {
        name: 'department',
        type: formField.SELECT,
        entityName: entity.DEPARTMENTS,
        label: 'Здание',
        required: true,
        initialValue: (values, mode, user) => user.departmentId,
        calculatedValue: departmentCalculatedValue,
        visibleOptions: departmentVisibleOptions,
      },
      {
        name: 'checkpointPost',
        visible: false,
        initialValue: checkpointPostValue,
        calculatedValue: checkpointPostValue,
      },
      {
        name: 'issueReason',
        type: formField.SELECT,
        label: 'Причина выдачи',
        entityName: entity.ISSUE_REASONS,
        visibleOptions: issueReasonVisibleOptions,
      },
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        required: true,
        visible: false,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },

      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        grid: { sm: 12 },
        initialValue: (values, mode) => mode !== 'copy' ? values.managementCompanyComment : null,
        required: (currentValues) => currentValues.status === REJECTED,
      },
      {
        name: 'file',
        label: 'Файл',
        type: formField.FILE,
        required: true,
        rowBreakBefore: true,
      },
      {
        name: 'isOffsetCard',
        label: 'Карта на взаимозачет',
        type: formField.CHECKBOX,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={ADMIN_SKUD_REQUESTS_FORM}
        entityName="requests"
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}

export default AdminSkudForm;
