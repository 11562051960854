import React from 'react';
import PropTypes from 'prop-types';

import { isUndefined } from 'utils/typeUtils';

import CheckpointView, {
  CheckpointDxGrid,
  CheckpointDxGridContainer,
  CheckpointForm,
  CheckpointFormDialog,
  CheckpointMobileGrid,
} from '../CheckpointView';

const getDxGrid = (DxGrid) => (
  <CheckpointDxGridContainer component={DxGrid || <CheckpointDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <CheckpointDxGridContainer component={MobileGrid || <CheckpointMobileGrid />} />
);

const getDataTableView = ({ breakpoint, checkpointDxGrid, checkpointMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(checkpointMobileGrid);
  }
  if (isUndefined(checkpointDxGrid) || checkpointDxGrid) {
    return getDxGrid(checkpointDxGrid);
  }
};

const getFormDialog = (Form) => (
  <CheckpointFormDialog form={Form || <CheckpointForm />} />
);

export const getCheckpointView = (props) => {
  const {
    breakpoint,
    checkpointDxGrid,
    checkpointMobileGrid,
    checkpointForm,
    checkpointFormDialog,
    checkpointView,
    viewName,
    viewStyles,
  } = props;

  if (checkpointView) return checkpointView;

  return (
    <CheckpointView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        checkpointDxGrid,
        checkpointMobileGrid,
      })}
      formDialog={checkpointFormDialog || getFormDialog(checkpointForm)}
    />
  );
};

getCheckpointView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  checkpointDxGrid: PropTypes.node,
  checkpointMobileGrid: PropTypes.node,
  checkpointForm: PropTypes.node,
  checkpointFormDialog: PropTypes.node,
  checkpointView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
