/*
 *
 * ReceptionDxGrid15755
 *
 */

import {ReceptionDxGrid} from 'pages/Requests/ReceptionView';
import {
    CURRENT_DAY_REQUESTS,
    REQUESTS_FOR_USER_DEPARTMENT,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT,
    REQUESTS_FOR_ON_PASS,
    ON_CREATE_REQUESTS,
    ACTIVE_REQUESTS,
    REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE,
    REQUESTS_ON_PROPERTY_BRINGING,
    REQUESTS_ON_PROPERTY_REMOVED,
} from 'domain/Data/Filters/constants';
import {usersWhoSeeAllRequests} from '../common/constants';
import {requestAction} from 'domain/typeConstants/action';
import {requestStatus} from 'domain/typeConstants/status';

import {ON_VISIT} from 'domain/Data/RequestType/constants';


export class ReceptionDxGrid15755 extends ReceptionDxGrid {

    getFilters() {
        const {viewName, userId} = this.props;
        const filters = !usersWhoSeeAllRequests.includes(userId) ? [REQUESTS_FOR_USER_DEPARTMENT] : [];
        if (viewName === 'active') {
            filters.push(ACTIVE_REQUESTS)
            filters.push(REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE)
        }
        ;
        if (viewName === 'today') {
            filters.push(CURRENT_DAY_REQUESTS)
            filters.push(REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE)
        }
        ;
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'pass') filters.push(REQUESTS_FOR_ON_PASS);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);

        if (viewName === 'propertyBringing') filters.push(REQUESTS_ON_PROPERTY_BRINGING);
        if (viewName === 'propertyRemoved') filters.push(REQUESTS_ON_PROPERTY_REMOVED);
        return filters;
    }

    getColumns() {
        const columns = super.getColumns();

        columns.push({
            path: ['department', 'name'],
            name: 'department',
            title: 'Здание',
            getCellValue: (row) => row.department ? row.department.name : undefined,
            width: 150,
        });

        return columns;
    }

    getColumnOrder() {
        const {viewName} = this.props;
        const columnOrder = super.getColumnOrder();

        const companyNameIndex = columnOrder.findIndex(columnName => columnName === 'companyName')
        columnOrder.splice(companyNameIndex, 1);

        const companyIndex = columnOrder.findIndex((column) => column === 'company_name');
        columnOrder.splice(companyIndex + 1, 0, 'department');

        if (viewName === 'propertyBringing' || viewName === 'propertyRemoved') {
            return ['number', 'visitorFullName', 'visitDate', 'statusName', 'typeShortName', 'company_name', 'department', 'materialValuesData', 'note']
        }
        if (viewName === 'onVisit') {
            return ["number", "visitorFullName", "visitDate", "expirationDate", "statusName", "typeShortName", "company_name", "department", "passNumber",
                "arrivalDate", "departureDate", "note", "managementCompanyComment", "blackListNote", "authorName", "actualCreationDate", "requestChangeDate"
            ]
        }
        if (viewName === 'onCarEntry') {
            return ["number", "visitorFullName", "carNumber", "carModelText", "visitDate", "expirationDate", "statusName",
                "typeShortName", "company_name", "department", "passNumber", "parkingName", "parkingPlace", "arrivalDate",
                "departureDate", "note", "managementCompanyComment", "blackListNote", "authorName", "actualCreationDate",
                "requestChangeDate"
            ]
        }
        return columnOrder;
    }

    getActionButtons() {
        const actionButtons = super.getActionButtons()

        const departedActionButtonIndex = actionButtons.findIndex(b => b.name === requestAction.DEPARTED)
        actionButtons[departedActionButtonIndex].disabled = (selection, entities, currentRecord) => {
            if (currentRecord) {
                if (!currentRecord.status) return false
                return currentRecord.type.id !== ON_VISIT ||
                    (currentRecord.status.name !== requestStatus.ON_TERRITORY && currentRecord.status.name !== requestStatus.PASS_ISSUED)
            } else if (selection.length > 0) {
                return entities.some((element) => element.type && element.type.id === ON_VISIT ||
                    (element.status && element.status.name !== requestStatus.ON_TERRITORY && element.status.name !== requestStatus.PASS_ISSUED))
            }
            return true
        }

        const arrivedActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.IN_PROGRESS, requestStatus.CREATED, requestStatus.PASS_ISSUED]
        const arrivedActionButtonIndex = actionButtons.findIndex(b => b.name === requestAction.ARRIVED)
        actionButtons[arrivedActionButtonIndex].disabled = (selection, entities, currentRecord) => {
            if (currentRecord) {
                if (!currentRecord.status) return false
                return currentRecord.type.id !== ON_VISIT || arrivedActionDisableStatuses.includes(currentRecord.status.name)
            } else if (selection.length === 1) {
                if (!entities[0].status) return false;
                return entities[0].type.id !== ON_VISIT || arrivedActionDisableStatuses.includes(entities[0].status.name)
            }
            return true
        }

        const acceptActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED,
            requestStatus.IN_PROGRESS, requestStatus.AGREED, requestStatus.REJECTED, requestStatus.PASS_ISSUED]
        const acceptActionButtonIndex = actionButtons.findIndex(b => b.name === requestAction.ACCEPT)

        actionButtons[acceptActionButtonIndex].disabled = (selection, entities, currentRecord) => {
            if (currentRecord && selection.length === 0) {
                if (!currentRecord.status) return false
                return acceptActionDisableStatuses.includes(currentRecord.status.name)
            } else if (selection.length > 0) {
                return entities.some((element) => element.status &&
                    acceptActionDisableStatuses.includes(element.status.name)
                );
            }
            return true;
        }

        const rejectActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.REJECTED,
            requestStatus.AGREED, requestStatus.PASS_ISSUED]
        const rejectActionButtonIndex = actionButtons.findIndex(b => b.name === requestAction.REJECT)

        actionButtons[rejectActionButtonIndex].disabled = (selection, entities, currentRecord) => {
            if (currentRecord && selection.length === 0) {
                if (!currentRecord.status) return false
                return rejectActionDisableStatuses.includes(currentRecord.status.name)
            } else if (selection.length > 0) {
                return entities.some((element) => element.status &&
                    rejectActionDisableStatuses.includes(element.status.name)
                );
            } else return true
        }

        const addBlackListCarActionButtonIndex = actionButtons.findIndex(b => b.name === requestAction.ADD_TO_BLACK_LIST)
        actionButtons.splice(addBlackListCarActionButtonIndex, 1)

        return actionButtons
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems()

        const addBlackListCarActionItemIndex = actionMenuItems.findIndex(b => b.name === requestAction.ADD_TO_BLACK_LIST)
        actionMenuItems.splice(addBlackListCarActionItemIndex, 1)

        return actionMenuItems
    }
}
