/*
 *
 * ChangePasswordFormContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';

import { submit } from './actions';
import ChangePasswordForm from './ChangePasswordForm';

function ChangePasswordFormContainer({ onSubmit, ...restProps }) {
  return (
    <ChangePasswordForm
      onSubmit={onSubmit}
      {...restProps}
    />
  );
}

ChangePasswordFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: () => dispatch(submit(ownProps.nextPathAfterLogin)),
  };
}

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'changePasswordForm',
    getFormState: (state) => state.get('forms'),
  })(ChangePasswordFormContainer)
);
