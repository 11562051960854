/**
 *
 * SelectField
 *

 */

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { isObject } from 'utils/typeUtils';

function SelectField({
  input, label, helperText, meta: { touched, error, submitFailed }, options, required, readOnly, multiple,
  classNames, fieldName, ...restProps
}) {
  if (isObject(input.value)) { input = { ...input, value: input.value.id } }
  return (
    <TextField
      margin="none"
      select
      label={label}
      SelectProps={{
        MenuProps: {},
        multiple,
      }}
      FormHelperTextProps={{
        classes: {
          root: classNames.helperText,
        },
      }}
      error={(touched || submitFailed) && !!error}
      helperText={(touched || submitFailed) && error ? error : helperText}
      inputProps={{ readOnly }}
      required={required || false}
      {...input}
      {...restProps}
    >
      {/* { !required
      && <MenuItem key="none" value={null}><i>Пусто</i></MenuItem>
      } */}
      {
        options && options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {fieldName ? option[fieldName] : option.text}
          </MenuItem>
        ))
      }
    </TextField>
  );
}

SelectField.propTypes = {
  classNames: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  // required: PropTypes.bool,
  touched: PropTypes.bool,
};

SelectField.defaultProps = {
  classNames: {},
};

export default SelectField;
