/**
 *
 * SeniorParkingOperatorMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import { columnType } from 'domain/typeConstants/table';

import {
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_SENIOR_PARKING_OPERATOR,
} from 'domain/Data/Filters/constants';

import { SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID } from './constants';

import { addBlackListCarActionItem, viewActionItem } from '../common/actionMenu';

import {
  RowComponent, getRowHeight, statusColorLabels,
} from '../common/mobile';

class SeniorParkingOperatorMobileGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_SENIOR_PARKING_OPERATOR];
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    return filters;
  }

  // getPrimaryRowProps() {
  //   return {
  //     leftBlockGetter: (row) => row.number,
  //     centerBlockPrimaryGetter: (row) => row.author ? row.author.name : null,
  //     centerBlockSecondGetter: (row) => row.name,
  //     rightBlockGetter: (row) => row.status ? row.status.name : null,
  //     rightBlockBackgroundGetter: (value) => statusColorLabels[value],
  //   };
  // }

  getDetailRowFields() {
    return [
      {
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
      },
      {
        name: 'visitorFullName',
        title: 'ФИО посетителя',
      },
      {
        name: 'carNumber',
        title: '№ авто',
      },
      {
        name: 'carModelText',
        title: 'Марка',
      },
      {
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
      },
      {
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
      },
      {
        name: 'statusName',
        getValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: statusColorLabels,
      },
      {
        name: 'typeShortName',
        getValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
      },
      {
        name: 'tenantCompanyName',
        getValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
      },
      {
        name: 'arrivalDate',
        title: 'Прибыл',
        type: columnType.TIME,
      },
      {
        name: 'departureDate',
        title: 'Убыл',
        type: columnType.TIME,
      },
      {
        name: 'parkingName',
        getValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
      },
      {
        name: 'parkingPlace',
        title: 'Место',
      },
      {
        name: 'materialValuesData',
        title: 'Мат. ценности',
      },
      {
        name: 'companyName',
        title: 'Откуда посетитель',
      },
      {
        name: 'note',
        title: 'Примечание',
      },
      {
        name: 'blackListNote',
        title: 'Причина ЧС',
      },
    ];
  }

  getActionButtons() {
    return [];
  }

  getActionMenuItems() {
    return [viewActionItem, addBlackListCarActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="requests"
        name={SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        filters={this.getFilters()}
        onActionExecuting={onActionExecuting}
        sorting={this.getSorting()}
        viewName={viewName}
        detailRowFields={this.getDetailRowFields()}
        rowComponent={RowComponent}
        getRowHeight={getRowHeight}
      />
    );
  }
}

SeniorParkingOperatorMobileGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  viewName: PropTypes.string,
};

export default SeniorParkingOperatorMobileGrid;
