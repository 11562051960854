/**
 *
 * AssignedForm14326
 *
 */

import { AssignedForm } from 'pages/Requests/AssignedView';
import { helperTextClassName } from '../common/formClassNames';
import {
  CARRYING_OUT_WORKS,
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { BLOCKING_OR_ADDITIONAL_CARD_LEVEL, ENGINEERING_SERVICE } from 'custom/14326/domain/Data/RequestType/constants';
import * as entity from 'domain/Data/constants';
import { validateCarNumber } from 'utils/validators';
import { formField } from 'domain/typeConstants/form';
import { calculateMaxExpirationDate, validateExpirationDate } from '../utils';
import {
  ADDITIONAL_ACCESS_LEVEL,
  EMPLOYEE_ACCESS_LEVEL,
  OFFICE_MANAGER_ACCESS_LEVEL,
} from '../../../domain/Data/AccessLevel/constants';
import { formatText } from "utils/formatUtils";
import { IN_PROGRESS, COMPLETED, WAIT_S3, CREATED } from 'domain/Data/RequestStatus/constants';
import { MOVEMENT_MATERIAL_VALUES_DATA, PROPERTY_BRINGING, PROPERTY_REMOVAL } from 'custom/14326/domain/Data/OnPropertyBringingRequestType/constants';
import {
  carModelTextVisible, carModelTextHelperText, carNumberHelperText, carNumberVisible,
  isGroupRequestRowBreakBefore, isGroupRequestVisible, khimkiAccessPremisesLabel,
  khimkiAccessPremisesVisible, khimkiAccessPremisesRowBreakBefore, khimkiEmailGuestLabel,
  khimkiEmailGuestVisible, khimkiEmailMeetingLabel, khimkiGuestPhoneLabel, khimkiGuestPhoneVisible,
  khimkiTimeVisible, khimkiTimeLabel, khimkiTimeRowBreakBefore, khimkiTimeRowBreakAfter,
  longtermVisible, longtermRequired, nonstandardCarNumberVisible, parkingHelperText,
  parkingVisibleOptions, parkingCalculatedValue, parkingVisible, parkingDisabled,
  parkingPlaceVisible, parkingPlaceRequired, phoneLabel, typeHelperText, visitDateLabel,
  visitorFullNameLabel, visitorFullNameRequired, visitorFullNameVisible,
  whatHappenedVisible, whatHappenedLabel, whatHappenedRequired,
  whereHappenedLabel, whoMeetsLabel, workTypeVisibleOptions, workTypeCompareOptions,
  validateWorkingHour, implementationReportLabel,
} from '../common/fields';
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class AssignedForm14326 extends AssignedForm {

  getClassNames() {
    return {
      helperText: helperTextClassName,
    };
  }

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'customer',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'passNumber',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'accessCardKey',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'passportPhoto',
        visible: false,
        initialValue: (values, mode) => mode === 'copy' ? null : values.passportPhoto,
      },
      {
        name: 'department',
        label: 'Здание',
        visible: false,
        initialValue: (values, mode, user, entities) => {
          const company = entities.get('companies').get(values.company);
          return company ? company.department : null;
        },
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },

      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        orderBy: 'order',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return SERVICE;
          }
          return values.type;
        },
        clearErrorsOnChange: true,
        helperText: typeHelperText,
      },
      {
        name: 'status',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode !== 'add' && mode !== 'copy',
        type: 'select',
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return CREATED; }
          return values.status;
        },
        orderBy: 'name',
        required: true,
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const status = entities.get('requestStatuses').valueSeq().toJS();
          if (status) {
            return status.filter(item => item.id === currentValues.status ||
              item.id === IN_PROGRESS || item.id === COMPLETED).map((item) => item.id);
          }
        },
      },

      {
        name: 'isTrainingPass',
        type: 'checkbox',
        label: 'S3',
        disabled: true,
        visible: false
      },
      {
        name: 'trainingStatus',
        label: 'S3 статус',
        disabled: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === ON_PASS_MAKING
          && mode !== 'add' && currentValues.status !== WAIT_S3,
        calculatedValue: (values) => {
          if (values.isTrainingPass === true) { return 'Успешно' }
          return 'Не успешно';
        }
      },
      {
        name: 'longterm',
        type: formField.CHECKBOX,
        label: 'Долгосрочная',
        visible: longtermVisible,
        required: longtermRequired,
        calculatedValue: (values) => {
          if (![SERVICE, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type))
            return values.longterm;
          return null;
        }
      },
      {
        name: 'visitDate',
        type: formField.DATE,
        label: visitDateLabel,
        required: true,
        visible: true,
        initialValue: currentDate,
        rowBreakBefore: (currentValues, initialValues, mode) => (mode !== 'view' || currentValues.type === ON_PASS_MAKING
          || currentValues.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL),
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
      },
      {
        name: 'trainingResult',
        label: 'Результат S3',
        disabled: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === ON_PASS_MAKING
          && mode !== 'add' && currentValues.status !== WAIT_S3,
      },
      {
        name: 'expirationDate',
        type: formField.DATE,
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: (values) => values.longterm && values.type !== SERVICE,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm && values.type !== SERVICE ? values.expirationDate : null,
        validate: validateExpirationDate,
        min: (values) => (
          values.visitDate ? new Date(values.visitDate) : new Date()
        ),
        max: calculateMaxExpirationDate,
      },
      {
        name: 'khimkiAccessLevel',
        label: 'Уровень доступа',
        type: formField.SELECT,
        entityName: entity.ACCESS_LEVELS,
        required: true,
        visible: (values) => values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
        rowBreakBefore: (values) => values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
        visibleOptions: (currentValues) => {
          if (currentValues.type === ON_PASS_MAKING) {
            return [EMPLOYEE_ACCESS_LEVEL, OFFICE_MANAGER_ACCESS_LEVEL, ADDITIONAL_ACCESS_LEVEL];
          }
          return null;
        },
        orderBy: 'orderKhimki',
        calculatedValue: (values) => {
          if (values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL)
            return values.khimkiAccessLevel;
          return null;
        }
      },
      {
        name: 'trainingDate',
        label: 'Дата S3',
        type: 'date',
        disabled: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === ON_PASS_MAKING
          && mode !== 'add' && currentValues.status !== WAIT_S3,
      },
      {
        name: 'startWorkingHour',
        type: formField.SELECT,
        entityName: entity.WORKING_HOURS,
        label: 'Время начала',
        rowBreakBefore: true,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        validate: validateWorkingHour,
        required: true,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.startWorkingHour;
          return null;
        }
      },
      {
        name: 'endWorkingHour',
        type: formField.SELECT,
        entityName: entity.WORKING_HOURS,
        label: 'Время окончания',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        validate: validateWorkingHour,
        required: true,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.endWorkingHour;
          return null;
        }
      },
      {
        name: 'isGroupRequest',
        type: formField.CHECKBOX,
        label: 'Групповая заявка',
        visible: isGroupRequestVisible,
        rowBreakBefore: isGroupRequestRowBreakBefore,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS, ON_CAR_ENTRY].includes(values.type))
            return values.isGroupRequest;
          return null;
        }
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        calculatedValue: (values) => {
          if (values.isGroupRequest && values.type !== SERVICE)
            return values.groupRequestVisitorsData;
          return null;
        }
      },
      {
        type: formField.SELECT,
        entityName: entity.TIMES,
        name: 'khimkiTime',
        label: khimkiTimeLabel,
        visible: khimkiTimeVisible,
        required: true,
        compareOptions: (a, b) => {
          const firstItem = Number.parseInt(a.text, 10);
          const secondItem = Number.parseInt(b.text, 10);

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        },
        rowBreakBefore: khimkiTimeRowBreakBefore,
        rowBreakAfter: khimkiTimeRowBreakAfter,
        calculatedValue: (values) => {
          if ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING].includes(values.type))
            return values.khimkiTime;
          return null;
        }
      },
      {
        type: formField.SELECT,
        entityName: entity.WORK_TYPES,
        name: 'workType',
        label: 'Вид',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        visibleOptions: workTypeVisibleOptions,
        compareOptions: workTypeCompareOptions,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS].includes(values.type))
            return values.workType;
          return null;
        }
      },
      {
        name: 'khimkiRequestType',
        label: 'Тип заявки',
        type: formField.SELECT,
        entityName: entity.KHIMKI_REQUEST_TYPES,
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING,
        rowBreakBefore: (values) => values.type === ON_PROPERTY_BRINGING,
        calculatedValue: (values) => {
          if ([ON_PROPERTY_BRINGING].includes(values.type))
            return values.khimkiRequestType;
          return null;
        }
      },
      {
        name: 'khimkiAccessPremises',
        label: khimkiAccessPremisesLabel,
        visible: khimkiAccessPremisesVisible,
        rowBreakBefore: khimkiAccessPremisesRowBreakBefore,
        calculatedValue: (values) => {
          if (values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL
            || (values.type === ON_PROPERTY_BRINGING
              && (values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA
                || values.khimkiRequestType === PROPERTY_BRINGING)))
            return values.khimkiAccessPremises;
          return null;
        }
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: formField.DADATA,
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        visible: visitorFullNameVisible,
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        required: visitorFullNameRequired,
        rowBreakBefore: (values) => !values.isGroupRequest,
        calculatedValue: (values) => {
          if (!values.isGroupRequest && values.type !== SERVICE && values.type !== CARRYING_OUT_WORKS)
            return values.visitorFullName;
          return null;
        }
      },
      {
        name: 'khimkiEmailGuest',
        label: khimkiEmailGuestLabel,
        visible: khimkiEmailGuestVisible,
        rowBreakAfter: (values) => values.isGroupRequest,
        helperText: (values) => {
          if (values.type === ON_CAR_ENTRY) {
            return 'Указать для направления уведомления посетителю';
          } else if (values.type === ON_PASS_MAKING) {
            return 'Указать для направления уведомления получателю';
          }
          return null;
        },
        calculatedValue: (values) => {
          if ([ON_VISIT, ON_CAR_ENTRY, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type))
            return values.khimkiEmailGuest;
          return null;
        },
        required: (values) => values.type === ON_PASS_MAKING,
      },
      {
        type: formField.AUTOCOMPLETE,
        entityName: 'companies',
        name: 'company',
        label: 'Арендатор',
        required: true,
        visible: false,
        initialValue: (values, mode, user) => user.companyId,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'responsibleFromContractor',
        label: 'Ответственный от подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS].includes(values.type))
            return values.responsibleFromContractor;
          return null;
        }
      },
      {
        name: 'responsibleFromContractorPhone',
        label: 'Телефон отв. подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS].includes(values.type))
            return values.responsibleFromContractorPhone;
          return null;
        }
      },
      {
        name: 'khimkiResponsibleFromTenant',
        label: 'Ответственный от арендатора',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS].includes(values.type))
            return values.khimkiResponsibleFromTenant;
          return null;
        }
      },
      {
        name: 'khimkiGuestPhone',
        label: khimkiGuestPhoneLabel,
        visible: khimkiGuestPhoneVisible,
        required: (values) => !values.isGroupRequest && values.type !== ON_CAR_ENTRY,
        calculatedValue: (values) => {
          if ([ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
            ON_CAR_ENTRY, CARRYING_OUT_WORKS].includes(values.type))
            return values.khimkiGuestPhone;
          return null;
        }
      },
      {
        name: 'whoMeets',
        label: whoMeetsLabel,
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
        rowBreakBefore: true,
        required: true,
        initialValue: (values, mode, user, entities) => {
          const author = entities.get('employees').get(values.author);
          return author ? author.name : null;
        },
        calculatedValue: (values) => {
          if ([ON_VISIT, ON_CAR_ENTRY].includes(values.type))
            return values.whoMeets;
          return null;
        }
      },
      {
        name: 'khimkiEmailMeeting',
        label: khimkiEmailMeetingLabel,
        visible: (values) => [ON_VISIT, ON_CAR_ENTRY].includes(values.type),
        initialValue: (values, mode, user, entities) => {
          const author = entities.get('employees').get(values.author);
          return author ? author.email : null;
        },
        calculatedValue: (values) => {
          if ([ON_VISIT, ON_CAR_ENTRY].includes(values.type))
            return values.khimkiEmailMeeting;
          return null;
        }
      },
      {
        name: 'phone',
        label: phoneLabel,
        visible: (values) => values.type === ON_CAR_ENTRY,
        rowBreakBefore: (values) => values.type === ON_CAR_ENTRY,
        initialValue: (values, mode, user, entities) => {
          const author = entities.get('employees').get(values.author);
          return author ? author.phone : null;
        },
        calculatedValue: (values) => {
          if ([ON_CAR_ENTRY].includes(values.type))
            return values.phone;
          return null;
        }
      },
      {
        name: 'nonstandardCarNumber',
        type: formField.CHECKBOX,
        label: 'Нестандартный № авто',
        rowBreakBefore: true,
        rowBreakAfter: true,
        visible: nonstandardCarNumberVisible,
        calculatedValue: (values) => {
          if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)
            return null;
          return values.nonstandardCarNumber;
        }
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: carNumberVisible,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
        helperText: carNumberHelperText,
        calculatedValue: (values) => {
          if ((values.type === ON_PROPERTY_BRINGING
            && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA) || values.isGroupRequest)
            return null;
          return values.carNumber;
        }
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: carModelTextVisible,
        helperText: carModelTextHelperText,
        calculatedValue: (values) => {
          if ((values.type === ON_PROPERTY_BRINGING
            && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA) || values.isGroupRequest)
            return null;
          return values.carModelText;
        }
      },
      {
        name: 'parking',
        type: formField.SELECT,
        label: 'Парковка',
        entityName: 'parkings',
        visible: parkingVisible,
        visibleOptions: parkingVisibleOptions,
        calculatedValue: parkingCalculatedValue,
        rowBreakBefore: true,
        helperText: parkingHelperText,
        disabled: parkingDisabled,
        required: (values) => !!values.carNumber,
      },
      {
        name: 'parkingPlace',
        label: 'Место парковки',
        visible: (currentValues, initialValues, mode) => parkingPlaceVisible && mode === 'view',
        required: parkingPlaceRequired,
        calculatedValue: (values, mode) => {
          if (
            (values.type === ON_PROPERTY_BRINGING
              && (values.khimkiRequestType === PROPERTY_REMOVAL
                || values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)) || mode === 'view')
            return null;
          return values.parkingPlace;
        }
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
        normalize: (value) => value ? formatText(value, { limit: 2000 }) : value,
        calculatedValue: (values) => {
          if ([ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type))
            return values.materialValuesData;
          return null;
        }
      },
      {
        name: 'service',
        type: formField.SELECT,
        label: 'Сервис',
        entityName: 'services',
        visible: false,
        rowBreakBefore: true,
      },
      {
        name: 'whereHappened',
        label: whereHappenedLabel,
        visible: (values) => values.type === SERVICE,
        required: (values) => values.type === ENGINEERING_SERVICE,
        calculatedValue: (values) => {
          if ([SERVICE].includes(values.type))
            return values.whereHappened;
          return null;
        }
      },
      {
        name: 'whatHappened',
        label: whatHappenedLabel,
        visible: whatHappenedVisible,
        grid: { sm: 12 },
        required: whatHappenedRequired,
        calculatedValue: (values) => {
          if ([SERVICE, CARRYING_OUT_WORKS].includes(values.type))
            return values.whatHappened;
          return null;
        }
      },
      {
        name: 'executionTerm',
        type: formField.DATE,
        label: 'Срок исполнения',
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'implementationReport',
        label: implementationReportLabel,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'khimkiTenantComment',
        label: 'Комментарий от обслуживающей организации Арендатора',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
      },
      {
        name: 'attachments',
        label: 'Файл',
        type: formField.MULTIPLE_FILE,
        visible: (values) => [SERVICE, ON_PASS_MAKING, CARRYING_OUT_WORKS].includes(values.type),
        rowBreakBefore: true,
        helperText: () => 'Прикрепите один или несколько файлов',
        calculatedValue: (values) => {
          if ([SERVICE, ON_PASS_MAKING, CARRYING_OUT_WORKS].includes(values.type))
            return values.attachments;
          return null;
        }
      },
      {
        name: 'workPlace',
        label: 'Место проведения',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: (values) => values.type === CARRYING_OUT_WORKS,
        multiline: true,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS].includes(values.type))
            return values.workPlace;
          return null;
        }
      },
      {
        name: 'note',
        label: 'Примечание',
        visible: (values) => values.type !== SERVICE,
        grid: { sm: 12 },
        multiline: true,
        normalize: (value) => value ? formatText(value, { limit: 2000 }) : value,
        calculatedValue: (values) => {
          if (values.type !== SERVICE)
            return values.note;
          return null;
        }
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий Администрации ХБП',
        grid: { sm: 12 },
        visible: (currentValues, initialValues, mode) => mode === 'view',
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
      },
      {
        type: formField.CHECKBOX,
        name: 'isTechnicalServiceMonitoring',
        label: 'Контроль со стороны технической службы',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.isTechnicalServiceMonitoring;
          return null;
        }
      },
      {
        name: 'assignee',
        type: formField.SELECT,
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => {
            if (item.company === user.companyId && item.isPerformer) assignee.push(item.id);
          });
          return assignee;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
    ];
  }


}
