/**
 *
 * ReceptionDxGrid14140
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_FOR_ON_PASS,
  ON_CREATE_REQUESTS,
  ACTIVE_REQUESTS,
} from 'domain/Data/Filters/constants';
import {
  actualCreationDateColumnFilter,

  IS_DISCRETION_OF_SECURITY,
  isDiscretionOfSecurityColumn,
  isDiscretionOfSecurityColumnFilter,

  BERLINSKY_TIME,
  berlinskyTimeColumn,
  berlinskyTimeColumnFilter,
  
  BERLINSKY_VISIT_TIME,
  berlinskyVisitTimeColumn,
  berlinskyVisitTimeColumnFilter,

  getPassTabColumns,
  passTabColumnFilters,
  passTabColumnOrder,
} from '../common/dxGridComponents/columns';

export class ReceptionDxGrid14140 extends ReceptionDxGrid { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'pass') filters.push(REQUESTS_FOR_ON_PASS);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }

  getColumns() {
    const { viewName } = this.props;
    let columns = super.getColumns();

    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    if (viewName === 'all' || viewName === 'today') {
      columns.push(isDiscretionOfSecurityColumn);
    } else if (viewName === 'pass') {
      columns = getPassTabColumns(styles);
    }

    columns.push(berlinskyTimeColumn);
    columns.push(berlinskyVisitTimeColumn);

    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    let gridFilters = super.getGridFilters();

    if (viewName === 'all' || viewName === 'today') {
      gridFilters.push(actualCreationDateColumnFilter);
      gridFilters.push(isDiscretionOfSecurityColumnFilter);
    } else if (viewName === 'pass') {
      gridFilters = passTabColumnFilters;
    }

    gridFilters.push(berlinskyTimeColumnFilter);
    gridFilters.push(berlinskyVisitTimeColumnFilter);

    return gridFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    let columnOrder = super.getColumnOrder();

    if (viewName === 'all' || viewName === 'today') {
      const parkingPlaceIndex = columnOrder.findIndex((column) => column === 'parkingPlace');
      columnOrder.splice(parkingPlaceIndex + 1, 0, IS_DISCRETION_OF_SECURITY);
    } else if (viewName === 'pass') {
      columnOrder = passTabColumnOrder;
    }

    columnOrder.push(BERLINSKY_TIME);

    const statusNameIndex = columnOrder.findIndex((column) => column === 'statusName');
    columnOrder.splice(statusNameIndex, 0, BERLINSKY_VISIT_TIME);
    return columnOrder;
  }
}
