/*
 *
 * EmailSubscriptionButton actions
 *
 */

import {
  SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_ERROR,

  UNSUBSCRIBE,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
} from './constants';

export function subscribe() {
  return {
    type: SUBSCRIBE,
  };
}

export function subscribed() {
  return {
    type: SUBSCRIBE_SUCCESS,
  };
}

export function subscribingError(error) {
  return {
    type: SUBSCRIBE_ERROR,
    payload: { error },
  };
}



export function unsubscribe() {
  return {
    type: UNSUBSCRIBE,
  };
}

export function unsubscribed() {
  return {
    type: UNSUBSCRIBE_SUCCESS,
  };
}

export function unsubscribingError(error) {
  return {
    type: UNSUBSCRIBE_ERROR,
    payload: { error },
  };
}
