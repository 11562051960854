/**
 * DadataField selectors
 */

import { createSelector } from 'reselect';
import {
  makeSelectFields,
  makeSelectCurrentValues,
} from '../selectors';

const makeSelectDomain = (formName, fieldName) => createSelector(
  makeSelectFields(formName),
  (fields) => fields.get(fieldName),
);

const makeSelectValue = (formName, fieldName) => createSelector(
  makeSelectCurrentValues(formName),
  (formValues) => formValues[fieldName]
);

const makeSelectSuggestions = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  (field) => field.suggestions
);

export default makeSelectDomain;
export {
  makeSelectValue,
  makeSelectSuggestions,
};
