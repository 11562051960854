/**
 *
 * RequestsPage12924 sagas config
 *
 */

import { checkpointViewSaga } from './CheckpointView/sagas';

const requestsPageSagasConfig14326 = {
  checkpointViewSaga,
};

export default requestsPageSagasConfig14326;
