/**
 *
 * ServiceRequestsManagerBlackListFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import {
  SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM,
  SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG,
} from './constants';

function ServiceRequestsManagerBlackListFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG}
      formName={SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM}
      advancedForm={form}
      addModeTitle="Добавление в черный список"
    />
  );
}

ServiceRequestsManagerBlackListFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ServiceRequestsManagerBlackListFormDialog;
