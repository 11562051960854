/**
 *
 * getSagasConfig Function
 *
 */

export default function getSagasConfig(accountId) {
  switch (accountId) {
    default:
      return {};
  }
}
