/*
 *
 * AdministratorBCView constants 14691
 *
 */

export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/EXECUTE_ACCEPT_ACTION';

export const ACCEPT_REQUESTS = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/ACCEPT_REQUESTS';
export const ACCEPT_REQUESTS_SUCCESS = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/ACCEPT_REQUESTS_SUCCESS';
export const ACCEPT_REQUESTS_ERROR = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/ACCEPT_REQUESTS_ERROR';

export const EXECUTE_REJECT_ACTION = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/EXECUTE_REJECT_ACTION';

export const REJECT_REQUESTS = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/REJECT_REQUESTS';
export const REJECT_REQUESTS_SUCCESS = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/REJECT_REQUESTS_SUCCESS';
export const REJECT_REQUESTS_ERROR = 'claris-vnext/custom/14691/AdministratorBCPage/AdministratorBCView/REJECT_REQUESTS_ERROR';
