/**
 *
 * UsersMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';
import { USERS_DATA_TABLE } from './constants';
import { addActionButton } from '../common/actionButton';
import { editActionItem } from '../common/actionMenu';

import { RowComponent, getRowHeight } from '../common/mobile';

class UsersMobileGrid extends React.PureComponent {
  getFilters() {
    return [];
  }

  getDetailRowFields() {
    return [
      {
        name: 'name',
        title: 'Логин',
      },
      {
        name: 'employee.name',
        title: 'Сотрудник',
        getValue: (row) => row.employee ? row.employee.name : undefined,
      },
      {
        name: 'employee.department',
        title: 'Отдел',
        getValue: (row) => {
          if (row.employee && row.employee.department) {
            return row.employee.department.name;
          }
          return undefined;
        },
      },
      {
        name: 'employee.company',
        title: 'Организация',
        getValue: (row) => {
          if (row.employee && row.employee.company) {
            return row.employee.company.name;
          }
          return undefined;
        },
      },
      {
        name: 'identityBlock.is_Blocked',
        title: 'Заблокирован',
        getValue: (row) => row.identityBlock?.is_Blocked ? 'Да' : 'Нет',
      },
      {
        name: 'rolesText',
        title: 'Входит в группу',
      },
      {
        name: 'forcePasswordChange',
        title: 'Требовать смену пароля',
        getValue: (row) => row.forcePasswordChange ? 'Да' : 'Нет',
      },
    ];
  }

  getActionButtons() {
    return [addActionButton];
  }

  getActionMenuItems() {
    return [editActionItem];
  }

  getSorting() {
    return [{ columnId: 'name', columnName: 'name', direction: 'asc' }];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="users"
        name={USERS_DATA_TABLE}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        detailRowFields={this.getDetailRowFields()}
        filters={this.getFilters()}
        getRowHeight={getRowHeight}
        onActionExecuting={onActionExecuting}
        rowComponent={RowComponent}
        sorting={this.getSorting()}
        viewName={viewName}
      />
    );
  }
}

UsersMobileGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  viewName: PropTypes.string,
};

export default UsersMobileGrid;
