/**
 *
 * Assigned ActionButton Items
 *
 */

import React from 'react';
import Create from '@material-ui/icons/Create';
import { requestAction } from 'domain/typeConstants/action';
import { requestStatus } from 'domain/typeConstants/status';

export const editActionButton = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED
    }
    else if (selection.length === 1) {
      if (!entities.status) return false
      return entities.status.name === requestStatus.CLOSED
    }
    return true;
  },
};
