/**
 *
 * AdvancedMobileGrid list container primary row component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles/index';

import { LeftBlock } from './left-block';
import { DetailButton } from './detail-button';
import { CenterBlock } from './center-block';

import {
  LEFT_BLOCK_WIDTH,
  RIGHT_BLOCK_WIDTH,
} from './constants';

const styles = () => ({
  root: {
    borderBottom: 'solid 1px #eeeeee',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    padding: 5,
  },
  leftItem: {
    alignItself: 'flex-start',
  },
});

const RowBase = ({
  classes,
  listWidth,
  onActionMenuClick,
  onDetailRowClick,
  record,
  rowComponent,
  style,
  accountId,
}) => (
  <div
    className={classes.root}
    style={style}
  >
    <LeftBlock
      onActionMenuClick={onActionMenuClick}
      record={record}
      className={classes.leftItem}
    />
    <CenterBlock
      record={record}
      rootComponent={rowComponent}
      maxWidth={listWidth - LEFT_BLOCK_WIDTH - RIGHT_BLOCK_WIDTH - 10}
      accountId={accountId}
    />
    <DetailButton
      record={record}
      onClick={onDetailRowClick}
    />
  </div>
);

RowBase.propTypes = {
  classes: PropTypes.object.isRequired,
  listWidth: PropTypes.number.isRequired,
  onActionMenuClick: PropTypes.func.isRequired,
  onDetailRowClick: PropTypes.func.isRequired,
  record: PropTypes.object,
  rowComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  style: PropTypes.object,
};

RowBase.defaultProps = {
  record: {},
};

export const Row = withStyles(styles, { name: 'Row' })(RowBase);
