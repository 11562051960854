/*
 *
 * TenantDxGrid14691
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';
import {
    whoMeetsColumn,
    whoMeetsColumnFilter,
    agreedEmployeeColumn,
    agreedEmployeeColumnFilter,
} from '../common/dxGridComponents/columns';
import { columnSize, columnType, requestColumnSizes } from "domain/typeConstants/table";
import { columnStatusLabels } from "pages/Requests/common/columnStatusLabel";
import { requestAction } from "domain/typeConstants/action";
import { copyActionButton } from "../common/actionButton";
import { copyActionItem } from "../common/actionMenuItem";

export class TenantDxGrid14691 extends TenantDxGrid {
    getColumns() {
        return [
            {
                path: 'number',
                name: 'number',
                title: '№',
                type: columnType.NUMERIC,
                width: columnSize.NUMERIC,
            },
            {
                path: 'visitorFullName',
                name: 'visitorFullName',
                title: 'ФИО посетителя',
                width: requestColumnSizes.visitorFullName,
            },
            {
                path: 'carNumber',
                name: 'carNumber',
                title: '№ авто',
                width: requestColumnSizes.carNumber,
            },
            {
                path: 'carModelText',
                name: 'carModelText',
                title: 'Марка',
                width: requestColumnSizes.carModelText,
            },
            {
                path: 'visitDate',
                name: 'visitDate',
                title: 'Дата',
                type: columnType.DATE,
                width: columnSize.DATE,
            },
            {
                path: 'expirationDate',
                name: 'expirationDate',
                title: 'До',
                type: columnType.DATE,
                width: columnSize.DATE,
            },
            {
                path: ['status', 'name'],
                name: 'statusName',
                getCellValue: (row) => (row.status ? row.status.name : undefined),
                title: 'Статус',
                type: columnType.LABEL,
                labels: columnStatusLabels,
                width: requestColumnSizes.statusName,
            },
            {
                path: ['type', 'shortName'],
                name: 'typeShortName',
                getCellValue: (row) => (row.type ? row.type.shortName : undefined),
                title: 'Вид',
                width: requestColumnSizes.typeShortName,
            },
            {
                path: 'passNumber',
                name: 'passNumber',
                title: '№ пропуска',
                width: requestColumnSizes.passNumber,
            },
            {
                path: ['parking', 'name'],
                name: 'parkingName',
                getCellValue: (row) => (row.parking ? row.parking.name : undefined),
                title: 'Парковка',
                width: requestColumnSizes.parkingName,
            },
            {
                path: 'parkingPlace',
                name: 'parkingPlace',
                title: 'Место',
                width: requestColumnSizes.parkingPlace,
            },
            {
                path: 'materialValuesData',
                name: 'materialValuesData',
                title: 'Мат. ценности',
                width: requestColumnSizes.materialValuesData,
            },
            {
                path: 'companyName',
                name: 'companyName',
                title: 'Подрядчик',
                width: requestColumnSizes.companyName,
            },
            {
                path: 'whatHappened',
                name: 'whatHappened',
                title: 'Наименование работ',
                width: requestColumnSizes.whatHappened,
            },
            {
                path: ['vozdvizhenkaTime', 'name'],
                name: 'vozdvizhenkaTime',
                getCellValue: (row) => (row.vozdvizhenkaTime ? row.vozdvizhenkaTime.name : undefined),
                title: 'Время',
                width: 100,
            },
            {
                path: 'responsibleFromContractor',
                name: 'responsibleFromContractor',
                title: 'Отв. от порядчика',
                width: 70,
            },
            {
                path: 'responsibleFromContractorPhone',
                name: 'responsibleFromContractorPhone',
                title: 'Тел. отв.',
                width: 50,
            },
            {
                path: 'implementationReport',
                name: 'implementationReport',
                title: 'Отчет о выполнении',
                width: requestColumnSizes.implementationReport,
            },
            {
                path: ['service', 'name'],
                name: 'serviceName',
                getCellValue: (row) => (row.service ? row.service.name : undefined),
                title: 'Сервис',
                width: requestColumnSizes.serviceName,
            },

            whoMeetsColumn,
            agreedEmployeeColumn,
            {
                path: 'note',
                name: 'note',
                title: 'Примечание',
                width: requestColumnSizes.note,
            },
            {
                path: ['author', 'name'],
                name: 'authorName',
                getCellValue: (row) => (row.author ? row.author.name : undefined),
                title: 'Автор',
                width: requestColumnSizes.authorName,
            },
            {
                path: ['file', 'name'],
                name: 'file',
                getCellValue: (row) => (row.file ? row.file.id : undefined),
                title: 'Файл',
                type: columnType.FILE,
                width: 50,
            },
        ];
    }

    getGridFilters() {
        return [
            { columnName: 'number', value: '' },
            { columnName: 'visitorFullName', value: '' },
            { columnName: 'carNumber', value: '' },
            { columnName: 'carModelText', value: '' },
            { columnName: 'visitDate', value: '' },
            { columnName: 'expirationDate', value: '' },
            { columnName: 'statusName', value: '' },
            { columnName: 'typeShortName', value: '' },
            { columnName: 'passNumber', value: '' },
            { columnName: 'parkingName', value: '' },
            { columnName: 'parkingPlace', value: '' },
            { columnName: 'materialValuesData', value: '' },
            { columnName: 'companyName', value: '' },
            { columnName: 'whatHappened', value: '' },
            { columnName: 'vozdvizhenkaTime', value: '' },
            { columnName: 'responsibleFromContractor', value: '' },
            { columnName: 'responsibleFromContractorPhone', value: '' },
            { columnName: 'implementationReport', value: '' },
            { columnName: 'serviceName', value: '' },
            { columnName: 'note', value: '' },
            { columnName: 'authorName', value: '' },
            { columnName: 'file', value: '' },
            whoMeetsColumnFilter,
            agreedEmployeeColumnFilter,
        ];
    }

    getColumnOrder() {
        return [
            'number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
            'passNumber', 'parkingName', 'parkingPlace', 'materialValuesData', 'companyName', 'whatHappened', 'vozdvizhenkaTime',
            'responsibleFromContractor', 'responsibleFromContractorPhone', 'file', 'implementationReport',
            'serviceName', 'whoMeets', 'note', 'authorName', 'agreedEmployee',
        ];
    }

    getActionButtons() {
        const actionButtons = super.getActionButtons();
        const copyActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.COPY);
        actionButtons.splice(copyActionButtonIndex, 1, copyActionButton);

        return actionButtons;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();
        const copyMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.COPY);
        actionMenuItems.splice(copyMenuItemIndex, 1, copyActionItem);

        return actionMenuItems;
    }
}
