/*
 * CheckpointView sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import {
  noPlacesByCheckpointSaga,
  processVisitorArrivalSaga,
  processVisitorDepartureSaga,
} from 'domain/Data/Request/sagas';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { openViewDialog } from 'containers/AdvancedFormDialog/actions';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';

import {
  CHECKPOINT_REQUESTS_TABLE_GRID,
  CHECKPOINT_REQUESTS_FORM_DIALOG,
  EXECUTE_VIEW_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
  EXECUTE_NO_PLACES_ACTION,
} from './constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const isCheckpointFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  action.meta.dialog === CHECKPOINT_REQUESTS_FORM_DIALOG;

export default function createCheckpointViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_ARRIVED_ACTION, (config.executeArrivedActionSaga || executeArrivedActionSaga));
    yield takeLatest(EXECUTE_DEPARTED_ACTION, (config.executeDepartedActionSaga || executeDepartedActionSaga));
    yield takeLatest(EXECUTE_NO_PLACES_ACTION, (config.executeNoPlacesActionSaga || executeNoPlacesActionSaga));

    yield takeLatest([isCheckpointFormSaved, DISPLAY_NOTIFICATION], (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(CHECKPOINT_REQUESTS_TABLE_GRID));
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(CHECKPOINT_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeArrivedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorArrivalSaga, ids);
  yield put(loadData(CHECKPOINT_REQUESTS_TABLE_GRID));
}

export function* executeDepartedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorDepartureSaga, ids);
  yield put(loadData(CHECKPOINT_REQUESTS_TABLE_GRID));
}

export function* executeNoPlacesActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(noPlacesByCheckpointSaga, ids);
  yield put(loadData(CHECKPOINT_REQUESTS_TABLE_GRID));
}
