/*
 * withAuthorization
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectIsAuthenticated } from 'domain/User/selectors';

function withAuthorization(ProtectedComponent) {
  class AuthorizationContainer extends React.Component {
    componentWillMount = () => this.checkAuth();

    componentWillReceiveProps = () => this.checkAuth();

    checkAuth() {
      const { isAuthenticated, location, redirect } = this.props;
      if (!isAuthenticated) {
        redirect(location.pathname + location.search);
      }
    }

    render() {
      const { isAuthenticated, ...restProps } = this.props;
      const result = isAuthenticated === true ?
        <ProtectedComponent {...restProps} /> : null;
      return result;
    }
  }

  AuthorizationContainer.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    redirect: PropTypes.func.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    isAuthenticated: makeSelectIsAuthenticated(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      redirect: (nextPathAfterLogin) => dispatch(replace(`/login?next=${nextPathAfterLogin}`)),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(AuthorizationContainer);
}

export default withAuthorization;
