/*
 *
 * NavigationDrawer constants
 *
 */

export const OPEN_NAVIGATION_DRAWER = 'claris-vnext/NavigationDrawer/OPEN_NAVIGATION_DRAWER';
export const CLOSE_NAVIGATION_DRAWER = 'claris-vnext/NavigationDrawer/CLOSE_NAVIGATION_DRAWER';

export const NAVIGATION_DRAWER_WIDTH = 264;
