/**
 *
 * Requests ActionMenu Items
 *
 */

import React from 'react';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import { requestStatus } from 'domain/typeConstants/status';
import { requestAction } from 'domain/typeConstants/action';


export const viewActionItem = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
};

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED
    } else if (itemMenuRecord.length !== 0) {
      if (!entities[0].status) return false;
      return entities[0].status.name === requestStatus.CLOSED
    }
    return true;
  }
};

