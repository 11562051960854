/**
 *
 * AdministratorBC Requests DxGrid 14140
 *
 */

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_FOR_ON_PASS,
} from 'domain/Data/Filters/constants';
import { ReceptionDxGrid14140 } from '../ReceptionView';

import {
  DEPARTMENT,
  departmentColumn,
  departmentColumnFilter,

  IS_DISCRETION_OF_SECURITY,
  isDiscretionOfSecurityColumn,
  isDiscretionOfSecurityColumnFilter,
} from '../common/dxGridComponents/columns';

export class AdministratorBCRequestsDxGrid14140 extends ReceptionDxGrid14140 {
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'pass') filters.push(REQUESTS_FOR_ON_PASS);
    return filters;
  }

  getColumns() {
    const { viewName } = this.props;
    let columns = super.getColumns();
    columns = columns.filter((column) => column.name !== 'documentDetails');
    columns.push(departmentColumn);

    if (viewName === 'onCarEntry') {
      columns.push(isDiscretionOfSecurityColumn);
    }

    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    const columnFilters = super.getGridFilters();
    columnFilters.push(departmentColumnFilter);

    if (viewName === 'onCarEntry') {
      columnFilters.push(isDiscretionOfSecurityColumnFilter);
    }

    return columnFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    const columnOrder = super.getColumnOrder();
    const documentDetailsIndex = columnOrder.findIndex((column) => column === 'documentDetails');
    columnOrder.splice(documentDetailsIndex, 1, DEPARTMENT);

    if (viewName === 'onCarEntry') {
      const parkingPlaceIndex = columnOrder.findIndex((column) => column === 'parkingPlace');
      columnOrder.splice(parkingPlaceIndex + 1, 0, IS_DISCRETION_OF_SECURITY);
    }

    return columnOrder;
  }
}
