/**
 *
 * AdministratorBC Requests Form 14366
 *
 */

import { ReceptionForm14366 } from '../ReceptionView';

export class AdministratorBCRequestsForm14366 extends ReceptionForm14366 {
  getFields() {
    const fields = super.getFields();

    const companyIndex = fields.findIndex((field) => field.name === 'company');
    fields[companyIndex].initialValue = (values, mode, user) => user.companyId;
    fields[companyIndex].filterBy = null

    return fields;
  }
}
