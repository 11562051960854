/**
 *
 * AssignedMobileGrid12861
 *
 */

import { IN_PROGRESS_REQUESTS } from "domain/Data/Filters/constants";
import { requestAction } from "domain/typeConstants/action";
import { requestStatus } from "domain/typeConstants/status";
import AssignedMobileGrid from "pages/Requests/AssignedView/AssignedMobileGrid";


export class AssignedMobileGrid12861 extends AssignedMobileGrid {

    getFilters() {
        const { viewName } = this.props;
        const filters = super.getFilters();
        if (viewName === 'active') filters.push(IN_PROGRESS_REQUESTS);
        return filters;
    }

    getActionButtons() {
        return [];
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems()
        const unnecessaryMenuItems = new Set([requestAction.ACCEPT, requestAction.REJECT]);

        const completeActionMenuItemIndex = actionMenuItems.findIndex((item) => item.name === requestAction.COMPLETE);
        actionMenuItems[completeActionMenuItemIndex].label = 'Выполнить';
        actionMenuItems[completeActionMenuItemIndex].hidden = (itemMenuRecord, entities, currentRecord) => {
            if (currentRecord) {
                if (!currentRecord.status) return false
                return currentRecord.status.name === requestStatus.CLOSED
            } else if (itemMenuRecord.length !== 0) {
                if (!entities[0].status) return false;
                return (entities[0].status.name === requestStatus.CLOSED);
            }
            return true;
        }

        const viewActionItemIndex = actionMenuItems.findIndex((item) => item.name === requestAction.VIEW);
        actionMenuItems[viewActionItemIndex].hidden = (itemMenuRecord, entities, currentRecord) => {
            if (currentRecord) {
                if (!currentRecord.status) return false
                return currentRecord.status.name !== requestStatus.CLOSED
            } else if (itemMenuRecord.length !== 0) {
                if (!entities[0].status) return false;
                return (entities[0].status.name !== requestStatus.CLOSED);
            }
            return true;
        }

        return actionMenuItems.filter((item) => !unnecessaryMenuItems.has(item.name));
    }


    getDetailRowFields() {
        const fields = super.getDetailRowFields()

        const companyNameIndex = fields.findIndex((field) => field.name === 'companyName');
        fields[companyNameIndex].title = 'Абонент';

        const phone = {
            name: 'phone',
            getValue: (row) => (row.company ? row.company.phone : undefined),
            title: 'Телефон',
        }

        const client = {
            name: 'client',
            getValue: (row) => (row.company ? row.company.client : undefined),
            title: 'Кто встречает',
        }

        fields.splice(companyNameIndex + 1, 0, phone)
        fields.splice(companyNameIndex + 1, 0, client)

        const implementationReport = {
            name: 'implementationReport',
            title: 'Отчет о выполнении',
        }

        const noteIndex = fields.findIndex((field) => field.name === 'note');
        fields.splice(noteIndex, 0, implementationReport)

        return fields
    }

    getSorting() {
        return [ { columnId: 'whereHappened', columnName: 'whereHappened', direction: 'asc' }];
    }

}

