/**
 *
 * DocumentMatchingFormHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styleSheet = () => ({
  root: {
    margin: '0px auto 24px',
    outline: 'none',
    display: 'flex',
    padding: 20,
    borderRadius: 4,
    fontSize: 15,
    justifyContent: 'left',
    backgroundColor: '#f5f5f5',
  },
  copyButton: {
    margin: '16px 0 0px',
  },
  message: {
    display: 'flex',
  },
  visitDate: {
    margin: '0px 5px',
  },
});

class DocumentMatchingFormHeader extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    classes: PropTypes.object,
    visitorFullName: PropTypes.string,
    documentDetails: PropTypes.string,
    visitDate: PropTypes.string,
    onCopyButtonClick: PropTypes.func,
  };

  handleCopyButtonClick = () => this.props.onCopyButtonClick();

  renderVisitDate() {
    const { visitDate } = this.props;
    if (visitDate) {
      return (
        <FormattedDate value={visitDate} month="long" day="numeric" />
      );
    }
    return null;
  }

  renderHeader() {
    const {
      classes,
      visitorFullName,
      documentDetails,
    } = this.props;

    return (
      <div className={classes.root}>
        <Grid container >
          <Grid item xs={12}>
            <span>{`Посетитель ${visitorFullName || '(фио не указано)'}`}</span>
            <span className={classes.visitDate}>{this.renderVisitDate()}</span>
            <span> {`предъявил(-а) документ № ${documentDetails}`}</span>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.copyButton}
              onClick={this.handleCopyButtonClick}
              color="primary"
              variant="contained"
              autoFocus
            >
              Скопировать данные посетителя
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { documentDetails } = this.props;

    return (
      <div>
        {
          documentDetails && this.renderHeader()
        }
      </div>
    );
  }
}

export default withStyles(styleSheet)(DocumentMatchingFormHeader);
