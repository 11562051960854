/**
 *
 * UnloadingZoneOperatorView actions
 *
 */

import {
  EXECUTE_VIEW_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
} from './constants';

export function executeViewAction(id) {
  return {
    type: EXECUTE_VIEW_ACTION,
    payload: { id },
  };
}

export function executeArrivedAction(ids) {
  return {
    type: EXECUTE_ARRIVED_ACTION,
    payload: { ids },
  };
}

export function executeDepartedAction(ids) {
  return {
    type: EXECUTE_DEPARTED_ACTION,
    payload: { ids },
  };
}
