/**
 *
 * JournalDxGrid
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnWorkSize } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';


class JournalDxGrid extends React.PureComponent {

  getColumns() {
    return [
      {
        id: 'createdBy',
        name: 'createdBy',
        path: 'createdBy',
        title: 'Кто',
        width: columnWorkSize.RESPONSIBLE_NAME,
      },
      {
        id: 'changeDate',
        name: 'changeDate',
        path: 'changeDate',
        title: 'Когда',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        id: 'name',
        name: 'name',
        path: 'name',
        title: 'Изменения',
        type: columnType.FORMAT_TEXT,
        width: columnWorkSize.CHANGES_DESCRIPTION,
      },
    ];
  }

  getColumnOrder() {
    return [
      'createdBy',
      'changeDate',
      'name',
    ];
  }

  getSorting() {
    return [{ columnId: 'changeDate', columnName: 'changeDate', direction: 'desc' }];
  }

  getActionButtons() {
    return []
  }

  getTableFeatures() {
    return [
      { name: settings.SETTING_BUTTON, value: false },
      { name: settings.UPDATE_BUTTON, value: false },
      { name: settings.TABLE_DRAWER, value: false },
    ];
  }

  getFilters() {
    return [];
  }

  render() {
    const { entityName, mode, parent, parentId, height, dialogName, name } = this.props;

    return (
      <AdvancedDataTable
        name={name}
        actionButtons={this.getActionButtons()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        tableType={settings.PAGING_TABLE}
        entityName={entityName}
        mode={mode}
        parent={parent}
        parentId={parentId}
        height={height}
        dialogName={dialogName}
      />
    );
  }
}

JournalDxGrid.propTypes = {
  viewName: PropTypes.string,
};

export default JournalDxGrid;


