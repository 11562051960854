/*
 * Application configuration
 */

let envConfig;

if (process.env.NODE_ENV === 'development') {
  envConfig = require('./config.dev.json');
} else if (process.env.NODE_ENV === 'production') {
  envConfig = require('./config.prod.json');
} else if (process.env.NODE_ENV === 'test') {
  envConfig = require('./config.test.json');
}

const config = envConfig;
export default config;
