/**
 *
 * ReceptionMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';
import { RECEPTION_COMPANIES_DX_GRID } from './constants';
import { addActionButton } from '../common/actionButton';
import { editActionItem, copyActionItem } from '../common/actionMenu';
import { RowComponent, getRowHeight } from '../common/mobile';

class ReceptionMobileGrid extends React.PureComponent {
  getFilters() {
    return [];
  }

  getDetailRowFields() {
    return [
      {
        name: 'name',
        title: 'Наименование',
      },
      {
        name: 'fullName',
        title: 'Полное наименование',
      },
      {
        name: 'type',
        title: 'Вид',
        getValue: (row) => row.type ? row.type.name : undefined,
      },
      {
        name: 'status',
        title: 'Статус',
        getValue: (row) => row.status ? row.status.name : undefined,
      },
      {
        name: 'adress',
        title: 'Адрес',
      },
      {
        name: 'phone',
        title: 'Телефон',
      },
      {
        name: 'note',
        title: 'Примечание',
      },
    ];
  }

  getActionButtons() {
    return [addActionButton];
  }

  getActionMenuItems() {
    return [editActionItem, copyActionItem];
  }

  getSorting() {
    return [{ columnId: 'name', columnName: 'name', direction: 'desc' }];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="companies"
        name={RECEPTION_COMPANIES_DX_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        detailRowFields={this.getDetailRowFields()}
        filters={this.getFilters()}
        getRowHeight={getRowHeight}
        onActionExecuting={onActionExecuting}
        rowComponent={RowComponent}
        sorting={this.getSorting()}
        viewName={viewName}
      />
    );
  }
}

ReceptionMobileGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  viewName: PropTypes.string,
};

export default ReceptionMobileGrid;
