/*
 *
 * DocumentPositionFormContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import DocumentPositionForm from './DocumentPositionForm';
import { makeSelectParentId, } from 'containers/AdvancedFormDialog/selectors'
import * as actionCreators from './actions';
import { DOCUMENT_POSITION_FORM_DIALOG } from './constants';

class DocumentPositionFormContainer extends React.PureComponent {
  render() {
    return (
      <DocumentPositionForm  {...this.props} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  parentId: makeSelectParentId(DOCUMENT_POSITION_FORM_DIALOG),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPositionFormContainer);

