/*
 *
 * FileField sagas
 *
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { change } from 'redux-form/immutable';

import { SAVE_FILE_SUCCESS } from 'domain/Data/File/constants';
import { formField } from '../../../domain/typeConstants/form';

import { CLEAR, SHOW_ERROR_MESSAGE_FILE } from './constants';

import {
  makeSelectMultipleValue,
} from './selectors';

import { openAlertDialog } from "../../AlertDialog/actions";

const fieldFileIsSaved = (action) =>
  action.type === SAVE_FILE_SUCCESS &&
  action.meta.advancedForm &&
  action.meta.field;

export default function* rootSaga() {
  yield takeLatest(fieldFileIsSaved, function* ({ meta, payload }) {
    yield call(handleSavedFileSaga, meta.advancedForm, meta.field, payload.fileId, meta.fieldType);
  });

  yield takeLatest(CLEAR, function* ({ meta, payload }) {
    yield call(clearSaga, meta.advancedForm, meta.field, payload.fileId, meta.fieldType);
  });

  yield takeLatest(SHOW_ERROR_MESSAGE_FILE, function* ({ payload }) {
    yield call(showErrorMessageFileSaga, payload.alertTitle, payload.alertContent);
  });
}

export function* handleSavedFileSaga(formName, field, fileId, fieldType) {
  if (fieldType === formField.MULTIPLE_FILE) {
    let currentValue = yield select(makeSelectMultipleValue(formName, field));
    currentValue = [...currentValue, ...fileId];

    yield put(change(formName, field, [...new Set(currentValue)]));
  } else {
    yield put(change(formName, field, fileId));
  }
}

export function* clearSaga(formName, field, fileId, fieldType) {
  if (fieldType === formField.MULTIPLE_FILE) {
    let currentValue = yield select(makeSelectMultipleValue(formName, field));
    currentValue = currentValue.filter((id) => id !== fileId);

    yield put(change(formName, field, currentValue));
  } else {
    yield put(change(formName, field, null));
  }
}

export function* showErrorMessageFileSaga(alertTitle, alertContent) {
  yield put(openAlertDialog(alertTitle, alertContent));
}
