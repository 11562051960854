/**
 * Snackbar selectors
 */

import { createSelector } from 'reselect';

const makeSelectDomain = () => (state) => state.get('snackbar');

const makeSelectOpen = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('open')
);

const makeSelectMessage = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('message')
);

const makeSelectAutoHideDuration = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('autoHideDuration')
);

export default makeSelectDomain;
export {
  makeSelectOpen,
  makeSelectMessage,
  makeSelectAutoHideDuration,
};
