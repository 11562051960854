/**
 *
 * The screen selectors
 *
 */

import { createSelector } from 'reselect';

import makeSelectGlobal from 'domain/selectors';

const makeSelectDomain = () => createSelector(
  makeSelectGlobal(),
  (globalState) => globalState.get('screen')
);

const makeSelectScreenHeight = () => createSelector(
  makeSelectDomain(),
  (domain) => domain.get('height')
);

const makeSelectScreenWidth = () => createSelector(
  makeSelectDomain(),
  (domain) => domain.get('width')
);

const makeSelectScreenOrientation = () => createSelector(
  makeSelectDomain(),
  (domain) => domain.get('orientation')
);

const makeSelectBreakpoint = () => createSelector(
  makeSelectDomain(),
  (domain) => domain.get('breakpoint')
);

const makeSelectViewMode = () => createSelector(
  makeSelectDomain(),
  (domain) => domain.get('viewMode')
);

export {
  makeSelectScreenHeight,
  makeSelectScreenWidth,
  makeSelectScreenOrientation,
  makeSelectBreakpoint,
  makeSelectViewMode,
};
