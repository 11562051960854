/**
 *
 * Common fields for BP Faktoriya(14419)
 *
 */
import React from 'react';

import * as entity from 'domain/Data/constants';
import { formField } from 'domain/typeConstants/form';

import { PASSPORT } from 'domain/Data/DocumentType/constants';

import {
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  CARRYING_OUT_WORKS,
} from 'domain/Data/RequestType/constants';

import {
  GUEST_PARKING_TYPE,
  UNLOADING_PARKING_TYPE,
} from '../../../domain/ParkingType/constants';

const allowedRequestTypes = new Set([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS]);

/** ---------------- type ---------------- */
export const typeVisibleOptions = () => Array.from(allowedRequestTypes);
export const typeHelperText = (values) => {
  if ([ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type)) {
    return (
      <React.Fragment>
        Длинномеры допускаются на территорию в будние дни в ночное время с 20:00 до 07:00 утра, в выходные и праздничные дни круглосуточно
      </React.Fragment>
    );
  }
  return '';
};

/** ---------------- visitorFullName ---------------- */
export const visitorFullNameHelperText = (values) => {
  if ([ON_CAR_ENTRY].includes(values.type)) {
    return (
        <React.Fragment>
          ФИО и/или из какой компании
        </React.Fragment>
    );
  }
  return '';
};

/** ---------------- visitDate ---------------- */
export const visitDateRowBreakBefore = (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type);

/** ---------------- factoryTime ---------------- */
export const factoryTimeLabel = (values) => {
  if (values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL) {
    return 'Время';
  }
  return 'Время проведения работ';
};
/** ---------------- longterm ---------------- */
export const longtermVisible = (values) => ![ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type);

/** ---------------- expirationDate ---------------- */
export const expirationDateVisible = (values) => values.longterm && ![ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type);

/** ---------------- multipleEntry ---------------- */
export const multipleEntryVisible = (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type);

/** ---------------- nonstandardCarNumber ---------------- */
export const nonstandardCarNumberVisible = (values) => !values.isGroupRequest;

/** ---------------- carNumber ---------------- */
export const carNumberVisible = (values) => !values.isGroupRequest;

/** ---------------- carModelText ---------------- */
export const carModelTextVisible = (values) => !values.isGroupRequest;

/** ---------------- parking ---------------- */
export const parkingVisible = () => true;
export const parkingCalculatedValue = (values) => {
  if (values.type === ON_CAR_ENTRY || values.type === CARRYING_OUT_WORKS) return GUEST_PARKING_TYPE;
  if ([ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type)) return UNLOADING_PARKING_TYPE;
  return null;
};
export const parkingDisabled = (values) => [ON_CAR_ENTRY, ON_PROPERTY_REMOVAL, ON_PROPERTY_BRINGING, CARRYING_OUT_WORKS].includes(values.type);

/** ---------------- parkingPlace ---------------- */
export const parkingPlaceVisible = () => true;
export const parkingPlaceTenantVisible = (values) => ![ON_CAR_ENTRY, CARRYING_OUT_WORKS].includes(values.type);

/** ---------------- companyName ---------------- */
export const companyNameLabel = (values) => {
  if (values.type === CARRYING_OUT_WORKS) {
    return 'Подрядчик';
  }

  return 'Из какой компании';
};

export const companyNameVisible = true;
export const companyNameRequired = (values) => values.type === CARRYING_OUT_WORKS;

/** ---------------- workPlace ---------------- */
export const workPlace = {
  name: 'workPlace',
  label: 'Место проведения работ',
  visible: (values) => values.type === CARRYING_OUT_WORKS,
  required: (values) => values.type === CARRYING_OUT_WORKS,
  helperText: () => 'строение, этаж, комнаты, согласно плану БТИ',
};

/** ---------------- workType ---------------- */
export const workType = {
  type: formField.SELECT,
  entityName: entity.WORK_TYPES,
  name: 'workType',
  label: 'Вид работ',
  visible: (values) => values.type === CARRYING_OUT_WORKS,
  helperText: 'Работы связанные с повышенным образованием шума, пыли и запаха разрешено проводить с 20:00 до 06:00',
  rowBreakBefore: true,
};

export const responsibilityForWork = {
  name: 'responsibilityForWork',
  label: 'Приказ о назначен. отв. Подрядчика',
  helperText: 'Скан Приказа о назначении лиц, ответственных со стороны Подрядчика за выполнение работ по договору',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
export const responsibleForSafetyAndHealth = {
  name: 'responsibleForSafetyAndHealth',
  label: 'Приказ отв. по технике безопасности',
  helperText: 'Скан Приказа на лиц ответственных за охрану труда и технику безопасности при производстве работ по договору с приложением соответствующих удостоверений',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
export const responsibleForFireSafety = {
  name: 'responsibleForFireSafety',
  label: 'Приказ отв. за пожар.безопасность',
  helperText: 'Скан Приказа на лиц, ответственных за пожарную безопасность при производстве работ по договору, за электробезопасность при производстве работ по договору с приложением соответствующих удостоверений',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
export const fireWork = {
  name: 'fireWork',
  label: 'Приказ отв. огневых работ',
  helperText: 'Скан Приказа о лицах, имеющее право подписи в Нарядах на огневые работы при производстве работ по договору',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
export const shutdownEquipment = {
  name: 'shutdownEquipment',
  label: 'Отв. за отключение оборудование',
  helperText: 'Скан уведомление о лицах имеющих право давать заявки на отключение (обесточивание) оборудования (трасс), слив / залив жидкостей, проведении испытаний освидетельствований скрытых работ',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
export const carryInOrTakeawayMCV = {
  name: 'carryInOrTakeawayMCV',
  label: 'Отв. за заявки ТМЦ',
  helperText: 'Скан уведомление о лицах имеющих право давать заявки на внос / вынос товарно – материальных ценностей (ТМЦ), въезд / выезд автомобилей',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
export const productionPersonnel = {
  name: 'productionPersonnel',
  label: 'Список работников с паспортными данными',
  helperText: 'Скан перечень персонала производящего работы с обязательным предоставлением паспортных данных. Если лица, допущенные к выполнению работ не являются гражданами РФ, предоставить копии разрешений пребывания в РФ, копии патентов и прочих разрешающих документов',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};

export const requestScan = {
  name: 'requestScan',
  label: 'Скан заявки',
  helperText: 'по форме Бизнес-Парка',
  type: formField.FILE,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
  required: true,
};

export const isInstructed = {
  name: 'isInstructed',
  label: 'Инструктаж пройден',
  type: formField.CHECKBOX,
  visible: (values) => values.type === CARRYING_OUT_WORKS,
};
/** ---------------- documentType ---------------- */

export const documentTypeVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
  const documentTypes = entities.get(entity.DOCUMENT_TYPES).valueSeq().toJS();

  if (documentTypes && currentValues.type === CARRYING_OUT_WORKS) {
    return documentTypes
      .filter((documentType) => documentType.id !== PASSPORT)
      .map((documentType) => documentType.id);
  }

  return currentValues.documentType;
};
