/*
 *
 * AdminSkudRejectForm
 *
 */


import ReceptionRejectForm from '../ReceptionView/ReceptionRejectForm';

export default class AdminSkudRejectForm extends ReceptionRejectForm {

    getFields() {
        const fields = super.getFields();
        const unnecessaryFields = new Set(['parkingPlace','carNumber','carModelText','parking']);
        return fields.filter((field) => !unnecessaryFields.has(field.name));
    }

}