/**
 *
 * ReceptionForm15783
 *
 */

import React from 'react';
import {ReceptionForm} from "pages/Requests/ReceptionView";
import {ON_PASS_MAKING} from 'domain/Data/RequestType/constants';
import {issueReasonType} from "../common/constans";


export class ReceptionForm15783 extends ReceptionForm {

    getFields() {
        const fields = super.getFields();

        const visitDateIndex = fields.findIndex((field) => field.name === 'visitDate');
        const companyIndex = fields.findIndex((field) => field.name === 'company');
        const company = {...fields[companyIndex]}

        fields.splice(companyIndex, 1)
        fields.splice(visitDateIndex + 1, 0, company)

        const visitorFullNameIndex = fields.findIndex((field) => field.name === 'visitorFullName');
        const accessLevel =
            {
                name: 'vozdvizhenkaAccessLevel',
                type: 'select',
                entityName: 'vozdvizhenkaAccessLevels',
                label: 'Уровень доступа',
                visible: (values) => values.type === ON_PASS_MAKING,
                orderBy: 'id',
            }
        fields.splice(visitorFullNameIndex + 1, 0, accessLevel)

        const fileIndex = fields.findIndex((field) => field.name === 'file');
        const issueReason = {
            name: 'issueReason',
            type: 'select',
            label: 'Причина выдачи',
            entityName: 'issueReasons',
            visible: (values) => values.type === ON_PASS_MAKING,
            visibleOptions: () => [issueReasonType.NEW_EMPLOYEE, issueReasonType.LOST]
        }
        fields.splice(fileIndex + 1, 0, issueReason)

        return fields;
    }
}
