/*
 *
 * RequestsPage14002
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import TenantForm14002 from './TenantView/TenantForm14002';
import { ReceptionForm14002, ReceptionFormDialog14002 } from './ReceptionView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';

function RequestsPage14002(props) {

  return (
    <RequestsPage
      administratorBCForm={<ReceptionForm14002 />}
      administratorBCFormDialog={
        <ReceptionFormDialog14002
          form={<ReceptionForm14002 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      receptionForm={<ReceptionForm14002 />}
      receptionFormDialog={
        <ReceptionFormDialog14002
          form={<ReceptionForm14002 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      tenantForm={<TenantForm14002 />}
      {...props}
    />
  );
}

export default RequestsPage14002;
