/*
 *
 *  mobile row template for rules
 *
 */


import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    isCurrentYear,
    formattedDate,
    formattedNumeric,
    formattedCurrentYearDate,
} from 'utils/dateUtils';

import { withStyles } from '@material-ui/core/styles/index';

const styles = () => ({
    root: {
        width: '100%',
        padding: '0 5px',
    },
    label: {
        padding: '1px 5px',
        textTransform: 'uppercase',
        borderRadius: 15,
    },
    highlight: {
        fontWeight: 'bold',
    },
    lineItem: {
        marginLeft: 5,
    },
    overflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const HeaderComponent = ({classes, number}) => {
    return (
        <div className={classes.overflow}>
            <span>
                № <span>{number}</span>
            </span>
        </div>
    );
};

HeaderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    number: PropTypes.number,
};

const FooterComponent = ({classes, actualCreationDate}) => {
    return (
        <div className={classes.overflow}>
            <span>
                {actualCreationDate}
            </span>
        </div>
    );
};

FooterComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    actualCreationDate: PropTypes.string,
};

const currentYearDateOptions = {
    day: 'numeric',
    month: 'long',
};

class RowComponentBase extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        record: PropTypes.object.isRequired,
        maxWidth: PropTypes.number.isRequired,
    };

    /*shouldComponentUpdate(nextProps, nextState, nextContext) {

    }*/

    render() {
        const {record, classes, maxWidth} = this.props;

        const number = record.number && formattedNumeric(record.number);
        const actualCreationDate = record.actualCreationDate && isCurrentYear(record.actualCreationDate)
            ? formattedCurrentYearDate(record.actualCreationDate, currentYearDateOptions)
            : formattedDate(record.actualCreationDate);

        return (
            <div className={classes.root} style={{ maxWidth }}>
                <HeaderComponent
                    classes={classes}
                    number={number}
                />

                <FooterComponent
                    classes={classes}
                    actualCreationDate={actualCreationDate}
                />
            </div>
        );
    }
}

export const RowComponent = withStyles(styles)(RowComponentBase);
