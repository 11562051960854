/**
 * utils for dates
 */

import {
  isDate,
  isString,
  getTypeInfo,
} from './typeUtils';

const currentYearDateOptions = {
  day: 'numeric',
  month: 'short',
};
const dateOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};
const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
};
const timeWithSecondsOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const dayFormat = Object.freeze({
  DAY_IN_MILLISECONDS: 86400000,
});

/* ----------------------------------------------------------------
                  formatting date functions
---------------------------------------------------------------- */
export const formattedCurrentYearDate = (value, options) =>
  (value ? Intl.DateTimeFormat('ru-RU', options || currentYearDateOptions).format(new Date(value)) : null);
export const formattedDate = (value, options) =>
  (value ? Intl.DateTimeFormat('ru-RU', options || dateOptions).format(new Date(value)) : null);
export const formattedNumeric = (value) => (value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : null);
export const formattedTime = (value, options) =>
  (value ? Intl.DateTimeFormat('ru-RU', options || timeOptions).format(new Date(value)) : null);
export const formattedTimeWithSeconds = (value, options) =>
  (value ? Intl.DateTimeFormat('ru-RU', options || timeWithSecondsOptions).format(new Date(value)) : null);

/* ----------------------------------------------------------------
                  date functions
---------------------------------------------------------------- */
export const isCurrentYear = (value) => {
  const currentYear = new Date().getFullYear();
  const yearFromTableValue = new Date(value).getFullYear();
  return currentYear === yearFromTableValue;
};

export const convertToDateTimestamp = (value) => {
  let date = null;

  if (isString(value)) {
    date = new Date(value);
  } else if (isDate(value)) {
    date = value;
  } else {
    throw new Error(`parameter "value" must be "Date" or "String" type, but got "${getTypeInfo(value)}"`);
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};

export const convertToDateTimeTimestamp = (value) => {
  if (isString(value)) {
    return new Date(value).getTime();
  } else if (isDate(value)) {
    return value.getTime();
  }

  throw new Error(`parameter "value" must be "Date" or "String" type, but got "${getTypeInfo(value)}"`);
};

export const convertTimeToMinutes = (value) => {
  let date = null;

  if (isString(value)) {
    date = new Date(value);
  } else if (isDate(value)) {
    date = value;
  } else {
    throw new Error(`parameter "value" must be "Date" or "String" type, but got "${getTypeInfo(value)}"`);
  }

  return ((date.getHours() * 60) + date.getMinutes());
};

export const isTwoDatesEqualsByDay = (firstDate, secondDate) => {
  const firstValue = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()).getTime();
  const secondValue = new Date(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate()).getTime();

  return (firstValue === secondValue);
};

export const convertDatetoString = (date) => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) 
}

export const convertDateTimetoString = (date) => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
  + 'T'  + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
}