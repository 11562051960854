/**
 *
 * Requests ActionMenu Items
 *
 */

import React from 'react';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Close from '@material-ui/icons/Close';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import { requestStatus } from 'domain/typeConstants/status';
import { requestAction } from 'domain/typeConstants/action';

export const viewActionItem = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return !(currentRecord.status.statusZadachi === requestStatus.COMPLETED || currentRecord.status.statusZadachi === requestStatus.CLOSED)
    } else
      if (itemMenuRecord.length === 1) {
        const status = entities[0].status && entities[0].status.statusZadachi;
        if (!status) return true;
        return !(status === requestStatus.COMPLETED || status === requestStatus.CLOSED);
      }
    return true;
  },
};

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};

export const closeActionItem = {
  name: requestAction.CLOSE,
  label: 'Закрыть',
  icon: 'close',
  svgIcon: <Close />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return (currentRecord.status.name === requestStatus.COMPLETED || currentRecord.status.name === requestStatus.CLOSED)
    } else
      if (itemMenuRecord.length !== 0) {
        if (!entities[0].status) return false;
        return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED);
      }
    return true;
  },
};


export const completeActionItem = {
  name: requestAction.COMPLETE,
  label: 'Выполнить',
  icon: 'check_circle_outline',
  svgIcon: <CheckCircleOutline />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return (currentRecord.status.name === requestStatus.CLOSED)
    } else
      if (itemMenuRecord.length !== 0) {
        if (!entities[0].status) return false;
        return (entities[0].status.name === requestStatus.CLOSED);
      }
    return true;
  },
};

