/*
 *
 * ContactsPageContainer14326
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';

import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';

import ContactsPage14326 from './ContactsPage14326';

class ContactsPageContainer14326 extends React.PureComponent {
  render() {
    const { accountId, userRoles, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, ...restProps };
    return (
      <ContactsPage14326 {...props} />
    );
  }
}

ContactsPageContainer14326.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
});

export default connect(mapStateToProps)(ContactsPageContainer14326);
