/**
 *
 * UsersForm
 *
 */


import UsersForm from "pages/Settings/UsersView/UsersForm";

export class UsersForm14326 extends UsersForm {

  getFields() {
    let fields = super.getFields()

    const rolesIndex = fields.findIndex((field) => field.name === 'roles');

    const tenantPermanentAccessCardKey = {
      name: 'tenantPermanentAccessCardKey',
      label: 'Ключ карты постоянного арендатора',
      rowBreakBefore: true,
    }

    fields.splice(rolesIndex, 0, tenantPermanentAccessCardKey)

    return fields
  }
}