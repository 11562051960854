/**
 *
 * Screen reducer
 *
 */

import { Map } from 'immutable';

import {
  CHANGE_BREAKPOINT,
  CHANGE_SCREEN_PROPERTIES,
  INITIALIZE_SCREEN_PROPERTIES,
} from './constants';

const initialState = new Map({
  breakpoint: null,
  height: null,
  orientation: null,
  width: null,
  viewMode: null,
});

const screenReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_BREAKPOINT:
      return state.set('breakpoint', payload.breakpoint);

    case CHANGE_SCREEN_PROPERTIES:
      return state
        .set('breakpoint', payload.breakpoint)
        .set('height', payload.height)
        .set('orientation', payload.orientation)
        .set('width', payload.width)
        .set('viewMode', payload.viewMode);

    case INITIALIZE_SCREEN_PROPERTIES:
      return state
        .set('breakpoint', payload.breakpoint)
        .set('height', payload.height)
        .set('orientation', payload.orientation)
        .set('viewMode', payload.viewMode)
        .set('width', payload.width);

    default:
      return state;
  }
};

export default screenReducer;
