/**
 *
 * Normalizr schemes
 *
 *
 */

import { schema } from 'normalizr';

import * as C from './constants';

/** ===============================
 *        REQUEST SCHEMES
 * ============================== */
export const AccessCard = new schema.Entity(C.ACCESS_CARDS);
export const BlackList = new schema.Entity(C.BLACK_LIST);
export const CarModel = new schema.Entity(C.CAR_MODELS);
export const Comment = new schema.Entity(C.COMMENTS);
export const Department = new schema.Entity(C.DEPARTMENTS);
export const DocumentType = new schema.Entity(C.DOCUMENT_TYPES);
export const File = new schema.Entity(C.FILES);
export const IssueReason = new schema.Entity(C.ISSUE_REASONS);
export const Manager = new schema.Entity(C.MANAGERS);
export const ParkingType = new schema.Entity(C.PARKING_TYPES);
export const PassType = new schema.Entity(C.PASS_TYPES);
export const RequestStatus = new schema.Entity(C.REQUEST_STATUSES);
export const RequestType = new schema.Entity(C.REQUEST_TYPES);
export const RequestPriority = new schema.Entity(C.REQUEST_PRIORITIES);
export const ReservationTime = new schema.Entity(C.RESERVATION_TIMES);
export const Time = new schema.Entity(C.TIMES);
export const VehicleType = new schema.Entity(C.VEHICLE_TYPES);
export const WorkingTime = new schema.Entity(C.WORKING_TIMES);
export const WorkType = new schema.Entity(C.WORK_TYPES);
export const Attachment = new schema.Entity(C.ATTACHMENTS);
export const CountAttachments = new schema.Entity(C.COUNT_ATTACHMENTS);
export const Fired = new schema.Entity(C.FIRED);
export const JobDoing = new schema.Entity(C.JOB_DOINGS);


/** requests @vozdvizhenka(14691) */
export const VozdvizhenkaAccessLevel = new schema.Entity(C.VOZDVIZHENKA_ACCESS_LEVELS);

/** requests @bc-comcity(12970) */
export const Housing = new schema.Entity(C.HOUSINGS);

/** requests @atc-berlinsky-genevsky(14140) */
export const AccessArea = new schema.Entity(C.ACCESS_AREAS);
export const ActionCard = new schema.Entity(C.ACTION_CARDS);
export const MaterialCommodityValueType = new schema.Entity(C.MATERIAL_COMMODITY_VALUE_TYPES);
export const VisitTime = new schema.Entity(C.VISIT_TIMES);

/** requests @bc-khimki(14326) */
export const AccessLevel = new schema.Entity(C.ACCESS_LEVELS);
export const KhimkiRequestType = new schema.Entity(C.KHIMKI_REQUEST_TYPES);
export const Contact = new schema.Entity(C.CONTACTS);
export const WorkingHour = new schema.Entity(C.WORKING_HOURS);

/** requests @bp-factoriya(14419) */
export const Position = new schema.Entity(C.POSITIONS);

export const ContractorStatus = new schema.Entity(C.CONTRACTOR_STATUSES);
export const ContractorType = new schema.Entity(C.CONTRACTOR_TYPES);

export const Company = new schema.Entity(C.COMPANIES, {
  department: Department,
  status: ContractorStatus,
  type: ContractorType,
});


export const Employee = new schema.Entity(C.EMPLOYEES, {
  company: Company,
  department: Department,
  position: Position,
  fired: Fired,
});
export const Parking = new schema.Entity(C.PARKINGS, {
  type: ParkingType,
});
export const Service = new schema.Entity(C.SERVICES, {
  manager: Manager,
});

export const Request = new schema.Entity(C.REQUESTS, {
  accessCard: AccessCard,
  assignee: Employee,
  attachments: [Attachment],
  countAttachments: CountAttachments,
  author: Employee,
  blackList: BlackList,
  carModel: CarModel,
  comments: [Comment],
  company: Company,
  customer: Employee,
  department: Department,
  companyDepartment: Department,
  documentType: DocumentType,
  file: File,
  housing: Housing,
  issueReason: IssueReason,
  manager: Employee,
  parking: Parking,
  parkingType: ParkingType,
  passType: PassType,
  photo: File,
  passportPhoto: File,
  reservationTime: ReservationTime,
  requestPriority: RequestPriority,
  service: Service,
  status: RequestStatus,
  time: Time,
  type: RequestType,
  vehicleType: VehicleType,
  workingTime: WorkingTime,
  workType: WorkType,
  agreedEmployee: Employee,
  jobDoing: JobDoing,

  // atc berlinksy/genevsky
  berlinskyAccessArea: AccessArea,
  berlinskyAccessAreas: AccessArea,
  berlinskyActionCard: ActionCard,
  berlinskyMaterialCommodityValueTypes: MaterialCommodityValueType,
  berlinskyTime: Time,
  berlinskyVisitTime: VisitTime,

  // bc khimki
  khimkiTime: Time,
  khimkiRequestType: KhimkiRequestType,
  khimkiAccessLevel: AccessLevel,
  startWorkingHour: WorkingHour,
  endWorkingHour: WorkingHour,
  parkings: Parking,
  memorandumScan: File,

  // bp faktoriya
  carryInOrTakeawayMCV: File,
  fireWork: File,
  productionPersonnel: File,
  responsibilityForWork: File,
  responsibleForFireSafety: File,
  responsibleForSafetyAndHealth: File,
  shutdownEquipment: File,
  requestScan: File,
  factoryTime: Time,

  // vozdvizhenka
  vozdvizhenkaAccessLevel: VozdvizhenkaAccessLevel,
  vozdvizhenkaTime: Time,

  //gorkiy park
  acceptedByTenant: Employee,
  estimationCost: File,
  estimationCostScan: File,
});


/** ===============================
 *        REQUEST CHANGE SCHEMES
 * ============================== */

export const RequestChanges = new schema.Entity(C.TASK_CHANGES);


/** ===============================
 *        DOCUMENT SCHEMES
 * ============================== */
export const AgreementStep = new schema.Entity(C.AGREEMENT_STEPS);
export const ContractType = new schema.Entity(C.CONTRACT_TYPES);
export const Contract = new schema.Entity(C.CONTRACTS, {
  company: Company,
  type: ContractType,
});
export const DocumentCategory = new schema.Entity(C.DOCUMENT_CATEGORIES);
export const DocumentKind = new schema.Entity(C.DOCUMENT_KINDS);
export const DocumentState = new schema.Entity(C.DOCUMENT_STATES);
export const AgreementState = new schema.Entity(C.AGREEMENT_STATES);
export const AgreementScheme = new schema.Entity(C.AGREEMENT_SCHEMES, {
  state: AgreementState,
});
export const ObjectStructure = new schema.Entity(C.OBJECT_STRUCTURE_TYPES);

export const Project = new schema.Entity(C.PROJECTS);

export const EstimateItem = new schema.Entity(C.ESTIMATE_ITEMS, { project: Project });




export const Document = new schema.Entity(C.DOCUMENTS, {
  agreementScheme: AgreementScheme,
  agreementStep: AgreementStep,
  attachments: [Attachment],
  countAttachments: CountAttachments,
  author: Employee,
  category: DocumentCategory,
  company: Company,
  contract: Contract,
  file: File,
  kind: DocumentKind,
  responsible: Employee,
  scan: File,
  state: DocumentState,
  objectStructure: ObjectStructure,
  project: Project,
});

export const AgreementHistory = new schema.Entity(C.AGREEMENT_HISTORIES, {
  author: Employee,
  // document: Document, // зацикливает загрузку диалога
  responsible: Employee,
  scheme: AgreementScheme,
  state: AgreementState,
});

/** ===============================
 *          USER SCHEMES
 * ============================== */
export const Role = new schema.Entity(C.ROLES);

export const User = new schema.Entity(C.USERS, {
  employee: Employee,
  company: Company,
  department: Department,
  // identityBlock: IdentityBlock,
  roles: [Role],
});

/** ===============================
 *        VISITOR LOG SCHEMES
 * ============================== */
export const VisitorLog = new schema.Entity(C.VISITOR_LOGS, {
  author: Employee,
  request: Request,
});


/** ===============================
 *         NOTIFICATIONS SCHEMES
 * ============================== */
export const NotificationCategory = new schema.Entity(C.NOTIFICATION_CATEGORIES);

export const NotificationType = new schema.Entity(C.NOTIFICATION_TYPES, {
  category: NotificationCategory,
});

export const Notification = new schema.Entity(C.NOTIFICATIONS, {
  type: NotificationType,
  category: NotificationCategory,
  author: Employee,
  receiver: Employee,
});




/** ===============================
 *        TASK SCHEMES
 * ============================== */


export const TaskNumber = new schema.Entity(C.NUMBER);
export const TaskFinishDate = new schema.Entity(C.FINISH_DATE);
export const TaskRegistrationDate = new schema.Entity(C.REGISTRATION_DATE);
export const TaskDescription = new schema.Entity(C.DESCRIPTION);
export const TaskAuthor = new schema.Entity(C.AUTHOR);
export const TaskResponsible = new schema.Entity(C.RESPONSIBLE);
export const TaskStatus = new schema.Entity(C.TASK_STATUSES);
export const TaskCompany = new schema.Entity(C.COMPANY);
export const TaskFile = new schema.Entity(C.FILE);
export const TaskName = new schema.Entity(C.NAME);
export const TaskComment = new schema.Entity(C.COMMENT);
export const TaskTime = new schema.Entity(C.TIME);
export const TaskChangeDate = new schema.Entity(C.TASK_CHANGE_DATE);
export const LastAction = new schema.Entity(C.LAST_ACTION);
export const TaskType = new schema.Entity(C.TASK_TYPES);

export const RequestTask = new schema.Entity(C.TASKS, {
  author: TaskAuthor,
  responsible: TaskResponsible,
  status: TaskStatus,
  company: TaskCompany,
  file: TaskFile,
  attachments: [Attachment],
  countAttachments: CountAttachments,
  type: TaskType,
  document: Document,
});


/** ===============================
 *        TASK CHANGE SCHEMES
 * ============================== */

export const TaskChanges = new schema.Entity(C.TASK_CHANGES);

/** ===============================
 *        WORK SCHEMES
 * ============================== */

export const WorkRunDate = new schema.Entity(C.WORK_RUN_DATE);
export const WorkDescription = new schema.Entity(C.WORK_DESCRIPTION);
export const WorkAuthor = new schema.Entity(C.WORK_AUTHOR);
export const WorkTask = new schema.Entity(C.WORK_TASK);
export const WorkRequestTask = new schema.Entity(C.REQUEST);
export const WorkDuration = new schema.Entity(C.WORK_DURATION);

export const Works = new schema.Entity(C.WORKS, {
  author: WorkAuthor,
  task: WorkTask,
  request: WorkRequestTask,
}
);

export const GoodsAndServices = new schema.Entity(C.GOODS_AND_SERVICES)
export const DocumentPositionStates = new schema.Entity(C.DOCUMENT_POSITION_STATES)
export const DocumentPositions = new schema.Entity(C.DOCUMENT_POSITIONS
  , {
  asset: GoodsAndServices,
  state: DocumentState,
  estimateItem: EstimateItem,
  // alarm
}
)
