/**
 *
 * Parking12970 constants
 *
 */

export const CENTRAL_ENTRANCE = '3749501872000';
export const STOPPING_TRANSPORT_AT_CENTRAL_ENTRANCE = '3749501876000';
export const STOPPING_TRANSPORT_ON_SIDE_ENTRANCE = '3749501877000';
export const TECHNICAL_UNLOADING_ZONE = '3749501867000';
export const SIDE_ENTRANCE_IN_MARKET = '3749501871000';
export const STILOBAT_COURTYARD = '3749501873000';
export const STILOBAT_CENTRAL_ENTRANCE = '3749501875000';
export const ON_CIRCLE_104 = '3947480745000';
export const RAMP_104_POINT_2 = '3947480751000';
export const RAMP_102_POINT_1_AND_102_POINT_2 = '3947480757000';
