/**
 *
 * ReceptionDxGrid15511
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  ON_CREATE_REQUESTS,
  ACTIVE_REQUESTS,
  REQUESTS_ON_WORK_PRODUCTION,
  REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED,
} from 'domain/Data/Filters/constants';

export class ReceptionDxGrid15511 extends ReceptionDxGrid {

  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (viewName === 'property') { filters.push(REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED); }
    return filters;
  }

}
