/**
 *
 * TaskDxGrid
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnTaskSize } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import {
  ACTIVE_TASKS, TASKS_FOR_USER_DEPARTMENT,
  USER_AUTHOR_TASK, USER_RESPONSIBLE_TASK
} from 'domain/Data/Filters/constants';
import { USER_TASK_TABLE_GRID } from './constants';

import {
  closeActionButton,
  completeActionButton,
  addActionButton,
  editActionButton,
}
  from '../common/actionButton';
import {
  editActionItem,
  completeActionItem,
  closeActionItem,
} from '../common/actionMenu';
import { columnStatusLabels } from '../common/columnStatusLabel';

import * as actionCreators from './actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTaskDetailContainer } from 'pages/common/detailContainers';


class TaskDxGrid extends React.PureComponent {
  getFilters() {
    const { viewName } = this.props;
    let filters = [TASKS_FOR_USER_DEPARTMENT];
    if (viewName === 'activeTask') filters.push(ACTIVE_TASKS);
    if (viewName === 'userAuthorTasks') filters = [USER_AUTHOR_TASK];
    if (viewName === 'userResponsibleTasks') filters = [USER_RESPONSIBLE_TASK];
    return filters;
  }

  getColumns() {
    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnTaskSize.NUMERIC,
      },
      {
        name: 'status',
        path: ['status', 'name'],
        getCellValue: (row) => (row.status ? row.status.statusZadachi : undefined),
        title: 'Статус',
        labels: columnStatusLabels,
        type: columnType.LABEL,
        entityName: 'taskStatuses',
        width: columnTaskSize.STATUS_NAME,
      },
      {
        name: 'registrationDate',
        path: 'registrationDate',
        title: 'Создано',
        type: columnType.DATE,
        width: columnTaskSize.DATE,
      },
      {
        name: 'taskChangeDate',
        path: 'taskChangeDate',
        title: 'Изменено',
        type: columnType.DATE,
        width: columnTaskSize.DATE,
      },
      {
        name: 'company',
        path: ['company', 'name'],
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Контрагент',
        width: columnTaskSize.COMPANY,
      },
      {
        name: 'description',
        path: 'description',
        title: 'Что сделать',
        type: columnType.FORMAT_TEXT,
        width: columnTaskSize.NAME,
      },
      {
        name: 'responsible',
        path: ['responsible', 'name'],
        getCellValue: (row) => (row.responsible ? row.responsible.name : undefined),
        title: 'Ответственный',
        width: columnTaskSize.RESPONSIBLE_NAME,
      },
      {
        name: 'finishDate',
        path: 'finishDate',
        title: 'Завершить к',
        type: columnType.DATE,
        width: columnTaskSize.DATE,
      },
      {
        name: 'author',
        path: ['author', 'name'],
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: columnTaskSize.AUTHOR_NAME,
      },
      {
        name: 'department',
        path: ['department', 'name'],
        getCellValue: (row) => (row.department ? row.department.name : undefined),
        title: 'Отдел',
        width: columnTaskSize.COMPANY,
      },
      {
        name: 'countAttachments',
        path: ['countAttachments', 'name'],
        getCellValue: (row) => (row.attachments.length > 0 ? "+" + row.attachments.length : undefined),
        title: 'Файл',
        filter: false,
        width: columnTaskSize.FILE,
        sortable: false,
      },
      {
        name: 'lastAction',
        path: 'lastAction',
        title: 'Отчет о выполнении',
        type: columnType.FORMAT_TEXT,
        width: columnTaskSize.DESCRIPTION,
      },
      // {
      //   name: 'executors',
      //   path: 'executors',
      //   title: 'Соисполнители',
      // },
      {
        name: 'document',
        path: ['document', 'name'],
        getCellValue: (row) => (row.document ? row.document.name : undefined),
        title: 'Документ',
        width: columnTaskSize.COMPANY,
      },
    ];
  }

  getColumnOrder() {
    return ['number', 'status', 'company', 'description', 'responsible',
      'finishDate', 'author', 'department', 'document', 'fileName',
      'countAttachments', 'lastAction', 'registrationDate', 'taskChangeDate',
      // 'executors',
    ];
  }

  getActionButtons() {
    return [
      addActionButton, editActionButton, completeActionButton, closeActionButton,
    ];
  }

  getActionMenuItems() {
    return [
      editActionItem,
      completeActionItem,
      closeActionItem,
    ];
  }

  getSorting() {
    const { viewName } = this.props;
    if (viewName === 'activeTask' || viewName === 'userAuthorTasks' || viewName === 'userResponsibleTasks')
      return [{ columnId: 'taskChangeDate', columnName: 'Изменено', direction: 'desc' }];
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: true },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;
    return (
      <AdvancedDataTable
        entityName="tasks"
        name={USER_TASK_TABLE_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        detailContainer={getTaskDetailContainer()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />

    );
  }
}

TaskDxGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  viewName: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(TaskDxGrid);
