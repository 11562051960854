/**
 *
 * ReceptionRejectForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { REJECTED } from 'domain/Data/RequestStatus/constants';

import { validateCarNumber } from 'utils/validators';

import {
  // eslint-disable-next-line no-unused-vars
  ON_CAR_ENTRY, ON_PASS_MAKING, ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';

import { REJECT_FORM } from './constants';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';
import { visitorFullNameLabel } from '../common/fields';

class ReceptionRejectForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const currentDate = new Date();
    return [
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: () => false,
        calculatedValue: () => REJECTED,
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        required: true,
        visible: (values) => values.type !== SERVICE,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        disabled: true,
        rowBreakBefore: true,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'company',
        type: 'autocomplete',
        label: 'Арендатор',
        entityName: 'companies',
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        orderBy: 'name',
        rowBreakBefore: true,
        disabled: true,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'department',
        type: 'select',
        entityName: 'departments',
        label: 'Здание',
        visible: (values) => values.type === ON_PASS_MAKING,
        disabled: true,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT && values.type !== ON_PASS_MAKING,
        disabled: true,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT && values.type !== ON_PASS_MAKING,
        disabled: true,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT && values.type !== ON_PASS_MAKING,
        cached: false,
        calculatedValue: (values) => values.parking,
        disabled: true,
      },
      {
        name: 'parkingPlace',
        label: '№ места',
        visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT && values.type !== ON_PASS_MAKING,
        disabled: true,
        initialValue: () => null,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        visible: (values) => values.type === ON_VISIT || values.type === ON_PASS_MAKING,
        required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
        disabled: true,
        rowBreakBefore: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        required: true,
        grid: { sm: 12 },
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={REJECT_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default ReceptionRejectForm;
