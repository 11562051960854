/**
*
* DateFieldCreator
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import createDateFieldContainer from './createDateFieldContainer';

class DateFieldCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };
  static propTypes = {
    clearErrorsOnChange: PropTypes.bool,
    name: PropTypes.string.isRequired,
  };

  render() {
    const { clearErrorsOnChange, ...restProps } = this.props; // eslint-disable-line no-unused-vars

    const DateFieldContainer = createDateFieldContainer(this.context.advancedForm, this.props.name);
    return <DateFieldContainer formatmask = {this.props.formatmask}
    {...restProps} />;
  }
}

export default DateFieldCreator;
