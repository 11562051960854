import React from 'react'


class PlainTextViewer extends React.Component {

  componentWillUnmount() {
    const { clearDownloadLink } = this.props.data;
    if (clearDownloadLink) clearDownloadLink();
  }

  componentDidMount() {
    const { data } = this.props
    if (data.value) {
      const rawFile = new XMLHttpRequest();
      rawFile.open("GET", data.value, true);
      rawFile.send();
      rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4) {
          if (rawFile.status === 200 || rawFile.status == 0) {
            document.getElementById('viewer').innerText = rawFile.responseText;
          }
        }
      }
    }
  }

  render() {
    const { styles } = this.props;
    return (
      <div id="viewer" style={styles} />
    )
  }
}

export default PlainTextViewer;
