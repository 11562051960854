/*
 * TenantView sagas
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionTypes, stopAsyncValidation } from 'redux-form/immutable';

import * as api from 'utils/api';

import { loadData } from 'containers/AdvancedDataTable/actions';
import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openEditDialog,
  openViewDialog,
  openCopyDialog,
} from 'containers/AdvancedFormDialog/actions';

import { SERVICE } from 'domain/Data/RequestType/constants';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';

import { acceptRequestsSaga, closedByTenantRequestsSaga, closeRequestsSaga } from 'domain/Data/Request/sagas';

import {
  openAlertDialog,
} from 'containers/AlertDialog/actions';

import {
  makeSelectCurrentValues,
} from 'containers/AdvancedForm/selectors';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import {
  makeSelectEntities,
} from 'domain/Data/selectors';

import {
  validateParkingPlace,
} from 'utils/validators';

import {
  TENANT_REQUESTS_FORM,
  TENANT_REQUESTS_TABLE_GRID,
  TENANT_REQUESTS_FORM_DIALOG,
  TENANT_AGREED_ESTIMATION_COST_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_CLOSED_BY_TENANT_ACTION,
  EXECUTE_AGREED_ESTIMATION_COST_ACTION,
} from './constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const isTenantFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  action.meta.dialog === TENANT_REQUESTS_FORM_DIALOG;

const isAsyncValidationStarted = (action) => action.type === actionTypes.START_ASYNC_VALIDATION &&
  action.meta.form === TENANT_REQUESTS_FORM;

export default function createTenantViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_COPY_ACTION, (config.executeCopyActionSaga || executeCopyActionSaga));
    yield takeLatest(EXECUTE_CLOSE_ACTION, (config.executeCloseActionSaga || executeCloseActionSaga));
    yield takeLatest(EXECUTE_ACCEPT_ACTION, (config.executeAcceptActionSaga || executeAcceptActionSaga));
    yield takeLatest(EXECUTE_CLOSED_BY_TENANT_ACTION, (config.executeClosedByTenantActionSaga || executeClosedByTenantActionSaga));
    yield takeLatest(EXECUTE_AGREED_ESTIMATION_COST_ACTION, (config.executeAgreedEstimationCostActionSaga || executeAgreedEstimationCostActionSaga));

    yield takeLatest(isAsyncValidationStarted, asyncValidationSaga);

    yield takeLatest([isTenantFormSaved, DISPLAY_NOTIFICATION], (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  const mode = yield select(makeSelectViewMode());
  if (mode === 'mobile') {
    yield put(loadMobileData(TENANT_REQUESTS_TABLE_GRID))
  } else yield put(loadData(TENANT_REQUESTS_TABLE_GRID));
}

export function* executeAddActionSaga() {
  yield put(openAddDialog(TENANT_REQUESTS_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(TENANT_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(TENANT_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeCopyActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openCopyDialog(TENANT_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeCloseActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(closeRequestsSaga, ids);
  yield put(loadData(TENANT_REQUESTS_TABLE_GRID));
}

export function* executeAcceptActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(acceptRequestsSaga, ids);
  yield put(loadData(TENANT_REQUESTS_TABLE_GRID));
}

export function* executeClosedByTenantActionSaga({ payload: { id } }) {
  yield checkUserForBlockingSaga()
  yield call(closedByTenantRequestsSaga, id);
  yield put(loadData(TENANT_REQUESTS_TABLE_GRID));
}

export function* executeAgreedEstimationCostActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(TENANT_AGREED_ESTIMATION_COST_FORM_DIALOG, { id }));
  }
}

function* asyncValidationSaga() {
  const requestData = yield select(makeSelectCurrentValues(TENANT_REQUESTS_FORM));
  const result = yield call(searchBlackListItems, requestData.visitorFullName, requestData.carNumber);

  const alertTitle = null;
  let alertContent = null;

  if (requestData.type !== SERVICE && result.length > 0) {
    const isLevel = result.some((item) => item.level === true);
    const element = result.find((item) => {
      if (item.level === false) return item;
      return null;
    });

    if (element && !isLevel) {
      alertContent = 'Посетитель в черном списке, обратитесь в управляющую компанию\n';
    }
  }

  if (requestData.parkingPlace) {
    let parkings = yield select(makeSelectEntities());
    parkings = parkings.get('parkings').toArray();
    alertContent = validateParkingPlace(requestData, parkings, alertContent);
  }

  if (alertContent && alertContent.length > 0) {
    yield put(openAlertDialog(alertTitle, alertContent));
  }

  yield put(stopAsyncValidation(TENANT_REQUESTS_FORM));
}

function* searchBlackListItems(visitorFullName, carNumber) {
 if (!visitorFullName &&  !carNumber) return []
  const options = {};

  if (visitorFullName) {
    options.filterBy = `visitorFullName.ToLower().Trim() == "${visitorFullName}"`;
  }

  if (carNumber) {
    options.filterBy = options.filterBy ? `${options.filterBy} || ` : '';
    options.filterBy += `carNumber.ToLower().Replace(" ", "") == "${carNumber}"`;
  }


  const response = yield call(api.loadPage, 'blackList', options);
  return response.data;
}
