/**
 *
 * Requests ActionMenu Items
 *
 */

import React from 'react';
import Block from '@material-ui/icons/Block';
import CallMade from '@material-ui/icons/CallMade';
import CallReceived from '@material-ui/icons/CallReceived';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import Create from '@material-ui/icons/Create';
import Done from '@material-ui/icons/Done';
import FileCopy from '@material-ui/icons/FileCopy';
import FindInPage from '@material-ui/icons/FindInPage';
import Print from '@material-ui/icons/Print';
import Lock from '@material-ui/icons/Lock';
import { requestStatus } from 'domain/typeConstants/status';
import { requestAction } from 'domain/typeConstants/action';
import { role as roleType } from 'domain/typeConstants/roles';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { account } from 'domain/typeConstants/accounts';

function isReception() {
  const currentRoles = getValueFromLocalStorage(GLOBAL_USER_KEY).roles;
  const checkRoleReception = currentRoles.find((role) => (role === roleType.RECEPTION));
  return checkRoleReception ? true : false;
}

function isDisableArrivedActionButton() {
  const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId;
  const accounts = [account.BC_71_7ONE, account.WALLSTREET, account.BC_VISHNIAKI];
  return !accounts.includes(accountId);
}

export const viewActionItem = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
};

export const copyActionItem = {
  name: requestAction.COPY,
  label: 'Копия',
  icon: 'content_copy',
  svgIcon: <FileCopy />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};

export const acceptActionItem = {
  name: requestAction.ACCEPT,
  label: 'Принять',
  icon: 'done',
  svgIcon: <Done />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED, requestStatus.IN_PROGRESS,
      requestStatus.AGREED, requestStatus.REJECTED].includes(currentRecord.status.name)
    } else if (itemMenuRecord.length !== 0) {
      if (!entities[0].status) return false;
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED, requestStatus.IN_PROGRESS,
      requestStatus.AGREED, requestStatus.REJECTED].includes(entities[0].status.name)
    }
    return true;
  },
};

export const rejectActionItem = {
  name: requestAction.REJECT,
  label: 'Отклонить',
  icon: 'clear',
  svgIcon: <Clear />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.REJECTED, requestStatus.AGREED].includes(currentRecord.status.name)
    } else if (itemMenuRecord.length !== 0) {
      if (!entities[0].status) return false;
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.REJECTED, requestStatus.AGREED].includes(entities[0].status.name)
    }
    return true;
  },
};

export const arrivedActionItem = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.IN_PROGRESS].includes(currentRecord.status.name)
        || (currentRecord.status.name === requestStatus.CREATED && isDisableArrivedActionButton())
    } else if (itemMenuRecord.length === 1) {
      if (!entities[0].status) return false;
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.IN_PROGRESS].includes(entities[0].status.name)
        || (entities[0].status.name === requestStatus.CREATED && isDisableArrivedActionButton())
    }
    return true;
  },
};

export const checkpointArrivedActionItem = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.CLOSED, requestStatus.ON_TERRITORY, requestStatus.COMPLETED, requestStatus.IN_PROGRESS,
      requestStatus.REJECTED].includes(currentRecord.status.name)
        || (currentRecord.status.name === requestStatus.CREATED && isDisableArrivedActionButton())
    } else if (itemMenuRecord.length === 1) {
      if (!entities[0].status) return false;
      return [requestStatus.CLOSED, requestStatus.ON_TERRITORY, requestStatus.COMPLETED, requestStatus.IN_PROGRESS,
      requestStatus.REJECTED].includes(entities[0].status.name)
        || (entities[0].status.name === requestStatus.CREATED && isDisableArrivedActionButton())
    }
    return true;
  },
};

export const departedActionItem = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name !== requestStatus.ON_TERRITORY
    } else if (itemMenuRecord.length === 1) {
      if (!entities[0].status) return false;
      return entities[0].status.name !== requestStatus.ON_TERRITORY;
    }
    return true;
  },
};

export const closeActionItem = {
  name: requestAction.CLOSE,
  label: 'Закрыть',
  icon: 'close',
  svgIcon: <Close />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED || currentRecord.status.name === requestStatus.COMPLETED
    } else if (itemMenuRecord.length !== 0) {
      if (!entities[0].status) return false;
      return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED);
    }
    return true;
  },
};

export const closeRequestActionItem = {
  name: requestAction.CLOSE,
  label: 'Закрыть',
  icon: 'close',
  svgIcon: <Lock />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED || currentRecord.status.name === requestStatus.COMPLETED
    } else if (itemMenuRecord.length !== 0) {
      if (!entities[0].status) return false;
      return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED);
    }
    return true;
  },
};

export const completeActionItem = {
  name: requestAction.COMPLETE,
  label: 'Закрыть',
  icon: 'check_circle_outline',
  svgIcon: <CheckCircleOutline />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name !== requestStatus.IN_PROGRESS && currentRecord.status.name !== requestStatus.ACCEPTED
    } else if (itemMenuRecord.length !== 0) {
      if (!entities[0].status) return false;
      return (entities[0].status.name !== requestStatus.IN_PROGRESS && entities[0].status.name !== requestStatus.ACCEPTED);
    }
    return true;
  },
};

export const addBlackListCarActionItem = {
  name: requestAction.ADD_TO_BLACK_LIST,
  label: 'Добавить в ЧС',
  icon: 'block',
  svgIcon: <Block />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED || currentRecord.status.name === requestStatus.COMPLETED
    } else
      if (itemMenuRecord.length === 1) {
        if (!entities[0].status) return false;
        return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED);
      }
    return true;
  },
};

export const printActionItem = {
  name: requestAction.PRINT,
  label: 'Печать',
  icon: 'print',
  svgIcon: <Print />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};
