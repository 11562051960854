/**
 *
 *  CheckpointDxGrid14140
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';
import {
  ACTIVE_REQUESTS,
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_CHECKPOINT,
} from 'domain/Data/Filters/constants';

import {
  BERLINSKY_TIME,
  berlinskyTimeColumn,
  berlinskyTimeColumnFilter,

  BERLINSKY_VISIT_TIME,
  berlinskyVisitTimeColumn,
  berlinskyVisitTimeColumnFilter,

} from '../common/dxGridComponents/columns';

export class CheckpointDxGrid14140 extends CheckpointDxGrid {

  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_CHECKPOINT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }

  getColumns() {
    const columns = super.getColumns();

    columns.push({
      path: 'department',
      name: 'department',
      title: 'Здание',
      getCellValue: (row) => row.department ? row.department.name : undefined,
      width: 150,
    });

    columns.push(berlinskyTimeColumn);
    columns.push(berlinskyVisitTimeColumn);

    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    const gridFilters = super.getGridFilters();

    gridFilters.push({
      columnName: 'department',
      value: '',
    });

    gridFilters.push(berlinskyTimeColumnFilter);
    gridFilters.push(berlinskyVisitTimeColumnFilter);

    return gridFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();

    const companyIndex = columnOrder.findIndex((column) => column === 'tenantCompanyName');
    columnOrder.splice(companyIndex + 1, 0, 'department');

    columnOrder.push(BERLINSKY_TIME);

    const statusNameIndex = columnOrder.findIndex((column) => column === 'statusName');
    columnOrder.splice(statusNameIndex, 0, BERLINSKY_VISIT_TIME);

    return columnOrder;
  }
}
