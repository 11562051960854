/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

/* eslint import/no-webpack-loader-syntax: off */

// Needed for redux-saga es6 generator support
import 'babel-polyfill';

// Load One Signal SDK
import 'file-loader?name=[name].[ext]!features/OneSignal/OneSignalSDKUpdaterWorker.js'; // eslint-disable-line import/extensions
import 'file-loader?name=[name].[ext]!features/OneSignal/OneSignalSDKWorker.js'; // eslint-disable-line import/extensions

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import FontFaceObserver from 'fontfaceobserver';
// import { createBrowserHistory as createHistory } from 'history';
import { createBrowserHistory } from 'history';
import 'sanitize.css/sanitize.css';
import 'moment/locale/ru';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Import helpers for screen properties initialization
import { initializeScreenProperties } from 'domain/Screen/actions';
import { getScreenProperties } from 'utils/screenUtils';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import './global-styles';

// Import global api settings
import './global-api';
import './main.css';
// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

import config from 'config';

import { hubConnection } from 'signalr-no-jquery';
import { updateNeed } from 'utils/signalrUtils';

import { getValueFromLocalStorage } from 'utils/localStorage';
import { UPDATE_BY_SIGNALR } from 'global-constants';

const connection = hubConnection(config.pushServerUrl);
const hubProxy = connection.createHubProxy('dBEventsHub');

// set up event listeners i.e. for incoming "appendnewmessage" - BroadcastMessage event
hubProxy.on('appendnewmessage', function (user, message) {

  //Инициируем событие, если в настройках таблицы установлено автообновление и updateNeed вернет значение. 
  if (getValueFromLocalStorage(UPDATE_BY_SIGNALR)) {
    var entityName = updateNeed(message);

    if (entityName && entityName.length > 0) {
      var event = new CustomEvent('signalRMessage', { 'detail': entityName });
      window.dispatchEvent(event);
    }
  }
});

// connect signalR
connection.start({ jsonp: true })
  .done(function () { console.log('Now connected, connection ID=' + connection.id); })
  .fail(function () { console.log('Could not connect'); });

// Create redux store with history
const initialState = {};
// const history = createHistory();
const history = createBrowserHistory();
const store = configureStore(initialState, history);

store.dispatch(initializeScreenProperties(getScreenProperties()));

const MOUNT_NODE = document.getElementById('app');

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <App history={history} />
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  (new Promise((resolve) => {
    resolve(import('intl'));
  }))
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en.js'),
      import('intl/locale-data/jsonp/ru.js'),
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
