/**
 *
 * SeniorParkingOperatorView sagas
 *
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import {
  processVisitorArrivalSaga,
  processVisitorDepartureSaga,
} from 'domain/Data/Request/sagas';

import { makeSelectEntity } from 'domain/Data/selectors';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { openAddDialog, openViewDialog } from 'containers/AdvancedFormDialog/actions';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

import {
  BLACK_LIST_FORM_DIALOG,
  EXECUTE_ADD_BLACK_LIST_ITEM_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
  EXECUTE_VIEW_ACTION,
  SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID,
  SENIOR_PARKING_OPERATOR_REQUESTS_FORM_DIALOG,
} from './constants';

const isSeniorParkingOperatorFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  (
    action.meta.dialog === SENIOR_PARKING_OPERATOR_REQUESTS_FORM_DIALOG ||
    action.meta.dialog === BLACK_LIST_FORM_DIALOG
  );

export default function createSeniorParkingOperatorViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_ARRIVED_ACTION, (config.executeArrivedActionSaga || executeArrivedActionSaga));
    yield takeLatest(EXECUTE_DEPARTED_ACTION, (config.executeDepartedActionSaga || executeDepartedActionSaga));
    yield takeLatest(EXECUTE_ADD_BLACK_LIST_ITEM_ACTION, (config.executeAddBlackListItemActionSaga || executeAddBlackListCarActionSaga));

    yield takeLatest(isSeniorParkingOperatorFormSaved, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID));
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(SENIOR_PARKING_OPERATOR_REQUESTS_FORM_DIALOG, id));
  }
}

export function* executeArrivedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorArrivalSaga, ids);
  yield put(loadData(SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID));
}

export function* executeDepartedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorDepartureSaga, ids);
  yield put(loadData(SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID));
}

function* executeAddBlackListCarActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    yield put(openAddDialog(BLACK_LIST_FORM_DIALOG, { request }));
  }
}
