/*
 *
 * AdministratorBCView sagas 14691
 *
 */

import {fork, call, put, select, takeLatest, take} from 'redux-saga/effects';

import {makeSelectEntityId, makeSelectFormName} from 'containers/AdvancedFormDialog/selectors';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { closeDialog } from 'containers/AdvancedFormDialog/actions';
import { RECEPTION_REQUESTS_TABLE_GRID } from 'pages/Requests/ReceptionView/constants';
import * as api from 'utils/api';
import { changeEntitiesState } from 'domain/Data/actions';
import { REQUESTS } from 'domain/Data/constants';

import { EXECUTE_ACCEPT_ACTION, EXECUTE_REJECT_ACTION } from './constants';
import {
  acceptRequests,
  requestsAccepted,
  requestsAcceptingError,

  rejectRequests,
  requestsRejected,
  requestsRejectingError,
} from './actions';

import createServiceRequestsManagerViewSaga from '../../../../../pages/Requests/ServiceRequestsManagerView/sagas';

import { saveDialogSaga } from "../../../../../containers/AdvancedFormDialog/sagas";

export default function* rootSaga() {
  yield fork(createServiceRequestsManagerViewSaga());
  yield takeLatest(EXECUTE_ACCEPT_ACTION, executeAcceptActionSaga);
  yield takeLatest(EXECUTE_REJECT_ACTION, executeRejectActionSaga);
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export function* acceptActionSaga(requestsId) {
  yield put(acceptRequests());

  try {
    const response = yield call(api.post, `requests/${requestsId}/accepted`, { requestsId });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(requestsAccepted());
  } catch (err) {
    yield put(requestsAcceptingError(err));
  }
}

function* executeAcceptActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  yield call(saveDialogSaga, dialogName);
  yield call(acceptActionSaga, requestsId);
  yield put(closeDialog(dialogName));
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export function* rejectActionSaga(requestsId) {
  yield put(rejectRequests());

  try {
    const response = yield call(api.post, `requests/${requestsId}/rejected`, { requestsId });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(requestsRejected());
  } catch (err) {
    yield put(requestsRejectingError(err));
  }
}

function* executeRejectActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  yield call(saveDialogSaga, dialogName);
  yield call(rejectActionSaga, requestsId);
  yield put(closeDialog(dialogName));
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export const administratorBCViewSaga = {
  executeNeedViewRefreshSaga,
};
