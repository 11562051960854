/*
 *
 * ContactsDxGrid14326
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { CONTACTS_DX_GRID } from './constants';

class ContactsDxGrid14326 extends React.PureComponent {
  getFilters() {
    return [];
  }

  getColumns() {
    return [
      {
        path: 'name',
        name: 'name',
        title: 'Должность',
        width: 400,
      },
      {
        path: 'email',
        name: 'email',
        title: 'Email',
        width: 400,
      },
      {
        path: 'phone',
        name: 'phone',
        title: 'Телефон',
        width: 200,
      },
      {
        path: 'workTime',
        name: 'workTime',
        title: 'Часы работы',
        width: 200,
      },
    ];
  }

  getColumnOrder() {
    return [
      'order', 'name', 'email', 'phone', 'workTime',
    ];
  }

  getActionButtons() {
    return [];
  }

  getActionMenuItems() {
    return [];
  }

  getSorting() {
    return [{ columnId: 'order', columnName: 'order', direction: 'asc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.TABLE_ACTION_MENU, value: false },
      { name: settings.GROUPING, value: false },
      { name: settings.FILTERING, value: false },
    ];
  }

  render() {
    const { viewName } = this.props;

    return (
      <AdvancedDataTable
        entityName="contacts"
        name={CONTACTS_DX_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

ContactsDxGrid14326.propTypes = {
  viewName: PropTypes.string,
};

export default ContactsDxGrid14326;
