import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentRecordId } from 'domain/Navigation/selectors';
import DocumentDxGridContainer from './document/DocumentDxGridContainer';
import SelectGridDialog from './SelectGridDialog';

class SelectGridDialogContainer extends React.Component {

    handleSelect = (id) => {
        const { onSelect } = this.props;
        if (onSelect) onSelect(id);
    }

    handleSubmitButtonClick = () => {
        const { onSelect, currentRecordId } = this.props
        if (onSelect && currentRecordId) onSelect(currentRecordId);
    };

    handleCancelButtonClick = () => this.props.onCancel();

    renderContent = (entityName) => {
        if (entityName === 'documents')
            return (
                <DocumentDxGridContainer viewName={"allDocuments"} onSelect={this.handleSelect} />
            )
    }

    renderTitle = (entityName) => {
        let title = ''
        switch (entityName) {
            case 'documents':
                title = 'Выберите документ'
                break;
            case 'tasks':
                title = 'Выберите задачу'
                break;
            default:
                break;
        }
        return title;
    }

    render() {
        const { open, currentRecordId, entityName } = this.props

        return (
            <SelectGridDialog
                open={open}
                title={this.renderTitle(entityName)}
                content={this.renderContent(entityName)}
                onSelectAction={this.handleSubmitButtonClick}
                onCancelAction={this.handleCancelButtonClick}
                currentRecordId={currentRecordId}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentRecordId: makeSelectCurrentRecordId(),
});

export default connect(mapStateToProps, null)(SelectGridDialogContainer);
