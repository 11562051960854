/**
 *
 * AdvancedForm reducer
 *
 */

import {Map, OrderedMap} from 'immutable';
import {recordsFromArray} from 'utils/immutable';

import * as recordTypes from 'domain/Data/recordTypes';
import {
    SAVE_FILE,
    SAVE_FILE_PROGRESS,
    SAVE_FILE_SUCCESS,
    SAVE_FILE_ERROR,
} from 'domain/Data/File/constants';

import {
    LOAD_ENTITIES,
    LOAD_ENTITIES_SUCCESS,
    LOAD_ENTITIES_ERROR,
} from 'domain/Data/constants';

import {formField} from 'domain/typeConstants/form';

import {
    CLEAR_SUGGESTIONS,
    LOAD_SUGGESTIONS_SUCCESS,
} from './DadataField/constants';

import fileFieldReducer from './FileField/reducer';
import selectFieldReducer from './SelectField/reducer';
import comboBoxReducer from './ComboBox/reducer';
import comboBoxTextInputReducer from './ComboBoxTextInput/reducer';
import dadataReducer from './DadataField/reducer';
import autocompleteReducer from './Autocomplete/reducer';
import multiAutocompleteReducer from './MultiAutocomplete/reducer';
import selectCheckboxReducer from './SelectCheckbox/reducer';

import {
    ADD_ENTITY_TO_NORMALIZATION_QUEUE,
    CHANGE_DETAIL_TABLE_DATA,
    CLEAR_NORMALIZATION_QUEUE,
    INCREMENT_REQUESTS_RECEIVED_PARAMETER,
    INCREMENT_REQUESTS_SENT_PARAMETER,
    INITIALIZE,
    LOAD_FORM_DATA,
    REGISTER,
    SET_GROUPS,
    UNREGISTER, UPDATE_DETAIL_TABLE_STATUS,
} from './constants';

import {CLEAR_OPTIONS} from './Autocomplete/constants';
import {KHIMKI_RECEPTION_UPDATE_FORM_FIELD} from 'custom/14326/pages/Requests/ReceptionView/constants';
import {KHIMKI_SERVICE_UPDATE_FORM_FIELD} from 'custom/14326/pages/Requests/ServiceRequestsManagerView/constants';

const initialState = new Map({});

const normalizationQueue = new Map({
    requestsSent: 0,
    requestsReceived: 0,
    fields: new Map({}),
    entities: new Map({}),
});

function advancedFormReducer(state = initialState, action) {
    const {type, meta, payload} = action;
    let requestsSentAmount;
    let requestsReceivedAmount;

    switch (type) {
        case INITIALIZE:
            return initialState;

        case LOAD_FORM_DATA:
            return state
                .setIn([meta.advancedForm, 'mode'], payload.mode)
                .setIn([meta.advancedForm, 'data', 'id'], payload.entityId);

        case LOAD_ENTITIES:
        case LOAD_ENTITIES_SUCCESS:
        case LOAD_ENTITIES_ERROR: {
            const fieldType = state.getIn([meta.advancedForm, 'fields', meta.field, 'type']);
            if (fieldType === formField.SELECT) {
                return selectFieldReducer(state, action);
            }
            if (fieldType === formField.COMBOBOX) {
                return comboBoxReducer(state, action);
            }
            if (fieldType === formField.COMBOBOX_TEXT_INPUT) {
                return comboBoxTextInputReducer(state, action)
            }
            if (fieldType === formField.AUTOCOMPLETE) {
                return autocompleteReducer(state, action);
            }
            if (fieldType === formField.MULTI_AUTOCOMPLETE) {
                return multiAutocompleteReducer(state, action);
            }
            if (fieldType === formField.SELECT_CHECKBOX) {
                return selectCheckboxReducer(state, action);
            }
            return state;
        }

        case CLEAR_OPTIONS:
            return autocompleteReducer(state, action);

        case CLEAR_SUGGESTIONS:
        case LOAD_SUGGESTIONS_SUCCESS:
            return dadataReducer(state, action);

        case SAVE_FILE:
        case SAVE_FILE_PROGRESS:
        case SAVE_FILE_SUCCESS:
        case SAVE_FILE_ERROR:
            if (meta && meta.advancedForm && meta.field) {
                return fileFieldReducer(state, action);
            }
            return state;

        case INCREMENT_REQUESTS_SENT_PARAMETER:
            requestsSentAmount = state.getIn([meta.advancedForm, 'normalizationQueue', 'requestsSent']) || 0;
            return state.setIn([meta.advancedForm, 'normalizationQueue', 'requestsSent'], requestsSentAmount + 1);

        case INCREMENT_REQUESTS_RECEIVED_PARAMETER:
            requestsReceivedAmount = state.getIn([meta.advancedForm, 'normalizationQueue', 'requestsReceived']) || 0;
            return state.setIn([meta.advancedForm, 'normalizationQueue', 'requestsReceived'], requestsReceivedAmount + 1);

        case ADD_ENTITY_TO_NORMALIZATION_QUEUE:
            requestsReceivedAmount = state.getIn([meta.advancedForm, 'normalizationQueue', 'requestsReceived']) || 0;
            return state
                .setIn([meta.advancedForm, 'normalizationQueue', 'requestsReceived'], requestsReceivedAmount + 1)
                .setIn([meta.advancedForm, 'normalizationQueue', 'fields', payload.entity.field], {
                    ...payload.entity,
                    entityName: meta.entityName,
                })
                .setIn([meta.advancedForm, 'normalizationQueue', 'entities', meta.entityName], payload.entity);

        case CLEAR_NORMALIZATION_QUEUE:
            return state.setIn([meta.advancedForm, 'normalizationQueue'], normalizationQueue);

        case CHANGE_DETAIL_TABLE_DATA:
            return state
                .setIn([meta.advancedForm, meta.entityName], payload.data);

        case UPDATE_DETAIL_TABLE_STATUS:
            return state
                .setIn([meta.advancedForm, 'update'], payload.status);

        case REGISTER:
            return state
                .setIn([meta.advancedForm, 'data', 'entityName'], payload.entityName)
                .setIn([meta.advancedForm, 'data', 'id'], payload.entityId)
                .setIn([meta.advancedForm, 'useAsyncValidate'], payload.useAsyncValidate)
                .setIn([meta.advancedForm, 'groups'], payload.groups)
                .setIn([meta.advancedForm, 'params'], payload.params)
                .setIn([meta.advancedForm, 'documentPositions'], null)
                .setIn([meta.advancedForm, 'normalizationQueue'], normalizationQueue)
                .updateIn([meta.advancedForm, 'fields'], (fieldsOrderedMap) =>
                    updateFieldsOrderedMap(fieldsOrderedMap, payload.fields)
                );

        case UNREGISTER:
            return state.delete(meta.advancedForm);

        case KHIMKI_RECEPTION_UPDATE_FORM_FIELD:
        case KHIMKI_SERVICE_UPDATE_FORM_FIELD:
            return state
                .setIn([meta.formName, 'fields', payload.field.id], payload.field);

        case SET_GROUPS:
            return state.setIn([meta.advancedForm, 'groups'], payload.groups)

        default:
            return state;
    }
}

function updateFieldsOrderedMap(fieldsOrderedMap, fields) {
    let newFieldsState = fields;
    // Если поля уже есть в store, то переносим кешируемые данные в новое состояние полей
    if (fieldsOrderedMap) {
        newFieldsState = fields.map((field) => ({
            // свойства с новым состоянием
            ...field,
            // ид поля сформированный при первом запуске
            id: fieldsOrderedMap.getIn([field.name, 'id']),
            // кешируемые данные о загруженных объектах для выпадающих списков
            loadedOptions: fieldsOrderedMap.getIn([field.name, 'loadedOptions']),
            loaded: fieldsOrderedMap.getIn([field.name, 'loaded']),
        }));
        // Если полей еще нет в store, то добавляем ид каждому полю
    } else {
        newFieldsState = fields.map((field) => ({...field, id: field.name}));
    }

    return new OrderedMap(
        recordsFromArray(recordTypes.FormField, newFieldsState)
    );
}

export default advancedFormReducer;
