/**
*
* Autocomplete
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { match, parse } from 'autosuggest-highlight';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
});

class Autocomplete extends React.Component {
  state = {
    value: '',
  };

  componentWillMount() {
    const { entity } = this.props;

    if (entity && entity.name != null) {
      this.setState({ value: entity.name });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { entity } = this.props;

    if (entity && !nextProps.entity) {
      this.setState({ value: '' });
    } else if (!entity && nextProps.entity) {
      this.setState({ value: nextProps.entity.name });
    }
  }

  componentDidUpdate() {
    const { calculateFieldsValues, input: { name } } = this.props;
    calculateFieldsValues(name);
  }

  getSuggestionValue = (suggestion) => suggestion.text;

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.props.readOnly) return;
    this.props.loadOptions(value);
  };

  handleSuggestionsClearRequested = () => this.props.clearOptions();

  handleChange = (event, { newValue }) => this.setState({ value: newValue });

  handleBlur = () => {
    const { entity } = this.props;
    if (entity && entity.name != null) {
      this.setState({ value: entity.name });
    }
  };

  handleClearButtonMouseDown = (event) => {
    event.preventDefault();
  };

  handleClearButtonClick = (event) => {
    event.stopPropagation();
    if (this.props.readOnly) return;
    this.props.input.onChange(null);
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    const { input: { onChange, name }, calculateFieldsValues } = this.props;
    onChange(suggestion.value);
    calculateFieldsValues(name);
  };

  shouldRenderSuggestions = (suggestion) => { // eslint-disable-line
    if (suggestion) return true;
  };

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { readOnly } = this.props;

    if (readOnly) {
      return null;
    }

    const matches = match(suggestion.text, query);
    const parts = parse(suggestion.text, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {
            parts.map((part, index) => part.highlight ?
              (
                <span key={`${index + 1}part.text`} style={{ fontWeight: 300 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={`${index + 1}part.text`} style={{ fontWeight: 500 }}>
                  {part.text}
                </strong>
              )
            )
          }
        </div>
      </MenuItem>
    );
  };

  renderInput = (inputProps) => {
    const { classNames } = this.props;
    const {
      autoComplete,
      classes,
      helperText,
      meta: { touched, error, submitFailed },
      readOnly,
      ref,
      value,
      fileSize,
      fieldName,
      ...restProps
    } = inputProps;

    return (
      <TextField
        margin="none"
        autoComplete={autoComplete || 'off'}
        error={(touched || submitFailed) && !!error}
        helperText={(touched || submitFailed) && error ? error : helperText}
        value={value}
        inputRef={ref}
        {...restProps}
        FormHelperTextProps={{
          classes: {
            root: classNames.helperText,
          },
        }}
        InputProps={{
          readOnly,
          classes: {
            input: classes.input,
          },
          endAdornment: (
            !this.props.readOnly && !this.props.disabled && this.props.entity &&
            <InputAdornment position="end">
              <IconButton
                onClick={this.handleClearButtonClick}
                onMouseDown={this.handleClearButtonMouseDown}
              >
                <Icon>clear</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  render() {
    const {
      classes,
      entity, // eslint-disable-line no-unused-vars
      input,
      clearOptions, // eslint-disable-line no-unused-vars
      loadOptions, // eslint-disable-line no-unused-vars
      entityName, // eslint-disable-line no-unused-vars
      calculateFieldsValues, // eslint-disable-line no-unused-vars
      classNames, // eslint-disable-line no-unused-vars
      options,
      ...restProps
    } = this.props;

    return (
      <Autosuggest
        highlightFirstSuggestion
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={options}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          ...input,
          ...restProps,
          classes,
          value: this.state.value,
          onChange: this.handleChange,
          onBlur: this.handleBlur,
        }}
      />
    );
  }
}

Autocomplete.propTypes = {
  calculateFieldsValues: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clearOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  entity: PropTypes.object,
  entityName: PropTypes.string.isRequired,
  classNames: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  loadOptions: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
};

Autocomplete.defaultProps = {
  classNames: {},
};

export default withStyles(styles)(Autocomplete);
