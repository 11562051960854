import axios from 'axios';
import config from 'config';

import {
  getValueFromLocalStorage,
} from 'utils/localStorage';

axios.defaults.baseURL = config.apiServiceUrl;

const activeUser = getValueFromLocalStorage('claris-vnext-global-user');
if (activeUser) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${activeUser.accessToken}`; // eslint-disable-line dot-notation
}