/**
 *
 * TaskForm
 *
 * 
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { INITIAL_VALUE_SAAS_TASK_STATUS, INITIAL_VALUE_TASK_TYPE_STATUS, USER_TASK_FORM, INITIAL_VALUE_OTHER_TASK_STATUS, } from './constants';
import { formField } from 'domain/typeConstants/form';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { account } from 'domain/typeConstants/accounts';
import { TASKS } from 'domain/Data/constants';

class TaskForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();
    const currentDateclosed = new Date(currentDate);
    currentDateclosed.setDate(currentDateclosed.getDate() + 7);

    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    const isAltSoft = (accountId === account.ALTSOFT)
    const mode = this.props.mode;
    const parentId = this.props.parentId;

    return [
      {
        name: 'type',
        type: formField.COMBOBOX,
        label: 'Вид',
        visible: isAltSoft,
        grid: { sm: 2 },
        entityName: 'taskTypes',
        initialValue: (currentValues, mode) => {
          if (mode === 'add') {
            return INITIAL_VALUE_TASK_TYPE_STATUS;
          }
          return currentValues?.type;
        },
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        required: true,
        entityName: 'taskStatuses',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ((isAltSoft) ? INITIAL_VALUE_SAAS_TASK_STATUS : INITIAL_VALUE_OTHER_TASK_STATUS)
          } return values.status
        },

        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const taskType = [];
          entities.get('taskTypes').map((item) => {
            if (currentValues.type && currentValues.type === item.id)
              taskType.push(item.name);
          });
          const currentType = taskType[0];
          let taskStatuses = [];
          if (currentType && isAltSoft) {
            entities.get('taskStatuses').map((item) => {
              let str = item.name;
              if (str) {
                const pos = str.indexOf('.');
                if (str.slice(0, pos) === currentType) {
                  taskStatuses.push(item.id);
                }
              }
            })
          }
          else {
            entities.get('taskStatuses').map((item) => {
              taskStatuses.push(item.id);
            })
          }
          return taskStatuses;
        },
        orderBy: 'name',
        disabled: (mode === 'view'),
        grid: (isAltSoft) ? { sm: 4 } : { sm: 6 },
      },

      {
        name: 'finishDate',
        type: formField.DATETIME_DIVIDED,
        label: 'Завершить к',
        required: true,
        visible: true,
        initialValue: currentDateclosed,
        value: currentDateclosed,
        disabled: (mode === 'view'),
        grid: { sm: 3 },
      },
      {
        name: 'responsible',
        type: formField.COMBOBOX,
        label: 'Ответственный',
        entityName: 'employees',
        visible: true,
        disabled: (mode === 'view'),
        initialValue: (values, mode, user) => user.id,
        grid: { sm: 3 },
      },
      {
        name: 'company',
        type: formField.AUTOCOMPLETE,
        label: 'Контрагент',
        entityName: 'companies',
        visible: true,
        disabled: (mode === 'view'),
        initialValue: (values, mode, user) => user.companyId,
        helperText: () => 'Поиск в справочнике',
        grid: { sm: 6 },
      },
      {
        name: 'attachments',
        label: 'Файл',
        type: formField.MULTIPLE_FILE,
        visible: true,
        helperText: () => 'Прикрепите один или несколько файлов',
        disabled: (mode === 'view'),
        grid: { sm: 6 },
        rowBreakAfter: true,
      },
      {
        name: 'description',
        type: formField.EDITOR,
        label: 'Что сделать',
        visible: true,
        required: true,
        disabled: (mode === 'view'),
        multiline: true,
        grid: { sm: 12 },
      },
      {
        name: 'name',
        label: 'name',
        visible: false,
        grid: { sm: 3 },
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
        grid: { sm: 2 },
      },
      {
        name: 'author',
        type: formField.COMBOBOX,
        label: 'Автор',
        entityName: 'employees',
        visible: true,
        initialValue: (values, mode, user) => user.id,
        disabled: true,
        grid: { sm: 4 },
      },
      {
        name: 'registrationDate',
        type: formField.DATE,
        label: 'Создано',
        required: true,
        visible: true,
        initialValue: (mode === 'add') ? currentDate : undefined,
        disabled: true,
        grid: { sm: 3 },
      },
      {
        name: 'taskChangeDate',
        type: formField.DATE,
        label: 'Изменено',
        visible: !(mode === 'add'),
        disabled: true,
        grid: { sm: 3 },
      },
      {
        name: 'department',
        type: formField.SELECT,
        entityName: 'departments',
        label: 'Отдел',
        disabled: (mode === 'view'),
        initialValue: (values, mode, user) => (mode === 'add' || mode === 'copy') ? user.departmentId : values.department.id,
        grid: { sm: 6 },
      },
      {
        label: 'Документ',
        name: 'document',
        visible: !((mode === 'add' || mode === 'copy') && parentId),
        grid: { sm: 6 },
        disabled: (mode === 'view'),
        readOnly: true,
        entityName: 'documents',
        type: formField.SELECT_GRID,
        initialValue: (values, mode) => (mode === 'add' || mode === 'copy') && parentId,
      },
      // {
      //   name: 'executors',
      //   label: 'Соисполнители',
      //   entityName: 'employees',
      //   grid: { sm: 12 },
      //   type: formField.SELECT_CHECKBOX,
      // },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    return (
      <AdvancedForm
        name={USER_TASK_FORM}
        entityName={TASKS}
        classNames={this.getClassNames()}
        fields={this.getFields()}
        accountId={accountId}
        {...this.props}
      />
    );
  }
}

export default TaskForm;