/**
 *
 * TaskView sagas
 *
 */

import {call, select, put, takeLatest} from 'redux-saga/effects';
import {loadData} from 'containers/AdvancedDataTable/actions';
import {loadData as loadMobileData} from 'containers/AdvancedMobileGrid/actions';

import {taskStatusId} from 'domain/typeConstants/status';
import {DISPLAY_NOTIFICATION} from 'domain/Notifications/constants';
import {makeSelectEntity} from 'domain/Data/selectors';
import {makeSelectViewMode} from 'domain/Screen/selectors';
import {SAVE_DIALOG_SUCCESS} from 'containers/AdvancedFormDialog/constants';
import {
    openAddDialog,
    openEditDialog,
    openViewDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
    USER_TASK_TABLE_GRID,
    USER_TASK_FORM_DIALOG,
    EXECUTE_ADD_ACTION,
    EXECUTE_EDIT_ACTION,
    EXECUTE_CLOSE_ACTION,
    EXECUTE_PERFORME_ACTION,
} from './constants';

import {closeTasksSaga, performeTasksSaga} from 'domain/Data/Task/sagas';

import {TASKS} from 'domain/Data/constants';
import {checkUserForBlockingSaga} from 'domain/User/sagas';
import {makeSelectNavigation} from 'containers/AdvancedFormDialog/selectors';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && action.meta.dialog === USER_TASK_FORM_DIALOG;

export default function createTaskViewSaga(config = {}) {
    return function* rootSaga() {
        yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
        yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
        yield takeLatest(EXECUTE_CLOSE_ACTION, (config.executeCloseActionSaga || executeCloseActionSaga));
        yield takeLatest(EXECUTE_PERFORME_ACTION, (config.executePerformeActionSaga || executePerformeActionSaga));
        yield takeLatest([needViewRefresh, DISPLAY_NOTIFICATION], (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
    };
}

export function* executePerformeActionSaga({payload: {ids}}) {
    yield checkUserForBlockingSaga()
    yield call(performeTasksSaga, ids);
    yield put(loadData(USER_TASK_TABLE_GRID));
}

export function* executeCloseActionSaga({payload: {ids}}) {
    yield checkUserForBlockingSaga()
    yield call(closeTasksSaga, ids);
    yield put(loadData(USER_TASK_TABLE_GRID));
}

function* executeNeedViewRefreshSaga() {
    const mode = yield select(makeSelectViewMode());
    if (mode === 'mobile') {
        yield put(loadMobileData(USER_TASK_TABLE_GRID))
    } else yield put(loadData(USER_TASK_TABLE_GRID));
}

function* executeAddActionSaga() {
    yield put(openAddDialog(USER_TASK_FORM_DIALOG, {}));
}

function* executeEditActionSaga({payload: {id}}) {
    if (id) {
        const navigation = yield select(makeSelectNavigation(USER_TASK_FORM_DIALOG))
        const entity = yield select(makeSelectEntity(TASKS, id));
        if (entity.status === taskStatusId.CLOSED) {
            yield put(openViewDialog(USER_TASK_FORM_DIALOG, {id, status: entity.status, navigation}));
        } else {
            yield put(openEditDialog(USER_TASK_FORM_DIALOG, {id, status: entity.status, navigation}));
        }
    }
}
