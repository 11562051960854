import axios from 'axios';
import config from 'config';

import {removeValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants';
import {percentEncodingPassword} from './common'

export function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

export function checkErrorStatus(error) {
    if (error.response.status === 401) {
        removeValueFromLocalStorage(GLOBAL_USER_KEY);
        const {Authorization, ...restHeaders} = axios.defaults.headers.common;
        axios.defaults.headers.common = restHeaders;
        document.location.href = "/";
    }
    return error.response
}

export function authenticate(login, password) {
    return axios({
        method: 'POST',
        url: '/Token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=password&username=${login}&password=${percentEncodingPassword(password)}`,
    })
        .then(checkStatus);
}

export function forgotPassword(login) {
    const password = "secretword"
    return axios({
        method: 'POST',
        url: '/Token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=password&username=${login}&password=${password}`,
    })
        .then(checkStatus);
}

export function sendMailPasswordRecovery(email, fullName, login) {
    return axios
        .post('/vNext/v1/recoveryPassword/req', {email, fullName, login})
        .then(checkStatus, checkErrorStatus);
}

export function checkPasswordLink(login, link) {
    return axios
        .post('/vNext/v1/recoveryPassword/check', {login, link})
        .then(checkStatus, checkErrorStatus);
}

export function getUserByLogin(login) {
    const url = `/vNext/v1/users?filterBy=name="${login}"`;
    return axios.get(url).then(checkStatus, checkErrorStatus);
}

export function getLastBCVersion() {
    const url = `/vNext/v1/utils/bcVersion`;
    return axios.get(url).then(checkStatus, checkErrorStatus);
}

export function createDownloadTicket(fileId) {
    return axios
        .post(`/vNext/v1/files/${fileId}`)
        .then(checkStatus, checkErrorStatus);
}

export function createDownloadTicketForPreview(fileId) {
    return axios
        .post(`/vNext/v1/files/${fileId}/preview`)
        .then(checkStatus, checkErrorStatus);
}

export function changePassword(oldPassword, newPassword, newPasswordConfirmation) {
    return axios
        .post('/vNext/v1/users/setPassword', {oldPassword, newPassword, newPasswordConfirmation})
        .then(checkStatus, checkErrorStatus);
}

export function restorePassword(newPassword) {
    return axios
        .post('/vNext/v1/users/restorePassword', {newPassword})
        .then(checkStatus, checkErrorStatus);
}

export function changeRole(role) {
    return axios
        .post('/vNext/v1/users/changeRole', {role})
        .then(checkStatus, checkErrorStatus);
}

export function getDownloadLink(downloadId) {
    return `${config.apiServiceUrl}/vNext/v1/files?downloadId=${downloadId}`;
}

export function getCurrentEmployeeInfo() {
    const url = '/vNext/v1/users/current';
    return axios.get(url).then(checkStatus, checkErrorStatus);
}

export function getInstanceInfo() {
    const url = '/vNext/v1/users/instance';
    return axios.get(url).then(checkStatus, checkErrorStatus);
}

export function openBarrier(requestId) {
    const url = `/zhukov/v1/requests/${requestId}/openBarrier`;
    return axios.post(url).then(checkStatus, checkErrorStatus);
}

export function sendToParsec(accessCard, accessCardKey, visitorFullName, documentDetails) {
    const url = '/vNext/v1/parsec/send';
    const params = {accessCard, accessCardKey, visitorFullName, documentDetails};
    return axios.get(url, {params}).then(checkStatus, checkErrorStatus);
}

export function post(entityName, data, options) {
    return axios
        .post(`/vNext/v1/${entityName}`, data, options)
        .then(checkStatus, checkErrorStatus);
}

export function load(entityName, id) {
    // костыль - не загружать данные по id из кеша
    if (entityName === 'documentPositions' && id < 4000000000000) return
    let url = `/vNext/v1/${entityName}`;
    if (id) url += `/${id}`;
    const params = {view: "list"}
    return axios.get(url, {params}).then(checkStatus, checkErrorStatus);
}

export function loadReportList() {
    return axios.get("/vNext/v1/documents/reports").then(checkStatus, checkErrorStatus);
}

export function loadAll(entityName, {filters}) {
    const url = `/vNext/v1/${entityName}`;
    const params = {};

    if (filters && filters.length > 0) {
        params.views = filters.join(',');
    }

    return axios.get(url, {params}).then(checkStatus, checkErrorStatus);
}

export function loadPage(entityName, {
    pageSize,
    pageNumber,
    sorting,
    filters,
    filterBy,
    searchQuery,
    startAt,
    limit,
    includeTotalCount,
    view
}) {
    const params = {};
    const url = `/vNext/v1/${entityName}`;
    let includeMeta = '';

    if (view) {
        params.view = view;
    }

    if (includeTotalCount) includeMeta = 'totalCount,';
    if (pageSize && pageNumber) {
        includeMeta += 'pageInfo,';
        params.pageSize = pageSize;
        params.pageNumber = pageNumber;
    } else if (startAt && limit) {
        params.startAt = startAt;
        params.limit = limit;
    }

    if (sorting && sorting.length > 0) {
        const orderBy = sorting.reduce((acc, sortingRule) => {
            const validColumnName = sortingRule.columnId.replace('->', '.');
            return `${acc}${validColumnName} ${sortingRule.direction},`;
        }, '');
        params.orderBy = orderBy;
    }

    if (filters && filters.length > 0) {
        params.filters = filters.join(',');
    }

    if (filterBy && filterBy.length > 0) {
        params.filterBy = filterBy;
    }

    if (searchQuery) {
        params.searchQuery = searchQuery;
    }

    params.includeMeta = includeMeta;

    return axios.get(url, {params})
        .then(checkStatus, checkErrorStatus);
}

export function save(entityName, entity) {
    const url = `/vNext/v1/${entityName}`;
    return axios.post(url, entity).then(checkStatus, checkErrorStatus);
}

export function update(entityName, id, changedData) {
    const url = `/vNext/v1/${entityName}/${id}`;
    return axios.patch(url, changedData).then(checkStatus, checkErrorStatus);
}

export function copyRecord(entityName, id) {
    const url = `/vNext/v1/${entityName}/${id}/copy`;
    return axios.post(url).then(checkStatus, checkErrorStatus);
}

export function agreeDocument(id, comment) {
    return axios({
        method: 'POST',
        url: `/vNext/v1/documents/${id}/agree`,
        data: {comment},
    }).then(checkStatus, checkErrorStatus);
}

export function disagreeDocument(id, comment) {
    return axios({
        method: 'POST',
        url: `/vNext/v1/documents/${id}/disagree`,
        data: {comment},
    }).then(checkStatus, checkErrorStatus);
}

export function runDocument(id) {
    return axios({
        method: 'POST',
        url: `/vNext/v1/documents/${id}/run`,
    }).then(checkStatus, checkErrorStatus);
}

export function completeDocument(id) {
    return axios({
        method: 'POST',
        url: `/vNext/v1/documentPositions/${id}/complete`,
    }).then(checkStatus, checkErrorStatus);
}

export function loadAgreementHistory(id) {
    const url = `vNext/v1/agreementHistories?filterBy=document.id="${id}"`;
    return axios.get(url).then(checkStatus, checkErrorStatus);
}

export function loadChanges(journal, id) {
    const url = `vNext/v1/${journal}/${id}/newJournal`;
    return axios.get(url).then(checkStatus, checkErrorStatus);
}

export function unbindAccessCardByKey(accessCardKey) {
    return axios({
        method: 'POST',
        url: 'vNext/v1/accessCards/UnbindedAccessCards',
        data: {accessCardKey},
    }).then(checkStatus, checkErrorStatus);
}

export function getUnreadNotificationsCount() {
    return axios({
        method: 'GET',
        url: 'vNext/v1/users/current/unreadNotificationsCount',
    }).then(checkStatus, checkErrorStatus);
}

export function subscribeOnEmailMessages() {
    return axios({
        method: 'POST',
        url: 'vNext/v1/users/current/subscribeOnEmailMessages',
    }).then(checkStatus, checkErrorStatus);
}

export function unsubscribeOnEmailMessages() {
    return axios({
        method: 'POST',
        url: 'vNext/v1/users/current/unsubscribeOnEmailMessages',
    }).then(checkStatus, checkErrorStatus);
}

export function readUserNotifications() {
    return axios({
        method: 'POST',
        url: 'vNext/v1/notifications/allReaded',
    }).then(checkStatus, checkErrorStatus);
}

export function sendOnMatching({
                                   requestId, managers,
                               }) {
    return axios({
        method: 'POST',
        url: `vNext/v1/requests/${requestId}/onMatching`,
        data: {managers},
    }).then(checkStatus, checkErrorStatus);
}

export function getDocumentDetails(requestId) {
    return axios({
        method: 'GET',
        url: `vNext/v1/requests/${requestId}/documentDetails`,
    }).then(checkStatus, checkErrorStatus);
}


export function loadDashboardData(entity, options) {
    if (options) {
        const url = `/vNext/v1/requests/dashboard/${entity}?fromDate=${options.fromDate}&toDate=${options.toDate}`;
        return axios.get(url).then(checkStatus, checkErrorStatus);
    }
}