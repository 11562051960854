/**
 *
 * CheckpointDxGrid13539
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';

class CheckpointDxGrid13539 extends CheckpointDxGrid { // eslint-disable-line react/prefer-stateless-function
  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО посетителя',
        width: requestColumnSizes.visitorFullName,
        styles,
      },
      {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
        styles,
      },
      {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
        styles,
      },
      {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: ['company', 'name'],
        name: 'company_name',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.company_name,
        styles,
      },
      {
        path: ['parking', 'name'],
        name: 'parkingName',
        getCellValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
        width: requestColumnSizes.parkingName,
        styles,
      },
      {
        path: ['availableTenantCheckpoints'],
        name: 'availableTenantCheckpoints',
        title: 'Доступные парковки',
        width: requestColumnSizes.availableTenantCheckpoints,
        styles,
      },
      {
        path: 'companyName',
        name: 'companyName',
        title: 'Откуда посетитель',
        width: requestColumnSizes.companyName,
        styles,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
        styles,
      },
      {
        path: 'blackListNote',
        name: 'blackListNote',
        title: 'Причина ЧС',
        width: requestColumnSizes.blackListNote,
        styles,
      },
      {
        path: 'materialValuesData',
        name: 'materialValuesData',
        title: 'Мат. ценности',
        width: requestColumnSizes.materialValuesData,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: [
          { backgroundColor: '#FFF9C4', fontColor: 'black', condition: (value) => value === 'Создана' },
          { backgroundColor: '#E0E0E0', fontColor: 'black', condition: (value) => value === 'Закрыта' },
          { backgroundColor: '#9CEEC0', fontColor: 'black', condition: (value) => value === 'Выполнена' },
          { backgroundColor: '#FBBBC2', fontColor: 'black', condition: (value) => value === 'Отклонена' },
          { backgroundColor: '#E6EE9C', fontColor: 'black', condition: (value) => value === 'Принята' },
          { backgroundColor: '#CCB5AC', fontColor: 'black', condition: (value) => value === 'Возвращена' },
          { backgroundColor: '#FFE0B2', fontColor: 'black', condition: (value) => value === 'На территории' },
          { backgroundColor: '#B3E5FC', fontColor: 'black', condition: (value) => value === 'Выдан пропуск' },
          { backgroundColor: '#E1BEE7', fontColor: 'black', condition: (value) => value === 'Повторная' },
        ],
        width: requestColumnSizes.statusName,
      },
      {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
        styles,
      },
      {
        path: 'arrivalDate',
        name: 'arrivalDate',
        title: 'Прибыл',
        type: columnType.TIME,
        width: columnSize.TIME,
        styles,
      },
      {
        path: 'departureDate',
        name: 'departureDate',
        title: 'Убыл',
        type: columnType.TIME,
        width: columnSize.TIME,
        styles,
      },
      {
        path: 'parkingPlace',
        name: 'parkingPlace',
        title: 'Место',
        width: requestColumnSizes.parkingPlace,
        styles,
      },
    ];
  }

  getGridFilters() {
    return [
      { columnName: 'number', value: '' },
      { columnName: 'visitorFullName', value: '' },
      { columnName: 'carNumber', value: '' },
      { columnName: 'carModelText', value: '' },
      { columnName: 'visitDate', value: '' },
      { columnName: 'expirationDate', value: '' },
      { columnName: 'company_name', value: '' },
      { columnName: 'parkingName', value: '' },
      { columnName: 'availableTenantCheckpoints', value: '' },
      { columnName: 'companyName', value: '' },
      { columnName: 'note', value: '' },
      { columnName: 'blackListNote', value: '' },
      { columnName: 'materialValuesData', value: '' },
      { columnName: 'statusName', value: '' },
      { columnName: 'typeShortName', value: '' },
      { columnName: 'arrivalDate', value: '' },
      { columnName: 'departureDate', value: '' },
      { columnName: 'parkingPlace', value: '' },
    ];
  }

  getColumnOrder() {
    return ['number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'company_name', 'parkingName', 'availableTenantCheckpoints',
      'companyName', 'note', 'blackListNote', 'materialValuesData', 'statusName', 'typeShortName', 'arrivalDate', 'departureDate', 'parkingPlace',
    ];
  }
}

export default CheckpointDxGrid13539;
