/**
 *
 * SightingDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnSize, documentColumnSizes } from 'domain/typeConstants/table';
import { DOCUMENTS_FOR_MY_VISA, MY_AGREEMENT_HISTORIES } from 'domain/Data/Filters/constants';
import { SIGHTING_DOCUMENTS_DX_GRID } from './constants';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  agreeActionButton,
  disagreeActionButton,
  viewActionButton,
  addActionButton,
  editActionButton,
} from '../common/actionButton';
import {
  agreeActionItem,
  disagreeActionItem,
  viewActionItem,
  editActionItem,
} from '../common/actionMenu';

import { columnStatusLabels } from '../common/columnStatusLabel';
import { getSightingDetailContainer } from 'pages/common/detailContainers';

class SightingDxGrid extends React.PureComponent {
  /* -----------------------------------------------------------
                  Documents configuration
  ----------------------------------------------------------- */
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'resultOfAgreement') filters.push(MY_AGREEMENT_HISTORIES);
    if (viewName === 'forMyVisa') filters.push(DOCUMENTS_FOR_MY_VISA);
    return filters;
  }

  getColumns() {
    const { viewName } = this.props;

    if (viewName === 'resultOfAgreement') {
      return [
        {
          path: 'number',
          name: 'number',
          title: '№',
          getCellValue: (row) => row.document ? row.document.number : undefined,
          width: documentColumnSizes.documentNumber,
        },
        {
          name: 'company',
          path: 'company.name',
          title: 'Контрагент',
          getCellValue: (row) => row.document && row.document.company
            ? row.document.company.name : undefined,
          width: documentColumnSizes.companyName,
        },
        {
          name: 'content',
          path: 'content',
          title: 'Содержание',
          getCellValue: (row) => row.document ? row.document.content : undefined,
          type: columnType.FORMAT_TEXT,
          width: documentColumnSizes.content,
        },
        {
          name: 'date',
          path: 'date',
          title: 'Дата',
          type: columnType.DATE,
          width: columnSize.DATETIME,
        },
        {
          name: 'author',
          path: 'author',
          title: 'Чья виза',
          getCellValue: (row) => row.author ? row.author.name : undefined,
          width: documentColumnSizes.author,
        },
        {
          path: 'agreed',
          name: 'agreed',
          title: 'Решение',
          width: documentColumnSizes.agreed,
        },
        {
          path: 'comment',
          name: 'comment',
          title: 'Комментарий',
          width: documentColumnSizes.comment,
        },
      ];
    }

    return [
      {
        name: 'state',
        path: 'state.name',
        title: 'Состояние',
        getCellValue: (row) => row.state ? row.state.name : undefined,
        labels: columnStatusLabels,
        type: columnType.LABEL,
        entityName: 'documentStates',
        width: documentColumnSizes.stateName,
      },
      {
        path: 'number',
        name: 'number',
        title: '№',
        width: columnSize.NUMERIC,
      },
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Дата',
        type: 'date',
        width: columnSize.DATE,
      },
      {
        name: 'category',
        path: 'category.name',
        title: 'Тип',
        getCellValue: (row) => row.category ? row.category.name : undefined,
        entityName: 'documentCategories',
        width: documentColumnSizes.categoryName,
      },
      {
        name: 'company',
        path: 'company.name',
        title: 'Контрагент',
        getCellValue: (row) => row.company ? row.company.name : undefined,
        width: documentColumnSizes.companyName,
      },
      {
        path: 'content',
        name: 'content',
        title: 'Содержание',
        type: columnType.FORMAT_TEXT,
        width: documentColumnSizes.content,
      },
      {
        path: 'amount',
        name: 'amount',
        title: 'Сумма',
        width: documentColumnSizes.amount,
      },
      {
        path: 'sightingEmployeesText',
        name: 'sightingEmployeesText',
        title: 'Визирующие сотрудники',
        getCellValue: (row) => row.agreementStep ? row.agreementStep.sightingEmployeesText : undefined,
        width: documentColumnSizes.sightingEmployeesText,
      },
      {
        name: 'file',
        path: ['file','id'],
        getCellValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: columnSize.FILE,
        filter: false,
      },
      {
        name: 'scan',
        path: ['scan','name'],
        getCellValue: (row) => row.scan ? row.scan.id : undefined,
        title: 'Скан',
        type: columnType.FILE,
        width: columnSize.FILE,
        filter: false,
      },
    ];
  }

  getColumnOrder() {
    const { viewName } = this.props;
    if (viewName === 'resultOfAgreement') {
      return [
        'number', 'company', 'content', 'date', 'author', 'agreed', 'comment',
      ];
    }

    return [
      'state', 'number', 'actualCreationDate', 'category', 'company', 'content', 'amount',
      'sightingEmployeesText', 'file', 'scan',
    ];
  }

  getActionButtons() {
    const { viewName } = this.props;
    if (viewName === 'resultOfAgreement') {
      return [
        viewActionButton,
      ];
    }
    return [
      addActionButton, editActionButton, viewActionButton, agreeActionButton, disagreeActionButton,
    ];
  }

  getActionMenuItems() {
    const { viewName } = this.props;
    if (viewName === 'resultOfAgreement') {
      return [viewActionItem];
    }
    return [viewActionItem, editActionItem, agreeActionItem, disagreeActionItem];
  }

  getSorting() {
    const { viewName } = this.props;
    if (viewName === 'resultOfAgreement') {
      return [{ columnId: 'date', columnName: 'date', direction: 'desc' }];
    }
    return [{ columnId: 'actualCreationDate', columnName: 'actualCreationDate', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    const { viewName } = this.props;
    const isResultPage = viewName === 'resultOfAgreement';
    return isResultPage ?
      [{ name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false }]
      : [{ name: settings.TABLE_ROW_DETAIL, value: true },
      { name: settings.TABLE_DRAWER, value: true },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;
    const isResultPage = viewName === 'resultOfAgreement';
    return (
      <AdvancedDataTable
        name={SIGHTING_DOCUMENTS_DX_GRID}
        entityName={isResultPage ? 'agreementHistories' : 'documents'}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        detailContainer={isResultPage ? null : getSightingDetailContainer()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

SightingDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default SightingDxGrid;
