/**
 *
 * RequestsPage14140
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import {
  ReceptionDxGrid14140, ReceptionForm14140, ReceptionFormDialog14140
} from './ReceptionView';
import { CheckpointDxGrid14140 } from './CheckpointView';
import {
  AdministratorBCServiceRequestsForm14140, AdministratorBCRequestsDxGrid14140
} from './AdministratorBCView';
import { TenantForm14140, TenantDxGrid14140 } from './TenantView';
import { MakingAgreementBCDxGrid14140, MakingAgreementBCForm14140, } from './MakingAgreementBCView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';


function RequestsPage14140(props) {

  return (
    <RequestsPage
      receptionDxGrid={<ReceptionDxGrid14140 />}
      receptionForm={<ReceptionForm14140 />}
      receptionFormDialog={
        <ReceptionFormDialog14140
          form={<ReceptionForm14140 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      checkpointDxGrid={<CheckpointDxGrid14140 />}

      administratorBCDxGrid={<AdministratorBCRequestsDxGrid14140 />}
      administratorBCForm={<ReceptionForm14140 />}
      administratorBCFormDialog={
        <ReceptionFormDialog14140
          form={<ReceptionForm14140 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      administratorBCServiceForm={<AdministratorBCServiceRequestsForm14140 />}

      tenantForm={<TenantForm14140 />}
      tenantDxGrid={<TenantDxGrid14140 />}

      makingAgreementBCDxGrid={<MakingAgreementBCDxGrid14140 />}
      makingAgreementBCForm={<MakingAgreementBCForm14140 />}

      {...props}
    />
  );
}

export default RequestsPage14140;
