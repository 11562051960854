/*
 *
 * AssignedCompleteForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { COMPLETED } from 'domain/Data/RequestStatus/constants';
import { ASSIGNED_COMPLETE_FORM } from './constants';

class AssignedCompleteForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'assignee',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: false,
        calculatedValue: () => COMPLETED,
      },
      {
        name: 'whatHappened',
        label: 'Что случилось',
        multiline: false,
        visible: true,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'contactPersonFullName',
        label: 'Контактное лицо',
        visible: false,
      },
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        required: true,
        visible: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: true,
        grid: { sm: 12 },
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={ASSIGNED_COMPLETE_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default AssignedCompleteForm;
