/*
 *
 * CheckpointFormDialogContainer12924
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CHECKPOINT_REQUESTS_FORM_DIALOG } from 'pages/Requests/CheckpointView/constants';

import CheckpointFormDialog12924 from './CheckpointFormDialog12924';
import { executeOpenBarrierAction } from './actions';

class CheckpointFormDialogContainer12924 extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <CheckpointFormDialog12924
        onOpenBarrier={this.props.executeOpenBarrierAction}
        {...this.props}
      />
    );
  }
}

CheckpointFormDialogContainer12924.propTypes = {
  executeOpenBarrierAction: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    executeOpenBarrierAction: () => dispatch(executeOpenBarrierAction(CHECKPOINT_REQUESTS_FORM_DIALOG)),
  };
}

export default connect(null, mapDispatchToProps)(CheckpointFormDialogContainer12924);
