import { createMuiTheme } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

import { account } from 'domain/typeConstants/accounts';

import khimkiFavicon from 'custom/14326/assets/khimki-favicon.ico';

import withRoot from './withRoot';

import { changeFavicon } from './utils';

function withRootWrapper({ component, accountId }) {
  let primary = blue;
  const accent = cyan;
  const error = red;

  if (accountId === account.COMCITY) {
    primary = orange;
  }
  if (accountId === account.BC_KHIMKI) {
    primary = { main: '#217f90' };
    changeFavicon(khimkiFavicon);
  }
  if ([account.KRYLATSKI_HOLMY, account.BC_BELIYE_SADY, account.BC_4_VETRA].includes(accountId)) {
    primary = { main: '#223369' };
  }

  const theme = createMuiTheme({
    palette: { primary, accent, error },
    overrides: {
      MuiTableCell: {
        root: {
          whiteSpace: 'nowrap',
        },
        paddingDense: {
          paddingLeft: 0,
        },
      },
      MuiInput: {
        error: {
          '&:after': {
            backgroundColor: error.A700,
          },
        },
      },
      MuiFormLabel: {
        error: {
          color: error.A700,
        },
      },
      MuiFormHelperText: {
        error: {
          color: error.A700,
        },
      },
    },
  });

  return withRoot(theme)(component);
}

export default withRootWrapper;
