/*
 *
 * CheckpointParking
 *
 */

import React from 'react';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { checkpointColumnSize } from 'domain/typeConstants/table';
import { CHECKPOINT_PARKING_DATA_TABLE } from './constants';

class CheckpointParkingDataTable extends React.PureComponent {

  getFilters() {
    return [];
  }

  getColumns() {
    return [
      {
        name: 'name',
        path: 'name',
        title: 'Наименование',
        width: checkpointColumnSize.NAME
      },
      {
        name: 'capacity',
        path: 'capacity',
        title: 'Вместимость',
        width: checkpointColumnSize.CAPACITY
      },
      {
        name: 'busyPlacesCount',
        path: 'busyPlacesCount',
        title: 'На территории',
        width: checkpointColumnSize.BUSY_PLACES_COUNT
      },
      {
        name: 'aceptedRequestsCount',
        path: 'aceptedRequestsCount',
        title: 'Принято заявок',
        width: checkpointColumnSize.ACEPTED_REQUESTS_COUNT
      },
      {
        name: 'freeGuestPlacesCount',
        path: 'freeGuestPlacesCount',
        title: 'Свободно',
        width: checkpointColumnSize.FREE_GUEST_PLACES_COUNT
      },
    ];
  }

  getColumnOrder() {
    return ['name', 'capacity', 'busyPlacesCount', 'aceptedRequestsCount', 'freeGuestPlacesCount',];
  }

  getSorting() {
    return [{ columnId: 'name', columnName: 'name', direction: 'asc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [{ name: settings.SETTING_BUTTON, value: false },];
  }

  render() {
    const { viewName } = this.props
    return (
      <AdvancedDataTable
        name={CHECKPOINT_PARKING_DATA_TABLE}
        entityName={'parkings'}
        columns={this.getColumns()}
        columnOrder={this.getColumnOrder()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        tableFeatures={this.getTableFeatures()}
        sorting={this.getSorting()}
        viewName={viewName}
        onActionExecuting={() => null}
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

export default CheckpointParkingDataTable;
