/**
 *
 * Autocomplete Field
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Autosuggest from 'react-autosuggest';
import { match, parse } from 'autosuggest-highlight';

import { withStyles } from '@material-ui/core/styles/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import Paper from '@material-ui/core/Paper/index';
import TextField from '@material-ui/core/TextField/index';

import axios from 'axios/index';
import deburr from 'lodash/deburr';
import { kyrilicKeyboardLayoutToLatin } from 'utils/common';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

const getSuggestions = (value, suggestions) => {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => suggestion.name);
};

const getSuggestionValue = (suggestion) => suggestion.name;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: 500,
    marginTop: 10,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class IntegrationAutosuggest extends React.Component {
  signal = axios.CancelToken.source(); // eslint-disable-line

  state = {
    suggestions: [],
  };

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
  }

  componentDidUpdate() {
    const {
      changeIsValueScanned,
      isValueScanned,
      setValue,
    } = this.props;

    if (isValueScanned) {
      const { suggestions } = this.state;
      setValue(Array.isArray(suggestions) && suggestions.length !== 0 ? suggestions[0] : {});
      changeIsValueScanned(false);
      this.setState({ suggestions: [] }); // eslint-disable-line
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { entityName, pageNumber, pageSize } = this.props;

    axios.get(`/vNext/v1/${entityName}`, {
      cancelToken: this.signal.token,
      params: {
        searchQuery: value && kyrilicKeyboardLayoutToLatin(value),
        pageNumber,
        pageSize,
      },
    }).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          suggestions: getSuggestions(value, res.data),
        });
      }
    });
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    const { setValue } = this.props;
    if (setValue) setValue(suggestion);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const {
      classes,
      onChange,
      onKeyDown,
      placeholder,
      value,
    } = this.props;
    const { suggestions } = this.state;

    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          highlightFirstSuggestion
          inputProps={{
            classes,
            placeholder,
            value,
            onChange,
            onKeyDown,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  changeIsValueScanned: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  isValueScanned: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

IntegrationAutosuggest.defaultProps = {
  pageNumber: 1,
  pageSize: 5,
  placeholder: '',
  value: '',
};

export default withStyles(styles)(IntegrationAutosuggest);
