/**
 *
 * TaskView sagas
 *
 */

import { select, put, takeLatest } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openEditDialog,
} from 'containers/AdvancedFormDialog/actions';
import { makeSelectParent, makeSelectParentId } from 'containers/AdvancedFormDialog/selectors';
import {
  USER_TASK_TABLE_GRID,
  USER_TASK_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
} from './constants';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && action.meta.dialog === USER_TASK_FORM_DIALOG;

export default function* rootSaga() {
  yield takeLatest(EXECUTE_ADD_ACTION, executeAddActionSaga);
  yield takeLatest(EXECUTE_EDIT_ACTION, executeEditActionSaga);
  yield takeLatest([needViewRefresh, DISPLAY_NOTIFICATION], executeNeedViewRefreshSaga);
};

function* executeNeedViewRefreshSaga() {
  const mode = yield select(makeSelectViewMode());
  if (mode === 'mobile') {
    yield put(loadMobileData(USER_TASK_TABLE_GRID))
  } else {
    const parentId = yield select(makeSelectParentId(USER_TASK_FORM_DIALOG))
    const parent = yield select(makeSelectParent(USER_TASK_FORM_DIALOG))
    if (parent && parentId) {
      yield put(loadData(USER_TASK_TABLE_GRID, { parent, parentId, mode: 'detail' }))
    }
  }
}

function* executeAddActionSaga({ payload }) {
  yield put(openAddDialog(USER_TASK_FORM_DIALOG, payload));
}


function* executeEditActionSaga({ payload }) {
  if (payload.id) {
    yield put(openEditDialog(USER_TASK_FORM_DIALOG, payload));
  }
}

