/**
 *
 * Account types
 *
 *
 */

export const account = Object.freeze({
  BC_71_7ONE: '14002',
  BC_BERLINSKY_GENEVSKY: '14140',
  BC_CENTRAL_YARD: '14163',
  BC_DELTA_PLAZA: '15511',
  BC_KHIMKI: '14326',
  BC_LESNAYA: '14366',
  BC_BOLSHEVICK: '12405',
  BP_FAKTORIYA: '14419',
  BC_VISHNIAKI: '14328',
  WALLSTREET: '14254',
  COMCITY: '12970',
  LEFORT: '11994',
  NEW_HOLLAND: '13539',
  SILVER_CITY: '13557',
  TERMAL: '13475',
  ZHUKOV: '12924',
  LINKOR: '14278',
  VOZDVIZHENKA: '14691',
  ALTSOFT: '1000',
  BC_GORKIPARK_TOWER: '14720',
  ART_GALLERY: '14927',
  KRYLATSKI_HOLMY: '15755',
  BINOM: '12861',
  MOK_AFI_SQUARE: '15776',
  BC_BELIYE_SADY: '15774',
  BC_4_VETRA: '15783',
  TVK: '15767',
});
