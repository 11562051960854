/**
 *
 * WorkDxGridContainer
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction } from 'domain/typeConstants/action';
import * as actionCreators from './actions';

class WorkDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record, { parent, parentId }) => this.handleActionExecuting('edit', [record.id], { parent, parentId })

  handleActionExecuting = (actionName, ids, { parent, parentId }) => {
    switch (actionName) {
      case requestAction.ADD:
        this.props.executeAddAction(parent, parentId);
        break;

      case requestAction.EDIT:
        this.props.executeEditAction(ids[0], parent, parentId);
        break;

      case requestAction.COPY:
        this.props.executeCopyAction(ids[0], parent, parentId);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, parentId, height, dialogName, name } = this.props;
    return React.cloneElement(component, {
      name,
      parentId,
      height,
      dialogName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
    });
  }
}

WorkDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  executeAddAction: PropTypes.func.isRequired,
  executeCopyAction: PropTypes.func.isRequired,
  executeEditAction: PropTypes.func.isRequired,
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(WorkDxGridContainer);
