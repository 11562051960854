/**
 *
 * SupplyPositionView constants
 *
 */

export const SUPPLY_REQUEST_DX_GRID = 'SupplyRequestsDxGrid';
export const SUPPLY_REQUEST_POSITION_FORM_DIALOG = 'SupplyRequestPositionFormDialog';
export const SUPPLY_REQUEST_POSITION_FORM = 'SupplyRequestPositionForm';
export const SUPPLY_POSITION_DX_GRID = 'SupplyPositionDxGrid';

export const EXECUTE_CARGO_RECEIVED_ACTION = 'claris-vnext/DocumentsPage/SupplyPositionView/EXECUTE_CARGO_RECEIVED_ACTION';
export const EXECUTE_ORIGINALS_DELIVERED_ACTION = 'claris-vnext/DocumentsPage/SupplyPositionView/EXECUTE_ORIGINALS_DELIVERED_ACTION';

