/*
 *
 * FileFieldCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createFileFieldContainer from './createFileFieldContainer';

class FileFieldCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const FileFieldContainer = createFileFieldContainer(this.context.advancedForm, this.props.name);
    return <FileFieldContainer {...this.props} />;
  }
}

FileFieldCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

FileFieldCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FileFieldCreator;
