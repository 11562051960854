/**
 *
 * AgreementDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './sagas';

import { DIALOG_NAME } from './constants';
import * as actionCreators from './actions';
import AgreementDialog from './AgreementDialog';
import { makeSelectIsOpen, makeSelectRowIds, makeSelectDxGrid } from './selectors';

class AgreementDialogContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleSubmit = (ids, comment, dxGrid) => this.props.submit(ids, comment, dxGrid);

  handleCancel = () => this.props.close();

  render() {
    const {
      open,
      ids,
      dxGrid,
    } = this.props;

    return (
      <AgreementDialog
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        open={open}
        ids={ids}
        dxGrid={dxGrid}
      />
    );
  }
}

AgreementDialogContainer.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
};

const mapStateToProps = createStructuredSelector({
  open: makeSelectIsOpen(),
  ids: makeSelectRowIds(),
  dxGrid: makeSelectDxGrid(),
});

function mapDispatchToProps(dispatch) {
  return {
    submit: (ids, comment, dxGrid) => dispatch(actionCreators.submitAgreementDialog(ids, comment, dxGrid)),
    close: () => dispatch(actionCreators.closeAgreementDialog()),
  };
}

const withReducer = injectReducer({ key: DIALOG_NAME, reducer });
const withSaga = injectSaga({ key: DIALOG_NAME, saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(AgreementDialogContainer);
