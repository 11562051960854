/*
 *
 *  AdministratorBCServiceRequestsForm 14720
 *
 */

import { ServiceRequestsManagerForm14720 } from "../ServiceRequestsManagerView";
import { estimationCost, estimationCostScan } from "../common/dxGridComponents/fields";

export class AdministratorBCServiceRequestsForm14720 extends ServiceRequestsManagerForm14720 {
    getFields() {
        const fields = super.getFields();

        fields.push(estimationCost);
        fields.push(estimationCostScan);

        return fields;
    }
}
