/**
 *
 * MakingAgreementBCMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import { columnType } from 'domain/typeConstants/table';

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_MAKING_AGREEMENT_BC,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
} from 'domain/Data/Filters/constants';

import { MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID } from './constants';

import {
  acceptActionItem,
  rejectActionItem,
  viewActionItem,
} from '../common/actionMenu';

import {
  RowComponent, getRowHeight, statusColorLabels,
} from '../common/mobile';

class MakingAgreementBCMobileGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props; // eslint-disable-line
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') filters.push(REQUESTS_FOR_MAKING_AGREEMENT_BC);
    return filters;
  }

  getDetailRowFields() {
    return [
      {
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
      },
      {
        name: 'visitorFullName',
        title: 'ФИО посетителя',
      },
      {
        name: 'carNumber',
        title: '№ авто',
      },
      {
        name: 'carModelText',
        title: 'Марка',
      },
      {
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
      },
      {
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
      },
      {
        name: 'statusName',
        getValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: statusColorLabels,
      },
      {
        name: 'typeShortName',
        getValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
      },
      {
        name: 'company_name',
        getValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
      },
      {
        name: 'documentDetails',
        title: 'Серия и №',
      },
      {
        name: 'passNumber',
        title: '№ пропуска',
      },
      {
        name: 'parkingName',
        getValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
      },
      {
        name: 'parkingPlace',
        title: 'Место',
      },
      {
        name: 'materialValuesData',
        title: 'Мат. ценности',
      },
      {
        name: 'companyName',
        title: 'Откуда посетитель',
      },
      {
        name: 'whatHappened',
        title: 'Что случилось',
      },
      {
        name: 'serviceName',
        getValue: (row) => (row.service ? row.service.name : undefined),
        title: 'Сервис',
      },
      {
        name: 'note',
        title: 'Примечание',
      },
      {
        name: 'blackListNote',
        title: 'Причина ЧС',
      },
      {
        name: 'authorName',
        getValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
      },
    ];
  }

  getActionButtons() {
    return [];
  }

  getActionMenuItems() {
    return [viewActionItem, acceptActionItem, rejectActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="requests"
        name={MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        filters={this.getFilters()}
        onActionExecuting={onActionExecuting}
        sorting={this.getSorting()}
        viewName={viewName}
        detailRowFields={this.getDetailRowFields()}
        rowComponent={RowComponent}
        getRowHeight={getRowHeight}
      />
    );
  }
}

MakingAgreementBCMobileGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  viewName: PropTypes.string,
};

export default MakingAgreementBCMobileGrid;
