/**
 *
 * ServiceRequestsManagerCompleteForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { COMPLETED } from 'domain/Data/RequestStatus/constants';
import { formField } from 'domain/typeConstants/form';
import { SERVICE_REQUESTS_MANAGER_COMPLETE_FORM } from './constants';

class ServiceRequestsManagerCompleteForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'assignee',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: false,
        calculatedValue: () => COMPLETED,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: true,
        grid: { sm: 12 },
        required: true,
        disabled: true,
      },
      {
        name: 'executionTerm',
        type: formField.DATE,
        label: 'Срок исполнения',
        required: true,
        visible: true,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
        disabled: true
      },
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        required: true,
        visible: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: () => currentDate,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: true,
        grid: { sm: 12 },
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={SERVICE_REQUESTS_MANAGER_COMPLETE_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default ServiceRequestsManagerCompleteForm;
