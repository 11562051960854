/**
 *
 * AdminSkudView constants
 *
 */


export const ADMIN_SKUD_REQUESTS_TABLE_GRID = 'AdminSkudRequestsTableGrid';
export const ADMIN_SKUD_REQUESTS_FORM = 'AdminSkudRequestsForm';
export const ADMIN_SKUD_REQUESTS_FORM_DIALOG = 'AdminSkudRequestsFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/AdminSkudView/EXECUTE_ADD_ACTION';
export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/RequestsPage/AdminSkudView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/RequestsPage/AdminSkudView/EXECUTE_COPY_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RequestsPage/AdminSkudView/EXECUTE_EDIT_ACTION';
export const EXECUTE_REJECT_ACTION = 'claris-vnext/RequestsPage/AdminSkudView/EXECUTE_REJECT_ACTION';
