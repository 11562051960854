/**
 *
 * AlertDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actionCreators from './actions';
import AlertDialog from './AlertDialog';
import {
  makeSelectOpen,
  makeSelectTitle,
  makeSelectContent,
  makeSelectType,
} from './selectors';

class AlertDialogContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleRequestClose = () => this.props.close();

  handleConfirm = () => {
    this.props.confirm();
    this.props.close();
  }

  render() {
    const { open, title, content, type } = this.props;

    return (
      <AlertDialog
        open={open}
        title={title}
        content={content}
        type={type}
        onRequestClose={this.handleRequestClose}
        onConfirm={this.handleConfirm}
      />
    );
  }
}

AlertDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  open: makeSelectOpen(),
  title: makeSelectTitle(),
  content: makeSelectContent(),
  type: makeSelectType(),
});

function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(actionCreators.closeAlertDialog()),
    confirm: () => dispatch(actionCreators.confirmAlertDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialogContainer);
