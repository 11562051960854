import React from "react";
import {requestAction} from "domain/typeConstants/action";
import AddIcon from "@material-ui/icons//AddCircleOutline";
import {ON_AGREED, ON_AGREEMENT} from "domain/Data/DocumentState/constants";

export const addActionButton = {
    name: requestAction.ADD,
    disabled: (currentValues) => {
        return !currentValues.project || (currentValues?.state && [ON_AGREEMENT, ON_AGREED].includes(currentValues?.state))
    },
    svgIcon: <AddIcon/>
};
