/**
 *
 * TenantFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { TENANT_REQUESTS_FORM, TENANT_REQUESTS_FORM_DIALOG } from './constants';

function TenantFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={TENANT_REQUESTS_FORM_DIALOG}
      formName={TENANT_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
    />
  );
}

TenantFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default TenantFormDialog;
