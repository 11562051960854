/*
 *
 * ChangePasswordForm actions
 *
 */

import { SUBMIT } from './constants';

export function submit(oldPassword, newPassword, newPasswordConfirmation) {
  return {
    type: SUBMIT,
    payload: {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
    },
  };
}
