/**
 *
 * ColumnStatusLabels
 *
 */

import { agreementHistory, documentStatus, documentPositionStatus, supplierInvoiceState } from 'domain/typeConstants/status';

export const columnStatusLabels = [
  { backgroundColor: '#69F0AE', fontColor: 'black', condition: (value) => value === agreementHistory.AGREED },
  { backgroundColor: '#69F0AE', fontColor: 'black', condition: (value) => value === agreementHistory.COMPLETED },
  { backgroundColor: '#69F0AE', fontColor: 'black', condition: (value) => value === documentStatus.AGREED },
  { backgroundColor: '#81D4FA', fontColor: 'black', condition: (value) => value === documentStatus.ON_AGREEMENT },
  { backgroundColor: '#A5D6A7', fontColor: 'black', condition: (value) => value === documentStatus.IN_OPERATION },
  { backgroundColor: '#EF5350', fontColor: 'black', condition: (value) => value === documentStatus.POSTPONED },
  { backgroundColor: '#EF9A9A', fontColor: 'black', condition: (value) => value === agreementHistory.REJECTED },
  { backgroundColor: '#EF9A9A', fontColor: 'black', condition: (value) => value === documentStatus.REJECTED },
  { backgroundColor: '#CCCCCC', fontColor: 'black', condition: (value) => value === documentStatus.ARCHIVED },
  { backgroundColor: '#FFAB91', fontColor: 'black', condition: (value) => value === documentStatus.WAITING_FOR_PAYMENT },

  { backgroundColor: '#81D4FA', fontColor: 'black', condition: (value) => value === documentPositionStatus.SENT },
  { backgroundColor: '#F9FE40', fontColor: 'black', condition: (value) => value === documentPositionStatus.ORDERED },
  { backgroundColor: '#FFAB91', fontColor: 'black', condition: (value) => value === documentPositionStatus.REJECTED },
  { backgroundColor: '#669999', fontColor: 'black', condition: (value) => value === documentPositionStatus.CLARIFICATION },
  { backgroundColor: '#A5D6A7', fontColor: 'black', condition: (value) => value === documentPositionStatus.COMPLETED },

  { backgroundColor: '#FFFF99', fontColor: 'black', condition: (value) => value === supplierInvoiceState.PROCESSED },
  { backgroundColor: '#66cc66', fontColor: 'black', condition: (value) => value === supplierInvoiceState.IN_PROGRESS },
  { backgroundColor: '#e66f6d', fontColor: '#CCFFFF', condition: (value) => value === supplierInvoiceState.POSTPONED },
  { backgroundColor: '#FFFFFF', fontColor: '#808080', condition: (value) => value === supplierInvoiceState.FILED_ARCHIVE },
  { backgroundColor: '#84bae7', fontColor: 'black', condition: (value) => value === supplierInvoiceState.PENDING_APPROVAL },
  { backgroundColor: '#ebbdc5', fontColor: 'black', condition: (value) => value === supplierInvoiceState.AGREED },
  { backgroundColor: '#FFCC00', fontColor: 'black', condition: (value) => value === supplierInvoiceState.PENDING_PAIMENT },
  { backgroundColor: '#FF9900', fontColor: 'black', condition: (value) => value === supplierInvoiceState.PAID },

];
