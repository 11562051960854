/**
 *
 * AdminSkudView actions
 *
 */

import {
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_ADD_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_REJECT_ACTION,
} from './constants';

export function executeAddAction() {
  return {
    type: EXECUTE_ADD_ACTION,
  };
}

export function executeEditAction(id) {
  return {
    type: EXECUTE_EDIT_ACTION,
    payload: { id },
  };
}

export function executeCopyAction(id) {
  return {
    type: EXECUTE_COPY_ACTION,
    payload: { id },
  };
}

export function executeAcceptAction(ids) {
  return {
    type: EXECUTE_ACCEPT_ACTION,
    payload: { ids },
  };
}

export function executeRejectAction(ids) {
  return {
    type: EXECUTE_REJECT_ACTION,
    payload: { ids },
  };
}
