/**
 *
 * action menu item 14691(BC Vozdvizhenka)
 *
 */

import React from "react";

import Done from "@material-ui/icons/Done";
import FileCopy from '@material-ui/icons/FileCopy';
import { requestAction } from "domain/typeConstants/action";
import { requestStatus } from "domain/typeConstants/status";

import { getValueFromLocalStorage } from "utils/localStorage";
import { GLOBAL_USER_KEY } from "global-constants";
import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';

export const acceptActionItem = {
    name: requestAction.ACCEPT,
    label: 'Принять',
    icon: 'done',
    svgIcon: <Done />,
    hidden: (itemMenuRecord, entities, currentRecord) => {
        if (currentRecord) {
            if (!currentRecord.status) return false
            return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED,
            requestStatus.IN_PROGRESS, requestStatus.AGREED].includes(currentRecord.status.name)
                || (currentRecord.status.name === requestStatus.REJECTED
                    && currentRecord.agreedEmployee?.id !== getValueFromLocalStorage(GLOBAL_USER_KEY).id
                )
        }
        return true;
    },
};

export const copyActionItem = {
    name: requestAction.COPY,
    label: 'Копия',
    icon: 'content_copy',
    svgIcon: <FileCopy />,

    hidden: (itemMenuRecord, entities, currentRecord) => {
        if (currentRecord) {
            if (!currentRecord.type) return false
            return currentRecord.type.id !== ON_CAR_ENTRY
        }
        return true;
    },
};
