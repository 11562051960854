/**
 *
 * ReceptionDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from './actions';
import { requestAction } from "../../../domain/typeConstants/action";

class ReceptionDxGridContainerBase extends React.PureComponent {

    handleRowDoubleClick = (record) => this.props.executeEditAction(record.id);

    handleActionExecuting = (actionName, ids) => {
        switch (actionName) {
            case requestAction.ADD:
                this.props.executeAddAction();
                break;

            case requestAction.EDIT:
                this.props.executeEditAction(ids[0]);
                break;

            case requestAction.VIEW:
                this.props.executeViewAction(ids[0]);
                break;

            case requestAction.COPY:
                this.props.executeCopyAction(ids[0]);
                break;

            default:
                throw new Error('Executed a unknown action.');
        }
    };

    render() {
        const { component, viewName } = this.props;
        return React.cloneElement(component, {
            viewName,
            onActionExecuting: this.handleActionExecuting,
            onRowDoubleClick: this.handleRowDoubleClick,
        });
    }
}

ReceptionDxGridContainerBase.propTypes = {
    component: PropTypes.node.isRequired,
    viewName: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export const ReceptionDxGridContainer = connect(null, mapDispatchToProps)(ReceptionDxGridContainerBase);
