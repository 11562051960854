/**
*
* DateField
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from 'material-ui-pickers';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

class DateTimeField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.input.value,
      time: '00:00',
    };
    this.timerId = null;
  }

  componentDidMount() {
    const { input: { value } } = this.props;
    let delay = 100;
    if (value !== "") { delay = 0 }
    this.timerId = setTimeout(() => { this.setState({ time: this.getTime() }); }, delay)
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  componentDidUpdate(prevProps) {
    const { input: { value } } = this.props;
    if (prevProps.input.value !== value) {
      this.setState({ date: value });
    }
  }

  handleDateChange = (date) => {
    const { input: { onChange } } = this.props;
    const d = new Date(date);
    const newDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), this.state.time.slice(0, 2), this.state.time.slice(3));
    onChange(newDate);
    this.setState({ date: newDate });
  };

  handleTimeChange = (event) => {
    const { input: { onChange } } = this.props;
    const d = new Date(this.state.date);
    const newDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), event.target.value.slice(0, 2), event.target.value.slice(3));
    onChange(newDate);
    this.setState({
      date: newDate,
      time: event.target.value
    })

  };

  getTime = () => {
    const time = new Date(this.state.date);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      timezone: 'UTC'
    };
    return time.toLocaleString("ru", options);
  }

  getTimeList = () => {
    let timeList = [];
    for (let i = 0; i < 24; i++) {
      const t = i < 10 ? `0${i}` : i;
      timeList.push(`${t}:00`);
      timeList.push(`${t}:30`);
    }
    this.state.time && !timeList.includes(this.state.time) && timeList.push(this.state.time);
    return timeList;
  }

  handleClearDateChange = (event) => {
    event.stopPropagation();
    const { input: { onChange } } = this.props;
    onChange(null);
    this.setState({ date: null });
  };

  handleInputClick = (event) => {
    const { readOnly } = this.props;
    if (readOnly) event.stopPropagation();
  };

  render() {
    const {
      classNames,
      disabled,
      helperText,
      input,
      label,
      meta: { touched, error, submitFailed },
      readOnly,
      required,
      ...custom
    } = this.props;
    const { date } = this.state;

    const timeList = this.getTimeList();

    return (
      <div>
        <Grid container item xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Grid item>
            <DatePicker
              format="DD.MM.YYYY"
              autoOk
              margin="none"
              label={required ? `${label} *` : label}
              cancelLabel="Отмена"
              error={(touched || submitFailed) && !!error}
              helperText={(touched || submitFailed) && error ? error : helperText}
              invalidLabel=""
              disabled={disabled}
              FormHelperTextProps={{
                classes: {
                  root: classNames.helperText,
                },
              }}
              {...input}
              onBlur={() => null}
              onChange={this.handleDateChange}
              value={date}
              {...custom}
            />
          </Grid>
          <Grid item>
            <TextField
              margin="none"
              select
              label={"Время"}
              disabled={disabled}
              value={this.state.time}
              onChange={this.handleTimeChange}
            >
              {
                timeList.map((timeItem) => (
                  <MenuItem
                    key={timeItem}
                    value={timeItem}
                  >
                    {timeItem}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DateTimeField.propTypes = {
  classNames: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

DateTimeField.defaultProps = {
  classNames: {},
};

export default DateTimeField;
