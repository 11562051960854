import { ON_PASS_MAKING, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL } from "domain/Data/RequestType/constants"

export const groupRequestVisitorsDataHelperText = () => {
    return 'ФИО, № авто (формат: х111хх77) и марка пишутся в одну строку, каждый посетитель пишется с новой строки'
}


export const visitorFullNameLabel = (values) => {
    if ([ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, ON_PASS_MAKING].includes(values.type)) {
        return 'ФИО'
    }
    return 'ФИО посетителя'
}