/*
 *
 * ReceptionView actions
 *
 */

import {
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_SEND_TO_AGREMENT_ACTION,
  EXECUTE_SEND_TO_PERFORMER,
  ACCEPT_REQUESTS,
  ACCEPT_REQUESTS_ERROR,
  ACCEPT_REQUESTS_SUCCESS,

  EXECUTE_REJECT_ACTION,
  REJECT_REQUESTS,
  REJECT_REQUESTS_ERROR,
  REJECT_REQUESTS_SUCCESS,

  SEND_EMAIL,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
  KHIMKI_RECEPTION_UPDATE_FORM_FIELD,
  LOAD_DIALOG_DATA,
  EXECUTE_CLOSE_ACTION,
} from './constants';

export function executeAcceptAction(dialogName) {
  return {
    type: EXECUTE_ACCEPT_ACTION,
    meta: { dialogName },
  };
}

export function executeSendToAgreementByTenantAction(dialogName) {
  return {
    type: EXECUTE_SEND_TO_AGREMENT_ACTION,
    meta: { dialogName },
  };
}

export function executeSendToPerformer(dialogName) {
  return {
    type: EXECUTE_SEND_TO_PERFORMER,
    meta: { dialogName },
  };
}

export function updateFormField(formName, field) {
  return {
    type: KHIMKI_RECEPTION_UPDATE_FORM_FIELD,
    meta: { formName },
    payload: { field },
  };
}

export function acceptRequests() {
  return {
    type: ACCEPT_REQUESTS,
  };
}

export function requestsAccepted() {
  return {
    type: ACCEPT_REQUESTS_SUCCESS,
  };
}

export function requestsAcceptingError(error) {
  return {
    type: ACCEPT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function executeRejectAction(dialogName) {
  return {
    type: EXECUTE_REJECT_ACTION,
    meta: { dialogName },
  };
}

export function rejectRequests() {
  return {
    type: REJECT_REQUESTS,
  };
}

export function requestsRejected() {
  return {
    type: REJECT_REQUESTS_SUCCESS,
  };
}

export function requestsRejectingError(error) {
  return {
    type: REJECT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function sendEmail(ids) {
  return {
    type: SEND_EMAIL,
    payload: { ids },
  };
}

export function sendedEmail() {
  return {
    type: SEND_EMAIL_SUCCESS,
  };
}

export function sendingEmailError(error) {
  return {
    type: SEND_EMAIL_ERROR,
    payload: { error },
  };
}

export function executeCloseAction(dialogName) {
  return {
    type: EXECUTE_CLOSE_ACTION,
    meta: { dialogName },
  };
}

export function loadDialogData(entityName, id) {
  return {
    type: LOAD_DIALOG_DATA,
    payload: { entityName, id }
  };
}
