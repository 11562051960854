import React from 'react';
import PropTypes from 'prop-types';

import {
  setValueToLocalStorage,
  getValueFromLocalStorage,
} from 'utils/localStorage';

import { ACCOUNT_ID_KEY } from 'global-constants';
import { account } from 'domain/typeConstants/accounts';

import LoginPage from 'pages/Login';

const LoginPage12970 = ({ location: Location }) => {
  const presetAccountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);

  if (!presetAccountId || (presetAccountId !== account.COMCITY)) {
    setValueToLocalStorage(ACCOUNT_ID_KEY, account.COMCITY);
    location.reload(); // eslint-disable-line no-restricted-globals
  }

  return (<LoginPage location={Location} />);
};

LoginPage12970.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LoginPage12970;
