/**
 *
 * AdministratorForm15783
 *
 */
import {ReceptionForm15783} from "../ReceptionView";

export class AdministratorForm15783 extends ReceptionForm15783 {

}
