/**
*  TextFilterEditor Component
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/index';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => ({
    textField: {
        marginLeft: '6px',
    },
    inputRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'inline-flex',
        position: 'relative',
        fontSize: '14px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.1875em',
        minWidth: '150px',
    },
    hiddenSpan: {
        opacity: 0,
        position: 'absolute',
        zIndex: -100,
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
});

class TextFilterEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            value: '',
            showTooltip: false,
        };
        this.fieldRef = React.createRef();
        this.hiddenSpanRef = React.createRef();
    }

    handleMouseLeave = () => {
        this.setState({ showTooltip: false })
    }

    handleMouseEnter = () => {
        const field = this.fieldRef.current.getBoundingClientRect();
        const span = this.hiddenSpanRef.current.getBoundingClientRect();
        const showTooltip = field && span ? field.width < span.width : false
        this.setState({ showTooltip });
    }

    renderHiddenSpan = (outputValue) => {
        const { classes } = this.props;
        return (
            <span ref={this.hiddenSpanRef} className={classes.hiddenSpan}>
                {outputValue.replaceAll(' ', '_') || ''}
            </span>
        )
    }

    render() {
        const { classes, filter: { outputValue, columnName }, onChange } = this.props;
        const { showTooltip } = this.state;
        return (
            <div ref={this.fieldRef}>
                <Tooltip
                    id={`tooltip-${columnName}`}
                    placement="bottom"
                    title={outputValue}
                    open={showTooltip}
                >
                    <TextField
                        className={classes.textField}
                        name={columnName}
                        value={outputValue}
                        onChange={onChange}
                        onMouseLeave={this.handleMouseLeave}
                        onMouseEnter={this.handleMouseEnter}
                        fullWidth
                        InputProps={{ classes: { root: classes.inputRoot } }}
                    />
                </Tooltip >
                {this.renderHiddenSpan(outputValue)}
            </div>
        );
    }
}

TextFilterEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

TextFilterEditor.defaultProps = {
    value: undefined,
};

export default withStyles(styles)(TextFilterEditor);
