/**
*
* PageContent
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import { NAVIGATION_DRAWER_WIDTH } from 'containers/NavigationDrawer/constants';

const styleSheet = {
  pageContent: {
    width: `calc(100% - ${NAVIGATION_DRAWER_WIDTH}px)`,
    marginLeft: 0,
    flexGrow: 1,
    height: 'calc(100% - 56px)',
    marginTop: 56,
  },
  pageContentShift: {
    marginLeft: NAVIGATION_DRAWER_WIDTH,
  },
};

function PageContent({ breakpoint, children, classes, navigationDrawerIsOpen }) {
  return (
    <div
      className={classNames(
        classes.pageContent,
        {
          [classes.pageContentShift]: navigationDrawerIsOpen && breakpoint.up('sm'),
        }
      )}
    >
      {children}
    </div>
  );
}

PageContent.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  navigationDrawerIsOpen: PropTypes.bool.isRequired,
};

export default withStyles(styleSheet)(PageContent);
