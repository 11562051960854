import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import Redo from '@material-ui/icons/Redo';
import Undo from '@material-ui/icons/Undo';

const styles = () => ({
  toolbar: {
    alignItems: 'center',
    background: '#E0E0E0',
    padding: '0 10px',
    width: '100%',
  },
  center: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  number: {
    background: '#EEEEEE',
    padding: '2px 6px 2px 6px',
    borderRadius: 5,
    margin: 5,
    boxShadow: '0 2px 5px rgba(0, 0, 0, .5)',
  },
});

const UNKNOWN = 'Неизвестно';

const ToolbarBase = ({
  classes,
  currentPage,
  onClockwiseRotation,
  onCounterclockwiseRotation,
  onNextPage,
  onPreviousPage,
  onZoomIn,
  onZoomOut,
  pagesAmount,
  scale,
}) => {
  let computedScale = null;

  if (((scale * 100) % 10) === 0) {
    computedScale = `${(scale * 100)}%`;
  } else if (((scale * 100) % 10) < 1) {
    computedScale = `${Math.round(scale * 100)}%`;
  } else {
    computedScale = `${Math.ceil(scale * 100)}%`;
  }

  return (
    <Grid container className={classes.toolbar}>
      <Grid item xl={3} lg={3} md={3} sm={3} xs={3} className={classes.center}>
        <IconButton
          aria-label="ZoomOut"
          disabled={!pagesAmount}
          onClick={onZoomOut}
        >
          <RemoveCircleOutline />
        </IconButton>
        <span className={classes.number}>
          {computedScale}
        </span>
        <IconButton
          aria-label="ZoomIn"
          disabled={!pagesAmount}
          onClick={onZoomIn}
        >
          <AddCircleOutline />
        </IconButton>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.center}>
        <IconButton
          aria-label="NavigateBefore"
          disabled={!pagesAmount}
          onClick={onPreviousPage}
        >
          <NavigateBefore />
        </IconButton>
        <span className={classes.number}>
          {currentPage}
        </span>
        <IconButton
          aria-label="NavigateNext"
          disabled={!pagesAmount}
          onClick={onNextPage}
        >
          <NavigateNext />
        </IconButton>
        <span className={classes.number}>
          {` из ${pagesAmount || UNKNOWN}`}
        </span>
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={3} xs={3} className={classes.center}>
        <IconButton
          aria-label="counterclockwiseRotation"
          disabled={!pagesAmount}
          onClick={onCounterclockwiseRotation}
        >
          <Undo />
        </IconButton>
        <IconButton
          aria-label="clockwiseRotation"
          disabled={!pagesAmount}
          onClick={onClockwiseRotation}
        >
          <Redo />
        </IconButton>
      </Grid>
    </Grid>
  );
};

ToolbarBase.propTypes = {
  classes: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  onClockwiseRotation: PropTypes.func.isRequired,
  onCounterclockwiseRotation: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  pagesAmount: PropTypes.number,
  scale: PropTypes.number.isRequired,
};

export const Toolbar = withStyles(styles, { name: 'Toolbar' })(ToolbarBase);
