/**
 *
 * AdvancedMobileGrid sagas
 *
 */

import { call, put, select, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';

import {
  changeEntitiesState,
  changeEntitiesTotalCount,
} from 'domain/Data/actions';

import * as api from 'utils/api';

import {
  LOAD_DATA,
} from './constants';

import {
  changeIsListOver,
  changeIsLoading,
  clearSelection,
  dataLoaded,
  dataLoadingError,
} from './actions';

import {
  makeSelectEntitiesIds,
  makeSelectEntityName,
  makeSelectFilters,
  makeSelectGridFilters,
  makeSelectIsListOver,
  makeSelectLimit,
  makeSelectSearchQuery,
  makeSelectSorting,
} from './selectors';
import { makeSelectLocationState } from 'domain/selectors';
import { makeFieldFilter } from 'containers/AdvancedDataTable/sagas';

export default function* rootSaga() {
  yield takeLatest(LOAD_DATA, loadAdvancedMobileGridDataSaga);
}

export function* loadAdvancedMobileGridDataSaga({ meta: { name }, payload }) {
  yield put(clearSelection(name));

  const isListOver = yield select(makeSelectIsListOver(name));
  const entityName = payload.entityName || (yield select(makeSelectEntityName(name)));
  const filters = payload.filters || (yield select(makeSelectFilters(name)));
  const searchQuery = payload.searchQuery || (yield select(makeSelectSearchQuery(name)));
  const sorting = payload.sorting || (yield select(makeSelectSorting(name)));
  const startAt = payload.startAt || 1;
  const limit = payload.limit || (yield select(makeSelectLimit(name)));
  const gridFilters = yield select(makeSelectGridFilters(name));
  const locationState = yield select(makeSelectLocationState());
  const view = queryString.parse(locationState.location.search).view;

  try {
    const startTime = new Date().getTime();
    yield put(changeIsLoading(name, true));

    const loadPageParams = {
      filters,
      includeTotalCount: true,
      limit,
      searchQuery,
      sorting,
      startAt,
      view,
    };

    let filterBy = makeFilterByParameter(gridFilters);
    loadPageParams.filterBy = filterBy;
    const response = yield call(api.loadPage, entityName, loadPageParams);
    const { data, metadata } = response.data;

    yield put(changeEntitiesState(entityName, data));
    yield put(changeEntitiesTotalCount(entityName, metadata.totalCount));

    const ids = data.map((entity) => entity.id);

    if (payload.isLazyLoading) {
      const previousIds = yield select(makeSelectEntitiesIds(name));
      yield put(dataLoaded(name, new Set([...previousIds, ...ids])));
    } else {
      if (isListOver) yield put(changeIsListOver(name, false));
      yield put(dataLoaded(name, ids));
    }

    const endTime = new Date().getTime();
    const result = endTime - startTime;
    if (result < 700) yield* wait(700 - result);
    yield put(changeIsLoading(name, false));
    if (ids.length < limit) yield put(changeIsListOver(name, true));
  } catch (err) {
    yield put(changeIsLoading(name, false));
    yield put(dataLoadingError(name, err));
  }
}


export function makeFilterByParameter(gridFilters) {
  if (!gridFilters) {
    return null;
  }

  const allFilters = [];
  const gridFiltersWithValue = gridFilters.filter((gridFilter) => gridFilter.value);

  for (let gridFilter of gridFiltersWithValue) {
    const field = Array.isArray(gridFilter.path) ? gridFilter.path.join('.') : gridFilter.path;
    const fieldFilter = makeFieldFilter(field, gridFilter.type, gridFilter.operation, gridFilter.value);
    if (fieldFilter) {
      allFilters.push(fieldFilter);
    }
  }
  return allFilters.length > 0 ? allFilters.join(' && ') : null;
}

function* wait(time) {
  yield new Promise((resolve) => setTimeout(resolve, time));
}
