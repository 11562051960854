/*
 *
 * ReceptionFormDialog14326
 *
 */

import React from 'react';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import PropTypes from 'prop-types';

import { requestAction } from 'domain/typeConstants/action';
import { ACCEPTED, ON_TERRITORY, CLOSED, COMPLETED, IN_PROGRESS, CREATED, ON_AGREEMENT } from 'domain/Data/RequestStatus/constants'
import { RECEPTION_REQUESTS_FORM_DIALOG, RECEPTION_REQUESTS_FORM, RECEPTION_REQUESTS_TABLE_GRID } from 'pages/Requests/ReceptionView/constants';
import { role as roleType } from 'domain/typeConstants/roles';
import { CARRYING_OUT_WORKS, SERVICE } from 'domain/Data/RequestType/constants';
import { REQUESTS } from 'domain/Data/constants';

export class ReceptionFormDialog14326 extends React.Component {
  handleAcceptButtonClick = () => this.props.onAccept();
  handleRejectButtonClick = () => this.props.onReject();
  handleSendToAgreementByTenantButtonClick = () => this.props.onSendToAgreementByTenant();
  handleSendToPerformerButtonClick = () => this.props.onSendToPerformer();
  handleCloseButtonClick = () => this.props.onClose();

  renderCustomButtons() {
    const { mode, currentRole, currentRecord, selection, paramsRecord } = this.props;
    if (mode !== 'edit') return null;

    let status = null;
    let type = null;

    if (paramsRecord && paramsRecord.length > 0 ) {
      status = paramsRecord[0].status?.id;
      type = paramsRecord[0].type?.id
    } else if (currentRecord) {
      status = currentRecord.status?.id;
      type = currentRecord.type?.id
    } else if (selection && selection.length > 0) {
      status = selection[0]?.status?.id;
      type = selection[0]?.type?.id
    }

    const isAvailableRole = currentRole.includes(roleType.RECEPTION) || currentRole.includes(roleType.ADMINISTRATOR_BC)
    const isCarryingOutWorks = isAvailableRole && type === CARRYING_OUT_WORKS && ([CREATED, ON_AGREEMENT].includes(status)) ? true : false
    const isService = isAvailableRole && type === SERVICE && ([CREATED, ON_AGREEMENT].includes(status)) ? true : false

    return [
      {
        name: requestAction.SEND_TO_PERFORMER,
        label: 'Отправить Исполнителю',
        onClick: this.handleSendToPerformerButtonClick,
        visible: isService
      },
      {
        name: requestAction.SEND_TO_AGREEMENT_BY_TENANT,
        label: 'Согласовать с Арендатором',
        onClick: this.handleSendToAgreementByTenantButtonClick,
        visible: isCarryingOutWorks
      },
      {
        name: requestAction.ACCEPT,
        label: 'Согласовать',
        onClick: this.handleAcceptButtonClick,
        visible: ![ACCEPTED, ON_TERRITORY, CLOSED, COMPLETED, IN_PROGRESS].includes(status) &&
          type !== SERVICE
      },
      {
        name: requestAction.REJECT,
        label: 'Отклонить',
        onClick: this.handleRejectButtonClick,
        visible: ![ACCEPTED, ON_TERRITORY, CLOSED, COMPLETED, IN_PROGRESS].includes(status)
      },
      {
        name: requestAction.CLOSE,
        label: 'Закрыть',
        onClick: this.handleCloseButtonClick,
        visible: type === SERVICE && status === COMPLETED ? true : false
      },
    ];
  }

  render() {
    const { form, detailContainer } = this.props;
    return (
      <AdvancedFormDialog
        name={RECEPTION_REQUESTS_FORM_DIALOG}
        formName={RECEPTION_REQUESTS_FORM}
        dataTableName={RECEPTION_REQUESTS_TABLE_GRID}
        advancedForm={form}
        addModeTitle="Новая заявка"
        editModeTitle="Изменение заявки"
        viewModeTitle="Просмотр заявки"
        copyModeTitle="Копирование заявки"
        addModeSaveButtonLabel="Сохранить и Отправить"
        copyModeSaveButtonLabel="Сохранить и Отправить"
        customButtons={this.renderCustomButtons()}
        detailContainer={detailContainer}
        entityName={REQUESTS}
      />
    );
  }
}


ReceptionFormDialog14326.propTypes = {
  form: PropTypes.node.isRequired,
  mode: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ReceptionFormDialog14326;
