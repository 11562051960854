/**
 *
 * ReceptionDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { RECEPTION_COMPANIES_DX_GRID } from "./constants";
import { settings } from "containers/AdvancedDataTable/dxGridTypes";
import { columnCompanySize } from 'domain/typeConstants/table';
import {
    addActionButton,
    editActionButton,
    copyActionButton,
} from '../common/actionButton';

export class ReceptionDxGrid extends React.PureComponent {

    getFilters() {
        return [];
    }

    getColumns() {
        return [
            {
                path: 'name',
                name: 'name',
                title: 'Наименование',
                width: columnCompanySize.NAME,
            },
            {
                path: 'fullName',
                name: 'fullName',
                title: 'Полное наименование',
                width: columnCompanySize.FULL_NAME,
            },
            {
                path: ['type', 'name'],
                name: 'type',
                title: 'Вид',
                getCellValue: (row) => row.type ? row.type.name : undefined,
                width: columnCompanySize.TYPE,
            },
            {
                path: ['status', 'name'],
                name: 'status',
                title: 'Статус',
                getCellValue: (row) => row.status ? row.status.name : undefined,
                width: columnCompanySize.STATUS,
            },
            {
                path: 'adress',
                name: 'adress',
                title: 'Адрес',
                width: columnCompanySize.ADDRESS,
            },
            {
                path: 'phone',
                name: 'phone',
                title: 'Телефон',
                width: columnCompanySize.PHONE,
            },
            {
                path: 'note',
                name: 'note',
                title: 'Примечание',
                width: columnCompanySize.NOTE,
            },
        ];
    }

    getSorting() {
        return [{ columnId: 'name', columnName: 'name', direction: 'desc' }];
    }

    getColumnOrder() {
        return ['name', 'fullName', 'type', 'status', 'adress', 'phone', 'note'];
    }

    getActionButtons() {
        return [addActionButton, editActionButton, copyActionButton];
    }

    getActionMenuItems() {
        return [];
    }

    getPageSize() {
        return 10;
    }

    getTableFeatures() {
        return [
            { name: settings.TABLE_ROW_DETAIL, value: false },
            { name: settings.TABLE_DRAWER, value: false },
            { name: settings.FILTERING, value: true },
            { name: settings.FILTER_BUTTON, value: true },
            { name: settings.SETTING_BUTTON, value: true },
            { name: settings.UPDATE_BUTTON, value: true },
        ];
    }

    render() {
        const { onActionExecuting, onRowDoubleClick, viewName } = this.props;
        return (
            <AdvancedDataTable
                name={RECEPTION_COMPANIES_DX_GRID}
                entityName={'companies'}
                actionButtons={this.getActionButtons()}
                actionMenuItems={this.getActionMenuItems()}
                columnOrder={this.getColumnOrder()}
                columns={this.getColumns()}
                filters={this.getFilters()}
                pageSize={this.getPageSize()}
                onActionExecuting={onActionExecuting}
                onRowDoubleClick={onRowDoubleClick}
                sorting={this.getSorting()}
                tableFeatures={this.getTableFeatures()}
                viewName={viewName}
                filePreviewFieldName="scan"
                filtering
                selectable
                tableType={settings.PAGING_TABLE}
                mode="parent"
            />
        );
    }
}

ReceptionDxGrid.propTypes = {
    viewName: PropTypes.string,
    onActionExecuting: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
};

export default ReceptionDxGrid;
