/*
 *
 * dxgrid utils 14720
 *
 */

import { requestAction } from "../../../../../domain/typeConstants/action";
import { SERVICE } from "../../../../../domain/Data/RequestType/constants";

/* ---------- disableDeparted Button ---------- */
export function disableDepartedButton(actionButtons) {
    const departedIndex = actionButtons.findIndex((button) => button.name === requestAction.DEPARTED);
    actionButtons[departedIndex].disabled = (selection, entities) => {
        if (selection.length === 1) {
            if (!entities[0].type) return false;
            return (
                entities[0].type.id === SERVICE
            );
        }
        return true;
    };
}

/* ---------- disabledArrived Button ---------- */
export function disabledArrivedButton(actionButtons) {
    const arrivedIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    actionButtons[arrivedIndex].disabled = (selection, entities) => {
        if (selection.length === 1) {
            if (!entities[0].type) return false;
            return (
                entities[0].type.id === SERVICE
            );
        }
        return true;
    };
}

/* ---------- disabledAccept Button---------- */
export function disabledAcceptButton(actionButtons) {
    const acceptIndex = actionButtons.findIndex((button) => button.name === requestAction.ACCEPT);
    actionButtons[acceptIndex].disabled = (selection, entities) => {
        if (selection.length === 1) {
            if (!entities[0].type) return false;
            return (
                entities[0].type.id === SERVICE
            );
        }
        return true;
    };
}

/* ---------- disableDeparted Item---------- */
export function disableDepartedItem(actionMenuItems) {
    const departedIndex = actionMenuItems.findIndex((item) => item.name === requestAction.DEPARTED);
    actionMenuItems[departedIndex].hidden = (itemMenuRecord, entities) => {
        if (itemMenuRecord.length === 1) {
            if (!entities[0].type) return false;
            return (
                entities[0].type.id === SERVICE
            );
        }
        return true;
    };
}

/* ---------- disabledArrived Item---------- */
export function disabledArrivedItem(actionMenuItems) {
    const departedIndex = actionMenuItems.findIndex((item) => item.name === requestAction.ARRIVED);
    actionMenuItems[departedIndex].hidden = (itemMenuRecord, entities) => {
        if (itemMenuRecord.length === 1) {
            if (!entities[0].type) return false;
            return (
                entities[0].type.id === SERVICE
            );
        }
        return true;
    };
}

/* ---------- disabledAccept Item---------- */
export function disabledAcceptItem(actionMenuItems) {
    const departedIndex = actionMenuItems.findIndex((item) => item.name === requestAction.ACCEPT);
    actionMenuItems[departedIndex].hidden = (itemMenuRecord, entities) => {
        if (itemMenuRecord.length === 1) {
            if (!entities[0].type) return false;
            return (
                entities[0].type.id === SERVICE
            );
        }
        return true;
    };
}
