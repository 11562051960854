/**
 *
 * table types
 *
 * 
 */

export const columnType = Object.freeze({
  CHIP: 'chip',
  DATE: 'date',
  DATETIME: 'datetime',
  DATEFULLTIME: 'datefulltime',
  FILE: 'file',
  LABEL: 'label',
  NUMERIC: 'numeric',
  PHONE_NUMBER: 'phoneNumber',
  PRINT_DOCUMENT: 'printDocument',
  TIME: 'time',
  BOOLEAN: 'boolean',
  TEXT: 'text',
  FORMAT_TEXT: 'format_text',
});

export const columnSize = Object.freeze({
  DATE: 100,
  DATETIME: 160, // 110
  FILE: 85,
  NUMERIC: 65,
  TIME: 90,
});


export const columnTaskSize = Object.freeze({
  DATE: 130,
  DATETIME: 160, // 110
  FILE: 85,
  NUMERIC: 65,
  TIME: 90,
  AUTHOR_NAME: 150,
  RESPONSIBLE_NAME: 150,
  COMPANY: 150,
  DESCRIPTION: 500,
  STATUS_NAME: 100,
  NAME: 500,
});


export const columnWorkSize = Object.freeze({
  DATE: 130,
  DATETIME: 116,
  FILE: 85,
  NUMERIC: 65,
  TIME: 90,
  AUTHOR_NAME: 151,
  RESPONSIBLE_NAME: 150,
  COMPANY: 150,
  DESCRIPTION: 743,
  CHANGES_DESCRIPTION: 866,
  STATUS_NAME: 100,
  DURATION: 120,
});


export const requestColumnSizes = Object.freeze({
  accessCard: 110,
  accessCardNumber: 95,
  assigneeName: 120,
  authorName: 105,
  availableTenantCheckpoints: 142,
  blackListNote: 110,
  carModelText: 77,
  carNumber: 82,
  company_name: 107,
  companyName: 151,
  contactPersonFullName: 140,
  documentDetails: 100,
  executionTerm: 133,
  fileName: 75,
  housing: 82,
  implementationReport: 150,
  materialValuesData: 125,
  note: 150,
  office: 95,
  parkingName: 95,
  parkingPlace: 77,
  passNumber: 119,
  phone: 150,
  serviceName: 86,
  statusName: 90,
  tenantCompanyName: 110,
  typeShortName: 100,
  khimkiRequestType: 110,
  visitorFullName: 150,
  whatHappened: 130,
  whereHappened: 86,
  whoMeets: 90,
  workType: 150,
  workPlace: 100,
  multipleEntry: 90,
  agreedEmployee: 100,
  managementCompanyComment: 150,
  actualCreationDate: 150,
  isTrainingPass: 90,
  trainingResult: 120,
  issueReason: 100,
});

export const documentColumnSizes = Object.freeze({
  agreed: 292,
  amount: 82,
  author: 130,
  categoryName: 65,
  comment: 220,
  companyName: 137,
  content: 145,
  documentNumber: 65,
  sightingEmployeesText: 191,
  stateName: 150,
});

export const userColumnSizes = Object.freeze({
  chip: 650,
  name: 150,
  employeeName: 165,
  employeeCompany: 185,
  employeeDepartment: 155,
  isBlocked: 125,
  rolesText: 435,
  forcePasswordChange: 190,
});

export const rulesColumnSizes = Object.freeze({
  actualCreationDate: 150,
  validUntil: 150,
  content: 450,
  newsTopic: 150,
});

export const columnCompanySize = Object.freeze({
  ADDRESS: 250,
  FULL_NAME: 250,
  NAME: 200,
  NOTE: 250,
  PHONE: 150,
  STATUS: 100,
  TYPE: 150,
});

export const checkpointColumnSize = Object.freeze({
  NAME: 150,
  CAPACITY: 150,
  BUSY_PLACES_COUNT: 150,
  ACEPTED_REQUESTS_COUNT: 150,
  FREE_GUEST_PLACES_COUNT: 150,
});