/*
 *
 * RequestsPage14254
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import { TenantForm14254 } from './TenantView';

function RequestsPage14254(props) {
  return (
    <RequestsPage
      tenantForm={<TenantForm14254 />}
      {...props}
    />
  );
}

export default RequestsPage14254;
