/**
 *
 * ReceptionView sagas
 *
 */

import { put, takeLatest } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';

import {
  openAddDialog,
  openEditDialog,
  openViewDialog,
} from 'containers/AdvancedFormDialog/actions';
import {
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_VIEW_ACTION,
  RECEPTION_RULE_FORM_DIALOG,
  RECEPTION_RULE_DATA_TABLE,
} from './constants';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  action.meta.dialog === RECEPTION_RULE_FORM_DIALOG;

export default function createReceptionViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(RECEPTION_RULE_DATA_TABLE));
}

function* executeAddActionSaga() {
  yield put(openAddDialog(RECEPTION_RULE_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(RECEPTION_RULE_FORM_DIALOG, { id }));
  }
}

function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(RECEPTION_RULE_FORM_DIALOG, { id }));
  }
}
