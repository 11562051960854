/**
 *
 * SupplyPositionDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnWorkSize } from 'domain/typeConstants/table';
import { DOCUMENT_POSITIONS } from 'domain/Data/constants';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import {
  cargoReceivedActionButton,
  originalsDeliveredActionButton,
} from '../common/actionButton';
import { columnStatusLabels } from '../common/columnStatusLabel';
import { SUPPLY_POSITION_DX_GRID } from './constants';

class SupplyPositionDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  /* -----------------------------------------------------------
                  Supply Position configuration
  ----------------------------------------------------------- */
  getFilters() {
    return [];
  }

  getColumns() {
    return [
      {
        name: 'state',
        path: ['state', 'name'],
        getCellValue: (row) => (row.state ? row.state.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'amount',
        path: 'amount',
        title: 'Сумма',
        type: columnType.NUMERIC,
        width: columnWorkSize.NUMERIC,
      },
      {
        name: 'supplierInvoiceName',
        path: ['supplierInvoice', 'name'],
        title: 'Счет поставщика',
        getCellValue: (row) => (row.supplierInvoice ? row.supplierInvoice.name : undefined),
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'supplierInvoiceDeliverySchedule',
        path: ['supplierInvoice', 'deliverySchedule'],
        title: 'Поставка план',
        type: columnType.DATE,
        getCellValue: (row) => (row.supplierInvoice ? row.supplierInvoice.deliverySchedule : undefined),
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'supplierInvoiceActualCreationDate',
        path: ['supplierInvoice', 'actualCreationDate'],
        title: 'Дата счета',
        type: columnType.DATE,
        getCellValue: (row) => (row.supplierInvoice ? row.supplierInvoice.actualCreationDate : undefined),
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'supplierInvoiceState',
        path: ['supplierInvoice', 'state', 'name'],
        getCellValue: (row) => (row.supplierInvoice && row.supplierInvoice.state ? row.supplierInvoice.state.name : undefined),
        title: 'Статус счета',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'price',
        path: 'price',
        title: 'Цена',
        type: columnType.NUMERIC,
        width: columnWorkSize.NUMERIC,
      },
      {
        name: 'changedDate',
        path: 'changedDate',
        title: 'Изменена',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'desiredDate',
        path: 'desiredDate',
        title: 'Желаемая дата',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'asset',
        path: ['asset', 'name'],
        title: 'ТМЦ',
        getCellValue: (row) => (row.asset ? row.asset.name : undefined),
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'assetText',
        path: 'assetText',
        title: 'ТМЦ текстом',
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'positionsCount',
        path: 'positionsCount',
        title: 'Количество',
        type: columnType.NUMERIC,
        width: columnWorkSize.NUMERIC,
      },
      {
        name: 'received',
        path: 'received',
        title: 'Получено',
        type: columnType.NUMERIC,
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'description',
        path: 'description',
        title: 'Примечание',
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'creationDate',
        path: 'creationDate',
        title: 'Добавлен',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'documentNumber',
        path: ['document', 'number'],
        getCellValue: (row) => (row.document ? row.document.number : undefined),
        title: '№ заявки',
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'documentActualCreationDate',
        path: ['document', 'actualCreationDate'],
        title: 'Дата заявки',
        getCellValue: (row) => (row.document ? row.document.actualCreationDate : undefined),
        type: columnType.DATE,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'documentObjectStructureName',
        path: ['document', 'objectStructure', 'name'],
        title: 'Объект',
        getCellValue: (row) => (row.document && row.document.objectStructure ? row.document.objectStructure.name : undefined),
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'documentState',
        path: ['document', 'state', 'name'],
        getCellValue: (row) => (row.document && row.document.state ? row.document.state.name : undefined),
        title: 'Статус заявки',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'docsDelivered',
        path: ['document', 'deliveryOriginals'],
        title: 'Ориг доставлен',
        getCellValue: (row) => (row.document && row.document.deliveryOriginals ? 'Да' : 'Нет'),
        label: { backgroundColor: '#69F0AE', fontColor: 'black', condition: (value) => value === 'Да' },
        type: columnType.FORMAT_TEXT,
        width: columnWorkSize.STATUS_NAME,
      },
    ];
  }

  getColumnOrder() {
    return [
      'changedDate', 'state', 'desiredDate', 'supplierInvoiceDeliverySchedule', 'asset', 'assetText',
      'positionsCount', 'price', 'amount', 'received', 'description', 'supplierInvoiceName',
      'supplierInvoiceActualCreationDate', 'supplierInvoiceState', 'docsDelivered', 'creationDate',
      'documentNumber', 'documentActualCreationDate', 'documentObjectStructureName', 'documentState',
    ];
  }

  getActionButtons() {
    return [
      cargoReceivedActionButton, originalsDeliveredActionButton
    ]
  }

  getSorting() {
    return [{ columnId: 'changedDate', columnName: 'changedDate', direction: 'desc' }];
  }

  getActionMenuItems() {
    return [];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [{ name: settings.TABLE_ROW_DETAIL, value: false },
    { name: settings.TABLE_DRAWER, value: false },
    { name: settings.TABLE_ACTION_MENU, value: false },
    { name: settings.FILTERING, value: true },
    { name: settings.FILTER_BUTTON, value: true },
    { name: settings.SETTING_BUTTON, value: true },
    { name: settings.UPDATE_BUTTON, value: true },
    ]
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;
    return (

      <AdvancedDataTable
        name={SUPPLY_POSITION_DX_GRID}
        entityName={DOCUMENT_POSITIONS}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

SupplyPositionDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
};

export default SupplyPositionDxGrid;
