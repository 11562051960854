/*
 *
 * DadataField constants
 *
 */

export const CLEAR_SUGGESTIONS = 'claris-vnext/AdvancedForm/DadataField/CLEAR_SUGGESTIONS';

export const LOAD_SUGGESTIONS = 'claris-vnext/AdvancedForm/DadataField/LOAD_SUGGESTIONS';
export const LOAD_SUGGESTIONS_SUCCESS = 'claris-vnext/AdvancedForm/DadataField/LOAD_SUGGESTIONS_SUCCESS';
export const LOAD_SUGGESTIONS_ERROR = 'claris-vnext/AdvancedForm/DadataField/LOAD_SUGGESTIONS_ERROR';
