/**
 *
 * DetailTable actions
 *
 */

import {LOAD_OPTIONS} from './constants';

export function loadOptions(advancedForm, field) {
    return {
        type: LOAD_OPTIONS,
        meta: {advancedForm, field},
    };
}
