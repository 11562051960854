/**
 *
 * getViewConfig Function
 *
 */

import { getAdminView } from './getRoleView';
import getSagasConfig from './getSagasConfig';
import createDashboardViewSaga from './DashboardView/sagas';

const defaultViewConfig = {
  createComponent: getAdminView,
  createSaga: (sagaConfig) => createDashboardViewSaga(sagaConfig.dashboardViewSaga),
};

export function createViewComponent(userRoles, props) {

  return defaultViewConfig.createComponent(props);
}

export function createViewSaga(userRoles, accountId) {

  return defaultViewConfig.createSaga(getSagasConfig(accountId));
}
