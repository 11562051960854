/**
 *
 * SupplyRequestDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction, documentAction } from 'domain/typeConstants/action';
import * as actionCreators from './actions';

class SupplyRequestDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => { this.props.executeEditAction(record.id) };

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case documentAction.AGREE:
        this.props.executeAgreeAction(ids);
        break;

      case documentAction.DISAGREE:
        this.props.executeDisagreeAction(ids);
        break;

      case requestAction.VIEW:
        this.props.executeViewAction(ids[0]);
        break;

      case requestAction.ADD:
        this.props.executeAddAction();
        break;

      case requestAction.EDIT:
        this.props.executeEditAction(ids[0]);
        break;

        case 'addNew':
          this.props.executeAddNewAction();
          break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName } = this.props;
    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
    });
  }
}

SupplyRequestDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  executeAgreeAction: PropTypes.func.isRequired,
  executePrintAction: PropTypes.func,
  executeDisagreeAction: PropTypes.func.isRequired,
  executeViewAction: PropTypes.func.isRequired,
  executeEditAction: PropTypes.func.isRequired,
  executeAddAction: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(SupplyRequestDxGridContainer);
