/*
 *
 * LocalStorage functions
 *
 */

import {
  getTypeInfo,
  isString,
} from './typeUtils';

export const getValueFromLocalStorage = (key) => {
  if (!isString(key)) throw new Error(`key type for localStorage must be "String", but got "${getTypeInfo(key)}"`);
  return JSON.parse(localStorage.getItem(key));
};

export const setValueToLocalStorage = (key, value) => {
  if (!isString(key)) throw new Error(`key type for localStorage must be "String", but got "${getTypeInfo(key)}"`);
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeValueFromLocalStorage = (key) => {
  if (!isString(key)) throw new Error(`key type for localStorage must be "String", but got "${getTypeInfo(key)}"`);
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
