/**
 *
 * TaskView
 *
 * 
 */

import React from 'react';
import TaskView from './TaskView';

class TaskViewContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  render() {
    return (
      <TaskView {...this.props} />
    );
  }
}

export default TaskViewContainer;