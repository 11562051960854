/*
 *
 * Document sagas
 *
 */

import { call, put } from 'redux-saga/effects';
import * as api from 'utils/api';
import {
  documentSendCargo, documentCargoReceivedSuccess, documentCargoReceiveError,
  documentDeliveryOriginals, documentDeliveredOriginalsSuccess, documentDeliveryOriginalsError
} from './actions';

export function* documentSendCargoSaga(id) {
  yield put(documentSendCargo(id));
  try {
    yield call(api.completeDocument, id);
    yield put(documentCargoReceivedSuccess());
  } catch (err) {
    yield put(documentCargoReceiveError(err));
  }
}

export function* documentOriginalsDeliveredSaga(id, changedData) {
  yield put(documentDeliveryOriginals(id));
  try {
    yield call(api.update, 'documents', id, changedData);
    yield put(documentDeliveredOriginalsSuccess());
  } catch (err) {
    yield put(documentDeliveryOriginalsError(err));
  }
}

