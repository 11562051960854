/**
 *
 * ReceptionForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import * as entity from 'domain/Data/constants';
import { formField } from 'domain/typeConstants/form';
import { RECEPTION_RULE_FORM } from './constants';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';

export class ReceptionForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();
    const { viewName } = this.props;
    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'isNews',
        visible: false,
        initialValue: viewName === 'allNews',
        type: formField.CHECKBOX,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        disabled: true,
      },
      {
        name: 'actualCreationDate',
        type: formField.DATE,
        label: 'Дата начала действия',
        required: true,
        visible: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
      },
      {
        name: 'addedDate',
        visible: false,
        type: formField.DATE,
        initialValue: currentDate,
      },
      {
        name: 'validUntil',
        type: formField.DATE,
        label: 'Действует до',
        visible: true,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.validUntil) > currentDate ?
              new Date(values.validUntil) : currentDate;
          }
          return null;
        },
        min: (values) => new Date(values.actualCreationDate) > currentDate ?
          new Date(values.actualCreationDate) : currentDate,
        calculatedValue: (values) => {
          if (values.actualCreationDate && values.validUntil) {
            const actualCreationDate = new Date(values.actualCreationDate);
            const validUntil = new Date(values.validUntil);
            if (actualCreationDate > validUntil) return null;
          }
          return values.validUntil;
        },
      },
      {
        name: 'company',
        type: formField.AUTOCOMPLETE,
        label: 'Арендатор',
        entityName: entity.COMPANIES,
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        orderBy: 'name',
        numberInQueueFromEnd: 1,
        helperText: 'Укажите арендатора, если новость только для него. Иначе оставьте поле пустым',
        grid: { sm: 6 },
      },
      {
        name: 'file',
        label: 'Файл',
        type: formField.FILE,
        visible: true,
        helperText: () => 'Прикрепите файл',
        grid: { sm: 6 },
        fileSize: {
          size: 10e6,
          errorTextFile: 'Размер прикрепляемого файла не должен превышать 10 Мб'
        },
      },
      {
        name: 'content',
        label: 'Содержание',
        grid: { sm: 12 },
        type: formField.EDITOR,
        multiline: true,
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={RECEPTION_RULE_FORM}
        entityName={entity.DOCUMENTS}
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}
