import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedDate } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import NotificationAvatar from './NotificationAvatar';

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  heading: {},
  content: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.46429em'
  },
  сreationDate: {},
});

const deleteParagraph = value => value.replace(/<p>/, '').replace(/<\/p>/, '');

function Notification(props) {
  const { classes, className, content, сreationDate, heading, typeId } = props;

  return (
    <Paper className={classnames({ [classes.root]: true, [className]: !!className })}>
      <Grid container wrap="nowrap" spacing={16}>
        <Grid item>
          <NotificationAvatar typeId={typeId} />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography className={classes.heading} variant="body2" component="h3">
            {heading}
          </Typography>
          <Typography className={classes.сreationDate} variant="caption" component="p">
            <FormattedDate value={сreationDate} year="numeric" month="short" day="numeric" hour="numeric" minute="numeric" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" spacing={16}>
        <Grid item>
          <div className={classes.content} dangerouslySetInnerHTML={{ __html: deleteParagraph(content) }} />
        </Grid>
      </Grid>
    </Paper>
  );
}

Notification.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  content: PropTypes.string,
  сreationDate: PropTypes.string,
  heading: PropTypes.string,
  typeId: PropTypes.string,
};

export default withStyles(styles)(Notification);
