/**
 * The user state selectors
 */

import { createSelector } from 'reselect';

import makeSelectGlobal from 'domain/selectors';

import { role } from 'domain/typeConstants/roles';

const makeSelectDomain = () => createSelector(
  makeSelectGlobal(),
  (globalState) => globalState.get('user')
);

const makeSelectUser = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.toJS()
);

const makeSelectUserId = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.id
);

const makeSelectUsername = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.name
);

const makeSelectCompanyId = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.companyId
);

const makeSelectDepartmentId = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.departmentId
);

const makeSelectAccountName = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.accountName
);

const makeSelectAccountId = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.accountId
);

const makeSelectIsAuthenticated = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.isAuthenticated
);

const makeSelectForcePasswordChange = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.forcePasswordChange
);

const makeSelectRoles = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.roles.valueSeq().toJS()
);

const makeSelectIsAdmin = () => createSelector(
  makeSelectRoles(),
  (roles) => roles.includes(role.SYSTEM_ADMIN)
);

export default makeSelectUser;
export {
  makeSelectAccountId,
  makeSelectAccountName,
  makeSelectCompanyId,
  makeSelectDepartmentId,
  makeSelectForcePasswordChange,
  makeSelectIsAdmin,
  makeSelectIsAuthenticated,
  makeSelectRoles,
  makeSelectUserId,
  makeSelectUsername,
};
