/**
 *
 * TenantDxGrid11994
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';

import { makeSelectRoles } from 'domain/User/selectors';
import {
  addActionButton,
  copyActionButton,
  closeActionButton,
} from 'pages/Requests/common/actionButton';

const editAction = {
  name: 'edit',
  label: 'Изменить',
  icon: 'create',
  disabled: (selection, entities) => !(selection.length !== 0 && entities[0].status.name === 'Создана'),
};

class TenantDxGrid11994 extends TenantDxGrid { // eslint-disable-line react/prefer-stateless-function
  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО посетителя',
        width: requestColumnSizes.visitorFullName,
        styles,
      },
      {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
        styles,
      },
      {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
        styles,
      },
      {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        width: requestColumnSizes.statusName,
        labels: [
          { backgroundColor: '#FFF9C4', fontColor: 'black', condition: (value) => value === 'Создана' },
          { backgroundColor: '#E0E0E0', fontColor: 'black', condition: (value) => value === 'Закрыта' },
          { backgroundColor: '#9CEEC0', fontColor: 'black', condition: (value) => value === 'Выполнена' },
          { backgroundColor: '#FBBBC2', fontColor: 'black', condition: (value) => value === 'Отклонена' },
          { backgroundColor: '#E6EE9C', fontColor: 'black', condition: (value) => value === 'Принята' },
          { backgroundColor: '#CCB5AC', fontColor: 'black', condition: (value) => value === 'Возвращена' },
          { backgroundColor: '#FFE0B2', fontColor: 'black', condition: (value) => value === 'На территории' },
          { backgroundColor: '#B3E5FC', fontColor: 'black', condition: (value) => value === 'Выдан пропуск' },
          { backgroundColor: '#E1BEE7', fontColor: 'black', condition: (value) => value === 'Повторная' },
        ],
      },
      {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
        styles,
      },
      {
        path: ['accessCard', 'number'],
        name: 'accessCardNumber',
        getCellValue: (row) => (row.accessCard ? row.accessCard.number : undefined),
        title: '№ карты',
        width: requestColumnSizes.accessCardNumber,
        styles,
      },
      {
        path: ['parking', 'name'],
        name: 'parkingName',
        getCellValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
        width: requestColumnSizes.parkingName,
        styles,
      },
      {
        path: 'parkingPlace',
        name: 'parkingPlace',
        title: 'Место',
        width: requestColumnSizes.parkingPlace,
        styles,
      },
      {
        path: 'materialValuesData',
        name: 'materialValuesData',
        title: 'Мат. ценности',
        width: requestColumnSizes.materialValuesData,
        styles,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
        styles,
      },
      {
        path: ['author', 'name'],
        name: 'authorName',
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: requestColumnSizes.authorName,
        styles,
      },
    ];
  }

  getGridFilters() {
    return [
      { columnName: 'number', value: '' },
      { columnName: 'visitorFullName', value: '' },
      { columnName: 'carNumber', value: '' },
      { columnName: 'carModelText', value: '' },
      { columnName: 'visitDate', value: '' },
      { columnName: 'expirationDate', value: '' },
      { columnName: 'statusName', value: '' },
      { columnName: 'typeShortName', value: '' },
      { columnName: 'accessCardNumber', value: '' },
      { columnName: 'parkingName', value: '' },
      { columnName: 'parkingPlace', value: '' },
      { columnName: 'materialValuesData', value: '' },
      { columnName: 'note', value: '' },
      { columnName: 'authorName', value: '' },
    ];
  }

  getColumnOrder() {
    return ['number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName', 'accessCardNumber',
      'parkingName', 'parkingPlace', 'materialValuesData', 'note', 'authorName',
    ];
  }

  checkThatIsNotOnlyTenantRole() {
    const { userRoles } = this.props;
    return userRoles.some((item) => { // eslint-disable-line
      if (item === 'lefortTenant') return true;
      if (item === 'lefortReception') return true;
    });
  }

  getActionButtons() {
    if (this.checkThatIsNotOnlyTenantRole()) return [addActionButton, copyActionButton, closeActionButton];
    return [addActionButton, editAction, copyActionButton, closeActionButton];
  }
}

const mapStateToProps = createStructuredSelector({
  userRoles: makeSelectRoles(),
});

export default connect(mapStateToProps)(TenantDxGrid11994);
