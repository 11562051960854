/*
 *
 * ApplicantView actions
 *
 */

import {
  EXECUTE_ADD_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_CLOSE_ACTION,
} from './constants';

export function executeAddAction() {
  return {
    type: EXECUTE_ADD_ACTION,
  };
}

export function executeViewAction(id) {
  return {
    type: EXECUTE_VIEW_ACTION,
    payload: { id },
  };
}

export function executeCopyAction(id) {
  return {
    type: EXECUTE_COPY_ACTION,
    payload: { id },
  };
}

export function executeCloseAction(ids) {
  return {
    type: EXECUTE_CLOSE_ACTION,
    payload: { ids },
  };
}
