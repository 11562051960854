/**
 * AdvancedDataTable state selectors
 */

import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { mapEntityNameToSchema } from 'domain/Data/utils';
import * as dataSelectors from 'domain/Data/selectors';
import { sortTableData } from './utils';

const makeSelectDomain = () => (state) => state.get('advancedDataTables');

const makeSelectAdvancedDataTable = (dataTableName) => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get(dataTableName),
);

const makeSelectAdvancedDataTablesNames = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.keySeq().toJS(),
);

const makeSelectEntityName = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState && dataTableState.get('entityName'));

const makeSelectEntitiesIds = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState && dataTableState.get('ids') ? dataTableState.get('ids').toJS() : [],
);

const makeSelectDataById = (dataTableName, id) => createSelector(
  makeSelectEntityName(dataTableName),
  dataSelectors.makeSelectEntities(),
  (entityName, entities) => selectData(entityName, [id], entities)[0],
);

const makeSelectData = (dataTableName) => createSelector(
  makeSelectEntityName(dataTableName),
  makeSelectEntitiesIds(dataTableName),
  dataSelectors.makeSelectEntities(),
  (entityName, ids, entities) => selectData(entityName, ids, entities),
);

function selectData(entityName, ids, entities) {
  const denormalizedData = denormalize(
    {
      [entityName]: ids,
    },
    {
      [entityName]: [mapEntityNameToSchema(entityName)],
    },
    entities,
  );

  return Object.values(denormalizedData[entityName]);
}

const makeSelectColumns = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('columns').valueSeq().toJS(),
);

const makeSelectColumnOrder = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('columnOrder').valueSeq().toJS(),
);

const makeSelectColumnWidths = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('columnWidths').valueSeq().toJS(),
);

const makeSelectColumnVisibilityNames = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('columnVisibilityNames').valueSeq().toJS(),
);

const makeSelectExpandedRowIds = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('expandedRowIds').valueSeq().toJS(),
);

const makeSelectLoaderState = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('loaderState'),
);

const makeSelectPageSize = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('pageSize'),
);

const makeSelectPageSizes = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('pageSizes'),
);

const makeSelectPageNumber = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('pageNumber'),
);

const makeSelectTabPosition = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('tab'),
);

const makeSelectSelection = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState ?
    dataTableState.get('selection').toJS() : null,
);

const makeSelectMenuItem = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState ?
    dataTableState.get('itemMenuRecord').toJS() : null,
);

const makeSelectSorting = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('sorting').toJS(),
);

const makeSelectFilters = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('filters').toJS(),
);

const makeSelectClearFilterDialog = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('clearFilterDialog'),
);

const makeSelectGridFilters = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('gridFilters'),
);

const makeSelectSearchQuery = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('searchQuery'),
);

const makeSelectCurrentRecordId = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState ? dataTableState.getIn(['currentRecord', 'id']) : null,
);

const makeSelectCurrentRecord = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState && dataTableState.get('currentRecord'),
);

const makeSelectFileExtension = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('extension'),
);

const makeSelectDownloadLink = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('downloadLink'),
);

const makeSelectIsOpenDrawer = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('isOpenDrawer'),
);

const makeSelectDrawerRecord = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  (dataTableState) => dataTableState.get('drawerRecord'),
);

const makeSelectDetailContainerNames = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  makeSelectAdvancedDataTablesNames(),
  (dataTableState, dataTableNames) => dataTableState.get('detailContainerNames')
    .filter(item => dataTableNames.includes(item)),
);

const makeSelectActionButtons = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  makeSelectEntityName(dataTableName),
  makeSelectSelection(dataTableName),
  dataSelectors.makeSelectEntities(),
  makeSelectCurrentRecord(dataTableName),
  (dataTableState, entityName, selection, entities, currentRecord) => dataTableState.get('actionButtons')
    .map((action) => calculateActionState(action, entityName, selection, entities, currentRecord)).toJS(),
);

const makeSelectActionMenuItems = (dataTableName) => createSelector(
  makeSelectAdvancedDataTable(dataTableName),
  makeSelectEntityName(dataTableName),
  makeSelectMenuItem(dataTableName),
  dataSelectors.makeSelectEntities(),
  makeSelectCurrentRecord(dataTableName),
  (dataTableState, entityName, menuItem, entities, currentRecord) => dataTableState.get('actionMenuItems')
    .map((action) => calculateActionStateForMenuItems(action, entityName, menuItem, entities, currentRecord)).toJS(),
);


function calculateActionState(action, entityName, selection, entities, currentRecord) {
  const { disabled, ...restProps } = action;
  const selectedEntities = selection && selection.length > 0 ?
    selectData(entityName, selection, entities) : [];

  const isDisabled = disabled === true ||
    (typeof disabled === 'function' && disabled(selection, selectedEntities, currentRecord));
  return {
    ...restProps,
    disabled: isDisabled,
  };
}

function calculateActionStateForMenuItems(action, entityName, itemMenuRecord, entities, currentRecord) {
  const { hidden, ...restProps } = action;
  const selectedEntities = itemMenuRecord && itemMenuRecord.length > 0 ?
    selectData(entityName, itemMenuRecord, entities) : [];

  const isHidden = hidden === true ||
    (typeof hidden === 'function' && hidden(itemMenuRecord, selectedEntities, currentRecord));

  return {
    ...restProps,
    hidden: isHidden,
  };
}

const makeSelectTotalCount = (dataTableName) => createSelector(
  makeSelectEntityName(dataTableName),
  dataSelectors.makeSelectData(),
  (entityName, dataState) => dataState.getIn(['meta', entityName, 'totalCount'])
);

const makeSelectTotalCountBreadcrumb = () => createSelector(
  dataSelectors.makeSelectData(),
  (dataState) => dataState.getIn(['meta', 'breadcrumb', 'totalCount'])
);

const makeSelectExpectedPageIds = (dataTableName, entityName, addedEntities) => createSelector(
  dataSelectors.makeSelectEntities(),
  makeSelectColumns(dataTableName),
  makeSelectSorting(dataTableName),
  makeSelectPageSize(dataTableName),
  makeSelectPageNumber(dataTableName),
  makeSelectEntitiesIds(dataTableName),
  (entities, columns, sorting, pageSize, pageNumber, currentIds) => {
    if (pageNumber === 1 && sorting.length > 0) {
      const addedIds = addedEntities.map((entity) => entity.id);
      const allIds = [...new Set([...addedIds, ...currentIds])];
      const currentPageEntities = selectData(entityName, allIds, entities);
      const sortedIds = sortTableData(currentPageEntities, columns, sorting)
        .map((entity) => entity.id);
      const result = currentIds;
      sortedIds.forEach((id, index) => {
        if (!currentIds.includes(id)) result.splice(index, 0, id);
      });

      return result.slice(0, pageSize);
    }

    return currentIds;
  }
);

const makeSelectRowSelection = (dataTableName) => createSelector(
  makeSelectCurrentRecord(dataTableName),
  makeSelectSelection(dataTableName),
  (currentRecord, selection) => {
    if (selection || currentRecord) {
      return currentRecord ? currentRecord : selection[0] || null
    }
    else return null
  }
);

const makeSelectAgreementStep = (dataTableName) => createSelector(
  makeSelectSelection(dataTableName),
  dataSelectors.makeSelectEntities(),
  (record, entities) => {
    return record && record.length > 0 && record[0].agreementStep ? entities.getIn(['agreementSteps', record[0]?.agreementStep])?.sightingEmployeesText : ''
  }
);

export default makeSelectDomain;
export {
  makeSelectAdvancedDataTablesNames,
  makeSelectEntityName,
  makeSelectEntitiesIds,
  makeSelectData,
  makeSelectColumns,
  makeSelectColumnOrder,
  makeSelectColumnWidths,
  makeSelectColumnVisibilityNames,
  makeSelectExpandedRowIds,
  makeSelectLoaderState,
  makeSelectPageSize,
  makeSelectPageSizes,
  makeSelectPageNumber,
  makeSelectSelection,
  makeSelectMenuItem,
  makeSelectSorting,
  makeSelectFilters,
  makeSelectClearFilterDialog,
  makeSelectGridFilters,
  makeSelectSearchQuery,
  makeSelectCurrentRecordId,
  makeSelectActionButtons,
  makeSelectActionMenuItems,
  makeSelectTotalCount,
  makeSelectTotalCountBreadcrumb,
  makeSelectExpectedPageIds,
  makeSelectTabPosition,
  makeSelectRowSelection,
  makeSelectFileExtension,
  makeSelectDownloadLink,
  makeSelectDetailContainerNames,
  makeSelectCurrentRecord,
  makeSelectAgreementStep,
  makeSelectIsOpenDrawer,
  makeSelectDrawerRecord,
  makeSelectDataById,
};
