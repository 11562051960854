/**
 *
 * Rules ActionMenu Items
 *
 */

import React from 'react';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import { documentAction } from 'domain/typeConstants/action';

export const viewActionItem = {
  name: documentAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
};

export const editActionItem = {
  name: documentAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
    } else if (itemMenuRecord.length === 1) {
      const status = entities[0].status && entities[0].status.name;
      if (!status) return false;
    }
    return true;
  },
};
