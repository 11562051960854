/**
 *
 * UsersDxGrid14326
 *
 */

import { userColumnSizes } from "domain/typeConstants/table";
import UsersDxGrid from "pages/Settings/UsersView/UsersDxGrid";

export class UsersDxGrid14326 extends UsersDxGrid {

  getColumns() {
    const columns = super.getColumns();

    columns.push({
      path: ['employee', 'tenantPermanentAccessCardKey'],
      name: 'tenantPermanentAccessCardKey',
      title: 'Ключ арендатора',
      getCellValue: (row) => {
        if (row.employee) {
          return row.employee.tenantPermanentAccessCardKey;
        }
        return undefined;
      },
      width: userColumnSizes.employeeDepartment,
    });
    return columns;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();

    const isBlockedIndex = columnOrder.findIndex((item) => item === 'isBlocked');
    columnOrder.splice(isBlockedIndex, 0, 'tenantPermanentAccessCardKey');

    return columnOrder;
  }
}


