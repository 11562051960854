/*
 * AssignedView sagas
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  inProgressRequestsSaga,
  rejectRequestsSaga,
  closeRequestsSaga,
} from 'domain/Data/Request/sagas';
import {
  openAddDialog,
  openEditDialog,
  openViewDialog,
  openCopyDialog,
} from 'containers/AdvancedFormDialog/actions';
import { CLOSED } from 'domain/Data/RequestStatus/constants';
import { makeSelectEntity } from 'domain/Data/selectors';
import {
  ASSIGNED_REQUESTS_TABLE_GRID,
  ASSIGNED_REQUESTS_FORM_DIALOG,
  ASSIGNED_COMPLETE_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_REJECT_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_COMPLETE_ACTION,
} from './constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  (action.meta.dialog === ASSIGNED_REQUESTS_FORM_DIALOG ||
    action.meta.dialog === ASSIGNED_COMPLETE_FORM_DIALOG);

export default function createAssignedViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_COPY_ACTION, (config.executeCopyActionSaga || executeCopyActionSaga));
    yield takeLatest(EXECUTE_ACCEPT_ACTION, (config.executeAcceptActionSaga || executeAcceptActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_REJECT_ACTION, (config.executeRejectActionSaga || executeRejectActionSaga));
    yield takeLatest(EXECUTE_COMPLETE_ACTION, (config.executeCompleteActionSaga || executeCompleteActionSaga));
    yield takeLatest(EXECUTE_CLOSE_ACTION, (config.executeCloseActionSaga || executeCloseActionSaga));
    yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(ASSIGNED_REQUESTS_TABLE_GRID));
}

function* executeAddActionSaga() {
  yield put(openAddDialog(ASSIGNED_REQUESTS_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  const requests = yield select(makeSelectEntity('requests', id));

  if (requests.status === CLOSED) {
    yield put(openViewDialog(ASSIGNED_REQUESTS_FORM_DIALOG, {id}));
  } else if (id) {
    yield put(openEditDialog(ASSIGNED_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeCopyActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openCopyDialog(ASSIGNED_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(ASSIGNED_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeCloseActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(closeRequestsSaga, ids);
  yield put(loadData(ASSIGNED_REQUESTS_TABLE_GRID));
}

function* executeAcceptActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(inProgressRequestsSaga, ids);
  yield put(loadData(ASSIGNED_REQUESTS_TABLE_GRID));
}

function* executeRejectActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(rejectRequestsSaga, ids);
  yield put(loadData(ASSIGNED_REQUESTS_TABLE_GRID));
}

function* executeCompleteActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(ASSIGNED_COMPLETE_FORM_DIALOG, { id }));
  }
}
