/**
 *
 * UnbindingAccessCardDialog actions
 *
 */

import {
  CHANGE_LOADING_STATE,
  CHANGE_SNACKBAR_STATE,
  SUBMIT_UNBINDING_ACCESS_CARD_DIALOG,
} from './constants';

export const submitUnbindingAccessCardDialog = (key) => ({
  type: SUBMIT_UNBINDING_ACCESS_CARD_DIALOG,
  payload: { key },
});

export const changeLoadingState = (loadingState) => ({
  type: CHANGE_LOADING_STATE,
  payload: { loadingState },
});

export const changeSnackbarState = ({
  message,
  state,
  variant,
}) => ({
  type: CHANGE_SNACKBAR_STATE,
  payload: {
    message,
    state,
    variant,
  },
});
