/*
 *
 * CheckpointDxGrid15755
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';

import {
  ACTIVE_REQUESTS,
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_CHECKPOINT,
  REQUESTS_FOR_CHECKPOINT_POST,
} from 'domain/Data/Filters/constants';
import { noPlacesActionButton } from '../common/actionButton';


export class CheckpointDxGrid15755 extends CheckpointDxGrid {

  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_CHECKPOINT];
    filters.push(REQUESTS_FOR_CHECKPOINT_POST)
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    return filters;
  }

  getColumns() {
    const columns = super.getColumns();

    columns.push({
      path: ['department', 'name'],
      name: 'department',
      title: 'Здание',
      getCellValue: (row) => row.department ? row.department.name : undefined,
      width: 150,
    });

    return columns;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();

    const companyIndex = columnOrder.findIndex((column) => column === 'tenantCompanyName');
    columnOrder.splice(companyIndex + 1, 0, 'department');

    return columnOrder;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();
    actionButtons.push(noPlacesActionButton);
    return actionButtons;
  }

}
