/**
 * ChangeRoleDialog selectors
 */

import { createSelector } from 'reselect';

import { makeSelectRoles } from 'domain/User/selectors';

import { role } from 'domain/typeConstants/roles';

const makeSelectDomain = () => (state) => state.get('changeRoleDialog');

const makeSelectIsOpen = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('open')
);

const makeSelectCurrentRole = () => createSelector(
  makeSelectRoles(),
  (roles) => {
    if (roles.includes(role.TENANT)) {
      return role.TENANT;
    } else if (roles.includes(role.ADMINISTRATOR_BC)) {
      return role.ADMINISTRATOR_BC;
    } else if (roles.includes(role.APPLICANT)) {
      return role.APPLICANT;
    } else if (roles.includes(role.CHECKPOINT)) {
      return role.CHECKPOINT;
    } else if (roles.includes(role.ASSIGNED)) {
      return role.ASSIGNED;
    } else if (roles.includes(role.SERVICE_REQUESTS_MANAGER)) {
      return role.SERVICE_REQUESTS_MANAGER;
    } else if (roles.includes(role.SIGHTING)) {
      return role.SIGHTING;
    } else if (roles.includes(role.MAKING_AGREEMENT_BC)) {
      return role.MAKING_AGREEMENT_BC;
    } else if (roles.includes(role.CHECKPOINT_OPERATOR)) {
      return role.CHECKPOINT_OPERATOR;
    } else if (roles.includes(role.PARKING_OPERATOR)) {
      return role.PARKING_OPERATOR;
    } else if (roles.includes(role.SENIOR_PARKING_OPERATOR)) {
      return role.SENIOR_PARKING_OPERATOR;
    } else if (roles.includes(role.UNLOADING_ZONE_OPERATOR)) {
      return role.UNLOADING_ZONE_OPERATOR;
    } else if (roles.includes(role.RECEPTION)) {
      return role.RECEPTION;
    } else if (roles.includes(role.USER)) {
      return role.USER;
    } else if (roles.includes(role.SUPPLY_APPLICANT)) {
      return role.SUPPLY_APPLICANT;
    } else if (roles.includes(role.ADMIN_SKUD)) {
      return role.ADMIN_SKUD;
    }
    return null;
  }
);

export default makeSelectDomain;
export {
  makeSelectIsOpen,
  makeSelectCurrentRole,
};
