/**
 *
 * ReceptionView sagas
 *
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';

import {
  acceptRequestsSaga,
} from 'domain/Data/Request/sagas';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openEditDialog,
  openViewDialog,
  openAddDialog,
} from 'containers/AdvancedFormDialog/actions';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

import {
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_REJECT_ACTION,
  EXECUTE_VIEW_ACTION,
  MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID,
  MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG,
  REJECT_FORM_DIALOG,
} from './constants';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS
  && new Set([REJECT_FORM_DIALOG, MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG]).has(action.meta.dialog);

export default function createReceptionViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ACCEPT_ACTION, (config.executeAcceptActionSaga || executeAcceptActionSaga));
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_REJECT_ACTION, (config.executeRejectActionSaga || executeRejectActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));

    yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID));
}

function* executeAddActionSaga() {
  yield put(openAddDialog(MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeAcceptActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(acceptRequestsSaga, ids);
  yield put(loadData(MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID));
}

function* executeRejectActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  if (ids && ids.length > 0) {
    yield put(openEditDialog(REJECT_FORM_DIALOG, { id: ids[0] }));
  }
}
