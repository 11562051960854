/*
 *
 * AdministratorBCRequestsDxGrid14927
 *
 */

import { CURRENT_DAY_REQUESTS, ON_CREATE_REQUESTS, REQUESTS_ON_CAR_ENTRY, REQUESTS_ON_VISIT, REQUESTS_ON_WORK_PRODUCTION } from 'domain/Data/Filters/constants';
import { ReceptionDxGrid14927 } from '../ReceptionView/ReceptionDxGrid14927';

export class AdministratorBCDxGrid14927 extends ReceptionDxGrid14927 {

    getFilters() {
        const { viewName } = this.props;
        const filters = [];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        return filters;
    }

}
