/**
 *
 * WorkDxGrid
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnWorkSize } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { columnStatusLabels } from 'pages/Documents/common/columnStatusLabel';

class SupplierInvoiceDxGrid extends React.PureComponent {

  getColumns() {
    return [
      {
        name: 'serialNumber',
        path: 'serialNumber',
        title: '№',
        width: columnWorkSize.DURATION,
      },
      {
        name: 'state',
        path: ['state', 'name'],
        title: 'Состояние',
        getCellValue: (row) => row.state ? row.state.name : undefined,
        type: columnType.LABEL,
        labels: columnStatusLabels,
        entityName: 'documentStates',
        width: columnWorkSize.DURATION,
      },
      {
        name: 'company',
        path: ['company', 'name'],
        title: 'Контрагент',
        getCellValue: (row) => row.company ? row.company.name : null,
        width: columnWorkSize.DURATION,
      },
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Дата оригинала',
        type: 'date',
        width: columnWorkSize.DURATION,
      },
      {
        path: 'amount',
        name: 'amount',
        title: 'Сумма',
        width: columnWorkSize.DURATION,
      },
      {
        path: 'deliverySchedule',
        name: 'deliverySchedule',
        title: 'План поставки',
        type: 'date',
        width: columnWorkSize.DURATION,
      },
      {
        name: 'file',
        path: ['scan', 'id'],
        getCellValue: (row) => row.scan ? row.scan.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: columnWorkSize.DURATION,
      },

      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: columnWorkSize.DURATION,
      },
      {
        name: 'author',
        path: ['author', 'name'],
        title: 'Автор',
        getCellValue: (row) => row.author ? row.author.name : undefined,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'responsible',
        path: ['responsible', 'name'],
        title: 'Ответственный',
        getCellValue: (row) => row.responsible ? row.responsible.name : undefined,
        width: columnWorkSize.DURATION,
      },
      {
        path: 'addedDate',
        name: 'addedDate',
        title: 'Добавлен',
        type: 'date',
        width: columnWorkSize.DURATION,
      },
      {
        path: 'lastChangedDate',
        name: 'lastChangedDate',
        title: 'Изменен',
        type: 'date',
        width: columnWorkSize.DURATION,
      },
      {
        path: 'number',
        name: 'number',
        title: '№ оригинала',
        width: columnWorkSize.DURATION,
      },
    ];
  }

  getColumnOrder() {
    return [
      'serialNumber', 'number', 'state', 'company',
      'actualCreationDate', 'file', 'scan', 'amount',
      'deliverySchedule', 'note', 'author', 'responsible',
      'lastChangedDate', 'addedDate'];
  }

  getSorting() {
    return [{ columnId: 'serialNumber', columnName: 'serialNumber', direction: 'asc' }];
  }

  getActionButtons() {
    return []
  }

  getActionMenuItems() {
    return [];
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }

  getFilters() {
    return [];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, mode, parent, parentId, entityName, height, dialogName, name } = this.props;

    return (
      <AdvancedDataTable
        tableType={settings.PAGING_TABLE}
        name={name}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        selectable
        entityName={entityName}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        mode={mode}
        parent={parent}
        parentId={parentId}
        height={height}
        dialogName={dialogName}
      />
    );
  }
}

SupplierInvoiceDxGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  viewName: PropTypes.string,
};

export default SupplierInvoiceDxGrid