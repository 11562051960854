/**
 *
 * ServiceRequestsManagerView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function ServiceRequestsManagerView({ dataTable, formDialog, serviceRequestsManagerAcceptFormDialog,
  serviceRequestsManagerRejectFormDialog, serviceRequestsManagerCompleteFormDialog, agreementDialog,
  serviceRequestsManagerBlackListFormDialog, serviceRequestsManagerVisitorArrivalFormDialog,
  viewName, viewStyles, workformDialog, }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { formDialog}
      { workformDialog}
      { serviceRequestsManagerAcceptFormDialog}
      { serviceRequestsManagerRejectFormDialog}
      { serviceRequestsManagerCompleteFormDialog}
      { serviceRequestsManagerBlackListFormDialog}
      { serviceRequestsManagerVisitorArrivalFormDialog}
      { agreementDialog}
    </div>
  );
}

ServiceRequestsManagerView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  serviceRequestsManagerAcceptFormDialog: PropTypes.node.isRequired,
  serviceRequestsManagerRejectFormDialog: PropTypes.node.isRequired,
  serviceRequestsManagerCompleteFormDialog: PropTypes.node.isRequired,
  serviceRequestsManagerBlackListFormDialog: PropTypes.node.isRequired,
  serviceRequestsManagerVisitorArrivalFormDialog: PropTypes.node.isRequired,
  workformDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
};

ServiceRequestsManagerView.defaultProps = {
  viewStyles: {},
};

export default ServiceRequestsManagerView;
