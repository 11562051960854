/**
 *
 * ColumnStatusLabels
 *
 */

import { requestStatus } from 'domain/typeConstants/status';

export const columnStatusLabels = [
  { fontColor: 'black', condition: (value) => value === requestStatus.CREATED },
  { backgroundColor: '#E0E0E0', fontColor: 'black', condition: (value) => value === requestStatus.CLOSED },
  { backgroundColor: '#9CEEC0', fontColor: 'black', condition: (value) => value === requestStatus.COMPLETED },
  { backgroundColor: '#FBBBC2', fontColor: 'black', condition: (value) => value === requestStatus.REJECTED },
  { backgroundColor: '#E6EE9C', fontColor: 'black', condition: (value) => value === requestStatus.ACCEPTED },
  { backgroundColor: '#CCB5AC', fontColor: 'black', condition: (value) => value === requestStatus.RETURNED },
  { backgroundColor: '#FFE0B2', fontColor: 'black', condition: (value) => value === requestStatus.ON_TERRITORY },
  { backgroundColor: '#81D4FA', fontColor: 'black', condition: (value) => value === requestStatus.ON_AGREEMENT },
  { backgroundColor: '#AFDFB4', fontColor: 'black', condition: (value) => value === requestStatus.PASS_ISSUED },
  { backgroundColor: '#E59EBF', fontColor: 'black', condition: (value) => value === requestStatus.NO_PLACES },
  { backgroundColor: '#E1BEE7', fontColor: 'black', condition: (value) => value === requestStatus.REPEATED },
  { backgroundColor: '#D3FFBF', fontColor: 'black', condition: (value) => value === requestStatus.IN_PROGRESS },
  { backgroundColor: '#ddb78a', fontColor: 'black', condition: (value) => value === requestStatus.ON_AGREEMENT_BY_TENANT },
  { backgroundColor: '#E6EE9C', fontColor: 'black', condition: (value) => value === requestStatus.AGREED },
  { backgroundColor: '#61D89F', fontColor: 'black', condition: (value) => value === requestStatus.SEND_TO_TENANT },
  { backgroundColor: '#6997D3', fontColor: 'white', condition: (value) => value === requestStatus.PASS_MADE},

  /* ------------------- Gorkiy park BC 14720 ------------------- */
  { backgroundColor: '#8cee7e', fontColor: 'black', condition: (value) => value === requestStatus.AGREED_ESTIMATION_COST },
  { backgroundColor: '#eed95e', fontColor: 'black', condition: (value) => value === requestStatus.AGREEMENT_ESTIMATION_COST },

  { backgroundColor: '#a7ccbf', fontColor: 'black', condition: (value) => value === requestStatus.WAIT_S3 },
  { backgroundColor: '#E1ccbf', fontColor: 'black', condition: (value) => value === requestStatus.SEND_TO_PERFORMER },

  /* ------------------- Binom 12861 ------------------- */
  { backgroundColor: '#a7ccbf', fontColor: 'black', condition: (value) => value === requestStatus.REPAIR },
  { backgroundColor: '#FBBBC2', fontColor: 'black', condition: (value) => value === requestStatus.POSTPONED },
  { backgroundColor: '#E1ccbf', fontColor: 'black', condition: (value) => value === requestStatus.PAINTING },
  { backgroundColor: '#6997D3', fontColor: 'black', condition: (value) => value === requestStatus.WELDING },
];
