/**
 *
 * RequestsPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { makeSelectRoles, makeSelectAccountId, makeSelectUserId } from 'domain/User/selectors';
import { makeSelectBreakpoint } from 'domain/Screen/selectors';

import getRequestsPage from './getRequestsPage';
import saga from './sagas';

export class RequestsPageContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { accountId, userRoles, userId, match: { params }, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, userId, params, ...restProps };
    return getRequestsPage(accountId, props);
  }
}

RequestsPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
  breakpoint: makeSelectBreakpoint(),
  userId: makeSelectUserId(),
});

const reducer = (state = {}) => state;
const withReducer = injectReducer({ key: 'receptionPage', reducer });
const withSaga = injectSaga({ key: 'receptionPage', saga });
const withConnect = connect(mapStateToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(RequestsPageContainer);
