/**
 *
 * 11994 Requests column labels
 *
 */

export const columnLabels = [
  { backgroundColor: '#FFF9C4', fontColor: 'black', condition: (value) => value === 'Создана' },
  { backgroundColor: '#E0E0E0', fontColor: 'black', condition: (value) => value === 'Закрыта' },
  { backgroundColor: '#9CEEC0', fontColor: 'black', condition: (value) => value === 'Выполнена' },
  { backgroundColor: '#FBBBC2', fontColor: 'black', condition: (value) => value === 'Отклонена' },
  { backgroundColor: '#E6EE9C', fontColor: 'black', condition: (value) => value === 'Принята' },
  { backgroundColor: '#CCB5AC', fontColor: 'black', condition: (value) => value === 'Возвращена' },
  { backgroundColor: '#FFE0B2', fontColor: 'black', condition: (value) => value === 'На территории' },
  { backgroundColor: '#B3E5FC', fontColor: 'black', condition: (value) => value === 'Выдан пропуск' },
  { backgroundColor: '#E1BEE7', fontColor: 'black', condition: (value) => value === 'Повторная' },
];
