/**
 * Snackbar selectors
 */

import { createSelector } from 'reselect';

const makeSelectDomain = () => (state) => state.get('emailSubscription');

const makeSelectOpen = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('isSubscribed')
);

export default makeSelectDomain;
export {
  makeSelectOpen,
};
