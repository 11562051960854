/*
 *
 * NotificationsListContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { makeSelectData } from './selectors';
import { loadData } from './actions';
import reducer from './reducer';
import saga from './sagas';
import NotificationsList from './NotificationsList';

class NotificationsListContainer extends React.PureComponent {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    return (
      <NotificationsList {...this.props} />
    );
  }
}

NotificationsListContainer.propTypes = {
  loadData: PropTypes.func,
};

const withReducer = injectReducer({ key: 'notificationsList', reducer });
const withSaga = injectSaga({ key: 'notificationsList', saga });

const mapStateToProps = createStructuredSelector({
  data: makeSelectData(),
});

const mapDispatchToProps = (dispatch) => ({
  loadData: () => dispatch(loadData()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(NotificationsListContainer);
