/*
 *
 * AlertDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {

  handleRequestClose = () => this.props.onRequestClose && this.props.onRequestClose();

  handleConfirm = () => this.props.onConfirm && this.props.onConfirm();

  render() {
    const { open, title, content, type } = this.props
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span>{title}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span>{content}</span>
            </DialogContentText>
          </DialogContent>

          {type === 'confirm' ?
            <DialogActions>
              <Button onClick={this.handleConfirm} color="primary" autoFocus>
                ДА
              </Button>
              <Button onClick={this.handleRequestClose} color="primary">
                Нет
              </Button>
            </DialogActions>
            :
            <DialogActions>
              <Button onClick={this.handleRequestClose} color="primary">
                ОК
              </Button>
            </DialogActions>
          }
        </Dialog>
      </div>
    );
  }
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
};

export default AlertDialog;
