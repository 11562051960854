/**
 *
 * RestrictedAdministratorBC Service Requests Form 14366
 *
 */

import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { department, company } from '../common/fields';

export class RestrictedAdministratorBCServiceRequestsForm14366 extends ServiceRequestsManagerForm {
  getFields() {
    const fields = super.getFields();

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields[assigneeIndex].rowBreakAfter = false;

    fields.splice(assigneeIndex + 1, 0, company, department);

    return fields;
  }
}
