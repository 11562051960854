/*
 *
 * Request sagas
 *
 */

import {call, put} from 'redux-saga/effects';
import * as api from 'utils/api';
import {changeEntitiesState} from '../actions';
import {REQUESTS} from '../constants';
import {
    acceptRequests,
    requestsAccepted,
    requestsAcceptingError,
    inProgressRequests,
    requestsInProgressed,
    requestsInProgressError,
    rejectRequests,
    requestsRejected,
    requestsRejectingError,
    closeRequests,
    requestsClosed,
    requestsClosingError,
    processVisitorArrival,
    visitorArrivalProcessed,
    visitorArrivalProcessingError,
    processVisitorDeparture,
    visitorDepartureProcessed,
    visitorDepartureProcessingError,
    sendToAgreementByTenantRequests,
    requestsSendedToAgreementByTenant,
    requestsSendingToAgreementByTenant,
    requestsSendedToPerformer,
    requestsSendingToPerformer,
    instructedRequests,
    instructingRequests,
    instructRequests,
    closeByTenantRequests,
    closingByTenantRequests,
    closedByTenantRequests,
    sendNoPlacesRequests,
    requestsSendedNoPlaces,
    requestsSendingNoPlaces,
} from './actions';
import {ON_TERRITORY} from "../RequestStatus/constants";

export function* acceptRequestsSaga(ids) {
    yield put(acceptRequests(ids));

    try {
        const response = yield call(api.post, 'requests/accepted', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsAccepted());
    } catch (err) {
        yield put(requestsAcceptingError(err));
    }
}

export function* inProgressRequestsSaga(ids) {
    yield put(inProgressRequests(ids));

    try {
        const response = yield call(api.post, 'requests/inProgress', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsInProgressed());
    } catch (err) {
        yield put(requestsInProgressError(err));
    }
}

export function* rejectRequestsSaga(ids) {
    yield put(rejectRequests(ids));

    try {
        const response = yield call(api.post, 'requests/rejected', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsRejected());
    } catch (err) {
        yield put(requestsRejectingError(err));
    }
}

export function* closeRequestsSaga(ids) {
    yield put(closeRequests(ids));
    try {
        const response = yield call(api.post, 'requests/closed', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsClosed());
    } catch (err) {
        yield put(requestsClosingError(err));
    }
}

export function* firedRequestsSaga(ids) {
    yield put(closeRequests(ids));
    try {
        const response = yield call(api.post, 'requests/fired', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsClosed());
    } catch (err) {
        yield put(requestsClosingError(err));
    }
}

export function* processVisitorArrivalSaga(ids, status) {
    yield put(processVisitorArrival(ids));
    const newStatus = status || ON_TERRITORY
    try {
        const response = yield call(api.post, 'requests/visitorArrived', {ids, status: newStatus});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(visitorArrivalProcessed());
    } catch (err) {
        yield put(visitorArrivalProcessingError(err));
    }
}

export function* processVisitorDepartureSaga(ids) {
    yield put(processVisitorDeparture(ids));

    try {
        const response = yield call(api.post, 'requests/visitorDeparted', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(visitorDepartureProcessed());
    } catch (err) {
        yield put(visitorDepartureProcessingError(err));
    }
}

export function* noPlacesByCheckpointSaga(ids) {
    yield put(sendNoPlacesRequests(ids));
    try {
        const response = yield call(api.post, 'requests/noPlaces', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsSendedNoPlaces());
    } catch (err) {
        yield put(requestsSendingNoPlaces(err));
    }
}

export function* sendToAgreementByTenantSaga(ids) {
    yield put(sendToAgreementByTenantRequests(ids));

    try {
        const response = yield call(api.post, 'requests/onAgreementByTenant', {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsSendedToAgreementByTenant());
    } catch (err) {
        yield put(requestsSendingToAgreementByTenant(err));
    }
}

export function* sendToPerformerSaga(ids) {
    yield put(sendToAgreementByTenantRequests(ids));
    try {
        const response = yield call(api.post, `requests/${ids}/onPerformer`, {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(requestsSendedToPerformer());
    } catch (err) {
        yield put(requestsSendingToPerformer(err));
    }
}

export function* instructedRequestsSaga(ids) {
    yield put(instructRequests(ids));
    try {
        const response = yield call(api.post, `requests/${ids}/instructed`, {ids});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(instructedRequests());
    } catch (err) {
        yield put(instructingRequests(err));
    }

}

export function* closedByTenantRequestsSaga(id) {
    yield put(closeByTenantRequests(id));
    try {
        const response = yield call(api.post, `requests/${id}/closedByTenant`, {ids: id});
        yield put(changeEntitiesState(REQUESTS, response.data));
        yield put(closedByTenantRequests());
    } catch (err) {
        yield put(closingByTenantRequests(err));
    }
}
