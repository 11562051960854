/*
 *
 * AdministratorBCView actions 14691
 *
 */

import {
  ACCEPT_REQUESTS,
  ACCEPT_REQUESTS_ERROR,
  ACCEPT_REQUESTS_SUCCESS,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_REJECT_ACTION,
  REJECT_REQUESTS,
  REJECT_REQUESTS_ERROR,
  REJECT_REQUESTS_SUCCESS,
} from './constants';

export function executeAcceptAction(dialogName) {
  return {
    type: EXECUTE_ACCEPT_ACTION,
    meta: { dialogName },
  };
}

export function acceptRequests() {
  return {
    type: ACCEPT_REQUESTS,
  };
}

export function requestsAccepted() {
  return {
    type: ACCEPT_REQUESTS_SUCCESS,
  };
}

export function requestsAcceptingError(error) {
  return {
    type: ACCEPT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function executeRejectAction(dialogName) {
  return {
    type: EXECUTE_REJECT_ACTION,
    meta: { dialogName },
  };
}

export function rejectRequests() {
  return {
    type: REJECT_REQUESTS,
  };
}

export function requestsRejected() {
  return {
    type: REJECT_REQUESTS_SUCCESS,
  };
}

export function requestsRejectingError(error) {
  return {
    type: REJECT_REQUESTS_ERROR,
    payload: { error },
  };
}
