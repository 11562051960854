/*
 *
 * AdministratorBCRequestsDxGrid14720
 *
 */

import { ReceptionDxGrid14720 } from '../ReceptionView/ReceptionDxGrid14720';

import {
    estimationCostColumn,
    estimationCostColumnFilter,
    ESTIMATION_COST,
    estimationCostScanColumn,
    estimationCostScanColumnFilter,
    ESTIMATION_COST_SCAN,
} from "../common/dxGridComponents/columns";
import {
    ACCESS_CARD_REQUESTS, CURRENT_DAY_REQUESTS, ON_CREATE_REQUESTS, REQUESTS_ON_CAR_ENTRY, REQUESTS_ON_VISIT,
    REQUESTS_ON_WORK_PRODUCTION, REQUESTS_WITHOUT_EMPTY_NAME
} from 'domain/Data/Filters/constants';


export class AdministratorBCRequestsDxGrid14720 extends ReceptionDxGrid14720 {

    getFilters() {
        const { roles, viewName } = this.props;
        const filters = [];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (!(viewName === 'all' && (roles.includes('administratorBC') || roles.includes('reception')))) {
            filters.push(REQUESTS_WITHOUT_EMPTY_NAME);
        }
        if (viewName === 'passMaking') filters.push(ACCESS_CARD_REQUESTS);
        return filters;
    }

    getActionButtons() {
        return super.getActionButtonsWithoutCustomDisable();
    }

    getActionMenuItems() {
        return super.getActionMenuItemsWithoutCustomDisable();
    }

    getColumns() {
        const columns = super.getColumns();
        columns.push(estimationCostColumn);
        columns.push(estimationCostScanColumn);
        return columns;
    }

    getGridFilters() {
        const gridFilters = super.getGridFilters();
        gridFilters.push(estimationCostColumnFilter);
        gridFilters.push(estimationCostScanColumnFilter);
        return gridFilters;
    }

    getColumnOrder() {
        const columnOrder = super.getColumnOrder();
        const fileIndex = columnOrder.findIndex(column => column === 'fileName');
        columnOrder.splice(fileIndex + 1, 0, ESTIMATION_COST, ESTIMATION_COST_SCAN);
        return columnOrder;
    }
}
