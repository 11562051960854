/**
 *
 * AdministratorForm15755
 *
 */

import { CARRYING_OUT_WORKS, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, ON_VISIT } from 'domain/Data/RequestType/constants';
import ReceptionForm15755 from '../ReceptionView/ReceptionForm15755';
import { ACCEPTED, CREATED, ON_TERRITORY, PASS_ISSUED } from 'domain/Data/RequestStatus/constants';
import * as entity from 'domain/Data/constants';


export class AdministratorForm15755 extends ReceptionForm15755 {


    getFields() {

        const currentDate = new Date();

        const fields = super.getFields();

        const statusIndex = fields.findIndex((field) => field.name === 'status');
        fields[statusIndex].calculatedValue = (values, mode) => {
            if (mode === 'add' || mode === 'copy') {
                return [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type) ? ACCEPTED : CREATED
            }
            return values.status;
        }


        const arrivalDateIndex = fields.findIndex((field) => field.name === 'arrivalDate');
        fields[arrivalDateIndex].calculatedValue = (values, mode) => {
            if (mode === 'edit' && values.arrivalDate === null && (
                (values.type === ON_VISIT && values.status === PASS_ISSUED) || (values.type === ON_CAR_ENTRY && values.status === ON_TERRITORY)
            )) { return currentDate }
            if (mode === 'copy') { return null }
            return values.arrivalDate
        }

        const typeIndex = fields.findIndex((field) => field.name === 'type');
        fields[typeIndex].visibleOptions = (currentValues, initialValues, mode, user, entities) => {
            return entities.get(entity.REQUEST_TYPES).filter(t => t.id !== CARRYING_OUT_WORKS).map(t => t.id)
        }

        return fields;
    }


}
