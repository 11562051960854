/*
 * RequestsPage12924 sagas
 */

import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';
import { SUBMIT_FORM_SUCCESS } from 'containers/AdvancedForm/constants';
import { makeSelectMode, makeSelectChangedValues, makeSelectCurrentValues } from 'containers/AdvancedForm/selectors';
import { openAlertDialog } from 'containers/AlertDialog/actions';

import checkpointViewSaga from './CheckpointView/sagas';

export default function* rootSaga() {
  yield takeLatest(SUBMIT_FORM_SUCCESS, handleFormSubmitSaga);
  yield fork(checkpointViewSaga);
}

function* handleFormSubmitSaga({ meta: { advancedForm } }) {
  const mode = yield select(makeSelectMode(advancedForm));

  let accessCard = null;
  let accessCardKey = null;
  let visitorFullName = null;
  let documentDetails = null;

  if (mode === 'add' || mode === 'copy') {
    const currentValues = yield select(makeSelectCurrentValues(advancedForm));

    accessCard = currentValues.accessCard;
    accessCardKey = currentValues.accessCardKey;
    visitorFullName = currentValues.visitorFullName;
    documentDetails = currentValues.documentDetails;
  } else if (mode === 'edit') {
    const changedData = yield select(makeSelectChangedValues(advancedForm));
    const currentValues = yield select(makeSelectCurrentValues(advancedForm));

    accessCard = changedData.accessCard;
    accessCardKey = changedData.accessCardKey;
    visitorFullName = changedData.visitorFullName || currentValues.visitorFullName;
    documentDetails = changedData.documentDetails || currentValues.documentDetails;
  }

  if (accessCard || accessCardKey) {
    try {
      yield call(api.sendToParsec, accessCard, accessCardKey, visitorFullName, documentDetails);
    } catch (err) {
      console.log(err.response);
      yield put(openAlertDialog('Произошла ошибка', 'Ошибка приема данных в Parsec, обратитесь к системному администратору.'));
    }
  }
}
