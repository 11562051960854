/*
 *
 * TenantDxGrid14278
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';

export class TenantDxGrid14278 extends TenantDxGrid {
  getColumns() {
    const columns = super.getColumns();

    columns.push({
      path: 'department',
      name: 'department',
      title: 'Корпус',
      getCellValue: (row) => row.department ? row.department.name : undefined,
    });

    return columns;
  }

  getColumnWidths() {
    const columnWidths = super.getColumnWidths();

    columnWidths.push({
      columnName: 'department', width: 155,
    });

    return columnWidths;
  }

  getGridFilters() {
    const gridFilters = super.getGridFilters();

    gridFilters.push({
      columnName: 'department', value: '',
    });

    return gridFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const noteIndex = columnOrder.findIndex((columnName) => columnName === 'note');
    columnOrder.splice(noteIndex + 1, 0, 'department');
    return columnOrder;
  }
}
