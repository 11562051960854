/**
 *
 * SelectTextFilterCreator
 *
 */

import React from 'react';

import createSelectTextFilterContainer from './createSelectTextFilterContainer';

class SelectTextFilterCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const SelectTextFilterContainer = createSelectTextFilterContainer(this.props.filter.entityName);
    return <SelectTextFilterContainer {...this.props} />;
  }
}


export default SelectTextFilterCreator;