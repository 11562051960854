import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { account } from 'domain/typeConstants/accounts';

import {
  makeSelectBreakpoint,
  makeSelectScreenWidth,
} from 'domain/Screen/selectors';
import {
  makeSelectAccountId,
} from 'domain/User/selectors';

import * as screenActions from 'domain/Screen/actions';
import {
  getBreakpointByWidth,
} from 'utils/screenUtils';

import {
  getValueFromLocalStorage,
} from 'utils/localStorage';

import App from './App';

class AppContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateScreen = this.updateScreen.bind(this);
  }

  componentDidMount() {
    const { accountId } = this.props;

    if (accountId === account.BC_KHIMKI) {
      document.title = 'Портал ХБП';
    }

    window.addEventListener('resize', this.updateScreen);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreen);
  }

  updateScreen() {
    const { breakpoint, changeScreenProperties, screenWidth } = this.props;

    if (screenWidth && getBreakpointByWidth(window.innerWidth) !== getBreakpointByWidth(screenWidth)) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      breakpoint.width = width;
      breakpoint.height = height;
      changeScreenProperties({
        height,
        width,
        orientation: height > width ? 'portrait' : 'landscape',
        breakpoint,
        viewMode: breakpoint.down('md') ? 'mobile' : 'desktop',
      });
    }
  }

  render() {
    return <App {...this.props} />;
  }
}

AppContainer.propTypes = {
  breakpoint: PropTypes.object,
  initializeScreenProperties: PropTypes.func.isRequired,
  changeScreenProperties: PropTypes.func.isRequired,
  screenWidth: PropTypes.number,
  accountId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const mapStateToProps = createStructuredSelector({
  breakpoint: makeSelectBreakpoint(),
  screenWidth: makeSelectScreenWidth(),
  accountId: makeSelectAccountId(),
});

const mapDispatchToProps = (dispatch) => ({
  initializeScreenProperties: (params) => dispatch(screenActions.initializeScreenProperties(params)),
  changeScreenProperties: (params) => dispatch(screenActions.changeScreenProperties(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
