/**
*
* EditorContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import * as advancedFormActionCreators from '../actions';
import { connect } from 'react-redux';
import EditorField from './EditorField';

function createEditorContainer(formName) {
  class EditorContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    normalize = (value) => this.props.normalize ?
      this.props.normalize(value) || null :
      value || null;

    render() {
      const { clearErrorsOnChange, ...restProps } = this.props; // eslint-disable-line no-unused-vars
      return (
        <Field
          {...restProps}
          component={EditorField}
          normalize={this.normalize}
        />
      );
    }
  }

  EditorContainer.propTypes = {
    normalize: PropTypes.func,
    clearErrorsOnChange: PropTypes.bool,
  };

  const mapDispatchToProps = (dispatch) => ({
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
  });

  return connect(null, mapDispatchToProps)(EditorContainer);
}

export default createEditorContainer;
