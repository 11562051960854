/**
*
* ActionButton
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const styleSheet = {
  actionIcon: {
    marginRight: 6,
  },
};

class ActionButton extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { classes, label, icon, onClick, disabled, viewMode } = this.props;
    return (
      <Button
        color="primary"
        onClick={onClick}
        size="small"
        disabled={disabled}
      >
        <Icon className={classes.actionIcon}>
          {icon}
        </Icon>
        {viewMode !== 'mobile' && label}
      </Button>
    );
  }
}

ActionButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styleSheet)(ActionButton);
