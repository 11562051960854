/*
 * withForcePasswordChange
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectForcePasswordChange } from 'domain/User/selectors';

function withForcePasswordChange(Component) {
  class ForcePasswordChangeContainer extends React.Component {
    componentWillMount = () => this.check();

    componentWillReceiveProps = () => this.check();

    check() {
      const { isForcePasswordChange, location, redirect } = this.props;
      if (isForcePasswordChange) {
        redirect(location.pathname + location.search);
      }
    }

    render() {
      const { isForcePasswordChange, ...restProps } = this.props;
      const result = isForcePasswordChange !== true ?
        <Component {...restProps} /> : null;
      return result;
    }
  }

  ForcePasswordChangeContainer.propTypes = {
    isForcePasswordChange: PropTypes.bool,
    location: PropTypes.object.isRequired,
    redirect: PropTypes.func.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    isForcePasswordChange: makeSelectForcePasswordChange(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      redirect: (nextPathAfterLogin) => dispatch(replace(`/changePassword?next=${nextPathAfterLogin}`)),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ForcePasswordChangeContainer);
}

export default withForcePasswordChange;
