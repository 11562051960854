/*
 *
 * createAdvancedFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectBreakpoint } from 'domain/Screen/selectors';

import AdvancedFormDialog from './AdvancedFormDialog';
import * as actionCreators from './actions';
import {
  makeSelectEntityId,
  makeSelectIsOpen,
  makeSelectLoadingState,
  makeSelectMode,
  makeSelectTabPosition,
} from './selectors';
import { changeSelection } from 'containers/AdvancedDataTable/actions';
import { makeSelectCurrentFieldValue } from 'containers/AdvancedForm/selectors';
import { makeSelectEntitiesIds } from 'containers/AdvancedDataTable/selectors';
import { makeSelectEntityData } from 'domain/Data/selectors';

function createAdvancedFormDialog({dialogName, formName, entityName, dataTableName, numberField}) {
  const AdvancedFormDialogContainer = ({ closeDialog, saveDialog, ...restProps }) =>
  (<AdvancedFormDialog
    onSave={saveDialog}
    onCancel={closeDialog}
    onClose={closeDialog}
    formName={formName}
    dialogName={dialogName}
    {...restProps}
  />);

  AdvancedFormDialogContainer.propTypes = {
    closeDialog: PropTypes.func.isRequired,
    saveDialog: PropTypes.func.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    breakpoint: makeSelectBreakpoint(),
    entityId: makeSelectEntityId(dialogName),
    loadingState: makeSelectLoadingState(dialogName),
    mode: makeSelectMode(dialogName),
    open: makeSelectIsOpen(dialogName),
    number: makeSelectCurrentFieldValue(formName, numberField || 'number'),

    tab: makeSelectTabPosition(dialogName),
    entityIds: makeSelectEntitiesIds(dataTableName),
    entities: makeSelectEntityData(entityName),
  });

  const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(actionCreators.closeDialog(dialogName)),
    saveDialog: () => dispatch(actionCreators.saveDialog(dialogName)),
    openEditDialog: (params) => dispatch(actionCreators.openEditDialog(dialogName, params)),
    openViewDialog: (params) => dispatch(actionCreators.openViewDialog(dialogName, params)),
    changeSelection: (dataTableName, selection) => dispatch(changeSelection(dataTableName, selection)),
    changeTab: (id) => dispatch(actionCreators.changeTab(dialogName, id)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(AdvancedFormDialogContainer);
}

export default createAdvancedFormDialog;
