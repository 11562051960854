/*
 *
 * AdministratorBCServiceRequestsFormDialogContainer14326
 *
 */

import { ServiceRequestsManagerFormDialogContainer14326 } from '../ServiceRequestsManagerView';

export class AdministratorBCServiceRequestsFormDialogContainer14326 extends ServiceRequestsManagerFormDialogContainer14326 {
}
