/**
 *
 * Common fields for BC Khimki(14326)
 *
 */

import React from 'react';
import { formField } from 'domain/typeConstants/form';
import {
  CARRYING_OUT_WORKS,
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import * as entity from 'domain/Data/constants';
import {
  GUEST_PARKING_TYPE,
  UNLOADING_LOADING_PARKING_TYPE,
} from '../../../domain/Data/ParkingType/constants';
import {
  ADDITIONAL_ACCESS_LEVEL,
  BLOCK_CARD_ACCESS_LEVEL,
  EMPLOYEE_ACCESS_LEVEL,
  OFFICE_MANAGER_ACCESS_LEVEL,
} from '../../../domain/Data/AccessLevel/constants';
import {
  BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
} from '../../../domain/Data/RequestType/constants';
import {
  MOVEMENT_MATERIAL_VALUES_DATA,
  PROPERTY_BRINGING,
  PROPERTY_REMOVAL,
} from '../../../domain/Data/OnPropertyBringingRequestType/constants';
import {
  // eslint-disable-next-line no-unused-vars
  F_UNDERGROUND_PARKING,
  D_DETAIL_PARKING,
  B_PARKING,
} from '../../../domain/Data/Parking/constants';
import { ACCEPTED, REJECTED, ON_AGREEMENT_BY_TENANT } from 'domain/Data/RequestStatus/constants';

/** ---------------- visitorFullName ---------------- */
export const visitorFullNameLabel = (values) => {
  if (values.type === ON_PASS_MAKING) {
    return 'Получатель электронной персональной карты';
  }
  if (values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL) {
    return 'Владелец электронной персональной карты';
  }

  return 'ФИО посетителя';
};
export const visitorFullNameVisible = (values) => !values.isGroupRequest && values.type !== SERVICE && values.type !== CARRYING_OUT_WORKS;
export const visitorFullNameRequired = (values) => !values.isGroupRequest && [ON_VISIT, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
  ON_CAR_ENTRY].includes(values.type);

/** ---------------- visitDate ---------------- */
export const visitDateLabel = (values) => {
  if (values.type === CARRYING_OUT_WORKS) {
    return 'Дата начала';
  }

  return 'Дата';
};

/** ---------------- type ---------------- */
export const typeHelperText = (values) => {
  if (values.type === ON_PROPERTY_BRINGING) {
    if (values.khimkiRequestType === PROPERTY_BRINGING) {
      return (
        <React.Fragment>
          Доставка на территорию Химки Бизнес Парк возможна только автомобилями грузоподъемностью не более 3,5
          тонн или длиной не более 6 метров
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        Арендатор обязуется поддерживать чистоту зон, через которые осуществляется провоз товаров и возвращать тележку
        на парковочную станцию перед служебным входом
      </React.Fragment>
    );
  }

  return '';
};
export const typeCompareOptions = (a, b) => {
  const firstItem = Number.parseInt(a.khimkiNumber, 10);
  const secondItem = Number.parseInt(b.khimkiNumber, 10);

  if (firstItem < secondItem) return -1;
  if (firstItem > secondItem) return 1;
  return 0;
};

/** ---------------- isGroupRequest ---------------- */
const _groupRequestTypes = new Set([CARRYING_OUT_WORKS, ON_CAR_ENTRY]);
export const isGroupRequestVisible = (values) => _groupRequestTypes.has(values.type);
export const isGroupRequestRowBreakBefore = (values) => _groupRequestTypes.has(values.type);

/** ---------------- longterm ---------------- */
export const longtermVisible = (values) => ![SERVICE, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type);
export const longtermRequired = () => false;

/** ---------------- companyName ---------------- */
export const companyNameLabel = (values) => {
  if (values.type === CARRYING_OUT_WORKS) {
    return 'Подрядчик';
  }
  if (values.type === ON_PROPERTY_BRINGING) {
    return 'Компания-Поставщик';
  }

  return 'Из какой компании';
};

export const companyNameVisible = true;
export const companyNameRequired = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA) {
    return false;
  }
  return values.type === CARRYING_OUT_WORKS || values.type === ON_PROPERTY_BRINGING;
};

/** ---------------- carNumber ---------------- */
export const carNumberVisible = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA) {
    return false;
  }

  if (values.isGroupRequest) {
    return false;
  }

  return new Set([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL, CARRYING_OUT_WORKS]).has(values.type);
};

export const carNumberHelperText = (values) => {
  if (values.type === ON_PROPERTY_BRINGING) {
    return (
      <React.Fragment>
        Марки и № авто обязательно, если автомобиль не забрендирован
      </React.Fragment>
    );
  }

  return '';
};

/** ---------------- carModelText ---------------- */
export const carModelTextHelperText = () => '';
export const carModelTextVisible = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA) {
    return false;
  }

  if (values.isGroupRequest) {
    return false;
  }

  return (new Set([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL, ON_PASS_MAKING, CARRYING_OUT_WORKS]).has(values.type)
    && !values.isGroupRequest);
};

/** ---------------- nonstandardCarNumber ---------------- */
export const nonstandardCarNumberVisible = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA) {
    return false;
  }

  return new Set([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, CARRYING_OUT_WORKS]).has(values.type);
};

/** ---------------- parking ---------------- */
export const parkingVisible = (values) => { // eslint-disable-line
  return new Set([ON_CAR_ENTRY, BLOCKING_OR_ADDITIONAL_CARD_LEVEL, ON_PASS_MAKING]).has(values.type);
};

export const parkingMultiSelectVisible = (values) => { // eslint-disable-line
  return new Set([ON_PASS_MAKING]).has(values.type);
};

export const parkingVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
  const parkings = entities.get('parkings').valueSeq().toJS();

  if (currentValues.type === ON_CAR_ENTRY) {
    return parkings
      .filter((parking) => parking.type === GUEST_PARKING_TYPE)
      .map((parking) => parking.id);
  }

  if (currentValues.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL) {
    return parkings
      .filter((parking) => parking.id !== D_DETAIL_PARKING && parking.id !== B_PARKING)
      .map((parking) => parking.id);
  }

  if (currentValues.type === ON_PROPERTY_BRINGING) {
    return parkings
      .filter((parking) => parking.type === UNLOADING_LOADING_PARKING_TYPE)
      .map((parking) => parking.id);
  }

  return parkings.map((parking) => parking.id);
};

export const parkingMultiSelectVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
  const visibleOptions = parkingVisibleOptions(currentValues, initialValues, mode, user, entities);
  if (visibleOptions && Array.isArray(visibleOptions)) {
    return visibleOptions
      .filter((option) => option !== D_DETAIL_PARKING)
      .map((option) => option);
  }

  return visibleOptions;
};

export const parkingCalculatedValue = (currentValues, mode, entities) => {
  const parkings = new Set([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL]);
  if (!parkings.has(currentValues.type)) return null;
  if (entities) {
    const currentParking = entities.getIn(['parkings', currentValues.parking]);
    const parkingType = currentParking && currentParking.type;

    if (currentValues.type === ON_CAR_ENTRY && parkingType !== GUEST_PARKING_TYPE) return null;
    if (currentValues.type === ON_PROPERTY_BRINGING
      && (parkingType !== UNLOADING_LOADING_PARKING_TYPE || currentValues.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)) return null;
  }

  return currentValues.parking;
};

export const parkingHelperText = (currentValues) => {
  if (currentValues.type === ON_PROPERTY_BRINGING && !!currentValues.parking) {
    return (
      <React.Fragment>
        Доставка на территорию Химки Бизнес Парк возможна только автомобилями грузоподъемностью не более 3,5
        тонн или длиной не более 6 метров
      </React.Fragment>
    );
  }

  return '';
};

export const parkingDisabled = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === PROPERTY_BRINGING) {
    return true;
  }

  return false;
};

/** ---------------- parkingPlace ---------------- */
export const parkingPlaceVisible = (values) => {
  if (values.type === ON_PROPERTY_BRINGING
    && (values.khimkiRequestType === PROPERTY_REMOVAL || values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)) {
    return false;
  }
  return new Set([ON_CAR_ENTRY, ON_PASS_MAKING,
    BLOCKING_OR_ADDITIONAL_CARD_LEVEL]).has(values.type);
};
export const parkingPlaceRequired = () => { // eslint-disable-line
  return false;
};
export const parkingPlaceCalculatedValue = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && (values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA
    || values.khimkiRequestType === PROPERTY_REMOVAL)) {
    return null;
  }

  return values.parkingPlace;
};

/** ---------------- khimkiGuestPhone ---------------- */
export const khimkiGuestPhoneLabel = (values) => {
  if (values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL) {
    return 'Телефон получателя';
  }
  if (values.type === CARRYING_OUT_WORKS) {
    return 'Телефон отв. арендатора';
  }

  return 'Телефон посетителя';
};
export const khimkiGuestPhoneVisible = (values) => [ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
  ON_CAR_ENTRY, CARRYING_OUT_WORKS].includes(values.type);
export const khimkiGuestPhoneRequired = () => false;

/** ---------------- khimkiAccessLevel ---------------- */
export const khimkiAccessLevelVisibleOptions = (currentValues) => {
  if (currentValues.type === ON_PASS_MAKING) {
    return [EMPLOYEE_ACCESS_LEVEL, OFFICE_MANAGER_ACCESS_LEVEL];
  }
  if (currentValues.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL) {
    return [BLOCK_CARD_ACCESS_LEVEL, ADDITIONAL_ACCESS_LEVEL];
  }

  return [];
};

/** ---------------- whatHappened ---------------- */
export const whatHappenedLabel = (values) => {
  if (values.type === CARRYING_OUT_WORKS) {
    return 'Наименование/Характер работ';
  }
  if (values.type === SERVICE) {
    return 'Суть заявки';
  }

  return 'Что нужно сделать';
};

export const whatHappenedRequired = (values) => values.type === SERVICE;
export const whatHappenedVisible = (values) => [SERVICE, CARRYING_OUT_WORKS].includes(values.type);
/** ---------------- whereHappened ---------------- */
export const whereHappenedLabel = (values) => {
  if (values.type === SERVICE) {
    return 'Место возникновения инцидента/события';
  }

  return 'Где произошло';
};

/** ---------------- whoMeets ---------------- */
export const whoMeetsLabel = (values) => {
  if (values.type === ON_CAR_ENTRY) {
    return 'ФИО встречающего';
  }

  return 'Заявитель (ФИО контактного лица)';
};

/** ---------------- implementationReport ---------------- */
export const implementationReportLabel = (values) => {
  if (values.type === SERVICE) {
    return 'Выполненные действия';
  }

  return 'Отчет о выполнении';
};

/** ---------------- khimkiTime ---------------- */

export const khimkiTimeLabel = (values) => {
  if (values.type === CARRYING_OUT_WORKS) {
    return 'Время проведения работ';
  }
  return 'Время';
};

export const khimkiTimeVisible = (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING].includes(values.type);
export const khimkiTimeRowBreakBefore = (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING].includes(values.type);
export const khimkiTimeRowBreakAfter = () => false;

/** ---------------- khimkiEmailGuest ---------------- */
export const khimkiEmailGuestLabel = (values) => {
  if (values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL) {
    return 'E-mail получателя';
  }

  return 'E-mail посетителя';
};
export const khimkiEmailGuestVisible = (values) => [ON_VISIT, ON_CAR_ENTRY, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type);
/** ---------------- khimkiEmailMeeting ---------------- */
export const khimkiEmailMeetingLabel = (values) => {
  if (values.type === ON_CAR_ENTRY) {
    return 'E-mail встречающего';
  }

  return 'E-mail заявителя';
};

/** ---------------- workType ---------------- */
export const workTypeVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
  const workTypes = entities.get(entity.WORK_TYPES).valueSeq().toJS();

  if (workTypes) {
    return workTypes
      .filter((item) => item.isKhimki)
      .map((item) => item.id);
  }

  return [];
};
export const workTypeCompareOptions = (a, b) => {
  const firstItem = Number.parseInt(a.number, 10);
  const secondItem = Number.parseInt(b.number, 10);

  if (firstItem < secondItem) return -1;
  if (firstItem > secondItem) return 1;
  return 0;
};

/** ---------------- khimkiAccessPremises ---------------- */
export const khimkiAccessPremisesLabel = (values) => {
  if (values.type === ON_PROPERTY_BRINGING && (values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA || values.khimkiRequestType === PROPERTY_BRINGING)) {
    return 'Маршрут перемещения';
  }
  return 'Доступ в помещение';
};

export const khimkiAccessPremisesVisible = (values) => (values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL
  || (values.type === ON_PROPERTY_BRINGING && (values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA || values.khimkiRequestType === PROPERTY_BRINGING)));

export const khimkiAccessPremisesRowBreakBefore = () => false;

/** ---------------- phone ---------------- */
export const phoneLabel = (values) => {
  if (values.type === ON_CAR_ENTRY) {
    return 'Телефон встречающего';
  }

  return 'Телефон заявителя';
};

/** ---------------- department ---------------- */
export const department = {
  type: 'select',
  entityName: 'departments',
  name: 'department',
  label: 'Здание',
  visible: true,
  required: true,
  calculatedValue: (values, mode, entities, nameOfFieldCausedCalculation) => {
    if (entities && (!values.department || nameOfFieldCausedCalculation === 'company')) {
      const company = entities.get('companies').get(values.company);
      return company ? company.department : values.department;
    }

    return values.department;
  },
};

/** ---------------- khimkiTenantComment ---------------- */
export const khimkiTenantComment = {
  name: 'khimkiTenantComment',
  label: 'Комментарий от обслуживающей организации Арендатора',
  grid: { sm: 12 },
  visible: (values) => values.type === SERVICE,
  calculatedValue: (values) => {
    if (values.type === SERVICE)
      return values.khimkiTenantComment;
    return null;
  },
};

export const managementCompanyComment =
{
  name: 'managementCompanyComment',
  label: 'Комментарий Администрации ХБП',
  grid: { sm: 12 },
  visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
  initialValue: (values, mode) => {
    if (mode === 'copy') {
      return null;
    }
    return values.managementCompanyComment;
  },
  required: (values) => values.status === REJECTED ||
    values.status === ACCEPTED || values.status === ON_AGREEMENT_BY_TENANT,
  calculatedValue: (values, mode) => {
    if (mode === 'edit' || mode === 'view') return values.managementCompanyComment;
    return null;
  },
};

export const memorandumScan =
{
  name: 'memorandumScan',
  label: 'Скан меморандума',
  type: 'file',
  visible: (values) => values.type === CARRYING_OUT_WORKS,
  initialValue: (values, mode) => {
    if (mode === 'copy') {
      return null;
    }
    return values.memorandumScan;
  },
  // fileSize: (values) => {
  //   if (values.type === CARRYING_OUT_WORKS) {
  //     return {
  //       size: 30e6,
  //       errorTextFile: 'Размер прикрепляемого файла не должен превышать 30 Мб'
  //     };
  //   }
  //   return null;
  // },
  rowBreakBefore: true,
};

export const assignee =
{
  name: 'assignee',
  type: formField.SELECT,
  label: 'Исполнитель',
  entityName: 'employees',
  orderBy: 'name',
  cached: false,
  visible: (values) => values.type === SERVICE,
  rowBreakAfter: (values) => values.type === SERVICE,
  calculatedValue: (values, mode) => {
    if (mode !== 'copy' && values.type === SERVICE) return values.assignee;
    return null;
  },
  visibleOptions: (currentValues, initialValue, mode, user, entities) => {
    const assignee = [];
    entities.get('employees').map((item) => { // eslint-disable-line
      if (item.isPerformer) assignee.push(item.id);
    });

    return assignee;
  },
}

/** ---------------- startWorkingHour ---------------- */
const isEqualDates = (dateA, dateB) => {
  if (!dateA || !dateB) {
    return false;
  }

  return dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate();
};

const compareWorkingHours = (startWorkingHour, endWorkingHour) => {
  if (!startWorkingHour && !endWorkingHour) {
    return undefined;
  }

  if (startWorkingHour && !endWorkingHour) {
    return 1;
  }

  if (!startWorkingHour && endWorkingHour) {
    return -1;
  }

  const hours = {
    4137202084000: 0,
    4137202085000: 1,
    4137202087000: 2,
    4139362717000: 3,
    4139362748000: 4,
    4139362754000: 5,
    4139362757000: 6,
    4139362760000: 7,
    4139362764000: 8,
    4139362787000: 9,
    4139362796000: 10,
    4139362799000: 11,
    4139362804000: 12,
    4139362818000: 13,
    4139362835000: 14,
    4139362839000: 15,
    4139362842000: 16,
    4139362846000: 17,
    4139362849000: 18,
    4139362851000: 19,
    4139362857000: 20,
    4139362870000: 21,
    4139362872000: 22,
    4139362879000: 23,
  };

  const startWorkingNumber = hours[startWorkingHour] || -1;
  const endWorkingNumber = hours[endWorkingHour] || -1;

  return startWorkingNumber > endWorkingNumber ? 1 : -1;
};

export const validateWorkingHour = (values) => {
  if (
    (!values.expirationDate || isEqualDates(values.expirationDate, values.visitDate)) &&
    compareWorkingHours(values.startWorkingHour, values.endWorkingHour) === 1
  ) {
    return { errorHelperText: 'Некорректно указано время' };
  }

  return null;
};
