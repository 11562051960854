/*
 *
 * Request constants
 *
 */

export const ACCEPT_REQUESTS = 'claris-vnext/Data/Request/ACCEPT_REQUESTS';
export const ACCEPT_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/ACCEPT_REQUESTS_SUCCESS';
export const ACCEPT_REQUESTS_ERROR = 'claris-vnext/Data/Request/ACCEPT_REQUESTS_ERROR';

export const IN_PROGRESS_REQUESTS = 'claris-vnext/Data/Request/IN_PROGRESS_REQUESTS';
export const IN_PROGRESS_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/IN_PROGRESS_REQUESTS_SUCCESS';
export const IN_PROGRESS_REQUESTS_ERROR = 'claris-vnext/Data/Request/IN_PROGRESS_REQUESTS_ERROR';

export const REJECT_REQUESTS = 'claris-vnext/Data/Request/REJECT_REQUESTS';
export const REJECT_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/REJECT_REQUESTS_SUCCESS';
export const REJECT_REQUESTS_ERROR = 'claris-vnext/Data/Request/REJECT_REQUESTS_ERROR';

export const CLOSE_REQUESTS = 'claris-vnext/Data/Request/CLOSE_REQUESTS';
export const CLOSE_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/CLOSE_REQUESTS_SUCCESS';
export const CLOSE_REQUESTS_ERROR = 'claris-vnext/Data/Request/CLOSE_REQUESTS_ERROR';

export const PROCESS_REQUESTS_ON_VISITOR_ARRIVAL = 'claris-vnext/Data/Request/PROCESS_REQUESTS_ON_VISITOR_ARRIVAL';
export const PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_SUCCESS = 'claris-vnext/Data/Request/PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_SUCCESS';
export const PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_ERROR = 'claris-vnext/Data/Request/PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_ERROR';

export const PROCESS_REQUESTS_ON_VISITOR_DEPARTURE = 'claris-vnext/Data/Request/PROCESS_REQUESTS_ON_VISITOR_DEPARTURE';
export const PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_SUCCESS = 'claris-vnext/Data/Request/PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_SUCCESS';
export const PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_ERROR = 'claris-vnext/Data/Request/PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_ERROR';

export const SEND_TO_AGREEMENT_BY_TENANT = 'claris-vnext/Data/Request/SEND_TO_AGREEMENT_BY_TENANT';
export const SEND_TO_AGREEMENT_BY_TENANT_SUCCESS = 'claris-vnext/Data/Request/SEND_TO_AGREEMENT_BY_TENANT_SUCCESS';
export const SEND_TO_AGREEMENT_BY_TENANT_ERROR = 'claris-vnext/Data/Request/SEND_TO_AGREEMENT_BY_TENANT_ERROR';

export const INSTRUCTED_REQUESTS = 'claris-vnext/Data/Request/INSTRUCTED_REQUESTS';
export const INSTRUCTED_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/INSTRUCTED_REQUESTS_SUCCESS';
export const INSTRUCTED_REQUESTS_ERROR = 'claris-vnext/Data/Request/INSTRUCTED_REQUESTS_ERROR';

export const CLOSED_BY_TENANT_REQUESTS = 'claris-vnext/Data/Request/CLOSED_BY_TENANT_REQUESTS';
export const CLOSED_BY_TENANT_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/CLOSED_BY_TENANT_REQUESTS_SUCCESS';
export const CLOSED_BY_TENANT_REQUESTS_ERROR = 'claris-vnext/Data/Request/CLOSED_BY_TENANT_REQUESTS_ERROR';

export const PERFORME_REQUESTS = 'claris-vnext/Data/Request/PERFORME_REQUESTS';
export const PERFORME_REQUESTS_SUCCESS = 'claris-vnext/Data/Request/PERFORME_REQUESTS_SUCCESS';
export const PERFORME_REQUESTS_ERROR = 'claris-vnext/Data/Request/PERFORME_REQUESTS_ERROR';

export const EXECUTE_SEND_TO_PERFORMER = 'claris-vnext/Data/Request/EXECUTE_SEND_TO_PERFORMER';
export const SEND_TO_PERFORMER_SUCCESS = 'claris-vnext/Data/Request/SEND_TO_PERFORMER_SUCCESS';
export const SEND_TO_PERFORMER_ERROR = 'claris-vnext/Data/Request/SEND_TO_PERFORMER_ERROR';

export const SEND_NO_PLACES = 'claris-vnext/Data/Request/SEND_NO_PLACES';
export const SENDED_NO_PLACES = 'claris-vnext/Data/Request/SENDED_NO_PLACES';
export const SEND_NO_PLACES_ERROR = 'claris-vnext/Data/Request/SEND_NO_PLACES_ERROR';
