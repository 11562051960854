/*
 *
 * ServiceRequestsManagerAcceptFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { SERVICE_REQUESTS_MANAGER_ACCEPT_FORM, SERVICE_REQUESTS_MANAGER_ACCEPT_FORM_DIALOG } from './constants';

function ServiceRequestsManagerAcceptFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={SERVICE_REQUESTS_MANAGER_ACCEPT_FORM_DIALOG}
      formName={SERVICE_REQUESTS_MANAGER_ACCEPT_FORM}
      advancedForm={form}
      editModeTitle="Принять заявку"
    />
  );
}

ServiceRequestsManagerAcceptFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ServiceRequestsManagerAcceptFormDialog;
