/**
 *
 * ReceptionMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import { RowComponent } from '../common/mobile';

import { columnType } from 'domain/typeConstants/table';

import {
  ACTIVE_RULES,
  COMPANY_RULES, NEWS_RULES, NOT_NEWS_RULES,
} from 'domain/Data/Filters/constants';

import { addActionButton } from '../common/actionButton';
import {
  editActionItem,
  viewActionItem,
} from '../common/actionMenu';

import { RECEPTION_RULE_DATA_TABLE } from './constants';

export class ReceptionMobileGrid extends React.PureComponent {
  static propTypes = {
    viewName: PropTypes.string,
    onActionExecuting: PropTypes.func,
  };

  getFilters() {
    const { viewName } = this.props;

    const filters = [];
    if (viewName === 'all') filters.push(COMPANY_RULES);
    if (viewName === 'active') filters.push(ACTIVE_RULES);
    if (viewName === 'allNews') filters.push(NEWS_RULES);
    if (viewName === 'allDocumentsRules') filters.push(NOT_NEWS_RULES);
    return filters;
  }

  getDetailRowFields() {
    return [
      {
        name: 'actualCreationDate',
        title: 'Дата',
        type: columnType.DATE,
      },
      {
        name: 'validUntil',
        title: 'Действует до',
        type: columnType.DATE,
      },
      {
        name: 'content',
        title: 'Содержание',
      },
      {
        name: 'file.name',
        getValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
      },
    ];
  }

  getSorting() {
    return [{ columnId: 'actualCreationDate', columnName: 'actualCreationDate', direction: 'desc' }];
  }

  getActionButtons() {
    return [addActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, editActionItem];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="documents"
        name={RECEPTION_RULE_DATA_TABLE}

        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        detailRowFields={this.getDetailRowFields()}
        filters={this.getFilters()}
        onActionExecuting={onActionExecuting}
        sorting={this.getSorting()}
        rowComponent={RowComponent}
        viewName={viewName}
      />
    );
  }
}
