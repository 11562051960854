/**
 *
 * CheckpointMobileGrid 15755
 *
 */

import CheckpointMobileGrid from 'pages/Requests/CheckpointView/CheckpointMobileGrid';
import {
    CURRENT_DAY_REQUESTS,
    REQUESTS_FOR_USER_DEPARTMENT,
    ACTIVE_REQUESTS,
    REQUESTS_FOR_CHECKPOINT,
    NOT_REJECTED_REQUESTS,
} from 'domain/Data/Filters/constants';

export class CheckpointMobileGrid15755 extends CheckpointMobileGrid {
    getFilters() {
        const { viewName } = this.props;
        const filters = [REQUESTS_FOR_CHECKPOINT, REQUESTS_FOR_USER_DEPARTMENT];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        if (viewName === 'today') {
          filters.push(CURRENT_DAY_REQUESTS);
          filters.push(NOT_REJECTED_REQUESTS);
        }
    
        return filters;
      }
}


