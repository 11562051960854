/**
 *
 * UnbindingAccessCardDialog sagas
 *
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';

import { SUBMIT_UNBINDING_ACCESS_CARD_DIALOG } from './constants';

import { changeLoadingState, changeSnackbarState } from './actions';

export default function* rootSaga() {
  yield takeLatest(SUBMIT_UNBINDING_ACCESS_CARD_DIALOG, submitUnbindingAccessCardDialogSaga);
}

function* submitUnbindingAccessCardDialogSaga({ payload: { key } }) {
  const startTime = new Date().getTime();

  try {
    yield put(changeLoadingState(true));

    yield call(api.unbindAccessCardByKey, key);

    const endTime = new Date().getTime();
    const result = endTime - startTime;
    if (result < 1000) yield* wait(1000 - result);

    yield put(changeLoadingState(false));
    yield put(changeSnackbarState({
      state: true,
      variant: 'success',
      message: 'успешно',
    }));
  } catch (error) {
    yield put(changeLoadingState(false));
    yield put(changeSnackbarState({
      state: true,
      variant: 'error',
      message: 'ошибка',
    }));
    console.log(error);
  }
}

function* wait(time) {
  yield new Promise((resolve) => setTimeout(resolve, time));
}
