export const validateThatExpirationDateIsNotLessThanVisitDate = (values) => {
  if (values.longterm) {
    const visitDate = new Date(values.visitDate).getTime();
    const expirationDate = new Date(values.expirationDate).getTime();
    if (expirationDate && (expirationDate < visitDate)) {
      return { errorHelperText: 'Значение поля "Дата окончания" не может быть меньше чем значение поля "Дата"' };
    }
  }

  return null;
};
