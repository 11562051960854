/*
 *
 * NotificationsPageContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';

import NotificationsPage from './NotificationsPage';

class NotificationsPageContainer extends React.PureComponent {
  render() {
    return (
      <NotificationsPage {...this.props} />
    );
  }
}

NotificationsPageContainer.propTypes = {};

export default connect()(NotificationsPageContainer);

