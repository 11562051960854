import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Replay from '@material-ui/icons/Replay';
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => ({
  root: {
    display: 'flex',
    height: 35,
    width: 180,
  },
  input: {
    width: '100%',
    fontSize: 14,
    height: 33,
  },
  resetButton: {
    height: 25,
    width: 25,
  },
  clearButton: {
    height: 20,
    marginBottom: 3,
    marginRight: 3,
    width: 20,
  },
  searchButton: {
    height: 25,
    marginRight: 3,
    width: 25,
  },
});

class SearchPanelBase extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    clearTooltipTitle: PropTypes.string,
    listRef: PropTypes.object.isRequired,
    loadData: PropTypes.func.isRequired,
    onChangeSearchQuery: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    searchQuery: PropTypes.string,
    searchTooltipTitle: PropTypes.string,
    updateTooltipTitle: PropTypes.string,
  };

  static defaultProps = {
    clearTooltipTitle: '',
    placeholder: 'Поиск...',
    searchTooltipTitle: '',
    updateTooltipTitle: '',
  };

  _isMounted;

  constructor(props) {
    super(props);

    this.state = {
      searchValue: props.searchQuery || '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleScrollToTop = () => {
    const { listRef } = this.props;
    if (listRef && listRef.current) listRef.current.scrollToItem(0);
  };

  handleSearchButtonClick = () => {
    const { searchValue } = this.state;
    const { onChangeSearchQuery, loadData } = this.props;
    let { searchQuery } = this.props;

    if (searchQuery == null) searchQuery = '';

    if (searchValue.trim() === searchQuery.trim()) return;
    if (searchValue && this._isMounted) {
      this.handleScrollToTop();
      onChangeSearchQuery(searchValue);
      loadData();
    }
  };

  handleResetButtonClick = () => {
    const { onChangeSearchQuery, loadData } = this.props;
    const { searchValue } = this.state;

    if (this._isMounted) {
      this.handleScrollToTop();
      onChangeSearchQuery(searchValue);
      loadData();
    }
  };

  handleClearButtonClick = () => {
    const { onChangeSearchQuery, searchQuery, loadData } = this.props;

    if (searchQuery) {
      onChangeSearchQuery(null);
      loadData();
    }

    this.setState({ searchValue: '' });
  };

  handleChange = (event) => {
    const searchValue = event.target.value;
    this.setState({ searchValue });
  };

  handleKeyPress = (event) => {
    const { searchValue } = this.state;
    const { onChangeSearchQuery, loadData } = this.props;
    let { searchQuery } = this.props;

    if (searchQuery == null) searchQuery = '';

    if (event.key === 'Enter') {
      if (searchValue.trim() === searchQuery.trim()) return;
      if (searchValue) {
        this.handleScrollToTop();
        onChangeSearchQuery(searchValue);
        loadData();
      }
    }
  };

  render() {
    const {
      classes,
      clearTooltipTitle,
      placeholder,
      searchTooltipTitle,
      updateTooltipTitle,
    } = this.props;
    const {
      searchValue,
    } = this.state;

    return (
      <div className={classes.root}>
        <Input
          className={classes.input}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          placeholder={placeholder}
          value={searchValue}
          startAdornment={
            <InputAdornment position="end">
              <Tooltip
                id="search-icon-tooltip"
                title={searchTooltipTitle}
                placement={'bottom'}
              >
                <IconButton
                  className={classes.searchButton}
                  aria-label="Search icon"
                  onClick={this.handleSearchButtonClick}
                >
                  <Search />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Fade
                in={searchValue !== ''}
                style={{
                  transitionDelay: searchValue ? '300ms' : '0ms',
                  zIndex: 1000,
                }}
              >
                { searchValue !== '' ?
                  <div>
                    <Tooltip
                      id="clear-icon-tooltip"
                      title={clearTooltipTitle}
                      placement={'bottom'}
                    >
                      <IconButton
                        className={classes.clearButton}
                        aria-label="Clear value icon"
                        onClick={this.handleClearButtonClick}
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </div>
                  :
                  <IconButton
                    className={classes.clearButton}
                    aria-label="Clear value icon"
                    onClick={this.handleClearButtonClick}
                    disabled={searchValue === ''}
                  >
                    <Clear />
                  </IconButton>
                }
              </Fade>
              <Tooltip
                id="update-icon-tooltip"
                title={updateTooltipTitle}
                placement={'bottom'}
              >
                <IconButton
                  className={classes.resetButton}
                  aria-label="Reset icon"
                  onClick={this.handleResetButtonClick}
                >
                  <Replay />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </div>
    );
  }
}

export const SearchPanel = withStyles(styles, { name: 'SearchPanel' })(SearchPanelBase);
