/**
 *
 * SettingsPage sagas
 *
 */

import { fork, select } from 'redux-saga/effects';
import { makeSelectAccountId, makeSelectRoles } from 'domain/User/selectors';
import { createViewSaga } from './viewConfigs';

export default function* rootSaga() {
  const accountId = yield select(makeSelectAccountId());
  const userRoles = yield select(makeSelectRoles());
  const viewSaga = createViewSaga(userRoles, accountId);

  yield fork(viewSaga);
}
