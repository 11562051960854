/**
 *
 * AgreementDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { SelectField } from './SelectTextField';
import { EMPLOYEES } from 'domain/Data/constants';


const selectStyles = (theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  updateIcon: {
    height: 32,
    width: 32,
  },
});

class AgreementDialog extends React.PureComponent {

  handleSubmitButtonClick = () => {
    const { id, managers, onSubmit } = this.props;
    if (onSubmit) onSubmit(id, managers);
  };

  handleCancelButtonClick = () => this.props.onCancel();

  handleUpdateList = () => {
    this.props.loadOptions(EMPLOYEES)
  }

  render() {
    const {
      open,
      managers,
      managerOptions,
      setManagers,
      classes,
    } = this.props;

    return (
      <div>
        <Dialog
          open={open}
        >
          <DialogTitle>
            <div className={classes.dialogTitle}>
              <span>Согласование заявки</span>
              <Tooltip
                id="tooltip-fab"
                placement="bottom"
                title={"Обновить список"}
              >
                <IconButton
                  aria-label="Update List"
                  className={classes.updateIcon}
                  onClick={this.handleUpdateList}
                >
                  <AutorenewIcon />
                </IconButton>
              </Tooltip>
            </div>
          </DialogTitle>
          <DialogContent>
            <form>
              <SelectField
                name="manager"
                label="Менеджер"
                options={managerOptions}
                value={managers}
                required
                onChange={setManagers}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSubmitButtonClick}
              disabled={managers.length === 0}
              color="primary"
              autoFocus
            >
              Сохранить и Отправить
            </Button>
            <Button onClick={this.handleCancelButtonClick} color="primary">
              Отмена
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  static propTypes = {
    managers: PropTypes.array,
    managerOptions: PropTypes.array,
    setManagers: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    loadOptions: PropTypes.func,
  };
}

export default AgreementDialog = withStyles(selectStyles)(AgreementDialog);
