/**
 *
 * SupplyRequestForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import * as DocumentState from 'domain/Data/DocumentState/constants';
import * as DocumentType from 'domain/Data/DocumentType/constants';
import {SUPPLY_REQUEST_FORM} from './constants';
import {DOCUMENTS} from 'domain/Data/constants';
import {addActionButton} from "../common/mobile/buttons";

class SupplyRequestForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    getFields() {
        const currentDate = new Date();

        return [
            {
                name: 'autoNumber',
                label: 'Номер документа',
                visible: (currentValues, initialValues, mode) => mode !== 'add',
                disabled: true,
            },
            {
                name: 'actualCreationDate',
                label: 'Дата',
                type: 'date',
                visible: false,
                disabled: true,
                initialValue: (values, mode) => {
                    if (mode === 'add') {
                        return currentDate;
                    }
                    return values.actualCreationDate;
                },
            },
            {
                name: 'author',
                type: 'select',
                label: 'Автор',
                entityName: 'employees',
                visible: false,
                initialValue: (values, mode, user) => user.id,
                disabled: true,
            },
            {
                name: 'state',
                type: 'select',
                label: 'Статус',
                entityName: 'documentStates',
                visible: false,
                initialValue: (values, mode) => {
                    if (mode === 'add') return DocumentState.ON_CREATED;
                    return values.state;
                },
                disabled: true,
            },
            {
                name: 'category',
                visible: false,
                initialValue: (values, mode) => {
                    if (mode === 'add') return DocumentType.REQUEST;
                    return values.state;
                },
            },
            {
                name: 'objectStructure',
                label: 'Объект',
                visible: true,
                type: 'select',
                entityName: 'objectStructures',
            },
            {
                name: 'contract',
                type: 'select',
                entityName: 'contracts',
                label: 'Договор',
                visible: false,
            },
            {
                name: 'agreementScheme',
                type: 'comboBox',
                entityName: 'agreementSchemes',
                label: 'Вид согласования',
                visible: true,
            },
            {
                name: 'scan',
                label: 'Файл',
                type: 'file',
                visible: true,
            },
            {
                name: 'content',
                label: 'Содержание',
                visible: true,
                grid: {sm: 12},
            },
            {
                name: 'positions',
                label: 'Позиции',
                type: 'table',
                entityName: 'documents',
                serviceName: 'SupplyRequestsPositions',
                filterBy: (currentValues) => currentValues.project ? `project.id.contains("${currentValues.project}")` : null,
                filterEntityName: 'documentPositions',
                filter: (currentValues) => currentValues.id ? `document.id.contains("${currentValues.id}")` : null,
                actionButtons: [addActionButton],
                grid: {sm: 12},
            }
        ];
    }

    getClassNames() {
        return {
            helperText: '',
        };
    }

    render() {
        return (
            <AdvancedForm
                name={SUPPLY_REQUEST_FORM}
                panelName='Информация'
                entityName={DOCUMENTS}
                fields={this.getFields()}
                classNames={this.getClassNames()}
                {...this.props}
            />
        );
    }
}

export default SupplyRequestForm;