/*
 *
 * ChangePasswordForm sagas
 *
 */

import { take, put, select, takeLatest } from 'redux-saga/effects';
import {
  startSubmit,
  stopSubmit,
  touch,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';

import * as userActionCreators from 'domain/User/actions';
import { CHANGE_PASSWORD_ERROR } from 'domain/User/constants';

import { SUBMIT } from './constants';
const CHANGE_PASSWORD_FORM_NAME = 'changePasswordForm';

export default function* rootSaga() {
  yield takeLatest(SUBMIT, submitChangePasswordFormSaga);
}

function* submitChangePasswordFormSaga({ payload: { nextPathAfterLogin } }) {
  yield put(touch(CHANGE_PASSWORD_FORM_NAME, 'oldPassword', 'newPassword', 'newPasswordConfirmation'));
  const syncErrors = yield select(getFormSyncErrors(CHANGE_PASSWORD_FORM_NAME, (state) => state.get('forms')));
  if (syncErrors.oldPassword || syncErrors.newPassword || syncErrors.newPasswordConfirmation) return;

  const formValues = yield select(getFormValues(CHANGE_PASSWORD_FORM_NAME, (state) => state.get('forms')));
  const { oldPassword, newPassword, newPasswordConfirmation } = formValues.toJS();
  yield put(startSubmit(CHANGE_PASSWORD_FORM_NAME));
  yield put(userActionCreators.changePassword(oldPassword, newPassword, newPasswordConfirmation, nextPathAfterLogin));
  const { payload: { error } } = yield take(CHANGE_PASSWORD_ERROR);

  yield put(stopSubmit(CHANGE_PASSWORD_FORM_NAME, error.data || { _error: error.message }));
}
