export function changeFavicon(favicon) {
  if (!favicon) {
    console.err('Error in parameter favicon');
    return;
  }
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = favicon;
  document.getElementsByTagName('head')[0].appendChild(link);
}
