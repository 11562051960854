/**
 *
 * Global constants
 *
 */

/* ===================================
        localStorage constants
==================================== */
export const GLOBAL_USER_KEY = 'claris-vnext-global-user';
export const ACCOUNT_ID_KEY = 'claris-vnext-account-id';
export const BUILD_VERSION_KEY = 'claris-vnext-version';
export const UPDATE_BY_SIGNALR = 'updateBySignalR';
