import { OrderedMap } from 'immutable';

export function recordsFromArray(RecordType, array, keyProperty = 'id') {
  return array.reduce((acc, el) => acc.set(el[keyProperty], new RecordType(el)), new OrderedMap({}));
}

export function keyFromValue(value) {
  if (Array.isArray(value)) {
    return value.join('->');
  } else {
    return value;
  }
}
