import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField/index';

import { columnType } from 'domain/typeConstants/table';
import { filterOperation as op, settings } from '../dxGridTypes';
import SelectTextFilterEditor from './SelectTextFilter';
import { getValueFromLocalStorage, setValueToLocalStorage } from 'utils/localStorage';
import { convertDatetoString } from 'utils/dateUtils';
import TextFilterEditor from './TextFilter';

const styles = (theme) => ({
  paper: {
    margin: '48px',
    display: 'flex',
    outline: 'none',
    position: 'relative',
    flexDirection: 'column',
    overflowY: 'initial',
  },
  inputRoot: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.1875em',
    minWidth: '150px',
  },
  buttonRoot: {
    padding: '8px 8px',
    color: '#217f90',
  },
  dialogContent: {
    padding: '0px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    height: '32px',
  },
  textField: {
    marginLeft: '6px',
    maxWidth: '150px',
    fontSize: '14px',
  },
  booleanField: {
    marginLeft: '6px',
    width: '136px',
    fontSize: '14px',
  },
  numericField: {
    marginLeft: '6px',
    width: '136px',
    fontSize: '14px',
    paddingTop: '1px',
  },
  dateRange: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  filterField: {
    marginRight: '12px',
    marginLeft: '6px',
    minWidth: '228px',
  },
  savedFilterList: {
    marginTop: 'auto',
    padding: '10px'
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '6px',
    paddingRight: '6px',
    height: '32px',
    fontSize: '14px',
  },
  condition: {
    paddingTop: '6px',
    height: '32px',
  },
  outputValue: {
    height: '32px',
    paddingTop: '6px',
  },
})

class FilterListDialog extends React.Component {

  constructor(props) {
    super(props);

    this.options = {
      [columnType.DATE]: [op.DATE_RANGE, op.ONE_DAY],
      [columnType.DATEFULLTIME]: [op.DATE_RANGE, op.ONE_DAY],
      [columnType.DATETIME]: [op.DATE_RANGE, op.ONE_DAY],
      [columnType.NUMERIC]: [op.EQUAL, op.NOT_EQUAL, op.GREATER_THAN, op.GREATER_THAN_OR_EQUAL, op.LESS_THAN, op.LESS_THAN_OR_EQUAL],
      [columnType.BOOLEAN]: [op.EQUAL],
      [columnType.TEXT]: [op.CONTAINS, op.NOT_CONTAINS],
    }
    this.dayMilliseconds = 24 * 60 * 60 * 1000;
  }

  state = {
    filters: [],
    isOpenSaveDialog: false,
    filterName: '',
    selectedFilter: '',
  };

  componentDidMount() {
    this.updateFilters()
  }

  componentDidUpdate() {
    const { filterDialog, clearFilterDialog } = this.props;
    if (filterDialog === true) {
      this.updateFilters()
      clearFilterDialog(false)
    }
  }

  updateFilters() {
    const { localFilters, gridFilters } = this.props;
    if (gridFilters.length === 0) {
      return this.setState({ filters: localFilters, selectedFilter: '' })
    }
    const filters = localFilters.map(filter => {
      const gridFilter = gridFilters.find(g => g.columnName === filter.columnName)
      if (gridFilter) {
        filter.outputValue = gridFilter.value
        if (gridFilter.operation !== undefined) {
          filter.operation = gridFilter.operation
        }
      }
      return filter
    })
    this.setState({ filters, selectedFilter: '' });
  }

  correctFilters = () => {
    const { filters } = this.state;
    const newFilters = filters.map(filter => {
      if (filter.operation === op.DATE_RANGE && filter.outputValue !== "") {
        if (isNaN(filter.outputValue.fromValue) && isNaN(filter.outputValue.toValue)) {
          return { ...filter, outputValue: '' }
        }
        const outputValue = {};
        if (filter.outputValue.fromValue && !isNaN(filter.outputValue.fromValue)) {
          outputValue.fromValue = filter.outputValue.fromValue
        }
        if (filter.outputValue.toValue && !isNaN(filter.outputValue.toValue)) {
          outputValue.toValue = filter.outputValue.toValue
        }
        return { ...filter, outputValue }
      }
      else return filter
    })
    this.setState({ filters: newFilters })
    return newFilters
  }

  updateFilterInLocalStorage = (accessKey, filters) => {
    const { configName } = this.props
    let localStorageConfig = getValueFromLocalStorage(configName);
    if (accessKey === undefined) return null;
    localStorageConfig[accessKey][settings.CUSTOM_FILTERING].value = filters;
    setValueToLocalStorage(configName, localStorageConfig);
  }

  updateSavedFiltersInLocalStorage = (accessKey, savedFilters) => {
    if (accessKey === undefined) return null;
    const { configName } = this.props
    let localStorageConfig = getValueFromLocalStorage(configName);
    if (localStorageConfig[accessKey][settings.SAVED_FILTERS] === undefined) {
      return null
    } else {
      localStorageConfig[accessKey][settings.SAVED_FILTERS] = savedFilters;
      setValueToLocalStorage(configName, localStorageConfig);
    }
  }

  renderDialogTitle = (title) => {
    const { classes, savedFilters } = this.props

    const handleChangeSelectedFilter = (e) => {
      const { savedFilters } = this.props
      const savedFilter = savedFilters.find(f => f.name === e.target.value).value
      this.setState({
        selectedFilter: e.target.value,
        filters: savedFilter
      })
    }

    return (
      <div className={classes.dialogTitle}>
        <DialogTitle>{title}</DialogTitle>
        <Grid className={classes.savedFilterList}>
          <TextField
            value={this.state.selectedFilter}
            id={"filterName"}
            select
            className={classes.filterField}
            onChange={handleChangeSelectedFilter}
            label={"сохраненные фильтры"}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {savedFilters.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))
            }
          </TextField>
        </Grid>
      </div>
    );
  }

  renderItemContent = () => {
    const { classes, messages } = this.props;

    const renderCondition = (filter) => {
      const optionsType = this.options[filter.type] || this.options['text'];

      const handleChangeCondition = (e) => {
        const newFilters = this.state.filters.map(filter => {
          if (filter.columnName === e.target.name) {
            return { ...filter, operation: e.target.value }
          } else return filter
        })
        this.setState({ filters: newFilters })
      }

      return (
        <TextField
          value={filter.operation}
          name={filter.columnName}
          select
          onChange={handleChangeCondition}
          InputProps={{ classes: { root: classes.inputRoot } }}
        >
          {
            optionsType.map((option) => (
              <MenuItem key={option} value={option}>
                {messages[option]}
              </MenuItem>
            ))
          }
        </TextField>
      )
    }

    const renderOutputValue = (filter) => {
      const { classes } = this.props

      const handleChangeValueClick = (e) => {
        const newFilters = this.state.filters.map(filter => {
          if (filter.columnName === e.target.name) {
            return { ...filter, outputValue: e.target.value }
          } else return filter
        })
        this.setState({ filters: newFilters })
      }

      const handleChangeOneDateRangeClick = (e) => {
        const fromValue = new Date(e.target.value)
        const newFilters = this.state.filters.map(filter => {
          if (filter.columnName === e.target.name) {
            return { ...filter, outputValue: { fromValue } }
          } else return filter
        })
        this.setState({ filters: newFilters })
      }

      const handleChangeDateRangeClick = (e, prefix) => {
        const columnName = e.target.name;
        let date = new Date(e.target.value)
        const newFilters = this.state.filters.map(filter => {
          if (filter.columnName === columnName.substr(0, columnName.length - 1)) {
            return { ...filter, outputValue: { ...filter.outputValue, [prefix]: date } }
          } else return filter
        })
        this.setState({ filters: newFilters })
      }

      const handleChangeSelectClick = (name, value) => {
        const newFilters = this.state.filters.map(filter => {
          if (filter.columnName === name) {
            return { ...filter, outputValue: value }
          } else return filter
        })
        this.setState({ filters: newFilters })
      }

      switch (filter.type) {
        case columnType.NUMERIC:
          return <TextField
            className={classes.numericField}
            name={filter.columnName}
            value={filter.outputValue} type="number"
            InputProps={{ classes: { root: classes.inputRoot } }}
            onChange={handleChangeValueClick}
          />
        case columnType.BOOLEAN:
          return <TextField
            className={classes.booleanField}
            InputProps={{ classes: { root: classes.inputRoot } }}
            name={filter.columnName}
            select
            value={filter.outputValue}
            onChange={handleChangeValueClick}
          >
            <MenuItem value={''}>{""}</MenuItem>
            <MenuItem value={'true'}>{"Да"}</MenuItem>
            <MenuItem value={'false'}>{"Нет"}</MenuItem>
          </TextField>
        case columnType.DATE:
        case columnType.DATEFULLTIME:
        case columnType.DATETIME:
          {
            if (filter.operation === op.ONE_DAY) {
              const from = filter.outputValue?.fromValue || ''
              const fromValue = from && convertDatetoString(from);

              return <TextField
                className={classes.textField}
                name={filter.columnName}
                value={fromValue}
                type="date"
                onChange={handleChangeOneDateRangeClick}
              />
            } else
              if (filter.operation === op.DATE_RANGE) {
                const from = filter.outputValue?.fromValue || ''
                const to = filter.outputValue?.toValue || ''
                const fromValue = from && convertDatetoString(from);
                const toValue = to && convertDatetoString(to);

                return <Grid container className={classes.dateRange} >
                  <Grid item>
                    <TextField
                      className={classes.textField}
                      name={`${filter.columnName}1`}
                      value={fromValue}
                      type="date"
                      onChange={(e) => handleChangeDateRangeClick(e, 'fromValue')}
                    />
                  </Grid>

                  <Grid item>
                    <span style={{ padding: '10px' }}>по</span>
                    <TextField className={classes.textField}
                      name={`${filter.columnName}2`}
                      value={toValue}
                      type="date"
                      onChange={(e) => handleChangeDateRangeClick(e, 'toValue')}
                    />
                  </Grid>
                </Grid>
              }
          }

        default:
          if (filter.entityName) {
            return <SelectTextFilterEditor
              onChange={handleChangeSelectClick}
              filter={filter}
            />
          } else
            return <TextFilterEditor
              onChange={handleChangeValueClick}
              filter={filter}
            />
      }
    }

    return (
      <div style={{ padding: '20px' }}>
        <table>
          <tbody>
            {this.state.filters.map(filter =>
              <tr key={filter.columnName} className={classes.row} >
                <td className={classes.title} >
                  <span> {`${filter.title}:`}</span>
                </td>
                <td className={classes.condition}>
                  {renderCondition(filter)}
                </td>
                <td className={classes.outputValue}>
                  {renderOutputValue(filter)}
                </td>
              </tr>
            )
            }
          </tbody>
        </table>
      </div>
    )
  }

  renderActions = () => {
    const {
      classes,
      onCancel,
    } = this.props;

    const handleSave = () => {
      this.setState({
        isOpenSaveDialog: true,
        filterName: ''
      })
    }

    const handleRemove = () => {
      const { accessKey, savedFilters } = this.props;
      const { selectedFilter, filters } = this.state
      const filterIndex = savedFilters.findIndex((item) => item.name === selectedFilter);
      savedFilters.splice(filterIndex, 1);
      this.updateSavedFiltersInLocalStorage(accessKey, savedFilters)
      const newFilters = filters.map(filter => { return { ...filter, outputValue: '' } })
      this.setState({ selectedFilter: '', filters: newFilters })
    }

    const handleClear = () => {
      const newFilters = this.state.filters.map(filter => { return { ...filter, outputValue: '' } })
      this.setState({ selectedFilter: '', filters: newFilters })
    }

    const equalFilters = (gridFilters, selectedFilter) => {
      const { savedFilters } = this.props;
      const savedFilter = savedFilters.filter(f => f.name === selectedFilter)[0].value
      for (var i = 0; i < gridFilters.length; i++)
        if (
          gridFilters[i].columnName !== savedFilter[i].columnName ||
          gridFilters[i].operation !== savedFilter[i].operation ||
          gridFilters[i].value !== savedFilter[i].outputValue
        ) return false;
      return true;
    }

    const handleSubmit = () => {
      const { onSubmit, accessKey } = this.props;
      const filters = this.correctFilters();
      const gridFilters = filters
        .map(i => {
          return {
            columnName: i.columnName,
            operation: i.operation,
            value: i.outputValue
          }
        })
      this.state.selectedFilter !== '' && !equalFilters(gridFilters, this.state.selectedFilter)
        && this.setState({ selectedFilter: '' })
      this.updateFilterInLocalStorage(accessKey, filters)
      if (onSubmit) onSubmit(gridFilters)
    }

    return (
      <DialogActions>
        <Button className={classes.buttonRoot} onClick={handleSave}>Сохранить</Button>
        <Button className={classes.buttonRoot} onClick={handleRemove}>Удалить</Button>
        <Button className={classes.buttonRoot} onClick={handleClear}>Очистить</Button>
        <Button className={classes.buttonRoot} onClick={handleSubmit}>Применить</Button>
        <Button className={classes.buttonRoot} onClick={onCancel}>Отмена</Button>
      </DialogActions>
    )
  }

  handleSaveFilter = () => {
    const { accessKey, savedFilters } = this.props;
    const { filterName, filters } = this.state;
    savedFilters.push({ name: filterName, value: filters })
    this.updateSavedFiltersInLocalStorage(accessKey, savedFilters)
    this.setState({ isOpenSaveDialog: false })
  }

  handleResetFilter = () => {
    this.setState({ isOpenSaveDialog: false })
  }

  handleChangeFilterName = (e) => {
    this.setState({ filterName: e.target.value })
  }

  render() {
    const { classes, open, onCancel, } = this.props;
    const { isOpenSaveDialog } = this.state;

    return (
      <>
        <Dialog
          onClose={onCancel}
          open={open}
          maxWidth={'md'}
          classes={{
            paper: classes.paper,
          }}
        >
          {this.renderDialogTitle('Настройка фильтра')}
          <DialogContent className={classes.dialogContent}>
            {this.renderItemContent()}
          </DialogContent>
          {this.renderActions()}
        </Dialog>
        <Dialog open={isOpenSaveDialog}
          onClose={this.handleResetFilter}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Введите название фильтра</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="filterName"
              type="text"
              fullWidth
              onChange={this.handleChangeFilterName}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveFilter}>OK</Button>
            <Button onClick={this.handleResetFilter}>Отмена</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(FilterListDialog);
