/**
 *
 * AgreementDialog sagas
 *
 */

import {takeLatest, select, call, put} from 'redux-saga/effects';
import {SUBMIT_AGREEMENT_DIALOG} from './constants';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants';
import {makeSelectEntity, makeSelectEntityData} from 'domain/Data/selectors';
import {AGREEMENT_SCHEMES, DOCUMENTS, DOCUMENT_POSITIONS, ESTIMATE_ITEMS} from 'domain/Data/constants';
import {account} from 'domain/typeConstants/accounts';
import {SUPPLY_REQUEST_DX_GRID} from 'pages/Documents/SupplyRequestView/constants';
import * as api from "utils/api";
import {loadData} from "containers/AdvancedDataTable/actions";
import {SIGHTING_DOCUMENTS_DX_GRID} from "../../SightingView/constants";

export default function* rootSaga() {
    yield takeLatest(SUBMIT_AGREEMENT_DIALOG, submitAgreementDialogSaga);
}

function* submitAgreementDialogSaga({payload: {ids, comment, dxGrid}}) {
    const user = getValueFromLocalStorage(GLOBAL_USER_KEY)
    const startTime = new Date().getTime();
    if (ids.length > 0) {
        for (let i = 0; i < ids.length; i += 1) {
            try {
                // TVK check documentPositions
                if (user.accountId === account.TVK && dxGrid === SUPPLY_REQUEST_DX_GRID) {
                    const document = yield select(makeSelectEntity(DOCUMENTS, ids[i]))
                    if (document.agreementScheme) {
                        const agreementScheme = yield select(makeSelectEntity(AGREEMENT_SCHEMES, document.agreementScheme))
                        if (agreementScheme.lastAgreementStep && document.agreementStep === agreementScheme.lastAgreementStep) {
                            // если текущий шаг последний, то обновляем факты

                            const documentPositionsRecords = yield select(makeSelectEntityData(DOCUMENT_POSITIONS));
                            const estimateItemsRecords = yield select(makeSelectEntityData(ESTIMATE_ITEMS));
                            const estimateItems = Object.values(estimateItemsRecords.toJS())

                            let documentPositions = []
                            if (documentPositionsRecords) {
                                documentPositions = Object.values(documentPositionsRecords.toJS())
                            }

                            for (let j = 0; j < documentPositions.length; j++) {
                                const estimateId = documentPositions[j].estimateItem
                                const count = documentPositions[j].positionsCount

                                if (estimateId && count) {
                                    const estimateItemData = estimateItems.find(i => i.id === estimateId)
                                    const countFact = estimateItemData.countFact + count;
                                    const countAvailable = estimateItemData.countPlan - (estimateItemData.countFact + count)
                                    const countReserv =  estimateItemData.countReserv - count

                                    const changedData = {
                                        countFact,
                                        countAvailable,
                                        countReserv,
                                    }
                                    yield call(api.update, ESTIMATE_ITEMS, estimateId, changedData);
                                }
                            }
                        }
                    }
                }
                yield call(api.agreeDocument, ids[i], comment);

                const endTime = new Date().getTime();
                const result = endTime - startTime;
                if (result < 1000) yield* wait(1000 - result);
                yield put(loadData(dxGrid || SIGHTING_DOCUMENTS_DX_GRID));
            } catch (err) {
                console.error(err);
            }
        }
    }
}

function* wait(time) {
    yield new Promise((resolve) => setTimeout(resolve, time));
}
