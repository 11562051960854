/**
 *
 * getSettingsPage Function
 *
 */

import React from 'react';
import { account } from 'domain/typeConstants/accounts';
import { SettingsPage14326 } from 'custom/14326/pages/Settings';
import SettingsPage from './SettingsPage';


export function getSettingsPage(accountId, props) {
  switch (accountId) {

    case account.BC_KHIMKI:
      return (
        <SettingsPage14326 {...props} />
      );

    default:
      return (
        <SettingsPage {...props} />
      );
  }
}
