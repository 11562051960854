/*
 *
 * RequestsPage14140 sagas
 *
 */

import { fork } from 'redux-saga/effects';

import receptionViewSaga from './ReceptionView/sagas';

export default function* rootSaga() {
  yield fork(receptionViewSaga);
}
