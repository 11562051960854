/**
 *
 * SupplyRequestPositionForm15767
 *
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {ON_CREATED, ON_REJECTED} from 'domain/Data/DocumentPositionState/constants';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants';
import {DOCUMENT_POSITION_FORM} from 'pages/Positions/PositionsView/constants';


export class SupplyRequestPositionForm15767 extends React.PureComponent {

    getFields() {
        const parentId = this.props.parentId;
        const projectId = this.props.supplyRequestFormValues.project;

        return [
            {
                name: 'state',
                label: 'Статус',
                entityName: 'documentPositionStates',
                visible: false,
                initialValue: (values, mode) => {
                    if (mode === 'add' || mode === 'copy') return ON_CREATED;
                    return values.state;
                },
            },
            {
                name: 'document',
                type: 'comboBox',
                label: 'Документ',
                entityName: 'documents',
                visible: false,
                initialValue: (values, mode) => mode === 'add' ? parentId : values.document?.id
            },
            {
                name: 'estimateItem',
                type: 'autocomplete',
                entityName: 'estimateItems',
                filterBy: `project.id.contains("${projectId}")`,
                label: 'ЛВЗ',
                required: true,
            },
            {
                name: 'available',
                label: 'Доступно',
                disabled: true,
                calculatedValue: (values, mode, entities) => {
                    if (values.estimateItem) {
                        const items = entities.get('estimateItems')?.valueSeq()?.toJS();
                        if (items) {
                            return items.find(i => i.id === values.estimateItem)?.available
                        }
                    }
                }
            },
            {
                name: 'assetText',
                label: 'ТМЦ текстом',
                disabled: (values, mode) => {
                    return mode === 'edit' && !([ON_CREATED, ON_REJECTED].includes(values.state))
                },
                grid: {sm: 12},
            },
            {
                name: 'positionsCount',
                label: 'Количество',
                required: true,
            },
            {
                name: 'received',
                label: 'Получено',
            },
        ];
    }

    getClassNames() {
        return {
            helperText: '',
        };
    }

    render() {
        return (
            <AdvancedForm
                name={DOCUMENT_POSITION_FORM}
                entityName="documentPositions"
                classNames={this.getClassNames()}
                fields={this.getFields()}
                emptyPlace
                accountId={getValueFromLocalStorage(GLOBAL_USER_KEY).accountId}
                isDetail={false}
                {...this.props}
            />
        );

    }
}

