/**
 *
 * VisitorArrivalForm12970
 *
 */

import { VisitorArrivalForm } from 'pages/Requests/ReceptionView';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  SERVICE,
} from 'domain/Data/RequestType/constants';

class VisitorArrivalForm12970 extends VisitorArrivalForm {
  getFields() {
    const fields = super.getFields();

    const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
    const companyIndex = fields.findIndex((field) => field.name === 'company');
    const documentTypeIndex = fields.findIndex((field) => field.name === 'documentType');
    const documentDetailsIndex = fields.findIndex((field) => field.name === 'documentDetails');

    fields[passNumberIndex] = {
      name: 'accessCard',
      type: 'autocomplete',
      label: 'Карта доступа',
      entityName: 'accessCards',
      visible: (values) => [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type),
      filters: ['AccessCardsOfHousing'],
      filterBy: (values) => {
        if (values.housing) {
          return `housings != null && housings.Contains("${values.housing}")`;
        }
        return null;
      },
      initialValue: (values, mode) => {
        if (mode === 'copy') return null;
        return values.accessCard;
      },
      rowBreakBefore: true,
    };

    fields[documentTypeIndex].visible = (values) => values.type !== SERVICE;
    fields[documentDetailsIndex].visible = (values) => values.type !== SERVICE;

    fields.splice(passNumberIndex + 1, 0,
      {
        name: 'housing',
        label: 'Корпус',
        type: 'select',
        entityName: 'housings',
        orderBy: 'name',
        visible: (values) => ([ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type)),
        required: (values) => (values.type === ON_VISIT ||
          ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type) && !!values.visitorFullName)),
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          if (currentValues.company) {
            const company = entities
              .get('companies')
              .get(currentValues.company);
            return company ? company.get('housings') || [] : [];
          }

          return [];
        },
        calculatedValue: (values) => {
          if (values.type === ON_VISIT ||
            ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type) && values.visitorFullName)) {
            return values.housing;
          }

          return null;
        },
        disabled: (currentValues) => ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(currentValues.type) && !(currentValues.visitorFullName)),
      },
    );
    fields.splice(companyIndex + 1, 0,
      {
        name: 'floor',
        type: 'number',
        label: 'Этаж',
        visible: true,
      },
    );

    return fields;
  }
}

export default VisitorArrivalForm12970;
