/**
 *
 * AdministratorBC Requests DxGrid
 *
 */

import {
    ACTIVE_REQUESTS,
    CURRENT_DAY_REQUESTS,
    ON_CREATE_REQUESTS,
    ON_MATCHING_REQUESTS,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT,
    REQUESTS_ON_WORK_PRODUCTION,
} from 'domain/Data/Filters/constants';
import ReceptionDxGrid from '../ReceptionView/ReceptionDxGrid';


export class AdministratorBCDxGrid extends ReceptionDxGrid {

    getFilters() {
        const { viewName } = this.props;
        const filters = [];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'agree') filters.push(ON_MATCHING_REQUESTS);
        return filters;
    }

    getColumns() {
        return super.getColumns();
    }

    getColumnWidths() {
        return super.getColumnWidths();
    }

    getColumnOrder() {
        return super.getColumnOrder();
    }

    getGridFilters() {
        return super.getGridFilters();
    }
}