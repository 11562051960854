/*
 *
 * VisitorArrivalForm11994
 *
 */

import { VisitorArrivalForm } from 'pages/Requests/ReceptionView';

class VisitorArrivalForm11994 extends VisitorArrivalForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
    const companyIndex = fields.findIndex((field) => field.name === 'company');

    fields[passNumberIndex] = {
      name: 'accessCard',
      type: 'autocomplete',
      label: 'Карта доступа',
      entityName: 'accessCards',
    };

    fields[companyIndex].type = 'autocomplete';

    return fields;
  }
}

export default VisitorArrivalForm11994;
