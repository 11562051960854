/**
 *
 * SupplyRequestView constants
 *
 */

export const SUPPLY_REQUEST_AGREEMENT_FORM_DIALOG = 'SupplyRequestAgreementFormDialog';
export const SUPPLY_REQUEST_DISAGREEMENT_FORM_DIALOG = 'SupplyRequestDisagreementFormDialog';
export const SUPPLY_REQUEST_DX_GRID = 'SupplyRequestsDxGrid';
export const SUPPLY_REQUEST_FORM = 'SupplyRequestsForm';
export const SUPPLY_REQUEST_FORM_DIALOG = 'SupplyRequestsFormDialog';
export const SUPPLY_REQUEST_POSITION_FORM_DIALOG = 'SupplyRequestPositionFormDialog';
export const EXECUTE_AGREE_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_AGREE_ACTION';
export const EXECUTE_PRINT_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_PRINT_ACTION';
export const EXECUTE_DISAGREE_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_DISAGREE_ACTION';
export const EXECUTE_RUN_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_RUN_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ADD_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_EDIT_ACTION';
export const EXECUTE_RUN_DIALOG_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_RUN_DIALOG_ACTION';
export const EXECUTE_AGREE_DIALOG_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_AGREE_DIALOG_ACTION';
export const EXECUTE_DISAGREE_DIALOG_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_DISAGREE_DIALOG_ACTION';
export const EXECUTE_POSITION_ADD_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_POSITION_ADD_ACTION';
export const EXECUTE_POSITION_EDIT_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_POSITION_EDIT_ACTION';
export const EXECUTE_POSITION_VIEW_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_POSITION_VIEW_ACTION';
export const EXECUTE_POSITION_COPY_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_POSITION_COPY_ACTION';
export const EXECUTE_ADD_NEW_ACTION = 'claris-vnext/DocumentsPage/SupplyRequestView/EXECUTE_ADD_NEW_ACTION';

