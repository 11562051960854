/*
 *
 * SelectGridField actions
 *
 */

import { EXECUTE_EDIT_ACTION } from './constants';

export function executeEditAction(entityName, recordId) {
  return {
    type: EXECUTE_EDIT_ACTION,
    payload: { entityName, recordId },
  };
}
