/**
 *
 * AgreementDialog actions
 *
 */

import {
  CLOSE_AGREEMENT_DIALOG,
  OPEN_AGREEMENT_DIALOG,
  SUBMIT_AGREEMENT_DIALOG,
} from './constants';

export function openAgreementDialog(ids, dxGrid) {
  return {
    type: OPEN_AGREEMENT_DIALOG,
    payload: { ids, dxGrid },
  };
}

export function submitAgreementDialog(ids, comment, dxGrid) {
  return {
    type: SUBMIT_AGREEMENT_DIALOG,
    payload: {
      ids,
      comment,
      dxGrid,
    },
  };
}

export function closeAgreementDialog() {
  return {
    type: CLOSE_AGREEMENT_DIALOG,
  };
}
