/**
 *
 * Parking14326 constants
 *
 */

export const F_UNDERGROUND_PARKING = '4016242730000';
export const D_PARKING = '4016242725000';
export const D_DETAIL_PARKING = '4063239747000';
export const B_PARKING = '4016242708000';
