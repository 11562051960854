/*
 *
 * CheckpointForm11994
 *
 */

import { CheckpointForm } from 'pages/Requests/CheckpointView';
import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';
import { ON_IMPORT_CARGO, ON_EXPORT_CARGO } from 'custom/11994/domain/Data/RequestType/constants';

class CheckpointForm11994 extends CheckpointForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    const carNumberIndex = fields.findIndex((field) => field.name === 'carNumber');
    fields[carNumberIndex].visible = (values) => values.type === ON_CAR_ENTRY ||
      values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO;

    const carModelTextIndex = fields.findIndex((field) => field.name === 'carModelText');
    fields[carModelTextIndex].visible = (values) => values.type === ON_CAR_ENTRY ||
      values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO;

    const parkingIndex = fields.findIndex((field) => field.name === 'parking');
    fields[parkingIndex].visible = (values) => values.type === ON_CAR_ENTRY ||
      values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO;

    const materialValuesDataIndex = fields.findIndex((field) => field.name === 'materialValuesData');
    fields[materialValuesDataIndex].visible = (values) => values.type === ON_IMPORT_CARGO ||
      values.type === ON_EXPORT_CARGO;

    const companyNameIndex = fields.findIndex((field) => field.name === 'companyName');
    fields[companyNameIndex].visible = (values) => values.type === ON_IMPORT_CARGO ||
      values.type === ON_EXPORT_CARGO;


    return fields;
  }
}

export default CheckpointForm11994;
