/**
 *
 * TaskMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import { columnType } from 'domain/typeConstants/table';
import { ACTIVE_TASKS, USER_AUTHOR_TASK, USER_RESPONSIBLE_TASK, TASKS_FOR_USER_DEPARTMENT } from 'domain/Data/Filters/constants';
import { USER_TASK_TABLE_GRID } from './constants';

import {
  addActionButton,
} from '../common/actionButton';
import {
  editActionItem,
  viewActionItem,
} from '../common/actionMenu';
import { RowComponent, getRowHeight } from '../common/mobile';

import { columnStatusLabels } from '../common/columnStatusLabel';

class TaskMobileGrid extends React.PureComponent {

  getFilters() {
    const { viewName } = this.props;
    let filters = [TASKS_FOR_USER_DEPARTMENT];
    if (viewName === 'activeTask') filters.push(ACTIVE_TASKS);
    if (viewName === 'userAuthorTasks') filters = [USER_AUTHOR_TASK];
    if (viewName === 'userResponsibleTasks') filters = [USER_RESPONSIBLE_TASK];
    return filters;
  }

  getDetailRowFields() {
    return [
      [
        {
          name: 'status',
          path: ['status', 'statusZadachi'],
          getValue: (row) => (row.status ? row.status.statusZadachi : undefined),
          title: 'Статус',
          labels: columnStatusLabels,
          type: columnType.LABEL,
        },
      ],
      {
        name: 'responsible',
        path: ['responsible', 'name'],
        getValue: (row) => (row.responsible ? row.responsible.name : undefined),
        title: 'Ответственный',
      },
      {
        name: 'company',
        path: ['company', 'name'],
        getValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Контрагент',
      },
      {
        name: 'finishDate',
        path: 'finishDate',
        title: 'Завершить к',
        type: columnType.DATE,
      },
      {
        name: 'description',
        path: 'description',
        title: 'Что сделать',
        type: 'text',
      },
      {
        name: 'lastAction',
        path: 'lastAction',
        title: 'Отчет о выполнении',
        type: 'text',
      },
    ];
  }

  getActionButtons() {
    return [addActionButton];
  }

  getActionMenuItems() {
    return [
      viewActionItem,
      editActionItem,
    ];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="tasks"
        name={USER_TASK_TABLE_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        detailRowFields={this.getDetailRowFields()}
        filters={this.getFilters()}
        getRowHeight={getRowHeight}
        onActionExecuting={onActionExecuting}
        rowComponent={RowComponent}
        sorting={this.getSorting()}
        viewName={viewName}
      />
    );
  }
}

TaskMobileGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  viewName: PropTypes.string,
};

export default TaskMobileGrid;
