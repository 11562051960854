/**
 * withPrivateRoot
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import AppBarContainer from 'containers/AppBar';
import BreadcrumbContainer from 'containers/Breadcrumb';
import NavigationDrawerContainer from 'containers/NavigationDrawer';
import PageContentContainer from 'containers/PageContent';
import SnackbarContainer from 'containers/Snackbar';
import AlertDialogContainer from 'containers/AlertDialog';
import ChangeRoleDialogContainer from 'containers/ChangeRoleDialog';
import OneSignalContainer from 'containers/OneSignal';
import { EmailSubscriptionButton } from 'containers/EmailSubscription';

import { getValueFromLocalStorage } from 'utils/localStorage';

import { ACCOUNT_ID_KEY } from 'global-constants';
import withRootWrapper from './withRootWrapper';
import withAuthorization from './withAuthorization';
import withForcePasswordChange from './withForcePasswordChange';

const styleSheet = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
};

function withPrivateRoot(Component) {
  const accountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);

  function WithPrivateRoot({ classes, ...restProps }) {
    return (
      <div className={classes.root}>
        <AppBarContainer>
          <BreadcrumbContainer
            location={restProps.location}
          />
        </AppBarContainer>
        <NavigationDrawerContainer
          location={restProps.location}
        />
        <PageContentContainer>
          <Component {...restProps} />
        </PageContentContainer>
        <SnackbarContainer />
        <AlertDialogContainer />
        <ChangeRoleDialogContainer />
        <OneSignalContainer />
        <EmailSubscriptionButton />
      </div>
    );
  }

  WithPrivateRoot.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    location: PropTypes.object.isRequired,
  };

  return withAuthorization(
    withForcePasswordChange(
      withRootWrapper({
        component: withStyles(styleSheet)(WithPrivateRoot),
        accountId,
      })
    )
  );
}

export default withPrivateRoot;
