/*
 *
 * RequestsPage14691 sagas config
 *
 */

import { administratorBCViewSaga } from './AdministratorBCView/sagas';
import { receptionViewSaga } from './ReceptionView/sagas';

const requestsPageSagasConfig14691 = {
  administratorBCViewSaga,
  receptionViewSaga,
};

export default requestsPageSagasConfig14691;
