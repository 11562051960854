/**
 *
 * CheckpointView12924 sagas
 *
 */

import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';
import { ON_TERRITORY, ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { makeSelectEntityId } from 'containers/AdvancedFormDialog/selectors';
import { closeDialog } from 'containers/AdvancedFormDialog/actions';
import { CHECKPOINT_REQUESTS_DATA_TABLE } from 'pages/Requests/CheckpointView/constants';
import * as snackbarActions from 'containers/Snackbar/actions';
import { SCAN_CAR_NUMBER } from 'features/CarNumberScanner/constants';

import { EXECUTE_OPEN_BARRIER_ACTION } from './constants';
import {
  openBarrier,
  barrierOpened,
  barrierOpeningError,
} from './actions';

export default function* rootSaga() {
  yield takeLatest(SCAN_CAR_NUMBER, scanCarNumberSaga);
  yield takeLatest(EXECUTE_OPEN_BARRIER_ACTION, executeOpenBarrierSaga);
}

function* scanCarNumberSaga({ payload: { carNumber } }) {
  if (carNumber) {
    const searchResult = yield call(searchRequestSaga, carNumber);
    if (searchResult) {
      if (searchResult.length === 0) {
        console.log(`Не найдена заявка с номером машины ${carNumber}`);
        yield put(snackbarActions.open(`Не найдена заявка с номером машины ${carNumber}`, { autoHideDuration: 2500 }));
      } else {
        yield call(openBarrierSaga, searchResult[0].id);
        yield put(loadData(CHECKPOINT_REQUESTS_DATA_TABLE));
        console.log(`Проехала машина ${carNumber}`);
        yield put(snackbarActions.open(`Проехала машина с номером ${carNumber}`, { autoHideDuration: 2500 }));
      }
    }
  }
}

function* searchRequestSaga(carNumber) {
  const cyrillicCarNumber = carNumber
    .replace(/A/gi, 'А')
    .replace(/B/gi, 'В')
    .replace(/E/gi, 'Е')
    .replace(/K/gi, 'К')
    .replace(/M/gi, 'М')
    .replace(/H/gi, 'Н')
    .replace(/O/gi, 'О')
    .replace(/P/gi, 'Р')
    .replace(/C/gi, 'С')
    .replace(/T/gi, 'Т')
    .replace(/Y/gi, 'У')
    .replace(/X/gi, 'Х');

  const latinCarNumber = carNumber
    .replace(/А/gi, 'A')
    .replace(/В/gi, 'B')
    .replace(/Е/gi, 'E')
    .replace(/К/gi, 'K')
    .replace(/М/gi, 'M')
    .replace(/Н/gi, 'H')
    .replace(/О/gi, 'O')
    .replace(/Р/gi, 'P')
    .replace(/С/gi, 'C')
    .replace(/Т/gi, 'T')
    .replace(/У/gi, 'Y')
    .replace(/Х/gi, 'X');

  const response = yield call(api.loadPage, 'requests', {
    filterBy: `(carNumber.Contains("${cyrillicCarNumber}") || ` +
      `carNumber.Contains("${latinCarNumber}")) && ` +
      `(status.id = "${ON_TERRITORY}" || status.id = "${ACCEPTED}")`,
    sorting: [{ columnId: 'actualCreationDate', direction: 'desc' }],
  });
  return response.data;
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(CHECKPOINT_REQUESTS_DATA_TABLE));
}

function* executeOpenBarrierSaga({ meta: { dialogName } }) {
  const requestId = yield select(makeSelectEntityId(dialogName));
  yield call(openBarrierSaga, requestId);
  yield put(closeDialog(dialogName));
  yield put(loadData(CHECKPOINT_REQUESTS_DATA_TABLE));
}

export function* openBarrierSaga(requestId) {
  yield put(openBarrier());

  try {
    yield call(api.openBarrier, requestId);
    yield put(barrierOpened());
  } catch (err) {
    yield put(barrierOpeningError(err));
  }
}

export const checkpointViewSaga = {
  executeNeedViewRefreshSaga,
};
