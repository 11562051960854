/*
 *
 * AdvancedFormDialog sagas
 *
 */

import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';
import { submitFormSaga } from 'containers/AdvancedForm/sagas';
import { closeDialog, dialogSaved, dialogSavingError, changeLoadingState } from './actions';
import { makeSelectFormName } from './selectors';
import { SAVE_DIALOG, OPEN_DIALOG } from './constants';
import { LOAD_DIALOG_DATA } from 'custom/14326/pages/Requests/ReceptionView/constants';
import { changeDialogDataState } from 'domain/Data/actions';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

export default function* rootSaga() {
  yield takeLatest(SAVE_DIALOG, function* (action) { yield call(saveDialogSaga, action.meta.dialog) });
  yield takeLatest(LOAD_DIALOG_DATA, function* (action) { yield call(loadDialogDataSaga, action.payload) });
  yield takeLatest(OPEN_DIALOG, function* () { yield checkUserForBlockingSaga() });
}

export function* saveDialogSaga(dialogName) {
  let result = null;
  try {
    yield checkUserForBlockingSaga()
    yield put(changeLoadingState(dialogName, true));
    const formName = yield select(makeSelectFormName(dialogName));
    result = yield call(submitFormSaga, formName);
    yield put(dialogSaved(dialogName));
    yield put(changeLoadingState(dialogName, false));
    yield put(closeDialog(dialogName));
  } catch (err) {
    yield put(changeLoadingState(dialogName, false));
    yield put(dialogSavingError(dialogName, err));
  }
  return result;
}

export function* loadDialogDataSaga(payload) {
  try {
    let response = yield call(api.load, payload.entityName, payload.id)
    yield put(changeDialogDataState(payload.entityName, [response.data]));
  }
  catch (err) {
    console.log("error", err)
  }
}
