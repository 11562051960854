/**
 * NotificationsButton state selectors
 */

import { createSelector } from 'reselect';

import makeSelectGlobal from 'domain/selectors';

const makeSelectNotifications = () => createSelector(
  makeSelectGlobal(),
  (globalState) => globalState.get('notifications')
);

const makeSelectUnreadCount = () => createSelector(
  makeSelectNotifications(),
  (notifications) => notifications.get('unreadCount'),
);

export default makeSelectNotifications;
export {
  makeSelectUnreadCount,
};
