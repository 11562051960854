/**
 *
 * JournalDxGridContainer
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';

class JournalDxGridContainer extends React.PureComponent {

  render() {
    const { component, parentId, height, dialogName, name } = this.props;

    return React.cloneElement(component, {
      name,
      parentId,
      height,
      dialogName,
    });
  }
}

JournalDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
};

export default JournalDxGridContainer
