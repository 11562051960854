/*
 *
 * NotificationsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import withPrivateRoot from 'containers/App/withPrivateRoot';
import NotificationsList from 'containers/NotificationsList';

const styleSheet = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  notificationsList: {
    maxWidth: 800,
  },
});

class NotificationsPage extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <NotificationsList className={classes.notificationsList} />
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  classes: PropTypes.object,
};

export default withPrivateRoot(
  withStyles(styleSheet)(NotificationsPage)
);
