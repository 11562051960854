import React, { PureComponent } from 'react';
import createPieChart from './createPieChart';



class PieChartCreator extends PureComponent {

    render() {
        const { entity } = this.props;
        const PieChartContainer = createPieChart(entity);

        return (
            <PieChartContainer {...this.props} />
        );
    }
}


export default PieChartCreator;

