/*
 *
 * Notification constants
 *
 */

export const DISPLAY_NOTIFICATION = 'claris-vnext/Data/Notification/DISPLAY_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'claris-vnext/Data/Notification/DISMISS_NOTIFICATION';
export const OPEN_NOTIFICATION = 'claris-vnext/Data/Notification/OPEN_NOTIFICATION';

export const LOAD_UNREAD_NOTIFICATIONS_COUNT = 'claris-vnext/Data/Notification/LOAD_UNREAD_NOTIFICATIONS_COUNT';
export const LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = 'claris-vnext/Data/Notification/LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS';
export const LOAD_UNREAD_NOTIFICATIONS_COUNT_ERROR = 'claris-vnext/Data/Notification/LOAD_UNREAD_NOTIFICATIONS_COUNT_ERROR';

export const READ_ALL_NOTIFICATIONS = 'claris-vnext/Data/Notification/READ_ALL_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS_SUCCESS = 'claris-vnext/Data/Notification/READ_ALL_NOTIFICATIONS_SUCCESS';
export const READ_ALL_NOTIFICATIONS_ERROR = 'claris-vnext/Data/Notification/READ_ALL_NOTIFICATIONS_ERROR';
