/**
 *
 * RequestsPage14163
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import ServiceRequestsManagerForm14163 from './ServiceRequestsManagerView/ServiceRequestsManagerForm14163';
import AssignedForm14163 from './AssignedView/AssignedForm14163';
import TenantForm14163 from './TenantView/TenantForm14163';

function RequestsPage14163(props) {
  return (
    <RequestsPage
      tenantForm={<TenantForm14163 />}
      serviceRequestsManagerForm={<ServiceRequestsManagerForm14163 />}
      assignedForm={<AssignedForm14163 />}
      {...props}
    />
  );
}

export default RequestsPage14163;
