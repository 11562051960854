/*
 *
 * CheckpointDxGrid14691
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';
import {columnSize, columnType, requestColumnSizes} from "../../../../../domain/typeConstants/table";
import {columnStatusLabels} from "../../../../../pages/Requests/common/columnStatusLabel";

export class CheckpointDxGrid14691 extends CheckpointDxGrid {
    getColumns() {
        const blackListStyle = {
            style: { color: '#d50000' },
            condition: (value) => value.isBlackList,
        };
        const styles = [blackListStyle];

        return [
            {
                path: 'number',
                name: 'number',
                title: '№',
                type: columnType.NUMERIC,
                styles,
            },
            {
                path: 'visitorFullName',
                name: 'visitorFullName',
                title: 'ФИО посетителя',
                styles,
            },
            {
                path: 'carNumber',
                name: 'carNumber',
                title: '№ авто',
                styles,
            },
            {
                path: 'carModelText',
                name: 'carModelText',
                title: 'Марка',
                styles,
            },
            {
                path: 'visitDate',
                name: 'visitDate',
                title: 'Дата',
                type: columnType.DATE,
                styles,
            },
            {
                path: 'expirationDate',
                name: 'expirationDate',
                title: 'До',
                type: columnType.DATE,
                styles,
            },
            {
                path: ['status', 'name'],
                name: 'statusName',
                getCellValue: (row) => (row.status ? row.status.name : undefined),
                title: 'Статус',
                type: columnType.LABEL,
                labels: columnStatusLabels,
            },
            {
                path: ['type', 'shortName'],
                name: 'typeShortName',
                getCellValue: (row) => (row.type ? row.type.shortName : undefined),
                title: 'Вид',
                styles,
            },
            {
                path: ['groupRequestVisitorsData'],
                name: 'groupRequestVisitorsData',
                getCellValue: (row) => row.groupRequestVisitorsData ? row.groupRequestVisitorsData : undefined,
                title: 'Список посетителей',
                styles,
            },
            {
                path: 'whatHappened',
                name: 'whatHappened',
                title: 'Наименование работ',
            },
            {
                path: ['vozdvizhenkaTime', 'name'],
                name: 'vozdvizhenkaTime',
                getCellValue: (row) => (row.vozdvizhenkaTime ? row.vozdvizhenkaTime.name : undefined),
                title: 'Время',
            },
            {
                path: 'companyName',
                name: 'companyName',
                title: 'Подрядчик',
                styles,
            },
            {
                path: ['file', 'name'],
                name: 'file',
                getCellValue: (row) => (row.file ? row.file.id : undefined),
                title: 'Файл',
                type: columnType.FILE,
            },
            {
                path: ['company', 'name'],
                name: 'tenantCompanyName',
                getCellValue: (row) => (row.company ? row.company.name : undefined),
                title: 'Арендатор',
                styles,
            },
            {
                path: 'arrivalDate',
                name: 'arrivalDate',
                title: 'Прибыл',
                type: columnType.TIME,
                styles,
            },
            {
                path: 'departureDate',
                name: 'departureDate',
                title: 'Убыл',
                type: columnType.TIME,
                styles,
            },
            {
                path: ['parking', 'name'],
                name: 'parkingName',
                getCellValue: (row) => (row.parking ? row.parking.name : undefined),
                title: 'Парковка',
                styles,
            },
            {
                path: 'parkingPlace',
                name: 'parkingPlace',
                title: 'Место',
                styles,
            },
            {
                path: 'materialValuesData',
                name: 'materialValuesData',
                title: 'Мат. ценности',
                styles,
            },
            {
                path: 'note',
                name: 'note',
                title: 'Примечание',
                styles,
            },
            {
                path: 'blackListNote',
                name: 'blackListNote',
                title: 'Причина ЧС',
                styles,
            },
        ];
    }

    getColumnWidths() {
        return [
            { columnName: 'number', width: columnSize.NUMERIC },
            { columnName: 'visitorFullName', width: requestColumnSizes.visitorFullName },
            { columnName: 'carNumber', width: requestColumnSizes.carNumber },
            { columnName: 'carModelText', width: requestColumnSizes.carModelText },
            { columnName: 'visitDate', width: columnSize.DATE },
            { columnName: 'expirationDate', width: columnSize.DATE },
            { columnName: 'statusName', width: requestColumnSizes.statusName },
            { columnName: 'typeShortName', width: requestColumnSizes.typeShortName },
            { columnName: 'tenantCompanyName', width: requestColumnSizes.tenantCompanyName },
            { columnName: 'arrivalDate', width: columnSize.TIME },
            { columnName: 'departureDate', width: columnSize.TIME },
            { columnName: 'parkingName', width: requestColumnSizes.parkingName },
            { columnName: 'parkingPlace', width: requestColumnSizes.parkingPlace },
            { columnName: 'materialValuesData', width: requestColumnSizes.materialValuesData },
            { columnName: 'companyName', width: requestColumnSizes.companyName },
            { columnName: 'whatHappened', width: requestColumnSizes.whatHappened },
            { columnName: 'vozdvizhenkaTime', width: 100 },
            { columnName: 'file', width: 50 },
            { columnName: 'note', width: requestColumnSizes.note },
            { columnName: 'blackListNote', width: requestColumnSizes.blackListNote },
            { columnName: 'groupRequestVisitorsData', width: 150 },
        ];
    }

    getGridFilters() {
        return [
            { columnName: 'number', value: '' },
            { columnName: 'visitorFullName', value: '' },
            { columnName: 'carNumber', value: '' },
            { columnName: 'carModelText', value: '' },
            { columnName: 'visitDate', value: '' },
            { columnName: 'expirationDate', value: '' },
            { columnName: 'statusName', value: '' },
            { columnName: 'typeShortName', value: '' },
            { columnName: 'tenantCompanyName', value: '' },
            { columnName: 'arrivalDate', value: '' },
            { columnName: 'departureDate', value: '' },
            { columnName: 'parkingName', value: '' },
            { columnName: 'parkingPlace', value: '' },
            { columnName: 'materialValuesData', value: '' },
            { columnName: 'companyName', value: '' },
            { columnName: 'whatHappened', value: '' },
            { columnName: 'vozdvizhenkaTime', value: '' },
            { columnName: 'note', value: '' },
            { columnName: 'blackListNote', value: '' },
            { columnName: 'file', value: '' },
            { columnName: 'groupRequestVisitorsData', value: '' },
        ];
    }

    getColumnOrder() {
        return ['number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
             'groupRequestVisitorsData', 'companyName', 'whatHappened', 'vozdvizhenkaTime', 'file', 'tenantCompanyName',
            'arrivalDate', 'departureDate', 'parkingName', 'parkingPlace', 'materialValuesData', 'note', 'blackListNote',
        ];
    }
}
