/*
 *
 * ServiceRequestsManagerDxGrid14720
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';
import { requestColumnSizes } from 'domain/typeConstants/table';
import { REQUESTS_WITHOUT_EMPTY_NAME } from "domain/Data/Filters/constants";
import {
  acceptedByTenantColumn,
  acceptedByTenantColumnFilter,
  ACCEPTED_BY_TENANT,
  estimationCostColumn,
  estimationCostColumnFilter,
  ESTIMATION_COST,
  estimationCostScanColumn,
  estimationCostScanColumnFilter,
  ESTIMATION_COST_SCAN,
} from "../common/dxGridComponents/columns";

export class ServiceRequestsManagerDxGrid14720 extends ServiceRequestsManagerDxGrid {
  getFilters() {
        const filters = super.getFilters();
        filters.push(REQUESTS_WITHOUT_EMPTY_NAME);
        return filters;
    }

  getColumns() {
    const { viewName } = this.props;
    const columns = super.getColumns();

    if (viewName === 'all') {
      columns.push(acceptedByTenantColumn);
    }

    columns.push(estimationCostColumn);
    columns.push(estimationCostScanColumn);

    const serviceNameIndex = columns.findIndex((column) => column.name === 'serviceName');
    columns[serviceNameIndex].entityName = "services";

    const phoneColumn = {
      path: 'phone',
      name: 'phone',
      title: 'Контактный тел',
      width: requestColumnSizes.phone,
    };
    columns.push(phoneColumn);
    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    const gridFilters = super.getGridFilters();

    if (viewName === 'all') {
      gridFilters.push(acceptedByTenantColumnFilter);
    }

    gridFilters.push(estimationCostColumnFilter);
    gridFilters.push(estimationCostScanColumnFilter);

    const phoneColumnFilters = {columnName: 'phone', value: ''};
    gridFilters.push(phoneColumnFilters);
    return gridFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    const columnOrder = super.getColumnOrder();
    columnOrder.push('phone');

    if (viewName === 'all') {
      columnOrder.push(ACCEPTED_BY_TENANT);
    }

    const companyNameIndex = columnOrder.findIndex((column) => column === 'companyName');
    columnOrder.splice(companyNameIndex, 1);

    const assigneeNameIndex = columnOrder.findIndex((column) => column === 'assigneeName');
    columnOrder.splice(assigneeNameIndex + 1, 0, 'companyName', 'authorName', 'phone');

    const fileIndex = columnOrder.findIndex(column => column === 'fileName');
    columnOrder.splice(fileIndex + 1, 0, ESTIMATION_COST, ESTIMATION_COST_SCAN);

    return columnOrder;
  }
}
