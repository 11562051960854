/**
 *
 * Tenant Rules DxGrid 12970
 *
 */

import { TenantDxGrid } from 'pages/Rules/TenantView';

import {
  RULES_FOR_COMCITY_TENANT,
  ACTIVE_RULES,
} from 'domain/Data/Filters/constants';

export class TenantDxGrid12970 extends TenantDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [RULES_FOR_COMCITY_TENANT];
    if (viewName === 'active') filters.push(ACTIVE_RULES);
    return filters;
  }
}
