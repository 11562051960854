/*
 *
 * AssignedView constants
 *
 */

export const ASSIGNED_REQUESTS_DATA_TABLE = 'AssignedRequestsDataTable';
export const ASSIGNED_REQUESTS_TABLE_GRID = 'AssignedRequestsTableGrid';
export const ASSIGNED_REQUESTS_FORM = 'AssignedRequestsForm';
export const ASSIGNED_REQUESTS_FORM_DIALOG = 'AssignedRequestsFormDialog';
export const ASSIGNED_COMPLETE_FORM = 'AssignedCompleteForm';
export const ASSIGNED_COMPLETE_FORM_DIALOG = 'AssignedCompleteFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_ADD_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_COPY_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_VIEW_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_COMPLETE_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_COMPLETE_ACTION';
export const EXECUTE_REJECT_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_REJECT_ACTION';
export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/RequestsPage/AssignedView/EXECUTE_ACCEPT_ACTION';
