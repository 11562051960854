/**
 *
 * SupplierInvoiceDxGridContainer
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from './actions';

class SupplierInvoiceDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => this.props.executeViewAction(record.id);

  render() {
    const { component, parentId, height, dialogName, name } = this.props;
    return React.cloneElement(component, {
      name,
      parentId,
      height,
      dialogName,
      onRowDoubleClick: this.handleRowDoubleClick,
    });
  }
}

SupplierInvoiceDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  executeViewAction: PropTypes.func.isRequired,
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(SupplierInvoiceDxGridContainer);

