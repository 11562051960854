/*
 *
 * TenantView constants
 *
 */

export const TENANT_REQUESTS_TABLE_GRID = 'TenantRequestsTableGrid';
export const TENANT_REQUESTS_DATA_TABLE = 'TenantRequestsDataTable';
export const TENANT_REQUESTS_FORM = 'TenantRequestsForm';
export const TENANT_REQUESTS_FORM_DIALOG = 'TenantRequestsFormDialog';
export const TENANT_AGREED_ESTIMATION_COST_FORM = 'TenantAgreedEstimationCostForm';
export const TENANT_AGREED_ESTIMATION_COST_FORM_DIALOG = 'TenantAgreedEstimationCostFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_VIEW_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_COPY_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_CLOSED_BY_TENANT_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_CLOSED_BY_TENANT_ACTION';
export const EXECUTE_AGREED_ESTIMATION_COST_ACTION = 'claris-vnext/RequestsPage/TenantView/EXECUTE_AGREED_ESTIMATION_COST_ACTION';
