/**
 *
 * ReceptionDxGrid11994
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';

import {
  REQUESTS_FOR_LEFORT_RECEPTION,
  REQUESTS_FOR_LEFORT_RECEPTION_DEPARTMENTS,
  REQUESTS_ON_TMC_MOVEMENT,
  ON_CREATE_REQUESTS,
} from 'domain/Data/Filters/constants';

import {
  TODAY_COLUMNS, ALL_COLUMNS,
  TODAY_COLUMN_FILTERS, ALL_COLUMN_FILTERS,
  TODAY_COLUMN_ORDER, ALL_COLUMN_ORDER,
} from './dxGridComponents';
import {
  MOVEMENT_TMC_COLUMNS,
  MOVEMENT_TMC_COLUMN_FILTERS,
  MOVEMENT_TMC_COLUMN_ORDER,
} from '../common/dxGridComponents';

class ReceptionDxGrid11994 extends ReceptionDxGrid { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { roles, viewName } = this.props;
    const filters = super.getFilters();

    if (viewName !== 'movementTMC') {
      filters.push(REQUESTS_FOR_LEFORT_RECEPTION);
    }

    if (roles.includes('lefortReceptionDepartments') && viewName !== 'movementTMC') {
      filters.push(REQUESTS_FOR_LEFORT_RECEPTION_DEPARTMENTS);
    }

    if (viewName === 'movementTMC') filters.push(REQUESTS_ON_TMC_MOVEMENT);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);

    return filters;
  }

  getColumns() {
    const { viewName } = this.props;

    if (viewName === 'today') {
      return TODAY_COLUMNS;
    } else if (viewName === 'movementTMC') {
      return MOVEMENT_TMC_COLUMNS;
    }

    return ALL_COLUMNS;
  }

  getGridFilters() {
    const { viewName } = this.props;

    if (viewName === 'today') {
      return TODAY_COLUMN_FILTERS;
    } else if (viewName === 'movementTMC') {
      return MOVEMENT_TMC_COLUMN_FILTERS;
    }

    return ALL_COLUMN_FILTERS;
  }

  getColumnOrder() {
    const { viewName } = this.props;

    if (viewName === 'today') {
      return TODAY_COLUMN_ORDER;
    } else if (viewName === 'movementTMC') {
      return MOVEMENT_TMC_COLUMN_ORDER;
    }

    return ALL_COLUMN_ORDER;
  }
}

export default ReceptionDxGrid11994;
