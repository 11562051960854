/**
 *
 * AdvancedForm sagas
 *
 */

import {call, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import {
    actionTypes,
    getFormError,
    startSubmit,
    stopSubmit,
    startAsyncValidation,
} from 'redux-form/immutable';
import * as api from 'utils/api';
import {saveEntitySaga, updateEntitySaga} from 'domain/Data/sagas';
import {changeEntitiesState,} from 'domain/Data/actions';
import {
    formDataSubmited,
    formDataSubmitingError,
    formDataLoaded,
    formDataLoadingError,
    incrementRequestsSentParameter,
    incrementRequestsReceivedParameter,
    setGroups, changeDetailTableData,
} from './actions';
import {LOAD_FORM_DATA, SUBMIT_FORM, CALCULATE_FIELDS_VALUES} from './constants';

import fileFieldRootSaga from './FileField/sagas';
import selectFieldRootSaga from './SelectField/sagas';
import comboBoxRootSaga from './ComboBox/sagas';
import comboBoxTextInputRootSaga from './ComboBoxTextInput/sagas';
import dadataRootSaga from './DadataField/sagas';
import autocompleteRootSaga from './Autocomplete/sagas';
import multiAutocompleteRootSaga from './MultiAutocomplete/sagas';
import selectCheckboxRootSaga from './SelectCheckbox/sagas';
import selectGridFieldRootSaga from './SelectGridField/sagas';
import selectDetailTableFieldRootSaga from './DetailTable/sagas';

import {
    makeSelectFields, makeSelectEntityId, makeSelectEntityName,
    makeSelectUseAsyncValidate, makeSelectMode, makeSelectParams,
    makeSelectInitialValues, makeSelectCurrentValues, makeSelectChangedValues,
    makeSelectRequiredRequestsInfo, makeSelectGroups, makeSelectDetailTableData,
    makeSelectAdvancedForm,
} from './selectors';
import {makeSelectEntity, makeSelectEntityValue} from 'domain/Data/selectors';
import {normalizeEntitiesQueueSaga, calculateFieldsValuesSaga} from './commonSagas';
import {encryptStringWithXORtoHex, findAndReplaceLink} from 'utils/formatUtils';
import {makeSelectAccountId, makeSelectUsername} from 'domain/User/selectors';
import {DOCUMENT_POSITIONS, WORKS, REQUESTS, TASKS} from 'domain/Data/constants'

import {account} from 'domain/typeConstants/accounts';
import {closeAlertDialog, openAlertDialog} from 'containers/AlertDialog/actions';
import {makeSelectResult} from 'containers/AlertDialog/selectors';
import {CLOSE_ALERT_DIALOG, CONFIRM_ALERT_DIALOG} from 'containers/AlertDialog/constants';
import {makeSelectActiveDialogs, makeSelectCountOpenedDialog} from "../AdvancedFormDialog/selectors";
import {SUPPLY_REQUEST_FORM, SUPPLY_REQUEST_FORM_DIALOG} from "../../pages/Documents/SupplyRequestView/constants";
import {DOCUMENT_POSITION_FORM_DIALOG} from "../../pages/Positions/PositionsView/constants";
import {convertDatetoString} from "../../utils/dateUtils";


export default function* rootSaga() {
    yield takeLatest(LOAD_FORM_DATA, function* (action) {
        yield call(loadFormDataSaga, action.meta.advancedForm);
    });

    yield takeLatest(SUBMIT_FORM, function* (action) {
        yield call(submitFormSaga, action.meta.advancedForm);
    });

    yield takeLatest(CALCULATE_FIELDS_VALUES, function* (action) {
        yield call(calculateFieldsValuesSaga, action.meta.advancedForm, action.meta.nameOfFieldCausedCalculation);
    });

    yield fork(fileFieldRootSaga);
    yield fork(selectFieldRootSaga);
    yield fork(comboBoxRootSaga);
    yield fork(comboBoxTextInputRootSaga);
    yield fork(dadataRootSaga);
    yield fork(autocompleteRootSaga);
    yield fork(multiAutocompleteRootSaga);
    yield fork(selectCheckboxRootSaga);
    yield fork(selectGridFieldRootSaga);
    yield fork(selectDetailTableFieldRootSaga);
}


export function* loadFormDataSaga(formName) {

    const requestsInfo = yield select(makeSelectRequiredRequestsInfo(formName));
    const openDialogs = yield select(makeSelectActiveDialogs())
    const countOpenedDialogs = yield select(makeSelectCountOpenedDialog())

    try {
        yield put(incrementRequestsSentParameter(formName));
        yield requestsInfo.valueSeq().toJS().map(
            function* (requestInfo) {
                const {entityName, ids} = requestInfo;
                if (ids && ids.length > 0) {
                    if (countOpenedDialogs === 2 &&
                        ['add', 'copy'].includes(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].mode)
                        && openDialogs[DOCUMENT_POSITION_FORM_DIALOG].mode === 'edit') {
                        // взять из кеш
                        let data = yield select(makeSelectDetailTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName));
                        yield put(changeEntitiesState(entityName, data));
                    } else {
                        const response = yield call(api.load, entityName, ids[0])
                        yield put(changeEntitiesState(entityName, [response.data]));
                    }
                }
            });

        yield put(formDataLoaded(formName));
        yield put(incrementRequestsReceivedParameter(formName));
        yield* normalizeEntitiesQueueSaga(formName);
    } catch (err) {
        yield put(formDataLoadingError(formName, err));
    }
}

const prepareCurrentValues = (entityName, currentValues, params, options) => {

    if (!entityName) return currentValues

    switch (entityName) {
        case TASKS:
            currentValues = Object.fromEntries(currentValues.entries());
            currentValues.description = findAndReplaceLink(currentValues.description)
            break;

        case WORKS:
            currentValues = Object.fromEntries(currentValues.entries());
            currentValues.description = findAndReplaceLink(currentValues.description)
            if (params && params.parentMode === 'copy') {
                currentValues.request = null
                currentValues.task = null
            }
            break;

        case REQUESTS:
            currentValues = Object.fromEntries(currentValues.entries());
            if (currentValues.visitorFullName) {
                currentValues.visitorFullName = currentValues.visitorFullName.replace(/ +/g, ' ').trim()
            }
            if (currentValues.groupRequestVisitorsData) {
                currentValues.groupRequestVisitorsData = currentValues.groupRequestVisitorsData.replace(/ +/g, ' ').replace(/ \n+/g, '\n').trim()
            }
            if (currentValues.visitDate){
                currentValues.visitDate.setHours(15)
                currentValues.visitDate.setMinutes(0)
                currentValues.visitDate.setSeconds(0)
            }
            break;

        case DOCUMENT_POSITIONS:
            currentValues = Object.fromEntries(currentValues.entries());
            currentValues.changedDate = new Date();
            currentValues.supplierInvoice = null;
            break;

        default:
            currentValues = Object.fromEntries(currentValues.entries());
    }

    if (currentValues && currentValues.request && currentValues.request.id) {
        currentValues.request = currentValues.request.id
    }

    currentValues = {...currentValues, ...options}

    return currentValues;
}

function* createChildRecord(entityName, currentValues) {
    let record = {};

    if (entityName === TASKS) {
        const user = yield select(makeSelectUsername())
        const company = yield select(makeSelectEntity('companies', currentValues.company))
        const department = yield select(makeSelectEntity('departments', currentValues.department))
        const responsible = yield select(makeSelectEntity('employees', currentValues.responsible))
        const status = yield select(makeSelectEntity('taskStatuses', currentValues.status))

        record = {
            attachments: [],
            comment: currentValues.comment,
            author: {id: currentValues.author, name: user},
            company: {id: currentValues.company, name: company.name},
            department: {id: currentValues.department, name: department.name},
            responsible: {id: currentValues.responsible, name: responsible.name},
            status: {id: currentValues.status, name: status.name, statusZadachi: status.statusZadachi},
            finishDate: currentValues.finishDate.toISOString(),
            registrationDate: currentValues.registrationDate.toISOString(),
            description: currentValues.description,
            document: -1,
            name: currentValues.name,
            number: currentValues.number,
            time: currentValues.time,
            type: currentValues.type,
            id: Date.now().toString(),
        }
    } else if (entityName === WORKS) {
        const user = yield select(makeSelectUsername())
        record = {
            author: {id: currentValues.author, name: user},
            description: currentValues.description,
            duration: currentValues.duration,
            runDate: currentValues.runDate.toISOString(),
            task: -1,
            request: -1,
            id: Date.now().toString(),
        }
    } else if (entityName === DOCUMENT_POSITIONS) {
        const assetData = yield select(makeSelectEntityValue('goodsAndServices', currentValues.asset));
        const estimateItem = yield select(makeSelectEntityValue('estimateItems', currentValues.estimateItem));
        record = {
            amount: currentValues.amount,
            asset: currentValues.asset && {id: currentValues.asset, name: assetData.name},
            assetText: currentValues.assetText,
            available: currentValues.available,
            changedDate: currentValues.changedDate,
            description: currentValues.description,
            desiredDate: currentValues.desiredDate,
            name: currentValues.name,
            positionsCount: currentValues.positionsCount,
            price: currentValues.price,
            received: currentValues.received,
            state: currentValues.state,
            estimateItem: currentValues.estimateItem && {
                id: currentValues.estimateItem,
                name: estimateItem.name,
                available: estimateItem.available
            },
            document: -1,
            id: Date.now().toString(),
        }
    }
    return record
}

const existDialog = (dialogs, dialogName) => {
    return dialogs[dialogName] !== undefined
}

function* currentValuesAddHash(entityName, currentValues) {
    const accountId = yield select(makeSelectAccountId())
    if ( entityName === 'requests') {
        const date = convertDatetoString(new Date());
        const {author, company, visitorFullName, carNumber} = currentValues
        const hashValues = [author, company, visitorFullName, carNumber, date].join(',');
        currentValues.hash = encryptStringWithXORtoHex(hashValues)
    }
    return currentValues;
}

export function* submitFormSaga(formName) {
    let result = null;
    yield put(startSubmit(formName));
    const errors = yield call(validateFormSaga, formName);
    result = errors;
    if (Object.values(errors).length > 0) {
        yield put(stopSubmit(formName, errors));
        throw new Error(errors._error);
    }
    const entityName = yield select(makeSelectEntityName(formName));
    const entityId = yield select(makeSelectEntityId(formName));
    const mode = yield select(makeSelectMode(formName));

    const useAsyncValidate = yield select(makeSelectUseAsyncValidate(formName));
    if (useAsyncValidate) {
        yield put(startAsyncValidation(formName));
        yield take((action) => action.type === actionTypes.STOP_ASYNC_VALIDATION && action.meta.form === formName);
        const error = yield select(getFormError(formName, (state) => state.get('forms')));
        if (error) {
            throw new Error(error);
        }
    }
    const openDialogs = yield select(makeSelectActiveDialogs())
    const countOpenedDialogs = yield select(makeSelectCountOpenedDialog())

    try {
        let currentValues = yield select(makeSelectCurrentValues(formName));
        const changedData = yield select(makeSelectChangedValues(formName));
        const params = yield select(makeSelectParams(formName));

        if (countOpenedDialogs === 2
            && existDialog(openDialogs, SUPPLY_REQUEST_FORM_DIALOG)
            && existDialog(openDialogs, DOCUMENT_POSITION_FORM_DIALOG)) {

            // новый документ, новая позиция
            if (openDialogs[SUPPLY_REQUEST_FORM_DIALOG].mode === 'add' && ['add', 'copy'].includes(openDialogs[DOCUMENT_POSITION_FORM_DIALOG].mode)) {
                let data = yield select(makeSelectDetailTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName));
                if (data === null || data === undefined) {
                    data = []
                }
                const record = yield createChildRecord(entityName, currentValues);
                data.push(record);
                yield put(changeDetailTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName, data));
            }

            // в новой задаче изменение действия
            if (['add', 'copy'].includes(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].mode) && openDialogs[DOCUMENT_POSITION_FORM_DIALOG].mode === 'edit') {
                let data = yield select(makeSelectDetailTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName));
                if (data === null || data === undefined) {
                    data = []
                }
                const newData = data.map(item => item.id === currentValues.id ? {...item, ...changedData} : item)
                yield put(changeDetailTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName, newData));
            }

            //в существующем документе добавление позиции
            if (openDialogs[SUPPLY_REQUEST_FORM_DIALOG].mode === 'edit' && ['add', 'copy'].includes(openDialogs[DOCUMENT_POSITION_FORM_DIALOG].mode)) {
                if (Object.keys(currentValues).length > 0) {
                    let newRecord = currentValues.toJS()
                    newRecord.document = openDialogs[SUPPLY_REQUEST_FORM_DIALOG].entityId
                    yield call(saveEntitySaga, entityName, newRecord)
                    const filterBy = `document.id.contains("${openDialogs[SUPPLY_REQUEST_FORM_DIALOG].entityId}")`
                    yield updateTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName, filterBy)
                }
            }

            //в существующем документе изменение позиции
            if (openDialogs[SUPPLY_REQUEST_FORM_DIALOG].mode === 'edit' && openDialogs[DOCUMENT_POSITION_FORM_DIALOG].mode === 'edit') {
                if (Object.keys(changedData).length > 0) {
                    yield call(updateEntitySaga, entityName, entityId, changedData);
                    const filterBy = `document.id.contains("${openDialogs[SUPPLY_REQUEST_FORM_DIALOG].entityId}")`
                    yield updateTableData(openDialogs[SUPPLY_REQUEST_FORM_DIALOG].formName, entityName, filterBy)
                }
            }
        } else {

            /* сохранение по умолчанию*/
            if (mode === 'add' || mode === 'copy') {
                if (Object.keys(currentValues).length > 0) {
                    currentValues = prepareCurrentValues(entityName, currentValues, params)
                    currentValues = yield call(currentValuesAddHash, entityName, currentValues);

                    // диалог подтверждения для групповых заявок
                    if (currentValues.isGroupRequest && currentValues.groupRequestVisitorsData) {
                        const countRequests = currentValues.groupRequestVisitorsData.split('\n').length
                        yield put(openAlertDialog('Внимание', `Будет создано ${countRequests} заявок. Продолжить?`, 'confirm'));
                        while (true) {
                            yield take((action) => action.type === CONFIRM_ALERT_DIALOG || action.type === CLOSE_ALERT_DIALOG)
                            const resultDialog = yield select(makeSelectResult());
                            if (resultDialog) {
                                result = yield call(saveEntitySaga, entityName, currentValues)
                                if (result.errors) {
                                    yield put(openAlertDialog("Ошибка", result.errors?._error))
                                }
                                break;
                            } else {
                                yield call(closeAlertDialog)
                                break;
                            }
                        }
                    } else {
                        // сохранение данных формы
                        result = yield call(saveEntitySaga, entityName, currentValues)

                        // если SupplyRequestForm, то сохранение позиций формы
                        if (existDialog(openDialogs, SUPPLY_REQUEST_FORM_DIALOG) && mode === 'add' && countOpenedDialogs === 1) {
                            const form = yield select(makeSelectAdvancedForm(SUPPLY_REQUEST_FORM))
                            const fields = yield select(makeSelectFields(formName));
                            const tableFields = fields.filter((field) => field.type === 'table');
                            yield tableFields.valueSeq().toJS().map(
                                function* (field) {
                                    const data = form.toJS()[field.filterEntityName];
                                    if (data && field.filterEntityName === 'documentPositions') {
                                        for (let i = 0; i < data.length; i++) {
                                            const newRecord = {
                                                document: result.id,
                                                state: data[i].state,
                                                estimateItem: data[i].estimateItem ? data[i].estimateItem.id : null,
                                                asset: data[i].asset ? data[i].asset.id : null,
                                                available: data[i].available,
                                                assetText: data[i].assetText,
                                                positionsCount: data[i].positionsCount,
                                                received: data[i].received
                                            }
                                            yield call(saveEntitySaga, 'documentPositions', newRecord)
                                        }
                                    }
                                });
                        }
                        if (result.errors) {
                            yield put(openAlertDialog("Ошибка", result.errors?._error))
                        }
                    }
                }
            } else if (mode === 'edit') {
                if (Object.keys(changedData).length > 0) {
                    if (changedData.visitDate){
                        changedData.visitDate.setHours(15)
                        changedData.visitDate.setMinutes(0)
                        changedData.visitDate.setSeconds(0)
                    }
                    yield call(updateEntitySaga, entityName, entityId, changedData);
                }
            }
        }
        yield put(stopSubmit(formName));
        yield put(formDataSubmited(formName));
    } catch (err) {
        const {data} = err.response;
        yield put(stopSubmit(formName, data.errors ? data.errors : {_error: 'Произошла ошибка при сохранении формы'}));
        yield put(formDataSubmitingError(formName, err));
        throw err;
    }
    return result;
}


function* updateTableData(formName, entityName, filterBy) {
    const startTime = new Date().getTime();
    const response = yield call(api.loadPage, entityName, {filterBy});
    const endTime = new Date().getTime();
    const result = endTime - startTime;
    if (result < 1500) yield* wait(1200 - result);
    yield put(changeDetailTableData(formName, entityName, response.data))
}

function* getGroupToExpand(formName, invalidFieldNames, groups) {
    const groupToExpand = []
    if (invalidFieldNames.length > 0) {
        invalidFieldNames.map(name => {
            groups.forEach(g => {
                if (g.expand === true || g.type === 'detailContainer') {
                    groupToExpand.findIndex(f => f.name === g.name) === -1 && groupToExpand.push(g)
                } else {

                    if (g.items.includes(name)) {
                        const groupIndex = groupToExpand.findIndex(f => f.name === g.name)
                        if (groupIndex === -1) {
                            groupToExpand.push({...g, expand: true})
                        } else groupToExpand[groupIndex] = {...g, expand: true}
                    } else {
                        const groupIndex = groupToExpand.findIndex(f => f.name === g.name)
                        if (groupIndex === -1) {
                            groupToExpand.push(g)
                        }
                    }
                }
            })
        })
        yield put(setGroups(formName, groupToExpand));
    }
}


export function* validateFormSaga(formName) {
    const fields = yield select(makeSelectFields(formName));
    const mode = yield select(makeSelectMode(formName));
    const initialValues = yield select(makeSelectInitialValues(formName));
    const currentValues = yield select(makeSelectCurrentValues(formName));
    const groups = yield select(makeSelectGroups(formName));
    const errors = {};
    const invalidFieldNames = []

    const isRequired = (field) => (typeof field.required === 'boolean' && field.required) ||
        (typeof field.required === 'function' && field.required(currentValues, mode));

    const isVisible = (field) => (typeof field.visible === 'boolean' && field.visible) ||
        (typeof field.visible === 'function' && field.visible(currentValues, initialValues, mode));

    const isEmpty = (values, field) => Array.isArray(values[field.name])
        ? values[field.name].length === 0
        : !values[field.name]
        || (values[field.name] && (values[field.name].toString() === '<p></p>\n' || values[field.name].toString() === 'Invalid Date'));

    const isInvalid = (field) => (typeof field.validate === 'function' && field.validate(currentValues, mode));

    // обязательные поля
    const emptyRequiredFields = fields.filter((field) => isRequired(field) && isVisible(field) && isEmpty(currentValues, field));
    emptyRequiredFields.forEach((field) => {
        errors[field.name] = 'обязательное поле';
    });

    if (emptyRequiredFields.size > 0) {
        const fields = emptyRequiredFields.toJS()
        const fieldTitles = []

        for (let key in fields) {
            const label = typeof fields[key].label === 'function' ? fields[key].label(currentValues) : fields[key].label
            fieldTitles.push(label)
            invalidFieldNames.push(key)
        }
        errors._error = 'Не заполнены обязательные поля: ' + fieldTitles.join(', ');
    }

    // поля не прошедшие валидацию
    const invalidFields = fields.filter((field) => isVisible(field) && !isEmpty(currentValues, field) && isInvalid(field));
    invalidFields.forEach((field) => {
        const result = field.validate(currentValues, mode);
        if (result && Object.keys(result).length > 0) {
            errors[field.name] = result.errorHelperText ? result.errorHelperText : 'Некорректно заполненое поле'
        }
    });

    if (invalidFields.size > 0) {
        const fields = invalidFields.toJS()
        const fieldTitles = []

        for (let key in fields) {
            if (typeof fields[key].label === 'function') {
                fieldTitles.push(fields[key].label(currentValues))
            } else fieldTitles.push(fields[key].label)
            invalidFieldNames.push(key)
        }

        const invalidErrorText = 'Некорректно заполнены поля: ' + fieldTitles.join(', ');
        errors._error = errors._error ? errors._error + '. ' + invalidErrorText : invalidErrorText
    }

    yield* getGroupToExpand(formName, invalidFieldNames, groups)
    return errors;
}

function* wait(time) {
    yield new Promise((resolve) => setTimeout(resolve, time));
}
