import React, { PureComponent } from 'react';
import PieChartWidget from './PieChart';
import { makeSelectEntityData, makeSelectEntityRange } from '../selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';


function createPieChart(entity) {
    class PieChartContainer extends PureComponent {

        render() {
            return (
                <PieChartWidget {...this.props} />
            );
        }
    }

    const mapStateToProps = createStructuredSelector({
        data: makeSelectEntityData(entity),
        range: makeSelectEntityRange(entity),
    });

    return connect(mapStateToProps)(PieChartContainer);
}

export default createPieChart;
