/**
 *
 * getViewConfig Function
 *
 */
import { role } from 'domain/typeConstants/roles';

import {
  getTenantView,
  getReceptionView,
} from './getRoleView';

import getSagasConfig from './getSagasConfig';

import createReceptionViewSaga from './ReceptionView/sagas';

const defaultViewConfig = {
  createComponent: getReceptionView,
  createSaga: (sagaConfig) => createReceptionViewSaga(sagaConfig.receptionViewSaga),
};

function createEmptySaga() { return function* rootSaga() { } }

const viewConfigs = [
  {
    role: role.TENANT,
    createComponent: getTenantView,
    createSaga: () => createEmptySaga(),
  },
];

export function createViewComponent(userRoles, props) {
  const config = viewConfigs.find((c) => userRoles.includes(c.role));
  if (config) {
    return config.createComponent(props);
  }
  return defaultViewConfig.createComponent(props);
}

export function createViewSaga(userRoles, accountId) {
  const config = viewConfigs.find((c) => userRoles.includes(c.role));
  if (config) {
    return config.createSaga(getSagasConfig(accountId));
  }

  return defaultViewConfig.createSaga(getSagasConfig(accountId));
}
