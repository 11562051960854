/*
 *
 * User sagas
 *
 * 
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import axios from 'axios';

import * as api from 'utils/api';
import {addEntitiesState} from 'domain/Data/actions';
import {COMPANIES, EMPLOYEES} from 'domain/Data/constants';
import {initialize as initializeAdvancedForm} from 'containers/AdvancedForm/actions';

import {
    connectToCarNumberScannerService,
    disconnectFromCarNumberScannerService,
} from 'features/CarNumberScanner/actions';

import {
    getValueFromLocalStorage,
    removeValueFromLocalStorage,
    setValueToLocalStorage,
} from 'utils/localStorage';

import {ACCOUNT_ID_KEY, BUILD_VERSION_KEY, GLOBAL_USER_KEY, UPDATE_BY_SIGNALR} from 'global-constants';

import {role} from 'domain/typeConstants/roles';
import {account} from 'domain/typeConstants/accounts';

import {LOG_IN, LOG_OUT, CHANGE_PASSWORD, RESTORE_PASSWORD, CHECK_USER_FOR_BLOCKING, CHECK_VERSION} from './constants';

import {
    logged,
    loginError,
    loadUserInfo,
    userInfoLoaded,
    userInfoLoadingError,
    passwordChanged,
    passwordChangingError,
} from './actions';

import makeSelectUser from './selectors';
import {settings} from 'containers/AdvancedDataTable/dxGridTypes';
import {loadNavigationItems} from "../Navigation/actions";

export default function* rootSaga() {
    yield takeLatest(LOG_IN, loginSaga);
    yield takeLatest(LOG_OUT, logoutSaga);
    yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
    yield takeLatest(CHECK_USER_FOR_BLOCKING, checkUserForBlockingSaga);
    yield takeLatest(CHECK_VERSION, checkVersionSaga);
    yield takeLatest(RESTORE_PASSWORD, restorePasswordSaga);
}

function LoginError(message) {
    this.name = 'LoginError';
    this.message = message;

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, LoginError);
    } else {
        this.stack = (new Error()).stack;
    }
}

LoginError.prototype = Object.create(Error.prototype);

const accessDeniedUsers = ['Присско']

export function* loginSaga({payload}) {
    try {
        const {Authorization, ...restHeaders} = axios.defaults.headers.common;
        const presetAccountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);
        axios.defaults.headers.common = restHeaders;

        const response = yield call(api.authenticate, payload.login, payload.password);
        const {access_token, user_name} = response.data;
        if (!access_token || !user_name || accessDeniedUsers.includes(user_name)) {
            throw new LoginError('Не удалось аутентифицировать пользователя, обратитесь в техподдержку или к администраторам системы');
        }
        yield put(logged(response.data));

        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;

        yield call(loadUserInfoSaga);
        const user = yield select(makeSelectUser());
        setValueToLocalStorage(GLOBAL_USER_KEY, user);

        yield put(loadNavigationItems(user));

        if (user.accountId === account.ZHUKOV) {
            const company = yield call(api.load, 'companies', '3448349753000')
            setValueToLocalStorage(settings.TERMINAL_ADVANCED_INPUT, company.data.terminalAdvancedInput ? company.data.terminalAdvancedInput : false);
        }

        const version = yield call(api.getLastBCVersion);
        const presetVersion = getValueFromLocalStorage(BUILD_VERSION_KEY);
        if (
            version && (presetVersion === null || presetVersion !== version.data)) {
            setValueToLocalStorage(BUILD_VERSION_KEY, version.data);
            yield call(logoutSaga);
            window.location.reload(true);
        }

        if (!presetAccountId || (presetAccountId !== user.accountId)) {
            setValueToLocalStorage(ACCOUNT_ID_KEY, user && user.accountId);
        }

        setValueToLocalStorage(UPDATE_BY_SIGNALR, user.isAutoUpdate || false);

        yield put(initializeAdvancedForm());

        if (user.forcePasswordChange) {
            yield call(redirectToPasswordChangePage, payload.nextPathAfterLogin);
            return;
        }

        if (user.accountId === '12924' && user.roles.includes('checkpoint')) {
            yield put(connectToCarNumberScannerService());
        }

        yield call(redirectAfterLoginSaga, user, payload.nextPathAfterLogin);

        if (!presetAccountId || (presetAccountId !== user.accountId)) {
            location.reload(); // eslint-disable-line no-restricted-globals
        }
    } catch (err) {
        if (err instanceof LoginError) {
            yield put(loginError(err));
        } else if (err.response && err.response.status === 400) {
            if (err.response.data.error_description === "The user is blocked.") {
                yield put(loginError(new Error('Пользователь заблокирован')));
            } else if (err.response.data.error_description === "The instance is blocked.") {
                yield put(loginError(new Error('Кабинет заблокирован')));
            } else {
                yield put(loginError(new Error('Неверный логин или пароль')));
            }
        } else if (err.response && err.response.status > 400) {
            yield put(loginError(new Error('Произошла ошибка при обработке запроса на сервере, обратитесь в техподдержку или к администраторам системы')));
        } else {
            yield put(loginError(new Error('Произошла ошибка при входе в систему, обратитесь в техподдержку или к администраторам системы')));
        }
        console.error(err, err.response);
    }
}

function* redirectToPasswordChangePage(nextPathAfterLogin) {
    if (nextPathAfterLogin) {
        yield put(push(`/changePassword?next=${nextPathAfterLogin}`));
    } else {
        yield put(push('/changePassword'));
    }
}

function* redirectAfterLoginSaga(user) {
    let path = null;
    const {roles, accountId} = user;

    if (!path) {
        if (roles.includes(role.CHECKPOINT)) {
            path = '/requests?view=today';
        } else if (roles.includes(role.MAKING_AGREEMENT_BC)) {
            path = '/requests?view=agree';
        } else if (roles.includes(role.SIGHTING)) {
            path = '/documents?view=forMyVisa';
        } else if (accountId === account.COMCITY && (roles.includes(role.RECEPTION) || roles.includes(role.PARKING_OPERATOR)
            || roles.includes(role.CHECKPOINT_OPERATOR) || roles.includes(role.UNLOADING_ZONE_OPERATOR))) {
            path = '/requests?view=today';
        } else if (accountId === account.BC_BELIYE_SADY && (roles.includes(role.ADMINISTRATOR_BC)
            || roles.includes(role.TENANT) || roles.includes(role.RECEPTION))) {
            path = '/requests?view=all';
        } else if (roles.includes(role.USER)) {
            path = '/tasks?view=activeTask';
        } else if (roles.includes(role.ADMINISTRATOR_BC) || roles.includes(role.RESTRICTED_ADMINISTRATOR_BC)) {
            path = '/requests?view=today';
        } else if (roles.includes(role.SERVICE_REQUESTS_MANAGER) || roles.includes(role.TENANT) || roles.includes(role.RECEPTION)) {
            path = '/requests?view=active';
        } else if (roles.includes(role.SUPPLY_APPLICANT)) {
            path = '/supplyRequests?view=allSupplyRequests';
        } else if (roles.includes(role.ASSIGNED) && accountId === account.BINOM) {
            path = '/requests?view=active';
        } else {
            path = '/requests?view=all';
        }
    }
    yield put(push(path));
}

export function* changePasswordSaga({
                                        payload: {
                                            oldPassword,
                                            newPassword,
                                            newPasswordConfirmation,
                                            nextPathAfterLogin
                                        }
                                    }) {
    try {
        yield call(api.changePassword, oldPassword, newPassword, newPasswordConfirmation);
        yield put(passwordChanged());

        yield call(loadUserInfoSaga);
        const user = yield select(makeSelectUser());
        setValueToLocalStorage(GLOBAL_USER_KEY, user);

        yield call(redirectAfterLoginSaga, user, nextPathAfterLogin);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors._error) { // eslint-disable-line no-underscore-dangle
            const error = new Error(err.response.data.errors._error); // eslint-disable-line no-underscore-dangle
            error.data = err.response.data.errors;
            yield put(passwordChangingError(error));
        } else if (err.response && err.response.status > 400) {
            yield put(passwordChangingError(new Error('Произошла ошибка при обработке запроса на сервере, обратитесь в техподдержку или к администраторам системы')));
        } else {
            yield put(passwordChangingError(new Error('Произошла ошибка при смене пароля, обратитесь в техподдержку или к администраторам системы')));
        }
        console.error(err, err.response);
    }
}

export function* restorePasswordSaga({payload: {newPassword}}) {
    try {
        yield call(api.restorePassword, newPassword);
        yield put(passwordChanged());
        yield call(loadUserInfoSaga);
        const user = yield select(makeSelectUser());
        setValueToLocalStorage(GLOBAL_USER_KEY, user);
        yield call(redirectAfterLoginSaga, user);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors._error) { // eslint-disable-line no-underscore-dangle
            const error = new Error(err.response.data.errors._error); // eslint-disable-line no-underscore-dangle
            error.data = err.response.data.errors;
            yield put(passwordChangingError(error));
        } else if (err.response && err.response.status > 400) {
            yield put(passwordChangingError(new Error('Произошла ошибка при обработке запроса на сервере, обратитесь в техподдержку или к администраторам системы')));
        } else {
            yield put(passwordChangingError(new Error('Произошла ошибка при смене пароля, обратитесь в техподдержку или к администраторам системы')));
        }
        console.error(err, err.response);
    }
}


export function* loadUserInfoSaga() {
    yield put(loadUserInfo());

    try {
        const response = yield call(api.getCurrentEmployeeInfo);
        if (response) {
            const {accountId, companyId, id, name, isHide} = response.data;
            let {roles} = response.data;
            const mobileCheckpointIdx = roles.indexOf('mobileCheckpoint');
            if (mobileCheckpointIdx > -1) {
                response.data.roles.slice(mobileCheckpointIdx, 1, 'reception')
            }
            response.data.date = new Date()
            if (isHide) {
                throw new LoginError('Кабинет заблокирован, обратитесь в техподдержку или к администраторам системы');
            }
            if (!response || !accountId || !companyId || !id || !name || !roles || roles.length === 0) {
                throw new LoginError('Не удалось авторизоваться в системе, обратитесь в техподдержку или к администраторам системы');
            }
            yield put(addEntitiesState(COMPANIES, [{id: companyId}]));
            yield put(addEntitiesState(EMPLOYEES, [{id}]));
            yield put(userInfoLoaded(response.data)); // запись в текущего пользователя в стор
        } else {
            throw new LoginError('Не удалось авторизоваться в системе. Сотрудник не определен');
        }
    } catch (err) {
        yield put(userInfoLoadingError(err));
        throw err;
    }
}

export function* logoutSaga() {
    removeValueFromLocalStorage(GLOBAL_USER_KEY);

    const user = yield select(makeSelectUser());
    if (user.accountId === account.COMCITY && user.roles.includes(role.CHECKPOINT)) {
        yield put(disconnectFromCarNumberScannerService());
    }

    const {Authorization, ...restHeaders} = axios.defaults.headers.common; // eslint-disable-line no-unused-vars
    axios.defaults.headers.common = restHeaders;

    if (user.accountId === account.COMCITY) {
        yield put(push('/login-comcity'));
    } else if (user.accountId === account.BC_KHIMKI) {
        yield put(push('/login-khimki'));
    } else {
        yield put(push('/login'));
    }
}

export function* checkUserForBlockingSaga() {
    const activeUser = getValueFromLocalStorage(GLOBAL_USER_KEY);
    if (activeUser && activeUser.date) {
        const currentDate = new Date();
        const diffHour = ((currentDate - new Date(activeUser.date)) / (1000 * 60 * 60)).toFixed(1)
        if (diffHour > 12 || diffHour < 0) {
            const user = yield call(api.getUserByLogin, activeUser.login);
            if (user?.data[0] && user.data[0].isBlocked) {
                yield call(logoutSaga);
            } else {
                activeUser.date = currentDate
                setValueToLocalStorage(GLOBAL_USER_KEY, activeUser);
            }
        }
    } else yield call(logoutSaga);
}

export function* checkVersionSaga() {
    const activeUser = getValueFromLocalStorage(GLOBAL_USER_KEY);
    if (activeUser && activeUser.date) {
        const currentDate = new Date();
        const diffHour = ((currentDate - new Date(activeUser.date)) / (1000 * 60 * 60)).toFixed(1)
        if (diffHour > 24 || diffHour < 0) {
            const version = yield call(api.getLastBCVersion);
            if (version) {
                const bundle = version.data;
                const localVersion = getValueFromLocalStorage(BUILD_VERSION_KEY);
                if (localVersion === null || localVersion !== bundle) {
                    setValueToLocalStorage(BUILD_VERSION_KEY, bundle);
                    window.location.reload(true);
                }
            }
        }
    } else yield call(logoutSaga);
}