/**
 *
 * TasksPage
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withPrivateRoot from 'containers/App/withPrivateRoot';
import { createViewComponent } from './viewConfigs';

const styleSheet = (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
    padding: theme.spacing.unit * 2,
    width: '100%',
    height: '100%',
  },
});

export class DashboardPage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  getViewStyles() {
    const { breakpoint } = this.props;
    const styles = {
      root: {
        height: 'inherit',
      },
      dataTable: {
        position: 'relative',
      },
    };

    if (breakpoint.down('md')) {
      styles.dataTable.height = '100%';
    }

    return styles;
  }

  renderView() {
    const { userRoles } = this.props;
    const viewStyles = this.getViewStyles();

    return createViewComponent(userRoles, { viewStyles, ...this.props });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>{this.renderView()}</div>
    );
  }
}


DashboardPage.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withPrivateRoot(
  withStyles(styleSheet)(DashboardPage)
);


