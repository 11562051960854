/**
 *
 * AdvancedForm constants
 *
 */

export const INITIALIZE = 'claris-vnext/AdvancedForm/INITIALIZE';

export const LOAD_FORM_DATA = 'claris-vnext/AdvancedForm/LOAD_FORM_DATA';
export const LOAD_FORM_DATA_SUCCESS = 'claris-vnext/AdvancedForm/LOAD_FORM_DATA_SUCCESS';
export const LOAD_FORM_DATA_ERROR = 'claris-vnext/AdvancedForm/LOAD_FORM_DATA_ERROR';

export const SUBMIT_FORM = 'claris-vnext/AdvancedForm/SUBMIT_FORM';
export const SUBMIT_FORM_SUCCESS = 'claris-vnext/AdvancedForm/SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_ERROR = 'claris-vnext/AdvancedForm/SUBMIT_FORM_ERROR';

export const CALCULATE_FIELDS_VALUES = 'claris-vnext/AdvancedForm/CALCULATE_FIELDS_VALUES';

export const ADD_ENTITY_TO_NORMALIZATION_QUEUE = 'claris-vnext/AdvancedForm/ADD_ENTITY_TO_NORMALIZATION_QUEUE';
export const CLEAR_NORMALIZATION_QUEUE = 'claris-vnext/AdvancedForm/CLEAR_NORMALIZATION_QUEUE';
export const INCREMENT_REQUESTS_RECEIVED_PARAMETER = 'claris-vnext/AdvancedForm/INCREMENT_REQUESTS_RECEIVED_PARAMETER';
export const INCREMENT_REQUESTS_SENT_PARAMETER = 'claris-vnext/AdvancedForm/INCREMENT_REQUESTS_SENT_PARAMETER';

export const REGISTER = 'claris-vnext/AdvancedForm/REGISTER';
export const UNREGISTER = 'claris-vnext/AdvancedForm/UNREGISTER';

export const LOAD_DATA_TABLE = 'claris-vnext/AdvancedForm/LOAD_DATA_TABLE';
export const CLEAR_ROW_DATA = 'claris-vnext/AdvancedForm/CLEAR_ROW_DATA';
export const CHANGE_SORTING = 'claris-vnext/AdvancedForm/CHANGE_SORTING';
export const CHANGE_PAGE_NUMBER = 'claris-vnext/AdvancedForm/CHANGE_PAGE_NUMBER';
export const CHANGE_PAGE_SIZE = 'claris-vnext/AdvancedForm/CHANGE_PAGE_SIZE';
export const CHANGE_GRID_FILTERS = 'claris-vnext/AdvancedForm/CHANGE_GRID_FILTERS';
export const CALCULATE_DATA_TABLE = 'claris-vnext/AdvancedForm/CALCULATE_DATA_TABLE';
export const CHANGE_COLUMNS = 'claris-vnext/AdvancedForm/CHANGE_COLUMNS';
export const CHANGE_CURRENT_RECORD = 'claris-vnext/AdvancedForm/CHANGE_CURRENT_RECORD';
export const CHANGE_COLUMN_ORDER_TABLE = 'claris-vnext/AdvancedForm/CHANGE_COLUMN_ORDER_TABLE';
export const CHANGE_COLUMN_WIDTHS_TABLE = 'claris-vnext/AdvancedForm/CHANGE_COLUMN_WIDTHS_TABLE';
export const CHANGE_LOADER_STATE_TABLE = 'claris-vnext/AdvancedForm/CHANGE_LOADER_STATE_TABLE';
export const CHANGE_SELECTION_TABLE = 'claris-vnext/AdvancedForm/CHANGE_SELECTION_TABLE';
export const CLEAR_SELECTION_TABLE = 'claris-vnext/AdvancedForm/CLEAR_SELECTION_TABLE';
export const CHANGE_CHECKED_ALL_TABLE = 'claris-vnext/AdvancedForm/CHANGE_CHECKED_ALL_TABLE';
export const UPDATE_BUTTONS_STATE = 'claris-vnext/AdvancedForm/UPDATE_BUTTONS_STATE';
export const CHANGE_PROGRESS_VISIBILITY = 'claris-vnext/AdvancedForm/CHANGE_PROGRESS_VISIBILITY';
export const CHANGE_LAZY_LOADING_STATE = 'claris-vnext/AdvancedForm/CHANGE_LAZY_LOADING_STATE';
export const LOAD_DATA_ERROR = 'claris-vnext/AdvancedForm/LOAD_DATA_ERROR';
export const LOAD_DATA_SUCCESS_TABLE = 'claris-vnext/AdvancedForm/LOAD_DATA_SUCCESS_TABLE';
export const CHANGE_ENTITIES_TOTAL_COUNT = 'claris-vnext/AdvancedForm/CHANGE_ENTITIES_TOTAL_COUNT';
export const SET_GROUPS = 'claris-vnext/AdvancedForm/SET_GROUPS';

export const CHANGE_ROW_CONTAINER_DATA_TABLE = 'claris-vnext/AdvancedForm/CHANGE_ROW_CONTAINER_DATA_TABLE';
export const CHANGE_DETAIL_TABLE_DATA = 'claris-vnext/AdvancedForm/CHANGE_DETAIL_TABLE_DATA';
export const UPDATE_DETAIL_TABLE_STATUS = 'claris-vnext/AdvancedForm/UPDATE_DETAIL_TABLE_STATUS';
