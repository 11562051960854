/*
 *
 *  ReceptionFormDialogContainer14326
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { RECEPTION_REQUESTS_FORM_DIALOG, RECEPTION_REQUESTS_TABLE_GRID } from 'pages/Requests/ReceptionView/constants';
import { makeSelectMode } from 'containers/AdvancedFormDialog/selectors/';

import {
  executeAcceptAction, executeRejectAction, executeSendToAgreementByTenantAction,
  executeSendToPerformer, executeCloseAction, loadDialogData
} from './actions';

import ReceptionFormDialog14326 from './ReceptionFormDialog14326';
import KhimkiReceptionAcceptFormDialog from './KhimkiReceptionAcceptFormDialog';
import KhimkiReceptionAcceptForm from './KhimkiReceptionAcceptForm';

import ReceptionSendToAgreementByTenantFormDialog from './ReceptionSendToAgreementByTenantFormDialog';
import ReceptionSendToAgreementByTenantForm from './ReceptionSendToAgreementByTenantForm';

import { makeSelectRoles } from 'domain/User/selectors';
import { makeSelectCurrentRecord, makeSelectSelection, } from 'containers/AdvancedDataTable/selectors';
import { REQUESTS } from 'domain/Data/constants';
import { makeSelectDialogValue } from 'domain/Data/selectors';


class ReceptionFormDialogContainer14326 extends React.Component {

  componentDidMount() {
    const { params } = this.props
    if (params && params.requestId) {
      this.props.loadDialogData(params.requestId)
    }
  }

  render() {
    return (
      <div>
        <ReceptionFormDialog14326
          onAccept={this.props.executeAcceptAction}
          onReject={this.props.executeRejectAction}
          onSendToAgreementByTenant={this.props.executeSendToAgreementByTenantAction}
          onSendToPerformer={this.props.executeSendToPerformer}
          onClose={this.props.executeCloseAction}
          {...this.props}
        />
        <KhimkiReceptionAcceptFormDialog
          form={<KhimkiReceptionAcceptForm />}
        />
        <ReceptionSendToAgreementByTenantFormDialog
          form={<ReceptionSendToAgreementByTenantForm />}
        />
      </div>
    );
  }
}

ReceptionFormDialogContainer14326.propTypes = {
  executeAcceptAction: PropTypes.func.isRequired,
  executeRejectAction: PropTypes.func.isRequired,
  executeSendToAgreementByTenantAction: PropTypes.func.isRequired,
  executeSendToPerformer: PropTypes.func.isRequired,
  executeCloseAction: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  mode: makeSelectMode(RECEPTION_REQUESTS_FORM_DIALOG),
  currentRecord: makeSelectCurrentRecord(RECEPTION_REQUESTS_TABLE_GRID),
  selection: makeSelectSelection(RECEPTION_REQUESTS_TABLE_GRID),
  currentRole: makeSelectRoles(),
  paramsRecord: makeSelectDialogValue(REQUESTS),
});

function mapDispatchToProps(dispatch) {
  return {
    executeAcceptAction: () => dispatch(executeAcceptAction(RECEPTION_REQUESTS_FORM_DIALOG)),
    executeRejectAction: () => dispatch(executeRejectAction(RECEPTION_REQUESTS_FORM_DIALOG)),
    executeSendToAgreementByTenantAction: () => dispatch(executeSendToAgreementByTenantAction(RECEPTION_REQUESTS_FORM_DIALOG)),
    executeSendToPerformer: () => dispatch(executeSendToPerformer(RECEPTION_REQUESTS_FORM_DIALOG)),
    executeCloseAction: () => dispatch(executeCloseAction(RECEPTION_REQUESTS_FORM_DIALOG)),
    loadDialogData: (id) => dispatch(loadDialogData(REQUESTS, id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionFormDialogContainer14326);
