/*
 *
 * Global reducer
 *
 */

import { combineReducers } from 'redux-immutable';

import dataReducer from './Data/reducer';
import navigationReducer from './Navigation/reducer';
import screenReducer from './Screen/reducer';
import userReducer from './User/reducer';
import notificationsReducer from './Notifications/reducer';

const globalReducer = combineReducers({
  data: dataReducer,
  navigation: navigationReducer,
  screen: screenReducer,
  user: userReducer,
  notifications: notificationsReducer,
});

export default globalReducer;
