/**
*
* LoginPage
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import withRootWrapper from 'containers/App/withRootWrapper';

import { getValueFromLocalStorage } from 'utils/localStorage';
import { account } from 'domain/typeConstants/accounts';

import { ACCOUNT_ID_KEY } from 'global-constants';
import clarisLogo from './clarisLogo.png';
import millhouseLogo from 'custom/15755/assets/millhouse_logo_white.jpg';


const styleSheet = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    height: 'calc(100% - 56px)',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 'calc(100% - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
    },
    overflowY: 'auto',
  },
  khimkiLogo: {
    width: 111,
    height: 32,
  },
});


function withPublicRoot(Component) {
  const accountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);

  function WithPublicRoot({ classes, ...restProps }) {
    return (
      <div className={classes.root}>
        {accountId !== account.BC_KHIMKI &&
          <AppBar position="static" color="inherit">
            <Toolbar>
              {(() => {
                if (accountId === account.COMCITY) {
                  return null;
                }
                if (accountId === account.KRYLATSKI_HOLMY || accountId === account.BC_BELIYE_SADY || accountId === account.BC_4_VETRA ) {
                  return <img src={millhouseLogo} alt="Логотип Millhouse" style={{height: 32}}/>;
                }
                return <img src={clarisLogo} alt="Логотип Claris"/>;
              })()}
            </Toolbar>
          </AppBar>
        }
        <div className={classes.content}>
          <Component {...restProps} />
        </div>
      </div>
    );
  }

  WithPublicRoot.propTypes = {
    classes: PropTypes.object,
    nextPathAfterLogin: PropTypes.string,
  };

  return withRootWrapper({
    component: withStyles(styleSheet)(WithPublicRoot),
    accountId,
  });
}

export default withPublicRoot;
