/*
 *
 * RestorePasswordForm actions
 *
 */

import { SUBMIT } from './constants';

export function submit() {
  return {
    type: SUBMIT,
  };
}
