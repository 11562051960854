/**
 *
 * UnbindingAccessCardDialog selectors
 *
 */

import { createSelector } from 'reselect';


const makeSelectDomain = () => (state) => state.get('unbindingAccessCardDialog');

const makeSelectLoadingState = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('loadingState')
);

const makeSelectSnackbar = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('snackbar')
);

const makeSelectSnackbarState = () => createSelector(
  makeSelectSnackbar(),
  (snackbar) => snackbar.get('state'),
);

const makeSelectSnackbarVariant = () => createSelector(
  makeSelectSnackbar(),
  (snackbar) => snackbar.get('variant'),
);

const makeSelectSnackbarMessage = () => createSelector(
  makeSelectSnackbar(),
  (snackbar) => snackbar.get('message'),
);

export default makeSelectDomain;
export {
  makeSelectLoadingState,
  makeSelectSnackbarState,
  makeSelectSnackbarVariant,
  makeSelectSnackbarMessage,
};
