import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import AssignedView, {
  AssignedCompleteForm,
  AssignedCompleteFormDialog,
  AssignedDxGrid,
  AssignedDxGridContainer,
  AssignedForm,
  AssignedFormDialog,
  AssignedMobileGrid,
} from '../AssignedView';

const getDxGrid = (DxGrid) => (
  <AssignedDxGridContainer component={DxGrid || <AssignedDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <AssignedDxGridContainer component={MobileGrid || <AssignedMobileGrid />} />
);

const getDataTableView = ({ breakpoint, assignedDxGrid, assignedMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(assignedMobileGrid);
  }
  if (isUndefined(assignedDxGrid) || assignedDxGrid) {
    return getDxGrid(assignedDxGrid);
  }
};

const getFormDialog = (Form) => (
  <AssignedFormDialog form={Form || <AssignedForm />} />
);

const getCompleteFormDialog = (completeForm) => (
  <AssignedCompleteFormDialog form={completeForm || <AssignedCompleteForm />} />
);

export const getAssignedView = (props) => {
  const {
    assignedCompleteForm,
    assignedCompleteFormDialog,
    assignedDxGrid,
    assignedForm,
    assignedFormDialog,
    assignedMobileGrid,
    assignedView,
    breakpoint,
    viewName,
    viewStyles,
  } = props;

  if (assignedView) return assignedView;

  return (
    <AssignedView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        assignedDxGrid,
        assignedMobileGrid,
      })}
      formDialog={assignedFormDialog || getFormDialog(assignedForm)}
      assignedCompleteFormDialog={assignedCompleteFormDialog || getCompleteFormDialog(assignedCompleteForm)}
    />
  );
};

getAssignedView.propTypes = {
  assignedCompleteForm: PropTypes.node,
  assignedCompleteFormDialog: PropTypes.node,
  assignedDxGrid: PropTypes.node,
  assignedForm: PropTypes.node,
  assignedFormDialog: PropTypes.node,
  assignedMobileGrid: PropTypes.node,
  assignedView: PropTypes.node,
  breakpoint: PropTypes.object.isRequired,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
