/**
 * AdvancedDataTable state selectors
 */

import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { makeSelectEntities } from 'domain/Data/selectors';
import { mapEntityNameToSchema } from 'domain/Data/utils';

const makeSelectDomain = () => (state) => state.get('notificationsList');

const makeSelectDataIds = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.getIn(['data', 'ids']).toJS(),
);

const makeSelectData = () => createSelector(
  makeSelectDataIds(),
  makeSelectEntities(),
  (ids, entities) => selectData(ids, entities),
);

function selectData(ids, entities) {
  const denormalizedData = denormalize(
    {
      notifications: ids,
    },
    {
      notifications: [mapEntityNameToSchema('notifications')],
    },
    entities,
  );

  return Object.values(denormalizedData.notifications);
}

export default makeSelectDomain;
export {
  makeSelectData,
};
