/*
 *
 * EmailSubscriptionButton constants
 *
 */

export const SUBSCRIBE = 'claris-vnext/EmailSubscriptionButton/SUBSCRIBE';
export const SUBSCRIBE_SUCCESS = 'claris-vnext/EmailSubscriptionButton/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'claris-vnext/EmailSubscriptionButton/SUBSCRIBE_ERROR';

export const UNSUBSCRIBE = 'claris-vnext/EmailSubscriptionButton/UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = 'claris-vnext/EmailSubscriptionButton/UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_ERROR = 'claris-vnext/EmailSubscriptionButton/UNSUBSCRIBE_ERROR';