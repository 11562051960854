/**
*
* Search
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const styleSheet = {
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 48,
  },
  textField: {
    width: 0,
  },
  openTextField: {
    width: 'auto',
    marginRight: 8,
  },
  searchIcon: {
    padding: '4px',
  },
  clearButton: {
    position: 'absolute',
    right: 0,
  },
};


class Search extends React.PureComponent {
  state = {
    open: true,
    value: '',
  }

  handleChange = (event) => {
    const { value } = this.state;
    this.setState({ value: event.target.value }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(event.target.value, value);
    });
    if (event.target.value === '') {
      const { onClear, onClearSearch } = this.props;
      if (onClear) onClear();
      if (onClearSearch) onClearSearch();
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const { onSearch } = this.props;
      if (onSearch) onSearch(this.state.value);
    }
  }

  handleClearButtonClick = () => {
    this.setState({ value: '' }, () => {
      const { onClear, onClearSearch } = this.props;
      if (onClear) onClear();
      if (onClearSearch) onClearSearch();
    });
  }

  handleSearchClick = () => {
    const { onSearch } = this.props;
    if (onSearch) onSearch(this.state.value);
  }

  render() {
    const { classes, className, placeholder } = this.props;
    const { open, value } = this.state;

    return (
      <div className={classnames(classes.root, className)}>
        <IconButton
          onClick={this.handleSearchClick}
        >
          <Icon>search</Icon>
        </IconButton>
        {
          open &&
          <TextField
            className={classnames(
              classes.textField,
              {
                [classes.openTextField]: open,
              }
            )}
            value={value}
            placeholder={placeholder || 'Введите запрос'}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
        }
        {
          value &&
          <IconButton
            className={classes.clearButton}
            onClick={this.handleClearButtonClick}
          >
            <Icon>close</Icon>
          </IconButton>
        }
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onRequestSearch: PropTypes.func,
  onRequestClearSearch: PropTypes.func,
};

export default withStyles(styleSheet)(Search);
