/**
 *
 * CheckpointForm14419
 *
 */

import { CheckpointForm } from 'pages/Requests/CheckpointView';
import {
  CARRYING_OUT_WORKS,
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL,
  ON_VISIT
} from "domain/Data/RequestType/constants";
import {
  companyNameLabel,
  companyNameRequired,
  companyNameVisible,
  factoryTimeLabel,
  carryInOrTakeawayMCV,
  fireWork, productionPersonnel,
  requestScan,
  responsibilityForWork,
  responsibleForFireSafety,
  responsibleForSafetyAndHealth, shutdownEquipment,
  workPlace, workType, nonstandardCarNumberVisible
} from "../common/fields";
import { formField } from 'domain/typeConstants/form';
import * as entity from 'domain/Data/constants';
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class CheckpointForm14419 extends CheckpointForm {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'number',
        label: '№',
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        orderBy: 'name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        entityName: 'requestTypes',
        orderBy: 'order',
      },
      {
        name: 'longterm',
        type: 'checkbox',
        label: 'Долгосрочная',
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
          calculatedValue: (values) => {
            const visitDate = new Date(values.visitDate);
            return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
            )
          },
      },
      {
        name: 'expirationDate',
        type: 'date',
        label: 'Дата окончания',
        visible: (values) => values.longterm,
      },
      workType,
      {
        name: 'factoryTime',
        type: formField.SELECT,
        label: factoryTimeLabel,
        entityName: entity.TIMES,
        required: true,
        visible: (currentValues) => currentValues.type === ON_PROPERTY_BRINGING || currentValues.type === ON_PROPERTY_REMOVAL || currentValues.type === CARRYING_OUT_WORKS,
        helperText: (values) => {
          if (values.type === CARRYING_OUT_WORKS) {
            return 'Время проведения работ по правилам БП';
          }
          return null;
        },
      },
      {
        name: 'isGroupRequest',
        type: formField.CHECKBOX,
        label: 'Групповая заявка',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: true,
      },
      {
        name: 'nonstandardCarNumber',
        type: formField.CHECKBOX,
        label: 'Нестандартный № авто',
        rowBreakBefore: (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type),
        visible: nonstandardCarNumberVisible,
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (currentValues, initialValues, mode) => mode === 'view' && currentValues.groupRequestVisitorsData,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'company',
        type: 'select',
        label: 'Арендатор',
        entityName: 'companies',
        orderBy: 'name',
        numberInQueueFromEnd: 1,
        rowBreakAfter: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING,
      },
      {
        name: 'passNumber',
        label: '№ пропуска',
        visible: (values) => values.type === ON_VISIT ||
          values.type === ON_PASS_MAKING,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL || values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL || values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'whatHappened',
        label: 'Наименование\\Характер работ',
        multiline: false,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        grid: { sm: 12 },
      },
      {
        name: 'companyName',
        label: companyNameLabel,
        visible: companyNameVisible,
        required: companyNameRequired,
      },
      {
        name: 'responsibleFromContractor',
        label: 'Ответственный от подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'responsibleFromContractorPhone',
        label: 'Телефон отв. подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        disabled: true,
        grid: { sm: 12 },
      },
      workPlace,
      requestScan,
      {
        name: 'file',
        label: 'Договор подряда',
        type: formField.FILE,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: true,
        helperText: 'Скан договора подряда',
      },
      responsibilityForWork,
      responsibleForSafetyAndHealth,
      responsibleForFireSafety,
      fireWork,
      shutdownEquipment,
      carryInOrTakeawayMCV,
      productionPersonnel,
    ];
  }
}
