/**
 *
 * createAdvancedMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { downloadFile as downloadFileAction } from 'domain/Data/File/actions';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import AdvancedMobileGrid from './AdvancedMobileGrid';
import * as actionCreators from './actions';
import {
  makeSelectActionButtons,
  makeSelectActionMenuItems,
  makeSelectData,
  makeSelectFilters,
  makeSelectGridFilters,
  makeSelectIsListOver,
  makeSelectIsLoading,
  makeSelectLazyLoadingBlockSize,
  makeSelectLimit,
  makeSelectMenuItem,
  makeSelectSearchQuery,
  makeSelectSelection,
  makeSelectSorting,
  makeSelectTotalCount,
} from './selectors';
import { SETTINGS_CONFIG_NAME } from 'containers/AdvancedDataTable/constants';
import { getValueFromLocalStorage, setValueToLocalStorage } from 'utils/localStorage';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import {changeCurrentRecord} from "./actions";

function createAdvancedMobileGrid(dxGridName, entityName) {
  class AdvancedMobileGridContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

    static defaultProps = {
      totalCount: 0,
    };

    static propTypes = {
      changeMenuItem: PropTypes.func.isRequired,
      changeSearchQuery: PropTypes.func.isRequired,
      changeSelection: PropTypes.func.isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
      limit: PropTypes.number.isRequired,
      loadData: PropTypes.func.isRequired,
      onActionExecuting: PropTypes.func.isRequired,
      selection: PropTypes.arrayOf(PropTypes.string),
    };

    constructor(props) {
      super(props);
      const { loadData, limit } = props;

      if (loadData) {
        loadData({
          startAt: 1,
          limit,
        });
      }
    }

    handleRequestLoadData = (pageSize, pageNumber, sorting) =>
      this.props.loadData({ pageSize, pageNumber, sorting });

    handleSelectionChange = (selection) => this.props.changeSelection(selection);

    handleMenuItemChange = (recordId) => this.props.changeMenuItem(recordId);

    handleActionButtonClick = (actionName) => {
      const { onActionExecuting, selection } = this.props;
      if (onActionExecuting) onActionExecuting(actionName, selection);
    };

    handleActionMenuItemClick = (actionName, record) => {
      const { onActionExecuting } = this.props;
      if (onActionExecuting) onActionExecuting(actionName, [record.id]);
    };

    handleChangeSearchQuery = (searchQuery) => {
      const { changeSearchQuery } = this.props;
      if (changeSearchQuery) changeSearchQuery(searchQuery);
    };

    handleClearFilters = (gridFilters) => {
      const { changeGridFilters, accountId, viewName } = this.props;
      if (changeGridFilters) {
        changeGridFilters(gridFilters)

        const accessKey = `${accountId}_${dxGridName}_${viewName}_mobileTable`;
        let localStorageConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
        const config = localStorageConfig[accessKey];
        const filters = config[settings.CUSTOM_FILTERING].value
        config[settings.CUSTOM_FILTERING].value = filters.map(f => ({ ...f, outputValue: '' }));
        setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageConfig)
      }
    }

    render() {
      const {
        data,
        changeSearchQuery,
        ...restProps
      } = this.props;

      return (
        <AdvancedMobileGrid
          entityName={entityName}
          name={dxGridName}
          onActionButtonClick={this.handleActionButtonClick}
          onActionMenuItemClick={this.handleActionMenuItemClick}
          onMenuItemChange={this.handleMenuItemChange}
          onRequestLoadData={this.handleRequestLoadData}
          onSelectionChange={this.handleSelectionChange}
          onSortingChanged={this.handleSortingChange}
          onChangeSearchQuery={this.handleChangeSearchQuery}
          onClearFilters={this.handleClearFilters}
          rows={data}
          {...restProps}
        />
      );
    }
  }

  const mapStateToProps = createStructuredSelector({
    actionButtons: makeSelectActionButtons(dxGridName),
    actionMenuItems: makeSelectActionMenuItems(dxGridName),
    data: makeSelectData(dxGridName, entityName),
    filters: makeSelectFilters(dxGridName),
    gridFilters: makeSelectGridFilters(dxGridName),
    isListOver: makeSelectIsListOver(dxGridName),
    isLoading: makeSelectIsLoading(dxGridName),
    lazyLoadingBlockSize: makeSelectLazyLoadingBlockSize(dxGridName),
    limit: makeSelectLimit(dxGridName),
    menuItem: makeSelectMenuItem(dxGridName),
    searchQuery: makeSelectSearchQuery(dxGridName),
    selection: makeSelectSelection(dxGridName),
    sorting: makeSelectSorting(dxGridName),
    totalCount: makeSelectTotalCount(dxGridName, entityName),
    viewMode: makeSelectViewMode(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      changeIsLoading: (isLoading) => dispatch(actionCreators.changeIsLoading(dxGridName, isLoading)),
      changeMenuItem: (recordId) => dispatch(actionCreators.changeMenuItem(dxGridName, recordId)),
      changeSearchQuery: (searchQuery) => dispatch(actionCreators.changeSearchQuery(dxGridName, searchQuery)),
      changeSelection: (selection) => dispatch(actionCreators.changeSelection(dxGridName, selection)),
      changeCurrentRecord: (record) => dispatch(actionCreators.changeCurrentRecord(dxGridName, record)),
      changeSorting: (sorting) => dispatch(actionCreators.changeSorting(dxGridName, sorting)),
      changeGridFilters: (gridFilters) => dispatch(actionCreators.changeGridFilters(dxGridName, gridFilters)),
      downloadFile: (fileId) => dispatch(downloadFileAction(fileId)),
      loadData: (options) => dispatch(actionCreators.loadData(dxGridName, options)),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(AdvancedMobileGridContainer);
}

export default createAdvancedMobileGrid;
