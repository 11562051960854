/**
 *
 * getViewConfig Function
 *
 */
import { role } from 'domain/typeConstants/roles';

import {
  getAdministratorBCView,
  getApplicantView,
  getAssignedView,
  getCheckpointOperatorView,
  getCheckpointView,
  getMakingAgreementBCView,
  getParkingOperatorView,
  getReceptionView,
  getRestrictedAdministratorBCView,
  getSeniorParkingOperatorView,
  getServiceRequestsManagerView,
  getTenantView,
  getUnloadingZoneOperatorView,
  getAdminSkudView,
} from './getRoleView';

import getSagasConfig from './getSagasConfig';

import createApplicantViewSaga from './ApplicantView/sagas';
import createAssignedViewSaga from './AssignedView/sagas';
import createCheckpointViewSaga from './CheckpointView/sagas';
import createReceptionViewSaga from './ReceptionView/sagas';
import createServiceRequestsManagerViewSaga from './ServiceRequestsManagerView/sagas';
import createTenantViewSaga from './TenantView/sagas';
import createMakingAgreementBCSaga from './MakingAgreementBCView/sagas';
import createCheckpointOperatorSaga from './CheckpointOperator/sagas';
import createParkingOperatorSaga from './ParkingOperator/sagas';
import createSeniorParkingOperatorSaga from './SeniorParkingOperator/sagas';
import createUnloadingZoneOperatorSaga from './UnloadingZoneOperator/sagas';
import createAdminSkudSaga from './AdminSkud/sagas';

const defaultViewConfig = {
  createComponent: getReceptionView,
  createSaga: (sagaConfig) => createReceptionViewSaga(sagaConfig.receptionViewSaga),
};

const viewConfigs = [
  {
    role: role.ADMINISTRATOR_BC,
    createComponent: getAdministratorBCView,
    createSaga: (sagaConfig) => createReceptionViewSaga(sagaConfig.receptionViewSaga),
  },
  {
    role: role.RESTRICTED_ADMINISTRATOR_BC,
    createComponent: getRestrictedAdministratorBCView,
    createSaga: (sagaConfig) => createReceptionViewSaga(sagaConfig.receptionViewSaga),
  },
  {
    role: role.TENANT,
    createComponent: getTenantView,
    createSaga: (sagaConfig) => createTenantViewSaga(sagaConfig.tenantViewSaga),
  },
  {
    role: role.CHECKPOINT,
    createComponent: getCheckpointView,
    createSaga: (sagaConfig) => createCheckpointViewSaga(sagaConfig.checkpointViewSaga),
  },
  {
    role: role.APPLICANT,
    createComponent: getApplicantView,
    createSaga: (sagaConfig) => createApplicantViewSaga(sagaConfig.applicantViewSaga),
  },
  {
    role: role.ASSIGNED,
    createComponent: getAssignedView,
    createSaga: (sagaConfig) => createAssignedViewSaga(sagaConfig.assignedViewSaga),
  },
  {
    role: role.SERVICE_REQUESTS_MANAGER,
    createComponent: getServiceRequestsManagerView,
    createSaga: (sagaConfig) => createServiceRequestsManagerViewSaga(sagaConfig.serviceRequestsManagerViewSaga),
  },
  {
    role: role.MAKING_AGREEMENT_BC,
    createComponent: getMakingAgreementBCView,
    createSaga: (sagaConfig) => createMakingAgreementBCSaga(sagaConfig.makingAgreementViewBCSaga),
  },
  {
    role: role.CHECKPOINT_OPERATOR,
    createComponent: getCheckpointOperatorView,
    createSaga: (sagaConfig) => createCheckpointOperatorSaga(sagaConfig.checkpointViewSaga),
  },
  {
    role: role.PARKING_OPERATOR,
    createComponent: getParkingOperatorView,
    createSaga: (sagaConfig) => createParkingOperatorSaga(sagaConfig.parkingOperatorViewSaga),
  },
  {
    role: role.SENIOR_PARKING_OPERATOR,
    createComponent: getSeniorParkingOperatorView,
    createSaga: (sagaConfig) => createSeniorParkingOperatorSaga(sagaConfig.seniorParkingOperatorViewSaga),
  },
  {
    role: role.UNLOADING_ZONE_OPERATOR,
    createComponent: getUnloadingZoneOperatorView,
    createSaga: (sagaConfig) => createUnloadingZoneOperatorSaga(sagaConfig.unloadingZoneOperatorViewSaga),
  },
  {
    role: role.ADMIN_SKUD,
    createComponent: getAdminSkudView,
    createSaga: (sagaConfig) => createAdminSkudSaga(sagaConfig.adminSkudViewSaga),
  },
];

export function createViewComponent(userRoles, props) {
  const config = viewConfigs.find((c) => userRoles.includes(c.role));
  if (config) {
    return config.createComponent(props);
  }

  return defaultViewConfig.createComponent(props);
}

export function createViewSaga(userRoles, accountId) {
  const config = viewConfigs.find((c) => userRoles.includes(c.role));
  if (config) {
    return config.createSaga(getSagasConfig(accountId));
  }

  return defaultViewConfig.createSaga(getSagasConfig(accountId));
}
