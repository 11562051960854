/**
 *
 * AccessLevel14326 constants
 *
 */

export const EMPLOYEE_ACCESS_LEVEL = '4022245336000';
export const OFFICE_MANAGER_ACCESS_LEVEL = '4022245340000';
export const ADDITIONAL_ACCESS_LEVEL = '4022306537000';
export const BLOCK_CARD_ACCESS_LEVEL = '4022306536000';
