/*
 *
 * ServiceRequestsManagerFormDialog14326
 *
 */

import React from 'react';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import PropTypes from 'prop-types';

import { requestAction } from 'domain/typeConstants/action';
import { ACCEPTED, ON_TERRITORY, CLOSED, COMPLETED, IN_PROGRESS, CREATED, ON_AGREEMENT } from 'domain/Data/RequestStatus/constants'
import { CARRYING_OUT_WORKS, SERVICE } from 'domain/Data/RequestType/constants';
import {
  SERVICE_REQUESTS_MANAGER_REQUESTS_FORM,
  SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG,
  SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID
}
  from 'pages/Requests/ServiceRequestsManagerView/constants';
import { role as roleType } from 'domain/typeConstants/roles';
import { REQUESTS } from 'domain/Data/constants';


export class ServiceRequestsManagerFormDialog14326 extends React.Component {

  handleAcceptButtonClick = () => this.props.onAccept();
  handleRejectButtonClick = () => this.props.onReject();
  handleCloseButtonClick = () => this.props.onClose();
  handleSendToPerformerButtonClick = () => this.props.onSendToPerformer();
  handleSendToAgreementByTenantButtonClick = () => this.props.onSendToAgreementByTenant();

  renderCustomButtons() {
    const { mode, currentRole, currentRecord, selection } = this.props;
    if (mode !== 'edit') return null;

    const status = currentRecord ? currentRecord.status.id : selection && selection.length > 0 ? selection[0]?.status?.id : null
    const type = currentRecord ? currentRecord.type.id : selection && selection.length > 0 ? selection[0]?.type?.id : null

    const isAvailableRole = currentRole.includes(roleType.RECEPTION) || currentRole.includes(roleType.ADMINISTRATOR_BC)
    const isCarryingOutWorks = isAvailableRole && type === CARRYING_OUT_WORKS && ([CREATED, ON_AGREEMENT].includes(status)) ? true : false
    const isService = isAvailableRole && type === SERVICE && ([CREATED, ON_AGREEMENT].includes(status)) ? true : false

    return [
      {
        name: requestAction.SEND_TO_PERFORMER,
        label: 'Отправить Исполнителю',
        onClick: this.handleSendToPerformerButtonClick,
        visible: isService
      },
      {
        name: requestAction.SEND_TO_AGREEMENT_BY_TENANT,
        label: 'Согласовать с Арендатором',
        onClick: this.handleSendToAgreementByTenantButtonClick,
        visible: isCarryingOutWorks
      },
      {
        name: requestAction.ACCEPT,
        label: 'Согласовать',
        onClick: this.handleAcceptButtonClick,
        visible: ![ACCEPTED, ON_TERRITORY, CLOSED, COMPLETED, IN_PROGRESS].includes(status) &&
          type !== SERVICE
      },
      {
        name: requestAction.REJECT,
        label: 'Отклонить',
        onClick: this.handleRejectButtonClick,
        visible: ![ACCEPTED, ON_TERRITORY, CLOSED, COMPLETED, IN_PROGRESS].includes(status)
      },
      {
        name: requestAction.CLOSE,
        label: 'Закрыть',
        onClick: this.handleCloseButtonClick,
        visible: type === SERVICE && status === COMPLETED ? true : false
      },
    ];
  }

  render() {
    const { form, detailContainer } = this.props;
    return (
      <AdvancedFormDialog
        name={SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG}
        formName={SERVICE_REQUESTS_MANAGER_REQUESTS_FORM}
        dataTableName={SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID}
        advancedForm={form}
        addModeTitle="Новая заявка"
        editModeTitle="Изменение заявки"
        viewModeTitle="Просмотр заявки"
        copyModeTitle="Копирование заявки"
        addModeSaveButtonLabel="Сохранить и Отправить"
        copyModeSaveButtonLabel="Сохранить и Отправить"
        customButtons={this.renderCustomButtons()}
        detailContainer={detailContainer}
        entityName={REQUESTS}
      />
    );
  }
}

ServiceRequestsManagerFormDialog14326.propTypes = {
  form: PropTypes.node.isRequired,
  mode: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ServiceRequestsManagerFormDialog14326;
