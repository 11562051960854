import { HttpConnection, HubConnection } from '@aspnet/signalr-client/dist/browser/signalr-clientES5-1.0.0-alpha2-final';

import config from 'config';
import { scanPassport } from './actions';

const createScannerMiddleware = () => (store) => {
  connectToService(store).catch(() => undefined);
  return (next) => (action) => next(action);
};

const connectToService = (store) => {
  const httpConnection = new HttpConnection(`${config.scannerServiceUrl}/DocumentScanerHub`);
  const hubConnection = new HubConnection(httpConnection);
  hubConnection.on('ScanReady', createScanPassportHandler(store));
  return hubConnection.start();
};

const createScanPassportHandler = (store) => (data) => store.dispatch(scanPassport(data));

export default createScannerMiddleware;
