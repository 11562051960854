/**
 *
 * SelectCheckBoxCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createSelectCheckBoxContainer from './createSelectCheckBoxContainer';

class SelectCheckBoxCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const SelectCheckBoxContainer = createSelectCheckBoxContainer(this.context.advancedForm, this.props.name);
    return <SelectCheckBoxContainer {...this.props} />;
  }
}

SelectCheckBoxCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

SelectCheckBoxCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SelectCheckBoxCreator;