/**
*
* DataTablePagination
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { createPaginationData } from 'containers/AdvancedDataTable/utils';

const styleSheet = {
  root: {
    padding: 5,
    overflow: "hidden",
    display: "flex",
    justifyContent: "flex-end",
    minHeight: 48,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "13px",
    fontWeight: 400,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "1.375em",
    paddingRight: 20,
  },
  block: {
    paddingRight: 30
  },
};

class DataTablePagination extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  handlePageNumberChange = (newPageNumber) => {
    const { onPageNumberChange } = this.props;
    if (onPageNumberChange) onPageNumberChange(newPageNumber);
  }

  handleChangePageSize = (e) => {
    const { onPageSizeChange } = this.props
    if (onPageSizeChange) onPageSizeChange(e.target.value)
  }

  getNavigationMessage = () => {
    const { classes, pageSize, pageNumber, totalCount, } = this.props;
    const pageFrom = (pageNumber - 1) * pageSize + 1
    const pageTo = pageNumber * pageSize > totalCount ? totalCount : pageNumber * pageSize
    return (<div className={classes.block}>
      {/* <span>показаны записи с {pageFrom} по {pageTo} из {totalCount}</span> */}
      <span>с {pageFrom} по {pageTo} из {totalCount}</span>
    </div>
    )
  }

  getRowPerPage = () => {
    const { classes, pageSize, pageSizes, } = this.props;
    return (<div className={classes.block}>
      {/* <span className={classes.block}>Отображать строк на странице</span> */}
      <TextField
        id="pageSizes"
        select
        value={pageSize}
        onChange={this.handleChangePageSize}
        InputProps={{
          disableUnderline: true,
          style: { fontSize: 14 },
        }}
      >
        {pageSizes.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </div>
    )
  }

  getPagination = () => {
    const {
      pageSize,
      pageNumber,
      totalCount,
    } = this.props;

    const panel = createPaginationData(totalCount, pageSize, pageNumber)
    return (
      <div>
        <IconButton
          disabled={panel.previous}
          onClick={() => this.handlePageNumberChange(pageNumber - 1)}>
          <ChevronLeft />
        </IconButton>

        {panel.list.map((page, idx) => {
          if (page > 0) {
            return (
              <Button
                key={idx}
                style={{ minWidth: "auto", fontWeight: page === pageNumber && 600 }}
                onClick={() => this.handlePageNumberChange(page)}
              >
                {page}
              </Button>
            )
          } return <span key={idx}>...</span>
        })}

        <IconButton
          disabled={panel.next}
          onClick={() => this.handlePageNumberChange(pageNumber + 1)}>
          <ChevronRight />
        </IconButton>
      </div>
    )
  }

  render() {
    const { classes, width } = this.props;

    return (
      <Toolbar className={classes.root}
        style={{
          width: width, 
          backgroundColor: "#e5e8e67a",
          height: "32px", 
          minHeight: "24px"
        }}
      >
        {this.getNavigationMessage()}
        {this.getRowPerPage()}
        {this.getPagination()}
      </Toolbar >
    );
  }
}

DataTablePagination.propTypes = {
  classes: PropTypes.object,
  dense: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFirstPageButtonClick: PropTypes.func,
  onPreviousPageButtonClick: PropTypes.func,
  onNextPageButtonClick: PropTypes.func,
  onLastPageButtonClick: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onPageNumberChange: PropTypes.func,
};

export default withStyles(styleSheet)(DataTablePagination);
