/**
 *
 * RequestsPage14326
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';

import {
  ReceptionDxGrid14326,
  ReceptionForm14326,
  VisitorArrivalForm14326,
  ReceptionRejectForm14326,
  ReceptionMobileGrid14326,
} from './ReceptionView';

import ReceptionFormDialogContainer14326 from './ReceptionView/ReceptionFormDialogContainer14326';

import {
  TenantDxGrid14326,
  TenantForm14326,
  TenantFormDialog14326,
} from './TenantView';

import {
  CheckpointDxGrid14326,
  CheckpointFormDialog14326,
} from './CheckpointView';

import {
  ServiceRequestsManagerForm14326,
  ServiceRequestsManagerDxGrid14326,
  ServiceRequestsManagerFormDialogContainer14326,
  ServiceRequestsManagerRejectForm14326,
  ServiceRequestsManagerAcceptForm14326,
  ServiceRequestsManagerMobileGrid14326,
} from './ServiceRequestsManagerView';

import {
  AssignedForm14326,
  AssignedDxGrid14326,
  AssignedFormDialog14326,
} from './AssignedView';

import {
  AdministratorBCRequestsForm14326,
  AdministratorBCServiceRequestsDxGrid14326,
  AdministratorBCRequestsDxGrid14326,
  AdministratorBCRequestsFormDialogContainer14326,
  AdministratorBCServiceRequestsFormDialogContainer14326,
  AdministratorBCRequestsRejectForm14326,
  AdministratorBCServiceRequestsRejectForm14326,
  AdministratorBCServiceRequestsAcceptForm14326,
  AdministratorBCRequestsMobileGrid14326,
  AdministratorBCServiceRequestsMobileGrid14326,
} from './AdministratorBCView';
import { ReceptionRejectFormDialog } from '../../../../pages/Requests/ReceptionView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';


function RequestsPage14326(props) {

  return (
    <RequestsPage
      administratorBCForm={<AdministratorBCRequestsForm14326 />}
      administratorBCVisitorArrivalForm={<VisitorArrivalForm14326 />}
      administratorBCDxGrid={<AdministratorBCRequestsDxGrid14326 />}
      administratorBCRejectForm={<AdministratorBCRequestsRejectForm14326 />}
      administratorBCServiceForm={<ServiceRequestsManagerForm14326 />}
      administratorBCServiceFormDialog={
        <AdministratorBCServiceRequestsFormDialogContainer14326
          form={<ServiceRequestsManagerForm14326 />}
        />}
      administratorBCServiceDxGrid={<AdministratorBCServiceRequestsDxGrid14326 />}
      administratorBCFormDialog={<AdministratorBCRequestsFormDialogContainer14326
        params={props.params}
        form={<AdministratorBCRequestsForm14326 />}
        detailContainer={getRequestDetailContainer()}
      />}
      administratorBCServiceRejectForm={<AdministratorBCServiceRequestsRejectForm14326 />}
      administratorBCServiceAcceptForm={<AdministratorBCServiceRequestsAcceptForm14326 />}
      administratorBCServiceMobileGrid={<AdministratorBCServiceRequestsMobileGrid14326 />}
      administratorBCMobileGrid={<AdministratorBCRequestsMobileGrid14326 />}

      receptionDxGrid={<ReceptionDxGrid14326 />}
      receptionForm={<ReceptionForm14326 />}
      rejectFormDialog={<ReceptionRejectFormDialog form={<ReceptionRejectForm14326 />} />}
      receptionFormDialog={
        <ReceptionFormDialogContainer14326
          form={<ReceptionForm14326 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      receptionMobileGrid={<ReceptionMobileGrid14326 />}
      visitorArrivalForm={<VisitorArrivalForm14326 />}

      tenantDxGrid={<TenantDxGrid14326 />}
      tenantFormDialog={
        <TenantFormDialog14326
          form={<TenantForm14326 />}
        />
      }

      checkpointForm={<TenantForm14326 />}
      checkpointDxGrid={<CheckpointDxGrid14326 />}
      checkpointFormDialog={
        <CheckpointFormDialog14326
          form={<TenantForm14326 />}
        />
      }

      serviceRequestsManagerForm={<ServiceRequestsManagerForm14326 />}
      serviceRequestsManagerDxGrid={<ServiceRequestsManagerDxGrid14326 />}
      serviceRequestsManagerFormDialog={
        <ServiceRequestsManagerFormDialogContainer14326
          form={<ServiceRequestsManagerForm14326 />}
        />
      }
      serviceRequestsManagerRejectForm={<ServiceRequestsManagerRejectForm14326 />}
      serviceRequestsManagerAcceptForm={<ServiceRequestsManagerAcceptForm14326 />}
      serviceRequestsManagerMobileGrid={<ServiceRequestsManagerMobileGrid14326 />}

      assignedForm={<AssignedForm14326 />}
      assignedDxGrid={<AssignedDxGrid14326 />}
      assignedFormDialog={
        <AssignedFormDialog14326
          form={<AssignedForm14326 />}
        />}

      {...props}
    />
  );
}

export default RequestsPage14326;
