/**
*
* MessageField
*
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  message: {
    fontSize: '12px',
    color: 'red',
    margin: 'auto'
  }
};

class MessageField extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  render() {
    const { classes, input } = this.props;
    return (
      <div className={classes.message}>{input.value}</div>
    )
  }
}

export default withStyles(styles)(MessageField);
