/**
 *
 * RequestType14140 constants
 *
 */

export const MAKING_PASS_ON_THE_CAR = '4014367125000';
export const ON_LOSS_OR_BREAK_OF_PASS = '4016501825000';
export const ON_RETURN_CARDS = '4016501833000';
export const ON_CHECK_CARD = '4016501891000';
