/**
 *
 *  ParkingOperatorDxGrid12970
 *
 */

import { ParkingOperatorDxGrid } from 'pages/Requests/ParkingOperator';

import { requestAction } from 'domain/typeConstants/action';

import {
  CURRENT_DAY_REQUESTS_FOR_COMCITY_PARKING_OPERATOR,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_COMCITY_PARKING_OPERATOR,
} from 'domain/Data/Filters/constants';

import { parkingOperatorArrivedActionButton, parkingOperatorDepartedActionButton } from '../common/actionButton';
import { parkingOperatorArrivedActionItem, parkingOperatorDepartedActionItem } from '../common/actionMenu';

class ParkingOperatorDxGrid12970 extends ParkingOperatorDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_COMCITY_PARKING_OPERATOR];
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS_FOR_COMCITY_PARKING_OPERATOR);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    return filters;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();
    const arrivedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    const departedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.DEPARTED);
    actionButtons[arrivedButtonIndex] = parkingOperatorArrivedActionButton;
    actionButtons[departedButtonIndex] = parkingOperatorDepartedActionButton;

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();
    const arrivedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.ARRIVED);
    const departedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.DEPARTED);
    actionMenuItems[arrivedActionMenuIndex] = parkingOperatorArrivedActionItem;
    actionMenuItems[departedActionMenuIndex] = parkingOperatorDepartedActionItem;

    return actionMenuItems;
  }
}

export default ParkingOperatorDxGrid12970;
