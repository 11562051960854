/**
 *
 * createSelectFieldContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {clearSubmitErrors} from 'redux-form/immutable';
import * as actionCreators from './actions';
import {
    makeSelectIsLoaded,
    makeSelectIsCached,
    makeSelectOptions,
} from './selectors';
import {
    makeSelectRecord,
    makeSelectMode,
    makeSelectCurrentValues,
    makeSelectDetailTableData, makeSelectUpdateDetailTable,
} from '../selectors';
import DetailTableField from './DetailTableField';
import {executeAddAction, executeEditAction} from 'pages/Positions/PositionsView/actions';

function createDetailTableFieldContainer(formName, fieldName) {

    class DetailTableFieldContainer extends React.PureComponent {
        render() {
            return (
                <DetailTableField {...this.props}/>
            );
        }
    }

    DetailTableFieldContainer.contextTypes = {
        advancedForm: PropTypes.string.isRequired,
    };

    DetailTableFieldContainer.propTypes = {
        cached: PropTypes.bool.isRequired,
        clearErrors: PropTypes.func.isRequired,
        clearErrorsOnChange: PropTypes.bool,
        loaded: PropTypes.bool.isRequired,
        loadOptions: PropTypes.func.isRequired,
        mode: PropTypes.string,
        multiple: PropTypes.bool,
        name: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        record: PropTypes.object,
    };

    const mapStateToProps = createStructuredSelector({
        loaded: makeSelectIsLoaded(formName, fieldName),
        cached: makeSelectIsCached(formName, fieldName),
        options: makeSelectOptions(formName, fieldName),
        record: makeSelectRecord(formName),
        mode: makeSelectMode(formName),
        currentValues: makeSelectCurrentValues(formName),
        data: makeSelectDetailTableData(formName, 'documentPositions'),
        update: makeSelectUpdateDetailTable(formName),
    });

    const mapDispatchToProps = (dispatch) => ({
        loadOptions: () => dispatch(actionCreators.loadOptions(formName, fieldName)),
        clearErrors: () => dispatch(clearSubmitErrors(formName)),
        addRecord: () => dispatch(executeAddAction()),
        editRecord: (id) => dispatch(executeEditAction(id)),
    });


    return connect(mapStateToProps, mapDispatchToProps)(DetailTableFieldContainer);
}

export default createDetailTableFieldContainer;
