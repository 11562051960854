import React, { PureComponent } from 'react';
import {
    BarChart,
    Bar,
    Brush,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    //   ResponsiveContainer,
} from 'recharts';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/index';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';
import MuiTooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import { getValueFromLocalStorage, setValueToLocalStorage } from 'utils/localStorage';
import { SETTINGS_CONFIG_NAME } from 'containers/AdvancedDataTable/constants';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { convertDatetoString } from 'utils/dateUtils';


const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    background: 'white',
                    opacity: 0.7,
                    borderRadius: 10,
                    color: 'black',
                    border: '1px solid grey',
                }}
            >
                <label style={{ fontSize: 14, fontStyle: "bold", padding: 5 }}
                >{payload[0].payload.id} : {payload[0].payload.value}</label>
            </div >
        );
    }
    return null
};


export default class BarChartWidget extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: null,
            toDate: null,
        };

        const { range, entity, saveRange } = this.props
        if (!range) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const firstDayMonth = new Date(year, month, 1);

            this.state = {
                fromDate: firstDayMonth,
                toDate: currentDate,
            };
            saveRange(entity, this.state)
        }
        else {
            this.state = {
                fromDate: range.fromDate,
                toDate: range.toDate,
            };
        }
    }


    componentDidMount() { this.updateChart() }

    updateChart = () => {
        const { loadData, entity } = this.props;
        const { fromDate, toDate } = this.state;
        const tomorrow = new Date(toDate);
        tomorrow.setDate(toDate.getDate() + 1)
        const options = { fromDate: convertDatetoString(fromDate), toDate: convertDatetoString(tomorrow) }
        loadData && loadData(entity, options)
    }


    getData = () => {
        const { data } = this.props;
        return data && data.map((item, idx) => {
            return (
                {
                    value: item.count,
                    id: item.id,
                    row: idx,
                }
            )
        });
    }


    chartClick = function (value) {
        const { navigate, link, accessKey, field, setFilter } = this.props;
        const { fromDate, toDate } = this.state;
        let localStorageConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
        const filters = localStorageConfig[accessKey]?.[settings.CUSTOM_FILTERING].value;

        if (filters) {
            const newFilters = filters.map(filter => {
                if (filter.columnName === field) {
                    return { ...filter, outputValue: value }
                }
                if (filter.columnName === 'actualCreationDate') {
                    return {
                        ...filter, outputValue: { fromValue: convertDatetoString(fromDate), toValue: convertDatetoString(toDate) }
                    }
                }
                else return { ...filter, outputValue: '' }
            })
            localStorageConfig[accessKey][settings.CUSTOM_FILTERING].value = newFilters;
            setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageConfig);
        } else {
            const filterData = {
                columnName: { name: field, value },
                'actualCreationDate': { fromValue: convertDatetoString(fromDate), toValue: convertDatetoString(toDate) },
            }
            setFilter(accessKey, filterData)
        }
        navigate(link)
        return;
    }

    handleChangeDateRangeClick = (e) => this.setState({ [e.target.name]: new Date(e.target.value) })

    updateClick = () => {
        const { entity, saveRange } = this.props
        saveRange(entity, this.state)
        this.updateChart();
    }

    renderTitle = () => {
        const { title } = this.props
        const { fromDate, toDate } = this.state

        return (
            <Grid container style={{ display: "flex", flexDirection: "column" }}>
                <Grid item><Typography variant="title" style={{ padding: 10 }}>{title}</Typography></Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <Typography variant="subheading">Дата: </Typography>
                    <Grid container style={{ alignItems: 'center' }}>
                        <Grid item>
                            <span style={{ padding: '10px' }}>с</span>
                            <TextField
                                name="fromDate"
                                value={fromDate && convertDatetoString(fromDate)}
                                type="date"
                                onChange={this.handleChangeDateRangeClick}
                            />
                        </Grid>

                        <Grid item>
                            <span style={{ padding: '10px' }}>по</span>
                            <TextField
                                name="toDate"
                                value={toDate && convertDatetoString(toDate)}
                                type="date"
                                onChange={this.handleChangeDateRangeClick}
                            />
                        </Grid>

                        <Grid item>
                            <MuiTooltip
                                id="tooltip-fab"
                                placement="bottom"
                                title={"Обновить"}
                            >
                                <IconButton
                                    onClick={this.updateClick}
                                    size="small"
                                    variant="fab"
                                >
                                    <AutorenewIcon />
                                </IconButton>
                            </MuiTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    render() {
        const chartData = this.getData();
        return (
            <Paper
                style={{ width: 630, height: 420 }}
                elevation={4}>
                {this.renderTitle()}
                <BarChart
                    width={600}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 15,
                        right: 0,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis dataKey="row" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="value" fill="#8884d8" onClick={(e) => this.chartClick(chartData[e.row].id)}
                        style={{ cursor: "pointer" }}
                    />
                    <Brush dataKey="row" height={30} stroke="#8884d8" />
                </BarChart>
            </Paper>
        );
    }
}
