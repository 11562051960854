import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import {
  REQUESTS_PATH,
  SERVICE_REQUESTS_PATH,
} from 'domain/Navigation/constants';

import ReceptionView, {
  BlackListForm,
  BlackListFormDialog,
  ReceptionDxGridContainer,
  ReceptionForm,
  ReceptionFormDialog,
  ReceptionMobileGrid,
  ReceptionRejectForm,
  ReceptionRejectFormDialog,
  VisitorArrivalForm,
  VisitorArrivalFormDialog,
  WorkForm,
  WorkFormDialog,
} from '../ReceptionView';

import {
  AgreementDialog,
} from '../common/AgreementDialog';

import ServiceRequestsManagerView, {
  ServiceRequestsManagerAcceptForm,
  ServiceRequestsManagerAcceptFormDialog,
  ServiceRequestsManagerCompleteForm,
  ServiceRequestsManagerCompleteFormDialog,
  ServiceRequestsManagerDxGrid,
  ServiceRequestsManagerDxGridContainer,
  ServiceRequestsManagerForm,
  ServiceRequestsManagerFormDialog,
  ServiceRequestsManagerMobileGrid,
  ServiceRequestsManagerRejectForm,
  ServiceRequestsManagerRejectFormDialog,
  ServiceRequestsManagerBlackListForm,
  ServiceRequestsManagerBlackListFormDialog,
  ServiceRequestsManagerVisitorArrivalForm,
  ServiceRequestsManagerVisitorArrivalFormDialog,
} from '../ServiceRequestsManagerView';

import { AdministratorBCDxGrid, AdministratorBCForm } from '../AdministratorBCView'
import { getRequestDetailContainer } from 'pages/common/detailContainers';

/** request components */
const getBlackListFormDialog = (blackListForm) => (
  <BlackListFormDialog form={blackListForm || <BlackListForm />} />
);

const getVisitorArrivalFormDialog = (visitorArrivalForm) => (
  <VisitorArrivalFormDialog form={visitorArrivalForm || <VisitorArrivalForm />} />
);

const getDxGrid = (dxGrid) => (
  <ReceptionDxGridContainer component={dxGrid || <AdministratorBCDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <ReceptionDxGridContainer component={MobileGrid || <ReceptionMobileGrid />} />
);

const getFormDialog = (form) => (
  <ReceptionFormDialog
    form={form || <AdministratorBCForm />}
    detailContainer={getRequestDetailContainer()}
  />
);

const getWorkFormDialog = () => (
  <WorkFormDialog form={<WorkForm />} />
);

const getRejectFormDialog = (rejectForm) => (
  <ReceptionRejectFormDialog form={rejectForm || <ReceptionRejectForm />} />
);

const getAgreementDialog = () => <AgreementDialog />;

const getDataTableView = ({ breakpoint, administratorBCDxGrid, administratorBCDataTable, administratorBCMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(administratorBCMobileGrid);
  }
  if (isUndefined(administratorBCDxGrid) || administratorBCDxGrid) {
    return getDxGrid(administratorBCDxGrid);
  }
};

/** service request components */
const getServiceAcceptFormDialog = (acceptForm) => (
  <ServiceRequestsManagerAcceptFormDialog form={acceptForm || <ServiceRequestsManagerAcceptForm />} />
);

const getServiceCompleteFormDialog = (completeForm) => (
  <ServiceRequestsManagerCompleteFormDialog form={completeForm || <ServiceRequestsManagerCompleteForm />} />
);

const getServiceRejectFormDialog = (rejectForm) => (
  <ServiceRequestsManagerRejectFormDialog form={rejectForm || <ServiceRequestsManagerRejectForm />} />
);

const getServiceFormDialog = (form) => (
  <ServiceRequestsManagerFormDialog form={form || <ServiceRequestsManagerForm />} />
);

const getServiceDxGrid = (dxGrid) => (
  <ServiceRequestsManagerDxGridContainer component={dxGrid || <ServiceRequestsManagerDxGrid />} />
);

const getServiceMobileGrid = (mobileGrid) => (
  <ServiceRequestsManagerDxGridContainer component={mobileGrid || <ServiceRequestsManagerMobileGrid />} />
);

const getServiceBlackListFormDialog = (blackListForm) => (
  <ServiceRequestsManagerBlackListFormDialog form={blackListForm || <ServiceRequestsManagerBlackListForm />} />
);

const getServiceVisitorArrivalFormDialog = (arrivalForm) => (
  <ServiceRequestsManagerVisitorArrivalFormDialog form={arrivalForm || <ServiceRequestsManagerVisitorArrivalForm />} />
);

const getServiceDataTableView = ({ breakpoint, administratorBCServiceDxGrid, administratorBCServiceMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getServiceMobileGrid(administratorBCServiceMobileGrid);
  }
  if (isUndefined(administratorBCServiceDxGrid) || administratorBCServiceDxGrid) {
    return getServiceDxGrid(administratorBCServiceDxGrid);
  }
};

const isRequestsPage = (pathname) => pathname === REQUESTS_PATH || pathname.startsWith(`${REQUESTS_PATH}/`);

const isServiceRequestsPage = (pathname) => pathname === SERVICE_REQUESTS_PATH || pathname.startsWith(`${SERVICE_REQUESTS_PATH}/`);

export const getAdministratorBCView = (props) => {
  const {
    administratorBCAgreementDialog,
    administratorBCBlackListForm,
    administratorBCBlackListFormDialog,
    administratorBCDxGrid,
    administratorBCForm,
    administratorBCFormDialog,
    administratorBCMobileGrid,
    administratorBCRejectForm,
    administratorBCRejectFormDialog,
    administratorBCView,
    administratorBCVisitorArrivalForm,
    administratorBCVisitorArrivalFormDialog,

    administratorBCServiceAcceptForm,
    administratorBCServiceAcceptFormDialog,
    administratorBCServiceCompleteForm,
    administratorBCServiceCompleteFormDialog,
    administratorBCServiceDxGrid,
    administratorBCServiceMobileGrid,
    administratorBCServiceForm,
    administratorBCServiceFormDialog,
    administratorBCServiceRejectForm,
    administratorBCServiceRejectFormDialog,
    administratorBCServiceBlackListFormDialog,
    administratorBCServiceBlackListForm,
    administratorBCServiceVisitorArrivalFormDialog,
    administratorBCServiceVisitorArrivalForm,
    administratorBCServiceView,
    location: { pathname },
    params,
    viewName,
    breakpoint,
    viewStyles,
  } = props;

  if (isRequestsPage(pathname) && administratorBCView) return administratorBCView;
  if (isServiceRequestsPage(pathname) && administratorBCServiceView) return administratorBCServiceView;

  if (isRequestsPage(pathname)) {
    return (
      <ReceptionView
        key={viewName}
        viewName={viewName}
        params={params}
        viewStyles={viewStyles}
        dataTable={getDataTableView({
          breakpoint,
          administratorBCDxGrid,
          administratorBCMobileGrid,
        })}
        formDialog={administratorBCFormDialog || getFormDialog(administratorBCForm)}
        agreementDialog={administratorBCAgreementDialog || getAgreementDialog()}
        rejectFormDialog={administratorBCRejectFormDialog
          || getRejectFormDialog(administratorBCRejectForm)}
        visitorArrivalFormDialog={administratorBCVisitorArrivalFormDialog
          || getVisitorArrivalFormDialog(administratorBCVisitorArrivalForm)}
        blackListFormDialog={administratorBCBlackListFormDialog
          || getBlackListFormDialog(administratorBCBlackListForm)}
        workformDialog={getWorkFormDialog()}
      />
    );
  } else if (isServiceRequestsPage(pathname)) {
    return (
      <ServiceRequestsManagerView
        key={viewName}
        viewName={viewName}
        viewStyles={viewStyles}
        dataTable={getServiceDataTableView({
          breakpoint,
          administratorBCServiceDxGrid,
          administratorBCServiceMobileGrid,
        })}
        formDialog={administratorBCServiceFormDialog || getServiceFormDialog(administratorBCServiceForm)}
        agreementDialog={administratorBCAgreementDialog || getAgreementDialog()}
        serviceRequestsManagerAcceptFormDialog={administratorBCServiceAcceptFormDialog
          || getServiceAcceptFormDialog(administratorBCServiceAcceptForm)}
        serviceRequestsManagerRejectFormDialog={administratorBCServiceRejectFormDialog
          || getServiceRejectFormDialog(administratorBCServiceRejectForm)}
        serviceRequestsManagerCompleteFormDialog={administratorBCServiceCompleteFormDialog
          || getServiceCompleteFormDialog(administratorBCServiceCompleteForm)}
        serviceRequestsManagerBlackListFormDialog={administratorBCServiceBlackListFormDialog
          || getServiceBlackListFormDialog(administratorBCServiceBlackListForm)}
        serviceRequestsManagerVisitorArrivalFormDialog={administratorBCServiceVisitorArrivalFormDialog
          || getServiceVisitorArrivalFormDialog(administratorBCServiceVisitorArrivalForm)}
        workformDialog={getWorkFormDialog()}
      />
    );
  }

  return null;
};

getAdministratorBCView.propTypes = {
  administratorBCAgreementDialog: PropTypes.node,
  administratorBCBlackListForm: PropTypes.node,
  administratorBCBlackListFormDialog: PropTypes.node,
  administratorBCDxGrid: PropTypes.node,
  administratorBCForm: PropTypes.node,
  administratorBCFormDialog: PropTypes.node,
  administratorBCMobileGrid: PropTypes.node,
  administratorBCRejectForm: PropTypes.node,
  administratorBCRejectFormDialog: PropTypes.node,
  administratorBCView: PropTypes.node,
  administratorBCVisitorArrivalForm: PropTypes.node,
  administratorBCVisitorArrivalFormDialog: PropTypes.node,
  administratorBCServiceAcceptForm: PropTypes.node,
  administratorBCServiceAcceptFormDialog: PropTypes.node,
  administratorBCServiceCompleteForm: PropTypes.node,
  administratorBCServiceCompleteFormDialog: PropTypes.node,
  administratorBCServiceDataTable: PropTypes.node,
  administratorBCServiceDxGrid: PropTypes.node,
  administratorBCServiceMobileGrid: PropTypes.node,
  administratorBCServiceForm: PropTypes.node,
  administratorBCServiceFormDialog: PropTypes.node,
  administratorBCServiceRejectForm: PropTypes.node,
  administratorBCServiceRejectFormDialog: PropTypes.node,
  administratorBCServiceView: PropTypes.node,
  workForm: PropTypes.node,
  workFormDialog: PropTypes.node,
  location: PropTypes.object,
  params: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  viewName: PropTypes.string,
  breakpoint: PropTypes.object.isRequired,
  viewStyles: PropTypes.object,
};
