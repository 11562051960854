/*
 *
 * LoginForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import StyledTextField from './StyledTextField';
import Grid from '@material-ui/core/Grid';
import { account } from 'domain/typeConstants/accounts';
import sawatzkyLogo from 'custom/15755/assets/sawatzky_logo_white.png';
import millhouseLogo from 'custom/15755/assets/millhouse_logo.png';


const styleSheet = (theme) => ({
  errorText: {
    display: 'flex',
    fontSize: 15,
    color: theme.palette.error.A700,
    backgroundColor: theme.palette.error[50],
    padding: '8px 0',
  },
  errorIcon: {
    fill: theme.palette.error.A700,
    margin: '0 8px',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldIcon: {
    margin: '0px 16px 0px 0px',
    position: 'relative',
    bottom: '-10px',
  },
  buttons: {
    textAlign: 'right',
    padding: '32px 0 16px',
  },
  enterButton: {
    color: '#fff',
  },
  enterButtonKrylatsky: {
    color: '#fff',
    borderColor: 'white',
  },
  sawatzkyLogo: {
    height: 35
  },
  millhouseLogo: {
    height: 30
  }
});

class LoginForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  validateLogin = (value) => value ? undefined : 'введите ваш логин';

  validatePassword = (value) => value ? undefined : 'введите пароль';

  forgotPassword = () => { this.props.forgotPassword(); };

  render() {
    const { className, classes, error, message, typeMessage, accountId } = this.props;
    const isKrylatskyGroup = [account.KRYLATSKI_HOLMY, account.BC_BELIYE_SADY, account.BC_4_VETRA].includes(accountId)

    return (
      <form
        className={className}
        onSubmit={this.handleSubmit}
      >
        {
          error &&
          <Typography variant="subheading" component="h3" className={classes.errorText}>
            <Icon className={classes.errorIcon}> error</Icon>
            {error}
          </Typography>
        }

        {isKrylatskyGroup ?
          <>
            <div className={classes.fieldContainer}>
              <StyledTextField
                autoComplete="on"
                label="Ваш логин"
                name="login"
                validate={this.validateLogin}
                fullWidth
                margin="normal"
                isKrylatsky={isKrylatskyGroup}
              />
            </div>
            <div className={classes.fieldContainer}>
              <StyledTextField
                autoComplete="on"
                label="Пароль"
                name="password"
                type="password"
                validate={this.validatePassword}
                fullWidth
                margin="normal"
                isKrylatsky={isKrylatskyGroup}
              />
            </div>
          </>
          :
          <>
            <div className={classes.fieldContainer}>
              <Icon className={classes.fieldIcon}>person</Icon>
              <StyledTextField
                autoComplete="on"
                label="Ваш логин"
                name="login"
                validate={this.validateLogin}
                fullWidth
                margin="normal"
              />
            </div>
            <div className={classes.fieldContainer}>
              <Icon className={classes.fieldIcon}>lock</Icon>
              <StyledTextField
                autoComplete="on"
                label="Пароль"
                name="password"
                type="password"
                validate={this.validatePassword}
                fullWidth
                margin="normal"
              />
            </div>
          </>
        }

        {isKrylatskyGroup ?
          <Grid container spacing={8} style={{ alignItems: 'center' }}>
            <Grid item>
              <div className={classes.buttons} style={isKrylatskyGroup && { paddingRight: 10 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  className={classes.enterButtonKrylatsky}
                >
                  Войти
                </Button>
              </div>
            </Grid>
            <Grid item >
              <Typography variant="caption" display="block" gutterBottom className={classes.buttons}>
                <a href="#" fontSize="8" onClick={this.forgotPassword} style={{ color: 'white' }}>Восстановить пароль</a>
              </Typography>
            </Grid>
          </Grid>
          :
          <Grid container spacing={8} style={{ alignItems: 'center' }}>
            <Grid item >
              <Typography variant="caption" display="block" gutterBottom className={classes.buttons}>
                <a href="#" fontSize="8" onClick={this.forgotPassword}>Восстановить пароль</a>
              </Typography>
            </Grid>

            <Grid item xs>
              <div className={classes.buttons}>
                <Button
                  variant="raised"
                  color="primary"
                  type="submit"
                  className={classes.enterButton}
                >
                  Войти
                </Button>
              </div>
            </Grid>

          </Grid>
        }

        {isKrylatskyGroup &&
          <Grid container style={{ justifyContent: 'space-between', paddingTop: 16 }}>
            <Grid item>
              <img src={millhouseLogo} alt="Millhouse Logo" className={classes.millhouseLogo} />
            </Grid>
            <Grid item>
              <img src={sawatzkyLogo} alt="Sawatzky Logo" className={classes.sawatzkyLogo} />
            </Grid>
          </Grid>
        }

        {
          message &&
          <Typography variant="caption" gutterBottom color={typeMessage}>
            {message}
          </Typography>
        }
      </form>
    );
  }
}

LoginForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(LoginForm);
