/*
 *
 * ChangePasswordForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import StyledTextField from './StyledTextField';

// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import Input from '@material-ui/core/Input';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import FormControl from '@material-ui/core/FormControl';
// import Grid from '@material-ui/core/Grid';
// import FormHelperText from '@material-ui/core/FormHelperText';

const styleSheet = (theme) => ({
  errorText: {
    display: 'flex',
    fontSize: 15,
    color: theme.palette.error.A700,
    backgroundColor: theme.palette.error[50],
    padding: '8px 0',
  },
  errorIcon: {
    fill: theme.palette.error.A700,
    margin: '0 8px',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldIcon: {
    margin: '0px 16px 0px 0px',
    position: 'relative',
    bottom: '-10px',
  },
  buttons: {
    textAlign: 'right',
    padding: '32px 0 16px',
  },
  formControl: {
    width: '125%',
  },
});

class ChangePasswordForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      showOldPassword: false,
      showNewPassword: false,
      showNewPasswordConfirmation: false,
      errorNewPasswordHint: '',
      errorNewPasswordConfirmationHint: '',
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  }

  validateOldPassword = (value) => value ? undefined : 'введите старый пароль';

  validatePassword = (value) => {
    const lowerCase = /^[a-z]+$/;
    const upperCase = /^[A-Z]+$/;
    const digit = /^[0-9]+$/;
    let isLowerCase = false;
    let isUpperCase = false;
    let isDigit = false;
    if (value && value.length > 7) {
      for (let i = 0; i < value.length; i++) {
        if (!isLowerCase && lowerCase.test(value[i])) { isLowerCase = true }
        else if (!isUpperCase && upperCase.test(value[i])) isUpperCase = true
        else if (!isDigit && digit.test(value[i])) isDigit = true
      }
      if (isLowerCase && isUpperCase && isDigit) return undefined;
      else return 'пароль не соответствует условиям';
    } else
      if (!value) return undefined
    return 'Недостаточная длина пароля';
  }

  handleChange = (prop, hint) => (event) => {
    this.setState({ [prop]: event.target.value });
    this.setState({ [hint]: this.validatePassword(event.target.value) });
  }

  handleClickShowOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword })
  };

  handleClickShowPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  };

  handleClickShowPasswordConfirmation = () => {
    this.setState({ showNewPasswordConfirmation: !this.state.showNewPasswordConfirmation })
  };


  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { className, classes, error } = this.props;
    const showNewPassword = false;
    const showPasswordConfirmation = false;

    return (
      <form
        className={className}
        onSubmit={this.handleSubmit}
      >
        {
          error &&
          <Typography variant="subheading" component="h3" className={classes.errorText}>
            <Icon className={classes.errorIcon}> error</Icon>
            {error}
          </Typography>
        }
        <div className={classes.fieldContainer}>
          <Icon className={classes.fieldIcon}>lock_open</Icon>
          <StyledTextField
            autoComplete="on"
            label="Старый пароль"
            name="oldPassword"
            type="password"
            validate={this.validateOldPassword}
            fullWidth
            margin="normal"
          />
        </div>
        <div className={classes.fieldContainer}>
          <Icon className={classes.fieldIcon}>lock</Icon>
          <StyledTextField
            autoComplete="on"
            label="Новый пароль"
            name="newPassword"
            type={showNewPassword ? 'text' : 'password'}
            validate={this.validatePassword}
            fullWidth
            margin="normal"
          />
        </div>
        <div className={classes.fieldContainer}>
          <Icon className={classes.fieldIcon}>lock</Icon>
          <StyledTextField
            autoComplete="on"
            label="Подтверждение пароля"
            name="newPasswordConfirmation"
            type={showPasswordConfirmation ? 'text' : 'password'}
            validate={this.validatePassword}
            fullWidth
            margin="normal"
          />
        </div>

        {/* 
        <div>
          <Grid container >
            <Grid item>
              <Icon className={classes.fieldIcon} >lock</Icon>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} >
                 <InputLabel htmlFor="oldPassword">Старый пароль</InputLabel>
                <Input
                  id="oldPassword"
                  type={this.state.showOldPassword ? 'text' : 'password'}
                  value={this.state.oldPassword}
                  onChange={this.handleChange('oldPassword')}
                  fullWidth
                  name="oldPassword"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowOldPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </div> */}
        {/* 
        <div>
          <Grid container >
            <Grid item>
              <Icon className={classes.fieldIcon} >lock</Icon>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}
                error={this.state.errorNewPasswordHint && this.state.errorNewPasswordHint.length > 0}
              >
                <InputLabel htmlFor="newPassword">Новый пароль</InputLabel>
                <Input
                  id="newPassword"
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  value={this.state.newPassword}
                  onChange={this.handleChange('newPassword', 'errorNewPasswordHint')}
                  fullWidth
                  name="newPassword"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {
                  this.state.errorNewPasswordHint &&
                  this.state.errorNewPasswordHint.length > 0
                  && <FormHelperText 
                  >{this.state.errorNewPasswordHint}</FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container >
            <Grid item>
              <Icon className={classes.fieldIcon} >lock</Icon>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}
                error={this.state.errorNewPasswordConfirmationHint 
                  && this.state.errorNewPasswordConfirmationHint.length > 0}
              >
                <InputLabel htmlFor="newPasswordConfirmation">Подтверждение пароля</InputLabel>
                <Input
                  id="newPasswordConfirmation"
                  type={this.state.showNewPasswordConfirmation ? 'text' : 'password'}
                  value={this.state.newPasswordConfirmation}
                  onChange={this.handleChange('newPasswordConfirmation', 'errorNewPasswordConfirmationHint')}
                  fullWidth
                  name="newPasswordConfirmation"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPasswordConfirmation}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showNewPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {
                  this.state.errorNewPasswordConfirmationHint &&
                  this.state.errorNewPasswordConfirmationHint.length > 0
                  && <FormHelperText>{this.state.errorNewPasswordConfirmationHint}</FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
        </div> */}

        <div className={classes.buttons}>
          <Button
            variant="raised"
            color="primary"
            type="submit"
          >
            Сменить пароль
          </Button>
        </div>
      </form>
    );
  }
}

ChangePasswordForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(ChangePasswordForm);
