/**
 *
 * ReceptionDxGrid14366
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';

import { role } from 'domain/typeConstants/roles';
import { requestAction } from 'domain/typeConstants/action';
import { requestStatus } from 'domain/typeConstants/status';
import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';
import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE,
  REQUESTS_WITHOUT_SERVICE_TYPE,
  ON_CREATE_REQUESTS,
  ACTIVE_REQUESTS,
} from 'domain/Data/Filters/constants';
import { BULVAR_TSVETNOY } from '../../../domain/Data/Departments/constants';

const isRequestOnCarEntryType = (request) => (request.type && request.type.id === ON_CAR_ENTRY);
const isRequestOnCarEntryTypeAndCreatedStatus = (request) => (request.type && request.type.id === ON_CAR_ENTRY &&
  request.status && request.status.name === requestStatus.CREATED);

const isAtLeastOneRequestOnCarEntryType = (requests) => (requests.some((request) => isRequestOnCarEntryType(request)));
const isAtLeastOneRequestOnCarEntryTypeAndCreatedStatus = (requests) => (requests.some((request) => isRequestOnCarEntryTypeAndCreatedStatus(request)));

const acceptCondition = (request) => (
  new Set([requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED,
  requestStatus.IN_PROGRESS, requestStatus.CREATED]).has(request.status.name)
);
const rejectCondition = (request) => (
  new Set([requestStatus.CLOSED, requestStatus.COMPLETED,
  requestStatus.REJECTED, requestStatus.CREATED]).has(request.status.name)
);
const arrivedCondition = (request) => (
  new Set([requestStatus.CLOSED, requestStatus.ON_TERRITORY, requestStatus.COMPLETED,
  requestStatus.IN_PROGRESS, requestStatus.CREATED]).has(request.status.name)
);
const departedCondition = (request) => (
  new Set([requestStatus.ON_TERRITORY, requestStatus.CREATED]).has(request.status.name)
);

const excludedButtonForBulvarTsvetnoy = new Set([requestAction.ADD, requestAction.EDIT, requestAction.COPY]);

export class ReceptionDxGrid14366 extends ReceptionDxGrid {

  getFilters() {
    const { viewName, department } = this.props;
    const filters = [REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);  
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (department === BULVAR_TSVETNOY && (viewName === 'all' || viewName === 'today')) {
      filters.push(REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE);
      filters.push(REQUESTS_WITHOUT_SERVICE_TYPE);
    }
    return filters;
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];
    const columns = super.getColumns();
    const companyIndex = columns.findIndex((column) => column.name === 'company_name');

    columns.splice(companyIndex, 0, {
      path: 'office',
      name: 'office',
      title: '№ Офиса',
      getCellValue: (row) => row.company ? row.company.office : undefined,
      width: requestColumnSizes.office,
      styles,
    });
    columns.push({
      path: 'arrivalDate',
      name: 'arrivalDate',
      title: 'Вход',
      type: columnType.DATETIME,
      width: columnSize.DATETIME,
      styles,
    });

    columns.push({
      path: 'departureDate',
      name: 'departureDate',
      title: 'Выход',
      type: columnType.DATETIME,
      width: columnSize.DATETIME,
      styles,
    });
    return columns;
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();
    columnFilters.push({ columnName: 'arrivalDate', value: '' });
    columnFilters.push({ columnName: 'departureDate', value: '' });
    columnFilters.push({ columnName: 'office', value: '' });
    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const companyIndex = columnOrder.findIndex((item) => item === 'company_name');
    columnOrder.splice(companyIndex, 0, 'office');
    columnOrder.push('arrivalDate');
    columnOrder.push('departureDate');
    return columnOrder;
  }

  getActionButtons() {
    const { department, roles } = this.props;
    let actionButtons = super.getActionButtons();
    const acceptButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ACCEPT);
    const rejectButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.REJECT);
    const arrivedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    const departedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.DEPARTED);

    const acceptButton = Object.assign({}, actionButtons[acceptButtonIndex]);
    const rejectButton = Object.assign({}, actionButtons[rejectButtonIndex]);
    const arrivedButton = Object.assign({}, actionButtons[arrivedButtonIndex]);
    const departedButton = Object.assign({}, actionButtons[departedButtonIndex]);
    /* -----------------------------------------------
                      ACCEPT BUTTON
    ----------------------------------------------- */
    acceptButton.disabled = (selection, requests) => {
      if (selection.length !== 0) {
        if (!requests[0].status) return false;
        if (isAtLeastOneRequestOnCarEntryType(requests)) return true;

        return requests.some((request) => acceptCondition(request));
      }
      return true;
    };

    /* -----------------------------------------------
                      REJECT BUTTON
    ----------------------------------------------- */
    rejectButton.disabled = (selection, requests) => {
      if (selection.length !== 0) {
        if (!requests[0].status) return false;
        if (isAtLeastOneRequestOnCarEntryType(requests)) return true;

        return requests.some((request) => rejectCondition(request));
      }
      return true;
    };

    /* -----------------------------------------------
                      ARRIVED BUTTON
    ----------------------------------------------- */
    arrivedButton.disabled = (selection, requests) => {
      if (selection.length === 1) {
        if (!requests[0].status) return false;
        if (isAtLeastOneRequestOnCarEntryTypeAndCreatedStatus(requests)) return true;

        return arrivedCondition(requests[0]);
      }
      return true;
    };

    /* -----------------------------------------------
                      DEPARTED BUTTON
    ----------------------------------------------- */
    departedButton.disabled = (selection, requests) => {
      if (selection.length === 1) {
        if (!requests[0].status) return false;
        if (isAtLeastOneRequestOnCarEntryTypeAndCreatedStatus(requests)) return true;

        return departedCondition(requests[0]);
      }
      return true;
    };

    actionButtons.splice(acceptButtonIndex, 1, acceptButton);
    actionButtons.splice(rejectButtonIndex, 1, rejectButton);
    actionButtons.splice(arrivedButtonIndex, 1, arrivedButton);
    actionButtons.splice(departedButtonIndex, 1, departedButton);

    if (department === BULVAR_TSVETNOY && Array.isArray(roles) && roles.includes(role.RECEPTION)) {
      actionButtons = actionButtons.filter((button) => !excludedButtonForBulvarTsvetnoy.has(button.name));
    }

    return actionButtons;
  }

  getActionMenuItems() {
    const { department, roles } = this.props;
    let menuItems = super.getActionMenuItems();

    const acceptMenuItemIndex = menuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
    const rejectMenuItemIndex = menuItems.findIndex((menuItem) => menuItem.name === requestAction.REJECT);
    const arrivedMenuItemIndex = menuItems.findIndex((menuItem) => menuItem.name === requestAction.ARRIVED);
    const departedMenuItemIndex = menuItems.findIndex((menuItem) => menuItem.name === requestAction.DEPARTED);

    const acceptMenuItem = Object.assign({}, menuItems[acceptMenuItemIndex]);
    const rejectMenuItem = Object.assign({}, menuItems[rejectMenuItemIndex]);
    const arrivedMenuItem = Object.assign({}, menuItems[arrivedMenuItemIndex]);
    const departedMenuItem = Object.assign({}, menuItems[departedMenuItemIndex]);

    /* -----------------------------------------------
                     ACCEPT MENU ITEM
    ----------------------------------------------- */
    acceptMenuItem.hidden = (itemMenuRecord, requests) => {
      if (itemMenuRecord.length !== 0) {
        if (!requests[0].status) return false;
        if (isRequestOnCarEntryType(requests[0])) return true;

        return acceptCondition(requests[0]);
      }
      return true;
    };

    /* -----------------------------------------------
                     REJECT MENU ITEM
    ----------------------------------------------- */
    rejectMenuItem.hidden = (itemMenuRecord, requests) => {
      if (itemMenuRecord.length !== 0) {
        if (!requests[0].status) return false;
        if (isRequestOnCarEntryType(requests[0])) return true;

        return rejectCondition(requests[0]);
      }
      return true;
    };

    /* -----------------------------------------------
                     ARRIVED MENU ITEM
    ----------------------------------------------- */
    arrivedMenuItem.hidden = (itemMenuRecord, requests) => {
      if (itemMenuRecord.length === 1) {
        if (!requests[0].status) return false;
        if (isRequestOnCarEntryTypeAndCreatedStatus(requests[0])) return true;

        return arrivedCondition(requests[0]);
      }
      return true;
    };

    /* -----------------------------------------------
                     DEPARTED MENU ITEM
    ----------------------------------------------- */
    departedMenuItem.hidden = (itemMenuRecord, requests) => {
      if (itemMenuRecord.length === 1) {
        if (!requests[0].status) return false;
        if (isRequestOnCarEntryTypeAndCreatedStatus(requests[0])) return true;

        return departedCondition(requests[0]);
      }
      return true;
    };

    menuItems.splice(acceptMenuItemIndex, 1, acceptMenuItem);
    menuItems.splice(rejectMenuItemIndex, 1, rejectMenuItem);
    menuItems.splice(arrivedMenuItemIndex, 1, arrivedMenuItem);
    menuItems.splice(departedMenuItemIndex, 1, departedMenuItem);

    if (department === BULVAR_TSVETNOY && Array.isArray(roles) && roles.includes(role.RECEPTION)) {
      menuItems = menuItems.filter((item) => !excludedButtonForBulvarTsvetnoy.has(item.name));
    }

    return menuItems;
  }
}
