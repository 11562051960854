import React from 'react';
import PropTypes from 'prop-types';
import {isUndefined} from 'utils/typeUtils';

import AgreementDialog from 'pages/Documents/common/AgreementDialog';
import DisagreementDialog from 'pages/Documents/common/DisagreementDialog';

import SupplyRequestView, {
    SupplyRequestDxGrid,
    SupplyRequestDxGridContainer,
    SupplyRequestForm,
    SupplyRequestFormDialogContainer,
    SupplyRequestMobileGrid,
    DocumentPositionForm,
    DocumentPositionFormDialog,
    SupplierInvoiceForm,
    SupplierInvoiceFormDialog,
} from '../SupplyRequestView';
import DocumentPositionsDxGridContainer from 'pages/Positions/PositionsView/DocumentPositionsDxGridContainer';
import DocumentPositionsDxGrid from 'pages/Positions/PositionsView/DocumentPositionsDxGrid';
import {
    DOCUMENT_POSITIONS,
    SUPPLIER_INVOICES
} from 'domain/Data/constants';

const getAgreementDialog = () => <AgreementDialog/>;

const getDisagreementDialog = () => <DisagreementDialog/>;

const getDxGrid = (DxGrid) => (
    <SupplyRequestDxGridContainer component={DxGrid || <SupplyRequestDxGrid/>}/>
);

const getMobileGrid = (MobileGrid) => (
    <SupplyRequestDxGridContainer component={MobileGrid || <SupplyRequestMobileGrid/>}/>
);

const getFormDialog = (Form) => (
    <SupplyRequestFormDialogContainer form={Form || <SupplyRequestForm/>}
    />
);

const getPositionFormDialog = (Form) => (
    <DocumentPositionFormDialog form={Form || <DocumentPositionForm/>}/>
);

const getInvoiceDocumentPositions = () => (
    <DocumentPositionsDxGridContainer
        label="Позиции"
        component={<DocumentPositionsDxGrid
            parent={SUPPLIER_INVOICES}
            entityName={DOCUMENT_POSITIONS}
            mode="detail"/>}/>
);

const getInvoiceDetailContainer = () => {
    return {
        items: [getInvoiceDocumentPositions()],
        height: 286,
    }
}

const getInvoiceFormDialog = (Form) => (
    <SupplierInvoiceFormDialog
        form={Form || <SupplierInvoiceForm/>}
        detailContainer={getInvoiceDetailContainer()}
    />
);

const getDataTableView = ({breakpoint, supplyRequestDxGrid, supplyRequestMobileGrid}) => {
    if (breakpoint.down('md')) {
        return getMobileGrid(supplyRequestMobileGrid);
    }
    if (isUndefined(supplyRequestDxGrid) || supplyRequestDxGrid) {
        return getDxGrid(supplyRequestDxGrid);
    }
    return <div>ERROR TEMPLATE</div>
};

export const getSupplyRequestView = (props) => {
    const {
        supplyRequestAgreementDialog,
        supplyRequestDisagreementDialog,
        supplyRequestDxGrid,
        supplyRequestForm,
        supplyRequestFormDialog,
        supplyRequestPositionForm,
        supplyRequestPositionFormDialog,
        supplyRequestInvoiceForm,
        supplyRequestInvoiceFormDialog,
        supplyRequestMobileGrid,
        supplyRequestView,
        viewName,
        breakpoint,
        viewStyles,
    } = props;

    if (supplyRequestView) return supplyRequestView;

    return (
        <SupplyRequestView
            key={viewName}
            viewName={viewName}
            dataTable={getDataTableView({
                breakpoint,
                supplyRequestDxGrid,
                supplyRequestMobileGrid,
            })}
            formDialog={supplyRequestFormDialog || getFormDialog(supplyRequestForm)}
            positionFormDialog={supplyRequestPositionFormDialog || getPositionFormDialog(supplyRequestPositionForm)}
            invoiceFormDialog={supplyRequestInvoiceFormDialog || getInvoiceFormDialog(supplyRequestInvoiceForm)}
            agreementDialog={supplyRequestAgreementDialog || getAgreementDialog()}
            disagreementDialog={supplyRequestDisagreementDialog || getDisagreementDialog()}
            viewStyles={viewStyles}
        />
    );
};

getSupplyRequestView.propTypes = {
    supplyRequestAgreementDialog: PropTypes.node,
    supplyRequestDataTable: PropTypes.node,
    supplyRequestDisagreementDialog: PropTypes.node,
    supplyRequestDxGrid: PropTypes.node,
    supplyRequestForm: PropTypes.node,
    supplyRequestFormDialog: PropTypes.node,
    supplyRequestPositionForm: PropTypes.node,
    supplyRequestPositionFormDialog: PropTypes.node,
    supplyRequestInvoiceForm: PropTypes.node,
    supplyRequestInvoiceFormDialog: PropTypes.node,
    supplyRequestView: PropTypes.node,
    viewName: PropTypes.string,
};
