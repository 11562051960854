/*
 *
 * AssignedView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function AssignedView({ dataTable, formDialog, assignedCompleteFormDialog, viewName, viewStyles }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { formDialog }
      { assignedCompleteFormDialog }
    </div>
  );
}

AssignedView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  assignedCompleteFormDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
};

AssignedView.defaultProps = {
  viewStyles: {},
};

export default AssignedView;
