/*
 *
 * File constants
 *
 */

export const DOWNLOAD_FILE = 'claris-vnext/Data/File/DOWNLOAD_FILE';

export const SAVE_FILE = 'claris-vnext/Data/File/SAVE_FILE';
export const SAVE_FILE_ERROR = 'claris-vnext/Data/File/SAVE_FILE_ERROR';
export const SAVE_FILE_PROGRESS = 'claris-vnext/Data/File/SAVE_FILE_PROGRESS';
export const SAVE_FILE_SUCCESS = 'claris-vnext/Data/File/SAVE_FILE_SUCCESS';
