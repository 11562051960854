/**
 *
 * getViewConfig Function
 *
 */

import {
  getTaskView,

} from './getRoleView';

import getSagasConfig from './getSagasConfig';
import createTaskViewSaga from './TaskView/sagas';

const defaultViewConfig = {
  createComponent: getTaskView,
  createSaga: (sagaConfig) => createTaskViewSaga(sagaConfig.taskViewSaga),
};

export function createViewComponent(userRoles, props) {

  return defaultViewConfig.createComponent(props);
}

export function createViewSaga(userRoles, accountId) {

  return defaultViewConfig.createSaga(getSagasConfig(accountId));
}
