/*
 *
 *
 * AdministratorBCServiceRequestsMobileGrid14326
 *
 */

import ServiceRequestsManagerMobileGrid from 'pages/Requests/ServiceRequestsManagerView/ServiceRequestsManagerMobileGrid'
import {
    ACTIVE_REQUESTS,
    REQUESTS_SERVICE_AND_WORK_PRODUCTION
} from "domain/Data/Filters/constants";
import { requestAction } from "domain/typeConstants/action";
import {
    sendEmailActionMenuItem,
    sendToAgreementActionMenuItem,
    sendToAgreementByTenantActionMenuItem,
} from "../common/actionMenuItem";

export class AdministratorBCServiceRequestsMobileGrid14326 extends ServiceRequestsManagerMobileGrid {
    getFilters() {
        const { viewName } = this.props; // eslint-disable-line
        const filters = [REQUESTS_SERVICE_AND_WORK_PRODUCTION];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        return filters;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();

        const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
        actionMenuItems[editIndex].label = 'Открыть';
        actionMenuItems.splice(editIndex - 1, 0, sendToAgreementActionMenuItem, sendEmailActionMenuItem);
        actionMenuItems.push(sendToAgreementByTenantActionMenuItem);

        const acceptIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
        actionMenuItems[acceptIndex].label = 'Принять/Отправить исполнителю';

        return actionMenuItems;
    }
}
