/**
 *
 * Screen utils
 *
 */

const xs = Object.freeze({ min: 0, max: 599 });
const sm = Object.freeze({ min: 600, max: 959 });
const md = Object.freeze({ min: 960, max: 1279 });
const lg = Object.freeze({ min: 1280, max: 1919 });
const xl = Object.freeze({ min: 1290 });

const allowedValues = ['xs', 'sm', 'md', 'lg', 'xl'];

/** @internal */
function checkParams(key, argName) {
  if (typeof key !== 'string') throw Error(`"${argName || 'key'}" argument must be "String" type, but got "${typeof key}"`);
  if (!allowedValues.includes(key)) {
    let result = '| ';
    allowedValues.forEach((item) => { result += `${item} | `; });
    throw Error(`key must be one of "${result.trim()}", but got "${key}"`);
  }
}

/** @internal */
function up(key) {
  checkParams(key);
  if (key === 'xl') return false;
  return this.width > this.sizes[key].max;
}

/** @internal */
function down(key) {
  checkParams(key);
  return this.width < this.sizes[key].min;
}

/** @internal */
function between(keyA, keyB) {
  checkParams(keyA, 'first');
  checkParams(keyB, 'second');
  if (allowedValues.indexOf(keyA) >= allowedValues.indexOf(keyB)) {
    throw Error('first argument must be less than second one');
  }
  if (keyB === 'xl') return this.width >= this.sizes[keyA].min;

  return this.width >= this.sizes[keyA].min && this.width <= this.sizes[keyB].max;
}

/** @internal */
function only(key) {
  checkParams(key);
  if (key === 'xl') return this.width >= this.sizes[key].min;
  return this.width >= this.sizes[key].min && this.width <= this.sizes[key].max;
}

/** @internal */
const breakpoint = Object.defineProperties({}, {
  width: {
    value: null,
    writable: true,
  },
  height: {
    value: null,
    writable: true,
  },
  sizes: {
    value: {
      xs,
      sm,
      md,
      lg,
      xl,
    },
    writable: false,
    configurable: false,
  },
  up: {
    value: up,
    writable: false,
    configurable: false,
  },
  down: {
    value: down,
    writable: false,
    configurable: false,
  },
  between: {
    value: between,
    writable: false,
    configurable: false,
  },
  only: {
    value: only,
    writable: false,
    configurable: false,
  },
});

export function getBreakpointByWidth(width) {
  if (width <= xs.max) {
    return 'xs';
  }
  if (width >= sm.min && width <= sm.max) {
    return 'sm';
  }
  if (width >= md.min && width <= md.max) {
    return 'md';
  }
  if (width >= lg.min && width <= lg.max) {
    return 'lg';
  }

  return 'xl';
}

export function getScreenProperties() {
  const _breakpoint = Object.assign(breakpoint); // eslint-disable-line
  _breakpoint.width = screen.width;   // eslint-disable-line no-restricted-globals
  _breakpoint.height = screen.height;  // eslint-disable-line no-restricted-globals

  return {
    height: screen.height,  // eslint-disable-line no-restricted-globals
    width: screen.width,  // eslint-disable-line no-restricted-globals
    orientation: (screen.height > screen.width)  // eslint-disable-line no-restricted-globals
      ? 'portrait'
      : 'landscape',
    breakpoint: _breakpoint,
    viewMode: _breakpoint.down('md') ? 'mobile' : 'desktop',
  };
}
