/**
 *
 * Common fields for 15755
 *
 */

import {
    ON_CAR_ENTRY,
    ON_PROPERTY_BRINGING,
    ON_PROPERTY_REMOVAL,
    ON_PASS_MAKING,
    CARRYING_OUT_WORKS,
    ON_VISIT,
} from 'domain/Data/RequestType/constants';
import * as entity from 'domain/Data/constants';
import { departmentPostChain } from './constants';
import {
    ON_TERRITORY, ACCEPTED, CLOSED, COMPLETED, CREATED, IN_PROGRESS, REJECTED,
    ON_AGREEMENT, ON_AGREEMENT_BY_TENANT, PASS_ISSUED, NO_PLACES,
    PASS_MADE, SEND_TO_TENANT, PASS_RETURNED, PASS_NOT_RETURNED
} from 'domain/Data/RequestStatus/constants';

/** ---------------- department ---------------- */

export const departmentCalculatedValue = (values, mode, entities) => {
    if (!values.company) return null
    if (entities && !values.department) {
        const company = entities.get(entity.COMPANIES).get(values.company);
        return company && company.department ? company.department : values.department;
    }
    return values.department;
};

export const departmentVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
    if (currentValues.company) {
        const company = entities
            .get(entity.COMPANIES)
            .get(currentValues.company);
        const companyDepartments = company ? company.get(entity.DEPARTMENTS) || [] : [];
        const departments = entities.get(entity.DEPARTMENTS)
            .filter((department) => companyDepartments.includes(department.id))
            .map((department) => department.id);
        return departments;
    } return []
};

export const issueReasonVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
    return entities.get(entity.ISSUE_REASONS).filter(i => i.isKrylatsky).map(i => i.id)
};

export const checkpointPostValue = (values, mode, user) =>
    values.department && (values.carNumber || (values.isGroupRequest && [ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS, ON_PASS_MAKING]
        .includes(values.type))) ? departmentPostChain[values.department] : null


const enableStatuses = [

    // ON_VISIT
    { type: ON_VISIT, current: ACCEPTED, options: [ACCEPTED, PASS_ISSUED, REJECTED, CLOSED] },
    { type: ON_VISIT, current: REJECTED, options: [ACCEPTED, REJECTED, CLOSED] },
    { type: ON_VISIT, current: CLOSED, options: [CLOSED, PASS_RETURNED, PASS_NOT_RETURNED] },
    {
        type: ON_VISIT, current: PASS_ISSUED,
        options: (values) => {
            const result = [PASS_ISSUED, PASS_RETURNED, PASS_NOT_RETURNED]
            values.longterm ? result.push(ACCEPTED) : result.push(CLOSED)
            return result
        },
    },

    // ON_CAR_ENTRY
    { type: ON_CAR_ENTRY, current: ACCEPTED, options: [ACCEPTED, REJECTED, ON_TERRITORY, CLOSED, NO_PLACES] },
    { type: ON_CAR_ENTRY, current: REJECTED, options: [REJECTED, CLOSED] },
    { type: ON_CAR_ENTRY, current: CLOSED, options: [CLOSED] },
    { type: ON_CAR_ENTRY, current: NO_PLACES, options: [NO_PLACES, ON_TERRITORY, CLOSED] },
    {
        type: ON_CAR_ENTRY, current: ON_TERRITORY,
        options: (values) => {
            const result = [ON_TERRITORY]
            values.longterm ? result.push(ACCEPTED) : result.push(CLOSED)
            return result
        }
    },

    // ON_PASS_MAKING
    { type: ON_PASS_MAKING, current: CREATED, options: [CREATED, ACCEPTED, REJECTED] },
    { type: ON_PASS_MAKING, current: ACCEPTED, options: [ACCEPTED, PASS_MADE, SEND_TO_TENANT] },
    { type: ON_PASS_MAKING, current: REJECTED, options: [REJECTED, CLOSED] },
    { type: ON_PASS_MAKING, current: PASS_MADE, options: [PASS_MADE, SEND_TO_TENANT, COMPLETED] },
    { type: ON_PASS_MAKING, current: SEND_TO_TENANT, options: [SEND_TO_TENANT, COMPLETED] },
    { type: ON_PASS_MAKING, current: COMPLETED, options: [COMPLETED, CLOSED] },

    // ON_PROPERTY_BRINGING
    { type: ON_PROPERTY_BRINGING, current: ACCEPTED, options: [ACCEPTED, CLOSED] },
    { type: ON_PROPERTY_BRINGING, current: CLOSED, options: [CLOSED] },

    //ON_PROPERTY_REMOVAL
    { type: ON_PROPERTY_REMOVAL, current: ACCEPTED, options: [ACCEPTED, CLOSED] },
    { type: ON_PROPERTY_REMOVAL, current: CLOSED, options: [CLOSED] },
    { type: ON_PROPERTY_REMOVAL, current: COMPLETED, options: [COMPLETED, CLOSED] },
]



export const statusVisibleOptions = (currentValues) => {
    const options = enableStatuses.find(i => i.type === currentValues.type && i.current === currentValues.status)?.options
    if (Array.isArray(options)) return options
    else if (typeof options === 'function') return options(currentValues)
    return null
}
