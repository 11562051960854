/*
 *
 * Data actions
 *
 */

import {
  ADD_ENTITIES_STATE,
  CHANGE_ENTITIES_STATE,
  CHANGE_ENTITIES_TOTAL_COUNT,
  INCREASE_ENTITIES_TOTAL_COUNT,
  LOAD_ENTITIES,
  LOAD_ENTITIES_SUCCESS,
  LOAD_ENTITIES_ERROR,
  SAVE_ENTITY,
  SAVE_ENTITY_SUCCESS,
  SAVE_ENTITY_ERROR,
  UPDATE_ENTITY,
  UPDATE_ENTITY_SUCCESS,
  UPDATE_ENTITY_ERROR,
  CLEAR_ENTITY,
  CHANGE_DIALOG_DATA_STATE,
  UPDATE_TERMINAL_ADVANCED_INPUT,
} from './constants';

export function addEntitiesState(entityName, entities, options) {
  return {
    type: ADD_ENTITIES_STATE,
    payload: {
      entityName,
      entities,
      options,
    },
  };
}

export function changeEntitiesState(entityName, entities) {
  return {
    type: CHANGE_ENTITIES_STATE,
    payload: { entityName, entities },
  };
}

export function changeEntitiesTotalCount(entityName, totalCount) {
  return {
    type: CHANGE_ENTITIES_TOTAL_COUNT,
    payload: { entityName, totalCount },
  };
}

export function clearEntity(entityName) {
  return {
    type: CLEAR_ENTITY,
    payload: { entityName },
  };
}

export function increaseEntitiesTotalCount(entityName, value = 1) {
  return {
    type: INCREASE_ENTITIES_TOTAL_COUNT,
    payload: { entityName, value },
  };
}

export function loadEntities(entityName, options = {}, meta) {
  return {
    type: LOAD_ENTITIES,
    meta,
    payload: { entityName, ...options },
  };
}

export function entitiesLoaded(entities, meta) {
  return {
    type: LOAD_ENTITIES_SUCCESS,
    meta,
    payload: { entities },
  };
}

export function entityLoadingError(error, meta) {
  return {
    type: LOAD_ENTITIES_ERROR,
    meta,
    payload: { error },
  };
}

export function saveEntity(entityName, entity, meta) {
  return {
    type: SAVE_ENTITY,
    meta,
    payload: { entityName, entity },
  };
}

export function entitySaved(meta) {
  return {
    type: SAVE_ENTITY_SUCCESS,
    meta,
  };
}

export function entitySavingError(error, meta) {
  return {
    type: SAVE_ENTITY_ERROR,
    meta,
    payload: { error },
  };
}

export function updateEntity(id, changedData, meta) {
  return {
    type: UPDATE_ENTITY,
    meta,
    payload: { id, changedData },
  };
}

export function entityUpdated(meta) {
  return {
    type: UPDATE_ENTITY_SUCCESS,
    meta,
  };
}

export function entityUpdatingError(error, meta) {
  return {
    type: UPDATE_ENTITY_ERROR,
    meta,
    payload: { error },
  };
}

export function changeDialogDataState(entityName, entities) {
  return {
    type: CHANGE_DIALOG_DATA_STATE,
    payload: { entityName, entities },
  };
}

export function updateTerminalAdvancedInput(options) {
  return {
    type: UPDATE_TERMINAL_ADVANCED_INPUT,
    payload:  options 
  };
}   