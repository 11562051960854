/**
 *
 * AdvancedMobileGridCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectAccountId } from 'domain/User/selectors';
import createAdvancedMobileGrid from './createAdvancedMobileGrid';
import { clearEntity as clearEntityBase } from 'domain/Data/actions';
import { getValueFromLocalStorage, setValueToLocalStorage } from 'utils/localStorage';
import { settings, filterOperation } from 'containers/AdvancedDataTable/dxGridTypes';
import { FALLBACK_VIEW_NAME, MOBILE_TABLE_KEY, SETTINGS_CONFIG_NAME } from 'containers/AdvancedDataTable/constants';
import { needToUpdateSettingsConfig } from 'containers/AdvancedMobileGrid/utils';
import { register } from './actions';
import { checkTableFeature } from 'containers/AdvancedDataTable/utils';


class AdvancedMobileGridCreator extends React.PureComponent {

  static defaultProps = {
    actionButtons: [],
    actionMenuItems: [],
    detailRowFields: [],
    filters: [],
    limit: 30,
    onActionExecuting: () => { },
    sorting: [],
    viewName: 'all',
  };
  static propTypes = {
    actionButtons: PropTypes.array,
    actionMenuItems: PropTypes.array,
    columns: PropTypes.array,
    entityName: PropTypes.string.isRequired,
    filters: PropTypes.array,
    initialize: PropTypes.func.isRequired,
    limit: PropTypes.number,
    name: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    sorting: PropTypes.array,
    viewName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      settingsConfig: null,
    };
  }

  componentWillMount() {
    const {
      actionButtons,
      actionMenuItems,
      entityName,
      filters,
      initialize,
      name,
      sorting,
      dashboardFilter,
      limit,
      customGridFilters,
      features,
    } = this.props;

    const ACCESS_KEY = this.getAccessKey();
    const gridFilters = customGridFilters ? customGridFilters.map(item => { return { columnName: item.columnName, value: '', path: item.path, type: item.type } }) : []

    let localStorageConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);

    const config = {
      [settings.FILTERING]: { value: gridFilters },
      [settings.CUSTOM_FILTERING]: { value: customGridFilters },
      [settings.CLEAR_FILTER_BUTTON]: {
        enabled: (() => {
          const result = checkTableFeature(settings.CLEAR_FILTER_BUTTON, features);
          return result ? result.value : false;
        })()
      },
    };

    const shouldLocalStorageUpdate = needToUpdateSettingsConfig({
      accessKey: ACCESS_KEY,
      currentConfig: config,
      localStorageConfig,
    });

    if (!localStorageConfig) {
      const initConfig = { [ACCESS_KEY]: config };
      setValueToLocalStorage(SETTINGS_CONFIG_NAME, initConfig);
      localStorageConfig = initConfig;
    } else if (shouldLocalStorageUpdate || !localStorageConfig[ACCESS_KEY]) {

      const dashboardFilterData = dashboardFilter?.get(ACCESS_KEY);
      if (dashboardFilterData) {
        const filters = config[settings.CUSTOM_FILTERING].value;
        const newFilters = filters.map(filter => {
          if (filter.columnName === dashboardFilterData.columnName.name) {
            return { ...filter, outputValue: dashboardFilterData.columnName.value }
          }
          if (filter.columnName === 'actualCreationDate') {
            return {
              ...filter, outputValue: {
                fromValue: dashboardFilterData.actualCreationDate.fromValue,
                toValue: dashboardFilterData.actualCreationDate.toValue
              }
            }
          }
          else return { ...filter, outputValue: '' }
        })
        config[settings.CUSTOM_FILTERING].value = newFilters;
      }

      localStorageConfig[ACCESS_KEY] = config;
      setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageConfig);
    }

    this.updateFiltersFromLocalStorage(ACCESS_KEY, gridFilters);

    initialize(name, {
      actionButtons,
      actionMenuItems,
      entityName,
      filters,
      gridFilters,
      limit,
      sorting,
    });

    this.setState({ settingsConfig: localStorageConfig[ACCESS_KEY] });
  }

  updateFiltersFromLocalStorage = (ACCESS_KEY, filters) => {
    let gridfilters = filters;
    let localStorageFilterConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    let filterConfig = localStorageFilterConfig[ACCESS_KEY][settings.CUSTOM_FILTERING].value;
    filterConfig &&
      filterConfig.map(item => {
        if (item.outputValue) {
          if (item.operation === filterOperation.DATE_RANGE
            || item.operation === filterOperation.MONTH
            || item.operation === filterOperation.ONE_DAY) {
            const fromValue = new Date(item.outputValue.fromValue.substring(0, 10));
            const toValue = new Date(item.outputValue.toValue.substring(0, 10));
            gridfilters.map(i => {
              if (i.columnName === item.columnName) {
                i.operation = item.operation
                delete i.value;
                i.value = {
                  fromValue: fromValue,
                  toValue: toValue,
                }
              }
            })
          }
          else {
            gridfilters.map(i => {
              if (i.columnName === item.columnName) {
                i.operation = item.operation
                i.value = item.outputValue
              }
            })
          }
        }
      })
  }

  getAccessKey = () => {
    const { accountId, name, viewName } = this.props
    return `${accountId}_${name}_${viewName || FALLBACK_VIEW_NAME}_${MOBILE_TABLE_KEY}`;
  }

  render() {
    const {
      entityName,
      name,
      initialize,
      ...restProps
    } = this.props;
    const AdvancedMobileGridContainer = createAdvancedMobileGrid(name, entityName);

    return (
      <AdvancedMobileGridContainer
        {...restProps}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
});

const mapDispatchToProps = (dispatch) => ({
  clearEntity: (entityName) => dispatch(clearEntityBase(entityName)),
  initialize: (name, params) => dispatch(register(name, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedMobileGridCreator);
