/*
 *
 * RequestsPage12924
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import CheckpointForm from 'pages/Requests/CheckpointView/CheckpointForm';
import { ReceptionForm12924, ReceptionFormDialog12924 } from './ReceptionView';
import TenantForm12924 from './TenantView/TenantForm12924';
import CheckpointFormDialogContainer12924 from './CheckpointView/CheckpointFormDialogContainer12924';
import { getRequestDetailContainer } from 'pages/common/detailContainers';


function RequestsPage12924(props) {
  return (
    <RequestsPage
      receptionForm={<ReceptionForm12924 />}
      receptionFormDialog={
        <ReceptionFormDialog12924
          form={<ReceptionForm12924 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      tenantForm={<TenantForm12924 />}
      checkpointFormDialog={<CheckpointFormDialogContainer12924 form={<CheckpointForm />} />}
      administratorBCFormDialog={<ReceptionFormDialog12924
        params={props.params}
        form={<ReceptionForm12924 />}
        detailContainer={getRequestDetailContainer()}
      />}
      {...props}
    />
  );
}

export default RequestsPage12924;
