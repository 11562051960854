/**
 *
 * ChangeRoleDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './sagas';

import * as actionCreators from './actions';
import ChangeRoleDialog from './ChangeRoleDialog';
import { makeSelectIsOpen, makeSelectCurrentRole } from './selectors';

class ChangeRoleDialogContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleAccept = (role) => this.props.accept(role);

  handleCancel = () => this.props.close();

  render() {
    return (
      <ChangeRoleDialog
        open={this.props.open}
        currentRole={this.props.currentRole}
        onAccept={this.handleAccept}
        onCancel={this.handleCancel}
      />
    );
  }
}

ChangeRoleDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  currentRole: PropTypes.string.isRequired,
  accept: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  open: makeSelectIsOpen(),
  currentRole: makeSelectCurrentRole(),
});

function mapDispatchToProps(dispatch) {
  return {
    accept: (role) => dispatch(actionCreators.acceptChangeRoleDialog(role)),
    close: () => dispatch(actionCreators.closeChangeRoleDialog()),
  };
}

const withReducer = injectReducer({ key: 'changeRoleDialog', reducer });
const withSaga = injectSaga({ key: 'changeRoleDialog', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ChangeRoleDialogContainer);
