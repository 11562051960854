/**
 *
 * AdvancedMobileGrid constants
 *
 */
export const CHANGE_CHECKED_ALL = 'claris-vnext/AdvancedMobileGrid/CHANGE_CHECKED_ALL';
export const CHANGE_IS_LOADING = 'claris-vnext/AdvancedMobileGrid/CHANGE_IS_LOADING';
export const CHANGE_IS_LIST_OVER = 'claris-vnext/AdvancedMobileGrid/CHANGE_IS_LIST_OVER';
export const CHANGE_LAZY_LOADING_STATE = 'claris-vnext/AdvancedMobileGrid/CHANGE_LAZY_LOADING_STATE';
export const CHANGE_MENU_ITEM = 'claris-vnext/AdvancedMobileGrid/CHANGE_MENU_ITEM';
export const CHANGE_SEARCH_QUERY = 'claris-vnext/AdvancedMobileGrid/CHANGE_SEARCH_QUERY';
export const CHANGE_SELECTION = 'claris-vnext/AdvancedMobileGrid/CHANGE_SELECTION';
export const CHANGE_SORTING = 'claris-vnext/AdvancedMobileGrid/CHANGE_SORTING';
export const CHANGE_GRID_FILTERS = 'claris-vnext/AdvancedMobileGrid/CHANGE_GRID_FILTERS';
export const CLEAR_SELECTION = 'claris-vnext/AdvancedMobileGrid/CLEAR_SELECTION';

export const LOAD_DATA = 'claris-vnext/AdvancedMobileGrid/LOAD_DATA';
export const LOAD_DATA_ERROR = 'claris-vnext/AdvancedMobileGrid/LOAD_DATA_ERROR';
export const LOAD_DATA_SUCCESS = 'claris-vnext/AdvancedMobileGrid/LOAD_DATA_SUCCESS';

export const REGISTER = 'claris-vnext/AdvancedMobileGrid/REGISTER';
export const UNREGISTER = 'claris-vnext/AdvancedMobileGrid/UNREGISTER';
export const CHANGE_CURRENT_RECORD = 'claris-vnext/AdvancedMobileGrid/CHANGE_CURRENT_RECORD';
export const DEFAULT_ROW_HEIGHT = 60;
