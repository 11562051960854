/**
 *
 * DetailTableFieldCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import createDetailTableFieldContainer from './createDetailTableFieldContainer';


class DetailTableFieldCreator extends React.PureComponent {
    render() {
        const DetailTableFieldContainer = createDetailTableFieldContainer(this.context.advancedForm, this.props.name);
        return <DetailTableFieldContainer {...this.props} />;
    }
}

DetailTableFieldCreator.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
};

DetailTableFieldCreator.propTypes = {
    name: PropTypes.string.isRequired,
};

export default DetailTableFieldCreator;
