/*
 *
 * Define Type Checking functions
 *
 */

const ARRAY_TYPE = 'Array';
const BOOLEAN_TYPE = 'Boolean';
const DATE_TYPE = 'Date';
const FUNCTION_TYPE = 'Function';
const NULL_TYPE = 'Null';
const NUMBER_TYPE = 'Number';
const OBJECT_TYPE = 'Object';
const REG_EXP_TYPE = 'RegExp';
const STRING_TYPE = 'String';
const UNDEFINED_TYPE = 'Undefined';

export const getTypeInfo = (object) => (Object.prototype.toString.call(object).slice(8, -1));

export const isArray = (object) => (getTypeInfo(object) === ARRAY_TYPE);
export const isBoolean = (object) => (getTypeInfo(object) === BOOLEAN_TYPE);
export const isDate = (object) => (getTypeInfo(object) === DATE_TYPE);
export const isFunction = (object) => (getTypeInfo(object) === FUNCTION_TYPE);
export const isNull = (object) => (getTypeInfo(object) === NULL_TYPE);
export const isNumber = (object) => (getTypeInfo(object) === NUMBER_TYPE);
export const isObject = (object) => (getTypeInfo(object) === OBJECT_TYPE);
export const isRegExg = (object) => (getTypeInfo(object) === REG_EXP_TYPE);
export const isString = (object) => (getTypeInfo(object) === STRING_TYPE);
export const isUndefined = (object) => (getTypeInfo(object) === UNDEFINED_TYPE);
