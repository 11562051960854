/**
*
* ActionsMenu
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';

const styleSheet = {
  icon: {},
  button: {},
  denseItem: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  denseItemIcon: {
    fontSize: 20,
    padding: 2,
  },
  denseItemText: {
    fontSize: 14,
  },
};

class ActionMenu extends React.Component { // eslint-disable-line react/prefer-stateless-function
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    const { record, onMenuItemChange } = this.props;
    onMenuItemChange([record.id]);
    event.stopPropagation();
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = (event) => {
    event.stopPropagation();
    this.setState({ open: false });
  };

  createItemClickHandler = (item) => (event) => {
    event.stopPropagation();
    this.setState({ open: false }, () => {
      const { onItemClick } = this.props;
      if (onItemClick) onItemClick(item.name);
    });
  };

  render() {
    const { classes, dense, items } = this.props;

    return (
      <div>
        <IconButton
          className={classes.button}
          onClick={this.handleClick}
        >
          <Icon className={classes.icon}>menu</Icon>
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
        >
          {
            items.map((item) => {
              if (item.hidden) return null;
              return (
                <ListItem
                  className={classNames({
                    [classes.denseItem]: dense,
                  })}
                  key={item.name}
                  onClick={this.createItemClickHandler(item)}
                  button
                >
                  <ListItemIcon>
                    <Icon
                      classes={{
                        root: dense ? classes.denseItemIcon : null,
                      }}
                    >
                      {item.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: dense ? classes.denseItemText : null,
                    }}
                    primary={item.label}
                  />
                </ListItem>
              );
            }
            )
          }
        </Menu>
      </div>
    );
  }
}

ActionMenu.propTypes = {
  classes: PropTypes.object,
  dense: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ),
  onItemClick: PropTypes.func,
  record: PropTypes.object,
  onMenuItemChange: PropTypes.func,
};

export default withStyles(styleSheet)(ActionMenu);
