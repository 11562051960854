/*
 *
 *  mobile row template for supply request
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    isCurrentYear,
    formattedDate,
    formattedNumeric,
    formattedCurrentYearDate,
} from 'utils/dateUtils';

import {withStyles} from '@material-ui/core/styles/index';
import {statusColorLabels} from './mobileStatusLabels';

const styles = () => ({
    root: {
        width: '100%',
        padding: '0 5px',
    },
    label: {
        padding: '1px 5px',
        textTransform: 'uppercase',
        borderRadius: 15,
    },
    highlight: {
        fontWeight: 'bold',
    },
    lineItem: {
        marginLeft: 5,
    },
    overflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const HeaderComponent = ({classes, number, status, statusStyles}) => (
    <div className={classes.overflow}>
    <span>
      № <span>{number}</span>
    </span>
        <span
            style={statusStyles}
            className={classNames(classes.lineItem, classes.label)}
        >
      {status}
    </span>

    </div>
);

HeaderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    status: PropTypes.string,
    statusStyles: PropTypes.object,
    number: PropTypes.string,
};

const FooterComponent = ({classes, actualCreationDate, company,}) => (
    <div className={classes.overflow}>
        {
            <span>{actualCreationDate}</span>
        }
        <div>
            {
                <span>{company}</span>
            }
        </div>
    </div>
);

FooterComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    actualCreationDate: PropTypes.string,
};

const currentYearDateOptions = {
    day: 'numeric',
    month: 'long',
};

class RowComponentBase extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        record: PropTypes.object.isRequired,
        maxWidth: PropTypes.number.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        const {record} = this.props;
        const currentStatus = record.state ? record.state.name : null;
        const nextStatus = nextProps.record.state ? nextProps.record.state.name : null;

        return (record.number === nextProps.record.number)
            || (currentStatus === nextStatus);
    }

    getContent = () => {
        const {record, classes} = this.props;

        return (
            <div
                className={classNames(classes.overflow, classes.highlight)}
            >
                {record.actualCreationDate}
            </div>
        );
    };

    render() {
        const {record, classes, maxWidth} = this.props;
        const number = record.autoNumber && formattedNumeric(record.autoNumber);
        const status = record.state ? record.state.name : undefined;
        const actualCreationDate = record.actualCreationDate && isCurrentYear(record.actualCreationDate)
            ? formattedCurrentYearDate(record.actualCreationDate, currentYearDateOptions)
            : formattedDate(record.actualCreationDate);
        const company = record.company ? record.company.name : undefined;

        const statusStyles = statusColorLabels[status];

        return (
            <div className={classes.root} style={{maxWidth}}>
                <HeaderComponent
                    classes={classes}
                    number={number}
                    status={status}
                    statusStyles={statusStyles}
                />
                <FooterComponent
                    classes={classes}
                    actualCreationDate={actualCreationDate ? actualCreationDate : ''}
                    company={company}
                />
            </div>
        );
    }
}

export const SupplyRequestRowComponent = withStyles(styles)(RowComponentBase);
