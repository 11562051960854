/*
 *
 * AssignedFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { ASSIGNED_REQUESTS_FORM, ASSIGNED_REQUESTS_FORM_DIALOG } from './constants';

function AssignedFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={ASSIGNED_REQUESTS_FORM_DIALOG}
      formName={ASSIGNED_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
      reportModeTitle="Отправка отчёта"
    />
  );
}

AssignedFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default AssignedFormDialog;
