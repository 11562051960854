/**
 *
 * UsersForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { APPLICANT, USERS_FORM } from './constants';
import { formField } from '../../../domain/typeConstants/form';
import { validatePassword } from 'utils/validators';

class UsersForm extends React.PureComponent {

  getFields() {
    const { mode } = this.props;
    return [
      {
        name: 'fullName',
        label: 'Фамилия Имя Отчество',
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        required: true,
        rowBreakBefore: true,
      },
      {
        name: 'company',
        required: true,
        type: 'autocomplete',
        label: 'Контрагент',
        entityName: 'companies',
        rowBreakBefore: true,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'department',
        required: true,
        type: 'select',
        label: 'Отдел',
        entityName: 'departments',
        rowBreakBefore: true,
      },
      {
        name: 'email',
        label: 'E-mail',
        entityName: 'email',
        rowBreakBefore: true,
        required: (values) => values.isSendEmail,
      },
      {
        name: 'phone',
        label: 'Телефон',
        entityName: 'phone',
        rowBreakBefore: true,
      },
      {
        name: 'name',
        label: 'Логин',
        required: true,
        disabled: (currentValues, initialValues, mode) => mode === 'edit',
        rowBreakBefore: true,
      },
      {
        name: 'password',
        label: 'Пароль',
        password: true,
        required: () => mode === 'add',
        validate: validatePassword,
        rowBreakBefore: true,
      },
      {
        name: 'passwordConfirmation',
        label: 'Подтверждение пароля',
        password: true,
        visible: (currentValues) => currentValues.password,
        required: () => mode === 'add',
        validate: (currentValues) => {
          if (currentValues.passwordConfirmation !== currentValues.password) { return { errorHelperText: 'Пароль и подтверждение не совпадают' } }
          return null;
        },
        rowBreakBefore: true,
      },
      {
        name: 'roles',
        type: 'select',
        label: 'Роли пользователя',
        multiple: true,
        entityName: 'roles',
        orderBy: 'name',
        rowBreakBefore: true,
        initialValue: (values, mode) => mode === 'add' ? [APPLICANT] : values.roles,
      },
      {
        name: 'isSendEmail',
        type: formField.CHECKBOX,
        label: (currentValues, initialValues, mode) => {
          if (mode === 'edit') { return 'Отправить на e-mail логин и новый пароль' }
          else
            return 'Отправить на e-mail пароль и логин'
        },
        entityName: 'isSendEmail',
        disabled: false,
        rowBreakBefore: true,
      },
      {
        name: 'forcePasswordChange',
        type: formField.CHECKBOX,
        label: 'Требовать смену пароля при входе',
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={USERS_FORM}
        entityName="users"
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}

export default UsersForm;
