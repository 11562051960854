/*
 *
 * SupplyRequestForm15767
 *
 */


import {REQUEST} from 'domain/Data/DocumentType/constants';
import * as DocumentState from 'domain/Data/DocumentState/constants';
import SupplyRequestForm from 'pages/Documents/SupplyRequestView/SupplyRequestForm';
import {addActionButton} from "./buttons";


export class SupplyRequestForm15767 extends SupplyRequestForm {

    getFields() {
        const currentDate = new Date();
        return [
            {
                name: 'actualCreationDate',
                label: 'Дата',
                type: 'date',
                visible: false,
                initialValue: (values, mode) => {
                    if (mode === 'add') {
                        return currentDate;
                    }
                    return values.actualCreationDate;
                },
            },
            {
                name: 'author',
                type: 'select',
                label: 'Автор',
                entityName: 'employees',
                visible: false,
                initialValue: (values, mode, user) => user.id,
                disabled: true,
            },
            {
                name: 'state',
                type: 'select',
                label: 'Статус',
                entityName: 'documentStates',
                visible: false,
                initialValue: (values, mode) => {
                    if (mode === 'add') return DocumentState.ON_CREATED;
                    return values.state;
                },
                disabled: true,
            },
            {
                name: 'category',
                visible: false,
                initialValue: (values, mode) => {
                    if (mode === 'add') return REQUEST;
                    return values.state;
                },
            },
            {
                name: 'project',
                type: 'select',
                entityName: 'projects',
                label: 'Проект',
                required: true,
            },
            {
                name: 'manager',
                visible: false,
                calculatedValue: (values, mode, entities) => {
                    if (mode === 'add' && values.project) {
                        const project = entities.getIn(['projects', values.project]);
                        return project?.manager
                    }
                    return null
                },
            },
            {
                name: 'director',
                visible: false,
                calculatedValue: (values, mode, entities) => {
                    if (mode === 'add' && values.project) {
                        const project = entities.getIn(['projects', values.project]);
                        return project?.director
                    }
                    return null
                },
            },
            {
                name: 'supplier',
                visible: false,
                calculatedValue: (values, mode, entities) => {
                    if (mode === 'add' && values.project) {
                        const project = entities.getIn(['projects', values.project]);
                        return project.supplier
                    }
                    return null
                },
            },
            {
                name: 'pto',
                visible: false,
                calculatedValue: (values, mode, entities) => {
                    if (mode === 'add' && values.project) {
                        const project = entities.getIn(['projects', values.project]);
                        return project?.pto
                    }
                    return null
                },
            },
            {
                name: 'agreementScheme',
                type: 'select',
                entityName: 'agreementSchemes',
                label: 'Маршрут',
                required: true,
            },
            {
                name: 'scan',
                label: 'Файл',
                type: 'file',
                visible: true,
            },
            {
                name: 'content',
                label: 'Содержание',
                visible: true,
                grid: {sm: 12},
            },
            {
                name: 'positions',
                label: 'Позиции',
                type: 'table',
                entityName: 'documents',
                serviceName: 'SupplyRequestsPositions',
                filterBy: (currentValues) => currentValues.project ? `project.id.contains("${currentValues.project}")` : null,
                filterEntityName: 'documentPositions',
                filter: (currentValues) => currentValues.id ? `document.id.contains("${currentValues.id}")` : null,
                actionButtons: [addActionButton],
                grid: {sm: 12},
            }
        ];
    }

}
