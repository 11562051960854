/*
 *
 * AdvancedDataTable actions
 *
 */

import {
  CHANGE_SELECTION,
  CLEAR_SELECTION,
  CHANGE_MENU_ITEM,
  CHANGE_COLUMN_ORDER,
  CHANGE_COLUMN_WIDTHS,
  CHANGE_COLUMNS,
  CHANGE_EXPANDED_ROW_IDS,
  CLEAR_COLLAPSE_ROWS_DATA,
  CHANGE_LOADER_STATE,
  CHANGE_CURRENT_RECORD,
  CHANGE_LAZY_LOADING_STATE,
  CHANGE_VISIBLE_COLUMNS,
  CLEAR_SEARCH_QUERY,
  CHANGE_SEARCH_QUERY,
  CHANGE_GRID_FILTERS,
  CLEAR_FILTER_DIALOG,
  LOAD_DATA,
  LOAD_DATA_SUCCESS,
  LOAD_DATA_ERROR,
  REGISTER,
  UNREGISTER,
  CHANGE_TAB,
  SET_CONTAINER_WIDTH,
  GET_DOWNLOAD_LINK,
  SAVE_FILE_EXTENSION,
  SET_DOWNLOAD_LINK,
  CLEAR_DOWNLOAD_LINK,
  CLEAR_IDS,
  OPEN_PREVIEW_DRAWER,
} from './constants';

export function changeSelection(dataTable, selection) {
  return {
    type: CHANGE_SELECTION,
    meta: { dataTable },
    payload: { selection },
  };
}

export function changeTab(dataTable, id) {
  return {
    type: CHANGE_TAB,
    meta: { dataTable },
    payload: { id },
  };
}


export function setContainerWidth(width) {
  return {
    type: SET_CONTAINER_WIDTH,
    payload: { width },
  };
}


export function clearSelection(dataTable) {
  return {
    type: CLEAR_SELECTION,
    meta: { dataTable },
  };
}

export function changeMenuItem(dataTable, recordId) {
  return {
    type: CHANGE_MENU_ITEM,
    meta: { dataTable },
    payload: { recordId },
  };
}

export const changeLoaderState = (dataTable, { loaderState }) => ({
  type: CHANGE_LOADER_STATE,
  meta: { dataTable },
  payload: { loaderState },
});

export function changeColumnOrder(dataTable, columnOrder) {
  return {
    type: CHANGE_COLUMN_ORDER,
    meta: { dataTable },
    payload: { columnOrder },
  };
}

export function changeColumnWidths(dataTable, columnWidths) {
  return {
    type: CHANGE_COLUMN_WIDTHS,
    meta: { dataTable },
    payload: { columnWidths },
  };
}

export function changeColumns(dataTable, columns) {
  return {
    type: CHANGE_COLUMNS,
    meta: { dataTable },
    payload: { columns },
  };
}

export function changeVisibleColumns(dataTable, hiddenColumns) {
  return {
    type: CHANGE_VISIBLE_COLUMNS,
    meta: { dataTable },
    payload: { hiddenColumns },
  };
}

export function changeExpandedRowIds(dataTable, expandedRowIds) {
  return {
    type: CHANGE_EXPANDED_ROW_IDS,
    meta: { dataTable },
    payload: { expandedRowIds },
  };
}

export function clearIds(dataTable) {
  return {
    type: CLEAR_IDS,
    meta: { dataTable },
  };
}

export function clearCollapseRowsData(dataTable) {
  return {
    type: CLEAR_COLLAPSE_ROWS_DATA,
    meta: { dataTable },
  };
}

export function changeCurrentRecord(dataTable, record) {
  return {
    type: CHANGE_CURRENT_RECORD,
    meta: { dataTable },
    payload: { record },
  };
}

export function clearSearchQuery(dataTable, {
  entityName,
  filters,
  sorting,
  pageSize,
  pageNumber,
  limit,
  startAt,
  mode,
  parent,
  parentId,
} = {}) {
  return {
    type: CLEAR_SEARCH_QUERY,
    meta: { dataTable },
    payload: {
      entityName,
      filters,
      sorting,
      pageSize,
      pageNumber,
      limit,
      startAt,
      mode,
      parent,
      parentId,
    },
  };
}


export function changeSearchQuery(dataTable, searchQuery) {
  return {
    type: CHANGE_SEARCH_QUERY,
    meta: { dataTable },
    payload: { searchQuery },
  };
}

export function changeGridFilters(dataTable, {
  gridFilters,
  entityName,
  filters,
  sorting,
  pageSize,
  pageNumber,
  limit,
  startAt,
  mode,
  parent,
  parentId,
} = {}) {
  return {
    type: CHANGE_GRID_FILTERS,
    meta: { dataTable },
    payload: {
      gridFilters,
      entityName,
      filters,
      sorting,
      pageSize,
      pageNumber,
      limit,
      startAt,
      mode,
      parent,
      parentId,
    },
  };
}

export function clearFilterDialog(dataTable, isClear) {
  return {
    type: CLEAR_FILTER_DIALOG,
    meta: { dataTable },
    payload: { isClear },
  };
}

export function loadData(dataTable, {
  entityName,
  filters,
  searchQuery,
  sorting,
  pageSize,
  pageNumber,
  limit,
  startAt,
  mode,
  parent,
  parentId,
  dialogName,
} = {}) {
  return {
    type: LOAD_DATA,
    meta: { dataTable },
    payload: {
      entityName,
      filters,
      searchQuery,
      sorting,
      pageSize,
      pageNumber,
      limit,
      startAt,
      mode,
      parent,
      parentId,
      dialogName,
    },
  };
}

export function changeLazyLoadingState(dataTable, lazyLoadingState) {
  return {
    type: CHANGE_LAZY_LOADING_STATE,
    meta: { dataTable },
    payload: { lazyLoadingState },
  };
}

export function dataLoaded(dataTable, ids) {
  return {
    type: LOAD_DATA_SUCCESS,
    meta: { dataTable },
    payload: { ids },
  };
}

export function dataLoadingError(dataTable, error) {
  return {
    type: LOAD_DATA_ERROR,
    meta: { dataTable },
    payload: { error },
  };
}

export function register(dataTable, {
  entityName,
  columns,
  columnOrder,
  columnWidths,
  filters,
  gridFilters,
  sorting,
  pageSize,
  pageSizes,
  pageNumber,
  actionButtons,
  actionMenuItems,
  lazyLoadingBlockSize,
  columnVisibilityNames,
  detailContainerNames,
  ids,
  isOpenDrawer,
  drawerRecord,
} = {}) {
  return {
    type: REGISTER,
    meta: { dataTable },
    payload: {
      entityName,
      columns,
      columnOrder,
      columnWidths,
      filters,
      gridFilters,
      sorting,
      pageSize,
      pageSizes,
      pageNumber,
      actionButtons,
      actionMenuItems,
      lazyLoadingBlockSize,
      columnVisibilityNames,
      detailContainerNames,
      ids,
      isOpenDrawer,
      drawerRecord,
    },
  };
}

export function unregister(dataTable) {
  return {
    type: UNREGISTER,
    meta: { dataTable },
  };
}

export function getDownloadLink(dataTable, fileId) {
  return {
    type: GET_DOWNLOAD_LINK,
    meta: { dataTable },
    payload: { fileId },
  };
}

export function saveFileExtension(dataTable, extension) {
  return {
    type: SAVE_FILE_EXTENSION,
    meta: { dataTable },
    payload: { extension },
  };
}

export function setDownloadLink(dataTable, link) {
  return {
    type: SET_DOWNLOAD_LINK,
    meta: { dataTable },
    payload: { link },
  };
}

export function clearDownloadLink(dataTable) {
  return {
    type: CLEAR_DOWNLOAD_LINK,
    meta: { dataTable },
  };
}

export function openPreviewDrawer(dataTable, mode, drawerRecord) {
  return {
    type: OPEN_PREVIEW_DRAWER,
    meta: { dataTable },
    payload: { mode, drawerRecord }
  };
}