/**
 *
 * 11994 reception dxGridColumnFilters
 *
 */

export const ALL_COLUMN_FILTERS = [
  { columnName: 'number', value: '' },
  { columnName: 'visitorFullName', value: '' },
  { columnName: 'carNumber', value: '' },
  { columnName: 'carModelText', value: '' },
  { columnName: 'visitDate', value: '' },
  { columnName: 'expirationDate', value: '' },
  { columnName: 'statusName', value: '' },
  { columnName: 'typeShortName', value: '' },
  { columnName: 'company_name', value: '' },
  { columnName: 'documentDetails', value: '' },
  { columnName: 'accessCardNumber', value: '' },
  { columnName: 'parkingPlace', value: '' },
  { columnName: 'materialValuesData', value: '' },
  { columnName: 'companyName', value: '' },
  { columnName: 'note', value: '' },
  { columnName: 'blackListNote', value: '' },
  { columnName: 'authorName', value: '' },
];

export const TODAY_COLUMN_FILTERS = [
  { columnName: 'number', value: '' },
  { columnName: 'visitorFullName', value: '' },
  { columnName: 'visitDate', value: '' },
  { columnName: 'expirationDate', value: '' },
  { columnName: 'statusName', value: '' },
  { columnName: 'company_name', value: '' },
  { columnName: 'documentDetails', value: '' },
  { columnName: 'accessCardNumber', value: '' },
  { columnName: 'parkingPlace', value: '' },
  { columnName: 'companyName', value: '' },
  { columnName: 'note', value: '' },
  { columnName: 'authorName', value: '' },
  { columnName: 'typeShortName', value: '' },
];
