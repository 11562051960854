/*
 *
 * RequestsPage12405
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';

import VisitorArrivalForm12405 from "./ReceptionView/VisitorArrivalForm12405";

function RequestsPage12405(props) {
    return (
        <RequestsPage
            visitorArrivalForm={<VisitorArrivalForm12405 />}
            {...props}
        />
    );
}

export default RequestsPage12405;
