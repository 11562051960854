/**
 *
 * ParkingOperatorView sagas
 *
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import {
  processVisitorArrivalSaga,
  processVisitorDepartureSaga,
} from 'domain/Data/Request/sagas';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { openViewDialog } from 'containers/AdvancedFormDialog/actions';

import {
  PARKING_OPERATOR_REQUESTS_TABLE_GRID,
  PARKING_OPERATOR_REQUESTS_FORM_DIALOG,
  EXECUTE_VIEW_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
} from './constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const isParkingOperatorFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
 action.meta.dialog === PARKING_OPERATOR_REQUESTS_FORM_DIALOG;

export default function createParkingOperatorViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_ARRIVED_ACTION, (config.executeArrivedActionSaga || executeArrivedActionSaga));
    yield takeLatest(EXECUTE_DEPARTED_ACTION, (config.executeDepartedActionSaga || executeDepartedActionSaga));

    yield takeLatest(isParkingOperatorFormSaved, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(PARKING_OPERATOR_REQUESTS_TABLE_GRID));
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(PARKING_OPERATOR_REQUESTS_FORM_DIALOG, id));
  }
}

export function* executeArrivedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorArrivalSaga, ids);
  yield put(loadData(PARKING_OPERATOR_REQUESTS_TABLE_GRID));
}

export function* executeDepartedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorDepartureSaga, ids);
  yield put(loadData(PARKING_OPERATOR_REQUESTS_TABLE_GRID));
}
