/*
 *
 * CheckpointView actions
 *
 */

import {
  EXECUTE_OPEN_BARRIER_ACTION,
  OPEN_BARRIER,
  OPEN_BARRIER_SUCCESS,
  OPEN_BARRIER_ERROR,
} from './constants';

export function executeOpenBarrierAction(dialogName) {
  return {
    type: EXECUTE_OPEN_BARRIER_ACTION,
    meta: { dialogName },
  };
}

export function openBarrier() {
  return {
    type: OPEN_BARRIER,
  };
}

export function barrierOpened() {
  return {
    type: OPEN_BARRIER_SUCCESS,
  };
}

export function barrierOpeningError(error) {
  return {
    type: OPEN_BARRIER_ERROR,
    payload: { error },
  };
}
