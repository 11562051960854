/*
 *
 * TenantDxGrid15755
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';


export class TenantDxGrid15755 extends TenantDxGrid {


}
