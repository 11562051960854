/*
 *
 * ReceptionDxGrid15774
 *
 */

import {ReceptionDxGrid} from 'pages/Requests/ReceptionView';
import {requestAction} from "domain/typeConstants/action";
import {requestColumnSizes} from "domain/typeConstants/table";
import {
    ACCESS_CARD_REQUESTS, ACTIVE_REQUESTS, CURRENT_DAY_REQUESTS, NOT_PASS_ISSUED,
    ON_CREATE_REQUESTS, REQUESTS_ON_A_DEPT, REQUESTS_ON_B_DEPT, REQUESTS_ON_CAR_TYPE,
    REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED, REQUESTS_ON_VISIT, REQUESTS_ON_WORK_PRODUCTION
} from "domain/Data/Filters/constants";
import {acceptActionButton, arrivedGroupActionButton} from "../common/actionButton";
import {acceptActionItem} from "../common/actionMenuItem";


export class ReceptionDxGrid15774 extends ReceptionDxGrid {

    getFilters() {
        const {viewName} = this.props;
        const filters = [];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisitA' || viewName === 'onVisitB') {
            filters.push(REQUESTS_ON_VISIT);
            filters.push(ACTIVE_REQUESTS);
            filters.push(NOT_PASS_ISSUED);
        }
        viewName === 'onVisitA' && filters.push(REQUESTS_ON_A_DEPT);
        viewName === 'onVisitB' && filters.push(REQUESTS_ON_B_DEPT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_TYPE);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'property') filters.push(REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED);
        if (viewName === 'passMaking') filters.push(ACCESS_CARD_REQUESTS);
        return filters;
    }

    getColumns() {
        const {viewName} = this.props;
        const columns = super.getColumns();

        if (viewName === 'all' || viewName === 'onVisitA' || viewName === 'onVisitB') {
            columns.push({
                path: ['company','department','name'],
                name: 'companyDepartment',
                title: 'Здание',
                getCellValue: (row) => row.companyDepartment ? row.companyDepartment.name : undefined,
                width: requestColumnSizes.company_name,
            });
        }

        return columns;
    }

    getColumnOrder() {
        const {viewName} = this.props;
        const columnOrder = super.getColumnOrder();

        if (viewName === 'property') {
            const whatHappenedIndex = columnOrder.findIndex(columnName => columnName === 'whatHappened')
            columnOrder.splice(whatHappenedIndex, 1);
            const serviceNameIndex = columnOrder.findIndex(columnName => columnName === 'serviceName')
            columnOrder.splice(serviceNameIndex, 1);
            const companyNameIndex = columnOrder.findIndex(columnName => columnName === 'companyName')
            columnOrder.splice(companyNameIndex, 1);
        }
        if (viewName === 'all' || viewName === 'onVisitA' || viewName === 'onVisitB') {
            const companyNameIndex = columnOrder.findIndex(columnName => columnName === 'company_name')
            columnOrder.splice(companyNameIndex + 1, 0, 'companyDepartment');
        }

        return columnOrder;
    }

    getActionButtons() {
        const {viewName} = this.props;
        const actionButtons = super.getActionButtons()
        const rejectButtonIndex = actionButtons.findIndex(button => button.name === requestAction.REJECT)
        actionButtons.splice(rejectButtonIndex, 1)
        const acceptButtonIndex = actionButtons.findIndex(button => button.name === requestAction.ACCEPT)
        actionButtons.splice(acceptButtonIndex, 1, acceptActionButton)
        if (viewName === 'onVisitA' || viewName === 'onVisitB') {
            actionButtons.push(arrivedGroupActionButton)
        }
        return actionButtons
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems()
        const rejectMenuItemIndex = actionMenuItems.findIndex(button => button.name === requestAction.REJECT)
        actionMenuItems.splice(rejectMenuItemIndex, 1)

        const acceptMenuItemIndex = actionMenuItems.findIndex(menu => menu.name === requestAction.ACCEPT)
        actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem)

        return actionMenuItems
    }

}
