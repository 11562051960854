/**
 *
 * AdminSkudView sagas
 *
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { CLOSED } from 'domain/Data/RequestStatus/constants';
import { makeSelectEntity } from 'domain/Data/selectors';
import { acceptRequestsSaga } from 'domain/Data/Request/sagas';

import {
  openAddDialog,
  openEditDialog,
  openCopyDialog,
  openViewDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
  ADMIN_SKUD_REQUESTS_TABLE_GRID,
  ADMIN_SKUD_REQUESTS_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_REJECT_ACTION,
} from './constants';

import { REJECT_FORM_DIALOG } from '../ReceptionView/constants'
import { checkUserForBlockingSaga } from 'domain/User/sagas';
import { clearDetailRowData } from 'containers/AdvancedForm/actions';
import { WORK_GRID } from 'pages/common/constants';
import { WORKS } from 'domain/Data/constants';

const isAdminSkudFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  (action.meta.dialog === ADMIN_SKUD_REQUESTS_FORM_DIALOG || action.meta.dialog === REJECT_FORM_DIALOG);

  
export default function createAdminSkudSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_COPY_ACTION, (config.executeCopyActionSaga || executeCopyActionSaga));
    yield takeLatest(EXECUTE_ACCEPT_ACTION, (config.executeAcceptActionSaga || executeAcceptActionSaga));
    yield takeLatest(EXECUTE_REJECT_ACTION, (config.executeRejectActionSaga || executeRejectActionSaga));
    yield takeLatest(isAdminSkudFormSaved, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(ADMIN_SKUD_REQUESTS_TABLE_GRID));
}

function* executeAddActionSaga() {
  yield put(clearDetailRowData(WORK_GRID, WORKS))
  yield put(openAddDialog(ADMIN_SKUD_REQUESTS_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  yield put(clearDetailRowData(WORK_GRID, WORKS))
  const requests = yield select(makeSelectEntity('requests', id));
  if (requests.status === CLOSED) {
    yield put(openViewDialog(ADMIN_SKUD_REQUESTS_FORM_DIALOG, { id }));
  } else if (id) {
    const status = requests.status;
    const parentId = null;
    yield put(openEditDialog(ADMIN_SKUD_REQUESTS_FORM_DIALOG, { id, parentId, status }));
  }
}

function* executeCopyActionSaga({ payload: { id } }) {
  if (id) {
    yield put(clearDetailRowData(WORK_GRID, WORKS))
    yield put(openCopyDialog(ADMIN_SKUD_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeAcceptActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(acceptRequestsSaga, ids);
  yield put(loadData(ADMIN_SKUD_REQUESTS_TABLE_GRID));
}

function* executeRejectActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    yield put(openEditDialog(REJECT_FORM_DIALOG, { id: ids[0] }));
  }
}
