/**
 *
 * TenantForm14419
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import { formField } from 'domain/typeConstants/form';
import * as entity from 'domain/Data/constants';
import { CREATED } from 'domain/Data/RequestStatus/constants';
import {
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  CARRYING_OUT_WORKS,
} from 'domain/Data/RequestType/constants';
import { validateCarNumber } from 'utils/validators';
import {
  expirationDateVisible, longtermVisible, multipleEntryVisible,
  parkingCalculatedValue, parkingDisabled, parkingVisible,
  typeHelperText, typeVisibleOptions, visitDateRowBreakBefore,
  nonstandardCarNumberVisible, carModelTextVisible, carNumberVisible,
  parkingPlaceTenantVisible, companyNameLabel, companyNameVisible,
  companyNameRequired, carryInOrTakeawayMCV, fireWork,
  productionPersonnel, responsibilityForWork, responsibleForFireSafety,
  responsibleForSafetyAndHealth, shutdownEquipment, workPlace,
  workType, factoryTimeLabel, requestScan,
} from '../common/fields';

import { helperTextClassName } from '../common/formClassNames';
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class TenantForm14419 extends TenantForm {

  getClassNames() {
    return {
      helperText: helperTextClassName,
    };
  }

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'customer',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        cached: false,
        type: formField.SELECT,
        entityName: entity.COMPANIES,
        name: 'company',
        label: 'Арендатор',
        visible: false,
        initialValue: (values, mode, user) => user.companyId,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: entity.REQUEST_STATUSES,
        visible: (currentValues, initialValues, mode) => mode === 'view',
        initialValue: CREATED,
        orderBy: 'name',
      },
      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        required: true,
        entityName: entity.REQUEST_TYPES,
        orderBy: 'order',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_CAR_ENTRY;
          }
          return values.type;
        },
        clearErrorsOnChange: true,
        helperText: typeHelperText,
        visibleOptions: typeVisibleOptions,
      },
      {
        name: 'longterm',
        type: formField.CHECKBOX,
        label: 'Долгосрочная',
        visible: longtermVisible,
      },
      {
        name: 'visitDate',
        type: formField.DATE,
        label: 'Дата',
        required: true,
        visible: true,
        initialValue: currentDate,
        rowBreakBefore: visitDateRowBreakBefore,
        helperText: () => 'не менее чем за 1 час до прибытия автомобиля',
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
      },
      {
        name: 'expirationDate',
        type: formField.DATE,
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: expirationDateVisible,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm ? values.expirationDate : null,
        min: (values) => new Date(values.visitDate) < currentDate ?
          new Date(values.visitDate) : currentDate,
      },
      workType,
      {
        name: 'factoryTime',
        type: formField.SELECT,
        label: factoryTimeLabel,
        entityName: entity.TIMES,
        required: true,
        visible: (currentValues) => currentValues.type === ON_PROPERTY_BRINGING || currentValues.type === ON_PROPERTY_REMOVAL || currentValues.type === CARRYING_OUT_WORKS,
        helperText: (values) => {
          if (values.type === CARRYING_OUT_WORKS) {
            return 'Время проведения работ по правилам БП';
          }
          return null;
        },
      },
      {
        name: 'isGroupRequest',
        type: formField.CHECKBOX,
        label: 'Групповая заявка',
        visible: (values) => values.type === ON_CAR_ENTRY || values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: true,
      },
      {
        name: 'nonstandardCarNumber',
        type: formField.CHECKBOX,
        label: 'Нестандартный № авто',
        rowBreakBefore: true,
        visible: nonstandardCarNumberVisible,
      },
      {
        name: 'multipleEntry',
        label: 'Многократный въезд',
        type: formField.CHECKBOX,
        visible: multipleEntryVisible,
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest,
        required: (values) => values.isGroupRequest,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'visitorFullName',
        label: (values) => values.type === ON_CAR_ENTRY ? 'Гость/Посетитель/Подрядчик' : 'ФИО посетителя',
        type: formField.DADATA,
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        visible: (values) => !values.isGroupRequest && values.type !== CARRYING_OUT_WORKS,
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        required: (values) => !values.isGroupRequest,
        rowBreakBefore: true,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        required: (values) => (values.type === ON_CAR_ENTRY || values.type === ON_PROPERTY_REMOVAL || values.type === ON_PROPERTY_BRINGING)
          && !values.isGroupRequest,
        visible: carNumberVisible,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: carModelTextVisible,
      },
      {
        name: 'parking',
        type: formField.SELECT,
        label: 'Парковка',
        entityName: entity.PARKINGS,
        visible: parkingVisible,
        disabled: parkingDisabled,
        calculatedValue: parkingCalculatedValue,
      },
      {
        name: 'parkingPlace',
        label: 'Место парковки',
        visible: parkingPlaceTenantVisible,
        initialValue: () => null,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whatHappened',
        label: 'Наименование\\Характер работ',
        multiline: false,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        grid: { sm: 12 },
      },
      {
        name: 'companyName',
        label: companyNameLabel,
        visible: companyNameVisible,
        required: companyNameRequired,
      },
      {
        name: 'responsibleFromContractor',
        label: 'Ответственный от подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'responsibleFromContractorPhone',
        label: 'Телефон отв. подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        visible: true,
        grid: { sm: 12 },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        disabled: true,
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
      },
      workPlace,
      requestScan,
      {
        name: 'file',
        label: 'Договор подряда',
        type: formField.FILE,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: true,
        helperText: 'Скан договора подряда',
      },
      responsibilityForWork,
      responsibleForSafetyAndHealth,
      responsibleForFireSafety,
      fireWork,
      shutdownEquipment,
      carryInOrTakeawayMCV,
      productionPersonnel,
    ];
  }
}
