/**
 *
 * MultiAutocomplete constants
 *
 */

export const CLEAR_OPTIONS = 'claris-vnext/AdvancedForm/MultiAutocomplete/CLEAR_OPTIONS';
export const LOAD_OPTIONS = 'claris-vnext/AdvancedForm/MultiAutocomplete/LOAD_OPTIONS';
export const IS_INITIALIZED = 'claris-vnext/AdvancedForm/MultiAutocomplete/IS_INITIALIZED';

export const INITIALIZATION = 'claris-vnext/AdvancedForm/MultiAutocomplete/INITIALIZATION';
