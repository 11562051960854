/**
 *
 * SupplyRequestView sagas
 *
 */

import {call, select, put, takeLatest} from 'redux-saga/effects';
import {startSubmit, stopSubmit} from "redux-form/immutable";
import {
    makeSelectCurrentFieldValue,
    makeSelectDetailTableData,
    makeSelectEntityId
} from 'containers/AdvancedForm/selectors';
import * as api from 'utils/api';
import {openAlertDialog} from 'containers/AlertDialog/actions';
import {loadData} from 'containers/AdvancedDataTable/actions';
import {loadData as loadMobileData} from 'containers/AdvancedMobileGrid/actions';

import {openAgreementDialog} from 'pages/Documents/common/AgreementDialog/actions';
import {openDisagreementDialog} from 'pages/Documents/common/DisagreementDialog/actions';

import {saveDialogSaga} from 'containers/AdvancedFormDialog/sagas';
import {makeSelectIsOpen} from 'containers/AdvancedFormDialog/selectors';
import {makeSelectViewMode} from 'domain/Screen/selectors';

import {SAVE_DIALOG_SUCCESS} from 'containers/AdvancedFormDialog/constants';
import {
    closeDialog,
    openViewDialog,
    openAddDialog,
    openEditDialog,
    openCopyDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
    SUPPLY_REQUEST_AGREEMENT_FORM_DIALOG,
    SUPPLY_REQUEST_DISAGREEMENT_FORM_DIALOG,
    SUPPLY_REQUEST_DX_GRID,
    SUPPLY_REQUEST_FORM_DIALOG,

    EXECUTE_AGREE_ACTION,
    EXECUTE_PRINT_ACTION,
    EXECUTE_DISAGREE_ACTION,
    EXECUTE_VIEW_ACTION,
    EXECUTE_ADD_ACTION,
    EXECUTE_EDIT_ACTION,

    EXECUTE_AGREE_DIALOG_ACTION,
    EXECUTE_DISAGREE_DIALOG_ACTION,
    EXECUTE_RUN_DIALOG_ACTION,

    EXECUTE_POSITION_ADD_ACTION,
    EXECUTE_POSITION_EDIT_ACTION,
    EXECUTE_POSITION_VIEW_ACTION,
    EXECUTE_POSITION_COPY_ACTION,
    SUPPLY_REQUEST_POSITION_FORM_DIALOG,
    EXECUTE_ADD_NEW_ACTION, SUPPLY_REQUEST_FORM,
} from './constants';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {account} from 'domain/typeConstants/accounts';
import {DOCUMENTS, DOCUMENT_POSITIONS, ESTIMATE_ITEMS} from 'domain/Data/constants';
import {makeSelectEntity, makeSelectEntityData} from 'domain/Data/selectors';
import {GLOBAL_USER_KEY} from 'global-constants';
import {saveEntitySaga} from 'domain/Data/sagas';
import makeSelectUser from 'domain/User/selectors';
import {ON_CREATED} from 'domain/Data/DocumentState/constants';
import {REQUEST} from 'domain/Data/DocumentType/constants';

import {validateFormSaga} from "../../../containers/AdvancedForm/sagas";


const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && (
    action.meta.dialog === SUPPLY_REQUEST_FORM_DIALOG
    || action.meta.dialog === SUPPLY_REQUEST_AGREEMENT_FORM_DIALOG
    || action.meta.dialog === SUPPLY_REQUEST_DISAGREEMENT_FORM_DIALOG
);

export default function createSupplyRequestViewSaga(config = {}) {
    return function* rootSaga() {
        yield takeLatest(EXECUTE_AGREE_ACTION, (config.executeAgreeActionSaga || executeAgreeActionSaga));
        yield takeLatest(EXECUTE_PRINT_ACTION, (config.executePrintActionSaga || executePrintActionSaga));
        yield takeLatest(EXECUTE_DISAGREE_ACTION, (config.executeDisagreeActionSaga || executeDisagreeActionSaga));

        yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
        yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
        yield takeLatest(EXECUTE_ADD_NEW_ACTION, (config.executeAddActionSaga || executeAddNewActionSaga));
        yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));

        yield takeLatest(EXECUTE_AGREE_DIALOG_ACTION, (config.executeAgreeDialogActionSaga || executeAgreeDialogActionSaga));
        yield takeLatest(EXECUTE_DISAGREE_DIALOG_ACTION, (config.executeDisagreeDialogActionSaga || executeDisagreeDialogActionSaga));
        yield takeLatest(EXECUTE_RUN_DIALOG_ACTION, (config.executeRunDialogActionSaga || executeRunDialogActionSaga));
        yield takeLatest(EXECUTE_POSITION_ADD_ACTION, executePositionAddActionSaga);
        yield takeLatest(EXECUTE_POSITION_EDIT_ACTION, executePositionEditActionSaga);
        yield takeLatest(EXECUTE_POSITION_VIEW_ACTION, executePositionViewActionSaga);
        yield takeLatest(EXECUTE_POSITION_COPY_ACTION, executePositionCopyActionSaga);

        yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
    };
}

function* executePositionAddActionSaga({payload: {parentId}}) {
    yield put(openAddDialog(SUPPLY_REQUEST_POSITION_FORM_DIALOG, {}, parentId));
}

function* executePositionEditActionSaga({payload: {id, parentId}}) {
    if (id) {
        yield put(openEditDialog(SUPPLY_REQUEST_POSITION_FORM_DIALOG, {id, parentId}));
    }
}

function* executePositionViewActionSaga({payload: {id, parentId}}) {
    if (id) {
        yield put(openViewDialog(SUPPLY_REQUEST_POSITION_FORM_DIALOG, {id, parentId}));
    }
}

function* executePositionCopyActionSaga({payload: {id, parentId}}) {
    if (id) {
        yield put(openCopyDialog(SUPPLY_REQUEST_POSITION_FORM_DIALOG, {id, parentId}));
    }
}

function* executeNeedViewRefreshSaga() {
    const mode = yield select(makeSelectViewMode());
    if (mode === 'mobile') {
        yield put(loadMobileData(SUPPLY_REQUEST_DX_GRID))
    } else yield put(loadData(SUPPLY_REQUEST_DX_GRID));
}

export function* executeAgreeActionSaga({payload: {ids}}) {
    if (ids) {
        yield put(openAgreementDialog(ids, SUPPLY_REQUEST_DX_GRID));
    }
}

export function* executeDisagreeActionSaga({payload: {ids}}) {
    if (ids) {
        yield put(openDisagreementDialog(ids, SUPPLY_REQUEST_DX_GRID));
    }
}

export function* executePrintActionSaga({payload: {id}}) {
    if (id) {
        console.log('executePrintActionSaga', id);
    }
}

export function* executeViewActionSaga({payload: {id}}) {
    if (id) {
        yield put(openViewDialog(SUPPLY_REQUEST_FORM_DIALOG, {id}));
    }
}

export function* executeEditActionSaga({payload: {id}}) {
    if (id) {
        yield put(openEditDialog(SUPPLY_REQUEST_FORM_DIALOG, {id}));
    }
}

export function* executeAddActionSaga() {
    yield put(openAddDialog(SUPPLY_REQUEST_FORM_DIALOG, {}));
}


export function* executeAddNewActionSaga() {
    const user = yield select(makeSelectUser())
    const currentValues = {
        author: user.id,
        state: ON_CREATED,
        type: REQUEST,
        responsible: user.id,
        category: REQUEST,
    }
    const result = yield call(saveEntitySaga, DOCUMENTS, currentValues)
    yield put(loadData(SUPPLY_REQUEST_DX_GRID))
    yield put(openEditDialog(SUPPLY_REQUEST_FORM_DIALOG, {id: result.id}));
}

export function* executeAgreeDialogActionSaga({meta}) {
    const entityId = yield select(makeSelectEntityId(meta.form));
    yield put(openAgreementDialog([entityId], SUPPLY_REQUEST_DX_GRID));
    yield put(closeDialog(meta.dialog));
}

export function* executeDisagreeDialogActionSaga({meta}) {
    const entityId = yield select(makeSelectEntityId(meta.form));
    yield put(openDisagreementDialog([entityId], SUPPLY_REQUEST_DX_GRID));
    yield put(closeDialog(meta.dialog));
}

export function* executeRunDialogActionSaga({meta}) {
    yield put(startSubmit(meta.form));
    const errors = yield call(validateFormSaga, meta.form);
    if (Object.values(errors).length > 0) {
        yield put(stopSubmit(meta.form, errors));
        return
    }
    let entityId = yield select(makeSelectEntityId(meta.form));

    let checkAvailablePositions = true
    const user = getValueFromLocalStorage(GLOBAL_USER_KEY)

    // TVK check documentPositions
    if (user.accountId === account.TVK) {
        const documentPositionsRecords = yield select(makeSelectDetailTableData(SUPPLY_REQUEST_FORM, DOCUMENT_POSITIONS));
        const documentPositions = documentPositionsRecords ? documentPositionsRecords.filter(d => d?.id !== null) : []
        const limitedAgreements = ['4961034120000', '4970067124000']
        const currentAgreementScheme = yield select(makeSelectCurrentFieldValue(meta.form, 'agreementScheme'));

        if (limitedAgreements.includes(currentAgreementScheme)) {
            for (let i = 0; i < documentPositions.length; i++) {
                const count = Number(documentPositions[i]?.positionsCount)
                const available = documentPositions[i]?.estimateItem?.available
                if (count > available) {
                    checkAvailablePositions = false
                }
            }
        }
    }

    if (!checkAvailablePositions) {
        const header = 'Внимание';
        let content = 'Превышен лимит ЛЗВ. Поменяйте маршрут согласования.';
        yield put(openAlertDialog(header, content));
    } else {
        const saveDialogResult = yield call(saveDialogSaga, meta.dialog);
        if (!entityId) {
            entityId = saveDialogResult && saveDialogResult.id;
        }
        const dialogIsOpen = yield select(makeSelectIsOpen(meta.dialog));
        if (dialogIsOpen) {
            return;
        }
        try {
            yield call(api.runDocument, entityId);
            yield put(loadData(SUPPLY_REQUEST_DX_GRID));
        } catch (err) {
            console.error(err);
            const header = 'Операция прервана';
            let content = 'Произошла ошибка на сервере при обработке документа.';

            const {response} = err;
            if (response && response.data) {
                const data = JSON.parse(response.data);
                if (data && data.message) {
                    content = data.message;
                }
            }
            yield put(openAlertDialog(header, content));
        }
    }
}
