/**
 *
 * ServiceRequestsManagerForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { CARRYING_OUT_WORKS, SERVICE } from 'domain/Data/RequestType/constants';
import {
  CREATED,
  CLOSED,
  ACCEPTED,
  COMPLETED,
  REJECTED,
  IN_PROGRESS,
} from 'domain/Data/RequestStatus/constants';
import { formField } from 'domain/typeConstants/form';
import * as entities from 'domain/Data/constants';
import { validateCarNumber } from 'utils/validators';
import { SERVICE_REQUESTS_MANAGER_REQUESTS_FORM } from './constants';
import { sliceText } from 'utils/formatUtils'
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';

class ServiceRequestsManagerForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();
    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        disabled: true,
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        visibleOptions: () => [COMPLETED, CLOSED, REJECTED, ACCEPTED, CREATED, IN_PROGRESS],
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return CREATED; }

          return values.status;
        },
        required: true,
      },
      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        initialValue: () => SERVICE,
        visibleOptions: () => [SERVICE, CARRYING_OUT_WORKS],
        orderBy: 'order',
        clearErrorsOnChange: true,
      },
      {
        name: 'longterm',
        type: 'checkbox',
        label: 'Долгосрочная',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'actualCreationDate',
        type: formField.DATE,
        visible: false,
        initialValue: currentDate,
      },
      {
        name: 'isGroupRequest',
        type: 'checkbox',
        label: 'Групповая заявка',
        rowBreakBefore: (values) => values.type !== SERVICE,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'workType',
        label: 'Вид',
        type: formField.SELECT,
        entityName: entities.WORK_TYPES,
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'requestPriority',
        type: formField.SELECT,
        label: 'Приоритет',
        entityName: 'requestPriorities',
        visible: (values, initialValues, mode) => values.type === SERVICE || (mode === 'edit' || mode === 'add')
          && values.type !== CARRYING_OUT_WORKS,
      },
      {
        name: 'companyName',
        label: 'Подрядчик',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'responsibleFromContractor',
        label: 'Ответственный от подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'responsibleFromContractorPhone',
        label: 'Телефон отв. подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
      },
      {
        name: 'nonstandardCarNumber',
        type: 'checkbox',
        label: 'Нестандартный № авто',
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakAfter: (values) => values.type === CARRYING_OUT_WORKS,
        visible: (values) => values.type === CARRYING_OUT_WORKS && !values.isGroupRequest,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => values.type === CARRYING_OUT_WORKS && !values.isGroupRequest,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => values.type === CARRYING_OUT_WORKS && !values.isGroupRequest,
      },
      {
        name: 'service',
        type: formField.SELECT,
        label: 'Сервис',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'whatHappened',
        label: (values) => values.type === CARRYING_OUT_WORKS ? 'Наименование/Характер работ' : 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE || values.type === CARRYING_OUT_WORKS,
        grid: { sm: 12 },
        required: (values) => values.type === SERVICE,
        normalize: (value) => value ? sliceText(value, 8000) : value,
      },
      {
        name: 'file',
        label: 'Файл',
        type: formField.FILE,
        visible: true,
        rowBreakBefore: true,
      },
      {
        name: 'workPlace',
        label: 'Место проведения',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: (values) => values.type === CARRYING_OUT_WORKS,
      },
      {
        name: 'assignee',
        type: formField.SELECT,
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        rowBreakAfter: (values) => values.type === SERVICE,
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => {
            if (item.isPerformer) assignee.push(item.id);
          });

          return assignee;
        },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        visible: (values) => values.type === SERVICE,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },
      {
        name: 'executionTerm',
        type: formField.DATE,
        label: 'Срок исполнения',
        visible: (values) => values.type === SERVICE,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 6 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        grid: { sm: 6 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
        required: (values) => values.status === REJECTED
      },

      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
        normalize: (value) => value ? sliceText(value, 252) : value,
      },
      {
        name: 'company',
        label: 'Арендатор',
        type: 'select',
        entityName: 'companies',
        initialValue: (values, mode, user) => user.companyId,
        cached: false,
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        numberInQueueFromEnd: 1
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={SERVICE_REQUESTS_MANAGER_REQUESTS_FORM}
        entityName="requests"
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}

export default ServiceRequestsManagerForm;
