/**
 *
 * AdvancedMobileGrid.row.center-block component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 7,
    paddingRight: 7,
    width: 185,
  },
  primary: {
    fontSize: 13,
    fontWeight: 'bold',
    height: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  second: {
    fontSize: 12,
    maxHeight: 37,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const CenterBlockBase = ({
  rootComponent: Root, record, maxWidth, accountId
}) => (
  Root
    ? (
      <Root
        record={record}
        maxWidth={maxWidth}
        accountId={accountId}
      />
    ) : (
      <div>Default Template</div>
    )
);

CenterBlockBase.propTypes = {
  rootComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  record: PropTypes.object.isRequired,
  maxWidth: PropTypes.number.isRequired,
};

export const CenterBlock = withStyles(styles, { name: 'CenterBlock' })(CenterBlockBase);
