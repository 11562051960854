/**
 *
 * ReceptionMobileGrid 14366
 *
 */

import ReceptionMobileGrid from 'pages/Requests/ReceptionView/ReceptionMobileGrid';

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE,
  REQUESTS_WITHOUT_SERVICE_TYPE,
  ON_CREATE_REQUESTS,
} from 'domain/Data/Filters/constants';
import { BULVAR_TSVETNOY } from 'custom/14366/domain/Data/Departments/constants';

export class ReceptionMobileGrid14366 extends ReceptionMobileGrid {

  getFilters() {
    const { viewName, department } = this.props;
    const filters = [REQUESTS_FOR_USER_DEPARTMENT];

    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (department === BULVAR_TSVETNOY && (viewName === 'all' || viewName === 'today')) {
      filters.push(REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE);
      filters.push(REQUESTS_WITHOUT_SERVICE_TYPE);
    }
    return filters;
  }


  getDetailRowFields() {
    return super.getDetailRowFields();
  }

  getActionButtons() {
    return super.getActionButtons();
  }

  getActionMenuItems() {
    return super.getActionMenuItems();
  }

  getSorting() {
    return super.getSorting();
  }
}
