/**
 *
 * TenantForm13557
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';

import { CREATED, ACCEPTED } from 'domain/Data/RequestStatus/constants';
import {
  ON_CAR_ENTRY,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { TP_1_PARKING, TP_2_PARKING, TP_3_PARKING } from "../../../domain/Data/Parking/constants";

class TenantForm13557 extends TenantForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();
    const unnecessaryParking = new Set([TP_1_PARKING, TP_2_PARKING, TP_3_PARKING]);

    const whoMeetsIndex = fields.findIndex((field) => field.name === 'whoMeets');
    fields[whoMeetsIndex].required = (values) => values.type === ON_VISIT;

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].calculatedValue = (values) => values.type === SERVICE ?
      CREATED : ACCEPTED;

    const parkingPlaceIndex = fields.findIndex((field) => field.name === 'parkingPlace');
    fields[parkingPlaceIndex].visible = (values) => values.type === ON_CAR_ENTRY;

    const parkingIndex = fields.findIndex((field) => field.name === 'parking');
    fields[parkingIndex].visibleOptions = (currentValues, initialValues, mode, user, entities) => {
      const parking = [];
      entities.get('parkings').map((item) => {
        if (!unnecessaryParking.has(item.id)) parking.push(item.id);
      });
      return parking;
    };

    return fields;
  }
}

export default TenantForm13557;
