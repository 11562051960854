/**
 *
 * AgreementDialog reducer
 *
 */

import { Map, List } from 'immutable';

import {
  CLOSE_AGREEMENT_DIALOG,
  OPEN_AGREEMENT_DIALOG,
  SUBMIT_AGREEMENT_DIALOG,
} from './constants';

const initialState = new Map({
  open: false,
  ids: new List([]),
});

function agreementDialogReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_AGREEMENT_DIALOG:
      return state
        .set('open', true)
        .set('ids', new List(payload.ids))
        .set('dxGrid', payload.dxGrid);

    case CLOSE_AGREEMENT_DIALOG:
      return state
        .set('open', false)
        .set('ids', new List([]))
        .set('dxGrid', '');

    case SUBMIT_AGREEMENT_DIALOG:
      return state
        .set('open', false)
        .set('ids', new List([]))
        .set('dxGrid', '');

    default:
      return state;
  }
}


export default agreementDialogReducer;
