/**
 * BreadcrumbDivider
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';

const styleSheet = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 4,
    marginRight: 4,
  },
};

function BreadcrumbDivider({ classes }) {
  return (
    <div className={classes.root}>
      <ChevronRight />
    </div>
  );
}

BreadcrumbDivider.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styleSheet)(BreadcrumbDivider);
