/*
 *
 * ServiceRequestsManagerMobileGrid14326
 *
 */

import ServiceRequestsManagerMobileGrid from 'pages/Requests/ServiceRequestsManagerView/ServiceRequestsManagerMobileGrid'
import {
    ACTIVE_REQUESTS,
    REQUESTS_SERVICE_AND_WORK_PRODUCTION,
} from "../../../../../domain/Data/Filters/constants";
import {
    addBlackListCarActionItem,
    arrivedActionItem,
    departedActionItem, sendEmailActionMenuItem,
    sendToAgreementActionMenuItem
} from "../common/actionMenuItem";
import {requestAction} from "../../../../../domain/typeConstants/action";

export class ServiceRequestsManagerMobileGrid14326 extends ServiceRequestsManagerMobileGrid {
    getFilters() {
        const { viewName } = this.props; // eslint-disable-line
        const filters = [REQUESTS_SERVICE_AND_WORK_PRODUCTION];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        return filters;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();

        const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
        actionMenuItems[editIndex].label = 'Открыть';

        actionMenuItems.push(arrivedActionItem);
        actionMenuItems.push(departedActionItem);
        actionMenuItems.push(addBlackListCarActionItem);
        actionMenuItems.push(sendToAgreementActionMenuItem);
        actionMenuItems.push(sendEmailActionMenuItem);

        return actionMenuItems;
    }
}
