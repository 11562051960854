import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import ServiceRequestsManagerView, {
  ServiceRequestsManagerAcceptForm,
  ServiceRequestsManagerAcceptFormDialog,
  ServiceRequestsManagerCompleteForm,
  ServiceRequestsManagerCompleteFormDialog,
  ServiceRequestsManagerDxGrid,
  ServiceRequestsManagerDxGridContainer,
  ServiceRequestsManagerForm,
  ServiceRequestsManagerFormDialog,
  ServiceRequestsManagerRejectForm,
  ServiceRequestsManagerRejectFormDialog,
  ServiceRequestsManagerMobileGrid,
  ServiceRequestsManagerBlackListForm,
  ServiceRequestsManagerBlackListFormDialog,
  ServiceRequestsManagerVisitorArrivalForm,
  ServiceRequestsManagerVisitorArrivalFormDialog,
  WorkForm,
  WorkFormDialog,
} from '../ServiceRequestsManagerView';
import {
  AgreementDialog,
} from '../common/AgreementDialog';

const getDxGrid = (DxGrid) => (
  <ServiceRequestsManagerDxGridContainer component={DxGrid || <ServiceRequestsManagerDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <ServiceRequestsManagerDxGridContainer component={MobileGrid || <ServiceRequestsManagerMobileGrid />} />
);

const getDataTableView = ({
  breakpoint,
  serviceRequestsManagerDxGrid,
  serviceRequestsManagerMobileGrid,
}) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(serviceRequestsManagerMobileGrid);
  }
  if (isUndefined(serviceRequestsManagerDxGrid) || serviceRequestsManagerDxGrid) {
    return getDxGrid(serviceRequestsManagerDxGrid);
  }
};

const getFormDialog = (Form) => (
  <ServiceRequestsManagerFormDialog form={Form || <ServiceRequestsManagerForm />} />
);

const getWorkFormDialog = () => (
  <WorkFormDialog form={<WorkForm />} />
);

const getRejectFormDialog = (rejectForm) => (
  <ServiceRequestsManagerRejectFormDialog form={rejectForm || <ServiceRequestsManagerRejectForm />} />
);

const getAcceptFormDialog = (acceptForm) => (
  <ServiceRequestsManagerAcceptFormDialog form={acceptForm || <ServiceRequestsManagerAcceptForm />} />
);

const getCompleteFormDialog = (completeForm) => (
  <ServiceRequestsManagerCompleteFormDialog form={completeForm || <ServiceRequestsManagerCompleteForm />} />
);

const getAgreementDialog = () => <AgreementDialog />;

const getBlackListFormDialog = (blackListForm) => (
  <ServiceRequestsManagerBlackListFormDialog form={blackListForm || <ServiceRequestsManagerBlackListForm />} />
);

const getVisitorArrivalFormDialog = (arrivalForm) => (
  <ServiceRequestsManagerVisitorArrivalFormDialog form={arrivalForm || <ServiceRequestsManagerVisitorArrivalForm />} />
);

export const getServiceRequestsManagerView = (props) => {
  const {
    breakpoint,
    serviceRequestsManagerDxGrid,
    serviceRequestsManagerMobileGrid,
    serviceRequestsManagerForm,
    serviceRequestsManagerFormDialog,
    serviceRequestsManagerView,
    serviceRequestsManagerRejectForm,
    serviceRequestsManagerRejectFormDialog,
    serviceRequestsManagerAcceptForm,
    serviceRequestsManagerAcceptFormDialog,
    serviceRequestsManagerCompleteForm,
    serviceRequestsManagerCompleteFormDialog,
    serviceRequestsManagerBlackListForm,
    serviceRequestsManagerBlackListFormDialog,
    serviceRequestsManagerVisitorArrivalForm,
    serviceRequestsManagerVisitorArrivalFormDialog,
    viewName,
    viewStyles,
  } = props;

  if (serviceRequestsManagerView) return serviceRequestsManagerView;

  return (
    <ServiceRequestsManagerView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        serviceRequestsManagerDxGrid,
        serviceRequestsManagerMobileGrid,
      })}
      formDialog={serviceRequestsManagerFormDialog || getFormDialog(serviceRequestsManagerForm)}
      agreementDialog={getAgreementDialog()}
      serviceRequestsManagerAcceptFormDialog={serviceRequestsManagerAcceptFormDialog
        || getAcceptFormDialog(serviceRequestsManagerAcceptForm)}
      serviceRequestsManagerRejectFormDialog={serviceRequestsManagerRejectFormDialog
        || getRejectFormDialog(serviceRequestsManagerRejectForm)}
      serviceRequestsManagerCompleteFormDialog={serviceRequestsManagerCompleteFormDialog
        || getCompleteFormDialog(serviceRequestsManagerCompleteForm)}
      serviceRequestsManagerBlackListFormDialog={serviceRequestsManagerBlackListFormDialog
        || getBlackListFormDialog(serviceRequestsManagerBlackListForm)}
      serviceRequestsManagerVisitorArrivalFormDialog={serviceRequestsManagerVisitorArrivalFormDialog
        || getVisitorArrivalFormDialog(serviceRequestsManagerVisitorArrivalForm)}
        workformDialog={getWorkFormDialog()}
    />
  );
};

getServiceRequestsManagerView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  serviceRequestsManagerDxGrid: PropTypes.node,
  serviceRequestsManagerMobileGrid: PropTypes.node,
  serviceRequestsManagerForm: PropTypes.node,
  serviceRequestsManagerFormDialog: PropTypes.node,
  serviceRequestsManagerView: PropTypes.node,
  serviceRequestsManagerRejectForm: PropTypes.node,
  serviceRequestsManagerRejectFormDialog: PropTypes.node,
  serviceRequestsManagerAcceptForm: PropTypes.node,
  serviceRequestsManagerAcceptFormDialog: PropTypes.node,
  serviceRequestsManagerCompleteForm: PropTypes.node,
  serviceRequestsManagerCompleteFormDialog: PropTypes.node,
  serviceRequestsManagerBlackListForm: PropTypes.node,
  serviceRequestsManagerBlackListFormDialog: PropTypes.node,
  serviceRequestsManagerVisitorArrivalForm: PropTypes.node,
  serviceRequestsManagerVisitorArrivalFormDialog: PropTypes.node,
  workForm: PropTypes.node,
  workFormDialog: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
