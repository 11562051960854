/**
 *
 * ReceptionView sagas
 *
 */

import { put, takeLatest, select } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import { openAddDialog, openCopyDialog, openEditDialog, openViewDialog } from 'containers/AdvancedFormDialog/actions';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import {
    EXECUTE_ADD_ACTION,
    EXECUTE_EDIT_ACTION,
    EXECUTE_VIEW_ACTION,
    EXECUTE_COPY_ACTION,
    RECEPTION_COMPANIES_FORM_DIALOG,
    RECEPTION_COMPANIES_DX_GRID,
} from './constants';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS &&
    action.meta.dialog === RECEPTION_COMPANIES_FORM_DIALOG;

export default function createReceptionViewSaga(config = {}) {
    return function* rootSaga() {
        yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
        yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
        yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
        yield takeLatest(EXECUTE_COPY_ACTION, (config.executeViewActionSaga || executeCopyActionSaga));
        yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
    };
}

function* executeNeedViewRefreshSaga() {
    const mode = yield select(makeSelectViewMode());
    if (mode === 'mobile') {
        yield put(loadMobileData(RECEPTION_COMPANIES_DX_GRID))
    } else yield put(loadData(RECEPTION_COMPANIES_DX_GRID));
}

function* executeAddActionSaga() {
    yield put(openAddDialog(RECEPTION_COMPANIES_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
    if (id) {
        yield put(openEditDialog(RECEPTION_COMPANIES_FORM_DIALOG, { id }));
    }
}

function* executeViewActionSaga({ payload: { id } }) {
    if (id) {
        yield put(openViewDialog(RECEPTION_COMPANIES_FORM_DIALOG, { id }));
    }
}

export function* executeCopyActionSaga({ payload: { id } }) {
    if (id) {
        yield put(openCopyDialog(RECEPTION_COMPANIES_FORM_DIALOG, { id }));
    }
}
