/**
 *
 * Document actions
 *
 */

import {
  SEND_CARGO,
  CARGO_RECEIVE_SUCCESS,
  CARGO_RECEIVE_ERROR,
  DELIVERY_ORIGINALS,
  DELIVERED_ORIGINALS_SUCCESS,
  DELIVERY_ORIGINALS_ERROR,
} from './constants';

export function documentSendCargo(id) {
  return {
    type: SEND_CARGO,
    payload: { id },
  };
}

export function documentCargoReceivedSuccess() {
  return {
    type: CARGO_RECEIVE_SUCCESS,
  };
}

export function documentCargoReceiveError(err) {
  return {
    type: CARGO_RECEIVE_ERROR,
    payload: { err },
  };
}


export function documentDeliveryOriginals(id) {
  return {
    type: DELIVERY_ORIGINALS,
    payload: { id },
  };
}

export function documentDeliveredOriginalsSuccess() {
  return {
    type: DELIVERED_ORIGINALS_SUCCESS,
  };
}

export function documentDeliveryOriginalsError(err) {
  return {
    type: DELIVERY_ORIGINALS_ERROR,
    payload: { err },
  };
}

