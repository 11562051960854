/*
 *
 * RequestsPage14691
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import { ReceptionForm14691, ReceptionDxGrid14691, ReceptionRejectForm14691, ReceptionFormDialog14691 } from './ReceptionView';
import { TenantForm14691, TenantDxGrid14691 } from './TenantView';
import {
  AdministratorBCRequestsForm14691,
  AdministratorBCRequestsFormDialogContainer14691,
  AdministratorBCRequestsDxGrid14691,
} from './AdministratorBCView';

import {
  CheckpointDxGrid14691,
  CheckpointForm14691,
} from './CheckpointView';

import {
  ServiceRequestsManagerForm14691,
  ServiceRequestsManagerDxGrid14691,
} from './ServiceRequestsManagerView';

import { ReceptionRejectFormDialog } from "../../../../pages/Requests/ReceptionView";
import { getRequestDetailContainer } from 'pages/common/detailContainers';


function RequestsPage14691(props) {


  return (
    <RequestsPage
      administratorBCForm={<AdministratorBCRequestsForm14691 />}
      administratorBCFormDialog={<AdministratorBCRequestsFormDialogContainer14691
        form={<AdministratorBCRequestsForm14691 />}
        detailContainer={getRequestDetailContainer()}
      />}
      administratorBCDxGrid={<AdministratorBCRequestsDxGrid14691 />}
      administratorBCRejectForm={<ReceptionRejectForm14691 />}

      receptionForm={<ReceptionForm14691 />}
      receptionFormDialog={
        <ReceptionFormDialog14691
          form={<ReceptionForm14691 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      receptionDxGrid={<ReceptionDxGrid14691 />}
      rejectFormDialog={<ReceptionRejectFormDialog form={<ReceptionRejectForm14691 />} />}

      tenantForm={<TenantForm14691 />}
      tenantDxGrid={<TenantDxGrid14691 />}

      checkpointDxGrid={<CheckpointDxGrid14691 />}
      checkpointForm={<CheckpointForm14691 />}

      serviceRequestsManagerForm={<ServiceRequestsManagerForm14691 />}
      serviceRequestsManagerDxGrid={<ServiceRequestsManagerDxGrid14691 />}
      {...props}
    />
  );
}

export default RequestsPage14691;
