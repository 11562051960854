/**
 *
 * ReceptionForm11994
 *
 */

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReceptionForm } from 'pages/Requests/ReceptionView';
import { ON_CAR_ENTRY, ON_VISIT, SERVICE } from 'domain/Data/RequestType/constants';
import { CREATED, ON_TERRITORY, REJECTED } from 'domain/Data/RequestStatus/constants';
import { PASSPORT } from 'domain/Data/DocumentType/constants';
import {
  GUEST_PARKING,
  TENANT_PARKING,
  LOADING_AND_UNLOADING_PARKING,
} from 'custom/11994/domain/Data/Parking/constants';
import { ON_IMPORT_CARGO, ON_EXPORT_CARGO, ON_MOVEMENT_TMC } from 'custom/11994/domain/Data/RequestType/constants';
import { CHILDREN_HOSPIS } from 'custom/11994/domain/Data/Company/constants';
import { validateCarNumber } from 'utils/validators';
import { makeSelectRoles } from 'domain/User/selectors';
import { validateThatExpirationDateIsNotLessThanVisitDate } from '../utils';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';
import { groupRequestVisitorsDataHelperText, visitorFullNameLabel } from 'pages/Requests/common/fields';

class ReceptionForm11994 extends ReceptionForm {
  getFields() {
    const currentDate = new Date();
    const { userRoles } = this.props;

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'customer',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        type: 'date',
        visible: false,
        initialValue: () => null,
        calculatedValue: (values, mode) => mode === 'add' && values.type === ON_VISIT ? currentDate : null
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'department',
        visible: false,
        initialValue: (values, mode, user) => user.departmentId,
      },
      {
        name: 'passNumber',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'view',
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return values.type === ON_VISIT ? ON_TERRITORY : CREATED; }

          return values.status;
        },
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const status = entities.get('requestStatuses').valueSeq().toJS();
          if (status && (currentValues.status === REJECTED || currentValues.status === CREATED)) {
            return status.filter(item => item.id !== ON_TERRITORY).map((item) => item.id);
          }
        },
        orderBy: 'name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        orderBy: 'order',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_VISIT;
          }
          return values.type;
        },
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          if (currentValues.company) {
            const company = entities
              .get('companies')
              .get(currentValues.company);
            return company ? company.get('requestTypes') || [] : [];
          }

          return [];
        },
        clearErrorsOnChange: true,
      },
      {
        name: 'longterm',
        type: 'checkbox',
        label: 'Долгосрочная',
        visible: (values) => values.type !== ON_CAR_ENTRY || values.parking !== GUEST_PARKING,
        calculatedValue: (values) => values.type === ON_CAR_ENTRY &&
          values.parking === GUEST_PARKING ? null : values.longterm,
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        required: true,
        initialValue: currentDate,
        rowBreakBefore: true,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'expirationDate',
        type: 'date',
        label: 'Дата окончания',
        validate: validateThatExpirationDateIsNotLessThanVisitDate,
        required: (values) => values.longterm,
        visible: (values) => values.longterm &&
          (values.type !== ON_CAR_ENTRY || values.parking !== GUEST_PARKING),
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => {
          if (values.parking === GUEST_PARKING) return null;
          if (values.longterm) return values.expirationDate;
          return null;
        },
        min: (values) => new Date(values.visitDate) < currentDate ?
          new Date(values.visitDate) : currentDate,
        max: (values) => {
          if (values.company === CHILDREN_HOSPIS) {
            const current = new Date();
            return current.setMonth(current.getMonth() + 1);
          }
          return undefined;
        },
      },
      {
        name: 'materialValuesMovementTime',
        label: 'Время перемещения',
        visible: (values) => values.type === ON_MOVEMENT_TMC,
        rowBreakBefore: true,
      },
      {
        name: 'isGroupRequest',
        type: 'checkbox',
        label: 'Групповая заявка',
        rowBreakBefore: true,
        visible: () => {
          if (userRoles.length !== 0) {
            return userRoles.some((item) => { // eslint-disable-line
              if (item === 'lefortGroupRequestViewing') return true;
            });
          }
          return false;
        },
      },
      {
        name: 'nonstandardCarNumber',
        type: 'checkbox',
        label: 'Нестандартный № авто',
        rowBreakBefore: false,
        rowBreakAfter: true,
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_EXPORT_CARGO || values.type === ON_IMPORT_CARGO,
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO,
        required: true,
        visibleOptions: (values) => values.type === ON_CAR_ENTRY ?
          [GUEST_PARKING, TENANT_PARKING] : [LOADING_AND_UNLOADING_PARKING],
        calculatedValue: (values) => {
          if (values.type === ON_CAR_ENTRY) {
            if (values.parking !== GUEST_PARKING && values.parking !== TENANT_PARKING) {
              return null;
            }
            return values.parking;
          } else if (values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO) {
            return LOADING_AND_UNLOADING_PARKING;
          }
          return null;
        },
        rowBreakBefore: true,
      },
      {
        name: 'workingTime',
        type: 'select',
        label: 'Время',
        entityName: 'workingTimes',
        visible: (values) => values.type === ON_CAR_ENTRY && values.parking === GUEST_PARKING,
        calculatedValue: (values) => values.type === ON_CAR_ENTRY && values.parking === GUEST_PARKING ?
          values.workingTime : null,
      },
      {
        name: 'parkingPlace',
        label: 'Место парковки',
        initialValue: () => null,
        visible: (values) => (values.type === ON_CAR_ENTRY && values.parking === TENANT_PARKING) ||
          values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO,
        required: (values) => values.type === ON_CAR_ENTRY && values.parking === TENANT_PARKING,
        calculatedValue: (values, mode) => {
          if (mode === 'copy' && values.type === ON_VISIT) {
            return null;
          }
          return values.parkingPlace;
        },
      },
      {
        name: 'company',
        type: 'autocomplete',
        label: 'Арендатор',
        entityName: 'companies',
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        orderBy: 'name',
        rowBreakBefore: true,
        initialValue: (values, mode, user) => user.companyId,
        numberInQueueFromEnd: 1,
        required: true,
      },
      {
        name: 'maxEntriesNumber',
        label: 'Максимальное кол-во визитов',
        visible: (values) => values.type === ON_VISIT || values.type === ON_MOVEMENT_TMC ||
          values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO ||
          (values.type === ON_CAR_ENTRY && values.parking !== GUEST_PARKING),
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        required: true,
        visible: (values) => (values.type === ON_VISIT || values.type === ON_MOVEMENT_TMC) &&
          !values.isGroupRequest,
        rowBreakBefore: true,
      },
      {
        name: 'materialValuesPickupPlace',
        label: 'Место забора',
        visible: (values) => values.type === ON_MOVEMENT_TMC,
        required: true,
        rowBreakBefore: true,
      },
      {
        name: 'materialValuesDestinationPlace',
        label: 'Место назначения',
        visible: (values) => values.type === ON_MOVEMENT_TMC,
        required: true,
      },
      {
        name: 'accessCard',
        type: 'autocomplete',
        label: 'Карта доступа',
        entityName: 'accessCards',
        visible: (values) => values.type === ON_VISIT,
        initialValue: () => null,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO) &&
          !values.isGroupRequest,
        required: true,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModel',
        type: 'autocomplete',
        label: 'Марка автомобиля',
        entityName: 'carModels',
        orderBy: 'name',
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_IMPORT_CARGO || values.type === ON_EXPORT_CARGO) &&
          !values.isGroupRequest,
        required: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy' && values.type === ON_VISIT) {
            return null;
          }
          return values.carModel;
        },
      },
      {
        name: 'carModelText',
        visible: false,
        calculatedValue: (values, mode) => {
          if (mode === 'copy' && values.type === ON_VISIT) {
            return null;
          }
          return values.carModelText;
        },
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
        rowBreakBefore: true,
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: true
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_IMPORT_CARGO ||
          values.type === ON_EXPORT_CARGO || values.type === ON_MOVEMENT_TMC,
      },
      {
        name: 'documentType',
        type: 'select',
        label: 'Вид документа',
        initialValue: PASSPORT,
        entityName: 'documentTypes',
        rowBreakBefore: true,
        visible: (values) => values.type === ON_VISIT &&
          !values.isGroupRequest,
        calculatedValue: (values) => values.isGroupRequest ? PASSPORT : values.documentType,
      },
      {
        name: 'documentDetails',
        label: 'Серия, № документа',
        visible: (values) => values.type === ON_VISIT &&
          !values.isGroupRequest,
        required: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.note;
        },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
        required: (currentValues) => currentValues.status === REJECTED,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: (values) => values.type === ON_IMPORT_CARGO ||
          values.type === ON_EXPORT_CARGO || values.type === ON_MOVEMENT_TMC,
        rowBreakBefore: true,
      },
    ];
  }
}

const mapStateToProps = createStructuredSelector({
  userRoles: makeSelectRoles(),
});

export default connect(mapStateToProps)(ReceptionForm11994);
