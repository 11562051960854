/**
 *
 * ActionMenu Items
 *
 */

import React from 'react';

import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Clear from '@material-ui/icons/Clear';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import Print from '@material-ui/icons/Print';

import { requestAction, documentAction } from 'domain/typeConstants/action';
import { documentStatus } from 'domain/typeConstants/status';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants'

export const viewActionItem = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
  hidden: (itemMenuRecord, entities, currentRecord) => {

    if (currentRecord) {
      if (!currentRecord.status) return false
      return (currentRecord.status.name !== documentStatus.ARCHIVED)
    } else if (itemMenuRecord.length === 1) {
      const state = entities[0].state?.name;
      if (!state) return false;
      return !(state === documentStatus.ARCHIVED);
    }
    return true;
  },
};

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return (currentRecord.status.name === documentStatus.ARCHIVED)
    } else if (itemMenuRecord.length === 1) {
      const state = entities[0].state?.name;
      if (!state) return false;
      return (state === documentStatus.ARCHIVED);
    }
    return true;
  },
};

export const agreeActionItem = {
  name: documentAction.AGREE,
  label: 'Согласовать',
  icon: 'assignment_turned_in',
  svgIcon: <AssignmentTurnedIn />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      const sightingEmployeesText = currentRecord?.agreementStep?.sightingEmployeesText || ""
      const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
      return !sightingEmployeesText.split(',').map(i => i.trim()).includes(userName)
    } else {
      const sightingEmployeesText = entities[0] && entities[0].agreementStep
        && entities[0].agreementStep.sightingEmployeesText ?
        entities[0].agreementStep.sightingEmployeesText : ""
      const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
      return itemMenuRecord.length !== 1 || !sightingEmployeesText.split(',').map(i => i.trim()).includes(userName)
    }
  },
};

export const disagreeActionItem = {
  name: documentAction.DISAGREE,
  label: 'Отклонить',
  icon: 'clear',
  svgIcon: <Clear />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      const sightingEmployeesText = currentRecord?.agreementStep?.sightingEmployeesText || ""
      const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
      return !sightingEmployeesText.split(',').map(i => i.trim()).includes(userName)
    } else {
      const sightingEmployeesText = entities[0] && entities[0].agreementStep
        && entities[0].agreementStep.sightingEmployeesText ?
        entities[0].agreementStep.sightingEmployeesText : ""
      const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
      return itemMenuRecord.length !== 1 || !sightingEmployeesText.split(',').map(i => i.trim()).includes(userName)
    }
  },
};

export const selectActionItem = {
  name: requestAction.SELECT,
  label: 'Выбрать',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
};