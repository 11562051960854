/*
 *
 * Object functions
 *
 */

import {
  getTypeInfo,
  isArray,
  isObject,
  isString,
  isNull,
} from './typeUtils';

/**
 * Check exists value of key or not in target Object.
 * Example:
 *    const object = {a: {b: {c: 'initial' } } };
 *    isContainKeyInObject(object, ['a', 'b', 'c']) or isContainKeyInObject(object, 'a.b.c')
 *
 * @param {[Object]} object Object to set the nested key
 * @param {[Array]} key An array or string to describe the path(Ex: ['a', 'b', 'c'] or 'a.b.c')
 */

export const isContainKeyInObject = (object, key) => {
  let properties;

  if (isNull(object)) return false;
  if (!isObject(object)) throw new Error(`First argument must be "Object" type, but got "${getTypeInfo(object)}"`);

  if (isArray(key)) properties = key;
  else if (isString(key)) properties = key.split('.');
  else throw new Error(`Second argument must be "Array" or "String" type, but got ${getTypeInfo(key)}`);

  if (properties.length === 0 || properties[0] === '') throw new Error('Parameter in second argument must not be empty array or string');

  const $checkKeyInObject = (_object, _key, index) => {
    if (index === (_key.length - 1)) {
      if (_object[_key[index]]) return true;
      return false;
    }

    if (_key[index] in _object) return $checkKeyInObject(_object[_key[index]], _key, (index + 1));

    return false;
  };

  return $checkKeyInObject(object, properties, 0);
};

/**
 * Sets a value of nested key string descriptor inside a Object or changes value if it exists inside Object.
 * Does not change the passed object, but return new object with modified value
 * Example:
 *    const object = {a: {b: {c: 'initial' } } };
 *    setValueToObjectKey(object, ['a', 'b', 'c'], 'changed-value') or setValueToObjectKey(object, 'a.b.c')
 *
 * @param {[Object]} object Object to set the nested key
 * @param {[Array]} key An array or string to describe the path(Ex: ['a', 'b', 'c'] or 'a.b.c')
 * @param {[Object]} value Any value
 */

export const setValueToObjectKey = (object, key, value) => {
  const objectCopy = JSON.parse(JSON.stringify(object));
  let properties;

  if (!(isObject(object))) throw new Error(`First argument must be "Object" type, but got ${getTypeInfo(object)}`);

  if (isArray(key)) properties = key;
  else if (isString(key)) properties = key.split('.');
  else throw new Error(`Second argument must be "Array" or "String" type, but got ${getTypeInfo(key)}`);

  if (properties.length === 0 || properties[0] === '') throw new Error('Parameter in second argument must not be empty array or string');

  const $setValueToObject = (_object, _propertyPath, _value, index) => {
    const targetObject = _object;
    if (index === (_propertyPath.length - 1)) {
      targetObject[_propertyPath[index]] = _value;
      return targetObject;
    }

    if (_propertyPath[index] in _object) {
      targetObject[_propertyPath[index]] = $setValueToObject(targetObject[_propertyPath[index]], _propertyPath, _value, (index + 1));
      return targetObject;
    }

    targetObject[_propertyPath[index]] = {};
    targetObject[_propertyPath[index]] = $setValueToObject(targetObject[_propertyPath[index]], _propertyPath, _value, (index + 1));
    return targetObject;
  };

  return $setValueToObject(objectCopy, properties, value, 0);
};

export const getAllKeysAsArray = (object) => {
  const result = [];
  const getAllKeysOfTheObject = (obj) => {
    if (!isObject(obj) && !isArray(obj)) return null;
    if (isObject(obj)) {
      const keys = Object.keys(obj);
      keys.map((item) => { // eslint-disable-line
        if (item !== 'undefined') result.push(item);
      });
      for (let i = 0; i < keys.length; i += 1) {
        getAllKeysOfTheObject(obj[keys[i]]);
      }
    }
    if (isArray(obj) && (obj.length > 0)) {
      for (let i = 0; i < obj.length; i += 1) {
        getAllKeysOfTheObject(obj[i]);
      }
    }
    return null;
  };

  getAllKeysOfTheObject(object);
  return result;
};

// Test object that consists of 22 keys
// const testObject = {
//   a: [{ a: 5, b: { c: true } }],
//   b: [{ e: true }, null, { a: 5, b: [{ a: 2, b: 3 }] }],
//   c: 1,
//   d: { a: { u: 100 }, c: { d: 8, AA: [108, { _AA: 88, _b: { CCC: true } }] }, St: { StInner: 15 } },
// };

export const isTwoObjectEqualsByKeysAmount = (object1, object2) => {
  if (!(isObject(object1))) throw new Error(`First argument must be "Object" type, but got ${getTypeInfo(object1)}`);
  if (!(isObject(object2))) throw new Error(`Second argument must be "Object" type, but got ${getTypeInfo(object2)}`);

  const amountKeysForObject1 = getAllKeysAsArray(object1).length;
  const amountKeysForObject2 = getAllKeysAsArray(object2).length;
  return (amountKeysForObject1 === amountKeysForObject2);
};
