/*
 *
 * RestorePasswordForm sagas
 *
 */

import { take, put, select, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  startSubmit,
  stopSubmit,
  touch,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';

import * as userActionCreators from 'domain/User/actions';
import { CHANGE_PASSWORD_ERROR } from 'domain/User/constants';
import { SUBMIT } from './constants';
import { CHECK_PASSWORD_LINK } from 'pages/RestorePassword/constants';
import * as api from 'utils/api';

const RESTORE_PASSWORD_FORM_NAME = 'restorePasswordForm';

export default function* rootSaga() {
  yield takeLatest(SUBMIT, submitRestorePasswordFormSaga);
  yield takeLatest(CHECK_PASSWORD_LINK, checkPasswordLinkSaga);
}

function* submitRestorePasswordFormSaga() {
  yield put(touch(RESTORE_PASSWORD_FORM_NAME, 'newPassword', 'newPasswordConfirmation'));
  const syncErrors = yield select(getFormSyncErrors(RESTORE_PASSWORD_FORM_NAME, (state) => state.get('forms')));
  if (syncErrors.newPassword || syncErrors.newPasswordConfirmation) return;
  const formValues = yield select(getFormValues(RESTORE_PASSWORD_FORM_NAME, (state) => state.get('forms')));
  if (!formValues) return;
  const { newPassword, newPasswordConfirmation } = formValues && formValues.toJS();

  if (newPassword !== newPasswordConfirmation) return;
  yield put(startSubmit(RESTORE_PASSWORD_FORM_NAME));
  yield put(userActionCreators.restorePassword(newPassword));

}



function* checkPasswordLinkSaga({ payload: { login, link } }) {
  try {
    const response = yield call(api.forgotPassword, login);
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`; // eslint-disable-line dot-notation

  } catch (err) {
    if (err.response && err.response.status === 400 && err.response.data.error_description === "The user is blocked.") {
      yield put(stopSubmit(RESTORE_PASSWORD_FORM_NAME, { _error: "Пользователь заблокирован" }));
    }
  }
  try {
    const response = yield call(api.checkPasswordLink, login, link);
    if (response.data.length == 0) 
    yield put(stopSubmit(RESTORE_PASSWORD_FORM_NAME, { _error: "Время действия ссылки истекло" }));

  } catch (err) {
    console.log("err", err);
    throw err;
  }

}

