/*
 *
 * ServiceRequestsManagerDxGridContainer14419
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction } from 'domain/typeConstants/action';
import * as actionCreators from 'pages/Requests/ServiceRequestsManagerView/actions';

class ServiceRequestsManagerDxGridContainer14419 extends React.PureComponent {

    handleRowDoubleClick = (record) => this.props.executeViewAction(record.id);

    handleActionExecuting = (actionName, ids) => {
        switch (actionName) {
            case requestAction.VIEW:
                this.props.executeViewAction(ids[0]);
                break;
            case requestAction.INSTRUCTED:
                this.props.executeInstructedAction(ids[0]);
                break;

            default:
                throw new Error('Executed a unknown action.');
        }
    };

    render() {
        const { component, viewName } = this.props;
        return React.cloneElement(component, {
            viewName,
            onActionExecuting: this.handleActionExecuting,
            onRowDoubleClick: this.handleRowDoubleClick,
        });
    }
}

ServiceRequestsManagerDxGridContainer14419.propTypes = {
    component: PropTypes.node.isRequired,
    viewName: PropTypes.string,
    executeViewAction: PropTypes.func,
    executeInstructedAction: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(ServiceRequestsManagerDxGridContainer14419);
