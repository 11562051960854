/*
 *
 * Task sagas
 *
 */

import { call, put } from 'redux-saga/effects';
import * as api from 'utils/api';

import { changeEntitiesState } from '../actions';
import { TASKS } from '../constants';
import {
  closeTasks,
  tasksClosed,
  tasksClosingError,
  performeTasks,
  tasksPerformed,
  tasksPerformedError,
} from './actions';

export function* closeTasksSaga(ids) {
  yield put(closeTasks(ids));
  try {
    const response = yield call(api.post, 'tasks/closed', { ids });
    yield put(changeEntitiesState(TASKS, response.data));
    yield put(tasksClosed());
  } catch (err) {
    yield put(tasksClosingError(err));
  }
}

export function* performeTasksSaga(ids) {
  yield put(performeTasks(ids));
  try {
    const response = yield call(api.post, 'tasks/inProgress', { ids });
    yield put(changeEntitiesState(TASKS, response.data));
    yield put(tasksPerformed());
  } catch (err) {
    yield put(tasksPerformedError(err));
  }
}
