/*
 *
 * CheckpointView constants
 *
 */

export const EXECUTE_OPEN_BARRIER_ACTION = 'claris-vnext/custom/12924/RequestsPage/CheckpointView/EXECUTE_OPEN_BARRIER_ACTION';

export const OPEN_BARRIER = 'claris-vnext/custom/12924/RequestsPage/CheckpointView/OPEN_BARRIER';
export const OPEN_BARRIER_SUCCESS = 'claris-vnext/custom/12924/RequestsPage/CheckpointView/CLOSE_REQUESTS_SUCCESS';
export const OPEN_BARRIER_ERROR = 'claris-vnext/custom/12924/RequestsPage/CheckpointView/CLOSE_REQUESTS_ERROR';

