/*
 *
 * createAdvancedDataTable
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { downloadFile as downloadFileAction } from 'domain/Data/File/actions';
import Loader from 'components/CircularLoader';
import AdvancedDataTable from './AdvancedDataTable';

import * as actionCreators from './actions';
import {
  makeSelectData,
  makeSelectColumns,
  makeSelectColumnOrder,
  makeSelectColumnWidths,
  makeSelectColumnVisibilityNames,
  makeSelectExpandedRowIds,
  makeSelectLoaderState,
  makeSelectPageSize,
  makeSelectPageSizes,
  makeSelectPageNumber,
  makeSelectSelection,
  makeSelectMenuItem,
  makeSelectSorting,
  makeSelectFilters,
  makeSelectSearchQuery,
  makeSelectCurrentRecordId,
  makeSelectActionButtons,
  makeSelectActionMenuItems,
  makeSelectTotalCount,
  makeSelectGridFilters,
  makeSelectClearFilterDialog,
  makeSelectTabPosition,
  makeSelectFileExtension,
  makeSelectDownloadLink,
  makeSelectIsOpenDrawer,
  makeSelectDrawerRecord,
} from './selectors';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { loadFilterEntity } from './FilterListDialog/SelectTextFilter/actions';
import { makeSelectFilterEntities } from 'domain/Data/selectors';
import { makeSelectContainerWidth } from 'containers/NavigationDrawer/selectors';
import makeSelectUser from 'domain/User/selectors';
import { updateTerminalAdvancedInput } from 'domain/Data/actions';
import { loadUnreadNotificationsCount } from 'domain/Notifications/actions';
import { makeSelectViewMode } from 'domain/Screen/selectors';


function createAdvancedDataTable(dataTableName, entityName) {
  class AdvancedDataTableContainer extends React.PureComponent {

    componentDidMount() {
      const { loadData, settingsConfig, viewName, mode, parent, parentId, dialogName } = this.props
      if (settingsConfig.tableType === settings.VIRTUAL_TABLE) {
        loadData({
          startAt: 1,
          limit: settingsConfig[settings.LAZY_LOADING_BLOCK_SIZE].value,
          mode, parent, parentId, dialogName,
        });
      } else {
        loadData({
          startAt: 1, limit: [settingsConfig[settings.PAGE_SIZE].value], viewName,
          mode, parent, parentId, dialogName,
        });
      }
      this.loadFilterData()
    }

    loadFilterData = () => {
      const { columns, filterEntities, loadFilterEntity } = this.props
      columns.forEach(column => {
        if (column.entityName) {
          const columnEntityName = column.entityName
          if (filterEntities?.get(columnEntityName) === undefined) {
            loadFilterEntity(column.entityName)
          }
        }
      })
    }

    handleLoadUnreadNotificationsCount = () => this.props.loadUnreadNotificationsCount()

    handleRowDoubleClick = (record) => {
      const { onRowDoubleClick, parent, parentId } = this.props;
      if (onRowDoubleClick) onRowDoubleClick(record, { parent, parentId });
    }

    handleLoadData = (options) => {
      const { loadData } = this.props;
      if (loadData) loadData({ ...options });
    }

    handleSelectionChange = (selection) => this.props.changeSelection(selection);

    handleMenuItemChange = (record) => {
      const { changeMenuItem, changeCurrentRecord } = this.props;
      if (changeCurrentRecord) changeCurrentRecord(record);
      if (changeMenuItem) changeMenuItem([record.id])
    };

    handleColumnOrderChange = (columnOrder) => {
      const { onChangeSettingsConfig, changeColumnOrder, settingsConfig } = this.props;
      settingsConfig[settings.REORDERING].value = columnOrder;
      if (onChangeSettingsConfig) onChangeSettingsConfig(settingsConfig);
      if (changeColumnOrder) changeColumnOrder(columnOrder)
    }

    handleColumnWidthChange = (columnWidths) => {
      const { onChangeSettingsConfig, changeColumnWidths, settingsConfig } = this.props;
      settingsConfig[settings.RESIZING].value = columnWidths;
      if (onChangeSettingsConfig) onChangeSettingsConfig(settingsConfig);
      if (changeColumnWidths) changeColumnWidths(columnWidths)
    }

    handleColumnVisibleChange = (hiddenColumns) => {
      const { onChangeSettingsConfig, changeVisibleColumns, settingsConfig } = this.props;
      settingsConfig[settings.COLUMN_VISIBILITY].value = hiddenColumns;
      if (onChangeSettingsConfig) onChangeSettingsConfig(settingsConfig);
      if (changeVisibleColumns) changeVisibleColumns(hiddenColumns)
    }

    handleChangeExpandedRowIds = (expandedRowIds) => {
      const { changeExpandedRowIds, clearCollapseRowsData, data, changeCurrentRecord } = this.props
      if (clearCollapseRowsData && expandedRowIds.length > 0) clearCollapseRowsData()
      if (changeExpandedRowIds) changeExpandedRowIds(expandedRowIds)
      const record = data.find(column => column.id === expandedRowIds[0])
      if (changeCurrentRecord) changeCurrentRecord(record);
    }


    handleActionButtonClick = (actionName) => {
      const { onActionExecuting, selection, currentRecordId, parent, parentId } = this.props;
      if (onActionExecuting) {
        if (actionName === 'add') { onActionExecuting(actionName, [], { parent, parentId }) }
        else if (currentRecordId) {
          onActionExecuting(actionName, [currentRecordId], { parent, parentId })
        } else {
          const selectionIds = selection.map(s => s.id)
          onActionExecuting(actionName, selectionIds, { parent, parentId })
        }
      }
    }

    handleActionMenuItemClick = (actionName, record) => {
      const { onActionExecuting, parent, parentId } = this.props;
      if (onActionExecuting) onActionExecuting(actionName, [record.id], { parent, parentId });
    }

    render() {
      const {
        accessKey,
        selectable,
        tableFeatures,
        actionButtons,
        actionMenuItems,
        data,
        columns,
        columnOrder,
        columnWidths,
        expandedRowIds,
        loaderState,
        detailContainer,
        pageSize,
        pageNumber,
        selection,
        itemMenuRecord,
        sorting,
        totalCount,
        pageSizes,
        currentRecordId,
        gridFilters,
        savedFilters,
        localFilters,
        clearFilterDialog,
        filterDialog,
        loadData,
        clearSearchQuery,
        changeGridFilters,
        viewName,
        settingsConfig,
        checkTableFeature,
        onChangeSettingsConfig,
        onChangeResetSettings,
        clearEntity,
        columnVisibilityNames,
        filePreviewFieldName,
        downloadLink,
        downloadFile,
        getDownloadLink,
        clearDownloadLink,
        fileExtension,
        mode,
        parent,
        parentId,
        height,
        dialogName,
        tab,
        changeTab,
        setContainerWidth,
        containerWidth,
        user,
        changeCurrentRecord,
        openPreviewDrawer,
        isOpenDrawer,
        drawerRecord,
        updateTerminalAdvancedInput,
        viewMode,
      } = this.props;

      return (
        <div>
          <AdvancedDataTable
            accessKey={accessKey}
            viewName={viewName}
            dataTableName={dataTableName}
            selectable={selectable}
            tableFeatures={tableFeatures}
            columns={columns}
            columnOrder={columnOrder}
            columnWidths={columnWidths}
            columnVisibilityNames={columnVisibilityNames}
            expandedRowIds={expandedRowIds}
            loaderState={loaderState}
            detailContainer={detailContainer}
            data={data}
            actionButtons={actionButtons}
            actionMenuItems={actionMenuItems}
            selection={selection}
            itemMenuRecord={itemMenuRecord}
            sorting={sorting}
            pageSize={pageSize}
            pageNumber={pageNumber}
            pageSizes={pageSizes}
            totalCount={totalCount}
            currentRecordId={currentRecordId}
            gridFilters={gridFilters}
            savedFilters={savedFilters}
            localFilters={localFilters}
            clearFilterDialog={clearFilterDialog}
            filterDialog={filterDialog}
            filePreviewFieldName={filePreviewFieldName}
            downloadLink={downloadLink}
            onRowDoubleClick={this.handleRowDoubleClick}
            onLoadData={this.handleLoadData}
            onSelectionChange={this.handleSelectionChange}
            onMenuItemChange={this.handleMenuItemChange}
            onActionButtonClick={this.handleActionButtonClick}
            onActionMenuItemClick={this.handleActionMenuItemClick}
            onColumnOrderChange={this.handleColumnOrderChange}
            onColumnWidthChange={this.handleColumnWidthChange}
            onColumnVisibleChange={this.handleColumnVisibleChange}
            onExpandedRowIdsChange={this.handleChangeExpandedRowIds}
            getDownloadLink={getDownloadLink}
            clearDownloadLink={clearDownloadLink}
            fileExtension={fileExtension}
            downloadFile={downloadFile}
            loadData={loadData}
            clearSearchQuery={clearSearchQuery}
            changeGridFilters={changeGridFilters}
            settingsConfig={settingsConfig}
            checkTableFeature={checkTableFeature}
            onChangeSettingsConfig={onChangeSettingsConfig}
            onChangeResetSettings={onChangeResetSettings}
            clearEntity={clearEntity}
            mode={mode}
            parent={parent}
            parentId={parentId}
            height={height}
            dialogName={dialogName}
            tab={tab}
            containerWidth={containerWidth}
            changeTab={changeTab}
            setContainerWidth={setContainerWidth}
            user={user}
            entityName={entityName}
            changeCurrentRecord={changeCurrentRecord}
            openPreviewDrawer={openPreviewDrawer}
            isOpenDrawer={isOpenDrawer}
            drawerRecord={drawerRecord}
            updateTerminalAdvancedInput={updateTerminalAdvancedInput}
            loadUnreadNotificationsCount={this.handleLoadUnreadNotificationsCount}
            viewMode={viewMode}
          />

          <Loader state={loaderState} size={50} thickness={4} />
        </div>
      );
    }
  }

  AdvancedDataTableContainer.propTypes = {
    selectable: PropTypes.bool,
    actionButtons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
      })
    ),
    actionMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
      })
    ),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageSize: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    selection: PropTypes.arrayOf(PropTypes.object),
    itemMenuRecord: PropTypes.arrayOf(PropTypes.string),
    sorting: PropTypes.arrayOf(PropTypes.object),
    totalCount: PropTypes.number.isRequired,
    onRowDoubleClick: PropTypes.func,
    onActionExecuting: PropTypes.func,
    loadData: PropTypes.func.isRequired,
    changeSelection: PropTypes.func.isRequired,
    changeMenuItem: PropTypes.func.isRequired,
    clearSearchQuery: PropTypes.func.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    data: makeSelectData(dataTableName),
    columns: makeSelectColumns(dataTableName),
    columnOrder: makeSelectColumnOrder(dataTableName),
    columnWidths: makeSelectColumnWidths(dataTableName),
    columnVisibilityNames: makeSelectColumnVisibilityNames(dataTableName),
    expandedRowIds: makeSelectExpandedRowIds(dataTableName),
    loaderState: makeSelectLoaderState(dataTableName),
    pageSize: makeSelectPageSize(dataTableName),
    pageSizes: makeSelectPageSizes(dataTableName),
    pageNumber: makeSelectPageNumber(dataTableName),
    selection: makeSelectSelection(dataTableName),
    menuItem: makeSelectMenuItem(dataTableName),
    sorting: makeSelectSorting(dataTableName),
    filters: makeSelectFilters(dataTableName),
    gridFilters: makeSelectGridFilters(dataTableName),
    searchQuery: makeSelectSearchQuery(dataTableName),
    actionButtons: makeSelectActionButtons(dataTableName),
    actionMenuItems: makeSelectActionMenuItems(dataTableName),
    totalCount: makeSelectTotalCount(dataTableName),
    currentRecordId: makeSelectCurrentRecordId(dataTableName),
    filterDialog: makeSelectClearFilterDialog(dataTableName),
    filterEntities: makeSelectFilterEntities(),
    tab: makeSelectTabPosition(dataTableName),
    containerWidth: makeSelectContainerWidth(),
    fileExtension: makeSelectFileExtension(dataTableName),
    downloadLink: makeSelectDownloadLink(dataTableName),
    user: makeSelectUser(),
    isOpenDrawer: makeSelectIsOpenDrawer(dataTableName),
    drawerRecord: makeSelectDrawerRecord(dataTableName),
    viewMode: makeSelectViewMode(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      changeSelection: (selection) => dispatch(actionCreators.changeSelection(dataTableName, selection)),
      changeMenuItem: (recordId) => dispatch(actionCreators.changeMenuItem(dataTableName, recordId)),
      changeColumnOrder: (columnOrder) => dispatch(actionCreators.changeColumnOrder(dataTableName, columnOrder)),
      changeColumnWidths: (columnWidths) => dispatch(actionCreators.changeColumnWidths(dataTableName, columnWidths)),
      changeVisibleColumns: (hiddenColumns) => dispatch(actionCreators.changeVisibleColumns(dataTableName, hiddenColumns)),
      changeExpandedRowIds: (expandedRowIds) => dispatch(actionCreators.changeExpandedRowIds(dataTableName, expandedRowIds)),
      clearCollapseRowsData: () => dispatch(actionCreators.clearCollapseRowsData(dataTableName)),
      changeCurrentRecord: (record) => dispatch(actionCreators.changeCurrentRecord(dataTableName, record)),
      loadData: (options) => dispatch(actionCreators.loadData(dataTableName, options)),
      clearSearchQuery: (options) => dispatch(actionCreators.clearSearchQuery(dataTableName, options)),
      changeGridFilters: (options) => dispatch(actionCreators.changeGridFilters(dataTableName, options)),
      clearFilterDialog: (isClear) => dispatch(actionCreators.clearFilterDialog(dataTableName, isClear)),
      loadFilterEntity: (params) => dispatch(loadFilterEntity(params)),
      getDownloadLink: (fileId) => dispatch(actionCreators.getDownloadLink(dataTableName, fileId)),
      downloadFile: (fileId) => dispatch(downloadFileAction(fileId)),
      clearDownloadLink: () => dispatch(actionCreators.clearDownloadLink(dataTableName)),
      changeTab: (id) => dispatch(actionCreators.changeTab(dataTableName, id)),
      setContainerWidth: (width) => dispatch(actionCreators.setContainerWidth(width)),
      openPreviewDrawer: (mode, record) => dispatch(actionCreators.openPreviewDrawer(dataTableName, mode, record)),
      updateTerminalAdvancedInput: (options) => dispatch(updateTerminalAdvancedInput(options)),
      loadUnreadNotificationsCount: () => dispatch(loadUnreadNotificationsCount()),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(AdvancedDataTableContainer);
}

export default createAdvancedDataTable;
