/*
 *
 * RequestsPageContainer14140
 *
 */

import React from 'react';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import RequestsPage14140 from './RequestsPage14140';
import saga from './sagas';

function RequestsPageContainer14140(props) {
  return (
    <RequestsPage14140 {...props} />
  );
}

const withSaga = injectSaga({ key: 'receptionPage14140', saga });

export default compose(withSaga)(RequestsPageContainer14140);
