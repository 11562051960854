/*
 *
 * AlertDialogReducer reducer
 *
 */

import { Map } from 'immutable';
import { OPEN_ALERT_DIALOG, CLOSE_ALERT_DIALOG, CONFIRM_ALERT_DIALOG } from './constants';

const initialState = new Map({
  open: false,
  title: null,
  content: null,
  type: null,
  result: null,
});

function alertDialogReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_ALERT_DIALOG:
      return state
        .set('open', true)
        .set('title', payload.title)
        .set('content', payload.content)
        .set('type', payload.type);

    case CLOSE_ALERT_DIALOG:
      return state
        .set('open', false)
        .set('title', null)
        .set('content', null)
        .set('type', null)
        .set('result', false);

    case CONFIRM_ALERT_DIALOG:
      return state
        .set('result', true);

    default:
      return state;
  }
}


export default alertDialogReducer;
