/*
 *
 * TenantView actions
 *
 */

import {
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_CLOSED_BY_TENANT_ACTION,
  EXECUTE_AGREED_ESTIMATION_COST_ACTION,
} from './constants';

export function executeAddAction() {
  return {
    type: EXECUTE_ADD_ACTION,
  };
}

export function executeEditAction(id) {
  return {
    type: EXECUTE_EDIT_ACTION,
    payload: { id },
  };
}

export function executeViewAction(id) {
  return {
    type: EXECUTE_VIEW_ACTION,
    payload: { id },
  };
}

export function executeCopyAction(id) {
  return {
    type: EXECUTE_COPY_ACTION,
    payload: { id },
  };
}

export function executeCloseAction(ids) {
  return {
    type: EXECUTE_CLOSE_ACTION,
    payload: { ids },
  };
}

export function executeAcceptAction(ids) {
  return {
    type: EXECUTE_ACCEPT_ACTION,
    payload: { ids },
  };
}

export function executeClosedByTenantAction(id) {
  return {
    type: EXECUTE_CLOSED_BY_TENANT_ACTION,
    payload: { id },
  };
}

export function executeAgreedEstimationCostAction(id) {
  return {
    type: EXECUTE_AGREED_ESTIMATION_COST_ACTION,
    payload: { id },
  };
}
