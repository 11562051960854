/*
 * Functions for formatting numbers
 */

export const formatNumberWithSpaces = (num) => (num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '));

export const formatText = (string, options) => {
    if (string.length > options.limit) {
        return string.substring(0, options.limit);
    }
    return string;
};

export const sliceText = (text, limit) => {
    let suff = '';
    if (text.length > limit) { suff = '...' };
    return text.slice(0, limit) + suff;
};

export const findAndReplaceLink = (text) => {
    const pattern = /([-a-zA-Z0-9@:%_\+.~#?&\/\/=]{2,256}\.[a-zA-Zа-яА-ЯёЁ0-9]{2,4}\b(\/?[-a-zA-Z0-9а-яА-ЯёЁ@:%_\+.~#?&\/\/=]*)?)/gi;
    return text.replace(pattern, '<a href="$1" target="_blank">$1</a>');
}

export const unique = (arr) => {
    let result = [];
    for (let str of arr) {
        if (!result.includes(str)) {
            result.push(str);
        }
    }
    return result;
}

const KEY = 'bc-secret-key'

export function encryptStringWithXORtoHex(input, key = KEY) {
    let hexString = '';
    while (key.length < input.length) {
        key += key;
    }
    for (let i = 0; i < input.length; i++) {
        const valueCode = input[i].charCodeAt(0);
        const keyCode = key[i].charCodeAt(0);
        const xorValueCode = valueCode ^ keyCode;
        let xorValueAsHexString = xorValueCode.toString("16");
        if (xorValueAsHexString.length === 1) {
            xorValueAsHexString = "00" + xorValueAsHexString;
        }
        else if (xorValueAsHexString.length === 2) {
            xorValueAsHexString = "0" + xorValueAsHexString;
        }
        hexString += xorValueAsHexString;
    }
    let outputString = ''
    for (let i = 0; i < hexString.length; i += 3) {
        const hexValueString = hexString.substring(i, i + 3);
        let value = parseInt(hexValueString, 16);
        outputString += String.fromCharCode(value);
    }
    return outputString;
}

export function decryptStringWithXORFromHex(input, key = KEY) {
    let c = ''
    while (key.length < input.length / 3) {
        key += key;
    }
    for (let i = 0; i < input.length; i += 3) {
        const hexValueString = input.substring(i, i + 3);
        let value1 = parseInt(hexValueString, 16);
        let value2 = key.charAt(i / 3).charCodeAt(0);
        let xorValue = value1 ^ value2;
        c += String.fromCharCode(xorValue);
    }
    return c;
};
