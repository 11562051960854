/*
 * ApplicantView sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openViewDialog,
  openCopyDialog,
} from 'containers/AdvancedFormDialog/actions';

import { closeRequestsSaga } from 'domain/Data/Request/sagas';

import {
  APPLICANT_REQUESTS_TABLE_GRID,
  APPLICANT_REQUESTS_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_CLOSE_ACTION,
} from './constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const isApplicantFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  action.meta.dialog === APPLICANT_REQUESTS_FORM_DIALOG;

export default function createApplicantViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_COPY_ACTION, (config.executeCopyActionSaga || executeCopyActionSaga));
    yield takeLatest(EXECUTE_CLOSE_ACTION, (config.executeCloseActionSaga || executeCloseActionSaga));

    yield takeLatest(isApplicantFormSaved, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(APPLICANT_REQUESTS_TABLE_GRID));
}

export function* executeAddActionSaga() {
  yield put(openAddDialog(APPLICANT_REQUESTS_FORM_DIALOG, {}));
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(APPLICANT_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeCopyActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openCopyDialog(APPLICANT_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeCloseActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(closeRequestsSaga, ids);
  yield put(loadData(APPLICANT_REQUESTS_TABLE_GRID));
}
