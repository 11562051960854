/**
 *
 * ActionButton Items
 *
 */

import React from 'react';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Clear from '@material-ui/icons/Clear';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import FileCopy from '@material-ui/icons/FileCopy';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import {requestAction, documentAction} from 'domain/typeConstants/action';
import {documentStatus} from 'domain/typeConstants/status';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants'

export const addActionButton = {
    name: requestAction.ADD,
    label: 'Добавить',
    icon: 'add_circle_outline',
    svgIcon: <AddCircleOutline/>,
};

export const editActionButton = {
    name: requestAction.EDIT,
    label: 'Изменить',
    icon: 'create',
    svgIcon: <Create/>,
    disabled: (selection, entities, currentRecord) => {
        if (currentRecord) {
            if (!currentRecord.state) return false
            return currentRecord.state.name === documentStatus.ARCHIVED
        } else if (selection.length === 1) {
            const state = entities[0].state?.name;
            if (!state) return false;
            return state === documentStatus.ARCHIVED;
        }
        return true;
    },
};

export const viewActionButton = {
    name: requestAction.VIEW,
    label: 'Просмотреть',
    icon: 'find_in_page',
    svgIcon: <FindInPage/>,
    disabled: (selection, entities, currentRecord) => {
        if (currentRecord) {
            if (!currentRecord.state) return false
            return !(currentRecord.state.name === documentStatus.ARCHIVED)
        } else if (selection.length === 1) {
            const state = entities[0].state?.name;
            if (!state) return false;
            return !(state === documentStatus.ARCHIVED);
        }
        return true;
    },
};

export const agreeActionButton = {
    name: documentAction.AGREE,
    label: 'Согласовать',
    icon: 'assignment_turned_in',
    svgIcon: <AssignmentTurnedIn/>,
    disabled: (selection, entities, currentRecord) => isSightingEmployee(selection, entities, currentRecord),
};

export const disagreeActionButton = {
    name: documentAction.DISAGREE,
    label: 'Отклонить',
    icon: 'clear',
    svgIcon: <Clear/>,
    disabled: (selection, entities, currentRecord) => isSightingEmployee(selection, entities, currentRecord),
};

const isSightingEmployee = (selection, entities, currentRecord) => {
    const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
    let isDisabled = true;
    if (currentRecord) {
        const sightingEmployeesText = currentRecord?.agreementStep?.sightingEmployeesText || ""
        isDisabled = !sightingEmployeesText.split(',').map(i => i.trim()).includes(userName)
    } else {
        entities.forEach((entity) => {
            if (isDisabled) {
                const sightingEmployeesText = entity && entity.agreementStep && entity.agreementStep.sightingEmployeesText
                    ? entity.agreementStep.sightingEmployeesText : ""
                isDisabled = !sightingEmployeesText.split(',').map(i => i.trim()).includes(userName)
            }
        })
    }
    return isDisabled
}

export const addActionButtonTable = {
    name: requestAction.ADD,
    label: 'Добавить',
    icon: 'add_circle_outline',
    svgIcon: <AddCircleOutline/>,
    disabled: false,
};

export const editActionButtonTable = {
    name: requestAction.EDIT_BY_STATUS,
    label: 'Изменить',
    icon: 'create',
    svgIcon: <Create/>,
    disabled: true,
}

export const copyActionButtonTable = {
    name: requestAction.COPY_BY_STATUS,
    label: 'Копия',
    icon: 'content_copy',
    svgIcon: <FileCopy/>,
    disabled: true,
}

export const cargoReceivedActionButton = {
    name: requestAction.CARGO_RECEIVED,
    label: 'Груз получен',
    icon: 'check',
    svgIcon: <CheckOutlinedIcon/>,
    disabled: (selection, entities, currentRecord) => !(currentRecord || selection.length === 1),
};

export const originalsDeliveredActionButton = {
    name: requestAction.ORIGINALS_DELIVERED,
    label: 'Оригиналы доставлены',
    icon: 'description',
    svgIcon: <DescriptionOutlinedIcon/>,
    disabled: (selection, entities, currentRecord) => !(currentRecord || selection.length === 1),
}