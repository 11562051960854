/**
 *
 * UsersView constants
 *
 */

export const USERS_DATA_TABLE = 'UsersDataTable';
export const USERS_DX_GRID = 'UsersDxGrid';
export const USERS_FORM = 'UsersForm';
export const USERS_FORM_DIALOG = 'UsersFormDialog';

/*actions*/
export const EXECUTE_ADD_ACTION = 'claris-vnext/UsersView/EXECUTE_ADD_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/UsersView/EXECUTE_VIEW_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/UsersView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/UsersView/EXECUTE_EDIT_ACTION';

export const EXECUTE_BLOCK_ACTION = 'claris-vnext/UsersView/EXECUTE_BLOCK_ACTION';
export const BLOCK_USER = 'claris-vnext/UsersView/BLOCK_USER';
export const USER_BLOCKED = 'claris-vnext/UsersView/USER_BLOCKED';
export const USER_BLOCKED_ERROR = 'claris-vnext/UsersView/USER_BLOCKED_ERROR';

/*roles*/
export const APPLICANT = '3077937690000'
