/**
 *
 * UnbindingAccessCardDialog reducer
 *
 */

import { Map } from 'immutable';

import {
  CHANGE_LOADING_STATE,
  CHANGE_SNACKBAR_STATE,
  SUBMIT_UNBINDING_ACCESS_CARD_DIALOG,
} from './constants';

const initialState = new Map({
  loadingState: false,
  snackbar: new Map({
    variant: 'success',
  }),
});

function unbindingAccessCardDialogReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SUBMIT_UNBINDING_ACCESS_CARD_DIALOG:
      return state
        .set('loadingState', false);

    case CHANGE_LOADING_STATE:
      return state.set('loadingState', payload.loadingState);

    case CHANGE_SNACKBAR_STATE:
      return state
        .setIn(['snackbar', 'state'], payload.state)
        .setIn(['snackbar', 'message'], payload.message)
        .setIn(['snackbar', 'variant'], payload.variant);

    default:
      return state;
  }
}

export default unbindingAccessCardDialogReducer;
