/**
 *
 *  CheckpointOperatorDxGrid12970
 *
 */

import { CheckpointOperatorDxGrid } from 'pages/Requests/CheckpointOperator';
import {
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_COMCITY_CHECKPOINT_OPERATOR,
} from 'domain/Data/Filters/constants';

class CheckpointOperatorDxGrid12970 extends CheckpointOperatorDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_COMCITY_CHECKPOINT_OPERATOR];
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    return filters;
  }
}

export default CheckpointOperatorDxGrid12970;
