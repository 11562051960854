/**
 *
 * TenantView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function TenantView({ dataTable, formDialog, viewName, viewStyles, agreedEstimationCostFormDialog }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { formDialog }
      { agreedEstimationCostFormDialog }
    </div>
  );
}

TenantView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
};

TenantView.defaultProps = {
  viewStyles: {},
};

export default TenantView;
