/**
 *
 * ServiceRequestsManagerView sagas
 *
 */

import { call, select, put, takeLatest } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { CLOSED } from 'domain/Data/RequestStatus/constants';
import { SERVICE, CARRYING_OUT_WORKS } from 'domain/Data/RequestType/constants';
import { makeSelectEntity } from 'domain/Data/selectors';
import {
  acceptRequestsSaga, sendToAgreementByTenantSaga,
  instructedRequestsSaga, closeRequestsSaga,
} from 'domain/Data/Request/sagas';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openEditDialog,
  openCopyDialog,
  openViewDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
  SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID,
  SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG,
  SERVICE_REQUESTS_MANAGER_ACCEPT_FORM_DIALOG,
  SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG,
  SERVICE_REQUESTS_MANAGER_COMPLETE_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_REJECT_ACTION,
  EXECUTE_COMPLETE_ACTION,
  EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION,
  EXECUTE_INSTRUCTED_ACTION,
} from './constants';

import { RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG } from 'custom/14326/pages/Requests/ReceptionView/constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  (action.meta.dialog === SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG ||
    action.meta.dialog === SERVICE_REQUESTS_MANAGER_ACCEPT_FORM_DIALOG ||
    action.meta.dialog === SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG ||
    action.meta.dialog === SERVICE_REQUESTS_MANAGER_COMPLETE_FORM_DIALOG);

export default function createServiceRequestsManagerViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_COPY_ACTION, (config.executeCopyActionSaga || executeCopyActionSaga));
    yield takeLatest(EXECUTE_ACCEPT_ACTION, (config.executeAcceptActionSaga || executeAcceptActionSaga));
    yield takeLatest(EXECUTE_CLOSE_ACTION, (config.executeCloseActionSaga || executeCloseActionSaga));
    yield takeLatest(EXECUTE_REJECT_ACTION, (config.executeRejectActionSaga || executeRejectActionSaga));
    yield takeLatest(EXECUTE_COMPLETE_ACTION, (config.executeCompleteActionSaga || executeCompleteActionSaga));
    yield takeLatest(EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION, (config.executeSendToAgreementByTenantActionSaga || executeSendToAgreementByTenantActionSaga));
    yield takeLatest(EXECUTE_INSTRUCTED_ACTION, (config.executeInstructedActionSaga || executeInstructedActionSaga));
    yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
}

function* executeAddActionSaga() {
  yield put(openAddDialog(SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  const requests = yield select(makeSelectEntity('requests', id));
  if (requests.status === CLOSED) {
    yield put(openViewDialog(SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG, { id }));
  } else if (id) {
    const status = requests.status;
    const parentId = null;
    yield put(openEditDialog(SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG, { id, parentId, status }));
  }
}

function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeCopyActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openCopyDialog(SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeAcceptActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    if (request.type === SERVICE) {
      yield put(openEditDialog(SERVICE_REQUESTS_MANAGER_ACCEPT_FORM_DIALOG, { id: ids[0] }));
    } else {
      yield checkUserForBlockingSaga()
      yield call(acceptRequestsSaga, ids);
    }
  }
}

function* executeRejectActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    yield put(openEditDialog(SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG, { id: ids[0] }));
  }
}

function* executeCompleteActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(SERVICE_REQUESTS_MANAGER_COMPLETE_FORM_DIALOG, { id }));
  }
}

export function* executeCloseActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(closeRequestsSaga, ids);
  yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
}

function* executeSendToAgreementByTenantActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    if (request.type === SERVICE || (request.type === CARRYING_OUT_WORKS && !request.memorandumScan)) {
      yield put(openEditDialog(RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG, { id: ids[0] }));
    } else {
      yield checkUserForBlockingSaga()
      yield call(sendToAgreementByTenantSaga, ids);
      yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
    }
  }
}

function* executeInstructedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(instructedRequestsSaga, ids);
  yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
}
