/**
 *
 * AdminSkudDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { ACCESS_CARD_REQUESTS } from 'domain/Data/Filters/constants';
import {
  acceptActionButton,
  addActionButton,
  copyActionButton,
  editActionButton,
  rejectActionButton,
} from '../common/actionButton';
import {
  acceptActionItem,
  copyActionItem,
  editActionItem,
  rejectActionItem,
} from '../common/actionMenu';
import { columnStatusLabels } from '../common/columnStatusLabel';

import { ADMIN_SKUD_REQUESTS_TABLE_GRID } from './constants';


class AdminSkudDxGrid extends React.PureComponent {

  getFilters() {
    const filters = [ACCESS_CARD_REQUESTS];
    return filters;
  }

  getColumns() {

    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО',
        width: requestColumnSizes.visitorFullName,
        styles,
      },
      {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        entityName: 'requestStatuses',
        width: requestColumnSizes.statusName,
      },
      {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        entityName: 'requestTypes',
        width: requestColumnSizes.typeShortName,
        styles,
      },
      {
        path: ['company', 'name'],
        name: 'company_name',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.company_name,
        styles,
      },
      {
        path: 'department',
        name: 'department',
        title: 'Здание',
        getCellValue: (row) => row.department ? row.department.name : undefined,
        width: 150,
      },
      {
        path: 'documentDetails',
        name: 'documentDetails',
        title: 'Серия и №',
        width: requestColumnSizes.documentDetail,
        styles,
      },
      {
        path: 'companyName',
        name: 'companyName',
        title: 'Подразделение',
        width: requestColumnSizes.companyName,
        styles,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
        styles,
      },
      {
        path: ['file', 'name'],
        name: 'fileName',
        getCellValue: (row) => (row.file ? row.file.id : undefined),
        title: 'Файл',
        type: columnType.FILE,
        width: requestColumnSizes.fileName,
        styles,
        filter: false,
      },
      {
        path: 'issueReason',
        name: 'issueReason',
        title: 'Причина выдачи',
        getCellValue: (row) => row.issueReason ? row.issueReason.name : undefined,
        width: requestColumnSizes.issueReason,
        styles,
      },

      {
        path: 'managementCompanyComment',
        name: 'managementCompanyComment',
        title: 'Комментарий УК',
        width: requestColumnSizes.note,
        styles,
      },
      {
        path: ['author', 'name'],
        name: 'authorName',
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: requestColumnSizes.authorName,
        styles,
      },
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Создана',
        type: columnType.DATETIME,
        width: columnSize.DATE,
        styles,
      },
      {
        path: 'requestChangeDate',
        name: 'requestChangeDate',
        title: 'Изменено',
        type: columnType.DATETIME,
        width: columnSize.DATE,
        styles,
      }
    ];
  }

  getColumnOrder() {
    return [
      'number', 'visitorFullName', 'visitDate', 'statusName', 'company_name', 'department', 'companyName', 'fileName',
      'issueReason', 'note', 'managementCompanyComment', 'authorName', 'actualCreationDate', 'requestChangeDate'
    ]
  }

  getActionButtons() {
    return [
      addActionButton, editActionButton, copyActionButton, acceptActionButton, rejectActionButton];
  }

  getActionMenuItems() {
    return [
      editActionItem, copyActionItem, acceptActionItem, rejectActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_DRAWER, value: true },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (

      <AdvancedDataTable
        name={ADMIN_SKUD_REQUESTS_TABLE_GRID}
        entityName="requests"
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

AdminSkudDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default AdminSkudDxGrid;
