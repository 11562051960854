/*
 *
 * ChangePasswordPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { replace } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectForcePasswordChange } from 'domain/User/selectors';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import saga from './sagas';

import ChangePasswordPage from './ChangePasswordPage';

export class ChangePasswordPageContainer extends React.Component {
  componentWillMount = () => this.check();

  componentWillReceiveProps = () => this.check();

  check() {
    const { isForcePasswordChange, redirectToRoot } = this.props;
    if (!isForcePasswordChange) {
      redirectToRoot('/');
    }
  }

  render() {
    const { location } = this.props;
    return (
      <ChangePasswordPage
        nextPathAfterLogin={location.search}
      />
    );
  }
}

ChangePasswordPageContainer.propTypes = {
  isForcePasswordChange: PropTypes.bool,
  location: PropTypes.object.isRequired,
  redirectToRoot: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isForcePasswordChange: makeSelectForcePasswordChange(),
});

function mapDispatchToProps(dispatch) {
  return {
    redirectToRoot: () => dispatch(replace('/')),
  };
}

const reducer = (state = {}) => state;

const withReducer = injectReducer({ key: 'changePasswordFormPage', reducer });
const withSaga = injectSaga({ key: 'changePasswordFormPage', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ChangePasswordPageContainer);

