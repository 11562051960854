/**
 *
 * DetailTableField constants
 *
 */


export const LOAD_OPTIONS = 'claris-vnext/AdvancedForm/DetailTableField/LOAD_OPTIONS';


