/**
 *
 * RestrictedAdministratorBC Service Requests DxGrid
 *
 */

import { ServiceRequestsManagerDxGrid } from '../ServiceRequestsManagerView';

class RestrictedAdministratorBCServiceRequestsDxGrid extends ServiceRequestsManagerDxGrid {
  getFilters() {
    const { viewName } = this.props; // eslint-disable-line
    const filters = ['ServiceRequests', 'RequestsForUserDepartment'];
    if (viewName === 'active') filters.push('ActiveRequests');
    return filters;
  }
}

export default RestrictedAdministratorBCServiceRequestsDxGrid;
