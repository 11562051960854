/**
 *
 * DocumentsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withPrivateRoot from 'containers/App/withPrivateRoot';
import { role } from 'domain/typeConstants/roles';
import { getSightingView, getSupplyRequestView, getSupplyPositionView } from './getRoleView';

const styleSheet = (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
    padding: theme.spacing.unit * 2,
    width: '100%',
    height: '100%',
  },
});

export class DocumentsPage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getViewStyles() {
    const { breakpoint } = this.props;
    const styles = {
      root: {
        height: 'inherit',
      },
      dataTable: {
        position: 'relative',
      },
    };

    if (breakpoint.down('md')) {
      styles.dataTable.height = '100%';
    }

    return styles;
  }

  renderView() {
    const { userRoles, viewName } = this.props;
    const viewStyles = this.getViewStyles();

    if (userRoles.includes(role.SIGHTING)) {
      return getSightingView({ viewStyles, ...this.props });
    }
    else if (userRoles.includes(role.SUPPLY_APPLICANT)) {
      if (viewName === 'allSupplyPositions') {
        return getSupplyPositionView({ viewStyles, ...this.props });
      } else return getSupplyRequestView({ viewStyles, ...this.props });
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>{this.renderView()}</div>
    );
  }
}

DocumentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withPrivateRoot(
  withStyles(styleSheet)(DocumentsPage)
);
