/**
 *
 * getViewComponent function
 *
 */

import React from 'react';
import { document } from 'domain/typeConstants/document';
import { PDFViewer, ImageViewer, PlainTextViewer } from '../DocumentViewer';

const CONTAINER_WIDTH = 700

const styles = {
  height: '100%',
  margin: '10px',
  width: `${CONTAINER_WIDTH}px`,
};

export const getViewComponent = ({ type, data }) => {
  if (data.value) {
    switch (type) {

      case document.PDF:
        return (<PDFViewer data={data} styles={styles} />);

      case document.PNG:
      case document.JPEG:
      case document.JPG:
      case document.BMP:
      case document.GIF:
        return (<ImageViewer data={data} styles={styles} />);

      case document.TXT:
        return (<PlainTextViewer data={data} styles={styles} />);

      default:
        return (<div></div>);
    }
  }
  return (<div></div>);
};
