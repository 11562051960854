/*
 *
 * ReceptionDxGrid14691
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';
import {
    getReceptionAllAndTodayTab,
    receptionAllAndTodayTabFilters,
    receptionAllAndTodayTabOrders,
    WHO_MEETS,
    whoMeetsColumn,
    whoMeetsColumnFilter,
} from '../common/dxGridComponents/columns';
import { REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE, ON_CREATE_REQUESTS, } from "domain/Data/Filters/constants";
import { requestAction } from "domain/typeConstants/action";
import { acceptActionButton, copyActionButton } from "../common/actionButton";
import { acceptActionItem, copyActionItem } from "../common/actionMenuItem";

export class ReceptionDxGrid14691 extends ReceptionDxGrid {

    getFilters() {
        const filters = super.getFilters();
        const { viewName } = this.props;

        if (viewName === 'all' || viewName === 'today') filters.push(REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        return filters;
    }

    getColumns() {
        const { viewName } = this.props;
        const columns = super.getColumns();

        if (viewName === 'all' || viewName === 'today') {
            const blackListStyle = {
                style: { color: '#d50000' },
                condition: (value) => value.isBlackList,
            };
            const styles = [blackListStyle];

            return getReceptionAllAndTodayTab(styles);
        }
        columns.push(whoMeetsColumn);
        return columns;
    }

    getGridFilters() {
        const { viewName } = this.props;
        const gridFilters = super.getGridFilters();

        if (viewName === 'all' || viewName === 'today') {
            return receptionAllAndTodayTabFilters;
        }

        gridFilters.push(whoMeetsColumnFilter);
        return gridFilters;
    }

    getColumnOrder() {
        const { viewName } = this.props;
        const columnOrder = super.getColumnOrder();

        if (viewName === 'all' || viewName === 'today') {
            return receptionAllAndTodayTabOrders;
        }

        const noteIndex = columnOrder.findIndex((name) => name === 'note');
        columnOrder.splice(noteIndex, 0, WHO_MEETS);
        return columnOrder;
    }

    getActionButtons() {
        const actionButtons = super.getActionButtons();
        const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
        actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);

        const copyActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.COPY);
        actionButtons.splice(copyActionButtonIndex, 1, copyActionButton);

        return actionButtons;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();
        const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
        actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);

        const copyMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.COPY);
        actionMenuItems.splice(copyMenuItemIndex, 1, copyActionItem);

        return actionMenuItems;
    }

}
