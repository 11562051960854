/**
 *
 * ColumnStatusLabels
 *
 */

import { requestStatus } from 'domain/typeConstants/status';

export const statusColorLabels = {
  [requestStatus.CREATED]: {
    // backgroundColor: '#bbdefb',
  },
  [requestStatus.CLOSED]: {
    backgroundColor: '#E0E0E0',
  },
  [requestStatus.COMPLETED]: {
    backgroundColor: '#9CEEC0',
  },
  [requestStatus.REJECTED]: {
    backgroundColor: '#FBBBC2',
  },
  [requestStatus.ACCEPTED]: {
    backgroundColor: '#E6EE9C',
    color: '#424242',
  },
  [requestStatus.RETURNED]: {
    backgroundColor: '#CCB5AC',
  },
  [requestStatus.ON_TERRITORY]: {
    backgroundColor: '#FFE0B2',
    color: '#424242',
  },
  [requestStatus.REPEATED]: {
    backgroundColor: '#E1BEE7',
  },
  [requestStatus.IN_PROGRESS]: {
    backgroundColor: '#D3FFBF',
  },
  [requestStatus.ON_AGREEMENT]: {
    backgroundColor: '#81D4FA',
  },
  [requestStatus.AGREED]: {
    backgroundColor: '#E6EE9C',
  },
  [requestStatus.ON_AGREEMENT_BY_TENANT]: {
    backgroundColor: '#ddb78a',
  },
};
