/*
 *
 * RequestsPage15755
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import { TenantForm15755, TenantDxGrid15755, TenantFormDialog15755 } from './TenantView';
import {
  CheckpointDxGrid15755,
  CheckpointMobileGrid15755,
  CheckpointForm15755,
} from './CheckpointView';
import {
  ReceptionDxGrid15755,
  ReceptionForm15755,
  ReceptionFormDialog15755,
  ReceptionMobileGrid15755,
} from './ReceptionView';

import {
  AdministratorBCRequestsDxGrid15755,
  AdministratorForm15755,
  AdministratorFormDialog15755,
  AdministratorBCServiceRequestsForm15755,
  AdministratorBCServiceRequestsDxGrid15755,
} from './AdministratorBCView';
import { getDetailContainer } from './common/detailContainers';


function RequestsPage15755(props) {
  return (
    <RequestsPage
      administratorBCDxGrid={<AdministratorBCRequestsDxGrid15755 />}
      administratorBCForm={<AdministratorForm15755 />}
      administratorBCFormDialog={
        <AdministratorFormDialog15755
          form={<AdministratorForm15755 />}
          detailContainer={getDetailContainer()}
        />
      }

      administratorBCServiceDxGrid={<AdministratorBCServiceRequestsDxGrid15755 />}
      administratorBCServiceForm={<AdministratorBCServiceRequestsForm15755 />}
      

      tenantForm={<TenantForm15755 />}
      tenantDxGrid={<TenantDxGrid15755 />}
      tenantFormDialog={
        <TenantFormDialog15755
          form={<TenantForm15755 />}
          detailContainer={getDetailContainer()}
        />
      }
      receptionDxGrid={<ReceptionDxGrid15755 userId={props.userId} />}
      receptionMobileGrid={<ReceptionMobileGrid15755 />}
      receptionForm={<ReceptionForm15755 />}
      receptionFormDialog={
        <ReceptionFormDialog15755
          form={<ReceptionForm15755 />}
          detailContainer={getDetailContainer()}
        />
      }

      checkpointDxGrid={<CheckpointDxGrid15755 />}
      checkpointMobileGrid={<CheckpointMobileGrid15755 />}
      checkpointForm={<CheckpointForm15755 />}

      {...props}
    />
  );
}

export default RequestsPage15755;
