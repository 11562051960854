/**
 *
 * Requests action menu items 14326(BC Khimki)
 *
 */

import React from 'react';
import { requestAction } from 'domain/typeConstants/action';

import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Email from '@material-ui/icons/Email';
import CallReceived from '@material-ui/icons/CallReceived';
import CallMade from '@material-ui/icons/CallMade';
import Done from "@material-ui/icons/Done";
import Block from '@material-ui/icons/Block';

import { requestStatus } from 'domain/typeConstants/status';
import { requestType } from '../../../../../domain/typeConstants/type';

import { role as roleType } from 'domain/typeConstants/roles';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';


function isReception() {
  const currentRoles = getValueFromLocalStorage(GLOBAL_USER_KEY).roles;
  const checkRoleReception = currentRoles.find((role) => (role === roleType.RECEPTION));
  return checkRoleReception ? true : false;
}

export const acceptActionItem = {
  name: requestAction.ACCEPT,
  label: 'Принять/Отправить исполнителю',
  icon: 'done',
  svgIcon: <Done />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.ACCEPTED, requestStatus.CLOSED, requestStatus.COMPLETED,
      requestStatus.IN_PROGRESS, requestStatus.AGREED].includes(currentRecord.status.name) ||
        (currentRecord.status.name === requestStatus.REJECTED &&
          currentRecord.agreedEmployee?.id !== getValueFromLocalStorage(GLOBAL_USER_KEY).id)
    }

    // if (itemMenuRecord.length !== 0) {
    //   if (!entities[0].status) return false;
    //   return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED ||
    //     entities[0].status.name === requestStatus.ACCEPTED || entities[0].status.name === requestStatus.IN_PROGRESS ||
    //     entities[0].status.name === requestStatus.AGREED || (entities[0].status.name === requestStatus.REJECTED
    //       && entities[0].agreedEmployee.id !== getValueFromLocalStorage(GLOBAL_USER_KEY).id));
    // }

    return true;
  },
};

export const sendToAgreementActionMenuItem = {
  name: requestAction.SEND_TO_AGREEMENT,
  label: 'Согласовать с Адм. ХБП',
  icon: 'assignment_turned_in',
  svgIcon: <AssignmentTurnedIn />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.ACCEPTED, requestStatus.CLOSED, requestStatus.REJECTED, requestStatus.COMPLETED].includes(currentRecord.status.name) ||
        (currentRecord.status.name === requestStatus.AGREED && isReception())
    }

    // if (itemMenuRecord.length === 1) {
    //   const status = entities[0].status && entities[0].status.name;
    //   if (!status) return false;
    //   return (status === requestStatus.ACCEPTED || status === requestStatus.CLOSED || status === requestStatus.REJECTED || status === requestStatus.COMPLETED
    //     || (status === requestStatus.AGREED && isReception()));
    // }
    return true;
  },
};

export const sendToAgreementByTenantActionMenuItem = {
  name: requestAction.SEND_TO_AGREEMENT_BY_TENANT,
  label: 'Согласовать с арендатором',
  icon: 'assignment_ind',
  svgIcon: <AssignmentInd />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status || !currentRecord.type) return false
      return [requestStatus.CLOSED, requestStatus.COMPLETED].includes(currentRecord.status.name) ||
        currentRecord.type.name !== requestType.CARRYING_OUT_WORKS ||
        (currentRecord.status.name === requestStatus.AGREED && isReception())
    }

    // if (itemMenuRecord.length === 1) {
    //   const status = entities[0].status && entities[0].status.name;
    //   const type = entities[0].type && entities[0].type.name;
    //   if (!status) return false;
    //   if (!type) return false;
    //   return (status === requestStatus.CLOSED || status === requestStatus.COMPLETED || type !== requestType.CARRYING_OUT_WORKS
    //     || (status === requestStatus.AGREED && isReception()));
    // }
    return true;
  },
};

export const sendEmailActionMenuItem = {
  name: requestAction.SEND_EMAIL,
  label: 'Экспорт в outlook',
  icon: 'email',
  svgIcon: <Email />,
  disabled: (itemMenuRecord) => itemMenuRecord.length !== 1,
};


export const arrivedActionItem = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.IN_PROGRESS, requestStatus.CREATED].includes(currentRecord.status.name)
    }

    // if (itemMenuRecord.length === 1) {
    //   if (!entities[0].status) return false;
    //   return (entities[0].status.name === requestStatus.CLOSED ||
    //     entities[0].status.name === requestStatus.COMPLETED || entities[0].status.name === requestStatus.IN_PROGRESS
    //     || entities[0].status.name === requestStatus.CREATED);
    // }
    return true;
  },
};

export const departedActionItem = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name !== requestStatus.ON_TERRITORY
    }

    // if (itemMenuRecord.length === 1) {
    //   if (!entities[0].status) return false;
    //   return entities[0].status.name !== requestStatus.ON_TERRITORY;
    // }
    return true;
  },
};

export const addBlackListCarActionItem = {
  name: requestAction.ADD_TO_BLACK_LIST,
  label: 'Добавить в ЧС',
  icon: 'block',
  svgIcon: <Block />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED || currentRecord.status.name === requestStatus.COMPLETED
    }

    // if (itemMenuRecord.length === 1) {
    //   if (!entities[0].status) return false;
    //   return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED);
    // }
    return true;
  },
};
