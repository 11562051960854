import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Fade from '@material-ui/core/Fade/Fade';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const style = () => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  progressBar: {
    zIndex: 1000,
  },
});

const CircularLoaderBase = ({
  classes,
  size,
  state,
  thickness,
  styles,
}) => {
  const elementStyle = Object.assign({}, {
    transitionDelay: state ? '200ms' : '0ms',
  }, styles);

  return (
    <div className={classes.root}>
      <Fade
        in={state}
        className={classes.progressBar}
        style={elementStyle}
        unmountOnExit
      >
        <MuiCircularProgress thickness={thickness} size={size} />
      </Fade>
    </div>
  );
};

CircularLoaderBase.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number,
  state: PropTypes.bool.isRequired,
  styles: PropTypes.object,
  thickness: PropTypes.number,
};

CircularLoaderBase.defaultProps = {
  size: 70,
  styles: {},
  thickness: 4,
};

export default withStyles(style, { name: 'CircularProgress' })(CircularLoaderBase);
