/**
 *
 * RestrictedAdministratorBC Requests MobileGrid
 *
 */

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
} from 'domain/Data/Filters/constants';
import { ReceptionMobileGrid } from '../ReceptionView';

class RestrictedAdministratorBCRequestsMobileGrid extends ReceptionMobileGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    return filters;
  }
}

export default RestrictedAdministratorBCRequestsMobileGrid;
