/**
 *
 * MakingAgreementBCFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import {
  MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG,
  MAKING_AGREEMENT_BC_REQUESTS_FORM,
} from './constants';

function MakingAgreementBCFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG}
      formName={MAKING_AGREEMENT_BC_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
    />
  );
}

MakingAgreementBCFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default MakingAgreementBCFormDialog;
