/**
 *
 * AdministratorBC Requests DxGrid 15755
 *
 */

import {
    CURRENT_DAY_REQUESTS,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT,
    REQUESTS_FOR_ON_PASS,
    ON_CREATE_REQUESTS,
    REQUESTS_FOR_MAKING_AGREEMENT_BC,
    REQUESTS_ON_PROPERTY_BRINGING,
    REQUESTS_ON_PROPERTY_REMOVED,
} from 'domain/Data/Filters/constants';

import { ReceptionDxGrid15755 } from '../ReceptionView/ReceptionDxGrid15755';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { addBlackListCarActionButton } from 'pages/Requests/common/actionButton';
import { addBlackListCarActionItem } from 'pages/Requests/common/actionMenu';

export class AdministratorBCRequestsDxGrid15755 extends ReceptionDxGrid15755 {

    getFilters() {
        const { viewName } = this.props;
        const filters = [];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'pass') filters.push(REQUESTS_FOR_ON_PASS);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'agree') filters.push(REQUESTS_FOR_MAKING_AGREEMENT_BC);
        if (viewName === 'propertyBringing') filters.push(REQUESTS_ON_PROPERTY_BRINGING);
        if (viewName === 'propertyRemoved') filters.push(REQUESTS_ON_PROPERTY_REMOVED);

        return filters
    }

    getTableFeatures() {
        const tableFeatures = super.getTableFeatures();
        const tableRowDetailIndex = tableFeatures.findIndex((field) => field.name === settings.TABLE_ROW_DETAIL);
        tableFeatures.splice(tableRowDetailIndex, 1);
        return tableFeatures;
    }


    getActionButtons() {
        const actionButtons = super.getActionButtons()
        actionButtons.push(addBlackListCarActionButton)
        return actionButtons
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems()
        actionMenuItems.push(addBlackListCarActionItem)
        return actionMenuItems
    }
}
