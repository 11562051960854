/**
 *
 * 11994 common dxGridColumnOrder
 *
 */

export const MOVEMENT_TMC_COLUMN_ORDER = [
  'number',
  'statusName',
  'visitDate',
  'expirationDate',
  'visitorFullName',
  'materialValuesData',
  'note',
  'company_name',
  'authorName',
  'arrivalDate',
  'departureDate',
  'actualCreationDate',
];
