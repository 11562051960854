/*
 *
 * UsersView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

function UsersView({ dataTable, formDialog, viewName, viewStyles, }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      {
        React.cloneElement(formDialog, { viewName })
      }
    </div>
  );
}

UsersView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
};

export default UsersView;
