import React from 'react';
import PropTypes from 'prop-types';

const Logotype12970 = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 29.104 8.466">
    <g transform="translate(-1.838 -5.811)">
      <text
        transform="scale(.91387 1.0943)"
        x={5.004}
        y={10.631}
        fontFamily="Ebrima"
        fontSize={7.048}
        fontWeight="bold"
        letterSpacing={0}
        stroke="#000"
        strokeWidth={0.265}
        wordSpacing={0}
        style={{
          lineHeight: 1.25,
        }}
      >
        <tspan x={5.004} y={10.631}>
          {'Comcity'}
        </tspan>
      </text>
      <ellipse
        cx={30.082}
        cy={11.361}
        rx={0.859}
        ry={0.821}
        fill="#886b33"
        strokeWidth={0}
        paintOrder="stroke markers fill"
      />
      <path
        fill="#ee8201"
        strokeWidth={0}
        paintOrder="stroke markers fill"
        d="M1.837 13.574h11.048v.703H1.837z"
      />
    </g>
  </svg>
);

Logotype12970.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Logotype12970.defaultProps = {
  width: 110,
  height: 32,
};

export default Logotype12970;
