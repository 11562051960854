/*
 *
 * ReceptionForm13557
 *
 */

import { ReceptionForm } from 'pages/Requests/ReceptionView';
import { ON_VISIT, SERVICE } from 'domain/Data/RequestType/constants';
import { CREATED, ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { TP_1_PARKING, TP_2_PARKING, TP_3_PARKING } from 'custom/13557/domain/Data/Parking/constants';

class ReceptionForm13557 extends ReceptionForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();
    const unnecessaryParking = new Set([TP_1_PARKING, TP_2_PARKING, TP_3_PARKING]);

    const whoMeetsIndex = fields.findIndex((field) => field.name === 'whoMeets');
    fields[whoMeetsIndex].required = (values) => values.type === ON_VISIT;

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].calculatedValue = (values) => values.type === SERVICE ?
      CREATED : ACCEPTED;

    const parkingIndex = fields.findIndex((field) => field.name === 'parking');
    fields[parkingIndex].visibleOptions = (currentValues, initialValues, mode, user, entities) => {
      const parking = [];
      entities.get('parkings').map((item) => {
        if (!unnecessaryParking.has(item.id)) parking.push(item.id);
      });
      return parking;
    };

    return fields;
  }
}

export default ReceptionForm13557;
