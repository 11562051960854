/**
 *
 * getRequestsPage Function
 *
 */
import React from 'react';

import {account} from 'domain/typeConstants/accounts';

import {RequestsPage11994} from 'custom/11994/pages/Requests';
import {RequestsPage12924} from 'custom/12924/pages/Requests';
import {RequestsPage12970} from 'custom/12970/pages/Requests';
import {RequestsPage13539} from 'custom/13539/pages/Requests';
import {RequestsPage13557} from 'custom/13557/pages/Requests';
import {RequestsPage14002} from 'custom/14002/pages/Requests';
import {RequestsPage14163} from 'custom/14163/pages/Requests';
import {RequestsPage14366} from 'custom/14366/pages/Requests';
import {RequestsPage14140} from 'custom/14140/pages/Requests';
import {RequestsPage14326} from 'custom/14326/pages/Requests';
import {RequestsPage14419} from 'custom/14419/pages/Requests';
import {RequestsPage14278} from 'custom/14278/pages/Requests';
import {RequestsPage14691} from 'custom/14691/pages/Requests';
import {RequestsPage12405} from "custom/12405/pages/Requests";
import {RequestsPage14720} from "custom/14720/pages/Requests";
import {RequestsPage14927} from "custom/14927/pages/Requests";
import {RequestsPage14254} from 'custom/14254/pages/Requests';
import {RequestsPage15511} from 'custom/15511/pages/Requests';
import {RequestsPage15755} from 'custom/15755/pages/Requests';
import {RequestsPage12861} from 'custom/12861/pages/Requests';
import {RequestsPage15776} from 'custom/15776/pages/Requests';
import {RequestsPage15774} from "custom/15774/pages/Requests";
import {RequestsPage15783} from "custom/15783/pages/Requests";
import RequestsPage from './RequestsPage';


export default function getRequestsPage(accountId, props) {
    switch (accountId) {
        case account.LEFORT:
            return (
                <RequestsPage11994 {...props} />
            );

        case account.ZHUKOV:
            return (
                <RequestsPage12924 {...props} />
            );

        case account.COMCITY:
            return (
                <RequestsPage12970 {...props} />
            );

        case account.NEW_HOLLAND:
            return (
                <RequestsPage13539 {...props} />
            );

        case account.SILVER_CITY:
            return (
                <RequestsPage13557 {...props} />
            );

        case account.BC_71_7ONE:
            return (
                <RequestsPage14002 {...props} />
            );

        case account.BC_CENTRAL_YARD:
            return (
                <RequestsPage14163 {...props} />
            );

        case account.LINKOR:
            return (
                <RequestsPage14278 {...props} />
            );

        case account.BC_LESNAYA:
            return (
                <RequestsPage14366 {...props} />
            );

        case account.BC_BERLINSKY_GENEVSKY:
            return (
                <RequestsPage14140 {...props} />
            );

        case account.BC_KHIMKI:
            return (
                <RequestsPage14326 {...props} />
            );

        case account.BP_FAKTORIYA:
            return (
                <RequestsPage14419 {...props} />
            );

        case account.VOZDVIZHENKA:
            return (
                <RequestsPage14691 {...props} />
            );

        case account.BC_BOLSHEVICK:
            return (
                <RequestsPage12405 {...props} />
            );

        case account.BC_GORKIPARK_TOWER:
            return (
                <RequestsPage14720 {...props} />
            );

        case account.ART_GALLERY:
            return (
                <RequestsPage14927 {...props} />
            );

        case account.WALLSTREET:
            return (
                <RequestsPage14254 {...props} />
            );

        case account.BC_DELTA_PLAZA:
            return (
                <RequestsPage15511 {...props} />
            );

        case account.KRYLATSKI_HOLMY:
            return (
                <RequestsPage15755 {...props} />
            );

        case account.BINOM:
            return (
                <RequestsPage12861 {...props} />
            );

        case account.MOK_AFI_SQUARE:
            return (
                <RequestsPage15776 {...props} />
            );

        case account.BC_BELIYE_SADY:
            return (
                <RequestsPage15774 {...props} />
            );

        case account.BC_4_VETRA:
            return (
                <RequestsPage15783 {...props} />
            );

        default:
            return (
                <RequestsPage {...props} />
            );
    }
}
