import React from 'react';
import PropTypes from 'prop-types';

import ReceptionView, {
  ReceptionDxGrid,
  ReceptionDxGridContainer,
  ReceptionForm,
  ReceptionFormDialog,
  ReceptionMobileGrid,
} from '../ReceptionView';

const getDxGrid = (DxGrid) => (
  <ReceptionDxGridContainer component={DxGrid || <ReceptionDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <ReceptionDxGridContainer component={MobileGrid || <ReceptionMobileGrid />} />
);

const getFormDialog = (Form) => (
  <ReceptionFormDialog form={Form || <ReceptionForm />} />
);

const getDataTableView = ({ breakpoint, receptionDxGrid, receptionMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(receptionMobileGrid);
  }

  return getDxGrid(receptionDxGrid);
};

export const getReceptionView = (props) => {
  const {
    breakpoint,
    receptionDxGrid,
    receptionForm,
    receptionFormDialog,
    receptionMobileGrid,
    receptionView,
    viewName,
    viewStyles,
  } = props;

  if (receptionView) return receptionView;

  return (
    <ReceptionView
      key={viewName}
      viewName={viewName}
      breakpoint={breakpoint}
      dataTable={getDataTableView({
        breakpoint,
        receptionDxGrid,
        receptionMobileGrid,
      })}
      formDialog={receptionFormDialog || getFormDialog(receptionForm)}
      viewStyles={viewStyles}
    />
  );
};

getReceptionView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  receptionDxGrid: PropTypes.node,
  receptionForm: PropTypes.node,
  receptionFormDialog: PropTypes.node,
  receptionMobileGrid: PropTypes.node,
  receptionView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
