/*
 *
 * RequestsPageContainer12924
 *
 */

import React from 'react';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import RequestsPage12924 from './RequestsPage12924';
import saga from './sagas';

function RequestsPageContainer12924(props) {
  return (
    <RequestsPage12924 {...props} />
  );
}

const withSaga = injectSaga({ key: 'receptionPage12924', saga });

export default compose(
  withSaga,
)(RequestsPageContainer12924);
