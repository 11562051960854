/*
 *
 * LoginForm sagas
 *
 */

import { take, put, select, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  startSubmit,
  stopSubmit,
  touch,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import * as api from 'utils/api';

import * as userActionCreators from 'domain/User/actions';
import { LOG_IN_ERROR } from 'domain/User/constants';
import { SUBMIT_LOGIN_FORM, FORGOT_PASSWORD_LOGIN_FORM } from './constants';
import { setInfoMessage } from './actions'

const LOGIN_FORM_NAME = 'loginForm';

export default function* rootSaga() {
  yield takeLatest(SUBMIT_LOGIN_FORM, submitLoginForm);
  yield takeLatest(FORGOT_PASSWORD_LOGIN_FORM, forgotPasswordLoginForm);
}

function* submitLoginForm({ payload: { nextPathAfterLogin } }) {
  yield put(touch(LOGIN_FORM_NAME, 'login', 'password'));
  const syncErrors = yield select(getFormSyncErrors(LOGIN_FORM_NAME, (state) => state.get('forms')));
  if (syncErrors.login || syncErrors.password) return;

  const formValues = yield select(getFormValues(LOGIN_FORM_NAME, (state) => state.get('forms')));
  const { login, password } = formValues.toJS();
  yield put(startSubmit(LOGIN_FORM_NAME));
  yield put(userActionCreators.login(login, password, nextPathAfterLogin));
  const { payload: { error } } = yield take(LOG_IN_ERROR);
  yield put(stopSubmit(LOGIN_FORM_NAME, { _error: error.message }));
}


function* forgotPasswordLoginForm() {
  yield put(touch(LOGIN_FORM_NAME, 'login', 'password'));
  const formValues = yield select(getFormValues(LOGIN_FORM_NAME, (state) => state.get('forms')));
  const { login } = formValues.toJS();
  try {
    const response = yield call(api.forgotPassword, login);
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`; // eslint-disable-line dot-notation
  } catch (err) {
    if (err && err.response && err.response.status === 400 && err.response.data.error_description === "The user is blocked.") {
      yield put(stopSubmit(LOGIN_FORM_NAME, { _error: "Пользователь заблокирован" }));
    }
  }
  try {
    const response = yield call(api.getUserByLogin, login);
    const fullName = response.data[0] && response.data[0].fullName ? response.data[0].fullName : null;
    const email = response.data[0] && response.data[0].email ? response.data[0].email : null;

    if (!fullName) {
       yield put(setInfoMessage("Введите логин с учетом регистра или обратитесь к администратору", "error"));
     } else
    if (!email) {
      yield put(setInfoMessage("В данных сотрудника отсутствует email, обратитесь к администратору", "error"));
    }
    else {
      yield call(api.sendMailPasswordRecovery, email, fullName, login);
      yield put(setInfoMessage("На адрес " + email + " отправлена ссылка для сброса пароля", "primary"));
    }
  } catch (err) {
    console.log("err", err);
    throw err;
  }
}
