/**
 *
 * Rules ActionButton Items
 *
 */
import React from 'react';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Create from '@material-ui/icons/Create';

import { documentAction } from 'domain/typeConstants/action';

export const addActionButton = {
  name: documentAction.ADD,
  label: 'Добавить',
  icon: 'add_circle_outline',
  svgIcon: <AddCircleOutline />,
};

export const editActionButton = {
  name: documentAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => !(currentRecord || selection.length === 1)
};