/**
 *
 * ServiceRequestsManager14163
 *
 */

import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { SERVICE } from 'domain/Data/RequestType/constants';

class ServiceRequestsManager14163 extends ServiceRequestsManagerForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields[assigneeIndex].calculatedValue = (values, mode, entities) => {
      let assignee = null;
      if (values.type === SERVICE && values.service && entities) {
        entities.get('services').forEach((item) => {
          if (values.service === item.id) assignee = item.manager;
        });
      }
      return assignee;
    };

    return fields;
  }
}

export default ServiceRequestsManager14163;
