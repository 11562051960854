/*
 *
 * NavigationDrawer actions
 *
 */

import {
  OPEN_NAVIGATION_DRAWER,
  CLOSE_NAVIGATION_DRAWER,
} from './constants';

export function openNavigationDrawer(width) {
  return {
    type: OPEN_NAVIGATION_DRAWER,
    payload: { width },
  };
}

export function closeNavigationDrawer(width) {
  return {
    type: CLOSE_NAVIGATION_DRAWER,
    payload: { width },
  };
}
