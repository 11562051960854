/**
 *
 * UnloadingZoneOperatorView constants
 *
 */

export const UNLOADING_ZONE_OPERATOR_REQUESTS_TABLE_GRID = 'UnloadingZoneOperatorRequestsTableGrid';
export const UNLOADING_ZONE_OPERATOR_REQUESTS_FORM = 'UnloadingZoneOperatorRequestsForm';
export const UNLOADING_ZONE_OPERATOR_REQUESTS_FORM_DIALOG = 'UnloadingZoneOperatorRequestsFormDialog';

export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/UnloadingZoneOperatorView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/UnloadingZoneOperatorView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/UnloadingZoneOperatorView/EXECUTE_DEPARTED_ACTION';
