/**
*
* Checkbox
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  error: {
    color: theme.palette.error.main,
  },

  helperText: {
    margin: "0",
    fontSize: "0.75rem",
    textAlign: "left",
    minHeight: "1em",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sansSerif"',
    lineHeight: "1em",
    marginTop: "8px",
    color: "rgba(0, 0, 0, 0.54)",
  }
});

function EnhancedCheckbox({
  input: { value, ...restInputProps },
  label,
  meta: { touched, error, submitFailed },
  readOnly,
  calculateFieldsValues,
  classes,
  required,
  tooltip,
  fileSize,
  fieldName,
  helperText,
  helperTextClassName,
  ...custom
}) { // eslint-disable-line no-unused-vars
  const isError = (touched || submitFailed) && !!error;
  const renderCheckBox = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={value}
              {...restInputProps}
              onChange={(event, checked) => {
                if (readOnly) return;
                restInputProps.onChange(checked);
                calculateFieldsValues();
              }}
              classes={{
                root: classNames({ [classes.error]: isError }),
              }}
              {...custom}
            />
          }
          label={`${label}${required ? ' *' : ''}`}
          classes={{
            label: classNames({ [classes.error]: isError }),
          }}
        />
        <span
          className={classNames(classes.helperText,
            helperTextClassName?.helperText,
          )}
        >{helperText}</span>
      </div >
    )
  }

  return (
    tooltip
      ? (
        <Tooltip title={tooltip} placement="bottom" id={`${restInputProps.name}`}>
          {renderCheckBox()}
        </Tooltip>
      ) : renderCheckBox()

  );
}

EnhancedCheckbox.propTypes = {
  calculateFieldsValues: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default withStyles(styles)(EnhancedCheckbox);
