/*
 *
 *  common fields for gorkiy park BC 14720
 *
 */

import { formField } from "domain/typeConstants/form";
import { SERVICE } from "domain/Data/RequestType/constants";

/** ---------------- estimationCost ---------------- */
export const estimationCost = {
    name: 'estimationCost',
    label: 'Смета',
    type: formField.FILE,
    visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE
        &&(mode === 'add' || mode === 'edit'|| mode === 'view'),
    calculatedValue: (values, mode) => {
        if (mode === 'copy' && values.type === SERVICE) return null;
        return values.estimationCost;
    },
};

/** ---------------- estimationCostScan ---------------- */
export const estimationCostScan = {
    name: 'estimationCostScan',
    label: 'Скан сметы',
    type: formField.FILE,
    visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE
        &&(mode === 'add' || mode === 'edit'|| mode === 'view'),
    calculatedValue: (values, mode) => {
        if (mode === 'copy' && values.type === SERVICE) return null;
        return values.estimationCostScan;
    },
};
