/**
 *
 * SupplierInvoicesView sagas
 *
 */

import { select, put, takeLatest } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { openViewDialog } from 'containers/AdvancedFormDialog/actions';

import {
  EXECUTE_VIEW_ACTION,
  SUPPLIER_INVOICES_GRID,
  SUPPLIER_INVOICE_FORM_DIALOG,
} from './constants';
import { makeSelectParent, makeSelectParentId } from 'containers/AdvancedFormDialog/selectors';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && action.meta.dialog === SUPPLIER_INVOICE_FORM_DIALOG

export default function* rootSaga() {
  yield takeLatest(EXECUTE_VIEW_ACTION, executeViewActionSaga);
  yield takeLatest([needViewRefresh, DISPLAY_NOTIFICATION], executeNeedViewRefreshSaga);
}

function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(SUPPLIER_INVOICE_FORM_DIALOG, { id }));
  }
}

function* executeNeedViewRefreshSaga() {
  const parentId = yield select(makeSelectParentId(SUPPLIER_INVOICE_FORM_DIALOG))
  const parent = yield select(makeSelectParent(SUPPLIER_INVOICE_FORM_DIALOG))
  if (parent && parentId) {
    yield put(loadData(SUPPLIER_INVOICES_GRID, { parent, parentId, mode: 'detail' }))
  }
}
