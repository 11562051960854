/*
 *
 * SupplyRequestFormDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import {
  executeRunDialogAction,
  executeAgreeDialogAction,
  executeDisagreeDialogAction,
} from './actions';
import SupplyRequestFormDialog from './SupplyRequestFormDialog';
import { SUPPLY_REQUEST_DX_GRID } from './constants';
import { makeSelectRowSelection } from 'containers/AdvancedDataTable/selectors';


class SupplyRequestFormDialogContainer extends React.Component {
  render() {
    const { viewName } = this.props;
    return (
      <div>
        <SupplyRequestFormDialog
          onRun={this.props.run}
          onAgree={this.props.agree}
          onDisagree={this.props.disagree}
          viewName={viewName}
          {...this.props}
        />
      </div>
    );
  }
}

SupplyRequestFormDialogContainer.propTypes = {
  run: PropTypes.func.isRequired,
  agree: PropTypes.func.isRequired,
  disagree: PropTypes.func.isRequired,
  viewName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  row: makeSelectRowSelection(SUPPLY_REQUEST_DX_GRID),
});

function mapDispatchToProps(dispatch) {
  return {
    run: (dialogName, formName) => dispatch(executeRunDialogAction(dialogName, formName)),
    agree: (dialogName, formName) => dispatch(executeAgreeDialogAction(dialogName, formName)),
    disagree: (dialogName, formName) => dispatch(executeDisagreeDialogAction(dialogName, formName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyRequestFormDialogContainer);
