import React, { PureComponent } from 'react';
import BarChartWidget from './BarChart';
import { makeSelectEntityData, makeSelectEntityRange } from '../selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';


function createBarChart(entity) {
    class BarChartContainer extends PureComponent {

        render() {
            return (
                <BarChartWidget {...this.props} />
            );
        }
    }

    const mapStateToProps = createStructuredSelector({
        data: makeSelectEntityData(entity),
        range: makeSelectEntityRange(entity),
    });

    return connect(mapStateToProps)(BarChartContainer);
}

export default createBarChart;
