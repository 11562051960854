/**
 *
 * AgreementDialog constants
 *
 */

export const OPEN_AGREEMENT_DIALOG = 'claris-vnext/RequestsPage/AgreementDialog/OPEN_AGREEMENT_DIALOG';
export const SUBMIT_AGREEMENT_DIALOG = 'claris-vnext/RequestsPage/AgreementDialog/ACCEPT_AGREEMENT_DIALOG';
export const CLOSE_AGREEMENT_DIALOG = 'claris-vnext/RequestsPage/AgreementDialog/CLOSE_AGREEMENT_DIALOG';

export const LOAD_OPTIONS = 'claris-vnext/RequestsPage/AgreementDialog/LOAD_OPTIONS';

export const SET_MANAGER_ENTITY_IDS = 'claris-vnext/RequestsPage/AgreementDialog/SET_MANAGER_ENTITY_IDS';
export const SET_MANAGERS_VALUES = 'claris-vnext/RequestsPage/AgreementDialog/SET_MANAGER_VALUES';

export const DIALOG_NAME = 'requestsAgreementDialog';
