/**
 *
 * UserMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import cyan from '@material-ui/core/colors/cyan';
import amber from '@material-ui/core/colors/amber';
import Person from '@material-ui/icons/Person';

import classNames from 'classnames';

import { account } from 'domain/typeConstants/accounts';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { ACCOUNT_ID_KEY } from 'global-constants';

const styleSheet = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: cyan['A400'], // eslint-disable-line dot-notation
  },
  comcityAvatar: {
    backgroundColor: amber['A400'], // eslint-disable-line dot-notation
  },
  khimkiAvatar: {
    backgroundColor: '#132932',
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  userInfo: {
    padding: 10,
  },
  mobileUserInfo: {
    fontSize: 12,
  },
});

function makeAvatarContent(username) { // eslint-disable-line
  return username
    .split(' ')
    .filter((word) => word)
    .filter((word, index) => index < 2)
    .map((word) => word.charAt(0));
}

function UserMenu({
  classes, username, accountName, breakpoint,
}) {
  const accountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);

  return (
    <div className={classes.root}>
      <Avatar
        className={classNames({
          [classes.avatar]: accountId !== account.COMCITY,
          [classes.comcityAvatar]: accountId === account.COMCITY,
          [classes.khimkiAvatar]: accountId === account.BC_KHIMKI,
        })}
      >
        <Person />
      </Avatar>
      {
        breakpoint.down('sm')
          ? (
            <div className={classes.userInfoContainer}>
              <Typography type="body2" color="inherit" className={classes.mobileUserInfo}>
                {username}
              </Typography>
              <Typography type="body2" color="inherit" className={classes.mobileUserInfo}>
                {accountName}
              </Typography>
            </div>
          ) : (
            <Typography type="body2" color="inherit" className={classes.userInfo}>
              {username}
            </Typography>
          )
      }
    </div>
  );
}

UserMenu.propTypes = {
  accountName: PropTypes.string,
  breakpoint: PropTypes.object.isRequired,
  classes: PropTypes.object,
  username: PropTypes.string,
};

export default withStyles(styleSheet)(UserMenu);
