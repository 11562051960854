/*
 *
 * User reducer
 *
 */

import { List } from 'immutable';
import { UserInfo } from 'domain/Data/recordTypes';
import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
} from 'utils/localStorage';

import {
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOAD_USER_INFO_SUCCESS,
  LOG_OUT,
} from './constants';

let activeUser = getValueFromLocalStorage('claris-vnext-global-user');
if (activeUser) {
  const { accessToken, accountId, id, isAuthenticated, login, name, roles } = activeUser;
  if (!accessToken || !accountId || !id || !isAuthenticated ||
    !login || !name || !roles || roles.length === 0) {
    removeValueFromLocalStorage('claris-vnext-global-user');
    activeUser = null;
  }
}

const initialState = activeUser ?
  new UserInfo({
    ...activeUser,
    roles: new List(activeUser.roles),
  }) :
  new UserInfo();

function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOG_IN_SUCCESS:
      return state
        .set('login', payload.data.user_name)
        .set('isAuthenticated', true)
        .set('accessToken', payload.data.access_token);
    case LOAD_USER_INFO_SUCCESS:
      return state
        .set('id', payload.data.id)
        .set('name', payload.data.name)
        .set('companyId', payload.data.companyId)
        .set('departmentId', payload.data.departmentId)
        .set('accountId', payload.data.accountId)
        .set('accountName', payload.data.accountName)
        .set('forcePasswordChange', payload.data.forcePasswordChange)
        .set('defaultRequestTypeId', payload.data.defaultRequestTypeId)
        .set('isAutoUpdate', payload.data.isAutoUpdate)
        .set('checkpointPostId', payload.data.checkpointPostId)
        .set('roles', new List(payload.data.roles))
        .set('date', payload.data.date);
    case LOG_IN_ERROR:
    case LOG_OUT:
      return state
        .set('login', null)
        .set('isAuthenticated', false)
        .set('accessToken', null);
    default:
      return state;
  }
}

export default userReducer;
