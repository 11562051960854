/**
*
* createAutocompleteContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field } from 'redux-form/immutable';

import * as actionCreators from './actions';
import { makeSelectEntity, makeSelectLoadedOptions, makeSelectValue } from './selectors';
import Autocomplete from './Autocomplete';
import * as advancedFormActionCreators from '../actions';
import { makeSelectMode } from '../selectors';

function createAutocompleteContainer(formName, fieldName) {
  class AutocompleteContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    componentWillMount() {
      const { entityName, name, loadInitialValue, value, mode } = this.props;
      if (mode !== null && loadInitialValue && value) {
        loadInitialValue({
          entityName,
          id: value,
          field: name,
        });
      }
    }

    render() {
      const { clearErrorsOnChange, loadInitialValue, ...restProps } = this.props; // eslint-disable-line no-unused-vars

      return (
        <Field
          {...restProps}
          component={Autocomplete}
        />
      );
    }
  }

  AutocompleteContainer.propTypes = {
    calculateFieldsValues: PropTypes.func.isRequired,
    clearErrorsOnChange: PropTypes.bool,
    entityName: PropTypes.string.isRequired,
    loadInitialValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };

  AutocompleteContainer.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    entity: makeSelectEntity(formName, fieldName),
    value: makeSelectValue(formName, fieldName),
    options: makeSelectLoadedOptions(formName, fieldName),
    mode: makeSelectMode(formName),
  });

  const mapDispatchToProps = (dispatch) => ({
    calculateFieldsValues: (nameOfFieldCausedCalculation) => dispatch(advancedFormActionCreators.calculateFieldsValues(formName, nameOfFieldCausedCalculation)),
    clearOptions: () => dispatch(actionCreators.clearOptions(formName, fieldName)),
    loadInitialValue: (params) => dispatch(actionCreators.loadInitialValue(formName, params)),
    loadOptions: (searchQuery) => dispatch(actionCreators.loadOptions(formName, fieldName, searchQuery)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(AutocompleteContainer);
}

export default createAutocompleteContainer;
