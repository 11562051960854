/**
 *
 * ActionMenu Items
 *
 */

import React from 'react';
import Create from '@material-ui/icons/Create';
import { requestAction } from 'domain/typeConstants/action';

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};