/*
 *
 * SupplyRequestInvoiceFormContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectParentId, } from 'containers/AdvancedFormDialog/selectors'
import * as actionCreators from './actions';
import { SUPPLIER_INVOICE_FORM_DIALOG } from './constants';
import SupplierInvoiceForm from './SupplierInvoiceForm';

class SupplierInvoiceFormContainer extends React.PureComponent {
  render() {
    return (
      <SupplierInvoiceForm {...this.props} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  parentId: makeSelectParentId(SUPPLIER_INVOICE_FORM_DIALOG),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierInvoiceFormContainer);

