import { HttpConnection, HubConnection } from '@aspnet/signalr-client/dist/browser/signalr-clientES5-1.0.0-alpha2-final';

import config from 'config';

import makeSelectUser from 'domain/User/selectors';

import { scanCarNumber } from './actions';

import {
  CONNECT_TO_CAR_NUMBER_SCANNER_SERVICE,
  DISCONNECT_TO_CAR_NUMBER_SCANNER_SERVICE,
} from './constants';

const createCarNumberScannerMiddleware = () => (store) => {
  let hubConnection = null;

  const user = makeSelectUser()(store.getState());
  if (user.accountId === '12924' && user.roles.includes('checkpoint')) {
    hubConnection = createHubConnection(store);
    hubConnection.start().catch(() => undefined);
  }

  return (next) => (action) => {
    if (action.type === CONNECT_TO_CAR_NUMBER_SCANNER_SERVICE) {
      hubConnection = createHubConnection(store);
      hubConnection.start().catch(() => undefined);
    } else if (action.type === DISCONNECT_TO_CAR_NUMBER_SCANNER_SERVICE) {
      hubConnection.stop().catch(() => undefined);
    }
    return next(action);
  };
};

const createHubConnection = (store) => {
  const httpConnection = new HttpConnection(`${config.carNumberScannerServiceUrl}/CVSHub`);
  const newHubConnection = new HubConnection(httpConnection);
  newHubConnection.on('NumberReady', (data) => store.dispatch(scanCarNumber(data)));
  return newHubConnection;
};

export default createCarNumberScannerMiddleware;
