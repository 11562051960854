/**
 *
 * AdvancedMobileGrid list container header row component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { SearchPanel } from './header-row/search-panel';
import { FilterPanel } from './header-row/filter-panel';

const styles = () => ({
  root: {
    height: 40,
    display: 'flex',
    background: '#cfd8dc',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
  },
  toolbar: {
    height: 40,
    display: 'flex',
    background: '#cfd8dc',
  },
  iconButton: {
    height: 28,
    width: 28,
  },
  icon: {
    color: '#757575',
    cursor: 'pointer',
    height: 28,
  },
});

const HeaderRowBase = ({
  actionButtons,
  classes,
  listRef,
  loadData,
  onActionButtonClick,
  onChangeSearchQuery,
  searchQuery,
  onClickFilterOn,
  onClickFilterOff,
  features,
}) => (
  <div className={classes.root}>
    <div>
      {
        actionButtons.map((button) => (
          <IconButton
            aria-label={button.name}
            className={classes.iconButton}
            disabled={button.disabled}
            key={button.name}
            onClick={() => onActionButtonClick(button.name)}
          >
            {button.svgIcon}
          </IconButton>
        ))
      }
    </div>
    <div className={classes.toolbar}>
      <SearchPanel
        listRef={listRef}
        loadData={loadData}
        onChangeSearchQuery={onChangeSearchQuery}
        searchQuery={searchQuery}
      />
      <FilterPanel
        onClickFilterOn={onClickFilterOn}
        onClickFilterOff={onClickFilterOff}
        loadData={loadData}
        features={features}
      />
    </div>
  </div>
);

HeaderRowBase.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    svgIcon: PropTypes.node,
    disabled: PropTypes.bool,
  })),
  classes: PropTypes.object.isRequired,
  listRef: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  onActionButtonClick: PropTypes.func.isRequired,
  onChangeSearchQuery: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

export const HeaderRow = withStyles(styles, { name: 'HeaderRow' })(HeaderRowBase);
