/*
 *
 * AssignedDxGrid14720
 *
 */

import { AssignedDxGrid } from 'pages/Requests/AssignedView';
import { requestColumnSizes } from 'domain/typeConstants/table';
import {
    estimationCostColumn,
    estimationCostColumnFilter,
    ESTIMATION_COST,
    estimationCostScanColumn,
    estimationCostScanColumnFilter,
    ESTIMATION_COST_SCAN,
} from "../common/dxGridComponents/columns";

export class AssignedDxGrid14720 extends AssignedDxGrid {

    getColumns() {
        const columns = super.getColumns();

        const serviceNameIndex = columns.findIndex((column) => column.name === 'serviceName');
        columns[serviceNameIndex].entityName = "services";

        const phoneColumn = {
            path: 'phone',
            name: 'phone',
            title: 'Контактный тел',
            width: requestColumnSizes.phone
        };
        columns.push(phoneColumn);
        columns.push(estimationCostColumn);
        columns.push(estimationCostScanColumn);
        return columns;
    }

    getGridFilters() {
        const gridFilters = super.getGridFilters();
        const phoneColumnFilters = { columnName: 'phone', value: '' };
        gridFilters.push(phoneColumnFilters);
        gridFilters.push(estimationCostColumnFilter);
        gridFilters.push(estimationCostScanColumnFilter);
        return gridFilters;
    }

    getColumnOrder() {
        const columnOrder = super.getColumnOrder();
        columnOrder.push('phone');
        const fileIndex = columnOrder.findIndex(column => column === 'fileName');
        columnOrder.splice(fileIndex + 1, 0, ESTIMATION_COST, ESTIMATION_COST_SCAN);
        return columnOrder;
    }
}
