/*
 * LoginPage sagas
 */

import { fork } from 'redux-saga/effects';
import loginFormSaga from 'containers/LoginForm/sagas';

export default function* rootSaga() {
  yield fork(loginFormSaga);
}
