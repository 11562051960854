/**
 *
 * validate functions for RequestsPage14326
 *
 */

import {
  ON_CAR_ENTRY,
  CARRYING_OUT_WORKS,
} from 'domain/Data/RequestType/constants';

import { D_DETAIL_PARKING } from 'custom/14326/domain/Data/Parking/constants';

export const calculateMaxExpirationDate = (values) => {
  if (values.visitDate) {
    const maxDate = new Date(values.visitDate);
    const types = [ON_CAR_ENTRY];

    if (values.longterm && types.includes(values.type)) {
      if (values.parking === D_DETAIL_PARKING) {
        maxDate.setDate(maxDate.getDate() + 5);
      } else maxDate.setDate(maxDate.getDate() + 365);

      return maxDate;
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    return new Date(year + 1, month, day);
  }

  return null;
};

export const validateExpirationDate = (values) => {
  if (values.visitDate && values.expirationDate) {
    const visitDate = new Date(values.visitDate);
    const expirationDate = new Date(values.expirationDate);
    const diff = (expirationDate.setHours(0, 0, 0, 0) - visitDate.setHours(0, 0, 0, 0)) / (24 * 3600 * 1000);
    const types = [ON_CAR_ENTRY];
    if (values.longterm && types.includes(values.type) && diff > 365) {
      return { errorMessage: 'Не может быть больше 1 года после даты посещения' };
    } else if (values.longterm && types.includes(values.type) && values.parking === D_DETAIL_PARKING && diff > 5) {
      return { errorMessage: 'Максимальный срок бронирования - не более 5 суток' };
    }
  }

  return null;
};

export const validatIsReadInstruction = (values) => {
  if (!values.isReadInstruction && values.type === CARRYING_OUT_WORKS) {
    return {
      errorMessage: 'Без ознакомления с инструкцией дальнейшие действия, т.е. отправка на согласование, невозможны',
    };
  }

  return null;
};
