/**
 *
 * ServiceRequestsManagerVisitorArrivalForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { ON_TERRITORY } from 'domain/Data/RequestStatus/constants';
import { SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM } from './constants';
import { DocumentMatchingFormHeader } from '../common/DocumentMatchingFormHeader';
import { visitorFullNameLabel } from '../common/fields';

class ServiceRequestsManagerVisitorArrivalForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: () => false,
        calculatedValue: () => ON_TERRITORY,
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
      },
      {
        name: 'arrivalDate',
        type: 'date',
        label: 'Дата прибытия',
        required: true,
        calculatedValue: () => currentDate,
      },
      {
        name: 'departureDate',
        type: 'date',
        visible: () => false,
        calculatedValue: (values) => values.longterm ? null : values.departureDate,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        visible: (values) => values.type !== SERVICE,
      },
      {
        name: 'passNumber',
        label: '№ пропуска',
        visible: (values) => values.type === ON_VISIT ||
          values.type === ON_PASS_MAKING,
      },
      {
        name: 'company',
        type: 'autocomplete',
        label: 'Арендатор',
        entityName: 'companies',
        orderBy: 'name',
        rowBreakBefore: true,
        disabled: true,
        cached: false,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'documentType',
        type: 'select',
        label: 'Вид документа',
        entityName: 'documentTypes',
        visible: (values) => values.type !== ON_CAR_ENTRY &&
          values.type !== SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'documentDetails',
        label: 'Серия, № документа',
        visible: (values) => values.type !== ON_CAR_ENTRY &&
          values.type !== SERVICE,
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        header={<DocumentMatchingFormHeader />}
        name={SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default ServiceRequestsManagerVisitorArrivalForm;
