/*
 *
 * CarNumberScanner actions
 *
 */

import {
  CONNECT_TO_CAR_NUMBER_SCANNER_SERVICE,
  DISCONNECT_TO_CAR_NUMBER_SCANNER_SERVICE,
  SCAN_CAR_NUMBER,
} from './constants';

export function connectToCarNumberScannerService(user) {
  return {
    type: CONNECT_TO_CAR_NUMBER_SCANNER_SERVICE,
    payload: {
      user,
    },
  };
}

export function disconnectFromCarNumberScannerService() {
  return {
    type: DISCONNECT_TO_CAR_NUMBER_SCANNER_SERVICE,
  };
}

export function scanCarNumber(carNumber) {
  return {
    type: SCAN_CAR_NUMBER,
    payload: { carNumber },
  };
}
