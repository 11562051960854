/**
 *
 * Data utils
 *
 *
 */

import * as C from './constants';
import * as recordTypes from './recordTypes';
import * as schemes from './schemes';

export function mapEntityNameToRecord(entityName) {
  const maps = {
    [C.ACCESS_AREAS]: recordTypes.AccessArea,
    [C.ACCESS_CARDS]: recordTypes.AccessCard,
    [C.ACCESS_LEVELS]: recordTypes.AccessLevel,
    [C.ACTION_CARDS]: recordTypes.ActionCard,
    [C.AGREEMENT_HISTORIES]: recordTypes.AgreementHistory,
    [C.AGREEMENT_SCHEMES]: recordTypes.AgreementScheme,
    [C.AGREEMENT_STATES]: recordTypes.AgreementState,
    [C.AGREEMENT_STEPS]: recordTypes.AgreementStep,
    [C.ATTACHMENTS]: recordTypes.Attachment,
    [C.BLACK_LIST]: recordTypes.BlackList,
    [C.CAR_MODELS]: recordTypes.CarModel,
    [C.COMMENTS]: recordTypes.Comment,
    [C.COMPANIES]: recordTypes.Company,
    [C.CONTRACT_TYPES]: recordTypes.ContractType,
    [C.CONTRACTS]: recordTypes.Contract,
    [C.CONTRACTOR_TYPES]: recordTypes.ContractorType,
    [C.CONTRACTOR_STATUSES]: recordTypes.ContractorStatus,
    [C.DEPARTMENTS]: recordTypes.Department,
    [C.DOCUMENT_CATEGORIES]: recordTypes.DocumentCategory,
    [C.DOCUMENT_KINDS]: recordTypes.DocumentKind,
    [C.DOCUMENT_STATES]: recordTypes.DocumentState,
    [C.DOCUMENT_TYPES]: recordTypes.DocumentType,
    [C.DOCUMENTS]: recordTypes.Document,
    [C.EMPLOYEES]: recordTypes.Employee,
    [C.FILES]: recordTypes.File,
    [C.HOUSINGS]: recordTypes.Housing,
    [C.ISSUE_REASONS]: recordTypes.IssueReason,
    [C.KHIMKI_REQUEST_TYPES]: recordTypes.KhimkiRequestType,
    [C.MANAGERS]: recordTypes.Manager,
    [C.MATERIAL_COMMODITY_VALUE_TYPES]: recordTypes.MaterialCommodityValueType,
    [C.NOTIFICATION_CATEGORIES]: recordTypes.NotificationCategory,
    [C.NOTIFICATION_TYPES]: recordTypes.NotificationType,
    [C.NOTIFICATIONS]: recordTypes.Notification,
    [C.OBJECT_STRUCTURE_TYPES]: recordTypes.ObjectStructure,
    [C.PARKING_TYPES]: recordTypes.ParkingType,
    [C.PARKINGS]: recordTypes.Parking,
    [C.PASS_TYPES]: recordTypes.PassType,
    [C.POSITIONS]: recordTypes.Position,
    [C.REQUEST_STATUSES]: recordTypes.RequestStatus,
    [C.REQUEST_TYPES]: recordTypes.RequestType,
    [C.REQUESTS]: recordTypes.Request,
    [C.REQUEST_PRIORITIES]: recordTypes.RequestPriority,
    [C.RESERVATION_TIMES]: recordTypes.ReservationTime,
    [C.ROLES]: recordTypes.Role,
    [C.SERVICES]: recordTypes.Service,
    [C.TIMES]: recordTypes.Time,
    [C.USERS]: recordTypes.User,
    [C.VEHICLE_TYPES]: recordTypes.VehicleType,
    [C.VISIT_TIMES]: recordTypes.VisitTime,
    [C.VISITOR_LOGS]: recordTypes.VisitorLog,
    [C.VOZDVIZHENKA_ACCESS_LEVELS]: recordTypes.VozdvizhenkaAccessLevel,
    [C.WORK_TYPES]: recordTypes.WorkType,
    [C.WORKING_TIMES]: recordTypes.WorkingTime,
    [C.CONTACTS]: recordTypes.Contact,
    [C.WORKING_HOURS]: recordTypes.WorkingHour,
    [C.JOB_DOINGS]: recordTypes.JobDoing,
    [C.PROJECTS]: recordTypes.Project,
    [C.ESTIMATE_ITEMS]: recordTypes.EstimateItem,
    [C.NUMBER]: recordTypes.TaskNumber,
    [C.FINISH_DATE]: recordTypes.TaskFinishDate,
    [C.REGISTRATION_DATE]: recordTypes.TaskRegistrationDate,
    [C.DESCRIPTION]: recordTypes.TaskDescription,
    [C.AUTHOR]: recordTypes.TaskAuthor,
    [C.RESPONSIBLE]: recordTypes.TaskResponsible,
    [C.STATUS]: recordTypes.TaskStatus,
    [C.COMPANY]: recordTypes.TaskCompany,
    [C.FILE]: recordTypes.TaskFile,

    [C.TASKS]: recordTypes.RequestTask,
    [C.TASK_STATUSES]: recordTypes.TaskStatus,
    [C.NAME]: recordTypes.TaskName,
    [C.COUNT_ATTACHMENTS]: recordTypes.CountAttachments,
    [C.TASK_TYPES]: recordTypes.TaskType,

    [C.WORK_RUN_DATE]: recordTypes.WorkRunDate,
    [C.WORK_AUTHOR]: recordTypes.WorkAuthor,
    [C.WORK_DESCRIPTION]: recordTypes.WorkDescription,
    [C.WORKS]: recordTypes.Works,
    [C.WORK_TASK]: recordTypes.WorkTask,

    [C.REQUEST]: recordTypes.WorkRequestTask,
    [C.DOCUMENT_POSITIONS]: recordTypes.DocumentPositions,
    [C.GOODS_AND_SERVICES]: recordTypes.GoodsAndServices,
    [C.TASK_CHANGES]: recordTypes.TaskChange,
  };

  return maps[entityName];
}

export function mapEntityNameToSchema(entityName) {
  const maps = {
    [C.ACCESS_AREAS]: schemes.AccessArea,
    [C.ACCESS_CARDS]: schemes.AccessCard,
    [C.ACCESS_LEVELS]: schemes.AccessLevel,
    [C.ACTION_CARDS]: schemes.ActionCard,
    [C.AGREEMENT_HISTORIES]: schemes.AgreementHistory,
    [C.AGREEMENT_SCHEMES]: schemes.AgreementScheme,
    [C.AGREEMENT_STATES]: schemes.AgreementState,
    [C.AGREEMENT_STEPS]: schemes.AgreementStep,
    [C.ATTACHMENTS]: schemes.Attachment,
    [C.BLACK_LIST]: schemes.BlackList,
    [C.CAR_MODELS]: schemes.CarModel,
    [C.COMMENTS]: schemes.Comment,
    [C.COMPANIES]: schemes.Company,
    [C.CONTRACT_TYPES]: schemes.ContractType,
    [C.CONTRACTS]: schemes.Contract,
    [C.CONTRACTOR_STATUSES]: schemes.ContractorStatus,
    [C.CONTRACTOR_TYPES]: schemes.ContractorType,
    [C.DEPARTMENTS]: schemes.Department,
    [C.DOCUMENT_CATEGORIES]: schemes.DocumentCategory,
    [C.DOCUMENT_KINDS]: schemes.DocumentKind,
    [C.DOCUMENT_STATES]: schemes.DocumentState,
    [C.DOCUMENT_TYPES]: schemes.DocumentType,
    [C.DOCUMENTS]: schemes.Document,
    [C.EMPLOYEES]: schemes.Employee,
    [C.FILES]: schemes.File,
    [C.HOUSINGS]: schemes.Housing,
    [C.ISSUE_REASONS]: schemes.IssueReason,
    [C.KHIMKI_REQUEST_TYPES]: schemes.KhimkiRequestType,
    [C.MANAGERS]: schemes.Manager,
    [C.MATERIAL_COMMODITY_VALUE_TYPES]: schemes.MaterialCommodityValueType,
    [C.NOTIFICATION_CATEGORIES]: schemes.NotificationCategory,
    [C.NOTIFICATION_TYPES]: schemes.NotificationType,
    [C.NOTIFICATIONS]: schemes.Notification,
    [C.OBJECT_STRUCTURE_TYPES]: schemes.ObjectStructure,
    [C.PARKING_TYPES]: schemes.ParkingType,
    [C.PARKINGS]: schemes.Parking,
    [C.PASS_TYPES]: schemes.PassType,
    [C.POSITIONS]: schemes.Position,
    [C.REQUEST_STATUSES]: schemes.RequestStatus,
    [C.REQUEST_TYPES]: schemes.RequestType,
    [C.REQUESTS]: schemes.Request,
    [C.REQUEST_PRIORITIES]: schemes.RequestPriority,
    [C.RESERVATION_TIMES]: schemes.ReservationTime,
    [C.ROLES]: schemes.Role,
    [C.SERVICES]: schemes.Service,
    [C.TIMES]: schemes.Time,
    [C.USERS]: schemes.User,
    [C.VEHICLE_TYPES]: schemes.VehicleType,
    [C.VISIT_TIMES]: schemes.VisitTime,
    [C.VISITOR_LOGS]: schemes.VisitorLog,
    [C.VOZDVIZHENKA_ACCESS_LEVELS]: schemes.VozdvizhenkaAccessLevel,
    [C.WORK_TYPES]: schemes.WorkType,
    [C.WORKING_TIMES]: schemes.WorkingTime,
    [C.CONTACTS]: schemes.Contact,
    [C.WORKING_HOURS]: schemes.WorkingHour,
    [C.JOB_DOINGS]: schemes.JobDoing,
    [C.PROJECTS]: schemes.Project,
    [C.ESTIMATE_ITEMS]: schemes.EstimateItem,
    [C.NUMBER]: schemes.TaskNumber,
    [C.FINISH_DATE]: schemes.TaskFinishDate,
    [C.REGISTRATION_DATE]: schemes.TaskRegistrationDate,
    [C.DESCRIPTION]: schemes.TaskDescription,
    [C.AUTHOR]: schemes.TaskAuthor,
    [C.RESPONSIBLE]: schemes.TaskResponsible,
    [C.STATUS]: schemes.TaskStatus,
    [C.COMPANY]: schemes.TaskCompany,
    [C.FILE]: schemes.TaskFile,

    [C.TASKS]: schemes.RequestTask,
    [C.TASK_STATUSES]: schemes.TaskStatus,
    [C.NAME]: schemes.TaskName,
    [C.COUNT_ATTACHMENTS]: schemes.CountAttachments,
    [C.TASK_TYPES]: schemes.TaskType,

    [C.WORK_RUN_DATE]: schemes.WorkRunDate,
    [C.WORK_AUTHOR]: schemes.WorkAuthor,
    [C.WORK_DESCRIPTION]: schemes.WorkDescription,
    [C.WORKS]: schemes.Works,
    [C.DOCUMENT_POSITIONS]: schemes.DocumentPositions,
    [C.GOODS_AND_SERVICES]: schemes.GoodsAndServices,
    [C.DOCUMENT_POSITION_STATES]: schemes.DocumentPositionStates,

    [C.TASK_CHANGES]: schemes.TaskChanges,
    [C.REQUEST_CHANGES]: schemes.RequestChanges,
  };

  return maps[entityName];
}
