/*
 *
 * RequestsPage14278
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import { TenantForm14278, TenantDxGrid14278 } from './TenantView';
import { CheckpointDxGrid14278 } from './CheckpointView';
import { ReceptionDxGrid14278 } from './ReceptionView';
import { AdministratorBCRequestsDxGrid14278 } from './AdministratorBCView/AdministratorBCRequestsDxGrid14278';


function RequestsPage14278(props) {
  return (
    <RequestsPage
      tenantForm={<TenantForm14278 />}
      tenantDxGrid={<TenantDxGrid14278 />}
      checkpointDxGrid={<CheckpointDxGrid14278 />}
      administratorBCDxGrid={<AdministratorBCRequestsDxGrid14278 />}
      receptionDxGrid={<ReceptionDxGrid14278 />}
      {...props}
    />
  );
}

export default RequestsPage14278;
