/**
*
* createMultiAutocompleteContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field } from 'redux-form/immutable';

import * as actionCreators from './actions';
import {
  makeSelectEntity, makeSelectIsInitialized,
  makeSelectLoadedOptions,
  makeSelectValues,
} from './selectors';
import MultiSelect from './MultiAutocomplete';
import { makeSelectMode, makeSelectRecord } from '../selectors';

function createMultiAutocompleteContainer(formName, fieldName) {
  class MultiAutocompleteContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    componentWillMount() {
      const {
        entityName,
        initialization,
        mode,
        record,
        isInitialized,
      } = this.props;

      if (mode === 'edit' && initialization && !isInitialized) {
        initialization({
          entityName,
          mode,
          record,
        });
      }
    }

    render() {
      const {
        clearErrorsOnChange,
        initialization,
        initialValues,
        isInitialized,
        ...restProps
      } = this.props; // eslint-disable-line no-unused-vars

      return (
        <Field
          {...restProps}
          component={MultiSelect}
        />
      );
    }
  }

  MultiAutocompleteContainer.propTypes = {
    clearErrorsOnChange: PropTypes.bool,
    entityName: PropTypes.string.isRequired,
    initialization: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    isInitialized: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    record: PropTypes.object,
  };

  MultiAutocompleteContainer.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    entity: makeSelectEntity(formName, fieldName),
    options: makeSelectLoadedOptions(formName, fieldName),
    values: makeSelectValues(formName, fieldName),
    mode: makeSelectMode(formName),
    record: makeSelectRecord(formName),
    isInitialized: makeSelectIsInitialized(formName, fieldName),
  });

  const mapDispatchToProps = (dispatch) => ({
    clearOptions: () => dispatch(actionCreators.clearOptions(formName, fieldName)),
    loadOptions: (searchQuery) => dispatch(actionCreators.loadOptions(formName, fieldName, searchQuery)),
    initialization: (params) => dispatch(actionCreators.initialization(formName, fieldName, params)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(MultiAutocompleteContainer);
}

export default createMultiAutocompleteContainer;
