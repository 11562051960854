/**
 *
 * getSagasConfig Function
 *
 */

import { account } from 'domain/typeConstants/accounts';

import requestsPageSagasConfig12429 from 'custom/12924/pages/Requests/sagasConfig';
import requestsPageSagasConfig14326 from 'custom/14326/pages/Requests/sagasConfig';
import requestsPageSagasConfig14691 from 'custom/14691/pages/Requests/sagasConfig';
import requestsPageSagasConfig14419 from 'custom/14419/pages/Requests/sagasConfig';
import requestsPageSagasConfig14140 from '../../custom/14140/pages/Requests/sagasConfig';

export default function getSagasConfig(accountId) {
  switch (accountId) {
    case account.ZHUKOV:
      return requestsPageSagasConfig12429;

    case account.BC_KHIMKI:
      return requestsPageSagasConfig14326;
    case account.BC_BERLINSKY_GENEVSKY:
      return requestsPageSagasConfig14140;
    case account.VOZDVIZHENKA:
      return requestsPageSagasConfig14691;
    case account.BP_FAKTORIYA:
      return requestsPageSagasConfig14419;

    default:
      return {};
  }
}
