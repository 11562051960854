/**
 *
 * Navigation reducer
 *
 *
 *
 */

import {fromJS} from 'immutable';

import {role} from 'domain/typeConstants/roles';
import {account} from 'domain/typeConstants/accounts';

import {
    DOCUMENTS_ID,
    DOCUMENTS_PATH,
    PARKINGS_ID,
    PARKINGS_PATH,
    REQUESTS_ID,
    REQUESTS_PATH,
    RULES_ID,
    RULES_PATH,
    NOTIFICATIONS_ID,
    NOTIFICATIONS_PATH,
    SERVICE_REQUESTS_ID,
    SERVICE_REQUESTS_PATH,
    SETTINGS_ID,
    SETTINGS_PATH,
    UNBINDING_ACCESS_CARD_ID,
    UNBINDING_ACCESS_CARD_PATH,
    VISITOR_LOGS_ID,
    VISITOR_LOGS_PATH,
    CONTACTS_PATH,
    COMPANIES_ID,
    COMPANIES_PATH,
    TASKS_PATH,
    TASK_ID, LOAD_NAVIGATION_ITEMS,
} from './constants';
import {getValueFromLocalStorage, setValueToLocalStorage} from "../../utils/localStorage";
import {GLOBAL_USER_KEY} from "../../global-constants";

const requestsAllowedRoles = [role.RECEPTION, role.APPLICANT, role.TENANT, role.CHECKPOINT, role.ASSIGNED,
    role.SERVICE_REQUESTS_MANAGER, role.MAKING_AGREEMENT_BC, role.CHECKPOINT_OPERATOR, role.PARKING_OPERATOR,
    role.SENIOR_PARKING_OPERATOR, role.UNLOADING_ZONE_OPERATOR, role.ADMINISTRATOR_BC, role.RESTRICTED_ADMINISTRATOR_BC, role.ADMIN_SKUD,
];

const requestAllViewRoles = [role.ADMINISTRATOR_BC, role.APPLICANT, role.SUPPLY_APPLICANT, role.TENANT, role.SERVICE_REQUESTS_MANAGER, role.ASSIGNED, role.ADMIN_SKUD]

const rulesAllowedRoles = [role.RECEPTION, role.TENANT, role.ADMINISTRATOR_BC];

const getActiveRequestsAllowedRoles = (accountId) => {
    let roles = [role.APPLICANT, role.SERVICE_REQUESTS_MANAGER, role.RECEPTION, role.CHECKPOINT, role.TENANT]
    if (accountId === account.BINOM) {
        roles.push(role.ASSIGNED)
    }
    return roles
}

const getPropertyAllowedRoles = (accountId) => {
    let roles = [role.RECEPTION, role.CHECKPOINT]
    if (accountId === account.BC_BELIYE_SADY) {
        roles.push(role.ADMINISTRATOR_BC)
        roles.push(role.TENANT)
    }
    return roles
}

const getRequestsOnVisitAllowedRoles = (accountId) => {
    let roles = [role.RECEPTION, role.MAKING_AGREEMENT_BC, role.ADMINISTRATOR_BC, role.RESTRICTED_ADMINISTRATOR_BC]
    if (accountId === account.BC_BELIYE_SADY) {
        roles = roles.filter(item => item !== role.ADMINISTRATOR_BC && item !== role.RECEPTION)
        roles.push(role.TENANT)
    }
    return roles
}

const getRequestsOnCarEntryAllowedRoles = (accountId) => {
    let roles = [role.RECEPTION, role.ADMINISTRATOR_BC, role.RESTRICTED_ADMINISTRATOR_BC]
    if (accountId === account.BC_BELIYE_SADY) {
        roles.push(role.TENANT)
        roles.push(role.CHECKPOINT)
    }
    return roles
}

const getRequestsAllAllowedRoles = (accountId, userRoles) => {
    let roles = requestAllViewRoles
    if (accountId === account.KRYLATSKI_HOLMY && userRoles.includes(role.CHECKPOINT)) {
        roles.push(role.CHECKPOINT)
    }
    if (accountId === account.BC_BELIYE_SADY && userRoles.includes(role.RECEPTION)) {
        roles.push(role.RECEPTION)
    }
    return roles
}

const getCurrentDayRequestsAllowedRoles = (accountId) => {
    let roles = requestsAllowedRoles.filter((item) => (item !== role.APPLICANT && item !== role.ASSIGNED
        && item !== role.SERVICE_REQUESTS_MANAGER && item !== role.ADMIN_SKUD))
    if (accountId === account.BC_BELIYE_SADY) {
        roles = roles.filter(item => item !== role.ADMINISTRATOR_BC && item !== role.RECEPTION)
    }
    return roles
}

const getPassMakingAllowedRoles = (accountId) => {
    if (accountId === account.BC_GORKIPARK_TOWER) {
        return [role.RECEPTION]
    } else if (accountId === account.BC_BELIYE_SADY) {
        return [role.ADMINISTRATOR_BC, role.TENANT]
    } else if (accountId === account.BC_4_VETRA) {
        return [role.ADMINISTRATOR_BC, role.RECEPTION]
    } else return []
}


// const getPropertyBringingOrRemovedAllowedRoles = (accountId) => {
//     if (accountId === account.KRYLATSKI_HOLMY) {
//         return [role.RECEPTION, role.ADMINISTRATOR_BC]
//     } else if (accountId === account.BC_BERLINSKY_GENEVSKY) {
//         return [role.ADMINISTRATOR_BC, role.RECEPTION, role.TENANT]
//     } else return []
// }

const items = (accountId, userRoles) => {
    if (accountId === undefined) {
        const user = getValueFromLocalStorage(GLOBAL_USER_KEY)
        accountId = user?.accountId || ''
        userRoles = user?.roles || []
    }

    return [
        {
            id: REQUESTS_ID,
            title: 'Заявки',
            link: {
                pathname: REQUESTS_PATH,
                search: userRoles.includes(role.CHECKPOINT) ? '?view=active' : '?view=all',
            },
            icon: 'feedback',
            order: 1,
            allowedRoles: requestsAllowedRoles,
        },
        {
            id: 'allRequests',
            title: accountId === account.BC_KHIMKI && userRoles.includes(role.ASSIGNED) ? 'Инженерные' : 'Все',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=all',
            },
            icon: 'date_range',
            parent: REQUESTS_ID,
            order: accountId === account.BC_BELIYE_SADY && userRoles.includes(role.ADMINISTRATOR_BC) ? 11 : 1,
            allowedRoles: getRequestsAllAllowedRoles(accountId, userRoles),
        },
        {
            id: 'activeRequests',
            title: 'Активные',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=active',
            },
            parent: REQUESTS_ID,
            order: 1,
            allowedRoles: getActiveRequestsAllowedRoles(accountId),
            disallowedAccounts: [account.BC_BELIYE_SADY],
        },
        {
            id: 'currentDayRequests',
            title: 'Ждем сегодня',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=today',
            },
            icon: 'event_available',
            parent: REQUESTS_ID,
            order: 2,
            allowedRoles: getCurrentDayRequestsAllowedRoles(accountId),
        },
        {
            id: 'requestsOnVisitA',
            title: 'На посещение А',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=onVisitA',
            },
            icon: 'people',
            parent: REQUESTS_ID,
            order: 3,
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC],
            allowedAccounts: [account.BC_BELIYE_SADY],
        },
        {
            id: 'requestsOnVisitB',
            title: 'На посещение Б',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=onVisitB',
            },
            icon: 'people',
            parent: REQUESTS_ID,
            order: 4,
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC],
            allowedAccounts: [account.BC_BELIYE_SADY],
        },
        {
            id: 'requestsOnVisit',
            title: 'На посещение',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=onVisit',
            },
            icon: 'people',
            parent: REQUESTS_ID,
            order: 4,
            allowedRoles: getRequestsOnVisitAllowedRoles(accountId),
            disallowedAccounts: [account.LEFORT, account.BC_KHIMKI, account.BP_FAKTORIYA],
        },
        {
            id: 'requestsOnCarEntry',
            title: 'На въезд авто',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=onCarEntry',
            },
            icon: 'directions_car',
            parent: REQUESTS_ID,
            order: 5,
            allowedRoles: getRequestsOnCarEntryAllowedRoles(accountId),
            disallowedAccounts: [account.LEFORT, account.BC_KHIMKI, account.VOZDVIZHENKA],
        },
        {
            id: 'propertyBringing',
            title: 'На Внос',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=propertyBringing',
            },
            parent: REQUESTS_ID,
            order: 6,
            // allowedRoles: getPropertyBringingOrRemovedAllowedRoles(accountId),
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC],
            allowedAccounts: [account.KRYLATSKI_HOLMY],
        },
        {
            id: 'propertyRemoved',
            title: 'На Вынос',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=propertyRemoved',
            },
            parent: REQUESTS_ID,
            order: 6,
            // allowedRoles: getPropertyBringingOrRemovedAllowedRoles(accountId),
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC],
            allowedAccounts: [account.KRYLATSKI_HOLMY],
        },
        {
            id: 'lefortGuestsRequests',
            title: 'Гостевая',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=guest',
            },
            parent: REQUESTS_ID,
            order: 6,
            allowedRoles: [role.CHECKPOINT],
            allowedAccounts: [account.LEFORT],
        },
        {
            id: 'movementTMC',
            title: 'Перемещение ТМЦ',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=movementTMC',
            },
            parent: REQUESTS_ID,
            order: 7,
            allowedRoles: [role.RECEPTION, role.CHECKPOINT],
            allowedAccounts: [account.LEFORT],
        },
        {
            id: 'executeToday',
            title: 'Выполнить сегодня',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=today',
            },
            parent: REQUESTS_ID,
            order: 8,
            allowedRoles: [role.ASSIGNED],
            disallowedAccounts: [account.BINOM],
        },
        {
            id: 'request_works',
            title: 'Все',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=works',
            },
            parent: REQUESTS_ID,
            order: 8,
            allowedRoles: [role.ASSIGNED],
            allowedAccounts: [account.BC_KHIMKI],
        },
        {
            id: 'agree',
            title: 'Согласовать',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=agree',
            },
            parent: REQUESTS_ID,
            order: 9,
            allowedRoles: [role.MAKING_AGREEMENT_BC],
        },
        {
            id: 'pass',
            title: 'Пропуск',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=pass',
            },
            parent: REQUESTS_ID,
            order: 10,
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC],
            allowedAccounts: [account.BC_BERLINSKY_GENEVSKY],
        },
        {
            id: 'property',
            title: 'Внос/Вынос',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=property',
            },
            parent: REQUESTS_ID,
            order: 10,
            allowedRoles: getPropertyAllowedRoles(accountId),
        },
        {
            id: 'create',
            title: accountId === account.BC_KHIMKI ? 'Создана' : 'Новые',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=create',
            },
            parent: REQUESTS_ID,
            order: 11,
            allowedRoles: accountId && accountId === account.KRYLATSKI_HOLMY ? [role.ADMINISTRATOR_BC] : [role.RECEPTION, role.ADMINISTRATOR_BC],
            disallowedAccounts: [account.BC_BELIYE_SADY]
        },
        {
            id: 'accessCard',
            title: 'Карты доступа',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=accessCard',
            },
            parent: REQUESTS_ID,
            order: 11,
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC],
            allowedAccounts: [account.BC_KHIMKI],
        },
        {
            id: 'dashboard',
            title: 'Дашборд',
            parent: REQUESTS_ID,
            link: {
                pathname: 'dashboard',
            },
            order: 12,
            allowedRoles: [role.ADMINISTRATOR_BC],
        },
        {
            id: 'serviceWorks',
            title: 'Работы',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=serviceWorks',
            },
            parent: REQUESTS_ID,
            order: 12,
            allowedRoles: [role.RECEPTION],
            allowedAccounts: [account.BC_KHIMKI],
        },
        {
            id: 'carryingOutWorks',
            title: 'Производство работ',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=carryingOutWorks',
            },
            parent: REQUESTS_ID,
            order: 12,
            allowedRoles: [role.RECEPTION, role.ADMINISTRATOR_BC, role.CHECKPOINT],
            allowedAccounts: [account.BP_FAKTORIYA],
        },
        {
            id: 'NotInstructedRequests',
            title: 'Непроинструктированные',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=notInstructed',
            },
            parent: REQUESTS_ID,
            order: 13,
            allowedRoles: [role.SERVICE_REQUESTS_MANAGER],
            allowedAccounts: [account.BP_FAKTORIYA]
        },
        {
            id: 'passMaking',
            title: 'Пост. пропуск',
            link: {
                pathname: REQUESTS_PATH,
                search: '?view=passMaking',
            },
            parent: REQUESTS_ID,
            order: accountId === account.BC_BELIYE_SADY && userRoles.includes(role.ADMINISTRATOR_BC) ? 1 : 11,
            allowedRoles: getPassMakingAllowedRoles(accountId),
            allowedAccounts: [account.BC_GORKIPARK_TOWER, account.BC_BELIYE_SADY, account.BC_4_VETRA],
        },

        /* -----------------------------------------
                         Tasks Page
          ----------------------------------------- */

        {
            id: TASK_ID,
            title: 'Задачи',
            link: {
                pathname: TASKS_PATH,
                search: '?view=allTask',
            },
            icon: 'feedback',
            order: 14,
            allowedRoles: [role.USER],
        },
        {
            id: 'allTasks',
            title: 'Все',
            link: {
                pathname: TASKS_PATH,
                search: '?view=allTask',
            },
            parent: TASK_ID,
            order: 15,
            allowedRoles: [role.USER],
        },
        {
            id: 'activeTasks',
            title: 'Активные',
            link: {
                pathname: TASKS_PATH,
                search: '?view=activeTask',
            },
            parent: TASK_ID,
            order: 16,
            allowedRoles: [role.USER],
        },
        {
            id: 'responsibleTasks',
            title: 'Я Ответственный',
            link: {
                pathname: TASKS_PATH,
                search: '?view=userResponsibleTasks',
            },
            parent: TASK_ID,
            order: 17,
            allowedRoles: [role.USER],
        },
        {
            id: 'authorTasks',
            title: 'Я Автор',
            link: {
                pathname: TASKS_PATH,
                search: '?view=userAuthorTasks',
            },
            parent: TASK_ID,
            order: 18,
            allowedRoles: [role.USER],
        },


        /* -----------------------------------------
                  Service Requests Page
        ----------------------------------------- */
        {
            id: SERVICE_REQUESTS_ID,
            title: 'Сервисные Заявки',
            link: {
                pathname: SERVICE_REQUESTS_PATH,
                search: '?view=all',
            },
            icon: 'assignment',
            order: 2,
            allowedRoles: [role.ADMINISTRATOR_BC, role.RESTRICTED_ADMINISTRATOR_BC],
            disallowedAccounts: [account.BC_BELIYE_SADY]
        },
        {
            id: 'serviceAllRequests',
            title: 'Все',
            link: {
                pathname: SERVICE_REQUESTS_PATH,
                search: '?view=all',
            },
            icon: 'date_range',
            parent: SERVICE_REQUESTS_ID,
            order: 1,
            allowedRoles: [role.ADMINISTRATOR_BC, role.RESTRICTED_ADMINISTRATOR_BC],
        },
        {
            id: 'serviceActiveRequests',
            title: 'Активные',
            link: {
                pathname: SERVICE_REQUESTS_PATH,
                search: '?view=active',
            },
            parent: SERVICE_REQUESTS_ID,
            order: 2,
            allowedRoles: [role.ADMINISTRATOR_BC, role.RESTRICTED_ADMINISTRATOR_BC],
        },
        {
            id: 'serviceManagerRequests',
            title: 'Менеджер',
            link: {
                pathname: SERVICE_REQUESTS_PATH,
                search: '?view=manager',
            },
            parent: SERVICE_REQUESTS_ID,
            order: 3,
            allowedRoles: [role.ADMINISTRATOR_BC],
            allowedAccounts: [account.BC_KHIMKI],
        },
        /* -----------------------------------------
                        Documents Page
        ----------------------------------------- */
        {
            id: DOCUMENTS_ID,
            title: 'Документы',
            link: {
                pathname: DOCUMENTS_PATH,
                search: '?view=allDocuments',
            },
            icon: 'chrome_reader_mode',
            order: 4,
            allowedRoles: [role.SIGHTING],
        },
        {
            id: 'allDocuments',
            title: 'Все',
            link: {
                pathname: DOCUMENTS_PATH,
                search: '?view=allDocuments',
            },
            parent: DOCUMENTS_ID,
            order: 1,
            allowedRoles: [role.SIGHTING],
        },
        {
            id: 'sighting',
            title: 'Ждут моей визы',
            link: {
                pathname: DOCUMENTS_PATH,
                search: '?view=forMyVisa',
            },
            parent: DOCUMENTS_ID,
            order: 2,
            allowedRoles: [role.SIGHTING],
        },
        {
            id: 'resultOfAgreement',
            title: 'Результат согласования',
            link: {
                pathname: DOCUMENTS_PATH,
                search: '?view=resultOfAgreement',
            },
            parent: DOCUMENTS_ID,
            order: 3,
            allowedRoles: [role.SIGHTING],
        },
        /* -----------------------------------------
                        Rules Page
        ----------------------------------------- */
        {
            id: RULES_ID,
            title: 'Информация',
            link: {
                pathname: RULES_PATH,
                search: '?view=allDocumentsRules',
            },
            icon: 'library_books',
            order: 5,
            allowedRoles: rulesAllowedRoles,
        },
        {
            id: 'allDocumentsRules',
            title: 'Документы',
            link: {
                pathname: RULES_PATH,
                search: '?view=allDocumentsRules',
            },
            parent: RULES_ID,
            order: 1,
            allowedRoles: rulesAllowedRoles,
        },
        {
            id: 'allNews',
            title: 'Новости',
            link: {
                pathname: RULES_PATH,
                search: '?view=allNews',
            },
            parent: RULES_ID,
            order: 2,
            allowedRoles: rulesAllowedRoles,
            disallowedAccounts: [account.KRYLATSKI_HOLMY],
        },
        {
            id: 'allContacts',
            title: 'Контакты',
            link: {
                pathname: CONTACTS_PATH,
                search: '?view=allContacts',
            },
            parent: RULES_ID,
            order: 3,
            allowedRoles: rulesAllowedRoles,
            allowedAccounts: [account.BC_KHIMKI],
        },

        /* -----------------------------------------
                Company Page
      ----------------------------------------- */
        {
            id: COMPANIES_ID,
            title: 'Контрагенты',
            link: {
                pathname: COMPANIES_PATH,
                search: '?view=all',
            },
            icon: 'contact_page',
            order: 5.1,
            allowedRoles: [role.ADMINISTRATOR_BC],
        },
        {
            id: 'allCompanies',
            title: 'Все',
            link: {
                pathname: COMPANIES_PATH,
                search: '?view=allCompanies',
            },
            parent: COMPANIES_ID,
            order: 1,
            allowedRoles: [role.ADMINISTRATOR_BC],
        },
        /* -----------------------------------------
                       Parkings Page
        ----------------------------------------- */
        {
            id: PARKINGS_ID,
            title: 'Парковки',
            icon: 'drive_eta',
            order: 6,
            allowedRoles: [role.CHECKPOINT, role.CHECKPOINT_OPERATOR, role.PARKING_OPERATOR, role.SENIOR_PARKING_OPERATOR,
                role.UNLOADING_ZONE_OPERATOR,
            ],
            link: {
                pathname: PARKINGS_PATH,
                search: '?view=all',
            },
        },
        /* -----------------------------------------
                        Visitor Logs Page
        ----------------------------------------- */
        {
            id: VISITOR_LOGS_ID,
            title: 'Журнал посещений',
            link: {
                pathname: VISITOR_LOGS_PATH,
            },
            icon: 'event_note',
            order: 7,
            allowedRoles: [role.RECEPTION],
            allowedAccounts: [account.COMCITY],
        },
        {
            id: 'allVisitorLogs',
            title: 'Все',
            link: {
                pathname: VISITOR_LOGS_PATH,
                search: '?view=all',
            },
            parent: VISITOR_LOGS_ID,
            order: 1,
            allowedRoles: [role.RECEPTION],
            allowedAccounts: [account.COMCITY],
        },
        /* -----------------------------------------
                Unbinding Access Card Page
        ----------------------------------------- */
        {
            id: UNBINDING_ACCESS_CARD_ID,
            title: 'Отвязка карты доступа',
            link: {
                pathname: UNBINDING_ACCESS_CARD_PATH,
            },
            icon: 'credit_card',
            order: 8,
            allowedRoles: [role.RECEPTION],
            allowedAccounts: [account.COMCITY],
        },


        {
            id: 'supplyRequests',
            title: 'Снабжение',
            link: {
                pathname: "/supplyRequests",
                search: '?view=allSupplyRequests',
            },
            icon: 'library_books',
            order: 80,
            allowedRoles: [role.SUPPLY_APPLICANT],
        },
        {
            id: 'allSupplyRequests',
            title: 'Заявки',
            link: {
                pathname: "/supplyRequests",
                search: '?view=allSupplyRequests',
            },
            parent: "supplyRequests",
            order: 81,
            allowedRoles: [role.SUPPLY_APPLICANT],
        },
        {
            id: 'activeSupplyRequests',
            title: 'Активные',
            link: {
                pathname: "/supplyRequests",
                search: '?view=activeSupplyRequests',
            },
            parent: "supplyRequests",
            order: 82,
            allowedRoles: [role.SUPPLY_APPLICANT],
        },
        {
            id: 'allSupplyPositions',
            title: 'Позиции',
            link: {
                pathname: "/supplyPositions",
                search: '?view=allSupplyPositions',
            },
            parent: "supplyRequests",
            order: 83,
            allowedRoles: [role.SUPPLY_APPLICANT],
        },

        /* -----------------------------------------
                Notifications Page
        ----------------------------------------- */
        {
            id: NOTIFICATIONS_ID,
            title: 'История оповещений',
            link: {
                pathname: NOTIFICATIONS_PATH,
            },
            icon: 'credit_card',
            order: 98,
            disallowedAccounts: [account.KRYLATSKI_HOLMY],
        },
        /* -----------------------------------------
                        Settings Page
        ----------------------------------------- */
        {
            id: SETTINGS_ID,
            title: 'Настройки',
            icon: 'settings',
            order: 99,
            allowedRoles: [role.SYSTEM_ADMIN],
            link: {
                pathname: SETTINGS_PATH,
                search: '?view=users',
            },
        },
        {
            id: 'users',
            title: 'Пользователи',
            link: {
                pathname: SETTINGS_PATH,
                search: '?view=users',
            },
            parent: SETTINGS_ID,
            order: 1,
            allowedRoles: [role.SYSTEM_ADMIN],
        },
    ];
}

const initialState = (accountId, roles) => fromJS({
    items: itemsByInstance(items(accountId, roles), accountId)
        .reduce((acc, item) => ({
            [item.id]: item,
            ...acc,
        }), {}),
});

const updateNavigation = (accountId, roles) => {
    return initialState(accountId, roles)
}

function navigationReducer(state = initialState(), action) {
    switch (action.type) {
        case LOAD_NAVIGATION_ITEMS:
            return updateNavigation(action.payload.accountId, action.payload.roles)
        default:
            return state;
    }
}

function itemsByInstance(defaultItems, accountId) {
    let customItems = defaultItems;
    const idRules = customItems.findIndex((item) => item.id === 'rules');
    const idCreate = customItems.findIndex((item) => item.id === 'create');

    switch (accountId) {
        case account.VOZDVIZHENKA:
            customItems[idRules].allowedRoles = [role.ADMINISTRATOR_BC];
            customItems[idCreate].disallowedRoles = [role.RECEPTION];
            return customItems;
        default:
            return defaultItems;
    }
}

export default navigationReducer;
