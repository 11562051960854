/*
 *
 * TenantForm12924
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import { ACCEPTED, CREATED } from 'domain/Data/RequestStatus/constants';
import { ON_VISIT, SERVICE } from 'domain/Data/RequestType/constants';
import { visitorFullNameLabel } from 'pages/Requests/common/fields';

class TenantForm12924 extends TenantForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    const visitorFullNameIndex = fields.findIndex((field) => field.name === 'visitorFullName');
    fields[visitorFullNameIndex] = {
      name: 'visitorFullName',
      label: visitorFullNameLabel,
      visible: (values) => !values.isGroupRequest && values.type !== SERVICE,
      autoFocus: (currentValues, initialValues, mode) => mode === 'add',
      required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
      rowBreakBefore: true,
    };

    fields.splice(0, 0, {
      name: 'accessCard',
      visible: false,
      initialValue: () => null,
    });

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].calculatedValue = (values, mode) => {
      if (mode === 'add' || mode === 'copy') {
        return values.type === ON_VISIT ? ACCEPTED : CREATED;
      }
      return values.status;
    };

    return fields;
  }
}

export default TenantForm12924;
