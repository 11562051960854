/**
 *
 * RequestsPage15776
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import {  ServiceRequestsManagerForm15776} from './ServiceRequestsManagerView';
import {  AdministratorBCRequestsForm15776} from './AdministratorBCView';
import { ApplicantForm15776 } from './ApplicantView';


function RequestsPage15776(props) {

  return (
    <RequestsPage
      administratorBCForm={<AdministratorBCRequestsForm15776 />}
      administratorBCServiceForm={<ServiceRequestsManagerForm15776 />}
      serviceRequestsManagerForm={<ServiceRequestsManagerForm15776 />}
      applicantForm={<ApplicantForm15776/>}
      {...props}
    />
  );
}

export default RequestsPage15776;
