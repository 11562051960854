/**
*
* MaskField
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  root: {
    border: 0,
    margin: -2,
    display: 'block',
    minWidth: 0,
    flexGrow: 1,
    boxSizing: 'content-box',
    verticalAlign: 'middle',
    fontSize: '1.5rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    width: '100%',
    height: '100%',
    outline: 'none',

  },
  label: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  },

  inputRoot: {
    width: '100%'
  },

  helperText: {
    margin: 0,
    fontSize: '0.75rem',
    textAlign: 'left',
    marginTop: '8px',
    minHeight: '1em',
    lineHeight: '1em',
  },

  error: {
    color: '#d50000'
  },
};

class MaskField extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      autoComplete,
      classes,
      helperText,
      input,
      label,
      meta: { error },
      readOnly,
      required,
      name,
      disabled,
      ...restProps
    } = this.props;

    return (
      <div>
        <div className={classNames(classes.label, error && classes.error)}>
          {required ? `${label} *` : label}
        </div>
        <div className={classes.inputRoot}>
          <input
            className={classes.root}
            name={name}
            normalize={this.normalize}
            disabled={disabled}
            required={required}
            {...input}
            type="text"
          />
          {helperText && <div className={classNames(classes.label, error && classes.error)}>
            {helperText}
          </div>}
        </div>
      </div>
    )

  }
}

MaskField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  classes: PropTypes.object.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.object,
  required: PropTypes.bool,
};

export default withStyles(styles)(MaskField);
