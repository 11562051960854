/**
 *
 * CheckpointForm14326
 *
 */

import { CheckpointForm } from 'pages/Requests/CheckpointView';
import {
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_VISIT,
} from 'domain/Data/RequestType/constants';
import { formField } from 'domain/typeConstants/form';
import { visitorFullNameLabel } from '../common/fields';

export class CheckpointForm14326 extends CheckpointForm {
  
  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'number',
        label: '№',
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: 'requestStatuses',
        orderBy: 'name',
      },
      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        entityName: 'requestTypes',
        orderBy: 'order',
      },
      {
        name: 'longterm',
        type: formField.CHECKBOX,
        label: 'Долгосрочная',
      },
      {
        name: 'visitDate',
        type: formField.DATE,
        label: 'Дата',
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
      },
      {
        name: 'expirationDate',
        type: formField.DATE,
        label: 'Дата окончания',
        visible: (values) => values.longterm,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        rowBreakBefore: true,
      },
      {
        name: 'company',
        type: formField.SELECT,
        label: 'Арендатор',
        entityName: 'companies',
        orderBy: 'name',
        numberInQueueFromEnd: 1,
        rowBreakAfter: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING,
      },
      {
        name: 'passNumber',
        label: '№ пропуска',
        visible: (values) => values.type === ON_VISIT ||
          values.type === ON_PASS_MAKING,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'parking',
        type: formField.SELECT,
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: true
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
      },
    ];
  }
}
