/**
 * AgreementDialog selectors
 */

import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import * as dataSelectors from 'domain/Data/selectors';
import { mapEntityNameToSchema } from 'domain/Data/utils';

import { DIALOG_NAME } from './constants';

const makeSelectDomain = () => (state) => state.get(DIALOG_NAME);

const makeSelectIsOpen = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('open')
);

const makeSelectRowIds = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('id')
);

const makeSelectDataTable = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('dataTable')
);

const makeSelectManagerIds = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('managerFieldIds').toJS(),
);

const makeSelectManagerData = (entityName) => createSelector(
  makeSelectManagerIds(),
  dataSelectors.makeSelectEntities(),
  (ids, entities) => {
    const data = selectData(entityName, ids, entities);

    if (Array.isArray(data)) {
      return data.filter((manager) => manager.isMatching).sort(byField("name"));
    }

    return [];
  }
);

function byField(field) {
  return (a, b) => a[field] > b[field] ? 1 : -1;
}

function selectData(entityName, ids, entities) {
  const denormalizedData = denormalize(
    {
      [entityName]: ids,
    },
    {
      [entityName]: [mapEntityNameToSchema(entityName)],
    },
    entities,
  );

  return Object.values(denormalizedData[entityName]);
}

const makeSelectManagersValues = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('managers'),
);

export default makeSelectDomain;
export {
  makeSelectIsOpen,
  makeSelectRowIds,
  makeSelectDomain,
  makeSelectManagerData,
  makeSelectManagersValues,
  makeSelectDataTable,
};
