import React from 'react';
import PropTypes from 'prop-types';

import UnloadingZoneOperatorView, {
  UnloadingZoneOperatorDxGrid,
  UnloadingZoneOperatorDxGridContainer,
  UnloadingZoneOperatorForm,
  UnloadingZoneOperatorFormDialog,
  UnloadingZoneOperatorMobileGrid,
} from '../UnloadingZoneOperator';

const getDxGrid = (DxGrid) => (
  <UnloadingZoneOperatorDxGridContainer component={DxGrid || <UnloadingZoneOperatorDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <UnloadingZoneOperatorDxGridContainer component={MobileGrid || <UnloadingZoneOperatorMobileGrid />} />
);

const getDataTableView = ({ breakpoint, unloadingZoneOperatorDxGrid, unloadingZoneOperatorMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(unloadingZoneOperatorMobileGrid);
  }

  return getDxGrid(unloadingZoneOperatorDxGrid);
};

const getFormDialog = (Form) => (
  <UnloadingZoneOperatorFormDialog form={Form || <UnloadingZoneOperatorForm />} />
);

export const getUnloadingZoneOperatorView = (props) => {
  const {
    breakpoint,
    unloadingZoneOperatorDxGrid,
    unloadingZoneOperatorMobileGrid,
    unloadingZoneOperatorForm,
    unloadingZoneOperatorFormDialog,
    unloadingZoneOperatorView,
    viewName,
    viewStyles,
  } = props;

  if (unloadingZoneOperatorView) return unloadingZoneOperatorView;

  return (
    <UnloadingZoneOperatorView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        unloadingZoneOperatorDxGrid,
        unloadingZoneOperatorMobileGrid,
      })}
      formDialog={unloadingZoneOperatorFormDialog || getFormDialog(unloadingZoneOperatorForm)}
    />
  );
};

getUnloadingZoneOperatorView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  unloadingZoneOperatorDxGrid: PropTypes.node,
  unloadingZoneOperatorMobileGrid: PropTypes.node,
  unloadingZoneOperatorForm: PropTypes.node,
  unloadingZoneOperatorFormDialog: PropTypes.node,
  unloadingZoneOperatorView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
