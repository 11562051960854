/*
 *
 * getViewConfig Function
 *
 */

import {
    getReceptionView,
} from './getRoleView';

import getSagasConfig from './getSagasConfig';

import createReceptionViewSaga from './ReceptionView/sagas';

const defaultViewConfig = {
    createComponent: getReceptionView,
    createSaga: (sagaConfig) => createReceptionViewSaga(sagaConfig.receptionViewSaga),
};

export function createViewComponent(userRoles, props) {
    return defaultViewConfig.createComponent(props);
}

export function createViewSaga(userRoles, accountId) {
    return defaultViewConfig.createSaga(getSagasConfig(accountId));
}
