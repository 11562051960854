/**
 *
 * MakingAgreementBCView constants
 *
 */

export const MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID = 'MakingAgreementBCTableGrid';
export const MAKING_AGREEMENT_BC_REQUESTS_FORM = 'MakingAgreementBCForm';
export const MAKING_AGREEMENT_BC_REQUESTS_FORM_DIALOG = 'MakingAgreementBCFormDialog';
export const REJECT_FORM = 'MakingAgreementBCRejectForm';
export const REJECT_FORM_DIALOG = 'MakingAgreementBCRejectFormDialog';

export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/RequestsPage/MakingAgreementBCView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/MakingAgreementBCView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RequestsPage/MakingAgreementBCView/EXECUTE_EDIT_ACTION';
export const EXECUTE_REJECT_ACTION = 'claris-vnext/RequestsPage/MakingAgreementBCView/EXECUTE_REJECT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/MakingAgreementBCView/EXECUTE_VIEW_ACTION';
