/**
  *
  *
  *  ReceptionView sagas
  *
  */

import { fork } from 'redux-saga/effects';
import createServiceRequestsManagerViewSaga from 'pages/Requests/ServiceRequestsManagerView/sagas';

export default function* rootSaga() {
  yield fork(createServiceRequestsManagerViewSaga());
}
