/*
 *
 * loginReducer reducer
 *
 */

import { Map } from 'immutable';
import { SET_MESSAGE_FORM } from "./constants";

const initialState = new Map({});

function loginReducer(state = initialState, { type, payload }) {
          switch (type) {
                    case SET_MESSAGE_FORM:
                              return state
                                        .setIn(['message'], payload.message)
                                        .setIn(['typeMessage'], payload.typeMessage);
                                        default:
                              return state;
          }
}

export default loginReducer;