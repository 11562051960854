/**
 *
 * DashboardView
 *
 * 
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { statusAnnotation, typeAnnotation } from './constants';
import { REQUESTS_PATH } from 'domain/Navigation/constants';
import PieChart from './PieChart/PieChartCreator';
import BarChart from './BarChart/BarChartCreator';


class DashboardView extends React.Component {

  render() {
    const { accountId } = this.props
    return (
      <Grid container spacing={16}>
        <Grid item>
          <PieChart
            annotation={statusAnnotation(accountId)}
            title="Статус"
            entity={"status"}
            field={"statusName"}
            link={{ pathname: REQUESTS_PATH, search: '?view=all' }}
            {...this.props}
          />
        </Grid>
        <Grid item>
          <PieChart
            annotation={typeAnnotation(accountId)}
            title="Тип"
            entity={"type"}
            field={"typeShortName"}
            link={{ pathname: REQUESTS_PATH, search: '?view=all' }}
            {...this.props}
          />
        </Grid>
        <Grid item>
          <BarChart
            title="Компания"
            entity={"company"}
            field={"company_name"}
            link={{ pathname: REQUESTS_PATH, search: '?view=all' }}
            {...this.props}
          />
        </Grid>
      </Grid>
    );
  }
}

DashboardView.defaultProps = {
  viewStyles: {},
};

export default DashboardView;
