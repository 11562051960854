/**
 *
 * AssignedDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction } from 'domain/typeConstants/action';
import * as actionCreators from './actions';

class AssignedDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => this.props.executeEditAction(record.id);

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case requestAction.ADD:
        this.props.executeAddAction();
        break;

      case requestAction.EDIT:
        this.props.executeEditAction(ids[0]);
        break;

      case requestAction.COPY:
        this.props.executeCopyAction(ids[0]);
        break;

      case requestAction.ACCEPT:
        this.props.executeAcceptAction(ids);
        break;

      case requestAction.REJECT:
        this.props.executeRejectAction(ids);
        break;

      case requestAction.COMPLETE:
        this.props.executeCompleteAction(ids[0]);
        break;

      case requestAction.VIEW:
        this.props.executeViewAction(ids[0]);
        break;

      case requestAction.CLOSE:
        this.props.executeCloseAction(ids);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName } = this.props;
    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
    });
  }
}

AssignedDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  executeAddAction: PropTypes.func.isRequired,
  executeEditAction: PropTypes.func.isRequired,
  executeCopyAction: PropTypes.func.isRequired,
  executeAcceptAction: PropTypes.func.isRequired,
  executeRejectAction: PropTypes.func.isRequired,
  executeCompleteAction: PropTypes.func.isRequired,
  executeViewAction: PropTypes.func.isRequired,
  executeCloseAction: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(AssignedDxGridContainer);
