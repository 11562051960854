import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import ApplicantView, {
  ApplicantDxGrid,
  ApplicantDxGridContainer,
  ApplicantForm,
  ApplicantFormDialog,
  ApplicantMobileGrid,
} from '../ApplicantView';

const getDxGrid = (DxGrid) => (
  <ApplicantDxGridContainer component={DxGrid || <ApplicantDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <ApplicantDxGridContainer component={MobileGrid || <ApplicantMobileGrid />} />
);

const getFormDialog = (form) => (
  <ApplicantFormDialog form={form || <ApplicantForm />} />
);

const getDataTableView = ({ breakpoint, applicantDataTable, applicantDxGrid, applicantMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(applicantMobileGrid);
  }
  if (isUndefined(applicantDxGrid) || applicantDxGrid) {
    return getDxGrid(applicantDxGrid);
  }

  return null
};

export const getApplicantView = (props) => {
  const {
    applicantDataTable,
    applicantDxGrid,
    applicantForm,
    applicantFormDialog,
    applicantMobileGrid,
    applicantView,
    breakpoint,
    viewName,
    viewStyles,
  } = props;

  if (applicantView) return applicantView;

  return (
    <ApplicantView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        applicantDataTable,
        applicantDxGrid,
        applicantMobileGrid,
      })}
      formDialog={applicantFormDialog || getFormDialog(applicantForm)}
    />
  );
};

getApplicantView.propTypes = {
  applicantDataTable: PropTypes.node,
  applicantDxGrid: PropTypes.node,
  applicantForm: PropTypes.node,
  applicantFormDialog: PropTypes.node,
  applicantMobileGrid: PropTypes.node,
  applicantView: PropTypes.node,
  breakpoint: PropTypes.object.isRequired,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
