/*
 *
 * snackbarReducer reducer
 *
 */

import { Map } from 'immutable';
import { OPEN, CLOSE } from './constants';

const initialState = new Map({
  open: false,
  message: null,
  autoHideDuration: null,
});

const DEFAULT_AUTO_HIDE_DURATION = 5000;

function snackbarReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN:
      return state
        .set('open', true)
        .set('message', payload.message)
        .set('autoHideDuration', payload.options.autoHideDuration || DEFAULT_AUTO_HIDE_DURATION);

    case CLOSE:
      return state
        .set('open', false)
        .set('message', null)
        .set('autoHideDuration', null);

    default:
      return state;
  }
}


export default snackbarReducer;
