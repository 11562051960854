import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

import Notifications from '@material-ui/icons/Notifications';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {

  },
  badge: {
    backgroundColor: red[800],
  },
};

export class NotificationsButton extends React.PureComponent {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  renderIcon() {
    const { classes, unreadCount } = this.props;
    if (unreadCount) {
      return (
        <Badge classes={{ colorSecondary: classes.badge }} badgeContent={unreadCount} color="secondary">
          <Notifications />
        </Badge>
      );
    }
    return <Notifications />;
  }

  render() {
    const { classes, className } = this.props;
    return (
      <IconButton
        className={classnames({ [classes.root]: true, [className]: !!className })}
        color="inherit"
        onClick={this.handleClick}
      >
        {
          this.renderIcon()
        }
      </IconButton>
    );
  }
}

NotificationsButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  unreadCount: PropTypes.number,
};

NotificationsButton.defaultProps = {
  unreadCount: 0,
};

export default withStyles(styles)(NotificationsButton);
