/*
 *
 * TaskFormContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectParentId, } from 'containers/AdvancedFormDialog/selectors'

import TaskForm from './TaskForm';
import { USER_TASK_FORM_DIALOG } from './constants';

class TaskFormContainer extends React.PureComponent {
    render() {
        return (
            <TaskForm  {...this.props} />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    parentId: makeSelectParentId(USER_TASK_FORM_DIALOG),
});

export default connect(mapStateToProps)(TaskFormContainer);
