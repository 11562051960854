/**
 *
 * TextField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InitialTextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {checkNumber} from "utils/validators";

const styles = {
    root: {
        color: 'red',
    },
};

class TextField extends React.PureComponent {
    render() {
        const {
            classes,
            classNames,
            helperText,
            input,
            input: {value, onChange, ...restInputProps},
            label,
            meta: {touched, error, submitFailed},
            readOnly,
            required,
            tooltip,
            type,
            calculateFieldsValues,
            mask,
            ...restProps
        } = this.props;

        const handleChange = (e) => {
            if (!mask) {
                onChange(e.target.value)
            } else {
                if (mask === 'number' && (checkNumber(e.target.value) || e.target.value === '')) {
                    onChange(e.target.value)
                }
            }
        }

        return (
            tooltip
                ? (
                    <Tooltip title={tooltip} placement="bottom-start" id={`${input.name}`}>
                        <InitialTextField
                            margin="none"
                            label={required ? `${label} *` : label}
                            type={type}
                            error={(touched || submitFailed) && !!error}
                            helperText={(touched || submitFailed) && error ? error : helperText}
                            inputProps={{readOnly, autoComplete: 'new-password'}}
                            // rows={restProps.multiline ? 3 : 1}
                            FormHelperTextProps={{
                                classes: {
                                    root: classNames.helperText,
                                },
                            }}
                            {...restInputProps}
                            fullWidth={true}
                            multiline={restProps.multiline}
                            disabled={restProps.disabled}
                            onChange={handleChange}
                            value={value}
                        />
                    </Tooltip>
                ) : (
                    <InitialTextField
                        margin="none"
                        label={required ? `${label} *` : label}
                        type={type}
                        error={(touched || submitFailed) && !!error}
                        helperText={(touched || submitFailed) && error ? error : helperText}
                        inputProps={{readOnly, autoComplete: 'new-password'}}
                        // rows={restProps.multiline ? 3 : 1}
                        FormHelperTextProps={{
                            classes: {
                                root: classNames.helperText,
                            },
                        }}
                        {...restInputProps}
                        fullWidth={true}
                        multiline={restProps.multiline}
                        disabled={restProps.disabled}
                        onChange={handleChange}
                        value={value}
                    />
                )
        );
    }
}

const StyledTextField = styled(TextField)`
  input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset;
  }

  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px white inset;
  }
`;

TextField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    classes: PropTypes.object.isRequired,
    classNames: PropTypes.object,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    meta: PropTypes.object,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    type: PropTypes.string,
};

TextField.defaultProps = {
    classNames: {},
};

export default withStyles(styles)(StyledTextField);
