/*
 *
 * SightingFormDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import {
  executeRunDialogAction,
  executeAgreeDialogAction,
  executeDisagreeDialogAction,
} from './actions';

import SightingFormDialog from './SightingFormDialog';
import { SIGHTING_DOCUMENTS_DX_GRID } from './constants';
import { makeSelectAgreementStep, makeSelectCurrentRecord, makeSelectSelection, } from 'containers/AdvancedDataTable/selectors';

class SightingFormDialogContainer extends React.Component {
  render() {
    const { viewName } = this.props;
    return (
      <div>
        <SightingFormDialog
          onRun={this.props.run}
          onAgree={this.props.agree}
          onDisagree={this.props.disagree}
          viewName={viewName}
          {...this.props}
        />
      </div>
    );
  }
}

SightingFormDialogContainer.propTypes = {
  run: PropTypes.func.isRequired,
  agree: PropTypes.func.isRequired,
  disagree: PropTypes.func.isRequired,
  viewName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentRecord: makeSelectCurrentRecord(SIGHTING_DOCUMENTS_DX_GRID),
  selection: makeSelectSelection(SIGHTING_DOCUMENTS_DX_GRID),
  agreementStep: makeSelectAgreementStep(SIGHTING_DOCUMENTS_DX_GRID),
});

function mapDispatchToProps(dispatch) {
  return {
    run: (dialogName, formName) => dispatch(executeRunDialogAction(dialogName, formName)),
    agree: (dialogName, formName) => dispatch(executeAgreeDialogAction(dialogName, formName)),
    disagree: (dialogName, formName) => dispatch(executeDisagreeDialogAction(dialogName, formName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SightingFormDialogContainer);