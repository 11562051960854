/**
 *
 * ActionMenu12970 Items
 *
 */

import React from 'react';

import CallReceived from '@material-ui/icons/CallReceived';
import CallMade from '@material-ui/icons/CallMade';

import { requestAction } from 'domain/typeConstants/action';
import { requestStatus } from 'domain/typeConstants/status';

import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';
import { FOR_UNLOADING, PARKING_AT_MAIN_ENTRANCE } from '../../../domain/Data/RequestType/constants';

const parkingOperatorRequestTypeCondition = (request) => (![ON_CAR_ENTRY, PARKING_AT_MAIN_ENTRANCE].includes(request.type.id));
const unloadingZoneOperatorRequestTypeCondition = (request) => (![FOR_UNLOADING].includes(request.type.id));

export const arrivedActionItem = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  hidden: (itemMenuRecord, entities) => {
    if (itemMenuRecord.length === 1) {
      const row = entities[0];
      if (!row.status) return false;
      return ((row.status.name !== requestStatus.ON_TERRITORY && row.status.name !== requestStatus.ACCEPTED &&
        row.status.name === requestStatus.CLOSED) || row.status.name === requestStatus.CREATED ||
        row.status.name === requestStatus.REJECTED);
    }
    return true;
  },
};

export const parkingOperatorArrivedActionItem = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  hidden: (itemMenuRecord, entities) => {
    if (itemMenuRecord.length === 1) {
      const row = entities[0];
      const requestStatusCondition = (request) => (![requestStatus.ACCEPTED, requestStatus.ON_TERRITORY].includes(request.status.name));
      if (!row.status) return false;

      return (requestStatusCondition(row) || parkingOperatorRequestTypeCondition(row));
    }
    return true;
  },
};

export const unloadingZoneOperatorArrivedActionItem = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  hidden: (itemMenuRecord, entities) => {
    if (itemMenuRecord.length === 1) {
      const row = entities[0];
      const requestStatusCondition = (request) => (![requestStatus.ACCEPTED, requestStatus.ON_TERRITORY].includes(request.status.name));
      if (!row.status) return false;

      return (requestStatusCondition(row) || unloadingZoneOperatorRequestTypeCondition(row));
    }
    return true;
  },
};

export const departedActionItem = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  hidden: (itemMenuRecord, entities) => {
    if (itemMenuRecord.length === 1) {
      const row = entities[0];
      if (!row.status) return false;
      return ((row.status.name !== requestStatus.ON_TERRITORY && row.status.name === requestStatus.CLOSED)
        || row.status.name === requestStatus.CREATED);
    }
    return true;
  },
};

export const parkingOperatorDepartedActionItem = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  hidden: (itemMenuRecord, entities) => {
    if (itemMenuRecord.length === 1) {
      const row = entities[0];
      const requestStatusCondition = (request) => (![requestStatus.ON_TERRITORY].includes(request.status.name));
      if (!row.status) return false;

      return (requestStatusCondition(row) || parkingOperatorRequestTypeCondition(row));
    }
    return true;
  },
};

export const unloadingZoneOperatorDepartedActionItem = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  hidden: (itemMenuRecord, entities) => {
    if (itemMenuRecord.length === 1) {
      const row = entities[0];
      const requestStatusCondition = (request) => (![requestStatus.ON_TERRITORY].includes(request.status.name));
      if (!row.status) return false;

      return (requestStatusCondition(row) || unloadingZoneOperatorRequestTypeCondition(row));
    }
    return true;
  },
};
