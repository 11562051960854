/**
 *
 * MultiAutocompleteCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createMultiAutocompleteContainer from './createMultiAutocompleteContainer';

class MultiAutocompleteCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleUpdateComponent = () => {
    this.forceUpdate();
  };

  render() {
    const AutocompleteContainer = createMultiAutocompleteContainer(this.context.advancedForm, this.props.name);
    return <AutocompleteContainer onUpdateComponent={this.handleUpdateComponent} {...this.props} />;
  }
}

MultiAutocompleteCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

MultiAutocompleteCreator.propTypes = {
  name: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
};

export default MultiAutocompleteCreator;
