/**
 *
 * Data sagas
 *
 */

import { call, fork, takeEvery, put } from 'redux-saga/effects';

import * as api from 'utils/api';

import fileRootSaga from './File/sagas';
import {
  LOAD_ENTITIES,
  SAVE_ENTITY,
  UPDATE_ENTITY,
  UPDATE_TERMINAL_ADVANCED_INPUT,
} from './constants';

import {
  changeEntitiesState,
  entitiesLoaded,
  entityLoadingError,
  entitySaved,
  entitySavingError,
  entityUpdated,
  entityUpdatingError,
} from './actions';

export default function* rootSaga() {
  yield takeEvery(LOAD_ENTITIES, function* ({ meta, payload }) {
    yield call(
      loadEntitiesSaga,
      payload.entityName,
      {
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        sorting: payload.sorting,
        filters: payload.filters,
        filterBy: payload.filterBy,
        searchQuery: payload.searchQuery,
        includeTotalCount: payload.includeTotalCount,
        view: payload.view,
      },
      meta,
    );
  });

  yield takeEvery(SAVE_ENTITY, function* ({ meta, payload }) {
    yield call(saveEntitySaga, payload.entityName, payload.entity, meta);
  });

  yield takeEvery(UPDATE_ENTITY, function* ({ meta, payload }) {
    yield call(updateEntitySaga, payload.entityName, payload.id, payload.changedData, meta);
  });

  yield takeEvery(UPDATE_TERMINAL_ADVANCED_INPUT, function* ({payload}) {
    yield call(updateTerminalAdvancedInputSaga, 'companies', '3448349753000', payload);
  });

  yield fork(fileRootSaga);
}

export function* loadEntitiesSaga(entityName, options, meta) {
  try {
    const response = yield call(api.loadPage, entityName, options);
    const { data, metadata } = response.data; // eslint-disable-line no-unused-vars
    const entities = data || response.data;
    yield put(changeEntitiesState(entityName, entities));
    yield put(entitiesLoaded(entities, meta));
  } catch (err) {
    yield put(entityLoadingError(err, meta));
  }
}

export function* saveEntitySaga(entityName, entity, meta) {
  entity.source = entityName === "requests" && 'bc'
  try {
    const response = yield call(api.save, entityName, removeNullKeys(entity));
    yield put(entitySaved());
    return response && response.data;
  } catch (err) {
    yield put(entitySavingError(err, meta));
    throw err;
  }
}

const removeNullKeys = obj =>
  Object.keys(obj)
    .filter(k => obj[k] != null)
    .reduce(
      (newObj, k) => {
        if ((obj[k] instanceof Object) && !(obj[k] instanceof Date) && !(obj[k] instanceof Array)) {
          return { ...newObj, [k]: removeNullKeys(obj[k]) }
        }
        else {
          return { ...newObj, [k]: obj[k] }
        }
      }, {}
    )
  ;

export function* updateEntitySaga(entityName, id, changedData, meta) {
  try {
    yield call(api.update, entityName, id, changedData);
    yield put(entityUpdated());
  } catch (err) {
    yield put(entityUpdatingError(err, meta));
    throw err;
  }
}

export function* updateTerminalAdvancedInputSaga(entityName, id, changedData) {
  try {
    yield call(api.update, entityName, id, changedData);
    yield put(entityUpdated());
  } catch (err) {
    yield put(entityUpdatingError(err));
    throw err;
  }
}
