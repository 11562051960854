/*
 *
 * RequestsPage13557
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';

import ReceptionForm13557 from './ReceptionView/ReceptionForm13557';
import TenantForm13557 from './TenantView/TenantForm13557';

function RequestsPage13557(props) {
  return (
    <RequestsPage
      receptionForm={<ReceptionForm13557 />}
      tenantForm={<TenantForm13557 />}
      {...props}
    />
  );
}

export default RequestsPage13557;
