/**
 *
 * TenantForm13539
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import {
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';

class TenantForm13539 extends TenantForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    const typeIndex = fields.findIndex((field) => field.name === 'type');
    fields[typeIndex].visibleOptions = [ON_VISIT, ON_CAR_ENTRY, SERVICE];

    const companyNameIndex = fields.findIndex((field) => field.name === 'companyName');
    fields[companyNameIndex].visible = (values) => [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type);

    return fields;
  }
}

export default TenantForm13539;
