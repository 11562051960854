/*
 *
 * VisitorArrivalForm12405
 *
 */

import VisitorArrivalForm from "../../../../../pages/Requests/ReceptionView/VisitorArrivalForm";
import { ON_PASS_MAKING, ON_VISIT, ON_CAR_ENTRY, SERVICE } from "../../../../../domain/Data/RequestType/constants";

class VisitorArrivalForm12405 extends VisitorArrivalForm{
    getFields() {
        const fields = super.getFields();

        const passNumberIndex = fields.findIndex(field => field.name === 'passNumber');
        fields[passNumberIndex].visible = (values) =>
            values.type === ON_VISIT ||
            values.type === ON_PASS_MAKING ||
            values.type === ON_CAR_ENTRY;

        const documentTypeIndex = fields.findIndex(field => field.name === 'documentType');
        fields[documentTypeIndex].visible = (values) =>
            values.type !== SERVICE;

        const documentDetailsIndex = fields.findIndex(field => field.name === 'documentDetails');
        fields[documentDetailsIndex].visible = (values) =>
            values.type !== SERVICE;
        return fields;
    }
}

export default VisitorArrivalForm12405;
