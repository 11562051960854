/**
 *
 * Requests action buttons 14326(BC Khimki)
 *
 */

import React from 'react';
import { requestAction } from 'domain/typeConstants/action';

import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Email from '@material-ui/icons/Email';
import CallReceived from '@material-ui/icons/CallReceived';
import CallMade from '@material-ui/icons/CallMade';
import Block from '@material-ui/icons/Block';
import Create from '@material-ui/icons/Create';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';

import { requestStatus } from 'domain/typeConstants/status';
import { requestType } from 'domain/typeConstants/type';
import { role as roleType } from 'domain/typeConstants/roles';

import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { ENGINEERING_SERVICE } from 'custom/14326/domain/Data/RequestType/constants';


function isReception() {
  const currentRoles = getValueFromLocalStorage(GLOBAL_USER_KEY).roles;
  const checkRoleReception = currentRoles.find((role) => (role === roleType.RECEPTION));
  return checkRoleReception ? true : false;
}

export const acceptActionButton = {
  name: requestAction.ACCEPT,
  label: 'Принять',
  icon: 'done',
  svgIcon: <Done />,
  disabled: (selection, entities) => {
    if (selection.length !== 0) {
      if (!entities[0].status) return false;
      if (!entities[0].agreedEmployee) return false;

      return entities.some((element) => element.status &&
        (
          element.type.id !== ENGINEERING_SERVICE &&
          (element.status.name === requestStatus.CLOSED || element.status.name === requestStatus.COMPLETED ||
            element.status.name === requestStatus.ACCEPTED || element.status.name === requestStatus.IN_PROGRESS
            || element.status.name === requestStatus.AGREED))
        || (element.status.name !== requestStatus.CREATED && element.type.id === ENGINEERING_SERVICE)
        || (element.status.name === requestStatus.REJECTED
          && element.agreedEmployee.id !== getValueFromLocalStorage(GLOBAL_USER_KEY).id));
    }
    return true;
  },
};

export const sendToAgreementActionButton = {
  name: requestAction.SEND_TO_AGREEMENT,
  label: 'Согласовать с Адм. ХБП',
  icon: 'assignment_turned_in',
  svgIcon: <AssignmentTurnedIn />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      const status = entities[0].status && entities[0].status.name;
      if (!status) return false;
      return (status === requestStatus.ACCEPTED || status === requestStatus.CLOSED || status === requestStatus.REJECTED || status === requestStatus.COMPLETED
        || (status === requestStatus.AGREED && isReception()));
    }
    return true;
  },
};

export const sendToAgreementByTenantActionButton = {
  name: requestAction.SEND_TO_AGREEMENT_BY_TENANT,
  label: 'Согласовать с арендатором',
  icon: 'assignment_ind',
  svgIcon: <AssignmentInd />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      const status = entities[0].status && entities[0].status.name;
      const type = entities[0].type && entities[0].type.name;
      if (!status) return false;
      if (!type) return false;
      return (status === requestStatus.CLOSED || status === requestStatus.COMPLETED || type !== requestType.CARRYING_OUT_WORKS
        || (status === requestStatus.AGREED && isReception())
      );
    }
    return true;
  },
};

export const sendEmailActionButton = {
  name: requestAction.SEND_EMAIL,
  label: 'Экспорт в outlook',
  icon: 'email',
  svgIcon: <Email />,
  disabled: (selection) => selection.length !== 1,
};


export const arrivedActionButton = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      if (!entities[0].status) return false;
      return (entities[0].status.name === requestStatus.CLOSED ||
        entities[0].status.name === requestStatus.COMPLETED || entities[0].status.name === requestStatus.IN_PROGRESS
        || entities[0].status.name === requestStatus.CREATED);
    }
    return true;
  },
};

export const departedActionButton = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      if (!entities[0].status) return false;
      return entities[0].status.name !== requestStatus.ON_TERRITORY;
    }
    return true;
  },
};

export const addBlackListCarActionButton = {
  name: requestAction.ADD_TO_BLACK_LIST,
  label: 'Добавить в ЧС',
  icon: 'block',
  svgIcon: <Block />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      if (!entities[0].status) return false;
      return (entities[0].status.name === requestStatus.CLOSED || entities[0].status.name === requestStatus.COMPLETED);
    }
    return true;
  },
};

export const editActionButton = {
  name: requestAction.EDIT,
  label: 'Открыть',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    let status;
    let type;
    if (currentRecord || selection.length === 1) {
      if (currentRecord) {
        status = currentRecord.status.name;
        type = currentRecord.type.id;
      }
      else if (selection.length === 1) {
        status = entities[0].status && entities[0].status.name;
        type = entities[0].type && entities[0].type.id
      }
      if (!status || (status === requestStatus.COMPLETED && type === ENGINEERING_SERVICE))
        return false;
      return status === requestStatus.CLOSED || status === requestStatus.COMPLETED;
    }
    return true;
  },
};

export const closeActionButton = {
  name: requestAction.CLOSE,
  label: 'Закрыть',
  icon: 'close',
  svgIcon: <Close />,
  disabled: (selection, entities) => {
    if (selection.length !== 0) {
      const status = entities[0].status && entities[0].status.name;
      const type = entities[0].type && entities[0].type.id
      if (!status || (status === requestStatus.COMPLETED && type === ENGINEERING_SERVICE))
        return false;
    }
    return true;
  },
};


export const firedActionButton = {
  name: requestAction.FIRED,
  label: 'Уволен',
  icon: 'done',
  svgIcon: <Done />,
  disabled: (selection, entities) => {
    if (selection.length !== 0) {
      if (!entities[0].status) return false;
      return !(entities.some((element) => element.status &&
        element.status.name === requestStatus.WAIT_S3)
      );
    }
    return true;
  },
};