/**
 *
 * AdministratorBC Service Requests Form 15755
 *
 */

import ReceptionForm15755 from '../ReceptionView/ReceptionForm15755';
import { CARRYING_OUT_WORKS, SERVICE } from 'domain/Data/RequestType/constants';

export class AdministratorBCServiceRequestsForm15755 extends ReceptionForm15755 {

  getFields() {
    const fields = super.getFields();

    const typeIndex = fields.findIndex((field) => field.name === 'type');

    fields[typeIndex].initialValue = (values, mode) => mode === 'add' ? SERVICE : values.type
    fields[typeIndex].visibleOptions = [SERVICE, CARRYING_OUT_WORKS]

    return fields;
  }
}
