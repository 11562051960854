/**
 *
 * Requests action buttons 15774
 *
 */

import React from 'react';
import {requestAction} from 'domain/typeConstants/action';
import {requestStatus} from 'domain/typeConstants/status';
import Done from '@material-ui/icons/Done';


export const acceptActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED,
    requestStatus.IN_PROGRESS, requestStatus.AGREED, requestStatus.REJECTED, requestStatus.PASS_ISSUED]


export const acceptActionButton = {
    name: requestAction.ACCEPT,
    label: 'Принять',
    icon: 'done',
    svgIcon: <Done/>,
    disabled: (selection, entities, currentRecord) => {
        if (currentRecord && selection.length === 0) {
            if (!currentRecord.status) return false
            return acceptActionDisableStatuses.includes(currentRecord.status.name)
        } else if (selection.length > 0) {
            return entities.some((element) => element.status &&
                acceptActionDisableStatuses.includes(element.status.name)
            );
        }
        return true;
    },
};

export const arrivedGroupActionButton = {
    name: requestAction.ARRIVED_GROUP,
    label: 'Принять группу',
    icon: 'done',
    svgIcon: <Done/>,
    disabled: (selection) => !(selection.length > 0),
};