/**
 *
 * SupplierInvoiceView actions
 *
 */

import {
  EXECUTE_VIEW_ACTION,
} from './constants';

export function executeViewAction(id) {
  return {
    type: EXECUTE_VIEW_ACTION,
    payload: { id },
  };
}
