/**
 *
 * SupplyPositionView actions
 *
 */

import {
  EXECUTE_CARGO_RECEIVED_ACTION,
  EXECUTE_ORIGINALS_DELIVERED_ACTION,
} from './constants';

export function executeCargoReceivedAction(id) {
  return {
    type: EXECUTE_CARGO_RECEIVED_ACTION,
    payload: { id },
  };
}

export function executeOriginalsDeliveredAction(id) {
  return {
    type: EXECUTE_ORIGINALS_DELIVERED_ACTION,
    payload: { id },
  };
}