/**
 *
 * RestrictedAdministratorBC Requests Form 14366
 *
 */

import { ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { ReceptionForm14366 } from '../ReceptionView';

export class RestrictedAdministratorBCRequestsForm14366 extends ReceptionForm14366 {
  getFields() {
    const fields = super.getFields();

    const companyIndex = fields.findIndex((field) => field.name === 'company');
    fields[companyIndex].initialValue = (values, mode, user) => user.companyId;

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].calculatedValue = (values, mode) => {
      if (mode === 'add' || mode === 'copy') { return ACCEPTED; }

      return values.status;
    };

    return fields;
  }
}
