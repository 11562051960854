/*
 *
 * ReceptionDxGrid14720
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';
import { requestColumnSizes } from 'domain/typeConstants/table';
import { REQUESTS_WITHOUT_EMPTY_NAME, ACCESS_CARD_REQUESTS } from "domain/Data/Filters/constants";
import {
  disableDepartedButton,
  disabledAcceptButton,
  disabledArrivedButton,
  disabledAcceptItem,
  disabledArrivedItem,
  disableDepartedItem
} from "../common/utils";

import {
  estimationCostColumn,
  estimationCostColumnFilter,
  ESTIMATION_COST,
  estimationCostScanColumn,
  estimationCostScanColumnFilter,
  ESTIMATION_COST_SCAN,
} from "../common/dxGridComponents/columns";

export class ReceptionDxGrid14720 extends ReceptionDxGrid {
  getFilters() {
    const filters = super.getFilters();
    const { roles, viewName } = this.props;
    if (!(viewName === 'all' && (roles.includes('administratorBC') || roles.includes('reception')))) {
      filters.push(REQUESTS_WITHOUT_EMPTY_NAME);
    }
    if (viewName === 'passMaking') filters.push(ACCESS_CARD_REQUESTS);
    return filters;
  }

  getColumns() {
    const columns = super.getColumns();

    const serviceNameIndex = columns.findIndex((column) => column.name === 'serviceName');
    columns[serviceNameIndex].entityName = "services";

    const phoneColumn = {
      path: 'phone',
      name: 'phone',
      title: 'Контактный тел',
      width: requestColumnSizes.phone,
    };
    columns.push(phoneColumn);
    columns.push(estimationCostColumn);
    columns.push(estimationCostScanColumn);

    return columns;
  }

  getGridFilters() {
    const gridFilters = super.getGridFilters();
    const phoneColumnFilters = { columnName: 'phone', value: '' };
    gridFilters.push(phoneColumnFilters);

    gridFilters.push(estimationCostColumnFilter);
    gridFilters.push(estimationCostScanColumnFilter);

    return gridFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    columnOrder.push('phone');

    const companyNameIndex = columnOrder.findIndex((column) => column === 'company_name');
    columnOrder.splice(companyNameIndex + 1, 0, 'authorName', 'phone', 'actualCreationDate');

    const fileIndex = columnOrder.findIndex(column => column === 'fileName');
    columnOrder.splice(fileIndex + 1, 0, ESTIMATION_COST, ESTIMATION_COST_SCAN);

    return columnOrder;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    disabledAcceptButton(actionButtons);

    disabledArrivedButton(actionButtons);

    disableDepartedButton(actionButtons);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    disabledAcceptItem(actionMenuItems);

    disabledArrivedItem(actionMenuItems);

    disableDepartedItem(actionMenuItems);

    return actionMenuItems;
  }

  getActionButtonsWithoutCustomDisable() {
    return super.getActionButtons();
  }

  getActionMenuItemsWithoutCustomDisable() {
    return super.getActionMenuItems();
  }
}
