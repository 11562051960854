/**
 * The data state selectors
 */

import { createSelector } from 'reselect';
import makeSelectGlobal from 'domain/selectors';

const makeSelectData = () => createSelector(
  makeSelectGlobal(),
  (globalState) => globalState.get('data')
);

const makeSelectFilterEntity = (entityName) => createSelector(
  makeSelectData(),
  (state) => (state.getIn(['filter', entityName]) ? state.getIn(['filter', entityName]) : null)
);

const makeSelectFilterEntities = () => createSelector(
  makeSelectData(),
  (state) => (state.getIn(['filter']) ? state.getIn(['filter']) : null)
);

const makeSelectEntities = () => createSelector(
  makeSelectData(),
  (dataState) => dataState.get('entities')
);

const makeSelectEntityData = (entityName) => createSelector(
  makeSelectData(),
  (dataState) => dataState && dataState.getIn(['entities', entityName])
);

const makeSelectEntity = (entityName, id) => createSelector(
  makeSelectData(),
  (dataState) => dataState.getIn(['entities', entityName, id])
);

const makeSelectEntityValue = (entityName, id) => createSelector(
  makeSelectData(),
  (dataState) => dataState.getIn(['entities', entityName, id])
);

const makeSelectDialogValue = (entityName) => createSelector(
  makeSelectData(),
  (dataState) => dataState.getIn(['dialog', entityName])
);

export default makeSelectData;
export {
  makeSelectData,
  makeSelectEntities,
  makeSelectEntity,
  makeSelectEntityValue,
  makeSelectEntityData,
  makeSelectFilterEntities,
  makeSelectFilterEntity,
  makeSelectDialogValue,
};
