/*
 *
 * SnackbarContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectAccountId } from 'domain/User/selectors';

import * as actionCreators from './actions';
import Snackbar from './Snackbar';
import {
  makeSelectOpen,
  makeSelectMessage,
  makeSelectAutoHideDuration,
} from './selectors';

class SnackbarContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleClose = () => this.props.close();

  render() {
    const { open, message, autoHideDuration, accountId } = this.props;
    return (
      <Snackbar
        open={open}
        message={message}
        autoHideDuration={autoHideDuration}
        onClose={this.handleClose}
        accountId={accountId}
      />
    );
  }
}

SnackbarContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  open: makeSelectOpen(),
  message: makeSelectMessage(),
  autoHideDuration: makeSelectAutoHideDuration(),
  accountId: makeSelectAccountId(),
});

function mapDispatchToProps(dispatch) {
  return {
    close: () => dispatch(actionCreators.close()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer);
