/**
 * The navigation state selectors
 */

import { createSelector } from 'reselect';

import makeSelectGlobal from 'domain/selectors';
import { makeSelectAccountId, makeSelectRoles } from 'domain/User/selectors';
import { makeSelectViewMode } from 'domain/Screen/selectors';

const makeSelectNavigation = () => createSelector(
  makeSelectGlobal(),
  (globalState) => globalState.get('navigation')
);

const makeSelectItems = () => createSelector(
  makeSelectNavigation(),
  (navigationState) => navigationState.get('items')
);

const makeSelectCurrentDxGridName = () => createSelector(
  makeSelectNavigation(),
  (navigationState) => navigationState.get('currentDxGrid')
)

const makeSelectCurrentRecordId = () => createSelector(
  makeSelectNavigation(),
  (navigationState) => navigationState.getIn(['currentRecord', 'id'])
);

const makeSelectCurrentRecord = () => createSelector(
  makeSelectNavigation(),
  (navigationState) => navigationState.get('currentRecord')
);

const makeSelectAllowedItems = () => createSelector(
  makeSelectItems(),
  makeSelectAccountId(),
  makeSelectRoles(),
  makeSelectViewMode(),
  (itemsState, currentAccountId, currentRoles, viewMode) => Object.values(itemsState.toJS())
    .filter((item) => {
      const isDisallowedAccount = !item.disallowedAccounts ||
        !item.disallowedAccounts.includes(currentAccountId);
      const isAllowedAccount = !item.allowedAccounts ||
        item.allowedAccounts.includes(currentAccountId);
      const isDisallowedRole = !item.disallowedRoles ||
        item.disallowedRoles.some((role) => !currentRoles.includes(role));
      const isAllowedRole = !item.allowedRoles ||
        item.allowedRoles.some((role) => currentRoles.includes(role));
      const idDisallowedViewMode = !item.disallowedViewMode ||
        item.disallowedViewMode.some((mode) => !viewMode.includes(mode));
      return isDisallowedAccount && isAllowedAccount && isAllowedRole && isDisallowedRole && idDisallowedViewMode;
    }
    )
);

const makeSelectLocation = () => (state, ownProps) => ownProps.location;

function sortNavItemsByAsc(a, b) {
  if (a.order > b.order) return 1;
  if (a.order < b.order) return -1;
  return 0;
}

const makeSelectComposedItems = () => createSelector(
  makeSelectAllowedItems(),
  makeSelectLocation(),
  (items, location) => items
    .filter((item) => !item.parent)
    .sort(sortNavItemsByAsc)
    .map((item) => ({
      ...item,
      items: items
        .filter((subItem) => subItem.parent === item.id)
        .sort(sortNavItemsByAsc)
        .map((subItem) => ({
          ...subItem,
          isActive: isActiveItem(subItem, location),
        })),
    }))
    .map((item) => ({
      ...item,
      isActive: item.items.some((subItem) => subItem.isActive) || isActiveItem(item, location),
    }))
);

const itemIsMatchLocation = (item, location) => item.link &&
  (
    item.link.pathname === location.pathname ||
    location.pathname.startsWith(`${item.link.pathname}/`)
  );

const isActiveItem = (item, location) => itemIsMatchLocation(item, location) &&
  (
    (item.link.search === location.search) ||
    (!item.link.search && !location.search) ||
    (item.order === 1 && !location.search)
  );

const makeSelectActiveComposedItem = () => createSelector(
  makeSelectComposedItems(),
  (composedItems) => composedItems.find((item) => item.isActive)
);

export default makeSelectNavigation;
export {
  makeSelectItems,
  makeSelectComposedItems,
  makeSelectActiveComposedItem,
  makeSelectCurrentDxGridName,
  makeSelectCurrentRecordId,
  makeSelectCurrentRecord,
};
