/**
 * DocumentMatchingFormHeader selectors
 */

import { createSelector } from 'reselect';

const makeSelectDomain = () => (state) => state.get('DocumentMatchingFormHeader');

const makeSelectVisitorFullName = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('visitorFullName')
);

const makeSelectDocumentDetails = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('documentDetails')
);

const makeSelectRequestNumber = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('requestNumber')
);

const makeSelectVisitDate = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('visitDate')
);

export default makeSelectDomain;
export {
  makeSelectVisitorFullName,
  makeSelectDocumentDetails,
  makeSelectRequestNumber,
  makeSelectVisitDate,
};
