/**
 *
 * Autocomplete sagas
 *
 */

import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { loadEntities } from 'domain/Data/actions';
import * as api from 'utils/api';

import { LOAD_OPTIONS, LOAD_INITIAL_VALUE } from './constants';
import makeSelectField from './selectors';
import {
  makeSelectCurrentValues,
  makeSelectInitialValues,
  makeSelectMode,
} from '../selectors';

import {
  addEntityToNormalizationQueue,
  incrementRequestsSentParameter,
} from '../actions';

import { normalizeEntitiesQueueSaga } from '../commonSagas';

export default function* rootSaga() {
  yield takeEvery(LOAD_INITIAL_VALUE, loadInitialValueSaga);
  yield takeLatest(LOAD_OPTIONS, function* (action) {
    yield call(loadOptionsSaga, action);
  });
}

export function* loadOptionsSaga({ meta, payload }) {
  const { entityName, filters, filterBy } = yield select(makeSelectField(meta.advancedForm, meta.field));
  const options = { searchQuery: payload.searchQuery, pageNumber: 1, pageSize: 5 };
  if (filters && Array.isArray(filters)) {
    options.filters = filters;
  } else if (filters && typeof filters === 'function') {
    const initialValues = yield select(makeSelectInitialValues(meta.advancedForm));
    const currentValues = yield select(makeSelectCurrentValues(meta.advancedForm));
    const mode = yield select(makeSelectMode(meta.advancedForm));
    options.filters = filters(currentValues, initialValues, mode);
  }

  if (filterBy && typeof filterBy === 'string') {
    options.filterBy = filterBy;
  } else if (filterBy && typeof filterBy === 'function') {
    const initialValues = yield select(makeSelectInitialValues(meta.advancedForm));
    const currentValues = yield select(makeSelectCurrentValues(meta.advancedForm));
    const mode = yield select(makeSelectMode(meta.advancedForm));
    options.filterBy = filterBy(currentValues, initialValues, mode);
  }

  yield put(loadEntities(entityName, options, meta));
}

export function* loadInitialValueSaga({ payload, meta }) {
  const { entityName, id } = payload;
  const field = yield select(makeSelectField(meta.advancedForm, meta.field));

  try {
    yield put(incrementRequestsSentParameter(meta.advancedForm));
    const response = yield call(api.load, entityName, id);
    if (response) {
      const { data } = response;
      const entity = {
        field: field.name,
        data: [data],
        numberInQueueFromEnd: field.numberInQueueFromEnd,
      };

      yield put(addEntityToNormalizationQueue(meta.advancedForm, entityName, entity));
      yield* normalizeEntitiesQueueSaga(meta.advancedForm);
    }
  } catch (err) {
    console.log(err);
  }
}
