/**
 *
 *  UnloadingZoneOperatorDxGrid12970
 *
 */

import { UnloadingZoneOperatorDxGrid } from 'pages/Requests/UnloadingZoneOperator';

import { requestAction } from 'domain/typeConstants/action';
import {
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_COMCITY_UNLOADING_ZONE_OPERATOR,
} from 'domain/Data/Filters/constants';

import { unloadingZoneOperatorArrivedActionButton, unloadingZoneOperatorDepartedActionButton } from '../common/actionButton';
import { unloadingZoneOperatorArrivedActionItem, unloadingZoneOperatorDepartedActionItem } from '../common/actionMenu';

class UnloadingZoneOperatorDxGrid12970 extends UnloadingZoneOperatorDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_COMCITY_UNLOADING_ZONE_OPERATOR];
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    return filters;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();
    const arrivedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    const departedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.DEPARTED);
    actionButtons[arrivedButtonIndex] = unloadingZoneOperatorArrivedActionButton;
    actionButtons[departedButtonIndex] = unloadingZoneOperatorDepartedActionButton;

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();
    const arrivedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.ARRIVED);
    const departedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.DEPARTED);
    actionMenuItems[arrivedActionMenuIndex] = unloadingZoneOperatorArrivedActionItem;
    actionMenuItems[departedActionMenuIndex] = unloadingZoneOperatorDepartedActionItem;

    return actionMenuItems;
  }
}

export default UnloadingZoneOperatorDxGrid12970;
