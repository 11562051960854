/**
 *
 * RequestsPage15511
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import { ReceptionDxGrid15511 } from './ReceptionView';
import { CheckpointDxGrid15511 } from './CheckpointView';

function RequestsPage15511(props) {

  return (
    <RequestsPage
      receptionDxGrid={<ReceptionDxGrid15511 />}
      checkpointDxGrid={<CheckpointDxGrid15511 />}
      {...props}
    />
  );
}

export default RequestsPage15511;
