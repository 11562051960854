/*
 *
 * EmailSubscriptionButton sagas
 *
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';
import * as actionCreators from './actions';

import { SUBSCRIBE, UNSUBSCRIBE } from './constants';

export default function* rootSaga() {
  yield takeLatest(SUBSCRIBE, subscribeOnEmailSaga);
  yield takeLatest(UNSUBSCRIBE, unsubscribeOnEmailSaga);
}

function* subscribeOnEmailSaga() {
  try {
    yield call(api.subscribeOnEmailMessages);
    yield put(actionCreators.subscribed());
  } catch(e) {
    yield put(actionCreators.subscribingError(e));
  }
}

function* unsubscribeOnEmailSaga() {
  try {
    yield call(api.unsubscribeOnEmailMessages);
    yield put(actionCreators.unsubscribed());
  } catch(e) {
    yield put(actionCreators.unsubscribingError(e));
  }
}