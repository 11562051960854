/**
 *
 * ReceptionView14691 sagas
 *
 */

import { call, put, select } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import { acceptRequestsSaga } from 'domain/Data/Request/sagas';
import { openEditDialog } from 'containers/AdvancedFormDialog/actions';
import {
  RECEPTION_REQUESTS_TABLE_GRID,
  REJECT_FORM_DIALOG,
} from 'pages/Requests/ReceptionView/constants';
import { ON_VISIT } from 'domain/Data/RequestType/constants';
import { makeSelectEntity } from 'domain/Data/selectors';
import { makeSelectRoles } from 'domain/User/selectors';

function* executeAcceptActionSaga({ payload: { ids } }) {
  const allowedIds = [];
  for (const id of ids) {
    const request = yield select(makeSelectEntity('requests', id));
    const roles = yield select(makeSelectRoles());
    if (request.type === ON_VISIT || roles.includes('administratorBC')) {
      allowedIds.push(id);
    }
  }

  if (allowedIds.length > 0) {
    yield call(acceptRequestsSaga, allowedIds);
    yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
  }
}

function* executeRejectActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const id = ids[0];
    const request = yield select(makeSelectEntity('requests', id));
    const roles = yield select(makeSelectRoles());
    if (request.type === ON_VISIT || roles.includes('administratorBC')) {
      yield put(openEditDialog(REJECT_FORM_DIALOG, { id }));
    }
  }
}

export const receptionViewSaga = {
  executeAcceptActionSaga,
  executeRejectActionSaga
};
