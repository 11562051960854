/**
 *
 * TaskFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { USER_TASK_FORM, USER_TASK_FORM_DIALOG } from './constants';


function TaskFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={USER_TASK_FORM_DIALOG}
      formName={USER_TASK_FORM}
      advancedForm={form}
      addModeTitle="Новая задача"
      editModeTitle="Изменение задачи"
      maxWidth="lg"
    />
  );
}

TaskFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default TaskFormDialog;
