/**
 *
 * createSelectCheckBoxContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, clearSubmitErrors } from 'redux-form/immutable';

import SelectCheckBox from './SelectCheckBox';
import * as actionCreators from './actions';
import * as advancedFormActionCreators from '../actions';
import {
  makeSelectIsLoaded,
  makeSelectIsCached,
  makeSelectOptions,
} from './selectors';
import {
  makeSelectRecord,
  makeSelectMode,
} from '../selectors';

function createSelectCheckBoxContainer(formName, fieldName) {
  class SelectCheckBoxContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    componentWillMount() {
      const {
        cached,
        loaded,
        loadOptions,
        mode,
      } = this.props;

      if (mode === null || mode === 'view') return;
      if ((!cached || !loaded)) {
        loadOptions();
      }
    }

    handleChange = () => { // eslint-disable-line
      if (this.props.clearErrorsOnChange) {
        this.props.clearErrors();
      }
      this.props.calculateFieldsValues();
    };

    render() {
      const {
        loaded, // eslint-disable-line no-unused-vars
        cached, // eslint-disable-line no-unused-vars
        loadOptions, // eslint-disable-line no-unused-vars
        clearErrorsOnChange, // eslint-disable-line no-unused-vars
        calculateFieldsValues, // eslint-disable-line no-unused-vars
        clearErrors, // eslint-disable-line no-unused-vars
        record, // eslint-disable-line no-unused-vars
        multiple,
        disabled,
        ...restProps
      } = this.props;

      return (
        <Field
          {...restProps}
          onChange={this.handleChange}
          component={SelectCheckBox}
          disabled={disabled}
        />
      );
    }
  }

  SelectCheckBoxContainer.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };

  SelectCheckBoxContainer.propTypes = {
    cached: PropTypes.bool.isRequired,
    calculateFieldsValues: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearErrorsOnChange: PropTypes.bool,
    loaded: PropTypes.bool.isRequired,
    loadOptions: PropTypes.func.isRequired,
    mode: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    record: PropTypes.object,
  };

  const mapStateToProps = createStructuredSelector({
    loaded: makeSelectIsLoaded(formName, fieldName),
    cached: makeSelectIsCached(formName, fieldName),
    options: makeSelectOptions(formName, fieldName),
    record: makeSelectRecord(formName),
    mode: makeSelectMode(formName),
  });

  const mapDispatchToProps = (dispatch) => ({
    loadOptions: () => dispatch(actionCreators.loadOptions(formName, fieldName)),
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
    clearErrors: () => dispatch(clearSubmitErrors(formName)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(SelectCheckBoxContainer);
}

export default createSelectCheckBoxContainer;
