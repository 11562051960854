/**
 *
 * DisagreementDialog constants
 *
 */

export const OPEN_DISAGREEMENT_DIALOG = 'claris-vnext/DocumentsPage/DisagreementDialog/OPEN_DISAGREEMENT_DIALOG';
export const SUBMIT_DISAGREEMENT_DIALOG = 'claris-vnext/DocumentsPage/DisagreementDialog/ACCEPT_DISAGREEMENT_DIALOG';
export const CLOSE_DISAGREEMENT_DIALOG = 'claris-vnext/DocumentsPage/DisagreementDialog/CLOSE_DISAGREEMENT_DIALOG';

export const DIALOG_NAME = 'documentsDisagreementDialog';
