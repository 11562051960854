/**
 *
 * ColumnStatusLabels
 *
 */

import { taskStatus } from 'domain/typeConstants/status';

export const statusColorLabels = {

  [taskStatus.CREATED]: {
    backgroundColor: '#FFFFFF',
  },

  [taskStatus.IN_PROGRESS]: {
    backgroundColor: '#9CEEC0',
  },

  [taskStatus.COMPLETED]: {
    backgroundColor: '#81D4FA',
  },

  [taskStatus.PAUSED]: {
    backgroundColor: '#FFE0B2',
  },

  [taskStatus.CLOSED]: {
    backgroundColor: '#E0E0E0',
  },

};
