/**
 *
 * ServiceRequestsManagerForm15776
 *
 */


import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { SERVICE } from 'domain/Data/RequestType/constants';
import { ASSIGNEE_USER, EVDOKIMOV } from '../common/constants';


export class ServiceRequestsManagerForm15776 extends ServiceRequestsManagerForm {

    getFields() {

        const fields = super.getFields();

        const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');

        fields[assigneeIndex].initialValue = (values, mode) => mode === 'add' && values.type === SERVICE
            ? ASSIGNEE_USER : null
        fields[assigneeIndex].calculatedValue = (values, mode) => {
            if (mode === 'copy') return null;
            if (mode === 'add' && values.type === SERVICE && values.assignee === null) return ASSIGNEE_USER
            return values.assignee;
        }

        const manager = {
            name: 'manager',
            visible: false,
            initialValue: EVDOKIMOV
        }

        fields.push(manager)

        return fields;
    }

}


