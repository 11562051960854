/**
 *
 * validate functions for RequestsPage14691
 *
 */

export const validateExpirationDate = (values) => {
  if (values.visitDate && values.expirationDate) {
    const visitDate = new Date(values.visitDate);
    const expirationDate = new Date(values.expirationDate);
    const diff = (expirationDate.setHours(0, 0, 0, 0) - visitDate.setHours(0, 0, 0, 0)) / (24 * 3600 * 1000);
    if (values.longterm && diff > 180) {
      return { errorMessage: 'Не может быть больше 180 дней после даты посещения' };
    }
  }

  return null;
};

export const calculateMaxExpirationDate = (values) => {
  if (values.visitDate) {
    const maxDate = new Date(values.visitDate);

    if (values.longterm) {
      maxDate.setDate(maxDate.getDate() + 180);
      return maxDate;
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    return new Date(year + 3, month, day);
  }

  return null;
};
