import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Autorenew from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Code from '@material-ui/icons/Code';
import CompareArrows from '@material-ui/icons/CompareArrows';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/icons/Menu';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import FormatIndentIncrease from '@material-ui/icons/FormatIndentIncrease';

import { screenForMatchMedia } from 'domain/typeConstants/screen';
import SwitchComponent from './SwitchComponent';
import { settings } from './dxGridTypes';
import { account } from 'domain/typeConstants/accounts';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { role } from 'domain/typeConstants/roles';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  settingDialog: {
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },
    [theme.breakpoints.up('sm')]: {
      width: 620,
    },
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
  },
  dialogActionsContainer: {
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    marginBottom: 5,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      flexWrap: 'nowrap',
    },
  },
  dialogActionButton: {
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '47%',
    },
  },
  dialogResetButton: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  emptySpace: {
    flexBasis: '56%',
  },
  groupTitle: {
    color: '#212121',
    marginBottom: 10,
    marginTop: 10,
  },
});

const isLargeScreenSize = !(window.matchMedia(screenForMatchMedia.EXTRA_SMALL).matches || window.matchMedia(screenForMatchMedia.SMALL).matches);

class TableSettingsDialog extends React.Component {
  constructor(props) {
    super(props);

    const { settingsConfig, user } = props;
    this.state = {
      [settings.COLUMN_VISIBILITY]: settingsConfig[settings.COLUMN_VISIBILITY].enabled,
      [settings.FILTERING]: settingsConfig[settings.FILTERING].enabled,
      [settings.FILTER_BUTTON]: settingsConfig[settings.FILTER_BUTTON].enabled,
      [settings.PAGING_TABLE]: settingsConfig.tableType === settings.PAGING_TABLE,
      [settings.REORDERING]: settingsConfig[settings.REORDERING].enabled,
      [settings.RESIZING]: settingsConfig[settings.RESIZING].enabled,
      [settings.TABLE_ACTION_MENU]: settingsConfig[settings.TABLE_ACTION_MENU].enabled,
      [settings.TABLE_DRAWER]: settingsConfig[settings.TABLE_DRAWER].enabled,
      [settings.TABLE_ROW_DETAIL]: settingsConfig[settings.TABLE_ROW_DETAIL].enabled,
      [settings.VIRTUAL_TABLE]: settingsConfig.tableType === settings.VIRTUAL_TABLE,
      [settings.SIGNALR_UPDATE]: user.isAutoUpdate,
    };
    if (user.accountId === account.ZHUKOV) {
      this.state[settings.TERMINAL_ADVANCED_INPUT] = getValueFromLocalStorage(settings.TERMINAL_ADVANCED_INPUT)
    }
  }

  handleChangeSwitch = (name) => (event) => {
    if (name === settings.VIRTUAL_TABLE) {
      this.setState({
        [name]: event.target.checked,
        [settings.PAGING_TABLE]: !(event.target.checked),
      });
      return null;
    } else if (name === settings.PAGING_TABLE) {
      this.setState({
        [name]: event.target.checked,
        [settings.VIRTUAL_TABLE]: !(event.target.checked),
      });
      return null;
    }
    else if (name === settings.FILTERING) {
      this.setState({
        [name]: event.target.checked,
        [settings.FILTER_BUTTON]: event.target.checked
      })
    }

    this.setState({ [name]: event.target.checked });
    return null;
  };

  render() {
    const {
      checkTableFeature,
      classes,
      onClickCancel,
      onClickReset,
      onClickSave,
      settingsConfig,
      tableFeatures,
      mode,
      user,
      ...other
    } = this.props;

    const isResizingFeature = (() => {
      const result = checkTableFeature(settings.RESIZING, tableFeatures);
      return result ? result.value : true;
    })();
    const isReorderingFeature = (() => {
      const result = checkTableFeature(settings.REORDERING, tableFeatures);
      return result ? result.value : true;
    })();
    const isTableActionFeature = (() => {
      const result = checkTableFeature(settings.TABLE_ACTION_MENU, tableFeatures);
      return result ? result.value : true;
    })();
    const isFilteringFeature = (() => {
      const result = checkTableFeature(settings.FILTERING, tableFeatures);
      return result ? result.value : true;
    })();
    const isColumnVisibilityFeature = (() => {
      const result = checkTableFeature(settings.COLUMN_VISIBILITY, tableFeatures);
      return result ? result.value : true;
    })();
    const isTableRowDetailFeature = (() => {
      const result = checkTableFeature(settings.TABLE_ROW_DETAIL, tableFeatures);
      return result ? result.value : true;
    })();
    const isTableDrawerFeature = (() => {
      const result = checkTableFeature(settings.TABLE_DRAWER, tableFeatures);
      return result ? result.value : true;
    })();

    return (
      <Dialog
        className={classes.settingDialog}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth={false}
        onEntering={this.handleEntering}
        PaperProps={{
          style: {
            margin: '0 auto',
          },
        }}
        {...other}
      >
        <Grid container>
          <DialogTitle>Настройки таблицы</DialogTitle>
          <DialogContent>
            <div className={classes.groupTitle}>Тип таблицы</div>
            <Grid container>
              <Grid item xl={6} md={6} xs={12}>
                <SwitchComponent
                  iconComponent={<FirstPage />}
                  checked={this.state[settings.PAGING_TABLE]}
                  classes={classes}
                  onChange={this.handleChangeSwitch}
                  title={'Таблица со страницами'}
                  value={settings.PAGING_TABLE}
                />
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <SwitchComponent
                  iconComponent={<Autorenew />}
                  checked={this.state[settings.VIRTUAL_TABLE]}
                  classes={classes}
                  onChange={this.handleChangeSwitch}
                  title={'Таблица с бесконечным списком'}
                  value={settings.VIRTUAL_TABLE}
                />
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <SwitchComponent
                  iconComponent={<FormatIndentIncrease />}
                  checked={this.state[settings.SIGNALR_UPDATE] || false}
                  classes={classes}
                  onChange={this.handleChangeSwitch}
                  title={'Автообновление'}
                  value={settings.SIGNALR_UPDATE}
                />
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
              </Grid>
            </Grid>
            <div className={classes.groupTitle} style={{ marginTop: 18 }}>Дополнительные возможности таблицы</div>
            <Grid container>
              {isResizingFeature &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<Code />}
                    checked={this.state[settings.RESIZING]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Изменение ширины колонок'}
                    value={settings.RESIZING}
                  />
                </Grid>
              }
              {isReorderingFeature &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<CompareArrows />}
                    checked={this.state[settings.REORDERING]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Изменение порядка колонок'}
                    value={settings.REORDERING}
                  />
                </Grid>
              }
              {isTableActionFeature &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<Menu />}
                    checked={this.state[settings.TABLE_ACTION_MENU]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Меню в каждой строке'}
                    value={settings.TABLE_ACTION_MENU}
                  />
                </Grid>
              }
              {isLargeScreenSize && isFilteringFeature &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<FilterList />}
                    checked={this.state[settings.FILTERING]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Фильтр'}
                    value={settings.FILTERING}
                  />
                </Grid>
              }
              {isColumnVisibilityFeature &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<Visibility />}
                    checked={this.state[settings.COLUMN_VISIBILITY]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Управление видимостью колонок'}
                    value={settings.COLUMN_VISIBILITY}
                  />
                </Grid>
              }
              {isLargeScreenSize && isTableRowDetailFeature && mode !== 'detail' &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<ExpandMore />}
                    checked={this.state[settings.TABLE_ROW_DETAIL]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Детальное описание строки'}
                    value={settings.TABLE_ROW_DETAIL}
                  />
                </Grid>
              }
              {isLargeScreenSize && isTableDrawerFeature &&
                <Grid item xl={6} md={6} xs={12}>
                  <SwitchComponent
                    iconComponent={<ChevronLeft />}
                    checked={this.state[settings.TABLE_DRAWER]}
                    classes={classes}
                    onChange={this.handleChangeSwitch}
                    title={'Выдвижной контейнер для строки'}
                    value={settings.TABLE_DRAWER}
                  />
                </Grid>
              }
            </Grid>

            {isLargeScreenSize && user.accountId === account.ZHUKOV && user.roles.includes(role.ADMINISTRATOR_BC) &&
              <>
                <div className={classes.groupTitle} style={{ marginTop: 18 }}>Терминал</div>
                <Grid container>
                  <Grid item xl={6} md={6} xs={12}>
                    <SwitchComponent
                      iconComponent={<Edit />}
                      checked={this.state[settings.TERMINAL_ADVANCED_INPUT]}
                      classes={classes}
                      onChange={this.handleChangeSwitch}
                      title={'Ручной ввод ФИО'}
                      value={settings.TERMINAL_ADVANCED_INPUT}
                    />
                  </Grid>
                </Grid>
              </>
            }

          </DialogContent>
          <DialogActions className={classes.dialogActionsContainer}>
            <Button className={classes.dialogResetButton} onClick={onClickReset} color="primary">
              Сбросить настройки
            </Button>
            <div className={classes.emptySpace}></div>
            <Button className={classes.dialogActionButton} onClick={onClickSave(this.state)} color="primary">
              Сохранить
            </Button>
            <Button className={classes.dialogActionButton} onClick={onClickCancel} color="primary">
              Отмена
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    );
  }
}

TableSettingsDialog.propTypes = {
  classes: PropTypes.object,
  checkTableFeature: PropTypes.func,
  onClickCancel: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  settingsConfig: PropTypes.object.isRequired,
  tableFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.bool,
    }),
  ),
};

export default withStyles(styles)(TableSettingsDialog);
