/**
 *
 * SupplyRequestView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

function SupplyRequestView({ dataTable, formDialog, positionFormDialog, invoiceFormDialog, agreementDialog, disagreementDialog, viewName, viewStyles, }) {

  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { React.cloneElement(formDialog, { viewName })}
      { React.cloneElement(positionFormDialog, { viewName })}
      { React.cloneElement(invoiceFormDialog, { viewName })}
      { agreementDialog}
      { disagreementDialog}
    </div>
  );
}

SupplyRequestView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  agreementDialog: PropTypes.node,
  disagreementDialog: PropTypes.node,
};

SupplyRequestView.defaultProps = {
  viewStyles: {},
};

export default SupplyRequestView;
