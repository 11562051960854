/*
 *
 * BlackListFormContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectParams } from 'containers/AdvancedFormDialog/selectors'

import { BLACK_LIST_FORM_DIALOG } from './constants';
import BlackListForm from './BlackListForm';

class BlackListFormContainer extends React.PureComponent {
    render() {
        return (
            <BlackListForm  {...this.props} />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    params: makeSelectParams(BLACK_LIST_FORM_DIALOG),
});


export default connect(mapStateToProps)(BlackListFormContainer);
