/**
 *
 * ColumnStatusLabels
 *
 */

import { taskStatus } from 'domain/typeConstants/status';

export const columnStatusLabels = [
  { backgroundColor: '#f1c40f', fontColor: 'black', condition: (value) => value === taskStatus.CREATED },
  { backgroundColor: '#FDFF3A', fontColor: 'black', condition: (value) => value === taskStatus.IN_PROGRESS },
  { backgroundColor: '#2dcc70', fontColor: 'black', condition: (value) => value === taskStatus.COMPLETED },
  { backgroundColor: '#e84c3d', fontColor: 'black', condition: (value) => value === taskStatus.PAUSED },
  { backgroundColor: '#bec3c7', fontColor: 'black', condition: (value) => value === taskStatus.CLOSED },

  // Альтсофт
  { backgroundColor: '#3598DB', fontColor: 'white', condition: (value) => value === taskStatus.TO_PROGRESS },
  { backgroundColor: '#616161', fontColor: 'white', condition: (value) => value === taskStatus.IN_PAUSE },
  { backgroundColor: '#86ABD7', fontColor: 'black', condition: (value) => value === taskStatus.TO_UPDATE },
  { backgroundColor: '#2CDE97', fontColor: 'black', condition: (value) => value === taskStatus.DONE },
  { backgroundColor: '#FF6F69', fontColor: 'black', condition: (value) => value === taskStatus.EVALUATE },
  { backgroundColor: '#37EC4C', fontColor: 'black', condition: (value) => value === taskStatus.TESTING },
  { backgroundColor: '#339966', fontColor: 'black', condition: (value) => value === taskStatus.AT_CLIENT },
  { backgroundColor: '#A27D74', fontColor: 'white', condition: (value) => value === taskStatus.RETURN },
  { backgroundColor: '#9999FF', fontColor: 'black', condition: (value) => value === taskStatus.PERFORMED },
  { backgroundColor: '#E8548F', fontColor: 'black', condition: (value) => value === taskStatus.REGISTRATION },
  { backgroundColor: '#FE5A27', fontColor: 'black', condition: (value) => value === taskStatus.RATED },

];