/**
 *
 * ReceptionMobileGrid 14326
 *
 */

import ReceptionMobileGrid from 'pages/Requests/ReceptionView/ReceptionMobileGrid';
import {
  sendToAgreementActionMenuItem,
  sendToAgreementByTenantActionMenuItem,
  sendEmailActionMenuItem, acceptActionItem
} from '../common/actionMenuItem';
import { requestAction } from 'domain/typeConstants/action';

export class ReceptionMobileGrid14326 extends ReceptionMobileGrid {
  getFilters() {
    return super.getFilters();
  }

  getDetailRowFields() {
    return super.getDetailRowFields();
  }

  getActionButtons() {
    return super.getActionButtons();
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const viewIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.VIEW);
    actionMenuItems.splice(viewIndex, 1);

    const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
    actionMenuItems[editIndex].label = 'Открыть';

    const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);

    actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);

    actionMenuItems.push(sendToAgreementActionMenuItem);
    actionMenuItems.push(sendToAgreementByTenantActionMenuItem);
    actionMenuItems.push(sendEmailActionMenuItem);

    return actionMenuItems;
  }

  getSorting() {
    return super.getSorting();
  }
}
