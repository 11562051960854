/*
 *
 * RequestsPage14691 sagas
 *
 */

import { fork } from 'redux-saga/effects';

import administratorBCViewSaga from './AdministratorBCView/sagas';

export default function* rootSaga() {
  yield fork(administratorBCViewSaga);
}
