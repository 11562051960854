/**
 *
 * TaskView sagas
 *
 */


import { call, select, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as api from 'utils/api';
import { saveDataAction } from "./actions";
import { LOAD_DATA } from "./constants";


export default function createDashboardViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeEvery(LOAD_DATA, (config.loadDataSaga || loadDataSaga));
  };
}

export function* loadDataSaga({ payload: { entityName, options } }) {
  try {
    const response = yield call(api.loadDashboardData, entityName, options)
    yield put(saveDataAction(entityName, response.data));
  }
  catch (err) {
    console.log("error", err)
  }
}
