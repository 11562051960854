/*
 *
 * ReceptionForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { RECEPTION_COMPANIES_FORM } from "../ReceptionView/constants";
import * as entity from "../../../domain/Data/constants";

export class ReceptionForm extends React.PureComponent {
    getFields() {
        return [
            {
                name: 'name',
                label: 'Наименование',
                visible: true,
            },
            {
                name: 'type',
                type: 'select',
                label: 'Вид',
                entityName: 'contractorTypes',
                visible: true,
            },
            {
                name: 'status',
                type: 'select',
                label: 'Статус',
                entityName: 'contractorStatuses',
                visible: true,
            },
            {
                name: 'adress',
                label: 'Адрес',
                visible: true,
            },
            {
                name: 'phone',
                label: 'Телефон',
                visible: true,
            },
            {
                name: 'note',
                label: 'Примечание',
                visible: true,
            },
            {
                name: 'contactPerson',
                label: 'Контактное лицо',
                visible: true,
            },
            {
                name: 'phoneContactPerson',
                label: 'Тел КЛ',
                visible: true,
            },

        ];
    }

    getClassNames() {
        return {
            helperText: '',
        };
    }

    render() {
        return (
            <AdvancedForm
                name={RECEPTION_COMPANIES_FORM}
                entityName={entity.COMPANIES}
                fields={this.getFields()}
                classNames={this.getClassNames()}
                {...this.props}
            />
        );
    }
}
