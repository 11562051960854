import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

import Block from '@material-ui/icons/Block';
import CallMade from '@material-ui/icons/CallMade';
import CallReceived from '@material-ui/icons/CallReceived';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Clear from '@material-ui/icons/Clear';
import Done from '@material-ui/icons/Done';
import Drafts from '@material-ui/icons/Drafts';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import History from '@material-ui/icons/History';
import Redo from '@material-ui/icons/Redo';

import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import lime from '@material-ui/core/colors/lime';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import teal from '@material-ui/core/colors/teal';

const NEW_REQUEST_TYPE_ID = '4025678976000';

const REQUEST_CLOSED_TYPE_ID = '4025679009000';
const REQUEST_ACCEPTED_TYPE_ID = '4025679016000';
const REQUEST_REJECTED_TYPE_ID = '4025679012000';
const REQUEST_COMPLETED_TYPE_ID = '4025679005000';
const REQUEST_IN_PROGRESS_TYPE_ID = '4025679006000';
const REQUEST_STATUS_CHANGED_TYPE_ID = '4025679025000';
const VISITOR_ARRIVED_TYPE_ID = '4025679029000';
const VISITOR_DEPARTURED_TYPE_ID = '4025679223000';

const NEW_SERVICE_REQUEST_TYPE_ID = '3900766914000';
const SERVICE_REQUEST_ACCEPTED_TYPE_ID = '3910721264000';
const SERVICE_REQUEST_REJECTED_TYPE_ID = '3910721272000';
const SERVICE_REQUEST_IN_PROGRESS_TYPE_ID = '3900766938000';
const SERVICE_REQUEST_STATUS_CHANGED_TYPE_ID = '3900766942000';
const SERVICE_REQUEST_RESPONSIBLE_CHANGED_TYPE_ID = '3900766938000';

const DOCUMENT_STATUS_CHANGED_TYPE_ID = "4430895939000";
const TASK_STATUS_CHANGED_TYPE_ID = "4208743392000";



const styles = () => ({
  newRequest: {
    color: '#fff',
    backgroundColor: lime[500],
  },
  requestClosed: {
    color: '#fff',
    backgroundColor: grey[500],
  },
  requestAccepted: {
    color: '#fff',
    backgroundColor: green[500],
  },
  requestRejected: {
    color: '#fff',
    backgroundColor: red[500],
  },
  requestCompleted: {
    color: '#fff',
    backgroundColor: teal[500],
  },
  requestInProgress: {
    color: '#fff',
    backgroundColor: blue[500],
  },
  requestStatusChanged: {
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  visitorArrived: {
    color: '#fff',
    backgroundColor: orange[500],
  },
  visitorDepartured: {
    color: '#fff',
    backgroundColor: lightBlue[500],
  },
  default: {
    color: '#fff',
    backgroundColor: grey[500],
  },
});

function getPropsByTypeId(typeId, classes) {
  switch (typeId) {
    case NEW_REQUEST_TYPE_ID:
    case NEW_SERVICE_REQUEST_TYPE_ID:
      return {
        className: classes.newRequest,
        icon: <Drafts />,
      };

    case REQUEST_CLOSED_TYPE_ID:
      return {
        className: classes.requestClosed,
        icon: <Clear />,
      };

    case REQUEST_ACCEPTED_TYPE_ID:
    case SERVICE_REQUEST_ACCEPTED_TYPE_ID:
      return {
        className: classes.requestAccepted,
        icon: <Done />,
      };

    case REQUEST_REJECTED_TYPE_ID:
    case SERVICE_REQUEST_REJECTED_TYPE_ID:
      return {
        className: classes.requestRejected,
        icon: <Block />,
      };

    case REQUEST_COMPLETED_TYPE_ID:
      return {
        className: classes.requestCompleted,
        icon: <CheckCircleOutline />,
      };

    case REQUEST_IN_PROGRESS_TYPE_ID:
      return {
        className: classes.requestInProgress,
        icon: <History />,
      };

    case REQUEST_STATUS_CHANGED_TYPE_ID:
    case SERVICE_REQUEST_IN_PROGRESS_TYPE_ID:
    case SERVICE_REQUEST_STATUS_CHANGED_TYPE_ID:
    case SERVICE_REQUEST_RESPONSIBLE_CHANGED_TYPE_ID:
      return {
        className: classes.requestStatusChanged,
        icon: <Redo />,
      };

    case VISITOR_ARRIVED_TYPE_ID:
      return {
        className: classes.visitorArrived,
        icon: <CallReceived />,
      };

    case VISITOR_DEPARTURED_TYPE_ID:
      return {
        className: classes.visitorDepartured,
        icon: <CallMade />,
      };


    case DOCUMENT_STATUS_CHANGED_TYPE_ID:
      return {
        className: classes.newRequest,
        icon: <Drafts />,
      };

    case TASK_STATUS_CHANGED_TYPE_ID:
      return {
        className: classes.newRequest,
        icon: <Drafts />,
      };

    default:
      return {
        className: classes.default,
        icon: <ErrorOutline />,
      };
  }
}

function NotificationAvatar(props) {
  const { classes, typeId } = props;
  const { className, icon } = getPropsByTypeId(typeId, classes);

  return (
    <Avatar className={className}>{icon}</Avatar>
  );
}

NotificationAvatar.propTypes = {
  classes: PropTypes.object,
  typeId: PropTypes.string,
};

export default withStyles(styles)(NotificationAvatar);
