/*
 *
 * RequestsPage15783
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import {TenantForm15783, TenantFormDialog15783} from './TenantView';
import {ReceptionDxGrid15783, ReceptionForm15783, ReceptionFormDialog15783} from './ReceptionView';
import {
    AdministratorBCRequestsDxGrid15783,
    AdministratorForm15783,
    AdministratorFormDialog15783
} from "./AdministratorBCView";

import {getDetailContainer} from './common/detailContainers';


function RequestsPage15783(props) {
    return (
        <RequestsPage
            administratorBCDxGrid={<AdministratorBCRequestsDxGrid15783/>}
            administratorBCForm={<AdministratorForm15783/>}
            administratorBCFormDialog={
                <AdministratorFormDialog15783
                    form={<AdministratorForm15783/>}
                    detailContainer={getDetailContainer()}
                />
            }
            receptionDxGrid={<ReceptionDxGrid15783/>}
            receptionForm={<ReceptionForm15783/>}
            receptionFormDialog={
                <ReceptionFormDialog15783
                    form={<ReceptionForm15783/>}
                    detailContainer={getDetailContainer()}
                />
            }

            tenantForm={<TenantForm15783/>}
            tenantFormDialog={
                <TenantFormDialog15783
                    form={<TenantForm15783/>}
                    detailContainer={getDetailContainer()}
                />
            }
            {...props}
        />
    );
}

export default RequestsPage15783;
