/**
 *
 * SupplierInvoiceView constants
 *
 */

export const EXECUTE_VIEW_ACTION = 'claris-vnext/SupplierInvoices/SupplierInvoicesView/EXECUTE_VIEW_ACTION';

export const SUPPLIER_INVOICES_GRID = 'SupplierInvoicesGrid';
export const SUPPLIER_INVOICE_FORM_DIALOG = 'SupplierInvoiceFormDialog';
export const SUPPLIER_INVOICE_FORM = 'SupplierInvoiceForm';

