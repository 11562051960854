/**
*
* ChangePasswordPage
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import withPublicRoot from 'containers/App/withPublicRoot';
import RestorePasswordFormSheet from './RestorePasswordFormSheet';

const styleSheet = () => ({
  restorePasswordFormSheetContainer: {
    minHeight: 520,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  restorePasswordFormSheet: {
    width: 400,
  },
});

function RestorePasswordPage({ classes, nextPathAfterLogin }) {
  return (
    <div className={classes.restorePasswordFormSheetContainer}>
      <RestorePasswordFormSheet
        className={classes.restorePasswordFormSheet}
        nextPathAfterLogin={nextPathAfterLogin}
      />
    </div>
  );
}

RestorePasswordPage.propTypes = {
  classes: PropTypes.object,
  nextPathAfterLogin: PropTypes.string,
};

export default withPublicRoot(
  withStyles(styleSheet)(RestorePasswordPage)
);
