/**
 * NavigationDrawer selectors
 */

import { createSelector } from 'reselect';

const makeSelectNavigationDrawer = () => (state) => state.get('navigationDrawer');

const makeSelectIsOpen = () => createSelector(
  makeSelectNavigationDrawer(),
  (navigationDrawer) => navigationDrawer.get('isOpen')
);

const makeSelectContainerWidth = () => createSelector(
  makeSelectNavigationDrawer(),
  (navigationDrawer) => navigationDrawer.get('containerWidth')
);

export default makeSelectNavigationDrawer;
export {
  makeSelectIsOpen,
  makeSelectContainerWidth,
};
