/**
*
* DateTimeTableCell
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { LightTooltip } from './DataTableBody';


const styleSheet = {
  cell: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: 8,
    paddingRight: 8,
  }
};

class DateTimeTableCell extends React.PureComponent {
  createCellContent() {
    const { children, type } = this.props;
    const currentDate = new Date();
    let date;
    if (!children) return null;

    date = new Date(children);
    const isToday = currentDate.getDate() === date.getDate();
    const isMonth = currentDate.getMonth() === date.getMonth();
    const isYear = currentDate.getFullYear() === date.getFullYear();

    switch (type) {

      case 'date': {
        if (isYear && isToday && isMonth) {
          return (<FormattedDate value={date} month="short" day="numeric" />)
        }
        if (isYear) {
          return (<FormattedDate value={date} month="short" day="numeric" />)
        }
        return (
          <div>
            <FormattedDate value={date} month="short" day="numeric" />
            <span>{date.getFullYear() - 2000}</span>
          </div>
        )
      }

      case 'datetime': {
        const fullDate = date.toLocaleString();
        if (isYear && isToday && isMonth) {
          return (
            <div>
              <FormattedDate value={date} month="short" day="numeric" />
              <FormattedTime value={date} hour="numeric" minute="numeric" />
            </div>
          )
        }
        if (isYear) {
          return (
            <LightTooltip title={fullDate}>
              <div><FormattedDate value={date} month="short" day="numeric" /></div>
            </LightTooltip>)
        }
        return (
          <LightTooltip title={fullDate}>
            <div>
              <FormattedDate value={date} month="short" day="numeric" />
              <span>{date.getFullYear() - 2000}</span>
            </div>
          </LightTooltip>
        )
      }

      case 'time':
        return (
          <FormattedTime value={date} hour="numeric" minute="numeric" />
        );

      default:
        throw new Error('Unknown a type of DateTimeTableCell.');
    }
  }

  render() {
    const { classes, onClick, onContextMenu } = this.props;
    return (
      <TableCell
        className={classes.cell}
        style={this.props.style}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        {this.createCellContent()}
      </TableCell>
    );
  }
}

DateTimeTableCell.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  type: PropTypes.string,
};

export default withStyles(styleSheet)(DateTimeTableCell);
