/*
 *
 * ServiceRequestsManagerForm14419
 *
 */

import { ReceptionForm14419 } from '../ReceptionView/ReceptionForm14419';

import { isInstructed } from '../common/fields';

export class ServiceRequestsManagerForm14419 extends ReceptionForm14419 {
    getFields() {
        const fields = super.getFields();
        fields.push(isInstructed);
        return fields;
    }
}
