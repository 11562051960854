/**
 *
 * VisitorLogs Page
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import withPrivateRoot from 'containers/App/withPrivateRoot';

import ReceptionVisitorLogsDxGrid from './ReceptionVisitorLogsDxGrid12970';

const styleSheet = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
});

class VisitorLogsPage12970 extends React.PureComponent {
  render() {
    return (
      <div className={this.props.classes.root}>
        <Paper>
          <ReceptionVisitorLogsDxGrid
            key={this.props.viewName}
            viewName={this.props.viewName}
          />
        </Paper>
      </div>
    );
  }
}

VisitorLogsPage12970.propTypes = {
  classes: PropTypes.object,
  viewName: PropTypes.string,
};

export default withPrivateRoot(
  withStyles(styleSheet)(VisitorLogsPage12970)
);
