/**
 *
 * AdministratorBC Requests Form 15776
 *
 */

import { SERVICE } from "domain/Data/RequestType/constants";
import { AdministratorBCForm } from "pages/Requests/AdministratorBCView";
import { ASSIGNEE_USER } from "../common/constants";


export class AdministratorBCRequestsForm15776 extends AdministratorBCForm {

    getFields() {
        const fields = super.getFields();

        const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
        fields[assigneeIndex].initialValue = (values, mode) => mode === 'add' && values.type === SERVICE
            ? ASSIGNEE_USER : null
        fields[assigneeIndex].calculatedValue = (values, mode) => {
            if (mode === 'copy') return null;
            if (mode === 'add' && values.type === SERVICE && values.assignee === null) return ASSIGNEE_USER
            return values.assignee;
        }
        return fields;
    }
}
