/**
 *
 * BlackListForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedForm from 'containers/AdvancedForm';
import { BLACK_LIST_FORM } from './constants';
import { visitorFullNameLabel } from '../common/fields';

class BlackListForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();
    const { request } = this.props.params;

    return [
      {
        name: 'actualCreationDate',
        type: 'date',
        visible: false,
        initialValue: () => currentDate,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        normalize: (value) => value ? value.replace(' ', '') : value,
        initialValue: () => request.carNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        initialValue: () => request.carModelText,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        initialValue: () => request.visitorFullName,
        rowBreakBefore: true,
      },
      {
        name: 'company',
        type: 'autocomplete',
        label: 'Арендатор',
        entityName: 'companies',
        orderBy: 'name',
        numberInQueueFromEnd: 1,
        initialValue: () => request.company,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'level',
        type: 'checkbox',
        label: 'Блокировать сохранение заявки',
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={BLACK_LIST_FORM}
        entityName="blackList"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

BlackListForm.propTypes = {
  params: PropTypes.shape({
    request: PropTypes.object.isRequired,
  }),
};

export default BlackListForm;
