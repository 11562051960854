/**
 *
 * Modal window for TableActionMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';

const styles = (theme) => ({
  typography: {
    margin: theme.spacing.unit * 2,
  },
  icon: {
    height: 24,
    width: 12,
  },
  button: {

  },
  denseItem: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  denseItemIcon: {
    fontSize: 20,
    padding: 2,
  },
  denseItemText: {
    fontSize: 14,
  },
});

class TableModalActionMenu extends React.PureComponent {
  render() {
    const {
      anchorElLeftPosition,
      anchorElTopPosition,
      classes,
      dense,
      items,
      onActionMenuItemClick,
      onClose,
      open,
      row,
    } = this.props;

    return (
      <Popover
        anchorOrigin={{
          horizontal: anchorElLeftPosition || 'left',
          vertical: anchorElTopPosition || 'top',
        }}
        onClose={onClose}
        open={open}
      >
        <List>
          {
            items.map((item) => {
              if (item.hidden) return null;
              return (
                <ListItem
                  button
                  className={classNames({
                    [classes.denseItem]: dense,
                  })}
                  key={item.name}
                  onClick={() => onActionMenuItemClick(item.name, row)}
                >
                  <ListItemIcon>
                    <div className={classes.icon}>
                      {item.svgIcon}
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: dense ? classes.denseItemText : null,
                    }}
                    primary={item.label}
                  />
                </ListItem>
              );
            }
            )
          }
        </List>
      </Popover>
    );
  }
}

TableModalActionMenu.propTypes = {
  anchorElLeftPosition: PropTypes.number,
  anchorElTopPosition: PropTypes.number,
  classes: PropTypes.object,
  dense: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onActionMenuItemClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  row: PropTypes.object,
};

export default withStyles(styles)(TableModalActionMenu);
