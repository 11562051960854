/**
*
* MultiAutocomplete
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { match, parse } from 'autosuggest-highlight';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import { getArrayFromString } from './utils';

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    marginTop: 10,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  chip: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  autoPart: {
  },
});

class MultiAutocomplete extends React.Component {
  state = {
    value: '',
  };

  componentWillMount() {
    const { entity } = this.props;
    if (entity && entity.name != null) {
      this.setState({ value: entity.name });
    }
  }

  getSuggestionValue = (suggestion) => suggestion.text;

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.props.readOnly) return;
    this.props.loadOptions(value);
  };

  handleSuggestionsClearRequested = () => this.props.clearOptions();

  handleChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  handleBlur = () => {
    const { entity } = this.props;
    if (entity && entity.name != null) {
      this.setState({ value: entity.name });
    }
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    const { input: { onChange, value }, onUpdateComponent } = this.props;

    if (!value) {
      onChange([suggestion.value]);
    } else if (Array.isArray(value) && !value.includes(suggestion.value)) {
      value.push(suggestion.value);
      onChange(value);
      onUpdateComponent();
    }

    this.setState({ value: '' });
  };

  shouldRenderSuggestions = (suggestion) => { // eslint-disable-line
    if (suggestion) return true;
  };

  renderSuggestionsContainer = (options) => { // eslint-disable-line
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.text, query);
    const parts = parse(suggestion.text, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {
            parts.map((part, index) => (part.highlight
              ? (
                <span key={`${index + 1}part.text`} style={{ fontWeight: 300 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={`${index + 1}part.text`} style={{ fontWeight: 500 }}>
                  {part.text}
                </strong>
              )))
          }
        </div>
      </MenuItem>
    );
  };

  handleDeleteChip = (id) => () => {
    const { input: { onChange }, values } = this.props;
    const newValues = [];
    values.forEach((item) => {
      if (item.id !== id) {
        newValues.push(item.id);
      }
    });

    onChange(newValues);
  };

  renderChip = () => {
    const {
      values, classes, mode, record, input: { name },
    } = this.props;
    const names = getArrayFromString(record, name);

    return (
      <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
        { mode === 'view'
          ? names.map((item, index) => (
            <Chip
              className={classes.chip}
              label={item}
              key={`${item}_${index + 1}`}
              color="primary"
            />
          ))
          : Array.isArray(values) && values.map((item, index) => (
            <Chip
              className={classes.chip}
              label={item.name}
              key={`${item.id}_${item.name}_${index + 1}`}
              onDelete={mode !== 'view' ? this.handleDeleteChip(item.id) : null}
              color="primary"
            />
          ))
        }
      </Grid>
    );
  };

  renderInput = (inputProps) => {
    const {
      autoComplete,
      classes,
      helperText,
      meta: { touched, error, submitFailed },
      readOnly,
      ref,
      value,
      onUpdateComponent, // eslint-disable-line no-unused-vars
      ...restProps
    } = inputProps;

    return (
      <TextField
        margin="none"
        autoComplete={autoComplete || 'off'}
        error={(touched || submitFailed) && !!error}
        helperText={(touched || submitFailed) && error ? error : helperText}
        value={value}
        inputRef={ref}
        {...restProps}
        InputProps={{
          readOnly,
          classes: {
            input: classes.input,
          },
        }}
      />
    );
  };

  render() {
    const {
      classes,
      entity, // eslint-disable-line no-unused-vars
      input,
      clearOptions, // eslint-disable-line no-unused-vars
      loadOptions, // eslint-disable-line no-unused-vars
      entityName, // eslint-disable-line no-unused-vars
      onUpdateComponent, // eslint-disable-line no-unused-vars
      options,
      ...restProps
    } = this.props;

    return (
      <Grid container className={classes.container}>
        {this.renderChip()}
        <Grid item lg={12} sm={12} md={12} xl={12} xs={12} className={classes.autoPart}>
          <Autosuggest
            highlightFirstSuggestion
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderInputComponent={this.renderInput}
            suggestions={options}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            onSuggestionSelected={this.handleSuggestionSelected}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            shouldRenderSuggestions={this.shouldRenderSuggestions}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={{
              ...input,
              ...restProps,
              classes,
              value: this.state.value,
              onChange: this.handleChange,
              onBlur: this.handleBlur,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

MultiAutocomplete.propTypes = {
  onUpdateComponent: PropTypes.func.isRequired,
  values: PropTypes.array,
  mode: PropTypes.string,
  record: PropTypes.object,
  classes: PropTypes.object.isRequired,
  clearOptions: PropTypes.func.isRequired,
  entity: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  entityName: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  loadOptions: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
};

export default withStyles(styles)(MultiAutocomplete);
