/**
 *
 * RequestType constants
 *
 */

export const CARRYING_OUT_WORKS = '421534160000';
export const ON_CAR_ENTRY = '393629546000';
export const ON_PASS_MAKING = '437149164000';
export const ON_PROPERTY_BRINGING = '393629549000';
export const ON_PROPERTY_REMOVAL = '421534163000';
export const ON_VISIT = '393629542000';
export const SERVICE = '3724900074000';
export const ON_MATCHING = '3736492009000';
