import React, { Component } from 'react';
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw, } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { unemojify } from "node-emoji";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';


const styleSheet = {
  rootBorder: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: '#C0C0C0'
  },
  errorBorder: {
    borderColor: '#d50000'
  },

  rootLabel: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  errorLabel: {
    color: '#d50000'
  },
  disabledText: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
};

class EditorField extends Component {
  constructor(props) {
    super(props);
    this.delay = 200;
    this.state = {}
  }

  componentWillMount() {
    this.timeId = setTimeout(() => {
      const blocksFromHtml = htmlToDraft(this.props.input.value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      this.setState({
        editorState: EditorState.createWithContent(contentState)
      })
    }, this.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeId)
  }

  onEditorStateChange = editorState => {
    const { input: { onChange, value }, disabled } = this.props;
    if (!disabled) {
      const newValue = unemojify(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
      if (value !== newValue) {
        onChange(newValue);
      }
      this.setState({
        editorState
      });
    }
  };

  render() {
    const { editorState } = this.state;
    const { label, required, classes, meta: { error }, disabled, helperText } = this.props;

    return <div>
      <div className={classNames(classes.rootBorder, error && classes.errorBorder)} >
        <div className={classNames(classes.rootLabel, error && classes.errorLabel)}>
          {required ? `${label} *` : label}
        </div>
        <Editor
          toolbarHidden={disabled}
          toolbarClassName={classNames(classes.rootBorder, error && classes.errorBorder)}
          editorState={editorState}
          editorClassName={disabled && classes.disabledText}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ['inline', 'remove', 'colorPicker', 'link'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
            },
            link: {
              inDropdown: false,
            },
          }}
          localization={{
            locale: 'ru',
          }}
          handlePastedText={() => false}
        />
      </div >
      <div className={classNames(classes.rootLabel, error && classes.errorLabel)}>{error ? error : helperText}</div>
    </div >
  }
}

export default withStyles(styleSheet)(EditorField);