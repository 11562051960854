/**
 *
 * AgreementListDxGrid
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { columnType, columnSize, documentColumnSizes } from 'domain/typeConstants/table';


class AgreementListDxGrid extends React.PureComponent {

  getColumns() {
    return [
      {
        name: 'date',
        path: 'date',
        title: 'Дата',
        type: columnType.DATETIME,
        // direction: 'asc',
        width: columnSize.DATETIME,
      },
      {
        name: 'author',
        path: ['author', 'name'],
        title: 'Чья виза',
        getCellValue: (row) => row.author ? row.author.name : undefined,
        width: documentColumnSizes.author,
      },
      {
        name: 'agreed',
        path: 'agreed',
        title: 'Решение',
        width: documentColumnSizes.agreed,
      },
      {
        name: 'comment',
        path: 'comment',
        title: 'Комментарий',
        width: documentColumnSizes.comment,
      },
    ];
  }

  getColumnOrder() {
    return ['date', 'author', 'agreed', 'comment'];
  }

  getSorting() {
    return [{ columnId: 'date', columnName: 'date', direction: 'desc' }];
  }

  getActionButtons() {
    return []
  }

  getTableFeatures() {
    return [
      { name: settings.SETTING_BUTTON, value: false },
      { name: settings.UPDATE_BUTTON, value: false },
      { name: settings.TABLE_DRAWER, value: false },
    ];
  }

  getFilters() {
    return ['NoEmptyAgreed'];
  }

  render() {
    const { entityName, mode, parent, parentId, height, dialogName, name } = this.props;

    return (
      <AdvancedDataTable
        name={name}
        actionButtons={this.getActionButtons()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        tableType={settings.PAGING_TABLE}
        entityName={entityName}
        mode={mode}
        parent={parent}
        parentId={parentId}
        height={height}
        dialogName={dialogName}
      />
    );
  }
}

AgreementListDxGrid.propTypes = {
  viewName: PropTypes.string,
};

export default AgreementListDxGrid;
