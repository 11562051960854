/**
 *
 * WorkView constants
 *
 */

export const EXECUTE_ADD_ACTION = 'claris-vnext/Works/WorkView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/Works/WorkView/EXECUTE_EDIT_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/Works/WorkView/EXECUTE_COPY_ACTION';

export const WORK_GRID = 'WorkGrid';
export const USER_WORK_FORM_DIALOG = 'UserWorkFormDialog';
export const USER_WORK_FORM = 'UserWorkForm';
export const WORK_DELETE_FORM = 'WorkDeleteForm'
export const WORK_DELETE_FORM_DIALOG = 'WorkDeleteFormdialog'

