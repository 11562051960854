/**
 *
 * Tenant Rules DxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnSize, rulesColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import {
  ACTIVE_RULES, COMPANY_RULES, NEWS_RULES, NOT_NEWS_RULES,
} from 'domain/Data/Filters/constants';
import { TENANT_RULE_DATA_TABLE } from './constants';


export class TenantDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;

    const filters = [COMPANY_RULES];
    if (viewName === 'active') filters.push(ACTIVE_RULES);
    if (viewName === 'allNews') filters.push(NEWS_RULES);
    if (viewName === 'allDocumentsRules') filters.push(NOT_NEWS_RULES);
    return filters;
  }

  getColumns() {
    return [
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'validUntil',
        name: 'validUntil',
        title: 'Действует до',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'content',
        name: 'content',
        title: 'Содержание',
        type: columnType.FORMAT_TEXT,
        width: rulesColumnSizes.content,
      },
      {
        path: ['file', 'name'],
        name: 'file',
        getCellValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: columnSize.FILE,
      },
    ];
  }

  getColumnOrder() {
    return ['actualCreationDate', 'validUntil', 'content', 'file'];
  }

  getActionButtons() {
    return [];
  }

  getActionMenuItems() {
    return [];
  }

  getSorting() {
    return [{ columnId: 'actualCreationDate', columnName: 'actualCreationDate', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ACTION_MENU, value: false },
    ];
  }

  getDetailContainer() {
    return null;
  }

  render() {
    const { viewName } = this.props;

    return (
      <AdvancedDataTable
        entityName="documents"
        name={TENANT_RULE_DATA_TABLE}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        pagingPanelEntityTitle="документы"
        detailContainer={this.getDetailContainer()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

TenantDxGrid.propTypes = {
  viewName: PropTypes.string,
};
