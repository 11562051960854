/**
 *
 * FileField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';

import Clear from '@material-ui/icons/Clear';
import AttachFile from '@material-ui/icons/AttachFile';

import FieldProgress from 'components/FieldProgress';

const styleSheet = (theme) => ({
  fileInput: {
    display: 'none',
  },
  loadedFileInput: {
    cursor: 'pointer',
  },
  error: {
    color: theme.palette.error.main,
  },
});

class FileField extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleFileInputChange = (event) => {
    const { field, fileSize } = this.props;
    const { files } = event.currentTarget;

    if (files[0] && fileSize && (files[0].size > fileSize.size)) {
      this.props.showErrorMessageFile('Внимание', fileSize.errorTextFile);
      return;
    }
    if (files.length > 0) {
      this.props.saveFile(files[0], field.type);
    }
  };

  handleAttachButtonClick = () => {
    if (this.props.readOnly) return;
    if (!this.props.file) this.fileInput.click();
  };

  handleClearButtonMouseDown = (event) => {
    event.preventDefault();
  };

  handleClearButtonClick = (event) => {
    const { field } = this.props;
    event.stopPropagation();
    if (this.props.readOnly) return;
    this.props.clear(field.type);
  };

  render() {
    const {
      classes,
      classNames,
      clear, // eslint-disable-line no-unused-vars
      downloadFile, // eslint-disable-line no-unused-vars
      field,
      file,
      fullWidth,
      helperText,
      input: { value, name, ...restInputProps },
      label,
      meta: { touched, error, submitFailed }, // eslint-disable-line no-unused-vars
      readOnly,
      required,
      saveFile, // eslint-disable-line no-unused-vars
      fileSize,
      fieldName,
      showErrorMessageFile,
      ...restProps
    } = this.props;
    const inputValue = file ? file.name : '';
    const isError = (touched || submitFailed) && !!error;

    return (
      <FormControl
        fullWidth={fullWidth}
        ref={(input) => { this.formControl = input; }}
        onClick={(event) => {
          if (file) {
            event.preventDefault();
            this.props.downloadFile(file.id);
          }
        }}
        onMouseDown={(event) => {
          if (file) {
            event.preventDefault();
          }
        }}
        error={isError}
        required={required}
      >
        <Input
          type="file"
          className={classes.fileInput}
          name={`${name}-native`}
          inputRef={(input) => { this.fileInput = input; }}
          onChange={this.handleFileInputChange}
        />
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Input
          readOnly
          type="text"
          name={name}
          inputProps={{
            className: classnames({
              [classes.loadedFileInput]: !!file,
            }),
          }}
          inputRef={(input) => { this.textInput = input; }}
          fullWidth={fullWidth}
          value={inputValue}
          endAdornment={
            <InputAdornment position="end">
              {
                !readOnly && (
                  value ?
                    <IconButton
                      onClick={this.handleClearButtonClick}
                      onMouseDown={this.handleClearButtonMouseDown}
                    >
                      <Clear />
                    </IconButton>
                    :
                    <IconButton
                      onClick={this.handleAttachButtonClick}
                    >
                      <AttachFile className={classnames({ [classes.error]: isError })} />
                    </IconButton>
                )
              }
            </InputAdornment>
          }
          {...restInputProps}
          {...restProps}
          onBlur={() => undefined}
        />
        {
          field.progress &&
          <FieldProgress mode={field.progressMode} value={field.progressValue} />
        }
        {isError
          && <FormHelperText className={classNames.helperText}>обязательное поле</FormHelperText>
        }
        {
          !isError && helperText
          && <FormHelperText className={classNames.helperText}>{helperText}</FormHelperText>
        }
      </FormControl>
    );
  }
}

FileField.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
  clear: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  field: PropTypes.object,
  file: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  saveFile: PropTypes.func.isRequired,
};

FileField.defaultProps = {
  classNames: {},
};

export default withStyles(styleSheet)(FileField);
