/**
 *
 * AgreementDialog actions
 *
 */

import {
  CLOSE_AGREEMENT_DIALOG,
  OPEN_AGREEMENT_DIALOG,
  SUBMIT_AGREEMENT_DIALOG,
  LOAD_OPTIONS,
  SET_MANAGER_ENTITY_IDS,
  SET_MANAGERS_VALUES,
} from './constants';

export function loadOptions(entityName, options) {
  return {
    type: LOAD_OPTIONS,
    payload: { entityName, options },
  };
}

export function setManagerFieldIds(ids) {
  return {
    type: SET_MANAGER_ENTITY_IDS,
    payload: { ids },
  };
}

export function setManagersValues(ids) {
  return {
    type: SET_MANAGERS_VALUES,
    payload: { ids },
  };
}

export function openAgreementDialog(dataTable, { id }) {
  return {
    type: OPEN_AGREEMENT_DIALOG,
    meta: { dataTable },
    payload: { id },
  };
}

export function submitAgreementDialog(requestId, managers) {
  return {
    type: SUBMIT_AGREEMENT_DIALOG,
    payload: {
      requestId, managers,
    },
  };
}

export function closeAgreementDialog() {
  return {
    type: CLOSE_AGREEMENT_DIALOG,
  };
}
