/**
 *
 * TenantDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  ACTIVE_REQUESTS,
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_TENANT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
} from 'domain/Data/Filters/constants';

import {
  addActionButton,
  copyActionButton,
  closeActionButton,
  viewActionButton,
} from '../common/actionButton';
import {
  viewActionItem,
  copyActionItem,
  closeActionItem,
} from '../common/actionMenu';
import { columnStatusLabels } from '../common/columnStatusLabel';

import { TENANT_REQUESTS_TABLE_GRID } from './constants';

class TenantDxGrid extends React.PureComponent {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_TENANT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    return filters;
  }

  getColumns() {
    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО посетителя',
        width: requestColumnSizes.visitorFullName,
      },
      {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
      },
      {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
      },
      {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        entityName: 'requestStatuses',
        width: requestColumnSizes.statusName,
      },
      {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        entityName: 'requestTypes',
        width: requestColumnSizes.typeShortName,
      },
      {
        path: 'passNumber',
        name: 'passNumber',
        title: '№ пропуска',
        width: requestColumnSizes.passNumber,
      },
      {
        path: ['parking', 'name'],
        name: 'parkingName',
        getCellValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
        entityName: 'parkings',
        width: requestColumnSizes.parkingName,
      },
      {
        path: 'parkingPlace',
        name: 'parkingPlace',
        title: 'Место',
        width: requestColumnSizes.parkingPlace,
      },
      {
        path: 'materialValuesData',
        name: 'materialValuesData',
        title: 'Мат. ценности',
        width: requestColumnSizes.materialValuesData,
      },
      {
        path: 'whatHappened',
        name: 'whatHappened',
        title: 'Что случилось',
        width: requestColumnSizes.whatHappened,
      },
      {
        path: 'implementationReport',
        name: 'implementationReport',
        title: 'Отчет о выполнении',
        width: requestColumnSizes.implementationReport,
      },
      {
        path: ['service', 'name'],
        name: 'serviceName',
        getCellValue: (row) => (row.service ? row.service.name : undefined),
        title: 'Сервис',
        width: requestColumnSizes.serviceName,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
      },
      {
        path: 'managementCompanyComment',
        name: 'managementCompanyComment',
        title: 'Комментарий УК',
        width: requestColumnSizes.note,
      },
      {
        path: ['author', 'name'],
        name: 'authorName',
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: requestColumnSizes.authorName,
      },
    ];
  }

  getColumnOrder() {
    return [
      'number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
      'passNumber', 'parkingName', 'parkingPlace', 'materialValuesData', 'whatHappened', 'implementationReport',
      'serviceName', 'note', 'managementCompanyComment', 'authorName',
    ];
  }

  getActionButtons() {
    return [addActionButton, viewActionButton, copyActionButton, closeActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, copyActionItem, closeActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <AdvancedDataTable
        entityName="requests"
        name={TENANT_REQUESTS_TABLE_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

TenantDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default TenantDxGrid;
