/*
 * ChangePasswordPage sagas
 */

import { fork } from 'redux-saga/effects';
import changePasswordFormSaga from 'containers/ChangePasswordForm/sagas';

export default function* rootSaga() {
  yield fork(changePasswordFormSaga);
}

