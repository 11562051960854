/**
 *
 * AdministratorBC Service Requests Form 14140
 *
 */

import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { formField } from 'domain/typeConstants/form';
import { COMPANIES, DEPARTMENTS } from 'domain/Data/constants';

import {
  departmentHelperText,
  departmentCalculatedValue,
} from '../common/fields';

export class AdministratorBCServiceRequestsForm14140 extends ServiceRequestsManagerForm {
  getFields() {
    const fields = super.getFields();

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields[assigneeIndex].rowBreakAfter = false;

    fields.splice(assigneeIndex + 1, 0, {
      cached: false,
      type: formField.SELECT,
      entityName: COMPANIES,
      name: 'company',
      label: 'Арендатор',
      visible: true,
      rowBreakBefore: true,
      initialValue: (values, mode, user) => user.companyId,
      numberInQueueFromEnd: 1,
    }, {
      type: formField.SELECT,
      entityName: DEPARTMENTS,
      name: 'department',
      label: 'Здание',
      visible: true,
      required: true,
      calculatedValue: departmentCalculatedValue,
      helperText: departmentHelperText,
    });

    return fields;
  }
}
