/**
 *
 * AdministratorBC Service Requests Form 14366
 *
 */

import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { department, company } from '../common/fields';

export class AdministratorBCServiceRequestsForm14366 extends ServiceRequestsManagerForm {
  getFields() {
    const fields = super.getFields();

    const companyIndex = fields.findIndex((field) => field.name === 'company');
    fields.splice(companyIndex, 1);

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields[assigneeIndex].rowBreakAfter = false;

    fields.splice(assigneeIndex + 1, 0, company, department);

    const whereHappenedIndex = fields.findIndex((field) => field.name === 'whereHappened');
    fields[whereHappenedIndex].required = true;

    return fields;
  }
}
