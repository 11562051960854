/*
 *
 * ReceptionSendToAgreementByTenantFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM, RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG } from './constants';

function ReceptionSendToAgreementByTenantFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG}
      formName={RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM}
      advancedForm={form}
      editModeTitle="Согласовать с арендатором"
    />
  );
}

ReceptionSendToAgreementByTenantFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ReceptionSendToAgreementByTenantFormDialog;
