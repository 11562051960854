/**
 *
 * Record types of entities
 *
 *
 */

import { List, Record } from 'immutable';


/** ===============================
 *        WORK RECORDS
 * ============================== */

export const Works = Record({
  id: null,
  runDate: null,
  task: null,
  duration: null,
  description: null,
  author: null,
  request: null,
})

export const WorkDuration = Record({
  id: null,
  name: null,
});

export const WorkRunDate = Record({
  id: null,
  name: null,
});

export const WorkAuthor = Record({
  id: null,
  name: null,
});

export const WorkDescription = Record({
  id: null,
  name: null,
});

export const WorkTask = Record({
  id: null,
  name: null,
});

export const WorkRequestTask = Record({
  id: null,
  name: null,
});

/** ===============================
 *        TASKS RECORDS
 * ============================== */

export const RequestTask = Record({
  id: null,
  number: null,
  finishDate: null,
  registrationDate: null,
  description: null,
  author: null,
  responsible: null,
  status: null,
  company: null,
  file: null,
  name: null,
  comment: null,
  time: null,
  attachments: [],
  taskChangeDate: null,
  lastAction: null,
  type: null,
  department: null,
  executors: null,
  document: null,
  documentName: null,
})



export const TaskNumber = Record({
  id: null,
  name: null,
});

export const TaskFinishDate = Record({
  id: null,
  name: null,
});

export const TaskRegistrationDate = Record({
  id: null,
  name: null,
});

export const TaskDescription = Record({
  id: null,
  name: null,
});

export const TaskAuthor = Record({
  id: null,
  name: null,
});

export const TaskResponsible = Record({
  id: null,
  name: null,
});


export const TaskStatus = Record({
  id: null,
  name: null,
  taskType: null,
  statusZadachi: null,
});

export const TaskCompany = Record({
  id: null,
  name: null,
});

export const TaskFile = Record({
  id: null,
  name: null,
});

export const TaskName = Record({
  id: null,
  name: null,
});

export const TaskComment = Record({
  id: null,
  name: null,
});

export const TaskTime = Record({
  id: null,
  name: null,
});

export const CountAttachments = Record({
  id: null,
  name: null,
});

export const TaskChangeDate = Record({
  id: null,
  name: null,
});

export const LastAction = Record({
  id: null,
  name: null,
});

export const TaskType = Record({
  id: null,
  name: null,
});


/** ===============================
 *        TASKS CHANGES RECORDS
 * ============================== */

export const TaskChange = Record({
  id: null,
  name: null,
  changeDate: null,
  createdBy: null,
})

/** ===============================
 *        REQUESTS RECORDS
 * ============================== */
export const Request = Record({
  accessCard: null,
  accessCardKey: null,
  actualCreationDate: null,
  arrivalDate: null,
  assignee: null,
  attachments: [],
  author: null,
  availableTenantCheckpoints: null,
  blackListNote: null,
  carModel: null,
  carModelText: null,
  carNumber: null,
  comments: [],
  company: null,
  companyName: null,
  contactPersonFullName: null,
  customer: null,
  department: null,
  companyDepartment: null,
  departureDate: null,
  documentDetails: null,
  documentType: null,
  endWork: null,
  executionDate: null,
  executionTerm: null,
  expirationDate: null,
  requestChangeDate: null,
  file: null,
  floor: null,
  groupRequestVisitorsData: null,
  housing: null,
  id: null,
  implementationReport: null,
  informed: false,
  isBlackList: null,
  isGroupRequest: null,
  isFired: null,
  issueReason: null,
  isToday: null,
  longterm: false,
  manager: null,
  materialValuesData: null,
  materialValuesDestinationPlace: null,
  materialValuesMovementTime: null,
  materialValuesPickupPlace: null,
  maxEntriesNumber: null,
  multipleEntry: false,
  metersData: null,
  name: null,
  nonstandardCarNumber: null,
  note: null,
  number: null,
  parking: null,
  parkingPlace: null,
  parkingType: null,
  passNumber: null,
  passType: null,
  passportPhoto: null,
  photo: null,
  reservationTime: null,
  requestPriority: null,
  responsibleFromContractor: null,
  responsibleFromContractorPhone: null,
  responsibleFromTenant: null,
  isOffsetCard: false,
  service: null,
  startWork: null,
  status: null,
  time: null,
  type: null,
  vehicleType: null,
  visitDate: null,
  visitorFullName: null,
  whatHappened: null,
  whereHappened: null,
  whoMeets: null,
  whoMovesCargo: null,
  workingTime: null,
  workPlace: null,
  workType: null,
  agreedEmployee: null,
  managementCompanyComment: null,
  isDiscretionOfSecurity: false,
  jobDoing: null,
  isKrylatsky: null,

  // atc berlinksy/genevsky
  berlinksyAnotherTime: null,
  berlinskyAccessArea: null,
  berlinskyAccessAreas: null,
  berlinskyAccessAreasText: null,
  berlinskyActionCard: null,
  berlinskyMaterialCommodityValueTypes: null,
  berlinskyMaterialCommodityValueTypesText: null,
  berlinskyTime: null,
  berlinskyVisitTime: null,
  isGiveRemoteController: false,
  remoteControllerNumber: null,

  // bc khimki
  isReadInstruction: null,
  isTechnicalServiceMonitoring: null,
  khimkiAccessLevel: null,
  khimkiAccessPremises: null,
  khimkiEmailGuest: null,
  khimkiEmailMeeting: null,
  khimkiGuestPhone: null,
  khimkiRequestType: null,
  khimkiResponsibleFromTenant: null,
  khimkiTenantComment: null,
  khimkiTime: null,
  phone: null,
  startWorkingHour: null,
  endWorkingHour: null,
  parkings: null,
  parkingsText: null,
  isAllowProcessPersonalData: null,
  isAllowProcessPersonalDataMessage: null,
  isTrainingPass: null,
  isTerminal: null,
  trainingDate: null,
  trainingResult: null,
  trainingStatus: null,
  memorandumScan: null,
  // bp faktoriya
  position: null,
  carryInOrTakeawayMCV: null,
  fireWork: null,
  productionPersonnel: null,
  responsibilityForWork: null,
  responsibleForFireSafety: null,
  responsibleForSafetyAndHealth: null,
  shutdownEquipment: null,
  requestScan: null,
  factoryTime: null,
  isInstructed: false,
  // vozdvizhenka
  vodvizhenkaAccessRight: null,
  vozdvizhenkaAccessLevel: null,
  vozdvizhenkaTime: null,
  needDisableOrChangeSystem: false,
  // gorkiy park
  acceptedByTenant: null,
  estimationCost: null,
  estimationCostScan: null,

  // bc_lesnaya
  payableService: null,

  // krylatskie
  checkpointPost: null,
  desiredDate: null,
});

export const Attachment = Record({
  id: null,
  name: null,
});

export const Company = Record({
  id: null,
  name: null,

  adress: null,
  contactPerson: null,
  department: null,
  fullName: null,
  housings: null,
  note: null,
  office: null,
  phoneContactPerson: null,
  parkings: null,
  departments: null,
  requestTypes: null,
  status: null,
  type: null,

  phone: null,
  client: null,
});

export const ContractorStatus = Record({
  id: null,
  name: null,
});

export const ContractorType = Record({
  id: null,
  name: null,
});

export const Employee = Record({
  id: null,
  name: null,
  availableHousings: null,
  company: null,
  department: null,
  isPerformer: null,
  position: null,
  isMatching: false,
  phone: null,
  email: null,
  tenantPermanentAccessCardKey: null,
});

export const UserInfo = Record({
  id: null,
  login: null,
  name: null,
  companyId: null,
  departmentId: null,
  accountId: null,
  accountName: null,
  isAuthenticated: false,
  forcePasswordChange: null,
  accessToken: null,
  defaultRequestTypeId: null,
  isAutoUpdate: null,
  checkpointPostId: null,
  roles: new List([]),
  date: null,
});

export const VisitTime = Record({
  id: null,
  name: null,
});

export const RequestStatus = Record({
  id: null,
  name: null,
});

export const RequestType = Record({
  id: null,
  name: null,

  khimkiNumber: null,
  order: null,
  shortName: null,
});

export const Service = Record({
  id: null,
  name: null,
  manager: null,
});

export const Manager = Record({
  id: null,
  name: null,
});

export const Department = Record({
  id: null,
  name: null,
});

export const DocumentType = Record({
  id: null,
  name: null,
});

export const AccessCard = Record({
  id: null,
  name: null,
  number: null,
  key: null,
});

export const ParkingType = Record({
  id: null,
  name: null,
});

export const Parking = Record({
  aceptedRequestsCount: null,
  busyPlacesCount: null,
  capacity: null,
  freeGuestPlacesCount: null,
  id: null,
  name: null,
  type: null,
});

export const CarModel = Record({
  id: null,
  name: null,
});

export const BlackList = Record({
  actualCreationDate: null,
  carModelText: null,
  carNumber: null,
  company: null,
  id: null,
  level: null,
  name: null,
  note: null,
  visitorFullName: null,
});

export const WorkingTime = Record({
  id: null,
  name: null,
});

export const Time = Record({
  id: null,
  name: null,
  number: null,
});

export const IssueReason = Record({
  id: null,
  name: null,
  isKrylatsky: null,
});

export const PassType = Record({
  id: null,
  name: null,
});

export const VehicleType = Record({
  id: null,
  name: null,
});

export const Comment = Record({
  id: null,
  text: null,
});

export const RequestPriority = Record({
  id: null,
  name: null,
});

export const ReservationTime = Record({
  id: null,
  name: null,
});

export const File = Record({
  id: null,
  name: null,
});

export const WorkType = Record({
  id: null,
  name: null,

  isKhimki: false,
  number: null,
});

export const JobDoing = Record({
  id: null,
  name: null,
});

/** requests @bc-comcity(12970) */
export const Housing = Record({
  id: null,
  name: null,
});

/** requests @atc-berlinsky-genevsky(14140) */
export const AccessArea = Record({
  id: null,
  name: null,
});

/** requests @vozdvizhenka(14691) */
export const VozdvizhenkaAccessLevel = Record({
  id: null,
  name: null,
});

export const ActionCard = Record({
  id: null,
  name: null,
});

export const MaterialCommodityValueType = Record({
  id: null,
  name: null,
});

/** requests @bc-khimki(14326) */
export const KhimkiRequestType = Record({
  id: null,
  name: null,
});

export const AccessLevel = Record({
  id: null,
  name: null,
  orderKhimki: null,
});

export const WorkingHour = Record({
  id: null,
  name: null,
});

/** requests @bp-factoriya(14419) */
export const Position = Record({
  id: null,
  name: null,
});


/** ===============================
 *        REQUESTS CHANGES RECORDS
 * ============================== */

export const RequestChanges = Record({
  id: null,
  name: null,
  changeDate: null,
  createdBy: null,
})

export const RequestChangeFinishDate = Record({
  id: null,
  name: null,
});

export const RequestChangeRegistrationDate = Record({
  id: null,
  name: null,
});

export const RequestChangeResponsible = Record({
  id: null,
  name: null,
});

export const RequestChangeStatus = Record({
  id: null,
  name: null,
});

/** ===============================
 *        USERS RECORDS
 * ============================== */
export const User = Record({
  id: null,
  name: null,
  fullName: null,
  email: null,
  isGroup: null,
  isBlocked: null,
  password: null,
  passwordConfirmation: null,
  forcePasswordChange: null,
  rolesText: null,
  employee: null,
  company: null,
  department: null,
  roles: [],
  isSendEmail: false,
  phone: null,
  identityBlock: null,
  tenantPermanentAccessCardKey: null,
});

export const Role = Record({
  id: null,
  name: null,
  order: null,
});

/** ===============================
 *        NOTIFICATIONS RECORDS
 * ============================== */
export const Notification = Record({
  id: null,
  name: null,
  actualCreationDate: null,
  heading: null,
  content: null,
  isRead: null,
  type: null,
  category: null,
  author: null,
  receiver: null,
});

export const NotificationType = Record({
  id: null,
  name: null,
  category: null,
});

export const NotificationCategory = Record({
  id: null,
  name: null,
});

/** ===============================
 *        DOCUMENTS RECORDS
 * ============================== */
export const Document = Record({
  actualCreationDate: null,
  lastChangedDate: null,
  addedDate: null,
  agreementScheme: null,
  agreementStep: null,
  amount: null,
  attachments: [],
  author: null,
  category: null,
  company: null,
  content: null,
  contract: null,
  file: null,
  id: null,
  kind: null,
  newsTopic: null,
  note: null,
  number: null,
  responsible: null,
  scan: null,
  state: null,
  validUntil: null,
  isNews: null,
  objectStructure: null,
  serialNumber: null,
  deliverySchedule: null,
  name: null,
  project: null,
  autoNumber: null,
  positions: null,
  manager: null,
  director: null,
  pto: null,
  supplier: null,
});

export const DocumentCategory = Record({
  documentCategory: null,
  id: null,
  name: null,
});

export const DocumentKind = Record({
  id: null,
  name: null,
});

export const DocumentState = Record({
  id: null,
  name: null,
});

export const AgreementStep = Record({
  description: null,
  id: null,
  name: null,
  sightingEmployeesText: null,
});

export const Contract = Record({
  company: null,
  id: null,
  name: null,
  subject: null,
  type: null,
});

export const ContractType = Record({
  id: null,
  name: null,
});

export const ObjectStructure = Record({
  id: null,
  name: null,
});

export const AgreementHistory = Record({
  agreed: null,
  author: null,
  comment: null,
  date: null,
  document: null,
  id: null,
  name: null,
  responsible: null,
  scheme: null,
  state: null,
  documentNumber: null,
  documentCreationDate: null,
  company: null,
  content: null,
});

export const AgreementScheme = Record({
  description: null,
  id: null,
  name: null,
  outdated: false,
  state: null,
  lastAgreementStep: null,
});

export const AgreementState = Record({
  description: null,
  id: null,
  name: null,
  number: null,
});

export const Project = Record({
  id: null,
  name: null,
  director: null,
  supplier: null,
  manager: null,
  pto: null,

});

export const EstimateItem = Record({
  id: null,
  name: null,
  available: null,
  countPlan: null,
  countFact: null,
  countAvailable: null,
  countReserv: null,
});

/** ===============================
 *        DOCUMENTS CHANGES RECORDS
 * ============================== */

export const DocumentChanges = Record({
  id: null,
  name: null,
  changeDate: null,
  createdBy: null,
})

export const DocumentChangeFinishDate = Record({
  id: null,
  name: null,
});

export const DocumentChangeRegistrationDate = Record({
  id: null,
  name: null,
});

export const DocumentChangeResponsible = Record({
  id: null,
  name: null,
});

export const DocumentChangeStatus = Record({
  id: null,
  name: null,
});

/** ===============================
 *        DOCUMENTS POSITIONS RECORDS
 * ============================== */

export const DocumentPositions = Record({
  id: null,
  name: null,
  asset: null,
  assetText: null,
  creationDate: null,
  desiredDate: null,
  received: null,
  changedDate: null,
  positionsCount: null,
  description: null,
  state: null,
  amount: null,
  supplierInvoice: null,
  price: null,
  document: null,
  estimateItem: null,
  available: null,
})

export const PositionDocument = Record({
  id: null,
  name: null,
})

export const GoodsAndServices = Record({
  id: null,
  name: null,
})

export const DocumentPositionStates = Record({
  id: null,
  name: null,
});

export const DocumentSupplierInvoice = Record({
  id: null,
  name: null,
  deliverySchedule: null,
});



/** ===============================
 *      VISITOR LOGS RECORDS
 * ============================== */
export const VisitorLog = Record({
  arrivalDate: null,
  author: null,
  departureDate: null,
  id: null,
  name: null,
  request: null,
});

/** ===============================
 *      CONTACTS RECORDS
 * ============================== */

export const Contact = Record({
  id: null,
  name: null,
  order: null,
  workTime: null,
  email: null,
  phone: null,
});

/** ===============================
 *      DATA TABLE RECORDS
 * ============================== */
export const DataTableColumn = Record({
  dataType: null,
  getCellValue: null,
  header: null,
  id: null,
  labels: null,
  name: null,
  path: null,
  selection: null,
  styles: null,
  title: null,
  type: null,
  entityName: null,
  label: null,
  width: null,
  sortable: true,
});

/** ===============================
 *         FORM RECORDS
 * ============================== */
export const FormField = Record({
  autoFocus: false,
  disabled: false,
  helperText: null,
  id: null,
  label: null,
  multiline: false,
  name: null,
  required: false,
  tooltip: null,
  type: null,
  view: null,
  visible: true,

  calculatedValue: null,
  initialValue: null,
  normalize: null,
  validate: null,

  clearErrorsOnChange: null,
  grid: null,
  max: null,
  min: null,
  rowBreakAfter: false,
  rowBreakBefore: false,

  cached: true,
  entityName: null,
  filters: null,
  filterBy: null,
  filter: null,
  filterEntityName: null,
  loaded: false,
  loadedOptions: null,
  orderBy: null,
  visibleOptions: null,
  compareOptions: null,

  dadataParts: null,
  dadataType: null,
  suggestions: [],

  progress: false,
  progressMode: null,
  progressValue: null,
  numberInQueueFromEnd: null,

  fileSize: null,
  fieldName: null,
  additional: false,
  errorHelperText: null,
});
