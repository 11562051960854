/*
 *
 * AdvancedDataTable reducer
 *
 */

import { OrderedMap, Map, List } from 'immutable';
import { recordsFromArray, keyFromValue } from 'utils/immutable';

import * as recordTypes from 'domain/Data/recordTypes';
import {
  CHANGE_SELECTION,
  CLEAR_SELECTION,
  CHANGE_MENU_ITEM,
  CLEAR_SEARCH_QUERY,
  CHANGE_SEARCH_QUERY,
  CHANGE_GRID_FILTERS,
  CLEAR_FILTER_DIALOG,
  LOAD_DATA,
  LOAD_DATA_SUCCESS,
  REGISTER,
  UNREGISTER,
  CHANGE_COLUMN_ORDER,
  CHANGE_COLUMNS,
  CHANGE_COLUMN_WIDTHS,
  CHANGE_EXPANDED_ROW_IDS,
  CHANGE_LOADER_STATE,
  CHANGE_LAZY_LOADING_STATE,
  CHANGE_VISIBLE_COLUMNS,
  CHANGE_CURRENT_RECORD,
  CHANGE_TAB,
  CLEAR_DOWNLOAD_LINK,
  SAVE_FILE_EXTENSION,
  SET_DOWNLOAD_LINK,
  CLEAR_IDS,
  OPEN_PREVIEW_DRAWER,
} from './constants';

function advancedDataTableReducer(state = new Map({}), { type, meta, payload }) {

  switch (type) {
    case CHANGE_SELECTION:
      return state.setIn([meta.dataTable, 'selection'], new List(payload.selection));

    case CLEAR_SELECTION:
      return state.setIn([meta.dataTable, 'selection'], new List([]));

    case CHANGE_MENU_ITEM:
      return state.setIn([meta.dataTable, 'itemMenuRecord'], new List(payload.recordId));

    case CHANGE_COLUMN_ORDER:
      return state.setIn([meta.dataTable, 'columnOrder'], new List(payload.columnOrder));

    case CHANGE_COLUMN_WIDTHS:
      return state.setIn([meta.dataTable, 'columnWidths'], new List(payload.columnWidths));

    case CHANGE_VISIBLE_COLUMNS:
      return state.setIn([meta.dataTable, 'columnVisibilityNames'], new List(payload.hiddenColumns));

    case CHANGE_COLUMNS:
      return state.setIn([meta.dataTable, 'columns'], makeColumnsOrderedMap(payload.columns));

    case CHANGE_EXPANDED_ROW_IDS:
      return state
        .setIn([meta.dataTable, 'tab'], 0)
        .setIn([meta.dataTable, 'expandedRowIds'], new List(payload.expandedRowIds));

    case CLEAR_IDS:
      return state.setIn([meta.dataTable, 'ids'], new List([]));

    case CLEAR_SEARCH_QUERY:
      return state.setIn([meta.dataTable, 'searchQuery'], null);

    case CHANGE_SEARCH_QUERY:
      return state.setIn([meta.dataTable, 'searchQuery'], payload.searchQuery);

    case CLEAR_DOWNLOAD_LINK:
      return state.setIn([meta.dataTable, 'downloadLink'], null);

    case SAVE_FILE_EXTENSION:
      return state.setIn([meta.dataTable, 'extension'], payload.extension);

    case SET_DOWNLOAD_LINK:
      return state.setIn([meta.dataTable, 'downloadLink'], payload.link);

    case CHANGE_GRID_FILTERS:
      return state
        .setIn([meta.dataTable, 'gridFilters'], payload.gridFilters)
        .setIn([meta.dataTable, 'pageNumber'], 1);

    case CHANGE_CURRENT_RECORD:
      return state.setIn([meta.dataTable, 'currentRecord'], payload.record);

    case CHANGE_LAZY_LOADING_STATE:
      return state.setIn([meta.dataTable, 'lazyLoadingState'], payload.lazyLoadingState);

    case CLEAR_FILTER_DIALOG:
      return state.setIn([meta.dataTable, 'clearFilterDialog'], payload.isClear);

    case CHANGE_LOADER_STATE:
      return state.setIn([meta.dataTable, 'loaderState'], payload.loaderState);

    case CHANGE_TAB:
      return state.setIn([meta.dataTable, 'tab'], payload.id);

    case OPEN_PREVIEW_DRAWER:
      return state
        .setIn([meta.dataTable, 'isOpenDrawer'], payload.mode)
        .setIn([meta.dataTable, 'drawerRecord'], payload.drawerRecord);

    case LOAD_DATA: {
      let newState = state;
      if (payload.pageSize) {
        newState = newState.setIn([meta.dataTable, 'pageSize'], payload.pageSize);
      }

      if (payload.pageNumber) {
        newState = newState.setIn([meta.dataTable, 'pageNumber'], payload.pageNumber);
      }

      if (payload.sorting) {
        newState = newState.setIn([meta.dataTable, 'sorting'], new List(payload.sorting));
      }

      if (payload.filters) {
        newState = newState.setIn([meta.dataTable, 'filters'], new List(payload.filters));
      }

      if (payload.searchQuery) {
        newState = newState.setIn([meta.dataTable, 'searchQuery'], payload.searchQuery);
      }

      return newState;
    }

    case LOAD_DATA_SUCCESS:
      return state.setIn([meta.dataTable, 'ids'], new List(payload.ids));

    case REGISTER:
      let newState = state;
      newState = newState
        .setIn([meta.dataTable, 'columns'], makeColumnsOrderedMap(payload.columns))
        .setIn([meta.dataTable, 'columnOrder'], new List(payload.columnOrder))
        .setIn([meta.dataTable, 'columnWidths'], new List(payload.columnWidths))
        .setIn([meta.dataTable, 'columnVisibilityNames'], new List(payload.columnVisibilityNames))
        .setIn([meta.dataTable, 'entityName'], payload.entityName)
        .setIn([meta.dataTable, 'itemMenuRecord'], new List([]))
        .setIn([meta.dataTable, 'selection'], new List([]))
        .setIn([meta.dataTable, 'sorting'], new List(payload.sorting))
        .setIn([meta.dataTable, 'filters'], new List(payload.filters))
        .setIn([meta.dataTable, 'clearFilterDialog'], false)
        .setIn([meta.dataTable, 'gridFilters'], new List(payload.gridFilters))
        .setIn([meta.dataTable, 'searchQuery'], null)
        .setIn([meta.dataTable, 'loaderState'], false)
        .setIn([meta.dataTable, 'lazyLoadingBlockSize'], payload.lazyLoadingBlockSize)
        .setIn([meta.dataTable, 'actionButtons'], new List(payload.actionButtons))
        .setIn([meta.dataTable, 'actionMenuItems'], new List(payload.actionMenuItems))
        .setIn([meta.dataTable, 'pageSize'], payload.pageSize)
        .setIn([meta.dataTable, 'pageSizes'], payload.pageSizes)
        .setIn([meta.dataTable, 'pageNumber'], payload.pageNumber)
        .setIn([meta.dataTable, 'expandedRowIds'], new List([]))
        .setIn([meta.dataTable, 'tab'], 0)
        .setIn([meta.dataTable, 'isOpenDrawer'], false)
        .setIn([meta.dataTable, 'drawerRecord'], null)
        .setIn([meta.dataTable, 'currentRecord'], null);
      if (payload.detailContainerNames) {
        newState = newState.setIn([meta.dataTable, 'detailContainerNames'], payload.detailContainerNames);
      }
      if (payload.ids) {
        newState = newState.setIn([meta.dataTable, 'ids'], new List([]));
      }

      return newState

    case UNREGISTER:
      return state.delete(meta.dataTable);

    default:
      return state;
  }
}

function makeColumnsOrderedMap(columns) {
  const columnsWithIds = columns.map((column) =>
    ({ ...column, id: keyFromValue(column.path) })
  );
  return new OrderedMap(
    recordsFromArray(recordTypes.DataTableColumn, columnsWithIds)
  );
}

export default advancedDataTableReducer;
