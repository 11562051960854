/**
 *
 * DashboardView
 *
 * 
 */

import React from 'react';
import { push } from 'react-router-redux';
import DashboardView from './DashboardView';
import * as actionCreators from './actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectAccountId, makeSelectRoles } from 'domain/User/selectors';
import { makeSelectViewMode } from 'domain/Screen/selectors';

class DashboardViewContainer extends React.PureComponent {

  getAccesKey = () => {
    const { roles, accountId, viewMode } = this.props;

    let dataTableName;
    if (roles.includes('administratorBC') || roles.includes('reception')) { dataTableName = 'ReceptionRequestsTableGrid' }
    else if (roles.includes('tenant')) { dataTableName = 'TenantRequestsTableGrid' }

    return `${accountId}_${dataTableName}_all_${viewMode === 'mobile' ? 'mobileTable' : 'mainTable'}`;
  }

  render() {
    return (
      <DashboardView {...this.props} accessKey={this.getAccesKey()} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
  accountId: makeSelectAccountId(),
  viewMode: makeSelectViewMode()
});

const mapDispatchToProps = (dispatch) => ({
  loadData: (entityName, options) => dispatch(actionCreators.loadDataAction(entityName, options)),
  saveRange: (entityName, options) => dispatch(actionCreators.saveRangeAction(entityName, options)),
  setFilter: (accessKey, data) => dispatch(actionCreators.setFilterAction(accessKey, data)),
  navigate: (link) => dispatch(push(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardViewContainer);
