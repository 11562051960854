/**
 *
 * getViewConfig Function
 *
 */

import { role } from 'domain/typeConstants/roles';
import { getAdministratorBCView } from './getRoleView';
import getSagasConfig from './getSagasConfig';
import createAdministratorBCViewSaga from './UsersView/sagas';

const defaultViewConfig = {
  createComponent: getAdministratorBCView,
  createSaga: (sagaConfig) => createAdministratorBCViewSaga(sagaConfig.AdministratorBCViewSaga),
};

const viewConfigs = [
  {
    role: role.ADMINISTRATOR_BC,
    createComponent: getAdministratorBCView,
    createSaga: (sagaConfig) => createAdministratorBCViewSaga(sagaConfig.AdministratorBCViewSaga),
  },
];

export function createViewComponent(userRoles, props) {
  const config = viewConfigs.find((c) => userRoles.includes(c.role));
  if (config) {
    return config.createComponent(props);
  }
  return defaultViewConfig.createComponent(props);
}

export function createViewSaga(userRoles, accountId) {
  const config = viewConfigs.find((c) => userRoles.includes(c.role));
  if (config) {
    return config.createSaga(getSagasConfig(accountId));
  }
  return defaultViewConfig.createSaga(getSagasConfig(accountId));
}
