/**
 *
 * SettingsPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import queryString from 'query-string';

import { makeSelectBreakpoint } from 'domain/Screen/selectors';
import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';

import { getSettingsPage } from './getSettingsPage';
import saga from './sagas';

class SettingsPageContainer extends React.PureComponent {
  render() {
    const { accountId, userRoles, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, ...restProps };

    return (getSettingsPage(accountId, props));
  }
}

SettingsPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
  breakpoint: makeSelectBreakpoint(),
});

const reducer = (state = {}) => state;
const withReducer = injectReducer({ key: 'settingPage', reducer });
const withSaga = injectSaga({ key: 'settingPage', saga });
const withConnect = connect(mapStateToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(SettingsPageContainer);
