/**
 *
 * TenantDxGrid 14419
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';
import { columnType, requestColumnSizes } from "domain/typeConstants/table";

export class TenantDxGrid14419 extends TenantDxGrid {
  unnecessaryColumns = new Set(['serviceName', 'whatHappened', 'implementationReport', 'materialValuesData']);

  getColumns() {
    const columns = super.getColumns();

    columns.push({
      path: 'workType',
      name: 'workType',
      title: 'Вид работ',
      getCellValue: (row) => row.workType ? row.workType.name : undefined,
      width: 150,
    });
    columns.push({
      path: 'workPlace',
      name: 'workPlace',
      title: 'Место проведения работ',
      width: 190,
    });
    columns.push({
      path: ['groupRequestVisitorsData'],
      name: 'groupRequestVisitorsData',
      getCellValue: (row) => row.groupRequestVisitorsData ? row.groupRequestVisitorsData : undefined,
      title: 'Список посетителей',
      width: 150,
    });
    columns.push({
      path: 'companyName',
      name: 'companyName',
      title: 'Подрядчик',
      width: requestColumnSizes.companyName,
    });
    columns.push({
      path: 'whatHappened',
      name: 'whatHappened',
      title: 'Наименование работ',
      width: 150,
    });
    columns.push({
      path: ['factoryTime', 'name'],
      name: 'factoryTime',
      getCellValue: (row) => (row.factoryTime ? row.factoryTime.name : undefined),
      title: 'Время',
      width: 150,
    });
    columns.push({
      path: 'responsibleFromContractor',
      name: 'responsibleFromContractor',
      title: 'Отв. от порядчика',
      width: 150,
    });
    columns.push({
      path: 'responsibleFromContractorPhone',
      name: 'responsibleFromContractorPhone',
      title: 'Тел. отв.',
      width: 150
    });
    columns.push({
      path: ['requestScan', 'name'],
      name: 'requestScan',
      getCellValue: (row) => row.requestScan ? row.requestScan.id : undefined,
      title: 'Скан заявки',
      type: columnType.FILE,
      width: 150,
    });

    return columns.filter((column) => !this.unnecessaryColumns.has(column.name));
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();

    columnFilters.push({ columnName: 'workType', value: '' });
    columnFilters.push({ columnName: 'workPlace', value: '' });
    columnFilters.push({ columnName: 'groupRequestVisitorsData', value: '' });
    columnFilters.push({ columnName: 'whatHappened', value: '' },);
    columnFilters.push({ columnName: 'factoryTime', value: '' },);
    columnFilters.push({ columnName: 'responsibleFromContractor', value: '' },);
    columnFilters.push({ columnName: 'responsibleFromContractorPhone', value: '' },);
    columnFilters.push({ columnName: 'requestScan', value: '' },);
    columnFilters.push({ columnName: 'companyName', value: '' },);
    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder().filter((column) => !this.unnecessaryColumns.has(column));
    const parkingPlaceIndex = columnOrder.findIndex((column) => column === 'parkingPlace');

    columnOrder.splice(parkingPlaceIndex + 1, 0, 'workType', 'workPlace');
    columnOrder.push('groupRequestVisitorsData');
    columnOrder.push('companyName');
    columnOrder.push('whatHappened');
    columnOrder.push('factoryTime');
    columnOrder.push('responsibleFromContractor');
    columnOrder.push('responsibleFromContractorPhone');
    columnOrder.push('requestScan');

    return columnOrder;
  }
}
