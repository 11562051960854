import React from 'react';
import PropTypes from 'prop-types';

import UsersView, {
  UsersDxGrid,
  UsersDxGridContainer,
  UsersForm,
  UsersFormDialog,
  UsersMobileGrid,

} from '../UsersView';

const getDxGrid = (DxGrid) => (
  <UsersDxGridContainer component={DxGrid || <UsersDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <UsersDxGridContainer component={MobileGrid || <UsersMobileGrid />} />
);

const getFormDialog = (Form) => (
  <UsersFormDialog form={Form || <UsersForm />} />
);

const getDataTableView = ({ breakpoint, administratorBCDxGrid, administratorBCMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(administratorBCMobileGrid);
  }

  return getDxGrid(administratorBCDxGrid);
};

export const getAdministratorBCView = (props) => {

  const {
    breakpoint,
    administratorBCDxGrid,
    administratorBCForm,
    administratorBCFormDialog,
    administratorBCMobileGrid,
    administratorBCView,
    viewName,
    viewStyles,
  } = props;

  if (administratorBCView) return administratorBCView;

  return (
    <UsersView
      key={viewName}
      viewName={viewName}
      breakpoint={breakpoint}
      dataTable={getDataTableView({
        breakpoint,
        administratorBCDxGrid,
        administratorBCMobileGrid,
      })}
      formDialog={administratorBCFormDialog || getFormDialog(administratorBCForm)}
      viewStyles={viewStyles}
    />
  );
};

getAdministratorBCView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  administratorBCDxGrid: PropTypes.node,
  administratorBCForm: PropTypes.node,
  administratorBCFormDialog: PropTypes.node,
  administratorBCMobileGrid: PropTypes.node,
  administratorBCView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
