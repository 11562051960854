/**
 *
 * 11994 common dxGridColumns
 *
 */

import { columnType } from 'domain/typeConstants/table';
import { columnLabels } from '../columnLabels';

const blackListStyle = {
  style: { color: '#d50000' },
  condition: (value) => value.isBlackList,
};
const styles = [blackListStyle];

export const MOVEMENT_TMC_COLUMNS = [
  {
    path: 'number',
    name: 'number',
    title: '№',
    type: columnType.NUMERIC,
    styles,
  },
  {
    path: ['status', 'name'],
    name: 'statusName',
    getCellValue: (row) => (row.status ? row.status.name : undefined),
    title: 'Статус',
    type: columnType.LABEL,
    labels: columnLabels,
  },
  {
    path: 'visitDate',
    name: 'visitDate',
    title: 'Дата',
    type: columnType.DATE,
    styles,
  },
  {
    path: 'expirationDate',
    name: 'expirationDate',
    title: 'До',
    type: columnType.DATE,
    styles,
  },
  {
    path: 'visitorFullName',
    name: 'visitorFullName',
    title: 'Кто вносит',
    styles,
  },
  {
    path: 'materialValuesData',
    name: 'materialValuesData',
    title: 'Мат. ценности',
    styles,
  },
  {
    path: 'note',
    name: 'note',
    title: 'Примечание',
    styles,
  },
  {
    path: ['company', 'name'],
    name: 'company_name',
    getCellValue: (row) => (row.company ? row.company.name : undefined),
    title: 'Арендатор',
    styles,
  },
  {
    path: ['author', 'name'],
    name: 'authorName',
    getCellValue: (row) => (row.author ? row.author.name : undefined),
    title: 'Автор',
    styles,
  },
  {
    path: 'arrivalDate',
    name: 'arrivalDate',
    title: 'Прибыл',
    type: columnType.DATETIME,
  },
  {
    path: 'departureDate',
    name: 'departureDate',
    title: 'Убыл',
    type: columnType.DATETIME,
  },
  {
    path: 'actualCreationDate',
    name: 'actualCreationDate',
    title: 'Создано',
    type: columnType.DATETIME,
  },
];
