/**
 *
 * 11994 common dxGridColumnWidths
 *
 */

import { columnSize, requestColumnSizes } from 'domain/typeConstants/table';

export const MOVEMENT_TMC_COLUMN_WIDTHS = [
  { columnName: 'number', width: columnSize.NUMERIC },
  { columnName: 'statusName', width: requestColumnSizes.statusName },
  { columnName: 'visitDate', width: columnSize.DATE },
  { columnName: 'expirationDate', width: columnSize.DATE },
  { columnName: 'visitorFullName', width: requestColumnSizes.visitorFullName },
  { columnName: 'materialValuesData', width: requestColumnSizes.materialValuesData },
  { columnName: 'note', width: requestColumnSizes.note },
  { columnName: 'company_name', width: requestColumnSizes.company_name },
  { columnName: 'authorName', width: requestColumnSizes.authorName },
  { columnName: 'arrivalDate', width: columnSize.DATETIME },
  { columnName: 'departureDate', width: columnSize.DATETIME },
  { columnName: 'actualCreationDate', width: columnSize.DATETIME },
];
