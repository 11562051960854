import React from 'react';
import PropTypes from 'prop-types';

import ParkingOperatorView, {
  ParkingOperatorDxGrid,
  ParkingOperatorDxGridContainer,
  ParkingOperatorForm,
  ParkingOperatorFormDialog,
  ParkingOperatorMobileGrid,
} from '../ParkingOperator';

const getDxGrid = (DxGrid) => (
  <ParkingOperatorDxGridContainer component={DxGrid || <ParkingOperatorDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <ParkingOperatorDxGridContainer component={MobileGrid || <ParkingOperatorMobileGrid />} />
);

const getDataTableView = ({ breakpoint, parkingOperatorDxGrid, parkingOperatorMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(parkingOperatorMobileGrid);
  }

  return getDxGrid(parkingOperatorDxGrid);
};

const getFormDialog = (Form) => (
  <ParkingOperatorFormDialog form={Form || <ParkingOperatorForm />} />
);

export const getParkingOperatorView = (props) => {
  const {
    breakpoint,
    parkingOperatorDxGrid,
    parkingOperatorMobileGrid,
    parkingOperatorForm,
    parkingOperatorFormDialog,
    parkingOperatorView,
    viewName,
    viewStyles,
  } = props;

  if (parkingOperatorView) return parkingOperatorView;

  return (
    <ParkingOperatorView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        parkingOperatorDxGrid,
        parkingOperatorMobileGrid,
      })}
      formDialog={parkingOperatorFormDialog || getFormDialog(parkingOperatorForm)}
    />
  );
};

getParkingOperatorView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  parkingOperatorDxGrid: PropTypes.node,
  parkingOperatorForm: PropTypes.node,
  parkingOperatorFormDialog: PropTypes.node,
  parkingOperatorMobileGrid: PropTypes.node,
  parkingOperatorView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
