/**
 *
 * TenantView12924 sagas
 *
 */

import { put } from 'redux-saga/effects';

import { TENANT_REQUESTS_TABLE_GRID } from 'pages/Requests/TenantView/constants';
import { loadData } from 'containers/AdvancedDataTable/actions';

function* executeNeedViewRefreshSaga() {
  yield put(loadData(TENANT_REQUESTS_TABLE_GRID));
}

export const tenantViewSaga = {
  executeNeedViewRefreshSaga,
};
