import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
} from 'domain/Data/RequestType/constants';
import { FOR_UNLOADING } from 'custom/12970/domain/Data/RequestType/constants';

export const calculateMaxExpirationDate = (values) => {
  if (values.visitDate) {
    const maxDate = new Date(values.visitDate);
    const types = [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL];

    if (values.longterm && types.includes(values.type)) {
      maxDate.setDate(maxDate.getDate() + 5);
      return maxDate;
    } else if (values.longterm && values.type === FOR_UNLOADING) {
      maxDate.setDate(maxDate.getDate() + 30);
      return maxDate;
    }
  }

  return null;
};

export const validateExpirationDate = (values) => {
  if (values.visitDate && values.expirationDate) {
    const visitDate = new Date(values.visitDate);
    const expirationDate = new Date(values.expirationDate);
    const diff = (expirationDate.setHours(0, 0, 0, 0) - visitDate.setHours(0, 0, 0, 0)) / (24 * 3600 * 1000);
    const types = [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL];
    if (values.longterm && types.includes(values.type) && diff > 5) {
      return { errorHelperText: 'Не может быть больше 14 дней даты посещения' };
    } else if (values.longterm && values.type === FOR_UNLOADING && diff > 30) {
      return { errorHelperText: 'Не может быть больше 30 дней даты посещения' };
    }
  }

  return null;
};
