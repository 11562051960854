/**
 *
 * ReceptionForm15774
 *
 */

import React from 'react';
import {ReceptionForm} from "pages/Requests/ReceptionView";
import * as entity from 'domain/Data/constants';
import {SERVICE, CARRYING_OUT_WORKS, ON_VISIT, ON_PASS_MAKING} from 'domain/Data/RequestType/constants';
import {ACCEPTED, COMPLETED, CREATED, ON_TERRITORY, REJECTED} from "domain/Data/RequestStatus/constants";
import {issueReasonType} from "../common/constans";


export class ReceptionForm15774 extends ReceptionForm {

    getFields() {
        const fields = super.getFields();
        const typeIndex = fields.findIndex((field) => field.name === 'type');
        fields[typeIndex].visibleOptions = (currentValues, initialValues, mode, user, entities) => {
            return entities.get(entity.REQUEST_TYPES)
                .filter(t => t.id !== CARRYING_OUT_WORKS && t.id !== SERVICE).map(t => t.id)
        }

        const statusIndex = fields.findIndex((field) => field.name === 'status');
        fields[statusIndex].calculatedValue = (values, mode) => (mode === 'add' || mode === 'copy') ? ACCEPTED : values.status;
        fields[statusIndex].visibleOptions = (currentValues, initialValues, mode, user, entities) => {
            const status = entities.get('requestStatuses').valueSeq().toJS();
            if (currentValues.type === ON_PASS_MAKING) {
                return [COMPLETED, CREATED, ACCEPTED]
            } else if (status && (currentValues.status === REJECTED || currentValues.status === CREATED)) {
                return status.filter(item => item.id !== ON_TERRITORY).map((item) => item.id);
            }
        }

        const accessLevel =
            {
                name: 'vozdvizhenkaAccessLevel',
                type: 'select',
                entityName: 'vozdvizhenkaAccessLevels',
                label: 'Уровень доступа',
                visible: (values) => values.type === ON_PASS_MAKING,
                orderBy: 'id',
            }
        const fileIndex = fields.findIndex((field) => field.name === 'file');
        fields[fileIndex].helperText = 'Фото логотипа (при необходимости)'
        fields.splice(fileIndex + 1, 0, accessLevel)

        const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
        fields[passNumberIndex].visible = (values) => values.type === ON_VISIT || values.type === ON_PASS_MAKING
        fields[passNumberIndex].helperText = (values) => values.type === ON_PASS_MAKING && 'Номер пропуска (заполняется сотрудником УК)'
        fields[passNumberIndex].mask = (values) => values.type === ON_PASS_MAKING ? 'number' : null

        const issueReason = {
            name: 'issueReason',
            type: 'select',
            label: 'Причина выдачи',
            entityName: 'issueReasons',
            visible: (values) => values.type === ON_PASS_MAKING,
            visibleOptions: () => [issueReasonType.NEW_EMPLOYEE, issueReasonType.LOST]
        }
        fields.splice(passNumberIndex + 1, 0, issueReason)

        const visitorFullNameIndex = fields.findIndex((field) => field.name === 'visitorFullName');
        fields[visitorFullNameIndex].required = (values) => !values.isGroupRequest && (values.type === ON_VISIT || values.type === ON_PASS_MAKING)

        const longtermIndex = fields.findIndex((field) => field.name === 'longterm');
        fields[longtermIndex].visible = (values) => values.type !== SERVICE && values.type !== ON_PASS_MAKING

        return fields;
    }

}
