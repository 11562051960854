/*
 *
 * DetailContainer
 *
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabBar from 'containers/TabBar';

const styleSheet = {
    row: {
        borderLeft: '4px solid gray',
    },
    cell: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    container: {
        display: "inline-block"
    },
    tabBar: {
        marginBottom: 5,
        marginTop: 5,
    },
}

class DetailContainer extends React.PureComponent {

    specifyContainers({ items, record }) {
        return items.map((item) => {
            return {
                name: item.props.component.props.label,
                component: (
                    React.cloneElement(item, {
                        parentId: record.id,
                    })
                ),
                disabled: item.disabled,
            };
        });
    }

    render() {
        const { record, detailContainer: { items }, colspan, classes, tab, changeTab, containerWidth } = this.props;
        return (
            <tr className={classes.row} >
                <td colSpan={colspan} className={classes.cell}>
                    <div className={classes.container}
                        style={{ maxWidth: `${containerWidth}px` }}
                    >
                        <TabBar
                            id={record.id}
                            items={this.specifyContainers({ items, record })}
                            className={classes.tabBar}
                            tab={tab}
                            changeTab={changeTab}
                        />
                    </div>
                </td>
            </tr>
        );
    }
}

export default withStyles(styleSheet)(DetailContainer);







