/*
 *
 * LoginFormContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';

import { submitLoginForm, forgotPasswordLoginForm } from './actions';
import LoginForm from './LoginForm';
import { createStructuredSelector } from '../../../node_modules/reselect/lib/index';
import { makeSelectMessage,makeSelectTypeMessage } from './selectors';

function LoginFormContainer({ onSubmit, message, typeMessage, ...restProps }) {
  return (
    <LoginForm
      onSubmit={onSubmit}
      message={message}
      typeMessage={typeMessage}
      {...restProps}
    />
  );
}

LoginFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: () => dispatch(submitLoginForm(null, null, ownProps.nextPathAfterLogin)),
    forgotPassword: () => dispatch(forgotPasswordLoginForm()),
  };
}

const mapStateToProps = createStructuredSelector({
  message: makeSelectMessage(),
  typeMessage: makeSelectTypeMessage(),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'loginForm',
    getFormState: (state) => state.get('forms'),
  })(LoginFormContainer)
);
