/**
 *
 * PageContentContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectBreakpoint } from 'domain/Screen/selectors';

import { makeSelectIsOpen } from 'containers/NavigationDrawer/selectors';
import PageContent from './PageContent';

function PageContentContainer({ breakpoint, children, navigationDrawerIsOpen }) {
  return (
    <PageContent
      breakpoint={breakpoint}
      navigationDrawerIsOpen={navigationDrawerIsOpen}
    >
      {children}
    </PageContent>
  );
}

PageContentContainer.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  navigationDrawerIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  navigationDrawerIsOpen: makeSelectIsOpen(),
  breakpoint: makeSelectBreakpoint(),
});

export default connect(mapStateToProps, null)(PageContentContainer);
