/*
 *
 * CheckboxCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createCheckboxContainer from './createCheckboxContainer';

class CheckboxCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const CheckboxContainer = createCheckboxContainer(this.context.advancedForm);
    return <CheckboxContainer {...this.props} />;
  }
}

CheckboxCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

export default CheckboxCreator;
