/**
 *
 * MakingAgreementBCRejectForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { REJECTED } from 'domain/Data/RequestStatus/constants';

import { validateCarNumber } from 'utils/validators';

import {
  SERVICE,
} from 'domain/Data/RequestType/constants';

import { REJECT_FORM } from './constants';

class MakingAgreementBCRejectForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const currentDate = new Date();
    return [
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: () => false,
        calculatedValue: () => REJECTED,
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        rowBreakBefore: true,
        required: true,
        visible: (values) => values.type !== SERVICE,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        disabled: true,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'company',
        type: 'select',
        label: 'Арендатор',
        entityName: 'companies',
        orderBy: 'name',
        rowBreakBefore: true,
        disabled: true,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => values.type !== SERVICE,
        disabled: true,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => values.type !== SERVICE,
        disabled: true,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type !== SERVICE,
        cached: false,
        calculatedValue: (values) => values.parking,
        rowBreakBefore: true,
        disabled: true,
      },
      {
        name: 'parkingPlace',
        label: '№ места',
        visible: (values) => values.type !== SERVICE,
        disabled: true,
        initialValue: () => null,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        grid: { sm: 12 },
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={REJECT_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default MakingAgreementBCRejectForm;
