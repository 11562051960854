/**
 *
 * SupplyRequestFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import {documentAction} from 'domain/typeConstants/action';
import * as DocumentState from 'domain/Data/DocumentState/constants';
import {SUPPLY_REQUEST_DX_GRID, SUPPLY_REQUEST_FORM, SUPPLY_REQUEST_FORM_DIALOG} from './constants';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants'
import {isNull} from 'utils/typeUtils';
import {DOCUMENTS} from 'domain/Data/constants';

class SupplyRequestFormDialog extends React.Component {
    handleRunButtonClick = () => {
        this.props.onRun(SUPPLY_REQUEST_FORM_DIALOG, SUPPLY_REQUEST_FORM);
    }

    handleAgreeButtonClick = () => {
        this.props.onAgree(SUPPLY_REQUEST_FORM_DIALOG, SUPPLY_REQUEST_FORM);
    }

    handleDisagreeButtonClick = () => {
        this.props.onDisagree(SUPPLY_REQUEST_FORM_DIALOG, SUPPLY_REQUEST_FORM);
    }

    renderCustomButtons() {
        const {row} = this.props;
        const userName = getValueFromLocalStorage(GLOBAL_USER_KEY).name;
        const sightingEmployees = row && row.agreementStep && row.agreementStep.sightingEmployeesText
        const isSightingEmployee = sightingEmployees && sightingEmployees.split(',').map(i => i.trim()).includes(userName);
        const status = row && row.state ? row.state.id : null

        return [
            {
                name: documentAction.RUN,
                label: 'Старт',
                onClick: this.handleRunButtonClick,
                disabled: !(isNull(isSightingEmployee) && status !== DocumentState.ON_ARCHIVED
                    && status !== DocumentState.ON_AGREED),
            },
            {
                name: documentAction.AGREE,
                label: 'Согласовать',
                onClick: this.handleAgreeButtonClick,
                disabled: !isSightingEmployee,
            },
            {
                name: documentAction.DISAGREE,
                label: 'Отклонить',
                onClick: this.handleDisagreeButtonClick,
                disabled: !isSightingEmployee,
            },
        ];
    }

    render() {
        const {form} = this.props;
        return (
            <AdvancedFormDialog
                maxWidth="lg"
                name={SUPPLY_REQUEST_FORM_DIALOG}
                formName={SUPPLY_REQUEST_FORM}
                advancedForm={form}
                viewModeTitle="Просмотр документа"
                addModeTitle="Новый документ"
                editModeTitle="Изменение документа"
                customButtons={this.renderCustomButtons()}
                dataTableName={SUPPLY_REQUEST_DX_GRID}
                navigation={true}
                entityName={DOCUMENTS}
                numberField={'autoNumber'}
            />
        );
    }
}

SupplyRequestFormDialog.propTypes = {
    form: PropTypes.node.isRequired,
    onRun: PropTypes.func.isRequired,
    viewName: PropTypes.string.isRequired,
};

export default SupplyRequestFormDialog;
