/**
 *
 * SupplyPositionDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction } from 'domain/typeConstants/action';
import * as actionCreators from './actions';

class SupplyPositionDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => { };

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {

      case requestAction.CARGO_RECEIVED:
        this.props.executeCargoReceivedAction(ids[0]);
        break;

      case requestAction.ORIGINALS_DELIVERED:
        this.props.executeOriginalsDeliveredAction(ids[0]);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName } = this.props;
    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
    });
  }
}

SupplyPositionDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  executeCargoReceivedAction: PropTypes.func.isRequired,
  executeOriginalsDeliveredAction: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(SupplyPositionDxGridContainer);
