/*
 *
 * CheckpointForm15755
 *
 */

import { CheckpointForm } from 'pages/Requests/CheckpointView';
import { departmentVisibleOptions } from '../common/fields';
import * as entities from 'domain/Data/constants';
import { formField } from 'domain/typeConstants/form';


export class CheckpointForm15755 extends CheckpointForm {
    getFields() {
        const fields = super.getFields();

        const visitorFullNameIndex = fields.findIndex((field) => field.name === 'visitorFullName');

        fields.splice(visitorFullNameIndex, 0,
            {
                name: 'department',
                type: formField.SELECT,
                entityName: entities.DEPARTMENTS,
                label: 'Здание',
                visible: true,
                required: true,
                initialValue: (values, mode, user) => user.departmentId,
                visibleOptions: departmentVisibleOptions,
            });

        return fields;
    }
}

