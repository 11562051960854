/*
 *
 * AdvancedDataTable constants
 *
 */

export const CHANGE_SELECTION = 'claris-vnext/AdvancedDataTable/CHANGE_SELECTION';
export const CLEAR_SELECTION = 'claris-vnext/AdvancedDataTable/CLEAR_SELECTION';
export const CHANGE_MENU_ITEM = 'claris-vnext/AdvancedDataTable/CHANGE_MENU_ITEM';
export const CHANGE_COLUMN_ORDER = 'claris-vnext/AdvancedDataTable/CHANGE_COLUMN_ORDER';
export const CHANGE_COLUMNS = 'claris-vnext/AdvancedDataTable/CHANGE_COLUMNS';
export const CHANGE_COLUMN_WIDTHS = 'claris-vnext/AdvancedDataTable/CHANGE_COLUMN_WIDTHS';
export const CHANGE_EXPANDED_ROW_IDS = 'claris-vnext/AdvancedDataTable/CHANGE_EXPANDED_ROW_IDS';
export const CLEAR_COLLAPSE_ROWS_DATA = 'claris-vnext/AdvancedDataTable/CLEAR_COLLAPSE_ROWS_DATA';
export const CLEAR_SEARCH_QUERY = 'claris-vnext/AdvancedDataTable/CLEAR_SEARCH_QUERY';
export const CHANGE_SEARCH_QUERY = 'claris-vnext/AdvancedDataTable/CHANGE_SEARCH_QUERY';
export const CHANGE_GRID_FILTERS = 'claris-vnext/AdvancedDataTable/CHANGE_GRID_FILTERS';
export const CHANGE_LAZY_LOADING_STATE = 'claris-vnext/AdvancedDataTable/CHANGE_LAZY_LOADING_STATE';
export const CHANGE_VISIBLE_COLUMNS = 'claris-vnext/AdvancedDataTable/CHANGE_VISIBLE_COLUMNS';
export const CLEAR_FILTER_DIALOG = 'claris-vnext/AdvancedDataTable/CLEAR_FILTER_DIALOG';
export const CHANGE_LOADER_STATE = 'claris-vnext/AdvancedDataTable/CHANGE_LOADER_STATE';
export const CHANGE_CURRENT_RECORD = 'claris-vnext/AdvancedDataTable/CHANGE_CURRENT_RECORD';
export const CHANGE_TAB = 'claris-vnext/AdvancedDataTable/CHANGE_TAB';
export const SET_CONTAINER_WIDTH = 'claris-vnext/AdvancedDataTable/SET_CONTAINER_WIDTH';
export const GET_DOWNLOAD_LINK = 'claris-vnext/AdvancedDataTable/GET_DOWNLOAD_LINK';
export const SAVE_FILE_EXTENSION = 'claris-vnext/AdvancedDataTable/SAVE_FILE_EXTENSION';
export const SET_DOWNLOAD_LINK = 'claris-vnext/AdvancedDataTable/SET_DOWNLOAD_LINK';
export const CLEAR_DOWNLOAD_LINK = 'claris-vnext/AdvancedDataTable/CLEAR_DOWNLOAD_LINK';
export const CLEAR_IDS = 'claris-vnext/AdvancedDataTable/CLEAR_IDS';
export const OPEN_PREVIEW_DRAWER = 'claris-vnext/AdvancedDataTable/OPEN_PREVIEW_DRAWER';


export const LOAD_DATA = 'claris-vnext/AdvancedDataTable/LOAD_DATA';
export const LOAD_DATA_SUCCESS = 'claris-vnext/AdvancedDataTable/LOAD_DATA_SUCCESS';
export const LOAD_DATA_ERROR = 'claris-vnext/AdvancedDataTable/LOAD_DATA_ERROR';

export const REGISTER = 'claris-vnext/AdvancedDataTable/REGISTER';
export const UNREGISTER = 'claris-vnext/AdvancedDataTable/UNREGISTER';

export const SETTINGS_CONFIG_NAME = 'claris-vnext/AdvancedDataTables';
export const MAIN_TABLE_KEY = 'mainTable';
export const MOBILE_TABLE_KEY = 'mobileTable';
export const FALLBACK_VIEW_NAME = 'base';

export const LOAD_MODE = Object.freeze({
    PARENT: 'parent',
    DETAIL: 'detail',
    FORM: 'form',
});
