/**
 *
 * ReceptionFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { RECEPTION_COMPANIES_FORM, RECEPTION_COMPANIES_FORM_DIALOG } from './constants';

export function ReceptionFormDialog({ form, viewName }) {
    return (
        <AdvancedFormDialog
            name={RECEPTION_COMPANIES_FORM_DIALOG}
            formName={RECEPTION_COMPANIES_FORM}
            advancedForm={React.cloneElement(form, { viewName })}
            addModeTitle="Новый контрагент"
            editModeTitle="Изменение контрагента"
            viewModeTitle="Просмотр контрагента"
            copyModeTitle="Копирование контрагента"
        />
    );
}

ReceptionFormDialog.propTypes = {
    form: PropTypes.node.isRequired,
};

