/**
 *
 * ReceptionView actions
 *
 */

import {
    EXECUTE_ACCEPT_ACTION,
    EXECUTE_ADD_ACTION,
    EXECUTE_ADD_BLACK_LIST_ITEM_ACTION,
    EXECUTE_ARRIVED_ACTION,
    EXECUTE_CLOSE_ACTION,
    EXECUTE_COPY_ACTION,
    EXECUTE_DEPARTED_ACTION,
    EXECUTE_EDIT_ACTION,
    EXECUTE_REJECT_ACTION,
    EXECUTE_SEND_TO_AGREEMENT_ACTION,
    EXECUTE_VIEW_ACTION,
    EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION,
    HANDLE_PARAMS_ACTION,
    EXECUTE_SEND_EMAIL_ACTION,
    EXECUTE_FIRED_ACTION,
    EXECUTE_ARRIVED_GROUP_ACTION,
} from './constants';

export function executeAddAction() {
    return {
        type: EXECUTE_ADD_ACTION,
    };
}

export function executeEditAction(id) {
    return {
        type: EXECUTE_EDIT_ACTION,
        payload: {id},
    };
}

export function executeViewAction(id) {
    return {
        type: EXECUTE_VIEW_ACTION,
        payload: {id},
    };
}

export function executeCopyAction(id) {
    return {
        type: EXECUTE_COPY_ACTION,
        payload: {id},
    };
}

export function executeAcceptAction(ids) {
    return {
        type: EXECUTE_ACCEPT_ACTION,
        payload: {ids},
    };
}

export function executeRejectAction(ids) {
    return {
        type: EXECUTE_REJECT_ACTION,
        payload: {ids},
    };
}

export function executeArrivedAction(ids) {
    return {
        type: EXECUTE_ARRIVED_ACTION,
        payload: {ids},
    };
}

export function executeArrivedGroupAction(ids) {
    return {
        type: EXECUTE_ARRIVED_GROUP_ACTION,
        payload: {ids},
    };
}


export function executeDepartedAction(ids) {
    return {
        type: EXECUTE_DEPARTED_ACTION,
        payload: {ids},
    };
}

export function executeAddBlackListItem(ids) {
    return {
        type: EXECUTE_ADD_BLACK_LIST_ITEM_ACTION,
        payload: {ids},
    };
}

export function executeCloseAction(ids) {
    return {
        type: EXECUTE_CLOSE_ACTION,
        payload: {ids},
    };
}

export function executeFiredAction(ids) {
    return {
        type: EXECUTE_FIRED_ACTION,
        payload: {ids},
    };
}

export function executeSendToAgreementAction(id) {
    return {
        type: EXECUTE_SEND_TO_AGREEMENT_ACTION,
        payload: {id},
    };
}

export function executeSendToAgreementByTenantAction(ids) {
    return {
        type: EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION,
        payload: {ids},
    };
}

export function handleParams(requestId) {
    return {
        type: HANDLE_PARAMS_ACTION,
        payload: {requestId},
    };
}

export function executeSendEmailAction(ids) {
    return {
        type: EXECUTE_SEND_EMAIL_ACTION,
        payload: {ids},
    };
}
