/*
 *
 * Autocomplete reducer
 *
 */

import { FormField } from 'domain/Data/recordTypes';
import { LOAD_ENTITIES_SUCCESS, LOAD_ENTITIES_ERROR } from 'domain/Data/constants';
import { CLEAR_OPTIONS } from './constants';

function autocompleteReducer(state = new FormField({}), { type, meta, payload }) {
  switch (type) {
    case CLEAR_OPTIONS:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'loadedOptions'], [])
        .setIn([meta.advancedForm, 'fields', meta.field, 'loaded'], false);


    case LOAD_ENTITIES_SUCCESS:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'loadedOptions'], payload.entities.map((entity) => entity.id))
        .setIn([meta.advancedForm, 'fields', meta.field, 'loaded'], true);


    case LOAD_ENTITIES_ERROR:
      return state.setIn([meta.advancedForm, 'fields', meta.field, 'error'], 'Ошибка при загрузке списка значений');

    default:
      return state;
  }
}

export default autocompleteReducer;
