/**
 *
 * Requests ActionMenu Items
 *
 */

import React from 'react';


import Create from '@material-ui/icons/Create';
import FileCopy from '@material-ui/icons/FileCopy';
import { requestAction } from 'domain/typeConstants/action';


export const copyActionItem = {
  name: requestAction.COPY,
  label: 'Копия',
  icon: 'content_copy',
  svgIcon: <FileCopy />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord) => itemMenuRecord.length !== 1,
};

