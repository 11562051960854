/**
*
* CheckboxTableHeaderCell
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';

const styleSheet = {
  root: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  denseCheckbox: {
    width: 32,
    height: 32,
  },
};

function ToolsTableHeaderCell({ selectable, classes, allSelected, onSelectAllClick }) {
  return (
    <TableCell className={classes.root} padding="none">
      {
        selectable &&
        <Checkbox
          color="primary"
          className={classes.denseCheckbox}
          checked={allSelected}
          onChange={onSelectAllClick}
        />
      }
    </TableCell>
  );
}

ToolsTableHeaderCell.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool,
  onSelectAllClick: PropTypes.func.isRequired,
};

ToolsTableHeaderCell.defaultProps = {
  selectable: false,
};

export default withStyles(styleSheet)(ToolsTableHeaderCell);
