/**
 *
 * RequestsPage14419
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';

import {
  ReceptionForm14419,
  ReceptionDxGrid14419,
  VisitorArrivalForm14419,
  ReceptionRejectForm14419,
  ReceptionFormDialog14419,
} from './ReceptionView';

import {
  TenantForm14419,
  TenantDxGrid14419,
} from './TenantView';

import {
  CheckpointDxGrid14419,
  CheckpointForm14419,
} from './CheckpointView';

import {
  AdministratorBCRequestsDxGrid14419,
} from './AdministratorBCView';

import {
  ServiceRequestsManagerDxGrid14419,
  ServiceRequestsManagerForm14419,
  ServiceRequestsManagerDxGridContainer14419,
} from './ServiceRequestsManagerView';

import { ReceptionRejectFormDialog } from '../../../../pages/Requests/ReceptionView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';

function RequestsPage14419(props) {

  return (
    <RequestsPage
      administratorBCForm={<ReceptionForm14419 />}
      administratorBCDxGrid={<AdministratorBCRequestsDxGrid14419 />}
      administratorBCVisitorArrivalForm={<VisitorArrivalForm14419 />}
      administratorBCRejectForm={<ReceptionRejectForm14419 />}

      receptionForm={<ReceptionForm14419 />}
      receptionFormDialog={
        <ReceptionFormDialog14419
          form={<ReceptionForm14419 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      receptionDxGrid={<ReceptionDxGrid14419 />}
      visitorArrivalForm={<VisitorArrivalForm14419 />}
      rejectFormDialog={<ReceptionRejectFormDialog form={<ReceptionRejectForm14419 />} />}

      checkpointForm={<CheckpointForm14419 />}
      checkpointDxGrid={<CheckpointDxGrid14419 />}
      tenantForm={<TenantForm14419 />}
      tenantDxGrid={<TenantDxGrid14419 />}

      serviceRequestsManagerDxGrid={
        <ServiceRequestsManagerDxGridContainer14419
          component={<ServiceRequestsManagerDxGrid14419 />}
        />

      }
      serviceRequestsManagerForm={<ServiceRequestsManagerForm14419 />}
      {...props}
    >

    </RequestsPage>
  );
}

export default RequestsPage14419;
