/*
 *
 * CheckpointView constants
 *
 */

export const CHECKPOINT_REQUESTS_DATA_TABLE = 'CheckpointRequestsDataTable';
export const CHECKPOINT_REQUESTS_TABLE_GRID = 'CheckpointRequestsTableGrid';
export const CHECKPOINT_REQUESTS_FORM = 'CheckpointRequestsForm';
export const CHECKPOINT_REQUESTS_FORM_DIALOG = 'CheckpointRequestsFormDialog';

export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/CheckpointView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/CheckpointView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/CheckpointView/EXECUTE_DEPARTED_ACTION';
export const EXECUTE_NO_PLACES_ACTION = 'claris-vnext/RequestsPage/CheckpointView/EXECUTE_NO_PLACES_ACTION';
