/**
 *
 * TabBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    minHeight: 24,
  },
});

class TabBar extends React.Component {

  handleChangeTab = (event, value) => {
    const { changeTab } = this.props;
    if (changeTab) changeTab(value);
  };

  render() {
    const {
      classes,
      items,
      tab,
      height,
    } = this.props;


    return (
      <div>
        <AppBar position="static" color="default" className={classes.root}>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleChangeTab}
            scrollable
            scrollButtons="auto"
            textColor="primary"
            value={tab || 0}
            className={classes.toolbar}
          >
            {
              items.map((item, index) => (
                <Tab
                  label={item.name}
                  key={`${index + 1}_${item.name}_tab`}
                  disabled={item.disabled}
                  className={classes.toolbar}
                />
              ))
            }
          </Tabs>
        </AppBar>
        {
          items.map((item, index) => {
            if (tab === index) {
              return (
                <div key={`${index + 1}_${item.name}_container`}
                  style={{ height: `${height}px` }}
                >
                  {item.component}
                </div>
              );
            }
            return null;
          })
        }
      </div>
    );
  }
}

TabBar.propTypes = {
  changeTab: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default withStyles(styles)(TabBar);
