/*
 * NotificationsPage sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { readAllNotifications } from 'domain/Notifications/actions';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';
import { changeEntitiesState, changeEntitiesTotalCount } from 'domain/Data/actions';
import * as api from 'utils/api';

import { LOAD_DATA } from './constants';
import { loadData, dataLoaded, dataLoadingError } from './actions';

export default function* rootSaga() {
  yield takeLatest(LOAD_DATA, handleLoadDataSaga);
  yield takeLatest(DISPLAY_NOTIFICATION, handleDisplayNotificationSaga);
}

function* handleLoadDataSaga() {
  yield call(loadDataSaga);
  yield put(readAllNotifications());
}

export function* loadDataSaga() {
  try {
    const response = yield call(api.loadPage, 'notifications', {
      pageSize: 50,
      pageNumber: 1,
      filters: ['OwnNotifications'],
      sorting: [
        { columnId: 'actualCreationDate', direction: 'desc' },
      ],
      includeTotalCount: true,
    });
    const { data, metadata } = response.data;
    yield put(changeEntitiesState('notifications', data));
    yield put(changeEntitiesTotalCount('notifications', metadata.totalCount));
    yield put(changeEntitiesTotalCount('breadcrumb', metadata.totalCount));
    const ids = data.map((entity) => entity.id);
    yield put(dataLoaded(ids));
  } catch (err) {
    yield put(dataLoadingError(err));
  }
}

function* handleDisplayNotificationSaga() {
  yield put(loadData());
}
