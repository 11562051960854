/**
 *
 * SelectFieldCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createSelectFieldContainer from './createSelectFieldContainer';

class SelectFieldCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const SelectFieldContainer = createSelectFieldContainer(this.context.advancedForm, this.props.name);
    return <SelectFieldContainer {...this.props} />;
  }
}

SelectFieldCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

SelectFieldCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SelectFieldCreator;
