/**
 *
 * SightingView actions
 *
 */

import {
  EXECUTE_AGREE_ACTION,
  EXECUTE_DISAGREE_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_AGREE_DIALOG_ACTION,
  EXECUTE_DISAGREE_DIALOG_ACTION,
  EXECUTE_RUN_DIALOG_ACTION,
} from './constants';

export function executeAgreeAction(ids) {
  return {
    type: EXECUTE_AGREE_ACTION,
    payload: { ids },
  };
}

export function executeDisagreeAction(ids, dialog, form) {
  return {
    type: EXECUTE_DISAGREE_ACTION,
    meta: { dialog, form },
    payload: { ids },
  };
}

export function executeViewAction(id) {
  return {
    type: EXECUTE_VIEW_ACTION,
    payload: { id },
  };
}

export function executeEditAction(id) {
  return {
    type: EXECUTE_EDIT_ACTION,
    payload: { id },
  };
}

export function executeAddAction() {
  return {
    type: EXECUTE_ADD_ACTION,
  };
}


export function executeAgreeDialogAction(dialog, form) {
  return {
    type: EXECUTE_AGREE_DIALOG_ACTION,
    meta: { dialog, form },
  };
}

export function executeRunDialogAction(dialog, form) {
  return {
    type: EXECUTE_RUN_DIALOG_ACTION,
    meta: { dialog, form },
  };
}

export function executeDisagreeDialogAction(dialog, form) {
  return {
    type: EXECUTE_DISAGREE_DIALOG_ACTION,
    meta: { dialog, form },
  };
}
