/*
 *
 * UserFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { USERS_FORM, USERS_FORM_DIALOG } from './constants';


export class UserFormDialog extends React.Component {

  handleBlockButtonClick = () => this.props.onBlock()

  renderCustomButtons() {
    const { currentRecord, selection, mode } = this.props
    if (mode !== 'edit') return null;

    let showBlockButton;
    
    // // отключено до выяснения ошибки создания пользователя
    // if (currentRecord) {
    //   showBlockButton = currentRecord?.identityBlock?.is_Blocked !== undefined ? !currentRecord?.identityBlock.is_Blocked : false
    // }
    // else if (selection) {
    //   showBlockButton = selection[0]?.identityBlock?.is_Blocked !== undefined ? !selection[0]?.identityBlock.is_Blocked : false
    // }

    if (currentRecord) {
      showBlockButton = currentRecord?.isBlocked !== undefined ? !currentRecord?.isBlocked : false
    }
    else if (selection) {
      showBlockButton = selection[0]?.isBlocked !== undefined ? !selection[0]?.isBlocked : false
    }

    return [
      {
        name: 'block',
        label: 'Заблокировать',
        onClick: this.handleBlockButtonClick,
        visible: showBlockButton
      },
    ]
  }


  render() {
    const { form } = this.props;
    return (
      <AdvancedFormDialog
        name={USERS_FORM_DIALOG}
        formName={USERS_FORM}
        advancedForm={form}
        addModeTitle="Добавление пользователя"
        editModeTitle="Изменение пользователя"
        viewModeTitle="Просмотр пользователя"
        customButtons={this.renderCustomButtons()}
      />
    );
  }
}

UserFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default UserFormDialog;
