/**
*
* NavigationDrawer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { ACCOUNT_ID_KEY } from 'global-constants';
import { account } from 'domain/typeConstants/accounts';
import { getValueFromLocalStorage } from 'utils/localStorage';
import Logotype12970 from 'custom/12970/assets/Logotype12970';
import khimkiLogo from 'custom/14326/assets/khimki_logo.png';
import millhouseLogo from 'custom/15755/assets/millhouse_logo_white_cut.png';
import sawatzkyLogo from 'custom/15755/assets/sawatzky_logo.png';
import clarisLogo from './clarisLogo.png';
import { NAVIGATION_DRAWER_WIDTH } from './constants';


const styleSheet = (theme) => ({
  navigationDrawer: {
    position: 'fixed',
    height: '100%',
    width: NAVIGATION_DRAWER_WIDTH,
    display: "flex",
    flexDirection: "column",
  },
  navigationDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    height: 56,
  },
  navigationDrawerLogo: {
    height: 32,
    padding: '0 12px',
  },
  listItem: {
    [theme.breakpoints.down('sm')]: {
      padding: '5px 10px',
    },
  },
  listItemBold: {
    fontWeight: 'bold'
  },
  itemIcon: {
    marginRight: 0,
  },
  activeItemText: {
    color: theme.palette.primary['400'],
  },
  khimkiLogo: {
    height: 50,
    width: 45,
  },
  millhouseLogo: {
    height: 19,
    width: 150,
    marginBottom: 2,
  },
  sawatzkyLogo: {
    height: 23,
    width: 98,
    marginLeft: 40
  }
});

export class NavigationDrawer extends React.PureComponent {

  createItemClickHandler = (item) => () => {
    const { onRequestNavigate } = this.props;
    if (onRequestNavigate) onRequestNavigate(item.link);
  };

  createSubItemClickHandler = (subItem) => () => {
    const { onRequestNavigate, viewMode, onDrawerClose } = this.props;
    if (onRequestNavigate) onRequestNavigate(subItem.link);
    if (onDrawerClose && viewMode === 'mobile') onDrawerClose(null);
  };

  handleCloseDrawerButton = () => {
    const { onDrawerClose, containerWidth } = this.props;
    if (onDrawerClose) onDrawerClose(containerWidth + NAVIGATION_DRAWER_WIDTH);
  };

  renderItem(item, classes) {
    return (
      <ListItem
        key={item.id}
        button
        onClick={this.createItemClickHandler(item)}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.itemIcon}>
          <Icon>{item.icon}</Icon>
        </ListItemIcon>
        <ListItemText
          inset
          primary={
            <Typography className={classes.listItemBold} type="body2">{item.title}</Typography>
          }
        />
      </ListItem>
    );
  }

  renderSubItems(item, classes) {
    return (
      <Collapse key={`${item.id}-collapse`} in timeout="auto" unmountOnExit>
        {
          item.items.map((subItem) =>
          (<ListItem
            key={subItem.id}
            className={classes.listItem}
            onClick={this.createSubItemClickHandler(subItem)}
            button
          >
            <ListItemText
              inset
              style={{ paddingLeft: 40 }}
              primary={
                <Typography
                  className={
                    classNames({ [classes.activeItemText]: subItem.isActive })
                  }
                  type={subItem.isActive ? 'body2' : 'body1'}
                >
                  {subItem.title}
                </Typography>
              }
            />
          </ListItem>)
          )
        }
      </Collapse>
    );
  }

  render() {
    const { classes, isOpen, items, breakpoint } = this.props;

    return (
      <Drawer
        variant={breakpoint.down('md') ? 'temporary' : 'persistent'}
        classes={{
          paper: classes.navigationDrawer,
        }}
        open={isOpen}
        onClose={breakpoint.down('md') ? this.handleCloseDrawerButton : null}
      >
        <div className={classes.navigationDrawerHeader}>
          {(() => {
            const accountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);

            if (accountId === account.COMCITY) {
              return <Logotype12970 />;
            }
            if (accountId === account.BC_KHIMKI) {
              return <img src={khimkiLogo} alt="Логотип БЦ Химки" className={classes.khimkiLogo} />;
            }
            if (accountId === account.KRYLATSKI_HOLMY || accountId === account.BC_BELIYE_SADY || accountId === account.BC_4_VETRA) {
              return (<div style={{ padding: 3, paddingLeft: 28, }}>
                <img src={millhouseLogo} alt="Логотип Millhouse" className={classes.millhouseLogo} />
                <img src={sawatzkyLogo} alt="Логотип Sawatzky" className={classes.sawatzkyLogo} />
              </div>
              );
            }
            return <img className={classes.navigationDrawerLogo} src={clarisLogo} alt="Логотип Claris" />;
          })()}
          <IconButton onClick={this.handleCloseDrawerButton}>
            <Icon>chevron_left</Icon>
          </IconButton>
        </div>
        <Divider />
        <List style={{ overflowX: "auto" }}>
          {
            items.map((item) => ([
              this.renderItem(item, classes),
              this.renderSubItems(item, classes),
            ]))
          }
        </List>
      </Drawer>
    );
  }
}

NavigationDrawer.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  classes: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired,
        })
      ),
    })
  ).isRequired,
  onRequestNavigate: PropTypes.func.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(NavigationDrawer);
