/**
 *
 * AutocompleteCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createAutocompleteContainer from './createAutocompleteContainer';

class AutocompleteCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const AutocompleteContainer = createAutocompleteContainer(this.context.advancedForm, this.props.name);
    return <AutocompleteContainer {...this.props} />;
  }
}

AutocompleteCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

AutocompleteCreator.propTypes = {
  name: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
};

export default AutocompleteCreator;
