/*
 *
 * mobile label status documents
 *
 */

import { documentStatus } from 'domain/typeConstants/status';

export const statusColorLabels = {
  [documentStatus.AGREED]: {
    backgroundColor: '#69F0AE',
  },
  [documentStatus.ON_AGREEMENT]: {
    backgroundColor: '#81D4FA',
  },
  [documentStatus.IN_OPERATION]: {
    backgroundColor: '#A5D6A7',
  },
  [documentStatus.POSTPONED]: {
    backgroundColor: '#EF5350',
  },
  [documentStatus.REJECTED]: {
    backgroundColor: '#EF9A9A',
  },
  [documentStatus.ARCHIVED]: {
    backgroundColor: '#CCCCCC',
  },
  [documentStatus.WAITING_FOR_PAYMENT]: {
    backgroundColor: '#FFAB91',
  },
};
