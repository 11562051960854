/**
 *
 * ReceptionForm12970
 *
 */

import { ReceptionForm } from 'pages/Requests/ReceptionView';
import { validateCarNumber } from 'utils/validators';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_PASS_MAKING,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { CREATED, ON_TERRITORY, ACCEPTED, REJECTED } from 'domain/Data/RequestStatus/constants';
import { PASSPORT } from 'domain/Data/DocumentType/constants';
import { PERMANENT } from 'domain/Data/PassType/constants';
import {
  CENTRAL_ENTRANCE,
  ON_CIRCLE_104,
  RAMP_102_POINT_1_AND_102_POINT_2,
  RAMP_104_POINT_2,
  SIDE_ENTRANCE_IN_MARKET,
  STILOBAT_CENTRAL_ENTRANCE,
  STILOBAT_COURTYARD,
  STOPPING_TRANSPORT_AT_CENTRAL_ENTRANCE,
  STOPPING_TRANSPORT_ON_SIDE_ENTRANCE,
  TECHNICAL_UNLOADING_ZONE,
} from 'custom/12970/domain/Data/Parking/constants';
import {
  FOR_UNLOADING,
  PARKING_AT_MAIN_ENTRANCE,
} from 'custom/12970/domain/Data/RequestType/constants';
import { BUS } from 'custom/12970/domain/Data/VehicleType/constants';
import { PARKING, FOR_UNLOADING_ZONE } from 'custom/12970/domain/Data/ParkingType/constants';
import { calculateMaxExpirationDate, validateExpirationDate } from 'custom/12970/pages/Requests/utils';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';
import { groupRequestVisitorsDataHelperText, visitorFullNameLabel } from 'pages/Requests/common/fields';

class ReceptionForm12970 extends ReceptionForm {
  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        type: 'date',
        visible: false,
        initialValue: () => null,
        calculatedValue: (values, mode) => mode === 'add' && values.type === ON_VISIT ? currentDate : null
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'passNumber',
        type: 'autocomplete',
        entityName: 'accessCards',
        label: '№ пропуска',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        disabled: true,
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        calculatedValue: (values, mode) => {
          const parkingForCarEntry = [CENTRAL_ENTRANCE, STOPPING_TRANSPORT_ON_SIDE_ENTRANCE].includes(values.parking);
          const parkingForUnloading = [TECHNICAL_UNLOADING_ZONE, SIDE_ENTRANCE_IN_MARKET, CENTRAL_ENTRANCE,
            STILOBAT_COURTYARD, STILOBAT_CENTRAL_ENTRANCE, RAMP_104_POINT_2, RAMP_102_POINT_1_AND_102_POINT_2,
            ON_CIRCLE_104].includes(values.parking);

          if (mode === 'add') {
            if (values.type === ON_CAR_ENTRY && parkingForCarEntry) return CREATED;
            if (values.type === FOR_UNLOADING && parkingForUnloading) return CREATED;
            if ([PARKING_AT_MAIN_ENTRANCE, SERVICE].includes(values.type)) {
              return CREATED;
            }
            return ACCEPTED;
          } else if (mode === 'copy') {
            return values.type === ON_VISIT ? ON_TERRITORY : CREATED;
          }
          return values.status;
        },
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const status = entities.get('requestStatuses').valueSeq().toJS();
          if (status && (currentValues.status === REJECTED || currentValues.status === CREATED)) {
            return status.filter(item => item.id !== ON_TERRITORY).map((item) => item.id);
          }
        },
        orderBy: 'name',
        required: true,
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_VISIT;
          }
          return values.type;
        },
        orderBy: 'order',
        clearErrorsOnChange: true,
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          if (currentValues.company) {
            const company = entities
              .get('companies')
              .get(currentValues.company);
            return company ? company.get('requestTypes') || [] : [];
          }

          return [];
        },
      },
      {
        name: 'longterm',
        type: 'checkbox',
        label: 'Долгосрочная',
        visible: (values) => {
          if (values.type === ON_PASS_MAKING) return values.passType !== PERMANENT;
          if (values.type === SERVICE || values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL ||
            values.type === PARKING_AT_MAIN_ENTRANCE) return false;
          return true;
        },
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        rowBreakBefore: true,
        required: true,
        visible: (currentValues, initialValues, mode) => mode === 'view' || currentValues.type !== SERVICE,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        disabled: (currentValues) => currentValues.type === SERVICE,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'expirationDate',
        type: 'date',
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: (values) => values.longterm && values.type !== SERVICE,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm ? values.expirationDate : null,
        validate: validateExpirationDate,
        min: (values) => (
          values.visitDate ? new Date(values.visitDate) : new Date()
        ),
        max: calculateMaxExpirationDate,
      },
      {
        name: 'isGroupRequest',
        type: 'checkbox',
        label: 'Групповая заявка',
        rowBreakBefore: true,
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        visible: (values) => values.type !== SERVICE &&
          !values.isGroupRequest,
        required: (values) => (values.type === ON_VISIT ||
          values.type === FOR_UNLOADING) && !values.isGroupRequest,
        rowBreakBefore: true,
      },
      {
        name: 'company',
        type: 'autocomplete',
        label: 'Арендатор',
        entityName: 'companies',
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        orderBy: 'name',
        cached: false,
        numberInQueueFromEnd: 1,
        initialValue: (values, mode, user) => user.companyId,
        rowBreakAfter: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING ||
          values.type === SERVICE,
        required: true,
      },
      {
        name: 'documentType',
        type: 'select',
        label: 'Вид документа',
        initialValue: PASSPORT,
        entityName: 'documentTypes',
        visible: (values) => values.type !== SERVICE &&
          values.type !== FOR_UNLOADING && values.type !== PARKING_AT_MAIN_ENTRANCE &&
          !values.isGroupRequest,
        calculatedValue: (values) => values.isGroupRequest ? PASSPORT : values.documentType,
        rowBreakBefore: true,
      },
      {
        name: 'documentDetails',
        label: 'Серия, № документа',
        visible: (values) => values.type !== SERVICE &&
          values.type !== FOR_UNLOADING && values.type !== PARKING_AT_MAIN_ENTRANCE &&
          !values.isGroupRequest,
      },
      {
        name: 'time',
        type: 'select',
        label: 'Время',
        entityName: 'times',
        compareOptions: (a, b) => {
          const firstItem = Number.parseInt(a.text, 10);
          const secondItem = Number.parseInt(b.text, 10);

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        },
        required: true,
        visible: (values) => values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE,
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const currentDay = currentDate;
          const visitDay = currentValues.visitDate;

          const times = entities.get('times').toArray();

          if (visitDay <= currentDay) {
            const hours = currentDate.getHours();

            const options = times.map((item) => {
              const untilTime = Number(item.name.substr(8, 2));
              if (untilTime <= hours && untilTime !== 0) return null;
              return item.id;
            });
            return options;
          }

          return times.map((item) => item.id);
        },
        rowBreakBefore: true,
      },
      {
        name: 'nonstandardCarNumber',
        type: 'checkbox',
        label: 'Нестандартный № авто',
        rowBreakBefore: true,
        rowBreakAfter: true,
        visible: (values) => values.type === ON_CAR_ENTRY || values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL || values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE,
      },
      {
        name: 'vehicleType',
        type: 'select',
        label: 'Тип авто',
        entityName: 'vehicleTypes',
        visible: (values) => values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE,
        required: (values) => values.type === FOR_UNLOADING,
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          if (currentValues.type === PARKING_AT_MAIN_ENTRANCE) return [BUS];
          if (currentValues.type === FOR_UNLOADING) {
            let vehicleTypes = entities.get('vehicleTypes');
            vehicleTypes = vehicleTypes.map((item) => {
              if (item.id === BUS) return null;
              return item.id;
            });
            return vehicleTypes;
          }
          return [];
        },
        calculatedValue: (values) => {
          if (values.vehicleType === BUS && values.type !== PARKING_AT_MAIN_ENTRANCE) return null;
          if (values.type === PARKING_AT_MAIN_ENTRANCE) return BUS;
          return values.vehicleType;
        },
      },
      {
        name: 'accessCard',
        type: 'autocomplete',
        label: 'Карта доступа',
        entityName: 'accessCards',
        visible: (values) => [ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type),
        filters: ['AccessCardsOfHousing'],
        filterBy: (values) => {
          if (values.housing) {
            return `housings != null && housings.Contains("${values.housing}")`;
          }
          return null;
        },
        initialValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.accessCard;
        },
      },
      {
        name: 'housing',
        label: 'Корпус',
        type: 'select',
        entityName: 'housings',
        visible: (values) => ([ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type)),
        orderBy: 'name',
        required: (values) => (values.type === ON_VISIT ||
          ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type) && !!values.visitorFullName)),
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          if (currentValues.company) {
            const company = entities
              .get('companies')
              .get(currentValues.company);
            return company ? company.get('housings') || [] : [];
          }

          return [];
        },
        calculatedValue: (values) => {
          if (values.type === ON_VISIT ||
            ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type) && values.visitorFullName)) {
            return values.housing;
          }

          return null;
        },
        disabled: (currentValues) => ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(currentValues.type) && !(currentValues.visitorFullName)),
      },
      {
        name: 'passType',
        type: 'select',
        label: 'Вид пропуска',
        entityName: 'passTypes',
        visible: (values) => values.type === ON_PASS_MAKING,
        initialValue: () => PERMANENT,
        rowBreakBefore: true,
      },
      {
        name: 'issueReason',
        type: 'select',
        label: 'Причина выдачи',
        entityName: 'issueReasons',
        visible: (values) => values.type === ON_PASS_MAKING,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        required: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE) && !values.isGroupRequest,
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL ||
          values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE) && !values.isGroupRequest,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        required: (values) => ((values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE) &&
          !values.isGroupRequest) || values.type === ON_CAR_ENTRY,
        visible: (values) => (values.type === ON_CAR_ENTRY || values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL || values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE) &&
          !values.isGroupRequest,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        orderBy: 'name',
        required: (values) => values.type === ON_CAR_ENTRY,
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL ||
          values.type === FOR_UNLOADING || values.type === PARKING_AT_MAIN_ENTRANCE,
        cached: false,
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          if (currentValues.type === PARKING_AT_MAIN_ENTRANCE) return [STOPPING_TRANSPORT_AT_CENTRAL_ENTRANCE];
          if (currentValues.company) {
            const company = entities
              .get('companies')
              .get(currentValues.company);

            const companyParkings = company ? company.get('parkings') || [] : [];

            const isVisibleParking = (requestType, parkingType) => {
              if ((requestType === ON_CAR_ENTRY || requestType === ON_PROPERTY_REMOVAL ||
                requestType === ON_PROPERTY_BRINGING) && parkingType === PARKING) { return true; }
              if (requestType === FOR_UNLOADING && parkingType === FOR_UNLOADING_ZONE) { return true; }

              return false;
            };

            const parkings = entities.get('parkings')
              .filter((parking) => companyParkings.includes(parking.id))
              .filter((parking) => isVisibleParking(currentValues.type, parking.type))
              .map((parking) => parking.id);

            return parkings;
          }

          return [];
        },
        calculatedValue: (values, mode, entities) => {
          if (values.parking === STOPPING_TRANSPORT_AT_CENTRAL_ENTRANCE && values.type !== PARKING_AT_MAIN_ENTRANCE) return null;
          if (values.type === PARKING_AT_MAIN_ENTRANCE) return STOPPING_TRANSPORT_AT_CENTRAL_ENTRANCE;
          const parkings = entities && entities.get('parkings');
          const parking = parkings && parkings.get(values.parking);

          if ([ON_VISIT, ON_CAR_ENTRY, ON_PROPERTY_REMOVAL, ON_PROPERTY_BRINGING].includes(values.type)) {
            return (parking && parking.type === FOR_UNLOADING_ZONE) ? null : values.parking;
          }
          if ([FOR_UNLOADING].includes(values.type)) {
            return (parking && parking.type === PARKING) ? null : values.parking;
          }

          return values.parking;
        },
        rowBreakBefore: true,
      },
      {
        name: 'parkingPlace',
        label: '№ места',
        visible: (values) => values.type !== ON_VISIT &&
          values.type !== SERVICE && values.type !== ON_PASS_MAKING,
        initialValue: () => null,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL || values.type === FOR_UNLOADING,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: true
      },
      {
        name: 'floor',
        label: 'Этаж',
        visible: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'customer',
        type: 'select',
        label: 'Заявитель',
        entityName: 'employees',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
        view: 'Short',
      },
      {
        name: 'service',
        type: 'select',
        label: 'Сервис',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whoMovesCargo',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'assignee',
        type: 'select',
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        visible: false,
        view: 'Short',
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
      },
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        required: true,
        visible: false,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        required: true,
        visible: false,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
        initialValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.note;
        },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
        required: (currentValues) => currentValues.status === REJECTED,
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: (values) => values.type === SERVICE ||
          values.type === ON_PASS_MAKING ||
          values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL ||
          values.type === FOR_UNLOADING ||
          values.type === PARKING_AT_MAIN_ENTRANCE,
        rowBreakBefore: true,
      },
    ];
  }
}

export default ReceptionForm12970;
