/**
 *
 * MakingAgreementBCForm14140
 *
 */

import { MakingAgreementBCForm } from 'pages/Requests/MakingAgreementBCView';

export class MakingAgreementBCForm14140 extends MakingAgreementBCForm {
  getFields() {
    const fields = super.getFields();
    return fields;
  }
}
