/**
 *
 * RulesPage14326
 *
 */

import React from 'react';
import RulesPage from 'pages/Rules/RulesPage';
import {
  ReceptionDxGrid14326,
  ReceptionForm14326,
} from './ReceptionView';

import {
  TenantDxGrid14326,
} from './TenantView';


function RulesPage14326(props) {
  return (
    <RulesPage
      receptionDxGrid={<ReceptionDxGrid14326 />}
      receptionForm={<ReceptionForm14326 />}
      tenantDxGrid={<TenantDxGrid14326 />}
      {...props}
    />
  );
}
export default RulesPage14326;