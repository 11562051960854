/**
 *
 * createSelectFieldContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, clearSubmitErrors } from 'redux-form/immutable';

import SelectField from './SelectField';
import MultiSelectField from './MultiSelectField';
import * as actionCreators from './actions';
import * as advancedFormActionCreators from '../actions';
import {
  makeSelectIsLoaded,
  makeSelectIsCached,
  makeSelectOptions,
} from './selectors';
import {
  makeSelectRecord,
  makeSelectMode,
} from '../selectors';

function createSelectFieldContainer(formName, fieldName) {
  class SelectFieldContainer extends React.PureComponent {
    componentWillMount() {
      const {
        cached,
        loaded,
        loadOptions,
        mode,
        multiple,
      } = this.props;

      if (multiple && mode === 'view') return;
      if (mode && (!cached || !loaded)) {
        loadOptions();
      }
    }

    handleChange = () => {
      if (this.props.clearErrorsOnChange) {
        this.props.clearErrors();
      }
      setTimeout(() => this.props.calculateFieldsValues(), 0);
    };

    getTextValue = () => {
      const { name, record } = this.props;
      return record ? record[`${name}Text`] : '';
    };

    render() {
      const { loaded, cached, loadOptions, clearErrorsOnChange, calculateFieldsValues, clearErrors,
        record, multiple, ...restProps } = this.props;

      if (multiple) {
        return (
          <Field
            {...restProps}
            textValue={this.getTextValue()}
            onChange={this.handleChange}
            component={MultiSelectField}
          />
        );
      }

      return (
        <Field
          {...restProps}
          onChange={this.handleChange}
          component={SelectField}
        />
      );
    }
  }

  SelectFieldContainer.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };

  SelectFieldContainer.propTypes = {
    cached: PropTypes.bool.isRequired,
    calculateFieldsValues: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearErrorsOnChange: PropTypes.bool,
    loaded: PropTypes.bool.isRequired,
    loadOptions: PropTypes.func.isRequired,
    mode: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    record: PropTypes.object,
  };

  const mapStateToProps = createStructuredSelector({
    loaded: makeSelectIsLoaded(formName, fieldName),
    cached: makeSelectIsCached(formName, fieldName),
    options: makeSelectOptions(formName, fieldName),
    record: makeSelectRecord(formName),
    mode: makeSelectMode(formName),
  });

  const mapDispatchToProps = (dispatch) => ({
    loadOptions: () => dispatch(actionCreators.loadOptions(formName, fieldName)),
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
    clearErrors: () => dispatch(clearSubmitErrors(formName)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(SelectFieldContainer);
}

export default createSelectFieldContainer;
