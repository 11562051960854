/**
 *
 * SeniorParkingOperatorForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_PASS_MAKING,
} from 'domain/Data/RequestType/constants';
import { SENIOR_PARKING_OPERATOR_REQUESTS_FORM } from './constants';
import { visitorFullNameLabel } from '../common/fields';

class SeniorParkingOperatorForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'number',
        label: '№',
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        orderBy: 'name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        entityName: 'requestTypes',
        orderBy: 'order',
      },
      {
        name: 'longterm',
        type: 'checkbox',
        label: 'Долгосрочная',
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
          calculatedValue: (values) => {
            const visitDate = new Date(values.visitDate);
            return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
            )
          },
      },
      {
        name: 'expirationDate',
        type: 'date',
        label: 'Дата окончания',
        visible: (values) => values.longterm,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        rowBreakBefore: true,
      },
      {
        name: 'company',
        type: 'select',
        label: 'Арендатор',
        entityName: 'companies',
        numberInQueueFromEnd: 1,
        orderBy: 'name',
        rowBreakAfter: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING,
      },
      {
        name: 'passNumber',
        label: '№ пропуска',
        visible: (values) => values.type === ON_VISIT ||
          values.type === ON_PASS_MAKING,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: true
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={SENIOR_PARKING_OPERATOR_REQUESTS_FORM}
        entityName="requests"
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}

export default SeniorParkingOperatorForm;
