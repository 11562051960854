/**
 *
 * Common constants
 *
 */

export const USER_TASK_TABLE_GRID = 'UserTaskTableGrid';
export const SUPPLIER_INVOICES_GRID = 'SupplierInvoicesGrid';
export const WORK_GRID = 'WorkGrid';
export const TASKS_GRID = 'TasksGrid';
export const JOURNAL = 'Journal';
export const AGREEMENT_LIST = 'AgreementList';

