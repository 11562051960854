/**
 *
 * UnbindingAccessCardPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';

import getUnbindingAccessCardPage from './getUnbindingAccessCardPage';
import saga from './sagas';

export class UnbindingAccessCardPageContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { accountId, userRoles, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, ...restProps };

    return getUnbindingAccessCardPage(accountId, props);
  }
}

UnbindingAccessCardPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
});

const reducer = (state = {}) => state;
const withReducer = injectReducer({ key: 'unbindingAccessCardPage', reducer });
const withSaga = injectSaga({ key: 'unbindingAccessCardPage', saga });
const withConnect = connect(mapStateToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(UnbindingAccessCardPageContainer);
