/**
 * BreadcrumbContainer
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'react-router-redux';
import { makeSelectActiveComposedItem } from 'domain/Navigation/selectors';
import Breadcrumb from './Breadcrumb';
import { makeSelectTotalCountBreadcrumb } from 'containers/AdvancedDataTable/selectors';


function BreadcrumbContainer({ item, navigate, totalCount }) {
  return (
    <>
      {item &&
        <Breadcrumb
          item={item}
          onRequestNavigate={navigate}
          totalCount={totalCount}
        />
      }
    </>

  );
}

BreadcrumbContainer.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
      }).isRequired
    ),
  }) //.isRequired
  ,
  location: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  navigate: PropTypes.func.isRequired,
};


const mapStateToProps = createStructuredSelector({
  item: makeSelectActiveComposedItem(),
  totalCount: makeSelectTotalCountBreadcrumb(),
});

function mapDispatchToProps(dispatch) {
  return {
    navigate: (link) => dispatch(push(link)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbContainer);
