/**
 *
 * CheckpointDxGrid14419
 *
 */

import React from 'react';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';
import { columnType } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import {
  ON_ACCEPT_OR_ON_TERRITORY_OR_CREATE_AND_CAR_ENTRY_REQUESTS, REQUESTS_ON_WORK_PRODUCTION,
} from 'domain/Data/Filters/constants';
import { CHECKPOINT_REQUESTS_TABLE_GRID } from 'pages/Requests/CheckpointView/constants';
import { VisitorArrivalFormDialog } from 'pages/Requests/ReceptionView';
import { requestAction } from 'domain/typeConstants/action';
import { ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS } from '../../../../../domain/Data/RequestType/constants';
import { requestStatus } from 'domain/typeConstants/status';
import { VisitorArrivalForm14419 } from './VisitorArrivalForm14419';
import {
  getCheckpointCarryingOurWorkingTab,
  checkpointCarryingOurWorkingTabFilters,
  checkpointCarryingOurWorkingTabOrder,
} from '../common/dxGridComponents/columns';


export class CheckpointDxGrid14419 extends CheckpointDxGrid {

  unnecessaryColumns = new Set(['visitorFullName', 'materialValuesData']);

  getFilters() {
    const { viewName } = this.props;
    const filters = super.getFilters();
    if (viewName === 'today') {
      filters.push(ON_ACCEPT_OR_ON_TERRITORY_OR_CREATE_AND_CAR_ENTRY_REQUESTS);
    }
    if (viewName === 'carryingOutWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
    return filters;
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };

    const styles = [blackListStyle];
    const columns = super.getColumns();
    const { viewName } = this.props;

    if (viewName === 'carryingOutWorks') {
      return getCheckpointCarryingOurWorkingTab(styles);
    }

    columns.push({
      path: ['multipleEntry'],
      name: 'multipleEntry',
      getCellValue: (row) => row.multipleEntry ? row.multipleEntry : undefined,
      title: 'Многократный вход',
      type: columnType.BOOLEAN,
      styles,
    });

    columns.push({
      path: ['groupRequestVisitorsData'],
      name: 'groupRequestVisitorsData',
      getCellValue: (row) => row.groupRequestVisitorsData ? row.groupRequestVisitorsData : undefined,
      title: 'Список посетителей',
      styles,
    });

    columns.push({
      path: 'passNumber',
      name: 'passNumber',
      title: '№ пропуска',
    });

    const companyNameIndex = columns.findIndex((column) => column.name === 'companyName');
    columns[companyNameIndex].title = 'Подрядчик';

    return columns;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder().filter((column) => !this.unnecessaryColumns.has(column));
    const { viewName } = this.props;

    if (viewName === 'carryingOutWorks') {
      return checkpointCarryingOurWorkingTabOrder;
    }

    const expirationDateIndex = columnOrder.findIndex((columnName) => columnName === 'expirationDate');
    columnOrder.splice(expirationDateIndex + 1, 0, 'multipleEntry');

    const typeIndex = columnOrder.findIndex((columnName) => columnName === 'typeShortName');
    columnOrder.splice(typeIndex + 1, 0, 'visitorFullName');

    columnOrder.push('groupRequestVisitorsData');
    columnOrder.push('passNumber');

    return columnOrder;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();
    const { viewName } = this.props;
    const arrivedIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    actionButtons[arrivedIndex].disabled = (selection, entities) => {
      if (selection.length === 1) {
        if (!entities[0].status || !entities[0].type) return false;
        return (
          (viewName === 'all' && entities[0].type.id === ON_CAR_ENTRY &&
            (entities[0].status.name !== requestStatus.CREATED || entities[0].status.name === requestStatus.ACCEPTED))
          ||
          (viewName === 'all' && entities[0].status.name !== requestStatus.ACCEPTED &&
            (entities[0].type.id === ON_PROPERTY_BRINGING || entities[0].type.id === ON_PROPERTY_REMOVAL
              || entities[0].type.id === CARRYING_OUT_WORKS))
        );
      }
      return true;
    };
    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();
    const { viewName } = this.props;

    const arrivedIndex = actionMenuItems.findIndex((items) => items.name === requestAction.ARRIVED);
    actionMenuItems[arrivedIndex].hidden = (itemMenuRecord, entities) => {
      if (itemMenuRecord.length === 1) {
        if (!entities[0].status || !entities[0].type) return false;
        return (
          (viewName === 'all' && entities[0].type.id === ON_CAR_ENTRY &&
            (entities[0].status.name !== requestStatus.CREATED || entities[0].status.name === requestStatus.ACCEPTED))
          ||
          (viewName === 'all' && entities[0].status.name !== requestStatus.ACCEPTED &&
            (entities[0].type.id === ON_PROPERTY_BRINGING || entities[0].type.id === ON_PROPERTY_REMOVAL
              || entities[0].type.id === CARRYING_OUT_WORKS))
        );
      }
      return true;
    };
    return actionMenuItems;
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <div>
        <AdvancedDataTable
          entityName="requests"
          name={CHECKPOINT_REQUESTS_TABLE_GRID}
          actionButtons={this.getActionButtons()}
          actionMenuItems={this.getActionMenuItems()}
          columnOrder={this.getColumnOrder()}
          columns={this.getColumns()}
          filters={this.getFilters()}
          pageSize={this.getPageSize()}
          onActionExecuting={onActionExecuting}
          onRowDoubleClick={onRowDoubleClick}
          sorting={this.getSorting()}
          tableFeatures={this.getTableFeatures()}
          viewName={viewName}
          filtering
          selectable
          tableType={settings.PAGING_TABLE}
          mode="parent"
        />
        <VisitorArrivalFormDialog form={<VisitorArrivalForm14419 />} />
      </div>
    );
  }
}
