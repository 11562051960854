/**
 *
 * Filter constants
 *
 */

/** @REQUESTS-FILTERS */
export const ACTIVE_REQUESTS = 'ActiveRequests';
export const CURRENT_DAY_REQUESTS = 'CurrentDayRequests';
export const IN_PROGRESS_REQUESTS = 'InProgressRequests';
export const NOT_REJECTED_REQUESTS = 'NotRejectedRequests';
export const ON_MATCHING_REQUESTS = 'OnMatchingRequests';
export const ON_CREATE_REQUESTS = 'OnCreateRequests';
export const ON_ACCEPT_OR_ON_TERRITORY_OR_CREATE_AND_CAR_ENTRY_REQUESTS = 'OnAcceptOrOnTerritoryOrCreateAndCarEntryRequests';
export const REQUESTS_FOR_ON_PASS = 'RequestsForOnPass';
export const REQUESTS_FOR_USER_DEPARTMENT = 'RequestsForUserDepartment';
export const REQUESTS_ON_CAR_ENTRY = 'RequestsOnCarEntry';
export const REQUESTS_ON_CAR_TYPE = 'RequestsOnCarType';
export const REQUESTS_ON_VISIT = 'RequestsOnVisit';
export const REQUESTS_ON_A_DEPT = 'RequestsOnADept';
export const REQUESTS_ON_B_DEPT = 'RequestsOnBDept';
export const SERVICE_REQUESTS = 'ServiceRequests';
export const ACCESS_CARD_REQUESTS = 'AccessCardRequests';
export const REQUESTS_ON_WORK_PRODUCTION = 'RequestsOnWorkProduction';
export const REQUESTS_SERVICE_AND_WORK_PRODUCTION = 'RequestsServiceAndWorkProduction';
export const SERVICE_WORKS = 'ServiceWorkRequests';
export const REQUESTS_WITHOUT_SERVICE_TYPE = 'RequestsWithoutServiceType';
export const REQUESTS_WITHOUT_ON_PASS_MAKING_TYPE = 'RequestsWithoutOnPassMakingType';
export const MANAGER_REQUESTS = 'ManagerRequests';
export const ACTIVE_TASKS = 'ActiveTasks';
export const NOT_PASS_ISSUED = 'NotPassIssued';

// Applicant
export const REQUESTS_FOR_APPLICANT = 'RequestsForApplicant';
// Assignee
export const REQUESTS_FOR_SERVICE_DESK_ASSIGNEE = 'RequestsForServiceDeskAssignee';
// CheckpointOperator
export const REQUESTS_FOR_CHECKPOINT_OPERATOR = 'RequestsForCheckpointOperator';
// Checkpoint
export const REQUESTS_FOR_CHECKPOINT = 'RequestsForCheckpoint';
// MakingAgreementBC
export const REQUESTS_FOR_MAKING_AGREEMENT_BC = 'RequestsForMakingAgreementBC';
// ParkingOperator
export const REQUESTS_FOR_PARKING_OPERATOR = 'RequestsForParkingOperator';
// SeniorParkingOperator
export const REQUESTS_FOR_SENIOR_PARKING_OPERATOR = 'RequestsForSeniorParkingOperator';
// Tenant
export const REQUESTS_FOR_TENANT = 'RequestsForTenant';
// UnloadingZoneOperator
export const REQUESTS_UNLOADING_ZONE_OPERATOR = 'RequestsUnloadingZoneOperator';


/** Lefort(11994) */
export const ACTIVE_REQUESTS_ON_GUEST_PARKING = 'ActiveRequestsOnGuestParking';
export const REQUESTS_ON_TMC_MOVEMENT = 'RequestsOnTmcMovement';

// Reception
export const REQUESTS_FOR_LEFORT_RECEPTION = 'RequestsForLefortReception';
export const REQUESTS_FOR_LEFORT_RECEPTION_DEPARTMENTS = 'RequestsForLefortReceptionDepartments';

/** Comcity(12970) */
export const COMCITY_CURRENT_DAY_REQUESTS = 'ComcityCurrentDayRequests';

// CheckpointOperator
export const REQUESTS_FOR_COMCITY_CHECKPOINT_OPERATOR = 'RequestsForComcityCheckpointOperator';
// ParkingOperator
export const REQUESTS_FOR_COMCITY_PARKING_OPERATOR = 'RequestsForComcityParkingOperator';
export const CURRENT_DAY_REQUESTS_FOR_COMCITY_PARKING_OPERATOR = 'CurrentDayRequestsForComcityParkingOperator';
// Reception
export const REQUESTS_FOR_COMCITY_RECEPTION = 'RequestsForComcityReception';
// SeniorParkingOperator
export const REQUESTS_FOR_COMCITY_SENIOR_PARKING_OPERATOR = 'RequestsForComcitySeniorParkingOperator';
// UnloadingZoneOperator
export const REQUESTS_FOR_COMCITY_UNLOADING_ZONE_OPERATOR = 'RequestsForComcityUnloadingZoneOperator';

/** @DOCUMENTS-FILTERS */
export const DOCUMENTS_FOR_MY_VISA = 'DocumentsForMyVisa';
export const MY_AGREEMENT_HISTORIES = 'MyAgreementHistories';
export const ALL_SUPPLY_REQUESTS = 'AllSupplyRequests';
export const ACTIVE_SUPPLY_REQUESTS = 'ActiveSupplyRequests';

/** @RULES-FILTERS */
export const ACTIVE_RULES = 'ActiveRules';
export const COMPANY_RULES = 'CompanyRules';
export const NEWS_RULES = 'NewsRules';
export const NOT_NEWS_RULES = 'NotNewsRules';

/** @TASKS-FILTERS */
export const USER_AUTHOR_TASK = 'UserAuthorTasks';
export const USER_RESPONSIBLE_TASK = 'UserResponsibleTasks';
export const TASKS_FOR_USER_DEPARTMENT = 'TasksForUserDepartment';

/** @USERS-FILTERS */
export const USERS_WITHOUT_SYSADMIN = 'UsersWithoutSysadmin'

/** Comcity(12970) */
export const RULES_FOR_COMCITY_TENANT = 'RulesForComcityTenant';

/** Factoriya BC (14419) */
export const REQUESTS_FOR_AGREE = 'RequestsForAgree';
export const SERVICE_REQUESTS_NOT_INSTRUCTED = 'ServiceRequestsNotInstructed';

/** Gorkiy Park (14720) */
export const REQUESTS_WITHOUT_EMPTY_NAME = 'RequestsWithoutEmptyName';

/** Khimki (14326) */
export const WORKS_ON_SERVICE_DESK = 'WorksOnServiceDesk';

/**  Delta Plaza (15511)  */
export const REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED = "RequestsOnPropertyBringingOrRemoved";

/**  Krylatsky (15755)  */
// Checkpoint
export const REQUESTS_FOR_CHECKPOINT_POST = 'RequestsForCheckpointPost';
export const REQUESTS_ON_PROPERTY_BRINGING = "RequestsOnPropertyBringing";
export const REQUESTS_ON_PROPERTY_REMOVED = "RequestsOnPropertyRemoved";

