/*
 *
 * ServiceRequestsManagerForm14720
 *
 */


import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';
import { ON_PASS_MAKING, SERVICE, } from 'domain/Data/RequestType/constants';
import { estimationCost, estimationCostScan } from "../common/dxGridComponents/fields";

export class ServiceRequestsManagerForm14720 extends ServiceRequestsManagerForm {
  getFields() {
    const fields = super.getFields();
    fields.push({
      name: 'phone',
      label: 'Контактный тел',
      grid: { sm: 6 },
      required: (currentValues, mode) => mode === 'add',
      initialValue: (values, mode, user, entities) => {
        const author = entities.get('employees').get(values.author);
        return author ? author.phone : null;
      },
      rowBreakBefore: (values) => values.type === SERVICE || values.type === ON_PASS_MAKING,
    });

    fields.push(estimationCost);
    fields.push(estimationCostScan);

    return fields;
  }
}
