/**
 *
 * ActionMenu Items
 *
 */

import React from 'react';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import { requestAction } from 'domain/typeConstants/action';
import { documentStatus } from 'domain/typeConstants/status';


export const viewActionItem = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
  hidden: (itemMenuRecord, entities, currentRecord) => {

    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name !== documentStatus.ARCHIVED
    } else if (itemMenuRecord.length === 1) {
      const state = entities[0].state?.name;
      if (!state) return false;
      return !(state === documentStatus.ARCHIVED);
    }
    return true;
  },
};

export const editActionItem = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  hidden: (itemMenuRecord, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === documentStatus.ARCHIVED
    } else if (itemMenuRecord.length === 1) {
      const state = entities[0].state?.name;
      if (!state) return false;
      return (state === documentStatus.ARCHIVED);
    }
    return true;
  },
};
