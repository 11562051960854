/**
 *
 * getDocumentsPage Function
 *
 */
import React from 'react';

import DocumentsPage from './DocumentsPage';
import { account } from 'domain/typeConstants/accounts';
import DocumentsPage15767 from 'custom/15767/pages/Documents/DocumentsPage15767';

export default function getRequestsPage(accountId, props) {
  switch (accountId) {

    case account.TVK:
      return (
        <DocumentsPage15767 {...props} />
      )

    default:
      return (
        <DocumentsPage {...props} />
      );
  }
}
