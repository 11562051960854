/**
 *
 * CheckpointDxGrid11994
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';

import { acceptActionButton } from 'pages/Requests/common/actionButton';
import { acceptActionItem } from 'pages/Requests/common/actionMenu';

import {
  ACTIVE_REQUESTS_ON_GUEST_PARKING, REQUESTS_ON_TMC_MOVEMENT,
} from 'domain/Data/Filters/constants';

import {
  MOVEMENT_TMC_COLUMNS,
  MOVEMENT_TMC_COLUMN_WIDTHS,
  MOVEMENT_TMC_COLUMN_FILTERS,
  MOVEMENT_TMC_COLUMN_ORDER,
} from '../common/dxGridComponents';

class CheckpointDxGrid11994 extends CheckpointDxGrid { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;
    const filters = super.getFilters();
    if (viewName === 'guest') filters.push(ACTIVE_REQUESTS_ON_GUEST_PARKING);
    if (viewName === 'movementTMC') filters.push(REQUESTS_ON_TMC_MOVEMENT);

    return filters;
  }

  getColumns() {
    const { viewName } = this.props;

    if (viewName === 'movementTMC') {
      return MOVEMENT_TMC_COLUMNS;
    }

    return super.getColumns();
  }

  getGridFilters() {
    const { viewName } = this.props;

    if (viewName === 'movementTMC') {
      return MOVEMENT_TMC_COLUMN_FILTERS;
    }

    return super.getGridFilters();
  }

  getColumnOrder() {
    const { viewName } = this.props;

    if (viewName === 'movementTMC') {
      return MOVEMENT_TMC_COLUMN_ORDER;
    }

    return super.getColumnOrder();
  }

  getActionButtons = () => {
    const actionButtons = super.getActionButtons();
    actionButtons.push(acceptActionButton);
    return actionButtons;
  };

  getActionMenuItems = () => {
    const actionMenuItems = super.getActionMenuItems();
    actionMenuItems.push(acceptActionItem);
    return actionMenuItems;
  };
}

export default CheckpointDxGrid11994;
