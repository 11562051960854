/*
 *
 * Request actions
 *
 */

import {
  ACCEPT_REQUESTS,
  ACCEPT_REQUESTS_SUCCESS,
  ACCEPT_REQUESTS_ERROR,

  IN_PROGRESS_REQUESTS,
  IN_PROGRESS_REQUESTS_SUCCESS,
  IN_PROGRESS_REQUESTS_ERROR,

  REJECT_REQUESTS,
  REJECT_REQUESTS_SUCCESS,
  REJECT_REQUESTS_ERROR,

  CLOSE_REQUESTS,
  CLOSE_REQUESTS_SUCCESS,
  CLOSE_REQUESTS_ERROR,

  PROCESS_REQUESTS_ON_VISITOR_ARRIVAL,
  PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_SUCCESS,
  PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_ERROR,

  PROCESS_REQUESTS_ON_VISITOR_DEPARTURE,
  PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_SUCCESS,
  PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_ERROR,

  SEND_TO_AGREEMENT_BY_TENANT,
  SEND_TO_AGREEMENT_BY_TENANT_ERROR,
  SEND_TO_AGREEMENT_BY_TENANT_SUCCESS,

  EXECUTE_SEND_TO_PERFORMER,
  SEND_TO_PERFORMER_SUCCESS,
  SEND_TO_PERFORMER_ERROR,

  INSTRUCTED_REQUESTS,
  INSTRUCTED_REQUESTS_ERROR,
  INSTRUCTED_REQUESTS_SUCCESS,

  PERFORME_REQUESTS,
  PERFORME_REQUESTS_SUCCESS,
  PERFORME_REQUESTS_ERROR,
  SEND_NO_PLACES,
  SENDED_NO_PLACES,
  SEND_NO_PLACES_ERROR,

} from './constants';

export function acceptRequests(ids) {
  return {
    type: ACCEPT_REQUESTS,
    payload: { ids },
  };
}

export function requestsAccepted() {
  return {
    type: ACCEPT_REQUESTS_SUCCESS,
  };
}

export function requestsAcceptingError(error) {
  return {
    type: ACCEPT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function inProgressRequests(ids) {
  return {
    type: IN_PROGRESS_REQUESTS,
    payload: { ids },
  };
}

export function requestsInProgressed() {
  return {
    type: IN_PROGRESS_REQUESTS_SUCCESS,
  };
}

export function requestsInProgressError(error) {
  return {
    type: IN_PROGRESS_REQUESTS_ERROR,
    payload: { error },
  };
}

export function closeRequests(ids) {
  return {
    type: CLOSE_REQUESTS,
    payload: { ids },
  };
}

export function requestsClosed() {
  return {
    type: CLOSE_REQUESTS_SUCCESS,
  };
}

export function requestsClosingError(error) {
  return {
    type: CLOSE_REQUESTS_ERROR,
    payload: { error },
  };
}


export function performeRequests(ids) {
  return {
    type: PERFORME_REQUESTS,
    payload: { ids },
  };
}

export function requestsPerformed(ids) {
  return {
    type: PERFORME_REQUESTS_SUCCESS,
    payload: { ids },
  };
}

export function requestsPerformedError(ids) {
  return {
    type: PERFORME_REQUESTS_ERROR,
    payload: { ids },
  };
}


export function rejectRequests(ids) {
  return {
    type: REJECT_REQUESTS,
    payload: { ids },
  };
}

export function requestsRejected() {
  return {
    type: REJECT_REQUESTS_SUCCESS,
  };
}

export function requestsRejectingError(error) {
  return {
    type: REJECT_REQUESTS_ERROR,
    payload: { error },
  };
}

export function processVisitorArrival(ids) {
  return {
    type: PROCESS_REQUESTS_ON_VISITOR_ARRIVAL,
    payload: { ids },
  };
}

export function visitorArrivalProcessed() {
  return {
    type: PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_SUCCESS,
  };
}

export function visitorArrivalProcessingError(error) {
  return {
    type: PROCESS_REQUESTS_ON_VISITOR_ARRIVAL_ERROR,
    payload: { error },
  };
}

export function processVisitorDeparture(ids) {
  return {
    type: PROCESS_REQUESTS_ON_VISITOR_DEPARTURE,
    payload: { ids },
  };
}

export function visitorDepartureProcessed() {
  return {
    type: PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_SUCCESS,
  };
}

export function visitorDepartureProcessingError(error) {
  return {
    type: PROCESS_REQUESTS_ON_VISITOR_DEPARTURE_ERROR,
    payload: { error },
  };
}

export function sendToAgreementByTenantRequests(ids) {
  return {
    type: SEND_TO_AGREEMENT_BY_TENANT,
    payload: { ids },
  };
}

export function requestsSendedToAgreementByTenant() {
  return {
    type: SEND_TO_AGREEMENT_BY_TENANT_SUCCESS,
  };
}

export function requestsSendingToAgreementByTenant(error) {
  return {
    type: SEND_TO_AGREEMENT_BY_TENANT_ERROR,
    payload: { error },
  };
}

export function sendToPerformerRequests(ids) {
  return {
    type: EXECUTE_SEND_TO_PERFORMER,
    payload: { ids },
  };
}

export function requestsSendedToPerformer() {
  return {
    type: SEND_TO_PERFORMER_SUCCESS,
  };
}

export function requestsSendingToPerformer(error) {
  return {
    type: SEND_TO_PERFORMER_ERROR,
    payload: { error },
  };
}

export function sendNoPlacesRequests(ids) {
  return {
    type: SEND_NO_PLACES,
    payload: { ids },
  };
}

export function requestsSendedNoPlaces() {
  return {
    type: SENDED_NO_PLACES,
  };
}

export function requestsSendingNoPlaces(error) {
  return {
    type: SEND_NO_PLACES_ERROR,
    payload: { error },
  };
}

export function instructRequests(ids) {
  return {
    type: INSTRUCTED_REQUESTS,
    payload: { ids }
  };
}

export function instructedRequests() {
  return {
    type: INSTRUCTED_REQUESTS_SUCCESS,
  };
}

export function instructingRequests(error) {
  return {
    type: INSTRUCTED_REQUESTS_ERROR,
    payload: { error },
  };
}

export function closeByTenantRequests(ids) {
  return {
    type: CLOSE_REQUESTS,
    payload: { ids }
  };
}

export function closedByTenantRequests() {
  return {
    type: CLOSE_REQUESTS_SUCCESS,
  };
}

export function closingByTenantRequests(error) {
  return {
    type: CLOSE_REQUESTS_ERROR,
    payload: { error },
  };
}
