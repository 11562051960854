import React from 'react';
import PropTypes from 'prop-types';

import TaskView, {
  TaskDxGrid,
  TaskDxGridContainer,
  TaskForm,
  TaskFormDialog,
  TaskMobileGrid,
  WorkForm,
  WorkFormDialog,
} from '../TaskView';

import { getTaskDetailContainer } from 'pages/common/detailContainers';

const getDxGrid = () => (
  <TaskDxGridContainer component={<TaskDxGrid />} />
);

const getMobileGrid = () => (
  <TaskDxGridContainer component={<TaskMobileGrid />} />
);

const getFormDialog = () => (
  <TaskFormDialog
    form={<TaskForm />}
    detailContainer={getTaskDetailContainer()}
  />
);

const getWorkFormDialog = () => (
  <WorkFormDialog form={<WorkForm />} />
);

const getDataTableView = ({ breakpoint }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid();
  }
  return getDxGrid()
};

export const getTaskView = (props) => {
  const {
    breakpoint,
    taskView,
    params,
    viewName,
    viewStyles,
    accountId,
  } = props;

  if (taskView) return taskView;
  return (
    <TaskView
      key={viewName}
      viewName={viewName}
      params={params}
      breakpoint={breakpoint}
      dataTable={getDataTableView({
        breakpoint
      })}
      formDialog={getFormDialog()}
      workformDialog={getWorkFormDialog()}
      viewStyles={viewStyles}
      accountId={accountId}
    />
  );
};

getTaskView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  viewName: PropTypes.string,
  params: PropTypes.object,
  viewStyles: PropTypes.object,
};
