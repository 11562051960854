/**
 *
 * AdvancedFormDialog actions
 *
 */

import {
  OPEN_DIALOG,
  SAVE_DIALOG,
  SAVE_DIALOG_SUCCESS,
  SAVE_DIALOG_ERROR,
  CLOSE_DIALOG,
  REGISTER,
  UNREGISTER,
  CHANGE_LOADING_STATE,
  CHANGE_TAB,
} from './constants';

export function openAddDialog(dialog, { parentId, parent, params }) {
  return {
    type: OPEN_DIALOG,
    meta: { dialog },
    payload: { mode: 'add', parent, parentId, params },
  };
}

export function openEditDialog(dialog, { id, parent, parentId, status }) {
  return {
    type: OPEN_DIALOG,
    meta: { dialog },
    payload: { mode: 'edit', entityId: id, parent, parentId, status },
  };
}

export function openViewDialog(dialog, { id, parent, parentId }) {
  return {
    type: OPEN_DIALOG,
    meta: { dialog },
    payload: { mode: 'view', entityId: id, parent, parentId },
  };
}

export function openDeleteDialog(dialog, entityId, params) {
  return {
    type: OPEN_DIALOG,
    meta: { dialog },
    payload: { mode: 'delete', entityId, params },
  };
}

export function openCopyDialog(dialog, { id, parent, parentId }) {
  return {
    type: OPEN_DIALOG,
    meta: { dialog },
    payload: { mode: 'copy', entityId: id, parent, parentId },
  };
}

export function saveDialog(dialog) {
  return {
    type: SAVE_DIALOG,
    meta: { dialog },
  };
}

export function dialogSaved(dialog) {
  return {
    type: SAVE_DIALOG_SUCCESS,
    meta: { dialog },
  };
}

export function dialogSavingError(dialog, error) {
  return {
    type: SAVE_DIALOG_ERROR,
    meta: { dialog },
    payload: { error },
  };
}

export function closeDialog(dialog) {
  return {
    type: CLOSE_DIALOG,
    meta: { dialog },
  };
}

export function register(dialog, form, navigation) {
  return {
    type: REGISTER,
    meta: { dialog, form },
    payload: { navigation }
  };
}

export function unregister(dialog) {
  return {
    type: UNREGISTER,
    meta: { dialog },
  };
}

export function changeLoadingState(dialog, loadingState) {
  return {
    type: CHANGE_LOADING_STATE,
    meta: { dialog },
    payload: { loadingState },
  };
}

export function changeTab(dialog, id) {
  return {
    type: CHANGE_TAB,
    meta: { dialog },
    payload: { id },
  };
}
