/*
 *
 * AdministratorBCRequestsFormDialogContainer14326
 *
 */

import { ReceptionFormDialogContainer14326 } from '../ReceptionView';

export class AdministratorBCRequestsFormDialogContainer14326 extends ReceptionFormDialogContainer14326 {

}
