import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

class ToolbarButton extends React.PureComponent {
  render() {
    const { name, onClick, tooltipTitle, icon, disabled } = this.props;

    return (
      disabled ?
        <IconButton
          aria-label={name}
          onClick={onClick}
          size="medium"
          variant="fab"
          disabled={disabled}
        >
          {icon}
        </IconButton>
        :
        <Tooltip
          id="tooltip-fab"
          placement="bottom"
          title={tooltipTitle}
        >
          <IconButton
            aria-label={name}
            onClick={onClick}
            size="medium"
            variant="fab"
          >
            {icon}
          </IconButton>
        </Tooltip>
    );
  }
}

ToolbarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string,
};

ToolbarButton.defaultProps = {
  tooltipTitle: '',
};

export default ToolbarButton;
