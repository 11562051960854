/**
 *
 * TenantFormDialog14326
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { TENANT_REQUESTS_FORM, TENANT_REQUESTS_FORM_DIALOG } from 'pages/Requests/TenantView/constants';

export function TenantFormDialog14326({ form }) {
  return (
    <AdvancedFormDialog
      name={TENANT_REQUESTS_FORM_DIALOG}
      formName={TENANT_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
      addModeSaveButtonLabel="Сохранить и Отправить"
      copyModeSaveButtonLabel="Сохранить и Отправить"
    />
  );
}

TenantFormDialog14326.propTypes = {
  form: PropTypes.node.isRequired,
};

