/**
 *
 * action types
 *
 */

export const requestAction = Object.freeze({
  ACCEPT: 'accept',
  ADD: 'add',
  ADD_TO_BLACK_LIST: 'addBlackListItem',
  ARRIVED: 'arrived',
  ARRIVED_GROUP: 'arrivedGroup',
  CLOSE: 'close',
  COMPLETE: 'complete',
  COPY: 'copy',
  DEPARTED: 'departed',
  EDIT: 'edit',
  PRINT: 'print',
  REJECT: 'reject',
  SEND_TO_AGREEMENT: 'sendToAgreement',
  SEND_TO_PERFORMER: 'sendToPerformer',
  VIEW: 'view',
  SEND_TO_AGREEMENT_BY_TENANT: 'sendToAgreementByTenant',
  SEND_EMAIL: 'sendEmail',
  INSTRUCTED: 'instructed',
  PERFORME: 'performe',
  DELETE: 'delete',
  CLOSE_BY_TENANT: 'closeByTenant',
  AGREED_ESTIMATION_COST: 'agreedEstimationCost',
  EDIT_BY_STATUS: 'editByStatus',
  COPY_BY_STATUS: 'copyByStatus',
  CARGO_RECEIVED: 'cargoReceived',
  ORIGINALS_DELIVERED: 'originalsDelivered',
  REPORT: 'report',
  FIRED: 'fired',
  SELECT: 'select',
  NO_PLACES: 'noPlaces',
});

export const documentAction = Object.freeze({
  ADD: 'add',
  AGREE: 'agree',
  DISAGREE: 'disagree',
  RUN: 'run',
  EDIT: 'edit',
  VIEW: 'view',
  EDIT_BY_STATUS: 'editByStatus',
});
