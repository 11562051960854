/*
 *
 * AdministratorBCRequestsForm14691
 *
 */

import { ReceptionForm14691 } from '../ReceptionView';

export class AdministratorBCRequestsForm14691 extends ReceptionForm14691 {
    getFields() {
        const fields = super.getFields();
        const managementCompanyCommentIndex = fields.findIndex((fields) => fields.name === 'managementCompanyComment');
        fields[managementCompanyCommentIndex].disabled = false;
        return fields;
    }
}
