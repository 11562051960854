/*
 * NotificationsPage sagas
 */

import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';

import {
  LOAD_UNREAD_NOTIFICATIONS_COUNT,
  READ_ALL_NOTIFICATIONS,
} from './constants';

import {
  allNotificationsReaded,
  allNotificationsReadingError,
  unreadNotificationsCountLoaded,
  unreadNotificationsCountLoadingError,
} from './actions';

import { makeSelectUnreadCount } from './selectors';

export default function* rootSaga() {
  yield takeLatest(LOAD_UNREAD_NOTIFICATIONS_COUNT, loadUnreadNotifcationsCountSaga);
  yield takeLatest(READ_ALL_NOTIFICATIONS, readAllNotifcationsSaga);
}

export function* loadUnreadNotifcationsCountSaga() {
  try {
    const response = yield call(api.getUnreadNotificationsCount);
    const { unreadCount } = response.data;
    yield put(unreadNotificationsCountLoaded(unreadCount));
  } catch (err) {
    yield put(unreadNotificationsCountLoadingError(err));
  }
}

export function* readAllNotifcationsSaga() {
  try {
    const unreadCount = yield select(makeSelectUnreadCount());
    if (!unreadCount) {
      return;
    }
    yield call(api.readUserNotifications);
    yield put(allNotificationsReaded());
  } catch (err) {
    yield put(allNotificationsReadingError(err));
  }
}
