/**
 *
 * DisagreementDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';

const styleSheet = {
  textField: {
    width: 500,
  },
  errorMessage: {
    color: '#F44336',
    fontSize: 14,
    height: 22,
    textTransform: 'Uppercase',
  },
};

const ERROR_MESSAGE = 'Поле Комментарий обязательное для заполнения. Запишите причину отклонения.';

class DisagreementDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      errorMessage: '',
    };
  }

  handleSubmitButtonClick = () => {
    const { comment } = this.state;
    const { ids, onSubmit, dxGrid } = this.props;
    if (comment === '') {
      this.setState({ errorMessage: ERROR_MESSAGE });
      return;
    }
    this.setState({ comment: '' });
    if (onSubmit) onSubmit(ids, comment, dxGrid);
  };

  handleCancelButtonClick = () => {
    this.setState({ errorMessage: '', comment: '' });
    this.props.onCancel();
  };

  handleChange = (event) => this.setState({ comment: event.target.value });

  render() {
    const {
      classes,
      open,
    } = this.props;
    const { comment, errorMessage } = this.state;

    return (
      <div>
        <Dialog
          open={open}
        >
          <DialogTitle>
            <span>Отклонение документа</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>
            <form>
              <TextField
                className={classes.textField}
                label="Комментарий"
                margin="normal"
                multiline
                onChange={this.handleChange}
                rows={3}
                value={comment}
                required
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSubmitButtonClick} color="primary" autoFocus>
              Отклонить
            </Button>
            <Button onClick={this.handleCancelButtonClick} color="primary">
              Отмена
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DisagreementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
};

export default withStyles(styleSheet)(DisagreementDialog);
