/**
 * The dashboard state selectors
 */

import { createSelector } from 'reselect';

const makeSelectDashboard = () => (state) => state.get('dashboard');

const makeSelectEntityData = (entity) => createSelector(
    makeSelectDashboard(),
    (dashboardState) => dashboardState?.getIn([entity, 'data'])
);

const makeSelectEntityRange = (entity) => createSelector(
    makeSelectDashboard(),
    (dashboardState) => dashboardState?.getIn([entity, 'range'])
);

const makeSelectFilter = () => createSelector(
    makeSelectDashboard(),
    (dashboardState) => dashboardState?.get('filter')
);

export default makeSelectDashboard;
export {
    makeSelectEntityData,
    makeSelectEntityRange,
    makeSelectFilter,
};