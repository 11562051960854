/**
 *
 * ReceptionDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { documentAction } from 'domain/typeConstants/action';
import { makeSelectDepartmentId, makeSelectRoles } from 'domain/User/selectors';
import * as actionCreators from './actions';

class ReceptionDxGridContainerBase extends React.PureComponent {

  handleRowDoubleClick = (record) => this.props.executeEditAction(record.id);

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case documentAction.ADD:
        this.props.executeAddAction();
        break;

      case documentAction.EDIT:
        this.props.executeEditAction(ids[0]);
        break;

      case documentAction.VIEW:
        this.props.executeViewAction(ids[0]);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName, department, roles } = this.props;
    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
      department,
      roles,
    });
  }
}

ReceptionDxGridContainerBase.propTypes = {
  component: PropTypes.node.isRequired,
  department: PropTypes.string.isRequired,
  executeAddAction: PropTypes.func.isRequired,
  executeEditAction: PropTypes.func.isRequired,
  executeViewAction: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  viewName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  department: makeSelectDepartmentId(),
  roles: makeSelectRoles(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionDxGridContainerBase);