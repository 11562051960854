import { injectGlobal } from 'styled-components';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
  }

  #app {
    width: 100%;
    height: 100%;
  }
`;


