/*
 *
 * ServiceRequestsManagerRejectFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { SERVICE_REQUESTS_MANAGER_REJECT_FORM, SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG } from './constants';

function ServiceRequestsManagerRejectFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG}
      formName={SERVICE_REQUESTS_MANAGER_REJECT_FORM}
      advancedForm={form}
      editModeTitle="Отклонить заявку"
    />
  );
}

ServiceRequestsManagerRejectFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ServiceRequestsManagerRejectFormDialog;
