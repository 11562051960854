import React from 'react';
import PropTypes from 'prop-types';

import AdminSkudView, {
  AdminSkudDxGrid,
  AdminSkudDxGridContainer,
  AdminSkudForm,
  AdminSkudFormDialog,
  AdminSkudRejectForm,
} from '../AdminSkud';
import { ReceptionRejectFormDialog } from '../ReceptionView';


const getDxGrid = (DxGrid) => (
  <AdminSkudDxGridContainer component={DxGrid || <AdminSkudDxGrid />} />
);

const getDataTableView = ({ adminSkudDxGrid }) => {
  return getDxGrid(adminSkudDxGrid);
};

const getFormDialog = (Form) => (
  <AdminSkudFormDialog form={Form || <AdminSkudForm />} />
);

const getRejectFormDialog = (rejectForm) => (
  <ReceptionRejectFormDialog form={rejectForm || <AdminSkudRejectForm />} />
);

export const getAdminSkudView = (props) => {
  const {
    adminSkudDxGrid,
    adminSkudForm,
    adminSkudFormDialog,
    adminSkudView,
    rejectForm,
    rejectFormDialog,
    viewName,
    viewStyles,
  } = props;

  if (adminSkudView) return adminSkudView;

  return (
    <AdminSkudView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({ adminSkudDxGrid })}
      formDialog={adminSkudFormDialog || getFormDialog(adminSkudForm)}
      rejectFormDialog={rejectFormDialog || getRejectFormDialog(rejectForm)}
    />
  );
};

getAdminSkudView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  adminSkudDxGrid: PropTypes.node,
  adminSkudForm: PropTypes.node,
  adminSkudFormDialog: PropTypes.node,
  adminSkudView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
