/**
*
* DateField
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from 'material-ui-pickers';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

class DateField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.input.value,
    };
  }

  componentDidUpdate(prevProps) {
    const { input: { value } } = this.props;

    if (prevProps.input.value !== value) {
      this.setState({ // eslint-disable-line
        date: value,
      });
    }
  }

  handleDateChange = (date) => {
    const { input: { onChange } } = this.props;
    onChange(date);
    this.setState({ date });
  };

  handleClearDateChange = (event) => {
    event.stopPropagation();
    const { input: { onChange } } = this.props;
    onChange(null);
    this.setState({ date: null });
  };

  handleInputClick = (event) => {
    const { readOnly } = this.props;
    if (readOnly) event.stopPropagation();
  };

  render() {
    const {
      classNames,
      disabled,
      helperText,
      input,
      label,
      meta: { touched, error, submitFailed },
      readOnly,
      required,
      fieldName,
      fileSize,
      ...custom
    } = this.props;
    const { date } = this.state;

    return (
      <DatePicker
        format={this.props.formatmask}
        autoOk
        margin="none"
        label={required ? `${label} *` : label}
        cancelLabel="Отмена"
        error={(touched || submitFailed) && !!error}
        helperText={(touched || submitFailed) && error ? error : helperText}
        invalidLabel=""
        disabled={disabled}
        FormHelperTextProps={{
          classes: {
            root: classNames.helperText,
          },
        }}
        InputProps={{
          readOnly,
          endAdornment: date && !readOnly && !disabled && (
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear for datepcker"
                onClick={this.handleClearDateChange}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
          onClick: this.handleInputClick,
        }}
        {...input}
        onBlur={() => null}
        onChange={this.handleDateChange}
        value={date}
        {...custom}
      />
    );
  }
}

DateField.propTypes = {
  classNames: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

DateField.defaultProps = {
  classNames: {},
};

export default DateField;
