/*
 *
 * AdvancedFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from "@material-ui/core/Grid";
import Loader from 'components/CircularLoader';
import IconButton from '@material-ui/core/IconButton';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Tooltip from '@material-ui/core/Tooltip';
import {USER_TASK_FORM} from 'pages/Tasks/TaskView/constants';
import {taskStatusId} from 'domain/typeConstants/status';
import {isNull} from 'lodash';
import TabBar from 'containers/TabBar';

const MUIStyles = (theme) => ({
    root: {},
    paper: {
        [theme.breakpoints.down('md')]: {
            margin: 10,
        },
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-around',
        },
    },
    title: {
        [theme.breakpoints.down('md')]: {
            padding: '15px 15px 10px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px 7px',
        },
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px 10px',
        },
    },
});

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);


class AdvancedFormDialog extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        };
        this._timeId = null;
    }

    componentDidMount() {
        this._timeId = setTimeout(() => this.setState({ready: true}), 500);
    }

    componentWillUnmount() {
        clearTimeout(this._timeId);
    }

    handleSaveButtonClick = () => {
        const {onSave} = this.props;
        if (onSave) onSave();
    };

    handleCancelButtonClick = () => {
        const {onCancel} = this.props;
        if (onCancel) onCancel();
    };

    handleCloseButtonClick = () => {
        const {onClose} = this.props;
        if (onClose) onClose();
    };

    handleDeleteButtonClick = () => {
        const {onDelete} = this.props;
        if (onDelete) onDelete();
    };

    renderActions(mode) {
        const {loadingState} = this.props;

        switch (mode) {
            case 'add':
                return ([
                    <Button key={1} onClick={this.handleSaveButtonClick} disabled={loadingState} color="primary">
                        {this.props.addModeSaveButtonLabel}
                    </Button>,
                    <Button key={2} onClick={this.handleCancelButtonClick} color="primary">
                        {this.props.addModeCancelButtonLabel}
                    </Button>,
                ]);

            case 'edit':
                return ([
                    <Button key={1} onClick={this.handleSaveButtonClick} disabled={loadingState} color="primary">
                        {this.props.editModeSaveButtonLabel}
                    </Button>,
                    <Button key={2} onClick={this.handleCancelButtonClick} color="primary">
                        {this.props.editModeCancelButtonLabel}
                    </Button>,
                ]);

            case 'view':
                return (
                    <Button onClick={this.handleCloseButtonClick} color="primary">
                        {this.props.viewModeCloseButtonLabel}
                    </Button>
                );

            case 'copy':
                return ([
                    <Button key={1} onClick={this.handleSaveButtonClick} disabled={loadingState} color="primary">
                        {this.props.copyModeSaveButtonLabel}
                    </Button>,
                    <Button key={2} onClick={this.handleCancelButtonClick} color="primary">
                        {this.props.copyModeCancelButtonLabel}
                    </Button>,
                ]);

            case 'delete':
                return ([
                    <Button key={1} onClick={this.handleDeleteButtonClick} disabled={loadingState} color="primary">
                        {this.props.deleteModeSaveButtonLabel}
                    </Button>,
                    <Button key={2} onClick={this.handleCancelButtonClick} color="primary">
                        {this.props.deleteModeCancelButtonLabel}
                    </Button>,
                ]);

            default:
                return this.props.actions;
        }
    }

    renderCustomButtons(mode) {
        const isDisabled = mode === 'add'
        return this.props.customButtons.map((button) =>
                !(button.visible === false)
                && <Button key={button.name}
                           onClick={button.onClick}
                           color="primary"
                           disabled={!isNull(button.disabled) ? button.disabled : isDisabled}
                >{button.label}</Button>
        );
    }

    renderTitle(mode) {
        switch (mode) {
            case 'add':
                return this.props.addModeTitle;

            case 'edit':
                return this.props.editModeTitle;

            case 'view':
                return this.props.viewModeTitle;

            case 'copy':
                return this.props.copyModeTitle;

            case 'delete':
                return this.props.deleteModeTitle;

            default:
                return this.props.title;
        }
    }

    renderMobileCustomActions(mode) {
        return (
            <Grid container
                  direction="row"
                  justify="space-around">
                {
                    this.props.customButtons && this.renderCustomButtons(mode)
                }
            </Grid>
        );
    }

    renderMobileActions(mode) {
        return (
            <Grid container
                  direction="row"
                  justify="space-around">
                {
                    this.renderActions(mode)
                }
            </Grid>
        );
    }

    renderNavigateButton(title, idx, increment, limit, direction) {
        const isDisabled = idx === limit;
        const icon = direction === "forward" ? <NavigateNext/> : <NavigateBefore/>

        const onClick = (idx) => {
            const {entities, entityIds, openEditDialog, openViewDialog, navigation} = this.props
            const id = entityIds[idx]
            const entity = entities.get(id)

            // для задач
            if ([taskStatusId.CLOSED, taskStatusId.COMPLETED].includes(entity.status)) {
                openViewDialog({id, status: entity.status, navigation})
            } else openEditDialog({id, status: entity.status, navigation})

        };

        return (
            isDisabled ?
                <IconButton
                    aria-label={direction}
                    disabled={isDisabled}
                >
                    {icon}
                </IconButton>
                :
                <LightTooltip title={title} placement="bottom-start">
                    <IconButton
                        aria-label={direction}
                        onClick={() => onClick(idx + increment)}
                    >
                        {icon}
                    </IconButton>
                </LightTooltip>
        )
    }

    renderNavigationButtons(mode) {
        const {formName, entityIds, entityId} = this.props
        if (entityIds && entityId) {
            const idx = entityIds.indexOf(entityId)
            if (formName === USER_TASK_FORM && (mode === 'edit' || mode === 'view')) {
                return (
                    <div>
                        {this.renderNavigateButton("Предыдущий", idx, -1, 0, 'back')}
                        {this.renderNavigateButton("Следующий", idx, 1, entityIds.length - 1, 'forward')}
                    </div>
                )
            }
        }
    }

    specifyContainers(detailContainer, entityId, dialogName) {
        const {height, items} = detailContainer;
        return items.map((item) => {
            return {
                name: item.props.label,
                component: (
                    React.cloneElement(item, {
                        parentId: entityId,
                        height,
                        dialogName,
                    })
                ),
            };
        });
    }

    render() {
        const {
            advancedForm,
            breakpoint,
            classes,
            entityId,
            loadingState,
            mode,
            open,
            params,
            styles,
            maxWidth,
            onActionTableExecuting,
            viewName,
            navigation,
            dialogName,
            number,
            detailContainer,
            entityName,
            tab,
            changeTab,
        } = this.props;

        let formSize;
        (!maxWidth) ? formSize = "lg" : formSize = maxWidth
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open && this.state.ready}
                maxWidth={formSize}
                fullWidth
                classes={{
                    paper: classes.paper,
                }}
                PaperProps={{
                    style: {...styles},
                }}
            >
                <DialogTitle className={classes.title}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{lineHeight: '2em'}}>
                            {this.renderTitle(mode)} {number}
                        </div>
                        {navigation && this.renderNavigationButtons(mode)}
                    </div>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        {
                            React.cloneElement(advancedForm, {mode, entityId, params, viewName, dialogName})
                        }
                    </div>

                    {detailContainer && ['edit', 'view'].includes(mode) &&
                        <div style={{padding: '10px'}}>
                            <TabBar
                                id={entityId}
                                items={this.specifyContainers(detailContainer, entityId, dialogName)}
                                entityName={entityName}
                                tab={tab}
                                changeTab={changeTab}
                            />
                        </div>}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Grid>
                        {
                            breakpoint.down('md')
                                ? this.renderMobileCustomActions(mode)
                                : this.props.customButtons && this.renderCustomButtons(mode)
                        }
                        {
                            breakpoint.down('md')
                                ? this.renderMobileActions(mode)
                                : this.renderActions(mode)
                        }
                    </Grid>
                </DialogActions>
                {
                    breakpoint.down('md')
                        ? <Loader state={loadingState} thickness={3} size={50}/>
                        : <Loader state={loadingState} thickness={3} size={60}/>
                }
            </Dialog>
        );
    }
}

AdvancedFormDialog.propTypes = {
    breakpoint: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    advancedForm: PropTypes.node.isRequired,
    actions: PropTypes.node,
    customButtons: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
        })
    ),
    title: PropTypes.string,
    mode: PropTypes.oneOf(['add', 'edit', 'view', 'copy', 'delete']),
    entityId: PropTypes.string,
    params: PropTypes.object,
    addModeTitle: PropTypes.string,
    editModeTitle: PropTypes.string,
    viewModeTitle: PropTypes.string,
    copyModeTitle: PropTypes.string,
    addModeSaveButtonLabel: PropTypes.string,
    addModeCancelButtonLabel: PropTypes.string,
    editModeSaveButtonLabel: PropTypes.string,
    editModeCancelButtonLabel: PropTypes.string,
    viewModeCloseButtonLabel: PropTypes.string,
    copyModeSaveButtonLabel: PropTypes.string,
    copyModeCancelButtonLabel: PropTypes.string,
    deleteModeSaveButtonLabel: PropTypes.string,
    deleteModeCancelButtonLabel: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    loadingState: PropTypes.bool.isRequired,
    styles: PropTypes.object,
};

AdvancedFormDialog.defaultProps = {
    addModeTitle: 'Добавление',
    editModeTitle: 'Изменение',
    viewModeTitle: 'Просмотр',
    copyModeTitle: 'Копирование',
    deleteModeTitle: 'Удаление',
    addModeSaveButtonLabel: 'Сохранить',
    addModeCancelButtonLabel: 'Отмена',
    editModeSaveButtonLabel: 'Сохранить',
    editModeCancelButtonLabel: 'Отмена',
    viewModeCloseButtonLabel: 'Закрыть',
    copyModeSaveButtonLabel: 'Сохранить',
    copyModeCancelButtonLabel: 'Отмена',
    deleteModeSaveButtonLabel: 'Удалить',
    deleteModeCancelButtonLabel: 'Отмена',
    styles: {},
};

export default withStyles(MUIStyles)(AdvancedFormDialog);
