/*
 *
 * AdvancedFormDialog constants
 *
 */

export const OPEN_DIALOG = 'claris-vnext/AdvancedFormDialog/OPEN_DIALOG';
export const SAVE_DIALOG = 'claris-vnext/AdvancedFormDialog/SAVE_DIALOG';
export const SAVE_DIALOG_SUCCESS = 'claris-vnext/AdvancedFormDialog/SAVE_DIALOG_SUCCESS';
export const SAVE_DIALOG_ERROR = 'claris-vnext/AdvancedFormDialog/SAVE_DIALOG_ERROR';
export const CLOSE_DIALOG = 'claris-vnext/AdvancedFormDialog/CLOSE_DIALOG';
export const CHANGE_LOADING_STATE = 'claris-vnext/AdvancedFormDialog/CHANGE_LOADING_STATE';
export const CHANGE_TAB = 'claris-vnext/AdvancedFormDialog/CHANGE_TAB';

export const REGISTER = 'claris-vnext/AdvancedFormDialog/REGISTER';
export const UNREGISTER = 'claris-vnext/AdvancedFormDialog/UNREGISTER';

