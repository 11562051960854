/**
 *
 * NavigationDrawerContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'react-router-redux';
import { makeSelectBreakpoint, makeSelectViewMode } from 'domain/Screen/selectors';
import { makeSelectComposedItems } from 'domain/Navigation/selectors';
import * as navigationDrawerActionCreators from 'containers/NavigationDrawer/actions';
import { makeSelectContainerWidth, makeSelectIsOpen } from './selectors';
import NavigationDrawer from './NavigationDrawer';


function NavigationDrawerContainer({
  isOpen, items, closeNavigationDrawer, navigate, breakpoint, containerWidth, viewMode,
}) {
  return (
    <NavigationDrawer
      isOpen={isOpen}
      items={items}
      onDrawerClose={closeNavigationDrawer}
      onRequestNavigate={navigate}
      breakpoint={breakpoint}
      containerWidth={containerWidth}
      viewMode={viewMode}
    />
  );
}

NavigationDrawerContainer.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired,
        })
      ),
    })
  ).isRequired,
  location: PropTypes.object.isRequired,
  closeNavigationDrawer: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  breakpoint: makeSelectBreakpoint(),
  isOpen: makeSelectIsOpen(),
  items: makeSelectComposedItems(),
  containerWidth: makeSelectContainerWidth(),
  viewMode: makeSelectViewMode(),
});

function mapDispatchToProps(dispatch) {
  return {
    navigate: (link) => dispatch(push(link)),
    ...bindActionCreators(navigationDrawerActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawerContainer);
