/**
 *
 * AdministratorBC Requests DxGrid 15783
 *
 */

import {ReceptionDxGrid15783} from "../ReceptionView";

export class AdministratorBCRequestsDxGrid15783 extends ReceptionDxGrid15783 {
}
