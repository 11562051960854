/**
 * AdvancedForm utils
 */

import { isNumber, getTypeInfo } from 'utils/typeUtils';

      export function sortFields(fields) {
        const transformedFields = transformObjectToArray(fields);
        const fieldsWithoutNumber = transformedFields.filter((entity) => !entity.numberInQueueFromEnd);
        let fieldsWithNumber = transformedFields.filter((entity) => entity.numberInQueueFromEnd);
      
        if (fieldsWithNumber.length > 1) {
          fieldsWithNumber = fieldsWithNumber.sort((a, b) => {
      if (!isNumber(a.numberInQueueFromEnd)) {
        throw new Error(`value type for "numberInQueueFromEnd" property of "${a.field}" field must be "Number" type, but got "${getTypeInfo(a.numberInQueueFromEnd)}"`);
      }
      if (!isNumber(b.numberInQueueFromEnd)) {
        throw new Error(`value type for "numberInQueueFromEnd" property of "${b.field}" field must be "Number" type, but got "${getTypeInfo(b.numberInQueueFromEnd)}"`);
      }
      if (a.numberInQueueFromEnd === b.numberInQueueFromEnd) {
        throw new Error(`property "numberInQueueFromEnd" must be unique for each field, but found duplicate in fields "${a.field}" and "${b.field}"`);
      }

      if (a.numberInQueueFromEnd > b.numberInQueueFromEnd) return -1;
      if (a.numberInQueueFromEnd < b.numberInQueueFromEnd) return 1;
      return 0;
    });
  }

  
  return [...fieldsWithoutNumber, ...fieldsWithNumber];
}

/** @internal */
  function transformObjectToArray(fields) {
  const result = [];
  const keys = Object.keys(fields);

  for (let i = 0; i < keys.length; i += 1) {
    result.push({
      field: fields[keys[i]].field,
      entityName: fields[keys[i]].entityName,
      numberInQueueFromEnd: fields[keys[i]].numberInQueueFromEnd,
    });
  }

  return result;
}
