/**
 * Breadcrumb
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import BreadcrumbText from './BreadcrumbText';
import BreadcrumbSelect from './BreadcrumbSelect';
import BreadcrumbDivider from './BreadcrumbDivider';

const styleSheet = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
};

export class Breadcrumb extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  createBreadcrumbSelectItemClickHandler = (selectItem) => () => {
    const { onRequestNavigate } = this.props;
    if (onRequestNavigate) onRequestNavigate(selectItem.link);
  };

  renderBreadcrumbSelect(items) {
    return (
      <BreadcrumbSelect items={items}>
        {
          items.map((item) =>
            (
              <MenuItem
                key={item.id}
                selected={item.isActive}
                onClick={this.createBreadcrumbSelectItemClickHandler(item)}
              >
                {item.title}
              </MenuItem>
            )
          )
        }
      </BreadcrumbSelect>
    );
  }

  render() {
    const { classes, item, totalCount} = this.props;

    if (!item) {
      return null;
    }

    const subItems = item.items;

    return (
      <div className={classes.root}>
        <BreadcrumbText text={item.title} />
        {
          subItems.length > 0 &&
            <BreadcrumbDivider />
        }
        {
          subItems.length === 1 &&
            <BreadcrumbText text={subItems[0].title} />
        }
        {
          subItems.length > 1 &&
            this.renderBreadcrumbSelect(subItems)
        }
        {
          <div><span>&nbsp;&nbsp;Кол-во:&nbsp;{totalCount}</span></div>
        }
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
      }).isRequired
    ),
  }).isRequired,
  onRequestNavigate: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(Breadcrumb);
