/*
 *
 * CheckpointFormDialog12924
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { CHECKPOINT_REQUESTS_FORM, CHECKPOINT_REQUESTS_FORM_DIALOG } from 'pages/Requests/CheckpointView/constants';

class CheckpointFormDialog12924 extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleOpenBarrierButtonClick = () => this.props.onOpenBarrier();

  render() {
    const { form } = this.props;

    return (
      <AdvancedFormDialog
        name={CHECKPOINT_REQUESTS_FORM_DIALOG}
        formName={CHECKPOINT_REQUESTS_FORM}
        advancedForm={form}
        addModeTitle="Новая заявка"
        editModeTitle="Изменение заявки"
        viewModeTitle="Просмотр заявки"
        copyModeTitle="Копирование заявки"
        customButtons={[
          {
            name: 'openBarrier',
            label: 'Открыть шлагбаум',
            onClick: this.handleOpenBarrierButtonClick,
          },
        ]}
      />
    );
  }
}

CheckpointFormDialog12924.propTypes = {
  form: PropTypes.node.isRequired,
  onOpenBarrier: PropTypes.func.isRequired,
};

export default CheckpointFormDialog12924;
