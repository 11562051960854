/**
 *
 * RestrictedAdministratorBC Service Requests DxGrid 14366
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';
import {
  ACTIVE_REQUESTS,
  SERVICE_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
} from 'domain/Data/Filters/constants';

export class RestrictedAdministratorBCServiceRequestsDxGrid14366 extends ServiceRequestsManagerDxGrid {
  getFilters() {
    const { viewName } = this.props; // eslint-disable-line
    const filters = [SERVICE_REQUESTS, REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }
}
