/**
 *
 * ReceptionDxGrid12970
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';

import { columnSize, columnType, requestColumnSizes } from 'domain/typeConstants/table';
import { VISITOR_LOGS } from 'domain/Data/constants';

import { requestAction } from 'domain/typeConstants/action';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  COMCITY_CURRENT_DAY_REQUESTS,
  CURRENT_DAY_REQUESTS,
  REQUESTS_WITHOUT_SERVICE_TYPE,
  REQUESTS_FOR_COMCITY_RECEPTION,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  ON_CREATE_REQUESTS,
  ACTIVE_REQUESTS,
} from 'domain/Data/Filters/constants';

import { arrivedActionButton, departedActionButton } from '../common/actionButton';
import { arrivedActionItem, departedActionItem } from '../common/actionMenu';

class ReceptionDxGrid12970 extends ReceptionDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_WITHOUT_SERVICE_TYPE];
    if (viewName === 'today') {
      filters.push(REQUESTS_FOR_COMCITY_RECEPTION);
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(COMCITY_CURRENT_DAY_REQUESTS);
    }
    if (viewName === 'onVisit') {
      filters.push(REQUESTS_ON_VISIT);
      filters.push(REQUESTS_FOR_COMCITY_RECEPTION);
    }
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];
    let columns = super.getColumns().filter((item) => {
      if (item.path === 'documentDetails') return false;
      return true;
    });

    columns = columns.map((item) => {
      if (item.name === 'passNumber') {
        return {
          path: 'accessCard',
          name: 'accessCard',
          title: '№ пропуска',
          getCellValue: (row) => row.accessCard ? row.accessCard.number : undefined,
          width: requestColumnSizes.accessCard,
          styles,
        };
      }

      return item;
    });

    const companyIndex = columns.findIndex((item) => item.name === 'companyName');
    columns.splice(companyIndex + 1, 0, {
      path: 'housing',
      name: 'housing',
      title: 'Корпус',
      getCellValue: (row) => (row.housing ? row.housing.name : undefined),
      width: requestColumnSizes.housing,
      styles,
    });

    return columns;
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters().filter((item) => {
      if (item.columnName === 'documentDetails') return false;
      return true;
    });

    columnFilters.push({ columnName: 'housing', value: '' });

    return columnFilters;
  }

  getColumnOrder() {
    let columnOrder = super.getColumnOrder().filter((item) => {
      if (item === 'documentDetails') return false;
      return true;
    });

    columnOrder = columnOrder.map((item) => {
      if (item === 'passNumber') {
        return 'accessCard';
      }

      return item;
    });

    const companyIndex = columnOrder.findIndex((item) => item === 'companyName');
    columnOrder.splice(companyIndex + 1, 0, 'housing');
    return columnOrder;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const addButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ADD);
    const arrivedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    const departedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.DEPARTED);

    actionButtons.splice(arrivedButtonIndex, 1, arrivedActionButton);
    actionButtons.splice(departedButtonIndex, 1, departedActionButton);
    actionButtons.splice(addButtonIndex, 1);
    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const departedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.DEPARTED);
    const arrivedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.ARRIVED);

    actionMenuItems.splice(departedActionMenuIndex, 1, departedActionItem);
    actionMenuItems.splice(arrivedActionMenuIndex, 1, arrivedActionItem);
    return actionMenuItems;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: true },
      { name: settings.TABLE_DRAWER, value: false },
    ];
  }

  /* -----------------------------------------------------------
                  Visitor log configuration
  ----------------------------------------------------------- */
  getVisitorLogTableColumns() {
    return [
      {
        path: 'accessCard',
        name: 'accessCard',
        title: 'Карта доступа',
        getCellValue: (row) => {
          if (row.request && row.request.accessCard) {
            return row.request.accessCard.name;
          }

          return undefined;
        },
      },
      {
        path: 'arrivalDate',
        name: 'arrivalDate',
        title: 'Прибыл',
        type: columnType.DATETIME,
      },
      {
        path: 'departureDate',
        name: 'departureDate',
        title: 'Убыл',
        type: columnType.DATETIME,
      },
    ];
  }

  getVisitorLogTableColumnWidths() {
    return [
      { columnName: 'accessCard', width: requestColumnSizes.accessCard },
      { columnName: 'arrivalDate', width: columnSize.DATETIME },
      { columnName: 'departureDate', width: columnSize.DATETIME },
    ];
  }

  getVisitorLogTableColumnOrder() {
    return [
      'accessCard', 'arrivalDate', 'departureDate',
    ];
  }

  getVisitorLogActionButtons() {
    return [];
  }

  getVisitorLogActionMenuItems() {
    return [];
  }

  /* -----------------------------------------------------------
                  Row container configuration
  ----------------------------------------------------------- */
  getRowContainerType() {
    return {
      defaultPosition: 1,
      items: [
        {
          actionButtons: this.getVisitorLogActionButtons(),
          actionMenuItems: this.getVisitorLogActionMenuItems(),
          columnOrder: this.getVisitorLogTableColumnOrder(),
          columns: this.getVisitorLogTableColumns(),
          columnWidths: this.getVisitorLogTableColumnWidths(),
          entityName: VISITOR_LOGS,
          name: 'история',
          selectionByRowClick: true,
        },
      ],
    };
  }
}

export default ReceptionDxGrid12970;
