/**
 *
 * PositionsView sagas
 *
 */

import { call, select, put, takeLatest } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openEditDialog,
  openCopyDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_COPY_ACTION,
  DOCUMENT_POSITION_FORM_DIALOG,
  DOCUMENT_POSITION_GRID,
} from './constants';
import { makeSelectParent, makeSelectParentId } from 'containers/AdvancedFormDialog/selectors';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && action.meta.dialog === DOCUMENT_POSITION_FORM_DIALOG

export default function* rootSaga() {
  yield takeLatest(EXECUTE_ADD_ACTION, executeAddActionSaga);
  yield takeLatest(EXECUTE_EDIT_ACTION, executeEditActionSaga);
  yield takeLatest(EXECUTE_COPY_ACTION, executeCopyActionSaga);
  yield takeLatest([needViewRefresh, DISPLAY_NOTIFICATION], executeNeedViewRefreshSaga);
}

function* executeAddActionSaga({ payload }) {
  yield put(openAddDialog(DOCUMENT_POSITION_FORM_DIALOG, payload));
}

function* executeEditActionSaga({ payload }) {
  if (payload.id) {
    yield put(openEditDialog(DOCUMENT_POSITION_FORM_DIALOG, payload));
  }
}

function* executeCopyActionSaga({ payload }) {
  if (payload.id) {
    yield put(openCopyDialog(DOCUMENT_POSITION_FORM_DIALOG, payload));
  }
}

function* executeNeedViewRefreshSaga() {
  const parentId = yield select(makeSelectParentId(DOCUMENT_POSITION_FORM_DIALOG))
  const parent = yield select(makeSelectParent(DOCUMENT_POSITION_FORM_DIALOG))
  if (parent && parentId) {
    yield put(loadData(DOCUMENT_POSITION_GRID, { parent, parentId, mode: 'detail' }))
  }
}
