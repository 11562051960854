/**
 *
 *  CheckpointDxGrid15511
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';
import {
  ACTIVE_REQUESTS,
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_CHECKPOINT,
  REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED,
} from 'domain/Data/Filters/constants';

export class CheckpointDxGrid15511 extends CheckpointDxGrid {

  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_CHECKPOINT];
    if (viewName === 'active') {
      filters.push(ACTIVE_REQUESTS);
    }
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    if (viewName === 'property') { filters.push(REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED); }
    return filters;
  }
}
