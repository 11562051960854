/*
 *
 * ServiceRequestsManagerAcceptForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { SERVICE_REQUESTS_MANAGER_ACCEPT_FORM } from './constants';
import { formField } from "domain/typeConstants/form";
import { SERVICE } from "domain/Data/RequestType/constants";

class ServiceRequestsManagerAcceptForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: () => false,
        calculatedValue: () => ACCEPTED,
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
      },
      {
        name: 'assignee',
        type: 'select',
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        rowBreakBefore: true,
        rowBreakAfter: true,
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => {
            if (item.company === user.companyId && item.isPerformer) assignee.push(item.id);
          });
          return assignee;
        },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
      },
      {
        name: 'requestPriority',
        type: formField.SELECT,
        label: 'Приоритет',
        entityName: 'requestPriorities',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
        rowBreakAfter: true,
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        required: true,
        visible: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) =>
          currentDate,
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        grid: { sm: 12 },
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
        calculatedValue: (values, mode) => {
          if (mode === 'edit' || mode === 'view') return values.managementCompanyComment;
          return null;
        },
      }
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={SERVICE_REQUESTS_MANAGER_ACCEPT_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default ServiceRequestsManagerAcceptForm;
