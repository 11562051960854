/**
 *
 * UnbindingAccessCardDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Loader from 'components/CircularLoader';
import Snackbar from 'components/Snackbar';

import { kyrilicKeyboardLayoutToLatin } from 'utils/common';

import { Autocomplete } from 'components/FormFields';

const styleSheet = (theme) => ({
  textField: {
    width: 500,
  },
  errorMessage: {
    color: '#F44336',
    fontSize: 14,
    height: 22,
    paddingLeft: 18,
    paddingRight: 18,
  },
  container: {
    margin: '10px auto 0',
    position: 'relative',
    maxWidth: 1000,
  },
  head: {
    padding: '16px 0 5px 16px',
  },
  content: {
    padding: '0 24px 0 24px',
  },
  bottom: {
    padding: '10px 16px 5px 0',
  },
  button: {
    margin: theme.spacing.unit,
    color: '#FFF',
  },
});

const DIALOG_TITLE = 'Отвязка карты доступа';
const BUTTON_TITLE = 'Отвязать карту';

let previousCharTime;

class UnbindingAccessCardDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accessCard: {},
      isAccessCardScanned: false,
    };
  }

  handleChange = (event) => {
    const value = event.target.value;
    if (value != null) {
      this.setState({ accessCard: { name: kyrilicKeyboardLayoutToLatin(value) } });
    }
  };

  handleSubmitButtonClick = () => {
    const { accessCard } = this.state;
    const { onSubmit } = this.props;

    if (onSubmit && accessCard && accessCard.key) onSubmit(accessCard.key);
    this.setState({ accessCard: {} });
  };

  handleChangeIsAccessCardScanned = (state) => {
    this.setState({ isAccessCardScanned: state });
  };

  handleChangeKeyDown = (event) => {
    const currentCharTime = new Date().getTime();

    if (previousCharTime && (currentCharTime - previousCharTime) < 30) {
      if (event.keyCode === 13) {
        setTimeout(() => this.handleChangeIsAccessCardScanned(true), 500);
        setTimeout(this.handleSubmitScanner, 1000);
      }
    }

    previousCharTime = currentCharTime;
  };

  handleSubmitScanner = () => {
    previousCharTime = null;
    const { accessCard } = this.state;
    const { onSubmit } = this.props;

    if (onSubmit && accessCard && accessCard.key) onSubmit(accessCard.key);
    this.setState({ accessCard: {} });
  };

  handleSetAccessCard = (value) => {
    this.setState({ accessCard: value });
  };

  render() {
    const {
      classes,
      loadingState,
      onCloseSnackbar,
      snackbarMessage,
      snackbarState,
      snackbarVariant,
    } = this.props;
    const { accessCard, isAccessCardScanned } = this.state;

    return (
      <Paper className={classes.container}>
        <Grid container className={classes.head}>
          <Typography variant="title">{DIALOG_TITLE}</Typography>
        </Grid>
        <Grid container className={classes.content}>
          <form
            onSubmit={(e) => e.preventDefault()}
          >
            <Autocomplete
              changeIsValueScanned={this.handleChangeIsAccessCardScanned}
              entityName="accessCards"
              isValueScanned={isAccessCardScanned}
              onChange={this.handleChange}
              onKeyDown={this.handleChangeKeyDown}
              placeholder="Ключ карты"
              setValue={this.handleSetAccessCard}
              value={accessCard.name}
            />
          </form>
        </Grid>
        <Grid container className={classes.bottom}>
          <div style={{ flex: '0 0 0', marginLeft: 'auto' }} />
          <Button
            className={classes.button}
            color="primary"
            disabled={!accessCard.key}
            onClick={this.handleSubmitButtonClick}
            variant="contained"
          >
            {BUTTON_TITLE}
          </Button>
        </Grid>
        <Loader state={loadingState} />
        <Snackbar
          message={snackbarMessage}
          onClose={onCloseSnackbar}
          open={snackbarState}
          variant={snackbarVariant}
        />
      </Paper>
    );
  }
}

UnbindingAccessCardDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  loadingState: PropTypes.bool.isRequired,
  onCloseSnackbar: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  snackbarMessage: PropTypes.string,
  snackbarState: PropTypes.bool,
  snackbarVariant: PropTypes.string,
};

export default withStyles(styleSheet)(UnbindingAccessCardDialog);
