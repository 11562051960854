/**
 *
 * DocumentsPage sagas
 *
 */

import { fork, select } from 'redux-saga/effects';

import { makeSelectRoles } from 'domain/User/selectors';
import createSightingViewSaga from './SightingView/sagas';
import createSupplyRequestViewSaga from './SupplyRequestView/sagas';
import createSupplyPositionViewSaga from './SupplyPositionView/sagas';
import { role } from 'domain/typeConstants/roles';

export default function* rootSaga() {
  const userRoles = yield select(makeSelectRoles());
  if (userRoles.includes(role.SUPPLY_APPLICANT)) {
    yield fork(createSupplyPositionViewSaga({}));
    yield fork(createSupplyRequestViewSaga({}));
  } else {
    yield fork(createSightingViewSaga({}));
  }
}
