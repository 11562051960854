/**
 *
 * MultipleFileField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import Clear from '@material-ui/icons/Clear';
import AttachFile from '@material-ui/icons/AttachFile';
import Description from '@material-ui/icons/Description';

import FieldProgress from 'components/FieldProgress';

const ATTACH_BUTTON_WIDTH = 48;

const styleSheet = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  attachButton: {
    marginLeft: 'auto',
    width: ATTACH_BUTTON_WIDTH,
  },
  fileList: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${ATTACH_BUTTON_WIDTH}px)`,
  },
  fileItem: {
    display: 'flex',
    margin: theme.spacing.unit * (-1)
  },
  downloadBlock: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },

  fileInput: {
    display: 'none',
  },
  loadedFileInput: {
    cursor: 'pointer',
  },
  label: {},
  error: {
    color: theme.palette.error.main,
  },
  iconColor: {
    color: '#757575',
  },
  input: {
    marginTop: '0 !important',
  },
});

class MultipleFileField extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    classes: PropTypes.object.isRequired,
    classNames: PropTypes.object.isRequired,
    clear: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    field: PropTypes.object,
    files: PropTypes.array,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    saveFile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classNames: {},
  };

  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
  }

  handleFileInputChange = (event) => {
    const { field, fileSize } = this.props;
    const { files } = event.currentTarget;

    let sumFilesSize = 0;
    for (let i = 0; i < files.length; i++) { sumFilesSize += files[i].size }

    const defaultFileSize = {
      size: 10e6,
      errorTextFile: 'Размер прикрепляемых файлов не должен превышать 10 Мб'
    }

    const currentFileSize = fileSize || defaultFileSize;

    if (files && sumFilesSize && (sumFilesSize > currentFileSize.size)) {
      this.props.showErrorMessageFile('Внимание', currentFileSize.errorTextFile);
      return;
    }

    if (files.length > 0) {
      this.props.saveFile(files, field.type);
    }
  };

  handleAttachButtonClick = () => {
    const { readOnly } = this.props;
    if (readOnly) return;
    this.fileInput.current.click();
  };

  handleClearButtonMouseDown = (event) => {
    event.preventDefault();
  };

  handleClearButtonClick = (fileId) => (event) => {
    const { field } = this.props;
    event.stopPropagation();
    if (this.props.readOnly) return;
    this.props.clear(field.type, fileId);
  };

  handleDownloadFile = (id) => (event) => {
    const { downloadFile } = this.props;
    event.preventDefault();
    downloadFile(id);
  };

  handleMouseDown = (event) => {
    event.preventDefault();
  };

  renderFileList = ({ isError }) => () => {
    const { classes, files, readOnly } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.fileList}>
          {
            Array.isArray(files) && files.map((file) => (
              <div
                key={file.id}
                className={classes.fileItem}
              >
                <div // eslint-disable-line
                  onClick={this.handleDownloadFile(file.id)}
                  className={classes.downloadBlock}
                >
                  <IconButton>
                    <Description
                      className={classnames({
                        [classes.error]: isError,
                      })}
                    />
                  </IconButton>
                  <span>{file.name}</span>
                </div>
                {
                  !readOnly
                  && (
                    <div className={classes.clearButton}>
                      <IconButton
                        onClick={this.handleClearButtonClick(file.id)}
                      >
                        <Clear
                          className={classnames({
                            [classes.error]: isError,
                          })}
                        />
                      </IconButton>
                    </div>
                  )
                }
              </div>
            ))
          }
        </div>
        <div className={classes.attachButton}>
          <IconButton
            onClick={this.handleAttachButtonClick}
            disabled={readOnly}
          >
            <AttachFile className={classnames({ [classes.error]: isError })} />
          </IconButton>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      classNames,
      clear, // eslint-disable-line no-unused-vars
      downloadFile, // eslint-disable-line no-unused-vars
      field,
      files,
      fullWidth,
      helperText,
      input: { value, name, ...restInputProps },
      label,
      meta: { touched, error, submitFailed }, // eslint-disable-line no-unused-vars
      readOnly,
      required,
      saveFile, // eslint-disable-line no-unused-vars
      fileSize,
      fieldName,
      showErrorMessageFile,
      ...restProps
    } = this.props;

    const isError = (touched || submitFailed) && !!error;

    return (
      <FormControl
        fullWidth
        required={required}
        error={isError}
      >
        {
          files.length === 0
          && (
            <InputLabel
              htmlFor={name}
              className={classnames(classes.label, {
                [classes.error]: isError,
              })}
            >
              {label}
            </InputLabel>
          )
        }
        <Input
          fullWidth
          readOnly
          type="text"
          name={name}
          inputComponent={this.renderFileList({ isError })}
          classes={{
            root: classes.input,
          }}
          {...restInputProps}
          {...restProps}
        />
        <input
          className={classes.fileInput}
          ref={this.fileInput}
          onChange={this.handleFileInputChange}
          type="file"
          id="file"
          name="file"
          multiple
        />
        {
          field.progress &&
          <FieldProgress mode={field.progressMode} value={field.progressValue} />
        }
        {isError
          && <FormHelperText className={classNames.helperText}>обязательное поле</FormHelperText>
        }
        {
          !isError && helperText
          && <FormHelperText className={classNames.helperText}>{helperText}</FormHelperText>
        }
      </FormControl>
    );
  }
}

export default withStyles(styleSheet)(MultipleFileField);
