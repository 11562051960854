import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { columnStatusLabels } from 'pages/Requests/common/columnStatusLabel';

import { RECEPTION_VISITOR_LOGS_DX_GRID } from './constants';

class ReceptionVisitorLogsDxGrid12970 extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    return [];
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'accessCard',
        name: 'accessCard',
        title: 'Карта доступа',
        getCellValue: (row) => {
          if (row.request && row.request.accessCard) {
            return row.request.accessCard.name;
          }

          return undefined;
        },
        width: 175,
        styles,
      },
      {
        path: 'arrivalDate',
        name: 'arrivalDate',
        title: 'Прибыл',
        type: columnType.DATETIME,
        width: columnSize.DATETIME,
        styles,
      },
      {
        path: 'departureDate',
        name: 'departureDate',
        title: 'Убыл',
        type: columnType.DATETIME,
        width: columnSize.DATETIME,
        styles,
      },
      {
        path: 'requestNumber',
        name: 'requestNumber',
        title: '№ Заявки',
        getCellValue: (row) => row.request ? row.request.number : undefined,

        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => {
          if (row.request && row.request.status) {
            return row.request.status.name;
          }

          return undefined;
        },
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
      },
      {
        path: ['author', 'name'],
        name: 'authorName',
        title: 'Автор',
        getCellValue: (row) => row.author ? row.author.name : undefined,
        width: requestColumnSizes.authorName,
        styles,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО Посетителя',
        getCellValue: (row) => row.request ? row.request.visitorFullName : undefined,
        width: requestColumnSizes.visitorFullName,
        styles,
      },
      {
        path: ['request', 'visitDate'],
        name: 'visitDate',
        getCellValue: (row) => row.request ? row.request.visitDate : undefined,
        title: 'Дата посещения',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: ['company', 'name'],
        name: 'companyName',
        getCellValue: (row) => {
          if (row.request && row.request.company) {
            return row.request.company.name;
          }
          return undefined;
        },
        title: 'Контрагент',
        width: requestColumnSizes.companyName,
        styles,
      },
    ];
  }

  getColumnOrder() {
    return [
      'accessCard', 'arrivalDate', 'departureDate', 'requestNumber', 'statusName', 'authorName', 'visitorFullName',
      'visitDate', 'companyName',
    ];
  }

  getActionButtons() {
    return [];
  }

  getActionMenuItems() {
    return [];
  }

  getSorting() {
    return [{ columnId: 'arrivalDate', columnName: 'arrivalDate', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.TABLE_ACTION_MENU, value: false },
      { name: settings.GROUPING, value: false },
      { name: settings.FILTERING, value: false },
    ];
  }

  render() {
    const { viewName } = this.props;

    return (
      <AdvancedDataTable
        entityName="visitorLog"
        name={RECEPTION_VISITOR_LOGS_DX_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

ReceptionVisitorLogsDxGrid12970.propTypes = {
  viewName: PropTypes.string,
};

export default ReceptionVisitorLogsDxGrid12970;
