/**
 *
 * MakingAgreementBCDxGrid14366
 *
 */

import { MakingAgreementBCDxGrid } from 'pages/Requests/MakingAgreementBCView';
import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_FOR_MAKING_AGREEMENT_BC,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
} from 'domain/Data/Filters/constants';

import {
  addActionButton, editActionButton,
} from 'pages/Requests/common/actionButton';

import {
  editActionItem,
} from 'pages/Requests/common/actionMenu';

export class MakingAgreementBCDxGrid14366 extends MakingAgreementBCDxGrid {

  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') {
      filters.push(REQUESTS_FOR_USER_DEPARTMENT);
      filters.push(REQUESTS_FOR_MAKING_AGREEMENT_BC);
    }
    return filters;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    actionButtons.unshift(addActionButton, editActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const viewActionMenuIndex = actionMenuItems.findIndex((action) => action.name === 'view');
    actionMenuItems.splice(viewActionMenuIndex + 1, 0, editActionItem);

    return actionMenuItems;
  }
}
