/**
 *
 * AppBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import InitialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';
import Visibility from '@material-ui/icons/Visibility';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

import { NAVIGATION_DRAWER_WIDTH } from 'containers/NavigationDrawer/constants';
import NotificationsButton from './NotificationsButton';
import UserMenu from './UserMenu';

const styleSheet = (theme) => ({
  appBar: {
    position: 'fixed',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary['500'],
    color: 'white',
  },
  appBarShift: {
    marginLeft: NAVIGATION_DRAWER_WIDTH,
    width: `calc(100% - ${NAVIGATION_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    minHeight: 56,
  },
  menuButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
    },
    marginLeft: 12,
    marginRight: 12,
  },
  changeRoleButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
    marginLeft: 12,
  },
  notificationsButton: {
    marginLeft: 12,
  },
  logoutButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
    },
    marginLeft: 12,
    marginRight: 12,
  },
  logoutButtonShift: {
    marginRight: -12,
  },
  content: {
    flex: 1,
  },
  accountName: {
    marginLeft: 32,
    marginRight: 32,
  },
});

export class AppBar extends React.PureComponent {

  handleMenuButtonClick = () => {
    const { onOpenNavigationDrawer, containerWidth } = this.props
    if (onOpenNavigationDrawer) onOpenNavigationDrawer(containerWidth - NAVIGATION_DRAWER_WIDTH)
  };

  handleLogoutButtonClick = () => this.props.onLogout();

  handleChangeRoleButtonClick = () => this.props.onOpenChangeRoleDialog();

  render() {
    const {
      accountName,
      breakpoint,
      children,
      classes,
      isAdmin,
      navigationDrawerIsOpen,
      username,
    } = this.props;

    return (
      <InitialAppBar
        position="static"
        className={classNames(
          classes.appBar,
          {
            [classes.appBarShift]: navigationDrawerIsOpen && breakpoint.up('sm'),
          }
        )}
      >
        <Toolbar className={classes.toolbar} disableGutters={!navigationDrawerIsOpen}>
          {
            (!navigationDrawerIsOpen || breakpoint.down('md')) &&
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={this.handleMenuButtonClick}
            >
              <Menu />
            </IconButton>
          }
          {
            breakpoint.up('sm')
            && (
              <div className={classes.content}>
                {children}
              </div>
            )
          }
          {
            breakpoint.up('xs')
            && (
              <Typography type="body2" color="inherit" className={classes.accountName}>
                {accountName}
              </Typography>
            )
          }
          <UserMenu
            username={username}
            accountName={accountName}
            breakpoint={breakpoint}
          />
          <div>
            {
              breakpoint.up('xs') && <NotificationsButton />
            }
            {
              isAdmin && breakpoint.up('xs') &&
              <IconButton
                className={classes.changeRoleButton}
                color="inherit"
                onClick={this.handleChangeRoleButtonClick}
              >
                <Visibility />
              </IconButton>
            }
            <IconButton
              className={classNames(
                classes.logoutButton,
                {
                  [classes.logoutButtonShift]: navigationDrawerIsOpen,
                }
              )}
              color="inherit"
              onClick={this.handleLogoutButtonClick}
            >
              <PowerSettingsNew />
            </IconButton>
          </div>
        </Toolbar>
      </InitialAppBar>
    );
  }
}

AppBar.propTypes = {
  accountName: PropTypes.string,
  breakpoint: PropTypes.object.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object,
  isAdmin: PropTypes.bool,
  navigationDrawerIsOpen: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  onOpenChangeRoleDialog: PropTypes.func.isRequired,
  onOpenNavigationDrawer: PropTypes.func.isRequired,
  username: PropTypes.string,
};

export default withStyles(styleSheet)(AppBar);
