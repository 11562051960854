/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

// import HomePage from 'containers/HomePage';
import ChangePasswordPage from 'pages/ChangePassword';
import DocumentsPage from 'pages/Documents';
import HomePage from 'pages/Home';
import LoginPage from 'pages/Login';
import RestorePassword from 'pages/RestorePassword'
import NotFoundPage from 'pages/NotFound';
import NotificationsPage from 'pages/Notifications';
import ParkingsPageContainer from 'pages/Parkings';
import RequestsPage from 'pages/Requests';
import TasksPage from 'pages/Tasks';
import SettingsPageContainer from 'pages/Settings';
import UnbindingAccessCardPage from 'pages/UnbindingAccessCard';
import RulesPage from 'pages/Rules';
import CompaniesPage from 'pages/Companies';

import LoginPage12970 from 'custom/12970/pages/Login';
import VisitorLogsPageContainer12970 from 'custom/12970/pages/VisitorLogs';
import ContactsPageContainer14326 from 'custom/14326/pages/Contacts/index';
import Dashboard from 'pages/Dashboard';
import LoginPage14326 from 'custom/14326/pages/Login';

import {
  DOCUMENTS_PATH,
  PARKINGS_PATH,
  NOTIFICATIONS_PATH,
  REQUESTS_PATH,
  RULES_PATH,
  COMPANIES_PATH,
  SERVICE_REQUESTS_PATH,
  SETTINGS_PATH,
  UNBINDING_ACCESS_CARD_PATH,
  VISITOR_LOGS_PATH,
  CONTACTS_PATH,
  RESTORE_PASSWORD_PATH,
  TASKS_PATH,
} from 'domain/Navigation/constants';

function App({ history }) {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/login-comcity" component={LoginPage12970} />
        <Route path="/login-khimki" component={LoginPage14326} />
        <Route path="/changePassword" component={ChangePasswordPage} />
        <Route path={`${REQUESTS_PATH}/:requestId`} component={RequestsPage} />
        <Route path={REQUESTS_PATH} component={RequestsPage} />
        <Route path={SERVICE_REQUESTS_PATH} component={RequestsPage} />
        <Route path={UNBINDING_ACCESS_CARD_PATH} component={UnbindingAccessCardPage} />
        <Route path={DOCUMENTS_PATH} component={DocumentsPage} />
        <Route path={"/supplyRequests"} component={DocumentsPage} />
        <Route path={"/supplyPositions"} component={DocumentsPage} />
        <Route path={SETTINGS_PATH} component={SettingsPageContainer} />
        <Route path={RULES_PATH} component={RulesPage} />
        <Route path={COMPANIES_PATH} component={CompaniesPage} />
        <Route path={VISITOR_LOGS_PATH} component={VisitorLogsPageContainer12970} />
        <Route path={CONTACTS_PATH} component={ContactsPageContainer14326} />
        <Route path={PARKINGS_PATH} component={ParkingsPageContainer} />
        <Route path={NOTIFICATIONS_PATH} component={NotificationsPage} />
        <Redirect from="/businessCenter/requests" to="/requests" />
        <Route path={TASKS_PATH} component={TasksPage} />
        <Route path={`${TASKS_PATH}/:taskId`} component={TasksPage} />
        <Route path={'/dashboard'} component={Dashboard} />
        <Route path={`${RESTORE_PASSWORD_PATH}/:login/:link`} component={RestorePassword} />
        <Route path="/" component={HomePage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </ConnectedRouter>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default App;
