/**
 *
 * UnbindingAccessCardPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import withPrivateRoot from 'containers/App/withPrivateRoot';
import Snackbar from 'containers/Snackbar';

import CloseRequestDialog from './common/UnbindingAccessCardDialog';

import ReceptionView from './ReceptionView';

const styleSheet = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
});

export class UnbindingAccessCardPage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  renderReceptionFormDialog = () => this.props.receptionCloseRequestFormDialog || <CloseRequestDialog />;

  renderReceptionView = () => this.props.receptionView ||
    <ReceptionView
      key={this.props.viewName}
      viewName={this.props.viewName}
      formDialog={this.renderReceptionFormDialog()}
      snackbar={<Snackbar />}
    />;

  renderView() {
    return this.renderReceptionView();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>{ this.renderView() }</div>
    );
  }
}

UnbindingAccessCardPage.propTypes = {
  classes: PropTypes.object.isRequired,

  receptionCloseRequestFormDialog: PropTypes.node,
  receptionView: PropTypes.node,

  viewName: PropTypes.string,
};

export default withPrivateRoot(
  withStyles(styleSheet)(UnbindingAccessCardPage)
);
