import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import TenantView, {
  TenantDxGrid,
  TenantDxGridContainer,
  TenantForm,
  TenantFormDialog,
  TenantMobileGrid,
} from '../TenantView';
import {
  TenantAgreedEstimationCostFormDialog14720,
  TenantAgreedEstimationCostForm14720
} from "custom/14720/pages/Requests/TenantView";


const getDxGrid = (DxGrid) => (
  <TenantDxGridContainer component={DxGrid || <TenantDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <TenantDxGridContainer component={MobileGrid || <TenantMobileGrid />} />
);

const getDataTableView = ({ breakpoint, tenantDxGrid, tenantMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(tenantMobileGrid);
  }
  if (isUndefined(tenantDxGrid) || tenantDxGrid) {
    return getDxGrid(tenantDxGrid);
  }
};

const getFormDialog = (Form) => (
  <TenantFormDialog form={Form || <TenantForm />} />
);

const getAgreedEstimationCostFormDialog = (agreedEstimationCostForm) => (
  <TenantAgreedEstimationCostFormDialog14720 form={agreedEstimationCostForm || <TenantAgreedEstimationCostForm14720 />} />
);

export const getTenantView = (props) => {
  const {
    breakpoint,
    tenantDxGrid,
    tenantMobileGrid,
    tenantForm,
    tenantFormDialog,
    tenantView,
    viewName,
    viewStyles,
    agreedEstimationCostForm,
    agreedEstimationCostFormDialog,
  } = props;

  if (tenantView) return tenantView;

  return (
    <TenantView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        tenantDxGrid,
        tenantMobileGrid,
      })}
      formDialog={tenantFormDialog || getFormDialog(tenantForm)}
      agreedEstimationCostFormDialog={agreedEstimationCostFormDialog || getAgreedEstimationCostFormDialog(agreedEstimationCostForm)}
    />
  );
};

getTenantView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  tenantDxGrid: PropTypes.node,
  tenantMobileGrid: PropTypes.node,
  tenantForm: PropTypes.node,
  tenantFormDialog: PropTypes.node,
  tenantView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
