/**
 *
 * Assigned Requests DxGrid 14326
 *
 */

import { IN_PROGRESS_REQUESTS, REQUESTS_FOR_SERVICE_DESK_ASSIGNEE, WORKS_ON_SERVICE_DESK } from 'domain/Data/Filters/constants';
import { AssignedDxGrid } from 'pages/Requests/AssignedView';
import { requestAction } from '../../../../../domain/typeConstants/action';
import { requestColumnSizes } from 'domain/typeConstants/table';
import { requestStatus } from 'domain/typeConstants/status';
import { CARRYING_OUT_WORKS, SERVICE } from 'domain/Data/RequestType/constants';

export class AssignedDxGrid14326 extends AssignedDxGrid {

  getFilters() {
    const filters = [];
    const { viewName } = this.props;
    if (viewName === 'works') {
      filters.push(WORKS_ON_SERVICE_DESK);
    }
    else {
      filters.push(REQUESTS_FOR_SERVICE_DESK_ASSIGNEE)
      if (viewName === 'today') filters.push(IN_PROGRESS_REQUESTS);
    }
    return filters;
  }

  getColumns() {
    const { viewName } = this.props;
    const columns = super.getColumns();

    if (viewName === 'works') {
      const blackListStyle = {
        style: { color: '#d50000' },
        condition: (value) => value.isBlackList,
      };
      const styles = [blackListStyle];

      columns.push({
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
        styles,
        entityName: 'requestTypes'
      })

      columns.push({
        path: ['isTechnicalServiceMonitoring'],
        name: 'isTechnicalServiceMonitoring',
        getCellValue: (row) => (row.isTechnicalServiceMonitoring ? 'Да' : 'Нет'),
        title: 'Контроль ТС',
        width: requestColumnSizes.typeShortName,
      })

      columns.push({
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
        styles,
      })

      columns.push({
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
        styles,
      })

      columns.push({
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО посетителя',
        width: requestColumnSizes.visitorFullName,
        styles,
      })
      columns.push({
        path: ['company', 'name'],
        name: 'company_name',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.company_name,
        styles,
      })
      columns.push({
        path: 'managementCompanyComment',
        name: 'managementCompanyComment',
        title: 'Комментарий УК',
        width: requestColumnSizes.managementCompanyComment,
        styles,
      })
    }

    const unnecessaryColumns = new Set(['serviceName']);
    return columns.filter((column) => !unnecessaryColumns.has(column.name));
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const isDisable = (selection, entities) => {
      if (selection.length === 1) {
        const status = entities[0].status && entities[0].status.id;
        const type = entities[0].type && entities[0].type.id
        if ((type !== SERVICE && type !== CARRYING_OUT_WORKS)
        ) return true;
        return status === requestStatus.CLOSED || status === requestStatus.COMPLETED;
      }
      return true;
    }

    const editIndex = actionButtons.findIndex((button) => button.name === requestAction.EDIT);
    actionButtons[editIndex].label = 'Открыть';
    actionButtons[editIndex].disabled = isDisable

    const acceptIndex = actionButtons.findIndex((button) => button.name === requestAction.ACCEPT);
    actionButtons[acceptIndex].disabled = isDisable

    const rejectIndex = actionButtons.findIndex((button) => button.name === requestAction.REJECT);
    actionButtons[rejectIndex].disabled = isDisable

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();
    const isHidden = (itemMenuRecord, entities) => {
      if (itemMenuRecord.length === 1) {
        const status = entities[0].status && entities[0].status.name;
        const type = entities[0].type && entities[0].type.id
        if (!status) return false;
        if (type !== SERVICE && type !== CARRYING_OUT_WORKS) return true;
        return status === requestStatus.COMPLETED || status === requestStatus.CLOSED;
      }
      return true;
    }

    const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
    actionMenuItems[editIndex].label = 'Открыть';
    actionMenuItems[editIndex].hidden = isHidden

    const acceptIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
    actionMenuItems[acceptIndex].hidden = isHidden

    const rejectIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.REJECT);
    actionMenuItems[rejectIndex].hidden = isHidden

    return actionMenuItems;
  }

  getGridFilters() {
    const { viewName } = this.props;
    const columnFilters = super.getGridFilters();

    if (viewName === 'works') {
      columnFilters.push({ columnName: 'typeShortName', value: '' });
      columnFilters.push({ columnName: 'isTechnicalServiceMonitoring', value: '' });
      columnFilters.push({ columnName: 'carModelText', value: '' });
      columnFilters.push({ columnName: 'carNumber', value: '' });
      columnFilters.push({ columnName: 'visitorFullName', value: '' });
      columnFilters.push({ columnName: 'company_name', value: '' });
      columnFilters.push({ columnName: 'managementCompanyComment', value: '' });
    }
    return columnFilters
  }


  getColumnOrder() {
    const { viewName } = this.props;
    const columnOrder = super.getColumnOrder();
    if (viewName === 'works') {
      const whereHappenedIndex = columnOrder.findIndex((columnName) => columnName === 'whereHappened');
      columnOrder.splice(whereHappenedIndex, 0, 'isTechnicalServiceMonitoring');
      columnOrder.splice(whereHappenedIndex, 0, 'typeShortName');
      const authorIndex = columnOrder.findIndex((columnName) => columnName === 'author');
      columnOrder.splice(authorIndex, 0, 'carNumber', 'carModelText',
        'visitorFullName', 'company_name', 'managementCompanyComment'
      )
    }
    return columnOrder
  }

}