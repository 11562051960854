/**
 *
 * AdvancedFormCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createAdvancedForm from './createAdvancedForm';
import { register } from './actions';

class AdvancedFormCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getChildContext = () => ({
    advancedForm: this.props.name,
  });

  componentWillMount = () => {
    const { name, ...restProps } = this.props;
    this.context.store.dispatch(register(name, { ...restProps }));
  };

  render() {
    const { name, ...restProps } = this.props;
    const AdvancedForm = createAdvancedForm(name);
    return <AdvancedForm {...restProps} />;
  }
}

AdvancedFormCreator.contextTypes = {
  store: PropTypes.object.isRequired,
};

AdvancedFormCreator.childContextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

AdvancedFormCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AdvancedFormCreator;
