import {SERVICE} from 'domain/Data/RequestType/constants';
import {
    getTypeInfo,
    isArray,
} from './typeUtils';

export const checkCarNumber = (carNumber) => {
    const regexp = /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/i;
    return regexp.test(carNumber);
};

export const validateWorkTimeNumber = (values) => {
    if (values && values.duration && Number.isNaN(Number(values.duration))) return {errorHelperText: 'Введите число'}
    return null;
};


export const validateCarNumber = (values) => {
    if (!values.nonstandardCarNumber && values.carNumber && !checkCarNumber(values.carNumber)) {
        return {errorHelperText: 'Введите номер автомобиля по формату x111xx11 используя кириллицу или поднимите флаг Нестандартный № авто'};
    }

    return null;
};

export const checkNumber = (number) => {
    const regexp = /^\d+$/;
    return regexp.test(number);
};


export const validateParkingPlace = (requestData, parkings, alertContent) => {
    if (!isArray(parkings)) {
        throw new Error(`Тип второго аргумента("parkings") должен быть "Array", получено "${getTypeInfo(parkings)}"`);
    }
    const pattern = /\d+/g;
    let parkingPlace = requestData.parkingPlace.match(pattern);
    let currentParking;
    let newAlertContent = alertContent;

    if (parkings.length > 0) {
        currentParking = parkings.filter((item) => item.id === requestData.parking);
        currentParking = currentParking[0];
    }

    if (parkingPlace && parkingPlace.length > 0 && currentParking && currentParking.capacity) {
        parkingPlace = parkingPlace.filter((item) => {
            if (Number(item) > currentParking.capacity) return true;
            return false;
        });

        if (parkingPlace.length === 1) {
            if (alertContent == null) {
                newAlertContent = `Номер места парковки: ${parkingPlace[0]} превышает количество вместимости данной парковки, равное ${currentParking.capacity}\n`;
                return newAlertContent;
            }

            newAlertContent += `Номер места парковки: ${parkingPlace[0]} превышает количество вместимости данной парковки, равное ${currentParking.capacity}\n`;
            return newAlertContent;
        } else if (parkingPlace.length > 1) {
            if (newAlertContent == null) newAlertContent = 'Номера мест парковки: ';
            else newAlertContent += 'Номера мест парковки: ';

            parkingPlace.forEach((item, index, array) => {
                if ((array.length - 1) === index) newAlertContent += `${item} `;
                else newAlertContent += `${item}, `;
            });

            newAlertContent += `превышают количество вместимости данной парковки, равное ${currentParking.capacity}\n`;
            return newAlertContent;
        }
    }

    return alertContent;
};

//не меньше 3-х букв, чтобы первые символы  не были: точки, запятые, тире, равно и т.п.
export const validateKhimkiTenantComment = (values) => {
    if (values.type === SERVICE && values.khimkiTenantComment && values.khimkiTenantComment.length > 3
        && !values.khimkiTenantComment.includes("...", 0) && values.khimkiTenantComment != " "
        && values.khimkiTenantComment != "-"
    ) return null;
    return {errorHelperText: 'Не валидная запись'}
};

export const validatePassword = (values) => {
    const value = values.password;
    const lowerCase = /^[a-z]+$/;
    const upperCase = /^[A-Z]+$/;
    const digit = /^[0-9]+$/;
    let isLowerCase = false;
    let isUpperCase = false;
    let isDigit = false;
    if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
            if (!isLowerCase && lowerCase.test(value[i])) {
                isLowerCase = true
            } else if (!isUpperCase && upperCase.test(value[i])) isUpperCase = true
            else if (!isDigit && digit.test(value[i])) isDigit = true
        }
        if (isLowerCase && isUpperCase && isDigit && value.length > 7) return undefined;
        return {errorHelperText: 'Пароль должен быть не менее 8 символов и включать 0-9,A-Z,a-z'};
    }
}
