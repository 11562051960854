/*
 *
 * getCompaniesPage Function
 *
 */

import React from 'react';
import CompaniesPage from "./CompaniesPage";

export default function getCompaniesPage(accountId, props) {
    switch (accountId) {
        default:
            return (
                <CompaniesPage {...props} />
            );
    }
}
