import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';
import ReceptionView , {
    ReceptionDxGrid,
    ReceptionMobileGrid,
    ReceptionDxGridContainer,
    ReceptionFormDialog,
    ReceptionForm
} from '../ReceptionView';

const getDxGrid = (DxGrid) => (
    <ReceptionDxGridContainer component={DxGrid || <ReceptionDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
    <ReceptionDxGridContainer component={MobileGrid || <ReceptionMobileGrid />} />
  );

const getFormDialog = (Form) => (
    <ReceptionFormDialog form={Form || <ReceptionForm />} />
);

const getDataTableView = ({ breakpoint, receptionDxGrid, receptionMobileGrid }) => {
    if (breakpoint.down('md')) {
      return getMobileGrid(receptionMobileGrid);
    }
    if (isUndefined(receptionDxGrid) || receptionDxGrid) {
      return getDxGrid(receptionDxGrid);
    }
  };


export const getReceptionView = (props) => {
    const {
        breakpoint,
        receptionDxGrid,
        receptionMobileGrid,
        receptionForm,
        receptionFormDialog,
        receptionView,
        viewName,
        viewStyles,
    } = props;

    if (receptionView) return receptionView;

    return (
        <ReceptionView
            key={viewName}
            viewName={viewName}
            dataTable={getDataTableView({
                breakpoint,
                receptionDxGrid,
                receptionMobileGrid
            })}
            formDialog={receptionFormDialog || getFormDialog(receptionForm)}
            viewStyles={viewStyles}
        />
    )
};

getReceptionView.propTypes = {
    receptionDxGrid: PropTypes.node,
    receptionForm: PropTypes.node,
    receptionFormDialog: PropTypes.node,
    receptionView: PropTypes.node,
    viewName: PropTypes.string,
};
