/**
 *
 * AgreementDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const styleSheet = {
  textField: {
    width: 500,
  },
};

class AgreementDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
    };
  }

  handleSubmitButtonClick = () => {
    const { comment } = this.state;
    const { ids, onSubmit, dxGrid } = this.props;
    this.setState({ comment: '' });
    if (onSubmit) onSubmit(ids, comment, dxGrid);
  };

  handleCancelButtonClick = () => {
    this.setState({ comment: '' });
    this.props.onCancel();
  };

  handleChange = (event) => this.setState({ comment: event.target.value });

  render() {
    const {
      classes,
      open,
    } = this.props;
    const { comment } = this.state;

    return (
      <div>
        <Dialog
          open={open}
        >
          <DialogTitle>
            <span>Согласование документа</span>
          </DialogTitle>
          <DialogContent>
            <form>
              <TextField
                className={classes.textField}
                label="Комментарий"
                margin="normal"
                multiline
                onChange={this.handleChange}
                rows={3}
                value={comment}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSubmitButtonClick} color="primary" autoFocus>
              Согласовать
            </Button>
            <Button onClick={this.handleCancelButtonClick} color="primary">
              Отмена
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AgreementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
};

export default withStyles(styleSheet)(AgreementDialog);
