import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import moment from 'moment';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

function createRoot(theme) {
  function withRoot(Component) {
    function WithRoot(props) {
      return (
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="ru">
            <Component {...props} />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      );
    }

    return WithRoot;
  }

  return withRoot;
}


export default createRoot;
