/*
 *
 * TenantDxGrid15774
 *
 */

import {
    ACCESS_CARD_REQUESTS,
    ACTIVE_REQUESTS,
    CURRENT_DAY_REQUESTS,
    REQUESTS_FOR_TENANT,
    REQUESTS_ON_CAR_TYPE,
    REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED,
    REQUESTS_ON_VISIT,
} from "domain/Data/Filters/constants";
import {TenantDxGrid} from "pages/Requests/TenantView";


export class TenantDxGrid15774 extends TenantDxGrid {

    getFilters() {
        const { viewName } = this.props;
        const filters = [REQUESTS_FOR_TENANT];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_TYPE);
        if (viewName === 'passMaking') filters.push(ACCESS_CARD_REQUESTS);
        if (viewName === 'property') filters.push(REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED);
        return filters;
    }

}
