/*
 *
 * SupplyRequestDxGrid15767
 *
 */


import SupplyRequestDxGrid from 'pages/Documents/SupplyRequestView/SupplyRequestDxGrid';

export class SupplyRequestDxGrid15767 extends SupplyRequestDxGrid {


    getActionButtons() {
        const actionButtons = super.getActionButtons();
        return actionButtons.filter(button => button.name !== 'view');
    }

    getActionMenuItems() {
        const actionMenuItem = super.getActionMenuItems()
        return actionMenuItem.filter(item => item.name !== 'view')
    }

    getSorting() {
        return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
    }
}
