/*
 *
 * User constants
 *
 */

export const LOG_IN = 'claris-vnext/User/LOG_IN';
export const LOG_IN_SUCCESS = 'claris-vnext/User/LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'claris-vnext/User/LOG_IN_ERROR';
export const LOG_OUT = 'claris-vnext/User/LOG_OUT';

export const LOAD_USER_INFO = 'claris-vnext/User/LOAD_USER_INFO';
export const LOAD_USER_INFO_SUCCESS = 'claris-vnext/User/LOAD_USER_INFO_SUCCESS';
export const LOAD_USER_INFO_ERROR = 'claris-vnext/User/LOAD_USER_INFO_ERROR';

export const CHANGE_PASSWORD = 'claris-vnext/User/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'claris-vnext/User/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'claris-vnext/User/CHANGE_PASSWORD_ERROR';

export const CHECK_USER_FOR_BLOCKING = 'claris-vnext/User/CHECK_USER_FOR_BLOCKING';
export const CHECK_VERSION = 'claris-vnext/User/CHECK_VERSION';

export const RESTORE_PASSWORD = 'claris-vnext/User/RESTORE_PASSWORD';
export const RESTORE_PASSWORD_SUCCESS = 'claris-vnext/User/RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_ERROR = 'claris-vnext/User/RESTORE_PASSWORD_ERROR';