/**
 *
 * MakingAgreementBCDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_MAKING_AGREEMENT_BC,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
} from 'domain/Data/Filters/constants';

import { MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID } from './constants';

import {
  acceptActionButton,
  rejectActionButton,
} from '../common/actionButton';
import {
  acceptActionItem,
  rejectActionItem,
  viewActionItem,
} from '../common/actionMenu';
import { columnStatusLabels } from '../common/columnStatusLabel';

class MakingAgreementBCDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props; // eslint-disable-line
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') filters.push(REQUESTS_FOR_MAKING_AGREEMENT_BC);
    return filters;
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО посетителя',
        width: requestColumnSizes.visitorFullName,
        styles,
      },
      {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
        styles,
      },
      {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
        styles,
      },
      {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
      },
      {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
        styles,
      },
      {
        path: ['company', 'name'],
        name: 'company_name',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.company_name,
        styles,
      },
      {
        path: 'documentDetails',
        name: 'documentDetails',
        title: 'Серия и №',
        width: requestColumnSizes.documentDetails,
        styles,
      },
      {
        path: 'passNumber',
        name: 'passNumber',
        title: '№ пропуска',
        width: requestColumnSizes.passNumber,
        styles,
      },
      {
        path: ['parking', 'name'],
        name: 'parkingName',
        getCellValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
        width: requestColumnSizes.parkingName,
        styles,
      },
      {
        path: 'parkingPlace',
        name: 'parkingPlace',
        title: 'Место',
        width: requestColumnSizes.parkingPlace,
        styles,
      },
      {
        path: 'materialValuesData',
        name: 'materialValuesData',
        title: 'Мат. ценности',
        width: requestColumnSizes.materialValuesData,
        styles,
      },
      {
        path: 'companyName',
        name: 'companyName',
        title: 'Откуда посетитель',
        width: requestColumnSizes.companyName,
        styles,
      },
      {
        path: 'whatHappened',
        name: 'whatHappened',
        title: 'Что случилось',
        width: requestColumnSizes.whatHappened,
        styles,
      },
      {
        path: ['service', 'name'],
        name: 'serviceName',
        getCellValue: (row) => (row.service ? row.service.name : undefined),
        title: 'Сервис',
        width: requestColumnSizes.serviceName,
        styles,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
        styles,
      },
      {
        path: 'blackListNote',
        name: 'blackListNote',
        title: 'Причина ЧС',
        width: requestColumnSizes.blackListNote,
        styles,
      },
      {
        path: ['author', 'name'],
        name: 'authorName',
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: requestColumnSizes.authorName,
        styles,
      },
    ];
  }

  getColumnOrder() {
    return [
      'number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
      'company_name', 'passNumber', 'parkingName', 'parkingPlace', 'materialValuesData', 'companyName',
      'whatHappened', 'serviceName', 'note', 'blackListNote', 'authorName',
    ];
  }

  getActionButtons() {
    return [acceptActionButton, rejectActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, acceptActionItem, rejectActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <AdvancedDataTable
        name={MAKING_AGREEMENT_BC_REQUESTS_TABLE_GRID}
        entityName="requests"
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

MakingAgreementBCDxGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  viewName: PropTypes.string,
};

export default MakingAgreementBCDxGrid;
