import { REQUESTS, REQUEST_CHANGES } from "domain/Data/constants"
import { getJournal } from "pages/common/detailContainers"


export const getDetailContainer = () => {
    return {
        items: [
            getJournal(REQUESTS, REQUEST_CHANGES),
        ],
        height: 286,
    }
}

