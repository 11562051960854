/**
 *
 * DocumentPositionsDxGrid
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnWorkSize } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { columnStatusLabels } from 'pages/Documents/common/columnStatusLabel';
import { addActionButton, editActionButton, viewActionButton } from './actionButton';
import { editActionItem, viewActionItem, } from './actionMenu';

class DocumentPositionsDxGrid extends React.PureComponent {

  getFilters() {
    return [];
  }

  getColumns() {
    return [
      {
        name: 'state',
        path: ['state', 'name'],
        title: 'Статус',
        getCellValue: (row) => row.state ? row.state.name : undefined,
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'amount',
        path: 'amount',
        title: 'Сумма',
        type: columnType.NUMERIC,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'supplierInvoiceName',
        path: ['supplierInvoice', 'name'],
        title: 'Счет поставщика',
        getCellValue: (row) => (row.suppierInvoice ? row.supplierInvoice.name : undefined),
        width: columnWorkSize.DURATION,
      },
      {
        name: 'supplierInvoiceDeliverySchedule',
        path: ['supplierInvoice', 'deliverySchedule'],
        title: 'Поставка план',
        getCellValue: (row) => (row.supplierInvoice ? row.supplierInvoice.deliverySchedule : undefined),
        type: columnType.DATE,
        label: { backgroundColor: '#FFA500', fontColor: 'black', condition: (row) => row.supplierInvoice && row.supplierInvoice.deliverySchedule > row.desiredDate },
        width: columnWorkSize.DURATION,
      },
      {
        name: 'supplierInvoiceState',
        path: ['supplierInvoice', 'state', 'name'],
        title: 'Статус счета',
        getCellValue: (row) => (row.supplierInvoice ? row.supplierInvoice.state.name : undefined),
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'supplierInvoiceActualCreationDate',
        path: ['supplierInvoice', 'actualCreationDate'],
        title: 'Дата счета',
        getCellValue: (row) => (row.supplierInvoice ? row.supplierInvoice.actualCreationDate : undefined),
        type: columnType.DATE,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'price',
        path: 'price',
        title: 'Цена',
        type: columnType.NUMERIC,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'changedDate',
        path: 'changedDate',
        title: 'Изменена',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'desiredDate',
        path: 'desiredDate',
        title: 'Желаемая дата',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'asset',
        path: ['asset', 'name'],
        title: 'ТМЦ',
        getCellValue: (row) => (row.asset ? row.asset.name : undefined),
        width: columnWorkSize.DURATION,
      },
      {
        name: 'assetText',
        path: 'assetText',
        title: 'ТМЦ текстом',
        width: columnWorkSize.DURATION,
      },
      {
        name: 'positionsCount',
        path: 'positionsCount',
        title: 'Кол-во',
        type: columnType.NUMERIC,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'received',
        path: 'received',
        title: 'Получено',
        type: columnType.NUMERIC,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'description',
        path: 'description',
        title: 'Примечание',
        width: columnWorkSize.DURATION,
      },
      {
        name: 'docsDelivered',
        path: 'docsDelivered',
        title: 'Ориг доставлен',
        getCellValue: (row) => (row.document && row.document.deliveryOriginals ? 'Да' : 'Нет'),
        label: { backgroundColor: '#69F0AE', fontColor: 'black', condition: (value) => value === 'Да' },
        type: columnType.FORMAT_TEXT,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'creationDate',
        path: 'creationDate',
        title: 'Добавлен',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'documentNumber',
        path: ['document', 'number'],
        getCellValue: (row) => (row.document ? row.document.number : undefined),
        title: '№ заявки',
        width: columnWorkSize.STATUS_NAME,
      },
      {
        name: 'documentActualCreationDate',
        path: ['document', 'actualCreationDate'],
        title: 'Дата заявки',
        getCellValue: (row) => (row.document ? row.document.actualCreationDate : undefined),
        type: columnType.DATE,
        width: columnWorkSize.DATETIME,
      },
      {
        name: 'documentState',
        path: ['document', 'state', 'name'],
        getCellValue: (row) => (row.document && row.document.state ? row.document.state.name : undefined),
        title: 'Статус заявки',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: columnWorkSize.DURATION,
      },
      {
        name: 'documentObjectStructureName',
        path: ['document', 'objectStructure', 'name'],
        title: 'Объект',
        getCellValue: (row) => (row.documet && row.document.objectStructure ? row.document.objectStructure.name : undefined),
        width: columnWorkSize.STATUS_NAME,
      },

    ];
  }

  getColumnOrder() {
    return [
      'changedDate', 'state', 'desiredDate', 'supplierInvoiceDeliverySchedule', 'asset', 'assetText',
      'positionsCount', 'price', 'amount', 'received', 'description', 'creationDate',
      'documentNumber', 'documentActualCreationDate', 'documentObjectStructureName', 'documentState',
      'supplierInvoiceName', 'supplierInvoiceActualCreationDate', 'supplierInvoiceState', 'docsDelivered',
    ];
  }

  getSorting() {
    return [{ columnId: 'changedDate', columnName: 'changedDate', direction: 'desc' }];
  }

  getActionButtons() {
    return [
      addActionButton, editActionButton, viewActionButton,
    ]
  }

  getActionMenuItems() {
    return [editActionItem, viewActionItem];
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }


  render() {
    const { onActionExecuting, onRowDoubleClick, mode, parent, parentId, entityName, height, dialogName, name } = this.props;

    return (
      <AdvancedDataTable
        name={name}
        entityName={entityName}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        selectable
        mode={mode}
        parent={parent}
        parentId={parentId}
        height={height}
        dialogName={dialogName}
        tableType={settings.PAGING_TABLE}
      />
    );
  }
}

DocumentPositionsDxGrid.propTypes = {
  viewName: PropTypes.string,
};

export default DocumentPositionsDxGrid;


