/**
 *
 * AdministratorBC Requests Form 14326
 *
 */

import { ReceptionForm14326 } from '../ReceptionView';

export class AdministratorBCRequestsForm14326 extends ReceptionForm14326 {
  getFields() {
    const fields = super.getFields();

    const typeIndex = fields.findIndex((field) => field.name === 'type');
    fields[typeIndex].visibleOptions = null;

    return fields;
  }
}
