/**
 *
 * SelectField sagas
 *
 */

import { call, put, takeEvery } from 'redux-saga/effects';
import { openEditDialog, } from 'containers/AdvancedFormDialog/actions';
import { EXECUTE_EDIT_ACTION } from './constants';
import { SIGHTING_DOCUMENTS_FORM_DIALOG } from 'pages/Documents/SightingView/constants';

export default function* rootSaga() {
  yield takeEvery(EXECUTE_EDIT_ACTION, function* (action) {
    yield call(editEntitySaga, action);
  });
}

export function* editEntitySaga({ payload: { entityName, recordId } }) {
  switch (entityName) {
    case 'document':
      yield put(openEditDialog(SIGHTING_DOCUMENTS_FORM_DIALOG, { id: recordId }));
      break;

    default:
      break;
  }
}

