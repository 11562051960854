/**
 *
 * ComboBoxTextInput actions
 *
 */

import { LOAD_OPTIONS, ADD_NEW_OPTION } from './constants';

export function loadOptions(advancedForm, field) {
  return {
    type: LOAD_OPTIONS,
    meta: { advancedForm, field },
  };
}


export function addNewOption(advancedForm, field, name) {
  return {
    type: ADD_NEW_OPTION,
    meta: { advancedForm, field },
    payload: { name }
  };
}