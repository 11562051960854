import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class SelectGridDialog extends React.Component {
    render() {
        const { open, title, content, onSelectAction, onCancelAction } = this.props
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    open={open}
                    maxWidth={"lg"}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onSelectAction}>Выбрать</Button>
                        <Button onClick={onCancelAction}>Отмена</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default SelectGridDialog;