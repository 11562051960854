import React from 'react';
import DashboardView from '../DashboardView';

export const getAdminView = (props) => {
  const {
    breakpoint,
    params,
    viewStyles,
    accountId,
  } = props;

  return (
    <DashboardView
      params={params}
      breakpoint={breakpoint}
      viewStyles={viewStyles}
      accountId={accountId}
    />
  );
};


