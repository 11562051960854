/*
 *
 * dx grid columns 14720
 *
 */

/* ---------- acceptedByTenant Column ---------- */

import { columnType } from "domain/typeConstants/table";

export const ACCEPTED_BY_TENANT = 'acceptedByTenant';

export const acceptedByTenantColumn = {
    path: ACCEPTED_BY_TENANT,
    name: ACCEPTED_BY_TENANT,
    title: 'Принято арендатором',
    getCellValue: (row) => row.acceptedByTenant ? row.acceptedByTenant.name : undefined,
    width: 150,
};

export const acceptedByTenantColumnFilter = {
    columnName: ACCEPTED_BY_TENANT,
    value: '',
};

/* ---------- estimationCost Column ---------- */
export const ESTIMATION_COST = 'estimationCost';

export const estimationCostColumn = {
    path: ['estimationCost', 'name'],
    name: ESTIMATION_COST,
    getCellValue: (row) => row.estimationCost ? row.estimationCost.id : undefined,
    title: 'Смета',
    type: columnType.FILE,
    width: 100,
};

export const estimationCostColumnFilter = {
    columnName: ESTIMATION_COST,
    value: '',
};


/* ---------- estimationCost Column ---------- */
export const ESTIMATION_COST_SCAN = 'estimationCostScan';

export const estimationCostScanColumn = {
    path: ['estimationCostScan', 'name'],
    name: ESTIMATION_COST_SCAN,
    getCellValue: (row) => row.estimationCostScan ? row.estimationCostScan.id : undefined,
    title: 'Скан сметы',
    type: columnType.FILE,
    width: 100,
};

export const estimationCostScanColumnFilter = {
    columnName: ESTIMATION_COST_SCAN,
    value: '',
};

