/**
 *
 * screen types
 *
 */

export const screenForMatchMedia = Object.freeze({
  EXTRA_SMALL: '(max-width: 599px)',
  SMALL: '(max-width: 1023px)',
  MEDIUM: '(max-width: 1439px)',
  LARGE: '(max-width: 1919px)',
  EXTRA_LARGE: 'screen and (min-width: 1920px)',
});
