/*
 *
 * snackbarReducer reducer
 *
 */

import { Map } from 'immutable';
import { SUBSCRIBE, UNSUBSCRIBE } from './constants';

const initialState = new Map({
  isSubscribed: true,
});

function emailSubscriptionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SUBSCRIBE:
      return state
        .set('isSubscribed', true);

    case UNSUBSCRIBE:
      return state
        .set('isSubscribed', false);

    default:
      return state;
  }
}

export default emailSubscriptionReducer;