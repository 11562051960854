import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import ReceptionView, {
  BlackListForm,
  BlackListFormDialog,
  ReceptionDxGrid,
  ReceptionDxGridContainer,
  ReceptionForm,
  ReceptionFormDialog,
  ReceptionMobileGrid,
  ReceptionRejectForm,
  ReceptionRejectFormDialog,
  VisitorArrivalForm,
  VisitorArrivalFormDialog,
  WorkForm,
  WorkFormDialog,
} from '../ReceptionView';

import {
  AgreementDialog,
} from '../common/AgreementDialog';

import { getRequestDetailContainer } from 'pages/common/detailContainers';

const getDxGrid = (DxGrid) => (
  <ReceptionDxGridContainer component={DxGrid || <ReceptionDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <ReceptionDxGridContainer component={MobileGrid || <ReceptionMobileGrid />} />
);

const getFormDialog = (form) => (
  <ReceptionFormDialog
    form={form || < ReceptionForm />}
    detailContainer={getRequestDetailContainer()}
  />
);

const getWorkFormDialog = () => (
  <WorkFormDialog form={<WorkForm />} />
);

const getRejectFormDialog = (rejectForm) => (
  <ReceptionRejectFormDialog form={rejectForm || <ReceptionRejectForm />} />
);

const getVisitorArrivalFormDialog = (visitorArrivalForm) => (
  <VisitorArrivalFormDialog form={visitorArrivalForm || <VisitorArrivalForm />} />
);

const getBlackListFormDialog = (blackListForm) => (
  <BlackListFormDialog form={blackListForm || <BlackListForm />} />
);

const getDataTableView = ({ breakpoint, receptionDxGrid, receptionMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(receptionMobileGrid);
  }
  if (isUndefined(receptionDxGrid) || receptionDxGrid) {
    return getDxGrid(receptionDxGrid);
  }
};

const getAgreementDialog = () => <AgreementDialog />;

export const getReceptionView = (props) => {
  const {
    agreementDialog,
    blackListForm,
    blackListFormDialog,
    breakpoint,
    receptionView,
    receptionDxGrid,
    receptionMobileGrid,
    receptionForm,
    receptionFormDialog,
    rejectForm,
    rejectFormDialog,
    params,
    viewName,
    viewStyles,
    visitorArrivalForm,
    visitorArrivalFormDialog,
  } = props;

  if (receptionView) return receptionView;

  return (
    <ReceptionView
      key={viewName}
      viewName={viewName}
      params={params}
      breakpoint={breakpoint}
      dataTable={getDataTableView({
        breakpoint,
        receptionDxGrid,
        receptionMobileGrid,
      })}

      formDialog={receptionFormDialog || getFormDialog(receptionForm)}
      agreementDialog={agreementDialog || getAgreementDialog()}
      rejectFormDialog={rejectFormDialog || getRejectFormDialog(rejectForm)}
      visitorArrivalFormDialog={visitorArrivalFormDialog || getVisitorArrivalFormDialog(visitorArrivalForm)}
      blackListFormDialog={blackListFormDialog || getBlackListFormDialog(blackListForm)}
      workformDialog={getWorkFormDialog()}
      viewStyles={viewStyles}
    />
  );
};

getReceptionView.propTypes = {
  agreementDialog: PropTypes.node,
  blackListForm: PropTypes.node,
  blackListFormDialog: PropTypes.node,
  breakpoint: PropTypes.object.isRequired,
  receptionDxGrid: PropTypes.node,
  receptionForm: PropTypes.node,
  receptionFormDialog: PropTypes.node,
  receptionMobileGrid: PropTypes.node,
  receptionView: PropTypes.node,
  rejectForm: PropTypes.node,
  rejectFormDialog: PropTypes.node,
  viewName: PropTypes.string,
  params: PropTypes.object,
  viewStyles: PropTypes.object,
  visitorArrivalForm: PropTypes.node,
  visitorArrivalFormDialog: PropTypes.node,
  workForm: PropTypes.node,
  workFormDialog: PropTypes.node,
};
