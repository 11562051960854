/**
 *
 * ReceptionView sagas
 *
 */

import { fork, call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectEntityId } from 'containers/AdvancedFormDialog/selectors';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { closeDialog, openEditDialog } from 'containers/AdvancedFormDialog/actions';
import {
  RECEPTION_REQUESTS_TABLE_GRID,
  EXECUTE_SEND_EMAIL_ACTION,
  REJECT_FORM_DIALOG
} from 'pages/Requests/ReceptionView/constants';
import * as api from 'utils/api';
import { changeEntitiesState } from 'domain/Data/actions';
import { REQUESTS } from 'domain/Data/constants';

import { SERVICE, CARRYING_OUT_WORKS } from 'domain/Data/RequestType/constants';
import { makeSelectEntity } from 'domain/Data/selectors';
import { acceptRequestsSaga, sendToAgreementByTenantSaga, sendToPerformerSaga, closeRequestsSaga, } from 'domain/Data/Request/sagas';

import {
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_REJECT_ACTION,
  KHIMKI_RECEPTION_ACCEPT_FORM_DIALOG,
  EXECUTE_SEND_TO_AGREMENT_ACTION,
  EXECUTE_SEND_TO_PERFORMER,
  EXECUTE_CLOSE_ACTION,
  RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG,
} from './constants';
import {
  acceptRequests,
  requestsAccepted,
  requestsAcceptingError,

  rejectRequests,
  requestsRejected,
  requestsRejectingError,

  sendEmail,
  sendedEmail,
  sendingEmailError,
  updateFormField,
} from './actions';

import { saveDialogSaga } from "../../../../../containers/AdvancedFormDialog/sagas";
import { makeSelectFormName } from 'containers/AdvancedFormDialog/selectors';
import { managementCompanyComment, memorandumScan, assignee } from '../common/fields';
import { makeSelectCurrentValues } from 'containers/AdvancedForm/selectors';
import { makeSelectRoles } from 'domain/User/selectors';

export default function* rootSaga() {
  //yield fork(createServiceRequestsManagerViewSaga()); 
  yield takeLatest(EXECUTE_ACCEPT_ACTION, executeAcceptActionSaga);
  yield takeLatest(EXECUTE_REJECT_ACTION, executeRejectActionSaga);
  yield takeLatest(EXECUTE_SEND_TO_AGREMENT_ACTION, executeSendToAgrementActionSaga);
  yield takeLatest(EXECUTE_SEND_EMAIL_ACTION, executeSendEmailActionSaga);
  yield takeLatest(EXECUTE_SEND_TO_PERFORMER, executeSendToPerformerActionSaga);
  yield takeLatest(EXECUTE_CLOSE_ACTION, executeCloseActionSaga);
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export function* acceptActionSaga(requestsId) {
  yield put(acceptRequests());

  try {
    const response = yield call(api.post, `requests/${requestsId}/accepted`, { requestsId });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(requestsAccepted());
  } catch (err) {
    yield put(requestsAcceptingError(err));
  }
}

function* executeSendToAgrementActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  const formName = yield select(makeSelectFormName(dialogName));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  if (currentValues.type === CARRYING_OUT_WORKS) {
    const field = { ...managementCompanyComment, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }
  const result = yield call(saveDialogSaga, dialogName);
  if (result !== null) {
    yield call(sendToAgreementByTenantSaga, [requestsId]);
    yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
    yield put(closeDialog(dialogName));
  }
}

function* executeSendToAgreementByTenantActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    if (request.type === SERVICE || (request.type === CARRYING_OUT_WORKS && !request.memorandumScan)) {
      yield put(openEditDialog(RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG, { id: ids[0] }));
    } else {
      yield call(sendToAgreementByTenantSaga, ids);
      yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
    }
  }
}

function* executeCloseActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  yield call(closeRequestsSaga, [requestsId]);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
  yield put(closeDialog(dialogName));
}

function* executeSendToPerformerActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  const formName = yield select(makeSelectFormName(dialogName));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  if (currentValues.type === SERVICE) {
    let field = { ...assignee, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }
  const result = yield call(saveDialogSaga, dialogName);
  if (result !== null) {
    yield call(sendToPerformerSaga, [requestsId]);
    yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
    yield put(closeDialog(dialogName));
  }
}

function* executeAcceptActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  const formName = yield select(makeSelectFormName(dialogName));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  const roles = yield select(makeSelectRoles())

  if (currentValues.type === SERVICE) {
    const field = { ...managementCompanyComment, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }
  if (currentValues.type === CARRYING_OUT_WORKS && roles.includes("reception")) {
    const field = { ...memorandumScan, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }

  const result = yield call(saveDialogSaga, dialogName);
  if (result !== null) {
    yield call(acceptActionSaga, requestsId);
    yield put(closeDialog(dialogName));
    yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
  }
}

export function* rejectActionSaga(requestsId) {
  yield put(rejectRequests());

  try {
    const response = yield call(api.post, `requests/${requestsId}/rejected`, { requestsId });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(requestsRejected());
  } catch (err) {
    yield put(requestsRejectingError(err));
  }
}

function* executeRejectActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  yield put(closeDialog(dialogName));
  yield put(openEditDialog(REJECT_FORM_DIALOG, { id: requestsId }));
}

function* overridedExecuteAcceptActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    if (request.type === SERVICE || (request.type === CARRYING_OUT_WORKS && !request.memorandumScan)) {
      yield put(openEditDialog(KHIMKI_RECEPTION_ACCEPT_FORM_DIALOG, { id: ids[0] }));
    } else {
      yield call(acceptRequestsSaga, ids);
      yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
    }
  }
}

function* sendEmailActionSaga(ids) {
  yield put(sendEmail(ids[0]));

  try {
    const response = yield call(api.post, `requests/${ids}/sendEmail`, { ids });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(sendedEmail());
  } catch (err) {
    yield put(sendingEmailError(err));
  }
}

function* executeSendEmailActionSaga({ payload: { ids } }) {
  yield call(sendEmailActionSaga, ids);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export const receptionViewSaga = {
  executeNeedViewRefreshSaga,
  executeAcceptActionSaga: overridedExecuteAcceptActionSaga,
  executeSendToAgreementByTenantActionSaga,
};
