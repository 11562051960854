import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import AgreementDialog from 'pages/Documents/common/AgreementDialog';
import DisagreementDialog from 'pages/Documents/common/DisagreementDialog';

import SightingView, {
  SightingDxGrid,
  SightingDxGridContainer,
  SightingForm,
  SightingFormDialogContainer,
  SightingMobileGrid,
  TaskForm,
  TaskFormDialog,
} from '../SightingView';
import { getSightingDetailContainer } from 'pages/common/detailContainers';

const getAgreementDialog = () => <AgreementDialog />;

const getDisagreementDialog = () => <DisagreementDialog />;

const getDxGrid = (DxGrid) => (
  <SightingDxGridContainer component={DxGrid || <SightingDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <SightingDxGridContainer component={MobileGrid || <SightingMobileGrid />} />
);

const getFormDialog = (Form, viewName) => (
  <SightingFormDialogContainer
    form={Form || <SightingForm />}
    detailContainer={getSightingDetailContainer()}
    viewName={viewName}
  />
);

const getTaskFormDialog = (Form) => (
  <TaskFormDialog form={Form || <TaskForm />} />
);

const getDataTableView = ({ breakpoint, sightingDxGrid, sightingMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(sightingMobileGrid);
  }
  if (isUndefined(sightingDxGrid) || sightingDxGrid) {
    return getDxGrid(sightingDxGrid);
  }
};

export const getSightingView = (props) => {
  const {
    sightingAgreementDialog,
    sightingDisagreementDialog,
    sightingDxGrid,
    sightingForm,
    sightingFormDialog,
    sightingMobileGrid,
    sightingView,
    sightingTaskFormDialog,
    sightingTaskForm,
    viewName,
    breakpoint,
    viewStyles,
  } = props;

  if (sightingView) return sightingView;

  return (
    <SightingView
      key={viewName}
      viewName={viewName}
      dataTable={getDataTableView({
        breakpoint,
        sightingDxGrid,
        sightingMobileGrid,
      })}
      formDialog={sightingFormDialog || getFormDialog(sightingForm, viewName)}
      agreementDialog={sightingAgreementDialog || getAgreementDialog()}
      disagreementDialog={sightingDisagreementDialog || getDisagreementDialog()}
      taskFormDialog={sightingTaskFormDialog || getTaskFormDialog(sightingTaskForm)}
      viewStyles={viewStyles}
    />
  );
};

getSightingView.propTypes = {
  sightingAgreementDialog: PropTypes.node,
  sightingDisagreementDialog: PropTypes.node,
  sightingDxGrid: PropTypes.node,
  sightingForm: PropTypes.node,
  sightingFormDialog: PropTypes.node,
  sightingView: PropTypes.node,
  viewName: PropTypes.string,
};
