/**
 *
 * ReceptionDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import {columnType, columnSize, requestColumnSizes} from 'domain/typeConstants/table';
import {settings} from 'containers/AdvancedDataTable/dxGridTypes';
import {
    CURRENT_DAY_REQUESTS,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT,
    REQUESTS_ON_WORK_PRODUCTION,
    ON_CREATE_REQUESTS,
    ACTIVE_REQUESTS, REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED,
} from 'domain/Data/Filters/constants';

import {RECEPTION_REQUESTS_TABLE_GRID} from './constants';

import {
    acceptActionButton,
    addActionButton,
    addBlackListCarActionButton,
    arrivedActionButton,
    copyActionButton,
    departedActionButton,
    editActionButton,
    rejectActionButton,
} from '../common/actionButton';
import {
    acceptActionItem,
    addBlackListCarActionItem,
    arrivedActionItem,
    copyActionItem,
    departedActionItem,
    editActionItem,
    rejectActionItem,
} from '../common/actionMenu';
import {columnStatusLabels} from '../common/columnStatusLabel';
import {role} from 'domain/typeConstants/roles';
import {getRequestDetailContainer} from 'pages/common/detailContainers';


class ReceptionDxGrid extends React.PureComponent {

    getFilters() {
        const {viewName} = this.props;
        const filters = [];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'property') filters.push(REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED);
        return filters;
    }

    getColumns() {
        const {viewName} = this.props;

        const blackListStyle = {
            style: {color: '#d50000'},
            condition: (value) => value.isBlackList,
        };
        const styles = [blackListStyle];

        if (viewName === 'onWorks') {
            return this.getColumnsForRequestsOnWorksProduction(styles);
        }

        return [
            {
                path: 'number',
                name: 'number',
                title: '№',
                type: columnType.NUMERIC,
                width: columnSize.NUMERIC,
                styles,
            },
            {
                path: 'visitorFullName',
                name: 'visitorFullName',
                title: 'ФИО посетителя',
                width: requestColumnSizes.visitorFullName,
                styles,
            },
            {
                path: 'carNumber',
                name: 'carNumber',
                title: '№ авто',
                width: requestColumnSizes.carNumber,
                styles,
            },
            {
                path: 'carModelText',
                name: 'carModelText',
                title: 'Марка',
                width: requestColumnSizes.carModelText,
                styles,
            },
            {
                path: 'visitDate',
                name: 'visitDate',
                title: 'Дата',
                type: columnType.DATE,
                width: columnSize.DATE,
                styles,
            },
            {
                path: 'expirationDate',
                name: 'expirationDate',
                title: 'До',
                type: columnType.DATE,
                width: columnSize.DATE,
                styles,
            },
            {
                path: ['status', 'name'],
                name: 'statusName',
                getCellValue: (row) => (row.status ? row.status.name : undefined),
                title: 'Статус',
                type: columnType.LABEL,
                labels: columnStatusLabels,
                entityName: 'requestStatuses',
                width: requestColumnSizes.statusName,
            },
            {
                path: ['type', 'shortName'],
                name: 'typeShortName',
                getCellValue: (row) => (row.type ? row.type.shortName : undefined),
                title: 'Вид',
                entityName: 'requestTypes',
                width: requestColumnSizes.typeShortName,
                styles,
            },
            {
                path: ['company', 'name'],
                name: 'company_name',
                getCellValue: (row) => (row.company ? row.company.name : undefined),
                title: 'Арендатор',
                width: requestColumnSizes.company_name,
                styles,
            },
            {
                path: 'documentDetails',
                name: 'documentDetails',
                title: 'Серия и №',
                width: requestColumnSizes.documentDetail,
                styles,
            },
            {
                path: 'passNumber',
                name: 'passNumber',
                title: '№ пропуска',
                width: requestColumnSizes.passNumber,
                styles,
            },
            {
                path: ['parking', 'name'],
                name: 'parkingName',
                getCellValue: (row) => (row.parking ? row.parking.name : undefined),
                title: 'Парковка',
                entityName: 'parkings',
                width: requestColumnSizes.parkingName,
                styles,
            },
            {
                path: 'parkingPlace',
                name: 'parkingPlace',
                title: 'Место',
                width: requestColumnSizes.parkingPlace,
                styles,
            },
            {
                path: 'materialValuesData',
                name: 'materialValuesData',
                title: 'Мат. ценности',
                width: requestColumnSizes.materialValuesData,
                styles,
            },
            {
                path: 'companyName',
                name: 'companyName',
                title: 'Откуда посетитель',
                width: requestColumnSizes.companyName,
                styles,
            },
            {
                path: 'whatHappened',
                name: 'whatHappened',
                title: 'Что случилось',
                width: requestColumnSizes.whatHappened,
                styles,
            },
            {
                path: ['service', 'name'],
                name: 'serviceName',
                getCellValue: (row) => (row.service ? row.service.name : undefined),
                title: 'Сервис',
                width: requestColumnSizes.serviceName,
                styles,
            },
            {
                path: 'arrivalDate',
                name: 'arrivalDate',
                title: 'Прибыл',
                type: columnType.TIME,
                width: columnSize.DATE,
            },
            {
                path: 'departureDate',
                name: 'departureDate',
                title: 'Убыл',
                type: columnType.TIME,
                width: columnSize.DATE,
            },
            {
                path: 'note',
                name: 'note',
                title: 'Примечание',
                width: requestColumnSizes.note,
                styles,
            },
            {
                path: ['file', 'name'],
                name: 'fileName',
                getCellValue: (row) => (row.file ? row.file.id : undefined),
                title: 'Файл',
                type: columnType.FILE,
                width: requestColumnSizes.fileName,
                styles,
                filter: false,
            },
            {
                path: 'managementCompanyComment',
                name: 'managementCompanyComment',
                title: 'Комментарий УК',
                width: requestColumnSizes.note,
                styles,
            },
            {
                path: 'blackListNote',
                name: 'blackListNote',
                title: 'Причина ЧС',
                width: requestColumnSizes.blackListNote,
                styles,
            },
            {
                path: ['author', 'name'],
                name: 'authorName',
                getCellValue: (row) => (row.author ? row.author.name : undefined),
                title: 'Автор',
                width: requestColumnSizes.authorName,
                styles,
            },
            {
                path: 'actualCreationDate',
                name: 'actualCreationDate',
                title: 'Создана',
                type: columnType.DATETIME,
                width: columnSize.DATE,
                styles,
            },
            {
                path: 'requestChangeDate',
                name: 'requestChangeDate',
                title: 'Изменено',
                type: columnType.DATETIME,
                width: columnSize.DATE,
                styles,
            }
        ];
    }

    getColumnsForRequestsOnWorksProduction(styles) {
        return [
            {
                path: 'number',
                name: 'number',
                title: '№',
                type: columnType.NUMERIC,
                width: columnSize.NUMERIC,
                styles,
            },
            {
                path: ['status', 'name'],
                name: 'statusName',
                getCellValue: (row) => (row.status ? row.status.name : undefined),
                title: 'Статус',
                type: columnType.LABEL,
                labels: columnStatusLabels,
                width: requestColumnSizes.statusName,
                styles,
            },
            {
                path: 'visitDate',
                name: 'visitDate',
                title: 'Дата',
                type: columnType.DATE,
                width: columnSize.DATE,
                styles,
            },
            {
                path: 'expirationDate',
                name: 'expirationDate',
                title: 'ДО',
                type: columnType.DATE,
                width: columnSize.DATE,
                styles,
            },
            {
                path: 'startWork',
                name: 'startWork',
                title: 'Время с',
                width: columnSize.DATE,
                styles,
            },
            {
                path: 'endWork',
                name: 'endWork',
                title: 'по',
                width: columnSize.DATE,
                styles,
            },
            {
                path: ['company', 'name'],
                name: 'companyName',
                getCellValue: (row) => (row.company ? row.company.name : undefined),
                title: 'Арендатор',
                width: requestColumnSizes.companyName,
                styles,
            },
            {
                path: 'carNumber',
                name: 'carNumber',
                title: '№ авто',
                width: requestColumnSizes.carNumber,
                styles,
            },
            {
                path: 'carModelText',
                name: 'carModelText',
                title: 'Марка',
                width: requestColumnSizes.carModelText,
                styles,
            },
            {
                path: 'workType',
                name: 'workType',
                title: 'Вид',
                getCellValue: (row) => row.workType ? row.workType.name : undefined,
                width: requestColumnSizes.workType,
                styles,
            },
            {
                path: 'whatHappened',
                name: 'whatHappened',
                title: 'Работа',
                width: requestColumnSizes.whatHappened,
                styles,
            },
            {
                path: 'workPlace',
                name: 'workPlace',
                title: 'Место',
                width: requestColumnSizes.workPlace,
                styles,
            },
            {
                path: ['file', 'name'],
                name: 'fileName',
                getCellValue: (row) => (row.file ? row.file.id : undefined),
                title: 'Файл',
                type: columnType.FILE,
                width: requestColumnSizes.fileName,
                styles,
            },
            {
                path: 'note',
                name: 'note',
                title: 'Место',
                width: requestColumnSizes.note,
                styles,
            },
            {
                path: ['author', 'name'],
                name: 'authorName',
                getCellValue: (row) => (row.author ? row.author.name : undefined),
                title: 'Автор',
                width: requestColumnSizes.authorName,
                styles,
            },
            {
                path: 'actualCreationDate',
                name: 'actualCreationDate',
                title: 'Добавлено',
                type: columnType.DATE,
                width: columnSize.DATE,
                styles,
            },
            {
                path: 'managementCompanyComment',
                name: 'managementCompanyComment',
                title: 'Комментарий УК',
                width: requestColumnSizes.note,
                styles,
            },
        ];
    }

    getColumnOrder() {
        const {viewName} = this.props;

        if (viewName === 'onWorks') {
            return ['number', 'statusName', 'visitDate', 'expirationDate', 'startWork', 'endWork', 'companyName', 'carNumber', 'carModelText',
                'workType', 'whatHappened', 'workPlace', 'fileName', 'note', 'managementCompanyComment', 'authorName', 'actualCreationDate'];
        }

        if (viewName === 'onVisit') {
            return ["number", "visitorFullName", "visitDate", "expirationDate", "statusName", "typeShortName", "company_name", "department", "passNumber",
                "arrivalDate", "departureDate", "note", "managementCompanyComment", "blackListNote", "authorName", "actualCreationDate", "requestChangeDate"
            ]
        }
        if (viewName === 'onCarEntry') {
            return ["number", "visitorFullName", "carNumber", "carModelText", "visitDate", "expirationDate", "statusName",
                "typeShortName", "company_name", "department", "parkingName", "parkingPlace", "passNumber", "arrivalDate",
                "departureDate", "note", "managementCompanyComment", "blackListNote", "authorName", "actualCreationDate",
                "requestChangeDate"
            ]
        }
        return [
            'number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName',
            'company_name', 'passNumber', 'parkingName', 'parkingPlace', 'materialValuesData', 'companyName',
            'whatHappened', 'serviceName', 'arrivalDate', 'departureDate', 'fileName', 'note', 'managementCompanyComment', 'blackListNote', 'authorName', 'actualCreationDate',
            'requestChangeDate'];
    }

    getActionButtons() {
        return [
            addActionButton, editActionButton, copyActionButton, acceptActionButton, rejectActionButton,
            arrivedActionButton, departedActionButton, addBlackListCarActionButton,];
    }

    getActionMenuItems() {
        return [
            editActionItem, copyActionItem, acceptActionItem, rejectActionItem,
            arrivedActionItem, departedActionItem, addBlackListCarActionItem,
        ];
    }

    getSorting() {
        return [{columnId: 'number', columnName: 'number', direction: 'desc'}];
    }

    getPageSize() {
        return 10;
    }

    getTableFeatures() {
        return [
            {
                name: settings.TABLE_ROW_DETAIL,
                value: this.props.roles && this.props.roles.includes(role.ADMINISTRATOR_BC)
            },
            {name: settings.FILTERING, value: true},
            {name: settings.FILTER_BUTTON, value: true},
            {name: settings.SETTING_BUTTON, value: true},
            {name: settings.UPDATE_BUTTON, value: true},
        ];
    }

    render() {
        const {onActionExecuting, onRowDoubleClick, viewName} = this.props;

        return (
            <AdvancedDataTable
                entityName="requests"
                name={RECEPTION_REQUESTS_TABLE_GRID}
                actionButtons={this.getActionButtons()}
                actionMenuItems={this.getActionMenuItems()}
                columnOrder={this.getColumnOrder()}
                columns={this.getColumns()}
                filters={this.getFilters()}
                pageSize={this.getPageSize()}
                onActionExecuting={onActionExecuting}
                onRowDoubleClick={onRowDoubleClick}
                detailContainer={getRequestDetailContainer()}
                sorting={this.getSorting()}
                tableFeatures={this.getTableFeatures()}
                viewName={viewName}
                filePreviewFieldName="file"
                filtering
                selectable
                tableType={settings.PAGING_TABLE}
                mode="parent"
            />
        );
    }
}

ReceptionDxGrid.propTypes = {
    onActionExecuting: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    viewName: PropTypes.string,
};

export default ReceptionDxGrid;
