import React from 'react';
import PropTypes from 'prop-types';

import createEditorContainer from './createEditorContainer';

class EditorCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const EditorContainer = createEditorContainer(this.context.advancedForm);
    return <EditorContainer {...this.props} />;
  }
}

EditorCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

export default EditorCreator;