/**
 *
 * RequestsPage12970
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import AssignedForm12970 from './AssignedView/AssignedForm12970';
import CheckpointDxGrid12970 from './CheckpointView/CheckpointDxGrid12970';
import CheckpointOperatorDxGrid12970 from './CheckpointOperator/CheckpointOperatorDxGrid12970';
import ParkingOperatorDxGrid12970 from './ParkingOperator/ParkingOperatorDxGrid12970';
import SeniorParkingOperatorDxGrid12970 from './SeniorParkingOperator/SeniorParkingOperatorDxGrid12970';
import UnloadingZoneOperatorDxGrid12970 from './UnloadingZoneOperator/UnloadingZoneOperatorDxGrid12970';
import ReceptionDxGrid12970 from './ReceptionView/ReceptionDxGrid12970';
import ReceptionForm12970 from './ReceptionView/ReceptionForm12970';
import ServiceRequestsManagerForm12970 from './ServiceRequestsManagerView/ServiceRequestsManagerForm12970';
import TenantForm12970 from './TenantView/TenantForm12970';
import VisitorArrivalForm12970 from './ReceptionView/VisitorArrivalForm12970';

import ExtendedCheckpointForm12970 from './common/ExtendedCheckpointForm12970';

function RequestsPage12970(props) {
  return (
    <RequestsPage
      assignedForm={<AssignedForm12970 />}
      checkpointDxGrid={<CheckpointDxGrid12970 />}
      checkpointOperatorDxGrid={<CheckpointOperatorDxGrid12970 />}
      checkpointOperatorForm={<ExtendedCheckpointForm12970 />}
      parkingOperatorDxGrid={<ParkingOperatorDxGrid12970 />}
      parkingOperatorForm={<ExtendedCheckpointForm12970 />}
      seniorParkingOperatorDxGrid={<SeniorParkingOperatorDxGrid12970 />}
      seniorParkingOperatorForm={<ExtendedCheckpointForm12970 />}
      unloadingZoneOperatorDxGrid={<UnloadingZoneOperatorDxGrid12970 />}
      unloadingZoneOperatorForm={<ExtendedCheckpointForm12970 />}
      receptionDxGrid={<ReceptionDxGrid12970 />}
      receptionForm={<ReceptionForm12970 />}
      serviceRequestsManagerForm={<ServiceRequestsManagerForm12970 />}
      tenantForm={<TenantForm12970 />}
      visitorArrivalForm={<VisitorArrivalForm12970 />}
      {...props}
    />
  );
}

export default RequestsPage12970;
