/**
 *
 * TenantDxGrid14140
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';

import {
  actualCreationDateColumnFilter,

  IS_DISCRETION_OF_SECURITY,
  isDiscretionOfSecurityColumn,
  isDiscretionOfSecurityColumnFilter,
} from '../common/dxGridComponents/columns';

export class TenantDxGrid14140 extends TenantDxGrid { // eslint-disable-line react/prefer-stateless-function
  getColumns() {
    const columns = super.getColumns();
    columns.push(isDiscretionOfSecurityColumn);
    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    const gridFilters = super.getGridFilters();
    if (viewName === 'all') {
      gridFilters.push(actualCreationDateColumnFilter);
    }
    gridFilters.push(isDiscretionOfSecurityColumnFilter);
    return gridFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const parkingPlaceIndex = columnOrder.findIndex((column) => column === 'parkingPlace');
    columnOrder.splice(parkingPlaceIndex + 1, 0, IS_DISCRETION_OF_SECURITY);
    return columnOrder;
  }
}
