/**
 *
 * DisagreementDialog sagas
 *
 */

import {call, put, takeLatest, select} from 'redux-saga/effects';
import {loadData} from 'containers/AdvancedDataTable/actions';
import * as api from 'utils/api';
import {SUBMIT_DISAGREEMENT_DIALOG} from './constants';
import {SIGHTING_DOCUMENTS_DX_GRID} from '../../SightingView/constants';
import {account} from 'domain/typeConstants/accounts';
import {DOCUMENT_POSITIONS, ESTIMATE_ITEMS} from 'domain/Data/constants';
import {getValueFromLocalStorage} from 'utils/localStorage';
import {GLOBAL_USER_KEY} from 'global-constants';
import {makeSelectDetailTableData} from "containers/AdvancedForm/selectors";
import {SUPPLY_REQUEST_FORM} from "../../SupplyRequestView/constants";


export default function* rootSaga() {
    yield takeLatest(SUBMIT_DISAGREEMENT_DIALOG, submitDisagreementDialogSaga);
}

function* submitDisagreementDialogSaga({payload: {ids, comment, dxGrid}}) {
    const user = getValueFromLocalStorage(GLOBAL_USER_KEY)

    const startTime = new Date().getTime();
    if (ids.length > 0) {
        for (let i = 0; i < ids.length; i += 1) {
            try {
                yield call(api.disagreeDocument, ids[i], comment);
                if (user.accountId === account.TVK) {
                    // пересчет остатков
                    const documentPositions = yield select(makeSelectDetailTableData(SUPPLY_REQUEST_FORM, DOCUMENT_POSITIONS));
                    for (let j = 0; j < documentPositions.length; j++) {
                        const estimateItem = documentPositions[j].estimateItem
                        const count = documentPositions[j].positionsCount
                        const changedData = {
                            countReserv: estimateItem.countReserv - count,
                            available: estimateItem.countPlan - (estimateItem.countReserv - count),
                        }
                        yield call(api.update, ESTIMATE_ITEMS, estimateItem.id, changedData);
                    }
                }
                const endTime = new Date().getTime();
                const result = endTime - startTime;
                if (result < 1000) yield* wait(1000 - result);
            } catch (err) {
                console.error(err);
            }
        }
        yield put(loadData(dxGrid || SIGHTING_DOCUMENTS_DX_GRID));
    }
}

function* wait(time) {
    yield new Promise((resolve) => setTimeout(resolve, time));
}