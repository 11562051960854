import React from 'react';
import PropTypes from 'prop-types';

import SeniorParkingOperatorView, {
  SeniorParkingOperatorBlackListForm,
  SeniorParkingOperatorBlackListFormDialog,
  SeniorParkingOperatorDxGrid,
  SeniorParkingOperatorDxGridContainer,
  SeniorParkingOperatorForm,
  SeniorParkingOperatorFormDialog,
  SeniorParkingOperatorMobileGrid,
} from '../SeniorParkingOperator';

const getDxGrid = (DxGrid) => (
  <SeniorParkingOperatorDxGridContainer component={DxGrid || <SeniorParkingOperatorDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <SeniorParkingOperatorDxGridContainer component={MobileGrid || <SeniorParkingOperatorMobileGrid />} />
);

const getDataTableView = ({ breakpoint, seniorParkingOperatorDxGrid, seniorParkingOperatorMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(seniorParkingOperatorMobileGrid);
  }

  return getDxGrid(seniorParkingOperatorDxGrid);
};

const getFormDialog = (Form) => (
  <SeniorParkingOperatorFormDialog form={Form || <SeniorParkingOperatorForm />} />
);

const getBlackListFormDialog = (blackListForm) => (
  <SeniorParkingOperatorBlackListFormDialog form={blackListForm || <SeniorParkingOperatorBlackListForm />} />
);

export const getSeniorParkingOperatorView = (props) => {
  const {
    blackListForm,
    blackListFormDialog,
    breakpoint,
    seniorParkingOperatorDxGrid,
    seniorParkingOperatorForm,
    seniorParkingOperatorFormDialog,
    seniorParkingOperatorMobileGrid,
    seniorParkingOperatorView,
    viewName,
    viewStyles,
  } = props;

  if (seniorParkingOperatorView) return seniorParkingOperatorView;

  return (
    <SeniorParkingOperatorView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        seniorParkingOperatorDxGrid,
        seniorParkingOperatorMobileGrid,
      })}
      formDialog={seniorParkingOperatorFormDialog || getFormDialog(seniorParkingOperatorForm)}
      blackListFormDialog={blackListFormDialog || getBlackListFormDialog(blackListForm)}
    />
  );
};

getSeniorParkingOperatorView.propTypes = {
  blackListForm: PropTypes.node,
  blackListFormDialog: PropTypes.node,
  breakpoint: PropTypes.object.isRequired,
  seniorParkingOperatorDxGrid: PropTypes.node,
  seniorParkingOperatorForm: PropTypes.node,
  seniorParkingOperatorFormDialog: PropTypes.node,
  seniorParkingOperatorMobileGrid: PropTypes.node,
  seniorParkingOperatorView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
