/**
 *
 * OneSignalContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectUserId,
  makeSelectUsername,
  makeSelectAccountId,
} from 'domain/User/selectors';
import { initOneSignal } from 'features/OneSignal';
import * as notificationActions from 'domain/Notifications/actions';

class OneSignalContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    initOneSignal({
      userId: this.props.userId,
      userName: this.props.userName,
      accountId: this.props.accountId,
      onNotificationDisplay: this.handleNotificationDisplay,
      onNotificationDismiss: this.handleNotificationDismiss,
      onNotificationOpen: this.handleNotificationOpen,
    });
  }

  handleNotificationDisplay = (data) => this.props.displayNotification(data);

  handleNotificationDismiss = (data) => this.props.dismissNotification(data);

  handleNotificationOpen = (data) => this.props.openNotification(data);

  render = () => null;
}

OneSignalContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  displayNotification: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  openNotification: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userId: makeSelectUserId(),
  userName: makeSelectUsername(),
  accountId: makeSelectAccountId(),
});

function mapDispatchToProps(dispatch) {
  return {
    displayNotification: (data) => dispatch(notificationActions.displayNotification(data)),
    dismissNotification: (data) => dispatch(notificationActions.dismissNotification(data)),
    openNotification: (data) => dispatch(notificationActions.openNotification(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OneSignalContainer);
