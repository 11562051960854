/*
 *
 * Autocomplete actions
 *
 */

import { CLEAR_OPTIONS, LOAD_OPTIONS, LOAD_INITIAL_VALUE } from './constants';

export function clearOptions(advancedForm, field) {
  return {
    type: CLEAR_OPTIONS,
    meta: { advancedForm, field },
  };
}

export function loadOptions(advancedForm, field, searchQuery) {
  return {
    type: LOAD_OPTIONS,
    meta: { advancedForm, field },
    payload: { searchQuery },
  };
}

export function loadInitialValue(advancedForm, { entityName, id, field }) {
  return {
    type: LOAD_INITIAL_VALUE,
    meta: { advancedForm, field },
    payload: { entityName, id },
  };
}
