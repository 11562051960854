/**
 *
 * TaskDxGridContainer
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { requestAction } from 'domain/typeConstants/action';
import { makeSelectDepartmentId, makeSelectRoles } from 'domain/User/selectors';
import * as actionCreators from './actions';
import { taskStatus } from 'domain/typeConstants/status';

class TaskDxGridContainer extends React.PureComponent {

  handleRowDoubleClick = (record) => {
    if (record?.status.statusZadachi === taskStatus.CLOSED) {
      this.props.executeViewAction(record.id)
    } else this.props.executeEditAction(record.id)
  };

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case requestAction.PERFORME:
        this.props.executePerformeAction(ids);
        break;

      case requestAction.ADD:
        this.props.executeAddAction();
        break;

      case requestAction.EDIT:
        this.props.executeEditAction(ids[0]);
        break;

      case requestAction.VIEW:
        this.props.executeViewAction(ids[0]);
        break;

      case requestAction.COPY:
        this.props.executeCopyAction(ids[0]);
        break;

      case requestAction.CLOSE:
        this.props.executeCloseAction(ids);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName, department, roles, accountId } = this.props;
    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
      department,
      roles,
      accountId,
    });
  }
}

TaskDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  department: PropTypes.string.isRequired,
  executeAddAction: PropTypes.func.isRequired,
  executeCloseAction: PropTypes.func.isRequired,
  executeCopyAction: PropTypes.func.isRequired,
  executeEditAction: PropTypes.func.isRequired,
  executeViewAction: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  viewName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  department: makeSelectDepartmentId(),
  roles: makeSelectRoles(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDxGridContainer);
