/**
 *
 * SeniorParkingOperatorDxGridContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAction } from 'domain/typeConstants/action';
import * as actionCreators from './actions';

class SeniorParkingOperatorDxGridContainer extends React.PureComponent {
  
  handleRowDoubleClick = (record) => this.props.executeViewAction(record.id);

  handleActionExecuting = (actionName, ids) => {
    switch (actionName) {
      case requestAction.VIEW:
        this.props.executeViewAction(ids[0]);
        break;

      case requestAction.ARRIVED:
        this.props.executeArrivedAction(ids);
        break;

      case requestAction.DEPARTED:
        this.props.executeDepartedAction(ids);
        break;

      case requestAction.ADD_TO_BLACK_LIST:
        this.props.executeAddBlackListItem(ids);
        break;

      default:
        throw new Error('Executed a unknown action.');
    }
  };

  render() {
    const { component, viewName } = this.props;

    return React.cloneElement(component, {
      viewName,
      onActionExecuting: this.handleActionExecuting,
      onRowDoubleClick: this.handleRowDoubleClick,
    });
  }
}

SeniorParkingOperatorDxGridContainer.propTypes = {
  component: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  executeAddBlackListItem: PropTypes.func.isRequired,
  executeArrivedAction: PropTypes.func.isRequired,
  executeDepartedAction: PropTypes.func.isRequired,
  executeViewAction: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(SeniorParkingOperatorDxGridContainer);
