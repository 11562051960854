/*
 *
 * AdministratorBCServiceRequestsRejectForm14326
 *
 */

import { ServiceRequestsManagerRejectForm14326 } from '../ServiceRequestsManagerView';

export class AdministratorBCServiceRequestsRejectForm14326 extends ServiceRequestsManagerRejectForm14326 {

}
