/**
 *
 * ReceptionMobileGrid 15774
 *
 */

import ReceptionMobileGrid from 'pages/Requests/ReceptionView/ReceptionMobileGrid';
import {requestAction} from 'domain/typeConstants/action';
import {acceptActionItem} from "../common/actionMenuItem";

export class ReceptionMobileGrid15774 extends ReceptionMobileGrid {

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems()
        const rejectMenuItemIndex = actionMenuItems.findIndex(button => button.name === requestAction.REJECT)
        actionMenuItems.splice(rejectMenuItemIndex, 1)

        const acceptMenuItemIndex = actionMenuItems.findIndex(menu => menu.name === requestAction.ACCEPT)
        actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem)

        return actionMenuItems
    }


}
