/*
 *
 * RestorePasswordFormContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';

import { submit } from './actions';
import RestorePasswordForm from './RestorePasswordForm';

function RestorePasswordFormContainer({ onSubmit, ...restProps }) {
  return (
    <RestorePasswordForm
      onSubmit={onSubmit}
      {...restProps}
    />
  );
}

RestorePasswordFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: () => dispatch(submit()),
  };
}

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'restorePasswordForm',
    getFormState: (state) => state.get('forms'),
  })(RestorePasswordFormContainer)
);
