/**
 *
 * DisagreementDialog reducer
 *
 */

import { Map, List } from 'immutable';

import {
  OPEN_DISAGREEMENT_DIALOG,
  CLOSE_DISAGREEMENT_DIALOG,
  SUBMIT_DISAGREEMENT_DIALOG,
} from './constants';

const initialState = new Map({
  open: false,
  ids: new List([]),
});

function disagreementDialogReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_DISAGREEMENT_DIALOG:
      return state
        .set('open', true)
        .set('ids', new List(payload.ids))
        .set('dxGrid', payload.dxGrid);

    case CLOSE_DISAGREEMENT_DIALOG:
      return state
        .set('open', false)
        .set('ids', new List([]))
        .set('dxGrid', '');

    case SUBMIT_DISAGREEMENT_DIALOG:
      return state
        .set('open', false)
        .set('ids', new List([]))
        .set('dxGrid', '');

    default:
      return state;
  }
}


export default disagreementDialogReducer;
