/**
 *
 * Reception Rules Page constants
 *
 */

export const RECEPTION_RULE_DATA_TABLE = 'ReceptionRuleDataTable';
export const RECEPTION_RULE_FORM = 'ReceptionRuleForm';
export const RECEPTION_RULE_FORM_DIALOG = 'ReceptionRuleFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/RulesPage/ReceptionView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RulesPage/ReceptionView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RulesPage/ReceptionView/EXECUTE_VIEW_ACTION';
