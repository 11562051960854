/*
 *
 * ServiceRequestsManagerDxGrid14419
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';

import {
    SERVICE_REQUESTS_NOT_INSTRUCTED,
} from 'domain/Data/Filters/constants';


import {
    viewActionButton,
    instructedActionButton,
} from '../common/actionButton';
import {
    viewActionItem,
    instructedActionItem,
} from '../common/actionMenu';

import {
    carryingOurWorkingTabFilters, carryingOurWorkingTabOrder, getCarryingOurWorkingTab,
} from '../common/dxGridComponents/columns';

import { columnType } from 'domain/typeConstants/table';

export class ServiceRequestsManagerDxGrid14419 extends ServiceRequestsManagerDxGrid {

    getFilters() {
        const { viewName } = this.props;
        const filters = super.getFilters();
        if (viewName === 'notInstructed') {
            filters.push(SERVICE_REQUESTS_NOT_INSTRUCTED);
        }
        return filters;
    }

    getColumns() {
        const columns = getCarryingOurWorkingTab();
        columns.push({
            path: 'isInstructed',
            name: 'isInstructed',
            title: 'Инструктаж пройден',
            type: columnType.BOOLEAN,
            width: 50,
        });
        return columns;
    }

    getGridFilters() {
        const gridFilters = carryingOurWorkingTabFilters;
        gridFilters.push({ columnName: 'isInstructed', value: '' });
        return gridFilters;
    }

    getColumnOrder() {
        const columnOrder = carryingOurWorkingTabOrder;
        const statusNameIndex = columnOrder.findIndex((name) => name === 'statusName');
        columnOrder.splice(statusNameIndex + 1, 0, 'isInstructed');
        return columnOrder;
    }

    getActionButtons() {
        return [viewActionButton, instructedActionButton];
    }

    getActionMenuItems() {
        return [viewActionItem, instructedActionItem];
    }
}
