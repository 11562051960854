/**
 *
 * WorkForm
 *
 * 
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { USER_WORK_FORM } from './constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import * as actionCreators from '../../Tasks/TaskView/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateWorkTimeNumber } from 'utils/validators'

class WorkForm extends React.PureComponent {

  getFields() {
    const { parent, parentId, mode } = this.props
    const currentDate = new Date();
    return [
      {
        name: 'id',
        label: '№',
        visible: false,
        disabled: true,
        grid: { sm: 2 },
      },

      {
        name: 'runDate',
        type: 'date',
        label: 'Дата',
        required: true,
        visible: true,
        initialValue: (values, mode) => (mode === 'add' || mode === 'copy') ? currentDate : undefined,
        disabled: (mode === 'add' || mode === 'copy'),
        grid: { sm: 4 },
      },

      {
        name: 'author',
        type: 'autocomplete',
        label: 'Автор',
        entityName: 'employees',
        disabled: true,
        initialValue: (values, mode, user) => user.id,
        grid: { sm: 6 },
      },

      {
        name: 'duration',
        label: 'Часы',
        grid: { sm: 2 },
        validate: validateWorkTimeNumber,
        normalize: (value) => value ? value.replace(',', '.') : value,
      },

      {
        name: 'description',
        label: 'Что сделано',
        multiline: true,
        grid: { sm: 12 },
        required: true,
        type: 'editor',
      },

      {
        name: 'task',
        label: 'task',
        visible: false,
        initialValue: (mode === 'add' || mode === 'copy') && parent === 'tasks' && parentId,
      },

      {
        name: 'request',
        label: 'request',
        visible: false,
        initialValue: (mode === 'add' || mode === 'copy') && parent === 'requests' && parentId,
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={USER_WORK_FORM}
        entityName="works"
        classNames={this.getClassNames()}
        fields={this.getFields()}
        emptyPlace
        accountId={getValueFromLocalStorage(GLOBAL_USER_KEY).accountId}
        isDetail={false}

        {...this.props}
      />
    );

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(WorkForm);

