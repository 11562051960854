/*
 *
 * AdvancedMobileGrid  utils
 *
 */

import { getAllKeysAsArray } from 'utils/objectUtils';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';


export const needToUpdateSettingsConfig = ({ accessKey, currentConfig, localStorageConfig }) => {
  if (!localStorageConfig || !localStorageConfig[accessKey]) return true;
  const localStorageColumnFilter = localStorageConfig[accessKey].filtering.value.slice();
  const currentColumnFilter = currentConfig[settings.FILTERING].value.slice();
  const isColumnFilterEquals = compareTableFeature(localStorageColumnFilter, currentColumnFilter);

  if (!isColumnFilterEquals) return true;

  // config key amount
  const configKeyAmount = getAllKeysAsArray(currentConfig).length;
  const localStorageConfigKeyAmount = getAllKeysAsArray(localStorageConfig[accessKey]).length;

  return configKeyAmount > localStorageConfigKeyAmount;
};

export function compareTableFeature(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;

  for (let i = 0, length = a.length; i < length; i += 1) {
    if (b[i] == null) return null;
    if (a.columnName !== b.columnName) return false;
  }

  return true;
}
