/*
 *
 * RestorePasswordForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import StyledTextField from './StyledTextField';

const styleSheet = (theme) => ({
  errorText: {
    display: 'flex',
    fontSize: 15,
    color: theme.palette.error.A700,
    backgroundColor: theme.palette.error[50],
    padding: '8px 0',
  },
  errorIcon: {
    fill: theme.palette.error.A700,
    margin: '0 8px',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldIcon: {
    margin: '0px 16px 0px 0px',
    position: 'relative',
    bottom: '-10px',
  },
  buttons: {
    textAlign: 'right',
    padding: '32px 0 16px',
  },
  formControl: {
    width: '125%',
  },
});

class RestorePasswordForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      showNewPassword: false,
      showNewPasswordConfirmation: false,
      errorNewPasswordHint: '',
      errorNewPasswordConfirmationHint: '',
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  validatePassword = (value) => {
    const lowerCase = /^[a-z]+$/;
    const upperCase = /^[A-Z]+$/;
    const digit = /^[0-9]+$/;
    let isLowerCase = false;
    let isUpperCase = false;
    let isDigit = false;
    if (value && value.length > 7) {
      for (let i = 0; i < value.length; i++) {
        if (!isLowerCase && lowerCase.test(value[i])) { isLowerCase = true }
        else if (!isUpperCase && upperCase.test(value[i])) isUpperCase = true
        else if (!isDigit && digit.test(value[i])) isDigit = true
      }
      if (isLowerCase && isUpperCase && isDigit) return undefined;
      else return 'пароль не соответствует условиям';
    } else
      if (!value) return undefined;
    return 'Недостаточная длина пароля';
  };

  handleChange = (prop, hint) => (event) => {
    this.setState({ [prop]: event.target.value });
    this.setState({ [hint]: this.validatePassword(event.target.value) });
  };

  handleClickShowPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  };

  handleClickShowPasswordConfirmation = () => {
    this.setState({ showNewPasswordConfirmation: !this.state.showNewPasswordConfirmation })
  };


  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { className, classes, error } = this.props;
    const showNewPassword = false;
    const showPasswordConfirmation = false;

    return (
      <form
        className={className}
        onSubmit={this.handleSubmit}
      >
        {(error && error === "Время действия ссылки истекло") ?
          <Typography variant="subheading" component="h3" className={classes.errorText}>
            <Icon className={classes.errorIcon}> error</Icon>
            {error}
          </Typography>
          :
          <div>
            {
              error &&
              <Typography variant="subheading" component="h3" className={classes.errorText}>
                <Icon className={classes.errorIcon}> error</Icon>
                {error}
              </Typography>
            }
            <div className={classes.fieldContainer}>
              <Icon className={classes.fieldIcon}>lock</Icon>
              <StyledTextField
                autoComplete="on"
                label="Новый пароль"
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                validate={this.validatePassword}
                fullWidth
                margin="normal"
                disabled={false}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Icon className={classes.fieldIcon}>lock</Icon>
              <StyledTextField
                autoComplete="on"
                label="Подтверждение пароля"
                name="newPasswordConfirmation"
                type={showPasswordConfirmation ? 'text' : 'password'}
                validate={this.validatePassword}
                fullWidth
                margin="normal"
                disabled={false}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                variant="raised"
                color="primary"
                type="submit"
                disabled={error}
              >
                Восстановить пароль
             </Button>
            </div>
          </div>
        }
      </form>
    );
  }
}

RestorePasswordForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(RestorePasswordForm);
