/**
*
* DataTable
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTableHeader from './DataTableHeader';
import DataTableBody from './DataTableBody';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

const styleSheet = {
  root: {
    overflowX: 'auto',
  },
};

class DataTable extends React.PureComponent {

  constructor(props) {
    super(props);
    this.gridContainer = React.createRef();
    this.tableContainer = React.createRef();
    this.state = {
      virtualTableHeight: null,
    };
    this.updateVirtualTableHeight = this.updateVirtualTableHeight.bind(this);
  }

  componentDidMount() {
    this.updateVirtualTableHeight();
    window.addEventListener('resize', this.updateVirtualTableHeight);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.updateVirtualTableHeight);
  }

  scrollHandler = (e) => {
    const { settingsConfig } = this.props
    if (settingsConfig.tableType === settings.VIRTUAL_TABLE) {
      const offset = 150 // пока добавил так, выяснить из чего складывается
      const result = this.tableContainer.current.scrollHeight
        - (this.tableContainer.current.scrollTop + window.innerHeight)
        + offset
      if (result < 0) {
        const { data, totalCount, sorting, loadData, mode } = this.props
        if (data.length < totalCount) {
          const startAt = data.length + 1;
          const limit = settingsConfig.lazyLoadingBlockSize.value;
          loadData({ sorting, startAt, limit, mode });
        }
      }
    }
  }

  isAllSelected = (data, selection) => data && selection && data.length !== 0
    ? data.length === selection.length : false;

  handleSortLabelClick = (event, column) => {
    const { sorting } = this.props;
    let newSorting = [];

    const sortingRuleIndex = sorting.findIndex((sortingRule) =>
      sortingRule.columnId === column.id
    );

    if (sortingRuleIndex === -1) {
      const newSortingRule = { columnId: column.id, direction: 'desc' };
      newSorting = [newSortingRule];
    } else {
      const sortingRule = sorting[sortingRuleIndex];
      let changedSortingRule;

      if (sortingRule.direction === 'desc') {
        changedSortingRule = {
          ...sortingRule,
          direction: 'asc',
        };
        newSorting = [changedSortingRule];
      } else {
        changedSortingRule = {
          ...sortingRule,
          direction: 'desc',
        };
        newSorting = [changedSortingRule];
      }
    }

    this.createSortingChangeHandler(newSorting, sorting);
  }

  handleSelectAllClick = (event, checked) => {
    const { onSelectionChange } = this.props;
    if (checked) {
      onSelectionChange(this.props.data.map((row) => row));
      return;
    }
    onSelectionChange([]);
  };

  handleSelectRowClick = (record) => {
    const { selection, onSelectionChange, selectable } = this.props;
    const selectedIndex = selection.findIndex(selectionRecord => selectionRecord.id === record.id);
    let newSelection;

    if (!selectable) {
      return;
    } else if (selectedIndex === -1) {
      newSelection = [...selection, record];
    } else if (selectedIndex === 0) {
      newSelection = [...selection.slice(1)];
    } else if (selectedIndex === selection.length - 1) {
      newSelection = [...selection.slice(0, -1)];
    } else if (selectedIndex > 0) {
      newSelection = [
        ...selection.slice(0, selectedIndex),
        ...selection.slice(selectedIndex + 1),
      ];
    }
    onSelectionChange(newSelection);
  };

  handleActionMenuItemClick = (actionName, record) => {
    const { onActionMenuItemClick } = this.props;
    if (onActionMenuItemClick) onActionMenuItemClick(actionName, record);
  }

  createSortingChangeHandler(newSorting) {
    const { onSortingChange } = this.props;
    if (onSortingChange) onSortingChange(newSorting);
  }

  updateVirtualTableHeight() {
    if (this.props.dataTableName && this.gridContainer.current) {
      const gridContainer = this.gridContainer.current.getBoundingClientRect();
      const gridOffsetTop = gridContainer.top;
      const visibleWindowHeight = document.documentElement.clientHeight;
      // const toolbarHeight = document.getElementById('dataTableToolbar').getBoundingClientRect().height;
      const BOTTOM_GAP = 16;
      const { settingsConfig } = this.props;
      const paginationHeight = settingsConfig.tableType === settings.PAGING_TABLE ? 32 : 0
      let computedHeight = (visibleWindowHeight - gridOffsetTop - BOTTOM_GAP - paginationHeight);
      this.setState({ virtualTableHeight: computedHeight });
    }
  };

  getToolWidth = (isActionMenu, isTableRowDetail, isFilePreview) => {
    const { selectable } = this.props
    const buttonWidth = 32
    const paddings = 24
    const selectableWidth = selectable ? buttonWidth : 0
    const actionMenuItemsWidth = isActionMenu ? buttonWidth : 0
    const tableRowDetailWidth = isTableRowDetail ? buttonWidth : 0
    const filePreviewWidth = isFilePreview ? buttonWidth : 0
    return actionMenuItemsWidth + selectableWidth + tableRowDetailWidth + filePreviewWidth + paddings
  }

  getWidth = (columnOrder, columnWidths, toolWidth, columnVisibilityNames) => {
    return columnOrder.reduce((acc, b) => {
      const column = columnWidths.find(col => col.columnName === b
        && columnVisibilityNames && !columnVisibilityNames.includes(b))
      return column ? acc + column.width : acc
    }, toolWidth);
  }

  calculateHeight = (mode, height, virtualTableHeight) => {
    switch (mode) {
      case "parent":
        return virtualTableHeight;
      case "detail":
        // return 'none';
        return height;
      case "dialog":
        return height;
      default:
        return 'none'
    }
  }

  render() {
    const { dataTableName, selectable, tableFeatures, actionMenuItems,
      columns, data, sorting, onMenuItemChange, expandedRowIds, detailContainer,
      columnOrder, columnWidths, selection, onColumnOrderChange, onColumnWidthChange, onExpandedRowIdsChange,
      loaderState, height, pageSize, pageNumber, tableType, onRowClick, onRowDoubleClick,
      currentRecordId, gridFilters, onRowContextMenuClick, downloadFile,
      settingsConfig, columnVisibilityNames, mode, tab, changeTab, containerWidth,
      onActionMenuItemClick, onOpenDrawer, filePreviewFieldName,
    } = this.props;

    const { virtualTableHeight } = this.state
    const isAllSelected = this.isAllSelected(data, selection);

    let isActionMenu;
    let isTableRowDetail;
    let isFilePreview;

    if (settingsConfig) {
      isActionMenu = settingsConfig && settingsConfig[settings.TABLE_ACTION_MENU].enabled === true
      isTableRowDetail = settingsConfig && settingsConfig[settings.TABLE_ROW_DETAIL].enabled === true
      isFilePreview = settingsConfig && settingsConfig[settings.TABLE_DRAWER].enabled === true
    }
    else {
      isActionMenu = tableFeatures.find(f => f.name === settings.TABLE_ACTION_MENU && f.value === true) ? true : false
      isTableRowDetail = false
      isFilePreview = false
    }

    const toolWidth = this.getToolWidth(isActionMenu, isTableRowDetail, isFilePreview)
    const tableWidth = this.getWidth(columnOrder, columnWidths, toolWidth, columnVisibilityNames)
    const lastColumnWidth = containerWidth > tableWidth ? Math.floor(containerWidth - tableWidth) : 0

    return (
      <div ref={this.gridContainer}>
        <div ref={this.tableContainer}
          style={{ height: this.calculateHeight(mode, height, virtualTableHeight), overflowX: "auto" }}
          onScroll={(e) => this.scrollHandler(e)}
        >
          <DataTableHeader
            selectable={selectable}
            allSelected={isAllSelected}
            actionMenuItems={actionMenuItems}
            gridFilters={gridFilters}
            columns={columns}
            sorting={sorting}
            columnOrder={columnOrder}
            columnWidths={columnWidths}
            onSelectAllClick={this.handleSelectAllClick}
            onSortLabelClick={this.handleSortLabelClick}
            toolWidth={toolWidth}
            width={tableWidth}
            onColumnOrderChange={onColumnOrderChange}
            onColumnWidthChange={onColumnWidthChange}
            settingsConfig={settingsConfig}
            columnVisibilityNames={columnVisibilityNames}
            mode={mode}
            lastColumnWidth={lastColumnWidth}
          />
          <DataTableBody
            dataTableName={dataTableName}
            selectable={selectable}
            tableFeatures={tableFeatures}
            actionMenuItems={actionMenuItems}
            columns={columns}
            data={data}
            selection={selection}
            columnOrder={columnOrder}
            columnWidths={columnWidths}
            expandedRowIds={expandedRowIds}
            loaderState={loaderState}
            detailContainer={detailContainer}
            currentRecordId={currentRecordId}
            toolWidth={toolWidth}
            width={tableWidth}
            pageSize={pageSize}
            pageNumber={pageNumber}
            tableType={tableType}
            onMenuItemChange={onMenuItemChange}
            onExpandedRowIdsChange={onExpandedRowIdsChange}
            onRowClick={onRowClick}
            onRowContextMenuClick={onRowContextMenuClick}
            onActionMenuItemClick={onActionMenuItemClick}
            onOpenDrawer={onOpenDrawer}
            onRowDoubleClick={onRowDoubleClick}
            onSelectRowClick={this.handleSelectRowClick}
            isActionMenu={isActionMenu}
            isTableRowDetail={isTableRowDetail}
            isFilePreview={isFilePreview}
            columnVisibilityNames={columnVisibilityNames}
            downloadFile={downloadFile}
            tab={tab}
            changeTab={changeTab}
            containerWidth={containerWidth}
            lastColumnWidth={lastColumnWidth}
            filePreviewFieldName={filePreviewFieldName}
          />
        </div>
      </div>
    );
  }
}

DataTable.propTypes = {
  selectable: PropTypes.bool,
  actionMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string,
      labels: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.arrayOf(PropTypes.object),
  sorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    })
  ),
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onActionMenuItemClick: PropTypes.func,
  onSortingChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onMenuItemChange: PropTypes.func,
};

export default withStyles(styleSheet)(DataTable);
