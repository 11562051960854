/**
 *
 * RulesPage12970
 *
 */

import React from 'react';

import RulesPage from 'pages/Rules/RulesPage';

import {
  TenantDxGrid12970,
  TenantMobileGrid12970,
} from './TenantView';

function RulesPage12970(props) {
  return (
    <RulesPage
      tenantDxGrid={<TenantDxGrid12970 />}
      tenantMobileGrid={<TenantMobileGrid12970 />}
      {...props}
    />
  );
}

export default RulesPage12970;
