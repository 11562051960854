/*
 *
 * ServiceRequestsManagerView constants
 *
 */

export const SERVICE_REQUESTS_MANAGER_REQUESTS_DATA_TABLE = 'ServiceRequestsManagerRequestsDataTable';
export const SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID = 'ServiceRequestsManagerRequestsTableGrid';
export const SERVICE_REQUESTS_MANAGER_REQUESTS_FORM = 'ServiceRequestsManagerRequestsForm';
export const SERVICE_REQUESTS_MANAGER_REQUESTS_FORM_DIALOG = 'ServiceRequestsManagerRequestsFormDialog';
export const SERVICE_REQUESTS_MANAGER_ACCEPT_FORM = 'ServiceRequestsManagerAcceptForm';
export const SERVICE_REQUESTS_MANAGER_ACCEPT_FORM_DIALOG = 'ServiceRequestsManagerAcceptFormDialog';
export const SERVICE_REQUESTS_MANAGER_REJECT_FORM = 'ServiceRequestsManagerRejectForm';
export const SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG = 'ServiceRequestsManagerRejectFormDialog';
export const SERVICE_REQUESTS_MANAGER_COMPLETE_FORM = 'ServiceRequestsManagerCompleteForm';
export const SERVICE_REQUESTS_MANAGER_COMPLETE_FORM_DIALOG = 'ServiceRequestsManagerCompleteFormDialog';

export const SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM = 'ServiceRequestsManagerVisitorArrivalForm';
export const SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM_DIALOG = 'ServiceRequestsManagerVisitorArrivalFormDialog';
export const SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM = 'ServiceRequestsManagerBlackListForm';
export const SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG = 'ServiceRequestsManagerBlackListFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_VIEW_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_COPY_ACTION';
export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_REJECT_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_REJECT_ACTION';
export const EXECUTE_COMPLETE_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_COMPLETE_ACTION';
export const EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_CLOSE_ACTION';

export const EXECUTE_SEND_EMAIL_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_SEND_EMAIL_ACTION';
export const EXECUTE_SEND_TO_AGREEMENT_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_SEND_TO_AGREEMENT_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_DEPARTED_ACTION';
export const EXECUTE_ADD_BLACK_LIST_ITEM_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_ADD_BLACK_LIST_ITEM_ACTION';
export const EXECUTE_INSTRUCTED_ACTION = 'claris-vnext/RequestsPage/ServiceRequestsManagerView/EXECUTE_INSTRUCTED_ACTION';

export const SERVICE_REQUESTS_MANAGER_WORK_FORM_DIALOG = 'ServiceRequestManagerWorkFormDialog';
export const SERVICE_REQUESTS_MANAGER_WORK_FORM = 'ServiceRequestManagerWorkForm';