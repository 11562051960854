/*
 *
 * ReceptionDxGrid15783
 *
 */

import {ReceptionDxGrid} from 'pages/Requests/ReceptionView';
import {
    ACCESS_CARD_REQUESTS,
    ACTIVE_REQUESTS, CURRENT_DAY_REQUESTS, ON_CREATE_REQUESTS, REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED, REQUESTS_ON_VISIT,
    REQUESTS_ON_WORK_PRODUCTION
} from "domain/Data/Filters/constants";


export class ReceptionDxGrid15783 extends ReceptionDxGrid {

    getFilters() {
        const {viewName} = this.props;
        const filters = [];
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'property') filters.push(REQUESTS_ON_PROPERTY_BRINGING_OR_REMOVED);
        if (viewName === 'passMaking') filters.push(ACCESS_CARD_REQUESTS);
        return filters;
    }

    getColumnOrder() {
        const {viewName} = this.props;
        let columnOrder = super.getColumnOrder();

        if (viewName === 'passMaking') {
            columnOrder = ['number', 'visitDate', 'statusName', 'authorName', 'company_name', 'visitorFullName', 'fileName',]
        }

        return columnOrder;
    }

}
