/*
 *RestorePasswordPage sagas
 */

import { fork } from 'redux-saga/effects';
import restorePasswordFormSaga from 'containers/RestorePasswordForm/sagas';

export default function* rootSaga() {
  yield fork(restorePasswordFormSaga);
}

