/*
 *
 * AdministratorBCRequestsDxGrid14419
 *
 */

import { ReceptionDxGrid14419 } from '../ReceptionView';
import { columnType } from "domain/typeConstants/table";
import {
    CURRENT_DAY_REQUESTS,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_WORK_PRODUCTION,
    ON_CREATE_REQUESTS,
    REQUESTS_FOR_AGREE,
} from 'domain/Data/Filters/constants';

export class AdministratorBCRequestsDxGrid14419 extends ReceptionDxGrid14419 {

    unnecessaryColumns = new Set(['passNumber', 'parkingPlace', 'serviceName', 'documentDetails', 'materialValuesData']);

    getFilters() {
        const { viewName } = this.props;
        const filters = [];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'agree') filters.push(REQUESTS_FOR_AGREE);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'carryingOutWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        return filters;
    }

    getColumns() {
        const columns = super.getColumns();
        const { viewName } = this.props;
        if (viewName === 'agree') {
            columns.push({
                path: 'whatHappened',
                name: 'whatHappened',
                title: 'Наименование работ',
                width: 150,
            });
            columns.push({
                path: ['factoryTime', 'name'],
                name: 'factoryTime',
                getCellValue: (row) => (row.factoryTime ? row.factoryTime.name : undefined),
                title: 'Время',
                width: 150,
            });
            columns.push({
                path: 'workPlace',
                name: 'workPlace',
                title: 'Место работ',
                width: 150,
            });
            columns.push({
                path: ['requestScan', 'name'],
                name: 'requestScan',
                getCellValue: (row) => row.requestScan ? row.requestScan.id : undefined,
                title: 'Скан заявки',
                type: columnType.FILE,
                width: 150,
            });
            columns.push({
                path: 'responsibleFromContractor',
                name: 'responsibleFromContractor',
                title: 'Отв. от порядчика',
                width: 150,
            });
            columns.push({
                path: 'responsibleFromContractorPhone',
                name: 'responsibleFromContractorPhone',
                title: 'Тел. отв.',
                width: 150,
            });
            return columns.filter((column) => !this.unnecessaryColumns.has(column.name));
        }
        return columns;
    }

    getGridFilters() {
        const gridFilters = super.getGridFilters();
        const { viewName } = this.props;

        if (viewName === 'agree') {
            gridFilters.push({ columnName: 'whatHappened', value: '' });
            gridFilters.push({ columnName: 'factoryTime', value: '' });
            gridFilters.push({ columnName: 'workPlace', value: '' });
            gridFilters.push({ columnName: 'requestScan', value: '' });
            gridFilters.push({ columnName: 'responsibleFromContractor', value: '' },);
            gridFilters.push({ columnName: 'responsibleFromContractorPhone', value: '' },);
        }
        return gridFilters;
    }

    getColumnOrder() {
        const columnOrder = super.getColumnOrder();
        const { viewName } = this.props;
        if (viewName === 'agree') {
            const companyNameIndex = columnOrder.findIndex((columnName) => columnName === 'companyName');
            columnOrder.splice(companyNameIndex + 2, 0, 'whatHappened', 'factoryTime', 'workPlace', 'requestScan',
                'responsibleFromContractor', 'responsibleFromContractorPhone');
        }
        return columnOrder;
    }
}
