/**
 *
 * VisitorArrivalForm14326
 *
 */

import { VisitorArrivalForm } from 'pages/Requests/ReceptionView';
import { kyrilicKeyboardLayoutToLatin } from 'utils/common';

export class VisitorArrivalForm14326 extends VisitorArrivalForm {
  getFields() {
    const fields = super.getFields();

    const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
    fields[passNumberIndex] = {
      name: 'accessCardKey',
      label: 'Ключ карты доступа',
      initialValue: () => null,
      normalize: (value) => value ? kyrilicKeyboardLayoutToLatin(value) : value,
      // visible: (values) => values.type !== SERVICE && values.type !== ON_PASS_MAKING,
    };

    const documentTypeIndex = fields.findIndex((field) => field.name === 'documentType');
    fields[documentTypeIndex].visible = true;

    const documentDetailsIndex = fields.findIndex((field) => field.name === 'documentDetails');
    fields[documentDetailsIndex].visible = true;

    const fileIndex = fields.findIndex((field) => field.name === 'file');
    if (fileIndex === -1) {
      fields.push({
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: false,
        rowBreakBefore: true,
      });
    }

    fields.push(
      {
        name: 'isAllowProcessPersonalData',
        type: 'checkbox',
        label: 'Cогласие на обработку персональных данных',
        visible: true,
        required: (values) => !!values.documentDetails,
        rowBreakAfter: true,
      }
    )

    fields.push(
      {
        name: 'isAllowProcessPersonalDataMessage',
        type: 'message',
        visible: (values) => values.isAllowProcessPersonalData !== true,
        calculatedValue: (values) => {
          if (values.isAllowProcessPersonalData === true) { return null }
          return 'Вам необходимо получить согласие на обработку персональных данных и поднять флаг';
        }
      }
    )

    return fields;
  }
}
