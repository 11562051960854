/**
 *
 * ReceptionDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, userColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { editActionButton, addActionButton } from '../common/actionButton';
import { editActionItem } from '../common/actionMenu';
import { USERS_DATA_TABLE } from './constants';
import { role } from 'domain/typeConstants/roles';
import { USERS_WITHOUT_SYSADMIN } from 'domain/Data/Filters/constants';

class UsersDxGrid extends React.PureComponent {
  getFilters() {
    return [USERS_WITHOUT_SYSADMIN];
  }

  getColumns() {
    return [
      {
        path: 'name',
        name: 'name',
        title: 'Логин',
        width: userColumnSizes.name,
      },
      {
        path: ['employee', 'name'],
        name: 'employee.name',
        title: 'Сотрудник',
        getCellValue: (row) => row.employee ? row.employee.name : undefined,
        width: userColumnSizes.employeeName,
      },
      {
        path: ['employee', 'company'],
        name: 'employee.company',
        title: 'Организация',
        getCellValue: (row) => {
          if (row.employee && row.employee.company) {
            return row.employee.company.name;
          }
          return undefined;
        },
        width: userColumnSizes.employeeCompany,
      },
      {
        path: ['employee', 'department'],
        name: 'employee.department',
        title: 'Отдел',
        getCellValue: (row) => {
          if (row.employee && row.employee.department) {
            return row.employee.department.name;
          }
          return undefined;
        },
        width: userColumnSizes.employeeDepartment,
      },
      // //отключено до выяснения ошибки создания пользователя
      // {
      //   name: 'isBlocked',
      //   path: ['identityBlock', 'is_Blocked'],
      //   title: 'Заблокирован',
      //   type: columnType.BOOLEAN,
      //   width: userColumnSizes.isBlocked,
      //   getCellValue: (row) => {
      //     if (row.identityBlock) { return row.identityBlock.is_Blocked }
      //     return undefined;
      //   },
      // },
      {
        name: 'isBlocked',
        path: ['isBlocked'],
        title: 'Заблокирован',
        type: columnType.BOOLEAN,
        width: userColumnSizes.isBlocked,
        sortable: false,
        filter: false,
      },
      {
        path: 'rolesText',
        name: 'rolesText',
        title: 'Входит в группу',
        type: columnType.CHIP,
        width: userColumnSizes.chip,
        sortable: false,
        filter: false,
      },
      {
        path: 'forcePasswordChange',
        name: 'forcePasswordChange',
        title: 'Требовать смену пароля',
        type: columnType.BOOLEAN,
        width: userColumnSizes.forcePasswordChange,
      },
    ];
  }

  getColumnOrder() {
    return ['name', 'employee.name', 'employee.company', 'employee.department', 'forcePasswordChange', 'isBlocked', 'rolesText',];
  }

  getActionButtons() {
    const buttons = []
    const { roles } = this.props

    const isAdmin = roles.includes(role.ADMINISTRATOR_BC)
    isAdmin && buttons.push(addActionButton)
    buttons.push(editActionButton)

    return buttons
  }

  getActionMenuItems() {
    return [editActionItem];
  }

  getSorting() {
    return [{ columnId: 'name', columnName: 'name', direction: 'asc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }

  getRowContainerType() {
    return null;
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <AdvancedDataTable
        entityName="users"
        name={USERS_DATA_TABLE}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

UsersDxGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  viewName: PropTypes.string,
};

export default UsersDxGrid;
