/**
 *
 * Role types
 *
 */

export const role = Object.freeze({
  APPLICANT: 'applicant',
  ASSIGNED: 'serviceDeskAssignee',
  CHECKPOINT: 'checkpoint',
  RECEPTION: 'reception',
  SERVICE_REQUESTS_MANAGER: 'serviceRequestsManager',
  SIGHTING: 'sighting',
  SYSTEM_ADMIN: 'systemAdmin',
  TENANT: 'tenant',
  USER: 'user',
  MAKING_AGREEMENT_BC: 'makingAgreementBC',
  ADMINISTRATOR_BC: 'administratorBC',
  SUPPLY_APPLICANT: 'supplyApplicant',
  ADMIN_SKUD: 'adminSkud',

  /** @Comcity */
  CHECKPOINT_OPERATOR: 'checkpointOperator',
  PARKING_OPERATOR: 'parkingOperator',
  SENIOR_PARKING_OPERATOR: 'seniorParkingOperator',
  UNLOADING_ZONE_OPERATOR: 'unloadingZoneOperator',

  /** @BC_Lesnaya */
  RESTRICTED_ADMINISTRATOR_BC: 'restrictedAdministratorBC',
});
