/*
 *
 * AdvancedDataTableCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import createAdvancedDataTable from './createAdvancedDataTable';
import { clearEntity as clearEntityBase } from 'domain/Data/actions';
import { getValueFromLocalStorage, setValueToLocalStorage, removeValueFromLocalStorage } from 'utils/localStorage';
import { FALLBACK_VIEW_NAME, MAIN_TABLE_KEY, SETTINGS_CONFIG_NAME } from './constants';
import { settings, filterOperation } from 'containers/AdvancedDataTable/dxGridTypes';
import { checkTableFeature, needToUpdateSettingsConfig } from './utils';
import { makeSelectAccountId } from 'domain/User/selectors';
import { columnType } from 'domain/typeConstants/table';
import { register } from './actions';
import { unique } from 'utils/formatUtils';
import { makeSelectFilter } from 'pages/Dashboard/DashboardView/selectors';
import { convertDatetoString } from 'utils/dateUtils';

class AdvancedDataTableCreator extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      settingsConfig: null,
    };
  }

  componentWillMount() {
    const {
      actionButtons,
      actionMenuItems,
      columns,
      columnVisibilityNames,
      entityName,
      filters,
      pageSize,
      pageSizes,
      initialize,
      lazyLoadingBlockSize,
      name,
      sorting,
      tableFeatures,
      tableType,
      dashboardFilter,
    } = this.props;

    const columnOrder = unique(this.props.columnOrder);
    const columnWidths = this.getColumnWidths(columns)
    const customGridFilters = this.getCustomGridFilters(columns, columnOrder)
    const gridFilters = customGridFilters.map(item => { return { columnName: item.columnName, value: '' } })

    const ACCESS_KEY = this.getAccessKey();

    let localStorageConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    const localStorageTableType = localStorageConfig && localStorageConfig[ACCESS_KEY]?.tableType

    const config = {
      tableType: localStorageTableType ? localStorageTableType :
        tableType || settings.PAGING_TABLE,
      [settings.COLUMN_VISIBILITY]: {
        enabled: (() => {
          const result = checkTableFeature(settings.COLUMN_VISIBILITY, tableFeatures);
          return result ? result.value : false;
        })(),
        value: columnVisibilityNames,
      },
      [settings.FILTERING]: {
        enabled: (() => {
          const result = checkTableFeature(settings.FILTERING, tableFeatures);
          return result ? result.value : false;
        })(),
        value: gridFilters,
      },
      [settings.CUSTOM_FILTERING]: {
        enabled: (() => {
          const result = checkTableFeature(settings.FILTERING, tableFeatures);
          return result ? result.value : false;
        })(),
        value: customGridFilters,
      },
      [settings.LAZY_LOADING_BLOCK_SIZE]: {
        enabled: true,
        value: lazyLoadingBlockSize,
      },
      [settings.PAGE_SIZE]: {
        enabled: true,
        value: pageSize,
      },
      [settings.REORDERING]: {
        enabled: (() => {
          const result = checkTableFeature(settings.REORDERING, tableFeatures);
          return result ? result.value : true;
        })(),
        value: columnOrder,
      },
      [settings.RESIZING]: {
        enabled: (() => {
          const result = checkTableFeature(settings.RESIZING, tableFeatures);
          return result ? result.value : true;
        })(),
        value: columnWidths,
      },
      [settings.TABLE_ACTION_MENU]: {
        enabled: (() => {
          const result = checkTableFeature(settings.TABLE_ACTION_MENU, tableFeatures);
          return result ? result.value : true;
        })(),
      },
      [settings.TABLE_DRAWER]: {
        enabled: (() => {
          const result = checkTableFeature(settings.TABLE_DRAWER, tableFeatures);
          return result ? result.value : false;
        })(),
      },
      [settings.TABLE_ROW_DETAIL]: {
        enabled: (() => {
          const result = checkTableFeature(settings.TABLE_ROW_DETAIL, tableFeatures);
          return result ? result.value : false;
        })(),
      },
      [settings.SETTING_BUTTON]: {
        enabled: (() => {
          const result = checkTableFeature(settings.SETTING_BUTTON, tableFeatures);
          return result ? result.value : true;
        })(),
      },
      [settings.UPDATE_BUTTON]: {
        enabled: (() => {
          const result = checkTableFeature(settings.UPDATE_BUTTON, tableFeatures);
          return result ? result.value : false;
        })(),
      },
      [settings.FILTER_BUTTON]: {
        enabled: (() => {
          const result = checkTableFeature(settings.FILTER_BUTTON, tableFeatures);
          return result ? result.value : false;
        })(),
      },
    };

    const shouldLocalStorageUpdate = needToUpdateSettingsConfig({
      accessKey: ACCESS_KEY,
      currentConfig: config,
      localStorageConfig,
    });

    if (!localStorageConfig) {
      const initConfig = { [ACCESS_KEY]: config };
      setValueToLocalStorage(SETTINGS_CONFIG_NAME, initConfig);
      localStorageConfig = initConfig;
    } else if (shouldLocalStorageUpdate || !localStorageConfig[ACCESS_KEY]) {

      const dashboardFilterData = dashboardFilter?.get(ACCESS_KEY);
      if (dashboardFilterData) {
        const filters = config[settings.CUSTOM_FILTERING].value;
        const newFilters = filters.map(filter => {
          if (filter.columnName === dashboardFilterData.columnName.name) {
            return { ...filter, outputValue: dashboardFilterData.columnName.value }
          }
          if (filter.columnName === 'actualCreationDate') {
            return {
              ...filter, outputValue: {
                fromValue: dashboardFilterData.actualCreationDate.fromValue,
                toValue: dashboardFilterData.actualCreationDate.toValue
              }
            }
          }
          else return { ...filter, outputValue: '' }
        })
        config[settings.CUSTOM_FILTERING].value = newFilters;
      }

      localStorageConfig[ACCESS_KEY] = config;
      setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageConfig);
    }

    this.updateFiltersFromLocalStorage(ACCESS_KEY, gridFilters);
    const savedFilters = this.getSavedFilters(ACCESS_KEY);

    initialize(name, {
      actionButtons,
      actionMenuItems,
      columns,
      columnOrder: localStorageConfig[ACCESS_KEY][settings.REORDERING].value,
      columnVisibilityNames: localStorageConfig[ACCESS_KEY][settings.COLUMN_VISIBILITY].value,
      columnWidths: localStorageConfig[ACCESS_KEY][settings.RESIZING].value,
      entityName,
      filters,
      gridFilters,
      customGridFilters,
      savedFilters,
      pageSizes,
      lazyLoadingBlockSize,
      limit: [localStorageConfig[ACCESS_KEY][settings.PAGE_SIZE].value],
      pageNumber: 1,
      pageSize: localStorageConfig[ACCESS_KEY][settings.PAGE_SIZE].value,
      sorting,
      startAt: [1],
      detailContainerNames: this.getDetailContainerNames(),
      isOpenDrawer: false,
      drawerRecord: null,
    });

    this.setState({ settingsConfig: localStorageConfig[ACCESS_KEY] });
  }

  getDetailContainerNames = () => {
    const { detailContainer } = this.props
    if (detailContainer) {
      const detailContainerNames = [];
      const items = detailContainer.items;
      items.forEach(item => detailContainerNames.push(item.props.name));
      return detailContainerNames
    }
  }

  getAccessKey = () => {
    const { accountId, name, viewName } = this.props
    return `${accountId}_${name}_${viewName || FALLBACK_VIEW_NAME}_${MAIN_TABLE_KEY}`;
  }

  getCustomGridFilters = (columns, columnOrder) => {

    const getCondition = (type) => {
      switch (type) {
        case columnType.DATE:
        case columnType.DATEFULLTIME:
        case columnType.DATETIME:
          return filterOperation.DATE_RANGE
        case columnType.NUMERIC:
        case columnType.BOOLEAN:
          return filterOperation.EQUAL;
        default:
          return filterOperation.CONTAINS;
      }
    }

    const _columnOrder = Array.from(new Set(columnOrder)) //remove diplicates
    let filters = [];
    _columnOrder.forEach(column => {
      const idx = columns.findIndex((item) => item.name === column);
      if (idx !== -1 && !(columns[idx].filter === false)) {
        const filter = {
          columnName: columns[idx].name,
          status: false,
          outputValue: '',
          operation: getCondition(columns[idx].type),
          title: columns[idx].title,
          path: columns[idx].path,
          type: columns[idx].type || 'text',
          entityName: columns[idx].entityName || null,
        }
        filters.push(filter)
      }
    })
    return filters
  }

  getSavedFilters = () => {
    const ACCESS_KEY = this.getAccessKey();
    const localStorageFilterConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    let savedFilters = localStorageFilterConfig[ACCESS_KEY][settings.SAVED_FILTERS]
    if (savedFilters === undefined) {
      savedFilters = []
      localStorageFilterConfig[ACCESS_KEY][settings.SAVED_FILTERS] = savedFilters
      setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageFilterConfig);
    }
    return savedFilters;
  }

  getCustomFilters = () => {
    const ACCESS_KEY = this.getAccessKey();
    const localStorageFilterConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    return localStorageFilterConfig[ACCESS_KEY][settings.CUSTOM_FILTERING].value;
  }

  updateFiltersFromLocalStorage = (ACCESS_KEY, filters) => {
    let gridfilters = filters;
    let localStorageFilterConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    let filterConfig = localStorageFilterConfig[ACCESS_KEY][settings.CUSTOM_FILTERING].value;
    filterConfig &&
      filterConfig.map(item => {
        if (item.outputValue) {
          if (item.operation === filterOperation.DATE_RANGE
            || item.operation === filterOperation.MONTH
            || item.operation === filterOperation.ONE_DAY) {
            const fromValue = new Date(item.outputValue.fromValue.substring(0, 10));
            const toValue = new Date(item.outputValue.toValue.substring(0, 10));
            gridfilters.map(i => {
              if (i.columnName === item.columnName) {
                i.operation = item.operation
                delete i.value;
                i.value = {
                  fromValue: fromValue,
                  toValue: toValue,
                }
              }
            })
          }
          else {
            gridfilters.map(i => {
              if (i.columnName === item.columnName) {
                i.operation = item.operation
                i.value = item.outputValue
              }
            })
          }
        }
      })
  }

  getColumnWidths = (columns) => {
    return columns.map(c => { return { columnName: c.name, width: c.width || 100 } })
  }

  handleChangeSettingsConfig = (config) => {
    const {
      accountId,
      clearEntity,
      entityName,
      initialize,
      name,
      viewName,
      columns,
      pageSize,
      ...restProps
    } = this.props;

    const ACCESS_KEY = `${accountId}_${name}_${viewName || FALLBACK_VIEW_NAME}_${MAIN_TABLE_KEY}`;
    const localStorageConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    const columnWidths = this.getColumnWidths(columns)

    if (localStorageConfig[ACCESS_KEY].tableType !== config.tableType) {
      clearEntity(entityName);
      initialize(name, { entityName, columns, columnWidths, ids: [], pageSize, ...restProps });
    }
    localStorageConfig[ACCESS_KEY] = config;

    setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageConfig);
    this.setState(() => ({
      settingsConfig: config,
    }));
  };

  render() {
    const {
      accountId,
      viewName,
      name,
      selectable,
      onActionExecuting,
      onRowDoubleClick,
      tableFeatures,
      detailContainer,
      gridFilters,
      clearEntity,
      lazyLoadingBlockSize,
      mode,
      parent,
      parentId,
      height,
      dialogName,
      filePreviewFieldName,
      entityName,
    } = this.props;

    const { settingsConfig } = this.state
    const AdvancedDataTableContainer = createAdvancedDataTable(name, entityName);

    return (
      <AdvancedDataTableContainer
        gridFilters={gridFilters}
        selectable={selectable}
        tableFeatures={tableFeatures}
        detailContainer={detailContainer}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        accessKey={`${accountId}_${name}_${viewName || FALLBACK_VIEW_NAME}_${MAIN_TABLE_KEY}`}
        localFilters={this.getCustomFilters()}
        savedFilters={this.getSavedFilters()}
        viewName={viewName}
        settingsConfig={settingsConfig}
        checkTableFeature={checkTableFeature}
        onChangeSettingsConfig={this.handleChangeSettingsConfig}
        onChangeResetSettings={() => { removeValueFromLocalStorage(SETTINGS_CONFIG_NAME); this.componentWillMount(); }}
        clearEntity={clearEntity}
        lazyLoadingBlockSize={lazyLoadingBlockSize}
        mode={mode}
        parent={parent}
        parentId={parentId}
        height={height}
        dialogName={dialogName}
        filePreviewFieldName={filePreviewFieldName}
      />
    );
  }
}

AdvancedDataTableCreator.contextTypes = {
  store: PropTypes.object.isRequired,
};

AdvancedDataTableCreator.propTypes = {
  selectable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
  actionMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string,
      labels: PropTypes.arrayOf(
        PropTypes.shape({
          backgroundColor: PropTypes.string,
          fontColor: PropTypes.string.isRequired,
          condition: PropTypes.func,
        })
      ),
    })
  ).isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
  sorting: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

AdvancedDataTableCreator.defaultProps = {
  columnVisibilityNames: [''],
  filters: [],
  sorting: [],
  pageSize: 10,
  pageNumber: 1,
  pageSizes: [5, 10, 25, 50, 100],
  lazyLoadingBlockSize: 60,
};


const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  dashboardFilter: makeSelectFilter(),
});

const mapDispatchToProps = (dispatch) => ({
  clearEntity: (entityName) => dispatch(clearEntityBase(entityName)),
  initialize: (name, params) => dispatch(register(name, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedDataTableCreator);
