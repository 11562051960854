import React from 'react';
import PropTypes from 'prop-types';

import { Viewer } from './Viewer';
import { Toolbar } from './Toolbar';

class PDFViewer extends React.Component {
  state = {
    pagesAmount: null,
    currentPage: 1,
    scale: 1,
    rotationDegree: 0,
  };

  componentDidUpdate() {
    /* Adds a horizontal scroll when the page scale is large */
    const page = document.getElementsByClassName('react-pdf__Page__svg');
    window.setTimeout(() => {
      if (page.item(0)) {
        page.item(0).style.overflow = 'auto hidden';
      }
    }, 800);
  }

  componentWillUnmount() {
    this.handlePreviousPage = null;
    this.handleNextPage = null;
    const { clearDownloadLink } = this.props.data;
    if (clearDownloadLink) clearDownloadLink();
  }

  handleDocumentLoad = ({ numPages }) => {
    this.setState({ pagesAmount: numPages });
  };

  handlePreviousPage = () => {
    const { currentPage } = this.state;

    if (currentPage <= 1) return;
    this.setState(() => ({ currentPage: currentPage - 1 }));
  };

  handleNextPage = () => {
    const { currentPage, pagesAmount } = this.state;
    if (currentPage >= pagesAmount) return;
    this.setState(() => ({ currentPage: currentPage + 1 }));
  };

  handleZoomIn = () => {
    const { scale } = this.state;
    if (scale >= 5) return;
    this.setState({ scale: Number((scale + 0.2).toFixed(1)) });
  };

  handleZoomOut = () => {
    const { scale } = this.state;
    if (scale <= 0.2) return;
    this.setState({ scale: Number((scale - 0.2).toFixed(1)) });
  };

  handleClockwiseRotation = () => {
    const { rotationDegree } = this.state;
    if (rotationDegree === 270) {
      this.setState({ rotationDegree: 0 });
      return;
    }
    this.setState({ rotationDegree: rotationDegree + 90 });
  };

  handleCounterclockwiseRotation = () => {
    const { rotationDegree } = this.state;
    if (rotationDegree === 0) {
      this.setState({ rotationDegree: 270 });
      return;
    }
    this.setState({ rotationDegree: rotationDegree - 90 });
  };

  render() {
    const { styles, data } = this.props;
    const { currentPage, pagesAmount, scale, rotationDegree } = this.state;

    return (
      <div
        style={styles}
      >
        <Toolbar
          currentPage={currentPage}
          onNextPage={this.handleNextPage}
          onPreviousPage={this.handlePreviousPage}
          onClockwiseRotation={this.handleClockwiseRotation}
          onCounterclockwiseRotation={this.handleCounterclockwiseRotation}
          onZoomIn={this.handleZoomIn}
          onZoomOut={this.handleZoomOut}
          pagesAmount={pagesAmount}
          scale={scale}
        />
        <Viewer
          currentPage={currentPage}
          file={data.getValue ? data.getValue(data.value) : data.value}
          rotationDegree={rotationDegree}
          onDocumentLoad={this.handleDocumentLoad}
          ref={this.viewer}
          scale={scale}
        />
      </div>
    );
  }
}

PDFViewer.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
};

PDFViewer.defaultProps = {
  height: 600,
  width: 600,
};

export default PDFViewer;
