/**
 *
 * DocumentMatchingFormHeader reducer
 *
 */

import { Map } from 'immutable';

import {
  FIND_DOCUMENT_DETAILS,
  FIND_DOCUMENT_DETAILS_SUCCESS,
  FIND_DOCUMENT_DETAILS_ERROR,
} from './constants';

const initialState = new Map({
  visitorFullName: null,
  documentDetails: null,
  requestNumber: null,
  visitDate: null,
});

function documentMatchingFormHeaderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FIND_DOCUMENT_DETAILS:
    case FIND_DOCUMENT_DETAILS_ERROR:
      return state
        .set('visitorFullName', null)
        .set('documentDetails', null)
        .set('requestNumber', null)
        .set('visitDate', null);

    case FIND_DOCUMENT_DETAILS_SUCCESS:
      return state
        .set('visitorFullName', payload.visitorFullName)
        .set('documentDetails', payload.documentDetails)
        .set('requestNumber', payload.requestNumber)
        .set('visitDate', payload.visitDate);

    default:
      return state;
  }
}

export default documentMatchingFormHeaderReducer;
