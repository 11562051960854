/**
 *
 * SelectGridFieldCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createSelectGridFieldContainer from './createSelectGridFieldContainer';

class SelectGridFieldCreator extends React.PureComponent {
  render() {
    const SelectGridFieldContainer = createSelectGridFieldContainer(this.context.advancedForm, this.props.name);
    return <SelectGridFieldContainer {...this.props} />;
  }
}

SelectGridFieldCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

SelectGridFieldCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SelectGridFieldCreator;