/**
 * AgreementDialog selectors
 */

import { createSelector } from 'reselect';
import { DIALOG_NAME } from './constants';

const makeSelectDomain = () => (state) => state.get(DIALOG_NAME);

const makeSelectIsOpen = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('open')
);

const makeSelectRowIds = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('ids').toJS()
);

const makeSelectDxGrid = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('dxGrid')
);

export default makeSelectDomain;
export {
  makeSelectIsOpen,
  makeSelectRowIds,
  makeSelectDxGrid,
};
