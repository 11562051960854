/*
 *
 * NotificationsButtonContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';

import { loadUnreadNotificationsCount } from 'domain/Notifications/actions';
import { makeSelectUnreadCount } from 'domain/Notifications/selectors';

import NotificationsButton from './NotificationsButton';

class NotificationsButtonContainer extends React.PureComponent {
  componentDidMount() {
    this.props.loadUnreadNotificationsCount();
  }

  handleClick = () => {
    this.props.redirect('/notifications');
  }

  render() {
    return (
      <NotificationsButton
        onClick={this.handleClick}
        {...this.props}
      />
    );
  }
}

NotificationsButtonContainer.propTypes = {
  loadUnreadNotificationsCount: PropTypes.func,
  redirect: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  unreadCount: makeSelectUnreadCount(),
});

const mapDispatchToProps = (dispatch) => ({
  loadUnreadNotificationsCount: () => dispatch(loadUnreadNotificationsCount()),
  redirect: (path) => dispatch(replace(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsButtonContainer);
