/*
 *
 * Scanner sagas
 *
 */

import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { change } from 'redux-form/immutable';

import config from 'config';
import * as api from 'utils/api';
import { openEditDialog } from 'containers/AdvancedFormDialog/actions';
import { makeSelectActiveDialog } from 'containers/AdvancedFormDialog/selectors';
import { makeSelectAccountId } from 'domain/User/selectors';
import { PASSPORT, DRIVER_LICENSE } from 'domain/Data/DocumentType/constants';
import { CREATED, ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { RECEPTION_REQUESTS_FORM_DIALOG } from 'pages/Requests/ReceptionView/constants';
import { saveFile } from 'domain/Data/File/actions';
import * as snackbarActions from 'containers/Snackbar/actions';

import { SCAN_PASSPORT } from './constants';

export default function* rootSaga() {
  yield takeLatest(SCAN_PASSPORT, scanPassportSaga);
}

function* scanPassportSaga({ payload: { data } }) {
  if (data) {
    let activeDialog = yield select(makeSelectActiveDialog());

    if (activeDialog && activeDialog.get('formName') === 'VisitorArrivalForm') {
      yield call(changeVisitorArrivalFormSaga, data);
      // yield call(savePhotoSaga, 'VisitorArrivalForm', data.fotoFile); отключение сохранения скан паспорта
      return;
    }

    if (!activeDialog || activeDialog.get('formName') !== 'ReceptionRequestsForm') {
      const searchResult = yield call(searchRequestSaga, data.surname);
      if (searchResult) {
        if (searchResult.length === 0) {
          yield put(snackbarActions.open('Не найдена заявка для указанной фамилии', { autoHideDuration: 2500 }));
        } else if (searchResult.length === 1) {
          yield put(openEditDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id: searchResult[0].id }));
        } else {
          yield put(snackbarActions.open('Найдено несколько заявок для указанной фамилии', { autoHideDuration: 2500 }));
        }
      }

      activeDialog = yield select(makeSelectActiveDialog());
    }

    if (activeDialog) {
      yield call(changeReceptionRequestsFormValuesSaga, data);
      // yield call(savePhotoSaga, 'ReceptionRequestsForm', data.fotoFile); отключение сохранения скан паспорта
    }
  }
}

const getFullName = (data) => `${data.surname || ''} ${data.name || ''} ${data.patronymic || ''}`;

function* changeVisitorArrivalFormSaga(data) {
  const fullName = getFullName(data);
  const documentTypeName = (data.type || '').toLowerCase();

  let documentType = null;
  if (documentTypeName === 'passport') {
    documentType = PASSPORT;
  } else if (documentTypeName === 'drivelic') {
    documentType = DRIVER_LICENSE;
  }

  yield put(change('VisitorArrivalForm', 'visitorFullName', fullName));
  yield put(change('VisitorArrivalForm', 'documentDetails', data.number));
  yield put(change('VisitorArrivalForm', 'documentType', documentType));
}

function* changeReceptionRequestsFormValuesSaga(data) {
  const fullName = getFullName(data);
  const documentTypeName = (data.type || '').toLowerCase();

  let documentType = null;
  if (documentTypeName === 'passport') {
    documentType = PASSPORT;
  } else if (documentTypeName === 'drivelic') {
    documentType = DRIVER_LICENSE;
  }

  yield put(change('ReceptionRequestsForm', 'visitorFullName', fullName));
  yield put(change('ReceptionRequestsForm', 'documentDetails', data.number));
  yield put(change('ReceptionRequestsForm', 'documentType', documentType));
}

function* searchRequestSaga(surname) {
  const response = yield call(api.loadPage, 'requests', {
    filterBy: `(visitorFullName.StartsWith("${surname} ") || ` +
      `visitorFullName.EndsWith(" ${surname}") || ` +
      `visitorFullName.Contains("[ ]${surname}[ ]") || ` +
      `visitorFullName = "${surname}" || ` +
      `transliteratedVisitorFullName.StartsWith("${surname} ") || ` +
      `transliteratedVisitorFullName.EndsWith(" ${surname}") || ` +
      `transliteratedVisitorFullName.Contains("[ ]${surname}[ ]") || ` +
      `transliteratedVisitorFullName = "${surname}") && ` +
      `(status.id = "${CREATED}" || status.id = "${ACCEPTED}")`,
  });
  return response.data;
}

function* savePhotoSaga(advancedForm, fileName) {
  const accountId = yield select(makeSelectAccountId());

  // Исключение для Воздвиженка центр ТОК(14691)
  if (accountId === '14691') {
    return;
  }

  const result = yield call(getFileContent, fileName);
  const decodedContent = atob(result.data);
  let n = decodedContent.length;
  const uInt8Array = new Uint8Array(n);
  while (n--) { // eslint-disable-line no-plusplus
    uInt8Array[n] = decodedContent.charCodeAt(n);
  }
  const file = new File([uInt8Array], `${fileName}.jpg`);
  yield put(saveFile(file, { advancedForm, field: 'passportPhoto' }));
}

function getFileContent(fileId) {
  return axios.get(`${config.scannerServiceUrl}/DocumentScaner/GetFile`, {
    params: { id: fileId },
  });
}
