/*
 *
 * AssignedCompleteFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { ASSIGNED_COMPLETE_FORM, ASSIGNED_COMPLETE_FORM_DIALOG } from './constants';

function AssignedCompleteFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={ASSIGNED_COMPLETE_FORM_DIALOG}
      formName={ASSIGNED_COMPLETE_FORM}
      advancedForm={form}
      editModeTitle="Отправка отчета"
    />
  );
}

AssignedCompleteFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default AssignedCompleteFormDialog;
