/**
 *
 * getTasksPage Function
 *
 * 
 */
import React from 'react';
import TasksPage from './TasksPage';

export default function getTasksPage( props) {
      return (
        <TasksPage {  ...props}    />
      );
  }

