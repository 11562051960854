/*
 *
 *  ReceptionRejectForm14419
 *
 */

import ReceptionRejectForm from 'pages/Requests/ReceptionView/ReceptionRejectForm';
import { REJECTED } from "domain/Data/RequestStatus/constants";
import { ON_VISIT, SERVICE, CARRYING_OUT_WORKS } from "domain/Data/RequestType/constants";
import { validateCarNumber } from "utils/validators";
import { visitorFullNameLabel } from 'pages/Requests/common/fields';


export class ReceptionRejectForm14419 extends ReceptionRejectForm {

    getFields() {
        const currentDate = new Date();
        return [
            {
                name: 'status',
                type: 'select',
                label: 'Статус',
                entityName: 'requestStatuses',
                visible: () => false,
                calculatedValue: () => REJECTED,
            },
            {
                name: 'number',
                label: '№',
                disabled: true,
            },
            {
                name: 'visitDate',
                type: 'date',
                label: 'Дата',
                required: true,
                visible: (values) => values.type !== SERVICE,
                initialValue: currentDate,
                min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
                    new Date(initialValues.visitDate) : currentDate,
                disabled: true,
                rowBreakBefore: true,
            },
            {
                name: 'company',
                type: 'select',
                label: 'Арендатор',
                entityName: 'companies',
                orderBy: 'name',
                rowBreakBefore: true,
                rowBreakAfter: true,
                disabled: true,
                numberInQueueFromEnd: 1,
            },
            {
                name: 'carNumber',
                label: '№ автомобиля',
                visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT,
                disabled: true,
                normalize: (value) => value ? value.replace(' ', '') : value,
                validate: validateCarNumber,
            },
            {
                name: 'carModelText',
                label: 'Марка автомобиля',
                visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT,
                disabled: true,
            },
            {
                name: 'parking',
                type: 'select',
                label: 'Парковка',
                entityName: 'parkings',
                visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT && values.type !== CARRYING_OUT_WORKS,
                cached: false,
                calculatedValue: (values) => values.parking,
                disabled: true,
            },
            {
                name: 'parkingPlace',
                label: '№ места',
                visible: (values) => values.type !== SERVICE && values.type !== ON_VISIT && values.type !== CARRYING_OUT_WORKS,
                disabled: true,
                initialValue: () => null,
            },
            {
                name: 'whatHappened',
                label: 'Наименование работ',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                disabled: true,
            },
            {
                name: 'companyName',
                label: 'Подрядчик',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                disabled: true,
            },
            {
                name: 'visitorFullName',
                label: visitorFullNameLabel,
                type: 'dadata',
                dadataType: 'NAME',
                dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
                autoFocus: (currentValues, initialValues, mode) => mode === 'add',
                visible: (values) => values.type === ON_VISIT,
                required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
                disabled: true,
                rowBreakBefore: true,
            },
            {
                name: 'note',
                label: 'Примечание',
                grid: { sm: 12 },
                visible: true,
            },
            {
                name: 'managementCompanyComment',
                label: 'Комментарий УК',
                required: true,
                grid: { sm: 12 },
            },
        ];
    }
}
