/*
 *
 * ReceptionView constants
 *
 */

export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_SEND_TO_AGREMENT_ACTION = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/EXECUTE_SEND_TO_AGREMENT_ACTION';
export const EXECUTE_SEND_TO_PERFORMER = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/EXECUTE_SEND_TO_PERFORMER';

export const ACCEPT_REQUESTS = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/ACCEPT_REQUESTS';
export const ACCEPT_REQUESTS_SUCCESS = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/ACCEPT_REQUESTS_SUCCESS';
export const ACCEPT_REQUESTS_ERROR = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/ACCEPT_REQUESTS_ERROR';

export const EXECUTE_CLOSE_ACTION = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_REJECT_ACTION = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/EXECUTE_REJECT_ACTION';

export const REJECT_REQUESTS = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/REJECT_REQUESTS';
export const REJECT_REQUESTS_SUCCESS = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/REJECT_REQUESTS_SUCCESS';
export const REJECT_REQUESTS_ERROR = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/REJECT_REQUESTS_ERROR';

export const KHIMKI_RECEPTION_ACCEPT_FORM = 'KhimkiReceptionAcceptForm';
export const KHIMKI_RECEPTION_ACCEPT_FORM_DIALOG = 'KhimkiReceptionAcceptFormDialog';

export const RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM = 'ReceptionSendToAgreementByTenantForm';
export const RECEPTION_SEND_TO_AGREMENT_BY_TENANT_FORM_DIALOG = 'ReceptionSendToAgreementByTenantFormDialog';

export const SEND_EMAIL = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/SEND_EMAIL_ERROR';

export const KHIMKI_RECEPTION_UPDATE_FORM_FIELD = 'claris-vnext/custom/14326/RequestsPage/ReceptionView/KHIMKI_RECEPTION_UPDATE_FORM_FIELD';
export const LOAD_DIALOG_DATA = 'claris-vnext/RequestsPage/ReceptionView/LOAD_DIALOG_DATA';