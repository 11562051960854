/*
 *
 * ServiceRequestsManagerAcceptForm14326
 *
 */

import { ServiceRequestsManagerAcceptForm } from 'pages/Requests/ServiceRequestsManagerView';
import { ACCEPTED, SEND_TO_PERFORMER } from 'domain/Data/RequestStatus/constants';
import { SERVICE } from 'domain/Data/RequestType/constants';

export class ServiceRequestsManagerAcceptForm14326 extends ServiceRequestsManagerAcceptForm {
    getFields() {
        const fields = super.getFields();

        const managementCompanyCommentIdx = fields.findIndex((field) => field.name === 'managementCompanyComment');
        fields[managementCompanyCommentIdx].label = 'Комментарий Администрации ХБП';
        fields[managementCompanyCommentIdx].required = true;

        const requestPriorityIndex = fields.findIndex((field) => field.name === 'requestPriority');
        fields[requestPriorityIndex].visible = false;

        const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
        fields[assigneeIndex].visibleOptions = (currentValues, initialValue, mode, user, entities) => {
            const assignee = [];
            entities.get('employees').map((item) => { // eslint-disable-line
                if (item.isPerformer) assignee.push(item.id);
            });
            return assignee;
        };
        fields[assigneeIndex].calculatedValue = (values, mode) => {
            if (mode !== 'copy') return values.assignee;
            return null;
        }

        const statusIndex = fields.findIndex((field) => field.name === 'status');
        fields[statusIndex].calculatedValue = (values) => values.type === SERVICE ? SEND_TO_PERFORMER : ACCEPTED

        return fields;
    }
}
