/**
 *
 * MultiAutocomplete actions
 *
 */

import {
  CLEAR_OPTIONS,
  INITIALIZATION,
  LOAD_OPTIONS,
  IS_INITIALIZED,
} from './constants';

export function clearOptions(advancedForm, field) {
  return {
    type: CLEAR_OPTIONS,
    meta: { advancedForm, field },
  };
}

export function loadOptions(advancedForm, field, searchQuery) {
  return {
    type: LOAD_OPTIONS,
    meta: { advancedForm, field },
    payload: { searchQuery },
  };
}

export function initialization(advancedForm, fieldName, { mode, entityName, record }) {
  return {
    type: INITIALIZATION,
    meta: { entityName, fieldName, advancedForm },
    payload: { mode, record },
  };
}

export function changeIsInitialized(advancedForm, field) {
  return {
    type: IS_INITIALIZED,
    meta: { advancedForm, field },
  };
}
