import { createSelector } from 'reselect';

const makeSelectDomain = () => (state) => state.get('login');

const makeSelectMessage = () => createSelector(
          makeSelectDomain(),
          (domainState) => (domainState.get('message') ? domainState.get('message') : null)
);

const makeSelectTypeMessage = () => createSelector(
          makeSelectDomain(),
          (domainState) => (domainState.get('typeMessage') ? domainState.get('typeMessage') : null)
);

export default makeSelectDomain;
export {
          makeSelectMessage,
          makeSelectTypeMessage,
};