/**
*
* DataTableToolbar
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Settings from '@material-ui/icons/SettingsSharp';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Icon from '@material-ui/core/Icon';

import { GLOBAL_USER_KEY } from 'global-constants'
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import ActionButton from 'components/ActionButton';
import Search from 'components/Search';
import ToolbarButton from 'components/ToolBarButton';
import FilterListDialog from 'containers/AdvancedDataTable/FilterListDialog/FilterListDialog';
import TableCustomSettings from 'containers/AdvancedDataTable/TableSettingsDialog';

import { getFilterMessages } from 'containers/AdvancedDataTable/DxGridLocalizationMessages';
import { getValueFromLocalStorage, setValueToLocalStorage } from 'utils/localStorage';
import ColumnChoiser from './ColumnChoiser';
import { SETTINGS_CONFIG_NAME } from 'containers/AdvancedDataTable/constants';
import { account } from 'domain/typeConstants/accounts';


const styleSheet = () => ({
  root: {
    display: 'flex',
    padding: '0px 20px',
  },
  rootDense: {
    minHeight: 'auto',
  },
});

class DataTableToolbar extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);

    this.state = {
      isOpenFilterDialog: false,
      isOpenSettingsDialog: false,
      isOpenColumnChoiserDialog: false,
      columnChoiserPositionTop: null,
      columnChoiserPositionRight: null,
    };
  }


  createActionButtonClickHandler = (name) => () => {
    const { onActionButtonClick } = this.props;
    if (onActionButtonClick) onActionButtonClick(name);
  }

  handleClickSearch = (value) => {
    const { onSearch } = this.props;
    if (onSearch) onSearch(value);
  }

  handleClickClearSearch = () => {
    const { onClearSearch } = this.props;
    if (onClearSearch) onClearSearch();
  }

  handleClickUpdate = () => {
    const { onUpdateSearch } = this.props;
    if (onUpdateSearch) onUpdateSearch();
  };


  handleClickFilterButton = () => this.setState({ isOpenFilterDialog: true });

  handleClickFilterOffButton = () => {
    const { onClearFilters, gridFilters } = this.props;
    const clearGridFilters = gridFilters.map(f => f.value !== '' ? { ...f, value: '' } : f)
    if (onClearFilters) { onClearFilters(clearGridFilters) }
  };


  checkDisableFilterOffButton = () => {
    const { gridFilters } = this.props;
    const result = gridFilters.find(filter => filter.value !== '')
    if (result) return false
    return true;
  }

  handleClickFilterCancel = () => {
    this.setState({ isOpenFilterDialog: false });
  };

  handleClickFilterAccept = (gridFilters) => {
    this.setState({ isOpenFilterDialog: false });
    const { onFilterAccept } = this.props
    if (onFilterAccept) onFilterAccept(gridFilters)
  };


  // --------Column Choiser----------------
  handleClickColumnChoiserButton = (event) => {
    event.preventDefault();
    const containerName = `tableHeader-${this.props.mode || 'parent'}`
    const tableHeaderRect = document.getElementById(containerName).getBoundingClientRect();
    this.setState({
      isOpenColumnChoiserDialog: true,
      columnChoiserPositionTop: tableHeaderRect.bottom || 155,
      columnChoiserPositionRight: tableHeaderRect.right
    });
  };

  handleCloseColumnChoiser = () => {
    this.setState({ isOpenColumnChoiserDialog: false });
  };

  getColumnChoiserItems = () => {
    const { columnOrder, columns, columnVisibilityNames } = this.props
    const columnChoiserItems = []
    columnOrder.forEach(o => {
      const column = columns.find(col => col.name === o)
      if (column) {
        columnChoiserItems.push({
          name: column.name,
          title: column.title,
          visible: !columnVisibilityNames.includes(column.name)
        })
      }
    });
    return columnChoiserItems
  }

  handleColumnChoiserClick = (column) => {
    const { accessKey, onColumnVisibleChange, columnVisibilityNames } = this.props
    const localStorageConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    const settingsConfig = localStorageConfig[accessKey]
    let _hiddenColumns = [];
    if (columnVisibilityNames.includes(column.name)) {
      _hiddenColumns = columnVisibilityNames.filter(c => c !== column.name)
    } else _hiddenColumns = [...columnVisibilityNames, column.name]
    settingsConfig[settings.COLUMN_VISIBILITY].value = _hiddenColumns
    localStorageConfig[accessKey] = settingsConfig
    setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageConfig);

    if (onColumnVisibleChange) {
      onColumnVisibleChange(_hiddenColumns)
    }
  };


  //------------------------Settings------------------
  handleClickSettingsButton = () => {
    this.setState({ isOpenSettingsDialog: true });
  };

  handleClickSettingsCancel = () => {
    this.setState({ isOpenSettingsDialog: false });
  };

  handleClickSettingsReset = () => {
    const { onChangeResetSettings } = this.props;
    if (onChangeResetSettings) onChangeResetSettings();
    this.setState({ isOpenSettingsDialog: false });
  };

  handleClickSettingsSave = (parameters) => () => {
    const { onChangeSettingsConfig, settingsConfig, updateTerminalAdvancedInput } = this.props;
    const settingsConfigKeys = Object.keys(settingsConfig);
    const settingsKeys = Object.keys(parameters);
    const tableTypeProperty = 'tableType';
    const targetConfig = Object.assign({}, settingsConfig);

    targetConfig.tableType = parameters[settings.VIRTUAL_TABLE] ? settings.VIRTUAL_TABLE : settings.PAGING_TABLE;

    if (settingsConfigKeys) {
      settingsConfigKeys.map((settingsConfigKey) => {
        if (settingsKeys.includes(settingsConfigKey) && settingsConfigKey !== tableTypeProperty) {
          targetConfig[settingsConfigKey].enabled = parameters[settingsConfigKey];
        }
        return null;
      });
    }

    onChangeSettingsConfig(targetConfig);
    this.setState({ isOpenSettingsDialog: false });

    const user = getValueFromLocalStorage(GLOBAL_USER_KEY)
    if (user.isAutoLoading !== parameters.signalrUpdate) {
      const { saveAutoUpdate } = this.props;
      if (saveAutoUpdate) saveAutoUpdate(user.id, parameters.signalrUpdate)
    }

    if (user.accountId === account.ZHUKOV) {
      const terminalAdvancedInput = getValueFromLocalStorage(settings.TERMINAL_ADVANCED_INPUT)
      if (terminalAdvancedInput !== parameters.terminalAdvancedInput) {
        if (updateTerminalAdvancedInput) {
          updateTerminalAdvancedInput({ terminalAdvancedInput: parameters.terminalAdvancedInput })
          setValueToLocalStorage(settings.TERMINAL_ADVANCED_INPUT, parameters.terminalAdvancedInput)
        }
      }
    }
  };
  //-------------------------------------------------------

  render() {
    const { classes, actionButtons, width, tableFeatures, accessKey, localFilters,
      gridFilters, clearFilterDialog, filterDialog, savedFilters, configName,
      settingsConfig, checkTableFeature, columnOrder, columns, mode, user, viewMode,
    } = this.props;

    const {
      isOpenFilterDialog,
      isOpenColumnChoiserDialog,
      columnChoiserPositionTop,
      columnChoiserPositionRight,
    } = this.state;

    return (
      <Toolbar
        className={classNames(classes.root, classes.rootDense)}
        disableGutters
        id="dataTableToolbar"
        style={{ width: width, backgroundColor: "#e5e8e67a" }}
      >
        <Grid container style={{ justifyContent: "space-between", flexWrap: "nowrap" }}>
          <Grid item style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "flex-start"
          }}>
            {
              actionButtons && actionButtons.map(({ name, label, icon, disabled }) =>
              (<ActionButton
                key={name}
                label={label}
                icon={icon}
                disabled={disabled}
                onClick={this.createActionButtonClickHandler(name)}
                viewMode={viewMode}
              />)
              )
            }
          </Grid>

          <Grid item style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
            {
              settingsConfig && settingsConfig[settings.FILTERING].enabled &&
              <Search
                onSearch={this.handleClickSearch}
                onClearSearch={this.handleClickClearSearch}
              />
            }
            {
              settingsConfig && settingsConfig[settings.UPDATE_BUTTON].enabled &&
              <ToolbarButton
                onClick={this.handleClickUpdate}
                tooltipTitle={'Обновить'}
                name="update"
                icon={<AutorenewIcon />}
              />
            }
            {
              settingsConfig && settingsConfig[settings.COLUMN_VISIBILITY].enabled &&
              <>
                <ToolbarButton
                  onClick={(event) => this.handleClickColumnChoiserButton(event)}
                  tooltipTitle={'Настроить видимость колонок'}
                  name="columnChoiser"
                  icon={<VisibilityOff />}
                />
                <ColumnChoiser
                  open={isOpenColumnChoiserDialog}
                  anchorElTopPosition={columnChoiserPositionTop}
                  anchorElRightPosition={columnChoiserPositionRight}
                  onClose={this.handleCloseColumnChoiser}
                  onColumnClick={this.handleColumnChoiserClick}
                  columns={columns}
                  columnOrder={columnOrder}
                  settingsConfig={settingsConfig}
                  accessKey={accessKey}
                  items={this.getColumnChoiserItems()}
                />
              </>
            }

            {settingsConfig && settingsConfig[settings.FILTER_BUTTON].enabled &&
              <>
                <ToolbarButton
                  onClick={this.handleClickFilterButton}
                  tooltipTitle={'Фильтр'}
                  name="filters"
                  icon={<Icon>filter_list</Icon>}
                />
                <ToolbarButton
                  onClick={this.handleClickFilterOffButton}
                  tooltipTitle={'Очистить'}
                  name="filtersOff"
                  icon={<Icon>filter_list_off</Icon>}
                  disabled={this.checkDisableFilterOffButton()}
                />
                <FilterListDialog
                  open={isOpenFilterDialog}
                  onCancel={this.handleClickFilterCancel}
                  onSubmit={this.handleClickFilterAccept}
                  localFilters={localFilters}
                  gridFilters={gridFilters}
                  messages={getFilterMessages}
                  accessKey={accessKey}
                  filterDialog={filterDialog}
                  clearFilterDialog={clearFilterDialog}
                  savedFilters={savedFilters}
                  configName={configName}
                />
              </>
            }
            {
              settingsConfig && settingsConfig[settings.SETTING_BUTTON].enabled &&
              <>
                <ToolbarButton
                  onClick={this.handleClickSettingsButton}
                  tooltipTitle={'Настройки'}
                  name="settings"
                  icon={<Settings />}
                />
                <TableCustomSettings
                  onClickCancel={this.handleClickSettingsCancel}
                  onClickReset={this.handleClickSettingsReset}
                  onClickSave={this.handleClickSettingsSave}
                  open={this.state.isOpenSettingsDialog}
                  settingsConfig={settingsConfig}
                  tableFeatures={tableFeatures}
                  checkTableFeature={checkTableFeature}
                  mode={mode}
                  user={user}
                />
              </>
            }
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

DataTableToolbar.propTypes = {
  classes: PropTypes.object,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
  dense: PropTypes.bool,
  onActionButtonClick: PropTypes.func,
  onRequestSearch: PropTypes.func,
  onRequestClearSearch: PropTypes.func,
  onRequestRefresh: PropTypes.func,
};

export default withStyles(styleSheet)(DataTableToolbar);
