/**
 *
 * Data reducer
 *
 *
 */


import { OrderedMap, Map } from 'immutable';
import { normalize } from 'normalizr';
import {
  ADD_ENTITIES_STATE,
  CHANGE_ENTITIES_STATE,
  CHANGE_ENTITIES_TOTAL_COUNT,
  INCREASE_ENTITIES_TOTAL_COUNT,
  CLEAR_ENTITY,
  CHANGE_DIALOG_DATA_STATE,
} from './constants';
import { ADD_ENTITY_TO_STATE } from 'containers/AdvancedDataTable/FilterListDialog/SelectTextFilter/constants';
import * as recordTypes from './recordTypes';
import { mapEntityNameToSchema, mapEntityNameToRecord } from './utils';


const makeRecordsFromArray = (RecordType, array) =>
  array.reduce((acc, entity) =>
    acc.set(entity.id, new RecordType(entity)),
    new OrderedMap({})
  );

const makeInitialMetaState = () => new Map({ totalCount: 0 });

const initialState = new Map({
  entities: new Map({
    accessAreas: makeRecordsFromArray(recordTypes.AccessArea, []),
    accessCards: makeRecordsFromArray(recordTypes.AccessCard, []),
    accessLevels: makeRecordsFromArray(recordTypes.AccessLevel, []),
    actionCards: makeRecordsFromArray(recordTypes.ActionCard, []),
    agreementHistories: makeRecordsFromArray(recordTypes.AgreementHistory, []),
    agreementSchemes: makeRecordsFromArray(recordTypes.AgreementScheme, []),
    agreementStates: makeRecordsFromArray(recordTypes.AgreementState, []),
    agreementSteps: makeRecordsFromArray(recordTypes.AgreementStep, []),
    attachments: makeRecordsFromArray(recordTypes.Attachment, []),
    blackList: makeRecordsFromArray(recordTypes.BlackList, []),
    carModels: makeRecordsFromArray(recordTypes.CarModel, []),
    comments: makeRecordsFromArray(recordTypes.Comment, []),
    companies: makeRecordsFromArray(recordTypes.Company, []),
    contracts: makeRecordsFromArray(recordTypes.Contract, []),
    contractTypes: makeRecordsFromArray(recordTypes.ContractType, []),
    contractorStatuses: makeRecordsFromArray(recordTypes.ContractorStatus, []),
    contractorTypes: makeRecordsFromArray(recordTypes.ContractorType, []),
    departments: makeRecordsFromArray(recordTypes.Department, []),
    documentCategories: makeRecordsFromArray(recordTypes.DocumentCategory, []),
    documentKinds: makeRecordsFromArray(recordTypes.DocumentKind, []),
    documents: makeRecordsFromArray(recordTypes.Document, []),
    documentStates: makeRecordsFromArray(recordTypes.DocumentState, []),
    documentTypes: makeRecordsFromArray(recordTypes.DocumentType, []),
    employees: makeRecordsFromArray(recordTypes.Employee, []),
    files: makeRecordsFromArray(recordTypes.File, []),
    housings: makeRecordsFromArray(recordTypes.Housing, []),
    issueReasons: makeRecordsFromArray(recordTypes.IssueReason, []),
    khimkiRequestTypes: makeRecordsFromArray(recordTypes.KhimkiRequestType, []),
    managers: makeRecordsFromArray(recordTypes.Manager, []),
    materialCommodityValueTypes: makeRecordsFromArray(recordTypes.MaterialCommodityValueType, []),
    notificationCategories: makeRecordsFromArray(recordTypes.NotificationCategory, []),
    notifications: makeRecordsFromArray(recordTypes.Notification, []),
    notificationTypes: makeRecordsFromArray(recordTypes.NotificationType, []),
    objectStructures: makeRecordsFromArray(recordTypes.ObjectStructure, []),
    parkings: makeRecordsFromArray(recordTypes.Parking, []),
    parkingTypes: makeRecordsFromArray(recordTypes.ParkingType, []),
    passTypes: makeRecordsFromArray(recordTypes.PassType, []),
    positions: makeRecordsFromArray(recordTypes.Position, []),
    requests: makeRecordsFromArray(recordTypes.Request, []),
    requestStatuses: makeRecordsFromArray(recordTypes.RequestStatus, []),
    requestTypes: makeRecordsFromArray(recordTypes.RequestType, []),
    requestPriorities: makeRecordsFromArray(recordTypes.RequestPriority, []),
    reservationTimes: makeRecordsFromArray(recordTypes.ReservationTime, []),
    roles: makeRecordsFromArray(recordTypes.Role, []),
    services: makeRecordsFromArray(recordTypes.Service, []),
    times: makeRecordsFromArray(recordTypes.Time, []),
    users: makeRecordsFromArray(recordTypes.User, []),
    vehicleTypes: makeRecordsFromArray(recordTypes.VehicleType, []),
    visitorLogs: makeRecordsFromArray(recordTypes.VisitorLog, []),
    visitTimes: makeRecordsFromArray(recordTypes.VisitTime, []),
    vozdvizhenkaAccessLevels: makeRecordsFromArray(recordTypes.VozdvizhenkaAccessLevel, []),
    workingTimes: makeRecordsFromArray(recordTypes.WorkingTime, []),
    workTypes: makeRecordsFromArray(recordTypes.WorkType, []),
    contacts: makeRecordsFromArray(recordTypes.Contact, []),
    workingHours: makeRecordsFromArray(recordTypes.WorkingHour, []),
    jobDoings: makeRecordsFromArray(recordTypes.JobDoing, []),
    projects: makeRecordsFromArray(recordTypes.Project, []),
    estimateItems: makeRecordsFromArray(recordTypes.EstimateItem, []),
    tasks: makeRecordsFromArray(recordTypes.RequestTask, []),
    number: makeRecordsFromArray(recordTypes.TaskNumber, []),
    finishDate: makeRecordsFromArray(recordTypes.TaskFinishDate, []),
    registrationDate: makeRecordsFromArray(recordTypes.TaskRegistrationDate, []),
    // description: makeRecordsFromArray(recordTypes.TaskDescription, []),
    // author: makeRecordsFromArray(recordTypes.TaskAuthor, []),
    responsible: makeRecordsFromArray(recordTypes.TaskResponsible, []),
    status: makeRecordsFromArray(recordTypes.TaskStatus, []),
    taskType: makeRecordsFromArray(recordTypes.TaskStatus, []),
    company: makeRecordsFromArray(recordTypes.TaskCompany, []),
    file: makeRecordsFromArray(recordTypes.TaskFile, []),
    taskStatuses: makeRecordsFromArray(recordTypes.TaskStatus, []),
    name: makeRecordsFromArray(recordTypes.TaskName, []),
    taskChangeDate: makeRecordsFromArray(recordTypes.TaskChangeDate, []),
    lastAction: makeRecordsFromArray(recordTypes.LastAction, []),
    type: makeRecordsFromArray(recordTypes.TaskType, []),
    taskTypes: makeRecordsFromArray(recordTypes.TaskType, []),

    works: makeRecordsFromArray(recordTypes.Works, []),
    runDate: makeRecordsFromArray(recordTypes.WorkRunDate, []),
    task: makeRecordsFromArray(recordTypes.WorkTask, []),
    description: makeRecordsFromArray(recordTypes.WorkDescription, []),
    author: makeRecordsFromArray(recordTypes.WorkAuthor, []),
    request: makeRecordsFromArray(recordTypes.WorkRequestTask, []),

    goodsAndServices: makeRecordsFromArray(recordTypes.GoodsAndServices, []),
    documentPositions: makeRecordsFromArray(recordTypes.DocumentPositions, []),
    documentPositionStates: makeRecordsFromArray(recordTypes.DocumentPositionStates, []),
    taskChanges: makeRecordsFromArray(recordTypes.TaskChange, []),
    requestChanges: makeRecordsFromArray(recordTypes.RequestChanges, []),
    documentChanges: makeRecordsFromArray(recordTypes.DocumentChanges, []),

  }),
  meta: new Map({
    accessAreas: makeInitialMetaState(),
    accessCards: makeInitialMetaState(),
    accessLevels: makeInitialMetaState(),
    actionCards: makeInitialMetaState(),
    agreementHistories: makeInitialMetaState(),
    agreementSchemes: makeInitialMetaState(),
    agreementStates: makeInitialMetaState(),
    agreementSteps: makeInitialMetaState(),
    attachments: makeInitialMetaState(),
    blackList: makeInitialMetaState(),
    carModels: makeInitialMetaState(),
    comments: makeInitialMetaState(),
    companies: makeInitialMetaState(),
    contracts: makeInitialMetaState(),
    contractTypes: makeInitialMetaState(),
    contractorStatuses: makeInitialMetaState(),
    contractorTypes: makeInitialMetaState(),
    departments: makeInitialMetaState(),
    documentCategories: makeInitialMetaState(),
    documentKinds: makeInitialMetaState(),
    documents: makeInitialMetaState(),
    documentStates: makeInitialMetaState(),
    documentTypes: makeInitialMetaState(),
    employees: makeInitialMetaState(),
    files: makeInitialMetaState(),
    housings: makeInitialMetaState(),
    issueReasons: makeInitialMetaState(),
    khimkiRequestTypes: makeInitialMetaState(),
    managers: makeInitialMetaState(),
    materialCommodityValueTypes: makeInitialMetaState(),
    notificationCategories: makeInitialMetaState(),
    notifications: makeInitialMetaState(),
    notificationTypes: makeInitialMetaState(),
    objectStructures: makeInitialMetaState(),
    parkings: makeInitialMetaState(),
    parkingTypes: makeInitialMetaState(),
    passTypes: makeInitialMetaState(),
    positions: makeInitialMetaState(),
    requests: makeInitialMetaState(),
    requestStatuses: makeInitialMetaState(),
    requestTypes: makeInitialMetaState(),
    requestPriorities: makeInitialMetaState(),
    reservationTimes: makeInitialMetaState(),
    roles: makeInitialMetaState(),
    services: makeInitialMetaState(),
    times: makeInitialMetaState(),
    users: makeInitialMetaState(),
    vehicleTypes: makeInitialMetaState(),
    visitorLogs: makeInitialMetaState(),
    visitTimes: makeInitialMetaState(),
    vozdvizhenkaAccessLevels: makeInitialMetaState(),
    workingTimes: makeInitialMetaState(),
    workTypes: makeInitialMetaState(),
    contacts: makeInitialMetaState(),
    workingHours: makeInitialMetaState(),
    jobDoings: makeInitialMetaState(),
    projects: makeInitialMetaState(),
    estimateItems: makeInitialMetaState(),
    tasks: makeInitialMetaState(),
    number: makeInitialMetaState(),
    finishDate: makeInitialMetaState(),
    registrationDate: makeInitialMetaState(),
    description: makeInitialMetaState(),
    author: makeInitialMetaState(),
    responsible: makeInitialMetaState(),
    status: makeInitialMetaState(),
    company: makeInitialMetaState(),
    file: makeInitialMetaState(),
    taskStatuses: makeInitialMetaState(),
    taskChangeDate: makeInitialMetaState(),
    lastAction: makeInitialMetaState(),
    executors: makeInitialMetaState(),
    type: makeInitialMetaState(),
    taskTypes: makeInitialMetaState(),
    works: makeInitialMetaState(),
    runDate: makeInitialMetaState(),
    task: makeInitialMetaState(),
    request: makeInitialMetaState(),
    goodsAndServices: makeInitialMetaState(),
    documentPositions: makeInitialMetaState(),
    documentPositionStates: makeInitialMetaState(),
    taskChanges: makeInitialMetaState(),
    requestChanges: makeInitialMetaState(),
    documentChanges: makeInitialMetaState(),
  }),
});

function dataReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_ENTITIES_STATE:
    case CHANGE_ENTITIES_STATE: {
      const schema = mapEntityNameToSchema(payload.entityName);
      const normalized = normalize(payload.entities, [schema]);
      return mergeNormalizedEntities(state, normalized.entities);
    }

    case CHANGE_ENTITIES_TOTAL_COUNT:
      return state.setIn(['meta', payload.entityName, 'totalCount'], payload.totalCount);

    case INCREASE_ENTITIES_TOTAL_COUNT: {
      const newTotalCount = state.getIn(['meta', payload.entityName, 'totalCount']) + payload.value;
      return state.setIn(['meta', payload.entityName, 'totalCount'], newTotalCount);
    }

    case CLEAR_ENTITY: {
      return state.setIn(['entities', payload.entityName], {});
    }

    case CHANGE_DIALOG_DATA_STATE: {
      return state.setIn(['dialog', payload.entityName], payload.entities);
    }

    case ADD_ENTITY_TO_STATE: {
      return state.setIn(['filter', payload.entityName], payload.entity);
    }

    default:
      return state;
  }
}

const mergeNormalizedEntities = (state, normalizedEntities) =>
  Object.keys(normalizedEntities).reduce(
    (acc, entityName) => {
      const RecordType = mapEntityNameToRecord(entityName);
      const entities = normalizedEntities[entityName];
      const records = makeRecordsFromArray(RecordType, Object.values(entities));
      return acc.mergeIn(['entities', entityName], records);
    },
    state
  );

export default dataReducer;
