/*
 *
 * EmailSubscriptionButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';


const styleSheet = () => ({
  root: {
    width: 32,
    height: 32,
    minHeight: 32,
    position: 'fixed',
    bottom: 20,
    left: 72,
    backgroundColor: '#e54b4d',
    zIndex: 2147483000,
  },
  rootSubscribed: {
    opacity: .5,
  },
  rootUnsubscribed: {
    opacity: 1,
  },
  tooltip: {
    fontSize: 14,
    backgroundColor: 'black'
  }
});

class EmailSubscriptionButton extends React.PureComponent {
  render() {
    const { classes, onClick, isSubscribed } = this.props;

    return (
      <Tooltip
        title={isSubscribed ? "Вы подписаны на email-оповещения" : "Подписаться на email-оповещения" }
        placement="right"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          variant="fab"
          mini
          color="secondary"
          className={classnames({ 
            [classes.root]: true,
            [classes.rootSubscribed]: isSubscribed,
            [classes.rootUnsubscribed]: !isSubscribed
          })}
          onClick={onClick}
        >
          <MailIcon />
        </Button>
      </Tooltip>
    );
  }
}

export default withStyles(styleSheet)(EmailSubscriptionButton);