/**
 *
 * AdministratorBC Requests DxGrid 14366
 *
 */

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_MAKING_AGREEMENT_BC,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
} from 'domain/Data/Filters/constants';
import { requestAction } from 'domain/typeConstants/action';
import { acceptActionButton, rejectActionButton } from 'pages/Requests/common/actionButton';
import { acceptActionItem, rejectActionItem } from 'pages/Requests/common/actionMenu';
import { requestColumnSizes } from 'domain/typeConstants/table';
import { ReceptionDxGrid14366 } from '../ReceptionView';
import { GLOBAL_USER_KEY } from 'global-constants';
import { UK_TSVETNOY } from 'custom/14366/domain/Data/Departments/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';

const isUkTsvetnoy = () => {
  const departmentId = getValueFromLocalStorage(GLOBAL_USER_KEY).departmentId
  return departmentId === UK_TSVETNOY ? true : false;
}


export class AdministratorBCRequestsDxGrid14366 extends ReceptionDxGrid14366 {
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') filters.push(REQUESTS_FOR_MAKING_AGREEMENT_BC);
    return filters;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    const rejectActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.REJECT);

    actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);
    actionButtons.splice(rejectActionButtonIndex, 1, rejectActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const acceptActionMenuItemIndex = actionMenuItems.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    const rejectActionMenuItemIndex = actionMenuItems.findIndex((actionButton) => actionButton.name === requestAction.REJECT);

    actionMenuItems.splice(acceptActionMenuItemIndex, 1, acceptActionItem);
    actionMenuItems.splice(rejectActionMenuItemIndex, 1, rejectActionItem);

    return actionMenuItems;
  }

  getColumns() {
    const columns = super.getColumns();

    if (isUkTsvetnoy()) {
      columns.push({
        path: 'department',
        name: 'department',
        title: 'Здание',
        getCellValue: (row) => row.department ? row.department.name : undefined,
        width: requestColumnSizes.companyName,
      });
    }
    return columns;
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();
    if (isUkTsvetnoy()) {
      columnFilters.push({ columnName: 'department', value: '' });
    }
    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    if (isUkTsvetnoy()) {
      const companyIndex = columnOrder.findIndex((item) => item === 'company_name');
      columnOrder.splice(companyIndex + 1, 0, 'department');
    }
    return columnOrder;
  }

}
