/*
 *
 * BlackListFormContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectParams } from 'containers/AdvancedFormDialog/selectors'

import { SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG } from './constants';
import ServiceRequestsManagerBlackListForm from './ServiceRequestsManagerBlackListForm';

class ServiceRequestsManagerBlackListFormContainer extends React.PureComponent {
    render() {
        return (
            <ServiceRequestsManagerBlackListForm  {...this.props} />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    params: makeSelectParams(SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG),
});


export default connect(mapStateToProps)(ServiceRequestsManagerBlackListFormContainer);
