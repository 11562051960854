/*
 *
 * ParkingsPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';
import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';
import ParkingsPage from './ParkingsPage';

class ParkingsPageContainer extends React.PureComponent {

  render() {
    const { accountId, userRoles, match: { params }, ...restProps } = this.props;
    const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
    const props = { viewName, userRoles, params, ...restProps, accountId };
    return (
      <ParkingsPage {...props} />
    )
  }
}

ParkingsPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  accountId: PropTypes.string,
  userRoles: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  accountId: makeSelectAccountId(),
  userRoles: makeSelectRoles(),
});

export default connect(mapStateToProps)(ParkingsPageContainer);
