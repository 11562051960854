/*
 *
 * ServiceRequestsManagerDxGrid14691
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';

import {
    AGREED_EMPLOYEE,
    agreedEmployeeColumn,
    agreedEmployeeColumnFilter,
} from '../common/dxGridComponents/columns';
import { requestAction } from "domain/typeConstants/action";
import { acceptActionButton, copyActionButton } from "../common/actionButton";
import { acceptActionItem, copyActionItem } from "../common/actionMenuItem";

export class ServiceRequestsManagerDxGrid14691 extends ServiceRequestsManagerDxGrid {

    getColumns() {
        const columns = super.getColumns();
        columns.push(agreedEmployeeColumn);
        return columns;
    }

    getGridFilters() {
        const gridFilters = super.getGridFilters();
        gridFilters.push(agreedEmployeeColumnFilter);
        return gridFilters;
    }

    getColumnOrder() {
        const columnOrder = super.getColumnOrder();
        columnOrder.push(AGREED_EMPLOYEE);
        return columnOrder;
    }

    getActionButtons() {
        const actionButtons = super.getActionButtons();
        const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
        actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);

        const copyActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.COPY);
        actionButtons.splice(copyActionButtonIndex, 1, copyActionButton);

        return actionButtons;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();
        const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
        actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);

        const copyMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.COPY);
        actionMenuItems.splice(copyMenuItemIndex, 1, copyActionItem);

        return actionMenuItems;
    }
}
