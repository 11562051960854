/*
 *
 * File actions
 *
 */

import {
  DOWNLOAD_FILE,
  SAVE_FILE,
  SAVE_FILE_PROGRESS,
  SAVE_FILE_SUCCESS,
  SAVE_FILE_ERROR,
} from './constants';

export function downloadFile(fileId) {
  return {
    type: DOWNLOAD_FILE,
    payload: { fileId },
  };
}

export function saveFile(file, meta) {
  return {
    type: SAVE_FILE,
    meta,
    payload: { file },
  };
}

export function fileSavingProgress(progress, meta) {
  return {
    type: SAVE_FILE_PROGRESS,
    meta,
    payload: { progress },
  };
}

export function fileSaved(fileId, meta) {
  return {
    type: SAVE_FILE_SUCCESS,
    meta,
    payload: { fileId },
  };
}

export function fileSavingError(error, meta) {
  return {
    type: SAVE_FILE_ERROR,
    meta,
    payload: { error },
  };
}
