/**
 *
 * TaskFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { USER_TASK_TABLE_GRID, USER_TASK_FORM, USER_TASK_FORM_DIALOG } from './constants';
import { TASKS } from 'domain/Data/constants';

function TaskFormDialog({ form, detailContainer }) {
  return (
    <AdvancedFormDialog
      name={USER_TASK_FORM_DIALOG}
      formName={USER_TASK_FORM}
      dataTableName={USER_TASK_TABLE_GRID}
      advancedForm={form}
      addModeTitle="Новая задача"
      editModeTitle="Изменение задачи"
      viewModeTitle="Просмотр задачи"
      copyModeTitle="Копирование задачи"
      navigation={true}
      detailContainer={detailContainer}
      entityName={TASKS}
    />
  );
}

TaskFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default TaskFormDialog;
