/**
 *
 * UnloadingZoneOperatorFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { UNLOADING_ZONE_OPERATOR_REQUESTS_FORM, UNLOADING_ZONE_OPERATOR_REQUESTS_FORM_DIALOG } from './constants';

function UnloadingZoneOperatorFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={UNLOADING_ZONE_OPERATOR_REQUESTS_FORM_DIALOG}
      formName={UNLOADING_ZONE_OPERATOR_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
    />
  );
}

UnloadingZoneOperatorFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default UnloadingZoneOperatorFormDialog;
