/**
 *
 * BlackListFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import {
  BLACK_LIST_FORM,
  BLACK_LIST_FORM_DIALOG,
} from './constants';

function BlackListFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={BLACK_LIST_FORM_DIALOG}
      formName={BLACK_LIST_FORM}
      advancedForm={form}
      addModeTitle="Добавление в черный список"
    />
  );
}

BlackListFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default BlackListFormDialog;
