/*
 *
 * AdministratorBCServiceRequestsAcceptForm14326
 *
 */

import { ServiceRequestsManagerAcceptForm14326 } from '../ServiceRequestsManagerView';

export class AdministratorBCServiceRequestsAcceptForm14326 extends ServiceRequestsManagerAcceptForm14326 {

}
