/**
 *
 * TaskView
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

function TaskView({
  dataTable,
  formDialog,
  viewName,
  viewStyles,
  accountId,
  workformDialog,
}) {
  return (
    <div style={viewStyles.root}>
      {
        <Paper style={viewStyles.dataTable}>
          {
            React.cloneElement(dataTable, { viewName, accountId })
          }
        </Paper>
      }
      {formDialog}
      {workformDialog}
    </div>
  );
}

TaskView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
  workformDialog: PropTypes.node.isRequired,
};

TaskView.defaultProps = {
  viewStyles: {},
};

export default TaskView;
