/**
 *
 * AdministratorBC Requests Form
 *
 */

import { ACCEPTED } from 'domain/Data/RequestStatus/constants';
import ReceptionForm from '../ReceptionView/ReceptionForm';

export class AdministratorBCForm extends ReceptionForm {
    getFields() {
        const fields = super.getFields();

        const statusIndex = fields.findIndex((field) => field.name === 'status');
        fields[statusIndex].calculatedValue = (values, mode) => {
            if (mode === 'add' || mode === 'copy') { return ACCEPTED }
            return values.status
        };

        return fields;
    }
}