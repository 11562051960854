/**
 *
 * RestrictedAdministratorBC Requests DxGrid 14366
 *
 */

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_FOR_MAKING_AGREEMENT_BC,
  ON_CREATE_REQUESTS,
} from 'domain/Data/Filters/constants';
import { requestAction } from 'domain/typeConstants/action';
import { acceptActionButton, rejectActionButton } from 'pages/Requests/common/actionButton';
import { acceptActionItem, rejectActionItem } from 'pages/Requests/common/actionMenu';

import { ReceptionDxGrid14366 } from '../ReceptionView';

export class RestrictedAdministratorBCRequestsDxGrid14366 extends ReceptionDxGrid14366 {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') filters.push(REQUESTS_FOR_MAKING_AGREEMENT_BC);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    return filters;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    const rejectActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.REJECT);

    actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);
    actionButtons.splice(rejectActionButtonIndex, 1, rejectActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const acceptActionMenuItemIndex = actionMenuItems.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    const rejectActionMenuItemIndex = actionMenuItems.findIndex((actionButton) => actionButton.name === requestAction.REJECT);

    actionMenuItems.splice(acceptActionMenuItemIndex, 1, acceptActionItem);
    actionMenuItems.splice(rejectActionMenuItemIndex, 1, rejectActionItem);

    return actionMenuItems;
  }
}
