/*
 *
 * SupplyRequestPositionFormContainer15767
 *
 */

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectParentId} from 'containers/AdvancedFormDialog/selectors'
import * as actionCreators from 'pages/Positions/PositionsView/actions';
import {DOCUMENT_POSITION_FORM_DIALOG} from 'pages/Positions/PositionsView/constants';
import {SupplyRequestPositionForm15767} from './SupplyRequestPositionForm15767';
import {makeSelectCurrentValues} from 'containers/AdvancedForm/selectors';
import {SUPPLY_REQUEST_FORM} from 'pages/Documents/SupplyRequestView/constants';


class SupplyRequestPositionFormContainer15767 extends React.PureComponent {
    render() {
        return (
            <SupplyRequestPositionForm15767  {...this.props} />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    parentId: makeSelectParentId(DOCUMENT_POSITION_FORM_DIALOG),
    supplyRequestFormValues: makeSelectCurrentValues(SUPPLY_REQUEST_FORM)
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyRequestPositionFormContainer15767);

