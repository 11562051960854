/**
 *
 * ReceptionView constants
 *
 */

export const RECEPTION_REQUESTS_TABLE_GRID = 'ReceptionRequestsTableGrid';
export const RECEPTION_REQUESTS_FORM = 'ReceptionRequestsForm';
export const RECEPTION_REQUESTS_FORM_DIALOG = 'ReceptionRequestsFormDialog';
export const REJECT_FORM = 'RejectForm';
export const REJECT_FORM_DIALOG = 'RejectFormDialog';
export const VISITOR_ARRIVAL_FORM = 'VisitorArrivalForm';
export const VISITOR_ARRIVAL_FORM_DIALOG = 'VisitorArrivalFormDialog';
export const BLACK_LIST_FORM = 'BlackListForm';
export const BLACK_LIST_FORM_DIALOG = 'BlackListFormDialog';
export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_ADD_ACTION';
export const EXECUTE_ADD_BLACK_LIST_ITEM_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_ADD_BLACK_LIST_ITEM_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_ARRIVED_GROUP_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_ARRIVED_GROUP_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_FIRED_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_FIRED_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_COPY_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_DEPARTED_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_EDIT_ACTION';
export const EXECUTE_REJECT_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_REJECT_ACTION';
export const EXECUTE_SEND_TO_AGREEMENT_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_SEND_TO_AGREEMENT_ACTION';
export const EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_VIEW_ACTION';
export const HANDLE_PARAMS_ACTION = 'claris-vnext/RequestsPage/ReceptionView/HANDLE_PARAMS_ACTION';
export const EXECUTE_SEND_EMAIL_ACTION = 'claris-vnext/RequestsPage/ReceptionView/EXECUTE_SEND_EMAIL_ACTION';
export const RECEPTION_WORK_FORM_DIALOG = 'ReceptionWorkFormDialog';