/**
 *
 * AssignedDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  IN_PROGRESS_REQUESTS,
  REQUESTS_FOR_SERVICE_DESK_ASSIGNEE,
} from 'domain/Data/Filters/constants';

import {
  addActionButton,
  copyActionButton,
  acceptActionButton,
  rejectActionButton,
  completeActionButton,
} from '../common/actionButton';

import { editActionButton } from './actionButton';

import {
  viewActionItem,
  copyActionItem,
  acceptActionItem,
  rejectActionItem,
  completeActionItem,
} from '../common/actionMenu';

import { editActionItem } from './actionMenu';

import { columnStatusLabels } from '../common/columnStatusLabel';

import { ASSIGNED_REQUESTS_TABLE_GRID } from './constants';

class AssignedDxGrid extends React.PureComponent {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_SERVICE_DESK_ASSIGNEE];
    if (viewName === 'today') filters.push(IN_PROGRESS_REQUESTS);
    return filters;
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Добавлено',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: ['company', 'name'],
        name: 'companyName',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.companyName,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        entityName: 'requestStatuses',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
      },
      {
        path: ['service', 'name'],
        name: 'serviceName',
        getCellValue: (row) => (row.service ? row.service.name : undefined),
        title: 'Сервис',
        width: requestColumnSizes.serviceName,
        styles,
      },
      {
        path: 'whereHappened',
        name: 'whereHappened',
        title: 'Где',
        width: requestColumnSizes.whereHappened,
      },
      {
        path: 'whatHappened',
        name: 'whatHappened',
        title: 'Что сделать',
        width: requestColumnSizes.whatHappened,
      },
      {
        path: ['assignee', 'name'],
        name: 'assigneeName',
        getCellValue: (row) => (row.assignee ? row.assignee.name : undefined),
        title: 'Исполнитель',
        width: requestColumnSizes.assigneeName,
      },
      {
        path: 'executionTerm',
        name: 'executionTerm',
        title: 'Срок исполнения',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'executionDate',
        name: 'executionDate',
        title: 'Исполнение факт',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: ['file', 'name'],
        name: 'fileName',
        getCellValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: requestColumnSizes.fileName,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
      },
      {
        path: ['author', 'name'],
        name: 'authorName',
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: requestColumnSizes.authorName,
      },
    ];
  }

  getColumnOrder() {
    return ['number', 'actualCreationDate', 'companyName', 'statusName', 'serviceName', 'whereHappened', 'whatHappened', 'assigneeName',
      'executionTerm', 'executionDate', 'fileName', 'note', 'authorName'
    ];
  }

  getActionButtons() {
    return [addActionButton, editActionButton, copyActionButton, acceptActionButton, rejectActionButton, completeActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, editActionItem, copyActionItem, acceptActionItem, rejectActionItem, completeActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <AdvancedDataTable
        name={ASSIGNED_REQUESTS_TABLE_GRID}
        entityName="requests"
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

AssignedDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default AssignedDxGrid;
