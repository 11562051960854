/**
 *
 * document types
 *
 */

export const document = Object.freeze({
  DOC: 'doc',
  DOCX: 'docx',
  JPEG: 'jpeg',
  JPG: 'jpg',
  BMP: 'bmp',
  GIF: 'gif',
  PDF: 'pdf',
  PNG: 'png',
  TXT: 'txt',
});
