/*
 *
 * File sagas
 *
 */

import { buffers, eventChannel, END } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'utils/api';
import { formField } from 'domain/typeConstants/form';

import { FILES, ATTACHMENTS } from '../constants';
import { changeEntitiesState } from '../actions';
import { DOWNLOAD_FILE, SAVE_FILE } from './constants';
import {
  fileSavingProgress,
  fileSaved,
  fileSavingError,
} from './actions';

export default function* rootSaga() {
  yield takeLatest(DOWNLOAD_FILE, function* ({ payload }) {
    yield call(downloadFileSaga, payload.fileId);
  });

  yield takeLatest(SAVE_FILE, function* ({ meta, payload }) {
    yield call(saveFileSaga, payload.file, meta);
  });
}

function* downloadFileSaga(fileId) {
  const response = yield call(api.createDownloadTicket, fileId);
  window.open(api.getDownloadLink(response.data.ticketId), '_self');
}

function* saveFileSaga(files, meta) {
  const channel = yield call(createSaveFileChannel, files, meta.fieldType);

  yield takeLatest(channel, function* ({ progress, success, result, error }) {
    if (success) {
      if (meta.fieldType === formField.MULTIPLE_FILE) {
        yield put(changeEntitiesState(ATTACHMENTS, result));
        yield put(fileSaved(result.map((file) => file.id), meta));
      } else {
        yield put(changeEntitiesState(FILES, result));
        yield put(fileSaved(result[0].id, meta));
      }

      return;
    }

    if (error) {
      yield put(fileSavingError(error, meta));
      return;
    }

    if (progress) {
      yield put(fileSavingProgress(progress, meta));
    }
  });
}

function createSaveFileChannel(files, fieldType) {
  return eventChannel((emitter) => {
    const data = new FormData();

    if (fieldType === formField.MULTIPLE_FILE) {
      for (let i = 0; i < files.length; i += 1) {
        data.append(`file${i + 1}`, files[i]);
      }
    } else {
      data.append('file', files);
    }


    api.post('files', data, {
      onUploadProgress(event) {
        const progress = Math.round((event.loaded * 100) / event.total);
        emitter({ progress });
      },
    })
      .then((response) => {
        setTimeout(() => {
          emitter({ success: true, result: response.data });
          emitter(END);
        }, 200);
      })
      .catch((err) => {
        const error = new Error('File saving failed');
        error.inner = err;
        emitter({ error });
        emitter(END);
      });

    return () => undefined;
  }, buffers.sliding(5));
}
