/*
 *
 * FileField actions
 *
 */

import {
  CLEAR,
  SHOW_ERROR_MESSAGE_FILE
} from './constants';

export function clear(advancedForm, field, fieldType, fileId) {
  return {
    type: CLEAR,
    meta: { advancedForm, field, fieldType },
    payload: { fileId },
  };
}

export function showErrorMessageFile(alertTitle, alertContent) {
  return {
    type: SHOW_ERROR_MESSAGE_FILE,
    payload: { alertTitle,  alertContent},
  };
}
