/**
 *
 * action buttons 14691(BC Vozdvizhenka)
 *
 */

import React from "react";

import Done from "@material-ui/icons/Done";
import FileCopy from '@material-ui/icons/FileCopy';
import { requestAction } from "../../../../../domain/typeConstants/action";
import { requestStatus } from "../../../../../domain/typeConstants/status";

import { getValueFromLocalStorage } from "../../../../../utils/localStorage";
import { GLOBAL_USER_KEY } from "../../../../../global-constants";
import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';

export const acceptActionButton = {
    name: requestAction.ACCEPT,
    label: 'Принять',
    icon: 'done',
    svgIcon: <Done />,
    disabled: (selection, entities) => {
        if (selection.length !== 0) {
            if (!entities[0].status) return false;
            if (!entities[0].agreedEmployee) return false;

            return entities.some((element) => element.status &&
                (element.status.name === requestStatus.CLOSED || element.status.name === requestStatus.COMPLETED ||
                    element.status.name === requestStatus.ACCEPTED || element.status.name === requestStatus.IN_PROGRESS
                    || element.status.name === requestStatus.AGREED)
                || (element.status.name === requestStatus.REJECTED
                    && element.agreedEmployee.id !== getValueFromLocalStorage(GLOBAL_USER_KEY).id));
        }
        return true;
    },
};

export const copyActionButton = {
    name: requestAction.COPY,
    label: 'Копия',
    icon: 'content_copy',
    svgIcon: <FileCopy />,
    disabled: (selection, entities) => {
        return selection.length !== 1 || isCarEntryRequest(entities)
    },
};

const isCarEntryRequest = (entities) => {
    const requestTypeId = entities[0] && entities[0].type && entities[0].type.id ? entities[0].type.id : null
    return requestTypeId === ON_CAR_ENTRY
}