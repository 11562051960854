/**
*
* LoginPage
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import withPublicRoot from 'containers/App/withPublicRoot';
import LoginFormSheet from './LoginFormSheet';

const styleSheet = (theme) => ({
  loginFormSheetContainer: {
    minHeight: 520,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginFormSheet: {
    [theme.breakpoints.only('xs')]: {
      width: 340,
    },
    width: 400,
  },
});

function LoginPage({ classes, nextPathAfterLogin }) {
  return (
    <div className={classes.loginFormSheetContainer}>
      <LoginFormSheet
        className={classes.loginFormSheet}
        nextPathAfterLogin={nextPathAfterLogin}
      />
    </div>
  );
}

LoginPage.propTypes = {
  classes: PropTypes.object,
  nextPathAfterLogin: PropTypes.string,
};

export default withPublicRoot(
  withStyles(styleSheet)(LoginPage)
);
