/**
 *
 * SelectTextFilter sagas
 *
 */

import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from 'utils/api';
import { addEntityToState } from './actions';
import { LOAD_ENTITY } from './constants';

export default function* rootSaga() {
    yield takeEvery(LOAD_ENTITY, loadEntitySaga);
}

export function* loadEntitySaga(payload) {
    const entityName = payload.meta;
    try {
        const response = yield call(api.load, entityName);
        const entity = response.data;
        yield put(addEntityToState(entityName, entity));

    } catch (error) {
        console.log(error);
    }

}
