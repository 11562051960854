/**
 *
 * MakingAgreementBCDxGrid14140
 *
 */

import { MakingAgreementBCDxGrid } from 'pages/Requests/MakingAgreementBCView';

import {
  ACTUAL_CREATION_DATE,
  actualCreationDateColumn,
  actualCreationDateColumnFilter,

  departmentColumn,
  departmentColumnFilter,
} from '../common/dxGridComponents/columns';

export class MakingAgreementBCDxGrid14140 extends MakingAgreementBCDxGrid { // eslint-disable-line react/prefer-stateless-function
  getColumns() {
    const { viewName } = this.props;
    const columns = super.getColumns();

    if (viewName === 'all' || viewName === 'today' || viewName === 'agree') {
      columns.push(actualCreationDateColumn);
    }
    columns.push(departmentColumn);

    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    const gridFilters = super.getGridFilters();

    if (viewName === 'all' || viewName === 'today' || viewName === 'agree') {
      gridFilters.push(actualCreationDateColumnFilter);
    }
    gridFilters.push(departmentColumnFilter);
    return gridFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    const columnOrder = super.getColumnOrder();

    if (viewName === 'all' || viewName === 'today' || viewName === 'agree') {
      columnOrder.push(ACTUAL_CREATION_DATE);
    }

    const companyNameIndex = columnOrder.findIndex((column) => column === 'company_name');
    columnOrder.splice(companyNameIndex + 1, 0, 'department');

    return columnOrder;
  }
}
