import React from 'react';
import PropTypes from 'prop-types';

import MakingAgreementBCView, {
  MakingAgreementBCDxGrid,
  MakingAgreementBCDxGridContainer,
  MakingAgreementBCForm,
  MakingAgreementBCFormDialog,
  MakingAgreementBCRejectForm,
  MakingAgreementBCRejectFormDialog,
  MakingAgreementBCMobileGrid,
} from '../MakingAgreementBCView';

const getDxGrid = (DxGrid) => (
  <MakingAgreementBCDxGridContainer component={DxGrid || <MakingAgreementBCDxGrid />} />
);

const getMobileGrid = (MobileGrid) => (
  <MakingAgreementBCDxGridContainer component={MobileGrid || <MakingAgreementBCMobileGrid />} />
);

const getDataTableView = ({ breakpoint, makingAgreementBCDxGrid, makingAgreementBCMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(makingAgreementBCMobileGrid);
  }

  return getDxGrid(makingAgreementBCDxGrid);
};

const getFormDialog = (Form) => (
  <MakingAgreementBCFormDialog form={Form || <MakingAgreementBCForm />} />
);

const getRejectFormDialog = (rejectForm) => (
  <MakingAgreementBCRejectFormDialog form={rejectForm || <MakingAgreementBCRejectForm />} />
);

export const getMakingAgreementBCView = (props) => {
  const {
    breakpoint,
    makingAgreementBCDxGrid,
    makingAgreementBCForm,
    makingAgreementBCFormDialog,
    makingAgreementBCMobileGrid,
    makingAgreementBCRejectForm,
    makingAgreementBCRejectFormDialog,
    makingAgreementBCView,
    viewName,
    viewStyles,
  } = props;

  if (makingAgreementBCView) return makingAgreementBCView;

  return (
    <MakingAgreementBCView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        makingAgreementBCDxGrid,
        makingAgreementBCMobileGrid,
      })}
      formDialog={makingAgreementBCFormDialog || getFormDialog(makingAgreementBCForm)}
      rejectFormDialog={makingAgreementBCRejectFormDialog || getRejectFormDialog(makingAgreementBCRejectForm)}
    />
  );
};

getMakingAgreementBCView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  makingAgreementBCDxGrid: PropTypes.node,
  makingAgreementBCForm: PropTypes.node,
  makingAgreementBCFormDialog: PropTypes.node,
  makingAgreementBCMobileGrid: PropTypes.node,
  makingAgreementBCRejectForm: PropTypes.node,
  makingAgreementBCRejectFormDialog: PropTypes.node,
  makingAgreementBCView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
