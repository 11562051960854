/**
 *
 * UnloadingZoneOperatorDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_UNLOADING_ZONE_OPERATOR,
} from 'domain/Data/Filters/constants';

import {
  checkpointArrivedActionButton,
  departedActionButton,
} from '../common/actionButton';
import {
  viewActionItem,
  checkpointArrivedActionItem,
  departedActionItem,
} from '../common/actionMenu';
import { columnStatusLabels } from '../common/columnStatusLabel';

import { UNLOADING_ZONE_OPERATOR_REQUESTS_TABLE_GRID } from './constants';

class UnloadingZoneOperatorDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_UNLOADING_ZONE_OPERATOR];
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    return filters;
  }

  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
      },
      {
        path: 'visitorFullName',
        name: 'visitorFullName',
        title: 'ФИО посетителя',
        width: requestColumnSizes.visitorFullName,
        styles,
      },
      {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
        styles,
      },
      {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
        styles,
      },
      {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
      },
      {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
        styles,
      },
      {
        path: ['company', 'name'],
        name: 'tenantCompanyName',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.tenantCompanyName,
        styles,
      },
      {
        path: 'arrivalDate',
        name: 'arrivalDate',
        title: 'Прибыл',
        type: columnType.TIME,
        width: columnSize.TIME,
        styles,
      },
      {
        path: 'departureDate',
        name: 'departureDate',
        title: 'Убыл',
        type: columnType.TIME,
        width: columnSize.TIME,
        styles,
      },
      {
        path: ['parking', 'name'],
        name: 'parkingName',
        getCellValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
        width: requestColumnSizes.parkingName,
        styles,
      },
      {
        path: 'parkingPlace',
        name: 'parkingPlace',
        title: 'Место',
        width: requestColumnSizes.parkingPlace,
        styles,
      },
      {
        path: 'materialValuesData',
        name: 'materialValuesData',
        title: 'Мат. ценности',
        width: requestColumnSizes.materialValuesData,
        styles,
      },
      {
        path: 'companyName',
        name: 'companyName',
        title: 'Откуда посетитель',
        width: requestColumnSizes.companyName,
        styles,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
        styles,
      },
      {
        path: 'blackListNote',
        name: 'blackListNote',
        title: 'Причина ЧС',
        width: requestColumnSizes.blackListNote,
        styles,
      },
    ];
  }

  getColumnOrder() {
    return ['number', 'visitorFullName', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName', 'typeShortName', 'tenantCompanyName',
      'arrivalDate', 'departureDate', 'parkingName', 'parkingPlace', 'materialValuesData', 'companyName', 'note', 'blackListNote',
    ];
  }

  getActionButtons() {
    return [checkpointArrivedActionButton, departedActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, checkpointArrivedActionItem, departedActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (

      <AdvancedDataTable
        name={UNLOADING_ZONE_OPERATOR_REQUESTS_TABLE_GRID}
        entityName="requests"
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

UnloadingZoneOperatorDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default UnloadingZoneOperatorDxGrid;
