/*
 *
 * ServiceRequestsManagerForm14691
 *
 *
 */

import { ServiceRequestsManagerForm } from 'pages/Requests/ServiceRequestsManagerView';

import { formField } from "domain/typeConstants/form";

export class ServiceRequestsManagerForm14691 extends ServiceRequestsManagerForm {

    getFields() {
        const fields = super.getFields();
        fields.push({
            name: 'agreedEmployee',
            type: formField.AUTOCOMPLETE,
            label: 'Согласовал',
            visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
            entityName: 'employees',
            disabled: true,
            rowBreakBefore: true,
            initialValue: (values) => values.agreedEmployee,
        });
        return fields;
    }
}
