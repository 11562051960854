/**
 *
 * AdvancedMobileGrid.row.left-block component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/icons/Menu';

import { LEFT_BLOCK_WIDTH } from './constants';

const styles = () => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: LEFT_BLOCK_WIDTH,
    minWidth: LEFT_BLOCK_WIDTH,
  },
  icon: {
    color: '#757575',
    cursor: 'pointer',
  },
});

const LeftBlockBase = ({
  classes, record, onActionMenuClick,
}) => (
  <div className={classes.root}>
    <Menu
      className={classes.icon}
      onClick={onActionMenuClick(record)}
    />
  </div>
);

LeftBlockBase.propTypes = {
  classes: PropTypes.object.isRequired,
  onActionMenuClick: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export const LeftBlock = withStyles(styles, { name: 'LeftBlock' })(LeftBlockBase);
