/*
 *
 * ApplicantForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { CREATED } from 'domain/Data/RequestStatus/constants';
import { SERVICE } from 'domain/Data/RequestType/constants';
import { APPLICANT_REQUESTS_FORM } from './constants';

class ApplicantForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'company',
        visible: false,
        initialValue: (values, mode, user) => user.companyId,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'view',
        initialValue: CREATED,
        calculatedValue: (values) => values.status,
        required: true,
      },
      {
        name: 'type',
        initialValue: () => SERVICE,
        visible: false,
      },
      {
        name: 'service',
        type: 'select',
        label: 'Сервис',
        entityName: 'services',
        visible: true,
        rowBreakBefore: true,
      },
      {
        name: 'requestPriority',
        type: 'select',
        label: 'Приоритет',
        entityName: 'requestPriorities',
        visible: true,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        rowBreakBefore: true,
        grid: { sm: 12 },
        visible: true,
      },
      {
        name: 'whatHappened',
        label: 'Что случилось',
        multiline: false,
        visible: true,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        visible: true,
        grid: { sm: 12 },
      },
      {
        name: 'assignee',
        type: 'select',
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        visible: false,
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
      },
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        required: true,
        visible: false,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        required: true,
        visible: false,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: false,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: true,
        rowBreakBefore: true,
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={APPLICANT_REQUESTS_FORM}
        entityName="requests"
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}

export default ApplicantForm;
