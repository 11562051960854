/*
 *
 * Companies Page
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withPrivateRoot from 'containers/App/withPrivateRoot';
import { role } from "../../domain/typeConstants/roles";
import { getReceptionView } from "./getRoleView";

const styleSheet = (theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 10,
        },
        padding: theme.spacing.unit * 2,
        width: '100%',
        height: '100%',
    },
});

class CompaniesPage extends React.PureComponent {
    static propTypes = {
        breakpoint: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    getViewStyles() {
        const { breakpoint } = this.props;
        const styles = {
            root: {
                height: 'inherit',
            },
            dataTable: {
                position: 'relative',
            },
        };

        if (breakpoint.down('md')) {
            styles.dataTable.height = '100%';
        }
        return styles;
    }

    renderView() {
        const { userRoles } = this.props;
        const viewStyles = this.getViewStyles();

        if (userRoles.includes(role.ADMINISTRATOR_BC)) {
            return getReceptionView({ viewStyles, ...this.props });
        }
        return null;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>{this.renderView()}</div>
        );
    }
}

export default withPrivateRoot(
    withStyles(styleSheet)(CompaniesPage)
);
