/**
*
* ChangePasswordFormSheet
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import RestorePasswordForm from 'containers/RestorePasswordForm';

const styleSheet = (theme) => ({
  header: {
    padding: '32px',
    backgroundColor: theme.palette.primary[400],
  },
  headerText: {
    color: theme.palette.common.white,
  },
  headerBody: {
    padding: 32,
  },
  form: {
    padding: '16px 0 0',
  },
});

function RestorePasswordFormSheet({ classes, className, nextPathAfterLogin }) {
  return (
    <Paper className={className} elevation={4}>
      <div className={classes.headerBody}>
        <Typography variant="title" component="h3">
          Восстановление пароля
        </Typography>
        <Typography variant="caption">
          Пароль должен иметь длину не менее 8 символов и включать в себя цифры (0-9), английские заглавные буквы (A-Z), английские строчные буквы (a-z)
        </Typography>
        <RestorePasswordForm
          className={classes.form}
          nextPathAfterLogin={nextPathAfterLogin}
        />
      </div>
    </Paper>
  );
}

RestorePasswordFormSheet.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  nextPathAfterLogin: PropTypes.string,
};

export default withStyles(styleSheet)(RestorePasswordFormSheet);
