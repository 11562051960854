/*
 *
 * RequestsPageContainer14326
 *
 */

import React from 'react';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import RequestsPage14326 from './RequestsPage14326';
import saga from './sagas';

function RequestsPageContainer14326(props) {
  return (
    <RequestsPage14326 {...props} />
  );
}

const withSaga = injectSaga({ key: 'receptionPage14326', saga });

export default compose(
  withSaga,
)(RequestsPageContainer14326);
