/**
 *
 * DocumentPositionForm
 *
 * 
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { ON_CREATED, ON_REJECTED } from 'domain/Data/DocumentPositionState/constants';
import { DOCUMENT_POSITION_FORM } from './constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';

class DocumentPositionForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  getFields() {
    const currentDate = new Date();
    const mode = this.props.mode;
    const parentId = this.props.parentId;
    return [
      {
        name: 'state',
        label: 'Статус',
        entityName: 'documentPositionStates',
        visible: false,
        initialValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') return ON_CREATED;
          return values.state;
        },
      },
      {
        name: 'document',
        type: 'comboBox',
        label: 'Документ',
        entityName: 'documents',
        visible: false,
        initialValue: (values, mode) => mode === 'add' ? parentId : values.document?.id
      },
      {
        name: 'assetText',
        label: 'ТМЦ текстом',
        disabled: (values, mode) => {
          return mode === 'edit' && !([ON_CREATED, ON_REJECTED].includes(values.state))
        },
        grid: { sm: 12 },
      },
      {
        name: 'asset',
        label: 'ТМЦ',
        type: 'comboBoxTextInput',
        entityName: 'goodsAndServices',
        menuItemlabel: "Добавить ТМЦ в справочник",
        addDialogPlaceholder: "Ввести новое значение ТМЦ в справочник",
        grid: { sm: 12 },
      },
      {
        name: 'desiredDate',
        label: 'Желаемая дата',
        type: 'date',
        grid: { sm: 6 },
        rowBreakAfter: true,
      },
      {
        name: 'positionsCount',
        label: 'Количество',
        grid: { sm: 6 },
      },
      {
        name: 'received',
        label: 'Получено',
        grid: { sm: 6 },
      },
      {
        name: 'description',
        label: 'Примечание',
        grid: { sm: 12 },
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={DOCUMENT_POSITION_FORM}
        entityName="documentPositions"
        classNames={this.getClassNames()}
        fields={this.getFields()}
        emptyPlace
        accountId={getValueFromLocalStorage(GLOBAL_USER_KEY).accountId}
        isDetail={false}
        {...this.props}
      />
    );

  }
}

export default DocumentPositionForm;

