/**
 *
 * ActionButton12970 Buttons
 *
 */

import React from 'react';

import CallReceived from '@material-ui/icons/CallReceived';
import CallMade from '@material-ui/icons/CallMade';

import { requestAction } from 'domain/typeConstants/action';
import { requestStatus } from 'domain/typeConstants/status';

import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';
import { PARKING_AT_MAIN_ENTRANCE, FOR_UNLOADING } from '../../../domain/Data/RequestType/constants';

const parkingOperatorRequestTypeCondition = (request) => (![ON_CAR_ENTRY, PARKING_AT_MAIN_ENTRANCE].includes(request.type.id));
const unloadingZoneOperatorRequestTypeCondition = (request) => (request.type.id !== FOR_UNLOADING);

export const arrivedActionButton = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  disabled: (selection, entities) => {
    if (selection.length > 0) {
      let disable = false;
      let row = null;

      for (let i = 0; i < entities.length; i += 1) {
        row = entities[i];
        if (!row.status) break;
        if ((row.status.name !== requestStatus.ON_TERRITORY && row.status.name !== requestStatus.ACCEPTED &&
          row.status.name === requestStatus.CLOSED) || row.status.name === requestStatus.CREATED ||
          row.status.name === requestStatus.REJECTED) {
          disable = true;
        }
      }

      return disable;
    }

    return true;
  },
};

export const parkingOperatorArrivedActionButton = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  disabled: (selection, entities) => {
    if (selection.length > 0) {
      let disable = false;
      let row = null;
      const requestStatusCondition = (request) => (![requestStatus.ACCEPTED, requestStatus.ON_TERRITORY].includes(request.status.name));

      for (let i = 0; i < entities.length; i += 1) {
        row = entities[i];
        if (!row.status) break;
        if (requestStatusCondition(row) || parkingOperatorRequestTypeCondition(row)) {
          disable = true;
        }
      }

      return disable;
    }

    return true;
  },
};

export const unloadingZoneOperatorArrivedActionButton = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  disabled: (selection, entities) => {
    if (selection.length > 0) {
      let disable = false;
      let row = null;
      const requestStatusCondition = (request) => (![requestStatus.ACCEPTED, requestStatus.ON_TERRITORY].includes(request.status.name));

      for (let i = 0; i < entities.length; i += 1) {
        row = entities[i];
        if (!row.status) break;

        if (requestStatusCondition(row) || unloadingZoneOperatorRequestTypeCondition(row)) {
          disable = true;
        }
      }

      return disable;
    }

    return true;
  },
};

export const departedActionButton = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      const row = entities[0];
      if (!row.status) return false;
      return ((row.status.name !== requestStatus.ON_TERRITORY && row.status.name === requestStatus.CLOSED) ||
        row.status.name === requestStatus.CREATED);
    }
    return true;
  },
};

export const parkingOperatorDepartedActionButton = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      const row = entities[0];
      if (!row.status) return false;
      const requestStatusCondition = (request) => (![requestStatus.ON_TERRITORY].includes(request.status.name));

      return (requestStatusCondition(row) || parkingOperatorRequestTypeCondition(row));
    }
    return true;
  },
};

export const unloadingZoneOperatorDepartedActionButton = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  disabled: (selection, entities) => {
    if (selection.length === 1) {
      const row = entities[0];
      if (!row.status) return false;
      const requestStatusCondition = (request) => (![requestStatus.ON_TERRITORY].includes(request.status.name));

      return (requestStatusCondition(row) || unloadingZoneOperatorRequestTypeCondition(row));
    }
    return true;
  },
};
