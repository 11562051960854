/**
*
* ToolsTableBodyCell
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ActionMenu from 'components/ActionMenu';

const styleSheet = {
  root: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  menuContainer: {
    display: "flex",
  },
  actionMenuIcon: {
    fontSize: 20,
  },
  button: {
    width: 32,
    height: 32,
  },
  toolsExpandedRecord: {
    paddingLeft: 10
  },
};

function ToolsTableBodyCell({ classes, selectable, actionMenuItems, checked, onActionMenuItemClick,
  onMenuItemChange, record, expandedRowIds, onCheckboxClick, onExpandedRowClick, isTableRowDetail,
  isActionMenu, isFilePreview, onOpenDrawer, filePreviewFieldName }) {

  return (
    <TableCell
      className={classNames(classes.root,
        { [classes.toolsExpandedRecord]: expandedRowIds && expandedRowIds.includes(record.id) })}
      padding="none">
      <div className={classes.menuContainer}>
        {selectable &&
          <Checkbox
            color="primary"
            className={classes.button}
            checked={checked}
            onChange={onCheckboxClick}
          />
        }
        {isActionMenu && actionMenuItems && actionMenuItems.length > 0 &&
          <ActionMenu
            classes={{
              icon: classes.actionMenuIcon,
              button: classes.button,
            }}
            items={actionMenuItems}
            onItemClick={onActionMenuItemClick}
            onMenuItemChange={() => onMenuItemChange(record)}
            record={record}
          />
        }
        {isTableRowDetail &&
          <IconButton
            onClick={onExpandedRowClick}
            className={classes.button}
          >            {expandedRowIds && expandedRowIds.includes(record.id) ? <ExpandLess /> : <ExpandMore />}
          </IconButton>}

        {isFilePreview &&
          <IconButton
            onClick={onOpenDrawer}
            className={classes.button}
            disabled={!record[filePreviewFieldName || 'scan']}
          >
            <ChevronLeft />
          </IconButton>}
      </div>
    </TableCell>
  );
}

ToolsTableBodyCell.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  actionMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ),
  onActionMenuItemClick: PropTypes.func,
  onMenuItemChange: PropTypes.func,
  record: PropTypes.object,
};

ToolsTableBodyCell.defaultProps = {
  selectable: false,
};

export default withStyles(styleSheet)(ToolsTableBodyCell);
