/**
 *
 * Requests action buttons 15755(BC_Krylatsky)
 *
 */

import React from 'react';
import { requestAction } from 'domain/typeConstants/action';
import Done from '@material-ui/icons/Done';
import { requestStatus } from 'domain/typeConstants/status';


const noPlacesActionDisableStatuses = [requestStatus.ON_TERRITORY, requestStatus.NO_PLACES]


export const noPlacesActionButton = {
  name: requestAction.NO_PLACES,
  label: 'Нет мест',
  icon: 'done',
  svgIcon: <Done />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return noPlacesActionDisableStatuses.includes(currentRecord.status.name)
    }
    else if (selection.length > 0) {
      return selection.some((element) => element.status &&  noPlacesActionDisableStatuses.includes(element.status.name)
      );
    }
    return true;
  },
};
