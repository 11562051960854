/**
 *
 * SettingsPage14326
 *
 */

import React from 'react';
import SettingsPage from 'pages/Settings/SettingsPage';
import {
  UsersDxGrid14326,
  UsersForm14326,
} from './UsersView';

function SettingsPage14326(props) {
  return (
    <SettingsPage
      administratorBCDxGrid={<UsersDxGrid14326 />}
      administratorBCForm={<UsersForm14326 />}
      {...props}
    />
  );
}
export default SettingsPage14326;