/**
 *
 * AdministratorBC Service Requests DxGrid 14326
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';

import { columnType, requestColumnSizes } from 'domain/typeConstants/table';

import {
  sendToAgreementByTenantActionButton,
  sendToAgreementActionButton,
  sendEmailActionButton,
  arrivedActionButton,
  departedActionButton,
  addBlackListCarActionButton,
  acceptActionButton,
  editActionButton,
  closeActionButton,
} from '../common/actionButton';

import {
  sendToAgreementActionMenuItem,
  sendEmailActionMenuItem,
  arrivedActionItem,
  departedActionItem,
  addBlackListCarActionItem,
  acceptActionItem,
  sendToAgreementByTenantActionMenuItem,
} from '../common/actionMenuItem';
import { requestAction } from "domain/typeConstants/action";

export class AdministratorBCServiceRequestsDxGrid14326 extends ServiceRequestsManagerDxGrid {
  getColumns() {
    const { viewName } = this.props;
    const columns = super.getColumns();
    const unnecessaryColumns = new Set(['serviceName', 'fileName']);

    if (viewName === 'all') {
      columns.push({
        path: 'implementationReport',
        name: 'implementationReport',
        title: 'Ответ о выполнении',
        width: 230,
      });
    }

    columns.push({
      path: ['type', 'shortName'],
      name: 'typeShortName',
      getCellValue: (row) => (row.type ? row.type.shortName : undefined),
      title: 'Вид',
      entityName: 'requestTypes',
      width: 170,
    });

    columns.push({
      path: ['author', 'name'],
      name: 'authorName',
      getCellValue: (row) => (row.author ? row.author.name : undefined),
      title: 'Автор',
      width: 100,
    });

    columns.push({
      path: ['author', 'phone'],
      name: 'authorPhone',
      getCellValue: (row) => row.author ? row.author.phone : undefined,
      title: 'Телефон',
      width: 100,
    });

    columns.push({
      path: ['agreedEmployee', 'name'],
      name: 'agreedEmployee',
      title: 'Согласовал',
      getCellValue: (row) => row.agreedEmployee ? row.agreedEmployee.name : undefined,
      width: requestColumnSizes.agreedEmployee,
    });

    columns.push({
      path: 'actualCreationDate',
      name: 'actualCreationDate',
      title: 'Создана',
      type: columnType.DATETIME,
      width: requestColumnSizes.actualCreationDate,
    });


    columns.push({
      path: 'requestChangeDate',
      name: 'requestChangeDate',
      title: 'Изменено',
      type: columnType.DATETIME,
      width: requestColumnSizes.actualCreationDate,
    });

    columns.push({
      name: 'countAttachments',
      path: ['countAttachments', 'name'],
      getCellValue: (row) => (row.attachments.length > 0 ? "+" + row.attachments.length : undefined),
      title: 'Файл',
      filter: false,
      width: requestColumnSizes.fileName,
    })

    return columns.filter((column) => !unnecessaryColumns.has(column.name));
  }

  getGridFilters() {
    const { viewName } = this.props;
    const columnFilters = super.getGridFilters();

    if (viewName === 'all') {
      columnFilters.push({
        columnName: 'implementationReport',
        value: '',
      });
    }

    columnFilters.push({
      columnName: 'authorPhone',
      value: '',
    });

    columnFilters.push({
      columnName: 'typeShortName',
      value: '',
    });

    columnFilters.push({
      columnName: 'agreedEmployee', value: '',
    });

    columnFilters.push({
      columnName: 'actualCreationDate', value: '',
    });

    columnFilters.push({
      columnName: 'requestChangeDate', value: '',
    });


    return columnFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    const columnOrder = super.getColumnOrder();

    if (viewName === 'all') {
      columnOrder.push('implementationReport');
    }
    const whatHappenedIndex = columnOrder.findIndex((columnName) => columnName === 'whatHappened');
    columnOrder.splice(whatHappenedIndex + 1, 0, 'agreedEmployee', 'name');

    const companyNameIndex = columnOrder.findIndex((columnName) => columnName === 'companyName');
    columnOrder.splice(companyNameIndex + 1, 0, 'typeShortName');

    columnOrder.push('authorPhone');

    const executionDateIndex = columnOrder.findIndex((columnName) => columnName === 'executionDate');
    columnOrder.splice(executionDateIndex + 1, 0, 'countAttachments');

    return columnOrder;
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const editIndex = actionButtons.findIndex((button) => button.name === requestAction.EDIT);
    actionButtons.splice(editIndex, 1, editActionButton);

    actionButtons.push(sendToAgreementByTenantActionButton);
    actionButtons.push(arrivedActionButton);
    actionButtons.push(departedActionButton);
    actionButtons.push(addBlackListCarActionButton);
    actionButtons.push(sendToAgreementActionButton);
    actionButtons.push(sendEmailActionButton);

    const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);

    const completeIndex = actionButtons.findIndex((button) => button.name === requestAction.COMPLETE);
    actionButtons.splice(completeIndex, 1, closeActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    actionMenuItems.push(arrivedActionItem);
    actionMenuItems.push(departedActionItem);
    actionMenuItems.push(addBlackListCarActionItem);
    actionMenuItems.push(sendToAgreementActionMenuItem);
    actionMenuItems.push(sendEmailActionMenuItem);

    const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);

    actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);
    actionMenuItems.push(sendToAgreementByTenantActionMenuItem);

    return actionMenuItems;
  }

  getSorting() {
    return [{ columnId: 'requestChangeDate', columnName: 'requestChangeDate', direction: 'desc' }];
  }
}
