/*
 *
 * CheckpointFormDialog14326
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { CHECKPOINT_REQUESTS_FORM, CHECKPOINT_REQUESTS_FORM_DIALOG } from 'pages/Requests/CheckpointView/constants';

export function CheckpointFormDialog14326({ form }) {
  return (
    <AdvancedFormDialog
      name={CHECKPOINT_REQUESTS_FORM_DIALOG}
      formName={CHECKPOINT_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
      addModeSaveButtonLabel="Сохранить и Отправить"
      copyModeSaveButtonLabel="Сохранить и Отправить"
    />
  );
}

CheckpointFormDialog14326.propTypes = {
  form: PropTypes.node.isRequired,
};
