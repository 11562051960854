/**
 *
 * AdvancedMobileGrid actions
 *
 */

import {
  CHANGE_IS_LIST_OVER,
  CHANGE_IS_LOADING,
  CHANGE_MENU_ITEM,
  CHANGE_SEARCH_QUERY,
  CHANGE_SELECTION,
  CHANGE_SORTING,
  CHANGE_GRID_FILTERS,
  CLEAR_SELECTION,
  LOAD_DATA,
  LOAD_DATA_ERROR,
  LOAD_DATA_SUCCESS,
  REGISTER,
  UNREGISTER, CHANGE_CURRENT_RECORD,
} from './constants';

export function changeCurrentRecord(name, record) {
  return {
    type: CHANGE_CURRENT_RECORD,
    meta: { name },
    payload: { record },
  };
}

export function changeSelection(name, selection) {
  return {
    type: CHANGE_SELECTION,
    meta: { name },
    payload: { selection },
  };
}

export function changeIsLoading(name, isLoading) {
  return {
    type: CHANGE_IS_LOADING,
    meta: { name },
    payload: { isLoading },
  };
}

export function changeIsListOver(name, isListOver) {
  return {
    type: CHANGE_IS_LIST_OVER,
    meta: { name },
    payload: { isListOver },
  };
}

export function changeMenuItem(name, recordId) {
  return {
    type: CHANGE_MENU_ITEM,
    meta: { name },
    payload: { recordId },
  };
}

export function changeSorting(name, sorting) {
  return {
    type: CHANGE_SORTING,
    meta: { name },
    payload: { sorting },
  };
}

export function clearSelection(name) {
  return {
    type: CLEAR_SELECTION,
    meta: { name },
  };
}

export function changeSearchQuery(name, searchQuery) {
  return {
    type: CHANGE_SEARCH_QUERY,
    meta: { name },
    payload: { searchQuery },
  };
}

export function changeGridFilters(name, gridFilters) {
  return {
    type: CHANGE_GRID_FILTERS,
    meta: { name },
    payload: { gridFilters }
  };
}

export function loadData(name, {
  entityName,
  filters,
  isLazyLoading,
  limit,
  pageNumber,
  pageSize,
  searchQuery,
  sorting,
  startAt,
} = {}) {
  return {
    type: LOAD_DATA,
    meta: { name },
    payload: {
      entityName,
      filters,
      isLazyLoading,
      limit,
      pageNumber,
      pageSize,
      searchQuery,
      sorting,
      startAt,
    },
  };
}

export function dataLoaded(name, ids) {
  return {
    type: LOAD_DATA_SUCCESS,
    meta: { name },
    payload: { ids },
  };
}

export function dataLoadingError(name, error) {
  return {
    type: LOAD_DATA_ERROR,
    meta: { name },
    payload: { error },
  };
}

export function register(name, {
  actionButtons,
  actionMenuItems,
  entityName,
  filters,
  gridFilters,
  limit,
  sorting,
} = {}) {
  return {
    type: REGISTER,
    meta: { name },
    payload: {

      actionButtons,
      actionMenuItems,
      entityName,
      filters,
      gridFilters,
      limit,
      sorting
    },
  };
}

export function unregister(name) {
  return {
    type: UNREGISTER,
    meta: { name },
  };
}
