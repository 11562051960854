/**
*
* createDadataFieldContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field } from 'redux-form/immutable';

import * as actionCreators from './actions';
import { makeSelectSuggestions } from './selectors';
import DadataField from './DadataField';
import * as advancedFormActionCreators from '../actions';

function createDadataFieldContainer(formName, fieldName) {
  class DadataFieldContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render() {
      const { clearErrorsOnChange, ...restProps } = this.props; // eslint-disable-line no-unused-vars
      return (
        <Field
          {...restProps}
          component={DadataField}
        />
      );
    }
  }

  DadataFieldContainer.propTypes = {
    calculateFieldsValues: PropTypes.func.isRequired,
    clearErrorsOnChange: PropTypes.bool,
  };

  DadataFieldContainer.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    suggestions: makeSelectSuggestions(formName, fieldName),
  });

  const mapDispatchToProps = (dispatch) => ({
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
    clearSuggestions: () => dispatch(actionCreators.clearSuggestions(formName, fieldName)),
    loadSuggestions: (searchQuery) => dispatch(actionCreators.loadSuggestions(formName, fieldName, searchQuery)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(DadataFieldContainer);
}

export default createDadataFieldContainer;
