/**
 *
 * 11994 reception dxGridColumnWidths
 *
 */

import { columnSize, requestColumnSizes } from 'domain/typeConstants/table';

export const ALL_COLUMN_WIDTHS = [
  { columnName: 'number', width: columnSize.NUMERIC },
  { columnName: 'visitorFullName', width: requestColumnSizes.visitorFullName },
  { columnName: 'carNumber', width: requestColumnSizes.carNumber },
  { columnName: 'carModelText', width: requestColumnSizes.carModelText },
  { columnName: 'visitDate', width: columnSize.DATE },
  { columnName: 'expirationDate', width: columnSize.DATE },
  { columnName: 'statusName', width: requestColumnSizes.statusName },
  { columnName: 'typeShortName', width: requestColumnSizes.typeShortName },
  { columnName: 'company_name', width: requestColumnSizes.company_name },
  { columnName: 'documentDetails', width: requestColumnSizes.documentDetails },
  { columnName: 'accessCardNumber', width: requestColumnSizes.accessCardNumber },
  { columnName: 'parkingPlace', width: requestColumnSizes.parkingPlace },
  { columnName: 'materialValuesData', width: requestColumnSizes.materialValuesData },
  { columnName: 'companyName', width: requestColumnSizes.companyName },
  { columnName: 'note', width: requestColumnSizes.note },
  { columnName: 'blackListNote', width: requestColumnSizes.blackListNote },
  { columnName: 'authorName', width: requestColumnSizes.authorName },
];

export const TODAY_COLUMN_WIDTHS = [
  { columnName: 'number', width: columnSize.NUMERIC },
  { columnName: 'visitorFullName', width: requestColumnSizes.visitorFullName },
  { columnName: 'visitDate', width: columnSize.DATE },
  { columnName: 'expirationDate', width: columnSize.DATE },
  { columnName: 'statusName', width: requestColumnSizes.statusName },
  { columnName: 'company_name', width: requestColumnSizes.company_name },
  { columnName: 'documentDetails', width: requestColumnSizes.documentDetails },
  { columnName: 'accessCardNumber', width: requestColumnSizes.accessCardNumber },
  { columnName: 'parkingPlace', width: requestColumnSizes.parkingPlace },
  { columnName: 'companyName', width: requestColumnSizes.companyName },
  { columnName: 'note', width: requestColumnSizes.note },
  { columnName: 'authorName', width: requestColumnSizes.authorName },
  { columnName: 'typeShortName', width: requestColumnSizes.typeShortName },
];
