/**
 *
 * MakingAgreementBCRejectFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { REJECT_FORM, REJECT_FORM_DIALOG } from './constants';

function MakingAgreementBCRejectFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={REJECT_FORM_DIALOG}
      formName={REJECT_FORM}
      advancedForm={form}
      editModeTitle="Отклонить заявку"
    />
  );
}

MakingAgreementBCRejectFormDialog.propTypes = {
  form: PropTypes.node,
};

export default MakingAgreementBCRejectFormDialog;
