/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

import globalReducer from 'domain/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import { reducer as reduxFormReducer } from 'redux-form/immutable';
import advancedDataTablesReducer from 'containers/AdvancedDataTable/reducer';
import advancedFormDialogReducer from 'containers/AdvancedFormDialog/reducer';
import advancedFormReducer from 'containers/AdvancedForm/reducer';
import navigationDrawerReducer from 'containers/NavigationDrawer/reducer';
import snackbarReducer from 'containers/Snackbar/reducer';
import alertDialogReducer from 'containers/AlertDialog/reducer';
import advancedMobileGridReducer from 'containers/AdvancedMobileGrid/reducer';
import loginReducer from 'containers/LoginForm/reducer'
import dashboardReducer from 'pages/Dashboard/DashboardView/reducer';

/**
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

export function location(state = null, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return action.payload;
    default:
      return state;
  }
}
const routeReducer = combineReducers({ location });

/**
 *
 * Creates the main reducer with the dynamically injected ones
 *
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    global: globalReducer,
    forms: reduxFormReducer,
    advancedDataTables: advancedDataTablesReducer,
    advancedMobileGrid: advancedMobileGridReducer,
    advancedFormDialogs: advancedFormDialogReducer,
    advancedForms: advancedFormReducer,
    language: languageProviderReducer,
    route: routeReducer,
    navigationDrawer: navigationDrawerReducer,
    snackbar: snackbarReducer,
    alertDialog: alertDialogReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
    ...injectedReducers,
  });
}
