/**
 *
 * SeniorParkingOperatorView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function SeniorParkingOperatorView({ dataTable, formDialog, blackListFormDialog, viewName, viewStyles }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { formDialog }
      { blackListFormDialog }
    </div>
  );
}

SeniorParkingOperatorView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  blackListFormDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
};

SeniorParkingOperatorView.defaultProps = {
  viewStyles: {},
};

export default SeniorParkingOperatorView;
