/**
 *
 * Requests ActionButton Items
 *
 */

import React from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Block from '@material-ui/icons/Block';
import CallMade from '@material-ui/icons/CallMade';
import CallReceived from '@material-ui/icons/CallReceived';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import Create from '@material-ui/icons/Create';
import Done from '@material-ui/icons/Done';
import FileCopy from '@material-ui/icons/FileCopy';
import FindInPage from '@material-ui/icons/FindInPage';
import { requestAction } from 'domain/typeConstants/action';
import { requestStatus } from 'domain/typeConstants/status';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { account } from 'domain/typeConstants/accounts';


function isDisableArrivedActionButton() {
  const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId;
  const accounts = [account.BC_71_7ONE, account.WALLSTREET, account.BC_VISHNIAKI];
  return !accounts.includes(accountId);
}

export const addActionButton = {
  name: requestAction.ADD,
  label: 'Добавить',
  icon: 'add_circle_outline',
  svgIcon: <AddCircleOutline />,
};


export const editActionButton = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    return !(currentRecord || selection.length === 1);
  },
};

export const viewActionButton = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
  disabled: (selection, entities, currentRecord) => {
    return !(currentRecord || selection.length === 1);
  },
};


export const copyActionButton = {
  name: requestAction.COPY,
  label: 'Копия',
  icon: 'content_copy',
  svgIcon: <FileCopy />,
  disabled: (selection, entities, currentRecord) => {
    return !(currentRecord || selection.length === 1);
  },
};


const acceptActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.ACCEPTED,
requestStatus.IN_PROGRESS, requestStatus.AGREED, requestStatus.REJECTED]

export const acceptActionButton = {
  name: requestAction.ACCEPT,
  label: 'Принять',
  icon: 'done',
  svgIcon: <Done />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord && selection.length === 0) {
      if (!currentRecord.status) return false
      return acceptActionDisableStatuses.includes(currentRecord.status.name)
    }
    else if (selection.length > 0) {
      return entities.some((element) => element.status &&
        acceptActionDisableStatuses.includes(element.status.name)
      );
    }
    return true;
  },
};


const rejectActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.REJECTED, requestStatus.AGREED]

export const rejectActionButton = {
  name: requestAction.REJECT,
  label: 'Отклонить',
  icon: 'clear',
  svgIcon: <Clear />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord && selection.length === 0) {
      if (!currentRecord.status) return false
      return rejectActionDisableStatuses.includes(currentRecord.status.name)
    }
    else if (selection.length > 0) {
      return entities.some((element) => element.status &&
        rejectActionDisableStatuses.includes(element.status.name)
      );
    } else return true
  },
};

const arrivedActionDisableStatuses = [requestStatus.CLOSED, requestStatus.COMPLETED, requestStatus.IN_PROGRESS]

export const arrivedActionButton = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return arrivedActionDisableStatuses.includes(currentRecord.status.name) && isDisableArrivedActionButton()
    }
    else if (selection.length === 1) {
      if (!entities[0].status) return false;
      return arrivedActionDisableStatuses.includes(entities[0].status.name) && isDisableArrivedActionButton()
    }
    return true;
  },
};

export const departedActionButton = {
  name: requestAction.DEPARTED,
  label: 'Убыл',
  icon: 'call_made',
  svgIcon: <CallMade />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return currentRecord.status.name !== requestStatus.ON_TERRITORY
    } else if (selection.length > 0) {
      return entities.some((element) => element.status && element.status.name !== requestStatus.ON_TERRITORY)
    }
    return true
  },
};

const checkpointArrivedActionDisableStatuses = [requestStatus.CLOSED, requestStatus.ON_TERRITORY, requestStatus.COMPLETED,
requestStatus.IN_PROGRESS, requestStatus.REJECTED]

export const checkpointArrivedActionButton = {
  name: requestAction.ARRIVED,
  label: 'Прибыл',
  icon: 'call_received',
  svgIcon: <CallReceived />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return checkpointArrivedActionDisableStatuses.includes(currentRecord.status.name) && isDisableArrivedActionButton()
    }
    else if (selection.length === 1) {
      if (!entities[0].status) return false;
      return checkpointArrivedActionDisableStatuses.includes(entities[0].status.name) && isDisableArrivedActionButton()
    }
    return true;
  },
};

export const closeActionButton = {
  name: requestAction.CLOSE,
  label: 'Закрыть',
  icon: 'close',
  svgIcon: <Close />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return (currentRecord.status.name === requestStatus.CLOSED || currentRecord.status.name === requestStatus.COMPLETED);
    } else if (selection.length !== 0) {
      if (!entities[0].status) return false;
      return entities.some((element) => element.status.name === requestStatus.CLOSED || element.status.name === requestStatus.COMPLETED);
    }
    return true;
  },
};

export const completeActionButton = {
  name: requestAction.COMPLETE,
  label: 'Закрыть',
  icon: 'check_circle_outline',
  svgIcon: <CheckCircleOutline />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return (currentRecord.status.name !== requestStatus.IN_PROGRESS && currentRecord.status.name !== requestStatus.ACCEPTED);
    } else if (selection.length === 1) {
      if (!entities[0].status) return false;
      return (entities[0].status.name !== requestStatus.IN_PROGRESS && entities[0].status.name !== requestStatus.ACCEPTED);
    }
    return true;
  },
};

export const addBlackListCarActionButton = {
  name: requestAction.ADD_TO_BLACK_LIST,
  label: 'Добавить в ЧС',
  icon: 'block',
  svgIcon: <Block />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord) {
      if (!currentRecord.status) return false
      return [requestStatus.CLOSED, requestStatus.COMPLETED].includes(currentRecord.status.name)
    } else if (selection.length === 1) {
      if (!entities[0].status) return false;
      return [requestStatus.CLOSED, requestStatus.COMPLETED].includes(entities[0].status.name)
    } else return true
  },
};

export const addActionButtonTable = {
  name: requestAction.ADD,
  label: 'Добавить',
  icon: 'add_circle_outline',
  svgIcon: <AddCircleOutline />,
  disabled: false,
};


export const editActionButtonTable = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    return !(currentRecord || selection.length === 1);
  },
};

export const copyActionButtonTable = {
  name: requestAction.COPY,
  label: 'Копия',
  icon: 'content_copy',
  svgIcon: <FileCopy />,
  disabled: (selection, entities, currentRecord) => {
    return !(currentRecord || selection.length === 1);
  },
};
