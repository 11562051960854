/*
 *
 * ServiceRequestsManagerRejectForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { REJECTED } from 'domain/Data/RequestStatus/constants';
import { SERVICE_REQUESTS_MANAGER_REJECT_FORM } from './constants';

class ServiceRequestsManagerRejectForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    return [
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: () => false,
        calculatedValue: () => REJECTED,
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
      },
      {
        name: 'company',
        type: 'select',
        label: 'Арендатор',
        entityName: 'companies',
        orderBy: 'name',
        rowBreakBefore: true,
        disabled: true,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        required: true,
        grid: { sm: 12 },
      },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={SERVICE_REQUESTS_MANAGER_REJECT_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default ServiceRequestsManagerRejectForm;
