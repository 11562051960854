/**
 *
 * CheckpointOperatorView constants
 *
 */

export const CHECKPOINT_OPERATOR_REQUESTS_DX_GRID = 'CheckpointOperatorRequestsDxGrid';
export const CHECKPOINT_OPERATOR_REQUESTS_FORM = 'CheckpointOperatorRequestsForm';
export const CHECKPOINT_OPERATOR_REQUESTS_FORM_DIALOG = 'CheckpointOperatorRequestsFormDialog';

export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/CheckpointOperatorView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/CheckpointOperatorView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/CheckpointOperatorView/EXECUTE_DEPARTED_ACTION';
