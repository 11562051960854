/**
 *
 * Requests action menu items 15774
 *
 */

import React from 'react';
import Done from "@material-ui/icons/Done";
import {requestAction} from 'domain/typeConstants/action';
import {acceptActionDisableStatuses} from "./actionButton";


export const acceptActionItem = {
    name: requestAction.ACCEPT,
    label: 'Принять',
    icon: 'done',
    svgIcon: <Done/>,
    hidden: (itemMenuRecord, entities, currentRecord) => {
        if (currentRecord) {
            if (!currentRecord.status) return false
            return acceptActionDisableStatuses.includes(currentRecord.status.name)
        } else if (itemMenuRecord.length !== 0) {
            if (!entities[0].status) return false;
            return acceptActionDisableStatuses.includes(entities[0].status.name)
        }
        return true;
    },
};