/*
 *
 * AdministratorFormDialog15755
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { RECEPTION_REQUESTS_FORM_DIALOG, RECEPTION_REQUESTS_FORM, RECEPTION_REQUESTS_TABLE_GRID } from 'pages/Requests/ReceptionView/constants';
import { REQUESTS } from 'domain/Data/constants';

export class AdministratorFormDialog15755 extends React.Component {

  render() {
    const { form, detailContainer } = this.props;
    return (
      <AdvancedFormDialog
        name={RECEPTION_REQUESTS_FORM_DIALOG}
        formName={RECEPTION_REQUESTS_FORM}
        dataTableName={RECEPTION_REQUESTS_TABLE_GRID}
        advancedForm={form}
        addModeTitle="Новая заявка"
        editModeTitle="Изменение заявки"
        viewModeTitle="Просмотр заявки"
        copyModeTitle="Копирование заявки"
        addModeSaveButtonLabel="Сохранить и Отправить"
        copyModeSaveButtonLabel="Сохранить и Отправить"
        detailContainer={detailContainer}
        entityName={REQUESTS}
      />
    );
  }
}


AdministratorFormDialog15755.propTypes = {
  form: PropTypes.node.isRequired,
  mode: PropTypes.string,
};

export default AdministratorFormDialog15755;
