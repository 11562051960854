/**
 *
 * CheckpointDxGrid14326
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';

import { requestColumnSizes } from 'domain/typeConstants/table';

export class CheckpointDxGrid14326 extends CheckpointDxGrid { // eslint-disable-line react/prefer-stateless-function
  getColumns() {
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];

    const columns = super.getColumns();

    columns.push({
      path: ['company', 'phone'],
      name: 'company.phone',
      getCellValue: (row) => row.company ? row.company.phone : undefined,
      title: 'Телефон',
      width: requestColumnSizes.phone,
      styles,
    });
    columns.push({
      path: ['groupRequestVisitorsData'],
      name: 'groupRequestVisitorsData',
      getCellValue: (row) => row.groupRequestVisitorsData ? row.groupRequestVisitorsData : undefined,
      title: 'Список посетителей',
      width: 150,
      styles,
    });

    return columns;
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();

    columnFilters.push({
      columnName: 'company.phone', value: '',
    });

    columnFilters.push({
      columnName: 'groupRequestVisitorsData', value: '',
    });

    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();

    const companyNameIndex = columnOrder.findIndex((columnName) => columnName === 'tenantCompanyName');
    columnOrder.splice(companyNameIndex + 1, 0, 'company.phone');

    columnOrder.push('groupRequestVisitorsData');

    return columnOrder;
  }
}
