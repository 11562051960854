/**
 *
 * ServiceRequestManagerView sagas
 *
 */


import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  openAddDialog,
  openEditDialog,
  closeDialog,
} from 'containers/AdvancedFormDialog/actions';

import { loadData } from 'containers/AdvancedDataTable/actions';

import { makeSelectEntity } from 'domain/Data/selectors';

import {
  processVisitorDepartureSaga, closeRequestsSaga, sendToAgreementByTenantSaga, sendToPerformerSaga,
} from 'domain/Data/Request/sagas';

import {
  openAgreementDialog,
} from 'pages/Requests/common/AgreementDialog/actions';

import {
  SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID,
  SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM_DIALOG,
  SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG,

  EXECUTE_SEND_EMAIL_ACTION,
  EXECUTE_SEND_TO_AGREEMENT_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
  EXECUTE_ADD_BLACK_LIST_ITEM_ACTION,
  SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG,
} from 'pages/Requests/ServiceRequestsManagerView/constants';

import {
  EXECUTE_REJECT_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_SEND_TO_AGREMENT_ACTION,
  EXECUTE_SEND_TO_PERFORMER,
} from './constants';

import {
  rejectServiceRequests,
  serviceRequestsRejected,
  serviceRequestsRejectingError,

  acceptServiceRequests,
  serviceRequestsAccepted,
  serviceRequestsAcceptingError,

  sendEmail,
  sendedEmail,
  sendingEmailError,
  updateFormField,
} from './actions';

import * as api from '../../../../../utils/api';
import { changeEntitiesState } from '../../../../../domain/Data/actions';
import { REQUESTS } from '../../../../../domain/Data/constants';
import { makeSelectEntityId } from '../../../../../containers/AdvancedFormDialog/selectors';
import { saveDialogSaga } from "../../../../../containers/AdvancedFormDialog/sagas";
import { makeSelectFormName } from 'containers/AdvancedFormDialog/selectors';
import { makeSelectCurrentValues } from 'containers/AdvancedForm/selectors';
import { SERVICE, CARRYING_OUT_WORKS } from 'domain/Data/RequestType/constants';
import { managementCompanyComment, assignee } from '../common/fields';

export default function* rootSaga() {
  yield takeLatest(EXECUTE_SEND_EMAIL_ACTION, executeSendEmailActionSaga);
  yield takeLatest(EXECUTE_SEND_TO_AGREEMENT_ACTION, executeSendToAgreementActionSaga);
  yield takeLatest(EXECUTE_ARRIVED_ACTION, executeArrivedActionSaga);
  yield takeLatest(EXECUTE_DEPARTED_ACTION, executeDepartedActionSaga);
  yield takeLatest(EXECUTE_ADD_BLACK_LIST_ITEM_ACTION, executeAddBlackListCarActionSaga);
  yield takeLatest(EXECUTE_ACCEPT_ACTION, executeAcceptActionSaga);
  yield takeLatest(EXECUTE_REJECT_ACTION, executeRejectActionSaga);
  yield takeLatest(EXECUTE_CLOSE_ACTION, executeCloseActionSaga);
  yield takeLatest(EXECUTE_SEND_TO_AGREMENT_ACTION, executeSendToAgrementActionSaga);
  yield takeLatest(EXECUTE_SEND_TO_PERFORMER, executeSendToPerformerActionSaga);
}


function* executeSendToPerformerActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  const formName = yield select(makeSelectFormName(dialogName));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  if (currentValues.type === SERVICE) {
    let field = { ...assignee, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }
  const result = yield call(saveDialogSaga, dialogName);
  if (result !== null) {
    yield call(sendToPerformerSaga, [requestsId]);
    yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
    yield put(closeDialog(dialogName));
  }
}

function* executeCloseActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  yield call(closeRequestsSaga, [requestsId]);
  yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
  yield put(closeDialog(dialogName));
}

function* executeSendToAgrementActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  const formName = yield select(makeSelectFormName(dialogName));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  if (currentValues.type === CARRYING_OUT_WORKS) {
    const field = { ...managementCompanyComment, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }
  const result = yield call(saveDialogSaga, dialogName);
  if (result !== null) {
    yield call(sendToAgreementByTenantSaga, [requestsId]);
    yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
    yield put(closeDialog(dialogName));
  }
}

function* executeSendEmailActionSaga({ payload: { ids } }) {
  yield call(sendEmailActionSaga, ids);
  yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
}

function* sendEmailActionSaga(ids) {
  yield put(sendEmail(ids[0]));

  try {
    const response = yield call(api.post, `requests/${ids}/sendEmail`, { ids });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(sendedEmail());
  } catch (err) {
    yield put(sendingEmailError(err));
  }
}

function* executeSendToAgreementActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openAgreementDialog(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID, { id }));
  }
}

function* executeArrivedActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    yield put(openEditDialog(SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM_DIALOG, { id: ids[0] }));
  }
}

function* executeDepartedActionSaga({ payload: { ids } }) {
  yield call(processVisitorDepartureSaga, ids);
  yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
}

function* executeAddBlackListCarActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    yield put(openAddDialog(SERVICE_REQUESTS_MANAGER_BLACK_LIST_FORM_DIALOG, { params: { request } }));
  }
}

export function* acceptActionSaga(requestId) {
  yield put(acceptServiceRequests());

  try {
    const response = yield call(api.post, `requests/${requestId}/accepted`, { requestId });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(serviceRequestsAccepted());
  } catch (err) {
    yield put(serviceRequestsAcceptingError(err));
  }
}

function* executeAcceptActionSaga({ meta: { dialogName } }) {
  const requestsId = yield select(makeSelectEntityId(dialogName));
  const formName = yield select(makeSelectFormName(dialogName));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  if (currentValues.type === SERVICE) {
    const field = { ...managementCompanyComment, ...{ required: true } }
    yield put(updateFormField(formName, field));
  }
  const result = yield call(saveDialogSaga, dialogName);
  if (result !== null) {
    yield call(acceptActionSaga, requestsId);
    yield put(closeDialog(dialogName));
    yield put(loadData(SERVICE_REQUESTS_MANAGER_REQUESTS_TABLE_GRID));
  }
}

export function* rejectActionSaga(requestId) {
  yield put(rejectServiceRequests());

  try {
    const response = yield call(api.post, `requests/${requestId}/rejected`, { requestId });
    yield put(changeEntitiesState(REQUESTS, response.data));
    yield put(serviceRequestsRejected());
  } catch (err) {
    yield put(serviceRequestsRejectingError(err));
  }
}

function* executeRejectActionSaga({ meta: { dialogName } }) {
  const serviceRequestsId = yield select(makeSelectEntityId(dialogName));
  yield put(closeDialog(dialogName));
  yield put(openEditDialog(SERVICE_REQUESTS_MANAGER_REJECT_FORM_DIALOG, { id: serviceRequestsId }));
}
