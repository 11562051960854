/**
 *
 * ReceptionView sagas
 *
 */

import { call, select, put, takeLatest, fork } from 'redux-saga/effects';
import { actionTypes, stopAsyncValidation } from 'redux-form/immutable';
import * as api from 'utils/api';
import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import {
  acceptRequestsSaga,
  processVisitorDepartureSaga,
  sendToAgreementByTenantSaga,
  closeRequestsSaga,
  firedRequestsSaga, processVisitorArrivalSaga,
} from 'domain/Data/Request/sagas';
import {CLOSED, PASS_ISSUED} from 'domain/Data/RequestStatus/constants';
import { SERVICE } from 'domain/Data/RequestType/constants';
import { DISPLAY_NOTIFICATION } from 'domain/Notifications/constants';
import {
  makeSelectEntities,
  makeSelectEntity,
} from 'domain/Data/selectors';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openEditDialog,
  openCopyDialog,
  openViewDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
  openAlertDialog,
} from 'containers/AlertDialog/actions';

import {
  makeSelectCurrentValues,
} from 'containers/AdvancedForm/selectors';

import {
  validateParkingPlace,
} from 'utils/validators';

import {
  loadOptions,
  openAgreementDialog,
} from '../common/AgreementDialog/actions';

import {
  RECEPTION_REQUESTS_TABLE_GRID,
  RECEPTION_REQUESTS_FORM,
  RECEPTION_REQUESTS_FORM_DIALOG,
  REJECT_FORM_DIALOG,
  VISITOR_ARRIVAL_FORM_DIALOG,
  BLACK_LIST_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_ACCEPT_ACTION,
  EXECUTE_REJECT_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
  EXECUTE_ADD_BLACK_LIST_ITEM_ACTION,
  EXECUTE_SEND_TO_AGREEMENT_ACTION,
  EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION,
  HANDLE_PARAMS_ACTION,
  RECEPTION_WORK_FORM_DIALOG,
  EXECUTE_FIRED_ACTION, EXECUTE_ARRIVED_GROUP_ACTION,
} from './constants';

import createServiceRequestsManagerViewSaga from "../ServiceRequestsManagerView/sagas";
import { clearDetailRowData } from 'containers/AdvancedForm/actions'
import { WORK_GRID } from 'pages/common/constants';
import { WORKS } from 'domain/Data/constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { changeEntitiesState } from 'domain/Data/actions';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS &&
  (action.meta.dialog === RECEPTION_REQUESTS_FORM_DIALOG ||
    action.meta.dialog === VISITOR_ARRIVAL_FORM_DIALOG ||
    action.meta.dialog === BLACK_LIST_FORM_DIALOG ||
    action.meta.dialog === REJECT_FORM_DIALOG ||
    action.meta.dialog === RECEPTION_WORK_FORM_DIALOG
  );

const isAsyncValidationStarted = (action) => action.type === actionTypes.START_ASYNC_VALIDATION &&
  action.meta.form === RECEPTION_REQUESTS_FORM;

export default function createReceptionViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_COPY_ACTION, (config.executeCopyActionSaga || executeCopyActionSaga));
    yield takeLatest(EXECUTE_CLOSE_ACTION, (config.executeCloseActionSaga || executeCloseActionSaga));
    yield takeLatest(EXECUTE_FIRED_ACTION, (config.executeCloseActionSaga || executeFiredActionSaga));
    yield takeLatest(EXECUTE_ACCEPT_ACTION, (config.executeAcceptActionSaga || executeAcceptActionSaga));
    yield takeLatest(EXECUTE_REJECT_ACTION, (config.executeRejectActionSaga || executeRejectActionSaga));
    yield takeLatest(EXECUTE_ARRIVED_ACTION, (config.executeArrivedActionSaga || executeArrivedActionSaga));
    yield takeLatest(EXECUTE_ARRIVED_GROUP_ACTION, (config.executeArrivedGroupActionSaga || executeArrivedGroupActionSaga));
    yield takeLatest(EXECUTE_DEPARTED_ACTION, (config.executeDepartedActionSaga || executeDepartedActionSaga));
    yield takeLatest(EXECUTE_ADD_BLACK_LIST_ITEM_ACTION, (config.executeAddBlackListCarActionSaga || executeAddBlackListCarActionSaga));
    yield takeLatest(EXECUTE_SEND_TO_AGREEMENT_ACTION, (config.executeSendToAgreementActionSaga || executeSendToAgreementActionSaga));
    yield takeLatest(EXECUTE_SEND_TO_AGREEMENT_BY_TENANT_ACTION, (config.executeSendToAgreementByTenantActionSaga || executeSendToAgreementByTenantActionSaga));
    yield takeLatest(HANDLE_PARAMS_ACTION, (config.handleParamsSaga || handleParamsSaga));
    yield takeLatest(isAsyncValidationStarted, (config.asyncValidationSaga || asyncValidationSaga));
    yield takeLatest([needViewRefresh, DISPLAY_NOTIFICATION], (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
    yield fork(createServiceRequestsManagerViewSaga());
  };
}

function* executeNeedViewRefreshSaga() {
  const mode = yield select(makeSelectViewMode());
  if (mode === 'mobile') {
    yield put(loadMobileData(RECEPTION_REQUESTS_TABLE_GRID))
  } else yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

function* executeAddActionSaga() {
  yield put(clearDetailRowData(WORK_GRID, WORKS))
  yield put(openAddDialog(RECEPTION_REQUESTS_FORM_DIALOG, {}));
}

function* executeEditActionSaga({ payload: { id } }) {
  yield put(clearDetailRowData(WORK_GRID, WORKS))
  const requests = yield select(makeSelectEntity('requests', id));
  if (requests.status === CLOSED) {
    yield put(openViewDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id }));
  } else if (id) {
    const status = requests.status;
    const parentId = null;
    yield put(openEditDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id, parentId, status }));
  }
}

function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id }));
  }
}

function* executeCopyActionSaga({ payload: { id } }) {
  if (id) {
    yield put(clearDetailRowData(WORK_GRID, WORKS))
    yield put(openCopyDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id }));
  }
}

export function* executeCloseActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(closeRequestsSaga, ids);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export function* executeFiredActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(firedRequestsSaga, ids);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

function* executeAcceptActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(acceptRequestsSaga, ids);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

function* executeArrivedGroupActionSaga({ payload: { ids } }) {
  yield call(processVisitorArrivalSaga, ids, PASS_ISSUED);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

function* executeRejectActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    yield put(openEditDialog(REJECT_FORM_DIALOG, { id: ids[0] }));
  }
}

function* executeArrivedActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    yield put(openEditDialog(VISITOR_ARRIVAL_FORM_DIALOG, { id: ids[0] }));
  }
}

function* executeDepartedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorDepartureSaga, ids);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

function* executeAddBlackListCarActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    const request = yield select(makeSelectEntity('requests', ids[0]));
    yield put(openAddDialog(BLACK_LIST_FORM_DIALOG, { params: { request } }));
  }
}

function* executeSendToAgreementActionSaga({ payload: { id } }) {
  yield checkUserForBlockingSaga()
  if (id) {
    yield put(openAgreementDialog(RECEPTION_REQUESTS_TABLE_GRID, { id }));
  }
}

function* executeSendToAgreementByTenantActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(sendToAgreementByTenantSaga, ids);
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

function* handleParamsSaga({ payload: { requestId } }) {
  const response = yield call(api.load, 'requests', requestId);
  const request = response.data;
  if (!request) {
    return;
  }
  yield put(changeEntitiesState('requests', [request]));
  if (request.status && request.status.id === CLOSED) {
    yield put(openViewDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id: requestId }));
  } else {
    yield put(openEditDialog(RECEPTION_REQUESTS_FORM_DIALOG, { id: requestId }));
  }
}

function* asyncValidationSaga() {
  const requestData = yield select(makeSelectCurrentValues(RECEPTION_REQUESTS_FORM));
  const alertTitle = null;
  let alertContent = null;

  if (requestData.visitorFullName !== null || requestData.carNumber !== null) {
    const result = yield call(searchBlackListItems, requestData.visitorFullName, requestData.carNumber);
    if (requestData.type !== SERVICE && result.length > 0) {
      const isLevel = result.some((item) => item.level === true);
      const element = result.find((item) => {
        if (item.level === true) return item;
        return null;
      });

      if (element && isLevel) {
        alertContent = 'Посетитель в черном списке, обратитесь в управляющую компанию\n';
      }
    }
  }

  if (requestData.parkingPlace) {
    let parkings = yield select(makeSelectEntities());
    parkings = parkings.get('parkings').toArray();
    alertContent = validateParkingPlace(requestData, parkings, alertContent);
  }

  if (alertContent && alertContent.length > 0) {
    yield put(openAlertDialog(alertTitle, alertContent));
  }

  yield put(stopAsyncValidation(RECEPTION_REQUESTS_FORM));
}

function* searchBlackListItems(visitorFullName, carNumber) {
  const options = {};

  if (visitorFullName) {
    options.filterBy = `visitorFullName.ToLower().Trim() == "${visitorFullName.replace(/"/g, '\\"')}".Replace("\\"", "\\\\\\"")`;
  }

  if (carNumber) {
    options.filterBy = options.filterBy ? `${options.filterBy} || ` : '';
    options.filterBy += `carNumber.ToLower().Replace(" ", "") == "${carNumber}"`;
  }


  const response = yield call(api.loadPage, 'blackList', options);
  return response.data;
}
