/**
 *
 * Modal window for ColumnChoiser
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Switch from '@material-ui/core/Switch';

const styles = () => ({
  item: {
    padding: 0
  },
});

class ColumnChoiser extends React.PureComponent {

  render() {
    const {
      anchorElTopPosition,
      anchorElRightPosition,
      onColumnClick,
      classes,
      onClose,
      open,
      items,
    } = this.props;

    return (
      <Popover
        anchorOrigin={{
          horizontal: anchorElRightPosition || 'right',
          vertical: anchorElTopPosition || 'top',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
        open={open}
      >
        <List>
          {
            items.map((item) => {
              return (
                <ListItem
                  button
                  className={classes.item}
                  key={item.name}
                  onClick={() => onColumnClick(item)}
                >
                  <Switch
                    checked={item.visible}
                    color="primary"
                    value={item.name}
                    size="small"
                    style={{ height: 32 }}
                  />
                  <ListItemText primary={item.title} />
                </ListItem>
              );
            }
            )
          }
        </List>
      </Popover>
    );
  }
}

ColumnChoiser.propTypes = {
  anchorElLeftPosition: PropTypes.number,
  anchorElTopPosition: PropTypes.number,
  classes: PropTypes.object,
  onColumnClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ColumnChoiser);
