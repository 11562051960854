/*
 *
 * KhimkiReceptionAcceptForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { ACCEPTED, SEND_TO_PERFORMER } from 'domain/Data/RequestStatus/constants';
import { KHIMKI_RECEPTION_ACCEPT_FORM } from './constants';
import { SERVICE, CARRYING_OUT_WORKS } from 'domain/Data/RequestType/constants';
import { managementCompanyComment, memorandumScan } from '../common/fields';

class KhimkiReceptionAcceptForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: () => false,
        calculatedValue: (values) => values.type === SERVICE ? SEND_TO_PERFORMER : ACCEPTED,
      },
      {
        name: 'number',
        label: '№',
        disabled: true,
      },
      {
        name: 'assignee',
        type: 'select',
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        rowBreakBefore: true,
        rowBreakAfter: true,
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => {
            if (item.isPerformer) assignee.push(item.id);
          });
          return assignee;
        },
        calculatedValue: (values, mode) => {
          if (mode !== 'copy' && values.type === SERVICE) return values.assignee;
          return null;
        },
        visible: (values) => values.type === SERVICE
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        required: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        visible: (values) => values.type === SERVICE
      },
      { ...memorandumScan, ...{ visible: (values) => values.type === CARRYING_OUT_WORKS } },
      { ...managementCompanyComment, ...{ visible: (values) => values.type === CARRYING_OUT_WORKS } },
    ];
  }

  render() {
    return (
      <AdvancedForm
        name={KHIMKI_RECEPTION_ACCEPT_FORM}
        entityName="requests"
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default KhimkiReceptionAcceptForm;
