/*
 *
 *  SupplyRequestMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import {
    ACTIVE_SUPPLY_REQUESTS,
    ALL_SUPPLY_REQUESTS,
    DOCUMENTS_FOR_MY_VISA,
    MY_AGREEMENT_HISTORIES
} from "domain/Data/Filters/constants";
import {
    addActionButton,
} from '../common/actionButton';
import {
    agreeActionItem,
    disagreeActionItem,
    viewActionItem,
    editActionItem,
} from '../common/actionMenu';

import { SUPPLY_REQUEST_DX_GRID } from './constants';

import { getRowHeight, SupplyRequestRowComponent } from "../common/mobile";
import { statusColorLabels } from "../common/mobile";
import { columnType } from "domain/typeConstants/table";

class SupplyRequestMobileGrid extends React.Component {
    getFilters() {
        const { viewName } = this.props;
        const filters = [];
        if (viewName === 'allSupplyRequests') filters.push(ALL_SUPPLY_REQUESTS);
        if (viewName === 'activeSupplyRequests') {
            filters.push(ALL_SUPPLY_REQUESTS)
            filters.push(ACTIVE_SUPPLY_REQUESTS)
        }
        return filters;
    }

    getRowComponent() { return SupplyRequestRowComponent }

    getDetailRowFields() {
        return [
            {
                path: 'number',
                name: 'number',
                title: '№',
            },
            {
                path: 'state',
                name: 'state.name',
                title: 'Состояние',
                getValue: (row) => row.state ? row.state.name : undefined,
                labels: statusColorLabels,
                type: columnType.LABEL,
            },
            {
                path: 'actualCreationDate',
                name: 'actualCreationDate',
                title: 'Дата',
                type: 'date',
            },
            // {
            //     path: 'category',
            //     name: 'category.name',
            //     title: 'Тип',
            //     getValue: (row) => (row.category ? row.category.name : undefined),
            // },
            // {
            //     path: 'company',
            //     name: 'company_name',
            //     getValue: (row) => (row.company ? row.company.name : undefined),
            //     title: 'Контрагент',
            // },
            {
                path: 'content',
                name: 'content',
                title: 'Содержание',
            },
        ];
    }

    getActionButtons() {
        return [addActionButton];
    };

    getActionMenuItems() {
        return [
            agreeActionItem,
            disagreeActionItem,
            viewActionItem,
            editActionItem,
        ];
    };

    getSorting() {
        return [{ columnId: 'actualCreationDate', columnName: 'actualCreationDate', direction: 'desc' }];
    };

    render() {
        const { onActionExecuting, viewName } = this.props;

        return (
            <AdvancedMobileGrid
                entityName='documents'
                name={SUPPLY_REQUEST_DX_GRID}
                actionButtons={this.getActionButtons()}
                actionMenuItems={this.getActionMenuItems()}
                detailRowFields={this.getDetailRowFields()}
                filters={this.getFilters()}
                getRowHeight={getRowHeight}
                onActionExecuting={onActionExecuting}
                rowComponent={this.getRowComponent()}
                sorting={this.getSorting()}
                viewName={viewName}
            />
        );
    }
}

SupplyRequestMobileGrid.propTypes = {
    onActionExecuting: PropTypes.func,
    viewName: PropTypes.string,
};

export default SupplyRequestMobileGrid;
