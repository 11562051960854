/**
 *
 * 11994 reception dxGridColumns
 *
 */

import { columnType } from 'domain/typeConstants/table';
import { columnLabels } from '../../common/columnLabels';

const blackListStyle = {
  style: { color: '#d50000' },
  condition: (value) => value.isBlackList,
};
const styles = [blackListStyle];

export const ALL_COLUMNS = [
  {
    path: 'number',
    name: 'number',
    title: '№',
    type: columnType.NUMERIC,
    styles,
  },
  {
    path: 'visitorFullName',
    name: 'visitorFullName',
    title: 'ФИО посетителя',
    styles,
  },
  {
    path: 'carNumber',
    name: 'carNumber',
    title: '№ авто',
    styles,
  },
  {
    path: 'carModelText',
    name: 'carModelText',
    title: 'Марка',
    styles,
  },
  {
    path: 'visitDate',
    name: 'visitDate',
    title: 'Дата',
    type: columnType.DATE,
    styles,
  },
  {
    path: 'expirationDate',
    name: 'expirationDate',
    title: 'До',
    type: columnType.DATE,
    styles,
  },
  {
    path: ['status', 'name'],
    name: 'statusName',
    getCellValue: (row) => (row.status ? row.status.name : undefined),
    title: 'Статус',
    type: columnType.LABEL,
    labels: columnLabels,
  },
  {
    path: ['type', 'shortName'],
    name: 'typeShortName',
    getCellValue: (row) => (row.type ? row.type.shortName : undefined),
    title: 'Вид',
    styles,
  },
  {
    path: ['company', 'name'],
    name: 'company_name',
    getCellValue: (row) => (row.company ? row.company.name : undefined),
    title: 'Арендатор',
    styles,
  },
  {
    path: 'documentDetails',
    name: 'documentDetails',
    title: 'Серия и №',
    styles,
  },
  {
    path: ['accessCard', 'number'],
    name: 'accessCardNumber',
    getCellValue: (row) => (row.accessCard ? row.accessCard.number : undefined),
    title: '№ карты',
    styles,
  },
  {
    path: 'parkingPlace',
    name: 'parkingPlace',
    title: 'Место',
    styles,
  },
  {
    path: 'materialValuesData',
    name: 'materialValuesData',
    title: 'Мат. ценности',
    styles,
  },
  {
    path: 'companyName',
    name: 'companyName',
    title: 'Откуда посетитель',
    styles,
  },
  {
    path: 'note',
    name: 'note',
    title: 'Примечание',
    styles,
  },
  {
    path: 'blackListNote',
    name: 'blackListNote',
    title: 'Причина ЧС',
    styles,
  },
  {
    path: ['author', 'name'],
    name: 'authorName',
    getCellValue: (row) => (row.author ? row.author.name : undefined),
    title: 'Автор',
    styles,
  },
];

export const TODAY_COLUMNS = [
  {
    path: 'number',
    name: 'number',
    title: '№',
    type: columnType.NUMERIC,
    styles,
  },
  {
    path: 'visitorFullName',
    name: 'visitorFullName',
    title: 'ФИО посетителя',
    styles,
  },
  {
    path: 'visitDate',
    name: 'visitDate',
    title: 'Дата',
    type: columnType.DATE,
    styles,
  },
  {
    path: 'expirationDate',
    name: 'expirationDate',
    title: 'До',
    type: columnType.DATE,
    styles,
  },
  {
    path: ['status', 'name'],
    name: 'statusName',
    getCellValue: (row) => (row.status ? row.status.name : undefined),
    title: 'Статус',
    type: columnType.LABEL,
    labels: columnLabels,
  },
  {
    path: ['company', 'name'],
    name: 'company_name',
    getCellValue: (row) => (row.company ? row.company.name : undefined),
    title: 'Арендатор',
    styles,
  },
  {
    path: 'documentDetails',
    name: 'documentDetails',
    title: 'Серия и №',
    styles,
  },
  {
    path: ['accessCard', 'number'],
    name: 'accessCardNumber',
    getCellValue: (row) => (row.accessCard ? row.accessCard.number : undefined),
    title: '№ карты',
    styles,
  },
  {
    path: 'parkingPlace',
    name: 'parkingPlace',
    title: 'Место',
    styles,
  },
  {
    path: 'companyName',
    name: 'companyName',
    title: 'Откуда посетитель',
    styles,
  },
  {
    path: 'note',
    name: 'note',
    title: 'Примечание',
    styles,
  },
  {
    path: ['author', 'name'],
    name: 'authorName',
    getCellValue: (row) => (row.author ? row.author.name : undefined),
    title: 'Автор',
    styles,
  },
  {
    path: ['type', 'shortName'],
    name: 'typeShortName',
    getCellValue: (row) => (row.type ? row.type.shortName : undefined),
    title: 'Вид',
    styles,
  },
];
