/**
 *
 * MultiAutocomplete utils
 *
 */

export function getArrayFromString(record, fieldName) {
  const accessKey = `${fieldName}Text`;

  if (record && record[accessKey]) {
    return record[accessKey].split(',').map((item) => item.trim());
  }

  return [];
}

export function getIdsForFilter(record, fieldName) {
  if (record && record[fieldName]) {
    let result = '';
    const ids = record[fieldName];

    for (let i = 0, length = ids.length; i < length; i += 1) {
      if (i === 0) {
        result += `id="${ids[i]}"`;
      } else {
        result += `||id="${ids[i]}"`;
      }
    }

    return result;
  }

  return [];
}

export function getNamesForFilter(record, fieldName) {
  const accessKey = `${fieldName}Text`;

  if (record && record[accessKey]) {
    let result = '';
    const names = record[accessKey].split(',').map((item) => item.trim());

    for (let i = 0, length = names.length; i < length; i += 1) {
      if (i === 0) {
        result += `name=${names[i]}`;
      } else {
        result += `||name=${names[i]}`;
      }
    }

    return result;
  }

  return [];
}
