/*
 *
 * columns dxgrid 14419
 *
 */

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { columnStatusLabels } from 'pages/Requests/common/columnStatusLabel';

/* Service requests getCarryingOurWorkingTab 14419 */
export const getCarryingOurWorkingTab = () => ([
    {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
    },
    {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
    },
    {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
    },
    {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
    },
    {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
    },
    {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
    },
    {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
    },
    {
        path: ['company', 'name'],
        name: 'company_name',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.company_name,
    },
    {
        path: ['parking', 'name'],
        name: 'parkingName',
        getCellValue: (row) => (row.parking ? row.parking.name : undefined),
        title: 'Парковка',
        width: requestColumnSizes.parkingName,
    },
    {
        path: 'companyName',
        name: 'companyName',
        title: 'Подрядчик',
        width: requestColumnSizes.companyName,
    },
    {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
    },
    {
        path: 'managementCompanyComment',
        name: 'managementCompanyComment',
        title: 'Комментарий УК',
        width: requestColumnSizes.note,
    },
    {
        path: 'blackListNote',
        name: 'blackListNote',
        title: 'Причина ЧС',
        width: requestColumnSizes.blackListNote,
    },
    {
        path: ['author', 'name'],
        name: 'authorName',
        getCellValue: (row) => (row.author ? row.author.name : undefined),
        title: 'Автор',
        width: requestColumnSizes.authorName,
    },
    {
        path: 'whatHappened',
        name: 'whatHappened',
        title: 'Наименование работ',
        width: 150,
    },
    {
        path: ['factoryTime', 'name'],
        name: 'factoryTime',
        getCellValue: (row) => (row.factoryTime ? row.factoryTime.name : undefined),
        title: 'Время',
        width: 100,
    },
    {
        path: 'responsibleFromContractor',
        name: 'responsibleFromContractor',
        title: 'Отв. от порядчика',
        width: 150,
    },
    {
        path: 'responsibleFromContractorPhone',
        name: 'responsibleFromContractorPhone',
        title: 'Тел. отв.',
        width: 150,
    },
    {
        path: ['requestScan', 'name'],
        name: 'requestScan',
        getCellValue: (row) => row.requestScan ? row.requestScan.id : undefined,
        title: 'Скан заявки',
        type: columnType.FILE,
        width: columnSize.FILE,
    },
]);

export const carryingOurWorkingTabFilters = [
    { columnName: 'number', value: '' },
    { columnName: 'carNumber', value: '' },
    { columnName: 'carModelText', value: '' },
    { columnName: 'visitDate', value: '' },
    { columnName: 'expirationDate', value: '' },
    { columnName: 'statusName', value: '' },
    { columnName: 'typeShortName', value: '' },
    { columnName: 'company_name', value: '' },
    { columnName: 'parkingName', value: '' },
    { columnName: 'companyName', value: '' },
    { columnName: 'note', value: '' },
    { columnName: 'managementCompanyComment', value: '' },
    { columnName: 'blackListNote', value: '' },
    { columnName: 'authorName', value: '' },
    { columnName: 'whatHappened', value: '' },
    { columnName: 'factoryTime', value: '' },
    { columnName: 'responsibleFromContractor', value: '' },
    { columnName: 'responsibleFromContractorPhone', value: '' },
    { columnName: 'requestScan', value: '' },
];

export const carryingOurWorkingTabOrder = [
    'number', 'carNumber', 'carModelText', 'visitDate', 'expirationDate', 'statusName',
    'companyName', 'whatHappened', 'factoryTime',
    'responsibleFromContractor', 'responsibleFromContractorPhone', 'requestScan',
    'typeShortName',
    'company_name', 'parkingName', 'note', 'blackListNote', 'authorName', 'managementCompanyComment',
];


/* Checkpoint carryingOurWorkingTab 14419 */
export const getCheckpointCarryingOurWorkingTab = (styles) => ([
    {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
        styles,
    },
    {
        path: 'carNumber',
        name: 'carNumber',
        title: '№ авто',
        width: requestColumnSizes.carNumber,
        styles,
    },
    {
        path: 'carModelText',
        name: 'carModelText',
        title: 'Марка',
        width: requestColumnSizes.carModelText,
        styles,
    },
    {
        path: 'visitDate',
        name: 'visitDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
    },
    {
        path: 'expirationDate',
        name: 'expirationDate',
        title: 'До',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
    },
    {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
    },
    {
        path: ['type', 'shortName'],
        name: 'typeShortName',
        getCellValue: (row) => (row.type ? row.type.shortName : undefined),
        title: 'Вид',
        width: requestColumnSizes.typeShortName,
        styles,
    },
    {
        path: ['company', 'name'],
        name: 'tenantCompanyName',
        getCellValue: (row) => (row.company ? row.company.name : undefined),
        title: 'Арендатор',
        width: requestColumnSizes.tenantCompanyName,
        styles,
    },
    {
        path: 'arrivalDate',
        name: 'arrivalDate',
        title: 'Прибыл',
        type: columnType.TIME,
        width: columnSize.TIME,
        styles,
    },
    {
        path: 'departureDate',
        name: 'departureDate',
        title: 'Убыл',
        type: columnType.TIME,
        width: columnSize.TIME,
        styles,
    },
    {
        path: 'whatHappened',
        name: 'whatHappened',
        title: 'Наименование работ',
        width: 150,
        styles,
    },
    {
        path: ['factoryTime', 'name'],
        name: 'factoryTime',
        getCellValue: (row) => (row.factoryTime ? row.factoryTime.name : undefined),
        title: 'Время',
        width: 100,
        styles,
    },
    {
        path: 'companyName',
        name: 'companyName',
        title: 'Подрядчик',
        width: requestColumnSizes.companyName,
        styles,
    },
    {
        path: 'responsibleFromContractor',
        name: 'responsibleFromContractor',
        title: 'Отв. от порядчика',
        width: 150,
        styles,
    },
    {
        path: 'responsibleFromContractorPhone',
        name: 'responsibleFromContractorPhone',
        title: 'Тел. отв.',
        width: 150,
        styles,
    },
    {
        path: ['workType', 'name'],
        name: 'workType',
        getCellValue: (row) => row.workType ? row.workType.name : undefined,
        title: 'Вид работ',
        width: 150,
        styles,
    },
    {
        path: 'workPlace',
        name: 'workPlace',
        title: 'Место работ',
        width: 150,
        styles,
    },
    {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
        styles,
    },
    {
        path: 'blackListNote',
        name: 'blackListNote',
        title: 'Причина ЧС',
        width: requestColumnSizes.blackListNote,
        styles,
    },
    {
        path: 'passNumber',
        name: 'passNumber',
        title: '№ пропуска',
        width: requestColumnSizes.passNumber,
        styles,
    },
    {
        path: ['requestScan', 'name'],
        name: 'requestScan',
        getCellValue: (row) => row.requestScan ? row.requestScan.id : undefined,
        title: 'Скан заявки',
        type: columnType.FILE,
        width: columnSize.FILE,
    },
]);

export const checkpointCarryingOurWorkingTabFilters = [
    { columnName: 'number', value: '' },
    { columnName: 'carNumber', value: '' },
    { columnName: 'carModelText', value: '' },
    { columnName: 'visitDate', value: '' },
    { columnName: 'expirationDate', value: '' },
    { columnName: 'statusName', value: '' },
    { columnName: 'typeShortName', value: '' },
    { columnName: 'tenantCompanyName', value: '' },
    { columnName: 'arrivalDate', value: '' },
    { columnName: 'departureDate', value: '' },
    { columnName: 'whatHappened', value: '' },
    { columnName: 'factoryTime', value: '' },
    { columnName: 'companyName', value: '' },
    { columnName: 'responsibleFromContractor', value: '' },
    { columnName: 'responsibleFromContractorPhone', value: '' },
    { columnName: 'workType', value: '' },
    { columnName: 'workPlace', value: '' },
    { columnName: 'note', value: '' },
    { columnName: 'blackListNote', value: '' },
    { columnName: 'requestScan', value: '' },
    { columnName: 'passNumber', value: '' },
];

export const checkpointCarryingOurWorkingTabOrder = ['number', 'tenantCompanyName', 'carNumber', 'carModelText', 'visitDate',
    'expirationDate', 'statusName', 'typeShortName',
    'arrivalDate', 'departureDate', 'whatHappened', 'factoryTime', 'companyName',
    'responsibleFromContractor', 'responsibleFromContractorPhone', 'workType', 'workPlace', 'note', 'blackListNote', 'passNumber', 'requestScan',
];
