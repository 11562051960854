/*
 *
 * KhimkiReceptionAcceptFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { KHIMKI_RECEPTION_ACCEPT_FORM, KHIMKI_RECEPTION_ACCEPT_FORM_DIALOG } from './constants';

function KhimkiReceptionAcceptFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={KHIMKI_RECEPTION_ACCEPT_FORM_DIALOG}
      formName={KHIMKI_RECEPTION_ACCEPT_FORM}
      advancedForm={form}
      editModeTitle="Принять заявку"
    />
  );
}

KhimkiReceptionAcceptFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default KhimkiReceptionAcceptFormDialog;
