/*
 *
 * AdministratorBCRequestsDxGrid14691
 *
 */

import { ReceptionDxGrid14691 } from '../ReceptionView/ReceptionDxGrid14691';

import {
  CURRENT_DAY_REQUESTS,
  ON_CREATE_REQUESTS,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_FOR_ON_PASS,
} from 'domain/Data/Filters/constants';

import {
  agreeTabColumnFilters,
  agreeTabColumnOrder,
  getAgreeTabColumns,
} from '../common/dxGridComponents/columns';
import { requestAction } from 'domain/typeConstants/action';
import { acceptActionButton, copyActionButton } from "../common/actionButton";
import { acceptActionItem, copyActionItem } from "../common/actionMenuItem";

export class AdministratorBCRequestsDxGrid14691 extends ReceptionDxGrid14691 {
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') filters.push(ON_CREATE_REQUESTS, REQUESTS_FOR_ON_PASS);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    return filters;
  }

  getColumns() {
    const { viewName } = this.props;
    let columns = super.getColumns();

    if (viewName === 'agree') {
      columns = getAgreeTabColumns();
    }
    return columns;
  }

  getGridFilters() {
    const { viewName } = this.props;
    let gridFilters = super.getGridFilters();

    if (viewName === 'agree') gridFilters = agreeTabColumnFilters;

    return gridFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    let columnOrder = super.getColumnOrder();

    if (viewName === 'agree') columnOrder = agreeTabColumnOrder;

    return columnOrder;
  }

  getActionButtons() {
    const { viewName } = this.props;
    const actionButtons = super.getActionButtons();
    if (viewName === 'agree') {
      const acceptIndex = actionButtons.findIndex((button) => button.name === requestAction.ACCEPT);
      actionButtons[acceptIndex].label = 'Согласовать';
    } else {
      const acceptIndex = actionButtons.findIndex((button) => button.name === requestAction.ACCEPT);
      actionButtons[acceptIndex].label = 'Принять';
    }

    const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);

    const copyActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.COPY);
    actionButtons.splice(copyActionButtonIndex, 1, copyActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();
    const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
    actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);

    const copyMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.COPY);
    actionMenuItems.splice(copyMenuItemIndex, 1, copyActionItem);

    return actionMenuItems;
  }

}
