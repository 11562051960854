/**
 *
 * Screen actions
 *
 */

import {
  INITIALIZE_SCREEN_PROPERTIES,
  CHANGE_BREAKPOINT,
  CHANGE_SCREEN_PROPERTIES,
} from './constants';

export const initializeScreenProperties = ({
  height, width, orientation, breakpoint, viewMode
}) => ({
  type: INITIALIZE_SCREEN_PROPERTIES,
  payload: { height, width, orientation, breakpoint, viewMode },
});

export const changeBreakpoint = (breakpoint) => ({
  type: CHANGE_BREAKPOINT,
  payload: { breakpoint },
});

export const changeScreenProperties = ({
  height, width, orientation, breakpoint, viewMode,
}) => ({
  type: CHANGE_SCREEN_PROPERTIES,
  payload: { height, width, orientation, breakpoint, viewMode },
});
