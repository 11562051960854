/**
*
* DataTableHeader
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import ToolsTableHeaderCell from './ToolsTableHeaderCell';

const styleSheet = {
  headTable: {
    top: 0,
    zIndex: 1,
    position: "sticky",
    overflow: "visible",
    background: "#fff",
  },
  row: {
    height: "32px",
    backgroundColor: "rgb(207, 216, 220)"
  },
  cell: {
    position: "relative",
    paddingRight: 12,
    paddingLeft: 6,
  },
  cellResize: {
    top: 0,
    width: 8,
    right: 3,
    height: "100%",
    cursor: "col-resize",
    zIndex: 100,
    position: "absolute",
  },
  resizeMarkCommon: {
    top: "25%",
    width: 1,
    height: "50%",
    position: "absolute",
    transition: "all linear 100ms",
    backgroundColor: "#64b5f6",
    opacity: 0
  },
  resizeMarkLeft: {
    left: 3,
  },
  resizeMarkRight: {
    right: 2,
  },
  showDividers: {
    opacity: 1
  },
  sortLabel: {
    position: 'relative',
  },
  nonSortableLabel: {
    cursor: "auto",
  },
  filteredLabel: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#2f4f4f'
  },
  columnTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  columnSortLabel: {
    position: 'absolute',
    top: '3px',
  },
  columnContainer: {
    width: '100%',
    height: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
};

class DataTableHeader extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showDivider: null,
      draggedColumn: null
    };
  }

  getColumnDirection = (columnId, sorting) => {
    const column = sorting
      .find((sortingRule) => sortingRule.columnId === columnId);

    return column ? column.direction : null;
  }

  columnIsActive = (columnId, sorting) => sorting.findIndex((sortingRule) =>
    sortingRule.columnId === columnId
  ) !== -1;

  createSortLabelClickHandler = (column) => (event) => {
    const { onSortLabelClick } = this.props;
    if (onSortLabelClick && column.sortable) onSortLabelClick(event, column);
  };

  // ресайз колонок
  handleMouseDown = (column) => (event) => {
    const { columnWidths, onColumnWidthChange } = this.props
    const coords = event.target.parentNode.parentNode.getBoundingClientRect();
    const resizeContainerWidth = 8
    document.onmousemove = e => {
      const delta = e.pageX - coords.right + resizeContainerWidth
      const colWidth = columnWidths.find(c => c.columnName === column.name).width
      if (colWidth + delta > 0) {
        const newColumnWidths = columnWidths.map(col => col.columnName === column.name ? { ...col, width: col.width + delta } : col)
        if (onColumnWidthChange) onColumnWidthChange(newColumnWidths)
      } else document.onmousemove = null
    }
    document.onmouseup = () => { document.onmousemove = null }
  }

  // перенос колонки
  handleOver = (e) => { this.setState({ showDivider: null }) };
  handleOut = (column) => { this.setState({ showDivider: column }) };
  dragStartHandler = (e, column) => { this.setState({ draggedColumn: column.name }) }
  dragOverHandler = (e) => { e.preventDefault() }

  dropHandler = (e, column) => {
    e.preventDefault()
    const { columnOrder, onColumnOrderChange } = this.props
    const { draggedColumn } = this.state
    let newColumnOrder = [...columnOrder]
    const currentOrderIndex = columnOrder.findIndex(col => col === draggedColumn)
    const newOrderIndex = columnOrder.findIndex(col => col === column.name)
    newColumnOrder[currentOrderIndex] = columnOrder[newOrderIndex]
    newColumnOrder[newOrderIndex] = columnOrder[currentOrderIndex]

    this.setState({ draggedColumn: null })
    if (onColumnOrderChange) onColumnOrderChange(newColumnOrder)
  }

  render() {
    const { selectable, allSelected, gridFilters, classes, columns, columnOrder,
      columnWidths, sorting, onSelectAllClick, width, lastColumnWidth, toolWidth, settingsConfig, columnVisibilityNames, mode,
    } = this.props;

    const filteredColumns = gridFilters ? gridFilters.filter(g => g.value !== '').map(c => c.columnName) : []
    const isResizing = settingsConfig && settingsConfig[settings.RESIZING].enabled === true || false
    const isReordering = settingsConfig && settingsConfig[settings.REORDERING].enabled === true || false
    const nonSortableColumns = columns.filter(c => !c.sortable).map(c => c.name) || []

    return (
      { columns } ?
        <Table
          className={classes.headTable}
          style={{ width: width + lastColumnWidth }}
          id={`tableHeader-${mode || 'parent'}`}
        >
          <colgroup>
            <col key={"tools"} style={{ width: toolWidth }}></col>
            {columnOrder.map(o => {
              const column = columnWidths.find(col => col.columnName === o
                && !columnVisibilityNames.includes(col.columnName)
              )
              return column && <col key={column.columnName} style={{ width: column.width }}></col>
            })}

            {lastColumnWidth > 0 && <col key={"last_col"} style={{ width: `${lastColumnWidth}px` }}></col>}
          </colgroup>
          <TableHead>
            <TableRow className={classes.row}>
              {<ToolsTableHeaderCell
                selectable={selectable}
                allSelected={allSelected}
                onSelectAllClick={onSelectAllClick}
              />
              }
              {
                columnOrder.map(o => {
                  const column = columns.find(col => col.name === o && !columnVisibilityNames.includes(col.name))
                  if (column) {
                    const active = this.columnIsActive(column.id, sorting);
                    const direction = this.getColumnDirection(column.id, sorting) || 'desc';
                    return (
                      <TableCell
                        key={column.id}
                        numeric={column.numeric}
                        className={classes.cell}
                        onMouseOut={this.handleOver}
                        onMouseOver={() => this.handleOut(column.name)}
                        onDragStart={(e) => this.dragStartHandler(e, column)}
                        onDragOver={(e) => this.dragOverHandler(e)}
                        onDrop={(e) => this.dropHandler(e, column)}
                      >
                        <div className={classes.columnContainer}>
                          <div className={classNames(
                            classes.sortLabel,
                            { [classes.filteredLabel]: filteredColumns.includes(column.name) }
                          )}>
                            <TableSortLabel
                              active={active}
                              direction={direction}
                              onClick={this.createSortLabelClickHandler(column)}
                              draggable={isReordering}
                              className={classes.columnSortLabel}
                            >
                              <span className={classNames(classes.columnTitle,
                                { [classes.nonSortableLabel]: nonSortableColumns.includes(column.name) }
                              )}>
                                {column.title}
                              </span>
                            </TableSortLabel>
                          </div>
                        </div>
                        <div
                          className={classNames({
                            [classes.cellResize]: isResizing
                          })}
                          key={column.name}
                          data-name={column.name}
                          onMouseDown={isResizing ? this.handleMouseDown(column) : undefined}
                        >
                          <div className={classNames(
                            classes.resizeMarkCommon,
                            classes.resizeMarkLeft,
                            { [classes.showDividers]: isResizing && this.state.showDivider === column.name }
                          )} />
                          <div className={classNames(
                            classes.resizeMarkCommon,
                            classes.resizeMarkRight,
                            { [classes.showDividers]: isResizing && this.state.showDivider === column.name }
                          )} />
                        </div>
                      </TableCell>
                    );
                  }
                }, this)}
              {lastColumnWidth > 0 && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
        </Table >
        : <></>
    );
  }
}

DataTableHeader.propTypes = {
  selectable: PropTypes.bool,
  allSelected: PropTypes.bool,
  classes: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string,
    })
  ).isRequired,
  sorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    })
  ),
  onSelectAllClick: PropTypes.func,
  onSortLabelClick: PropTypes.func,
};

export default withStyles(styleSheet)(DataTableHeader);
