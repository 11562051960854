/**
 *
 * TaskView constants
 *
 */

export const USER_TASK_TABLE_GRID = 'UserTaskTableGrid';
export const USER_TASK_FORM = 'UserTaskForm';
export const USER_TASK_FORM_DIALOG = 'UserTaskFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/TaskPage/TaskView/EXECUTE_ADD_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/TaskPage/TaskView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/TaskPage/TaskView/EXECUTE_COPY_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/TaskPage/TaskView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/TaskPage/TaskView/EXECUTE_VIEW_ACTION';
export const EXECUTE_PERFORME_ACTION = 'claris-vnext/TaskPage/TaskView/EXECUTE_PERFORME_ACTION';

export const INITIAL_VALUE_TASK_TYPE_STATUS = '1025173000';
export const INITIAL_VALUE_SAAS_TASK_STATUS = '1025176000';
export const INITIAL_VALUE_OTHER_TASK_STATUS = '2175174168000';
