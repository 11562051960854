/*
 *
 * TextFieldCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createTextFieldContainer from './createTextFieldContainer';

class TextFieldCreator extends React.PureComponent {
  render() {
    const TextFieldContainer = createTextFieldContainer(this.context.advancedForm);
    return <TextFieldContainer {...this.props} />;
  }
}

TextFieldCreator.contextTypes = {
  advancedForm: PropTypes.string,
};

export default TextFieldCreator;
