/*
 *
 * TenantForm14254
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import { ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';

export class TenantForm14254 extends TenantForm {
  getFields() {
    const fields = super.getFields();

    const parkingPlaceIndex = fields.findIndex((field) => field.name === 'parkingPlace');
    fields[parkingPlaceIndex].visible = (values) => (values.type === ON_CAR_ENTRY)

    return fields;
  }
}
