/**
 *
 * Tenant Rules MobileGrid 12970
 *
 */

import { TenantMobileGrid } from 'pages/Rules/TenantView';

import {
  RULES_FOR_COMCITY_TENANT,
  ACTIVE_RULES,
} from 'domain/Data/Filters/constants';

export class TenantMobileGrid12970 extends TenantMobileGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [RULES_FOR_COMCITY_TENANT];
    if (viewName === 'active') filters.push(ACTIVE_RULES);
    return filters;
  }
}
