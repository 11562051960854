/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {makeSelectRoles, makeSelectAccountId} from 'domain/User/selectors';
import {createStructuredSelector} from 'reselect';
import {role as roleType} from 'domain/typeConstants/roles';


class HomePage extends React.PureComponent {
    handleSelectUrl = () => {
        const {role} = this.props;
        const isSighting = role.find((item) => (item === roleType.SIGHTING));
        const isUser = role.find((item) => (item === roleType.USER));
        const isServiceRequestsManager = role.find((item) => (item === roleType.SERVICE_REQUESTS_MANAGER));
        const isSupplyApplicant = role.find((item) => (item === roleType.SUPPLY_APPLICANT));
        const isTenant = role.find((item) => (item === roleType.TENANT))
        const isAdmin = role.find((item) => (item === roleType.ADMINISTRATOR_BC))
        const isReception = role.find((item) => (item === roleType.RECEPTION))
        const isCheckPoint = role.find((item) => (item === roleType.CHECKPOINT))
        const isServiceDeskAssignee = role.find((item) => (item === roleType.ASSIGNED))

        if (isSighting) {
            return '/documents?view=forMyVisa';
        }
        if (isUser) {
            return 'tasks?view=activeTask';
        }
        if (isTenant || isAdmin || isServiceRequestsManager || isReception) {
            return '/requests?view=active';
        }
        if (isSupplyApplicant) {
            return '/supplyRequests?view=allSupplyRequests';
        }
        if (isCheckPoint) {
            return '/requests?view=active';
        }
        if (isServiceDeskAssignee) {
            return '/requests?view=active';
        }
        return '/requests?view=all';
    };

    render() {
        return <Redirect to={this.handleSelectUrl()}/>;
    }
}


HomePage.propTypes = {
    role: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = createStructuredSelector({
    role: makeSelectRoles(),
    accountId: makeSelectAccountId(),
});

export default connect(mapStateToProps)(HomePage);
