
import React from 'react';
import PropTypes from 'prop-types';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Document } from 'react-pdf/dist/entry.webpack';
import { Page } from 'react-pdf';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  viewer: {
    heigth: '100%',
    width: '100%',
    textAlign: 'center',
  },
  page: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ViewerBase = ({
  classes,
  currentPage,
  file,
  onDocumentLoad,
  rotationDegree,
  scale,
}) => (
  <Document
    options={{
      cMapUrl: 'cmaps/',
      cMapPacked: true,
    }}
    className={classes.viewer}
    error={'Ошибка при загрузке PDF файла'}
    file={file}
    loading={'Идет загрузка данных...'}
    noData={'Не удалось загрузить данные'}
    onLoadSuccess={onDocumentLoad}
    rotate={rotationDegree}
  >
    <Page
      className={classes.page}
      pageNumber={currentPage}
      renderMode="svg"
      scale={scale}
      loading={'Загружается страница...'}
    />
  </Document>
);

ViewerBase.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  file: PropTypes.string,
  onDocumentLoad: PropTypes.func.isRequired,
  rotationDegree: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
};

export const Viewer = withStyles(styles, { name: 'Viewer' })(ViewerBase);
