/*
 *
 * checkpointDxGrid 14720
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';

export class CheckpointDxGrid14720 extends CheckpointDxGrid {
    getColumnOrder() {
        const columnOrder = super.getColumnOrder();

        const tenantCompanyNameIndex = columnOrder.findIndex((column) => column === 'tenantCompanyName');
        columnOrder.splice(tenantCompanyNameIndex + 1, 0, 'authorName', 'actualCreationDate');

        return columnOrder;
    }
}
