/**
*
* ComboBox
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InitialTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const styles = () => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },

  textField: {
    width: '100%',
  },

  menu: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },

  paper: {
    width: '100%',
    position: 'absolute',
    zIndex: 1000,
    height: '200px',
  },

  menuItem: {
    width: 'auto',
    overflow: "hidden",
    fontSize: "1rem",
    boxSizing: "content-box",
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '3em',
    whiteSpace: 'nowrap',
    paddingLeft: '16px',
    textOverflow: 'ellipsis',
    paddingRight: '16px',
    listStyleType: 'none',
    '&:hover ': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },

});

class ComboBox extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      openList: false,
      heightList: 200,
      mouseOverList: false,
    };
    this.timeId = null;
  }

  componentWillMount() {
    this.timeId = setTimeout(() => { this.setValue(); }, 1000);
  }

  setValue = () => {
    const { options, input } = this.props;
    if (options && input) {
      let value = input.value instanceof Object ? input.value.id : input.value !== -1 ? input.value : "";
      const entity = options.find(item => item.value && item.value.toLowerCase() == value.toLowerCase());
      entity && this.setState({ value: entity.text })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeId)
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ openList: !this.state.openList });
    }
  }

  handleChange = (event) => {
    const { options } = this.props;
    const count = options && options
      .filter((option) => option.text.toLowerCase()
        .includes(event.target.value.toLowerCase())).length;
    const height = count < 4 ? 50 * count : 200

    this.setState({
      value: event.target.value,
      openList: true,
      heightList: height,
    })
  };

  menuitemClick = (event) => {
    this.setState({
      value: event.currentTarget.dataset.id,
      openList: false
    })

    const entity = this.props.options.find(item => item.text == event.currentTarget.dataset.id);
    this.props.input.onChange(entity.value);
  }

  handleClick = () => {
    setTimeout(() => {
      if (this.state.openList === false) {
        const { options } = this.props;
        const count = options && options.filter((option) => option.text.toLowerCase().includes(this.state.value.toLowerCase())).length;
        const height = count < 4 ? 50 * count : 200
        this.setState({
          heightList: height
        })
      }
      this.setState({
        openList: !this.state.openList
      })
    }, 10)
  }

  handleBlur = () => { this.state.mouseOverList && this.setState({ openList: false }) };
  handleOver = () => { this.setState({ mouseOverList: true }) }
  handleOut = () => { this.setState({ mouseOverList: false }) }
  handleClearValueClick = () => {
    this.setState({
      value: '',
      heightList: 200,
    })
  }

  handleClearButtonMouseDown = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      options,
      label,
      required,
    } = this.props;

    const getHeight = Object.assign({}, { height: `${this.state.heightList}px` }, styles);

    return (
      <div className={classes.container}>
        <div>
          <InitialTextField
            margin="none"
            label={required ? `${label} *` : label}
            value={this.state.value}
            fullWidth={true}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
            onClick={this.handleClick}
            onBlur={this.handleBlur}
            disabled={this.props.disabled}
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                this.state.value && !this.props.disabled &&
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClearValueClick}
                    onMouseDown={this.handleClearButtonMouseDown}
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}

          />

        </div>
        <Paper
          className={classes.paper}
          square
          hidden={!this.state.openList}
          style={getHeight}

        >
          <div className={classes.menu}
            display={this.state.openList ? 'block' : 'none'}
            name="menu"
            onMouseOut={this.handleOver}
            onMouseOver={this.handleOut}
          >
            {
              options && options.filter((option) =>
                option.text.toLowerCase().includes(this.state.value.toLowerCase())).map(filteredOption => (
                  <li className={classes.menuItem}
                    key={filteredOption.value}
                    onClick={this.menuitemClick.bind(this)}
                    data-id={filteredOption.text}
                  >
                    {filteredOption.text}
                  </li>
                ))
            }
          </div>
        </Paper>
      </div>
    );
  }
}

ComboBox.propTypes = {
  classNames: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  touched: PropTypes.bool,
};

ComboBox.defaultProps = {
  classNames: {},
};

export default withStyles(styles)(ComboBox);