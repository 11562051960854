/**
 *
 * createTextFieldContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form/immutable';

import TextField from './TextField';
import * as advancedFormActionCreators from '../actions';

function createTextFieldContainer(formName) {
    class TextFieldContainer extends React.PureComponent {
        normalize = (value) => this.props.normalize ?
            this.props.normalize(value) || null :
            value || null;

        render() {
            const {clearErrorsOnChange, ...restProps} = this.props;
            return (
                <Field
                    {...restProps}
                    component={TextField}
                    normalize={this.normalize}
                />
            );
        }
    }

    TextFieldContainer.propTypes = {
        normalize: PropTypes.func,
        clearErrorsOnChange: PropTypes.bool,
        calculateFieldsValues: PropTypes.func.isRequired,
    };

    const mapDispatchToProps = (dispatch) => ({
        calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
    });

    return connect(null, mapDispatchToProps)(TextFieldContainer);
}

export default createTextFieldContainer;
