/**
 *
 * 11994 reception dxGridColumnOrder
 *
 */

export const ALL_COLUMN_ORDER = [
  'number',
  'visitorFullName',
  'carNumber',
  'carModelText',
  'visitDate',
  'expirationDate',
  'statusName',
  'typeShortName',
  'company_name',
  'documentDetails',
  'accessCardNumber',
  'parkingPlace',
  'materialValuesData',
  'companyName',
  'note',
  'blackListNote',
  'authorName',
];

export const TODAY_COLUMN_ORDER = [
  'number',
  'visitorFullName',
  'visitDate',
  'expirationDate',
  'statusName',
  'company_name',
  'documentDetails',
  'accessCardNumber',
  'parkingPlace',
  'companyName',
  'note',
  'authorName',
  'typeShortName',
];
