/**
 *
 * MultiAutocomplete sagas
 *
 */

import {
  call, put, select, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { change } from 'redux-form';
import { loadEntities } from 'domain/Data/actions';

import * as api from 'utils/api';

import { LOAD_OPTIONS, INITIALIZATION } from './constants';
import makeSelectField from './selectors';
import {
  makeSelectCurrentValues,
  makeSelectInitialValues,
  makeSelectMode,
  makeSelectEntityName,
  makeSelectEntityId,
} from '../selectors';

import { changeIsInitialized } from './actions';
import { getIdsForFilter, getArrayFromString, getNamesForFilter } from './utils';

export default function* rootSaga() {
  yield takeEvery(INITIALIZATION, initializationSaga);
  yield takeLatest(LOAD_OPTIONS, function* (action) {
    yield call(loadOptionsSaga, action);
  });
}

export function* loadOptionsSaga({ meta, payload }) {
  const { entityName, filters, filterBy } = yield select(makeSelectField(meta.advancedForm, meta.field));
  const options = { searchQuery: payload.searchQuery, pageNumber: 1, pageSize: 5 };

  if (filters && Array.isArray(filters)) {
    options.filters = filters;
  } else if (filters && typeof filters === 'function') {
    const initialValues = yield select(makeSelectInitialValues(meta.advancedForm));
    const currentValues = yield select(makeSelectCurrentValues(meta.advancedForm));
    const mode = yield select(makeSelectMode(meta.advancedForm));
    options.filters = filters(currentValues, initialValues, mode);
  }

  if (filterBy && typeof filterBy === 'string') {
    options.filterBy = filterBy;
  } else if (filterBy && typeof filterBy === 'function') {
    const initialValues = yield select(makeSelectInitialValues(meta.advancedForm));
    const currentValues = yield select(makeSelectCurrentValues(meta.advancedForm));
    const mode = yield select(makeSelectMode(meta.advancedForm));
    options.filterBy = filterBy(currentValues, initialValues, mode);
  }

  yield put(loadEntities(entityName, options, meta));
}

export function* initializationSaga({ meta }) {
  const { entityName, advancedForm, fieldName } = meta;

  const formEntityName = yield select(makeSelectEntityName(advancedForm));
  const formEntityId = yield select(makeSelectEntityId(advancedForm));

  try {
    const response = yield call(api.load, formEntityName, formEntityId);
    const { data: record } = response;
    const options = {};
    const isRecordIds = record && record[fieldName];
    const tagValues = isRecordIds ? record[fieldName] : getArrayFromString(record, fieldName);

    if (tagValues.length > 0) {
      options.filterBy = isRecordIds ? getIdsForFilter(record, fieldName) : getNamesForFilter(record, fieldName);
    }

    yield put(loadEntities(entityName, options, meta));
    yield put(change(advancedForm, fieldName, tagValues));
    yield put(changeIsInitialized(advancedForm, fieldName));
  } catch (error) {
    console.log(error);
  }
}
