/*
 *
 * TenantDxGrid14720
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';
import { requestColumnSizes } from 'domain/typeConstants/table';
import { closeByTenantActionButton, agreedEstimationCostActionButton } from "../common/actionButton";
import { closeByTenantActionItem, agreedEstimationCostActionItem } from "../common/actionMenuItem";
import {
    estimationCostColumn,
    estimationCostColumnFilter,
    estimationCostColumnWidth,
    ESTIMATION_COST,
    estimationCostScanColumn,
    estimationCostScanColumnFilter,
    estimationCostScanColumnWidth,
    ESTIMATION_COST_SCAN,
} from "../common/dxGridComponents/columns";

export class TenantDxGrid14720 extends TenantDxGrid {

    getColumns() {
        const columns = super.getColumns();
        const phoneColumn = {
            path: 'phone',
            name: 'phone',
            title: 'Контактный тел',
            width: requestColumnSizes.phone,
        };
        columns.push(phoneColumn);
        columns.push(estimationCostColumn);
        columns.push(estimationCostScanColumn);

        return columns;
    }

    getGridFilters() {
        const gridFilters = super.getGridFilters();
        const phoneColumnFilters = {columnName: 'phone', value: ''};
        gridFilters.push(phoneColumnFilters);
        gridFilters.push(estimationCostColumnFilter);
        gridFilters.push(estimationCostScanColumnFilter);

        return gridFilters;
    }

    getColumnOrder() {
        const columnOrder = super.getColumnOrder();
        columnOrder.push('phone');

        columnOrder.push(ESTIMATION_COST, ESTIMATION_COST_SCAN);

        return columnOrder;
    }

    getActionButtons() {
        const actionButtons = super.getActionButtons();
        actionButtons.push(closeByTenantActionButton);
        actionButtons.push(agreedEstimationCostActionButton);
        return actionButtons;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();
        actionMenuItems.push(closeByTenantActionItem);
        actionMenuItems.push(agreedEstimationCostActionItem);
        return actionMenuItems;
    }
}
