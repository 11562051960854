const entityNameMap = new Map([
  [4233325000, 'requests'],
  [1400998000, 'documents'],
  [640077000, 'tasks'],
  [5509502000, 'documents'] //это tblAgreementHistory, относится к документам на согласовании
]);

//Проверяем необходимость обновления грида: если инстанс совпадает,
//пользователь - редактор записи != текущему пользователю - возвращаем значение из entityNameMap по ClassId
//(undefined, если нет ключа в entityNameMap) для передачи в событие. Возвращаем '' в противном случае.
export const updateNeed = (message) => {

  var pos = message.indexOf('<InstanceId>');
  var instanceId  = '-1';

  if(pos != -1)
    instanceId  = message.substring(message.indexOf('<InstanceId>')+12, message.indexOf('</InstanceId>'));

  var curUser = JSON.parse(localStorage['claris-vnext-global-user']);

  if(curUser.accountId != instanceId)
  	return '';

  pos = message.indexOf('<ClassId>');
  var classId = '';
  var changedBy = '';

  if(pos != -1)
    classId = message.substring(pos+9, message.indexOf('</ClassId>'));

  pos = message.indexOf('<ChangedBy>');
  if(pos != -1)
    changedBy = message.substring(pos+11, message.indexOf('</ChangedBy>'));

  pos = message.indexOf('<CreatedBy>');
  if(pos != -1 && changedBy.length == 0)
    changedBy = message.substring(pos+11, message.indexOf('</CreatedBy>'));

  if(curUser.name == changedBy)
  	return '';
  
  return entityNameMap.get(parseInt(classId, 10));
};
