/**
*  SelectTextFilterEditor Component
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => ({
  input: {
    minWidth: '136px',
    marginLeft: '6px',
    fontSize: '14px',
  },
  clearIcon: {
    height: 32,
    width: 32,
  },
  buttons: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    outline: 'none'
  },
  container: {
    overflow: 'auto',
    outline: 'none'
  },
  hiddenSpan: {
    opacity: 0,
    position: 'absolute',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
});

class SelectTextFilterEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedAll: false,
      value: '',
      showTooltip: false,
    };
    this.fieldRef = React.createRef();
    this.hiddenSpanRef = React.createRef();
  }

  componentDidMount() {
    const { entity, filter: { path, outputValue } } = this.props;
    const value = path && Array.isArray(path) ? path[path.length - 1] :
      path && path.includes('.') ? path.slice(path.indexOf('.') + 1) : 'name'

    if (entity) {
      const entityList = entity.map(item => {
        return {
          id: item.id,
          value: item[value],
          selected: outputValue.split(',').includes(item[value]),
        }
      })
        .filter(item => item.value)
        .sort((a, b) => {
          if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
          if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
          return 0;
        })
      this.setState({ entity: entityList });
    }
  }

  handleClick = (event) => {
    const { entity, filter: { path, outputValue } } = this.props;
    const value = path && Array.isArray(path) ? path[path.length - 1] :
      path && path.includes('.') ? path.slice(path.indexOf('.') + 1) : 'name'

    if (entity && entity.length > 0) {
      const entityList = entity.map(item => {
        return {
          id: item.id,
          value: item[value],
          selected: outputValue.split(',').includes(item[value]),
        }
      })
        .filter(item => item.value)
        .sort((a, b) => {
          if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
          if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
          return 0;
        })
      this.setState({ entity: entityList });
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false })
  }

  handleMouseEnter = () => {
    const field = this.fieldRef.current.getBoundingClientRect();
    const span = this.hiddenSpanRef.current.getBoundingClientRect();
    const showTooltip = field && span ? field.width < span.width : false
    this.setState({ showTooltip });
  }

  handleMenuClose = () => { this.setState({ anchorEl: null }); };

  handleMenuItemClick = (valueItem) => {
    let newEntity = this.state.entity;
    newEntity.map(item => {
      if (item.id === valueItem.id) {
        item.selected = !valueItem.selected;
      }
    })
    this.setState({ entity: newEntity });
  }

  handleSelectAllClick = () => {
    let newEntity = this.state.entity;
    newEntity.map(item => item.selected = !this.state.selectedAll)

    this.setState({
      selectedAll: !this.state.selectedAll,
      entity: newEntity
    });
  }

  handleOKClick = () => {
    const { onChange, filter: { columnName } } = this.props;

    const selectedEntity = this.state.entity
      .filter(item => item.selected === true)
      .map(item => { return item.value })

    let { entity } = this.state;
    entity.map(item => item.selected = false)
    const outputValue = selectedEntity.join(',');

    this.setState({
      anchorEl: null,
      entity,
      selectedAll: false,
      value: outputValue,
    })
    onChange(columnName, outputValue);
  }

  renderHiddenSpan = (outputValue) => {
    const { classes } = this.props;
    return (
      <span ref={this.hiddenSpanRef} className={classes.hiddenSpan}>
        {outputValue.replaceAll(' ', '_') || ''}
      </span>
    )
  }

  render() {
    const { classes, filter: { outputValue, columnName } } = this.props;
    const { entity, showTooltip } = this.state;
    const getHeight = entity && entity.length > 10 ? { height: '400px' } : { height: 'auto' };
    return (
      <div ref={this.fieldRef}>

        <Tooltip
          id={`tooltip-${columnName}`}
          placement="bottom"
          title={outputValue}
          open={showTooltip}
        >
          <Input
            className={classes.input}
            onClick={this.handleClick}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={this.handleMouseEnter}
            value={outputValue || ''}
            fullWidth
          />
        </Tooltip>
        {this.renderHiddenSpan(outputValue)}
        {entity && entity.length > 0 &&
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            MenuListProps={{ dense: true }}
          >
            <MenuItem
              key={0}
              selected={this.state.selectedAll}
              onClick={() => this.handleSelectAllClick()}
            >
              <CheckBox
                color="primary"
                checked={this.state.selectedAll}
              /> Выделить все
            </MenuItem>
            <Divider />
            <div className={classes.container} style={getHeight}
            >
              {entity && entity.map((valueItem) => (
                <MenuItem
                  key={valueItem.id}
                  selected={valueItem.selected}
                  onClick={() => this.handleMenuItemClick(valueItem)}
                >
                  <CheckBox
                    color="primary"
                    checked={valueItem.selected}
                  />
                  {valueItem.value}
                </MenuItem>
              ))}
            </div>
            <div className={classes.buttons}>
              <Button color="primary" onClick={this.handleOKClick}>OK</Button>
              <Button color="primary" onClick={this.handleMenuClose}>Отмена</Button>
            </div>
          </Menu>}
      </div>
    );
  }
}

SelectTextFilterEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectTextFilterEditor.defaultProps = {
  value: undefined,
};

export default withStyles(styles)(SelectTextFilterEditor);
