/**
 *
 * DetailTableField
 *

 */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import {ON_AGREED, ON_AGREEMENT} from "domain/Data/DocumentState/constants";


const styles = () => ({
    item: {
        padding: 0,
        marginBottom: 5,
        marginTop: 5,
        display: 'flex',
        justifyContent: 'space-between',
    },

    container: {
        padding: 5,
        bordrerRadius: 5,
        boxShadow: 'inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5)',
        maxHeight: 200,
        overflowX: 'auto',
        minHeight: 50,
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    card: {
        padding: 5
    },
    title: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem'
    }

});

class DetailTableField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    fillData = () => {
        const {data} = this.props
        this.setState({data})
    }

    componentDidMount() {
        this.fillData()
    }

    componentDidUpdate() {
        this.fillData()
    }

    handleAddRecord = () => {
        const {addRecord} = this.props
        if (addRecord) addRecord()
    }

    handleActionExecuting = (type) => {
        if (type === 'add') {
            const {addRecord} = this.props
            if (addRecord) addRecord()
        } else console.log('no action')
    }

    handleEditRecord = (item) => {
        const {editRecord} = this.props
        if (editRecord) {
            editRecord(item.id)
        }
    }

    getColor = (item) => {
        const {mode} = this.props
        if (mode === 'add') {
            const available = item?.available || item?.estimateItem?.available
            const count = item.positionsCount || 0
            return available && count > available ? "error" : "textSecondary"
        }
        if (mode === 'edit') {
            const available = item?.available || item?.estimateItem?.available
            return available && available < 0 ? "error" : "textSecondary"
        }
        return "textSecondary"
    }

    getCardContent = (item) => {
        const {classes} = this.props;
        const itemName = item.estimateItem ? item.estimateItem.name :
            item.asset ? item.asset.name :
                item.assetText ? item.assetText : '';
        let itemCount = `Кол-во: ${item.positionsCount || 0}`
        if (item.available || item?.estimateItem?.available) {
            itemCount = itemCount + `, Доступно: ${item.available || item?.estimateItem?.available || 0}`
        }
        return (
            <CardContent className={classes.card}>
                <Typography variant="subheading" component="div">
                    {itemName}
                </Typography>
                <Typography variant="body2" color={this.getColor(item)}>
                    {itemCount}
                </Typography>
            </CardContent>
        )

    }

    render() {
        const {classes, record, actionButtons} = this.props;
        const {data} = this.state

        return (
            <div>
                <div className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        Позиции
                    </Typography>
                    {actionButtons && actionButtons.map(item => {
                        return (
                            <IconButton
                                key={item.name}
                                className={classes.iconButton}
                                disabled={item.disabled}
                                onClick={() => this.handleActionExecuting(item.name)}
                            >
                                {item.svgIcon}
                            </IconButton>
                        )
                    })}
                </div>

                <div className={classes.container}>
                    {data && data.map((item, idx) => {
                        return (<Card className={classes.item}
                                // key={item.estimateItem.id}
                                      key={idx}
                            >
                                {this.getCardContent(item)}
                                {/*<CardContent className={classes.card}>*/}
                                {/*    <Typography variant="subheading" component="div">*/}
                                {/*        {item?.estimateItem?.name || item.assetText}*/}
                                {/*    </Typography>*/}
                                {/*    <Typography variant="body2" color={this.getColor(item)}>*/}
                                {/*        Кол-во: {item.positionsCount || 0},*/}
                                {/*        Доступно: {item.available || item?.estimateItem?.available || 0}*/}
                                {/*    </Typography>*/}
                                {/*</CardContent>*/}
                                <CardActions>
                                    <IconButton
                                        disabled={record?.state && [ON_AGREEMENT, ON_AGREED].includes(record?.state)}
                                        onClick={() => this.handleEditRecord(item)}>
                                        <Edit/>
                                    </IconButton>
                                </CardActions>
                            </Card>
                        )
                    })
                    }
                </div>
            </div>
        );
    }

}

DetailTableField.defaultProps = {
    classNames: {},
};

export default withStyles(styles)(DetailTableField);
