/**
 *
 * AssigneeForm14366
 *
 */

import { AssignedForm } from 'pages/Requests/AssignedView';
import { GLOBAL_USER_KEY } from 'global-constants';
import { UK_TSVETNOY, MELIORA_PLACE } from 'custom/14366/domain/Data/Departments/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { assignee, department } from '../common/fields';


export class AssignedForm14366 extends AssignedForm {
  getFields() {
    const fields = super.getFields();

    const departmentId = getValueFromLocalStorage(GLOBAL_USER_KEY).departmentId
    const isDepartment = () => {
      return [UK_TSVETNOY, MELIORA_PLACE].includes(departmentId) ? true : false;
    }
    department.disabled = isDepartment()
    department.initialValue = (values, mode) => mode === 'add' && isDepartment() ? departmentId : null

    const visitDateIndex = fields.findIndex((field) => field.name === 'visitDate');
    fields.splice(visitDateIndex + 1, 0, department);

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields.splice(assigneeIndex, 1, assignee)

    return fields;
  }
}
