/**
 *
 * AdministratorForm15774
 *
 */
import {ReceptionForm15774} from "../ReceptionView";

export class AdministratorForm15774 extends ReceptionForm15774 {

}
