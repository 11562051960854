/*
 * UsersView sagas
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as api from 'utils/api';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  openAddDialog,
  openViewDialog,
  openEditDialog,
  closeDialog,
} from 'containers/AdvancedFormDialog/actions';

import { closeRequestsSaga } from 'domain/Data/Request/sagas';

import {
  USERS_DATA_TABLE,
  USERS_FORM_DIALOG,
  EXECUTE_ADD_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_BLOCK_ACTION,
  USER_BLOCKED,
} from './constants';
import { makeSelectFormName } from 'containers/AdvancedFormDialog/selectors';
import { makeSelectCurrentValues } from 'containers/AdvancedForm/selectors';
import { blockUser, userBlocked, userBlockedError } from './actions';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import makeSelectUser from 'domain/User/selectors';
import { role } from 'domain/typeConstants/roles';

const needViewRefresh = (action) => {
  return (action.type === SAVE_DIALOG_SUCCESS &&
    action.meta.dialog === USERS_FORM_DIALOG) || action.type === USER_BLOCKED;
}

export default function createUsersViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_ADD_ACTION, executeAddActionSaga);
  yield takeLatest(EXECUTE_VIEW_ACTION, executeViewActionSaga);
  yield takeLatest(EXECUTE_EDIT_ACTION, executeEditActionSaga);
  yield takeLatest(EXECUTE_CLOSE_ACTION, executeCloseActionSaga);
  yield takeLatest(EXECUTE_BLOCK_ACTION, executeBlockActionSaga);
  yield takeLatest(needViewRefresh, executeNeedViewRefreshSaga);
  };
}

function* executeNeedViewRefreshSaga() {
  const mode = yield select(makeSelectViewMode());
  if (mode === 'mobile') {
    yield put(loadMobileData(USERS_DATA_TABLE))
  } else yield put(loadData(USERS_DATA_TABLE));
}

export function* executeAddActionSaga() {
  yield put(openAddDialog(USERS_FORM_DIALOG, {}));
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(USERS_FORM_DIALOG, { id }));
  }
}

export function* executeEditActionSaga({ payload: { id } }) {
  if (id) {
    const user = yield select(makeSelectUser());
    if (user && user.roles && user.roles.includes(role.ADMINISTRATOR_BC)) {
      yield put(openEditDialog(USERS_FORM_DIALOG, { id }));
    } else yield put(openViewDialog(USERS_FORM_DIALOG, { id }));
  }
}

export function* executeCloseActionSaga({ payload: { ids } }) {
  yield call(closeRequestsSaga, ids);
  yield put(loadData(USERS_DATA_TABLE));
}

export function* executeBlockActionSaga({ payload: { id } }) {
  const formName = yield select(makeSelectFormName(id));
  const currentValues = yield select(makeSelectCurrentValues(formName));
  yield put(blockUser());
  try {
    yield call(api.post, `users/${currentValues.id}/blocked`);
    yield put(userBlocked());
  } catch (err) {
    yield put(userBlockedError(err));
  }
  finally {
    yield put(closeDialog(USERS_FORM_DIALOG))
  }
}
