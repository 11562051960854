/**
 *
 * FileField selectors
 *
 */

import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form/immutable';

import { makeSelectEntities } from 'domain/Data/selectors';
import { makeSelectFields } from '../selectors';

const makeSelectDomain = (formName, fieldName) => createSelector(
  makeSelectFields(formName),
  (fields) => fields.get(fieldName),
);

const makeSelectValue = (formName, fieldName) => createSelector(
  getFormValues(formName, (state) => state.get('forms')),
  (formValues) => formValues[fieldName]
);

const makeSelectFile = (formName, fieldName) => createSelector(
  makeSelectValue(formName, fieldName),
  makeSelectEntities(),
  (fieldValue, entities) => fieldValue ?
    entities.get('files').get(fieldValue) : null,
);

const makeSelectMultipleValue = (formName, fieldName) => createSelector(
  getFormValues(formName, (state) => state.get('forms')),
  (formValues) => {
    if (!Array.isArray(formValues[fieldName])) {
      return [];
    }

    return formValues[fieldName];
  },
);

const makeSelectMultipleFile = (formName, fieldName) => createSelector(
  makeSelectMultipleValue(formName, fieldName),
  makeSelectEntities(),
  (fieldValue, entities) => {
    const files = entities.get('attachments');
    if (fieldValue) {
      return fieldValue
        .map((id) => files.get(id))
        .filter((file) => file);
    }

    return [];
  }
);

export default makeSelectDomain;
export {
  makeSelectValue,
  makeSelectFile,
  makeSelectMultipleFile,
  makeSelectMultipleValue,
};
