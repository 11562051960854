/*
 *
 * Notification actions
 *
 */

import {
  DISPLAY_NOTIFICATION,
  DISMISS_NOTIFICATION,
  OPEN_NOTIFICATION,

  LOAD_UNREAD_NOTIFICATIONS_COUNT,
  LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
  LOAD_UNREAD_NOTIFICATIONS_COUNT_ERROR,

  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_SUCCESS,
  READ_ALL_NOTIFICATIONS_ERROR,
} from './constants';


export function displayNotification(data) {
  return {
    type: DISPLAY_NOTIFICATION,
    payload: { data },
  };
}

export function dismissNotification(data) {
  return {
    type: DISMISS_NOTIFICATION,
    payload: { data },
  };
}

export function openNotification(data) {
  return {
    type: OPEN_NOTIFICATION,
    payload: { data },
  };
}


export function loadUnreadNotificationsCount() {
  return {
    type: LOAD_UNREAD_NOTIFICATIONS_COUNT,
  };
}

export function unreadNotificationsCountLoaded(unreadCount) {
  return {
    type: LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
    payload: { unreadCount },
  };
}

export function unreadNotificationsCountLoadingError(error) {
  return {
    type: LOAD_UNREAD_NOTIFICATIONS_COUNT_ERROR,
    payload: { error },
  };
}


export function readAllNotifications() {
  return {
    type: READ_ALL_NOTIFICATIONS,
  };
}

export function allNotificationsReaded() {
  return {
    type: READ_ALL_NOTIFICATIONS_SUCCESS,
  };
}

export function allNotificationsReadingError(error) {
  return {
    type: READ_ALL_NOTIFICATIONS_ERROR,
    payload: { error },
  };
}
