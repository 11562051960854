/**
 *
 * ReceptionDxGrid14326
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_ON_WORK_PRODUCTION,
  ON_CREATE_REQUESTS,
  ACCESS_CARD_REQUESTS,
  SERVICE_WORKS,
  ACTIVE_REQUESTS,
} from 'domain/Data/Filters/constants';
import { columnType, requestColumnSizes, columnSize } from 'domain/typeConstants/table';
import {
  sendToAgreementActionButton,
  sendToAgreementByTenantActionButton,
  sendEmailActionButton,
  acceptActionButton,
  editActionButton,
  closeActionButton,
} from '../common/actionButton';

import {
  sendToAgreementActionMenuItem,
  sendToAgreementByTenantActionMenuItem,
  sendEmailActionMenuItem, acceptActionItem,
} from '../common/actionMenuItem';

import { requestAction } from 'domain/typeConstants/action';

export class ReceptionDxGrid14326 extends ReceptionDxGrid { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (viewName === 'accessCard') filters.push(ACCESS_CARD_REQUESTS);
    if (viewName === 'serviceWorks') filters.push(SERVICE_WORKS);
    return filters;
  }

  getColumns() {
    const { viewName } = this.props;
    if (viewName === 'onWorks') {
      return super.getColumns();
    }

    const columns = super.getColumns();
    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const allowProcessPersonalDataStyle = {
      // style: { backgroundColor: '#ccc9ad' },
      style: { backgroundColor: '#ebe17a' },
      condition: (value) => value.isAllowProcessPersonalData,
    };

    const styles = [blackListStyle];
    const allowPersonalStyles = [blackListStyle, allowProcessPersonalDataStyle]

    const companyIndex = columns.findIndex((column) => column.name === 'company_name');
    columns[companyIndex].title = 'Компания';

    const typeShortNameIndex = columns.findIndex((column) => column.name === 'typeShortName');
    columns[typeShortNameIndex].path = ['type', 'himkiName'];

    const statusNameIndex = columns.findIndex((column) => column.name === 'statusName');
    columns[statusNameIndex].path = ['status', 'alternativeName'];

    columns.push({
      path: ['author', 'phone'],
      name: 'authorPhone',
      getCellValue: (row) => row.author ? row.author.phone : undefined,
      title: 'Телефон',
      width: requestColumnSizes.phone,
      styles,
    });

    columns.push({
      path: 'phone',
      name: 'phone',
      title: 'Тел. встречающего',
      width: requestColumnSizes.phone,
      styles,
    });

    columns.push({
      path: 'khimkiRequestType',
      name: 'khimkiRequestType',
      title: 'Тип',
      getCellValue: (row) => row.khimkiRequestType ? row.khimkiRequestType.name : undefined,
      entityName: 'khimkiRequestTypes',
      width: requestColumnSizes.khimkiRequestType,
      styles,
    });

    columns.push({
      path: ['agreedEmployee', 'name'],
      name: 'agreedEmployee',
      title: 'Согласовал',
      getCellValue: (row) => row.agreedEmployee ? row.agreedEmployee.name : undefined,
      width: requestColumnSizes.agreedEmployee,
      styles,
    });

    columns.push({
      path: 'workPlace',
      name: 'workPlace',
      title: 'Место проведения',
      width: requestColumnSizes.workPlace,
      styles,
    });

    const passNumberIndex = columns.findIndex((column) => column.name === 'passNumber');
    columns[passNumberIndex] = {
      path: 'accessCardKey',
      name: 'accessCardKey',
      title: 'Ключ карты доступа',
      width: requestColumnSizes.passNumber,
      styles,
    };

    columns.push(
      {
        path: 'isTrainingPass',
        name: 'isTrainingPass',
        title: 'S3',
        type: columnType.BOOLEAN,
        width: requestColumnSizes.isTrainingPass,
        styles,
      })

    columns.push({
      path: 'trainingResult',
      name: 'trainingResult',
      title: 'Результат S3',
      width: requestColumnSizes.trainingResult,
      styles,
    });

    columns.push(
      {
        path: 'trainingDate',
        name: 'trainingDate',
        title: 'S3 Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
        styles,
      })

    columns.push(
      {
        path: 'isAllowProcessPersonalData',
        name: 'isAllowProcessPersonalData',
        title: 'ПН',
        type: columnType.BOOLEAN,
        width: requestColumnSizes.isTrainingPass,
        styles: allowPersonalStyles,
      })

    columns.push(
      {
        path: 'isTerminal',
        name: 'isTerminal',
        title: 'Терминал',
        type: columnType.BOOLEAN,
        width: requestColumnSizes.isTrainingPass,
        styles,
      })

    columns.push(
      {
        path: 'isFired',
        name: 'isFired',
        type: columnType.BOOLEAN,
        title: 'Уволен',
        width: requestColumnSizes.isTrainingPass,
        styles,
      }
    )

    columns.push({
      name: 'countAttachments',
      path: ['countAttachments', 'name'],
      getCellValue: (row) => (row.attachments.length > 0 ? "+" + row.attachments.length : undefined),
      title: 'Файл',
      filter: false,
      width: requestColumnSizes.fileName,
    })

    let filteredColumns = columns.filter((column) => !(new Set(['documentDetails', 'whatHappened', 'serviceName']).has(column.name)));

    if (viewName === 'today' || viewName === 'all') {
      filteredColumns = columns.filter((column) => !(new Set([
        'khimkiRequestType', 'phone', 'companyName', 'fileName', 'isTrainingPass', 'countAttachments',
        'trainingResult', 'trainingDate', 'blackListNote', 'workPlace'
      ]).has(column.name)));
    }

    if (viewName === 'accessCard') {
      filteredColumns = columns.filter((column) => !(new Set([
        'khimkiRequestType', 'carNumber', 'carModelText', 'phone', 'workPlace', 'fileName',
        'parkingName', 'materialValuesData', 'accessCardKey', 'companyName',
        'arrivalDate', 'departureDate', 'isTerminal', 'blackListNote',
      ]).has(column.name)));
    }

    if (viewName === 'agree') {
      filteredColumns = columns.filter((column) => !(new Set(['fileName']).has(column.name)));
    }

    return filteredColumns;
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();

    columnFilters.push({
      columnName: 'authorPhone', value: '',
    });
    columnFilters.push({
      columnName: 'phone', value: '',
    });
    // columnFilters.push({
    //   columnName: 'khimkiGuestPhone', value: '',
    // });
    columnFilters.push({
      columnName: 'khimkiRequestType', value: '',
    });
    columnFilters.push({
      columnName: 'agreedEmployee', value: '',
    });
    columnFilters.push({
      columnName: 'accessCardKey', value: '',
    });
    columnFilters.push({
      columnName: 'workPlace', value: '',
    });
    columnFilters.push({
      columnName: 'actualCreationDate', value: '',
    });
    columnFilters.push({
      columnName: 'isTrainingPass', value: '',
    });
    columnFilters.push({
      columnName: 'trainingResult', value: '',
    });
    columnFilters.push({
      columnName: 'trainingDate', value: '',
    });
    columnFilters.push({
      columnName: 'isTerminal', value: '',
    });
    columnFilters.push({
      columnName: 'isFired', value: '',
    });
    return columnFilters;
  }

  getColumnOrder() {
    const { viewName } = this.props;
    const columnOrder = super.getColumnOrder();

    let visitorFullNameIndex = columnOrder.findIndex((columnName) => columnName === 'visitorFullName');
    columnOrder.splice(visitorFullNameIndex + 1, 0, 'khimkiGuestPhone');

    const typeIndex = columnOrder.findIndex((columnName) => columnName === 'typeShortName');
    columnOrder.splice(typeIndex + 1, 0, 'khimkiRequestType');

    const companyNameIndex = columnOrder.findIndex((columnName) => columnName === 'company_name');
    columnOrder.splice(companyNameIndex + 1, 0, 'authorPhone', 'phone');

    const phoneIndex = columnOrder.findIndex((columnName) => columnName === 'phone');
    columnOrder.splice(phoneIndex + 1, 0, 'agreedEmployee', 'name');

    const parkingPlaceIndex = columnOrder.findIndex((columnName) => columnName === 'parkingPlace');
    columnOrder.splice(parkingPlaceIndex + 1, 0, 'workPlace');

    const passNumberIndex = columnOrder.findIndex((columnName) => columnName === 'passNumber');
    columnOrder.splice(passNumberIndex + 1, 0, 'accessCardKey');

    columnOrder.push('isTrainingPass');
    columnOrder.push('trainingResult');
    columnOrder.push('trainingDate');
    columnOrder.push('isTerminal');
    columnOrder.push('isFired');

    if (viewName === 'accessCard') {
      let numberIndex = columnOrder.findIndex((columnName) => columnName === 'number');
      columnOrder.splice(numberIndex, 0, 'isTrainingPass');
      columnOrder.splice(numberIndex, 0, 'trainingResult');
      columnOrder.splice(numberIndex, 0, 'trainingDate');
      columnOrder.splice(numberIndex, 0, 'actualCreationDate');

      numberIndex = columnOrder.findIndex((columnName) => columnName === 'number');
      columnOrder.splice(numberIndex + 1, 0, 'typeShortName');
      columnOrder.splice(numberIndex + 1, 0, 'statusName');

      const fileNameIndex = columnOrder.findIndex((columnName) => columnName === 'fileName');
      columnOrder[fileNameIndex] = 'countAttachments';
    }

    if (viewName === 'create' || viewName === 'agree') {
      const fileNameIndex = columnOrder.findIndex((columnName) => columnName === 'fileName');
      columnOrder[fileNameIndex] = 'countAttachments';
    }

    visitorFullNameIndex = columnOrder.findIndex((columnName) => columnName === 'visitorFullName');
    columnOrder.splice(visitorFullNameIndex + 1, 0, 'isAllowProcessPersonalData');

    return columnOrder;
  }

  getSorting() {
    const { viewName } = this.props;
    if (viewName === 'accessCard') {
      return [{ columnId: 'trainingDate', columnName: 'trainingDate', direction: 'desc' },
      { columnId: 'number', columnName: 'number', direction: 'desc' }
      ];
    }
    return super.getSorting();
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const acceptIndex = actionButtons.findIndex((button) => button.name === requestAction.ACCEPT);
    actionButtons[acceptIndex].label = 'Согласовать';

    const editIndex = actionButtons.findIndex((button) => button.name === requestAction.EDIT);
    actionButtons.splice(editIndex, 1, editActionButton);

    const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);
    actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);

    actionButtons.push(closeActionButton);
    actionButtons.push(sendEmailActionButton);
    actionButtons.push(sendToAgreementByTenantActionButton);
    actionButtons.push(sendToAgreementActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
    actionMenuItems[editIndex].label = 'Открыть';

    const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);

    actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);

    actionMenuItems.push(sendToAgreementActionMenuItem);
    actionMenuItems.push(sendToAgreementByTenantActionMenuItem);
    actionMenuItems.push(sendEmailActionMenuItem);

    return actionMenuItems;
  }
}
