/*
 *
 * RequestsPage14720
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import { ReceptionForm14720, ReceptionDxGrid14720, ReceptionFormDialog14720 } from "./ReceptionView";
import { TenantForm14720, TenantDxGrid14720 } from "./TenantView";
import {
    AdministratorBCRequestsForm14720,
    AdministratorBCRequestsDxGrid14720,
    AdministratorBCServiceRequestsForm14720
} from "./AdministratorBCView";
import { ServiceRequestsManagerForm14720, ServiceRequestsManagerDxGrid14720 } from "./ServiceRequestsManagerView";
import { AssignedDxGrid14720 } from './AssignedView';
import { CheckpointDxGrid14720 } from './CheckpointView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';


function RequestsPage14720(props) {

    return (
        <RequestsPage
            receptionForm={<ReceptionForm14720 />}
            receptionFormDialog={
                <ReceptionFormDialog14720
                    form={<ReceptionForm14720 />}
                    detailContainer={getRequestDetailContainer()}
                />
            }
            receptionDxGrid={<ReceptionDxGrid14720 />}
            tenantForm={<TenantForm14720 />}
            tenantDxGrid={<TenantDxGrid14720 />}
            administratorBCForm={<AdministratorBCRequestsForm14720 />}
            administratorBCFormDialog={<ReceptionFormDialog14720
                params={props.params}
                form={<AdministratorBCRequestsForm14720 />}
                detailContainer={getRequestDetailContainer()}
            />}
            administratorBCDxGrid={<AdministratorBCRequestsDxGrid14720 />}
            administratorBCServiceDxGrid={<ServiceRequestsManagerDxGrid14720 />}
            administratorBCServiceForm={<AdministratorBCServiceRequestsForm14720 />}
            serviceRequestsManagerDxGrid={<ServiceRequestsManagerDxGrid14720 />}
            serviceRequestsManagerForm={<ServiceRequestsManagerForm14720 />}
            assignedDxGrid={<AssignedDxGrid14720 />}
            checkpointDxGrid={<CheckpointDxGrid14720 />}
            {...props}
        />
    );
}

export default RequestsPage14720;
