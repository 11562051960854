/**
 * AdvancedFormDialog selectors
 */

import { makeSelectEntityData } from 'domain/Data/selectors';
import { createSelector } from 'reselect';

const makeSelectDomain = () => (state) => state.get('advancedFormDialogs');

const makeSelectAdvancedFormDialog = (dialogName) => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get(dialogName),
);

const makeSelectCountOpenedDialog = () => createSelector(
  makeSelectDomain(),
  (domainState) => {
    if (domainState) {
      let opened = 0;
      for (let prop of Object.values(domainState.toJS())) {
        prop.open === true && opened++
      }
      return opened
    }
  },
);

const makeSelectIsOpen = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('open'),
);

const makeSelectMode = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState ? advancedFormDialogState.get('mode') : null,
);

const makeSelectLoadingState = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('loadingState'),
);

const makeSelectEntityId = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState && advancedFormDialogState.get('entityId'),
);

const makeSelectParams = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('params'),
);

const makeSelectInitTables = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('initTables'),
);

const makeSelectNavigation = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('navigation'),
);

const makeSelectTabPosition = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('tab'),
);

const makeSelectStatus = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState ? advancedFormDialogState.get('status') : null,
);

const makeSelectFormName = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.get('formName'),
);

const makeSelectActiveDialog = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.find((dialog) => dialog.get('open')),
);

const makeSelectActiveDialogs = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.filter((dialog) => dialog.get('open')).toJS(),
);

const makeSelectParentId = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.getIn(['parentId']),
);

const makeSelectProjectId = (dialogName) => createSelector(
  makeSelectParentId(dialogName),
  makeSelectEntityData('documents'),
  (docId, entities) => entities.toJS()[docId]?.['project'],
);

const makeSelectParent = (dialogName) => createSelector(
  makeSelectAdvancedFormDialog(dialogName),
  (advancedFormDialogState) => advancedFormDialogState.getIn(['parent']),
);

export default makeSelectDomain;
export {
  makeSelectActiveDialog,
  makeSelectAdvancedFormDialog,
  makeSelectEntityId,
  makeSelectFormName,
  makeSelectIsOpen,
  makeSelectLoadingState,
  makeSelectMode,
  makeSelectParams,
  makeSelectParentId,
  makeSelectParent,
  makeSelectStatus,
  makeSelectCountOpenedDialog,
  makeSelectActiveDialogs,
  makeSelectInitTables,
  makeSelectNavigation,
  makeSelectTabPosition,
  makeSelectProjectId,
};
