/**
 *
 * SeniorParkingOperatorFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { SENIOR_PARKING_OPERATOR_REQUESTS_FORM, SENIOR_PARKING_OPERATOR_REQUESTS_FORM_DIALOG } from './constants';

function SeniorParkingOperatorFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={SENIOR_PARKING_OPERATOR_REQUESTS_FORM_DIALOG}
      formName={SENIOR_PARKING_OPERATOR_REQUESTS_FORM}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
    />
  );
}

SeniorParkingOperatorFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default SeniorParkingOperatorFormDialog;
