/*
 *
 * ChangePasswordPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { replace } from 'react-router-redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { checkPasswordLink } from './actions';
import saga from './sagas';
import RestorePasswordPage from './RestorePasswordPage';

export class RestorePasswordPageContainer extends React.Component {
  componentWillMount = () => this.check();
  componentWillReceiveProps = () => this.check();

  check() {
    const { login, link } = this.props.match.params;
    this.props.checkPasswordLink(login, link);
  }


  render() {
    return (
      <RestorePasswordPage />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkPasswordLink: (login, link) => dispatch(checkPasswordLink(login, link)),
  };
}

const reducer = (state = {}) => state;
const withReducer = injectReducer({ key: 'restorePasswordFormPage', reducer });
const withSaga = injectSaga({ key: 'restorePasswordFormPage', saga });
const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(RestorePasswordPageContainer);

