/*
 *
 * NotificationsList reducer
 *
 */

import { List, Map } from 'immutable';

import { LOAD_DATA_SUCCESS } from './constants';

const initialState = new Map({
  data: new Map({
    ids: new List(),
  }),
});

function notificationsListReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DATA_SUCCESS:
      return state.setIn(['data', 'ids'], new List(action.payload.ids));

    default:
      return state;
  }
}

export default notificationsListReducer;
