/**
 * Initialize the One Signal
 */

import config from 'config';

export default function initOneSignal({ userId, userName, accountId, onNotificationDisplay, onNotificationDismiss, onNotificationOpen }) {
  const OneSignal = window.OneSignal || [];
  const { initialized } = OneSignal;

  if (!initialized) {
    OneSignal.push(() => {
      OneSignal.init({
        appId: config.oneSignalAppId,
        autoRegister: false,
        allowLocalhostAsSecureOrigin: true,
        notificationClickHandlerMatch: 'origin',
        notifyButton: {
          enable: true,
          size: 'small',
          position: 'bottom-left',
          text: {
            'tip.state.unsubscribed': 'Подписаться на уведомления',
            'tip.state.subscribed': 'Вы подписаны на уведомления',
            'tip.state.blocked': 'Вы заблокировали уведомления',
            'message.prenotify': 'Нажмите, чтобы подписаться на уведомления',
            'message.action.subscribed': 'Спасибо за подписку!',
            'message.action.resubscribed': 'Вы подписаны на уведомления',
            'message.action.unsubscribed': 'Вы больше не будете получать уведомления',
            'dialog.main.title': 'Управление уведомлениями сайта',
            'dialog.main.button.subscribe': 'ПОДПИСАТЬСЯ',
            'dialog.main.button.unsubscribe': 'ОТПИСАТЬСЯ',
            'dialog.blocked.title': 'Разблокировать уведомления',
            'dialog.blocked.message': 'Чтобы разрешить уведомления, следуйте этим инструкциям:',
          },
        },
        welcomeNotification: {
          title: 'Кларис',
          message: 'Спасибо за подписку!',
        },
        promptOptions: {
          actionMessage: 'Вы бы хотели получать уведомления о изменениях в ваших заявках и договорах?',
          exampleNotificationTitleDesktop: 'Пример уведомления',
          exampleNotificationMessageDesktop: 'Уведомления будут отображаться на вашем рабочем столе',
          exampleNotificationTitleMobile: 'Пример уведомления',
          exampleNotificationMessageMobile: 'Уведомления будут отображаться на вашем устройстве',
          exampleNotificationCaption: 'Вы можете отказаться от подписки в любое время',
          acceptButtonText: 'Продолжить',
          cancelButtonText: 'Нет спасибо',
          showCredit: false,
        },
      });

      if (Notification.permission === 'granted') {
        // Automatically subscribe user if deleted cookies and browser shows "Allow"
        OneSignal.registerForPushNotifications();
      } else {
        OneSignal.showHttpPrompt();
      }

      OneSignal.on('notificationDisplay', onNotificationDisplay);
      OneSignal.on('notificationDismiss', onNotificationDismiss);

      OneSignal.addListenerForNotificationOpened((data) => onNotificationOpen(data));
    });
  }

  OneSignal.push(() => {
    OneSignal.sendTags({ userId, userName, accountId });
  });  
}
