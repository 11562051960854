import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ON_CAR_ENTRY,
  SERVICE,
} from 'domain/Data/RequestType/constants';

import {
  isCurrentYear,
  formattedDate,
  formattedNumeric,
  formattedCurrentYearDate,
} from 'utils/dateUtils';

import { withStyles } from '@material-ui/core/styles/index';
import { statusColorLabels } from './mobileStatusLabels';
import { account } from 'domain/typeConstants/accounts';

const styles = () => ({
  root: {
    width: '100%',
    padding: '0 5px',
  },
  label: {
    padding: '1px 5px',
    textTransform: 'uppercase',
    borderRadius: 15,
  },
  highlight: {
    fontWeight: 'bold',
  },
  lineItem: {
    marginLeft: 5,
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  list: {
    display: "flex",
    flexDirection: "column",
  }
});

const HeaderComponent = ({
  classes,
  number,
  requestType,
  status,
  statusStyles,
}) => (
  <div className={classes.overflow}>
    <span>
      № <span>{number}</span>
    </span>
    <span className={classNames(classes.lineItem)}>{requestType}</span>
    <span
      style={statusStyles}
      className={classNames(classes.lineItem, classes.label)}
    >
      {status}
    </span>
  </div>
);

HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  requestType: PropTypes.string,
  status: PropTypes.string,
  statusStyles: PropTypes.object,
  number: PropTypes.string,
};

const FooterComponent = ({
  classes,
  expirationDate,
  visitDate,
}) => (
  <div className={classes.overflow}>
    {
      visitDate
      && (
        <span>
          {expirationDate ? 'от' : ''} <span>{visitDate}</span>
        </span>
      )
    }
    {
      expirationDate
      && (
        <span className={classes.lineItem}>
          до <span>{expirationDate}</span>
        </span>
      )
    }
  </div>
);

FooterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  expirationDate: PropTypes.string,
  visitDate: PropTypes.string,
};

const currentYearDateOptions = {
  day: 'numeric',
  month: 'long',
};

class RowComponentBase extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    maxWidth: PropTypes.number.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { record } = this.props;
    const currentStatus = record.status ? record.status.name : null;
    const nextStatus = nextProps.record.status ? nextProps.record.status.name : null;

    if ((record.number === nextProps.record.number)
      || (currentStatus === nextStatus)) return true;

    return false;
  }

  getContentByRequestType = (type) => {
    const { record, classes, accountId } = this.props;

    if (accountId === account.BINOM) {
      
      const executionTerm = record.executionTerm && isCurrentYear(record.executionTerm)
      ? formattedCurrentYearDate(record.executionTerm, currentYearDateOptions)
      : formattedDate(record.executionTerm);

      return (
        <div className={classNames(classes.list)}>
          <span>{record.whereHappened}</span>
          <span>Срок исполнения: {executionTerm}</span>
          <span>{record.whatHappened}</span>
        </div>
      )
    }
    else if (type === ON_CAR_ENTRY) {
      return (
        <div
          className={classNames(classes.overflow, classes.highlight)}
        >
          <span className={classes.lineItem}>{record.carNumber === "" || record.carNumber === null
            ? record.visitorFullName
            : record.carNumber}</span>
        </div>
      );
    }
    else if (type === SERVICE) {
      return (
        <div
          className={classNames(classes.overflow, classes.highlight)}
        >
          <span>{record.whatHappened}</span>
          <span className={classes.lineItem}>{record.whereHappened}</span>
        </div>
      );
    }
    else
      return (
        <div className={classNames(classes.overflow, classes.highlight)}>
          <span className={classes.lineItem}>{record.visitorFullName}</span>
        </div>
      );
  };

  render() {
    const { record, classes, maxWidth, accountId } = this.props;
    const requestType = record.type;

    if (requestType || accountId === account.BINOM) {
      const number = record.number && formattedNumeric(record.number);
      const visitDate = record.visitDate && isCurrentYear(record.visitDate)
        ? formattedCurrentYearDate(record.visitDate, currentYearDateOptions)
        : formattedDate(record.visitDate);
      const expirationDate = record.expirationDate && isCurrentYear(record.expirationDate)
        ? formattedCurrentYearDate(record.expirationDate, currentYearDateOptions)
        : formattedDate(record.expirationDate);
      const status = record.status ? record.status.name : undefined;

      const statusStyles = statusColorLabels[status];

      return (
        <div className={classes.root} style={{ maxWidth }}>
          <HeaderComponent
            classes={classes}
            number={number}
            requestType={requestType?.shortName || ''}
            status={status}
            statusStyles={statusStyles}
          />
          {this.getContentByRequestType(requestType?.id)}
          <FooterComponent
            classes={classes}
            expirationDate={expirationDate}
            visitDate={visitDate}
          />
        </div>
      );
    }

    return null;
  }
}

export const RowComponent = withStyles(styles)(RowComponentBase);
