/**
 *
 * VisitorArrivalFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { VISITOR_ARRIVAL_FORM, VISITOR_ARRIVAL_FORM_DIALOG } from './constants';

function VisitorArrivalFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={VISITOR_ARRIVAL_FORM_DIALOG}
      formName={VISITOR_ARRIVAL_FORM}
      advancedForm={form}
      editModeTitle="Регистрация посетителя"
    />
  );
}

VisitorArrivalFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default VisitorArrivalFormDialog;
