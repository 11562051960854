/**
 *
 * ApplicantMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import { columnType } from 'domain/typeConstants/table';

import {
  ACTIVE_REQUESTS,
  REQUESTS_FOR_APPLICANT,
} from 'domain/Data/Filters/constants';

import { APPLICANT_REQUESTS_TABLE_GRID } from './constants';

import {
  addActionButton,
} from '../common/actionButton';
import {
  viewActionItem,
  copyActionItem,
  closeActionItem,
} from '../common/actionMenu';
import {
  RowComponent, getRowHeight, statusColorLabels,
} from '../common/mobile';

class ApplicantMobileGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_APPLICANT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }

  getDetailRowFields() {
    return [
      {
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
      },
      {
        name: 'actualCreationDate',
        title: 'Добавлено',
        type: columnType.DATE,
      },
      {
        name: 'statusName',
        getValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: statusColorLabels,
      },
      {
        name: 'serviceName',
        getValue: (row) => (row.service ? row.service.name : undefined),
        title: 'Сервис',
      },
      {
        name: 'whereHappened',
        title: 'Место',
      },
      {
        name: 'whatHappened',
        title: 'Что случилось',
      },
      {
        name: 'contactPersonFullName',
        title: 'Контактное лицо',
      },
      {
        name: 'executionTerm',
        title: 'Срок исполнения',
        type: columnType.DATE,
      },
      {
        name: 'executionDate',
        title: 'Исполнение факт',
        type: columnType.DATE,
      },
      {
        name: 'implementationReport',
        title: 'Отчет о выполнении',
      },
      {
        name: 'note',
        title: 'Примечание',
      },
    ];
  }

  getActionButtons() {
    return [addActionButton];
  }

  getActionMenuItems() {
    return [
      viewActionItem, copyActionItem, closeActionItem,
    ];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  render() {
    const { onActionExecuting, viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="requests"
        name={APPLICANT_REQUESTS_TABLE_GRID}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        filters={this.getFilters()}
        onActionExecuting={onActionExecuting}
        sorting={this.getSorting()}
        viewName={viewName}
        detailRowFields={this.getDetailRowFields()}
        rowComponent={RowComponent}
        getRowHeight={getRowHeight}
      />
    );
  }
}

ApplicantMobileGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  viewName: PropTypes.string,
};

export default ApplicantMobileGrid;
