/**
 *
 * AdvancedForm actions
 *
 */

import {
    ADD_ENTITY_TO_NORMALIZATION_QUEUE,
    INCREMENT_REQUESTS_RECEIVED_PARAMETER,
    CALCULATE_FIELDS_VALUES,
    CLEAR_NORMALIZATION_QUEUE,
    INCREMENT_REQUESTS_SENT_PARAMETER,
    INITIALIZE,
    LOAD_FORM_DATA,
    LOAD_FORM_DATA_ERROR,
    LOAD_FORM_DATA_SUCCESS,
    REGISTER,
    SUBMIT_FORM,
    SUBMIT_FORM_ERROR,
    SUBMIT_FORM_SUCCESS,
    UNREGISTER,
    LOAD_DATA_TABLE,
    CALCULATE_DATA_TABLE,
    CHANGE_COLUMNS,
    CHANGE_CURRENT_RECORD,
    CHANGE_COLUMN_ORDER_TABLE,
    CHANGE_COLUMN_WIDTHS_TABLE,
    CHANGE_LOADER_STATE_TABLE,
    CHANGE_SELECTION_TABLE,
    CHANGE_CHECKED_ALL_TABLE,
    UPDATE_BUTTONS_STATE,
    CLEAR_SELECTION_TABLE,
    CHANGE_PROGRESS_VISIBILITY,
    CHANGE_LAZY_LOADING_STATE,
    LOAD_DATA_ERROR,
    LOAD_DATA_SUCCESS_TABLE,
    CHANGE_ENTITIES_TOTAL_COUNT,
    CLEAR_ROW_DATA,
    CHANGE_SORTING,
    CHANGE_PAGE_NUMBER,
    CHANGE_PAGE_SIZE,
    CHANGE_GRID_FILTERS,
    SET_GROUPS,
    CHANGE_DETAIL_TABLE_DATA,
} from './constants';

export function initialize() {
    return {
        type: INITIALIZE,
    };
}

export function loadFormData(advancedForm, mode, entityId) {
    return {
        type: LOAD_FORM_DATA,
        meta: {advancedForm},
        payload: {mode, entityId},
    };
}

export function formDataLoaded(advancedForm) {
    return {
        type: LOAD_FORM_DATA_SUCCESS,
        meta: {advancedForm},
    };
}

export function formDataLoadingError(advancedForm, error) {
    return {
        type: LOAD_FORM_DATA_ERROR,
        meta: {advancedForm},
        payload: {error},
    };
}

export function submitForm(advancedForm) {
    return {
        type: SUBMIT_FORM,
        meta: {advancedForm},
    };
}

export function formDataSubmited(advancedForm) {
    return {
        type: SUBMIT_FORM_SUCCESS,
        meta: {advancedForm},
    };
}

export function formDataSubmitingError(advancedForm, error) {
    return {
        type: SUBMIT_FORM_ERROR,
        meta: {advancedForm},
        payload: {error},
    };
}

export function calculateFieldsValues(advancedForm, nameOfFieldCausedCalculation) {
    return {
        type: CALCULATE_FIELDS_VALUES,
        meta: {advancedForm, nameOfFieldCausedCalculation},
    };
}

export function register(advancedForm, {entityName, entityId, fields, useAsyncValidate, groups = [], params} = {}) {
    return {
        type: REGISTER,
        meta: {advancedForm},
        payload: {
            entityName,
            entityId,
            fields,
            useAsyncValidate,
            groups,
            params,
        },
    };
}

export const clearDetailRowData = (form, entityName) => ({
    type: CLEAR_ROW_DATA,
    meta: {form},
    payload: {entityName}
});

export function changeSorting(dxGrid, sorting, entityName) {
    return {
        type: CHANGE_SORTING,
        meta: {dxGrid},
        payload: {entityName, sorting},
    };
}

export function changePageNumber(dxGrid, entityName, pageNumber) {
    return {
        type: CHANGE_PAGE_NUMBER,
        meta: {dxGrid},
        payload: {entityName, pageNumber},
    };
}

export function changePageSize(dxGrid, entityName, pageSize, pageNumber) {
    return {
        type: CHANGE_PAGE_SIZE,
        meta: {dxGrid},
        payload: {entityName, pageSize, pageNumber},
    };
}

export function changeGridFilters(dxGrid, {entityName, gridFilters}) {
    return {
        type: CHANGE_GRID_FILTERS,
        meta: {dxGrid},
        payload: {entityName, gridFilters},
    };
}


export const loadDataTable = (dxGrid, {
    entityName, parentRowId, parentEntityName,
}) => ({
    type: LOAD_DATA_TABLE,
    meta: {dxGrid},
    payload: {
        entityName,
        parentRowId,
        parentEntityName,
    },
});


export const calculateDataTable = (dxGrid, {
    entityName, parentRowId, parentEntityName, sorting, pageSize, pageNumber,
}) => ({
    type: CALCULATE_DATA_TABLE,
    meta: {dxGrid},
    payload: {
        entityName,
        parentRowId,
        parentEntityName,
        sorting,
        pageSize,
        pageNumber,
    },
});

export function changeColumns(dxGrid, entityName, columns) {
    return {
        type: CHANGE_COLUMNS,
        meta: {dxGrid},
        payload: {entityName, columns},
    };
}

export function changeCurrentRecord(dxGrid, entityName, id) {
    return {
        type: CHANGE_CURRENT_RECORD,
        meta: {dxGrid},
        payload: {entityName, id},
    };
}

export const changeColumnOrder = (dxGrid, entityName, columnOrder) => ({
    type: CHANGE_COLUMN_ORDER_TABLE,
    meta: {dxGrid},
    payload: {entityName, columnOrder},
});

export const changeColumnWidths = (dxGrid, entityName, columnWidths) => ({
    type: CHANGE_COLUMN_WIDTHS_TABLE,
    meta: {dxGrid},
    payload: {entityName, columnWidths},
});

export const changeLoaderStateTable = (dxGrid, {entityName, loaderState}) => ({
    type: CHANGE_LOADER_STATE_TABLE,
    meta: {dxGrid},
    payload: {entityName, loaderState},
});

export function changeSelection(dxGrid, {entityName, selection}) {
    return {
        type: CHANGE_SELECTION_TABLE,
        meta: {dxGrid},
        payload: {entityName, selection},
    };
}


export function clearSelection(dxGrid, {entityName}) {
    return {
        type: CLEAR_SELECTION_TABLE,
        meta: {dxGrid},
        payload: {entityName},
    };
}

export function changeCheckedAll(dxGrid, {entityName, checkedAll}) {
    return {
        type: CHANGE_CHECKED_ALL_TABLE,
        meta: {dxGrid},
        payload: {entityName, checkedAll},
    };
}


export function updateButtonsState(dxGrid, {entityName, actionButtons}) {
    return {
        type: UPDATE_BUTTONS_STATE,
        meta: {dxGrid},
        payload: {entityName, actionButtons},
    };
}


export function changeProgressVisibility(dxGrid, {entityName, progressVisibility}) {
    return {
        type: CHANGE_PROGRESS_VISIBILITY,
        meta: {dxGrid},
        payload: {entityName, progressVisibility},
    };
}

export function changeLazyLoadingState(dxGrid, lazyLoadingState) {
    return {
        type: CHANGE_LAZY_LOADING_STATE,
        meta: {dxGrid},
        payload: {lazyLoadingState},
    };
}

export function dataLoadingError(dxGrid, error) {
    return {
        type: LOAD_DATA_ERROR,
        meta: {dxGrid},
        payload: {error},
    };
}

export function dataLoadedSuccess(dxGrid, {entityName, uniqueIds}) {
    return {
        type: LOAD_DATA_SUCCESS_TABLE,
        meta: {dxGrid},
        payload: {entityName, uniqueIds},
    };
}


export function changeEntitiesTotalCount(dxGrid, {entityName, totalCount}) {
    return {
        type: CHANGE_ENTITIES_TOTAL_COUNT,
        meta: {dxGrid},
        payload: {entityName, totalCount},
    };
}

export function unregister(advancedForm) {
    return {
        type: UNREGISTER,
        meta: {advancedForm},
    };
}

export function addEntityToNormalizationQueue(advancedForm, entityName, entity) {
    return {
        type: ADD_ENTITY_TO_NORMALIZATION_QUEUE,
        meta: {advancedForm, entityName},
        payload: {entity},
    };
}

export function clearNormalizationQueue(advancedForm) {
    return {
        type: CLEAR_NORMALIZATION_QUEUE,
        meta: {advancedForm},
    };
}

export function incrementRequestsSentParameter(advancedForm) {
    return {
        type: INCREMENT_REQUESTS_SENT_PARAMETER,
        meta: {advancedForm},
    };
}

export function incrementRequestsReceivedParameter(advancedForm) {
    return {
        type: INCREMENT_REQUESTS_RECEIVED_PARAMETER,
        meta: {advancedForm},
    };
}

export function setGroups(advancedForm, groups) {
    return {
        type: SET_GROUPS,
        meta: {advancedForm},
        payload: {groups},
    };
}

export function changeDetailTableData(advancedForm, entityName, data) {
    return {
        type: CHANGE_DETAIL_TABLE_DATA,
        meta: {advancedForm, entityName},
        payload: {data},
    };
}
