import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';


const styles = () => ({
  root: {
    width: '100%',
    padding: '0 5px',
  },
  label: {
    textTransform: 'uppercase',
    borderRadius: 15,
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const HeaderComponent = ({ classes, name }) => (
  <div className={classes.overflow}>
    <span>Логин: {name}</span>
  </div>
);

const FooterComponent = ({ classes, employee }) => (
  <div className={classes.overflow}>
    <span>
      Сотрудник: {employee}
    </span>
  </div>
);

HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
};

FooterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  employee: PropTypes.string,
};

class RowComponentBase extends React.Component {

  shouldComponentUpdate(nextProps) {
    const { record } = this.props;
    const currentStatus = record.status ? record.status.name : null;
    const nextStatus = nextProps.record.status ? nextProps.record.status.name : null;

    if ((record.number === nextProps.record.number)
      || (currentStatus === nextStatus)) return true;

    return false;
  }

  render() {
    const { record, classes, maxWidth } = this.props;
    const name = record.name
    const employee = record.employee ? record.employee.name : undefined;

    return (
      <div className={classes.root} style={{ maxWidth }}>
        <HeaderComponent
          classes={classes}
          name={name}
        />
        <FooterComponent
          classes={classes}
          employee={employee}
        />
      </div>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    maxWidth: PropTypes.number.isRequired,
  };

}

export const RowComponent = withStyles(styles)(RowComponentBase);
