/**
 *
 * AgreementDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { EMPLOYEES } from 'domain/Data/constants';

import reducer from './reducer';
import saga from './sagas';

import { DIALOG_NAME } from './constants';
import * as actionCreators from './actions';
import AgreementDialog from './AgreementDialog';
import { makeSelectIsOpen, makeSelectRowIds, makeSelectManagerData, makeSelectManagersValues } from './selectors';

class AgreementDialogContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  componentDidMount() {
    this.props.loadOptions(EMPLOYEES)
  }

  handleSubmit = (ids, comment) => this.props.submit(ids, comment);

  handleCancel = () => this.props.close();

  render() {
    const {
      open,
      id,
      managers,
      ...restProps
    } = this.props;

    return (
      <AgreementDialog
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        open={open}
        id={id}
        managers={managers.toJS()}
        {...restProps}
      />
    );
  }
}

AgreementDialogContainer.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  id: PropTypes.string,
  managers: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  open: makeSelectIsOpen(),
  id: makeSelectRowIds(),
  managerOptions: makeSelectManagerData(EMPLOYEES),
  managers: makeSelectManagersValues(),
});

function mapDispatchToProps(dispatch) {
  return {
    submit: (ids, comment) => dispatch(actionCreators.submitAgreementDialog(ids, comment)),
    close: () => dispatch(actionCreators.closeAgreementDialog()),
    setManagers: (ids) => dispatch(actionCreators.setManagersValues(ids)),
    loadOptions: (entity) => dispatch(actionCreators.loadOptions(entity))
  };
}

const withReducer = injectReducer({ key: DIALOG_NAME, reducer });
const withSaga = injectSaga({ key: DIALOG_NAME, saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(AgreementDialogContainer);
