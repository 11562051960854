import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  isCurrentYear,
  formattedDate,
  formattedNumeric,
} from 'utils/dateUtils';

import { withStyles } from '@material-ui/core/styles/index';
import { statusColorLabels } from './mobileStatusLabels';

const styles = () => ({
  root: {
    width: '100%',
    padding: '0 5px',
  },
  label: {
    padding: '1px 5px',
    textTransform: 'uppercase',
    borderRadius: 15,
  },
  highlight: {
    fontWeight: 'bold',
  },
  lineItem: {
    marginLeft: 5,
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  description: {
    maxWidth: "100%",
    overflow: "hidden",
    height: "2.7em",
    position: "relative",
  },
  date_company: {
    height: "1.5em",
    maxWidth: "100%",
    overflow: "hidden",
  }
});

const HeaderComponent = ({
  classes,
  number,
  status,
  statusStyles,
}) => (
  <div className={classes.overflow}>
    <span>
      № <span>{number}</span>
    </span>
    <span
      style={statusStyles}
      className={classNames(classes.lineItem, classes.label)}>
      {status}
    </span>
  </div>
);

HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string,
  statusStyles: PropTypes.object,
  number: PropTypes.string,
};

const FooterComponent = ({ classes, registrationDate, company, description, }) => (
  <div >
    <div className={classes.date_company}>
      <span className={classes.overflow}>
        {registrationDate}
      </span> <span className={classes.lineItem}>{company}</span>
    </div>
    <div className={classes.description}>
      {description}
    </div>

  </div>
);

FooterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

class RowComponentBase extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    maxWidth: PropTypes.number.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { record } = this.props;
    const currentStatus = record.status ? record.status.name : null;
    const nextStatus = nextProps.record.status ? nextProps.record.status.name : null;

    if ((record.number === nextProps.record.number)
      || (currentStatus === nextStatus)) return true;

    return false;
  }

  render() {
    const { record, classes, maxWidth } = this.props;
    const number = record.number && formattedNumeric(record.number);
    const status = record.status && record.status.statusZadachi ? record.status.statusZadachi : record.status.name;

    const regDateArr = formattedDate(record.registrationDate).slice(0, -3).split('.');
    const registrationDate = record.registrationDate && isCurrentYear(record.registrationDate)
      ? regDateArr[0] : regDateArr[0] + regDateArr[1]

    const statusStyles = statusColorLabels[status];
    const company = record.company ? record.company.name : undefined;
    const description = record.description ?
      record.description.replace(/(<([^>]+)>)/ig, '') : null;

    return (
      <div className={classes.root} style={{ maxWidth }}>
        <HeaderComponent
          classes={classes}
          number={number}
          status={status}
          statusStyles={statusStyles}
        />
        <FooterComponent
          classes={classes}
          registrationDate={registrationDate}
          company={company}
          description={description}
        />
      </div>
    );
  }
}

export const RowComponent = withStyles(styles)(RowComponentBase);
