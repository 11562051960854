/**
 *
 * UnbindingAccessCardDialogContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './sagas';

import * as actionCreators from './actions';
import UnbindingAccessCardDialog from './UnbindingAccessCardDialog';
import {
  makeSelectLoadingState,
  makeSelectSnackbarVariant,
  makeSelectSnackbarMessage,
  makeSelectSnackbarState,
} from './selectors';

class UnbindingAccessCardDialogContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleSubmit = (ids, comment) => this.props.submit(ids, comment);

  handleCloseSnackbar = () => {
    const { changeSnackbarState, snackbarVariant } = this.props;
    if (changeSnackbarState) {
      changeSnackbarState({
        state: false,
        message: '',
        variant: snackbarVariant,
      });
    }
  };

  render() {
    const {
      loadingState,
      snackbarState,
      snackbarMessage,
      snackbarVariant,
    } = this.props;

    return (
      <UnbindingAccessCardDialog
        onSubmit={this.handleSubmit}
        loadingState={loadingState}
        snackbarState={snackbarState}
        snackbarMessage={snackbarMessage}
        snackbarVariant={snackbarVariant}
        onCloseSnackbar={this.handleCloseSnackbar}
      />
    );
  }
}

UnbindingAccessCardDialogContainer.propTypes = {
  submit: PropTypes.func.isRequired,
  loadingState: PropTypes.bool.isRequired,
  snackbarState: PropTypes.bool,
  snackbarMessage: PropTypes.string,
  snackbarVariant: PropTypes.string,
  changeSnackbarState: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loadingState: makeSelectLoadingState(),
  snackbarState: makeSelectSnackbarState(),
  snackbarMessage: makeSelectSnackbarMessage(),
  snackbarVariant: makeSelectSnackbarVariant(),
});

function mapDispatchToProps(dispatch) {
  return {
    submit: (key) => dispatch(actionCreators.submitUnbindingAccessCardDialog(key)),
    changeSnackbarState: (params) => dispatch(actionCreators.changeSnackbarState(params)),
  };
}

const withReducer = injectReducer({ key: 'unbindingAccessCardDialog', reducer });
const withSaga = injectSaga({ key: 'unbindingAccessCardDialog', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(UnbindingAccessCardDialogContainer);
