/*
 *
 * TenantForm15783
 *
 */

import {TenantForm} from 'pages/Requests/TenantView';
import {ON_VISIT, ON_CAR_ENTRY, SERVICE, ON_PASS_MAKING} from 'domain/Data/RequestType/constants';
import {ACCEPTED, CREATED} from 'domain/Data/RequestStatus/constants';
import {issueReasonType} from "../common/constans";


export class TenantForm15783 extends TenantForm {

    getFields() {
        const fields = super.getFields();
        const statusIndex = fields.findIndex((field) => field.name === 'status');
        fields[statusIndex].calculatedValue = (values, mode) => {
            if (mode === 'add' && (values.type === ON_VISIT || values.type === ON_CAR_ENTRY)) {
                return ACCEPTED
            } else return CREATED
        }

        const visitDateIndex = fields.findIndex((field) => field.name === 'visitDate');
        fields[visitDateIndex].rowBreakBefore = (values, initialValues, mode) => mode !== 'view' && values.type !== ON_PASS_MAKING

        const longtermIndex = fields.findIndex((field) => field.name === 'longterm');
        fields[longtermIndex].visible = (values) => values.type !== SERVICE && values.type !== ON_PASS_MAKING

        const visitorFullNameIndex = fields.findIndex((field) => field.name === 'visitorFullName');
        fields[visitorFullNameIndex].required = (values) => !values.isGroupRequest && (values.type === ON_VISIT || values.type === ON_PASS_MAKING)

        const accessLevel =
            {
                name: 'vozdvizhenkaAccessLevel',
                type: 'select',
                entityName: 'vozdvizhenkaAccessLevels',
                label: 'Уровень доступа',
                visible: (values) => values.type === ON_PASS_MAKING,
                orderBy: 'id',
            }

        fields.splice(visitorFullNameIndex + 1, 0, accessLevel)

        const fileIndex = fields.findIndex((field) => field.name === 'file');
        fields[fileIndex].helperText = 'Фото логотипа (при необходимости)'

        const issueReason = {
            name: 'issueReason',
            type: 'select',
            label: 'Причина выдачи',
            entityName: 'issueReasons',
            visible: (values) => values.type === ON_PASS_MAKING,
            visibleOptions: () => [issueReasonType.NEW_EMPLOYEE, issueReasonType.LOST]
        }
        fields.splice(fileIndex + 1, 0, issueReason)

        return fields;
    }

}



