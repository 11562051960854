/**
 *
 * ReceptionView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

function ReceptionView({ dataTable, formDialog, viewName, viewStyles }) {
    return (
        <div style={viewStyles.root}>
            <Paper style={viewStyles.dataTable}>
                {
                    React.cloneElement(dataTable, { viewName })
                }
            </Paper>
            {
                React.cloneElement(formDialog, { viewName })
            }
        </div>
    );
}

ReceptionView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
    viewStyles: PropTypes.object,
};

ReceptionView.defaultProps = {
    viewStyles: {},
};

export default ReceptionView;
