/*
 *
 * ApplicantView constants
 *
 */

export const APPLICANT_REQUESTS_DATA_TABLE = 'ApplicantRequestsDataTable';
export const APPLICANT_REQUESTS_TABLE_GRID = 'ApplicantRequestsTableGrid';
export const APPLICANT_REQUESTS_FORM = 'ApplicantRequestsForm';
export const APPLICANT_REQUESTS_FORM_DIALOG = 'ApplicantRequestsFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/RequestsPage/ApplicantView/EXECUTE_ADD_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/ApplicantView/EXECUTE_VIEW_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/RequestsPage/ApplicantView/EXECUTE_COPY_ACTION';
export const EXECUTE_CLOSE_ACTION = 'claris-vnext/RequestsPage/ApplicantView/EXECUTE_CLOSE_ACTION';
