/*
 *
 * AssignedForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { IN_PROGRESS } from 'domain/Data/RequestStatus/constants';
import { SERVICE } from 'domain/Data/RequestType/constants';
import { ASSIGNED_REQUESTS_FORM } from './constants';

class AssignedForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'assignee',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'customer',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'company',
        visible: false,
        initialValue: (values, mode, user) => user.companyId,
        numberInQueueFromEnd: 1,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        initialValue: () => SERVICE,
        visibleOptions: () => [SERVICE],
        orderBy: 'order',
        clearErrorsOnChange: true,
      },
      {
        name: 'status',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: false,
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return IN_PROGRESS; }

          return values.status;
        },
        orderBy: 'name',
      },
      {
        name: 'actualCreationDate',
        type: 'date',
        visible: false,
        initialValue: currentDate,
      },
      {
        name: 'service',
        type: 'select',
        label: 'Сервис',
        entityName: 'services',
        visible: true,
        rowBreakBefore: true,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        visible: true,
        rowBreakBefore: true,
        disabled: (currentValues, initialValues, mode) => mode === 'edit',
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
      },
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        visible: true,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: true,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: true,
        rowBreakBefore: true,
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={ASSIGNED_REQUESTS_FORM}
        entityName="requests"
        fields={this.getFields()}
        classNames={this.getClassNames()}
        {...this.props}
      />
    );
  }
}

export default AssignedForm;
