/**
 *
 * RequestType 12861 constants
 *
 */

export const CLOSED = '4285216000'
export const OPENED = '2237224236000'
export const POSTPONED = '3580622861000'
export const REPAIR = '3590476970000'
export const WELDING = '3579221331000'
export const PAINTING = '3691694580000'
export const COMPLETED = '421575453000'