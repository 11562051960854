/**
 *
 * ComboBoxCreator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import createComboBoxTextInputContainer from './createComboBoxTextInputContainer';

class ComboBoxTextInputCreator extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const ComboBoxTextInputContainer = createComboBoxTextInputContainer(this.context.advancedForm, this.props.name);
    return <ComboBoxTextInputContainer {...this.props} />;
  }
}

ComboBoxTextInputCreator.contextTypes = {
  advancedForm: PropTypes.string.isRequired,
};

ComboBoxTextInputCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ComboBoxTextInputCreator;