/**
 *
 * AdvancedMobileGrid row detail component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  FormattedDate,
  FormattedNumber,
  FormattedTime,
} from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

const styles = () => ({
  root: {
    width: 315,
    padding: 5,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  field: {
    display: 'flex',
    fontSize: 12,
    padding: 1,
  },
  fieldTitle: {
    marginRight: 3,
  },
  fieldValue: {
    fontWeight: 'bold',
  },
  label: {
    padding: '1px 5px',
    textTransform: 'uppercase',
    borderRadius: 15,
  },
  divider: {
    margin: '0 5px',
    height: '15px',
    width: 1,
    background: '#9e9e9e',
  },
  paper: {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 0 5px #000',
  },
});

class RowDetailBase extends React.PureComponent {
  fields = null;

  static propTypes = {
    anchorElLeftPosition: PropTypes.number,
    anchorElTopPosition: PropTypes.number,
    classes: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    row: PropTypes.object,
    detailRowFields: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          getValue: PropTypes.func,
          name: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          type: PropTypes.string,
        }),
      ),
      PropTypes.array,
      PropTypes.func,
    ]),
  };

  constructor(props) {
    super(props);
    const { detailRowFields, row } = props;

    this.fields = typeof detailRowFields === 'function'
      ? detailRowFields(row)
      : detailRowFields;
  }

  componentDidUpdate() {
    const { detailRowFields, row } = this.props;
    this.fields = typeof detailRowFields === 'function'
      ? detailRowFields(row)
      : detailRowFields;
  }

  componentWillUnmount() {
    this.fields = null;
  }

  getValueByType = (field) => {
    const { row, classes } = this.props;
    const value = field.getValue ? field.getValue(row) : row[field.name];
    const currentDate = new Date();
    const valueDate = new Date(value);

    if (value) {
      if (field.type === 'date') {
        if (currentDate.getFullYear() === valueDate.getFullYear()) {
          return <FormattedDate value={new Date(value)} month="long" day="numeric" />;
        }

        return <FormattedDate value={new Date(value)} year="numeric" month="long" day="numeric" />;
      } else if (field.type === 'datetime') {
        if (currentDate.getFullYear() === valueDate.getFullYear()) {
          return (
            <span>
              <FormattedDate value={new Date(value)} month="long" day="numeric" />
              &nbsp;
              <FormattedTime value={new Date(value)} hour="numeric" minute="numeric" />
            </span>
          );
        }

        return (
          <span>
            <FormattedDate value={new Date(value)} year="numeric" month="long" day="numeric" />
            &nbsp;
            <FormattedTime value={new Date(value)} hour="numeric" minute="numeric" />
          </span>
        );
      } else if (field.type === 'time') {
        return <FormattedTime value={new Date(value)} hour="numeric" minute="numeric" />;
      } else if (field.type === 'numeric' && value) {
        return !Number.isNaN(Number(value)) ? <FormattedNumber value={value} /> : value;
      } else if (field.type === 'label') {
        return (
          <span
            className={classes.label}
            style={field.labels && field.labels[value]}
          >
            {value}
          </span>
        );
      }
      else if (field.type === 'text' && value) {
        let valueWithoutTags = value.replace(/(<([^>]+)>)/ig, '');
        if (valueWithoutTags.length > 100) { valueWithoutTags = valueWithoutTags.substr(0, 100) + "..." }
        return valueWithoutTags
      }

    }

    return value;
  };

  getValue = (field) => {
    const { row } = this.props;
    return field.getValue ? field.getValue(row) : row[field.name];
  };

  renderValue = (field) => {
    const { classes } = this.props;
    if (!field) return null;

    if (this.getValue(field)) {
      return (
        <div
          className={classNames(classes.field)}
          key={field.name}
        >
          <div className={classes.fieldTitle}>{field.title}:</div>
          <div className={classes.fieldValue}>
            {
              field.type
                ? this.getValueByType(field)
                : this.getValue(field)
            }
          </div>
        </div>
      );
    }

    return null;
  };

  renderChildRow = (row, number) => {
    const { classes } = this.props;
    const length = row.filter((field) => this.getValue(field)).length;

    return (
      <div className={classes.row} key={`row_number_${number + 1}`}>
        {
          row.map((field, index) => (
            <React.Fragment key={field.name}>
              {this.renderValue(field)}
              {
                length > 1 && index !== length - 1
                && (
                  <span className={classes.divider} />
                )
              }
            </React.Fragment>
          ))
        }
      </div>
    );
  };

  render() {
    const {
      anchorElLeftPosition,
      anchorElTopPosition,
      classes,
      onClose,
      open,
      row,
    } = this.props;

    return (
      <Popover
        anchorOrigin={{
          horizontal: anchorElLeftPosition || 'left',
          vertical: anchorElTopPosition || 'top',
        }}
        onClose={onClose}
        open={open}
      >
        <div className={classes.root}>
          {
            row && this.fields && this.fields.map((field, index) =>
              Array.isArray(field)
                ? this.renderChildRow(field, index)
                : this.renderValue(field)
            )
          }
        </div>
      </Popover>
    );
  }
}

export const RowDetail = withStyles(styles, { name: 'RowDetail' })(RowDetailBase);
