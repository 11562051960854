/**
 *
 * RequestsPage11994
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import PropTypes from 'prop-types';

import CheckpointDxGrid11994 from './CheckpointView/CheckpointDxGrid11994';
import CheckpointForm11994 from './CheckpointView/CheckpointForm11994';
import LefortReceptionForm11994 from './TenantView/LefortReceptionForm11994';
import LefortTenantForm11994 from './TenantView/LefortTenantForm11994';
import ReceptionDxGridContainer11994 from './ReceptionView/ReceptionDxGridContainer11994';
import ReceptionForm11994 from './ReceptionView/ReceptionForm11994';
import TenantDxGrid11994 from './TenantView/TenantDxGrid11994';
import TenantForm11994 from './TenantView/TenantForm11994';
import VisitorArrivalForm11994 from './ReceptionView/VisitorArrivalForm11994';

function RequestsPage11994(props) {
  const tenantForm = () => {
    if (props.roles.includes('lefortTenant')) {
      return <LefortTenantForm11994 />;
    } else if (props.roles.includes('lefortReception')) {
      return <LefortReceptionForm11994 />;
    }
    return <TenantForm11994 />;
  };

  return (
    <RequestsPage
      checkpointDxGrid={<CheckpointDxGrid11994 />}
      checkpointForm={<CheckpointForm11994 />}
      receptionDxGrid={<ReceptionDxGridContainer11994 />}
      receptionForm={<ReceptionForm11994 />}
      tenantDxGrid={<TenantDxGrid11994 />}
      tenantForm={tenantForm()}
      visitorArrivalForm={<VisitorArrivalForm11994 />}
      {...props}
    />
  );
}

RequestsPage11994.propTypes = {
  roles: PropTypes.array,
};

export default RequestsPage11994;
