import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import SupplyPositionView, {
  SupplyPositionDxGrid,
  SupplyPositionDxGridContainer,
} from '../SupplyPositionView';

import { DocumentPositionForm, DocumentPositionFormDialog } from '../SupplyRequestView/index';

const getDxGrid = (DxGrid) => (
  <SupplyPositionDxGridContainer component={DxGrid || <SupplyPositionDxGrid />} />
);

const getFormDialog = (Form) => (
  <DocumentPositionFormDialog form={Form || <DocumentPositionForm />} />
);

const getDataTableView = ({ supplyRequestPositionDxGrid }) => {
  if (isUndefined(supplyRequestPositionDxGrid) || supplyRequestPositionDxGrid) {
    return getDxGrid(supplyRequestPositionDxGrid);
  }
  return <div>ERROR TEMPLATE</div>
};

export const getSupplyPositionView = (props) => {
  const {
    supplyRequestPositionForm,
    supplyRequestPositionFormDialog,
    supplyRequestPositionDxGrid,
    supplyRequestView,
    viewName,
    viewStyles,
  } = props;

  if (supplyRequestView) return supplyRequestView;

  return (
    <SupplyPositionView
      key={viewName}
      viewName={viewName}
      dataTable={getDataTableView({
        supplyRequestPositionDxGrid,
      })}
      formDialog={supplyRequestPositionFormDialog || getFormDialog(supplyRequestPositionForm)}
      viewStyles={viewStyles}
    />
  );
};

getSupplyPositionView.propTypes = {
  supplyRequestDataTable: PropTypes.node,
  supplyRequestPositionDxGrid: PropTypes.node,
  supplyRequestForm: PropTypes.node,
  supplyRequestFormDialog: PropTypes.node,
  supplyRequestPositionForm: PropTypes.node,
  supplyRequestPositionFormDialog: PropTypes.node,
  supplyRequestView: PropTypes.node,
  viewName: PropTypes.string,
};
