/**
*
* MessageFieldContainer
*
*/

import React from 'react';
import { Field } from 'redux-form/immutable';
import MessageField from './MessageField';

class MessageFieldContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  render() {
    const { ...restProps } = this.props; // eslint-disable-line no-unused-vars

    return (
      <Field
        {...restProps}
        component={MessageField}
      />
    );
  }
}

export default MessageFieldContainer;
