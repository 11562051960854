/*
 *
 * Autocomplete sagas
 *
 */

import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { suggestionsLoaded, suggestionsLoadingError } from './actions';
import { LOAD_SUGGESTIONS } from './constants';
import makeSelectField from './selectors';

export default function* rootSaga() {
  yield takeLatest(LOAD_SUGGESTIONS, function* (action) {
    yield call(loadOptionsSaga, action);
  });
}

export function* loadOptionsSaga({ meta: { advancedForm, field }, payload }) {
  try {
    const { dadataType, dadataParts } = yield select(makeSelectField(advancedForm, field));
    const result = yield call(getSuggestions, payload.searchQuery, dadataType, dadataParts);
    yield put(suggestionsLoaded(advancedForm, field, result.data.suggestions));
  } catch (error) {
    yield put(suggestionsLoadingError(advancedForm, field, error));
  }
}

function getSuggestions(searchQuery, type, parts) {
  return axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio', {
    query: searchQuery,
    parts,
    count: 5,
  }, {
    headers: {
      Authorization: 'Token c325231f829d310a725a2257452332671bb3a74e',
    },
  });
}
