/**
 *
 *  CheckpointDxGrid14366
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';
import {
  CURRENT_DAY_REQUESTS,
  NOT_REJECTED_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_FOR_CHECKPOINT,
  ACTIVE_REQUESTS,
} from 'domain/Data/Filters/constants';

import { requestColumnSizes } from 'domain/typeConstants/table';

export class CheckpointDxGrid14366 extends CheckpointDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_CHECKPOINT, REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);  
    if (viewName === 'today') {
      filters.push(CURRENT_DAY_REQUESTS);
      filters.push(NOT_REJECTED_REQUESTS);
    }
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }

  getColumns() {
    const columns = super.getColumns();
    const companyIndex = columns.findIndex((column) => column.name === 'tenantCompanyName');

    columns.splice(companyIndex, 0, {
      path: 'office',
      name: 'office',
      title: '№ Офиса',
      getCellValue: (row) => row.company ? row.company.office : undefined,
      width: requestColumnSizes.office,
    });
    return columns;
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();
    columnFilters.push({ columnName: 'office', value: '' });

    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const companyIndex = columnOrder.findIndex((item) => item === 'tenantCompanyName');

    columnOrder.splice(companyIndex, 0, 'office');

    return columnOrder;
  }
}
