/**
 *
 * ServiceRequestsManagerVisitorArrivalFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';

import { SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM, SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM_DIALOG } from './constants';

function ServiceRequestsManagerVisitorArrivalFormDialog({ form }) {
  return (
    <AdvancedFormDialog
      name={SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM_DIALOG}
      formName={SERVICE_REQUESTS_MANAGER_VISITOR_ARRIVAL_FORM}
      advancedForm={form}
      editModeTitle="Регистрация посетителя"
    />
  );
}

ServiceRequestsManagerVisitorArrivalFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ServiceRequestsManagerVisitorArrivalFormDialog;
