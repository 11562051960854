/**
*
* SelectGridField
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import SelectGridDialogContainer from './templates/SelectGridDialogContainer';

import {
  SightingForm,
  SightingFormDialogContainer,
} from '../../../pages/Documents/SightingView';

const styles = {
  root: {
    color: 'red',
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  buttonPanel: {
    display: 'flex'
  },
};

class SelectGridField extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      openSelectDialog: false,
      entityRecord: null,
    };
  }

  componentDidMount() {
    const { input: { value }, entities, entityName } = this.props;
    if (value) {
      const entity = entities.get(entityName).get(value);
      entity &&
        this.setState({
          entityRecord: { id: value, row: entity },
          value: entity.name
        })
    }
  }

  render() {
    const {
      classes,
      classNames,
      helperText,
      input,
      label,
      meta: { touched, error, submitFailed },
      readOnly,
      required,
      fullWidth,
      disabled,
      entityName,
    } = this.props;

    const { entityRecord } = this.state;

    const handleSearchClick = () => this.setState({ openSelectDialog: true })
    const handleCancelClick = () => this.setState({ openSelectDialog: false })

    const handleRemoveClick = () => {
      this.setState({ value: '' })
      input.onChange(null);
    }

    const handleEditClick = () => {
      const { executeEditAction } = this.props
      if (executeEditAction) executeEditAction(this.state.entityRecord.id)
    }

    const handleSelectClick = (id) => {
      this.setState({ open: false })
      input.onChange(id);
    }

    const renderButtonPanel = () => {

      const renderButton = (name, icon, onClick) => {
        const { input, disabled } = this.props;
        return (
          <InputAdornment position="end">
            <IconButton
              className={classes.icon}
              onClick={onClick}
              disabled={['edit', 'remove'].includes(name) && !input.value || disabled}
            >
              <Icon>{icon}</Icon>
            </IconButton>
          </InputAdornment>
        )
      }

      return (
        <div className={classes.buttonPanel}>
          {renderButton('search', 'search', handleSearchClick)}
          {renderButton('edit', 'edit', handleEditClick)}
          {renderButton('remove', 'close', handleRemoveClick)}
        </div>
      )
    }

    return (
      <>
        <TextField
          label={required ? `${label} *` : label}
          margin="none"
          error={(touched || submitFailed) && !!error}
          helperText={(touched || submitFailed) && error ? error : helperText}
          fullWidth={fullWidth}
          value={this.state.value || ''}
          disabled={disabled}
          FormHelperTextProps={{
            classes: {
              root: classNames.helperText,
            },
          }}
          InputProps={{
            readOnly,
            classes: {
              input: classes.input,
            },
            endAdornment: (renderButtonPanel()),
          }}
        />

        <SelectGridDialogContainer
          open={this.state.openSelectDialog}
          onSelect={handleSelectClick}
          onCancel={handleCancelClick}
          entityName={entityName}
        />

        {entityRecord && entityName === 'documents' &&
          <SightingFormDialogContainer
            entityFromSelectGridField={entityRecord}
            viewName={"allDocuments"}
            form={<SightingForm />}
          />
        }
      </>
    )
  }
}

const StyledSelectGridField = styled(SelectGridField)`
  input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset;
  }

  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px white inset;
  }
`;

SelectGridField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  autoComplete: PropTypes.string,
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
};

SelectGridField.defaultProps = {
  classNames: {},
};

export default withStyles(styles)(StyledSelectGridField);
