import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

const styles = () => ({
    root: {
        paddingLeft: '10px',
    },
    toolbarButton: {
        height: 25,
        width: 25,
        margin: '2px',
    },
});

class FilterPanelBase extends React.Component {

    handleClickFilterOff = () => {
        const { onClickFilterOff, loadData } = this.props;
        onClickFilterOff();
        loadData();
    }

    isFilterOffButton = () => {
        const { features } = this.props
        return features ? features.find((item) => item.name === settings.CLEAR_FILTER_BUTTON)?.value : false
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.isFilterOffButton() &&
                    <IconButton
                        className={classes.toolbarButton}
                        aria-label={'filtersOff'}
                        onClick={this.handleClickFilterOff}
                        variant="fab"
                    >
                        <Icon>filter_list_off</Icon>
                    </IconButton>
                }
            </div>
        );
    }
}

export const FilterPanel = withStyles(styles, { name: 'FilterPanel' })(FilterPanelBase);
