/**
 *
 * ReceptionDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import {
  ACTIVE_RULES,
  COMPANY_RULES,
  NEWS_RULES,
  NOT_NEWS_RULES,
} from 'domain/Data/Filters/constants';

import { columnType, columnSize, rulesColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  addActionButton,
  editActionButton,
} from '../common/actionButton';

import {
  editActionItem,
  viewActionItem,
} from '../common/actionMenu';

import { RECEPTION_RULE_DATA_TABLE } from './constants';

export class ReceptionDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    viewName: PropTypes.string,
    onActionExecuting: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  };

  getFilters() {
    const { viewName } = this.props;

    const filters = [];
    if (viewName === 'all') filters.push(COMPANY_RULES);
    if (viewName === 'active') filters.push(ACTIVE_RULES);
    if (viewName === 'allNews') filters.push(NEWS_RULES);
    if (viewName === 'allDocumentsRules') filters.push(NOT_NEWS_RULES);
    return filters;
  }

  getColumns() {
    return [
      {
        path: 'addedDate',
        name: 'addedDate',
        title: 'Создано',
        type: columnType.DATETIME,
        width: columnSize.DATE,
      },
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'validUntil',
        name: 'validUntil',
        title: 'Действует до',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'content',
        name: 'content',
        title: 'Содержание',
        type: columnType.FORMAT_TEXT,
        width: rulesColumnSizes.content
      },
      {
        path: ['file', 'name'],
        name: 'file',
        getCellValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: columnSize.FILE,
      },
    ];
  }

  getColumnOrder() {
    return ['addedDate', 'actualCreationDate', 'validUntil', 'content', 'file'];
  }

  getActionButtons() {
    return [addActionButton, editActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, editActionItem];
  }

  getSorting() {
    return [{ columnId: 'addedDate', columnName: 'addedDate', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [];
  }

  getDetailContainer() {
    return null;
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <AdvancedDataTable
        entityName="documents"
        name={RECEPTION_RULE_DATA_TABLE}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        pagingPanelEntityTitle="документы"
        detailContainer={this.getDetailContainer()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}
