/**
 *
 * SupplyRequestPositionFormDialog15767
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import {DOCUMENT_POSITION_FORM, DOCUMENT_POSITION_FORM_DIALOG} from 'pages/Positions/PositionsView/constants';


function SupplyRequestPositionFormDialog15767({form}) {
    return (
        <AdvancedFormDialog
            name={DOCUMENT_POSITION_FORM_DIALOG}
            formName={DOCUMENT_POSITION_FORM}
            advancedForm={form}
            addModeTitle="Добавление позиции"
            editModeTitle="Изменение позиции"
            viewModeTitle="Просмотр позиции"
            copyModeTitle="Копирование позиции"
            maxWidth="sm"
        />
    );
}

SupplyRequestPositionFormDialog15767.propTypes = {
    form: PropTypes.node.isRequired,
};

export default SupplyRequestPositionFormDialog15767;
