import {select, put, call} from 'redux-saga/effects';
import {changeEntitiesState, entitiesLoaded} from 'domain/Data/actions';
import {makeSelectEntities} from 'domain/Data/selectors';
import {change} from 'redux-form/immutable';

import {
    makeSelectCurrentValues,
    makeSelectFields,
    makeSelectMode,
    makeSelectNormalizationQueueFields,
    makeSelectRequestsReceived,
    makeSelectRequestsSent,
} from './selectors';

import {changeDetailTableData, clearNormalizationQueue} from './actions';
import {sortFields} from './utils';
import * as api from "../../utils/api";


export function* normalizeEntitiesQueueSaga(formName) {
    const requestsSent = yield select(makeSelectRequestsSent(formName));
    const requestsReceived = yield select(makeSelectRequestsReceived(formName));

    if (requestsReceived >= requestsSent) {
        const normalizationQueueFields = yield select(makeSelectNormalizationQueueFields(formName));
        const sortedFields = sortFields(normalizationQueueFields);

        let entityName = null;
        let data = null;
        let field = null;

        for (let i = 0; i < sortedFields.length; i += 1) {
            field = sortedFields[i].field;
            data = normalizationQueueFields[field].data;
            entityName = normalizationQueueFields[field].entityName;

            yield put(changeEntitiesState(entityName, data));
            yield put(entitiesLoaded(data, {advancedForm: formName, field}));
        }

        yield put(clearNormalizationQueue(formName));
    }
}

export function* calculateFieldsValuesSaga(formName, nameOfFieldCausedCalculation) {

    const fields = yield select(makeSelectFields(formName));
    const mode = yield select(makeSelectMode(formName));
    const entities = yield select(makeSelectEntities());

    yield* wait(200);
    const currentValues = yield select(makeSelectCurrentValues(formName));
    if (currentValues) {
        const calculatedFields = fields.filter((field) => field.calculatedValue);
        const newCalculatedValues = calculatedFields.reduce((acc, field) => {
            const newValue = field.calculatedValue(currentValues, mode, entities, nameOfFieldCausedCalculation);
            return newValue !== currentValues[field.name] ? {
                ...acc,
                [field.name]: newValue,
            } : acc;
        }, {});

        yield Object.keys(newCalculatedValues).map(function* (fieldName) {
            yield put(change(formName, fieldName, newCalculatedValues[fieldName]));
        });
    }

    if (currentValues) {
        const tableFields = fields.filter((field) => field.type === 'table');

        yield tableFields.valueSeq().toJS().map(
            function* (field) {
                const filterBy = field.filter(currentValues)
                if (filterBy) {
                    const response = yield call(api.loadPage, field.filterEntityName, {filterBy})
                    yield put(changeDetailTableData(formName, field.filterEntityName, response.data))
                }
            });
    }
}

export function* wait(time) {
    yield new Promise((resolve) => setTimeout(resolve, time));
}