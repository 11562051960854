/*
 *
 * RequestsPage14927
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import { ReceptionForm14927, ReceptionDxGrid14927, ReceptionFormDialog14927, VisitorArrivalForm14927 } from './ReceptionView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';
import { AdministratorBCDxGrid14927 } from './AdministratorBCView/AdministratorBCDxGrid14927';


function RequestsPage14927(props) {

  return (
    <RequestsPage
      receptionDxGrid={<ReceptionDxGrid14927 />}
      receptionForm={<ReceptionForm14927 />}
      receptionFormDialog={
        <ReceptionFormDialog14927
          form={<ReceptionForm14927 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      administratorBCDxGrid={<AdministratorBCDxGrid14927 />}
      administratorBCForm={<ReceptionForm14927 />}
      administratorBCFormDialog={
        <ReceptionFormDialog14927
          form={<ReceptionForm14927 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      administratorBCVisitorArrivalForm={<VisitorArrivalForm14927 />}
      visitorArrivalForm={<VisitorArrivalForm14927 />}

      {...props}
    />
  );
}

export default RequestsPage14927;