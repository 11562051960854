/**
 *
 * AgreementDialog sagas
 *
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as api from 'utils/api';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { changeEntitiesState, changeEntitiesTotalCount } from 'domain/Data/actions';

import { SUBMIT_AGREEMENT_DIALOG, LOAD_OPTIONS } from './constants';

import {
  setManagerFieldIds,
} from './actions';

import {
  makeSelectDataTable,
} from './selectors';

export default function* rootSaga() {
  yield takeLatest(SUBMIT_AGREEMENT_DIALOG, submitAgreementDialogSaga);
  yield takeLatest(LOAD_OPTIONS, loadOptionsSaga);
}

function* submitAgreementDialogSaga({ payload: { requestId, managers } }) {
  const dataTable = yield select(makeSelectDataTable());
  yield call(api.sendOnMatching, { requestId, managers });
  yield put(loadData(dataTable));
}

function* loadOptionsSaga({ payload: { entityName, options } }) {
  try {
    const response = yield call(api.loadPage, entityName, {
      includeTotalCount: true,
      ...options,
    });
    const { data, metadata } = response.data;

    yield put(changeEntitiesState(entityName, data));
    yield put(changeEntitiesTotalCount(entityName, metadata.totalCount));

    const ids = data.map((entity) => entity.id);
    yield put(setManagerFieldIds(ids));
  } catch (err) {
    console.error('Error in Requests Agreement Dialog saga');
  }
}
