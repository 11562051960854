import React from 'react';
import { TASKS, TASK_CHANGES, WORKS } from 'domain/Data/constants';

import JournalDxGrid from "pages/Journal/JournalView/JournalDxGrid";
import JournalDxGridContainer from "pages/Journal/JournalView/JournalDxGridContainer";
import WorkDxGrid from "pages/Works/WorkView/WorkDxGrid";
import WorkDxGridContainer from "pages/Works/WorkView/WorkDxGridContainer";
import DocumentPositionsDxGridContainer from 'pages/Positions/PositionsView/DocumentPositionsDxGridContainer';
import DocumentPositionsDxGrid from 'pages/Positions/PositionsView/DocumentPositionsDxGrid';
import SupplierInvoiceDxGridContainer from 'pages/SupplierInvoices/SupplierInvoicesView/SupplierInvoiceDxGridContainer';
import SupplierInvoiceDxGrid from 'pages/SupplierInvoices/SupplierInvoicesView/SupplierInvoiceDxGrid';
import AgreementListDxGridContainer from 'pages/AgreementList/AgreementListView/AgreementListDxGridContainer';
import AgreementListDxGrid from 'pages/AgreementList/AgreementListView/AgreementListDxGrid';

import { REQUESTS, REQUEST_CHANGES } from 'domain/Data/constants';
import { AGREEMENT_HISTORIES, DOCUMENTS, DOCUMENT_CHANGES, DOCUMENT_POSITIONS, PARENT_DOCUMENTS } from 'domain/Data/constants';
import TaskDxGridContainer from 'pages/TasksDetail/TaskView/TaskDxGridContainer';
import TaskDxGrid from 'pages/TasksDetail/TaskView/TaskDxGrid';
import { WORK_GRID, USER_TASK_TABLE_GRID, JOURNAL, SUPPLIER_INVOICES_GRID, AGREEMENT_LIST, } from './constants';
import { DOCUMENT_POSITION_GRID } from 'pages/Positions/PositionsView/constants';


const getWorks = (parent) => (
    <WorkDxGridContainer
        label="История"
        name={WORK_GRID}
        component={<WorkDxGrid
            label="История"
            parent={parent}
            entityName={WORKS}
            mode="detail"
        />}
    />
);


const getTasks = (parent) => (
    <TaskDxGridContainer
        label="Задачи"
        name={USER_TASK_TABLE_GRID}
        component={<TaskDxGrid
            label="Задачи"
            parent={parent}
            entityName={TASKS}
            mode="detail"
        />}
    />
);

export const getJournal = (parent, entityName) => (
    <JournalDxGridContainer
        label="Журнал"
        name={JOURNAL}
        component={<JournalDxGrid
            parent={parent}
            entityName={entityName}
            label="Журнал"
            mode="detail"
        />}
    />
);


const getDocumentPositions = () => (
    <DocumentPositionsDxGridContainer
        label="Позиции"
        name={DOCUMENT_POSITION_GRID}
        component={<DocumentPositionsDxGrid
            parent={DOCUMENTS}
            entityName={DOCUMENT_POSITIONS}
            label="Позиции"
            mode="detail"
        />}
    />
);


const getSupplierInvoice = () => (
    <SupplierInvoiceDxGridContainer
        label="Счета поставщиков"
        name={SUPPLIER_INVOICES_GRID}
        component={<SupplierInvoiceDxGrid
            parent={PARENT_DOCUMENTS}
            entityName={DOCUMENTS}
            label="Счета поставщиков"
            mode="detail"
        />}
    />
);

const getAgreementList = () => (
    <AgreementListDxGridContainer
        label="Лист согласования"
        name={AGREEMENT_LIST}
        component={<AgreementListDxGrid
            parent={DOCUMENTS}
            entityName={AGREEMENT_HISTORIES}
            label="Лист согласования"
            mode="detail"
        />}
    />
);

export const getRequestDetailContainer = () => {
    return {
        items: [
            getWorks(REQUESTS),
            getJournal(REQUESTS, REQUEST_CHANGES),
        ],
        height: 286,
    }
}

export const getTenantDetailContainer = () => {
    return {
        items: [
            getJournal(REQUESTS, REQUEST_CHANGES),
        ],
        height: 286,
    }
}

export const getTaskDetailContainer = () => {
    return {
        items: [
            getWorks(TASKS),
            getJournal(TASKS, TASK_CHANGES),
        ],
        height: 286,
    }
}

export const getSupplyRequestDetailContainer = () => {
    return {
        items: [
            getDocumentPositions(),
            getSupplierInvoice(),
            getAgreementList(),
            getJournal(DOCUMENTS, DOCUMENT_CHANGES),
        ],
        height: 286,
    };
}


export const getSightingDetailContainer = () => {
    return {
        items: [
            getAgreementList(),
            getTasks(DOCUMENTS),
        ],
        height: 286,
    };
}

