/**
 *
 * ReceptionForm14326
 *
 */
import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
  CARRYING_OUT_WORKS,
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { D_DETAIL_PARKING } from 'custom/14326/domain/Data/Parking/constants';
import { CREATED, ON_TERRITORY, REJECTED, WAIT_S3, ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { PASSPORT } from 'domain/Data/DocumentType/constants';
import * as entity from 'domain/Data/constants';
import { validateCarNumber } from 'utils/validators';
import { formField } from 'domain/typeConstants/form';
import { kyrilicKeyboardLayoutToLatin } from 'utils/common';
import { helperTextClassName } from '../common/formClassNames';
import {
  carModelTextVisible, carModelTextHelperText, carNumberVisible,
  implementationReportLabel, isGroupRequestVisible, isGroupRequestRowBreakBefore,
  khimkiAccessPremisesLabel, khimkiAccessPremisesVisible, khimkiAccessPremisesRowBreakBefore,
  khimkiEmailGuestLabel, khimkiEmailGuestVisible,
  khimkiEmailMeetingLabel,
  khimkiGuestPhoneLabel, khimkiGuestPhoneVisible, khimkiGuestPhoneRequired,
  khimkiTimeLabel, khimkiTimeVisible, khimkiTimeRowBreakBefore, khimkiTimeRowBreakAfter,
  longtermVisible, longtermRequired, nonstandardCarNumberVisible,
  parkingCalculatedValue, parkingVisibleOptions, parkingVisible, parkingDisabled,
  parkingPlaceVisible, parkingPlaceRequired,
  phoneLabel, typeCompareOptions, visitDateLabel,
  visitorFullNameLabel, visitorFullNameVisible,
  whatHappenedLabel, whatHappenedVisible, whatHappenedRequired,
  whoMeetsLabel, workTypeVisibleOptions, workTypeCompareOptions,
  whereHappenedLabel,
  khimkiTenantComment,
  managementCompanyComment,
  memorandumScan,
  assignee,
} from '../common/fields';
import { BLOCKING_OR_ADDITIONAL_CARD_LEVEL } from '../../../domain/Data/RequestType/constants';
import { calculateMaxExpirationDate, validateExpirationDate } from '../utils';
import {
  ADDITIONAL_ACCESS_LEVEL,
  EMPLOYEE_ACCESS_LEVEL,
  OFFICE_MANAGER_ACCESS_LEVEL,
} from '../../../domain/Data/AccessLevel/constants';
import { sliceText, formatText } from 'utils/formatUtils'
import { MOVEMENT_MATERIAL_VALUES_DATA, PROPERTY_BRINGING, PROPERTY_REMOVAL } from 'custom/14326/domain/Data/OnPropertyBringingRequestType/constants';
import { RECEPTION_REQUESTS_FORM } from 'pages/Requests/ReceptionView/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { COMPANY_ARCHIVE_STATUS } from 'pages/Companies/ReceptionView/constants';
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class ReceptionForm14326 extends React.PureComponent {

  getClassNames() {
    return {
      helperText: helperTextClassName,
    };
  }

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'passportPhoto',
        visible: false,
        initialValue: (values, mode) => mode === 'copy' ? null : values.passportPhoto,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        disabled: true,
      },
      {
        name: 'status',
        type: formField.SELECT,
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return values.type === ON_VISIT ? ON_TERRITORY : CREATED; }

          return values.status;
        },
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const status = entities.get('requestStatuses').valueSeq().toJS();
          if (status && (currentValues.status === REJECTED || currentValues.status === CREATED)) {
            return status.filter(item => item.id !== ON_TERRITORY).map((item) => item.id);
          }
        },
        orderBy: 'name',
      },
      {
        name: 'type',
        type: formField.SELECT,
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_CAR_ENTRY;
          }
          return values.type;
        },
        orderBy: 'order',
        clearErrorsOnChange: true,
        compareOptions: typeCompareOptions,
      },
      {
        name: 'isTrainingPass',
        type: 'checkbox',
        label: 'S3',
        disabled: true,
        visible: false,
        initialValue: (values, mode) => mode === 'copy' ? null : values.isTrainingPass,
      },
      {
        name: 'trainingStatus',
        label: 'S3 статус',
        disabled: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === ON_PASS_MAKING
          && mode !== 'add' && mode !== 'copy' && currentValues.status !== WAIT_S3,
        calculatedValue: (values) => {
          if (values.isTrainingPass === true) { return 'Успешно' }
          return 'Не успешно';
        }
      },
      {
        name: 'longterm',
        type: formField.CHECKBOX,
        label: 'Долгосрочная',
        visible: longtermVisible,
        required: longtermRequired,
        calculatedValue: (values) => {
          if (![SERVICE, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type))
            return values.longterm;
          return null;
        }
      },
      {
        name: 'visitDate',
        type: formField.DATE,
        label: visitDateLabel,
        required: true,
        visible: true,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: (values) => [ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL, SERVICE].includes(values.type),
        disabled: (currentValues, initialValues, mode, user) => currentValues.author !== user.id,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'trainingResult',
        label: 'Результат S3',
        disabled: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === ON_PASS_MAKING
          && mode !== 'add' && mode !== 'copy' && currentValues.status !== WAIT_S3,
        initialValue: (values, mode) => mode === 'copy' ? null : values.trainingResult,
      },
      {
        name: 'expirationDate',
        type: formField.DATE,
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: (values) => values.longterm && values.type !== SERVICE,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm ? values.expirationDate : null,
        validate: validateExpirationDate,
        min: (values) => (
          values.visitDate ? new Date(values.visitDate) : new Date()
        ),
        max: calculateMaxExpirationDate,
        disabled: (currentValues, initialValues, mode, user) => currentValues.author !== user.id,
      },
      {
        name: 'khimkiAccessLevel',
        label: 'Уровень доступа',
        type: formField.SELECT,
        entityName: entity.ACCESS_LEVELS,
        required: true,
        visible: (values) => values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
        rowBreakBefore: (values) => values.type === ON_PASS_MAKING || values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
        visibleOptions: (currentValues) => {
          if (currentValues.type === ON_PASS_MAKING) {
            return [EMPLOYEE_ACCESS_LEVEL, OFFICE_MANAGER_ACCESS_LEVEL, ADDITIONAL_ACCESS_LEVEL];
          }
          return currentValues.khimkiAccessLevel;
        },
        calculatedValue: (values) => {
          if ([ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type))
            return values.khimkiAccessLevel;
          return null;
        },
        orderBy: 'orderKhimki',
      },
      {
        name: 'trainingDate',
        label: 'Дата S3',
        type: formField.DATE,
        disabled: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === ON_PASS_MAKING
          && mode !== 'add' && mode !== 'copy' && currentValues.status !== WAIT_S3,
        initialValue: (values, mode) => mode === 'copy' ? null : values.trainingDate,
      },
      {
        type: formField.SELECT,
        entityName: entity.TIMES,
        name: 'khimkiTime',
        label: khimkiTimeLabel,
        visible: khimkiTimeVisible,
        required: true,
        compareOptions: (a, b) => {
          const firstItem = Number.parseInt(a.text, 10);
          const secondItem = Number.parseInt(b.text, 10);
          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        },
        rowBreakBefore: khimkiTimeRowBreakBefore,
        rowBreakAfter: khimkiTimeRowBreakAfter,
        calculatedValue: (values) => {
          if ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING].includes(values.type))
            return values.khimkiTime;
          return null;
        }
      },
      {
        name: 'startWorkingHour',
        type: formField.SELECT,
        entityName: entity.WORKING_HOURS,
        label: 'Время начала',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.startWorkingHour;
          return null;
        }
      },
      {
        name: 'endWorkingHour',
        type: formField.SELECT,
        entityName: entity.WORKING_HOURS,
        label: 'Время окончания',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.endWorkingHour;
          return null;
        }
      },
      {
        name: 'isGroupRequest',
        type: formField.CHECKBOX,
        label: 'Групповая заявка',
        visible: isGroupRequestVisible,
        rowBreakBefore: isGroupRequestRowBreakBefore,
        calculatedValue: (values) => {
          if ([CARRYING_OUT_WORKS, ON_CAR_ENTRY].includes(values.type))
            return values.isGroupRequest;
          return null;
        }
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        calculatedValue: (values) => {
          if (values.isGroupRequest && values.type !== SERVICE)
            return values.groupRequestVisitorsData;
          return null;
        }
      },
      {
        type: formField.SELECT,
        entityName: entity.WORK_TYPES,
        name: 'workType',
        label: 'Вид',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        visibleOptions: workTypeVisibleOptions,
        compareOptions: workTypeCompareOptions,
        rowBreakBefore: true,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.workType;
          return null;
        }
      },
      {
        name: 'khimkiRequestType',
        label: 'Тип заявки',
        type: formField.SELECT,
        entityName: entity.KHIMKI_REQUEST_TYPES,
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING,
        rowBreakBefore: (values) => values.type === ON_PROPERTY_BRINGING,
        calculatedValue: (values) => {
          if (values.type === ON_PROPERTY_BRINGING)
            return values.khimkiRequestType;
          return null;
        },
      },
      {
        name: 'khimkiAccessPremises',
        label: khimkiAccessPremisesLabel,
        visible: khimkiAccessPremisesVisible,
        rowBreakBefore: khimkiAccessPremisesRowBreakBefore,
        calculatedValue: (values) => {
          if (values.type === BLOCKING_OR_ADDITIONAL_CARD_LEVEL
            || (values.type === ON_PROPERTY_BRINGING
              && (values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA
                || values.khimkiRequestType === PROPERTY_BRINGING)))
            return values.khimkiAccessPremises;
          return null;
        }
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: formField.DADATA,
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        visible: visitorFullNameVisible,
        rowBreakBefore: true,
        calculatedValue: (values) => {
          if (!values.isGroupRequest && values.type !== SERVICE && values.type !== CARRYING_OUT_WORKS)
            return values.visitorFullName;
          return null;
        }
      },
      {
        name: 'khimkiEmailGuest',
        label: khimkiEmailGuestLabel,
        visible: khimkiEmailGuestVisible,
        helperText: (values) => {
          if (values.type === ON_CAR_ENTRY) {
            return 'Указать для направления уведомления посетителю';
          } else if (values.type === ON_PASS_MAKING) {
            return 'Указать для направления уведомления получателю';
          }
          return null;
        },
        calculatedValue: (values) => {
          if ([ON_VISIT, ON_CAR_ENTRY, ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL].includes(values.type))
            return values.khimkiEmailGuest;
          return null;
        },
        required: (values) => values.type === ON_PASS_MAKING,
      },
      {
        name: 'responsibleFromContractor',
        label: 'Ответственный от подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.responsibleFromContractor;
          return null;
        }
      },
      {
        name: 'responsibleFromContractorPhone',
        label: 'Телефон отв. подрядчика',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: true,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.responsibleFromContractorPhone;
          return null;
        }
      },
      {
        name: 'khimkiResponsibleFromTenant',
        label: 'Ответственный от арендатора',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: false,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.khimkiResponsibleFromTenant;
          return null;
        }
      },
      {
        name: 'khimkiGuestPhone',
        label: khimkiGuestPhoneLabel,
        visible: khimkiGuestPhoneVisible,
        required: khimkiGuestPhoneRequired,
        calculatedValue: (values) => {
          if ([ON_PASS_MAKING, BLOCKING_OR_ADDITIONAL_CARD_LEVEL,
            ON_CAR_ENTRY, CARRYING_OUT_WORKS].includes(values.type))
            return values.khimkiGuestPhone;
          return null;
        }
      },
      {
        name: 'isFired',
        type: 'checkbox',
        label: 'Уволен',
        visible: (values) => values.type === ON_PASS_MAKING,
      },
      {
        name: 'whoMeets',
        label: whoMeetsLabel,
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
        rowBreakBefore: true,
        required: true,
        initialValue: (values, mode, user, entities) => {
          if (values.type === ON_CAR_ENTRY) {
            const author = entities.get('employees').get(values.author);
            return author ? author.name : null;
          }
          return null;
        },
        calculatedValue: (values) => {
          if ([ON_VISIT, ON_CAR_ENTRY].includes(values.type))
            return values.whoMeets;
          return null;
        }
      },
      {
        name: 'khimkiEmailMeeting',
        label: khimkiEmailMeetingLabel,
        visible: (values) => [ON_VISIT, ON_CAR_ENTRY].includes(values.type),
        initialValue: (values, mode, user, entities) => {
          const author = entities.get('employees').get(values.author);
          return author ? author.email : null;
        },
        calculatedValue: (values) => {
          if ([ON_VISIT, ON_CAR_ENTRY].includes(values.type))
            return values.khimkiEmailMeeting;
          return null;
        }
      },
      {
        name: 'phone',
        label: phoneLabel,
        visible: (values) => values.type === ON_CAR_ENTRY,
        initialValue: (values, mode, user, entities) => {
          const author = entities.get('employees').get(values.author);
          return author ? author.phone : null;
        },
        calculatedValue: (values) => {
          if ([ON_CAR_ENTRY].includes(values.type))
            return values.phone;
          return null;
        }
      },
      {
        name: 'company',
        type: formField.AUTOCOMPLETE,
        label: 'Арендатор',
        entityName: 'companies',
        filterBy: `!status.name.contains("${COMPANY_ARCHIVE_STATUS}") or (status = null)`,
        required: true,
        orderBy: 'name',
        numberInQueueFromEnd: 1,
        initialValue: (values, mode, user) => user.companyId,
        rowBreakBefore: true,
        rowBreakAfter: (values) => values.type === ON_VISIT ||
          values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING ||
          values.type === SERVICE,
      },
      {
        name: 'whereHappened',
        label: whereHappenedLabel,
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
        calculatedValue: (values) => {
          if (values.type === SERVICE)
            return values.whereHappened;
          if (values.type === CARRYING_OUT_WORKS)
            return values.workPlace;
          return null;
        }
      },
      {
        name: 'documentType',
        type: formField.SELECT,
        label: 'Вид документа',
        initialValue: PASSPORT,
        entityName: 'documentTypes',
        visible: (values) => values.type !== ON_CAR_ENTRY && values.type !== ON_PROPERTY_BRINGING &&
          values.type !== SERVICE && values.type !== BLOCKING_OR_ADDITIONAL_CARD_LEVEL
          && values.type !== ON_PASS_MAKING && values.type !== CARRYING_OUT_WORKS && !values.isGroupRequest,
        calculatedValue: (values) => values.isGroupRequest ? PASSPORT : values.documentType,
        rowBreakBefore: true,
      },
      {
        name: 'documentDetails',
        label: 'Серия, № документа',
        visible: (values) => !values.isGroupRequest && values.type !== ON_CAR_ENTRY &&
          values.type !== SERVICE && values.type !== ON_PROPERTY_BRINGING && values.type !== BLOCKING_OR_ADDITIONAL_CARD_LEVEL && values.type !== CARRYING_OUT_WORKS
          && values.type !== ON_PASS_MAKING,
        calculatedValue: (values) => {
          if (!values.isGroupRequest && values.type !== ON_CAR_ENTRY &&
            values.type !== SERVICE && values.type !== ON_PROPERTY_BRINGING && values.type !== BLOCKING_OR_ADDITIONAL_CARD_LEVEL && values.type !== CARRYING_OUT_WORKS
            && values.type !== ON_PASS_MAKING)
            return values.documentDetails;
          return null;
        }
      },
      {
        name: 'accessCardKey',
        label: 'Ключ карты доступа',
        initialValue: () => null,
        normalize: (value) => value ? kyrilicKeyboardLayoutToLatin(value) : value,
        visible: (values) => values.type === ON_CAR_ENTRY,
        calculatedValue: (values) => {
          if (values.type === ON_CAR_ENTRY)
            return values.accessCardKey;
          return null;
        }
      },
      {
        name: 'accessCard',
        initialValue: () => null,
        visible: false,
      },
      {
        name: 'nonstandardCarNumber',
        type: formField.CHECKBOX,
        label: 'Нестандартный № авто',
        rowBreakBefore: true,
        rowBreakAfter: true,
        visible: nonstandardCarNumberVisible,
        calculatedValue: (values) => {
          if (values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)
            return null;
          return values.nonstandardCarNumber;
        },

      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        visible: carNumberVisible,
        normalize: (value) => value ? value.split(' ').join('') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
        helperText: () => 'Введите номер автомобиля по формату x111xx11 используя кириллицу или поднимите флаг Нестандартный № авто',
        disabled: (currentValues, initialValues, mode, user) => currentValues.author !== user.id,
        calculatedValue: (values) => {
          if ((values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)
            || values.isGroupRequest) return null;
          return values.carNumber;
        },
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: carModelTextVisible,
        helperText: carModelTextHelperText,
        disabled: (currentValues, initialValues, mode, user) => currentValues.author !== user.id,
        calculatedValue: (values) => {
          if ((values.type === ON_PROPERTY_BRINGING && values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA)
            || values.isGroupRequest) return null;
          return values.carModelText;
        }
      },
      {
        name: 'parking',
        type: formField.SELECT,
        label: 'Парковка',
        entityName: 'parkings',
        visible: parkingVisible,
        visibleOptions: parkingVisibleOptions,
        calculatedValue: parkingCalculatedValue,
        rowBreakBefore: true,
        // helperText: parkingHelperText,
        disabled: parkingDisabled,
        required: (values) => !!values.carNumber,
      },
      {
        name: 'parkingPlace',
        label: '№ места',
        visible: parkingPlaceVisible,
        required: parkingPlaceRequired,
        initialValue: (values, mode) => {
          if (mode === 'copy' && values.parking === D_DETAIL_PARKING) {
            return null;
          }
          return values.parkingPlace;
        },
        calculatedValue: (values) => {
          if (values.type === ON_PROPERTY_BRINGING
            && (values.khimkiRequestType === PROPERTY_REMOVAL
              || values.khimkiRequestType === MOVEMENT_MATERIAL_VALUES_DATA))
            return null;
          return values.parkingPlace;
        }
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
        disabled: (currentValues, initialValues, mode, user) => currentValues.author !== user.id,
        normalize: (value) => value ? formatText(value, { limit: 2000 }) : value,
        calculatedValue: (values) => {
          if ([ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type))
            return values.materialValuesData;
          return null;
        }
      },
      {
        name: 'whatHappened',
        label: whatHappenedLabel,
        multiline: true,
        visible: whatHappenedVisible,
        grid: { sm: 12 },
        required: whatHappenedRequired,
        normalize: (value) => value ? sliceText(value, 8000) : value,
        calculatedValue: (values) => {
          if ([SERVICE, CARRYING_OUT_WORKS].includes(values.type))
            return values.whatHappened;
          return null;
        }
      },
      assignee,
      {
        name: 'executionDate',
        type: formField.DATE,
        label: 'Дата выполнения факт',
        visible: (values) => values.type === SERVICE,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode !== 'copy' && values.type === SERVICE) return values.executionDate;
          return null;
        },
      },
      {
        name: 'executionTerm',
        type: formField.DATE,
        label: 'Срок исполнения',
        visible: (values) => values.type === SERVICE,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode !== 'copy' && values.type === SERVICE) return values.executionTerm;
          return null;
        },
      },
      khimkiTenantComment,
      {
        name: 'implementationReport',
        label: implementationReportLabel,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy' || values.type !== SERVICE) return null;
          return values.implementationReport;
        },
      },
      {
        name: 'attachments',
        label: 'Файл',
        type: formField.MULTIPLE_FILE,
        visible: (values) => [SERVICE, ON_PASS_MAKING, CARRYING_OUT_WORKS].includes(values.type),
        rowBreakBefore: true,
        helperText: () => 'Прикрепите один или несколько файлов',
        calculatedValue: (values) => {
          if ([SERVICE, ON_PASS_MAKING, CARRYING_OUT_WORKS].includes(values.type))
            return values.attachments;
          return null;
        },
        fileSize: {
          size: 10e6,
          errorTextFile: 'Размер прикрепляемых файлов не должен превышать 10 Мб'
        },
      },
      {
        name: 'workPlace',
        label: 'Место проведения',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        required: (values) => values.type === CARRYING_OUT_WORKS,
        multiline: true,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.workPlace;
          return null;
        }
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: true,
        multiline: true,
        normalize: (value) => value ? formatText(value, { limit: 2000 }) : value,
      },
      managementCompanyComment,
      {
        type: formField.CHECKBOX,
        name: 'isTechnicalServiceMonitoring',
        label: 'Контроль со стороны технической службы',
        visible: (values) => values.type === CARRYING_OUT_WORKS,
        rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
        calculatedValue: (values) => {
          if (values.type === CARRYING_OUT_WORKS)
            return values.isTechnicalServiceMonitoring;
          return null;
        }
      },
      {
        name: 'agreedEmployee',
        type: formField.SELECT,
        label: 'Согласовал',
        visible: (currentValues, initialValues, mode) => mode === 'edit' || mode === 'view',
        entityName: 'employees',
        disabled: true,
      },
      {
        name: 'isAllowProcessPersonalData',
        type: 'checkbox',
        label: 'Cогласие на обработку персональных данных',
        visible: true,
        disabled: true,
      },
      memorandumScan,
    ];
  }

  render() {
    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    return (
      <AdvancedForm
        name={RECEPTION_REQUESTS_FORM}
        entityName="requests"
        classNames={this.getClassNames()}
        fields={this.getFields()}
        accountId={accountId}
        useAsyncValidate
        {...this.props}
      />
    );
  }
}

export default ReceptionForm14326