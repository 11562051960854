/**
 *
 * ReceptionForm14366
 *
 */
import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { ACCEPTED, CREATED } from 'domain/Data/RequestStatus/constants';
import {
  ON_CAR_ENTRY, ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { ON_TERRITORY, REJECTED } from 'domain/Data/RequestStatus/constants';
import { PASSPORT } from 'domain/Data/DocumentType/constants';
import { validateCarNumber } from 'utils/validators';
import { RECEPTION_REQUESTS_FORM } from 'pages/Requests/ReceptionView/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { kyrilicKeyboardLayoutToLatin } from 'utils/common';
import { BULVAR_TSVETNOY, UK_TSVETNOY, MELIORA_PLACE } from '../../../domain/Data/Departments/constants';
import { REQUESTS } from 'domain/Data/constants';
import { role as roleType } from 'domain/typeConstants/roles';
import { assignee, companyByStatus } from '../common/fields';
import { groupRequestVisitorsDataHelperText, visitorFullNameLabel } from 'pages/Requests/common/fields';

export class ReceptionForm14366 extends React.PureComponent {

  getFields() {
    const currentDate = new Date();
    const departmentId = getValueFromLocalStorage(GLOBAL_USER_KEY).departmentId
    const currentRoles = getValueFromLocalStorage(GLOBAL_USER_KEY).roles;
    const checkRoleReception = currentRoles.find((role) => (role === roleType.RECEPTION));
    const isDepartmentReception = () => {
      return checkRoleReception && [UK_TSVETNOY, MELIORA_PLACE].includes(departmentId) ? true : false;
    }

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'arrivalDate',
        type: 'date',
        visible: false,
        initialValue: null,
        calculatedValue: (values, mode) => mode === 'add' && values.type === ON_VISIT ? currentDate : null
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        disabled: true,
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'edit',
        calculatedValue: (values, mode) => {
          if (new Set([SERVICE, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL]).has(values.type)
            && values.department === BULVAR_TSVETNOY) {
            return CREATED;
          }
          if (values.type === ON_CAR_ENTRY) { return CREATED; }
          if (mode === 'add' || mode === 'copy') { return ACCEPTED; }

          return values.status;
        },
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const status = entities.get('requestStatuses').valueSeq().toJS();
          if (status && (currentValues.status === REJECTED || currentValues.status === CREATED)) {
            return status.filter(item => item.id !== ON_TERRITORY).map((item) => item.id);
          }
        },
        orderBy: 'name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_VISIT;
          }
          return values.type;
        },
        orderBy: 'order',
        clearErrorsOnChange: true,
      },
      {
        name: 'longterm',
        type: 'checkbox',
        label: 'Долгосрочная',
        visible: (values) => values.type !== SERVICE,
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        required: true,
        visible: (currentValues, initialValues, mode) => mode === 'view' || currentValues.type !== SERVICE,
        initialValue: currentDate,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
        disabled: (currentValues) => currentValues.type === SERVICE,
        calculatedValue: (values) => {
          const visitDate = new Date(values.visitDate);
          return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
          )
        },
      },
      {
        name: 'expirationDate',
        type: 'date',
        label: 'Дата окончания',
        required: (values) => values.longterm,
        visible: (values) => values.longterm && values.type !== SERVICE,
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return new Date(values.expirationDate) > currentDate ?
              new Date(values.expirationDate) : currentDate;
          }
          return null;
        },
        calculatedValue: (values) => values.longterm ? values.expirationDate : null,
        min: (values) => new Date(values.visitDate) < currentDate ?
          new Date(values.visitDate) : currentDate,
      },
      {
        name: 'isGroupRequest',
        type: 'checkbox',
        label: 'Групповая заявка',
        rowBreakBefore: (values) => values.type !== SERVICE,
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
      },
      {
        name: 'nonstandardCarNumber',
        type: 'checkbox',
        label: 'Нестандартный № авто',
        rowBreakBefore: false,
        rowBreakAfter: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL) && !values.isGroupRequest,
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        rowBreakBefore: (values) => values.isGroupRequest && values.type !== SERVICE,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        visible: (values) => !values.isGroupRequest && values.type !== SERVICE,
        required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
        rowBreakBefore: (values) => !values.isGroupRequest && values.type !== SERVICE,
      },
      companyByStatus,
      {
        name: 'documentType',
        type: 'select',
        label: 'Вид документа',
        initialValue: PASSPORT,
        entityName: 'documentTypes',
        visible: (values) => values.type !== ON_CAR_ENTRY &&
          values.type !== SERVICE && !values.isGroupRequest,
        calculatedValue: (values) => values.isGroupRequest ? PASSPORT : values.documentType,
        rowBreakBefore: true,
      },
      {
        name: 'documentDetails',
        label: 'Серия, № документа',
        visible: (values) => !values.isGroupRequest && values.type !== ON_CAR_ENTRY &&
          values.type !== SERVICE,
      },
      {
        name: 'passNumber',
        label: '№ пропуска',
        visible: (values) => values.type === ON_VISIT ||
          values.type === ON_PASS_MAKING,
        initialValue: () => null,
      },
      {
        name: 'accessCardKey',
        label: 'Ключ карты доступа',
        initialValue: () => null,
        normalize: (value) => value ? kyrilicKeyboardLayoutToLatin(value) : value,
        visible: (values) => values.type !== SERVICE && values.type !== ON_PASS_MAKING,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        required: (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest,
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL) &&
          !values.isGroupRequest,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL) &&
          !values.isGroupRequest,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
        rowBreakBefore: true,
      },
      {
        name: 'parkingPlace',
        label: '№ места',
        visible: (values) => (values.type !== ON_VISIT && values.type !== SERVICE
          && values.type !== ON_PASS_MAKING),
        initialValue: () => null,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: (values) => values.type !== ON_VISIT,
      },
      {
        type: 'select',
        entityName: 'departments',
        name: 'department',
        label: 'Здание',
        visible: true,
        required: true,
        disabled: isDepartmentReception(),
        initialValue: (values, mode) => mode === 'add' && isDepartmentReception() ? departmentId : null,
        calculatedValue: (values, mode, entities, nameOfFieldCausedCalculation) => {
          if (entities && (!values.department && nameOfFieldCausedCalculation === 'company')) {
            const company = entities.get('companies').get(values.company);
            return company && company.department ? company.department : values.department;
          }
          return values.department;
        },
        visibleOptions: () => {
          return ['3959751378000', '4006045944000','4443829413000']
        },
        orderBy: 'name',
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: (values) => (values.type === ON_CAR_ENTRY || values.type === ON_VISIT ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL)
      },
      {
        name: 'note',
        label: 'Примечание',
        grid: { sm: 12 },
        visible: (values) => values.type !== SERVICE,
      },
      {
        name: 'service',
        type: 'select',
        label: 'Сервис',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
      },
      assignee,
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        required: true,
        visible: false,
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        required: true,
        visible: false,
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        visible: (values) => values.type === SERVICE,
        required: true,
        rowBreakAfter: true,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 6 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
        required: (currentValues) => currentValues.status === REJECTED,
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: (values) => values.type === SERVICE ||
          values.type === ON_PASS_MAKING,
        rowBreakBefore: true,
      },
      {
        name: 'payableService',
        label: 'Платная услуга',
        type: 'checkbox',
        required: true,
        visible: (values) => values.type === ON_PASS_MAKING && checkRoleReception && departmentId === MELIORA_PLACE
      },
    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    return (
      <AdvancedForm
        name={RECEPTION_REQUESTS_FORM}
        entityName={REQUESTS}
        classNames={this.getClassNames()}
        fields={this.getFields()}
        useAsyncValidate
        accountId={accountId}
        {...this.props}
      />
    );
  }
}

export default ReceptionForm14366