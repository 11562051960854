/*
 *
 * ReceptionDxGridContainer11994
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectRoles } from 'domain/User/selectors';
import ReceptionDxGrid11994 from './ReceptionDxGrid11994';

class ReceptionDxGridContainer11994 extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <ReceptionDxGrid11994 {...this.props} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
});

export default connect(mapStateToProps)(ReceptionDxGridContainer11994);
