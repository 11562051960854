/*
 *
 * AdministratorBCRequestsFormDialogContainer14691
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RECEPTION_REQUESTS_FORM_DIALOG } from 'pages/Requests/ReceptionView/constants';
import { makeSelectMode } from 'containers/AdvancedFormDialog/selectors/';
import { executeRejectAction, executeAcceptAction } from './actions';
import AdministratorBCRequestsFormDialog14691 from './AdministratorBCRequestsFormDialog14691';

class AdministratorBCRequestsFormDialogContainer14691 extends React.Component {
  render() {
    return (
      <div>
        <AdministratorBCRequestsFormDialog14691
          onAccept={this.props.executeAcceptAction}
          onReject={this.props.executeRejectAction}
          {...this.props}
        />
      </div>
    );
  }
}

AdministratorBCRequestsFormDialogContainer14691.propTypes = {
  executeAcceptAction: PropTypes.func.isRequired,
  executeRejectAction: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  mode: makeSelectMode(RECEPTION_REQUESTS_FORM_DIALOG),
});

function mapDispatchToProps(dispatch) {
  return {
    executeAcceptAction: () => dispatch(executeAcceptAction(RECEPTION_REQUESTS_FORM_DIALOG)),
    executeRejectAction: () => dispatch(executeRejectAction(RECEPTION_REQUESTS_FORM_DIALOG)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorBCRequestsFormDialogContainer14691);
