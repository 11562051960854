/**
 *
 * DocumentMatchingFormHeaderContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import * as actionCreators from './actions';
import reducer from './reducer';
import saga from './sagas';
import {
  makeSelectVisitorFullName,
  makeSelectDocumentDetails,
  makeSelectRequestNumber,
  makeSelectVisitDate,
} from './selectors';
import DocumentMatchingFormHeader from './DocumentMatchingFormHeader';

class DocumentMatchingFormHeaderContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };
  static propTypes = {
    copyDocumentDetails: PropTypes.func,
    findDocumentDetails: PropTypes.func,
    visitorFullName: PropTypes.string,
    documentDetails: PropTypes.string,
    requestNumber: PropTypes.string,
    visitDate: PropTypes.string,
  };

  componentDidMount() {
    this.props.findDocumentDetails(this.context.advancedForm);
  }

  handleCopyButtonClick = () => {
    this.props.copyDocumentDetails(this.context.advancedForm, this.props.documentDetails);
  }

  render() {
    const {
      visitorFullName,
      documentDetails,
      requestNumber,
      visitDate,
      ...restProps
    } = this.props;

    return (
      <DocumentMatchingFormHeader
        visitorFullName={visitorFullName}
        documentDetails={documentDetails}
        requestNumber={requestNumber}
        visitDate={visitDate}
        onCopyButtonClick={this.handleCopyButtonClick}
        {...restProps}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  visitorFullName: makeSelectVisitorFullName(),
  documentDetails: makeSelectDocumentDetails(),
  requestNumber: makeSelectRequestNumber(),
  visitDate: makeSelectVisitDate(),
});

function mapDispatchToProps(dispatch) {
  return {
    copyDocumentDetails: (advancedForm, documentDetails) => dispatch(actionCreators.copyDocumentDetails(advancedForm, documentDetails)),
    findDocumentDetails: (advancedForm) => dispatch(actionCreators.findDocumentDetails(advancedForm)),
  };
}

const withReducer = injectReducer({ key: 'DocumentMatchingFormHeader', reducer });
const withSaga = injectSaga({ key: 'DocumentMatchingFormHeader', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(DocumentMatchingFormHeaderContainer);
