/**
 *
 * AdvancedMobileGrid.row.detail-button component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { RIGHT_BLOCK_WIDTH } from './constants';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    minWidth: RIGHT_BLOCK_WIDTH,
    width: RIGHT_BLOCK_WIDTH,
  },
  icon: {
    color: '#757575',
    cursor: 'pointer',
    height: 28,
    margin: 5,
    width: 28,
  },
});

const DetailButtonBase = ({
  classes, record, onClick,
}) => (
  <div className={classes.root}>
    <MoreHoriz
      className={classes.icon}
      onClick={onClick(record)}
    />
  </div>
);

DetailButtonBase.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

DetailButtonBase.defaultProps = {
  isExpanded: false,
};

export const DetailButton = withStyles(styles, { name: 'DetailButton' })(DetailButtonBase);
