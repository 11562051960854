/**
 *
 * SupplyRequestDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnSize, documentColumnSizes } from 'domain/typeConstants/table';
import { ALL_SUPPLY_REQUESTS, ACTIVE_SUPPLY_REQUESTS } from 'domain/Data/Filters/constants';
import { DOCUMENTS } from 'domain/Data/constants';
import { columnStatusLabels } from '../common/columnStatusLabel';
import { SUPPLY_REQUEST_DX_GRID } from './constants';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  agreeActionButton,
  disagreeActionButton,
  viewActionButton,
  addActionButton,
  editActionButton,
} from '../common/actionButton';

import {
  agreeActionItem,
  disagreeActionItem,
  viewActionItem,
  editActionItem,
} from '../common/actionMenu';
import { getSupplyRequestDetailContainer } from 'pages/common/detailContainers';


class SupplyRequestDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  /* -----------------------------------------------------------
                  Documents configuration
  ----------------------------------------------------------- */
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'allSupplyRequests') filters.push(ALL_SUPPLY_REQUESTS);
    if (viewName === 'activeSupplyRequests') {
      filters.push(ALL_SUPPLY_REQUESTS)
      filters.push(ACTIVE_SUPPLY_REQUESTS)
    };
    return filters;
  }

  getColumns() {
    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        width: documentColumnSizes.documentNumber,
      },
      {
        name: 'actualCreationDate',
        path: 'actualCreationDate',
        title: 'Дата',
        type: columnType.DATE,
        width: columnSize.DATETIME,
      },
      {
        name: 'state',
        path: 'state.name',
        title: 'Состояние',
        getCellValue: (row) => row.state ? row.state.name : undefined,
        labels: columnStatusLabels,
        type: columnType.LABEL,
        entityName: 'documentStates',
        width: documentColumnSizes.stateName,
      },
      {
        name: 'objectStructure',
        path: 'objectStructure.name',
        title: 'Объект',
        getCellValue: (row) => row.objectStructure ? row.objectStructure.name : undefined,
        width: documentColumnSizes.companyName,
      },
      {

        name: 'responsible',
        path: 'responsible.name',
        title: 'Ответственный',
        getCellValue: (row) => row.responsible ? row.responsible.name : undefined,
        width: documentColumnSizes.author,
      },
      {
        name: 'content',
        path: 'content',
        title: 'Содержание',
        getCellValue: (row) => row.content ? row.content : undefined,
        width: documentColumnSizes.content,
      },
      {
        name: 'file',
        path: ['file','id'],
        getCellValue: (row) => row.scan ? row.scan.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: columnSize.FILE,
      },
      {
        name: 'note',
        path: 'note',
        title: 'Примечание',
        getCellValue: (row) => row.note ? row.note : undefined,
        width: documentColumnSizes.comment,
      },
      {
        name: 'author',
        path: 'author.name',
        title: 'Автор',
        getCellValue: (row) => row.author ? row.author.name : undefined,
        width: documentColumnSizes.author,
      },
      {
        name: 'lastChangedDate',
        path: 'lastChangedDate',
        title: 'Изменен',
        type: columnType.DATETIME,
        width: columnSize.DATETIME,
      },
    ];
  }

  getColumnOrder() {
    return [
      'number', 'actualCreationDate', 'lastChangedDate', 'state', 'objectStructure', 'responsible',
      'content', 'file', 'note', 'author', 'sightingEmployeesText',
    ];
  }

  getActionButtons() {
    return [
      addActionButton, editActionButton, viewActionButton, agreeActionButton, disagreeActionButton,
    ];
  }

  getActionMenuItems() {
    return [viewActionItem, editActionItem, agreeActionItem, disagreeActionItem];
  }

  getSorting() {
    return [{ columnId: 'lastChangedDate', columnName: 'lastChangedDate', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: true },
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;
    return (
      <AdvancedDataTable
        name={SUPPLY_REQUEST_DX_GRID}
        entityName={DOCUMENTS}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        detailContainer={getSupplyRequestDetailContainer()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

SupplyRequestDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default SupplyRequestDxGrid;
