/*
 *
 * AdvancedDataTable sagas
 *
 */

import { call, put, select, takeLatest, fork, all } from 'redux-saga/effects';
import queryString from 'query-string';
import { makeSelectLocationState } from 'domain/selectors';
import { makeSelectAccountId } from 'domain/User/selectors';
import { changeEntitiesState, changeEntitiesTotalCount } from 'domain/Data/actions';
import { setValueToLocalStorage, getValueFromLocalStorage } from 'utils/localStorage';
import SelectTextFilterSaga from './FilterListDialog/SelectTextFilter/sagas'
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import * as api from 'utils/api';

import {
  CLEAR_SEARCH_QUERY,
  FALLBACK_VIEW_NAME,
  LOAD_DATA,
  MAIN_TABLE_KEY,
  SETTINGS_CONFIG_NAME,
  CHANGE_GRID_FILTERS,
  LOAD_MODE,
  GET_DOWNLOAD_LINK,
  CLEAR_COLLAPSE_ROWS_DATA,
} from './constants';

import {
  changeLoaderState,
  changeSearchQuery,
  changeLazyLoadingState,
  clearFilterDialog,
  clearSelection,
  dataLoaded,
  dataLoadingError,
  loadData,
  saveFileExtension,
  setDownloadLink,
  clearIds,
} from './actions';

import {
  makeSelectEntityName,
  makeSelectPageSize,
  makeSelectPageNumber,
  makeSelectSorting,
  makeSelectFilters,
  makeSelectGridFilters,
  makeSelectSearchQuery,
  makeSelectColumns,
  makeSelectEntitiesIds,
  makeSelectDetailContainerNames,
} from './selectors';
import { makeSelectEntityData } from 'domain/Data/selectors';

export default function* rootSaga() {
  yield takeLatest(LOAD_DATA, loadAdvancedDataTableDataSaga);
  yield takeLatest(CLEAR_SEARCH_QUERY, clearSearchQuerySaga);
  yield takeLatest(CHANGE_GRID_FILTERS, changeGridFiltersSaga);
  yield takeLatest(GET_DOWNLOAD_LINK, getDownloadLinkSaga);
  yield takeLatest(CLEAR_COLLAPSE_ROWS_DATA, clearCollapseRowsDataSaga);
  yield fork(SelectTextFilterSaga);
}

export function* loadAdvancedDataTableDataSaga({ meta: { dataTable }, payload }) {
  yield put(clearSelection(dataTable));
  const entityName = payload.entityName || (yield select(makeSelectEntityName(dataTable)));
  const { limit, startAt, pageNumber, pageSize } = payload

  let response;
  let data;
  let metadata;

  try {
    if (['taskChanges', 'requestChanges', 'documentChanges'].includes(entityName)) {
      if (limit && startAt) {
        // первая загрузка данных
        yield put(changeLoaderState(dataTable, { loaderState: true }));
        response = yield call(api.loadChanges, entityName, payload.parentId)
        data = response.data.map(r => {
          return {
            ...r,
            name: r.name.replaceAll('</p>', '')
              .replaceAll('<p>', '')
              .replaceAll('\\n', '')
          }
        })
        metadata = { totalCount: data.length }

        yield put(changeEntitiesState(entityName, data));
        yield put(changeEntitiesTotalCount(entityName, metadata.totalCount));

        const ids = data.map((entity) => entity.id).slice(startAt - 1, startAt + limit[0] - 1)

        yield put(dataLoaded(dataTable, ids));
        yield put(changeLoaderState(dataTable, { loaderState: false }));
      }
      else if (pageNumber && pageSize) {
        // навигация по журналу
        const entityData = yield select(makeSelectEntityData(entityName));
        const data = Object.values(entityData)

        const startAt = pageSize * (pageNumber - 1)
        const ids = data.map((entity) => entity.id).slice(startAt, startAt + pageSize)

        yield put(dataLoaded(dataTable, ids));
      }
    }
    else {
      const ACCESS_KEY = yield getAccessKey(dataTable, payload);
      let settingsConfig = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
      settingsConfig = settingsConfig && settingsConfig[ACCESS_KEY];

      const sorting = payload.sorting || (yield select(makeSelectSorting(dataTable)));
      const filters = payload.filters || (yield select(makeSelectFilters(dataTable)));
      const gridFilters = yield select(makeSelectGridFilters(dataTable));
      const searchQuery = payload.searchQuery || (yield select(makeSelectSearchQuery(dataTable)));
      const columns = yield select(makeSelectColumns(dataTable));

      let pageSize;
      let pageNumber;
      const startAt = payload.startAt || 1;
      let limit = 10;

      if (settingsConfig) {
        if (settingsConfig.tableType === settings.PAGING_TABLE) {
          pageSize = payload.pageSize || (yield select(makeSelectPageSize(dataTable)));
          pageNumber = payload.pageNumber || (yield select(makeSelectPageNumber(dataTable)));
        } else limit = settingsConfig[settings.LAZY_LOADING_BLOCK_SIZE].value;
      }
      const loadPageParams = {
        filters,
        includeTotalCount: true,
        limit,
        pageNumber,
        pageSize,
        searchQuery,
        sorting,
        startAt,
      };

      let filterBy = makeFilterByParameter(columns, gridFilters);
      const { mode, parent, parentId } = payload
      if (parent && parentId) {
        const filter = `${entityReplacer(parent) || 'request'}.id="${parentId}"`
        filterBy = filterBy ? filterBy + ` && ${filter}` : filter
      }
      loadPageParams.filterBy = filterBy

      yield put(changeLoaderState(dataTable, { loaderState: true }));
      response = yield call(api.loadPage, entityName, loadPageParams);
      data = response.data.data
      metadata = response.data.metadata

      if (data.length < limit) yield put(changeLazyLoadingState(dataTable, true));
      yield put(changeEntitiesState(entityName, data));
      yield put(changeEntitiesTotalCount(entityName, metadata.totalCount));
      if (payload.mode == LOAD_MODE.PARENT) yield put(changeEntitiesTotalCount('breadcrumb', metadata.totalCount));
      if (data.length === limit) yield put(changeLazyLoadingState(dataTable, false));
      let uniqueIds;
      if (settingsConfig.tableType === settings.VIRTUAL_TABLE && loadPageParams.startAt > 1) {
        const previousIds = yield select(makeSelectEntitiesIds(dataTable));
        const currentIds = data.map((entity) => entity.id);
        const ids = [...previousIds, ...currentIds];
        uniqueIds = [...new Set(ids)];
      } else uniqueIds = data.map((entity) => entity.id);

      yield put(dataLoaded(dataTable, uniqueIds));
      yield put(changeLoaderState(dataTable, { loaderState: false }));
    }
  }
  catch (err) {
    yield put(dataLoadingError(dataTable, err));
  }
}

export function* clearCollapseRowsDataSaga({ meta: { dataTable } }) {
  const detailContainerNames = yield select(makeSelectDetailContainerNames(dataTable));
  yield all(detailContainerNames.map(x => put(clearIds(x))));
}

export function* clearSearchQuerySaga({ meta: { dataTable }, payload }) {
  yield put(loadData(dataTable, payload));
}

export function* changeGridFiltersSaga({ meta: { dataTable }, payload }) {
  const gridFilters = payload.gridFilters
  const isFilledGridFilters = gridFilters.some((f) => f.value.length > 0)
  if (!isFilledGridFilters) {
    const ACCESS_KEY = yield getAccessKey(dataTable, payload);
    let localStorageAdvancedDataTables = getValueFromLocalStorage(SETTINGS_CONFIG_NAME);
    let customFilteringConfig = localStorageAdvancedDataTables[ACCESS_KEY][settings.CUSTOM_FILTERING].value;

    customFilteringConfig && customFilteringConfig.map(item => {
      item.status = false;
      item.outputValue = "";
      delete item.fromValue;
      delete item.toValue;
      return;
    })
    setValueToLocalStorage(SETTINGS_CONFIG_NAME, localStorageAdvancedDataTables);
    yield put(changeSearchQuery(dataTable, null))
    yield put(clearFilterDialog(dataTable, true))
  }
  yield put(loadData(dataTable, payload));
}

export function* getDownloadLinkSaga({ meta: { dataTable }, payload: { fileId } }) {
  const response = yield call(api.createDownloadTicketForPreview, fileId);
  const link = api.getDownloadLink(response.data.ticketId);
  let extension = "";
  if (response.data.extension.length !== 0) { extension = response.data.extension.slice(1) }
  yield put(saveFileExtension(dataTable, extension));
  yield put(setDownloadLink(dataTable, link));
}

function* getAccessKey(dataTable, payload) {
  const locationState = yield select(makeSelectLocationState());
  const view = payload.parentId === undefined ? queryString.parse(locationState.location.search).view : FALLBACK_VIEW_NAME;
  const accountId = yield select(makeSelectAccountId());
  const ACCESS_KEY = `${accountId}_${dataTable}_${view}_${MAIN_TABLE_KEY}`;
  return ACCESS_KEY
}

export function makeFilterByParameter(columns, gridFilters) {
  if (!columns || !gridFilters) {
    return null;
  }

  const allFilters = [];
  const gridFiltersWithValue = gridFilters.filter((gridFilter) => gridFilter.value);

  for (let gridFilter of gridFiltersWithValue) {
    const column = columns.find((column) => column.name === gridFilter.columnName);
    if (!column) continue;

    const field = Array.isArray(column.path) ? column.path.join('.') : column.path;
    const fieldFilter = makeFieldFilter(field, column.type, gridFilter.operation, gridFilter.value);
    if (fieldFilter) {
      allFilters.push(fieldFilter);
    }
  }

  return allFilters.length > 0 ? allFilters.join(' && ') : null;
}

export function makeFieldFilter(name, type, operation, value) {
  switch (type) {
    case 'datetime':
      return makeDateFieldFilter(name, operation, value);

    case 'date':
      return makeDateFieldFilter(name, operation, value);

    case 'numeric':
      return makeNumericFieldFilter(name, operation, value);

    case 'boolean':
      return makeBooleanFieldFilter(name, value);

    default:
      return makeStringFieldFilter(name, operation, value);
  }
}

function makeDateString(date, offset = 0) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + offset)
  return `DateTime(${newDate.getFullYear()},${newDate.getMonth() + 1},${newDate.getDate()})`;
}

function makeDateFieldFilter(name, operation, value) {
  const { fromValue, toValue } = value;
  switch (operation) {
    case 'equal':
      return `${name}=${makeDateString(value)}`;
    case 'notEqual':
      return `${name}!=${makeDateString(value)}`;
    case 'oneDay':
      if (fromValue) { return `${name}>=${makeDateString(fromValue)}&&${name}<=${makeDateString(fromValue, 1)}` };
    case 'dateRange':
      if (fromValue && toValue) { return `${name}>=${makeDateString(fromValue)}&&${name}<=${makeDateString(toValue, 1)}` }
      else if (fromValue) { return `${name}>=${makeDateString(fromValue)}` }
      else if (toValue) { return `${name}<=${makeDateString(toValue, 1)}` }
    case 'greaterThan':
      return `${name}>${makeDateString(value)}`;
    case 'greaterThanOrEqual':
      return `${name}>=${makeDateString(value)}`;
    case 'lessThan':
      return `${name}<${makeDateString(value)}`;
    case 'lessThanOrEqual':
      return `${name}<=${makeDateString(value)}`;
    default:
      return null;
  }
}

function makeNumericFieldFilter(name, operation, value) {
  switch (operation) {
    case 'equal':
      return `${name}=${value}`;
    case 'notEqual':
      return `${name}!=${value}`;
    case 'greaterThan':
      return `${name}>${value}`;
    case 'greaterThanOrEqual':
      return `${name}>=${value}`;
    case 'lessThan':
      return `${name}<${value}`;
    case 'lessThanOrEqual':
      return `${name}<=${value}`;
    default:
      return null;
  }
}

function makeBooleanFieldFilter(name, value) {
  return `${name}==${value}`
}

function makeStringFieldFilter(name, operation, value) {
  switch (operation) {
    case 'equal':
      return `${name}="${value}"`;
    case 'notEqual':
      return `${name}!="${value}"`;
    case 'startsWith':
      return `${name}.startsWith("${value}")`;
    case 'endsWith':
      return `${name}.endsWith("${value}")`;
    case 'contains':
      return '(' + value.split(',').map(item => `${name}.contains("${item}")`).join('||') + ')';
    case 'notContains':
      return '!(' + value.split(',').map(item => `${name}.contains("${item}")`).join('||') + ')';
    default:
      return null;
  }
}



function entityReplacer(entity) {
  const replacer = {
    requests: 'request',
    documents: 'document',
    tasks: 'task',
    parentDocument: 'parentdocument',
    supplierInvoices: 'supplierInvoice',
  };

  return replacer[entity];
}