/**
 * AdvancedForm selectors
 */

import {Map, OrderedMap} from 'immutable';
import {denormalize} from 'normalizr';
import {createSelector} from 'reselect';
import {getFormValues, getFormInitialValues} from 'redux-form/immutable';
import diff from 'immutablediff';
import patch from 'immutablepatch';

import {makeSelectEntities} from 'domain/Data/selectors';
import makeSelectUser from 'domain/User/selectors';
import {mapEntityNameToRecord, mapEntityNameToSchema} from 'domain/Data/utils';

const makeSelectDomain = () => (state) => state.get('advancedForms');

const makeSelectAdvancedForm = (formName) => createSelector(
    makeSelectDomain(),
    (domainState) => domainState.get(formName),
);

const makeSelectFields = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('fields'),
);

const makeSelectEntityName = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.getIn(['data', 'entityName']),
);

const makeSelectMode = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('mode'),
);

const makeSelectParams = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('params'),
);

const makeSelectEntityId = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.getIn(['data', 'id']),
);

const makeSelectNormalizationQueue = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('normalizationQueue'),
);

const makeSelectRequestsSent = (formName) => createSelector(
    makeSelectNormalizationQueue(formName),
    (queue) => queue && queue.get('requestsSent')
);

const makeSelectRequestsReceived = (formName) => createSelector(
    makeSelectNormalizationQueue(formName),
    (queue) => queue && queue.get('requestsReceived')
);

const makeSelectNormalizationQueueEntities = (formName) => createSelector(
    makeSelectNormalizationQueue(formName),
    (queue) => queue && queue.get('entities').toJS()
);

const makeSelectNormalizationQueueFields = (formName) => createSelector(
    makeSelectNormalizationQueue(formName),
    (queue) => queue && queue.get('fields').toJS()
);


const makeSelectUseAsyncValidate = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('useAsyncValidate'),
);

const makeSelectRecord = (formName) => createSelector(
    makeSelectEntityName(formName),
    makeSelectEntityId(formName),
    makeSelectEntities(),
    (entityName, entityId, entities) => {
        if (entityName && entityId) {
            let result = null;
            entities.get(entityName).valueSeq().toJS().forEach((item) => {
                if (item.id === entityId) {
                    result = item;
                }
            });

            return result;
        }

        return null;
    },
);

const makeSelectRequiredRequestsInfo = (formName) => createSelector(
    makeSelectEntityName(formName),
    makeSelectFields(formName),
    makeSelectMode(formName),
    makeSelectEntityId(formName),
    (entityName, fields, mode, entityId) => {
        let requiredRequestsInfo = fields
            .filter((field) => field.type === 'select' || field.type === 'comboBox')
            .map((field) => ({
                entityName: field.entityName,
                all: true,
                filters: field.filters,
            }));
        if (mode !== 'add' && !['requests', 'tasks', 'documents'].includes(entityName)) {
            requiredRequestsInfo = requiredRequestsInfo.set(entityName, {
                entityName,
                ids: [entityId],
            });
        }

        return requiredRequestsInfo;
    }
);

const makeSelectEntity = (formName) => createSelector(
    makeSelectEntities(),
    makeSelectEntityName(formName),
    makeSelectEntityId(formName),
    (entities, entityName, entityId) => entities.getIn([entityName, entityId])
);

const makeSelectInitialValues = (formName) => createSelector(
    makeSelectEntityName(formName),
    makeSelectEntity(formName),
    makeSelectFields(formName),
    makeSelectMode(formName),
    makeSelectUser(),
    makeSelectEntities(),
    (entityName, entity, fields, mode, user, entities) => {
        const RecordType = mapEntityNameToRecord(entityName);
        if (mode === 'add' || mode === 'copy') {
            const initialValues = fields.filter((field) => field.initialValue)
                .reduce((acc, field) => ({
                        ...acc,
                        [field.name]: typeof field.initialValue === 'function' ?
                            field.initialValue(acc, mode, user, entities) :
                            field.initialValue,
                    }),
                    mode === 'add' ? {} : (entity) && entity.toJS()
                );

            const calculatedFields = fields.filter((field) => field.calculatedValue);
            const calculatedValues = calculatedFields.reduce((acc, field) => {
                const newValue = field.calculatedValue(acc, mode);
                return newValue !== acc[field.name] ? {
                    ...acc,
                    [field.name]: newValue,
                } : acc;
            }, initialValues);

            return RecordType(calculatedValues);
        }

        return RecordType(entity);
    }
);

const makeSelectCurrentValues = (formName) => createSelector(
    getFormValues(formName, (state) => state.get('forms')),
    (values) => values
);

const makeSelectCurrentFieldValue = (formName, fieldName) => createSelector(
    makeSelectCurrentValues(formName),
    (values) => values ? values.get(fieldName) : null
);

const makeSelectChangedValues = (formName) => createSelector(
    getFormValues(formName, (state) => state.get('forms')),
    getFormInitialValues(formName, (state) => state.get('forms')),
    (currentValues, initialValues) => {
        if (currentValues && initialValues) {
            const ops = diff(initialValues, currentValues);
            const changes = patch(new Map(), ops);
            return changes.toJS();
        }

        return {};
    },
);

const makeSelectDenormalizedCurrentValues = (formName) => createSelector( // eslint-disable-line
    makeSelectEntities(),
    makeSelectEntityName(formName),
    makeSelectCurrentValues(formName),
    (entities, entityName, currentValues) => {
        const newEntityId = '-1';
        const schema = mapEntityNameToSchema(entityName);

        const denormalized = denormalize(
            {[entityName]: [newEntityId]},
            {[entityName]: [schema]},
            entities.setIn([entityName, newEntityId], currentValues),
        );

        return denormalized[entityName] ?
            denormalized[entityName][0] :
            null;
    },
);

const makeSelectSelectFieldsValues = (formName) => createSelector( // eslint-disable-line
    makeSelectEntities(),
    makeSelectFields(formName),
    (entities, fields) => {
        const selectFields = fields.filter((field) => field.type === 'select');

        const result = selectFields.reduce((acc, field) => {
            let values = entities
                .get(field.entityName)
                .filter((entity, id) => field.ids && field.ids.includes(id))
                .map((entity, id) => ({value: id, text: entity.name, orderValue: entity[field.orderBy]}))
                .valueSeq().toJS();

            if (field.orderBy) {
                values = values.sort((a, b) => {
                    if (a.orderValue > b.orderValue) return 1;
                    if (a.orderValue < b.orderValue) return -1;
                    return 0;
                });
            }

            return acc.set(field.entityName, values);
        }, new OrderedMap({}));

        return result.toJS();
    }
);

const makeSelectGroups = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('groups'),
);


const makeSelectPositions = (formName, entityName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedForm) => advancedForm && advancedForm.get(entityName)
);

const makeSelectDetailTableData = (formName, entityName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get(entityName),
);

const makeSelectUpdateDetailTable = (formName) => createSelector(
    makeSelectAdvancedForm(formName),
    (advancedFormState) => advancedFormState.get('update'),
);


export default makeSelectDomain;
export {
    makeSelectAdvancedForm,
    makeSelectFields,
    makeSelectMode,
    makeSelectParams,
    makeSelectEntityName,
    makeSelectEntityId,
    makeSelectUseAsyncValidate,
    makeSelectRequiredRequestsInfo,
    makeSelectInitialValues,
    makeSelectCurrentValues,
    makeSelectCurrentFieldValue,
    makeSelectChangedValues,
    makeSelectNormalizationQueue,
    makeSelectRequestsReceived,
    makeSelectRequestsSent,
    makeSelectNormalizationQueueEntities,
    makeSelectNormalizationQueueFields,
    makeSelectRecord,
    makeSelectGroups,
    makeSelectPositions,
    makeSelectDetailTableData,
    makeSelectUpdateDetailTable,
};
