/*
 *
 * SelectTextFilterEditor actions
 *
 */

import { LOAD_ENTITY, ADD_ENTITY_TO_STATE } from './constants';

export function loadFilterEntity(entityName) {
  return {
    type: LOAD_ENTITY,
    meta: entityName,
  };
}


export function addEntityToState(entityName, entity) {
  return {
    type: ADD_ENTITY_TO_STATE,
    payload: { entityName, entity },
  };
}