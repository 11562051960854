/*
 *
 * action buttons 14720 (BC Gorkiy Park)
 *
 */

import { requestAction } from "../../../../../domain/typeConstants/action";
import { requestStatus } from "../../../../../domain/typeConstants/status";
import { SERVICE } from "../../../../../domain/Data/RequestType/constants";

import HowToReg from "@material-ui/icons/HowToReg";
import Assignment from "@material-ui/icons/Assignment";
import React from "react";

export const closeByTenantActionButton = {
    name: requestAction.CLOSE_BY_TENANT,
    label: 'Принять исполнение',
    icon: 'howToReg',
    svgIcon: <HowToReg />,
    disabled: (selection, entities) => {
        if (selection.length !== 0) {
            if (!entities[0].status) return false;
            if (!entities[0].type) return false;
            return entities.some((element) => (element.status &&
                element.status.name !== requestStatus.COMPLETED) || element.type.id !== SERVICE);
        }
        return true;
    },
};

export const agreedEstimationCostActionButton = {
    name: requestAction.AGREED_ESTIMATION_COST,
    label: 'Скан смета',
    icon: 'assignment',
    svgIcon: <Assignment />,
    disabled: (selection, entities) => {
        if (selection.length !== 0) {
            if (!entities[0].status) return false;
            if (!entities[0].type) return false;
            return entities.some((element) => element.type.id !== SERVICE);
        }
        return true;
    },
};
