import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Notification from 'components/Notification';

const styles = (theme) => ({
  root: {

  },
  notification: {
    margin: theme.spacing.unit * 2,
  },
});

function NotificationsList(props) {
  const { data, classes, className } = props;

  return (
    <div className={classnames({ [classes.root]: true, [className]: !!className })}>
      {
        data.map((item) =>
          (<Notification
            className={classes.notification}
            key={item.id}
            typeId={item.type.id}
            heading={item.heading}
            сreationDate={item.actualCreationDate}
            content={item.content}
          />)
        )
      }
    </div>
  );
}

NotificationsList.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(NotificationsList);
