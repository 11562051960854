/**
 *
 * ChangeRoleDialog actions
 *
 */

import {
  OPEN_CHANGE_ROLE_DIALOG,
  ACCEPT_CHANGE_ROLE_DIALOG,
  CLOSE_CHANGE_ROLE_DIALOG,
} from './constants';

export function openChangeRoleDialog() {
  return {
    type: OPEN_CHANGE_ROLE_DIALOG,
  };
}

export function acceptChangeRoleDialog(role) {
  return {
    type: ACCEPT_CHANGE_ROLE_DIALOG,
    payload: {
      role,
    },
  };
}

export function closeChangeRoleDialog() {
  return {
    type: CLOSE_CHANGE_ROLE_DIALOG,
  };
}
