/*
 *
 * DadataField reducer
 *
 */

import { FormField } from 'domain/Data/recordTypes';
import {
  CLEAR_SUGGESTIONS,
  LOAD_SUGGESTIONS_SUCCESS,
} from './constants';

function dadataReducer(state = new FormField({}), { type, meta, payload }) {
  switch (type) {
    case CLEAR_SUGGESTIONS:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'suggestions'], []);

    case LOAD_SUGGESTIONS_SUCCESS:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'suggestions'], payload.suggestions);

    default:
      return state;
  }
}

export default dadataReducer;
