/*
 *
 * requestType
 *
 */


export const requestType = Object.freeze({
  CARRYING_OUT_WORKS: 'На проведение работ',
});
