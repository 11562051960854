/*
 *
 * PassType constants
 *
 */

export const PERMANENT = '2194469501000';
export const TEMPORARY = '2194469496000';

export const WITH_CHIP = '4150255674000';
export const WITHOUT_CHIP = '4150255677000';
