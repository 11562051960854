/**
 *
 *  CheckpointDxGrid12970
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';

import { requestAction } from 'domain/typeConstants/action';

import { arrivedActionButton, departedActionButton } from '../common/actionButton';
import { arrivedActionItem, departedActionItem } from '../common/actionMenu';

class CheckpointDxGrid12970 extends CheckpointDxGrid {
  getActionButtons() {
    const actionButtons = super.getActionButtons();
    const arrivedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.ARRIVED);
    const departedButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.DEPARTED);
    actionButtons[arrivedButtonIndex] = arrivedActionButton;
    actionButtons[departedButtonIndex] = departedActionButton;

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();
    const arrivedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.ARRIVED);
    const departedActionMenuIndex = actionMenuItems.findIndex((item) => item.name === requestAction.DEPARTED);
    actionMenuItems[arrivedActionMenuIndex] = arrivedActionItem;
    actionMenuItems[departedActionMenuIndex] = departedActionItem;

    return actionMenuItems;
  }
}

export default CheckpointDxGrid12970;
