/**
 *
 * ReceptionForm
 *
 */

import { ReceptionForm } from "pages/Rules/ReceptionView/ReceptionForm";


const INGKA_HANIM_LTD = '3940034387000'

export class ReceptionForm14326 extends ReceptionForm {

  getFields() {
    let fields = super.getFields()
    const { viewName } = this.props;

    const contentIndex = fields.findIndex((field) => field.name === 'content');
    fields[contentIndex].helperText = viewName === 'allNews' ? "Ссылки на документы можно оставлять в тексте новости" : null

    const newsTopic = {
      name: 'newsTopic',
      label: 'Тема',
      grid: { sm: 12 },
    }
    fields.splice(contentIndex, 0, newsTopic)

    const fileIndex = fields.findIndex((field) => field.name === 'file');
    fields[fileIndex].fileSize = null;
    fields[fileIndex].visible = viewName !== 'allNews';

    const numberIndex = fields.findIndex((field) => field.name === 'number');
    fields.splice(numberIndex, 1)

    const companyIndex = fields.findIndex((field) => field.name === 'company');
    fields[companyIndex].helperText = viewName === 'allNews' ? 'Укажите арендатора, если новость только для него. Иначе оставьте поле пустым' : ''
    fields[companyIndex].initialValue = (values, mode) => !values.isNews && mode === 'add' ? INGKA_HANIM_LTD : null
    fields[companyIndex].disabled = (values, initialValues, mode) => !values.isNews && mode === 'add'

    return fields
  }

}