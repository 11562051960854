/**
 *
 * SelectTextField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import CheckBox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const selectStyles = (theme) => ({
  input: {
    fontSize: 15,
    marginBottom: 6,
    width: '100%',
    lineHeight: 1.5
  },
  clearIcon: {
    height: 32,
    width: 32,
  },
  buttons: {
    paddingTop: '10px',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    outline: 'none'
  },
  container: {
    overflow: 'auto',
    outline: 'none'
  },
});

class SelectFieldBase extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedAll: false,
      value: '',
      entity: [],
      render: false,
    };
  }

  componentDidMount() {
    this.getEntityList()
  }

  componentDidUpdate(prevProps) {
    prevProps.options !== this.props.options && this.getEntityList()
  }

  getEntityList = () => {
    const { options } = this.props;

    if (options) {
      const entityList = options.map(item => {
        return {
          id: item.id,
          name: item.name,
          selected: false,
        }
      })
        .filter(item => item.name)
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        })
      this.setState({ entity: entityList });
    }
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => { this.setState({ anchorEl: null }); };

  handleResetValue = (event) => {
    if (event) event.stopPropagation();
    const { entity } = this.state;
    entity.map(item => item.selected = false)
    this.setState({
      selectedAll: false,
      entity,
      value: '',
    });
  }

  handleMenuItemClick = (id) => {
    let newEntity = this.state.entity;
    newEntity.map(item => {
      if (item.id === id) {
        item.selected = !item.selected
      }
    })
    this.setState({
      entity: newEntity,
      selectedAll: false,
      render: !this.state.render
    })
  }

  handleSelectAllClick = () => {
    let newEntity = this.state.entity;
    newEntity.map(item => item.selected = !this.state.selectedAll)

    this.setState({
      selectedAll: !this.state.selectedAll,
      entity: newEntity
    });
  }

  handleOKClick = () => {
    const outputValue = this.state.entity
      .filter(item => item.selected === true)
      .map(item => item.name)
      .join(', ')
    this.setState({
      anchorEl: null,
      value: outputValue
    })
    const outputIds = this.state.entity
      .filter(item => item.selected === true)
      .map(item => item.id)
    const { onChange } = this.props;
    onChange(outputIds);
  }

  handleCancelClick = () => {
    this.setState({
      anchorEl: null
    });

    setTimeout(() => {
      let { entity } = this.state;
      entity.map(item => item.selected = false)
      this.setState({
        entity,
        selectedAll: false,
      })
    }, 200);
  }

  buttonOKdisabled = () => {
    const { entity } = this.state
    return !entity.some(item => item.selected === true)
  }

  render() {
    const { entity } = this.state;
    const { classes, disabled } = this.props;
    const getHeight = entity && entity.length > 10 ? { height: '400px' } : { height: 'auto' };

    return (
      <div>
        <Input
          className={classes.input}
          endAdornment={this.props.value && (
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear for datepcker"
                className={classes.clearIcon}
                onClick={this.handleResetValue}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          )}
          onKeyPress={this.handleKeyPress}
          onClick={this.handleMenuOpen}
          disabled={disabled}
          fullWidth
          multiline
          value={this.state.value}
        />
        {entity &&
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            MenuListProps={{ dense: true }}

            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}

          >
            <MenuItem
              key={0}
              selected={this.state.selectedAll}
              onClick={() => this.handleSelectAllClick()}
            >
              <CheckBox
                color="primary"
                checked={this.state.selectedAll}
              /> Выделить все
            </MenuItem>
            <Divider />
            <div className={classes.container} style={getHeight}>
              {entity && entity.map((valueItem) => {
                return (
                  <MenuItem
                    key={valueItem.id}
                    selected={valueItem.selected}
                    onClick={() => this.handleMenuItemClick(valueItem.id)}
                  >
                    <CheckBox
                      color="primary"
                      checked={valueItem.selected}
                    />
                    {valueItem.name}
                  </MenuItem>
                )
              })}
            </div>
            <div className={classes.buttons}>
              <Button color="primary"
                onClick={this.handleOKClick}
                disabled={this.buttonOKdisabled()}
              >OK</Button>
              <Button color="primary"
                onClick={this.handleCancelClick}
              >Отмена</Button>
            </div>
          </Menu>}
      </div>
    );
  }

  static propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    error: PropTypes.string,
    required: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    value: PropTypes.array.isRequired,
  };

}

export const SelectField = withStyles(selectStyles)(SelectFieldBase);
