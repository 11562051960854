/**
 *
 * 11994 common dxGridColumnFilters
 *
 */

export const MOVEMENT_TMC_COLUMN_FILTERS = [
  { columnName: 'number', value: '' },
  { columnName: 'statusName', value: '' },
  { columnName: 'visitDate', value: '' },
  { columnName: 'expirationDate', value: '' },
  { columnName: 'visitorFullName', value: '' },
  { columnName: 'materialValuesData', value: '' },
  { columnName: 'note', value: '' },
  { columnName: 'company_name', value: '' },
  { columnName: 'authorName', value: '' },
  { columnName: 'arrivalDate', value: '' },
  { columnName: 'departureDate', value: '' },
  { columnName: 'actualCreationDate', value: '' },
];
