/**
 *
 * ServiceRequestsManager Requests DxGrid 14326
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';
import { requestAction } from 'domain/typeConstants/action';

import {
  sendToAgreementActionButton,
  sendEmailActionButton,
  arrivedActionButton,
  departedActionButton,
  addBlackListCarActionButton, acceptActionButton,
} from '../common/actionButton';

import {
  sendToAgreementActionMenuItem,
  sendEmailActionMenuItem,
  arrivedActionItem,
  departedActionItem,
  addBlackListCarActionItem, acceptActionItem,
} from '../common/actionMenuItem';

export class ServiceRequestsManagerDxGrid14326 extends ServiceRequestsManagerDxGrid {
  getColumns() {
    const columns = super.getColumns();
    const unnecessaryColumns = new Set(['serviceName']);

    return columns.filter((column) => !unnecessaryColumns.has(column.name));
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();

    const editIndex = actionButtons.findIndex((button) => button.name === requestAction.EDIT);
    actionButtons[editIndex].label = 'Открыть';

    const acceptActionButtonIndex = actionButtons.findIndex((actionButton) => actionButton.name === requestAction.ACCEPT);

    actionButtons.splice(acceptActionButtonIndex, 1, acceptActionButton);

    actionButtons.push(arrivedActionButton);
    actionButtons.push(departedActionButton);
    actionButtons.push(addBlackListCarActionButton);
    actionButtons.push(sendToAgreementActionButton);
    actionButtons.push(sendEmailActionButton);

    return actionButtons;
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems();

    const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
    actionMenuItems[editIndex].label = 'Открыть';

    const acceptMenuItemIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);

    actionMenuItems.splice(acceptMenuItemIndex, 1, acceptActionItem);

    actionMenuItems.push(arrivedActionItem);
    actionMenuItems.push(departedActionItem);
    actionMenuItems.push(addBlackListCarActionItem);
    actionMenuItems.push(sendToAgreementActionMenuItem);
    actionMenuItems.push(sendEmailActionMenuItem);

    return actionMenuItems;
  }
}
