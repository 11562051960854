/**
 *
 * Reception Rules Page actions
 *
 */

import {
  EXECUTE_EDIT_ACTION,
  EXECUTE_ADD_ACTION,
  EXECUTE_VIEW_ACTION,
} from './constants';

export const executeAddAction = () => ({
  type: EXECUTE_ADD_ACTION,
});

export const executeEditAction = (id) => ({
  type: EXECUTE_EDIT_ACTION,
  payload: { id },
});

export const executeViewAction = (id) => ({
  type: EXECUTE_VIEW_ACTION,
  payload: { id },
});
