/**
 *
 * TenantDxGrid14326
 *
 */

import { TenantDxGrid } from 'pages/Requests/TenantView';

import { columnType, requestColumnSizes } from 'domain/typeConstants/table';

export class TenantDxGrid14326 extends TenantDxGrid {
  getColumns() {
    const columns = super.getColumns();
    const unnecessaryColumns = new Set(['serviceName']);

    const typeShortNameIndex = columns.findIndex((column) => column.name === 'typeShortName');
    columns[typeShortNameIndex].path = ['type', 'himkiName'];

    const statusNameIndex = columns.findIndex((column) => column.name === 'statusName');
    columns[statusNameIndex].path = ['status', 'alternativeName'];

    columns.push({
      path: 'phone',
      name: 'phone',
      title: 'Тел. встречающего',
      width: requestColumnSizes.phone,
    });
    // columns.push({
    //   path: 'khimkiGuestPhone',
    //   name: 'khimkiGuestPhone',
    //   title: 'Телефон гостя',
    // });
    columns.push({
      path: 'khimkiRequestType',
      name: 'khimkiRequestType',
      title: 'Тип',
      getCellValue: (row) => row.khimkiRequestType ? row.khimkiRequestType.name : undefined,
      entityName: 'khimkiRequestTypes',
      width: requestColumnSizes.khimkiRequestType,
    });

    columns.push({
      path: 'whoMeets',
      name: 'whoMeets',
      title: 'Встречающий',
      width: requestColumnSizes.whoMeets,
    });

    columns.push({
      path: 'managementCompanyComment',
      name: 'managementCompanyComment',
      title: 'Комментарий ХБП',
      width: requestColumnSizes.managementCompanyComment,
    });

    columns.push({
      path: 'actualCreationDate',
      name: 'actualCreationDate',
      title: 'Создана',
      type: columnType.DATETIME,
      width: requestColumnSizes.actualCreationDate,
    });

    return columns.filter((column) => !unnecessaryColumns.has(column.name));
  }

  getGridFilters() {
    const columnFilters = super.getGridFilters();

    columnFilters.push({
      columnName: 'phone', value: '',
    });
    columnFilters.push({
      columnName: 'khimkiGuestPhone', value: '',
    });
    columnFilters.push({
      columnName: 'khimkiRequestType', value: '',
    });
    columnFilters.push({
      columnName: 'whoMeets', value: '',
    });
    columnFilters.push({
      columnName: 'actualCreationDate', value: '',
    });

    return columnFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();

    const visitorFullNameIndex = columnOrder.findIndex((columnName) => columnName === 'visitorFullName');
    columnOrder.splice(visitorFullNameIndex + 1, 0, 'khimkiGuestPhone');

    const typeIndex = columnOrder.findIndex((columnName) => columnName === 'typeShortName');
    columnOrder.splice(typeIndex + 1, 0, 'khimkiRequestType');

    const parkingPlaceIndex = columnOrder.findIndex((columnName) => columnName === 'parkingPlace');
    columnOrder.splice(parkingPlaceIndex + 1, 0, 'phone');
    columnOrder.splice(parkingPlaceIndex + 2, 0, 'whoMeets');

    columnOrder.push('actualCreationDate');

    return columnOrder;
  }
}
