/**
 *
 * ParkingOperatorView constants
 *
 */

export const PARKING_OPERATOR_REQUESTS_TABLE_GRID = 'ParkingOperatorRequestsTableGrid';
export const PARKING_OPERATOR_REQUESTS_FORM = 'ParkingOperatorRequestsForm';
export const PARKING_OPERATOR_REQUESTS_FORM_DIALOG = 'ParkingOperatorRequestsFormDialog';

export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/ParkingOperatorView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/ParkingOperatorView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/ParkingOperatorView/EXECUTE_DEPARTED_ACTION';
