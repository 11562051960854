/**
 *
 * ReceptionMobileGrid 15755
 *
 */

import ReceptionMobileGrid from 'pages/Requests/ReceptionView/ReceptionMobileGrid';
import {
    CURRENT_DAY_REQUESTS,
    REQUESTS_FOR_USER_DEPARTMENT,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT,
    REQUESTS_FOR_ON_PASS,
    ON_CREATE_REQUESTS,
    ACTIVE_REQUESTS,
} from 'domain/Data/Filters/constants';

export class ReceptionMobileGrid15755 extends ReceptionMobileGrid {
    getFilters() {
        const { viewName } = this.props;
        const filters = [REQUESTS_FOR_USER_DEPARTMENT];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'pass') filters.push(REQUESTS_FOR_ON_PASS);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
        return filters;
    }
}


