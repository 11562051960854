/**
 *
 * TenantMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import { columnType } from 'domain/typeConstants/table';

import {
  ACTIVE_RULES,
  COMPANY_RULES, NEWS_RULES, NOT_NEWS_RULES,
} from 'domain/Data/Filters/constants';

import { TENANT_RULE_DATA_TABLE } from './constants';

import { RowComponent } from '../common/mobile';

export class TenantMobileGrid extends React.PureComponent {
  static propTypes = {
    viewName: PropTypes.string,
  };

  getFilters() {
    const { viewName } = this.props;
    const filters = [COMPANY_RULES];
    if (viewName === 'active') filters.push(ACTIVE_RULES);
    if (viewName === 'allNews') filters.push(NEWS_RULES);
    if (viewName === 'allDocumentsRules') filters.push(NOT_NEWS_RULES);
    return filters;
  }

  getDetailRowFields() {
    return [
      {
        name: 'actualCreationDate',
        title: 'Дата',
        type: columnType.DATE,
      },
      {
        name: 'validUntil',
        title: 'Действует до',
        type: columnType.DATE,
      },
      {
        name: 'content',
        title: 'Содержание',
      },
      {
        name: 'file.name',
        getValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
      },
    ];
  }

  getSorting() {
    return [{ columnId: 'actualCreationDate', columnName: 'actualCreationDate', direction: 'desc' }];
  }

  render() {
    const { viewName } = this.props;

    return (
      <AdvancedMobileGrid
        entityName="documents"
        name={TENANT_RULE_DATA_TABLE}

        filters={this.getFilters()}
        sorting={this.getSorting()}
        viewName={viewName}
        rowComponent={RowComponent}
        detailRowFields={this.getDetailRowFields()}
      />
    );
  }
}
