/**
 *
 * ComboBox selectors
 *
 */

import { createSelector } from 'reselect';
import { makeSelectEntities } from 'domain/Data/selectors';
import makeSelectUser from 'domain/User/selectors';
import {
  makeSelectFields,
  makeSelectInitialValues,
  makeSelectCurrentValues,
  makeSelectMode,
} from '../selectors';
import { USER_TASK_FORM } from 'pages/Tasks/TaskView/constants';

const makeSelectDomain = (formName, fieldName) => createSelector(
  makeSelectFields(formName),
  (fields) => fields.get(fieldName),
);

const makeSelectIsLoaded = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  (fieldState) => fieldState && fieldState.loaded,
);

const makeSelectIsCached = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  (fieldState) => fieldState && fieldState.cached,
);

const makeSelectLoadedOptions = (formName, fieldName) => createSelector(
  makeSelectEntities(),
  makeSelectDomain(formName, fieldName),
  (entities, field) => {
    let values = entities
      .get(field.entityName)
      .filter((entity, id) => field.loadedOptions && field.loadedOptions.includes(id) && !!entity.name)
      .map((entity, id) => ({
        value: id,
        text: formName == USER_TASK_FORM && fieldName == 'status' ? entity.statusZadachi : entity.name,
        orderValue: entity[field.orderBy],
        number: entity.number,
        khimkiNumber: entity.khimkiNumber,
        orderKhimki: entity.orderKhimki,
      }))
      .valueSeq()
      .toJS();

    if (field.compareOptions) {
      values = values.sort(field.compareOptions);
    } else if (field.orderBy) {
      values = values.sort((a, b) => {
        if (a.orderValue > b.orderValue) return 1;
        if (a.orderValue < b.orderValue) return -1;
        return 0;
      });
    } else {
      values = values.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });
    }

    return values;
  },
);

const makeSelectVisibleOptionIds = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  (fieldState) => fieldState.visibleOptions,
);

const makeSelectOptions = (formName, fieldName) => createSelector(
  makeSelectLoadedOptions(formName, fieldName),
  makeSelectVisibleOptionIds(formName, fieldName),
  makeSelectInitialValues(formName),
  makeSelectCurrentValues(formName),
  makeSelectMode(formName),
  makeSelectUser(),
  makeSelectEntities(),
  (loadedOptions, visibleOptionIds, initialValues, currentValues, mode, user, entities) => {

    if (!visibleOptionIds) {
      return loadedOptions;
    }

    let ids = [];
    if (Array.isArray(visibleOptionIds)) {
      ids = visibleOptionIds;
    } else if (typeof visibleOptionIds === 'function') {
      ids = visibleOptionIds(currentValues, initialValues, mode, user, entities);
      if (!ids) {
        return loadedOptions;
      }
    }

    return loadedOptions.filter((option) => ids.includes(option.value));
  },
);

export default makeSelectDomain;
export {
  makeSelectIsLoaded,
  makeSelectIsCached,
  makeSelectLoadedOptions,
  makeSelectVisibleOptionIds,
  makeSelectOptions,
};
