import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'utils/typeUtils';

import {
  REQUESTS_PATH,
  SERVICE_REQUESTS_PATH,
} from 'domain/Navigation/constants';

import {
  RestrictedAdministratorBCRequestsDxGrid,
  RestrictedAdministratorBCRequestsMobileGrid,
  RestrictedAdministratorBCServiceRequestsDxGrid,
  RestrictedAdministratorBCServiceRequestsMobileGrid,
} from '../RestrictedAdministratorBCView';

import ReceptionView, {
  BlackListForm,
  BlackListFormDialog,
  ReceptionDxGridContainer,
  ReceptionForm,
  ReceptionFormDialog,
  ReceptionRejectForm,
  ReceptionRejectFormDialog,
  VisitorArrivalForm,
  VisitorArrivalFormDialog,
} from '../ReceptionView';

import ServiceRequestsManagerView, {
  ServiceRequestsManagerAcceptForm,
  ServiceRequestsManagerAcceptFormDialog,
  ServiceRequestsManagerCompleteForm,
  ServiceRequestsManagerCompleteFormDialog,
  ServiceRequestsManagerDxGridContainer,
  ServiceRequestsManagerForm,
  ServiceRequestsManagerFormDialog,
  ServiceRequestsManagerRejectForm,
  ServiceRequestsManagerRejectFormDialog,
} from '../ServiceRequestsManagerView';

/** request components */
const getBlackListFormDialog = (blackListForm) => (
  <BlackListFormDialog form={blackListForm || <BlackListForm />} />
);

const getVisitorArrivalFormDialog = (visitorArrivalForm) => (
  <VisitorArrivalFormDialog form={visitorArrivalForm || <VisitorArrivalForm />} />
);

const getFormDialog = (form) => (
  <ReceptionFormDialog form={form || <ReceptionForm />} />
);

const getRejectFormDialog = (rejectForm) => (
  <ReceptionRejectFormDialog form={rejectForm || <ReceptionRejectForm />} />
);

const getDxGrid = (dxGrid) => (
  <ReceptionDxGridContainer component={dxGrid || <RestrictedAdministratorBCRequestsDxGrid />} />
);

const getMobileGrid = (mobileGrid) => (
  <ReceptionDxGridContainer component={mobileGrid || <RestrictedAdministratorBCRequestsMobileGrid />} />
);

const getDataTableView = ({
  breakpoint,
  restrictedAdministratorBCDxGrid,
  restrictedAdministratorBCMobileGrid,
}) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(restrictedAdministratorBCMobileGrid);
  }
  if (isUndefined(restrictedAdministratorBCDxGrid) || restrictedAdministratorBCDxGrid) {
    return getDxGrid(restrictedAdministratorBCDxGrid);
  }
};

/** service request components */
const getServiceAcceptFormDialog = (acceptForm) => (
  <ServiceRequestsManagerAcceptFormDialog form={acceptForm || <ServiceRequestsManagerAcceptForm />} />
);

const getServiceCompleteFormDialog = (completeForm) => (
  <ServiceRequestsManagerCompleteFormDialog form={completeForm || <ServiceRequestsManagerCompleteForm />} />
);

const getServiceRejectFormDialog = (rejectForm) => (
  <ServiceRequestsManagerRejectFormDialog form={rejectForm || <ServiceRequestsManagerRejectForm />} />
);

const getServiceFormDialog = (form) => (
  <ServiceRequestsManagerFormDialog form={form || <ServiceRequestsManagerForm />} />
);

const getServiceDxGrid = (dxGrid) => (
  <ServiceRequestsManagerDxGridContainer component={dxGrid || <RestrictedAdministratorBCServiceRequestsDxGrid />} />
);

const getServiceMobileGrid = (mobileGrid) => (
  <ServiceRequestsManagerDxGridContainer component={mobileGrid || <RestrictedAdministratorBCServiceRequestsMobileGrid />} />
);

const getServiceDataTableView = ({
  breakpoint,
  restrictedAdministratorBCServiceDxGrid,
  restrictedAdministratorBCServiceMobileGrid,
}) => {
  if (breakpoint.down('md')) {
    return getServiceMobileGrid(restrictedAdministratorBCServiceMobileGrid);
  }
  if (isUndefined(restrictedAdministratorBCServiceDxGrid) || restrictedAdministratorBCServiceDxGrid) {
    return getServiceDxGrid(restrictedAdministratorBCServiceDxGrid);
  }

};

export const getRestrictedAdministratorBCView = (props) => {
  const {
    restrictedAdministratorBCBlackListForm,
    restrictedAdministratorBCBlackListFormDialog,
    restrictedAdministratorBCDxGrid,
    restrictedAdministratorBCMobileGrid,
    restrictedAdministratorBCForm,
    restrictedAdministratorBCFormDialog,
    restrictedAdministratorBCRejectForm,
    restrictedAdministratorBCRejectFormDialog,
    restrictedAdministratorBCView,
    restrictedAdministratorBCVisitorArrivalForm,
    restrictedAdministratorBCVisitorArrivalFormDialog,

    restrictedAdministratorBCServiceAcceptForm,
    restrictedAdministratorBCServiceAcceptFormDialog,
    restrictedAdministratorBCServiceCompleteForm,
    restrictedAdministratorBCServiceCompleteFormDialog,
    restrictedAdministratorBCServiceDxGrid,
    restrictedAdministratorBCServiceMobileGrid,
    restrictedAdministratorBCServiceForm,
    restrictedAdministratorBCServiceFormDialog,
    restrictedAdministratorBCServiceRejectForm,
    restrictedAdministratorBCServiceRejectFormDialog,
    restrictedAdministratorBCServiceView,

    breakpoint,
    location: { pathname },
    params,
    viewName,
    viewStyles,
  } = props;

  if (pathname === REQUESTS_PATH && restrictedAdministratorBCView) return restrictedAdministratorBCView;
  if (pathname === SERVICE_REQUESTS_PATH && restrictedAdministratorBCServiceView) return restrictedAdministratorBCServiceView;

  if (pathname === REQUESTS_PATH) {
    return (
      <ReceptionView
        key={viewName}
        viewName={viewName}
        params={params}
        viewStyles={viewStyles}
        dataTable={getDataTableView({
          breakpoint,
          restrictedAdministratorBCDxGrid,
          restrictedAdministratorBCMobileGrid,
        })}
        formDialog={restrictedAdministratorBCFormDialog
          || getFormDialog(restrictedAdministratorBCForm)}
        rejectFormDialog={restrictedAdministratorBCRejectFormDialog
          || getRejectFormDialog(restrictedAdministratorBCRejectForm)}
        visitorArrivalFormDialog={restrictedAdministratorBCVisitorArrivalFormDialog
          || getVisitorArrivalFormDialog(restrictedAdministratorBCVisitorArrivalForm)}
        blackListFormDialog={restrictedAdministratorBCBlackListFormDialog
          || getBlackListFormDialog(restrictedAdministratorBCBlackListForm)}
      />
    );
  } else if (pathname === SERVICE_REQUESTS_PATH) {
    return (
      <ServiceRequestsManagerView
        key={viewName}
        viewName={viewName}
        viewStyles={viewStyles}
        dataTable={getServiceDataTableView({
          breakpoint,
          restrictedAdministratorBCServiceDxGrid,
          restrictedAdministratorBCServiceMobileGrid,
        })}
        formDialog={restrictedAdministratorBCServiceFormDialog || getServiceFormDialog(restrictedAdministratorBCServiceForm)}
        serviceRequestsManagerAcceptFormDialog={restrictedAdministratorBCServiceAcceptFormDialog
          || getServiceAcceptFormDialog(restrictedAdministratorBCServiceAcceptForm)}
        serviceRequestsManagerRejectFormDialog={restrictedAdministratorBCServiceRejectFormDialog
          || getServiceRejectFormDialog(restrictedAdministratorBCServiceRejectForm)}
        serviceRequestsManagerCompleteFormDialog={restrictedAdministratorBCServiceCompleteFormDialog
          || getServiceCompleteFormDialog(restrictedAdministratorBCServiceCompleteForm)}
      />
    );
  }

  return null;
};

getRestrictedAdministratorBCView.propTypes = {
  restrictedAdministratorBCBlackListForm: PropTypes.node,
  restrictedAdministratorBCBlackListFormDialog: PropTypes.node,
  restrictedAdministratorBCDxGrid: PropTypes.node,
  restrictedAdministratorBCMobileGrid: PropTypes.node,
  restrictedAdministratorBCForm: PropTypes.node,
  restrictedAdministratorBCFormDialog: PropTypes.node,
  restrictedAdministratorBCRejectForm: PropTypes.node,
  restrictedAdministratorBCRejectFormDialog: PropTypes.node,
  restrictedAdministratorBCView: PropTypes.node,
  restrictedAdministratorBCVisitorArrivalForm: PropTypes.node,
  restrictedAdministratorBCVisitorArrivalFormDialog: PropTypes.node,

  restrictedAdministratorBCServiceAcceptForm: PropTypes.node,
  restrictedAdministratorBCServiceAcceptFormDialog: PropTypes.node,
  restrictedAdministratorBCServiceCompleteForm: PropTypes.node,
  restrictedAdministratorBCServiceCompleteFormDialog: PropTypes.node,
  restrictedAdministratorBCServiceDataTable: PropTypes.node,
  restrictedAdministratorBCServiceDxGrid: PropTypes.node,
  restrictedAdministratorBCServiceMobileGrid: PropTypes.node,
  restrictedAdministratorBCServiceForm: PropTypes.node,
  restrictedAdministratorBCServiceFormDialog: PropTypes.node,
  restrictedAdministratorBCServiceRejectForm: PropTypes.node,
  restrictedAdministratorBCServiceRejectFormDialog: PropTypes.node,
  restrictedAdministratorBCServiceView: PropTypes.node,

  breakpoint: PropTypes.object.isRequired,
  location: PropTypes.object,
  params: PropTypes.object,
  pathname: PropTypes.string.isRequired, // eslint-disable-line
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
