/**
 *
 * Data constants
 *
 *
 */

/**
 * Actions
 */

export const ADD_ENTITIES_STATE = 'claris-vnext/Data/ADD_ENTITIES_STATE';
export const CHANGE_ENTITIES_STATE = 'claris-vnext/Data/CHANGE_ENTITIES_STATE';

export const CHANGE_ENTITIES_TOTAL_COUNT = 'claris-vnext/Data/CHANGE_ENTITIES_TOTAL_COUNT';
export const INCREASE_ENTITIES_TOTAL_COUNT = 'claris-vnext/Data/INCREASE_ENTITIES_TOTAL_COUNT';

export const LOAD_ENTITIES = 'claris-vnext/Data/LOAD_ENTITIES';
export const LOAD_ENTITIES_SUCCESS = 'claris-vnext/Data/LOAD_ENTITIES_SUCCESS';
export const LOAD_ENTITIES_ERROR = 'claris-vnext/Data/LOAD_ENTITIES_ERROR';

export const SAVE_ENTITY = 'claris-vnext/Data/SAVE_ENTITY';
export const SAVE_ENTITY_SUCCESS = 'claris-vnext/Data/SAVE_ENTITY_SUCCESS';
export const SAVE_ENTITY_ERROR = 'claris-vnext/Data/SAVE_ENTITY_ERROR';

export const UPDATE_ENTITY = 'claris-vnext/Data/UPDATE_ENTITY';
export const UPDATE_ENTITY_SUCCESS = 'claris-vnext/Data/UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_ERROR = 'claris-vnext/Data/UPDATE_ENTITY_ERROR';

export const CLEAR_ENTITY = 'claris-vnext/Data/CLEAR_ENTITY';
export const CHANGE_DIALOG_DATA_STATE = 'claris-vnext/Data/CHANGE_DIALOG_DATA_STATE';
export const UPDATE_TERMINAL_ADVANCED_INPUT = 'claris-vnext/Data/UPDATE_TERMINAL_ADVANCED_INPUT';

/**
 * Names of entities
 */
export const ATTACHMENTS = 'attachments';
export const ACCESS_AREAS = 'accessAreas';
export const ACCESS_CARDS = 'accessCards';
export const ACCESS_LEVELS = 'accessLevels';
export const ACTION_CARDS = 'actionCards';
export const AGREEMENT_HISTORIES = 'agreementHistories';
export const AGREEMENT_SCHEMES = 'agreementSchemes';
export const AGREEMENT_STATES = 'agreementStates';
export const AGREEMENT_STEPS = 'agreementSteps';
export const BLACK_LIST = 'blackList';
export const CAR_MODELS = 'carModels';
export const COMMENTS = 'comments';
export const COMPANIES = 'companies';
export const CONTRACT_TYPES = 'contractTypes';
export const CONTRACTS = 'contracts';
export const CONTRACTOR_STATUSES = 'contractorStatuses';
export const CONTRACTOR_TYPES = 'contractorTypes';
export const DEPARTMENTS = 'departments';
export const DOCUMENT_CATEGORIES = 'documentCategories';
export const DOCUMENT_KINDS = 'documentKinds';
export const DOCUMENT_STATES = 'documentStates';
export const DOCUMENT_TYPES = 'documentTypes';
export const DOCUMENTS = 'documents';
export const EMPLOYEES = 'employees';
export const FILES = 'files';
export const HOUSINGS = 'housings';
export const ISSUE_REASONS = 'issueReasons';
export const KHIMKI_REQUEST_TYPES = 'khimkiRequestTypes';
export const MANAGERS = 'managers';
export const MATERIAL_COMMODITY_VALUE_TYPES = 'materialCommodityValueTypes';
export const NOTIFICATIONS = 'notifications';
export const NOTIFICATION_TYPES = 'notificationTypes';
export const NOTIFICATION_CATEGORIES = 'notificationCategories';
export const OBJECT_STRUCTURE_TYPES = 'objectStructures';
export const PARKING_TYPES = 'parkingTypes';
export const PARKINGS = 'parkings';
export const PASS_TYPES = 'passTypes';
export const POSITIONS = 'positions';
export const REQUEST_STATUSES = 'requestStatuses';
export const REQUEST_TYPES = 'requestTypes';
export const REQUESTS = 'requests';
export const REQUEST_PRIORITIES = 'requestPriorities';
export const RESERVATION_TIMES = 'reservationTimes';
export const ROLES = 'roles';
export const SERVICES = 'services';
export const TIMES = 'times';
export const USERS = 'users';
export const VEHICLE_TYPES = 'vehicleTypes';
export const VISIT_TIMES = 'visitTimes';
export const VISITOR_LOGS = 'visitorLog';
export const VOZDVIZHENKA_ACCESS_LEVELS = 'vozdvizhenkaAccessLevels';
export const WORK_TYPES = 'workTypes';
export const WORKING_TIMES = 'workingTimes';
export const WORKING_HOURS = 'workingHours';
export const CONTACTS = 'contacts';
export const FIRED = 'fired';
export const JOB_DOINGS = 'jobDoings';
export const PROJECTS = 'projects';
export const ESTIMATE_ITEMS = 'estimateItems';

export const TASKS = 'tasks';
export const NUMBER = 'number';
export const FINISH_DATE = 'finishDate';
export const REGISTRATION_DATE = 'registrationDate';
export const DESCRIPTION = 'description';
export const AUTHOR = 'author';
export const RESPONSIBLE = 'responsible';
export const STATUS = 'status';
export const COMPANY = 'company';
export const FILE = 'file';
export const TASK_STATUSES = 'taskStatuses';
export const NAME = 'name';
export const COMMENT = 'comment';
export const TIME = 'time';
export const COUNT_ATTACHMENTS = 'countAttachments';
export const TASK_CHANGE_DATE = 'taskChangeDate';
export const LAST_ACTION = 'lastAction';
export const TASK_TYPE = 'type';
export const TASK_TYPES = 'taskTypes';
export const TASK_DOCUMENT = 'document';

export const WORKS = 'works';
export const WORK_RUN_DATE = 'runDate';
export const WORK_DESCRIPTION = 'description';
export const WORK_AUTHOR = 'author';
export const WORK_TASK = 'task';
export const WORK_DURATION = 'duration';

export const TASK_CHANGES = 'taskChanges';
export const REQUEST_CHANGES = 'requestChanges';
export const DOCUMENT_CHANGES = 'documentChanges';
export const DOCUMENT_POSITIONS = 'documentPositions';
export const GOODS_AND_SERVICES = 'goodsAndServices';
export const DOCUMENT_POSITION_STATES = 'documentPositionStates';
export const PARENT_DOCUMENTS = 'parentDocument';
export const REQUEST = 'request';
export const SUPPLIER_INVOICES = 'supplierInvoices';
