/*
 *
 * AlertDialog actions
 *
 */

import { OPEN_ALERT_DIALOG, CLOSE_ALERT_DIALOG, CONFIRM_ALERT_DIALOG } from './constants';

export function openAlertDialog(title, content, type) {
  return {
    type: OPEN_ALERT_DIALOG,
    payload: { title, content, type },
  };
}

export function closeAlertDialog() {
  return {
    type: CLOSE_ALERT_DIALOG,
  };
}

export function confirmAlertDialog() {
  return {
    type: CONFIRM_ALERT_DIALOG,
  };
}
