/**
 *
 * SightingForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import * as DocumentState from 'domain/Data/DocumentState/constants';
import { SIGHTING_DOCUMENTS_FORM } from './constants';
import { validateWorkTimeNumber } from 'utils/validators'
import { DOCUMENTS } from 'domain/Data/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { formField } from 'domain/typeConstants/form';

class SightingForm extends React.PureComponent {
  getFields() {
    const currentDate = new Date();
    const { viewName, mode } = this.props;
    if (viewName === 'resultOfAgreement') {
      return [
        {
          name: 'document',
          type: 'select',
          entityName: 'documents',
          label: 'Номер документа',
          fieldName: 'number',
        },
        {
          name: 'documentCreationDate',
          type: 'date',
          label: 'Дата документа',
        },
        {
          name: 'date',
          label: 'Дата визирования',
          type: 'date',
        },
        {
          name: 'company',
          label: 'Контрагент',
        },
        {
          name: 'content',
          label: 'Содержание',
        },
        {
          name: 'author',
          label: 'Чья виза',
          entityName: 'employees',
          type: 'select',
          calculatedValue: (currentValues) => currentValues.author,
        },
        {
          name: 'agreed',
          label: 'Решение',
        },
        {
          name: 'comment',
          label: 'Комментарий',
        },
      ]
    }
    else
      return [
        {
          name: 'author',
          visible: false,
          initialValue: (values, mode, user) => user.id,
        },
        {
          name: 'number',
          label: 'Номер документа',
          visible: (currentValues, initialValues, mode) => mode === 'view' || mode === 'add' || mode === 'edit',
          grid: { sm: 2 },
        },
        {
          name: 'actualCreationDate',
          label: 'Дата документа',
          type: formField.DATE,
          visible: true,
          initialValue: (values, mode) => {
            if (mode === 'add') {
              return currentDate;
            }
            return values.actualCreationDate;
          },
          grid: { sm: 2 },
        },
        {
          name: 'category',
          type: formField.SELECT,
          label: 'Тип документа',
          entityName: 'documentCategories',
          visible: true,
          grid: { sm: 4 },
        },
        {
          name: 'state',
          type: formField.SELECT,
          label: 'Состояние',
          entityName: 'documentStates',
          visible: true,
          initialValue: (values, mode) => {
            if (mode === 'add') return DocumentState.ON_AGREEMENT;
            return values.state;
          },
          grid: { sm: 4 },
        },

        {
          name: 'responsible',
          type: formField.COMBOBOX,
          label: 'Ответственный',
          entityName: 'employees',
          visible: true,
          initialValue: (values, mode, user) => user.id,
          grid: { sm: 4 },
        },
        {
          name: 'company',
          type: formField.COMBOBOX,
          label: 'Контрагент',
          entityName: 'companies',
          visible: true,
          numberInQueueFromEnd: 1,
          initialValue: (values, mode, user) => user.companyId,
          grid: { sm: 4 },
        },
        {
          name: 'agreementScheme',
          type: formField.SELECT,
          entityName: 'agreementSchemes',
          label: 'Маршрут',
          visible: true,
          grid: { sm: 4 },
        },
        {
          name: 'amount',
          label: 'Сумма',
          visible: true,
          validate: validateWorkTimeNumber,
          normalize: (value) => value ? value.replace('.', ',') : value,
          grid: { sm: 4 },
        },
        {
          name: 'scan',
          label: 'Скан оригинала',
          type: formField.FILE,
          visible: true,
          grid: { sm: 4 },
        },
        {
          name: 'file',
          label: 'Файл',
          type: formField.FILE,
          visible: true,
          grid: { sm: 4 },
        },
        {
          name: 'content',
          label: 'Содержание',
          visible: true,
          type: formField.EDITOR,
          disabled: (mode === 'view'),
          grid: { sm: 12 },
        },
        {
          name: 'note',
          label: 'Примечание',
          visible: true,
          grid: { sm: 12 },
        },
      ];
  }

  // getGroups() {
  //   return [
  //     {
  //       name: 'group1',
  //       title: 'Общая информация',
  //       expand: true,
  //       type: 'fields',
  //       items: ['author', 'number', 'actualCreationDate', 'category',
  //         'state', 'responsible', 'company', 'agreementScheme',
  //         'amount', 'scan', 'file',
  //       ]
  //     },
  //     {
  //       name: 'group2',
  //       title: 'Содержание',
  //       expand: true,
  //       type: 'fields',
  //       items: ['content', 'note']
  //     },
  //     {
  //       name: 'group3',
  //       title: 'Детали',
  //       expand: true,
  //       type: 'detailContainer',
  //     }
  //   ]
  // }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    const { viewName } = this.props;
    return (
      <AdvancedForm
        name={SIGHTING_DOCUMENTS_FORM}
        entityName={viewName === 'resultOfAgreement' ? 'agreementHistories' : DOCUMENTS}
        fields={this.getFields()}
        classNames={this.getClassNames()}
        accountId={accountId}
        // groups={this.getGroups()}
        {...this.props}
      />
    );
  }
}

export default SightingForm

