/**
*
* SelectCheckBox
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InitialTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';

const styles = () => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },

  formLabel: {
    width: '100%',
  },

  menu: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },

  paper: {
    width: '100%',
    position: 'absolute',
    zIndex: 1000,
    height: '200px',
  },

  menuItem: {
    overflow: "hidden",
    boxSizing: "content-box",
    whiteSpace: 'nowrap',
    paddingLeft: '16px',
    textOverflow: 'ellipsis',
    paddingRight: '16px',
    listStyleType: 'none',
    '&:hover ': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },

  menuLabel: {
    width: '100%',
    fontSize: "1rem",
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '3em',
  }

});

const ROW_ITEM_HEIGHT = 50
const VISIBLE_ROWS = 4

class SelectCheckBox extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      openList: false,
      heightList: 200,
      mouseOverList: false,
      optionList: [],
      checkedAll: false,
    };
    this.timeId = null;
  }

  componentWillMount() {
    this.timeId = setTimeout(() => {
      const { options, input } = this.props;
      if (options && input) {
        const valueList = [];
        const optionList = options.map((option, idx) => {
          if (input.value.includes(option.text)) {
            valueList.push(option.text)
          }
          return {
            key: idx,
            label: option.text,
            checked: input.value.includes(option.text),
          }
        });
        this.setState({ optionList })
        this.setState({ value: valueList.join(', ') })
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeId)
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ openList: !this.state.openList });
    }
  }

  handleClick = () => {
    if (this.state.openList === false) {
      const height = this.state.optionList.length < VISIBLE_ROWS
        ? ROW_ITEM_HEIGHT * this.state.optionList.length
        : ROW_ITEM_HEIGHT * VISIBLE_ROWS

      this.setState({
        heightList: height
      })
    }
    this.setState({
      openList: !this.state.openList
    })
  }

  handleBlur = () => { this.state.mouseOverList && this.setState({ openList: false }) };
  handleOver = () => { this.setState({ mouseOverList: true }) }
  handleOut = () => { this.setState({ mouseOverList: false }) }

  handleCheckedAll = () => {
    const checkedAll = !this.state.checkedAll
    const valueList = [];
    const newOptionList = [...this.state.optionList]

    newOptionList.map(option => {
      option.checked = checkedAll
      if (option.checked === true) {
        valueList.push(option.label)
      }
      return option
    })

    const value = valueList.join(', ');
    this.setState({
      optionList: newOptionList,
      value,
      checkedAll
    })

    this.props.input.onChange(value);
  }

  handleChecked = (e) => {
    const label = e.target.name;
    const valueList = [];
    const newOptionList = [...this.state.optionList]

    newOptionList.map(option => {
      if (option.label == label) {
        option.checked = !option.checked
      }
      return option
    })

    newOptionList.forEach(option => option.checked === true && valueList.push(option.label));
    const value = valueList.join(', ');

    this.setState({
      optionList: newOptionList,
      value,
    })
    this.props.input.onChange(value);
  }

  render() {
    const {
      classes,
      label,
      required,
    } = this.props;

    const getHeight = Object.assign({}, { height: `${this.state.heightList}px` }, styles);

    return (
      <div className={classes.container} >
        <div>
          <InitialTextField
            margin="none"
            label={required ? `${label} *` : label}
            value={this.state.value}
            fullWidth={true}
            onKeyPress={this.handleKeyPress}
            onClick={this.handleClick}
            onBlur={this.handleBlur}
            disabled={this.props.disabled}
            InputProps={{
              classes: {
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownIcon />
                </InputAdornment>
              ),
            }}
          />

        </div>
        <Paper
          className={classes.paper}
          square
          hidden={!this.state.openList}
          style={getHeight}
        >
          <div className={classes.menu}
            display={this.state.openList ? 'block' : 'none'}
            name="menu"
            onMouseOut={this.handleOver}
            onMouseOver={this.handleOut}
          >
            <FormGroup row className={classes.menuItem}>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Checkbox
                    checked={this.state.checkedAll}
                    onChange={this.handleCheckedAll}
                    name="checkedAll"
                    color="primary"
                  />}
                label={"Все"}
                classes={{ label: classNames(classes.menuLabel) }}
              />
            </FormGroup>
            {this.state.optionList && this.state.optionList
              .map(option => (
                <FormGroup row
                  key={option.key}
                  className={classes.menuItem}
                >
                  <FormControlLabel
                    className={classes.formLabel}
                    control={
                      <Checkbox
                        checked={option.checked}
                        onChange={(e) => this.handleChecked(e)}
                        name={option.label}
                        color="primary"
                        key={option.key}
                      />}
                    label={option.label}
                    classes={{ label: classNames(classes.menuLabel) }}
                  />
                </FormGroup>
              ))
            }
          </div>
        </Paper>
      </div >
    );
  }
}

SelectCheckBox.propTypes = {
  classNames: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  touched: PropTypes.bool,
};

SelectCheckBox.defaultProps = {
  classNames: {},
};

export default withStyles(styles)(SelectCheckBox);