/**
 *
 * AdministratorBC Service Requests DxGrid 15755
 *
 */

import { ServiceRequestsManagerDxGrid } from 'pages/Requests/ServiceRequestsManagerView';
import { requestColumnSizes } from 'domain/typeConstants/table';


export class AdministratorBCServiceRequestsDxGrid15755 extends ServiceRequestsManagerDxGrid {

  getColumns() {
    const columns = super.getColumns();

    const blackListStyle = {
      style: { color: '#d50000' },
      condition: (value) => value.isBlackList,
    };
    const styles = [blackListStyle];


    columns.push({
      path: ['type', 'shortName'],
      name: 'typeShortName',
      getCellValue: (row) => (row.type ? row.type.shortName : undefined),
      title: 'Вид',
      entityName: 'requestTypes',
      width: requestColumnSizes.typeShortName,
      styles,
    });

    return columns;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();

    const statusNameIndex = columnOrder.findIndex((columnName) => columnName === 'statusName');
    columnOrder.splice(statusNameIndex + 1, 0, 'typeShortName');

    return columnOrder;
  }
}
