/*
 *
 * RequestStatus constants
 *
 */

export const ACCEPTED = '12884953000';
export const CLOSED = '4285216000';
export const COMPLETED = '421575453000';
export const CREATED = '4285215000';
export const IN_PROGRESS = '2237210852000';
export const ON_TERRITORY = '421575460000';
export const REJECTED = '421575459000';
export const ON_AGREEMENT = '3736492009000';
export const ON_AGREEMENT_BY_TENANT = '4092397887000';
export const PASS_ISSUED = '2804833187000';
export const NO_PLACES = '4893691388000';
export const PASS_MADE = '4898866951000';
export const SEND_TO_TENANT = '4898866743000';
export const PASS_RETURNED = '4898866118000';
export const PASS_NOT_RETURNED = '4898866120000';
	
/* ------------------- Gorkiy park BC 14720 ------------------- */
export const AGREED_ESTIMATION_COST = '4419350520000';

/* ------------------- Khimki BC 14326 ------------------- */
export const WAIT_S3 = '4417117407000';
export const SEND_TO_PERFORMER = "1812442996000";
