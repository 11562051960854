/*
 *
 * LoginForm constants
 *
 */

export const SUBMIT_LOGIN_FORM = 'claris-vnext/LoginForm/SUBMIT_LOGIN_FORM';
export const FORGOT_PASSWORD_LOGIN_FORM = 'claris-vnext/LoginForm/FORGOT_PASSWORD_LOGIN_FORM';
export const SET_MESSAGE_FORM = 'claris-vnext/LoginForm/SET_MESSAGE_FORM';

