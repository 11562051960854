/**
 *
 * AdvancedDataTable
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import DataTableToolbar from 'components/DataTableToolbar';
import DataTable from 'components/DataTable';
import DataTablePagination from 'components/DataTablePagination';
import TableModalActionMenu from 'containers/AdvancedDataTable/TableModalActionMenu';
import Drawer from 'components/Drawer';
import {SETTINGS_CONFIG_NAME} from './constants';
import {settings} from 'containers/AdvancedDataTable/dxGridTypes';
import {getViewComponent} from 'components/DocumentViewer/getViewComponent';

class AdvancedDataTable extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionMenuRecord: null,
            isOpenActionMenuDialog: false,
            menuAnchorPositionLeft: null,
            menuAnchorPositionTop: null,
        };

        this.container = React.createRef();
        this.updateContainerWidth = this.updateContainerWidth.bind(this);
        this.onSignalRMessage = this.onSignalRMessage.bind(this);
    }

    componentDidMount() {
        this.updateContainerWidth();
        window.addEventListener('resize', this.updateContainerWidth);
        window.addEventListener('signalRMessage', this.onSignalRMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateVirtualTableHeight);
        window.removeEventListener('signalRMessage', this.onSignalRMessage);
    }

    onSignalRMessage(e) {
        const {entityName, loadData, loadUnreadNotificationsCount} = this.props
        if (entityName == e.detail && loadData) loadData();
        if (loadUnreadNotificationsCount) loadUnreadNotificationsCount();
    };

    updateContainerWidth() {
        const container = this.container.current?.getBoundingClientRect();
        const {setContainerWidth} = this.props
        if (setContainerWidth && container) setContainerWidth(Math.floor(container.width))
    };

    handlePageSizeChange = (pageSize) => {
        const {
            sorting,
            filtering,
            onLoadData,
            parent,
            parentId,
            mode,
            dialogName,
            settingsConfig,
            onChangeSettingsConfig
        } = this.props;
        settingsConfig[settings.PAGE_SIZE].value = pageSize;
        if (onChangeSettingsConfig) onChangeSettingsConfig(settingsConfig);
        if (onLoadData) onLoadData({pageSize, pageNumber: 1, sorting, filtering, mode, parent, parentId, dialogName,});
    }

    handlePageNumberChange = (pageNumber) => {
        const {pageSize, sorting, filtering, onLoadData, parent, parentId, mode, dialogName,} = this.props;
        if (onLoadData) onLoadData({pageSize, pageNumber, sorting, filtering, mode, parent, parentId, dialogName,});
    }

    handleSortingChange = (sorting) => {
        const {pageSize, pageNumber, filtering, onLoadData, parent, parentId, mode, dialogName,} = this.props;
        if (onLoadData) onLoadData({pageSize, pageNumber, sorting, filtering, mode, parent, parentId, dialogName,});
    }

    handleSearch = (searchQuery) => {
        const {pageSize, sorting, pageNumber, filtering, onLoadData, parent, parentId, mode, dialogName,} = this.props;
        if (onLoadData) onLoadData({
            searchQuery,
            pageSize,
            pageNumber,
            sorting,
            filtering,
            mode,
            parent,
            parentId,
            dialogName,
        });
    }

    handleClearSearch = () => {
        const {
            clearSearchQuery,
            pageSize,
            sorting,
            pageNumber,
            filtering,
            parent,
            parentId,
            mode,
            dialogName,
        } = this.props
        if (clearSearchQuery) clearSearchQuery({
            pageSize,
            sorting,
            pageNumber,
            filtering,
            parent,
            parentId,
            mode,
            dialogName,
        })
    }

    handleUpdateSearch = () => {
        const {searchQuery, sorting, pageSize, filtering, onLoadData, parent, parentId, mode, dialogName,} = this.props;
        if (onLoadData) onLoadData({
            searchQuery,
            pageSize,
            pageNumber: 1,
            sorting,
            filtering,
            mode,
            parent,
            parentId,
            dialogName,
        });
    }

    handleFilterAccept = (gridFilters) => {
        const {
            changeGridFilters,
            searchQuery,
            pageSize,
            sorting,
            pageNumber,
            filtering,
            parent,
            parentId,
            mode,
            dialogName,
        } = this.props;
        if (changeGridFilters) changeGridFilters({
            gridFilters,
            searchQuery,
            pageSize,
            pageNumber,
            sorting,
            filtering,
            mode,
            parent,
            parentId,
            dialogName,
        });
    }

    handleClearFilters = (gridFilters) => {
        const {
            changeGridFilters,
            searchQuery,
            pageSize,
            sorting,
            pageNumber,
            filtering,
            parent,
            parentId,
            mode,
            dialogName,
        } = this.props;
        if (changeGridFilters) changeGridFilters({
            gridFilters,
            searchQuery,
            pageSize,
            pageNumber,
            sorting,
            filtering,
            mode,
            parent,
            parentId,
            dialogName,
        });
    }

    handleSelectionChange = (selection) => {
        const {onSelectionChange} = this.props;
        if (onSelectionChange) onSelectionChange(selection);
    }

    handleActionButtonClick = (actionName) => {
        const {onActionButtonClick} = this.props;
        if (onActionButtonClick) onActionButtonClick(actionName);
    }

    handleActionMenuItemClick = (actionName, record) => {
        const {onActionMenuItemClick} = this.props;
        if (onActionMenuItemClick) onActionMenuItemClick(actionName, record);
    }

    handleContextMenu = (event, record) => {
        event.preventDefault();
        const {onMenuItemChange, onRowClick} = this.props;
        if (onMenuItemChange) {
            onMenuItemChange([record.id])
        }
        if (onRowClick) {
            onRowClick(record)
        }

        this.setState({
            actionMenuRecord: record,
            isOpenActionMenuDialog: true,
            menuAnchorPositionLeft: event.clientX,
            menuAnchorPositionTop: event.clientY,
        });
    }

    handleCloseActionMenuDialog = () => {
        this.setState(() => ({isOpenActionMenuDialog: false}));
    };

    calculateTableHeight = () => {
        const {height, settingsConfig, dataTableName} = this.props
        const pagingHeight = settingsConfig.tableType === 'pagingTable' ? 32 : 0
        const toolbarHeight = dataTableName !== "Journal" ? 48 : 0
        return height - toolbarHeight - pagingHeight
    }

    handleCloseDrawer = () => {
        const {openPreviewDrawer} = this.props;
        if (openPreviewDrawer) openPreviewDrawer(false, null)
    };

    handleOpenDrawer = (drawerRecord) => {
        const {getDownloadLink, filePreviewFieldName, openPreviewDrawer} = this.props;
        if (getDownloadLink && drawerRecord && drawerRecord[filePreviewFieldName]) {
            openPreviewDrawer(true, drawerRecord)
            getDownloadLink(drawerRecord[filePreviewFieldName].id)
        }
        ;
    };

    handleRowClick = (record) => {
        const {
            changeCurrentRecord,
            getDownloadLink,
            filePreviewFieldName,
            openPreviewDrawer,
            isOpenDrawer,
            viewName
        } = this.props
        if (changeCurrentRecord) changeCurrentRecord(record);
        if (viewName === 'forMyVisa' && getDownloadLink && record && record[filePreviewFieldName]) {
            openPreviewDrawer(true, record)
            getDownloadLink(record[filePreviewFieldName].id)
        } else {
            openPreviewDrawer(false, null)
        }
    }


    render() {
        const {
            accessKey,
            dataTableName,
            selectable,
            multiSelectable,
            tableFeatures,
            pageSizes,
            actionButtons,
            actionMenuItems,
            columns,
            data,
            selection,
            sorting,
            pageSize,
            pageNumber,
            totalCount,
            columnOrder,
            columnWidths,
            expandedRowIds,
            loaderState,
            detailContainer,
            onColumnOrderChange,
            onColumnWidthChange,
            onExpandedRowIdsChange,
            onRowClick,
            onRowDoubleClick,
            onMenuItemChange,
            currentRecordId,
            gridFilters,
            clearFilterDialog,
            savedFilters,
            localFilters,
            filterDialog,
            loadData,
            viewName,
            settingsConfig,
            checkTableFeature,
            onChangeSettingsConfig,
            onChangeResetSettings,
            clearEntity,
            onColumnVisibleChange,
            columnVisibilityNames,
            downloadFile,
            mode,
            tab,
            changeTab,
            containerWidth,
            fileExtension,
            downloadLink,
            clearDownloadLink,
            user,
            filePreviewFieldName,
            isOpenDrawer,
            drawerRecord,
            updateTerminalAdvancedInput,
            viewMode,
        } = this.props;

        const {
            actionMenuRecord,
            isOpenActionMenuDialog,
            menuAnchorPositionLeft,
            menuAnchorPositionTop,
        } = this.state;

        return (
            <div ref={this.container}>
                <DataTableToolbar
                    accessKey={accessKey}
                    viewName={viewName}
                    actionButtons={actionButtons}
                    onActionButtonClick={this.handleActionButtonClick}
                    onSearch={this.handleSearch}
                    onClearSearch={this.handleClearSearch}
                    onUpdateSearch={this.handleUpdateSearch}
                    onFilterAccept={this.handleFilterAccept}
                    onClearFilters={this.handleClearFilters}
                    columnOrder={columnOrder}
                    columns={columns}
                    tableFeatures={tableFeatures}
                    gridFilters={gridFilters}
                    clearFilterDialog={clearFilterDialog}
                    savedFilters={savedFilters}
                    localFilters={localFilters}
                    filterDialog={filterDialog}
                    loadData={loadData}
                    configName={SETTINGS_CONFIG_NAME}
                    settingsConfig={settingsConfig}
                    checkTableFeature={checkTableFeature}
                    onChangeSettingsConfig={onChangeSettingsConfig}
                    onChangeResetSettings={onChangeResetSettings}
                    clearEntity={clearEntity}
                    onColumnVisibleChange={onColumnVisibleChange}
                    columnVisibilityNames={columnVisibilityNames}
                    mode={mode}
                    user={user}
                    updateTerminalAdvancedInput={updateTerminalAdvancedInput}
                    viewMode={viewMode}
                />
                <DataTable
                    dataTableName={dataTableName}
                    selectable={selectable}
                    multiSelectable={multiSelectable}
                    tableFeatures={tableFeatures}
                    actionMenuItems={actionMenuItems}
                    gridFilters={gridFilters}
                    columns={columns}
                    data={data}
                    selection={selection}
                    sorting={sorting}
                    columnOrder={columnOrder}
                    columnWidths={columnWidths}
                    columnVisibilityNames={columnVisibilityNames}
                    expandedRowIds={expandedRowIds}
                    loaderState={loaderState}
                    detailContainer={detailContainer}
                    currentRecordId={currentRecordId}
                    settingsConfig={settingsConfig}
                    totalCount={totalCount}
                    onRowClick={this.handleRowClick}
                    onRowDoubleClick={onRowDoubleClick}
                    onRowContextMenuClick={this.handleContextMenu}
                    onSortingChange={this.handleSortingChange}
                    onSelectionChange={this.handleSelectionChange}
                    onActionMenuItemClick={this.handleActionMenuItemClick}
                    onOpenDrawer={this.handleOpenDrawer}
                    onCloseDrawer={this.handleCloseDrawer}
                    onColumnOrderChange={onColumnOrderChange}
                    onColumnWidthChange={onColumnWidthChange}
                    onExpandedRowIdsChange={onExpandedRowIdsChange}
                    onMenuItemChange={onMenuItemChange}
                    downloadFile={downloadFile}
                    loadData={loadData}
                    mode={mode}
                    height={this.calculateTableHeight()}
                    tab={tab}
                    changeTab={changeTab}
                    containerWidth={containerWidth}
                    filePreviewFieldName={filePreviewFieldName}
                />
                {settingsConfig.tableType === settings.PAGING_TABLE &&
                    <DataTablePagination
                        pageSizes={pageSizes}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalCount={totalCount}
                        onPageSizeChange={this.handlePageSizeChange}
                        onPageNumberChange={this.handlePageNumberChange}
                    />
                }
                {settingsConfig[settings.TABLE_ACTION_MENU].enabled &&
                    <TableModalActionMenu
                        anchorElLeftPosition={menuAnchorPositionLeft}
                        anchorElTopPosition={menuAnchorPositionTop}
                        dense
                        items={actionMenuItems}
                        onActionMenuItemClick={this.handleActionMenuItemClick}
                        onClose={this.handleCloseActionMenuDialog}
                        open={isOpenActionMenuDialog}
                        row={actionMenuRecord}
                        onContextMenu={this.handleCloseActionMenuDialog}
                    />
                }
                {settingsConfig[settings.TABLE_DRAWER].enabled &&
                    <Drawer
                        open={isOpenDrawer}
                        row={drawerRecord}
                        viewName={viewName}
                        onClose={this.handleCloseDrawer}
                        viewComponent={getViewComponent({
                            type: fileExtension,
                            data: {
                                value: downloadLink,
                                clearDownloadLink: clearDownloadLink,
                                getValue: (value) => value, // optional
                            },
                        })}
                    />
                }
            </div>
        );
    }
}

AdvancedDataTable.propTypes = {
    selectable: PropTypes.bool,
    multiSelectable: PropTypes.bool,
    actionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        })
    ),
    actionMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.string,
        })
    ),
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            path: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string),
            ]).isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.string,
            labels: PropTypes.arrayOf(PropTypes.object),
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    dense: PropTypes.bool,
    selection: PropTypes.arrayOf(PropTypes.object),
    sorting: PropTypes.arrayOf(
        PropTypes.shape({
            columnId: PropTypes.string.isRequired,
            direction: PropTypes.string.isRequired,
        })
    ),
    filtering: PropTypes.string,
    pageSize: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    onRequestLoadData: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onPageNumberChange: PropTypes.func,
    onSortingChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onMenuItemChange: PropTypes.func,
    onRequestSearch: PropTypes.func,
    onRequestClearSearch: PropTypes.func,
    onRequestRefresh: PropTypes.func,
    onActionButtonClick: PropTypes.func,
    onActionMenuItemClick: PropTypes.func,
};

export default AdvancedDataTable;
