/**
 *
 * Navigation reducer constants
 *
 */

export const LOAD_NAVIGATION_ITEMS = 'claris-vnext/Navigation/LOAD_NAVIGATION_ITEMS';

export const REQUESTS_PATH = '/requests';
export const SERVICE_REQUESTS_PATH = '/serviceRequests';
export const DOCUMENTS_PATH = '/documents';
export const RULES_PATH = '/rules';
export const NOTIFICATIONS_PATH = '/notifications';
export const PARKINGS_PATH = '/parkings';
export const VISITOR_LOGS_PATH = '/visitorLogs';
export const UNBINDING_ACCESS_CARD_PATH = '/unbindingAccessCard';
export const SETTINGS_PATH = '/settings';
export const CONTACTS_PATH = '/contacts';
export const COMPANIES_PATH = '/companies';

export const REQUESTS_ID = 'requests';
export const SERVICE_REQUESTS_ID = 'serviceRequests';
export const DOCUMENTS_ID = 'documents';
export const RULES_ID = 'rules';
export const NOTIFICATIONS_ID = 'notifications';
export const PARKINGS_ID = 'parkings';
export const VISITOR_LOGS_ID = 'visitorLogs';
export const UNBINDING_ACCESS_CARD_ID = 'unbindingAccessCard';
export const SETTINGS_ID = 'settings';
export const CONTACTS_ID = 'contacts';
export const COMPANIES_ID = 'companies';

export const TASK_ID = 'tasks';
export const TASKS_PATH = '/tasks';
export const RESTORE_PASSWORD_PATH = '/recoveryPassword/resp';
