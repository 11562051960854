/**
*
* SelectTextFilterContainer
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SelectTextFilterEditor from './SelectTextFilterEditor'
import { makeSelectFilterEntity } from 'domain/Data/selectors';

function createSelectTextFilterContainer(entityName) {
  class SelectTextFilterContainer extends React.PureComponent {

    render() {
      const {
        onChange,
        entity,
        filter,
      } = this.props;

      return (
        <SelectTextFilterEditor
          onChange={onChange}
          entity={entity}
          filter={filter}
        />
      );
    }
  }

  const mapStateToProps = createStructuredSelector({
    entity: makeSelectFilterEntity(entityName),
  })

  return connect(mapStateToProps, null)(SelectTextFilterContainer);
}

export default createSelectTextFilterContainer;
