/**
*
* LabeledTableCell
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const styleSheet = {
  label: {
    padding: '0px 8px',
    borderRadius: 3,
    fontWeight: 500,
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 0.7,
  },
  cell: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: 8,
    paddingRight: 8,
  }
};

class LabeledTableCell extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { children, classes, onClick, views, onContextMenu } = this.props;
    const foundView = views.find((view) => view.condition(children));

    return (
      <TableCell
        onClick={onClick}
        onContextMenu={onContextMenu}
        className={classes.cell}
      >
        <span
          className={classes.label}
          style={foundView && {
            backgroundColor: foundView.backgroundColor,
            color: foundView.fontColor,
          }}
        >
          {children}
        </span>
      </TableCell>
    );
  }
}

LabeledTableCell.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  views: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styleSheet)(LabeledTableCell);
