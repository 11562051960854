/*
 *
 * Companies Page Containers
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import queryString from 'query-string';

import { makeSelectBreakpoint } from 'domain/Screen/selectors';
import { makeSelectRoles, makeSelectAccountId } from 'domain/User/selectors';

import getCompaniesPage from './getCompaniesPage'
import saga from './sagas';

export class CompaniesPageContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render() {
        const { accountId, userRoles, ...restProps } = this.props;
        const { view: viewName = 'all' } = queryString.parse(this.props.location.search);
        const props = { viewName, userRoles, ...restProps };

        return getCompaniesPage(accountId, props);
    }
}

CompaniesPageContainer.propTypes = {
    location: PropTypes.object.isRequired,
    accountId: PropTypes.string,
    userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = createStructuredSelector({
    accountId: makeSelectAccountId(),
    userRoles: makeSelectRoles(),
    breakpoint: makeSelectBreakpoint(),
});

const reducer = (state = {}) => state;
const withReducer = injectReducer({ key: 'companyPage', reducer });
const withSaga = injectSaga({ key: 'companyPage', saga });
const withConnect = connect(mapStateToProps);

export default compose(
    withReducer,
    withSaga,
    withConnect,
)(CompaniesPageContainer);
