/**
 *
 * ActionButton Items
 *
 */

import React from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Create from '@material-ui/icons/Create';
import FindInPage from '@material-ui/icons/FindInPage';
import { requestAction } from 'domain/typeConstants/action';
import { documentStatus } from 'domain/typeConstants/status';


export const addActionButton = {
  name: requestAction.ADD,
  label: 'Добавить',
  icon: 'add_circle_outline',
  svgIcon: <AddCircleOutline />,
};

export const editActionButton = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord || selection.length === 1) {
      let state;
      if (currentRecord) {
        state = currentRecord?.state?.name;
      } else state = entities[0].state?.name;
      if (!state) return false;
      return (state === documentStatus.ARCHIVED);
    }
    return true
  },
};

export const viewActionButton = {
  name: requestAction.VIEW,
  label: 'Просмотреть',
  icon: 'find_in_page',
  svgIcon: <FindInPage />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord || selection.length === 1) {
      let state;
      if (currentRecord) {
        state = currentRecord?.state?.name;
      } else state = entities[0].state?.name;
      if (!state) return false;
      return !(state === documentStatus.ARCHIVED);
    }
    return true;
  },
};
