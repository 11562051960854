/**
 *
 * SupplierInvoiceForm
 *
 * 
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import { SUPPLIER_INVOICE_FORM } from './constants';
import { DOCUMENTS } from 'domain/Data/constants';

class SupplierInvoiceForm extends React.PureComponent {

  getFields() {
    const currentDate = new Date();
    const mode = this.props.mode;
    const parentId = this.props.parentId;
    return [

      {
        name: 'name',
        label: 'Заявка',
      },
      {
        name: 'author',
        type: 'select',
        label: 'Автор',
        entityName: 'employees',
        initialValue: (values) => { return values.author.name },
        disabled: true,
      },
      {
        name: 'actualCreationDate',
        label: 'Дата',
        type: 'date',
        disabled: true,
        initialValue: (values, mode) => {
          if (mode === 'add') { return currentDate }
          return values.actualCreationDate;
        },
      },
      {
        name: 'objectStructure',
        label: 'Объект',
        type: 'select',
        entityName: 'objectStructures',
      },
      {
        name: 'contract',
        label: 'Договор',
        type: 'select',
        entityName: 'contracts',
      },

    ];
  }

  getClassNames() {
    return {
      helperText: '',
    };
  }

  render() {
    return (
      <AdvancedForm
        name={SUPPLIER_INVOICE_FORM}
        entityName={DOCUMENTS}
        classNames={this.getClassNames()}
        fields={this.getFields()}
        {...this.props}
      />
    );
  }
}

export default SupplierInvoiceForm;

