/**
*
* LoginFormSheet
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LoginForm from 'containers/LoginForm';

import { ACCOUNT_ID_KEY } from 'global-constants';
import { account } from 'domain/typeConstants/accounts';

import { getValueFromLocalStorage } from 'utils/localStorage';

import Logotype12970 from 'custom/12970/assets/Logotype12970';
import khimkiLogo from 'custom/14326/assets/khimki_logo.png';

const styleSheet = (theme) => ({
  header: {
    padding: '32px',
    backgroundColor: theme.palette.primary[400],
  },
  headerText: {
    color: theme.palette.common.white,
  },
  headerBody: {
    padding: 32,
  },
  form: {
    padding: '16px 0 0',
  },
  khimkiLogo: {
    height: 75,
    width: 70,
  },
  khimkiHeaderFontSize: {
    fontSize: 19,
  },
  paperBody: {
    backgroundColor: '#023972'
  }
});

const LoginFormTitle = ({ classes, title, subtitle }) => (
  <div>
    <Typography variant="headline" component="h3" className={classes.headerText}>
      {title}
    </Typography>
    {subtitle &&
      <Typography variant="body1" component="h3" className={classes.headerText}>
        {subtitle}
      </Typography>
    }
  </div>
);

LoginFormTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

function LoginFormSheet({ classes, className, nextPathAfterLogin }) {
  const accountId = getValueFromLocalStorage(ACCOUNT_ID_KEY);
  const headerStyles = {};

  if (accountId === account.COMCITY) {
    headerStyles.backgroundColor = '#EEE';
    headerStyles.padding = 24;
  }
  if (accountId === account.BC_KHIMKI) {
    headerStyles.backgroundColor = '#FFF';
    headerStyles.padding = 24;
    headerStyles.paddingBottom = 0;
  }
  if (accountId === account.KRYLATSKI_HOLMY || accountId === account.BC_BELIYE_SADY || accountId === account.BC_4_VETRA) {
    headerStyles.backgroundColor = '#023972';
    headerStyles.padding = 24;
    headerStyles.paddingBottom = 0;
  }

  const isKrylatskyGroup = [account.KRYLATSKI_HOLMY, account.BC_BELIYE_SADY, account.BC_4_VETRA].includes(accountId)

  return (
    <Paper className={classNames(className, isKrylatskyGroup && classes.paperBody)} elevation={4}>
      <div className={classes.header} style={headerStyles}>
        {(() => {
          if (accountId === account.COMCITY) {
            return (<Logotype12970 width={180} height={60} />);
          }

          if (accountId === account.BC_KHIMKI) {
            return <img src={khimkiLogo} alt="Логотип БЦ Химки" className={classes.khimkiLogo} />;
          }

          return (
            <LoginFormTitle
              classes={classes}
              title={isKrylatskyGroup ? 'Вход в систему' : "Кларис"}
              subtitle={isKrylatskyGroup ? "управление бизнес центром" : "Управление бизнес центрами"}
            />);
        })()}
      </div>

      <div className={classNames(classes.headerBody, isKrylatskyGroup && { paddingTop: '0' })}>
        {!isKrylatskyGroup &&
          < Typography
            variant="title"
            component="h3"
            className={classNames({
              [classes.khimkiHeaderFontSize]: accountId === account.BC_KHIMKI,
            })}
          >
            {
              accountId === account.BC_KHIMKI
                ? 'Вход в портал Химки Бизнес Парк'
                : 'Вход в систему'
            }
          </Typography>
        }

        <LoginForm
          className={classes.form}
          nextPathAfterLogin={nextPathAfterLogin}
          accountId={accountId}
        />
      </div>
    </Paper>
  );
}

LoginFormSheet.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  nextPathAfterLogin: PropTypes.string,
};

export default withStyles(styleSheet)(LoginFormSheet);
