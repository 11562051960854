/*
 *
 * Document constants
 *
 */


export const SEND_CARGO = 'claris-vnext/DocumentsPage/SupplyPositionView/SEND_CARGO';
export const CARGO_RECEIVE_SUCCESS = 'claris-vnext/DocumentsPage/SupplyPositionView/CARGO_RECEIVE_SUCCESS';
export const CARGO_RECEIVE_ERROR = 'claris-vnext/DocumentsPage/SupplyPositionView/CARGO_RECEIVE_ERROR';

export const DELIVERY_ORIGINALS = 'claris-vnext/DocumentsPage/SupplyPositionView/DELIVERY_ORIGINALS';
export const DELIVERED_ORIGINALS_SUCCESS = 'claris-vnext/DocumentsPage/SupplyPositionView/DELIVERED_ORIGINALS_SUCCESS';
export const DELIVERY_ORIGINALS_ERROR = 'claris-vnext/DocumentsPage/SupplyPositionView/DELIVERY_ORIGINALS_ERROR';
