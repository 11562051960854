import React from 'react';
import PropTypes from 'prop-types';

import TenantView, {
  TenantDxGrid,
  TenantMobileGrid,
} from '../TenantView';

const getDxGrid = (DxGrid) => (DxGrid || <TenantDxGrid />);

const getMobileGrid = (MobileGrid) => (MobileGrid || <TenantMobileGrid />);

const getDataTableView = ({ breakpoint, tenantDxGrid, tenantMobileGrid }) => {
  if (breakpoint.down('md')) {
    return getMobileGrid(tenantMobileGrid);
  }

  return getDxGrid(tenantDxGrid);
};

export const getTenantView = (props) => {
  const {
    breakpoint,
    tenantDxGrid,
    tenantMobileGrid,
    tenantView,
    viewName,
    viewStyles,
  } = props;

  if (tenantView) return tenantView;

  return (
    <TenantView
      key={viewName}
      viewName={viewName}
      viewStyles={viewStyles}
      dataTable={getDataTableView({
        breakpoint,
        tenantDxGrid,
        tenantMobileGrid,
      })}
    />
  );
};

getTenantView.propTypes = {
  breakpoint: PropTypes.object.isRequired,
  tenantDxGrid: PropTypes.node,
  tenantMobileGrid: PropTypes.node,
  tenantView: PropTypes.node,
  viewName: PropTypes.string,
  viewStyles: PropTypes.object,
};
