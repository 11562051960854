/*
 *
 * AdministratorBCRequestsMobileGrid 14326
 *
 */

import ReceptionMobileGrid from 'pages/Requests/ReceptionView/ReceptionMobileGrid'
import {
    CURRENT_DAY_REQUESTS,
    ON_CREATE_REQUESTS, ON_MATCHING_REQUESTS,
    REQUESTS_ON_CAR_ENTRY,
    REQUESTS_ON_VISIT,
    REQUESTS_ON_WORK_PRODUCTION,
} from "domain/Data/Filters/constants";
import {
    sendEmailActionMenuItem,
    sendToAgreementActionMenuItem,
    sendToAgreementByTenantActionMenuItem,
} from "../common/actionMenuItem";
import { requestAction } from "domain/typeConstants/action";

export class AdministratorBCRequestsMobileGrid14326 extends ReceptionMobileGrid {
    getFilters() {
        const { viewName } = this.props;
        const filters = [];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
        if (viewName === 'agree') filters.push(ON_MATCHING_REQUESTS);
        if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
        if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
        return filters;
    }

    getActionMenuItems() {
        const actionMenuItems = super.getActionMenuItems();

        const editIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.EDIT);
        actionMenuItems[editIndex].label = 'Открыть';
        actionMenuItems.splice(editIndex - 1, 0, sendToAgreementActionMenuItem, sendEmailActionMenuItem);

        const acceptIndex = actionMenuItems.findIndex((menuItem) => menuItem.name === requestAction.ACCEPT);
        actionMenuItems[acceptIndex].label = 'Принять/Отправить исполнителю';
        actionMenuItems.push(sendToAgreementByTenantActionMenuItem);

        return actionMenuItems;
    }

    getCustomGridFilters() {
        const gridFilters = super.getCustomGridFilters();

        const typeShortNameIndex = gridFilters.findIndex((gridFilter) => gridFilter.columnName === 'typeShortName');
        gridFilters[typeShortNameIndex].path = ['type', 'himkiName'];

        const statusNameIndex = gridFilters.findIndex((gridFilter) => gridFilter.columnName === 'statusName');
        gridFilters[statusNameIndex].path = ['status', 'alternativeName'];
        return gridFilters
    }

}
