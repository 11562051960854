/**
*
* DadataField
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { match, parse } from 'autosuggest-highlight';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
});

class DadataField extends React.Component {
  getSuggestionValue = (suggestion) => suggestion.text;

  handleSuggestionsFetchRequested = ({ value }) => this.props.loadSuggestions(value);

  handleSuggestionsClearRequested = () => this.props.clearSuggestions();

  handleSuggestionSelected = (event, { suggestion }) => {
    this.props.input.onChange(suggestion.value);
  };

  handleBlur = () => this.props.calculateFieldsValues();

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { readOnly } = this.props;

    if (readOnly) {
      return null;
    }

    const matches = match(suggestion.value, query);
    const parts = parse(suggestion.value, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {
            parts.map((part) => part.highlight ?
              (
                <span key={part.text} style={{ fontWeight: 300 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={part.text} style={{ fontWeight: 500 }}>
                  {part.text}
                </strong>
              )
            )
          }
        </div>
      </MenuItem>
    );
  };

  renderInput(inputProps) {
    const {
      autoComplete,
      classes,
      helperText, meta: { touched, error, submitFailed },
      label,
      ref,
      required,
      value,
      fileSize,
      fieldName,
      ...restProps
    } = inputProps;

    return (
      <TextField
        label={required ? `${label} *` : label}
        margin="none"
        autoComplete={autoComplete || 'off'}
        error={(touched || submitFailed) && !!error}
        helperText={(touched || submitFailed) && error ? error : helperText}
        value={value}
        inputRef={ref}
        {...restProps}
        InputProps={{
          readOnly: inputProps.readOnly,
          classes: {
            input: classes.input,
          },
        }}
      />
    );
  }

  render() {
    const {
      classes,
      input: { ...inputProps },
      clearSuggestions, // eslint-disable-line no-unused-vars
      loadSuggestions, // eslint-disable-line no-unused-vars
      calculateFieldsValues, // eslint-disable-line no-unused-vars
      suggestions,
      ...restProps
    } = this.props;

    return (
      <Autosuggest
        highlightFirstSuggestion
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          ...inputProps,
          ...restProps,
          classes,
          onBlur: this.handleBlur,
          value: this.props.input.value,
        }}
      />
    );
  }
}

DadataField.propTypes = {
  readOnly: PropTypes.bool,
  calculateFieldsValues: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  entity: PropTypes.object,
  input: PropTypes.object.isRequired,
  loadSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(DadataField);
