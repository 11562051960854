/**
 *
 * Common fields for BC ATC BD/GD(14140)
 *
 */

import React from 'react';
import {
  ACCESS_AREAS,
  DEPARTMENTS,
} from 'domain/Data/constants';
import {
  ON_CAR_ENTRY,
  ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { formField } from 'domain/typeConstants/form';
import { GROUND, UNDERGROUND } from '../../../domain/Data/ParkingType/constants';
import {
  BERLINSKY_HOUSE,
  GENEVSKY_HOUSE,
  ATC_BD_GD_HOUSE,
} from '../../../domain/Data/DepartmentType/constants';
import {
  MAKING_PASS_ON_THE_CAR,
  ON_LOSS_OR_BREAK_OF_PASS,
  ON_CHECK_CARD,
  ON_RETURN_CARDS,
} from '../../../domain/Data/RequestType/constants';
import {
  BROKEN,
  LOST,
  ON_RETURN,
} from '../../../domain/Data/IssueReasons/constants';

/** ---------------- type ---------------- */
export const typeHelperText = (values) => {
  if (values.type === ON_CAR_ENTRY && values.parking === UNDERGROUND) {
    return 'Гостевая';
  }
  if (values.type === ON_CAR_ENTRY && values.parking === GROUND) {
    return 'Разгрузка/Погрузка';
  }
  if (values.type === ON_CAR_ENTRY) {
    return 'Гостевая';
  }
  if (values.type === MAKING_PASS_ON_THE_CAR || values.type === ON_PASS_MAKING
    || (values.type === ON_LOSS_OR_BREAK_OF_PASS && values.issueReason !== ON_RETURN)) {
    return (
      <React.Fragment>
        Арендатор обязуется в течение 1 (одного) месяца компенсировать Арендодателю сумму в размере:<br />
        - 59 (пятьдесят девять) долларов США, включая НДС, за каждую утерянную/сломанную карту.<br />
        - 118 (сто восемнадцать) долларов США, включая НДС, за каждый утерянный/сломанный пульт.
      </React.Fragment>
    );
  }

  return '';
};

/** ---------------- visitorFullNameLabel ---------------- */
export const visitorFullNameLabel = (values) => {
  if (values.type === MAKING_PASS_ON_THE_CAR) {
    return 'ФИО';
  }

  return 'ФИО Посетителя';
};

/** ---------------- longterm ---------------- */
export const longtermVisible = (values) => {
  const excludedRequestTypes = new Set([SERVICE, MAKING_PASS_ON_THE_CAR, ON_LOSS_OR_BREAK_OF_PASS,
    ON_CHECK_CARD, ON_RETURN_CARDS, ON_PASS_MAKING]);
  return !excludedRequestTypes.has(values.type);
};

/** ---------------- visitDate ---------------- */
export const visitDateRowBreakBefore = (values) => new Set([ON_PASS_MAKING, MAKING_PASS_ON_THE_CAR,
  ON_LOSS_OR_BREAK_OF_PASS, ON_CHECK_CARD, ON_RETURN_CARDS]).has(values.type);

/** ---------------- documentType ---------------- */
const requestTypesToExcludeDocuments = new Set([ON_CAR_ENTRY, SERVICE, MAKING_PASS_ON_THE_CAR,
  ON_LOSS_OR_BREAK_OF_PASS, ON_RETURN_CARDS, ON_CHECK_CARD]);
export const documentTypeVisible = (values) => !requestTypesToExcludeDocuments.has(values.type) && !values.isGroupRequest;

/** ---------------- documentDetails ---------------- */
export const documentDetailsVisible = (values) => !requestTypesToExcludeDocuments.has(values.type);

/** ---------------- issueReason ---------------- */
export const issueReasonVisibleOptions = () => [BROKEN, LOST, ON_RETURN];
export const issueReasonVisible = (values) => values.type === ON_LOSS_OR_BREAK_OF_PASS;
export const issueReasonRequired = (values) => values.type === ON_LOSS_OR_BREAK_OF_PASS;

/** ---------------- nonStandardCarNumber ---------------- */
export const nonStandardCarNumberVisible = (values) => values.type === ON_CAR_ENTRY
  || values.type === MAKING_PASS_ON_THE_CAR;

/** ---------------- carNumber ---------------- */
export const carNumberHelperText = (values) => {
  if (values.type === MAKING_PASS_ON_THE_CAR) {
    return 'Предельная высота авто – 2,05 метра';
  }
  return '';
};

export const carNumberVisible = (values) => (values.type === ON_CAR_ENTRY
  || values.type === MAKING_PASS_ON_THE_CAR) &&
  !values.isGroupRequest;
export const carNumberRequired = (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest;

/** ---------------- carModelText ---------------- */
export const carModelTextVisible = (values) => (values.type === ON_CAR_ENTRY
  || values.type === MAKING_PASS_ON_THE_CAR) &&
  !values.isGroupRequest;

/** ---------------- parking ---------------- */
export const parkingVisible = (values) => values.type === ON_CAR_ENTRY
  || values.type === MAKING_PASS_ON_THE_CAR;
export const parkingHelperText = (values) => {
  if (values.type === ON_CAR_ENTRY && values.parking === GROUND) {
    return 'Разрешена стоянка не более 30 минут';
  } else if (values.type === ON_CAR_ENTRY && values.parking === UNDERGROUND) {
    return 'Не более 2,1м для заезда авто';
  }
  return '';
};

/** ---------------- parkingPlace ---------------- */
export const parkingPlaceVisible = (values) => {
  if (values.parking === GROUND) return false;
  return values.type === ON_CAR_ENTRY || values.type === MAKING_PASS_ON_THE_CAR;
};
export const parkingPlaceCalculatedValue = (currentValues) => {
  if (currentValues.parking === GROUND) return null;
  return currentValues.parkingPlace;
};
export const parkingPlaceRequired = (values) => values.parking === UNDERGROUND && values.isDiscretionOfSecurity === false;

/** ---------------- department ---------------- */
export const departmentHelperText = (values) => {
  if (values.type === ON_CAR_ENTRY && values.parking === GROUND && values.department === BERLINSKY_HOUSE) {
    return (
      <React.Fragment>
        Габаритные размеры грузового лифта: ширина 160см/длина 210см высота 230см.<br />
        Габаритные размеры входной двери в лифт: ширина 110см/ высота 200см.
      </React.Fragment>
    );
  } else if (values.type === ON_CAR_ENTRY && values.parking === GROUND && values.department === GENEVSKY_HOUSE) {
    return (
      <React.Fragment>
        Габаритные размеры грузового лифта: ширина 210см/длина 160см/высота 210см.<br />
        Габаритные размеры входной двери в лифт: ширина 130см/ высота 200см.
      </React.Fragment>
    );
  }

  return '';
};
export const departmentCalculatedValue = (values, mode, entities) => {
  if (entities && !values.department) {
    const company = entities.get('companies').get(values.company);
    return company && company.department ? company.department : values.department;
  }

  return values.department;
};

export const departmentVisibleOptions = (currentValues, initialValues, mode, user, entities) => {
  const departments = entities.get(DEPARTMENTS).valueSeq().toJS();

  if (departments) {
    return departments
      .filter((department) => department.id !== ATC_BD_GD_HOUSE)
      .map((department) => department.id);
  }

  return [];
};

/** ---------------- file ---------------- */
export const fileVisible = (values) => new Set([SERVICE, ON_PASS_MAKING, ON_LOSS_OR_BREAK_OF_PASS,
  ON_CHECK_CARD, MAKING_PASS_ON_THE_CAR, ON_PROPERTY_REMOVAL, ON_PROPERTY_BRINGING]).has(values.type);
export const fileLabel = (values) => {
  if (values.type === ON_PASS_MAKING) {
    return 'Файл (скан подписанной заявки)';
  }
  if (values.type === ON_LOSS_OR_BREAK_OF_PASS || values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL) {
    return 'Файл (скан подписанной заявки)';
  }

  return 'Файл';
};

/** ---------------- materialValuesData ---------------- */
export const materialValuesDataLabel = (values) => {
  if (values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL) {
    return 'Данные по материальным ценностям';
  }

  return 'Данные мат. ценностей';
};

/** ---------------- berlinskyAccessArea ---------------- */
export const berlinskyAccessArea = {
  type: formField.SELECT,
  entityName: ACCESS_AREAS,
  name: 'berlinskyAccessArea',
  label: 'Зона доступа',
  visible: () => true,
};

/** ---------------- berlinskyVisitTime ---------------- */
export const berlinskyVisitTimeLabel = (values) => {
  if (values.type === ON_CAR_ENTRY && values.parking === UNDERGROUND) {
    return 'Время стоянки';
  }
  if (values.type === ON_CAR_ENTRY && values.parking === GROUND) {
    return 'Время разгрузки/погрузки';
  }
  if (values.type === ON_CAR_ENTRY) {
    return 'Время стоянки';
  }
  if (values.type === ON_VISIT) {
    return 'Время посещения';
  }

  return 'Время разгрузки';
};

/** ---------------- PassNumber ---------------- */
export const passNumberVisible = (values) => new Set([ON_VISIT, ON_PASS_MAKING,
  ON_LOSS_OR_BREAK_OF_PASS, ON_CHECK_CARD, ON_RETURN_CARDS]).has(values.type);
