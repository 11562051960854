/**
*
* MaskFieldContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { createTextMask } from 'redux-form-input-masks';
import MaskField from './MaskField';
import TextField from '../TextField/TextField';

class MaskFieldContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  normalize = (value) => this.props.normalize ?
    this.props.normalize(value) || null :
    value || null;

  render() {
    const { clearErrorsOnChange, nonstandart, ...restProps } = this.props; // eslint-disable-line no-unused-vars

    const carNumberMask = !nonstandart ? createTextMask({
      pattern: 'A-999-AA-999',
      maskDefinitions: {
        A: { regExp: /[АВЕКМНОРСТУХавекмнорстух]/ },
        9: { regExp: /[0-9]/ },
      },
    }) : {}

    return (
      <Field
        {...restProps}
        component={nonstandart ? TextField : MaskField}
        normalize={this.normalize}
        {...carNumberMask}
      />
    );
  }
}

MaskFieldContainer.propTypes = {
  normalize: PropTypes.func,
  clearErrorsOnChange: PropTypes.bool,
};

export default MaskFieldContainer;
