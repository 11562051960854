/**
 *
 * getUnbindingAccessCardPage Function
 *
 */

import React from 'react';

// import { account } from 'domain/Data/typeConstants/accounts';

import UnbindingAccessCardPage from './UnbindingAccessCardPage';

export default function getUnbindingAccessCardPage(accountId, props) {
  switch (accountId) {
    default:
      return (
        <UnbindingAccessCardPage {...props} />
      );
  }
}
