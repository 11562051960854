/**
 * BreadcrumbSelect
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';

const styleSheet = (theme) => ({
  button: {
    lineHeight: '22px',
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
    marginLeft: -8,
    marginRight: -8,
  },
});

export class BreadcrumbSelect extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  state = {
    anchorEl: null,
    open: false,
  };

  handleButtonClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  createBreadcrumbSelectItemClickHandler = (selectItem) => () => {
    this.setState({ open: false }, () => {
      const { onClick } = selectItem;
      if (onClick) onClick();
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { anchorEl, open } = this.state;
    const { children, classes } = this.props;
    const selectedItemTitle = children.find((child) => child?.props?.selected)?.props?.children;

    return (
      <div>
        <Button
          color="inherit"
          size="small"
          className={classes.button}
          onClick={this.handleButtonClick}
        >
          {selectedItemTitle}
          <Icon>arrow_drop_down</Icon>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {
            React.Children.map(children, (child) =>
              React.cloneElement(child, {
                ...child.props,
                onClick: this.createBreadcrumbSelectItemClickHandler(child.props),
              })
            )
          }
        </Menu>
      </div>
    );
  }
}

BreadcrumbSelect.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.node
  ).isRequired,
  classes: PropTypes.object,
};

export default withStyles(styleSheet)(BreadcrumbSelect);
