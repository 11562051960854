import React, { PureComponent } from 'react';
import createBarChart from './createBarChart';



class BarChartCreator extends PureComponent {

    render() {
        const { entity } = this.props;
        const BarChartContainer = createBarChart(entity);

        return (
            <BarChartContainer {...this.props} />
        );
    }
}


export default BarChartCreator;

