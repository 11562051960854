/*
 *
 * TenantForm
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import {
  ON_VISIT,
  ON_CAR_ENTRY,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  ON_PASS_MAKING,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { CREATED } from 'domain/Data/RequestStatus/constants';
import { validateCarNumber } from 'utils/validators';
import { RESERVATION } from '../../../domain/Data/RequestType/constants';
import { groupRequestVisitorsDataHelperText, visitorFullNameLabel } from 'pages/Requests/common/fields';

class TenantForm14002 extends TenantForm {

  getFields() {
    const currentDate = new Date();

    return [
      {
        name: 'author',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'customer',
        visible: false,
        initialValue: (values, mode, user) => user.id,
      },
      {
        name: 'company',
        numberInQueueFromEnd: 1,
        visible: false,
        initialValue: (values, mode, user) => user.companyId,
      },
      {
        name: 'arrivalDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'departureDate',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'parkingPlace',
        label: 'Место парковки',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'passNumber',
        visible: false,
        initialValue: () => null,
      },
      {
        name: 'number',
        label: '№',
        visible: (currentValues, initialValues, mode) => mode === 'view',
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visible: (currentValues, initialValues, mode) => mode === 'view',
        calculatedValue: (values, mode) => {
          if (mode === 'add' || mode === 'copy') { return CREATED }
          return values.status;
        },
        orderBy: 'name',
      },
      {
        name: 'type',
        type: 'select',
        label: 'Вид',
        required: true,
        entityName: 'requestTypes',
        orderBy: 'order',
        initialValue: (values, mode) => {
          if (mode === 'add') {
            return ON_VISIT;
          }
          return values.type;
        },
        clearErrorsOnChange: true,
        helperText: (values) => values.type === ON_PASS_MAKING
          ? 'Изготовление постоянного пропуска сотрудникам Арендаторов'
          : '',
      },
      {
        name: 'visitDate',
        type: 'date',
        label: 'Дата',
        required: true,
        visible: true,
        initialValue: currentDate,
        rowBreakBefore: (currentValues, initialValues, mode) => mode !== 'view',
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
          new Date(initialValues.visitDate) : currentDate,
      },
      {
        name: 'reservationTime',
        type: 'select',
        label: 'Время',
        entityName: 'reservationTimes',
        required: true,
        compareOptions: (a, b) => {
          const firstItem = Number.parseInt(a.text, 10);
          const secondItem = Number.parseInt(b.text, 10);

          if (firstItem < secondItem) return -1;
          if (firstItem > secondItem) return 1;
          return 0;
        },
        visible: (values) => values.type === ON_CAR_ENTRY,
        visibleOptions: (currentValues, initialValues, mode, user, entities) => {
          const currentDay = currentDate;
          const visitDay = currentValues.visitDate;

          const times = entities.get('reservationTimes').toArray();
          if (visitDay <= currentDay) {
            const hours = currentDate.getHours();
            const options = times.map((item) => {
              const index = item.name.indexOf('-');
              const untilTime = Number.parseInt(item.name.substr(index + 1, 2), 10);
              if (untilTime <= hours && untilTime !== 0) return null;
              return item.id;
            });

            return options;
          }

          return times.map((item) => item.id);
        },
        rowBreakBefore: (values) => values.type !== ON_CAR_ENTRY,
      },
      {
        name: 'metersData',
        label: 'Показания счетчиков',
        rowBreakBefore: true,
        rowBreakAfter: true,
        visible: (values) => values.type === RESERVATION,
        grid: { sm: 12 },
      },
      {
        name: 'isGroupRequest',
        type: 'checkbox',
        label: 'Групповая заявка',
        visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY,
        rowBreakBefore: true,
      },
      {
        name: 'nonstandardCarNumber',
        type: 'checkbox',
        label: 'Нестандартный № авто',
        rowBreakBefore: false,
        rowBreakAfter: true,
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'groupRequestVisitorsData',
        label: 'Список посетителей',
        multiline: true,
        helperText: groupRequestVisitorsDataHelperText(),
        visible: (values) => values.isGroupRequest && values.type !== SERVICE,
        required: (values) => values.isGroupRequest && values.type === ON_VISIT,
        grid: { sm: 12 },
        rowBreakBefore: true,
      },
      {
        name: 'visitorFullName',
        label: visitorFullNameLabel,
        type: 'dadata',
        dadataType: 'NAME',
        dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        visible: (values) => !values.isGroupRequest && values.type !== SERVICE && values.type !== RESERVATION,
        autoFocus: (currentValues, initialValues, mode) => mode === 'add',
        required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'carNumber',
        label: '№ автомобиля',
        required: (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest,
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL) && !values.isGroupRequest,
        normalize: (value) => value ? value.replace(' ', '') : value,
        validate: validateCarNumber,
        rowBreakBefore: true,
      },
      {
        name: 'carModelText',
        label: 'Марка автомобиля',
        visible: (values) => (values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL) && !values.isGroupRequest,
      },
      {
        name: 'parking',
        type: 'select',
        label: 'Парковка',
        entityName: 'parkings',
        visible: (values) => values.type === ON_CAR_ENTRY ||
          values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'materialValuesData',
        label: 'Данные мат. ценностей',
        helperText: 'наименование, кол-во, ед.изм.',
        grid: { sm: 12 },
        required: true,
        visible: (values) => values.type === ON_PROPERTY_BRINGING ||
          values.type === ON_PROPERTY_REMOVAL,
      },
      {
        name: 'whoMeets',
        label: 'Кто встречает',
        visible: (values) => values.type === ON_VISIT,
        rowBreakBefore: true,
      },
      {
        name: 'note',
        label: 'Примечание',
        visible: (values) => values.type !== SERVICE,
        grid: { sm: 12 },
      },
      {
        name: 'managementCompanyComment',
        label: 'Комментарий УК',
        visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
        disabled: true,
        grid: { sm: 12 },
        initialValue: (values, mode) => {
          if (mode === 'copy') {
            return null;
          }
          return values.managementCompanyComment;
        },
      },
      {
        name: 'companyName',
        label: 'Из какой компании',
        visible: (values) => (values.type === ON_CAR_ENTRY || values.type === ON_VISIT ||
          values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL)
      },
      {
        name: 'service',
        type: 'select',
        label: 'Сервис',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
        rowBreakBefore: true,
      },
      {
        name: 'whereHappened',
        label: 'Где произошло',
        entityName: 'services',
        visible: (values) => values.type === SERVICE,
      },
      {
        name: 'whatHappened',
        label: 'Что нужно сделать',
        multiline: false,
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        required: true,
      },
      {
        name: 'assignee',
        type: 'select',
        label: 'Исполнитель',
        entityName: 'employees',
        orderBy: 'name',
        cached: false,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        rowBreakAfter: (values) => values.type === SERVICE,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.assignee;
        },
        visibleOptions: (currentValues, initialValue, mode, user, entities) => {
          const assignee = [];
          entities.get('employees').map((item) => {
            if (item.company === user.companyId && item.isPerformer) assignee.push(item.id);
          });

          return assignee;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'executionDate',
        type: 'date',
        label: 'Дата выполнения факт',
        required: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
          ? new Date(initialValues.visitDate) : currentDate,
        rowBreakBefore: true,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionDate;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        required: true,
        visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
        min: () => currentDate,
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.executionTerm;
        },
        disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        visible: (values) => values.type === SERVICE,
        grid: { sm: 12 },
        calculatedValue: (values, mode) => {
          if (mode === 'copy') return null;
          return values.implementationReport;
        },
        disabled: true,
      },
      {
        name: 'file',
        label: 'Файл',
        type: 'file',
        visible: (values) => values.type === SERVICE ||
          values.type === ON_PASS_MAKING,
        rowBreakBefore: true,
      },
    ];
  }
}

export default TenantForm14002;
