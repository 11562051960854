/*
 *
 * AssignedForm12861
 *
 */

import { AssignedForm } from 'pages/Requests/AssignedView';
import { CLOSED, OPENED, REPAIR, WELDING, PAINTING, COMPLETED, POSTPONED } from '../common/requestTypes';


export class AssignedForm12861 extends AssignedForm {

  getFields() {
    return [
      {
        name: 'number',
        label: '№',
        disabled: true,
        visible: false,
      },
      {
        name: 'actualCreationDate',
        label: 'Дата',
        type: 'date',
        disabled: true,
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        entityName: 'requestStatuses',
        visibleOptions: () => {
          return [CLOSED, OPENED, POSTPONED, REPAIR, WELDING, PAINTING, COMPLETED]
        },
        orderBy: 'name',
      },
      {
        name: 'assignee',
        type: 'select',
        entityName: 'employees',
        label: 'Исполнитель',
        disabled: true,
      },
      {
        name: 'executionTerm',
        type: 'date',
        label: 'Срок исполнения',
        disabled: true,
      },
      {
        name: 'desiredDate',
        label: 'Желаемая дата',
        type: 'date',
        disabled: true,
      },
      {
        name: 'executionDate',
        label: 'Дата выполнения',
        type: 'date',
      },
      {
        name: 'whoMeets',
        label: 'Клиент',
        disabled: true
      },
      {
        name: 'whereHappened',
        label: 'Адрес',
        multiline: true,
        grid: { sm: 12 },
        disabled: true,
      },
      {
        name: 'whatHappened',
        label: 'Что случилось',
        multiline: true,
        grid: { sm: 12 },
        disabled: true,
      },
      {
        name: 'implementationReport',
        label: 'Отчет о выполнении',
        multiline: true,
        grid: { sm: 12 },
      },
      {
        name: 'note',
        label: 'Примечание',
        multiline: true,
        grid: { sm: 12 },
      },
    ]
  }
}
