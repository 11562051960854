/**
 * BreadcrumbText
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styleSheet = (theme) => ({
  root: {
    lineHeight: '44px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
  },
});

export class BreadcrumbText extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleClick = (event) => {
    const { onClick } = this.props;
    if (onClick) onClick(event);
  };

  render() {
    const { classes, text } = this.props;

    return (
      <Typography
        type="title"
        color="inherit"
        className={classes.root}
        onClick={this.handleClick}
      >
        { text }
      </Typography>
    );
  }
}

BreadcrumbText.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styleSheet)(BreadcrumbText);
