/*
 *
 * CheckpointForm13539
 *
 */

import { CheckpointForm } from 'pages/Requests/CheckpointView';

class CheckpointForm13539 extends CheckpointForm { // eslint-disable-line react/prefer-stateless-function
  getFields() {
    const fields = super.getFields();

    fields.push({
      name: 'availableTenantCheckpoints',
      label: 'Доступные КПП арендатора',
      grid: { sm: 12 },
    });

    return fields;
  }
}

export default CheckpointForm13539;
