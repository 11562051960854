/**
 *
 * RequestsPage12924 sagas config
 *
 */

import { checkpointViewSaga } from './CheckpointView/sagas';
import { receptionViewSaga } from './ReceptionView/sagas';
import { tenantViewSaga } from './TenantView/sagas';

const requestsPageSagasConfig12429 = {
  checkpointViewSaga,
  receptionViewSaga,
  tenantViewSaga,
};

export default requestsPageSagasConfig12429;
