import React from 'react';
import PropTypes from 'prop-types';
import {VariableSizeList as List} from 'react-window';

import {Row} from './row';

import {DEFAULT_ROW_HEIGHT} from '../constants';

export class ListContainer extends React.PureComponent { // eslint-disable-line
    static defaultProps = {
        isLoading: false,
    };
    static propTypes = {
        getRowHeight: PropTypes.func,
        height: PropTypes.number,
        isListOver: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        listRef: PropTypes.object.isRequired,
        onActionMenuClick: PropTypes.func.isRequired,
        onDetailRowClick: PropTypes.func.isRequired,
        onLoadData: PropTypes.func.isRequired,
        rowComponent: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func,
        ]),
        rows: PropTypes.array.isRequired,
        width: PropTypes.number,
    };

    rowsSize = 0;
    isLoading = false;
    _isMounted;

    constructor(props) {
        super(props);
        const {isLoading} = props;

        this.isLoading = isLoading;
    }

    componentDidUpdate() {
        const {rows, isLoading} = this.props;

        if (rows.length !== this.rowsSize) {
            this.rowsSize = rows.length;
        }

        if (this.isLoading !== isLoading) {
            this.isLoading = isLoading;
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderRow = ({index, style, isScrolling}) => {
        const {
            isListOver,
            onActionMenuClick,
            onDetailRowClick,
            onLoadData,
            rowComponent,
            rows,
            width,
            accountId,
        } = this.props;

        if (!isListOver && isScrolling && index === this.rowsSize - 1 && !this.isLoading && this._isMounted) {
            this.isLoading = true;
            onLoadData({startAt: this.rowsSize, isLazyLoading: true});
        }

        return (
            <Row
                listWidth={width}
                onActionMenuClick={onActionMenuClick}
                onDetailRowClick={onDetailRowClick}
                record={rows[index]}
                rowComponent={rowComponent}
                style={style}
                accountId={accountId}
            />
        );
    };

    renderNoData = () => (
        <div
            style={{
                textAlign: 'center',
                fontSize: 16,
                marginTop: 20,
            }}
        >
            Нет данных
        </div>
    );

    render() {
        const {
            height,
            listRef,
            rows,
            width,
            getRowHeight,
        } = this.props;

        return (
            rows.length > 0
                ? (
                    <List
                        height={height || 460}
                        itemCount={rows.length}
                        itemSize={getRowHeight
                            ? (index) => getRowHeight(rows[index])
                            : () => DEFAULT_ROW_HEIGHT
                        }
                        ref={listRef}
                        useIsScrolling
                        width={width}
                    >
                        {this.renderRow}
                    </List>
                ) : (
                    this.renderNoData()
                )
        );
    }
}
