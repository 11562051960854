/**
 *
 * AdvancedForm actions
 *
 */

import {
  LOAD_DATA,
  LOAD_DATA_ERROR,
  LOAD_DATA_SUCCESS,
} from './constants';

export function loadData() {
  return {
    type: LOAD_DATA,
  };
}

export function dataLoaded(ids) {
  return {
    type: LOAD_DATA_SUCCESS,
    payload: { ids },
  };
}

export function dataLoadingError(error) {
  return {
    type: LOAD_DATA_ERROR,
    payload: { error },
  };
}
