/**
 *
 * AdvancedMobileGrid reducer
 *
 */

import {Map, List} from 'immutable';
import {
    CHANGE_CHECKED_ALL,
    CHANGE_GRID_FILTERS,
    CHANGE_IS_LIST_OVER,
    CHANGE_IS_LOADING,
    CHANGE_LAZY_LOADING_STATE,
    CHANGE_MENU_ITEM,
    CHANGE_SEARCH_QUERY,
    CHANGE_SELECTION,
    CHANGE_SORTING,
    CLEAR_SELECTION,
    LOAD_DATA,
    LOAD_DATA_SUCCESS,
    REGISTER,
    UNREGISTER,
    CHANGE_CURRENT_RECORD,
} from './constants';


const advancedMobileGridReducer = (state = new Map({}), {type, meta, payload}) => {
    switch (type) {
        case CHANGE_IS_LOADING:
            return state.setIn([meta.name, 'isLoading'], payload.isLoading);

        case CHANGE_IS_LIST_OVER:
            return state.setIn([meta.name, 'isListOver'], payload.isListOver);

        case CHANGE_CHECKED_ALL:
            return state.setIn([meta.name, 'checkedAll'], payload.checkedAll);

        case CHANGE_LAZY_LOADING_STATE:
            return state.setIn([meta.name, 'lazyLoadingState'], payload.lazyLoadingState);

        case CHANGE_MENU_ITEM:
            return state.setIn([meta.name, 'itemMenuRecord'], new List(payload.recordId));

        case CHANGE_SELECTION:
            return state.setIn([meta.name, 'selection'], new List(payload.selection));

        case CHANGE_CURRENT_RECORD:
            return state.setIn([meta.name, 'currentRecord'], payload.record);

        case CHANGE_SORTING:
            return state.setIn([meta.name, 'sorting'], new List(payload.sorting));

        case CHANGE_SEARCH_QUERY:
            return state.setIn([meta.name, 'searchQuery'], payload.searchQuery);

        case CHANGE_GRID_FILTERS:
            return state.setIn([meta.name, 'gridFilters'], new List(payload.gridFilters));

        case CLEAR_SELECTION:
            return state.setIn([meta.name, 'selection'], new List([]));

        case LOAD_DATA: {
            let newState = state;

            if (payload.sorting) {
                newState = newState.setIn([meta.name, 'sorting'], new List(payload.sorting));
            }
            if (payload.filters) {
                newState = newState.setIn([meta.name, 'filters'], new List(payload.filters));
            }
            if (payload.searchQuery) {
                newState = newState.setIn([meta.name, 'searchQuery'], payload.searchQuery);
            }
            if (payload.startAt) {
                newState = newState.setIn([meta.name, 'startAt'], payload.startAt);
            }
            if (payload.limit) {
                newState = newState.setIn([meta.name, 'limit'], payload.limit);
            }

            return newState;
        }

        case LOAD_DATA_SUCCESS:
            return state.setIn([meta.name, 'data', 'ids'], new List(payload.ids));

        case UNREGISTER:
            return state.delete(meta.name);

        case REGISTER:
            return state
                .setIn([meta.name, 'actionButtons'], new List(payload.actionButtons))
                .setIn([meta.name, 'actionMenuItems'], new List(payload.actionMenuItems))
                .setIn([meta.name, 'data', 'entityName'], payload.entityName)
                .setIn([meta.name, 'data', 'ids'], new List([]))
                .setIn([meta.name, 'filters'], new List(payload.filters))
                .setIn([meta.name, 'isListOver'], false)
                .setIn([meta.name, 'isLoading'], false)
                .setIn([meta.name, 'itemMenuRecord'], new List([]))
                .setIn([meta.name, 'limit'], payload.limit)
                .setIn([meta.name, 'searchQuery'], null)
                .setIn([meta.name, 'selection'], new List([]))
                .setIn([meta.name, 'sorting'], new List(payload.sorting))
                .setIn([meta.name, 'currentRecord'], null)
                .setIn([meta.name, 'gridFilters'], new List(payload.gridFilters));

        default:
            return state;
    }
};

export default advancedMobileGridReducer;
