/*
 *
 * Task actions
 *
 */

import {
  CLOSE_TASKS,
  CLOSE_TASKS_SUCCESS,
  CLOSE_TASKS_ERROR,
  PERFORME_TASKS,
  PERFORME_TASKS_SUCCESS,
  PERFORME_TASKS_ERROR,
} from './constants';


export function closeTasks(ids) {
  return {
    type: CLOSE_TASKS,
    payload: { ids },
  };
}

export function tasksClosed() {
  return {
    type: CLOSE_TASKS_SUCCESS,
  };
}

export function tasksClosingError(error) {
  return {
    type: CLOSE_TASKS_ERROR,
    payload: { error },
  };
}


export function performeTasks(ids) {
  return {
    type: PERFORME_TASKS,
    payload: { ids },
  };
}

export function tasksPerformed(ids) {
  return {
    type: PERFORME_TASKS_SUCCESS,
    payload: { ids },
  };
}

export function tasksPerformedError(ids) {
  return {
    type: PERFORME_TASKS_ERROR,
    payload: { ids },
  };
}