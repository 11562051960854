/**
 * AlertDialog selectors
 */

import { createSelector } from 'reselect';

const makeSelectDomain = () => (state) => state.get('alertDialog');

const makeSelectOpen = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('open')
);

const makeSelectTitle = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('title')
);

const makeSelectContent = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('content')
);

const makeSelectType = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('type')
);

const makeSelectResult = () => createSelector(
  makeSelectDomain(),
  (domainState) => domainState.get('result')
);

export default makeSelectDomain;
export {
  makeSelectOpen,
  makeSelectTitle,
  makeSelectContent,
  makeSelectResult,
  makeSelectType,
};
