/**
 *
 * UnbindingAccessCardPage sagas
 *
 */

import { fork, select } from 'redux-saga/effects';

import { makeSelectAccountId } from 'domain/User/selectors';

import getSagasConfig from './getSagasConfig';

import createReceptionViewSaga from './ReceptionView/sagas';

export default function* rootSaga() {
  const accountId = yield select(makeSelectAccountId());
  const config = getSagasConfig(accountId);

  yield fork(createReceptionViewSaga(config.receptionViewSaga));
}
