/*
 *
 * ServiceRequestsManagerView constants 14326
 *
 */

export const EXECUTE_ACCEPT_ACTION = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/EXECUTE_ACCEPT_ACTION';
export const EXECUTE_SEND_TO_PERFORMER = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/EXECUTE_SEND_TO_PERFORMER';

export const ACCEPT_REQUESTS = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/ACCEPT_REQUESTS';
export const ACCEPT_REQUESTS_SUCCESS = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/ACCEPT_REQUESTS_SUCCESS';
export const ACCEPT_REQUESTS_ERROR = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/ACCEPT_REQUESTS_ERROR';

export const EXECUTE_REJECT_ACTION = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/EXECUTE_REJECT_ACTION';

export const REJECT_REQUESTS = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/REJECT_REQUESTS';
export const REJECT_REQUESTS_SUCCESS = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/REJECT_REQUESTS_SUCCESS';
export const REJECT_REQUESTS_ERROR = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/REJECT_REQUESTS_ERROR';

export const EXECUTE_CLOSE_ACTION = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/EXECUTE_CLOSE_ACTION';
export const EXECUTE_SEND_TO_AGREMENT_ACTION = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/EXECUTE_SEND_TO_AGREMENT_ACTION';

export const SEND_EMAIL = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/SEND_EMAIL_ERROR';

export const KHIMKI_SERVICE_UPDATE_FORM_FIELD = 'claris-vnext/custom/14326/RequestsPage/ServiceRequestsManagerView/KHIMKI_SERVICE_UPDATE_FORM_FIELD';
