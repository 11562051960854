/*
 *
 * AdministratorBCRequestsFormDialog14691
 *
 */

import React from 'react';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import PropTypes from 'prop-types';
import { requestAction } from 'domain/typeConstants/action';
import { RECEPTION_REQUESTS_FORM_DIALOG, RECEPTION_REQUESTS_FORM, RECEPTION_REQUESTS_TABLE_GRID } from 'pages/Requests/ReceptionView/constants';
import { REQUESTS } from 'domain/Data/constants';

export class AdministratorBCRequestsFormDialog14691 extends React.Component {

  handleAcceptButtonClick = () => this.props.onAccept();
  handleRejectButtonClick = () => this.props.onReject();

  renderCustomButtons() {
    const { mode } = this.props;
    if (mode !== 'edit') return null;

    return [
      {
        name: requestAction.ACCEPT,
        label: 'Согласовать',
        onClick: this.handleAcceptButtonClick,
      },
      {
        name: requestAction.REJECT,
        label: 'Отклонить',
        onClick: this.handleRejectButtonClick,
      },
    ];
  }

  render() {
    const { form, detailContainer } = this.props;
    return (
      <AdvancedFormDialog
        name={RECEPTION_REQUESTS_FORM_DIALOG}
        formName={RECEPTION_REQUESTS_FORM}
        dataTableName={RECEPTION_REQUESTS_TABLE_GRID}
        advancedForm={form}
        addModeTitle="Новая заявка"
        editModeTitle="Изменение заявки"
        viewModeTitle="Просмотр заявки"
        copyModeTitle="Копирование заявки"
        customButtons={this.renderCustomButtons()}
        detailContainer={detailContainer}
        entityName={REQUESTS}
      />
    );
  }
}

AdministratorBCRequestsFormDialog14691.propTypes = {
  form: PropTypes.node.isRequired,
  mode: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default AdministratorBCRequestsFormDialog14691;
