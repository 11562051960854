/*
 *
 * EmailSubscriptionButtonContainer
 *
 */

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import * as actionCreators from './actions';
import { makeSelectOpen } from './selectors';
import EmailSubscriptionButton from './EmailSubscriptionButton';
import reducer from './reducer';
import saga from './sagas';

class EmailSubscriptionButtonContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  handleClick = () => {
    if (this.props.isSubscribed) {
      this.props.unsubscribe()
    } else {
      this.props.subscribe()
    }
  }

  render() {
    return (
      <EmailSubscriptionButton
        isSubscribed={this.props.isSubscribed}
        onClick={this.handleClick}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isSubscribed: makeSelectOpen()
});

function mapDispatchToProps(dispatch) {
  return {
    subscribe: () => dispatch(actionCreators.subscribe()),
    unsubscribe: () => dispatch(actionCreators.unsubscribe()),
  };
}

const withReducer = injectReducer({ key: 'emailSubscription', reducer });
const withSaga = injectSaga({ key: 'emailSubscription', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(EmailSubscriptionButtonContainer);