/*
 *
 * Scanner Snackbar
 *
 */

import { OPEN, CLOSE } from './constants';

export function open(message, options = {}) {
  return {
    type: OPEN,
    payload: { message, options },
  };
}

export function close() {
  return {
    type: CLOSE,
  };
}
