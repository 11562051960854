/**
 *
 * RequestsPage14366
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import {
  ReceptionForm14366,
  ReceptionDxGrid14366,
  VisitorArrivalForm14366,
  ReceptionMobileGrid14366,
  ReceptionFormDialog14366,
} from './ReceptionView';
import { TenantForm14366 } from './TenantView';
import { CheckpointDxGrid14366 } from './CheckpointView';
import {
  RestrictedAdministratorBCServiceRequestsForm14366,
  RestrictedAdministratorBCRequestsForm14366,
  RestrictedAdministratorBCRequestsDxGrid14366,
  RestrictedAdministratorBCServiceRequestsDxGrid14366,
} from './RestrictedAdministratorBCView';
import {
  AdministratorBCRequestsDxGrid14366,
  AdministratorBCRequestsForm14366,
  AdministratorBCServiceRequestsForm14366,
} from './AdministratorBCView';
import {
  MakingAgreementBCDxGrid14366,
  MakingAgreementBCForm14366,
} from './MakingAgreementBCView';
import { AssignedForm14366 } from './AssignedView';
import {
  ServiceRequestsManagerForm14366,
  ServiceRequestsManagerDxGrid14366,
} from './ServiceRequestsManagerView';
import { getRequestDetailContainer } from 'pages/common/detailContainers';


function RequestsPage14366(props) {
  return (
    <RequestsPage
      receptionDxGrid={<ReceptionDxGrid14366 />}
      receptionMobileGrid={<ReceptionMobileGrid14366 />}
      receptionForm={<ReceptionForm14366 />}
      receptionFormDialog={
        <ReceptionFormDialog14366
          form={<ReceptionForm14366 />}
          detailContainer={getRequestDetailContainer()}
        />
      }
      visitorArrivalForm={<VisitorArrivalForm14366 />}
      checkpointDxGrid={<CheckpointDxGrid14366 />}

      restrictedAdministratorBCDxGrid={<RestrictedAdministratorBCRequestsDxGrid14366 />}
      restrictedAdministratorBCForm={<RestrictedAdministratorBCRequestsForm14366 />}
      restrictedAdministratorBCFormDialog={<ReceptionFormDialog14366
        params={props.params}
        form={<RestrictedAdministratorBCRequestsForm14366 />}
      />}
      restrictedAdministratorBCServiceDxGrid={<RestrictedAdministratorBCServiceRequestsDxGrid14366 />}
      restrictedAdministratorBCServiceForm={<RestrictedAdministratorBCServiceRequestsForm14366 />}
      restrictedAdministratorBCVisitorArrivalForm={<VisitorArrivalForm14366 />}

      administratorBCDxGrid={<AdministratorBCRequestsDxGrid14366 />}
      administratorBCForm={<AdministratorBCRequestsForm14366 />}
      administratorBCServiceForm={<AdministratorBCServiceRequestsForm14366 />}
      administratorBCVisitorArrivalForm={<VisitorArrivalForm14366 />}
      administratorBCFormDialog={<ReceptionFormDialog14366
        params={props.params}
        form={<AdministratorBCRequestsForm14366 />}
        detailContainer={getRequestDetailContainer()}
      />}
      tenantForm={<TenantForm14366 />}

      makingAgreementBCDxGrid={<MakingAgreementBCDxGrid14366 />}
      makingAgreementBCForm={<MakingAgreementBCForm14366 />}

      assignedForm={<AssignedForm14366 />}

      serviceRequestsManagerForm={<ServiceRequestsManagerForm14366 />}
      serviceRequestsManagerDxGrid={<ServiceRequestsManagerDxGrid14366 />}
      {...props}
    />
  );
}

export default RequestsPage14366;
