/**
 *
 * getDashboardPage Function
 *
 * 
 */
import React from 'react';
import DashboardPage from './DashboardPage';

export default function getDashboardPage(props) {
  return (
    <DashboardPage {...props} />
  );
}

