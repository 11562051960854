/**
 *
 * RestrictedAdministratorBC Requests DxGrid
 *
 */

import {
  CURRENT_DAY_REQUESTS,
  REQUESTS_FOR_USER_DEPARTMENT,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  ON_CREATE_REQUESTS,
} from 'domain/Data/Filters/constants';
import { ReceptionDxGrid } from '../ReceptionView';

class RestrictedAdministratorBCRequestsDxGrid extends ReceptionDxGrid {
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_USER_DEPARTMENT];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    return filters;
  }
}

export default RestrictedAdministratorBCRequestsDxGrid;
