/*
 *
 * Task constants
 *
 */

export const CLOSE_TASKS = 'claris-vnext/Data/Request/CLOSE_TASKS';
export const CLOSE_TASKS_SUCCESS = 'claris-vnext/Data/Request/CLOSE_TASKS_SUCCESS';
export const CLOSE_TASKS_ERROR = 'claris-vnext/Data/Request/CLOSE_TASKS_ERROR';
export const PERFORME_TASKS = 'claris-vnext/Data/Request/PERFORME_TASKS';
export const PERFORME_TASKS_SUCCESS = 'claris-vnext/Data/Request/PERFORME_TASKS_SUCCESS';
export const PERFORME_TASKS_ERROR = 'claris-vnext/Data/Request/PERFORME_TASKS_ERROR';
