/*
 *
 * RequestPage11994Container
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectRoles } from 'domain/User/selectors';
import RequestsPage11994 from './RequestsPage11994';

class RequestsPage11994Container extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <RequestsPage11994 {...this.props} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
});

export default connect(mapStateToProps)(RequestsPage11994Container);
