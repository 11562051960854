/**
 *
 * AdministratorBC Requests DxGrid 14366
 *
 */

import ServiceRequestsManagerDxGrid from 'pages/Requests/ServiceRequestsManagerView/ServiceRequestsManagerDxGrid';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { REQUESTS_FOR_USER_DEPARTMENT } from 'domain/Data/Filters/constants';
import { UK_TSVETNOY, MELIORA_PLACE } from 'custom/14366/domain/Data/Departments/constants';
import { requestColumnSizes } from 'domain/typeConstants/table';


export class ServiceRequestsManagerDxGrid14366 extends ServiceRequestsManagerDxGrid {

  getFilters() {
    const filters = super.getFilters();
    const departmentId = getValueFromLocalStorage(GLOBAL_USER_KEY)?.departmentId;
    [UK_TSVETNOY, MELIORA_PLACE].includes(departmentId) && filters.push(REQUESTS_FOR_USER_DEPARTMENT)
    return filters;
  }


  getColumns() {
    const columns = super.getColumns();
    columns.push({
      path: 'office',
      name: 'office',
      title: '№ Офиса',
      getCellValue: (row) => row.company ? row.company.office : undefined,
      width: requestColumnSizes.office,
    });
    const whereHappenedIndex = columns.findIndex((column) => column.name === 'whereHappened');
    columns[whereHappenedIndex].title = 'Где произошло/Офис'
    return columns
  }


  getColumnOrder() {
    const columnOrder = super.getColumnOrder()
    const serviceNameIndex = columnOrder.findIndex((column) => column === 'serviceName');
    columnOrder.splice(serviceNameIndex + 1, 0, 'office');
    return columnOrder
  }

}
