/**
 *
 * fileFieldReducer reducer
 *
 */

import { Map } from 'immutable';

import {
  SAVE_FILE,
  SAVE_FILE_PROGRESS,
  SAVE_FILE_SUCCESS,
  SAVE_FILE_ERROR,
} from 'domain/Data/File/constants';

function fileFieldReducer(state = new Map({}), { type, meta, payload }) {
  switch (type) {
    case SAVE_FILE:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'progress'], true)
        .setIn([meta.advancedForm, 'fields', meta.field, 'progressMode'], 'determinate')
        .setIn([meta.advancedForm, 'fields', meta.field, 'progressValue'], 0);

    case SAVE_FILE_PROGRESS:
      return state.setIn([meta.advancedForm, 'fields', meta.field, 'progressValue'], payload.progress);

    case SAVE_FILE_SUCCESS:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'progress'], false)
        .setIn([meta.advancedForm, 'fields', meta.field, 'progressMode'], null)
        .setIn([meta.advancedForm, 'fields', meta.field, 'progressValue'], null);

    case SAVE_FILE_ERROR:
      return state
        .setIn([meta.advancedForm, 'fields', meta.field, 'error'], 'Ошибка при загрузке файла');

    default:
      return state;
  }
}


export default fileFieldReducer;
