/*
 *
 * LoginPageContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import saga from './sagas';

import LoginPage from './LoginPage';

function LoginPageContainer({ location }) {
  const { next: nextPathAfterLogin } = queryString.parse(location.search);
  return (
    <LoginPage
      nextPathAfterLogin={nextPathAfterLogin}
    />
  );
}

LoginPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

const reducer = (state = {}) => state;

const withReducer = injectReducer({ key: 'loginFormPage', reducer });
const withSaga = injectSaga({ key: 'loginFormPage', saga });
const withConnect = connect();

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(LoginPageContainer);
