/**
 *
 * ReceptionFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { RECEPTION_REQUESTS_FORM, RECEPTION_REQUESTS_FORM_DIALOG, RECEPTION_REQUESTS_TABLE_GRID } from './constants';
import { REQUESTS } from 'domain/Data/constants';

function ReceptionFormDialog({ form, detailContainer }) {
  return (
    <AdvancedFormDialog
      name={RECEPTION_REQUESTS_FORM_DIALOG}
      formName={RECEPTION_REQUESTS_FORM}
      dataTableName={RECEPTION_REQUESTS_TABLE_GRID}
      advancedForm={form}
      addModeTitle="Новая заявка"
      editModeTitle="Изменение заявки"
      viewModeTitle="Просмотр заявки"
      copyModeTitle="Копирование заявки"
      navigation={true}
      detailContainer={detailContainer}
      entityName={REQUESTS}
    />
  );
}

ReceptionFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
};

export default ReceptionFormDialog;
