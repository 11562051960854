/**
 *
 * DxGridLocalizationMessages Component
 *
 */

import { filterOperation } from './dxGridTypes';

export const getTableMessages = {
  noData: 'Нет данных',
};

export const getFilterMessages = {
  [filterOperation.CONTAINS]: 'Содержит',
  [filterOperation.ENDS_WITH]: 'Заканчивается с',
  [filterOperation.EQUAL]: 'Равен',
  filterPlaceholder: 'Фильтр...',
  [filterOperation.GREATER_THAN]: 'Больше чем',
  [filterOperation.GREATER_THAN_OR_EQUAL]: 'Больше чем или равен',
  [filterOperation.LESS_THAN]: 'Меньше чем',
  [filterOperation.LESS_THAN_OR_EQUAL]: 'Меньше чем или равен',
  [filterOperation.NOT_CONTAINS]: 'Не содержит',
  [filterOperation.NOT_EQUAL]: 'Не равен',
  [filterOperation.STARTS_WITH]: 'Начинается с',
  [filterOperation.DATE_RANGE]: 'Период',
  [filterOperation.MONTH]: 'Текущий месяц',
  [filterOperation.ONE_DAY]: 'День',
};
