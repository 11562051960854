/**
 *
 * AgreementDialog reducer
 *
 */

import { Map, List } from 'immutable';

import {
  CLOSE_AGREEMENT_DIALOG,
  OPEN_AGREEMENT_DIALOG,
  SUBMIT_AGREEMENT_DIALOG,
  SET_MANAGER_ENTITY_IDS,
  SET_MANAGERS_VALUES,
} from './constants';

const initialState = new Map({
  open: false,
  id: null,
  dataTable: null,

  managerFieldIds: new List([]),
  managers: new List([]),
});

function agreementDialogReducer(state = initialState, { type, meta, payload }) {
  switch (type) {
    case SET_MANAGERS_VALUES:
      return state.set('managers', new List(payload.ids));

    case SET_MANAGER_ENTITY_IDS:
      return state
        .set('managerFieldIds', new List(payload.ids));

    case OPEN_AGREEMENT_DIALOG:
      return state
        .set('open', true)
        .set('dataTable', meta.dataTable)
        .set('id', payload.id);

    case CLOSE_AGREEMENT_DIALOG:
    case SUBMIT_AGREEMENT_DIALOG:
      return state
        .set('open', false)
        .set('managers', new List([]))
        .set('id', null);

    default:
      return state;
  }
}

export default agreementDialogReducer;
