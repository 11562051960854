/**
 *
 * Assigned Requests DxGrid 12861
 *
 */

import { AssignedDxGrid } from 'pages/Requests/AssignedView';
import { requestAction } from 'domain/typeConstants/action';
import { columnSize, columnType, requestColumnSizes } from 'domain/typeConstants/table';
import { IN_PROGRESS_REQUESTS } from 'domain/Data/Filters/constants';
import { requestStatus } from 'domain/typeConstants/status';


export class AssignedDxGrid12861 extends AssignedDxGrid {

  getFilters() {
    const { viewName } = this.props;
    const filters = super.getFilters();
    if (viewName === 'active') filters.push(IN_PROGRESS_REQUESTS);
    return filters;
  }

  getColumns() {
    const columns = super.getColumns();

    const unnecessaryColumns = new Set(['serviceName', 'assigneeName', 'author', 'fileName', 'executionDate', 'authorName']);

    const whatHappenedIndex = columns.findIndex((column) => column.name === 'whatHappened');
    columns[whatHappenedIndex].title = 'Что случилось';
    columns[whatHappenedIndex].width = requestColumnSizes.whatHappened;

    const actualCreationDateIndex = columns.findIndex((column) => column.name === 'actualCreationDate');
    columns[actualCreationDateIndex].title = 'Дата';

    const companyNameIndex = columns.findIndex((column) => column.name === 'companyName');
    columns[companyNameIndex].title = 'Абонент';

    const whereHappenedIndex = columns.findIndex((column) => column.name === 'whereHappened');
    columns[whereHappenedIndex].title = 'Адрес';
    columns[whereHappenedIndex].width = requestColumnSizes.note;

    columns.push({
      path: ['company', 'locality'],
      name: 'company_locality',
      getCellValue: (row) => row.company ? row.company.locality : undefined,
      title: 'Населенный пункт',
    })

    columns.push(
      {
        path: 'desiredDate',
        name: 'desiredDate',
        title: 'Желаемая Дата',
        type: columnType.DATE,
        width: columnSize.DATE,
      }
    )

    columns.push({
      path: ['company', 'phone'],
      name: 'company_phone',
      getCellValue: (row) => row.company ? row.company.phone : undefined,
      title: 'Телефон',
    })

    columns.push({
      path: ['company', 'client'],
      name: 'company_client',
      getCellValue: (row) => row.company ? row.company.client : undefined,
      title: 'Клиент',
    })

    columns.push({
      path: 'implementationReport',
      name: 'implementationReport',
      title: 'Отчет о выполнении',
      width: requestColumnSizes.implementationReport,
    })

    return columns.filter((column) => !unnecessaryColumns.has(column.name));
  }

  getColumnOrder() {
    return ['number', 'actualCreationDate', 'statusName', 'executionTerm', 'whatHappened', 'companyName', 'company_client',
      'company_phone', 'whereHappened', 'desiredDate', 'note', 'implementationReport'];
  }

  getActionButtons() {
    const actionButtons = super.getActionButtons();
    const unnecessaryButtons = new Set([requestAction.ADD, requestAction.ACCEPT, requestAction.REJECT]);

    const completeActionButtonIndex = actionButtons.findIndex((button) => button.name === requestAction.COMPLETE);
    actionButtons[completeActionButtonIndex].label = 'Выполнить';
    actionButtons[completeActionButtonIndex].disabled = (selection, entities, currentRecord) => {
      if (currentRecord && selection.length === 0) {
        if (!currentRecord.status) return false
        return currentRecord.status.name === requestStatus.CLOSED
      }
      else if (selection.length > 0) {
        if (!entities[0].status) return false;
        return entities.some((element) => element.status.name === requestStatus.CLOSED);
      }
      return true;
    }

    return actionButtons.filter((button) => !unnecessaryButtons.has(button.name));
  }

  getActionMenuItems() {
    const actionMenuItems = super.getActionMenuItems()
    const unnecessaryMenuItems = new Set([requestAction.ACCEPT, requestAction.REJECT]);

    const completeActionMenuItemIndex = actionMenuItems.findIndex((item) => item.name === requestAction.COMPLETE);
    actionMenuItems[completeActionMenuItemIndex].label = 'Выполнить';
    actionMenuItems[completeActionMenuItemIndex].hidden = (itemMenuRecord, entities, currentRecord) => {
      if (currentRecord) {
        if (!currentRecord.status) return false
        return currentRecord.status.name === requestStatus.CLOSED
      } else if (itemMenuRecord.length !== 0) {
        if (!entities[0].status) return false;
        return (entities[0].status.name === requestStatus.CLOSED);
      }
      return true;
    }

    const viewActionItemIndex = actionMenuItems.findIndex((item) => item.name === requestAction.VIEW);
    actionMenuItems[viewActionItemIndex].hidden = (itemMenuRecord, entities, currentRecord) => {
      if (currentRecord) {
        if (!currentRecord.status) return false
        return currentRecord.status.name !== requestStatus.CLOSED
      } else if (itemMenuRecord.length !== 0) {
        if (!entities[0].status) return false;
        return (entities[0].status.name !== requestStatus.CLOSED);
      }
      return true;
    }

    return actionMenuItems.filter((item) => !unnecessaryMenuItems.has(item.name));
  }

}