/**
 *
 * AdministratorBC Requests DxGrid 15774
 *
 */

import {ReceptionDxGrid15774} from "../ReceptionView";
import {requestColumnSizes} from "domain/typeConstants/table";

export class AdministratorBCRequestsDxGrid15774 extends ReceptionDxGrid15774 {


    getColumns() {
        const columns = super.getColumns();

        const issueReason =
            {
                path: 'issueReason',
                name: 'issueReason',
                title: 'Причина выдачи',
                getCellValue: (row) => row.issueReason ? row.issueReason.name : undefined,
                width: requestColumnSizes.issueReason,
            }
        columns.push(issueReason)

        const vozdvizhenkaAccessLevel =
            {
                path: 'vozdvizhenkaAccessLevel',
                name: 'vozdvizhenkaAccessLevel',
                title: 'Уровень доступа',
                getCellValue: (row) => row.vozdvizhenkaAccessLevel ? row.vozdvizhenkaAccessLevel.name : undefined,
                width: requestColumnSizes.issueReason,
            }
        columns.push(vozdvizhenkaAccessLevel)

        return columns
    }


    getColumnOrder() {
        const {viewName} = this.props;
        let columnOrder = super.getColumnOrder();

        if (viewName === 'passMaking') {
            columnOrder = ['number', 'visitDate', 'statusName', 'visitorFullName', 'issueReason',
                'vozdvizhenkaAccessLevel', 'company_name', 'managementCompanyComment', 'passNumber', 'fileName',]
        }

        return columnOrder;
    }

}
