/**
 *
 * WorkView actions
 *
 */

import {
  EXECUTE_ADD_ACTION,
  EXECUTE_COPY_ACTION,
  EXECUTE_EDIT_ACTION,
} from './constants';

export function executeAddAction(parent,parentId) {
  return {
    type: EXECUTE_ADD_ACTION,
    payload: { parent, parentId },
  };
}

export function executeEditAction(id, parent, parentId) {
  return {
    type: EXECUTE_EDIT_ACTION,
    payload: { id, parent, parentId },
  };
}

export function executeCopyAction(id, parent, parentId) {
  return {
    type: EXECUTE_COPY_ACTION,
    payload: { id, parent, parentId },
  };
}

