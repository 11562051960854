/**
 *
 * createAdvancedForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form/immutable';
import {createStructuredSelector} from 'reselect';
import makeSelectUser, {makeSelectAccountId} from 'domain/User/selectors.js';

import AdvancedForm from './AdvancedForm';
import * as actionCreators from './actions';
import {
    makeSelectInitialValues,
    makeSelectCurrentValues,
    makeSelectGroups,
    makeSelectPositions,
} from './selectors';

function createAdvancedForm(formName) {
    class AdvancedFormContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
        componentWillMount() {
            const {loadFormData, calculateFieldsValues, mode, entityId} = this.props;
            loadFormData(mode, entityId);
            if (mode === 'edit') {
                calculateFieldsValues();
            }
        }

        render() {
            return (
                <AdvancedForm {...this.props} />
            );
        }
    }

    AdvancedFormContainer.propTypes = {
        mode: PropTypes.oneOf(['add', 'edit', 'view', 'copy', 'delete']),
        entityId: PropTypes.string,
        loadFormData: PropTypes.func.isRequired,
        calculateFieldsValues: PropTypes.func.isRequired,
    };

    const mapStateToProps = createStructuredSelector({
        initialValues: makeSelectInitialValues(formName),
        currentValues: makeSelectCurrentValues(formName),
        positionValues: makeSelectPositions('SupplyRequestsForm', 'documentPositions'),
        user: makeSelectUser(),
        accountId: makeSelectAccountId(),
        groups: makeSelectGroups(formName),
    });

    const mapDispatchToProps = (dispatch) => ({
        loadFormData: (mode, entityId) => dispatch(actionCreators.loadFormData(formName, mode, entityId)),
        calculateFieldsValues: () => dispatch(actionCreators.calculateFieldsValues(formName)),
        setGroups: (groups) => dispatch(actionCreators.setGroups(formName, groups)),
    });

    return connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({
            form: formName,
            // destroyOnUnmount: false, //  19 317   // повлияло на initialValue
            enableReinitialize: true,
            getFormState: (state) => state.get('forms'),
        })(AdvancedFormContainer)
    );
}

export default createAdvancedForm;
