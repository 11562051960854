/**
 *
 * ReceptionMobileGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedMobileGrid from 'containers/AdvancedMobileGrid';

import {CURRENT_DAY_REQUESTS, REQUESTS_ON_CAR_TYPE, REQUESTS_ON_VISIT,} from 'domain/Data/Filters/constants';
import {columnType} from 'domain/typeConstants/table';

import {RECEPTION_REQUESTS_TABLE_GRID} from './constants';

import {
    addActionButton,
} from '../common/actionButton';
import {
    acceptActionItem,
    addBlackListCarActionItem,
    arrivedActionItem,
    copyActionItem,
    departedActionItem,
    editActionItem,
    rejectActionItem,
    viewActionItem,
} from '../common/actionMenu';
import {
    RowComponent, getRowHeight, statusColorLabels,
} from '../common/mobile';
import {settings} from 'containers/AdvancedDataTable/dxGridTypes';

class ReceptionMobileGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    getFilters() {
        const {viewName} = this.props;
        const filters = [];
        if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
        if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
        if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_TYPE);
        return filters;
    }

    getDetailRowFields() {
        return [
            [
                {
                    name: 'number',
                    title: '№',
                    type: columnType.NUMERIC,
                },
                {
                    name: 'typeShortName',
                    getValue: (row) => (row.type ? row.type.shortName : undefined),
                    title: 'Вид',
                },
                {
                    name: 'statusName',
                    getValue: (row) => (row.status ? row.status.name : undefined),
                    title: 'Статус',
                    type: columnType.LABEL,
                    labels: statusColorLabels,
                },
            ],
            [
                {
                    name: 'visitDate',
                    title: 'Дата',
                    type: columnType.DATE,
                },
                {
                    name: 'expirationDate',
                    title: 'До',
                    type: columnType.DATE,
                },
            ],
            {
                name: 'visitorFullName',
                title: 'ФИО посетителя',
            },
            {
                name: 'company_name',
                getValue: (row) => (row.company ? row.company.name : undefined),
                title: 'Арендатор',
            },
            [
                {
                    name: 'carNumber',
                    title: '№ авто',
                },
                {
                    name: 'carModelText',
                    title: 'Марка',
                },
            ],
            [
                {
                    name: 'parkingName',
                    getValue: (row) => (row.parking ? row.parking.name : undefined),
                    title: 'Парковка',
                },
                {
                    name: 'parkingPlace',
                    title: 'Место',
                },
            ],
            {
                name: 'documentDetails',
                title: 'Серия и №',
            },
            {
                name: 'passNumber',
                title: '№ пропуска',
            },
            {
                name: 'materialValuesData',
                title: 'Мат. ценности',
            },
            {
                name: 'companyName',
                title: 'Откуда посетитель',
            },
            {
                name: 'whatHappened',
                title: 'Что случилось',
            },
            {
                name: 'serviceName',
                getValue: (row) => (row.service ? row.service.name : undefined),
                title: 'Сервис',
            },
            {
                name: 'note',
                title: 'Примечание',
            },
            {
                name: 'blackListNote',
                title: 'Причина ЧС',
            },
        ];
    }

    getActionButtons() {
        return [addActionButton];
    }

    getActionMenuItems() {
        return [
            viewActionItem, editActionItem, copyActionItem, acceptActionItem, rejectActionItem,
            arrivedActionItem, departedActionItem, addBlackListCarActionItem,
        ];
    }

    getSorting() {
        return [{columnId: 'number', columnName: 'number', direction: 'desc'}];
    }

    getCustomGridFilters() {
        return [
            {
                path: ['status', 'name'],
                columnName: "statusName",
                entityName: "requestStatuses",
                operation: "contains",
                outputValue: "",
                type: "text",
            },
            {
                path: ['type', 'shortName'],
                columnName: "typeShortName",
                entityName: "requestTypes",
                operation: "contains",
                outputValue: "",
                type: "text",
            },
            {
                path: 'actualCreationDate',
                columnName: "actualCreationDate",
                operation: "dateRange",
                outputValue: "",
                type: "datetime"
            },
            {
                path: 'companyName',
                columnName: "companyName",
                operation: "contains",
                outputValue: "",
                type: "text"
            }
        ]
    }

    getFeatures() {
        const {viewName} = this.props;
        return [
            {name: settings.CLEAR_FILTER_BUTTON, value: viewName === 'all'},
        ];
    }

    render() {
        const {onActionExecuting, viewName} = this.props;

        return (
            <AdvancedMobileGrid
                entityName="requests"
                name={RECEPTION_REQUESTS_TABLE_GRID}
                actionButtons={this.getActionButtons()}
                actionMenuItems={this.getActionMenuItems()}
                detailRowFields={this.getDetailRowFields()}
                filters={this.getFilters()}
                getRowHeight={getRowHeight}
                onActionExecuting={onActionExecuting}
                rowComponent={RowComponent}
                sorting={this.getSorting()}
                viewName={viewName}
                customGridFilters={this.getCustomGridFilters()}
                features={this.getFeatures()}
            />
        );
    }
}

ReceptionMobileGrid.propTypes = {
    onActionExecuting: PropTypes.func,
    viewName: PropTypes.string,
};

export default ReceptionMobileGrid;
