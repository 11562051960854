/**
 *
 * SightingView sagas
 *
 */

import { call, select, put, takeLatest } from 'redux-saga/effects';

import { makeSelectEntityId, makeSelectCurrentFieldValue } from 'containers/AdvancedForm/selectors';
import * as api from 'utils/api';
import {
  openAlertDialog,
} from 'containers/AlertDialog/actions';
import { loadData } from 'containers/AdvancedDataTable/actions';

import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import { openAgreementDialog } from 'pages/Documents/common/AgreementDialog/actions';
import { openDisagreementDialog } from 'pages/Documents/common/DisagreementDialog/actions';
import { saveDialogSaga } from 'containers/AdvancedFormDialog/sagas';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import {
  closeDialog,
  openViewDialog,
  openAddDialog,
  openEditDialog,
} from 'containers/AdvancedFormDialog/actions';

import {
  SIGHTING_AGREEMENT_FORM_DIALOG,
  SIGHTING_DISAGREEMENT_FORM_DIALOG,
  SIGHTING_DOCUMENTS_DX_GRID,
  SIGHTING_DOCUMENTS_FORM_DIALOG,
  EXECUTE_AGREE_ACTION,
  EXECUTE_DISAGREE_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_ADD_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_AGREE_DIALOG_ACTION,
  EXECUTE_DISAGREE_DIALOG_ACTION,
  EXECUTE_RUN_DIALOG_ACTION,
} from './constants';

import { USER_TASK_FORM_DIALOG } from 'pages/Tasks/TaskView/constants';
import { clearDetailRowData } from 'containers/AdvancedForm/actions'
import { AGREEMENT_HISTORIES, TASKS } from 'domain/Data/constants';
import { TASKS_GRID } from 'pages/common/constants';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && (
  action.meta.dialog === SIGHTING_DOCUMENTS_FORM_DIALOG
  || action.meta.dialog === SIGHTING_AGREEMENT_FORM_DIALOG
  || action.meta.dialog === SIGHTING_DISAGREEMENT_FORM_DIALOG
  || action.meta.dialog === USER_TASK_FORM_DIALOG
);

export default function createSightingViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_AGREE_ACTION, (config.executeAgreeActionSaga || executeAgreeActionSaga));
    yield takeLatest(EXECUTE_DISAGREE_ACTION, (config.executeDisagreeActionSaga || executeDisagreeActionSaga));
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_ADD_ACTION, (config.executeAddActionSaga || executeAddActionSaga));
    yield takeLatest(EXECUTE_EDIT_ACTION, (config.executeEditActionSaga || executeEditActionSaga));
    yield takeLatest(EXECUTE_AGREE_DIALOG_ACTION, (config.executeAgreeDialogActionSaga || executeAgreeDialogActionSaga));
    yield takeLatest(EXECUTE_DISAGREE_DIALOG_ACTION, (config.executeDisagreeDialogActionSaga || executeDisagreeDialogActionSaga));
    yield takeLatest(EXECUTE_RUN_DIALOG_ACTION, (config.executeRunDialogActionSaga || executeRunDialogActionSaga));
    yield takeLatest(needViewRefresh, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  const mode = yield select(makeSelectViewMode());
  if (mode === 'mobile') {
    yield put(loadMobileData(SIGHTING_DOCUMENTS_DX_GRID))
  } else yield put(loadData(SIGHTING_DOCUMENTS_DX_GRID));
}

export function* executeAgreeActionSaga({ payload: { ids } }) {
  if (ids) {
    yield put(openAgreementDialog(ids));
  }
}

export function* executeDisagreeActionSaga({ payload: { ids } }) {
  if (ids) {
    yield put(openDisagreementDialog(ids));
  }
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(SIGHTING_DOCUMENTS_FORM_DIALOG, { id }));
  }
}

export function* executeEditActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(SIGHTING_DOCUMENTS_FORM_DIALOG, { id }));
  }
}

export function* executeAddActionSaga() {
  yield put(clearDetailRowData("AgreementList", AGREEMENT_HISTORIES))
  yield put(clearDetailRowData(TASKS_GRID, TASKS))
  yield put(openAddDialog(SIGHTING_DOCUMENTS_FORM_DIALOG, {}));
}

export function* executeAgreeDialogActionSaga({ meta }) {
  const entityId = yield select(makeSelectEntityId(meta.form));
  yield put(openAgreementDialog([entityId]));
  yield put(closeDialog(meta.dialog));
}

export function* executeDisagreeDialogActionSaga({ meta }) {
  const entityId = yield select(makeSelectEntityId(meta.form));
  yield put(openDisagreementDialog([entityId]));
  yield put(closeDialog(meta.dialog));
}

export function* executeRunDialogActionSaga({ meta }) {
  const agreementScheme = yield select(makeSelectCurrentFieldValue(meta.form, 'agreementScheme'));
  if (agreementScheme) {
    let entityId = yield select(makeSelectEntityId(meta.form));
    if (entityId === undefined) {
      const saveDialogResult = yield call(saveDialogSaga, meta.dialog);
      entityId = saveDialogResult && saveDialogResult.id;
    }
    try {
      yield call(api.runDocument, entityId);
      yield put(closeDialog(meta.dialog));
      yield put(loadData(SIGHTING_DOCUMENTS_DX_GRID));
    } catch (err) {
      console.error(err);
      const header = 'Операция прервана';
      let content = 'Произошла ошиюка на сервере при обработке документа.';
      const { response } = err;
      if (response && response.data) {
        const data = JSON.parse(response.data);
        if (data && data.message) {
          content = data.message;
        }
      }
      yield put(openAlertDialog(header, content));
    }
  } else {
    const header = 'Операция прервана';
    let content = 'Введите название маршрута';
    yield put(openAlertDialog(header, content));
  }
}