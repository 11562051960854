/**
 *
 * DocumentMatchingFormHeader actions
 *
 */

import {
  COPY_DOCUMENT_DETAILS,
  FIND_DOCUMENT_DETAILS,
  FIND_DOCUMENT_DETAILS_SUCCESS,
  FIND_DOCUMENT_DETAILS_ERROR,
} from './constants';

export function copyDocumentDetails(advancedForm, documentDetails) {
  return {
    type: COPY_DOCUMENT_DETAILS,
    meta: { advancedForm },
    payload: { documentDetails },
  };
}

export function findDocumentDetails(advancedForm) {
  return {
    type: FIND_DOCUMENT_DETAILS,
    meta: { advancedForm },
  };
}

export function documentDetailsFound(visitorFullName, documentDetails, requestNumber, visitDate) {
  return {
    type: FIND_DOCUMENT_DETAILS_SUCCESS,
    payload: { visitorFullName, documentDetails, requestNumber, visitDate },
  };
}

export function documentDetailsError(error) {
  return {
    type: FIND_DOCUMENT_DETAILS_ERROR,
    payload: { error },
  };
}
