/**
 *
 * ReceptionView constants
 *
 */


export const RECEPTION_COMPANIES_DX_GRID = 'ReceptionCompaniesDxGrid';
export const RECEPTION_COMPANIES_FORM = 'ReceptionCompaniesForm';
export const RECEPTION_COMPANIES_FORM_DIALOG = 'ReceptionCompaniesFormDialog';

export const EXECUTE_ADD_ACTION = 'claris-vnext/CompaniesPage/ReceptionView/EXECUTE_ADD_ACTION';
export const EXECUTE_EDIT_ACTION = 'claris-vnext/CompaniesPage/ReceptionView/EXECUTE_EDIT_ACTION';
export const EXECUTE_VIEW_ACTION = 'claris-vnext/CompaniesPage/ReceptionView/EXECUTE_VIEW_ACTION';
export const EXECUTE_COPY_ACTION = 'claris-vnext/CompaniesPage/ReceptionView/EXECUTE_COPY_ACTION';

export const COMPANY_ARCHIVE_STATUS = 'Архив';