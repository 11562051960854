/*
 *
 * Snackbar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import InitialSnackbar from '@material-ui/core/Snackbar';

class Snackbar extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      open,
      autoHideDuration,
      onClose,
      message,
    } = this.props;
    const styles = {};

    return (
      <InitialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={
          <span>{message}</span>
        }
        style={styles}
      />
    );
  }
}

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default Snackbar;
