/*
 *
 * SupplyRequestMobileGrid15767
 *
 */


import { columnType } from 'domain/typeConstants/table';
import SupplyRequestMobileGrid from 'pages/Documents/SupplyRequestView/SupplyRequestMobileGrid';
import { statusColorLabels } from 'pages/Documents/common/mobile/mobileStatusLabels';
import { RowComponent } from './mobileRowTemplate';
import { editActionItem } from 'pages/Documents/common/actionMenu';

export class SupplyRequestMobileGrid15767 extends SupplyRequestMobileGrid {


    getDetailRowFields() {
        return [
            {
                path: 'autoNumber',
                name: 'autoNumber',
                title: '№',
            },
            {
                path: 'state',
                name: 'state.name',
                title: 'Состояние',
                getValue: (row) => row.state ? row.state.name : undefined,
                labels: statusColorLabels,
                type: columnType.LABEL,
            },
            {
                path: 'actualCreationDate',
                name: 'actualCreationDate',
                title: 'Дата',
                type: 'date',
            },
            {
                path: 'project',
                name: 'project',
                getValue: (row) => row.project ? row.project.name : undefined,
                title: 'Проект',
            },
            {
                path: 'content',
                name: 'content',
                title: 'Содержание',
            },
        ];
    }

    getActionMenuItems() {
        return [editActionItem];
    };

    getRowComponent() { return RowComponent }

    getSorting() {
        return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
    };

}
