/**
 *
 * ChangeRoleDialog sagas
 *
 */

import {call, takeLatest, put} from 'redux-saga/effects';
import {replace} from 'react-router-redux';
import * as api from 'utils/api';
import {
    getValueFromLocalStorage,
    setValueToLocalStorage,
} from 'utils/localStorage';
import {role as roleGroup} from 'domain/typeConstants/roles';
import {ACCEPT_CHANGE_ROLE_DIALOG} from './constants';
import {account} from "../../domain/typeConstants/accounts";
import {loadNavigationItems} from "../../domain/Navigation/actions";


export default function* rootSaga() {
    yield takeLatest(ACCEPT_CHANGE_ROLE_DIALOG, acceptChangeRoleDialogSaga);
}

function* acceptChangeRoleDialogSaga({payload: {role}}) {
    yield call(api.changeRole, role);
    const user = getValueFromLocalStorage('claris-vnext-global-user');
    if (user) {
        const removedRoles = [
            roleGroup.ADMINISTRATOR_BC,
            roleGroup.APPLICANT,
            roleGroup.ASSIGNED,
            roleGroup.CHECKPOINT,
            roleGroup.CHECKPOINT_OPERATOR,
            roleGroup.MAKING_AGREEMENT_BC,
            roleGroup.PARKING_OPERATOR,
            roleGroup.RECEPTION,
            roleGroup.SENIOR_PARKING_OPERATOR,
            roleGroup.SERVICE_REQUESTS_MANAGER,
            roleGroup.SIGHTING,
            roleGroup.TENANT,
            roleGroup.UNLOADING_ZONE_OPERATOR,
            roleGroup.USER,
        ];

        user.roles = user.roles ?
            [...user.roles.filter((item) => !removedRoles.includes(item)), role] :
            [role];

        setValueToLocalStorage('claris-vnext-global-user', user);

        yield* replaceUrlPath(role);
        location.reload(); // eslint-disable-line no-restricted-globals
    }
}

function* replaceUrlPath(role) {
    const user = getValueFromLocalStorage('claris-vnext-global-user');
    yield put(loadNavigationItems(user))

    let path = '/requests?view=all'

    if (role === roleGroup.CHECKPOINT) {
        path = '/requests?view=today';
    } else if (role === roleGroup.MAKING_AGREEMENT_BC) {
        path = '/requests?view=agree';
    } else if (role === roleGroup.SIGHTING) {
        path = '/documents?view=forMyVisa';
    } else if (user.accountId === account.COMCITY
        && [roleGroup.RECEPTION, roleGroup.PARKING_OPERATOR, roleGroup.CHECKPOINT_OPERATOR, roleGroup.UNLOADING_ZONE_OPERATOR].includes(role)) {
        path = '/requests?view=today';
    } else if (user.accountId === account.BC_BELIYE_SADY && [roleGroup.ADMINISTRATOR_BC, roleGroup.TENANT, roleGroup.RECEPTION].includes(role)) {
        path = '/requests?view=all';
    } else if (role === roleGroup.USER) {
        path = '/tasks?view=activeTask';
    } else if ([roleGroup.ADMINISTRATOR_BC, roleGroup.RESTRICTED_ADMINISTRATOR_BC].includes(role)) {
        path = '/requests?view=today';
    } else if ([roleGroup.SERVICE_REQUESTS_MANAGER, roleGroup.TENANT, roleGroup.RECEPTION].includes(role)) {
        path = '/requests?view=active';
    } else if (role === roleGroup.SUPPLY_APPLICANT) {
        path = '/supplyRequests?view=allSupplyRequests';
    } else if (role === roleGroup.ASSIGNED && user.accountId === account.BINOM) {
        path = '/requests?view=active';
    }

    yield put(replace(path));
}
