import React from 'react';
import styled from 'styled-components';
import TextField from 'containers/AdvancedForm/TextField';

const TextFieldWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 40px);
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
`;

const KrylatskyTextFieldWrapper = styled.div`
  display: inline-block;
  width: 100%;
  
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #023972 inset;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #023972 inset;
  }

   input {
    -webkit-text-fill-color: white;
   }
  label {
    -webkit-text-fill-color: grey
   }
`;

function StyledTextField(props) {
  return (props.isKrylatsky
    ? <KrylatskyTextFieldWrapper><TextField {...props} /></KrylatskyTextFieldWrapper>
    : <TextFieldWrapper><TextField {...props} /></TextFieldWrapper>

  );
}

export default StyledTextField;
