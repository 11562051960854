/**
 *
 * MultiAutocomplete selectors
 *
 */

import { createSelector } from 'reselect';
import { makeSelectEntities } from 'domain/Data/selectors';

import {
  makeSelectFields,
  makeSelectCurrentValues,
  makeSelectMode,
} from '../selectors';

const makeSelectDomain = (formName, fieldName) => createSelector(
  makeSelectFields(formName),
  (fields) => fields.get(fieldName),
);

const makeSelectValue = (formName, fieldName) => createSelector(
  makeSelectCurrentValues(formName),
  (formValues) => formValues[fieldName],
);

const makeSelectIsInitialized = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  (field) => (field ? field.isInitialized : null),
);

const makeSelectEntity = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  makeSelectValue(formName, fieldName),
  makeSelectEntities(),
  (field, value, entities) => entities.get(field.entityName).valueSeq().toJS(),
);

const makeSelectValues = (formName, fieldName) => createSelector(
  makeSelectDomain(formName, fieldName),
  makeSelectValue(formName, fieldName),
  makeSelectEntities(),
  makeSelectMode(formName),
  (field, value, entities) => {
    if (!value || (Array.isArray(value) && value.length === 0)) return [];

    const normalizedEntities = entities.get(field.entityName);
    return value
      .map((item) => normalizedEntities.get(item))
      .filter((item) => item != null);
  },
);

const makeSelectLoadedOptions = (formName, fieldName) => createSelector(
  makeSelectEntities(),
  makeSelectDomain(formName, fieldName),
  (entities, field) => {
    let values = entities
      .get(field.entityName)
      .filter((entity, id) => field.loadedOptions && field.loadedOptions.includes(id))
      .map((entity, id) => ({ value: id, text: entity.name, orderValue: entity[field.orderBy] }))
      .valueSeq()
      .toJS();

    if (field.orderBy) {
      values = values.sort((a, b) => {
        if (a.orderValue > b.orderValue) return 1;
        if (a.orderValue < b.orderValue) return -1;
        return 0;
      });
    }

    return values;
  },
);

export default makeSelectDomain;
export {
  makeSelectValue,
  makeSelectEntity,
  makeSelectLoadedOptions,
  makeSelectValues,
  makeSelectIsInitialized,
};
