/**
 *
 * DocumentsGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnSize, documentColumnSizes } from 'domain/typeConstants/table';
import { DOCUMENTS_DX_GRID } from '../../constants';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';
import { DOCUMENTS } from 'domain/Data/constants';
import { selectActionItem } from 'pages/Documents/common/actionMenu';

class DocumentDxGrid extends React.PureComponent {

    getFilters() {
        const filters = [];
        return filters;
    }

    getColumns() {
        return [
            {
                name: 'state',
                path: 'state.name',
                title: 'Состояние',
                getCellValue: (row) => row.state ? row.state.name : undefined,
                entityName: 'documentStates',
                width: documentColumnSizes.stateName,
            },
            {
                path: 'number',
                name: 'number',
                title: '№',
                width: columnSize.NUMERIC,
            },
            {
                path: 'actualCreationDate',
                name: 'actualCreationDate',
                title: 'Дата',
                type: 'date',
                width: columnSize.DATE,
            },
            {
                name: 'category',
                path: 'category.name',
                title: 'Тип',
                getCellValue: (row) => row.category ? row.category.name : undefined,
                width: documentColumnSizes.categoryName,
            },
            {
                name: 'company',
                path: 'company.name',
                title: 'Контрагент',
                getCellValue: (row) => row.company ? row.company.name : undefined,
                width: documentColumnSizes.companyName,
            },
            {
                path: 'content',
                name: 'content',
                title: 'Содержание',
                width: documentColumnSizes.content,
            },
            {
                path: 'amount',
                name: 'amount',
                title: 'Сумма',
                width: documentColumnSizes.amount,
            },
            {
                path: 'sightingEmployeesText',
                name: 'sightingEmployeesText',
                title: 'Визирующие сотрудники',
                getCellValue: (row) => row.agreementStep ? row.agreementStep.sightingEmployeesText : undefined,
                width: documentColumnSizes.sightingEmployeesText,
            },
            {
                name: 'file',
                path: ['file','id'],
                getCellValue: (row) => row.file ? row.file.id : undefined,
                title: 'Файл',
                type: columnType.FILE,
                width: columnSize.FILE,
            },
            {
                name: 'scan',
                path: ['scan','name'],
                getCellValue: (row) => row.scan ? row.scan.id : undefined,
                title: 'Скан',
                type: columnType.FILE,
                width: columnSize.FILE,
            },
        ];
    }

    getColumnOrder() {
        return [
            'state', 'number', 'actualCreationDate', 'category', 'company', 'content', 'amount',
            'sightingEmployeesText', 'file', 'scan',
        ];
    }

    getActionButtons() {
        return [];
    }

    getActionMenuItems() {
        return [selectActionItem]
    }

    getSorting() {
        return [{ columnId: 'actualCreationDate', columnName: 'actualCreationDate', direction: 'desc' }];
    }

    getPageSize() {
        return 10;
    }

    getTableFeatures() {
        return [
            { name: settings.TABLE_ROW_DETAIL, value: false },
            { name: settings.TABLE_DRAWER, value: false },
            { name: settings.SETTING_BUTTON, value: false },
            { name: settings.UPDATE_BUTTON, value: false }
        ];
    }

    render() {
        const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

        return (
            <AdvancedDataTable
                name={DOCUMENTS_DX_GRID}
                entityName={DOCUMENTS}
                actionButtons={this.getActionButtons()}
                actionMenuItems={this.getActionMenuItems()}
                columnOrder={this.getColumnOrder()}
                columns={this.getColumns()}
                filters={this.getFilters()}
                pageSize={this.getPageSize()}
                onActionExecuting={onActionExecuting}
                onRowDoubleClick={onRowDoubleClick}
                sorting={this.getSorting()}
                tableFeatures={this.getTableFeatures()}
                viewName={viewName}
                filtering
                selectable
                tableType={settings.PAGING_TABLE}
                mode="parent"
            />
        );
    }
}

DocumentDxGrid.propTypes = {
    viewName: PropTypes.string,
    onActionExecuting: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
};

export default DocumentDxGrid;
