/**
 *
 * VisitorArrivalForm14419
 *
 */

import { VisitorArrivalForm } from 'pages/Requests/ReceptionView';
import { CARRYING_OUT_WORKS, ON_CAR_ENTRY } from 'domain/Data/RequestType/constants';
import { validateCarNumber } from 'utils/validators';

export class VisitorArrivalForm14419 extends VisitorArrivalForm {
  getFields() {
    const fields = super.getFields();

    const passNumberIndex = fields.findIndex((field) => field.name === 'passNumber');
    fields[passNumberIndex].visible = (values) => values.type === ON_CAR_ENTRY || values.type === CARRYING_OUT_WORKS;

    const companyIndex = fields.findIndex((field) => field.name === 'company');

    fields.splice(companyIndex + 1, 0, {
      name: 'carNumber',
      label: '№ автомобиля',
      visible: (values) => values.type === ON_CAR_ENTRY,
      normalize: (value) => value ? value.replace(' ', '') : value,
      validate: validateCarNumber,
      rowBreakBefore: true,
    },
    {
      name: 'carModelText',
      label: 'Марка автомобиля',
      visible: (values) => values.type === ON_CAR_ENTRY,
    });

    return fields;
  }
}