/*
 *
 * UsersView actions
 *
 */

import {
  EXECUTE_ADD_ACTION,
  EXECUTE_VIEW_ACTION,
  EXECUTE_EDIT_ACTION,
  EXECUTE_CLOSE_ACTION,
  EXECUTE_BLOCK_ACTION,
  BLOCK_USER,
  USER_BLOCKED,
  USER_BLOCKED_ERROR,
} from './constants';

export function executeAddAction() {
  return {
    type: EXECUTE_ADD_ACTION,
  };
}

export function executeViewAction(id) {
  return {
    type: EXECUTE_VIEW_ACTION,
    payload: { id },
  };
}

export function executeEditAction(id) {
  return {
    type: EXECUTE_EDIT_ACTION,
    payload: { id },
  };
}

export function executeCloseAction(ids) {
  return {
    type: EXECUTE_CLOSE_ACTION,
    payload: { ids },
  };
}

export function executeBlockAction(id) {
  return {
    type: EXECUTE_BLOCK_ACTION,
    payload: { id },
  };
}

export function blockUser() {
  return {
    type: BLOCK_USER,
  };
}

export function userBlocked() {
  return {
    type: USER_BLOCKED,
  };
}

export function userBlockedError(error) {
  return {
    type: USER_BLOCKED_ERROR,
    payload: { error },
  };
}