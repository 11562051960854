/**
 *
 * ServiceRequestsManagerForm14326
 *
 */


import React from 'react';
import { ReceptionForm14326 } from '../ReceptionView';
import { helperTextClassName } from '../common/formClassNames';

import {
  whereHappenedLabel,
  whatHappenedLabel,
  implementationReportLabel,
  memorandumScan,
} from '../common/fields';
import {
  ACCEPTED,
  CLOSED,
  COMPLETED,
  CREATED,
  IN_PROGRESS, ON_AGREEMENT,
  ON_AGREEMENT_BY_TENANT,
  REJECTED,
  SEND_TO_PERFORMER,
} from 'domain/Data/RequestStatus/constants';
import { SERVICE, CARRYING_OUT_WORKS } from 'domain/Data/RequestType/constants';
import AdvancedForm from 'containers/AdvancedForm/AdvancedFormCreator';
import { SERVICE_REQUESTS_MANAGER_REQUESTS_FORM } from 'pages/Requests/ServiceRequestsManagerView/constants';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';
import { REQUESTS } from 'domain/Data/constants';

export class ServiceRequestsManagerForm14326 extends ReceptionForm14326 {
  getClassNames() {
    return {
      helperText: helperTextClassName,
    };
  }

  getFields() {
    const fields = super.getFields();

    const typeIndex = fields.findIndex((fieldName) => fieldName.name === 'type');
    fields[typeIndex].initialValue = (values, mode) => {
      if (mode === 'copy') {
        return values.type;
      }
      return SERVICE;
    };
    fields[typeIndex].visibleOptions = [SERVICE, CARRYING_OUT_WORKS];
    fields[typeIndex].orderBy = 'order';

    const whatHappenedIndex = fields.findIndex((field) => field.name === 'whatHappened');
    fields[whatHappenedIndex].label = whatHappenedLabel;

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].visibleOptions = () => [COMPLETED, CLOSED, REJECTED, ACCEPTED, CREATED, IN_PROGRESS, ON_AGREEMENT, ON_AGREEMENT_BY_TENANT, SEND_TO_PERFORMER];

    const whereHappenedIndex = fields.findIndex((field) => field.name === 'whereHappened');
    fields[whereHappenedIndex].label = whereHappenedLabel;

    const implementationReportIndex = fields.findIndex((field) => field.name === 'implementationReport');
    fields[implementationReportIndex].label = implementationReportLabel;

    const memorandumScanIndex = fields.findIndex((field) => field.name === 'memorandumScan');
    fields.splice(memorandumScanIndex, 1, memorandumScan)
    return fields;
  }

  render() {
    const accountId = getValueFromLocalStorage(GLOBAL_USER_KEY).accountId
    return (
      <AdvancedForm
        name={SERVICE_REQUESTS_MANAGER_REQUESTS_FORM}
        entityName={REQUESTS}
        fields={this.getFields()}
        classNames={this.getClassNames()}
        accountId={accountId}
        {...this.props}
      />
    );
  }
}
