/*
 *
 * TenantAgreedEstimationCostFormDialog14720
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import {
    TENANT_AGREED_ESTIMATION_COST_FORM,
    TENANT_AGREED_ESTIMATION_COST_FORM_DIALOG
} from "pages/Requests/TenantView/constants";


export function TenantAgreedEstimationCostFormDialog14720({ form }) {
    return (
        <AdvancedFormDialog
            name={TENANT_AGREED_ESTIMATION_COST_FORM_DIALOG}
            formName={TENANT_AGREED_ESTIMATION_COST_FORM}
            advancedForm={form}
            editModeTitle="Согласовать заявку"
        />
    );
}

TenantAgreedEstimationCostFormDialog14720.propTypes = {
    form: PropTypes.node.isRequired,
};
