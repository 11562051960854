/**
 *
 * TenantForm14366
 *
 */

import { TenantForm } from 'pages/Requests/TenantView';
import {
  ON_CAR_ENTRY, ON_PASS_MAKING,
  ON_PROPERTY_BRINGING,
  ON_PROPERTY_REMOVAL,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { ACCEPTED, CREATED } from 'domain/Data/RequestStatus/constants';
import { BULVAR_TSVETNOY, MELIORA_PLACE } from '../../../domain/Data/Departments/constants';
import { assignee, department } from '../common/fields';
import { role as roleType } from 'domain/typeConstants/roles';
import { getValueFromLocalStorage } from 'utils/localStorage';
import { GLOBAL_USER_KEY } from 'global-constants';

export class TenantForm14366 extends TenantForm {
  getFields() {
    const fields = super.getFields();

    const companyIndex = fields.findIndex((field) => field.name === 'company');
    fields[companyIndex].type = 'autocomplete';

    const statusIndex = fields.findIndex((field) => field.name === 'status');

    fields[statusIndex].calculatedValue = (values, mode) => {
      if (new Set([SERVICE, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL]).has(values.type)
        && values.department === BULVAR_TSVETNOY) {
        return CREATED;
      }
      if (values.type === ON_CAR_ENTRY) { return CREATED; }
      if (mode === 'add' || mode === 'copy') { return ACCEPTED; }

      return values.status;
    };

    const departmentId = getValueFromLocalStorage(GLOBAL_USER_KEY).departmentId
    department.disabled = (currentValues, initialValues, mode) => mode === 'add' || mode === 'copy'

    const whoMeetsIndex = fields.findIndex((field) => field.name === 'whoMeets');
    fields.splice(whoMeetsIndex + 1, 0, department);

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields.splice(assigneeIndex, 1, assignee)


    const currentRoles = getValueFromLocalStorage(GLOBAL_USER_KEY).roles;
    const checkRole = currentRoles.find((role) => (role === roleType.TENANT));

    const whereHappenedIndex = fields.findIndex((field) => field.name === 'whereHappened');
    fields[whereHappenedIndex].required = true;
    fields[whereHappenedIndex].label = 'Где произошло/Офис';
    
    const payableService =
    {
      name: 'payableService',
      label: 'Платная услуга',
      type: 'checkbox',
      required: true,
      visible: (values) => values.type === ON_PASS_MAKING && checkRole && departmentId === MELIORA_PLACE
    }
    fields.push(payableService)

    return fields;
  }
}
