/**
 *
 * createComboBoxContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, clearSubmitErrors } from 'redux-form/immutable';

import ComboBox from './ComboBox';
import * as actionCreators from './actions';
import * as advancedFormActionCreators from '../actions';
import {
  makeSelectIsLoaded,
  makeSelectIsCached,
  makeSelectOptions,
} from './selectors';
import {
  makeSelectRecord,
  makeSelectMode,
} from '../selectors';

function createComboBoxContainer(formName, fieldName) {
  class ComboBoxContainer extends React.PureComponent {

    componentWillMount() {
      const {
        loadOptions,
        mode,
      } = this.props;

      if (mode === null || mode === 'view') return;
      loadOptions();
    }

    handleChange = () => {
      if (this.props.clearErrorsOnChange) {
        this.props.clearErrors();
      }
      this.props.calculateFieldsValues();
    };

    render() {
      const {
        loadOptions,
        clearErrorsOnChange,
        calculateFieldsValues,
        clearErrors,
        record,
        multiple,
        disabled,
        ...restProps
      } = this.props;

      return (
        <Field
          {...restProps}
          onChange={this.handleChange}
          component={ComboBox}
          disabled={disabled}
        />
      );
    }
  }

  ComboBoxContainer.contextTypes = {
    advancedForm: PropTypes.string.isRequired,
  };

  ComboBoxContainer.propTypes = {
    cached: PropTypes.bool.isRequired,
    calculateFieldsValues: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearErrorsOnChange: PropTypes.bool,
    loaded: PropTypes.bool.isRequired,
    loadOptions: PropTypes.func.isRequired,
    mode: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    record: PropTypes.object,
  };

  const mapStateToProps = createStructuredSelector({
    loaded: makeSelectIsLoaded(formName, fieldName),
    cached: makeSelectIsCached(formName, fieldName),
    options: makeSelectOptions(formName, fieldName),
    record: makeSelectRecord(formName),
    mode: makeSelectMode(formName),
  });

  const mapDispatchToProps = (dispatch) => ({
    loadOptions: () => dispatch(actionCreators.loadOptions(formName, fieldName)),
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
    clearErrors: () => dispatch(clearSubmitErrors(formName)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ComboBoxContainer);
}

export default createComboBoxContainer;
