/**
 *
 * DisagreementDialog actions
 *
 */

import {
  CLOSE_DISAGREEMENT_DIALOG,
  OPEN_DISAGREEMENT_DIALOG,
  SUBMIT_DISAGREEMENT_DIALOG,
} from './constants';

export function openDisagreementDialog(ids, dxGrid) {
  return {
    type: OPEN_DISAGREEMENT_DIALOG,
    payload: { ids, dxGrid },
  };
}

export function submitDisagreementDialog(ids, comment, dxGrid) {
  return {
    type: SUBMIT_DISAGREEMENT_DIALOG,
    payload: {
      ids,
      comment,
      dxGrid,
    },
  };
}

export function closeDisagreementDialog() {
  return {
    type: CLOSE_DISAGREEMENT_DIALOG,
  };
}
