/*
 *
 * ServiceRequestsManagerRejectForm14326
 *
 */

import { ServiceRequestsManagerRejectForm } from 'pages/Requests/ServiceRequestsManagerView';

export class ServiceRequestsManagerRejectForm14326 extends ServiceRequestsManagerRejectForm {
    getFields() {
        const fields = super.getFields();
        fields.push({
            name: 'managementCompanyComment',
            label: 'Комментарий Администрации ХБП',
            grid: { sm: 12 },
            required: true,
        },);
        return fields;
    }
}
