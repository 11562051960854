/**
 *
 * MakingAgreementBCView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function MakingAgreementBCView({ dataTable, formDialog, rejectFormDialog, viewName, viewStyles }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { formDialog }
      { rejectFormDialog }
    </div>
  );
}

MakingAgreementBCView.propTypes = {
  dataTable: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  rejectFormDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
  viewStyles: PropTypes.object,
};

MakingAgreementBCView.propTypes = {
  viewStyles: {},
};

export default MakingAgreementBCView;
