/**
 *
 * status types
 *
 */

export const requestStatus = Object.freeze({
  ACCEPTED: 'Принята',
  CLOSED: 'Закрыта',
  COMPLETED: 'Выполнена',
  CREATED: 'Создана',
  IN_PROGRESS: 'Выполняется',
  ON_AGREEMENT: 'На согласовании',
  ON_TERRITORY: 'На территории',
  PASS_ISSUED: 'Выдан пропуск',
  REJECTED: 'Отклонена',
  REPEATED: 'Повторная',
  RETURNED: 'Возвращена',
  ON_AGREEMENT_BY_TENANT: 'У Арендатора',
  AGREED: 'Согласована',
  /* ------------------- Gorkiy park BC 14720 ------------------- */
  AGREED_ESTIMATION_COST: 'Смета согласована',
  AGREEMENT_ESTIMATION_COST: 'Согласование сметы',
  IS_DONE: 'Сделана',
  WAIT_S3: 'Ожидает S3',
  SEND_TO_PERFORMER: 'У Исполнителя',
  NO_PLACES: 'Нет мест',
  SEND_TO_TENANT: 'Отправлен Арендатору',
  PASS_MADE: 'Пропуск Изготовлен',
  /* ------------------- Binom 12861 ------------------- */
  REPAIR: 'Ремонт',
  POSTPONED: 'Отложена',
  PAINTING: 'Покраска',
  WELDING: 'Сварка',
});

export const documentStatus = Object.freeze({
  AGREED: 'Согласован',
  ARCHIVED: 'Сдан в архив',
  IN_OPERATION: 'В работе',
  ON_AGREEMENT: 'На согласовании',
  POSTPONED: 'Отложен',
  REJECTED: 'Отклонен',
  WAITING_FOR_PAYMENT: 'Ждет оплату',
});

export const documentPositionStatus = Object.freeze({
  SENT: 'Отправлена',
  ORDERED: 'Заказана',
  REJECTED: 'Отказана',
  CLARIFICATION: 'Уточнение',
  COMPLETED: 'Выполнена',
});

export const supplierInvoiceState = Object.freeze({
  PROCESSED: 'Обработан',
  IN_PROGRESS: 'В работе',
  POSTPONED: 'Отложен',
  FILED_ARCHIVE: 'Сдан в архив',
  PENDING_APPROVAL: 'На согласовании',
  AGREED: 'Согласован',
  PENDING_PAIMENT: 'Ждет оплату',
  PAID: 'Оплачен',
});

export const agreementHistory = Object.freeze({
  AGREED: 'Согласовано',
  REJECTED: 'Отклонено',
  COMPLETED: 'Завершено',
});

export const taskStatus = Object.freeze({
  IN_PROGRESS: 'Выполняется',
  CLOSED: 'Закрыта',
  CREATED: 'Создана',
  COMPLETED: 'Сделана',
  PAUSED: 'Пауза',
  /*----------AltSoft------------------*/
  TO_PROGRESS: '3 На выполнение',
  IN_PAUSE: '7 На паузе',
  TO_UPDATE: '9 Обновить',
  DONE: '99 Сделано',
  EVALUATE: '1 Оценить',
  TESTING: '5 Тестирование',
  AT_CLIENT: '6 У клиента',
  RETURN: '8 Возврат/Уточнение',
  PERFORMED: '4 Выполняется',
  REGISTRATION: '0 Регистрация',
  RATED: '2 Оценено',
});

export const taskStatusId = Object.freeze({
  COMPLETED: '2175174170000',
  CLOSED: '643412000',
});
