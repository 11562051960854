import React from 'react';
import styled from 'styled-components';
import TextField from 'containers/AdvancedForm/TextField';

const TextFieldWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 40px);
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
`;

function StyledTextField(props) {
  return (
    <TextFieldWrapper>
      <TextField {...props} />
    </TextFieldWrapper>
  );
}

export default StyledTextField;
