/**
 *
 * ReceptionView12924 sagas
 *
 */

import { put } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import { RECEPTION_REQUESTS_TABLE_GRID } from 'pages/Requests/ReceptionView/constants';

function* executeNeedViewRefreshSaga() {
  yield put(loadData(RECEPTION_REQUESTS_TABLE_GRID));
}

export const receptionViewSaga = {
  executeNeedViewRefreshSaga,
};
