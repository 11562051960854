/**
 *
 * SupplyPositionView sagas
 *
 */

import { select, put, takeLatest, call } from 'redux-saga/effects';
import { loadData } from 'containers/AdvancedDataTable/actions';
import { loadData as loadMobileData } from 'containers/AdvancedMobileGrid/actions';
import { makeSelectViewMode } from 'domain/Screen/selectors';
import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { openEditDialog } from 'containers/AdvancedFormDialog/actions';
import { makeSelectEntity } from 'domain/Data/selectors';
import {
  SUPPLY_POSITION_DX_GRID,
  EXECUTE_CARGO_RECEIVED_ACTION,
  EXECUTE_ORIGINALS_DELIVERED_ACTION,
  SUPPLY_REQUEST_POSITION_FORM_DIALOG,
} from './constants';
import { documentSendCargoSaga, documentOriginalsDeliveredSaga } from 'domain/Data/Document/sagas';

const needViewRefresh = (action) => action.type === SAVE_DIALOG_SUCCESS && (
  action.meta.dialog === SUPPLY_REQUEST_POSITION_FORM_DIALOG
);

export default function createSupplyPositionViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_CARGO_RECEIVED_ACTION, executeCargoReceivedSaga);
    yield takeLatest(EXECUTE_ORIGINALS_DELIVERED_ACTION, executeOriginalsDeliveredSaga);
    yield takeLatest(needViewRefresh, executeNeedViewRefreshSaga);
  };
}

function* executeCargoReceivedSaga({ payload: { id } }) {
  if (id) {
    yield put(openEditDialog(SUPPLY_REQUEST_POSITION_FORM_DIALOG, { id }));
    yield call(documentSendCargoSaga, id);
    yield put(loadData(SUPPLY_POSITION_DX_GRID));
  }
}


function* executeOriginalsDeliveredSaga({ payload: { id } }) {
  if (id) {
    const entity = yield select(makeSelectEntity('documentPositions', id));
    const docId = entity?.document?.id
    if (docId) {
      const deliveryOriginals = new Date();
      yield call(documentOriginalsDeliveredSaga, docId, { deliveryOriginals });
      yield put(loadData(SUPPLY_POSITION_DX_GRID));
    }
  }
}

function* executeNeedViewRefreshSaga() {
  const mode = yield select(makeSelectViewMode());
  if (mode === 'mobile') {
    yield put(loadMobileData(SUPPLY_POSITION_DX_GRID))
  } else yield put(loadData(SUPPLY_POSITION_DX_GRID));
}

