/**
 *
 * AdministratorBCForm 14720
 *
 */

import { ReceptionForm14720 } from '../ReceptionView';
import { estimationCost, estimationCostScan } from "../common/dxGridComponents/fields";

export class AdministratorBCRequestsForm14720 extends ReceptionForm14720 {
    getFields() {
        const fields = super.getFields();

        fields.push(estimationCost);
        fields.push(estimationCostScan);

        const typeIndex = fields.findIndex((field) => field.name === 'status');
        fields[typeIndex].disabled = false;

        return fields;
    }
}
