/*
 *
 * NotificationsList reducer
 *
 */

import { Map } from 'immutable';

import {
  DISPLAY_NOTIFICATION,
  LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
  READ_ALL_NOTIFICATIONS_SUCCESS,
} from './constants';

const initialState = new Map({
  unreadCount: null,
});

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_NOTIFICATION:
      return state.set('unreadCount', state.get('unreadCount') + 1);
    case LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      return state.set('unreadCount', action.payload.unreadCount);
    case READ_ALL_NOTIFICATIONS_SUCCESS:
      return state.set('unreadCount', null);

    default:
      return state;
  }
}

export default notificationsReducer;
