/*
 *
 * AdvancedFormDialog reducer
 *
 */

import { Map } from 'immutable';
import {
  CHANGE_LOADING_STATE,
  CHANGE_TAB,
  CLOSE_DIALOG,
  OPEN_DIALOG,
  REGISTER,
  UNREGISTER,
} from './constants';

function advancedFormDialogReducer(state = new Map({}), { type, meta, payload }) {
  switch (type) {
    case OPEN_DIALOG:
      return state
        .setIn([meta.dialog, 'open'], true)
        .setIn([meta.dialog, 'mode'], payload.mode)
        .setIn([meta.dialog, 'entityId'], payload.entityId)
        .setIn([meta.dialog, 'parentId'], payload.parentId)
        .setIn([meta.dialog, 'parent'], payload.parent)
        .setIn([meta.dialog, 'status'], payload.status)
        .setIn([meta.dialog, 'params'], payload.params)
        .setIn([meta.dialog, 'tab'], 0);

    case CHANGE_LOADING_STATE:
      return state.setIn([meta.dialog, 'loadingState'], payload.loadingState);

    case CLOSE_DIALOG:
      return state
        .setIn([meta.dialog, 'open'], false)
        .setIn([meta.dialog, 'mode'], null)

    case REGISTER:
      return state
        .setIn([meta.dialog, 'open'], false)
        .setIn([meta.dialog, 'loadingState'], false)
        .setIn([meta.dialog, 'navigation'], payload.navigation)
        .setIn([meta.dialog, 'formName'], meta.form);

    case UNREGISTER:
      return state.delete(meta.dialog);

    case CHANGE_TAB:
      return state.setIn([meta.dialog, 'tab'], payload.id);

    default:
      return state;
  }
}

export default advancedFormDialogReducer;
