import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

const styles = (theme) => ({
  root: {
  },
  dialogActionsContainer: {
    margin: '0 0 0 0',
    padding: '0 0 0 0',
    marginBottom: 5,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  dialogActionButton: {
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '47%',
    },
  },
  dialogResetButton: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  emptySpace: {
    flexBasis: '56%',
  },
  containerSwitchItems: {
    color: '#757575',
  },
  settingTitleText: {
    marginTop: 14,
  },
  formControl: {
    fontSize: 14,
  },
  groupTitle: {
    color: '#212121',
  },
  group: {
  },
  switch: {
    [theme.breakpoints.down('xs')]: {
      float: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      float: 'right',
    },
  },
  settingDialog: {
    margin: '0 auto',
    width: 920,
  },
  icon: {
    marginRight: 5,
  },
});

class SwitchComponent extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      checked,
      classes,
      disabled,
      iconComponent,
      onChange,
      title,
      value,
    } = this.props;

    return (
      <Grid
        alignItems={'center'}
        className={classes.containerSwitchItems}
        container
      >
        <Grid
          item
          xs={2}
          sm={1}
          md={1}
          lg={1}
          xl={1}
        >
          <span>{iconComponent}</span>
        </Grid>
        <Grid
          className={classes.groupTitle}
          item
          xs={8}
          sm={9}
          md={9}
          lg={9}
          xl={9}
        >
          {title}
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
        >
          <Switch
            className={classes.switch}
            checked={disabled ? false : checked}
            color="primary"
            disabled={disabled}
            onChange={onChange(value)}
            value={value}
          />
        </Grid>
      </Grid>
    );
  }
}

SwitchComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  iconComponent: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(SwitchComponent);
