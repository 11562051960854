/**
 *
 * ReceptionView sagas
 *
 */

import { put } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import { openEditDialog } from 'containers/AdvancedFormDialog/actions';
import { CHECKPOINT_REQUESTS_TABLE_GRID } from 'pages/Requests/CheckpointView/constants';
import { VISITOR_ARRIVAL_FORM_DIALOG } from 'pages/Requests/ReceptionView/constants';

export function* executeArrivedActionSaga({ payload: { ids } }) {
  if (ids && ids.length > 0) {
    yield put(openEditDialog(VISITOR_ARRIVAL_FORM_DIALOG, { id: ids[0] }));
  }
  yield put(loadData(CHECKPOINT_REQUESTS_TABLE_GRID));
}

export const checkpointViewSaga = {
  executeArrivedActionSaga,
};
