/**
 *
 * AdministratorBC Requests DxGrid 14326
 *
 */

import {
  CURRENT_DAY_REQUESTS,
  ON_CREATE_REQUESTS,
  ON_MATCHING_REQUESTS,
  REQUESTS_ON_CAR_ENTRY,
  REQUESTS_ON_VISIT,
  REQUESTS_ON_WORK_PRODUCTION,
  ACCESS_CARD_REQUESTS,
} from 'domain/Data/Filters/constants';
import { ReceptionDxGrid14326 } from '../ReceptionView';

export class AdministratorBCRequestsDxGrid14326 extends ReceptionDxGrid14326 {
  getFilters() {
    const { viewName } = this.props;
    const filters = [];
    if (viewName === 'today') filters.push(CURRENT_DAY_REQUESTS);
    if (viewName === 'onVisit') filters.push(REQUESTS_ON_VISIT);
    if (viewName === 'onCarEntry') filters.push(REQUESTS_ON_CAR_ENTRY);
    if (viewName === 'agree') filters.push(ON_MATCHING_REQUESTS);
    if (viewName === 'onWorks') filters.push(REQUESTS_ON_WORK_PRODUCTION);
    if (viewName === 'create') filters.push(ON_CREATE_REQUESTS);
    if (viewName === 'accessCard') filters.push(ACCESS_CARD_REQUESTS);
    return filters;
  }

  getColumns() {
    return super.getColumns();
  }

  getColumnOrder() {
    return super.getColumnOrder();
  }

  getGridFilters() {
    return super.getGridFilters();
  }
}
