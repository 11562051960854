/*
 *
 * Requests actionButton 14419
 *
 */

import React from 'react';

import FindInPage from '@material-ui/icons/FindInPage';
import Done from '@material-ui/icons/Done';

import { requestAction } from 'domain/typeConstants/action';

export const viewActionButton = {
    name: requestAction.VIEW,
    label: 'Просмотреть',
    icon: 'find_in_page',
    svgIcon: <FindInPage />,
    disabled: (selection) => selection.length !== 1,
};

export const instructedActionButton = {
    name: requestAction.INSTRUCTED,
    label: 'Инструктаж пройден',
    icon: 'done',
    svgIcon: <Done />,
    disabled: (selection) => selection.length !== 1,
};
