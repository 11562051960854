/*
 *
 * CheckpointForm14691
 *
 */

import { CheckpointForm } from 'pages/Requests/CheckpointView';
import {
    CARRYING_OUT_WORKS,
    ON_CAR_ENTRY,
    ON_PASS_MAKING,
    ON_PROPERTY_BRINGING,
    ON_PROPERTY_REMOVAL,
    ON_VISIT, SERVICE
} from "domain/Data/RequestType/constants";
import { formField } from "domain/typeConstants/form";
import * as entities from "domain/Data/constants";
import { groupRequestVisitorsDataHelperText } from 'pages/Requests/common/fields';

export class CheckpointForm14691 extends CheckpointForm {

    getFields() {
        const currentDate = new Date();

        return [
            {
                name: 'number',
                label: '№',
            },
            {
                name: 'status',
                type: 'select',
                label: 'Статус',
                entityName: 'requestStatuses',
                orderBy: 'name',
            },
            {
                name: 'type',
                type: 'select',
                label: 'Вид',
                entityName: 'requestTypes',
                orderBy: 'order',
            },
            {
                name: 'longterm',
                type: 'checkbox',
                label: 'Долгосрочная',
            },
            {
                name: 'visitDate',
                type: 'date',
                label: 'Дата',
                min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
                    new Date(initialValues.visitDate) : currentDate,
            },
            {
                name: 'expirationDate',
                type: 'date',
                label: 'Дата окончания',
                visible: (values) => values.longterm,
            },
            {
                name: 'isGroupRequest',
                type: 'checkbox',
                label: 'Групповая заявка',
                rowBreakBefore: (values) => values.type !== SERVICE,
                visible: (values) => values.type === ON_VISIT || values.type === ON_CAR_ENTRY || values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'groupRequestVisitorsData',
                label: 'Список посетителей',
                multiline: true,
                helperText: groupRequestVisitorsDataHelperText(),
                visible: (values) => values.isGroupRequest && values.type !== SERVICE,
                required: (values) => values.isGroupRequest && values.type === ON_VISIT,
                grid: { sm: 12 },
                rowBreakBefore: (values) => values.isGroupRequest && values.type !== SERVICE,
            },
            {
                name: 'vozdvizhenkaTime',
                type: formField.SELECT,
                entityName: entities.TIMES,
                label: 'Время доступа',
                visible: (values) => values.type === ON_PASS_MAKING || values.type === CARRYING_OUT_WORKS,
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'workType',
                label: 'Вид',
                type: formField.SELECT,
                entityName: entities.WORK_TYPES,
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
                rowBreakAfter: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'companyName',
                label: (values) => values.type === CARRYING_OUT_WORKS ? 'Подрядчик' : 'Из какой компании',
                visible: true
            },
            {
                name: 'responsibleFromContractor',
                label: 'Ответственный от подрядчика',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                required: true,
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'responsibleFromContractorPhone',
                label: 'Телефон отв. подрядчика',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                required: true,
            },
            {
                name: 'company',
                type: 'select',
                label: 'Арендатор',
                entityName: 'companies',
                orderBy: 'name',
                numberInQueueFromEnd: 1,
                rowBreakAfter: (values) => values.type === ON_VISIT ||
                    values.type === ON_CAR_ENTRY || values.type === ON_PASS_MAKING,
            },
            {
                name: 'nonstandardCarNumber',
                type: 'checkbox',
                label: 'Нестандартный № авто',
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
                rowBreakAfter: (values) => values.type === ON_CAR_ENTRY ||
                    values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL || values.type === CARRYING_OUT_WORKS,
                visible: (values) => (values.type === ON_CAR_ENTRY ||
                    values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL || values.type === CARRYING_OUT_WORKS)
                    && !values.isGroupRequest,
            },
            {
                name: 'passNumber',
                label: '№ пропуска',
                visible: (values) => values.type === ON_VISIT ||
                    values.type === ON_PASS_MAKING,
            },
            {
                name: 'carNumber',
                label: '№ автомобиля',
                visible: (values) => values.type === ON_CAR_ENTRY ||
                    values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL || values.type === CARRYING_OUT_WORKS,
                rowBreakBefore: true,
            },
            {
                name: 'carModelText',
                label: 'Марка автомобиля',
                visible: (values) => values.type === ON_CAR_ENTRY ||
                    values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL || values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'whatHappened',
                label: (values) => values.type === CARRYING_OUT_WORKS ? 'Наименование/Характер работ' : 'Что нужно сделать',
                multiline: false,
                visible: (values) => values.type === SERVICE || values.type === CARRYING_OUT_WORKS,
                grid: { sm: 12 },
            },

            {
                name: 'parking',
                type: 'select',
                label: 'Парковка',
                entityName: 'parkings',
                visible: (values) => values.type === ON_CAR_ENTRY ||
                    values.type === ON_PROPERTY_BRINGING || values.type === ON_PROPERTY_REMOVAL,
            },
            {
                name: 'materialValuesData',
                label: 'Данные мат. ценностей',
                helperText: 'наименование, кол-во, ед.изм.',
                grid: { sm: 12 },
                visible: (values) => values.type === ON_PROPERTY_BRINGING ||
                    values.type === ON_PROPERTY_REMOVAL,
            },
            {
                name: 'whoMeets',
                label: 'Кто встречает',
                visible: (values) => values.type === ON_VISIT,
                rowBreakBefore: true,
            },
            {
                name: 'file',
                label: 'Файл',
                type: 'file',
                visible: (values) => values.type === SERVICE ||
                    values.type === ON_PASS_MAKING || values.type === CARRYING_OUT_WORKS,
                rowBreakBefore: true,
            },
            {
                name: 'workPlace',
                label: 'Место проведения',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                required: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'note',
                label: 'Примечание',
                grid: { sm: 12 },
            },
            {
                name: 'managementCompanyComment',
                label: 'Комментарий УК',
                visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
                disabled: true,
                grid: { sm: 12 },
            },
            {
                name: 'needDisableOrChangeSystem',
                label: 'Необходимость отключения или изменения работы инженерных систем и оборудования здания',
                type: formField.CHECKBOX,
                visible: (values) => values.type === CARRYING_OUT_WORKS,
            },
        ];
    }
}
