/**
 *
 * TenantForm 14720
 *
 */


import { TenantForm } from 'pages/Requests/TenantView';
import { ON_PASS_MAKING, SERVICE, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL } from 'domain/Data/RequestType/constants';
import { estimationCost, estimationCostScan } from "../common/dxGridComponents/fields";

export class TenantForm14720 extends TenantForm {
  getFields() {
    const fields = super.getFields();
    fields.push({
      name: 'phone',
      label: 'Контактный тел',
      grid: { sm: 6 },
      required: (currentValues, mode) => mode === 'add',
      initialValue: (values, mode, user, entities) => {
        const author = entities.get('employees').get(values.author);
        return author ? author.phone : null;
      },
      rowBreakBefore: (values) => values.type === SERVICE || values.type === ON_PASS_MAKING,
    });

    const fileIndex = fields.findIndex((field) => field.name === 'file');
    fields[fileIndex].visible = (values) => [SERVICE, ON_PASS_MAKING, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL].includes(values.type);

    fields.push(estimationCost);
    fields.push(estimationCostScan);

    return fields;
  }
}
