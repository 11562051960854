/**
 *
 * WorkDxGrid
 *
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';
import { columnType, columnWorkSize } from 'domain/typeConstants/table';
import {
  addActionButtonTable, editActionButtonTable, copyActionButtonTable
} from 'pages/Requests/common/actionButton';
import { editActionItem, copyActionItem } from 'pages/Requests/common/actionMenu';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';


class WorkDxGrid extends React.PureComponent {

  getColumns() {
    return [
      {
        id: 'runDate',
        name: 'runDate',
        path: 'runDate',
        title: 'Дата',
        type: columnType.DATETIME,
        width: columnWorkSize.DATETIME
      },
      {
        id: 'author->name',
        name: 'author',
        path: ['author', 'name'],
        title: 'Автор',
        getCellValue: (row) => row.author ? row.author.name : undefined,
        width: columnWorkSize.AUTHOR_NAME
      },
      {
        id: 'duration',
        name: 'duration',
        path: 'duration',
        title: 'Часы',
        type: columnType.NUMERIC,
        width: columnWorkSize.DURATION
      },
      {
        id: 'description',
        name: 'description',
        path: 'description',
        title: 'Что сделано',
        type: columnType.FORMAT_TEXT,
        width: columnWorkSize.DESCRIPTION
      },
    ];
  }

  getColumnOrder() {
    return [
      'runDate', 'author', 'duration', 'description',];
  }

  getSorting() {
    return [{ columnId: 'runDate', columnName: 'runDate', direction: 'desc' }];
  }

  getActionButtons(mode) {
    if (mode === 'view') return []
    return [
      addActionButtonTable, editActionButtonTable, copyActionButtonTable
    ]
  }

  getActionMenuItems() {
    return [editActionItem, copyActionItem];
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_DRAWER, value: false },
      { name: settings.FILTERING, value: true },
      { name: settings.FILTER_BUTTON, value: true },
      { name: settings.SETTING_BUTTON, value: true },
      { name: settings.UPDATE_BUTTON, value: true },
    ];
  }

  getFilters() {
    return [];
  }

  getPageSize() {
    return 5;
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, mode, parent, parentId, entityName, height, dialogName, name } = this.props;

    return (
      <AdvancedDataTable
        tableType={settings.PAGING_TABLE}
        name={name}
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        pageSize={this.getPageSize()}
        selectable
        entityName={entityName}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        mode={mode}
        parent={parent}
        parentId={parentId}
        height={height}
        dialogName={dialogName}
      />
    );
  }
}

WorkDxGrid.propTypes = {
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  viewName: PropTypes.string,
};

export default WorkDxGrid
