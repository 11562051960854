
/* Parkings*/


export const TEENANT_PLACES = '4840491423000';
export const UNLOADING = '4840491399000';
export const GUEST = '4840491428000';


// departments checkpoint
export const departmentPostChain = Object.freeze({
  '4840499758000': '4862044610000', // Здание 5  Пост 3
  '4840500855000': '4862044609000', // Здание 1  Пост 1-2
  '4853765517000': '4862044609000', // Здание 1  Пост 1-2
  '4853765562000': '4862044609000', // Здание 1  Пост 1-2
  '4853765581000': '4862044609000', // Здание 1  Пост 1-2
});

// users Ids
export const usersWhoSeeAllRequests = [
  '4870335926000',		//Ресепшн 1
  '4870335948000',		//Ресепшн 2
  '4870335964000',		//Ресепшн 3
  '4870335980000',		//Ресепшн 4
  '4870336061000',		//Мониторная1
  '4870336098000',		//Мониторная2
  '4947093395000',    //Старший смены
]

export const TEENANT_PARKING_PLACE = '4840491423000'
