/**
 *
 * MakingAgreementBCDForm14366
 *
 */

import { MakingAgreementBCForm } from 'pages/Requests/MakingAgreementBCView';
import {
  ON_PASS_MAKING,
  ON_VISIT,
  SERVICE,
} from 'domain/Data/RequestType/constants';
import { ACCEPTED } from 'domain/Data/RequestStatus/constants';
import { department } from '../common/fields';
import { ALPHA_COM_ASSIGNED } from '../../../domain/Data/Employees/constants';

export class MakingAgreementBCForm14366 extends MakingAgreementBCForm {
  getFields() {
    const fields = super.getFields();

    const statusIndex = fields.findIndex((field) => field.name === 'status');
    fields[statusIndex].calculatedValue = (values, mode) => {
      if (mode === 'add' || mode === 'copy') { return ACCEPTED; }

      return values.status;
    };

    const whoMeetsIndex = fields.findIndex((field) => field.name === 'whoMeets');
    fields[whoMeetsIndex].rowBreakBefore = (values) => values.type !== ON_VISIT;
    fields.splice(whoMeetsIndex - 1, 0, department);

    const parkingPlaceIndex = fields.findIndex((field) => field.name === 'parkingPlace');
    fields[parkingPlaceIndex].visible = (values) => (values.type !== ON_VISIT && values.type !== SERVICE
      && values.type !== ON_PASS_MAKING);

    const assigneeIndex = fields.findIndex((field) => field.name === 'assignee');
    fields[assigneeIndex].calculatedValue = (currentValues) => {
      if (currentValues.type === SERVICE) {
        return ALPHA_COM_ASSIGNED;
      }

      return null;
    };

    return fields;
  }
}
