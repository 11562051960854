/**
*
* createCheckboxContainer
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form/immutable';

import Checkbox from './Checkbox';
import * as advancedFormActionCreators from '../actions';

function createCheckboxContainer(formName) {
  class CheckboxContainer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    normalize = (value) => value === true;

    render() {
      const { clearErrorsOnChange, ...restProps } = this.props; // eslint-disable-line no-unused-vars
      return (
        <Field
          {...restProps}
          component={Checkbox}
          normalize={this.normalize}
        />
      );
    }
  }

  CheckboxContainer.propTypes = {
    calculateFieldsValues: PropTypes.func.isRequired,
    clearErrorsOnChange: PropTypes.bool,
  };

  const mapDispatchToProps = (dispatch) => ({
    calculateFieldsValues: () => dispatch(advancedFormActionCreators.calculateFieldsValues(formName)),
  });

  return connect(null, mapDispatchToProps)(CheckboxContainer);
}

export default createCheckboxContainer;
