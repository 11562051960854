/**
 *
 * ActionButton Items
 *
 */

import React from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Create from '@material-ui/icons/Create';
import { requestAction } from 'domain/typeConstants/action';


export const addActionButton = {
  name: requestAction.ADD,
  label: 'Добавить',
  icon: 'add_circle_outline',
  svgIcon: <AddCircleOutline />,
};
export const editActionButton = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord || selection.length === 1) {
      return false
    } else
      return true
  },
};