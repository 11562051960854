/*
 *
 * TenantForm
 *
 */

import React from 'react';
import AdvancedForm from 'containers/AdvancedForm';
import {
    ON_VISIT,
    ON_CAR_ENTRY,
    ON_PROPERTY_BRINGING,
    ON_PROPERTY_REMOVAL,
    ON_PASS_MAKING,
    SERVICE,
    CARRYING_OUT_WORKS,
} from 'domain/Data/RequestType/constants';
import {CREATED} from 'domain/Data/RequestStatus/constants';
import {validateCarNumber} from 'utils/validators';
import {TENANT_REQUESTS_FORM} from './constants';
import {formField} from 'domain/typeConstants/form';
import * as entities from 'domain/Data/constants';
import {groupRequestVisitorsDataHelperText, visitorFullNameLabel} from '../common/fields';


class TenantForm extends React.PureComponent {

    getFields() {
        const currentDate = new Date();

        return [
            {
                name: 'author',
                visible: false,
                initialValue: (values, mode, user) => user.id,
            },
            {
                name: 'customer',
                visible: false,
                initialValue: (values, mode, user) => user.id,
            },
            {
                cached: false,
                type: 'select',
                entityName: 'companies',
                name: 'company',
                label: 'Арендатор',
                visible: false,
                initialValue: (values, mode, user) => user.companyId,
                numberInQueueFromEnd: 1,
            },
            {
                name: 'arrivalDate',
                visible: false,
                initialValue: () => null,
            },
            {
                name: 'departureDate',
                visible: false,
                initialValue: () => null,
            },
            {
                name: 'passNumber',
                visible: false,
                initialValue: () => null,
            },
            {
                name: 'number',
                label: '№',
                visible: (currentValues, initialValues, mode) => mode === 'view',
            },
            {
                name: 'status',
                type: 'select',
                label: 'Статус',
                entityName: 'requestStatuses',
                visible: (currentValues, initialValues, mode) => mode === 'view',
                initialValue: CREATED,
                orderBy: 'name',
            },
            {
                name: 'type',
                type: 'select',
                label: 'Вид',
                required: true,
                entityName: 'requestTypes',
                orderBy: 'order',
                initialValue: (values, mode) => {
                    if (mode === 'add') {
                        return ON_VISIT;
                    }
                    return values.type;
                },
                clearErrorsOnChange: true,
            },
            {
                name: 'longterm',
                type: 'checkbox',
                label: 'Долгосрочная',
                visible: (values) => values.type !== SERVICE,
            },
            {
                name: 'visitDate',
                type: 'date',
                label: 'Дата',
                required: true,
                visible: true,
                initialValue: currentDate,
                rowBreakBefore: (currentValues, initialValues, mode) => mode !== 'view',
                min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate ?
                    new Date(initialValues.visitDate) : currentDate,
                calculatedValue: (values) => {
                    const visitDate = new Date(values.visitDate);
                    return new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), currentDate.getHours()
                    )
                },
            },
            {
                name: 'expirationDate',
                type: 'date',
                label: 'Дата окончания',
                required: (values) => values.longterm,
                visible: (values) => values.longterm && values.type !== SERVICE,
                initialValue: (values, mode) => {
                    if (mode === 'copy') {
                        return new Date(values.expirationDate) > currentDate ?
                            new Date(values.expirationDate) : currentDate;
                    }
                    return null;
                },
                calculatedValue: (values) => values.longterm ? values.expirationDate : null,
                min: (values) => new Date(values.visitDate) < currentDate ?
                    new Date(values.visitDate) : currentDate,
            },
            {
                name: 'isGroupRequest',
                type: 'checkbox',
                label: 'Групповая заявка',
                rowBreakBefore: (values) => values.type !== SERVICE,
                visible: (values) => [ON_VISIT, ON_CAR_ENTRY, CARRYING_OUT_WORKS].includes(values.type),
            },
            {
                name: 'groupRequestVisitorsData',
                label: 'Список посетителей',
                multiline: true,
                helperText: groupRequestVisitorsDataHelperText(),
                visible: (values) => values.isGroupRequest && values.type !== SERVICE,
                required: (values) => values.isGroupRequest && values.type === ON_VISIT,
                grid: {sm: 12},
                rowBreakBefore: true,
            },
            {
                name: 'workType',
                label: 'Вид',
                type: formField.SELECT,
                entityName: entities.WORK_TYPES,
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
                rowBreakAfter: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'companyName',
                label: (values) => values.type === CARRYING_OUT_WORKS ? 'Подрядчик' : 'Из какой компании',
                visible: (values) => values.type !== SERVICE && values.type !== ON_PASS_MAKING
                    && values.type !== ON_PROPERTY_BRINGING && values.type !== ON_PROPERTY_REMOVAL,
            },
            {
                name: 'responsibleFromContractor',
                label: 'Ответственный от подрядчика',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                required: true,
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'responsibleFromContractorPhone',
                label: 'Телефон отв. подрядчика',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                required: true,
            },
            {
                name: 'visitorFullName',
                label: visitorFullNameLabel,
                type: 'dadata',
                dadataType: 'NAME',
                dadataParts: ['SURNAME', 'NAME', 'PATRONYMIC'],
                visible: (values) => !values.isGroupRequest && values.type !== SERVICE && values.type !== CARRYING_OUT_WORKS,
                autoFocus: (currentValues, initialValues, mode) => mode === 'add',
                required: (values) => !values.isGroupRequest && values.type === ON_VISIT,
                rowBreakBefore: true,
            },
            {
                name: 'nonstandardCarNumber',
                type: 'checkbox',
                label: 'Нестандартный № авто',
                rowBreakBefore: (values) => values.type === CARRYING_OUT_WORKS,
                rowBreakAfter: (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS].includes(values.type),
                visible: (values) => [ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS].includes(values.type)
                    && !values.isGroupRequest,
            },
            {
                name: 'carNumber',
                label: '№ автомобиля',
                required: (values) => values.type === ON_CAR_ENTRY && !values.isGroupRequest,
                visible: (values) => ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS].includes(values.type)) &&
                    !values.isGroupRequest,
                normalize: (value) => value ? value.replace(' ', '') : value,
                validate: validateCarNumber,
                rowBreakBefore: true,
            },
            {
                name: 'carModelText',
                label: 'Марка автомобиля',
                visible: (values) => ([ON_CAR_ENTRY, ON_PROPERTY_BRINGING, ON_PROPERTY_REMOVAL, CARRYING_OUT_WORKS].includes(values.type)) &&
                    !values.isGroupRequest,
            },
            {
                name: 'parking',
                type: 'select',
                label: 'Парковка',
                entityName: 'parkings',
                visible: (values) => values.type === ON_CAR_ENTRY ||
                    values.type === ON_PROPERTY_BRINGING ||
                    values.type === ON_PROPERTY_REMOVAL,
            },
            {
                name: 'parkingPlace',
                label: 'Место парковки',
                visible: (values) => (values.type === ON_CAR_ENTRY),
                initialValue: () => null,
            },
            {
                name: 'materialValuesData',
                label: 'Данные мат. ценностей',
                helperText: 'наименование, кол-во, ед.изм.',
                grid: {sm: 12},
                required: true,
                visible: (values) => values.type === ON_PROPERTY_BRINGING ||
                    values.type === ON_PROPERTY_REMOVAL,
            },
            {
                name: 'whoMeets',
                label: 'Кто встречает',
                visible: (values) => values.type === ON_VISIT,
                rowBreakBefore: true,
            },
            {
                name: 'service',
                type: 'select',
                label: 'Сервис',
                entityName: 'services',
                visible: (values) => values.type === SERVICE,
                rowBreakBefore: true,
            },
            {
                name: 'whereHappened',
                label: 'Где произошло',
                entityName: 'services',
                visible: (values) => values.type === SERVICE,
            },
            {
                name: 'whatHappened',
                label: (values) => values.type === CARRYING_OUT_WORKS ? 'Наименование/Характер работ' : 'Что нужно сделать',
                multiline: false,
                visible: (values) => values.type === SERVICE || values.type === CARRYING_OUT_WORKS,
                grid: {sm: 12},
            },
            {
                name: 'assignee',
                type: 'select',
                label: 'Исполнитель',
                entityName: 'employees',
                orderBy: 'name',
                cached: false,
                visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
                rowBreakAfter: (values) => values.type === SERVICE,
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.assignee;
                },
                visibleOptions: (currentValues, initialValue, mode, user, entities) => {
                    const assignee = [];
                    entities.get('employees').map((item) => {
                        if (item.company === user.companyId && item.isPerformer) assignee.push(item.id);
                    });

                    return assignee;
                },
                disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
            },
            {
                name: 'executionDate',
                type: 'date',
                label: 'Дата выполнения факт',
                required: true,
                visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
                min: (currentValues, initialValues) => new Date(initialValues.visitDate) < currentDate
                    ? new Date(initialValues.visitDate) : currentDate,
                rowBreakBefore: true,
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.executionDate;
                },
                disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
            },
            {
                name: 'executionTerm',
                type: 'date',
                label: 'Срок исполнения',
                required: true,
                visible: (currentValues, initialValues, mode) => currentValues.type === SERVICE && (mode === 'view' || mode === 'edit'),
                min: () => currentDate,
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.executionTerm;
                },
                disabled: (currentValues, initialValues, mode) => mode === 'view' || mode === 'edit',
            },
            {
                name: 'implementationReport',
                label: 'Отчет о выполнении',
                visible: (values) => values.type === SERVICE,
                grid: {sm: 12},
                calculatedValue: (values, mode) => {
                    if (mode === 'copy') return null;
                    return values.implementationReport;
                },
                disabled: true,
            },
            {
                name: 'file',
                label: 'Файл',
                type: 'file',
                visible: (values) => values.type === SERVICE || values.type === ON_PASS_MAKING || values.type === CARRYING_OUT_WORKS,
                rowBreakBefore: true,
            },
            {
                name: 'workPlace',
                label: 'Место проведения',
                visible: (values) => values.type === CARRYING_OUT_WORKS,
                required: (values) => values.type === CARRYING_OUT_WORKS,
            },
            {
                name: 'note',
                label: 'Примечание',
                grid: {sm: 12},
                visible: (values) => values.type !== SERVICE,
            },
            {
                name: 'managementCompanyComment',
                label: 'Комментарий УК',
                visible: (values, initialValues, mode) => mode === 'view' || mode === 'edit',
                disabled: true,
                grid: {sm: 12},
                initialValue: (values, mode) => {
                    if (mode === 'copy') {
                        return null;
                    }
                    return values.managementCompanyComment;
                },
            },
        ];
    }

    getClassNames() {
        return {
            helperText: '',
        };
    }

    render() {
        return (
            <AdvancedForm
                name={TENANT_REQUESTS_FORM}
                entityName="requests"
                fields={this.getFields()}
                classNames={this.getClassNames()}
                useAsyncValidate
                {...this.props}
            />
        );
    }
}

export default TenantForm;
