/**
 *
 * Requests ActionButton Items
 *
 */

import React from 'react';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Close from '@material-ui/icons/Close';
import Create from '@material-ui/icons/Create';
import FileCopy from '@material-ui/icons/FileCopy';
import { requestAction } from 'domain/typeConstants/action';
import { requestStatus } from 'domain/typeConstants/status';

export const addActionButton = {
  name: requestAction.ADD,
  label: 'Добавить',
  icon: 'add_circle_outline',
  svgIcon: <AddCircleOutline />,
};

export const closeActionButton = {
  name: requestAction.CLOSE,
  label: 'Закрыть',
  icon: 'close',
  svgIcon: <Close />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord && selection.length === 0) {
      return [requestStatus.CLOSED, requestStatus.COMPLETED].includes(currentRecord.status.name)
    }
    else
      if (selection.length > 0) {
        if (!entities[0].status) return false;
        return entities.some((element) => element.status.name === requestStatus.CLOSED || element.status.name === requestStatus.COMPLETED);
      }
    return true;
  },
};

export const completeActionButton = {
  name: requestAction.PERFORME,
  label: 'Выполнить',
  icon: 'check_circle_outline',
  svgIcon: <CheckCircleOutline />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord && selection.length === 0) {
      if (!currentRecord.status) return false
      return currentRecord.status.name === requestStatus.CLOSED
    }
    else if (selection.length > 0) {
      if (!entities[0].status) return false;
      return entities.some((element) => element.status.name === requestStatus.CLOSED);
    }
    return true;
  },
};

export const editActionButton = {
  name: requestAction.EDIT,
  label: 'Изменить',
  icon: 'create',
  svgIcon: <Create />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord || selection.length === 1) {
      return false
    } else
      return true
  },
};


export const copyActionButton = {
  name: requestAction.COPY,
  label: 'Копия',
  icon: 'content_copy',
  svgIcon: <FileCopy />,
  disabled: (selection, entities, currentRecord) => {
    if (currentRecord || selection.length === 1) {
      return false
    } else
      return true
  },
};