/*
 *
 * RequestsPage12861
 *
 */

import React from 'react';
import RequestsPage from 'pages/Requests/RequestsPage';
import { AssignedForm12861, AssignedDxGrid12861, AssignedMobileGrid12861 } from './AssignedView';


function RequestsPage12861(props) {
  return (
    <RequestsPage
      assignedForm={<AssignedForm12861 />}
      assignedDxGrid={<AssignedDxGrid12861 />}
      assignedMobileGrid = {<AssignedMobileGrid12861/>}
      {...props}
    />
  );
}

export default RequestsPage12861;