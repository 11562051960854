/**
 *
 * validate functions for RequestsPage15755
 *
 */

import { ON_PASS_MAKING, ON_VISIT } from "domain/Data/RequestType/constants";


export const validateExpirationDate = (values) => {
    if (values.visitDate && values.expirationDate) {
        const visitDate = new Date(values.visitDate);
        const expirationDate = new Date(values.expirationDate);
        const diff = (expirationDate.setHours(0, 0, 0, 0) - visitDate.setHours(0, 0, 0, 0)) / (24 * 3600 * 1000);
        if (values.longterm && diff > 365) {
            return { errorHelperText: 'Дата окончания долгосрочной заявки не более 1 года' };
        } else if (diff < 0) {
            return { errorHelperText: 'Дата окончания не может быть меньше чем Дата' };
        }
    }
    return null;
};

export const validatePassMakingLanguage = (values) => {
    if (values.type === ON_PASS_MAKING && (/[а-яА-Я]/.test(values.visitorFullName))) {
        return { errorHelperText: "Получателя карты записать латинскими буквами" }
    }
    return null
};

export const validateGroupRequestVisitorsData = (values) => {
    if (values.type === ON_VISIT && (/[0-9]/.test(values.groupRequestVisitorsData))) {
        return { errorHelperText: "Неверный формат ввода данных" }
    }
    return null
};

export const visitoFullNameHelperText = (values) => {
    if (values.type === ON_PASS_MAKING) {
        return 'Получателя карты записать латинскими буквами';
    }
    return null;
};