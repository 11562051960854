/**
 *
 * ApplicantDxGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedDataTable from 'containers/AdvancedDataTable';

import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { settings } from 'containers/AdvancedDataTable/dxGridTypes';

import {
  ACTIVE_REQUESTS,
  REQUESTS_FOR_APPLICANT,
} from 'domain/Data/Filters/constants';

import {
  addActionButton,
  copyActionButton,
  closeActionButton,
} from '../common/actionButton';
import {
  viewActionItem,
  copyActionItem,
  closeActionItem,
} from '../common/actionMenu';
import { columnStatusLabels } from '../common/columnStatusLabel';

import { APPLICANT_REQUESTS_TABLE_GRID } from './constants';

class ApplicantDxGrid extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  getFilters() {
    const { viewName } = this.props;
    const filters = [REQUESTS_FOR_APPLICANT];
    if (viewName === 'active') filters.push(ACTIVE_REQUESTS);
    return filters;
  }

  getColumns() {
    return [
      {
        path: 'number',
        name: 'number',
        title: '№',
        type: columnType.NUMERIC,
        width: columnSize.NUMERIC,
      },
      {
        path: 'actualCreationDate',
        name: 'actualCreationDate',
        title: 'Добавлено',
        type: columnType.DATETIME,
        width: columnSize.DATE,
      },
      {
        path: ['status', 'name'],
        name: 'statusName',
        getCellValue: (row) => (row.status ? row.status.name : undefined),
        title: 'Статус',
        type: columnType.LABEL,
        labels: columnStatusLabels,
        width: requestColumnSizes.statusName,
      },
      {
        path: ['service', 'name'],
        name: 'serviceName',
        getCellValue: (row) => (row.service ? row.service.name : undefined),
        title: 'Сервис',
        width: requestColumnSizes.serviceName,
      },
      {
        path: 'whereHappened',
        name: 'whereHappened',
        title: 'Где произошло',
        width: requestColumnSizes.whereHappened,
      },
      {
        path: 'whatHappened',
        name: 'whatHappened',
        title: 'Что случилось',
        width: requestColumnSizes.whatHappened,
      },
      {
        path: 'executionTerm',
        name: 'executionTerm',
        title: 'Срок исполнения',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'executionDate',
        name: 'executionDate',
        title: 'Исполнение факт',
        type: columnType.DATE,
        width: columnSize.DATE,
      },
      {
        path: 'implementationReport',
        name: 'implementationReport',
        title: 'Отчет о выполнении',
        width: requestColumnSizes.implementationReport,
      },
      {
        path: ['file', 'name'],
        name: 'fileName',
        getCellValue: (row) => row.file ? row.file.id : undefined,
        title: 'Файл',
        type: columnType.FILE,
        width: requestColumnSizes.fileName,
      },
      {
        path: 'note',
        name: 'note',
        title: 'Примечание',
        width: requestColumnSizes.note,
      },
      {
        path: 'requestPriority',
        name: 'requestPriority',
        title: 'Приоритет',
        getCellValue: (row) => (row.requestPriority ? row.requestPriority.name : undefined),
        width: requestColumnSizes.accessCard,
      },
    ];
  }

  getColumnOrder() {
    return ['number', 'actualCreationDate', 'statusName', 'serviceName', 'whereHappened', 'whatHappened', 'contactPersonFullName',
      'executionTerm', 'executionDate', 'implementationReport', 'fileName', 'note', 'requestPriority',
    ];
  }

  getActionButtons() {
    return [addActionButton, copyActionButton, closeActionButton];
  }

  getActionMenuItems() {
    return [viewActionItem, copyActionItem, closeActionItem];
  }

  getSorting() {
    return [{ columnId: 'number', columnName: 'number', direction: 'desc' }];
  }

  getPageSize() {
    return 10;
  }

  getTableFeatures() {
    return [
      { name: settings.TABLE_ROW_DETAIL, value: false },
      { name: settings.TABLE_DRAWER, value: false },
      {name: settings.FILTERING, value: true},
      {name: settings.FILTER_BUTTON, value: true},
    ];
  }

  render() {
    const { onActionExecuting, onRowDoubleClick, viewName } = this.props;

    return (
      <AdvancedDataTable
        name={APPLICANT_REQUESTS_TABLE_GRID}
        entityName="requests"
        actionButtons={this.getActionButtons()}
        actionMenuItems={this.getActionMenuItems()}
        columnOrder={this.getColumnOrder()}
        columns={this.getColumns()}
        filters={this.getFilters()}
        pageSize={this.getPageSize()}
        onActionExecuting={onActionExecuting}
        onRowDoubleClick={onRowDoubleClick}
        sorting={this.getSorting()}
        tableFeatures={this.getTableFeatures()}
        viewName={viewName}
        filePreviewFieldName="scan"
        filtering
        selectable
        tableType={settings.PAGING_TABLE}
        mode="parent"
      />
    );
  }
}

ApplicantDxGrid.propTypes = {
  viewName: PropTypes.string,
  onActionExecuting: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
};

export default ApplicantDxGrid;
