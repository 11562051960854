/*
 *
 * AdministratorBCRequestsRejectForm14326
 *
 */

import { ReceptionRejectForm14326 } from '../ReceptionView';

export class AdministratorBCRequestsRejectForm14326 extends ReceptionRejectForm14326 {

}
