/**
 *
 * RequestsPage12924 sagas config
 *
 */

import { receptionViewSaga } from './ReceptionView/sagas';

const requestsPageSagasConfig14326 = {
  receptionViewSaga,
};

export default requestsPageSagasConfig14326;
