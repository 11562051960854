/**
 *
 * TenantDxGrid14326
 *
 */

import { TenantDxGrid } from "pages/Rules/TenantView/TenantDxGrid";
import { rulesColumnSizes } from 'domain/typeConstants/table';
import { ACTIVE_RULES, COMPANY_RULES } from "domain/Data/Filters/constants";

export class TenantDxGrid14326 extends TenantDxGrid {

  NEWS_TOPIC = 'newsTopic'

  getFilters() {
    const { viewName } = this.props;
    let filters = super.getFilters();
    const companyIndex = filters.findIndex((item) => item === COMPANY_RULES);
    filters.splice(companyIndex, 1);
    if (viewName === 'allDocumentsRules') filters.push(ACTIVE_RULES);
    return filters;
  }

  isNews() {
    const { viewName } = this.props;
    return viewName === 'allNews';
  }

  getColumns() {
    let columns = super.getColumns();
    this.isNews() &&
      columns.push({
        path: this.NEWS_TOPIC,
        name: this.NEWS_TOPIC,
        title: 'Тема',
      });
    return columns;
  }

  getColumnWidths() {
    let columnWidths = super.getColumnWidths();
    this.isNews() &&
      columnWidths.push({ columnName: this.NEWS_TOPIC, width: rulesColumnSizes.newsTopic })
    return columnWidths
  }

  getColumnOrder() {
    let columnOrder = super.getColumnOrder()
    if (this.isNews()) {
      const contentIndex = columnOrder.findIndex((item) => item === 'content');
      columnOrder.splice(contentIndex, 0, this.NEWS_TOPIC);
    }
    return columnOrder;
  }

}


