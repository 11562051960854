/*
 * RequestsPage14326 sagas
 */

import { fork } from 'redux-saga/effects';

import receptionViewSaga from './ReceptionView/sagas';
import serviceRequestsManagerViewSaga from './ServiceRequestsManagerView/sagas';

export default function* rootSaga() {
  yield fork(receptionViewSaga);
  yield fork(serviceRequestsManagerViewSaga);
}

