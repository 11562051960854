/*
 *
 * ReceptionDxGrid14278
 *
 */

import { ReceptionDxGrid } from 'pages/Requests/ReceptionView';
import { REQUESTS_FOR_USER_DEPARTMENT, ON_CREATE_REQUESTS } from 'domain/Data/Filters/constants';

export class ReceptionDxGrid14278 extends ReceptionDxGrid {

  removeColumns = ['materialValuesData', 'whatHappened', 'parkingPlace', 'parkingName', 'serviceName', 'fileName', 'managementCompanyComment', 'blackListNote', 'companyName']

  getFilters() {
    const filters = super.getFilters();
    const { viewName } = this.props;

    if (viewName === 'today') {
      filters.push(REQUESTS_FOR_USER_DEPARTMENT);
    }
    return filters;
  }

  getColumns() {
    const columns = super.getColumns();
    const result = columns.filter(column => !this.removeColumns.some(removeColumn => column.name === removeColumn));
    return result;
  }

  getGridFilters() {
    const gridFilters = super.getGridFilters();

    gridFilters.push({
      columnName: 'department', value: '',
    });

    const result = gridFilters.filter(gridFilter => !this.removeColumns.some(removeColumn => gridFilter.columnName === removeColumn));

    return result;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const companyNameIndex = columnOrder.findIndex((columnName) => columnName === 'company_name');
    columnOrder.splice(companyNameIndex + 1, 0, 'department');

    const result = columnOrder.filter(order => !this.removeColumns.includes(order));

    return result;
  }
}
