/**
 *
 * Form types
 *
 */

export const formField = Object.freeze({
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX: 'checkbox',
  DADATA: 'dadata',
  DATETIME: 'datetime',
  DATE: 'date',
  FILE: 'file',
  MULTIPLE_FILE: 'multipleFile',
  MULTI_AUTOCOMPLETE: 'multiAutocomplete',
  MULTI_SELECT: 'multiSelect',
  SELECT: 'select',
  SELECT_GRID: 'selectGrid',
  EMPTY_BLOCK: 'emptyBlock',
  DATETIME_DIVIDED: 'datetimeDivided',
  COMBOBOX: 'comboBox',
  COMBOBOX_TEXT_INPUT: 'comboBoxTextInput',
  EDITOR: 'editor',
  MESSAGE: 'message',
  SELECT_CHECKBOX: 'selectCheckbox',
  TEXT: 'text',
});
