/*
 *
 * CheckpointDxGrid14278
 *
 */

import { CheckpointDxGrid } from 'pages/Requests/CheckpointView';
import { REQUESTS_FOR_USER_DEPARTMENT } from 'domain/Data/Filters/constants';

export class CheckpointDxGrid14278 extends CheckpointDxGrid {
  getFilters() {
    const filters = super.getFilters();
    const { viewName } = this.props;

    if (viewName === 'today') {
      filters.push(REQUESTS_FOR_USER_DEPARTMENT);
    }

    return filters;
  }

  getColumns() {
    const columns = super.getColumns();

    columns.push({
      path: 'department',
      name: 'department',
      title: 'Корпус',
      getCellValue: (row) => row.department ? row.department.name : undefined,
      width: 155,
    });

    return columns;
  }

  getGridFilters() {
    const gridFilters = super.getGridFilters();

    gridFilters.push({
      columnName: 'department', value: '',
    });

    return gridFilters;
  }

  getColumnOrder() {
    const columnOrder = super.getColumnOrder();
    const tenantCompanyNameIndex = columnOrder.findIndex((columnName) => columnName === 'tenantCompanyName');
    columnOrder.splice(tenantCompanyNameIndex + 1, 0, 'department');
    return columnOrder;
  }
}
