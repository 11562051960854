/*
 *
 * SupplyRequestFormDialogContainer15767
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect'
import {
    executeRunDialogAction,
    executeAgreeDialogAction,
    executeDisagreeDialogAction,
} from 'pages/Documents/SupplyRequestView/actions';

import {makeSelectRowSelection} from 'containers/AdvancedDataTable/selectors';
import {SUPPLY_REQUEST_DX_GRID, SUPPLY_REQUEST_FORM_DIALOG} from 'pages/Documents/SupplyRequestView/constants';
import SupplyRequestFormDialog15767 from './SupplyRequestFormDialog15767';
import {makeSelectMobileCurrentRecord} from "containers/AdvancedMobileGrid/selectors";
import {makeSelectViewMode} from "domain/Screen/selectors";
import {makeSelectMode} from "containers/AdvancedFormDialog/selectors";


class SupplyRequestFormDialogContainer15767 extends React.Component {
    render() {
        const {viewName} = this.props;
        return (
            <div>
                <SupplyRequestFormDialog15767
                    onRun={this.props.run}
                    onAgree={this.props.agree}
                    onDisagree={this.props.disagree}
                    viewName={viewName}
                    {...this.props}
                />
            </div>
        );
    }
}

SupplyRequestFormDialogContainer15767.propTypes = {
    run: PropTypes.func.isRequired,
    agree: PropTypes.func.isRequired,
    disagree: PropTypes.func.isRequired,
    viewName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
    row: makeSelectRowSelection(SUPPLY_REQUEST_DX_GRID),
    mobileRow: makeSelectMobileCurrentRecord(SUPPLY_REQUEST_DX_GRID),
    mode: makeSelectMode(SUPPLY_REQUEST_FORM_DIALOG),
    viewMode: makeSelectViewMode(),
});

function mapDispatchToProps(dispatch) {
    return {
        run: (dialogName, formName) => dispatch(executeRunDialogAction(dialogName, formName)),
        agree: (dialogName, formName) => dispatch(executeAgreeDialogAction(dialogName, formName)),
        disagree: (dialogName, formName) => dispatch(executeDisagreeDialogAction(dialogName, formName)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyRequestFormDialogContainer15767);
