/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import createScannerMiddleware from 'features/Scanner';
import createCarNumberScannerMiddleware from 'features/CarNumberScanner';

import advancedDataTablesSaga from 'containers/AdvancedDataTable/sagas';
import advancedMobileGridSaga from 'containers/AdvancedMobileGrid/sagas';
import advancedFormDialogSaga from 'containers/AdvancedFormDialog/sagas';
import advancedFormSaga from 'containers/AdvancedForm/sagas';
import dataSaga from 'domain/Data/sagas';
import userSaga from 'domain/User/sagas';
import notificationsSaga from 'domain/Notifications/sagas';
import scannerSaga from 'features/Scanner/sagas';
import workDataTableSaga from 'pages/Works/WorkView/sagas'
import documentPositionSaga from 'pages/Positions/PositionsView/sagas'
import supplierInvoicesSaga from 'pages/SupplierInvoices/SupplierInvoicesView/sagas'
import taskDetailSaga from 'pages/TasksDetail/TaskView/sagas'

import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const scannerMiddleware = createScannerMiddleware();
const carNumberScannerMiddleware = createCarNumberScannerMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    scannerMiddleware,
    carNumberScannerMiddleware,
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
        // Prevent recomputing reducers for `replaceReducer`
        shouldHotReload: false,
        stateSanitizer: (state) => state.getIn(['global', 'screen', 'breakpoint']) ? state.setIn(['global', 'screen', 'breakpoint'], '<<LONG_BLOB>>') : state,
      })
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  // Run sagas
  sagaMiddleware.run(advancedDataTablesSaga);
  sagaMiddleware.run(advancedFormDialogSaga);
  sagaMiddleware.run(advancedFormSaga);
  sagaMiddleware.run(dataSaga);
  sagaMiddleware.run(scannerSaga);
  sagaMiddleware.run(userSaga);
  sagaMiddleware.run(advancedMobileGridSaga);
  sagaMiddleware.run(notificationsSaga);
  sagaMiddleware.run(workDataTableSaga);
  sagaMiddleware.run(documentPositionSaga);
  sagaMiddleware.run(supplierInvoicesSaga);
  sagaMiddleware.run(taskDetailSaga);

  return store;
}
