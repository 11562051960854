/**
 *
 * CheckpointOperatorView sagas
 *
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { loadData } from 'containers/AdvancedDataTable/actions';
import {
  processVisitorArrivalSaga,
  processVisitorDepartureSaga,
} from 'domain/Data/Request/sagas';

import { SAVE_DIALOG_SUCCESS } from 'containers/AdvancedFormDialog/constants';
import { openViewDialog } from 'containers/AdvancedFormDialog/actions';

import {
  CHECKPOINT_OPERATOR_REQUESTS_DX_GRID,
  CHECKPOINT_OPERATOR_REQUESTS_FORM_DIALOG,
  EXECUTE_VIEW_ACTION,
  EXECUTE_ARRIVED_ACTION,
  EXECUTE_DEPARTED_ACTION,
} from './constants';
import { checkUserForBlockingSaga } from 'domain/User/sagas';

const isCheckpointOperatorFormSaved = (action) => action.type === SAVE_DIALOG_SUCCESS &&
 action.meta.dialog === CHECKPOINT_OPERATOR_REQUESTS_FORM_DIALOG;

export default function createCheckpointOperatorViewSaga(config = {}) {
  return function* rootSaga() {
    yield takeLatest(EXECUTE_VIEW_ACTION, (config.executeViewActionSaga || executeViewActionSaga));
    yield takeLatest(EXECUTE_ARRIVED_ACTION, (config.executeArrivedActionSaga || executeArrivedActionSaga));
    yield takeLatest(EXECUTE_DEPARTED_ACTION, (config.executeDepartedActionSaga || executeDepartedActionSaga));

    yield takeLatest(isCheckpointOperatorFormSaved, (config.executeNeedViewRefreshSaga || executeNeedViewRefreshSaga));
  };
}

function* executeNeedViewRefreshSaga() {
  yield put(loadData(CHECKPOINT_OPERATOR_REQUESTS_DX_GRID));
}

export function* executeViewActionSaga({ payload: { id } }) {
  if (id) {
    yield put(openViewDialog(CHECKPOINT_OPERATOR_REQUESTS_FORM_DIALOG, id));
  }
}

export function* executeArrivedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorArrivalSaga, ids);
  yield put(loadData(CHECKPOINT_OPERATOR_REQUESTS_DX_GRID));
}

export function* executeDepartedActionSaga({ payload: { ids } }) {
  yield checkUserForBlockingSaga()
  yield call(processVisitorDepartureSaga, ids);
  yield put(loadData(CHECKPOINT_OPERATOR_REQUESTS_DX_GRID));
}
