/**
 *
 * SelectField sagas
 *
 */

import {call, put, select, takeEvery} from 'redux-saga/effects';
import * as api from 'utils/api';
import {entityLoadingError} from 'domain/Data/actions';
import {LOAD_OPTIONS} from './constants';
import makeSelectField from './selectors';
import {
    makeSelectInitialValues,
    makeSelectCurrentValues,
    makeSelectMode,
} from '../selectors';
import {
    incrementRequestsSentParameter,
    addEntityToNormalizationQueue,
} from '../actions';
import {normalizeEntitiesQueueSaga} from '../commonSagas';


export default function* rootSaga() {
    yield takeEvery(LOAD_OPTIONS, function* (action) {
        yield call(loadOptionsSaga, action);
    });
}

export function* loadOptionsSaga({meta}) {

    const {entityName, filters, filterBy} = yield select(makeSelectField(meta.advancedForm, meta.field));
    const field = yield select(makeSelectField(meta.advancedForm, meta.field));

    const options = {};

    if (field.view) options.view = field.view;
    if (filters && Array.isArray(filters)) {
        options.filters = filters;
    } else if (filters && typeof filters === 'function') {
        const initialValues = yield select(makeSelectInitialValues(meta.advancedForm));
        const currentValues = yield select(makeSelectCurrentValues(meta.advancedForm));
        const mode = yield select(makeSelectMode(meta.advancedForm));
        options.filters = filters(currentValues, initialValues, mode);
    }

    if (filterBy && typeof filterBy === 'string') {
        options.filterBy = filterBy;
    } else if (filterBy && typeof filterBy === 'function') {
        const initialValues = yield select(makeSelectInitialValues(meta.advancedForm));
        const currentValues = yield select(makeSelectCurrentValues(meta.advancedForm));
        const mode = yield select(makeSelectMode(meta.advancedForm));
        options.filterBy = filterBy(currentValues, initialValues, mode);
    }

    try {
        yield put(incrementRequestsSentParameter(meta.advancedForm));
        const response = yield call(api.loadPage, entityName, options);
        let {data} = response;

        if (entityName === 'employees') {
            data = data.filter((item) => !(item.fired && item.fired))
        }

        const entity = {
            field: field.name,
            data,
            numberInQueueFromEnd: field.numberInQueueFromEnd,
        };

        yield put(addEntityToNormalizationQueue(meta.advancedForm, entityName, entity));

        yield* normalizeEntitiesQueueSaga(meta.advancedForm);
    } catch (err) {
        console.log(err);
        yield put(entityLoadingError(err, meta));
    }
}
