/*
 *
 * App actions
 *
 */

import {
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  LOAD_USER_INFO,
  LOAD_USER_INFO_SUCCESS,
  LOAD_USER_INFO_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHECK_USER_FOR_BLOCKING,
  RESTORE_PASSWORD,
  CHECK_VERSION,
} from './constants';

export function login(_login, password, nextPathAfterLogin) {
  return {
    type: LOG_IN,
    payload: {
      login: _login,
      password,
      nextPathAfterLogin,
    },
  };
}

export function logged(data) {
  return {
    type: LOG_IN_SUCCESS,
    payload: { data },
  };
}

export function loginError(error) {
  return {
    type: LOG_IN_ERROR,
    payload: { error },
  };
}

export function logout() {
  return {
    type: LOG_OUT,
  };
}

export function loadUserInfo() {
  return {
    type: LOAD_USER_INFO,
  };
}

export function userInfoLoaded(data) {
  return {
    type: LOAD_USER_INFO_SUCCESS,
    payload: { data },
  };
}

export function userInfoLoadingError(error) {
  return {
    type: LOAD_USER_INFO_ERROR,
    payload: { error },
  };
}


export function changePassword(oldPassword, newPassword, newPasswordConfirmation, nextPathAfterLogin) {
  return {
    type: CHANGE_PASSWORD,
    payload: { oldPassword, newPassword, newPasswordConfirmation, nextPathAfterLogin },
  };
}

export function restorePassword(newPassword) {
  return {
    type: RESTORE_PASSWORD,
    payload: {newPassword},
  };
}

export function passwordChanged(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { data },
  };
}

export function passwordChangingError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: { error },
  };
}

export function checkUserForBlocking() {
  return {
    type: CHECK_USER_FOR_BLOCKING,
  };
}

export function checkVersion() {
  return {
    type: CHECK_VERSION,
  };
}