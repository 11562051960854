import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';


const persistentViews = [
  'forMyVisa',
  // 'allDocuments',
]

const styleSheet = {
  previewDrawer: {
    position: 'fixed',
    height: 'calc(100% - 170px)',
    width: 700,
    marginTop: 122,
    marginRight: 16,
    overflow: "hidden",
  },
};


class DataTableDrawer extends React.PureComponent {
  render() {
    const {
      onClose,
      open,
      row,
      viewComponent,
      viewName,
      classes,
    } = this.props;

    return (
      <Drawer
        classes={{
          paper: classes.previewDrawer,
        }}
        variant={persistentViews.includes(viewName) ? 'persistent' : 'temporary'}
        anchor={'right'}
        onClose={onClose}
        open={open}
      >
        {persistentViews.includes(viewName) &&
          <IconButton
            onClick={onClose}
            onMouseDown={onClose}
          >
            <Clear />
          </IconButton>
        }
        {row &&
          <div>
            {viewComponent}
          </div>
        }
      </Drawer>
    );
  }
}

DataTableDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  row: PropTypes.object,
  viewComponent: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(DataTableDrawer);
