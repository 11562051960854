/*
 *
 * ParkingsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import withPrivateRoot from 'containers/App/withPrivateRoot';

import CheckpointParkingDataTable from './CheckpointParkingDataTable';

const styleSheet = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
});

class ParkingsPage extends React.PureComponent {
  render() {
    return (
      <div className={this.props.classes.root}>
        <Paper>
          <CheckpointParkingDataTable
            key={this.props.viewName}
            viewName={this.props.viewName}
          />
        </Paper>
      </div>
    );
  }
}

ParkingsPage.propTypes = {
  classes: PropTypes.object,
  viewName: PropTypes.string,
};

export default withPrivateRoot(
  withStyles(styleSheet)(ParkingsPage)
);
