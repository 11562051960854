import { columnType, columnSize, requestColumnSizes } from 'domain/typeConstants/table';
import { columnStatusLabels } from 'pages/Requests/common/columnStatusLabel';

/** ---------- actualCreationDate Column ---------- */
export const ACTUAL_CREATION_DATE = 'actualCreationDate';

export const actualCreationDateColumn = {
  path: ACTUAL_CREATION_DATE,
  name: ACTUAL_CREATION_DATE,
  title: 'Создано',
  type: columnType.DATETIME,
  width: columnSize.DATETIME,
};

export const actualCreationDateColumnFilter = {
  columnName: ACTUAL_CREATION_DATE,
  value: '',
};

/* ---------- isDiscretionOfSecurity Column ---------- */

export const IS_DISCRETION_OF_SECURITY = 'isDiscretionOfSecurity';

export const isDiscretionOfSecurityColumn = {
  path: IS_DISCRETION_OF_SECURITY,
  name: IS_DISCRETION_OF_SECURITY,
  title: 'На усмотрение СБ',
  type: columnType.BOOLEAN,
  width: 100,
};

export const isDiscretionOfSecurityColumnFilter = {
  columnName: IS_DISCRETION_OF_SECURITY,
  value: '',
};

/** ---------- department Column ---------- */
export const DEPARTMENT = 'department';

export const departmentColumn = {
  path: DEPARTMENT,
  name: DEPARTMENT,
  title: 'Здание',
  getCellValue: (row) => row.department ? row.department.name : undefined,
  width: 150,
};

export const departmentColumnFilter = {
  columnName: DEPARTMENT,
  value: '',
};

/** ---------- berlinskyTime Column ---------- */
export const BERLINSKY_TIME = 'berlinskyTime';

export const berlinskyTimeColumn = {
  path: ['berlinskyTime', 'name'],
  name: BERLINSKY_TIME,
  title: 'Время',
  getCellValue: (row) => row.berlinskyTime ? row.berlinskyTime.name : undefined,
  width: 200,
};

export const berlinskyTimeColumnFilter = {
  columnName: BERLINSKY_TIME,
  value: '',
};

/** ---------- berlinskyVisitTime Column ---------- */
export const BERLINSKY_VISIT_TIME = 'berlinskyVisitTime';

export const berlinskyVisitTimeColumn = {
  path: ['berlinskyVisitTime', 'name'],
  name: BERLINSKY_VISIT_TIME,
  title: 'Время',
  getCellValue: (row) => row.berlinskyVisitTime ? row.berlinskyVisitTime.name : undefined,
  width: 200,
};

export const berlinskyVisitTimeColumnFilter = {
  columnName: BERLINSKY_VISIT_TIME,
  value: '',
};

/** PASS TAB */
export const getPassTabColumns = (styles) => ([
  {
    path: 'number',
    name: 'number',
    title: '№',
    type: columnType.NUMERIC,
    width: columnSize.NUMERIC,
    styles,
  },
  {
    path: ['status', 'name'],
    name: 'statusName',
    getCellValue: (row) => (row.status ? row.status.name : undefined),
    title: 'Статус',
    type: columnType.LABEL,
    labels: columnStatusLabels,
    width: requestColumnSizes.statusName,
  },
  {
    path: ['type', 'shortName'],
    name: 'typeShortName',
    getCellValue: (row) => (row.type ? row.type.shortName : undefined),
    title: 'Вид',
    width: requestColumnSizes.typeShortName,
    styles,
  },
  {
    path: 'visitorFullName',
    name: 'visitorFullName',
    title: 'ФИО посетителя',
    width: requestColumnSizes.visitorFullName,
    styles,
  },
  {
    path: 'carNumber',
    name: 'carNumber',
    title: '№ авто',
    width: requestColumnSizes.carNumber,
    styles,
  },
  {
    path: 'carModelText',
    name: 'carModelText',
    title: 'Марка',
    width: requestColumnSizes.carModelText,
    styles,
  },
  {
    path: 'berlinskyTime',
    name: 'berlinskyTime',
    getCellValue: (row) => row.berlinskyTime ? row.berlinskyTime.name : undefined,
    title: 'Время',
    width: 120,
    styles,
  },
  {
    path: 'issueReason',
    name: 'issueReason',
    getCellValue: (row) => row.issueReason ? row.issueReason.name : undefined,
    title: 'Причина',
    width: 100,
    styles,
  },
  {
    path: 'berlinskyAccessAreas',
    name: 'berlinskyAccessAreas',
    getCellValue: (row) => row.berlinskyAccessAreas ? row.berlinskyAccessAreas.name : undefined,
    title: 'Зона доступа',
    width: 150,
    styles,
  },
  {
    path: ['company', 'name'],
    name: 'company_name',
    getCellValue: (row) => row.company ? row.company.name : undefined,
    title: 'Арендатор',
    width: requestColumnSizes.company_name,
    styles,
  },
  departmentColumn,
  {
    path: ['file', 'name'],
    name: 'fileName',
    getCellValue: (row) => (row.file ? row.file.id : undefined),
    title: 'Файл',
    type: columnType.FILE,
    width: requestColumnSizes.fileName,
    styles,
  },
  actualCreationDateColumn,
]);

export const passTabColumnFilters = [
  { columnName: 'number', value: '' },
  { columnName: 'statusName', value: '' },
  { columnName: 'typeShortName', value: '' },
  { columnName: 'visitorFullName', value: '' },
  { columnName: 'carNumber', value: '' },
  { columnName: 'carModelText', value: '' },
  { columnName: 'berlinskyTime', value: '' },
  { columnName: 'issueReason', value: '' },
  { columnName: 'berlinskyAccessAreas', value: '' },
  { columnName: 'company_name', value: '' },
  departmentColumnFilter,
  { columnName: 'fileName', value: '' },
  actualCreationDateColumnFilter,
];

export const passTabColumnOrder = [
  'number', 'statusName', 'typeShortName', 'visitorFullName', 'carNumber', 'carModelText', 'berlinskyTime', 'issueReason',
  'berlinskyAccessAreas', 'company_name', DEPARTMENT, 'fileName', ACTUAL_CREATION_DATE,
];
