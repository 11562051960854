/**
 *
 * ReceptionFormDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import AdvancedFormDialog from 'containers/AdvancedFormDialog';
import { RECEPTION_RULE_FORM, RECEPTION_RULE_FORM_DIALOG } from './constants';

export function ReceptionFormDialog({ form, viewName }) {
  return (
    <AdvancedFormDialog
      name={RECEPTION_RULE_FORM_DIALOG}
      formName={RECEPTION_RULE_FORM}
      advancedForm={form}
      addModeTitle="Новый документ"
      editModeTitle="Изменение документа"
      viewModeTitle="Просмотр документа"
      copyModeTitle="Копирование документа"
      viewName={viewName}
    />
  );
}

ReceptionFormDialog.propTypes = {
  form: PropTypes.node.isRequired,
  viewName: PropTypes.string,
};

