/*
 *
 * WorkFormContainer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectParent, makeSelectParentId, } from 'containers/AdvancedFormDialog/selectors'
import * as actionCreators from './actions';
import WorkForm from './WorkForm';
import { USER_WORK_FORM_DIALOG } from '../../Works/WorkView/constants';

class WorkFormContainer extends React.PureComponent {
    render() {
        return (
            <WorkForm  {...this.props} />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    parentId: makeSelectParentId(USER_WORK_FORM_DIALOG),
    parent: makeSelectParent(USER_WORK_FORM_DIALOG),
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkFormContainer);
