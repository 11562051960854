/*
 *
 * LoginForm actions
 *
 */

import {
  SUBMIT_LOGIN_FORM, FORGOT_PASSWORD_LOGIN_FORM, SET_MESSAGE_FORM, SEND_EMAIL_PASSWORD_RECOVERY,
} from './constants';

export function submitLoginForm(login, password, nextPathAfterLogin) {
  return {
    type: SUBMIT_LOGIN_FORM,
    payload: {
      login,
      password,
      nextPathAfterLogin,
    },
  };
}

export function forgotPasswordLoginForm() {
  return {
    type: FORGOT_PASSWORD_LOGIN_FORM,
  };
}

export function setInfoMessage(message, typeMessage) {
  return {
    type: SET_MESSAGE_FORM,
    payload: {
       message,
       typeMessage
      },
  };
}
