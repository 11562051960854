/*
 *
 * RequestsPage15774
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import {TenantForm15774, TenantFormDialog15774} from './TenantView';
import {
    ReceptionDxGrid15774,
    ReceptionForm15774,
    ReceptionFormDialog15774,
    ReceptionMobileGrid15774,
} from './ReceptionView';

import {
    AdministratorBCRequestsDxGrid15774,
    AdministratorForm15774,
    AdministratorFormDialog15774,
} from './AdministratorBCView';
import {getDetailContainer} from './common/detailContainers';
import {TenantDxGrid15774} from "./TenantView/TeenantDxGrid15774";


function RequestsPage15774(props) {
    return (
        <RequestsPage
            administratorBCDxGrid={<AdministratorBCRequestsDxGrid15774/>}
            administratorBCForm={<AdministratorForm15774/>}
            administratorBCFormDialog={
                <AdministratorFormDialog15774
                    form={<AdministratorForm15774/>}
                    detailContainer={getDetailContainer()}
                />
            }
            tenantDxGrid={<TenantDxGrid15774/>}
            tenantForm={<TenantForm15774/>}
            tenantFormDialog={
                <TenantFormDialog15774
                    form={<TenantForm15774/>}
                    detailContainer={getDetailContainer()}
                />
            }
            receptionDxGrid={<ReceptionDxGrid15774/>}
            receptionForm={<ReceptionForm15774/>}
            receptionFormDialog={
                <ReceptionFormDialog15774
                    form={<ReceptionForm15774/>}
                    detailContainer={getDetailContainer()}
                />
            }
            receptionMobileGrid={<ReceptionMobileGrid15774/>}

            {...props}
        />
    );
}

export default RequestsPage15774;
