/**
 *
 * SeniorParkingOperatorView constants
 *
 */

export const SENIOR_PARKING_OPERATOR_REQUESTS_TABLE_GRID = 'SeniorParkingOperatorRequestsTableGrid';
export const SENIOR_PARKING_OPERATOR_REQUESTS_FORM = 'SeniorParkingOperatorRequestsForm';
export const SENIOR_PARKING_OPERATOR_REQUESTS_FORM_DIALOG = 'SeniorParkingOperatorRequestsFormDialog';
export const BLACK_LIST_FORM = 'SeniorParkingOperatorBlackListForm';
export const BLACK_LIST_FORM_DIALOG = 'SeniorParkingOperatorBlackListFormDialog';

export const EXECUTE_VIEW_ACTION = 'claris-vnext/RequestsPage/SeniorParkingOperatorView/EXECUTE_VIEW_ACTION';
export const EXECUTE_ARRIVED_ACTION = 'claris-vnext/RequestsPage/SeniorParkingOperatorView/EXECUTE_ARRIVED_ACTION';
export const EXECUTE_DEPARTED_ACTION = 'claris-vnext/RequestsPage/SeniorParkingOperatorView/EXECUTE_DEPARTED_ACTION';
export const EXECUTE_ADD_BLACK_LIST_ITEM_ACTION = 'claris-vnext/RequestsPage/SeniorParkingOperatorView/EXECUTE_ADD_BLACK_LIST_ITEM_ACTION';
