/**
 *
 * RequestsPage13539
 *
 */

import React from 'react';

import RequestsPage from 'pages/Requests/RequestsPage';
import CheckpointDxGrid13539 from './CheckpointView/CheckpointDxGrid13539';
import CheckpointForm13539 from './CheckpointView/CheckpointForm13539';

import { ReceptionForm13539 } from './ReceptionView';
import ReceptionFormDialog13539 from './ReceptionView/ReceptionFormDialog13539';

import TenantForm13539 from './TenantView/TenantForm13539';

function RequestsPage13539(props) {
  return (
    <RequestsPage
      checkpointDxGrid={<CheckpointDxGrid13539 />}
      checkpointForm={<CheckpointForm13539 />}
      receptionForm={<ReceptionForm13539 />}
      receptionFormDialog={
        <ReceptionFormDialog13539
          form={<ReceptionForm13539 />}
        />
      }
      tenantForm={<TenantForm13539 />}
      {...props}
    />
  );
}

export default RequestsPage13539;
