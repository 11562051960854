/**
 *
 * SightingView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

function SightingView({
  dataTable, formDialog,
  agreementDialog, disagreementDialog,
  taskFormDialog, viewName, viewStyles, }) {
  return (
    <div style={viewStyles.root}>
      <Paper style={viewStyles.dataTable}>
        {
          React.cloneElement(dataTable, { viewName })
        }
      </Paper>
      { React.cloneElement(formDialog, { viewName }) }
      {taskFormDialog}
      {agreementDialog}
      {disagreementDialog}
    </div>
  );
}

SightingView.propTypes = {
  agreementDialog: PropTypes.node.isRequired,
  dataTable: PropTypes.node.isRequired,
  disagreementDialog: PropTypes.node.isRequired,
  formDialog: PropTypes.node.isRequired,
  taskFormDialog: PropTypes.node.isRequired,
  viewName: PropTypes.string.isRequired,
};

SightingView.defaultProps = {
  viewStyles: {},
};

export default SightingView;
